import type {
  MediaOverrideConnection,
  PhotosOverrideEdge,
  CneVideosOverrideEdge,
  MediaOverridesInput,
  MediaOverridesInputV2,
} from "@types";

export function relMediaOverridesConnection(
  input?: MediaOverrideConnection
): MediaOverridesInputV2[] | null {
  let mediaOverridesInput = [] as MediaOverridesInputV2[];

  input?.edges?.map((edge) => {
    const photosOverrideEdge = edge as PhotosOverrideEdge;
    const cneOverrideEdge = edge as CneVideosOverrideEdge;

    if (
      photosOverrideEdge.node?.id &&
      photosOverrideEdge.pagelayoutMediaOverrides
    ) {
      let photoIndex = mediaOverridesInput.findIndex(
        (d) => d.relName === photosOverrideEdge.relName
      );
      let photosMediaOverride = {
        id: photosOverrideEdge.node.id,
        pagelayoutMediaOverrides:
          photosOverrideEdge.pagelayoutMediaOverrides?.map(
            (pagelayoutMediaOverrides) => ({
              breakpoint: pagelayoutMediaOverrides?.breakpoint || "",
              aspectRatio: pagelayoutMediaOverrides?.aspectRatio || "",
            })
          ),
      };
      photoIndex >= 0 && mediaOverridesInput[photoIndex]
        ? mediaOverridesInput[photoIndex].overrides.push(photosMediaOverride)
        : mediaOverridesInput.push({
            relName: photosOverrideEdge.relName,
            overrides: [photosMediaOverride],
          });
    } else if (cneOverrideEdge.node?.id && cneOverrideEdge.cneVideoOverrides) {
      let cneVideoIndex = mediaOverridesInput.findIndex(
        (d) => d.relName === photosOverrideEdge.relName
      );
      let cneVideoOverride = {
        id: cneOverrideEdge.node.id,
        cneVideoOverrides: {
          disableAutoplay:
            cneOverrideEdge.cneVideoOverrides.disableAutoplay || false,
          muted: cneOverrideEdge.cneVideoOverrides.muted,
          loopVideo: cneOverrideEdge.cneVideoOverrides.loopVideo,
          disableAds: cneOverrideEdge.cneVideoOverrides.disableAds,
          continuousPlay: cneOverrideEdge.cneVideoOverrides.continuousPlay,
        },
      };
      cneVideoIndex >= 0 && mediaOverridesInput[cneVideoIndex]
        ? mediaOverridesInput[cneVideoIndex].overrides.push(cneVideoOverride)
        : mediaOverridesInput.push({
            relName: photosOverrideEdge.relName,
            overrides: [cneVideoOverride],
          });
    }
  });
  return mediaOverridesInput.length ? mediaOverridesInput : null;
}

export function mediaOverridesConnection(
  input?: MediaOverridesInput[]
): MediaOverridesInput[] | null {
  return input?.length ? input : null;
}

import { useEffect, useCallback } from "react";
import { Queries } from "@gql";
import { useApolloClient, useQuery } from "@apollo/client";
import { RESULT_TYPES, RecentSearchCandidate } from "../types";
import type {
  GetRecentSearch as GetRecentSearch,
  GetRecentSearchVariables as GetRecentSearchVariables,
  GetRecentSearch_recentSearches as RecentSearch,
} from "@types";

export const useRecentSearches = (
  copilotCode: string
): [() => RecentSearch[], (value: RecentSearchCandidate) => RecentSearch[]] => {
  const { data } = useQuery<GetRecentSearch, GetRecentSearchVariables>(
    Queries.GET_RECENT_SEARCH,
    {
      variables: { copilotCode },
    }
  );
  const Client = useApolloClient();

  const invalidateCache = useCallback(
    function (event: StorageEvent | null) {
      if (
        event === null ||
        (event.key && event.key.startsWith("recentStorage"))
      ) {
        Client.cache.evict({
          id: "ROOT_QUERY",
          fieldName: "recentSearches",
        });
        Client.cache.gc();
      }
    },
    [Client]
  );

  useEffect(() => {
    window.addEventListener("storage", invalidateCache);
    return () => {
      window.removeEventListener("storage", invalidateCache);
    };
  }, [invalidateCache]);

  const getRecentSearches = useCallback(() => {
    if (!copilotCode) {
      return [];
    }
    return data?.recentSearches || [];
  }, [data, copilotCode]);

  const addRecentSearch = useCallback(
    (value: RecentSearchCandidate) => {
      let recentSearches = getRecentSearches();
      let recentSearchToAdd: RecentSearch;
      switch (value.__typename) {
        case RESULT_TYPES.query:
        case RESULT_TYPES.recentQuery: {
          let query = value.query.trim();
          if (query) {
            recentSearchToAdd = {
              query,
              __typename: RESULT_TYPES.recentQuery,
            };
          } else {
            return recentSearches;
          }
          break;
        }
        case RESULT_TYPES.recentSearchResult:
          recentSearchToAdd = value;
          break;
        default: {
          let { contentType, ...searchResult } = value;
          recentSearchToAdd = {
            ...searchResult,
            type: value.contentType,
            __typename: RESULT_TYPES.recentSearchResult,
          };
          break;
        }
      }

      let mergedRecentSearches = recentSearches?.filter((recentSearch) => {
        if (
          recentSearch.__typename === RESULT_TYPES.recentQuery &&
          recentSearchToAdd.__typename === RESULT_TYPES.recentQuery
        ) {
          return recentSearch.query !== recentSearchToAdd.query;
        } else if (
          recentSearch.__typename === RESULT_TYPES.recentSearchResult &&
          recentSearchToAdd.__typename === RESULT_TYPES.recentSearchResult
        ) {
          return recentSearch?.id !== recentSearchToAdd?.id;
        }
        return true;
      });
      mergedRecentSearches.unshift(recentSearchToAdd);
      let updatedRecentSearches = mergedRecentSearches.slice(0, 5);
      //we dont want to store recentSearches with the __typename property in localStorage
      let recentSearchesToStore = updatedRecentSearches.map(
        ({ __typename, ...recentSearchProps }) => recentSearchProps
      );
      window.localStorage.setItem(
        `recentSearch:${copilotCode}`,
        JSON.stringify(recentSearchesToStore)
      );
      invalidateCache(null);
      return updatedRecentSearches;
    },
    [copilotCode, getRecentSearches, invalidateCache]
  );

  return [getRecentSearches, addRecentSearch];
};

import { Chip } from "@condenast/gemini";
import { useDefinedMessages } from "@hooks";
import styled from "styled-components";

const chipColorMap = new Map([
  ["verso-flat-package", "var(--color-purple-70)"],
  ["verso-focus-package", "var(--color-yellow-70)"],
  ["verso-top-story-package", "var(--color-green-60)"],
]);

const StyleChip = styled(Chip)`
  background-color: ${({ curationContainerType }) =>
    chipColorMap.get(curationContainerType)};
  color: var(--color-gray-1);
  margin-right: 0.25rem;
`;

const StyledPreset = styled.span`
  font-size: 0.75rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubHeading = styled.div`
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Indicator = styled.span`
  height: 6px;
  width: 6px;
  background-color: #778bfe;
  border-radius: 50%;
  display: inline-block;
`;

function Header({
  title,
  componentPreset,
  curationContainerType,
  hasChanges,
  hasContainerOptions,
}: {
  title: string;
  componentPreset?: string | null;
  curationContainerType: string;
  hasChanges: boolean;
  hasContainerOptions: boolean;
}) {
  const { translatePreviewOptions } = useDefinedMessages();

  const hasSubheading = Boolean(hasContainerOptions && componentPreset);
  return (
    <HeaderContainer>
      <HeaderTitle>
        {title}{" "}
        {hasChanges && (
          <Indicator role="tooltip" aria-label="Unsaved Changes Indicator" />
        )}
      </HeaderTitle>

      <SubHeading>
        {hasSubheading && (
          <>
            <StyleChip
              size="small"
              curationContainerType={curationContainerType}
            >
              {translatePreviewOptions(curationContainerType)}
            </StyleChip>
            <StyledPreset>{componentPreset}</StyledPreset>
          </>
        )}
      </SubHeading>
    </HeaderContainer>
  );
}

export default Header;

import type { FieldMergeFunction, TypePolicy } from "@apollo/client";
import type { GetLiveStoryUpdates_getLiveStory_updates as LiveStoryUpdateResult } from "@types";
import { safelyGetFromReference } from "../utils";

const mergeLiveStoryUpdates: FieldMergeFunction<
  LiveStoryUpdateResult,
  LiveStoryUpdateResult
> = (existing, incoming, { readField, isReference }) => {
  const existingEdges = existing?.edges ?? [];
  const newEdges = incoming.edges ?? [];
  if (existing && newEdges.length === 0) {
    return existing;
  }

  const mergedEdges = newEdges.length
    ? [...newEdges, ...existingEdges].filter((edge, index, array) => {
        const id = safelyGetFromReference(
          "id",
          edge?.node,
          isReference,
          readField
        );
        return (
          array.findIndex((edgeInList) => {
            const idInList = safelyGetFromReference(
              "id",
              edgeInList?.node,
              isReference,
              readField
            );
            return idInList === id;
          }) === index
        );
      })
    : existingEdges;
  if (existing && mergedEdges.length === existingEdges.length) {
    return existing;
  }
  mergedEdges.sort((edge1, edge2) => {
    const createdAt1 = safelyGetFromReference<string>(
      "createdAt",
      edge1?.node,
      isReference,
      readField
    );
    const createdAt2 = safelyGetFromReference<string>(
      "createdAt",
      edge2?.node,
      isReference,
      readField
    );
    if (!createdAt1) {
      return createdAt2 ? 1 : 0;
    } else if (!createdAt2) {
      return createdAt1 ? -1 : 0;
    } else if (createdAt1 > createdAt2) {
      return -1;
    } else if (createdAt2 > createdAt1) {
      return 1;
    } else {
      return 0;
    }
  });
  return {
    ...incoming,
    edges: mergedEdges,
  };
};

export const LiveStory: TypePolicy = {
  fields: {
    liveUpdates: {
      keyArgs: ["id", "organizationId"],
      merge: mergeLiveStoryUpdates,
    },
  },
};

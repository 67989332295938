import {
  ErrorMessage,
  ErrorPath,
  ValidationError,
} from "@condenast/cross-check";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import {
  ERROR_FIELD_MESSAGES,
  ERROR_FIELD_UNKNOWN,
  ERROR_SUMMARY_MESSAGES,
  ERROR_SUMMARY_UNKNOWN,
} from "./errorMessages";

import { AUTOGENERATED_MESSAGES } from "./autogeneratedMessages";
import { BYLINE_PREVIEW } from "./bylinePreview";
import { CONTENT_TYPES } from "./contentTypes";
import { CONTEXTUAL_HELP } from "./contextualHelp";
import { CONTRIBUTOR_CREDITS } from "./contributorCredits";
import { CONTRIBUTOR_TYPES } from "./contributorTypes";
import { FIELD_DESCRIPTIONS } from "./fieldDescriptions";
import { FIELD_LEGENDS } from "./fieldLegends";
import { FIELD_NAMES } from "./fieldNames";
import { isKeyOf } from "@lib";
import { LANGUAGES } from "./languages";
import { PAGE_LAYOUT } from "./pageLayout";
import { PLACEHOLDERS } from "./placeholders";
import { PREVIEW_OPTIONS } from "./previewOptions";
import { SELECT_OPTIONS } from "./selectOptions";
import { SEO_MESSAGES } from "./seoRules";
import { SEOResult, UserRole } from "@types";
import { USER_ROLES } from "./userRoles";
import { RESYNC_LABEL_TYPES } from "./resyncLabelTyes";
import { PRESET_NAMES } from "./presetNames";
import { TOOLTIP_OPTIONS } from "./tooltipOptions";

export const useDefinedMessages = () => {
  const intl = useIntl();

  const translateAutogeneratedMessages = useCallback(
    (name: string) => {
      if (isKeyOf(AUTOGENERATED_MESSAGES, name)) {
        return intl.formatMessage(AUTOGENERATED_MESSAGES[name]);
      }
      return undefined;
    },
    [intl]
  );

  const translateContentType = useCallback(
    (name: string, count: number | "many", fallback?: string) => {
      if (isKeyOf(CONTENT_TYPES, name)) {
        return intl.formatMessage(CONTENT_TYPES[name], {
          count: count === "many" ? 100 : count,
        });
      }

      return fallback || name;
    },
    [intl]
  );

  const translateContextualHelp = useCallback(
    (key: string) => {
      if (isKeyOf(CONTEXTUAL_HELP, key)) {
        return intl.formatMessage(CONTEXTUAL_HELP[key]);
      }

      return key;
    },
    [intl]
  );

  const translateContributorType = useCallback(
    (key: string) => {
      if (isKeyOf(CONTRIBUTOR_TYPES, key)) {
        return intl.formatMessage(CONTRIBUTOR_TYPES[key]);
      }

      return key;
    },
    [intl]
  );

  const translateContributorCredit = useCallback(
    (key: string, names: string[]) => {
      let uppercaseKey = key.toUpperCase();
      if (isKeyOf(CONTRIBUTOR_CREDITS, uppercaseKey)) {
        return intl.formatMessage(CONTRIBUTOR_CREDITS[uppercaseKey], {
          names: intl.formatList(names),
        });
      }
      return key;
    },
    [intl]
  );

  const translateResyncLabelType = useCallback(
    (key: string) => {
      if (isKeyOf(RESYNC_LABEL_TYPES, key)) {
        return intl.formatMessage(RESYNC_LABEL_TYPES[key]);
      }

      return key;
    },
    [intl]
  );
  const translateFieldName = useCallback(
    (fieldName: string) => {
      const path = fieldName.split(".");
      while (path.length) {
        const name = path.join(".");
        if (isKeyOf(FIELD_NAMES, name)) {
          return intl.formatMessage(FIELD_NAMES[name]);
        }
        // check for indexed paths ex. `items.0.node`
        const pathWithoutIndex = path.filter((part) => isNaN(parseInt(part)));
        if (pathWithoutIndex.length < path.length) {
          const nameWithoutIndex = pathWithoutIndex.join(".");
          if (isKeyOf(FIELD_NAMES, nameWithoutIndex)) {
            return intl.formatMessage(FIELD_NAMES[nameWithoutIndex]);
          }
        }
        path.pop();
      }

      return fieldName;
    },
    [intl]
  );

  const translateFieldLegend = useCallback(
    (legend: string) => {
      if (isKeyOf(FIELD_LEGENDS, legend)) {
        return intl.formatMessage(FIELD_LEGENDS[legend]);
      }

      return legend;
    },
    [intl]
  );

  const translateFieldDescription = useCallback(
    (key: string) => {
      if (isKeyOf(FIELD_DESCRIPTIONS, key)) {
        return intl.formatMessage(FIELD_DESCRIPTIONS[key]);
      }

      return key;
    },
    [intl]
  );

  const translatePageLayout = useCallback(
    (key: string) => {
      if (isKeyOf(PAGE_LAYOUT, key)) {
        return intl.formatMessage(PAGE_LAYOUT[key]);
      }

      return key;
    },
    [intl]
  );

  const translateBylinePreview = useCallback(
    (key: string, brandName: string | null, advertiserName: string) => {
      if (isKeyOf(BYLINE_PREVIEW, key)) {
        return intl.formatMessage(BYLINE_PREVIEW[key], {
          brandName: brandName,
          advertiserName: advertiserName,
        });
      }
      return key;
    },
    [intl]
  );

  const translatePlaceholder = useCallback(
    (key: string) => {
      if (isKeyOf(PLACEHOLDERS, key)) {
        return intl.formatMessage(PLACEHOLDERS[key]);
      }

      return key;
    },
    [intl]
  );

  const translateSelectOption = useCallback(
    (optionsPath: string, value: string) => {
      const key = `${optionsPath}.${value}`;
      if (isKeyOf(SELECT_OPTIONS, key)) {
        return intl.formatMessage(SELECT_OPTIONS[key]);
      }

      return value;
    },
    [intl]
  );

  const translateRoleName = useCallback(
    (role: UserRole) => intl.formatMessage(USER_ROLES[role]),
    [intl]
  );

  const translatePreviewOptions = useCallback(
    (key: string) => {
      if (isKeyOf(PREVIEW_OPTIONS, key)) {
        return intl.formatMessage(PREVIEW_OPTIONS[key]);
      }

      return key;
    },
    [intl]
  );

  const translateFieldError = useCallback(
    (errorMessage: ErrorMessage): { name: string; message: string } => {
      if (isKeyOf(ERROR_FIELD_MESSAGES, errorMessage.name)) {
        return {
          name: errorMessage.name,
          message: intl.formatMessage(
            ERROR_FIELD_MESSAGES[errorMessage.name],
            (errorMessage.details || {}) as Record<string, string>
          ),
        };
      }

      return {
        name: errorMessage.name,
        message: intl.formatMessage(ERROR_FIELD_UNKNOWN, {
          name: errorMessage.name,
        }),
      };
    },
    [intl]
  );

  const translateSummaryErrors = useCallback(
    (errors: ValidationError[]): { path: ErrorPath; message: string }[] => {
      const translatedSummary = (error: ValidationError): string => {
        const field = error.path.join(".");
        if (isKeyOf(ERROR_SUMMARY_MESSAGES, error.message.name)) {
          return intl.formatMessage(
            ERROR_SUMMARY_MESSAGES[error.message.name],
            {
              field: translateFieldName(field),
              ...((error.message.details || {}) as Record<string, string>),
            }
          );
        }

        return intl.formatMessage(ERROR_SUMMARY_UNKNOWN, {
          field,
          name: error.message.name,
        });
      };

      const translatedSummaryErrors = errors.map((error) => {
        return {
          path: error.path,
          message: translatedSummary(error),
        };
      });

      return translatedSummaryErrors;
    },
    [intl, translateFieldName]
  );

  const translateSEORule = useCallback(
    (rule: SEOResult) => {
      const ruleName = `${rule.name}.${rule.key}`;
      if (isKeyOf(SEO_MESSAGES, ruleName)) {
        return intl.formatMessage(SEO_MESSAGES[ruleName], rule);
      }

      return intl.formatMessage(
        {
          defaultMessage: "Untranslated SEO Rule, {rule}",
        },
        { rule: ruleName }
      );
    },
    [intl]
  );

  const translateLanguage = useCallback(
    (lang: string) => {
      if (isKeyOf(LANGUAGES, lang)) {
        return intl.formatMessage(LANGUAGES[lang]);
      }

      return lang;
    },
    [intl]
  );

  const translateComponentPresetsOptions = useCallback(
    (option: string) => {
      if (isKeyOf(PRESET_NAMES, option)) {
        return intl.formatMessage(PRESET_NAMES[option]);
      }

      return option;
    },
    [intl]
  );

  const translateToolTip = useCallback(
    (key: string, date: string) => {
      if (isKeyOf(TOOLTIP_OPTIONS, key)) {
        return intl.formatMessage(TOOLTIP_OPTIONS[key], {
          date,
        });
      }
      return key;
    },
    [intl]
  );

  return useMemo(
    () => ({
      translateAutogeneratedMessages,
      translateBylinePreview,
      translateContentType,
      translateContextualHelp,
      translateContributorCredit,
      translateContributorType,
      translateFieldDescription,
      translateFieldError,
      translateFieldLegend,
      translateFieldName,
      translateLanguage,
      translatePageLayout,
      translatePlaceholder,
      translatePreviewOptions,
      translateRoleName,
      translateSelectOption,
      translateSEORule,
      translateSummaryErrors,
      translateResyncLabelType,
      translateComponentPresetsOptions,
      translateToolTip,
    }),
    [
      translateAutogeneratedMessages,
      translateBylinePreview,
      translateContentType,
      translateContextualHelp,
      translateContributorCredit,
      translateContributorType,
      translateFieldDescription,
      translateFieldError,
      translateFieldLegend,
      translateFieldName,
      translateLanguage,
      translatePageLayout,
      translatePlaceholder,
      translatePreviewOptions,
      translateRoleName,
      translateSelectOption,
      translateSEORule,
      translateSummaryErrors,
      translateResyncLabelType,
      translateComponentPresetsOptions,
      translateToolTip,
    ]
  );
};

import { createTagpage_content_TagPage_query } from "@types";

export function query(input: createTagpage_content_TagPage_query) {
  const { filters } = input;
  const categories = filters?.categories || [];
  const id = categories.length > 0 ? [categories[0].id] : [];
  return {
    filters: {
      categoryIds: id,
      types: filters?.types || [],
    },
  };
}

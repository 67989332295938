import {
  Button,
  Card,
  CKEditor,
  Field,
  TextField,
  Select,
  Label,
} from "@components";
import { useDefinedMessages, useUniqueId } from "@hooks";
import {
  ChevronDownIcon,
  DraggableIcon,
  BinIcon,
  ChevronUpIcon,
} from "@condenast/gemini/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { IngredientData } from "src/types/recipe";
import CopilotMarkdownRenderer from "@condenast/atjson-renderer-copilot-markdown";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";
import {
  BlockEditor,
  InlineEditor,
  MinimalistEditor,
} from "@condenast/ckeditor5-build-condenast";
import { CKEditorConfiguration } from "@types";

const Ingredient = styled(Card)<{ $itemCollapsed: boolean }>`
  position: relative;
  display: grid;
  column-gap: var(--spacing-xs);
  row-gap: ${({ $itemCollapsed }) =>
    $itemCollapsed ? `0` : `var(--spacing-sm)`};
  padding-top: var(--spacing-sm);
  width: 100%;

  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    "number fields close"
    "number optional-fields close";

  :not(:first-child) {
    margin-top: 1px;
  }
  cursor: grab;
`;

const IngredientNumber = styled.div`
  grid-area: number;
  font-size: ${(props) => props.theme.FontStatement};
  text-align: center;
  padding: ${(props) => props.theme.SecondarySmallPadding};

  width: var(--spacing-lg);
  height: var(--spacing-lg);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

const EditorField = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

const Editor = styled(CKEditor)`
  grid-area: control;
`;

const BaseContextualFields = styled.div`
  grid-area: fields;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing-xs);

  > :nth-child(1) {
    flex-basis: calc(5 * var(--spacing-sm));
    flex-grow: 1;
  }
  > :nth-child(2) {
    flex-basis: calc(9.5 * var(--spacing-sm));
    flex-grow: 1;
  }
  > :nth-child(3) {
    flex-basis: calc(9.5 * var(--spacing-sm));
    flex-grow: 4;
  }
  > :nth-child(4) {
    flex-basis: calc(9.5 * var(--spacing-sm));
    flex-grow: 4;
  }
`;

const ExtendedContextualFields = styled(BaseContextualFields)`
  padding: var(--spacing-xs) var(--spacing-sm);
  grid-area: extended-fields;
  border-top: 1px solid ${(props) => props.theme.DividerColor};

  > :nth-child(1) {
    flex-basis: calc(9.5 * var(--spacing-sm));
    flex-grow: 1;
  }
  > :nth-child(2) {
    flex-basis: calc(9.5 * var(--spacing-sm));
    flex-grow: 1;
  }
  > :nth-child(3) {
    flex-basis: calc(9.5 * var(--spacing-sm));
    flex-grow: 1;
  }
  > :nth-child(4) {
    flex-basis: 100%;
  }
`;

const StyledSelect = styled(Select)`
  button {
    background: ${(props) => props.theme.SelectOptionHoverBackground};
  }
`;

const OptionalFields = styled.div<{ $open: boolean }>`
  grid-area: optional-fields;
  background: ${(props) => props.theme.SurfaceColor};
  svg {
    margin-top: var(--spacing-xxs);
    transform: ${({ $open }) => ($open ? `rotate(180deg)` : `rotate(0deg)`)};
  }
`;

const OptionalFieldsLabel = styled(Label)`
  width: 100%;
  padding: var(--spacing-xxs) var(--spacing-sm);
`;

const OptionalFieldsSubtitle = styled.span<{
  $open: boolean;
}>`
  display: ${({ $open }) => ($open ? "none" : "inherit")};
  font-weight: 400;
  margin-left: var(--spacing-sm);
`;

const Chevron = styled(ChevronDownIcon)`
  float: right;
`;

const IngredientActionBlock = styled.div<{ $itemCollapsed: boolean }>`
  display: flex;
  flex-direction: ${({ $itemCollapsed }) =>
    $itemCollapsed ? `row-reverse` : `column`};
`;

const CollapseButton = styled(Button)`
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    flex-shrink: 0;
  }
`;

const CloseButton = styled(Button)`
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    flex-shrink: 0;
  }
`;

const CollapsedIngredientHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-sm);

  & div {
    margin-right: var(--spacing-xs);
  }
`;

const Builds = {
  block: BlockEditor,
  inline: InlineEditor,
  minimalist: MinimalistEditor,
};

export function RecipeIngredient(props: {
  index: number;
  groupIndex: number;
  ingredient: IngredientData;
  onChange: (
    groupIndex: number,
    index: number,
    ingredient: IngredientData
  ) => void;
  onClose: (groupIndex: number, index: number) => void;
  ingredientRichTextBuild: keyof typeof Builds;
  ingredientRichTextConfig: CKEditorConfiguration;
  ingredientGroupRichTextBuild: keyof typeof Builds;
  unitOptions: Array<{ label: string; value: string; disabled?: boolean }>;
  isIngredientCollapsed: boolean;
  toggleIngredientCollapsed: (ingredient: IngredientData) => void;
}) {
  const {
    index,
    groupIndex,
    ingredient,
    onClose,
    onChange,
    ingredientRichTextBuild,
    isIngredientCollapsed,
    ingredientRichTextConfig,
    unitOptions,
    toggleIngredientCollapsed,
  } = props;

  const { translateFieldName } = useDefinedMessages();
  let intl = useIntl();
  const id = useUniqueId();

  const [quantityValue, setQuantityValue] = useState<string>(
    ingredient.unitQuantity ?? ""
  );
  const [unitSizeValue, setUnitSizeValue] = useState<string>(
    ingredient.unitSize ?? ""
  );
  const [unitValue, setUnitValue] = useState<string | undefined>(
    ingredient.unit ?? undefined
  );
  const [ingredientSizeValue, setIngredientSizeValue] = useState<string>(
    ingredient.ingredientSize ?? ""
  );
  const [ingredientNameValue, setIngredientNameValue] = useState<string>(
    ingredient.name ?? ""
  );
  const [treatmentValue, setTreatmentValue] = useState<string>(
    ingredient.treatment ?? ""
  );
  const [noteValue, setNoteValue] = useState<string>(ingredient.note ?? "");

  // CKEditor requires an onChange that is stable across re-renders
  // so update local state on change and have a separate effect that
  // can be updated when props change
  const [description, setDescription] = useState<string | null>(null);
  const onDescriptionChange = useCallback(
    (doc) => {
      setDescription(CopilotMarkdownRenderer.render(doc));
    },
    [setDescription]
  );
  useEffect(() => {
    if (description !== null) {
      onChange(groupIndex, index, {
        ...ingredient,
        description,
      } as IngredientData);
    }
    // only run when the description value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);
  const descriptionValue = useMemo(
    () =>
      CopilotMarkdownSource.fromRaw(ingredient.description ?? "").convertTo(
        VersoSource
      ),
    [ingredient]
  );

  let [showOptionalFields, setShowOptionalFields] = useState<boolean>(
    (unitSizeValue != "" ||
      ingredientSizeValue != "" ||
      treatmentValue != "" ||
      noteValue != "") ??
      false
  );

  const [numberIsHovered, setNumberIsHovered] = useState(false);

  return (
    <Ingredient
      onMouseOver={() => {
        setNumberIsHovered(true);
      }}
      onMouseOut={() => {
        setNumberIsHovered(false);
      }}
      $itemCollapsed={isIngredientCollapsed}
    >
      <IngredientNumber>
        {numberIsHovered ? (
          <DraggableIcon size="regular" className="draggable-icon" />
        ) : (
          props.index + 1
        )}
      </IngredientNumber>
      {isIngredientCollapsed ? (
        <CollapsedIngredientHeader>
          {Object.keys(ingredient).length === 1 ? (
            <FormattedMessage defaultMessage="New ingredient (empty)" />
          ) : (
            <>
              {ingredient.unitQuantity && (
                <div>{translateFieldName(ingredient.unitQuantity)}</div>
              )}
              {ingredient.unit && (
                <div>{translateFieldName(ingredient.unit)}</div>
              )}
              {ingredient.name && (
                <div>{translateFieldName(ingredient.name)}</div>
              )}
            </>
          )}
        </CollapsedIngredientHeader>
      ) : (
        <>
          <BaseContextualFields>
            <TextField
              id={`${id}-quantity`}
              label={translateFieldName("unitQuantity")}
              value={quantityValue}
              onChange={(unitQuantity: string) => {
                setQuantityValue(unitQuantity);
                onChange(groupIndex, index, {
                  ...ingredient,
                  unitQuantity,
                } as IngredientData);
              }}
              multiline={false}
            />
            <StyledSelect
              id={`${id}-unit`}
              label={translateFieldName("unit")}
              value={unitValue ?? undefined}
              onChange={(unit: string | undefined) => {
                setUnitValue(unit ?? undefined);
                onChange(groupIndex, index, {
                  ...ingredient,
                  unit,
                } as IngredientData);
              }}
              placeholder=""
              options={unitOptions}
            />
            <TextField
              id={`${id}-name`}
              label={translateFieldName("ingredient")}
              value={ingredientNameValue}
              onChange={(name: string) => {
                setIngredientNameValue(name);
                onChange(groupIndex, index, {
                  ...ingredient,
                  name,
                } as IngredientData);
              }}
              multiline={false}
            />
            <EditorField
              label={translateFieldName("description")}
              id={`CKEditor__${id}__description`}
            >
              <Editor
                onChange={onDescriptionChange}
                id={`CKEditor__${id}__description`}
                build={ingredientRichTextBuild}
                config={ingredientRichTextConfig}
                value={descriptionValue}
              />
            </EditorField>
          </BaseContextualFields>
          <OptionalFields $open={showOptionalFields}>
            <OptionalFieldsLabel
              onClick={() => {
                setShowOptionalFields(!showOptionalFields);
              }}
            >
              <FormattedMessage defaultMessage="Optional" />
              <OptionalFieldsSubtitle $open={showOptionalFields}>
                <FormattedMessage defaultMessage="Unit Size, Ingredient Size, Treatment, Note" />
              </OptionalFieldsSubtitle>
              <Chevron size="small" />
            </OptionalFieldsLabel>
            {showOptionalFields && (
              <ExtendedContextualFields>
                <TextField
                  id={`${id}-unit-size`}
                  label={translateFieldName("unitSize")}
                  value={unitSizeValue}
                  onChange={(unitSize: string) => {
                    setUnitSizeValue(unitSize);
                    onChange(groupIndex, index, {
                      ...ingredient,
                      unitSize,
                    } as IngredientData);
                  }}
                  multiline={false}
                />
                <TextField
                  id={`${id}-ingredient-size`}
                  label={translateFieldName("ingredientSize")}
                  value={ingredientSizeValue}
                  onChange={(ingredientSize: string) => {
                    setIngredientSizeValue(ingredientSize);
                    onChange(groupIndex, index, {
                      ...ingredient,
                      ingredientSize,
                    } as IngredientData);
                  }}
                  multiline={false}
                />
                <TextField
                  id={`${id}-treatment`}
                  label={translateFieldName("treatment")}
                  value={treatmentValue}
                  onChange={(treatment: string) => {
                    setTreatmentValue(treatment);
                    onChange(groupIndex, index, {
                      ...ingredient,
                      treatment,
                    } as IngredientData);
                  }}
                  multiline={false}
                />
                <TextField
                  id={`${id}-note`}
                  label={translateFieldName("note")}
                  value={noteValue ?? ""}
                  onChange={(note: string) => {
                    setNoteValue(note);
                    onChange(groupIndex, index, {
                      ...ingredient,
                      note,
                    } as IngredientData);
                  }}
                  multiline={false}
                />
              </ExtendedContextualFields>
            )}
          </OptionalFields>
        </>
      )}
      <IngredientActionBlock $itemCollapsed={isIngredientCollapsed}>
        <CollapseButton
          aria-label={intl.formatMessage({
            defaultMessage: "Toggle Collapse",
          })}
          onClick={() => toggleIngredientCollapsed(ingredient)}
        >
          {isIngredientCollapsed ? (
            <ChevronDownIcon size="regular" />
          ) : (
            <ChevronUpIcon size="regular" />
          )}
        </CollapseButton>
        <CloseButton
          aria-label={intl.formatMessage({
            defaultMessage: "Remove Ingredient",
          })}
          onClick={() => onClose(index, groupIndex)}
        >
          <BinIcon size="regular" />
        </CloseButton>
      </IngredientActionBlock>
    </Ingredient>
  );
}

import { CuratedListItems } from "@components";
import type {
  ContentSummary,
  ContentSummaryFields,
  ControlProps,
  FormError,
  FormFor_content_CuratedList_items_edges,
  FormFor_content_CuratedList_items,
  FormFor_form_controls_SortableCuratedListItemsFormControl,
  CuratedListItemUpdateOperation,
} from "@types";
import update from "immutability-helper";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { normalizeContentSummary, serializeContentSummary } from "@lib";
import { AssetSelector, useCKEditorConfig } from "../-private";
import { useDeferredPromise, useDefinedMessages, useToast } from "@hooks";
import { useIntl } from "react-intl";

const CKEDITOR_BUILDS = {
  Minimalist: "minimalist",
  Inline: "inline",
  Body: "block",
  Block: "block",
} as const;

export function SortableCuratedListItems(
  props: ControlProps<FormFor_form_controls_SortableCuratedListItemsFormControl>
) {
  const {
    assetSelectorConfiguration,
    dekRichTextConfiguration,
    hedRichTextConfiguration,
    currentOrganization,
    currentUser,
    model,
    setValue,
    errors,
  } = props;

  const itemIDRef = useRef<number>(0);

  const { translateContentType } = useDefinedMessages();

  const resolvedHedRichTextConfig = useCKEditorConfig(
    hedRichTextConfiguration,
    currentOrganization,
    model.id as string
  );

  const resolvedHedRichTextBuild =
    CKEDITOR_BUILDS[hedRichTextConfiguration?.build ?? "Minimalist"];

  const resolvedDekRichTextConfig = useCKEditorConfig(
    dekRichTextConfiguration,
    currentOrganization,
    model.id as string
  );
  const resolvedDekRichTextBuild =
    CKEDITOR_BUILDS[dekRichTextConfiguration?.build ?? "Minimalist"];

  const { defer: deferSelectingAssets, deferRef: selectingAssets } =
    useDeferredPromise<ContentSummary[] | null>();

  const [assetSelectorContextualOptions, setAssetSelectorContextualOptions] =
    useState<{ limitSelection?: number } | undefined>(undefined);
  const [assetSelectorToggle, setAssetSelectorToggle] = useState(false);
  const resolvedAssetSelectorOptions = useMemo(
    () =>
      assetSelectorConfiguration
        ? {
            ...assetSelectorConfiguration,
            ...assetSelectorContextualOptions,
          }
        : null,
    [assetSelectorConfiguration, assetSelectorContextualOptions]
  );

  const modelValue = model["items"] as FormFor_content_CuratedList_items | null;

  const itemErrors = useRef<FormError[][]>([]);

  useEffect(() => {
    const recomputedErrors = errors.reduce((sparseArray, { path, message }) => {
      if (path[0] === "items" && path[1] === "edges") {
        let index = parseInt(path[2]);
        if (!isNaN(index)) {
          sparseArray[index] = sparseArray[index] ?? [];
          sparseArray[index].push({
            message,
            path:
              path[3] === "node" ? ["item", ...path.slice(4)] : path.slice(3),
          });
        }
      }
      return sparseArray;
    }, [] as FormError[][]);
    itemErrors.current = recomputedErrors;
  }, [errors]);

  const decoratedValue = useMemo(() => {
    const edges = (modelValue?.edges ??
      []) as (FormFor_content_CuratedList_items_edges & {
      _itemID?: string;
    })[];
    return edges.map((edge) => ({
      ...edge,
      _itemID: edge._itemID ?? (itemIDRef.current++).toString(),
    }));
  }, [modelValue]);

  const normalizedValue = useMemo(() => {
    return decoratedValue.map(({ node, _itemID, ...itemFields }, index) => {
      return {
        ...itemFields,
        item: normalizeContentSummary(
          node as ContentSummaryFields | null,
          translateContentType
        ),
        itemID: _itemID,
        errors: itemErrors.current[index],
      };
    });
    // itemErrors.current is a mutable ref value and will not cause the component
    // to re-render, but we still need it as a dep key here since it gets updated
    // as a side effect of a different change that _does_ re-render the component
    // and at that time we want this memoized value to recompute
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decoratedValue, itemErrors.current]);

  const onAssetSelectorSubmit = useCallback(
    (assets: ContentSummary[]) => {
      selectingAssets.current?.resolve(assets);
    },
    [selectingAssets]
  );

  const intl = useIntl();

  const showRemovedDupesMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "One or more items already exist in this item",
      description:
        "Notification message shown when adding duplicate items to a list of items",
    }),
  });

  const onUpdate = useCallback(
    (operations: CuratedListItemUpdateOperation[]) => {
      const updatedItemErrors = update(itemErrors.current, {
        $splice: operations.map(([index, removeCount, ...items]) => [
          index,
          removeCount,
          ...items.map((item) => item.errors ?? []),
        ]),
      });
      itemErrors.current = updatedItemErrors;

      //Adding item id's to an array if they exist on the model
      let decoratedValueIds = decoratedValue.reduce((ids, { node }) => {
        const id = (node as ContentSummaryFields | null)?.id;
        if (id) {
          ids.push(id);
        }
        return ids;
      }, [] as string[]);

      let removedDupes = false;

      const dedupedOperations: CuratedListItemUpdateOperation[] =
        operations.map((operation) => {
          const [index, removeCount, ...items] = operation;

          if (removeCount > 0) {
            //If this is a remove operation remove id's to keep decoratedValueIds updated
            decoratedValueIds.splice(index, removeCount);
            return operation;
          }
          return [
            index,
            removeCount,
            ...items.filter((item) => {
              //Return item thats not already present in the model
              if (!decoratedValueIds.includes(item?.item?.id as string)) {
                return item;
              }
              //Set flag to show dup item is removed
              removedDupes = true;
              return;
            }),
          ];
        });

      const edges = update(
        (decoratedValue ?? []) as (FormFor_content_CuratedList_items_edges & {
          _itemID?: string;
        })[],
        {
          $splice: dedupedOperations.map(([index, removeCount, ...items]) => [
            index,
            removeCount,
            ...items.map(function serializeItem(item: {
              hed?: string | null;
              dek?: string | null;
              rubric?: string | null;
              item?: ContentSummary | null;
              itemID?: string;
            }): FormFor_content_CuratedList_items_edges & { _itemID?: string } {
              return {
                __typename: "CuratedListItemToCuratedListEdge",
                hed: item.hed || "",
                dek: item.dek || "",
                rubric: item.rubric || "",
                _itemID: item.itemID ?? (itemIDRef.current++).toString(),
                node: serializeContentSummary(item.item ?? null),
              };
            }),
          ]),
        }
      );

      if (removedDupes) {
        showRemovedDupesMessage();
      }

      setValue("items", update(modelValue, { edges: { $set: edges } }));
    },
    [decoratedValue, modelValue, setValue, showRemovedDupesMessage]
  );

  const openAssetSelector = useCallback(
    (options?: { limitSelection?: number }) => {
      setAssetSelectorContextualOptions(options);
      setAssetSelectorToggle(true);

      // resolve any unsettled promises before creating a new one
      selectingAssets.current?.resolve(null);
      return deferSelectingAssets().promise;
    },
    [deferSelectingAssets, selectingAssets, setAssetSelectorToggle]
  );

  const closeAssetSelector = useCallback(() => {
    selectingAssets.current?.resolve([]);
    setAssetSelectorToggle(false);
  }, [selectingAssets]);

  return (
    <>
      {assetSelectorToggle && resolvedAssetSelectorOptions && (
        <AssetSelector
          config={resolvedAssetSelectorOptions}
          currentOrganizationID={currentOrganization.organizationId}
          onClose={closeAssetSelector}
          onSubmit={onAssetSelectorSubmit}
          cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
          currentUser={currentUser}
        />
      )}
      <CuratedListItems
        onUpdate={onUpdate}
        selectAssets={openAssetSelector}
        items={normalizedValue}
        cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
        hedRichTextConfig={resolvedHedRichTextConfig}
        hedRichTextBuild={resolvedHedRichTextBuild}
        dekRichTextConfig={resolvedDekRichTextConfig}
        dekRichTextBuild={resolvedDekRichTextBuild}
        rubricRichTextBuild={CKEDITOR_BUILDS["Minimalist"]}
      />
    </>
  );
}
SortableCuratedListItems.displayName = "Control(SortableCuratedListItems)";

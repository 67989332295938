import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import {
  required,
  email,
  markdownLinks,
  optional,
} from "@condenast/copilot-validators";
import { FormFor_content_Contributor } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";
import { publishURI } from "../validators";

const { object } = validators;

const contributorValidators = object({
  name: required(),
  email: optional(email()),
  bio: optional(markdownLinks()),
}).andAlso(publishURI().on("publish"));

export default function validateContributor(
  contributor: FormFor_content_Contributor,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(contributor, build(contributorValidators), context, env);
}

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required, url } from "@condenast/copilot-validators";
import { FormFor_content_ExternalLink } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";

const { object } = validators;

const externalLinkValidators = object({
  hed: required(),
  url: required(url("absolute", "leading-slash")).catch(([{ message }]) => {
    return [
      {
        path: [],
        message: { ...message, name: "url-absolute-leading-slash" },
      },
    ];
  }),
});

export default function validateExternalLink(
  externalLink: FormFor_content_ExternalLink,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(externalLink, build(externalLinkValidators), context, env);
}

import { ReactNode, forwardRef } from "react";
import styled from "styled-components";
interface ContainerCardProps {
  children: ReactNode;
  containerKey: string;
  activeContainerKey: string | null;
  setActiveContainerKey: (key: string) => void;
}

export const ContainerCardWrapper = styled.section`
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.CardShadow};
  background: ${(props) => props.theme.Background};
  width: 100%;
  &[aria-current="true"] {
    box-shadow: 0 0 0 1px var(--color-blue-50);
  }
`;

const ContainerCard = forwardRef<HTMLDivElement, ContainerCardProps>(
  (props, ref) => {
    const {
      activeContainerKey,
      setActiveContainerKey,
      containerKey,
      children,
    } = props;

    return (
      <ContainerCardWrapper
        aria-current={activeContainerKey === containerKey}
        ref={ref}
        onClick={() => {
          setActiveContainerKey(containerKey);
        }}
      >
        {children}
      </ContainerCardWrapper>
    );
  }
);

ContainerCard.displayName = "ContainerCard";

export default ContainerCard;

import type { ContentSerializers } from "../types";
import type { AudioFileInput } from "@types";
import {
  contentConnection,
  allContributors,
  categoryTaxonomies,
} from "../fields";
import { serializerFor, entityMetadata } from "../utils";

const AudioFileFields = {
  allContributors,
  photosTout: contentConnection,
  related: contentConnection,
  categoryTaxonomies,
} as const;

export const AudioFileSerializers: ContentSerializers<AudioFileInput> = {
  save: (changes, model, { currentUser }) => {
    if (!("name" in model)) {
      return null;
    }

    return {
      ...serializerFor(AudioFileFields)(changes),
      name: model.name,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
      description: "description" in model ? model.description : "",
      provider: "provider" in model ? model.provider : "",
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
    return {
      name: createTitle,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      description: "",
      provider: "",
      embedUrl: "",
      transcriptUrl: "",
      durationInMs: null,
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

import {
  CuratedListFields_items,
  CuratedListFields_items_edges_node_ContentSummary,
  CuratedListItemsInput,
  FormFor_content_CuratedList_textItems,
  TEXT_FORMAT,
} from "@types";

export function textItems(
  input?: FormFor_content_CuratedList_textItems[]
): { content: any; textFormat: TEXT_FORMAT }[] {
  return (
    input
      ?.filter((textItem) => textItem.content)
      .map((textItem) => ({
        content: textItem.content,
        textFormat: textItem.textFormat || "MARKDOWN",
      })) || []
  );
}

export function connectedItems(
  input?: CuratedListFields_items
): CuratedListItemsInput[] {
  return (
    input?.edges?.map((edge) => {
      const node =
        edge?.node as CuratedListFields_items_edges_node_ContentSummary;
      return {
        id: node.id,
        contentType: node.contentType,
        rubric: edge?.rubric || undefined,
        dek: edge?.dek || undefined,
        hed: edge?.hed || undefined,
      };
    }) ?? []
  );
}

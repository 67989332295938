import { useCallback, useRef, useState, useMemo } from "react";
import {
  FieldName,
  FieldTogglesData,
} from "../../components/App/FormControl/Controls/BundleContainers/types";

export function useFieldToggles(initialFieldTogglesData: FieldTogglesData) {
  const initialDataRef = useRef(initialFieldTogglesData);
  const [fieldStates, setFieldStates] = useState(initialFieldTogglesData);

  const toggleField = useCallback(
    (name: FieldName, value: boolean) => {
      setFieldStates((prev) => ({ ...prev, [name]: value }));
    },
    [setFieldStates]
  );

  const resetToInitial = useCallback(() => {
    setFieldStates(initialDataRef.current);
  }, [setFieldStates, initialDataRef]);

  const hasChanges = useMemo(() => {
    return !Object.entries(fieldStates).every(
      ([key, value]) => value === initialDataRef.current[key as FieldName]
    );
  }, [fieldStates]);

  return {
    toggleField,
    resetToInitial,
    fieldStates,
    initialFieldTogglesData: initialDataRef.current,
    hasChanges,
  } as const;
}

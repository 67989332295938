import type {
  MainSearchAdapter,
  GetCurrentUser_currentUser,
  GetUsers_users,
  Category,
  ContentSummary,
  GetBrand_brandConfiguration_contentTypes,
} from "@types";

export type MainSearchAdapterFactoryContext = {
  search: (options: SearchPageURLParams) => void;
  contentTypes?: GetBrand_brandConfiguration_contentTypes[];
};

export type MainSearchAdapterFactory = (
  context: MainSearchAdapterFactoryContext,
  isDefault?: boolean
) => MainSearchAdapter;

export type SearchPageURLParams = {
  query?: string;
  sort?: string;
  page?: string;
  adapter?: string;
  date?: string;
  display?: string;
  lang?: string;
  status?: string;
  types?: string;
  collaborators?: string;
  contributors?: string;
  categories?: string;
};

export type AdvancedSearchOptions = Omit<
  SearchPageURLParams,
  "collaborators" | "contributors" | "categories" | "lang" | "status" | "types"
> & {
  lang?: string[];
  status?: string[];
  types?: string[];
  collaborators?: (GetUsers_users | GetCurrentUser_currentUser)[] | null;
  contributors?: ContentSummary[] | null;
  categories?: Category[] | null;
};

export enum MainSearchAdapterTypes {
  All = "all",
  Media = "media",
  Editorial = "editorial",
}

export type LabeledFilterSelection = {
  label: string;
  value: string;
  searchParamData?:
    | Category
    | GetUsers_users
    | GetCurrentUser_currentUser
    | ContentSummary;
};

export type SearchParamKey = keyof AdvancedSearchOptions;

export type LabeledFilterOptions = {
  [K in SearchParamKey]?: LabeledFilterSelection[];
};

export type AdapterFilterConfigs = {
  [K in SearchParamKey]?: string[];
};

export type GetAppliedFiltersOptions = {
  ignoredFilters?: SearchParamKey[];
  allowedOptions?: AdapterFilterConfigs;
  excludedOptions?: AdapterFilterConfigs;
};

import { SubmitBar } from "./SubmitBar";
import styled from "styled-components";
import { PublishBar } from "./PublishBar";
import { TakeoverWrapper } from "./TakeoverWrapper";

const StyledCheckIcon = styled.span`
  line-height: 0;
  display: block;
  padding: var(--spacing-xxs);
  color: ${(props) => props.theme.PlaceholderColor};
  background: ${(props) => props.theme.BorderlessHoverBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  visibility: hidden;
`;

const StyledIndexAndCheck = styled.div`
  line-height: 0;
  display: block;
  padding: var(--spacing-xxs);
  color: ${(props) => props.theme.PlaceholderColor};
  background: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const SlatList = styled.ul<{ cursorMode: "mouse" | "keyboard" }>`
  padding-block-end: calc(var(--spacing-sm) * 2);
  &:focus {
    outline: none;
  }
  li + li {
    margin-top: 2px;
  }
  [id=${(props) => props["aria-activedescendant"]}] {
    background: ${(props) => props.theme.SelectOptionHoverBackground};
    ${StyledCheckIcon} {
      visibility: visible;
    }

    ${(props) =>
      props.cursorMode === "keyboard" && `box-shadow: ${props.theme.FocusRing}`}
  }
`;

const GridList = styled.ul<{ cursorMode: "mouse" | "keyboard" }>`
  display: grid;
  gap: 2px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  padding-block-end: calc(var(--spacing-md) * 5);
  &:focus {
    outline: none;
  }
  [id=${(props) => props["aria-activedescendant"]}] {
    background: var(--color-gray-6);
    ${StyledCheckIcon} {
      visibility: visible;
    }

    ${(props) =>
      props.cursorMode === "keyboard" && `box-shadow: ${props.theme.FocusRing}`}
  }
`;

const ResultSlat = styled.li`
  cursor: pointer;
  background-color: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) => props.theme.Color};
  user-select: none;
  width: auto;

  &[aria-selected="true"] {
    color: ${(props) => props.theme.SecondaryActiveColor};
    background: ${(props) => props.theme.SecondaryActiveBackground};
    box-shadow: ${(props) => props.theme.FieldActiveRing};
    ${StyledCheckIcon} {
      background-color: ${(props) => props.theme.PrimaryBackground};
      color: ${(props) => props.theme.Background};
      visibility: visible;
      box-shadow: none;
    }
  }
`;

export const Takeover = {
  GridList,
  ResultSlat,
  SlatList,
  StyledCheckIcon,
  StyledIndexAndCheck,
  SubmitBar,
  PublishBar,
  TakeoverWrapper,
};

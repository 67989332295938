import styled from "styled-components";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Chip, Label } from "@components";
import { CloseIcon } from "@condenast/gemini/icons";
import { useDefinedMessages } from "@hooks";
import {
  AdvancedSearchOptions,
  LabeledFilterOptions,
  LabeledFilterSelection,
  SearchParamKey,
} from "../types";

const SelectionLabel = styled(Label)`
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-sm);
`;

const SelectionChip = styled(Chip)`
  padding: var(--spacing-xs) var(--spacing-sm);
  margin-right: var(--spacing-xs);
  margin-top: var(--spacing-xxs);
  svg {
    margin-left: var(--spacing-xs);
  }
  cursor: pointer;
`;

const ID_BASED_SEARCH_PARAMS = ["collaborators", "contributors", "categories"];
export const FilterPillList = (props: {
  searchParams: AdvancedSearchOptions;
  onChange: (updatedFilters: AdvancedSearchOptions) => void;
  selectedAdapterFilters?: AdvancedSearchOptions;
  totalSelections: number;
}) => {
  const {
    searchParams,
    onChange,
    selectedAdapterFilters = searchParams,
    totalSelections,
  } = props;

  let intl = useIntl();

  const {
    translateContentType,
    translateFieldName,
    translateLanguage,
    translateSelectOption,
  } = useDefinedMessages();

  const labeledSelectedAdapterFilters = useMemo(() => {
    let labeledFilters: LabeledFilterOptions = {};
    if (selectedAdapterFilters.types) {
      labeledFilters.types = selectedAdapterFilters.types.map((type) => ({
        value: type,
        label: translateContentType(type, 1),
      }));
    }
    if (selectedAdapterFilters.lang) {
      labeledFilters.lang = selectedAdapterFilters.lang.map((lang) => ({
        value: lang,
        label: translateLanguage(lang),
      }));
    }
    if (selectedAdapterFilters.status) {
      labeledFilters.status = selectedAdapterFilters.status.map((status) => ({
        value: status,
        label: translateSelectOption("statusFilterOptions", status),
      }));
    }
    if (selectedAdapterFilters.categories) {
      labeledFilters.categories = selectedAdapterFilters.categories.map(
        (category) => ({
          value: category.id,
          label: [...category.hierarchy]
            .reverse()
            .map((hierarchyMember) => hierarchyMember.name)
            .join(" > "),
          searchParamData: category,
        })
      );
    }
    if (selectedAdapterFilters.collaborators) {
      labeledFilters.collaborators = selectedAdapterFilters.collaborators.map(
        (collaborator) => ({
          value: collaborator.id,
          label: `${collaborator.firstName} ${collaborator.lastName}`,
          searchParamData: collaborator,
        })
      );
    }
    if (selectedAdapterFilters.contributors) {
      labeledFilters.contributors = selectedAdapterFilters.contributors.map(
        (contributor) => ({
          value: contributor.id,
          label: contributor.title?.content ?? "",
          searchParamData: contributor,
        })
      );
    }
    if (selectedAdapterFilters.date) {
      const selectedDates = selectedAdapterFilters.date.split("to");
      const dateFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      } as const;

      const startDate =
        selectedDates.length === 2 &&
        selectedDates[0] &&
        intl.formatDate(selectedDates[0], dateFormatOptions);
      const endDate =
        selectedDates.length === 2 &&
        selectedDates[1] &&
        intl.formatDate(selectedDates[1], dateFormatOptions);

      const translatedDate =
        startDate && endDate
          ? startDate === endDate
            ? startDate
            : intl.formatMessage(
                {
                  defaultMessage: "{start} to {end}",
                  description: "Label for Selected custom date range",
                },
                {
                  start: startDate,
                  end: endDate,
                }
              )
          : translateSelectOption(
              "searchDateOptions",
              selectedAdapterFilters.date
            );

      labeledFilters.date = [
        {
          value: selectedAdapterFilters.date,
          label: translatedDate,
        },
      ];
    }
    return labeledFilters;
  }, [
    intl,
    selectedAdapterFilters.categories,
    selectedAdapterFilters.collaborators,
    selectedAdapterFilters.contributors,
    selectedAdapterFilters.date,
    selectedAdapterFilters.lang,
    selectedAdapterFilters.status,
    selectedAdapterFilters.types,
    translateContentType,
    translateFieldName,
    translateLanguage,
    translateSelectOption,
  ]);

  const removeFilter = useCallback(
    (
      filterName: SearchParamKey,
      selectedFilterToRemove: LabeledFilterSelection
    ) => {
      if (ID_BASED_SEARCH_PARAMS.includes(filterName)) {
        onChange({
          [filterName]: (searchParams[filterName] as { id: string }[]).filter(
            (value) => value.id !== selectedFilterToRemove.searchParamData?.id
          ),
        });
      } else if (Array.isArray(searchParams[filterName])) {
        onChange({
          [filterName]: (searchParams[filterName] as string[]).filter(
            (value) => value !== selectedFilterToRemove.value
          ),
        });
      } else {
        onChange({ [filterName]: null });
      }
    },
    [searchParams, onChange]
  );
  const removeAll = useCallback(() => {
    const { query, page, adapter, ...filters } = selectedAdapterFilters;
    const newFilters = (Object.keys(filters) as SearchParamKey[]).reduce(
      (acc, filterName) => {
        acc[filterName] = undefined;
        return acc;
      },
      {} as AdvancedSearchOptions
    );
    onChange(newFilters);
  }, [selectedAdapterFilters, onChange]);

  return totalSelections > 0 ? (
    <>
      <SelectionLabel>
        <FormattedMessage
          defaultMessage="Applied ({num})"
          values={{
            num: totalSelections,
          }}
        />
      </SelectionLabel>
      {Object.entries(labeledSelectedAdapterFilters).map(
        ([filterName, selections]) => {
          return selections.map((selection) => {
            return (
              <SelectionChip
                size="small"
                key={`${filterName}:${selection.value}`}
                onClick={() =>
                  removeFilter(filterName as SearchParamKey, selection)
                }
              >
                <span>{selection.label}</span>
                <CloseIcon size="small" />
              </SelectionChip>
            );
          });
        }
      )}
      <Button treatment="text" size="small" onClick={removeAll}>
        <FormattedMessage defaultMessage="Clear all" />
      </Button>
    </>
  ) : (
    <></>
  );
};

FilterPillList.displayName = "FilterPillList";

import {
  ControlProps,
  SocialMediaFields,
  LodgingOfferFields,
  FormFor_form_controls_ReplicatorFormControl,
  FormFor_content_CuratedList_textItems as CuratedListType,
  FormFor_content_MusicReview_itemsReviewed_edges as MusicReviewItem,
  ReplicatorItem as ReplicatorItemType,
  ModelItem,
  OfferFields,
  OfferType,
  FormError,
} from "@types";
import {
  cloneElement,
  isValidElement,
  useCallback,
  useMemo,
  Children,
  ReactNode,
  Fragment,
  useRef,
  Key,
  useEffect,
} from "react";
import { Replicator as ReplicatorImplementation } from "@components";
import { useChangeset, useDefinedMessages } from "@hooks";
import { get } from "@lib";
import { MainModelProvider } from "@contexts";

function clone<T>(
  children: ReactNode,
  props: {
    model: T;
    setValue: <Key extends keyof T>(key: Key, value: T[Key]) => void;
    errors?: FormError[];
  }
): ReactNode {
  return Children.map(children, (c) => {
    if (isValidElement(c)) {
      if (c.type === Fragment) {
        let a = clone(c.props.children, props);
        return a;
      }
      return cloneElement(c, props);
    }
    return c;
  });
}

interface ReplicatorAdapter<T> {
  newItem: (keyGenerator: KeyGenerator) => ReplicatorItemType<T>;
  getItems: (
    items: ModelItem<T>[],
    defaultItems: number,
    keyGenerator: KeyGenerator
  ) => ReplicatorItemType<T>[];
  setItems: (
    setValue: (key: string, value: unknown) => void,
    name: string,
    items: ReplicatorItemType<T>[]
  ) => void;
}

type KeyGenerator = () => Key;
type ItemFactory<T> = () => T;

function hasKey<T>(item: ModelItem<T>): item is ReplicatorItemType<T> {
  return "_key" in item;
}

function createReplicatorAdapter<T>(
  itemFactory: ItemFactory<T>
): ReplicatorAdapter<T> {
  return {
    newItem: (createKey) => ({
      _key: createKey(),
      ...itemFactory(),
    }),
    getItems: (items, defaultItems, createKey) => {
      if (!items.length) {
        return Array.from(Array(defaultItems), () => ({
          _key: createKey(),
          ...itemFactory(),
        }));
      }
      return items.map((item) => {
        if (hasKey(item)) {
          return item;
        }
        return {
          _key: createKey(),
          ...item,
        };
      });
    },
    setItems: (setValue, name, items) => setValue(name, items),
  };
}

function getReplicatorAdapter(
  treatment: string,
  context: { defaultCurrency?: string }
) {
  switch (treatment) {
    case "award":
      return createReplicatorAdapter(() => ({ name: "", date: "" }));
    case "contact":
      return createReplicatorAdapter(() => ({ value: "" }));
    case "curatedList":
      return createReplicatorAdapter(() => ({} as CuratedListType));
    case "itemsReview":
      return createReplicatorAdapter(() => ({} as MusicReviewItem));
    case "lodgingOffer":
      return createReplicatorAdapter(() => ({} as LodgingOfferFields));
    case "offer": {
      const defaultCurrency = context.defaultCurrency;
      let defaultCountryCode = "";
      if (defaultCurrency === "USD") {
        defaultCountryCode = "US";
      } else if (defaultCurrency === "GBP") {
        defaultCountryCode = "GB";
      }
      return createReplicatorAdapter(
        () =>
          ({
            countryCode: defaultCountryCode,
            currency: defaultCurrency,
            offerType: OfferType.PURCHASE,
          } as OfferFields)
      );
    }
    case "social":
      return createReplicatorAdapter(
        () => ({ handle: "", network: "" } as SocialMediaFields)
      );
    default:
      return createReplicatorAdapter(() => ({} as unknown));
  }
}
export function ReplicatorItem<T>(props: {
  item: T;
  children: ReactNode;
  onChange: (items: T) => void;
  errors?: FormError[];
}) {
  const { item, children, onChange, errors } = props;

  let [changeset, setValue, { hasChanges }] = useChangeset(item);

  useEffect(() => {
    if (hasChanges) {
      onChange(changeset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeset]);

  return (
    <>
      {children ? (
        clone(children, {
          model: item,
          setValue,
          errors,
        })
      ) : (
        <></>
      )}
    </>
  );
}
export function Replicator<T>(
  props: ControlProps<FormFor_form_controls_ReplicatorFormControl>
) {
  let {
    model,
    name,
    setValue,
    children,
    treatment,
    defaultItems,
    sortable,
    defaultCurrency,
    errors,
  } = props;
  const { translateFieldName } = useDefinedMessages();
  const showLabel = treatment === "contact";
  const replicatorLabel = translateFieldName(name);

  const adapter = useMemo(() => {
    return getReplicatorAdapter(treatment as string, {
      defaultCurrency: defaultCurrency ?? undefined,
    }) as ReplicatorAdapter<T>;
  }, [defaultCurrency, treatment]);

  const itemIdRef = useRef<number>(0);
  const modelItems = useMemo(
    () => (get(model, name) as ModelItem<T>[]) ?? [],
    [model, name]
  );

  const createKey = useCallback(() => itemIdRef.current++, []);

  const decoratedItems = useMemo(() => {
    let adapterItems =
      adapter.getItems(modelItems, defaultItems ?? 0, createKey) ?? [];
    return adapterItems;
  }, [modelItems, adapter, defaultItems]);

  const onChange = useCallback(
    (items) => {
      adapter.setItems(setValue, name, items);
    },
    [name, setValue, adapter]
  );

  const add = useCallback(() => {
    const newItem = adapter.newItem(createKey);
    adapter.setItems(setValue, name, [...decoratedItems, newItem]);
  }, [name, setValue, decoratedItems, adapter]);

  const errorMap = useMemo(() => {
    const map: Record<Key, FormError[]> = {};
    errors.forEach((error) => {
      const [parentPath, indexPath, ...path] = error.path;
      if (parentPath === name) {
        const index = parseInt(indexPath);
        if (!isNaN(index)) {
          const key = decoratedItems[index]?._key;
          if (key !== undefined) {
            map[key] = map[key] ?? [];
            map[key].push({ ...error, path });
          }
        }
      }
    });
    return map;
    // this should only be re-computed when the `errors` array changes
    // as the index paths on the errors will become stale
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <MainModelProvider mainModel={model} setMainModelValue={setValue}>
      <ReplicatorImplementation
        items={decoratedItems}
        onChange={onChange}
        add={add}
        label={showLabel ? replicatorLabel : null}
        treatment={treatment}
        sortable={!!sortable}
      >
        {({ item, onChange }) => (
          <ReplicatorItem
            item={item}
            onChange={onChange}
            errors={errorMap[item._key] ?? []}
          >
            {children}
          </ReplicatorItem>
        )}
      </ReplicatorImplementation>
    </MainModelProvider>
  );
}

Replicator.displayName = "Control(Replicator)";

import React from "react";
import type { ComponentType } from "react";
import { Toast } from "@components";

export function withErrorBoundary<Props>(Component: ComponentType<Props>) {
  const ComponentWithErrorBoundary = class extends React.Component<
    Props,
    { hasError: boolean; errors: string[] }
  > {
    static displayName: string;
    constructor(props: Props) {
      super(props);
      this.state = { hasError: false, errors: [] };
    }

    static getDerivedStateFromError(error: Error) {
      return { hasError: true, errors: [error.message] };
    }

    render() {
      if (this.state.hasError) {
        return this.state.errors.map((error, index) => (
          <Toast type="error" key={index}>
            {error}
          </Toast>
        ));
      }
      return <Component {...this.props} />;
    }
  };
  ComponentWithErrorBoundary.displayName = `withErrorBoundary(${
    Component.displayName ?? "anonymous"
  })`;
  return ComponentWithErrorBoundary;
}

import get from "lodash/get";
import type { Reference } from "@apollo/client";
import type { ReadFieldFunction } from "@apollo/client/cache/core/types/common";

/**
 * A util function to safely read properties from the cache, where
 * properties may be `Reference` types - that is, entities that are
 * normalized from a nested object in the GQL schema
 * @param name the name of the field, which may be a reference
 * @param from the object to read this field from
 * @param isReference a function provided by the `merge` function
 * @param readField a function provided by the `merge` function
 * @returns
 */
export function safelyGetFromReference<T>(
  name: string,
  from: unknown,
  isReference: (obj: unknown) => obj is Reference,
  readField: ReadFieldFunction
) {
  return (isReference(from) ? readField(name, from) : get(from, name)) as T;
}

import { ReactNode, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { Button } from "@components";
import { CloseIcon } from "@condenast/gemini/icons";
import { useMediaQuery } from "@hooks";

const Takeover = styled.div<{ $isMobileView?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.theme.ElevationModal};
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};

  ${({ $isMobileView }) =>
    !$isMobileView &&
    css`
      top: 57px;
      width: 55%;
      margin: var(--spacing-xs);
      border-radius: ${(props) => props.theme.CornerRadius};
      box-shadow: ${(props) => props.theme.CardShadow};
      z-index: ${(props) => props.theme.ElevationCard};
      max-width: 1400px;
    `}
`;

const Header = styled.div`
  display: flex;
  gap: var(--spacing-sm);
  height: 56px;
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
  top: 0;
  font: var(--font-subhed);
  padding: var(--spacing-sm);
  align-items: center;
  justify-content: space-between;
`;

const Body = styled.div`
  height: 100%;
  max-height: calc(100vh - 2 * 56px);
  overflow-y: scroll;
  padding: var(--spacing-md) var(--spacing-md) 200px;
`;

const Footer = styled.div`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.DividerColor};
  bottom: 0;
  padding: var(--spacing-xs);
  align-items: center;
  justify-content: space-between;
`;
const HeaderContent = styled.div`
  flex-grow: 1;
`;
const CloseButton = styled(Button)`
  flex-shrink: 0;
  flex-grow: 0;
`;

type TakeoverWrapperProps = {
  header: ReactNode;
  body: ReactNode;
  footer?: ReactNode | null;
  onClose: () => void;
};

export const TakeoverWrapper = (props: TakeoverWrapperProps) => {
  const { header, body, footer, onClose } = props;
  const mobileView = useMediaQuery("(max-width: 875px)");
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <Takeover ref={ref} $isMobileView={mobileView}>
      {header && (
        <Header>
          <HeaderContent>{header}</HeaderContent>
          <CloseButton
            size="small"
            aria-label="Close"
            onClick={onClose}
            tooltipPlacement="bottom"
          >
            <CloseIcon size="small" />
          </CloseButton>
        </Header>
      )}
      <Body>{body}</Body>
      {footer && <Footer>{footer}</Footer>}
    </Takeover>
  );
};

import { RecipeFields_temperatures, RecipeTemperaturesInput } from "@types";

export function temperatures(
  temperatures: RecipeFields_temperatures
): RecipeTemperaturesInput {
  const { displayTemp, preheatTemp, cookingTemp } = temperatures;

  return {
    displayTemp: displayTemp ?? "",
    preheatTemp: preheatTemp ?? null,
    cookingTemp: cookingTemp ?? null,
  };
}

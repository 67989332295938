import { forwardRef, Ref } from "react";
import { BrandSwitcher } from "./BrandSwitcher";
import { SettingsMenu } from "./SettingsMenu";
import { MainSearch } from "./MainSearch";
import { HelpMenuButton } from "./HelpMenuButton";
import { ProfileMenu } from "./ProfileMenu";
import { CreateMenu } from "./CreateMenu";
import { ToolSwitcherMenuButton } from "@components";
import {
  CopilotIcon,
  EncoreIcon,
  MontroseIcon,
  CNEIcon,
} from "@condenast/gemini/icons";
import type {
  GetBrand_brandConfiguration_contentTypes as ContentTypes,
  GetCurrentUser_currentUser,
  Organization,
} from "@types";

import styled from "styled-components";
import { config } from "@lib";

const StyledToolSwitcherMenuButton = styled(ToolSwitcherMenuButton)<{
  $prideLogo?: boolean;
}>`
  grid-area: tool-switcher;

  ${({ $prideLogo }) =>
    $prideLogo &&
    `background: linear-gradient(
      #5BCEFA 0%, #5BCEFA 20%,
      #F5A9B8 20%, #F5A9B8 40%,
      #FFFFFF 40%, #FFFFFF 60%,
      #F5A9B8 60%, #F5A9B8 80%,
      #5BCEFA 80%, #5BCEFA 100%
    );
  
    button:not(:disabled):hover {
      background-color: rgba(0, 0, 0, 0.15);
    }
  `}
`;

const StyledBrandSwitcher = styled(BrandSwitcher)`
  grid-area: brand-switcher;
`;

const StyledMainSearch = styled(MainSearch)`
  grid-area: main-search;
`;

const StyledCreateMenu = styled(CreateMenu)`
  grid-area: create-menu;
`;

const StyledSettingsMenu = styled(SettingsMenu)`
  grid-area: settings-menu;
`;

const StyledHelpMenuButton = styled(HelpMenuButton)`
  grid-area: help-menu-button;
`;

const StyledProfileMenu = styled(ProfileMenu)`
  grid-area: profile-menu;
`;

const Nav = styled.nav`
  position: sticky;
  top: 0;
  z-index: ${(props) => props.theme.ElevationMenu};
  display: grid;
  grid-template-columns:
    auto var(--spacing-xs) auto var(--spacing-sm) 1fr var(--spacing-sm) auto var(
      --spacing-sm
    )
    auto auto auto auto var(--spacing-xs);
  grid-template-areas: "tool-switcher . brand-switcher . main-search . create-menu . settings-menu help-menu-button profile-menu .";
  align-items: center;
  background: ${(props) => props.theme.Background};
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
  color: ${(props) => props.theme.Color};

  @media (min-width: 700px) {
    & > *:first-child {
      border-right: 1px solid ${(props) => props.theme.DividerColor};
    }
  }

  @media (max-width: 800px) {
    grid-template-columns:
      auto var(--spacing-xs) auto var(--spacing-sm) 1fr var(--spacing-sm) auto
      auto auto auto auto var(--spacing-xs);
    grid-template-areas: "tool-switcher . brand-switcher . main-search . create-menu settings-menu help-menu-button profile-menu .";
  }

  @media (max-width: 700px) {
    ${StyledToolSwitcherMenuButton} {
      display: none;
    }

    padding-top: var(--spacing-xs);
    padding-bottom: var(--spacing-xs);
    grid-template-columns:
      var(--spacing-xs) auto var(--spacing-sm) 1fr var(--spacing-sm) auto
      auto auto auto auto var(--spacing-xs);
    grid-template-areas: ". brand-switcher . main-search . create-menu settings-menu help-menu-button profile-menu .";
  }

  @media (max-width: 650px) {
    grid-template-columns:
      var(--spacing-xs) auto 1fr auto auto
      auto auto auto auto var(--spacing-xs);
    grid-template-areas: ". brand-switcher . main-search create-menu settings-menu help-menu-button profile-menu .";
  }

  @media (max-width: 500px) {
    padding-top: var(--spacing-xxs);
    padding-bottom: var(--spacing-xxs);
  }
`;

export const NavBar = forwardRef(
  (
    props: {
      hostname?: string;
      currentOrganization: Organization;
      currentUser: GetCurrentUser_currentUser;
      organizations: Organization[];
      contentTypes: ContentTypes[];
      supportEmail: {
        to: string;
        subject: string;
        body: string;
      };
      trainingSignupLink?: string;
      enablePresets?: boolean;
      prideLogo?: boolean;
      commerceProductUrl?: string | null;
      v2VersoChannel?: boolean;
    },
    forwardedRef: Ref<HTMLElement>
  ) => {
    return (
      <Nav aria-live="polite" ref={forwardedRef}>
        <StyledToolSwitcherMenuButton
          $prideLogo={props.prideLogo}
          tool="Copilot"
          tools={[
            {
              name: "Copilot",
              icon: CopilotIcon,
              href: "https://copilot.condenast.io",
            },
            {
              name: "Encore",
              icon: EncoreIcon,
              href: "https://encore.conde.digital",
            },
            {
              name: "Montrose",
              icon: MontroseIcon,
              href: "https://montrose.condenast.com",
            },
            {
              name: "CNEAdmin",
              icon: CNEIcon,
              href: "https://admin.cnevids.com",
            },
          ]}
          environment={config.environment as string}
        />
        <StyledBrandSwitcher
          currentOrganization={props.currentOrganization}
          organizations={props.organizations}
          hostname={props.hostname}
        />
        <StyledMainSearch
          contentTypes={props.contentTypes}
          currentOrganization={props.currentOrganization}
          hostname={props.hostname}
        />
        <StyledCreateMenu
          contentTypes={props.contentTypes}
          currentOrganization={props.currentOrganization}
          currentUser={props.currentUser}
          enablePresets={props.enablePresets}
          commerceProductUrl={props.commerceProductUrl || undefined}
          v2VersoChannel={props.v2VersoChannel}
        />
        <StyledSettingsMenu
          hostname={props.hostname}
          currentOrganization={props.currentOrganization}
          currentUser={props.currentUser}
        />
        <StyledHelpMenuButton />
        <StyledProfileMenu
          hostname={props.hostname}
          currentOrganization={props.currentOrganization}
        />
      </Nav>
    );
  }
);
NavBar.displayName = "NavBar";

import { AnonymousControlProps, AspectRatio } from "@types";
import { ImageEditor as ImageEditorImplementation } from "@components";
import { useCallback, useMemo } from "react";

const createCropFromImageHeight = (
  ratioWidth: number,
  ratioHeight: number,
  originalImageHeight: number
) => {
  const cropHeight = originalImageHeight;
  let cropWidth = cropHeight * (ratioWidth / ratioHeight);

  return { cropWidth, cropHeight };
};

const createCropFromImageWidth = (
  ratioWidth: number,
  ratioHeight: number,
  originalImageWidth: number
) => {
  const cropWidth = originalImageWidth;
  let cropHeight = cropWidth * (ratioHeight / ratioWidth);

  return { cropWidth, cropHeight };
};

function createAspectRatioCrop(
  aspectRatioName: string,
  originalImage: AspectRatio
): AspectRatio {
  const [ratioWidth, ratioHeight] = aspectRatioName.split(":").map(Number);
  const { width: originalImageWidth, height: originalImageHeight } =
    originalImage;

  let { cropWidth, cropHeight } =
    ratioWidth / ratioHeight > originalImageWidth / originalImageHeight
      ? createCropFromImageWidth(ratioWidth, ratioHeight, originalImage.width)
      : createCropFromImageHeight(
          ratioWidth,
          ratioHeight,
          originalImage.height
        );

  // Round the calculated width and height to avoid decimal pixel values
  cropWidth = Math.round(cropWidth);
  cropHeight = Math.round(cropHeight);

  // Position crop at the top center of the image
  const x = Math.round((originalImageWidth - cropWidth) / 2);

  const crop: AspectRatio = {
    name: aspectRatioName,
    override: false,
    width: cropWidth,
    height: cropHeight,
    modifications: {
      crop: {
        width: cropWidth,
        height: cropHeight,
        x,
        y: 0,
      },
    },
  };

  return crop;
}

export function ImageEditor(
  props: AnonymousControlProps & { configuredAspectRatios: string[] }
) {
  let { model, currentOrganization, setValue, configuredAspectRatios } = props;

  const id = model.id as string;
  const filename = model.filename as string;
  const restrictCropping = model.restrictCropping as boolean;
  const altText = model.altText as string;
  const type =
    model.__typename === "Cartoon"
      ? ("cartoons" as string)
      : ("photos" as string);

  let cdnHost = useMemo(() => {
    return `https://${currentOrganization.metadata.mediaDomain}`;
  }, [currentOrganization]);

  let originalImage = useMemo(() => {
    let aspectRatios = model.aspectRatios as AspectRatio[];
    return aspectRatios.find((aspectRatio) => aspectRatio.name === "master");
  }, [model.aspectRatios]);

  let aspectRatios = useMemo(() => {
    let aspectRatios = model.aspectRatios as AspectRatio[];
    return configuredAspectRatios
      .map((configuredAspectRatio) => {
        let existingAspectRatio = aspectRatios.find(
          (aspectRatio) => aspectRatio.name === configuredAspectRatio
        );
        return (
          existingAspectRatio ||
          (originalImage &&
            createAspectRatioCrop(configuredAspectRatio, originalImage))
        );
      })
      .filter(
        (aspectRatio): aspectRatio is AspectRatio =>
          typeof aspectRatio !== undefined
      )
      .sort(function (a, b) {
        let ratioA = a.name.split(":").map(Number);
        let ratioB = b.name.split(":").map(Number);

        let valueA = ratioA[0] / ratioA[1];
        let valueB = ratioB[0] / ratioB[1];

        // Note: because we want widest to tallest, we subtract valueB from valueA.
        // If we wanted the opposite order, we would subtract valueA from valueB.
        return valueB - valueA;
      });
  }, [model.aspectRatios, configuredAspectRatios, originalImage]);

  let onSubmit = useCallback(
    (updatedAspectRatioCrops: AspectRatio[]) => {
      if (!updatedAspectRatioCrops || !updatedAspectRatioCrops.length) return;
      //merge the updated crops into the aspectRatios array
      let mergedAspectRatios = aspectRatios.map((aspectRatio) => {
        let updatedAspectRatioCrop = updatedAspectRatioCrops.find(
          (updatedAspectRatio) => updatedAspectRatio.name === aspectRatio.name
        );
        return updatedAspectRatioCrop || aspectRatio;
      });
      originalImage &&
        setValue("aspectRatios", [originalImage, ...mergedAspectRatios]);
    },
    [aspectRatios, setValue, originalImage]
  );

  return originalImage && aspectRatios && aspectRatios.length ? (
    <ImageEditorImplementation
      setValue={setValue}
      cdnHost={cdnHost}
      restrictCropping={restrictCropping}
      id={id}
      filename={filename}
      altText={altText}
      aspectRatios={aspectRatios}
      type={type}
      originalImage={originalImage}
      onTakeoverSubmit={onSubmit}
    />
  ) : null;
}
ImageEditor.displayName = "Control(ImageEditor)";

import { get } from "@lib";
import {
  ControlProps,
  FormFor_form_controls_IfModelValueEqualsFormControl,
} from "@types";
import {
  cloneElement,
  isValidElement,
  Children,
  ReactNode,
  Fragment,
} from "react";

function clone<T>(
  children: ReactNode,
  props: {
    model: T;
    setValue: (key: string, value: unknown) => void;
  }
): ReactNode {
  return Children.map(children, (c) => {
    if (isValidElement(c)) {
      if (c.type === Fragment) {
        let a = clone(c.props.children, props);
        return a;
      }
      return cloneElement(c, props);
    }
    return c;
  });
}

export function IfModelValueEquals(
  props: ControlProps<FormFor_form_controls_IfModelValueEqualsFormControl>
) {
  let { children, model, path, value } = props;
  let CurationTypeSelectValue = get(model, path) as string;

  let renderChild = value?.includes(CurationTypeSelectValue);
  return renderChild ? <>{clone(children, props)}</> : <></>;
}
IfModelValueEquals.displayName = "Control(IfModelValueEquals)";

import { forwardRef, HTMLAttributes } from "react";
import styled from "styled-components";
import type { Placement } from "@popperjs/core";

const Wrapper = styled.span<{ $size: "medium" | "regular" }>`
  font: ${(props) => props.theme.FontLabel};
  color: ${(props) => props.theme.MedallionColor};
  background: ${(props) => props.theme.MedallionBackground};
  box-shadow: ${(props) => props.theme.MedallionRing};
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  cursor: default;
  user-select: none;
  min-width: ${(props) =>
    props.$size === "medium" ? "var(--spacing-md)" : "var(--spacing-lg)"};
  min-height: ${(props) =>
    props.$size === "medium" ? "var(--spacing-md)" : "var(--spacing-lg)"};
  line-height: ${(props) =>
    props.$size === "medium" ? "var(--spacing-md)" : "var(--spacing-lg)"};
  max-width: var(--spacing-lg);
`;

export const UserMedallion = forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement> & {
    name: string;
    size: "medium" | "regular";
    className?: string;
    tooltipPlacement?: Placement;
  }
>((props, ref) => {
  let { size, name, ...forwardProps } = props;
  let initials = name
    .normalize()
    .split(/\s+/)
    .map((part) => [...part][0]);
  let resolvedInitials =
    props.size === "medium" ? initials[0] : initials.join("");

  return (
    <Wrapper ref={ref} $size={props.size} {...forwardProps}>
      {resolvedInitials}
    </Wrapper>
  );
});
UserMedallion.displayName = "UserMedallion";

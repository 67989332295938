import { ValidationError } from "@condenast/cross-check";
import {
  validators,
  BasicValidator,
  ValidationBuilder,
  builderFor,
} from "@condenast/cross-check-dsl";
import { UpdateGallery_updateGallery_Gallery_categoryTaxonomies as CategoryTaxonomy } from "@types";
import { capitalize } from "../../strings";

interface CategoriesValidatorOptions {
  taxonomyName: string;
}

function categoriesError(taxonomyName: string): ValidationError {
  return {
    path: [`categories${capitalize(taxonomyName)}`],
    message: {
      name: `${taxonomyName}-allowed-minimum`,
      details: null,
    },
  };
}

class CategoriesValidator extends BasicValidator<
  CategoryTaxonomy[],
  CategoriesValidatorOptions
> {
  static validatorName = "categories";

  async validate(value: CategoryTaxonomy[]): Promise<ValidationError[]> {
    const taxonomyName = this.options.taxonomyName;
    const errors: ValidationError[] = [];

    if (!taxonomyName) {
      throw new Error("taxonomyName is required");
    }

    const taxonomyToValidate =
      value &&
      value.find(
        (categoryTaxonomy) => categoryTaxonomy.taxonomy === taxonomyName
      );

    if (
      !taxonomyToValidate ||
      !taxonomyToValidate.categories ||
      !taxonomyToValidate.categories.length
    ) {
      errors.push(categoriesError(taxonomyName));
    }
    return errors;
  }
}

export function categories(
  options: CategoriesValidatorOptions
): ValidationBuilder<unknown> {
  return validators.isArray().andThen(builderFor(CategoriesValidator)(options));
}

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required } from "@condenast/copilot-validators";
import { FormFor_content_City } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";

const { object } = validators;

const cityValidators = object({
  name: required(),
});

export default function validateCity(
  city: FormFor_content_City,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(city, build(cityValidators), context, env);
}

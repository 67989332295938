import { useState } from "react";
import {
  ChevronDownIcon,
  INTArchitecturalDigestIcon,
  INTGQIcon,
  INTHouseAndGardenIcon,
  INTLaCucinaItalianaIcon,
  INTTatlerIcon,
  INTVogueBusinessIcon,
  INTWorldOfInteriorsIcon,
  USAllureIcon,
  USArchitecturalDigestIcon,
  USBonAppetitIcon,
  USCondeNastTravelerIcon,
  USEpicuriousIcon,
  USGlamourIcon,
  USGqIcon,
  USPitchforkIcon,
  USSelfIcon,
  USTeenVogueIcon,
  USTheNewYorkerIcon,
  USThemIcon,
  USVanityFairIcon,
  USVogueIcon,
  USWiredIcon,
} from "@condenast/gemini/icons";
import { ARIA, Button, Tooltip, RoutableLink } from "@components";
import type { Organization } from "@types";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";

const BRAND_ICON_MAP = {
  all: USAllureIcon,
  ad: USArchitecturalDigestIcon,
  "ad-int": INTArchitecturalDigestIcon,
  bon: USBonAppetitIcon,
  cnt: USCondeNastTravelerIcon,
  epi: USEpicuriousIcon,
  hg: INTHouseAndGardenIcon,
  glm: USGlamourIcon,
  gq: USGqIcon,
  "gq-int": INTGQIcon,
  p4k: USPitchforkIcon,
  self: USSelfIcon,
  tatler: INTTatlerIcon,
  tnv: USTeenVogueIcon,
  tny: USTheNewYorkerIcon,
  them: USThemIcon,
  vf: USVanityFairIcon,
  vogue: USVogueIcon,
  wrd: USWiredIcon,
  lci: INTLaCucinaItalianaIcon,
  voguebz: INTVogueBusinessIcon,
  woi: INTWorldOfInteriorsIcon,
};

const MARKET_BRANDS: { [key: string]: keyof typeof BRAND_ICON_MAP } = {
  Allure: "all",
  "Architectural Digest": "ad-int",
  "Condé Nast Traveler": "cnt",
  Glamour: "glm",
  GQ: "gq-int",
  Tatler: "tatler",
  "Vanity Fair": "vf",
  Vogue: "vogue",
  Wired: "wrd",
  WIRED: "wrd",
  "La Cucina Italiana": "lci",
};

function hasBrandLogo(
  copilotCode: string | null
): copilotCode is keyof typeof BRAND_ICON_MAP {
  return !!(copilotCode && copilotCode in BRAND_ICON_MAP);
}

const Container = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 1px;
`;

const LogoLink = styled(RoutableLink)`
  padding: var(--spacing-xs);
  text-decoration: none;
  color: ${(props) => props.theme.Color};

  svg {
    left: 0;
    max-width: 100%;
    color: ${(props) => props.theme.Color};
  }
`;

const StyledButton = styled(ARIA.MenuButton)`
  &[aria-expanded] svg {
    transform: rotate(180deg);
  }

  & ~ [role="menu"] {
    max-height: 398px;
    overflow-y: auto;

    li {
      border-bottom: 1px solid ${(props) => props.theme.DividerColor};
      margin: 0;

      &:last-child {
        border-bottom: none;
      }

      [role="menuitem"] {
        padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-xs);
      }
    }
  }
`;

const BrandMenuItem = styled.div`
  display: grid;
  gap: var(--spacing-xs);
`;

const Logotype = styled.span`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LogoWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: var(--spacing-xs);
  align-items: center;

  svg {
    height: var(--spacing-md);
  }
`;

function BrandLogo({
  organization,
  "aria-hidden": ariaHidden,
}: {
  organization: Organization;
  "aria-hidden"?: true;
}) {
  const code = organization.metadata.copilotCode;
  const name = organization.internalDisplayName;
  const ariaLabel = ariaHidden || name === null ? undefined : name;
  let Logo;
  let text;
  if (hasBrandLogo(code)) {
    Logo = BRAND_ICON_MAP[code];
  } else {
    let baseName = Object.keys(MARKET_BRANDS).find((brandName) =>
      name?.startsWith(brandName)
    );
    if (baseName) {
      Logo = BRAND_ICON_MAP[MARKET_BRANDS[baseName]];
      text = name?.slice(baseName.length).trim();
    }
  }
  return (
    <>
      {Logo ? (
        text ? (
          <LogoWrapper>
            <Logo aria-hidden={ariaHidden} aria-label={ariaLabel} />
            <Logotype aria-hidden>{text}</Logotype>
          </LogoWrapper>
        ) : (
          <Logo aria-hidden={ariaHidden} aria-label={ariaLabel} />
        )
      ) : (
        <Logotype aria-hidden={ariaHidden}>{name}</Logotype>
      )}
    </>
  );
}

export function BrandSwitcher(props: {
  hostname?: string;
  organizations: Organization[];
  currentOrganization: Organization;
  className?: string;
}) {
  const intl = useIntl();
  const { organizations, currentOrganization } = props;
  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null);
  const [linkElement, setLinkElement] = useState<HTMLAnchorElement | null>(
    null
  );

  return (
    <Container ref={setContainerElement} className={props.className}>
      {currentOrganization && (
        <Button
          treatment="borderless"
          ref={setLinkElement}
          as={LogoLink}
          to={`${props.hostname}/${currentOrganization.metadata.copilotCode}/dashboard`}
        >
          <BrandLogo organization={currentOrganization} />
          <Tooltip target={linkElement}>
            <FormattedMessage defaultMessage="Go to dashboard" />
          </Tooltip>
        </Button>
      )}
      {organizations && organizations.length > 1 && containerElement && (
        <StyledButton
          id="brand-switcher"
          aria-label={intl.formatMessage({ defaultMessage: "Switch brands" })}
          size="small"
          menu={{
            items: organizations
              .map(
                (organization) =>
                  ({
                    role: "link",
                    value: organization,
                    href: `${props.hostname}/${organization.metadata.copilotCode}/dashboard`,
                  } as const)
              )
              .sort((b1, b2) => {
                if (
                  !b1.value.internalDisplayName &&
                  !b2.value.internalDisplayName
                )
                  return 0;
                if (b1.value.internalDisplayName === null) return -1;
                if (b2.value.internalDisplayName === null) return 1;
                return b1.value.internalDisplayName.localeCompare(
                  b2.value.internalDisplayName
                );
              }),
            children(organization: Organization) {
              return (
                <BrandMenuItem>
                  <BrandLogo organization={organization} aria-hidden />
                  <span>{organization.internalDisplayName}</span>
                </BrandMenuItem>
              );
            },
            onSearch(items: Organization[], query: string) {
              const lowercaseQuery = query.toLocaleLowerCase();
              return items.find(
                (organization) =>
                  organization.internalDisplayName
                    ?.toLocaleLowerCase()
                    .startsWith(lowercaseQuery) ||
                  organization.metadata.copilotCode
                    ?.toLocaleLowerCase()
                    .startsWith(lowercaseQuery)
              );
            },
          }}
          popper={{
            placement: "bottom-start",
            referenceElement: containerElement,
          }}
        >
          <ChevronDownIcon size="small" />
        </StyledButton>
      )}
    </Container>
  );
}

BrandSwitcher.displayName = "BrandSwitcher";

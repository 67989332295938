import { useMemo, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { ARIA, Button } from "@components";
import { useDefinedMessages } from "@hooks";
import { getTranslations_getTranslations as translation } from "@types";

const StyledButton = styled(ARIA.MenuButton)`
  & ~ [role="menu"] {
    max-height: 398px;
    overflow-y: auto;
  }
  background: ${(props) => props.theme.Background};
`;

export function LangSwitcherMenu(props: {
  currentLang: string;
  existingLang: (translation | null)[];
  newLang?: (string | null)[];
  onChange: (lang: string, allTranslations: (translation | null)[]) => void;
}) {
  const { currentLang, onChange } = props;

  const { translateLanguage } = useDefinedMessages();

  const langMenuItems = useMemo(() => {
    let existingLang = props.existingLang?.filter(
      (lang) => lang?.lang != currentLang
    );
    let newLang = props.newLang?.filter((lang) => lang != currentLang);

    const items = [];
    if (existingLang && existingLang.length > 0) {
      items.push({
        role: "group",
        label: <FormattedMessage defaultMessage="Content Languages" />,
        items: existingLang.map((lang) => {
          return {
            role: "action",
            onClick: () => onChange(lang?.lang ?? "", existingLang),
            value: translateLanguage(lang?.lang ?? ""),
          };
        }),
      });
    }
    if (newLang && newLang.length > 0) {
      items.push({
        role: "group",
        label: <FormattedMessage defaultMessage="Add Translations" />,
        items: newLang.map((lang) => {
          return {
            role: "action",
            onClick: () => onChange(lang ?? "", existingLang),
            value: translateLanguage(lang ?? ""),
          };
        }),
      });
    }
    return items;
  }, [
    currentLang,
    props.existingLang,
    props.newLang,
    translateLanguage,
    onChange,
  ]);

  return langMenuItems.length > 0 ? (
    <StyledButton
      menu={{
        theme: "modal",
        items: langMenuItems,
        children(items: ReactNode) {
          return items;
        },
      }}
      popper={{
        placement: "bottom-start",
        strategy: "fixed",
      }}
    >
      {translateLanguage(currentLang)}
    </StyledButton>
  ) : (
    <Button>{translateLanguage(currentLang)}</Button>
  );
}

LangSwitcherMenu.displayName = "LangSwitcherMenu";

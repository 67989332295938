export const prefixZero = (num: string) => num.padStart(2, "0");

export const millisecondsToHours = (milliseconds: number) => {
  // 1- Convert to seconds:
  let s = parseInt((milliseconds / 1000).toString(), 10);
  // 2- Extract hours:
  const h = parseInt((s / 3600).toString(), 10); // 3,600 seconds in 1 hour
  s = s % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const m = parseInt((s / 60).toString(), 10); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  s = s % 60;

  const time = `${prefixZero(h.toString())}:${prefixZero(
    m.toString()
  )}:${prefixZero(s.toString())}`;
  return time;
};

export const timeToMilliseconds = (hrs: string, min: string, sec: string) => {
  return (parseInt(hrs) * 60 * 60 + parseInt(min) * 60 + parseInt(sec)) * 1000;
};

import { useState, ReactNode, useCallback } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { Button } from "@components";
import { ChevronDownIcon, SmallIcons } from "@condenast/gemini/icons";
import { FormattedMessage } from "react-intl";
import { useClickOutside } from "@hooks";

const Arrow = styled(ChevronDownIcon)`
  height: var(--spacing-sm);
  width: var(--spacing-sm);
`;

const FilterButton = styled(Button)<{
  $isTakeoverView?: boolean;
  $isOpen: boolean;
}>`
  ${({ $isTakeoverView, theme, $isOpen }) => {
    return `
      display: flex;
      align-items center;
      justify-content: ${$isTakeoverView ? "center" : "start"};
      border: 0;
      margin: 0;
      font: var(--font-body);
      width: 100%;
      min-height: var(--spacing-xl);
      color: ${theme.Color};
      border-radius: ${theme.CornerRadius};
      box-shadow: ${theme.FieldRing};
      background: ${
        $isTakeoverView ? theme.BorderlessActiveBackground : theme.Background
      };
      padding: var(--spacing-xs) var(--spacing-sm);
      cursor: pointer;

      &:not([disabled]):hover {
        cursor: initial;
        box-shadow: ${theme.FieldHoverRing};
      }

      &:not([disabled]):active {
        box-shadow: ${theme.FieldActiveRing};
        background: ${theme.SecondaryActiveBackground};
      }

      &:not([disabled]):focus,
      &:not([disabled])[aria-expanded="true"] {
        box-shadow: ${theme.FieldFocusRing},
          ${theme.FocusRing};
        outline: none;
        background: ${theme.SecondaryActiveBackground};
      }

      & svg {
        color: ${theme.Color};
      }

      & ${Arrow} {
        height: var(--spacing-sm);
        width: var(--spacing-sm);
        align-items: center;
        left: calc(1 * var(--spacing-xs));
        color: ${theme.Color};
        transform:  ${$isOpen ? "rotate(-180deg)" : "rotate(0)"};
        margin-left: auto;
      }
    `;
  }}
`;

const ContainerWrapper = styled.div`
  ${({ theme }) => {
    return `    
      display: grid;
      grid-gap: inherit;
      color: ${theme.Color};
      border-radius: ${theme.CornerRadius};
      box-shadow: ${theme.CardShadow};
      background: ${theme.Background};
      z-index: ${theme.ElevationMenu};
      max-height: 100%;
      min-width: 390px;
      padding: var(--spacing-sm);
    `;
  }}
`;

export const CustomFilterWrapper = (props: {
  children: ReactNode;
  icon?: SmallIcons;
  displayText: string;
  showArrow?: boolean;
  isTakeoverView?: boolean;
}) => {
  const {
    children,
    icon: Icon,
    displayText,
    showArrow = false,
    isTakeoverView,
  } = props;

  const [popperTarget, setPopperTarget] = useState<HTMLButtonElement | null>(
    null
  );
  const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(
    null
  );
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(popperTarget, popoverElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "right"],
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });
  const [isExpanded, setExpanded] = useState(false);

  const doClose = useCallback(() => {
    if (document.activeElement === popoverElement && popperTarget) {
      popperTarget.focus();
    }
    setExpanded(false);
  }, [popoverElement, popperTarget]);

  useClickOutside(wrapperElement, doClose);

  return (
    <div ref={setWrapperElement}>
      <FilterButton
        ref={setPopperTarget}
        tabIndex={isExpanded ? -1 : 0}
        aria-expanded={isExpanded ? "true" : undefined}
        onClick={(evt) => {
          setExpanded(!isExpanded);
          if (isExpanded && popperTarget) {
            popperTarget.focus();
          }
          evt.preventDefault();
        }}
        $isOpen={isExpanded}
        $isTakeoverView={isTakeoverView}
      >
        {Icon && <Icon size="small" />}
        <span>
          <FormattedMessage
            defaultMessage="{text}"
            values={{ text: displayText }}
          />
        </span>
        {showArrow && <Arrow size="regular" />}
      </FilterButton>
      {isExpanded && (
        <ContainerWrapper
          ref={setPopoverElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {children}
        </ContainerWrapper>
      )}
    </div>
  );
};

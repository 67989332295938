import { FC, useCallback, useMemo, useRef, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  ControlProps,
  FormFor_form_controls_BundleContainersFormControl,
  FormFor_content_Bundle_containers,
  FormFor_form_controls_BundleContainersFormControl_configs_containers as BundleContainerConfig,
} from "@types";
import styled from "styled-components";
import { KeyedContainer } from "./types";
import {
  createContainer,
  isKeyedContainer,
  isLegacyBundleContainerConfig,
} from "./utils";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import PlainTextRenderer from "@atjson/renderer-plain-text";
import { BundleContainersActions, ContainerSelector } from "./BundleComponents";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding-top: var(--spacing-xs);

  section {
    margin-top: 0;
  }
`;

const EmptyBundle = styled.div`
  margin: 0 auto;
  padding: var(--spacing-sm) var(--spacing-lg);
  row-gap: var(--spacing-sm);
  width: 100%;
  background: ${(props) => props.theme.PrimaryColor};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.CardShadow};
  font: var(--font-body);
`;

const MainFieldsetWrapper = styled.div``;

export const BundleContainers: FC<
  ControlProps<FormFor_form_controls_BundleContainersFormControl>
> = ({
  model,
  setValue,
  configs,
  currentOrganization,
  currentUser,
  permissions,
  children,
}) => {
  const mainFieldsetRef = useRef<HTMLDivElement | null>(null);

  const containerRefs = useRef<{
    [key: string]: HTMLDivElement | null;
  }>({});
  const containerKeyRef = useRef<number>(0);
  const [containerKeyToScrollTo, setContainerKeyToScrollTo] = useState<
    string | null
  >(null);

  const [activeContainerKey, setActiveContainerKey] = useState<string | null>(
    null
  );

  const [collapsedContainerKeys, setCollapsedContainerKeys] = useState<
    string[]
  >([]);

  const bundleType = useMemo(
    () => model.bundleType as string,
    [model.bundleType]
  );

  const mainFieldSetSummary = useMemo(() => {
    return {
      hed: PlainTextRenderer.render(
        CopilotMarkdownSource.fromRaw(model.hed as string)
      ).trim(),
      dek: PlainTextRenderer.render(
        CopilotMarkdownSource.fromRaw(model.dek as string)
      ).trim(),
    };
  }, [model.hed, model.dek]);

  const keyedContainers = useMemo(() => {
    const modelValue = model.containers as
      | FormFor_content_Bundle_containers[]
      | KeyedContainer[];
    return modelValue.map((container) => {
      if (isKeyedContainer(container)) {
        return container;
      }
      return {
        ...container,
        containerKey: container.id,
      } as KeyedContainer;
    });
  }, [model.containers]);

  const collapseAllContainers = useCallback(() => {
    const allContainerKeys: string[] = [];
    keyedContainers.forEach((container) => {
      allContainerKeys.push(container.containerKey);
    });
    setCollapsedContainerKeys(allContainerKeys);
  }, [keyedContainers]);

  const expandAllContainers = useCallback(() => {
    setCollapsedContainerKeys([]);
  }, [keyedContainers]);

  const toggleContainerCollapsed = (containerKey: string) => {
    if (collapsedContainerKeys.includes(containerKey)) {
      setCollapsedContainerKeys([
        ...collapsedContainerKeys.filter(
          (collapsedContainerKey) => collapsedContainerKey !== containerKey
        ),
      ]);
    } else {
      setCollapsedContainerKeys([...collapsedContainerKeys, containerKey]);
    }
  };

  const areAllContainersCollapsed = keyedContainers.every(({ containerKey }) =>
    collapsedContainerKeys.includes(containerKey)
  );

  const totalContainerCount = useMemo(() => {
    return keyedContainers.length;
  }, [keyedContainers]);

  const updateContainerByIndex = useCallback(
    (updatedContainer: KeyedContainer, index) => {
      setValue(`containers.${index}]`, updatedContainer);
    },
    [setValue]
  );

  const updateBundleContainers = useCallback(
    (updatedContainers: KeyedContainer[]) => {
      setValue("containers", updatedContainers);
    },
    [setValue]
  );

  useEffect(() => {
    if (
      containerKeyToScrollTo &&
      containerRefs.current[containerKeyToScrollTo]
    ) {
      containerRefs.current[containerKeyToScrollTo]?.scrollIntoView({
        behavior: "smooth",
      });
      setActiveContainerKey(containerKeyToScrollTo);
      setContainerKeyToScrollTo(null);
    }
  }, [containerKeyToScrollTo]);

  const scrollToMainFieldSet = useCallback(() => {
    if (mainFieldsetRef.current) {
      setActiveContainerKey(null);
      mainFieldsetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [mainFieldsetRef]);

  const moveContainer = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      if (sourceIndex !== destinationIndex) {
        const containersToReorder = [...keyedContainers];
        const [updatedContainer] = containersToReorder.splice(sourceIndex, 1);

        containersToReorder.splice(destinationIndex, 0, updatedContainer);
        updateBundleContainers(containersToReorder);
        setContainerKeyToScrollTo(updatedContainer.containerKey);
      }
    },
    [keyedContainers, updateBundleContainers]
  );

  const addContainer = useCallback(
    (containerConfig: BundleContainerConfig, addToEnd?: boolean) => {
      const containerKey = (containerKeyRef.current++).toString();
      const containerToAdd = createContainer({
        containerKey,
        curationContainerType: containerConfig.value,
        itemLimit:
          isLegacyBundleContainerConfig(containerConfig) &&
          containerConfig.itemLimit
            ? containerConfig.itemLimit
            : undefined,
      });
      const updatedContainers = addToEnd
        ? [...keyedContainers, containerToAdd]
        : [containerToAdd, ...keyedContainers];

      updateBundleContainers(updatedContainers);
      setContainerKeyToScrollTo(containerKey);
    },
    [keyedContainers, updateBundleContainers]
  );

  const duplicateContainer = useCallback(
    (containerKey) => {
      const containerToDuplicateIndex = keyedContainers.findIndex(
        (keyedContainer) => keyedContainer.containerKey === containerKey
      );

      if (containerToDuplicateIndex !== -1) {
        const containerToDuplicateKey = (containerKeyRef.current++).toString();
        const { id, containerKey, ...containerValuesToCopy } =
          keyedContainers[containerToDuplicateIndex];
        const duplicatedContainer = {
          ...containerValuesToCopy,
          containerKey: containerToDuplicateKey,
        };

        const updatedContainers = [
          ...keyedContainers.slice(0, containerToDuplicateIndex + 1),
          duplicatedContainer,
          ...keyedContainers.slice(containerToDuplicateIndex + 1),
        ];

        updateBundleContainers(updatedContainers);
        setContainerKeyToScrollTo(containerToDuplicateKey);
      }
    },
    [keyedContainers, updateBundleContainers]
  );

  const deleteContainer = useCallback(
    (containerKey) => {
      const index = keyedContainers.findIndex(
        (keyedContainer) => keyedContainer.containerKey === containerKey
      );

      if (index !== -1) {
        updateBundleContainers([
          ...keyedContainers.slice(0, index),
          ...keyedContainers.slice(index + 1),
        ]);
      }
      setActiveContainerKey(null);
    },
    [keyedContainers, updateBundleContainers, setActiveContainerKey]
  );
  return (
    <Wrapper>
      <BundleContainersActions
        configs={configs}
        bundleType={bundleType}
        containers={keyedContainers}
        addContainer={addContainer}
        deleteContainer={deleteContainer}
        moveContainer={moveContainer}
        setContainerKeyToScrollTo={setContainerKeyToScrollTo}
        collapseAllContainers={collapseAllContainers}
        expandAllContainers={expandAllContainers}
        areAllContainersCollapsed={areAllContainersCollapsed}
        scrollToMainFieldSet={scrollToMainFieldSet}
        mainFieldSetSummary={mainFieldSetSummary}
      />
      <MainFieldsetWrapper ref={mainFieldsetRef}>
        {children}
      </MainFieldsetWrapper>
      {keyedContainers?.map((containerData, containerIndex) => {
        return (
          <ContainerSelector
            key={containerData.containerKey}
            containerIndex={containerIndex}
            containerData={containerData}
            updateContainerByIndex={updateContainerByIndex}
            configs={configs}
            currentOrganization={currentOrganization}
            currentUser={currentUser}
            permissions={permissions}
            containerRefs={containerRefs}
            activeContainerKey={activeContainerKey}
            setActiveContainerKey={setActiveContainerKey}
            toggleContainerCollapsed={toggleContainerCollapsed}
            isCollapsed={
              !!collapsedContainerKeys.includes(containerData.containerKey)
            }
            deleteContainer={deleteContainer}
            duplicateContainer={duplicateContainer}
            moveContainer={moveContainer}
            totalContainerCount={totalContainerCount}
            errors={[]}
          />
        );
      })}
      {keyedContainers?.length ? (
        <BundleContainersActions
          configs={configs}
          bundleType={bundleType}
          containers={keyedContainers}
          scrollToMainFieldSet={scrollToMainFieldSet}
          mainFieldSetSummary={mainFieldSetSummary}
          deleteContainer={deleteContainer}
          addContainer={(containerConfig) =>
            addContainer(containerConfig, true)
          }
          moveContainer={moveContainer}
          setContainerKeyToScrollTo={setContainerKeyToScrollTo}
          collapseAllContainers={collapseAllContainers}
          expandAllContainers={expandAllContainers}
          areAllContainersCollapsed={areAllContainersCollapsed}
        />
      ) : (
        <EmptyBundle>
          <FormattedMessage defaultMessage="No containers added." />
        </EmptyBundle>
      )}
    </Wrapper>
  );
};
BundleContainers.displayName = "Control(BundleContainers)";

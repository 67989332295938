import fetch from "cross-fetch";
import {
  gql,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  InMemoryCacheConfig,
  StoreObject,
  from,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { PossibleTypes } from "./possibleTypes";
import {
  toastPropsVar,
  toastTimerVar,
  lastAssetSelectorAdapter,
  isAuthenticatedVar,
} from "./variables";
import { captureMessage } from "@sentry/react";
import { onError } from "@apollo/client/link/error";

import type {
  GetRecentSearch_recentSearches as RecentSearches,
  GetRecentSearch_recentSearches_RecentQuery as RecentQuery,
  GetRecentSearch_recentSearches_RecentSearchResult as RecentSearchResult,
  GetUsers_users,
  Organization,
} from "@types";
import { Activity, LiveStory, Product, Venue } from "./typePolicies";

function isRecentQuery(
  recentSearch: RecentSearches
): recentSearch is RecentQuery {
  return "query" in recentSearch;
}

function isRecentSearchResult(
  recentSearch: RecentSearches
): recentSearch is RecentSearchResult {
  return (
    "id" in recentSearch && "title" in recentSearch && "type" in recentSearch
  );
}

export const APOLLO_CACHE_CONFIG: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        toast() {
          return {
            props: toastPropsVar(),
            timer: toastTimerVar(),
          };
        },
        recentSearches(
          _: StoreObject,
          { variables }: { variables?: { copilotCode?: string } }
        ): RecentSearches[] {
          let recentSearchData =
            variables &&
            localStorage.getItem(`recentSearch:${variables.copilotCode}`);
          let recentSearches = recentSearchData
            ? JSON.parse(recentSearchData)
            : [];
          return recentSearches.map((recentSearch: RecentSearches) => {
            if (isRecentQuery(recentSearch)) {
              return {
                ...recentSearch,
                __typename: "RecentQuery",
              };
            } else if (isRecentSearchResult(recentSearch)) {
              return {
                ...recentSearch,
                __typename: "RecentSearchResult",
              };
            }
            return;
          });
        },
        contentSummary: {
          read(cached, { args, toReference }) {
            const id = args?.id;
            return id
              ? toReference({
                  __typename: "ContentSummary",
                  id,
                })
              : cached;
          },
        },
        lastAssetSelectorAdapter() {
          return { props: lastAssetSelectorAdapter() };
        },
      },
    },
    LiveStory,
    Venue,
    Activity,
    Product,
  },
  possibleTypes: PossibleTypes,
};

const inflateOrganizations = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    // We'll inflate users only on GetUsers;
    // doing this introspection on the operation
    // is more correct, but considerably less easy to understand
    if (operation.operationName === "GetUsers") {
      let organizationsById: Record<string, Organization> = {};
      return {
        data: {
          users: response.data?.users.map((user: GetUsers_users) => {
            let organizations = user.organizations.map((org) => {
              if (!organizationsById[org.id]) {
                organizationsById[org.id] = org;
              }
              return organizationsById[org.id];
            });
            return {
              ...user,
              organizations,
            };
          }),
        },
      };
    }
    return response;
  });
});

const copilotRegionHeader = new ApolloLink((operation, forward) => {
  const organizationId = operation.variables.organizationId || null;
  const userId = operation.variables.userId;

  if (!organizationId) {
    return forward(operation);
  }

  const { cache } = operation.getContext();

  const organization = cache.readFragment({
    id: `Organization:${organizationId}`,
    fragment: gql`
      fragment Metadata on Organization {
        metadata {
          copilotRegion
          copilotCode
        }
      }
    `,
  });

  if (!organization) {
    captureMessage(
      `No organization found matching current org ${organizationId} while user ${userId} was running ${operation}`
    );
    return forward(operation);
  }

  const copilotRegion = organization?.metadata?.copilotRegion || null;
  const internalDisplayName = organization?.internalDisplayName;
  const copilotCode = organization?.metadata?.copilotCode;

  if (!copilotRegion) {
    captureMessage(
      `No copilotRegion found in ${internalDisplayName} - ${copilotCode} - ${organizationId} while user ${userId} was running ${operation}`
    );
    return forward(operation);
  }

  //add the current region to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      region: copilotRegion,
    },
  }));

  return forward(operation);
});

const etagHeader = new ApolloLink((operation, forward) => {
  const { etag } = operation.getContext();

  if (etag) {
    //add the etag header
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        etag,
      },
    }));
  }

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((gqlError) => {
      if (gqlError?.extensions?.code === "UNAUTHENTICATED") {
        isAuthenticatedVar(false);
      }
    });
  }
});

export const GQLClient = new ApolloClient({
  link: from([
    errorLink,
    copilotRegionHeader,
    inflateOrganizations,
    etagHeader,
    createUploadLink({
      uri: "/gql",
      fetch,
      credentials: "include",
    }),
  ]),
  cache: new InMemoryCache(APOLLO_CACHE_CONFIG),
});

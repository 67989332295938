import type { GetAssetSelectorConfig_configs_assetSelectorConfig as AssetSelectorConfig } from "@types";
import { SearchPostProcessor } from "../types";
import { useGettyPostProcessor } from "./getty";
import { useGettyVideoPostProcessor } from "./gettyvideo";
import { useProductPostProcessor } from "./product";
import { useCommerceProductPostProcessor } from "./commerceproduct";
export function usePostProcessors(
  organizationId: string,
  config: AssetSelectorConfig,
  enableSimilarProduct: boolean
): SearchPostProcessor[] {
  const gettyPostProcessor = useGettyPostProcessor(organizationId);
  const gettyVideoPostProcessor = useGettyVideoPostProcessor(organizationId);
  const commerceProductPostProcessor =
    useCommerceProductPostProcessor(organizationId);
  const productPostProcessor = useProductPostProcessor(organizationId);

  const processors = {
    getty: gettyPostProcessor,
    gettyvideo: gettyVideoPostProcessor,
    product: productPostProcessor,
    commerceproduct: commerceProductPostProcessor,
  } as const;

  let p: SearchPostProcessor[] = [];
  for (let type of config.externalTypes ?? []) {
    if (type in processors) {
      p.push(processors[type as keyof typeof processors]);
    }
  }
  if (enableSimilarProduct) {
    p.push(processors["product"]);
  }
  return p;
}

import { defineMessages } from "react-intl";

export const RESYNC_LABEL_TYPES = defineMessages({
  refresh: {
    defaultMessage: "Refresh",
    description: " Resync banner refresh button label",
  },
  resync_gallery: {
    defaultMessage: "Resync Gallery Slides",
    description: "Resync gallery slides button label",
  },
  in_progress: {
    defaultMessage: "In Progress",
    description: "Resync banner button In Progress label",
  },
});

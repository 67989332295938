import { Button, Card, Field, CKEditor, DraggableList } from "@components";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import {
  AddIcon,
  BinIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@condenast/gemini/icons";
import { RecipeIngredient } from "./RecipeIngredient";
import { useDefinedMessages, useUniqueId } from "@hooks";
import {
  CKEditorConfiguration,
  GroupData,
  GroupUpdateOperation,
  IngredientData,
  IngredientUpdateOperation,
  KeyedItem,
} from "@types";
import {
  BlockEditor,
  InlineEditor,
  MinimalistEditor,
} from "@condenast/ckeditor5-build-condenast";
import { useCallback, useEffect, useState, useMemo } from "react";
import CopilotMarkdownRenderer from "@condenast/atjson-renderer-copilot-markdown";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";

const Section = styled(Card).attrs({ as: "section" })`
  display: relative;
  margin-block-start: var(--spacing-md);
  padding: var(--spacing-sm) 0 0;
`;

const ActionButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
`;

const CollapseAllButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
`;

const ActionsBlock = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  justify-content: center;
  align-items: center;
`;

const GroupListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 var(--spacing-sm) var(--spacing-sm);
`;

const GroupListBody = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto;
  margin: 0 auto;
  padding: var(--spacing-md);
  column-gap: var(--spacing-md);
  width: 100%;
  background: var(--color-gray-6);

  @media (max-width: 650px) {
    padding: var(--spacing-md) 0;
  }
`;

const Title = styled.h2`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
`;

const EditorField = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

const Editor = styled(CKEditor)`
  grid-area: control;
`;

const GroupHeader = styled.div`
  padding: var(--spacing-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.Background};
  box-shadow: ${(props) => props.theme.CardShadow};
  border-radius: ${(props) =>
    `${props.theme.CornerRadius} ${props.theme.CornerRadius} 0 0`};
  gap: var(--spacing-xs);

  ${EditorField} {
    flex: 50%;
  }
  ${ActionsBlock} {
    margin: 0;
    height: fit-content;
  }
`;

const GroupList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

const GroupHeaderActionsBlock = styled(ActionsBlock)`
  flex: 50%;
  justify-content: end;

  @media (max-width: 650px) {
    flex: none;

    ${ActionButton} {
      display: none;
    }
  }
`;

const TrailingIngredientsActionsBlock = styled(ActionsBlock)<{
  $collapsed?: boolean;
}>`
  ${({ $collapsed }) =>
    $collapsed &&
    `
    display: none;
  `}

  padding: var(--spacing-xs);
  background: ${(props) => props.theme.Background};
  box-shadow: ${(props) => props.theme.CardShadow};
  border-radius: ${(props) =>
    `0 0 ${props.theme.CornerRadius} ${props.theme.CornerRadius}`};
  z-index: ${(props) => props.theme.ElevationCard};
`;

const TrailingGroupsActionsBlock = styled(ActionsBlock)`
  margin-top: var(--spacing-sm);
`;

const NoIngredientsMessage = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: var(--spacing-xl) 0;

  & h4 {
    font-size: 1.75rem;
    margin-bottom: var(--spacing-sm);
  }

  & span {
    max-width: 375px;
    line-height: initial;
    display: inline-block;
  }
`;

const StyledDraggableGroupList = styled(DraggableList)`
  gap: var(--spacing-md);
` as typeof DraggableList;

const StyledDraggableIngredientList = styled(DraggableList)`
  gap: 1px;
` as typeof DraggableList;

const DraggableWrapper = styled.div<{
  $collapsed?: boolean;
}>`
  ${({ $collapsed }) =>
    $collapsed &&
    `
    ${StyledDraggableIngredientList} {
      display: none;
    }
  `}
`;

const CollapseButton = styled(Button)`
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    flex-shrink: 0;
  }
`;

const Builds = {
  block: BlockEditor,
  inline: InlineEditor,
  minimalist: MinimalistEditor,
};

const CollapsedGroupHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-sm);
  font: var(--font-subhed);
  font-size: 1rem;

  & div {
    margin-right: var(--spacing-xs);
  }
`;

type DecoratedIngredient = IngredientData & {
  _ingredientID: string;
};

type DecoratedIngredientGroup = Omit<GroupData, "ingredients"> & {
  _groupID: string;
  ingredients: DecoratedIngredient[];
};

function IngredientsGroup(props: {
  group: DecoratedIngredientGroup;
  groupIndex: number;
  treatment: "single" | "multiple";
  onGroupUpdate: (operations: GroupUpdateOperation[]) => void;
  onIngredientUpdate: (operations: IngredientUpdateOperation[]) => void;
  ingredientRichTextBuild: keyof typeof Builds;
  ingredientRichTextConfig: CKEditorConfiguration;
  ingredientGroupRichTextBuild: keyof typeof Builds;
  unitOptions: Array<{ label: string; value: string; disabled?: boolean }>;
  onClose: (groupIndex: number, index: number) => void;
  isGroupCollapsed: boolean;
  toggleIsGroupCollapsed: (group: DecoratedIngredientGroup) => void;
  collapsedIDs: string[];
  setCollapsedIDs: (value: React.SetStateAction<string[]>) => void;
}) {
  let {
    group,
    groupIndex,
    treatment,
    onGroupUpdate,
    onIngredientUpdate,
    ingredientRichTextBuild,
    ingredientRichTextConfig,
    ingredientGroupRichTextBuild,
    unitOptions,
    onClose,
    isGroupCollapsed,
    toggleIsGroupCollapsed,
    collapsedIDs,
    setCollapsedIDs,
  } = props;

  //set up hed ckeditor
  const { translateFieldName } = useDefinedMessages();
  let groupHed = group.hed;

  const id = useUniqueId();
  const intl = useIntl();
  const [hed, setHed] = useState<string | null>(null);

  // CKEditor requires an onChange that is stable across re-renders
  // so update local state on change and have a separate effect that
  // can be updated when props change
  const onHedChange = useCallback(
    (doc) => {
      setHed(CopilotMarkdownRenderer.render(doc));
    },
    [setHed]
  );
  useEffect(() => {
    if (hed !== null) {
      onGroupUpdate([[groupIndex, 1, { ...group, hed }]]);
    }
    // only run when the hed value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hed]);
  const hedValue = useMemo(
    () => CopilotMarkdownSource.fromRaw(groupHed ?? "").convertTo(VersoSource),
    [groupHed]
  );

  const onIngredientChange = useCallback(
    (
      groupIndex: number,
      ingredientIndex: number,
      ingredient: IngredientData
    ) => {
      onIngredientUpdate([[groupIndex, ingredientIndex, 1, ingredient]]);
    },
    [onIngredientUpdate]
  );

  const keyedIngredients = useMemo(
    () =>
      group.ingredients.map((ingredient) => ({
        key: ingredient._ingredientID,
        value: ingredient,
      })),
    [group.ingredients]
  );

  const moveIngredient = useCallback(
    (
      data: {
        index: number;
        item: KeyedItem<DecoratedIngredient>;
      },
      dropIndex: number
    ) => {
      if (data.index !== dropIndex) {
        const adjustedDropIndex =
          data.index < dropIndex ? dropIndex - 1 : dropIndex;
        onIngredientUpdate([
          [groupIndex, data.index, 1],
          [groupIndex, adjustedDropIndex, 0, data.item.value],
        ]);
      }
    },
    [onIngredientUpdate, groupIndex]
  );

  const toggleIngredientCollapsed = (ingredient: IngredientData) => {
    if (collapsedIDs.includes(`ingredient:${ingredient._ingredientID}`)) {
      setCollapsedIDs([
        ...collapsedIDs.filter(
          (id) => id !== `ingredient:${ingredient._ingredientID}`
        ),
      ]);
    } else {
      setCollapsedIDs([
        ...collapsedIDs,
        `ingredient:${ingredient._ingredientID}`,
      ]);
    }
  };

  return (
    <>
      {treatment === "single" ? (
        <StyledDraggableIngredientList
          items={keyedIngredients}
          onMove={moveIngredient}
        >
          {(ingredient, index) => (
            <RecipeIngredient
              index={index}
              groupIndex={groupIndex}
              ingredient={ingredient}
              onClose={onClose}
              onChange={onIngredientChange}
              ingredientRichTextBuild={ingredientRichTextBuild}
              ingredientRichTextConfig={ingredientRichTextConfig}
              ingredientGroupRichTextBuild={ingredientGroupRichTextBuild}
              unitOptions={unitOptions}
              isIngredientCollapsed={collapsedIDs.includes(
                `ingredient:${ingredient._ingredientID}`
              )}
              toggleIngredientCollapsed={toggleIngredientCollapsed}
            />
          )}
        </StyledDraggableIngredientList>
      ) : (
        <GroupList>
          <GroupHeader>
            {isGroupCollapsed ? (
              <CollapsedGroupHeader>
                {hedValue.content || (
                  <FormattedMessage defaultMessage="New Group" />
                )}
              </CollapsedGroupHeader>
            ) : (
              <EditorField
                label={translateFieldName("Group Name")}
                id={`CKEditor__${id}__hed`}
              >
                <Editor
                  onChange={onHedChange}
                  id={`CKEditor__${id}__hed`}
                  build={ingredientRichTextBuild}
                  value={hedValue}
                />
              </EditorField>
            )}
            <GroupHeaderActionsBlock>
              <ActionButton
                size="medium"
                onClick={() => {
                  onIngredientUpdate([[groupIndex, 0, 0, {}]]);
                  isGroupCollapsed && toggleIsGroupCollapsed(group);
                }}
              >
                <AddIcon size="regular" />
                <FormattedMessage
                  defaultMessage="Add Ingredient"
                  description="Add a blank ingredient item to recipe"
                />
              </ActionButton>
              <Button
                onClick={() => onGroupUpdate([[groupIndex, 1]])}
                aria-label={intl.formatMessage({
                  defaultMessage: "Remove Group",
                })}
              >
                <BinIcon size="regular" />
              </Button>
              <CollapseButton
                aria-label={intl.formatMessage({
                  defaultMessage: "Toggle Collapse",
                })}
                onClick={() => toggleIsGroupCollapsed(group)}
              >
                {isGroupCollapsed ? (
                  <ChevronDownIcon size="regular" />
                ) : (
                  <ChevronUpIcon size="regular" />
                )}
              </CollapseButton>
            </GroupHeaderActionsBlock>
          </GroupHeader>
          <DraggableWrapper $collapsed={isGroupCollapsed}>
            <StyledDraggableIngredientList
              items={keyedIngredients}
              onMove={moveIngredient}
            >
              {(ingredient, index) => (
                <RecipeIngredient
                  index={index}
                  groupIndex={groupIndex}
                  ingredient={ingredient}
                  onClose={onClose}
                  onChange={onIngredientChange}
                  ingredientRichTextBuild={ingredientRichTextBuild}
                  ingredientRichTextConfig={ingredientRichTextConfig}
                  ingredientGroupRichTextBuild={ingredientGroupRichTextBuild}
                  unitOptions={unitOptions}
                  isIngredientCollapsed={collapsedIDs.includes(
                    `ingredient:${ingredient._ingredientID}`
                  )}
                  toggleIngredientCollapsed={toggleIngredientCollapsed}
                />
              )}
            </StyledDraggableIngredientList>
          </DraggableWrapper>
          <TrailingIngredientsActionsBlock $collapsed={isGroupCollapsed}>
            <ActionButton
              size="medium"
              onClick={() => {
                onIngredientUpdate([
                  [groupIndex, group.ingredients.length, 0, {}],
                ]);
              }}
            >
              <AddIcon size="regular" />
              <FormattedMessage
                defaultMessage="Add Ingredient"
                description="Add a blank ingredient item to recipe"
              />
            </ActionButton>
          </TrailingIngredientsActionsBlock>
        </GroupList>
      )}
    </>
  );
}

const EmptyIngredientGroups = () => (
  <NoIngredientsMessage>
    <h4>
      <FormattedMessage
        defaultMessage="No Ingredients Yet"
        description="Message for empty list of ingredients"
      />
    </h4>
    <span>
      <FormattedMessage
        defaultMessage="Start creating your recipe by adding ingredients."
        description="Descriptive text for how to add ingredients to a recipe"
      />
    </span>
  </NoIngredientsMessage>
);

export function RecipeIngredientsList(props: {
  onGroupUpdate: (operations: GroupUpdateOperation[]) => void;
  onIngredientUpdate: (operations: IngredientUpdateOperation[]) => void;
  ingredientGroups: DecoratedIngredientGroup[];
  ingredientRichTextBuild: keyof typeof Builds;
  ingredientRichTextConfig: CKEditorConfiguration;
  ingredientGroupRichTextBuild: keyof typeof Builds;
  unitOptions: Array<{ label: string; value: string; disabled?: boolean }>;
}) {
  let {
    onGroupUpdate,
    onIngredientUpdate,
    ingredientGroups,
    ingredientRichTextBuild,
    ingredientRichTextConfig,
    ingredientGroupRichTextBuild,
    unitOptions,
  } = props;

  /**
   * collapsedIDs can be an array of group OR ingredient IDs that are collapsed
   * ex:
   * [
   *   "group:1", "group:2", "ingredient:1", "ingredient:2"
   * ]
   *
   * Collapse All should write all of the grouopIDs and ingredient IDs to that list
   * Expand all should clear the list
   *
   * Toggling a group means checking if the string "group:{groupID}" is in the list, and removing it or adding it if necessary
   * Toggling an ingredient...
   *
   * Checking the state of a particular group/ingredient just means checking if the prefixed ID is in the list
   */

  const idCollectionArray = ingredientGroups.reduce(
    (accumulator: string[], group) => {
      const ingredientIds = group.ingredients.map(
        (ingredient) => `ingredient:${ingredient._ingredientID}`
      );
      return accumulator.concat([...ingredientIds, `group:${group._groupID}`]);
    },
    []
  );

  const [collapsedIDs, setCollapsedIDs] = useState<string[]>([]);

  const toggleAllCollapsed = () => {
    if (collapsedIDs.length) {
      setCollapsedIDs([]);
    } else {
      setCollapsedIDs([...idCollectionArray]);
    }
  };

  const toggleGroupCollapsed = (group: DecoratedIngredientGroup) => {
    if (collapsedIDs.includes(`group:${group._groupID}`)) {
      setCollapsedIDs([
        ...collapsedIDs.filter((id) => id !== `group:${group._groupID}`),
      ]);
    } else {
      setCollapsedIDs([...collapsedIDs, `group:${group._groupID}`]);
    }
  };

  const removeIngredient = useCallback(
    (index: number, groupIndex: number) => {
      onIngredientUpdate([[groupIndex, index, 1]]);
    },
    [onIngredientUpdate]
  );

  const keyedGroups = useMemo(
    () =>
      ingredientGroups.map((group) => ({
        key: group._groupID,
        value: group,
      })),
    [ingredientGroups]
  );

  const moveGroup = useCallback(
    (
      data: {
        index: number;
        item: KeyedItem<DecoratedIngredientGroup>;
      },
      dropIndex: number
    ) => {
      if (data.index !== dropIndex) {
        const adjustedDropIndex =
          data.index < dropIndex ? dropIndex - 1 : dropIndex;
        onGroupUpdate([
          [data.index, 1],
          [adjustedDropIndex, 0, data.item.value],
        ]);
      }
    },
    [onGroupUpdate]
  );

  return (
    <Section>
      <GroupListHeader>
        <Title>
          <FormattedMessage defaultMessage="Ingredients" />
        </Title>
        <ActionsBlock>
          <CollapseAllButton
            treatment="borderless"
            size="medium"
            onClick={toggleAllCollapsed}
          >
            {collapsedIDs.length ? (
              <FormattedMessage
                defaultMessage="Expand all"
                description="Expand all items in group"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Collapse all"
                description="Collapse all items in group"
              />
            )}
          </CollapseAllButton>
          <ActionButton
            size="medium"
            onClick={() => {
              onGroupUpdate([[0, 0, { ingredients: [{}] }]]);
            }}
          >
            <AddIcon size="regular" />
            <FormattedMessage
              defaultMessage="Add Group"
              description="Add a blank group to recipe"
            />
          </ActionButton>
          {ingredientGroups.length < 2 && (
            <ActionButton
              size="medium"
              onClick={() => {
                onIngredientUpdate([[0, 0, 0, {}]]);
              }}
            >
              <AddIcon size="regular" />
              <FormattedMessage
                defaultMessage="Add Ingredient"
                description="Add a blank ingredient item to recipe"
              />
            </ActionButton>
          )}
        </ActionsBlock>
      </GroupListHeader>
      <GroupListBody>
        {keyedGroups.length > 0 ? (
          <StyledDraggableGroupList items={keyedGroups} onMove={moveGroup}>
            {(group, index) => (
              <IngredientsGroup
                onGroupUpdate={onGroupUpdate}
                onIngredientUpdate={onIngredientUpdate}
                group={group}
                groupIndex={index}
                treatment={keyedGroups.length > 1 ? "multiple" : "single"}
                ingredientRichTextBuild={ingredientRichTextBuild}
                ingredientRichTextConfig={ingredientRichTextConfig}
                ingredientGroupRichTextBuild={ingredientGroupRichTextBuild}
                unitOptions={unitOptions}
                onClose={removeIngredient}
                isGroupCollapsed={collapsedIDs.includes(
                  `group:${group._groupID}`
                )}
                collapsedIDs={collapsedIDs}
                toggleIsGroupCollapsed={toggleGroupCollapsed}
                setCollapsedIDs={setCollapsedIDs}
              />
            )}
          </StyledDraggableGroupList>
        ) : (
          <EmptyIngredientGroups />
        )}

        <TrailingGroupsActionsBlock>
          <ActionButton
            size="medium"
            onClick={() => {
              onGroupUpdate([
                [ingredientGroups.length, 0, { ingredients: [{}] }],
              ]);
            }}
          >
            <AddIcon size="regular" />
            <FormattedMessage
              defaultMessage="Add Group"
              description="Add a blank group to recipe"
            />
          </ActionButton>
          {ingredientGroups.length < 2 && (
            <ActionButton
              size="medium"
              onClick={() => {
                onIngredientUpdate([
                  [0, ingredientGroups[0].ingredients.length, 0, {}],
                ]);
              }}
            >
              <AddIcon size="regular" />
              <FormattedMessage
                defaultMessage="Add Ingredient"
                description="Add a blank ingredient item to recipe"
              />
            </ActionButton>
          )}
        </TrailingGroupsActionsBlock>
      </GroupListBody>
    </Section>
  );
}

import styled from "styled-components";
import { Takeover, Button } from "@components";
import { FormattedMessage, useIntl } from "react-intl";
import { isLegacyBundleContainerConfig } from "../../utils";
import {
  CurationType,
  FormFor_form_controls_BundleContainersFormControl_configs_containers as BundleContainerConfig,
} from "@types";
import { AddIcon } from "@condenast/gemini/icons";

const AddContainersBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;
const ContainerItem = styled.div`
  display: flex;
  padding: var(--spacing-sm);
  box-shadow: none;
  border-radius: none;
  border: 1px solid var(--color-gray-5);
`;

const ContainerCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const ContainerTag = styled.div`
  display: flex;
  flex-direction: row;
  margin: var(--spacing-xs);
`;

const ContainerLabel = styled.div`
  overflow: hidden;
  color: var(--color-black);
  text-overflow: ellipsis;
  font: var(--font-button);
  line-height: var(--spacing-md);
  margin: var(--spacing-xs);
`;

const ContainerType = styled.div`
  color: var(--gray-gray-1);
  font: var(--font-label);
  line-height: var(--spacing-sm);
  padding-right: var(--spacing-xs);
`;

const ContainerCurationType = styled.div`
  color: var(--gray-gray-1);
  font: var(--font-small-statements);
  line-height: var(--spacing-sm);
  padding-right: var(--spacing-xs);
`;

const StyledButton = styled(Button)`
  align-self: flex-start;
`;

const AddIconWrapper = styled.div`
  display: flex;
  border-radius: 50%;
  border: 2px solid var(--color-white);
  background-color: transparent;
`;

export function AddContainersTakeover(props: {
  allowedContainers?: BundleContainerConfig[];
  onClose: () => void;
  addContainer: (containerConfig: BundleContainerConfig) => void;
}) {
  const { allowedContainers, addContainer, onClose } = props;
  const intl = useIntl();
  return (
    <Takeover.TakeoverWrapper
      header={<FormattedMessage defaultMessage="Containers" />}
      body={
        <AddContainersBody>
          {allowedContainers?.map((containerConfig, index) => {
            return (
              <ContainerItem key={index}>
                <ContainerCardDetails>
                  <ContainerLabel>{containerConfig.label}</ContainerLabel>
                  <ContainerTag>
                    <ContainerType>
                      <FormattedMessage defaultMessage="Curation" />
                    </ContainerType>
                    {isLegacyBundleContainerConfig(containerConfig) && (
                      <ContainerCurationType>
                        {containerConfig.curationType ===
                        CurationType["SEARCH"] ? (
                          <FormattedMessage defaultMessage="Search" />
                        ) : (
                          <FormattedMessage defaultMessage="Manual" />
                        )}
                      </ContainerCurationType>
                    )}
                  </ContainerTag>
                </ContainerCardDetails>
                <StyledButton
                  onClick={() => addContainer(containerConfig)}
                  treatment="primary"
                  aria-label={intl.formatMessage({
                    defaultMessage: "Add container",
                  })}
                >
                  <AddIconWrapper>
                    <AddIcon size="regular" />
                  </AddIconWrapper>
                </StyledButton>
              </ContainerItem>
            );
          })}
        </AddContainersBody>
      }
      onClose={onClose}
    />
  );
}

export default AddContainersTakeover;

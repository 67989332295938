import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export function useQueryParams<
  T extends Record<string, string | string[] | undefined | null>
>(defaultParams?: Record<string, string | string[]>) {
  let [params, setParams] = useSearchParams();

  let queryParams = useMemo(() => {
    let result = { ...defaultParams };
    for (let [key, value] of params.entries()) {
      if (key.endsWith("[]")) {
        key = key.slice(0, key.length - 2);
        let arr = result[key];
        if (Array.isArray(arr)) {
          arr.push(value);
        } else {
          result[key] = [value];
        }
      } else {
        result[key] = value;
      }
    }
    return result as T;
  }, [params]);

  let getNewParamString = useCallback(
    (newQueryParams: Partial<T>) => {
      let search = new URLSearchParams(params);
      for (let key in newQueryParams) {
        let value = newQueryParams[key];
        if (Array.isArray(value)) {
          search.delete(`${key}[]`);
          if (value.length) {
            for (let item of value) {
              search.append(`${key}[]`, item);
            }
          }
        } else if (value != null) {
          search.set(key, value as string);
        } else {
          if (search.has(key)) {
            search.delete(key);
          }
        }
      }
      search.sort();
      return search;
    },
    [params]
  );

  let setQueryParams = useCallback(
    (newQueryParams: Partial<T>, replaceHistory = true) => {
      return setParams(getNewParamString(newQueryParams), {
        replace: replaceHistory,
      });
    },
    [getNewParamString, setParams]
  );

  return [queryParams, setQueryParams] as const;
}

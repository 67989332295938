import { default as styled, css } from "styled-components";
import type { ToggleMap } from "@types";
import { useEffect, useRef } from "react";
import { ThemeProvider } from "@contexts";
import { useDefinedMessages } from "@hooks";

interface StyledProps {
  $isDesktop?: boolean;
  $textArrangement?: string;
  $textAlignment?: string;
  $fullBleed?: boolean;
  $insetHeaderImage?: string;
  $storyImageSize?: string;
  $withRule?: boolean;
  $withCard?: boolean;
}

const ScrollWrapper = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(var(--spacing-xl) * 9.5);
  width: ${({ $isDesktop }) =>
    $isDesktop
      ? "calc(var(--spacing-xl) * 10)"
      : "calc(var(--spacing-xxl) * 3.5)"};
  background-color: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.CardShadow};
  ${({ $textArrangement, $isDesktop }) =>
    $textArrangement === "Text Left" &&
    $isDesktop &&
    css`
      flex-direction: row-reverse;
    `}
  ${({ $textArrangement, $isDesktop }) =>
    $textArrangement === "Text Right" &&
    $isDesktop &&
    css`
      flex-direction: row;
    `}
    ${({ $textArrangement }) =>
    $textArrangement === "Text Overlay" &&
    css`
      overflow: hidden;
    `};
`;

const ImageWrapper = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $textArrangement }) =>
    $textArrangement === "Text Above" &&
    css`
      order: 2;
    `}
  ${({ $textArrangement, $isDesktop }) =>
    ($textArrangement === "Text Left" || $textArrangement === "Text Right") &&
    !$isDesktop &&
    css`
      order: 2;
    `}
  ${({ $textArrangement }) =>
    $textArrangement === "Text Overlay" &&
    css`
      flex: 0;
      align-items: flex-start;
      min-height: 90%;
      overflow: hidden;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgb(0, 0, 0) 0px, transparent 65%);
      }
    `};
  ${({ $fullBleed }) =>
    $fullBleed &&
    css`
      flex: 1 80px;
    `};
  ${({ $insetHeaderImage, $isDesktop }) =>
    $insetHeaderImage === "Yes" &&
    $isDesktop &&
    css`
      overflow: hidden;
      flex: 1.5;
    `};
  ${({ $insetHeaderImage, $isDesktop, $fullBleed }) =>
    $insetHeaderImage === "No" &&
    $isDesktop &&
    !$fullBleed &&
    css`
      padding: 0 var(--spacing-lg);
    `};
`;

const Image = styled.img<StyledProps>`
  width: ${({ $isDesktop, $storyImageSize }) =>
    $isDesktop && $storyImageSize === "Small" ? "75%" : "100%"};
  ${({ $fullBleed }) =>
    $fullBleed &&
    css`
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    `};
`;

const DefaultImage = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.SkeletonColor};
  height: calc(var(--spacing-xxl) * 4.5);
  min-width: calc(var(--spacing-xxl) * 7);
  font: ${(props) => props.theme.FontBody};
  ${({ $fullBleed }) =>
    $fullBleed &&
    css`
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    `};
`;

const TextWrapper = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  flex: ${({ $textArrangement, $isDesktop }) =>
    ($textArrangement === "Text Left" || $textArrangement === "Text Right") &&
    $isDesktop &&
    "1"};
  gap: var(--spacing-xs);
  padding: var(--spacing-lg) var(--spacing-sm);
  align-items: center;
  justify-content: center;
  ${({ $isDesktop }) =>
    $isDesktop
      ? css`
          padding: var(--spacing-lg) var(--spacing-lg);
        `
      : css`
          padding: var(--spacing-lg) var(--spacing-sm);
        `}

  ${({ $textAlignment }) =>
    $textAlignment === "Left" &&
    css`
      align-items: flex-start;
    `};

  ${({ $textArrangement }) =>
    $textArrangement === "Text Overlay" &&
    css`
      position: relative;
      transform: translate(0%, -100%);
    `};
  ${({ $withRule, $withCard }) =>
    $withRule &&
    css`
      border-bottom: 1px solid
        ${(props) =>
          $withCard ? props.theme.SupportColor : props.theme.DividerColor};
      margin-bottom: var(--spacing-sm);
    `};
  ${({ $withCard }) =>
    $withCard &&
    css`
      background: ${(props) => props.theme.BorderlessActiveBackground};
    `};
`;

const ContributorWrapper = styled.div<StyledProps>`
  display: flex;
  padding-top: var(--spacing-xxs);

  && > div {
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    gap: var(--spacing-xxs);
    padding-left: var(--spacing-xs);
  }
`;

const Avatar = styled.div<StyledProps>`
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: ${(props) => props.theme.PlaceholderColor};
`;

const LgTextBox = styled.div<StyledProps>`
  width: 100%;
  max-width: 375px;
  height: 10px;
  border-radius: 25px;
  background-color: ${(props) => props.theme.Color};
`;

const MdTextBox = styled.div<StyledProps>`
  width: 134px;
  height: 6px;
  border-radius: 25px;
  background-color: ${(props) => props.theme.Color};
`;

const SmTextBox = styled.div<StyledProps>`
  width: calc(var(--spacing-lg) * 2);
  height: 6px;
  border-radius: 25px;
  background-color: ${(props) => props.theme.PlaceholderColor};
`;

export const PreviewViewPort = ({
  isDesktop,
  previewProps,
  imageUrl,
}: {
  isDesktop: boolean;
  previewProps: ToggleMap;
  imageUrl: string | undefined;
}) => {
  const {
    textAlignment,
    textArrangement,
    storyImageSize,
    insetHeaderImage,
    contributorPhoto,
    rule,
    card,
  } = previewProps;
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const { translatePageLayout } = useDefinedMessages();

  useEffect(() => {
    // scroll ScrollWrapper to the top on toggle change
    scrollWrapperRef.current?.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [previewProps]);

  function isFullBleed(): boolean {
    // set Text Above Full Bleed
    if (
      (textArrangement === "Text Above" || textArrangement === "Text Below") &&
      storyImageSize === "Full Bleed" &&
      isDesktop
    )
      return true;
    // set Text Left & Text Right Full Bleed
    if (
      (textArrangement === "Text Left" || textArrangement === "Text Right") &&
      isDesktop &&
      insetHeaderImage === "No"
    )
      return true;
    // set Text Overlay Full Bleed
    if (textArrangement === "Text Overlay") return true;
    return false;
  }

  return (
    <ScrollWrapper
      ref={scrollWrapperRef}
      $isDesktop={isDesktop}
      $textArrangement={textArrangement}
    >
      <ImageWrapper
        $textArrangement={textArrangement}
        $isDesktop={isDesktop}
        $fullBleed={isFullBleed()}
        $insetHeaderImage={insetHeaderImage}
      >
        {imageUrl ? (
          <Image
            alt="Header Image"
            src={imageUrl}
            $fullBleed={isFullBleed()}
            $isDesktop={isDesktop}
            $storyImageSize={storyImageSize}
          />
        ) : (
          <DefaultImage $fullBleed={isFullBleed()}>
            {translatePageLayout("defaultImageText")}
          </DefaultImage>
        )}
      </ImageWrapper>
      <ThemeProvider
        theme={textArrangement === "Text Overlay" ? "dark" : "light"}
      >
        <TextWrapper
          $textArrangement={textArrangement}
          $textAlignment={textAlignment}
          $isDesktop={isDesktop}
          $withRule={rule === "Yes"}
          $withCard={card === "Yes"}
        >
          <LgTextBox></LgTextBox>
          <LgTextBox></LgTextBox>
          <MdTextBox></MdTextBox>
          <MdTextBox></MdTextBox>
          {contributorPhoto === "Yes" && (
            <ContributorWrapper>
              <Avatar></Avatar>
              <div>
                <SmTextBox></SmTextBox>
                <SmTextBox></SmTextBox>
              </div>
            </ContributorWrapper>
          )}
        </TextWrapper>
      </ThemeProvider>
    </ScrollWrapper>
  );
};

PreviewViewPort.displayName = "PreviewViewPort";

import { FC, useContext } from "react";
import { useMediaQuery } from "@hooks";
import { Button, RadioButtonGroup } from "@components";
import styled from "styled-components";
import { LinkIcon, FullscreenIcon } from "@condenast/gemini/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { PreviewContext } from "../PreviewPage/PreviewPage.context";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(var(--spacing-sm) * 3.5);
  gap: var(--spacing-sm);
  background-color: ${(props) => props.theme.Background};
  padding-left: calc(var(--spacing-lg) * 2);
  padding-right: var(--spacing-lg);
`;

const HeaderLabel = styled.h1`
  font: ${(props) => props.theme.FontSubSectionHeading};
  display: inline-block;
  margin: 0 auto;
`;

const ViewModeControls = styled.div`
  flex-grow: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const Header: FC<{
  onCopy: () => void;
  onRefresh?: () => void;
}> = (props) => {
  let intl = useIntl();
  const isMobile = useMediaQuery("(max-width: 800px)");
  const [viewMode, setViewMode] = useContext(PreviewContext);
  const hasRefresh = typeof props.onRefresh === "function";

  return (
    <Wrapper className="preview-header">
      <HeaderLabel>
        <FormattedMessage defaultMessage="Preview" />
      </HeaderLabel>
      {!isMobile && (
        <ViewModeControls>
          <RadioButtonGroup
            value={viewMode}
            onChange={(value) => setViewMode(value as typeof viewMode)}
            options={[
              {
                value: "mobile",
                label: intl.formatMessage({
                  defaultMessage: "Mobile",
                  description:
                    "Toggle button for content preview in mobile mode",
                }),
                iconName: "MobileIcon",
              },
              {
                value: "desktop",
                label: intl.formatMessage({
                  defaultMessage: "Desktop",
                  description:
                    "Toggle button for content preview in desktop mode",
                }),
                iconName: "DesktopIcon",
              },
            ]}
            noLabel={true}
          />
        </ViewModeControls>
      )}
      {hasRefresh && (
        <Button treatment="primary" onClick={props.onRefresh}>
          <FormattedMessage defaultMessage="Refresh" />
        </Button>
      )}
      <Button
        size="medium"
        aria-label={intl.formatMessage({ defaultMessage: "Copy link" })}
        aria-details={intl.formatMessage({
          defaultMessage:
            "This link can be viewed without a Copilot login. This is not a live URL.",
        })}
        onClick={props.onCopy}
      >
        <LinkIcon size="regular" />
      </Button>
      {!isMobile && (
        <Button
          size="medium"
          aria-pressed={viewMode === "fullscreen"}
          aria-label={intl.formatMessage({
            defaultMessage: "Responsive",
            description: "Toggle button for content preview in responsive mode",
          })}
          onClick={() => {
            setViewMode("fullscreen");
          }}
        >
          <FullscreenIcon size="regular" />
        </Button>
      )}
    </Wrapper>
  );
};

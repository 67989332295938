import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { length, required } from "@condenast/copilot-validators";
import { FormFor_content_TagPage } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";
import { publishURI } from "../validators";

const { object } = validators;

const tagpageValidators = object({
  name: required(),
  query: object({
    filters: object({
      categories: length({ min: 1 })
        .on("publish", "save")
        .catch(([{ message }]) => {
          return [
            {
              path: [],
              message: { ...message, name: "query.filters.categories" },
            },
          ];
        }),
      types: length({ min: 1 })
        .on("publish")
        .catch(([{ message }]) => {
          return [
            {
              path: [],
              message: { ...message, name: "query.filters.types" },
            },
          ];
        }),
    }),
  }),
}).andAlso(publishURI().on("publish"));

export default function validateTagpage(
  tagpage: FormFor_content_TagPage,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(tagpage, build(tagpageValidators), context, env);
}

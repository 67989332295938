import { ARIA, Button, Field } from "@components";
import { ComponentProps, FC, useCallback } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useUniqueId } from "@hooks";
import styled from "styled-components";
import { AssetData, ContentSummary } from "@types";
import { Image } from "@condenast/gemini";

const ShowPosterSelectButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: var(--spacing-md);
  svg {
    left: 0;
    margin-right: var(--spacing-xxs);
  }
`;
const ResultContainer = styled.ul`
  display: block;
  overflow-y: auto;
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  overflow: scroll;
  max-height: 45vh;
  text-align: center;
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
`;
const SelectedPosterImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacing-xxs) 0 var(--spacing-md);
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
`;
const SVGClip = styled.svg`
  display: inline-block;
  opacity: 0.2;
  width: 100%;
  height: 100px;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  margin: var(--spacing-xxs) 0 var(--spacing-md);
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
`;
const ResultSlat = styled.li`
  display: inline-block;
  cursor: default;
  padding: 0;
  position: relative;
  margin: var(--spacing-xxs);
  &:hover {
    cursor: pointer;
    box-shadow: ${(props) => props.theme.FocusRing};
  }
`;

//on failure of loading poster image of a clip
function VideoIcon() {
  return (
    <SVGClip
      xmlns="http://www.w3.org/2000/svg"
      fill="#565656"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className="processing-media"
    >
      <path d="M7 11V6H3v5h4zm0 1.976V12H3v1h4v-.024zM7 5V4H3v1h4zm2-2h6v5.998c0 1.77-4.12-.401-4.12 2.231 0 1.125-.626 1.749-1.88 1.872V14a1 1 0 01-1 1H2a1 1 0 01-1-1V3a1 1 0 011-1h1a1 1 0 011-1h2a1 1 0 011 1h1a1 1 0 011 1zm0 1v1h1V4H9zm2 0v1h1V4h-1zm2 0v1h1V4h-1z" />
    </SVGClip>
  );
}

const SelectPosterImageDialog = styled(ARIA.Dialog)`
  padding: 0;
`;

const SelectPosterImageDialogBody = styled.div`
  padding: var(--spacing-xxs) var(--spacing-md) var(--spacing-sm);
`;

export const PosterImageDialog: FC<
  Omit<ComponentProps<typeof Field>, "children"> & {
    photosTout: AssetData;
    cdnHost: string;
    thumbnails: ContentSummary[];
    onChangePosterImage: (poster: ContentSummary) => void;
  }
> = (props) => {
  const { photosTout, cdnHost = "", thumbnails, onChangePosterImage } = props;
  const intl = useIntl();
  const uid = useUniqueId();
  const selectItemPrefix = `thumbnail-item-${uid}`;
  const [posterImage, setPosterImage] = useState(photosTout);
  const [updatedPoster, setUpdatedPoster] = useState<ContentSummary | null>(
    null
  );
  const [showPosterSelectDialog, setPosterSelectDialog] = useState(false);

  const updateSelectedPhotosTout = useCallback(
    (node: ContentSummary) => {
      let updatePosterImage = { ...posterImage };
      updatePosterImage.id = node.id;
      updatePosterImage.filename = node?.asset?.filename ?? null;
      setPosterImage(updatePosterImage);
      setUpdatedPoster(node);
    },
    [posterImage]
  );

  const submit = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      if (updatedPoster) {
        onChangePosterImage(updatedPoster);
      }
      setPosterSelectDialog(false);
    },
    [onChangePosterImage, updatedPoster]
  );

  return (
    <>
      {showPosterSelectDialog && (
        <SelectPosterImageDialog
          title={intl.formatMessage({
            defaultMessage: "Select Poster Image",
            description: "Select Poster Image dialog heading",
          })}
          size={"regular"}
          submitButton={
            <Button type="submit" onClick={submit}>
              {intl.formatMessage({
                defaultMessage: "Done",
                description: "Done button in select poster image modal",
              })}
            </Button>
          }
          onClose={() => {
            setPosterImage(photosTout);
            setPosterSelectDialog(false);
          }}
        >
          <SelectPosterImageDialogBody>
            <SelectedPosterImageContainer>
              {!posterImage.id ? (
                <VideoIcon />
              ) : (
                <Image
                  asset={{
                    id: posterImage.id || "",
                    type: posterImage.type || "photos",
                    filename: posterImage.filename || "",
                    altText: posterImage.altText || "",
                    width: posterImage.width || null,
                    height: posterImage.height || null,
                    publicURL: null,
                  }}
                  cdnHost={cdnHost}
                  modifications={{ width: 400, height: 400 }}
                />
              )}
            </SelectedPosterImageContainer>
            <ResultContainer tabIndex={0}>
              {thumbnails?.map((photo, index) => {
                return (
                  <ResultSlat
                    key={photo.id}
                    id={`${selectItemPrefix}-${index}`}
                    onClick={() => updateSelectedPhotosTout(photo)}
                  >
                    <Image
                      asset={{
                        id: photo.id,
                        type: "photos",
                        filename: photo?.asset?.filename || "",
                        publicURL: null,
                        altText: null,
                        height: null,
                        width: null,
                      }}
                      cdnHost={cdnHost}
                      modifications={{ width: 100, height: 100 }}
                    />
                  </ResultSlat>
                );
              })}
            </ResultContainer>
          </SelectPosterImageDialogBody>
        </SelectPosterImageDialog>
      )}
      {
        <ShowPosterSelectButton
          treatment="borderless"
          onClick={() => setPosterSelectDialog(true)}
        >
          {intl.formatMessage({
            defaultMessage: "Select Poster Image",
            description: "Poster Image Select button",
          })}
        </ShowPosterSelectButton>
      }
    </>
  );
};
PosterImageDialog.displayName = "PosterImageDialog";

import {
  FC,
  useCallback,
  useState,
  useMemo,
  useContext,
  useRef,
  useEffect,
  ChangeEvent,
} from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import CopilotMarkdownRenderer from "@condenast/atjson-renderer-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";
import {
  CKEditor as CKEditorImplementation,
  Field,
  Button,
  TextArea,
} from "@components";
import { EmbedIcon, RichTextIcon } from "@condenast/gemini/icons";
import { Queries, Mutations } from "@gql";
import {
  useDefinedMessages,
  useDeferredPromise,
  LifecycleStep,
  LifecycleContext,
  useToast,
} from "@hooks";
import type { EditorInstance } from "@condenast/ckeditor5-build-condenast";
import {
  GetConfigs,
  GetConfigsVariables,
  ContentSummary,
  ControlProps,
  KeyedItem,
  GetContentSummary,
  GetContentSummaryVariables,
  FormFor_form_controls_MdEditorFormControl,
  FormattedText,
  UpdateMedia,
  UpdateMediaVariables,
  UserRole,
} from "@types";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { AssetSelector, useCKEditorConfig, useCKUpload } from "../-private";
import { TileViewPanel } from "../CKEditor/TileViewPanel";
import { ThemeProvider, SnowplowContext } from "@contexts";
import { normalizeContentSummary } from "@lib";
import { InvalidMarkdownMessage } from "./InvalidMarkdownMessage";
import { autogenerate } from "./autogenerate";
import { hasConversionError } from "./conversion-check";

const EditorField = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    ".....   toggle"
    "label   toggle"
    "control control"
    "message message";
  align-items: end;

  height: fit-content;
  margin-bottom: var(--spacing-xs);
`;

const Editor = styled(CKEditorImplementation)`
  grid-area: control;
`;

const MDTextArea = styled(TextArea)`
  font: var(--font-code);
  line-height: 1.5;
  padding-top: var(--ck-spacing-large);
  padding-bottom: var(--ck-spacing-large);
`;

const ModeToggle = styled.div`
  grid-area: toggle;
  display: flex;
  gap: var(--spacing-xs);
`;

const CKEDITOR_BUILDS = {
  Minimalist: "minimalist",
  Inline: "inline",
  Body: "block",
  Block: "block",
} as const;

function serialize(fieldName: string, doc: VersoSource | string) {
  let serializedValue = "";
  if (typeof doc === "string") {
    serializedValue = doc;
  } else {
    serializedValue = CopilotMarkdownRenderer.render(doc).trim();
  }

  if (fieldName === "body") {
    return {
      content: serializedValue,
      textFormat: "MARKDOWN",
    };
  }
  return serializedValue;
}

const TRACKING_EVENTS = {
  ROUTE_CHANGE: "route-change",
  BODY_READY: "ckeditor-body-ready",
  READY_FROM_ROUTE: "ckeditor-body-ready-from-route",
  READY_FROM_LOAD: "ckeditor-body-ready-from-load",
} as const;

type EditingMode = "richText" | "markdown";

export const CKEditor: FC<
  ControlProps<FormFor_form_controls_MdEditorFormControl> & {
    linkAutogen: boolean;
    linkAutogenConfigFields: string[];
    enablePrismXML: boolean | null;
    labelKey?: string;
  }
> = (props) => {
  const {
    model,
    errors,
    name,
    setValue,
    setHasDeferredChanges,
    currentOrganization,
    currentUser,
    richTextConfiguration,
    assetSelectorConfiguration,
    autogeneratePath,
    autogenerateConfig,
    wordCount,
    characterCount,
    linkAutogen,
    linkAutogenConfigFields,
    ckeditorLinkAutogenConfiguration,
    addTask,
    removeTask,
    enablePrismXML,
    setBodyRef,
    enableMarkdown,
    labelKey,
  } = props;
  const intl = useIntl();
  const { trackDurationToReady, trackComponentEvent } =
    useContext(SnowplowContext);
  const authorName = `${currentUser.firstName} ${currentUser.lastName}`;
  const { translateFieldName, translateContentType } = useDefinedMessages();
  const cdnHost = `https://${currentOrganization.metadata.mediaDomain}`;
  const hasLinkAutogen = linkAutogen && linkAutogenConfigFields.includes(name);

  //defer ck body conversions until save
  const ckEditorBodyRef = useRef<EditorInstance | null>(null);

  const isBody = name === "body";

  //boolean to determine if body should be deferred
  //should eventually pull from a monorepo config
  const deferBody = isBody && model["__typename"] != "Livestoryupdate";

  useEffect(() => {
    if (isBody && setBodyRef) {
      setBodyRef(ckEditorBodyRef);
    }
  }, [ckEditorBodyRef, isBody, setBodyRef]);

  const Client = useApolloClient();
  const getContentSummary = useCallback(
    (contentType, id) => {
      return Client.query<GetContentSummary, GetContentSummaryVariables>({
        query: Queries.GET_CONTENT_SUMMARY,
        variables: {
          organizationId: currentOrganization.organizationId,
          contentType,
          id,
        },
      });
    },
    [Client, currentOrganization.organizationId]
  );

  let { data: configData } = useQuery<GetConfigs, GetConfigsVariables>(
    Queries.GET_CONFIGS,
    {
      variables: {
        organizationId: currentOrganization.organizationId,
        userId: currentUser.id,
      },
      fetchPolicy: "cache-first",
    }
  );

  const onUpload = useCKUpload(currentOrganization, currentUser);
  const [numberOfWords, setWordCount] = useState<number | null>(null);
  const [numberOfCharacters, setCharCount] = useState<number | null>(null);

  let resolvedRichTextConfiguration = JSON.parse(
    JSON.stringify(richTextConfiguration)
  );
  const enableAccordion = configData?.configs?.ckeditorAccordion || false;
  if (isBody && !enableAccordion) {
    let includeArray = richTextConfiguration?.include?.filter(function (e) {
      return e !== "Accordion";
    });
    resolvedRichTextConfiguration["include"] = includeArray;
  }
  const resolvedConfig = useCKEditorConfig(
    resolvedRichTextConfiguration,
    currentOrganization,
    model.id as string,
    {
      autogenConfig: hasLinkAutogen
        ? ckeditorLinkAutogenConfiguration ?? undefined
        : undefined,
      setWordCount: wordCount ? setWordCount : undefined,
      setCharCount: characterCount ? setCharCount : undefined,
    }
  );

  const resolvedBuild = useMemo(
    () => CKEDITOR_BUILDS[richTextConfiguration?.build ?? "Minimalist"],
    [richTextConfiguration]
  );

  const { defer: deferSelectingAssets, deferRef: selectingAssets } =
    useDeferredPromise<ContentSummary[] | null>();
  const [assetSelectorContextualOptions, setAssetSelectorContextualOptions] =
    useState<{ types?: string[] } | undefined>(undefined);
  const [assetSelectorToggle, setAssetSelectorToggle] = useState(false);

  const resolvedAssetSelectorOptions = useMemo(() => {
    let typesToFilter =
      assetSelectorContextualOptions && assetSelectorContextualOptions.types;
    if (typesToFilter) {
      let configuredContentTypes = assetSelectorConfiguration?.contentTypes;
      let configuredExternalContentTypes =
        assetSelectorConfiguration?.externalTypes;
      let filteredContentTypes =
        configuredContentTypes?.filter((contentType) =>
          typesToFilter?.includes(contentType)
        ) || [];
      let filterExternalContentTypes =
        configuredExternalContentTypes?.filter((contentType) =>
          typesToFilter?.includes(contentType)
        ) || [];
      return assetSelectorConfiguration
        ? {
            ...assetSelectorConfiguration,
            ...{
              contentTypes: filteredContentTypes,
              externalTypes: filterExternalContentTypes,
            },
          }
        : null;
    }

    return assetSelectorConfiguration || null;
  }, [assetSelectorConfiguration, assetSelectorContextualOptions]);

  const modelValue = model[name] as string | FormattedText | null;
  const md =
    typeof modelValue === "string" ? modelValue : modelValue?.content ?? "";

  const [conversionError, setConversionError] = useState<Error | undefined>(
    () => {
      const error = hasConversionError(md);
      if (error) {
        trackComponentEvent(
          "ck_editor",
          "rich_text_toggle_error",
          "error_on_page_load"
        );
      }
      return error;
    }
  );

  const isMarkdownEnabled =
    enableMarkdown || currentUser.role === UserRole.superadmin;

  const showInvalidState = conversionError && isMarkdownEnabled;

  const [editingMode, setEditingMode] = useState<EditingMode>(
    // if we're in an invalid state on load, start off in markdown editor
    showInvalidState ? "markdown" : "richText"
  );
  const versoDoc = useMemo(() => {
    if (editingMode === "richText") {
      return CopilotMarkdownSource.fromRaw(md).convertTo(VersoSource);
    }

    return undefined;
  }, [md, editingMode]);

  const onChange = useCallback(
    (doc) => {
      let serializedValue = serialize(name, doc);
      setValue(name, serializedValue);
    },
    [setValue, name]
  );

  const onEditingModeChange = useCallback(
    (newMode: EditingMode) => {
      if (newMode === editingMode) return;

      setEditingMode(newMode);

      if (newMode === "markdown" && deferBody && !conversionError) {
        const doc = ckEditorBodyRef?.current?.getData({
          format: "application/vnd.atjson+verso",
        });
        if (doc) onChange(doc);
        setHasDeferredChanges?.(false);
      }

      if (newMode === "richText") {
        const error = hasConversionError(md);
        setConversionError(error);
        if (error) {
          trackComponentEvent(
            "ck_editor",
            "rich_text_toggle_error",
            "error_switching_from_md_to_ck"
          );
        }
      }
    },
    [
      md,
      editingMode,
      deferBody,
      conversionError,
      onChange,
      setHasDeferredChanges,
      trackComponentEvent,
    ]
  );

  const onBodyChange = useCallback(() => {
    setHasDeferredChanges?.(true);
  }, [setHasDeferredChanges]);

  const onAssetSelectorSubmit = useCallback(
    (assets: ContentSummary[]) => {
      selectingAssets.current?.resolve(assets);
    },
    [selectingAssets]
  );

  const openAssetSelector = useCallback(
    (options?: { types?: string[]; limitSelection?: number }) => {
      setAssetSelectorContextualOptions(options);
      setAssetSelectorToggle(true);

      // resolve any unsettled promises before creating a new one
      selectingAssets.current?.resolve(null);
      return deferSelectingAssets().promise;
    },
    [
      deferSelectingAssets,
      selectingAssets,
      setAssetSelectorToggle,
      setAssetSelectorContextualOptions,
    ]
  );

  const closeAssetSelector = useCallback(() => {
    selectingAssets.current?.resolve([]);

    setAssetSelectorToggle(false);
  }, [selectingAssets, setAssetSelectorToggle]);

  const { defer: deferBatchViewAssets, deferRef: batchViewAssets } =
    useDeferredPromise<ContentSummary[] | null>();

  const [contentSummariesData, setContentSummariesData] = useState<
    KeyedItem<ContentSummary>[] | null
  >([]);

  const showBatchSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Credit updated",
      description: "Success notification when updating batch credit",
    }),
  });

  const showBatchErrorMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Sorry, something went wrong",
      description: "Error notification when updating batch credit",
    }),
  });

  const [batchUpdate, batchUpdateResult] = useMutation<
    UpdateMedia,
    UpdateMediaVariables
  >(Mutations.UPDATE_MEDIA);

  const onBatchUpdate = useCallback(
    async (items: ContentSummary[], credit: string) => {
      const mediaItems = items.map((item) => {
        return {
          credit,
          id: item.id,
          authorName,
          contentType: item.contentType,
        };
      });

      try {
        const { data } = await batchUpdate({
          variables: {
            organizationId: currentOrganization.organizationId,
            data: mediaItems,
          },
        });

        if (data?.updateMedia?.success) {
          showBatchSuccessMessage();
        } else {
          showBatchErrorMessage();
        }
      } catch {
        showBatchErrorMessage();
      }
    },
    [
      authorName,
      batchUpdate,
      currentOrganization.organizationId,
      showBatchSuccessMessage,
      showBatchErrorMessage,
    ]
  );

  const [batchViewToggle, setBatchViewToggle] = useState(false);

  const openBatchView = useCallback(
    async (options?: { data?: { type: string; id: string }[] }) => {
      let data = options && options.data;

      let contentSummaries =
        (await Promise.all(
          data?.map((item) => getContentSummary(item.type, item.id)) ?? []
        ).then((values) => {
          let summaries = values.reduce((acc, contentSummaryData, index) => {
            let contentSummary = normalizeContentSummary(
              contentSummaryData.data.contentSummary,
              translateContentType
            );
            if (contentSummary) {
              acc.push({
                value: contentSummary,
                key: index,
              });
            }
            return acc;
          }, [] as KeyedItem<ContentSummary>[]);
          return summaries;
        })) ?? [];
      setContentSummariesData(contentSummaries);
      setBatchViewToggle(true);
      batchViewAssets.current?.resolve(null);
      trackComponentEvent("batch_view", "button_click", "ckeditor_batch_view");
      return deferBatchViewAssets().promise;
    },
    [
      batchViewAssets,
      trackComponentEvent,
      deferBatchViewAssets,
      getContentSummary,
    ]
  );

  const onSubmitBatchView = useCallback(
    async (data: ContentSummary[] | null = []) => {
      batchViewAssets.current?.resolve(data);
      setBatchViewToggle(false);
      trackComponentEvent(
        "batch_view_submit",
        "button_click",
        "ckeditor_batch_view_submit"
      );
    },
    [batchViewAssets, trackComponentEvent]
  );

  const closeBatchView = useCallback(async () => {
    batchViewAssets.current?.reject(null);
    setBatchViewToggle(false);
    trackComponentEvent(
      "batch_view_close",
      "button_click",
      "ckeditor_batch_view_close"
    );
  }, [batchViewAssets, trackComponentEvent]);

  let autogeneratedValue = undefined;

  if (autogeneratePath ?? autogenerateConfig) {
    autogeneratedValue = autogenerate(
      autogeneratePath ?? null,
      autogenerateConfig ?? null,
      model
    );
  }

  const editorReady = useCallback(() => {
    if (name === "body") {
      const bodyReady = performance.mark(TRACKING_EVENTS.BODY_READY);
      const [lastRouteTransition] = performance.getEntriesByName(
        TRACKING_EVENTS.ROUTE_CHANGE
      );
      if (lastRouteTransition) {
        const durationFromRoute = performance.measure(
          TRACKING_EVENTS.READY_FROM_ROUTE,
          {
            start: lastRouteTransition.startTime,
            end: bodyReady.startTime,
          }
        ).duration;
        const shouldComputeTimeFromPageLoad = (
          lastRouteTransition as PerformanceMark
        ).detail?.firstPageLoad;
        const durationFromLoad = shouldComputeTimeFromPageLoad
          ? performance.measure(TRACKING_EVENTS.READY_FROM_LOAD, {
              start: performance.getEntriesByType("navigation")[0].startTime,
              end: bodyReady.startTime,
            }).duration
          : null;
        trackDurationToReady(durationFromLoad, durationFromRoute);
      }

      performance.clearMarks(TRACKING_EVENTS.BODY_READY);
      performance.clearMeasures(TRACKING_EVENTS.READY_FROM_ROUTE);
      performance.clearMeasures(TRACKING_EVENTS.READY_FROM_LOAD);
    }
    // `name` shouldn't change, but we don't want to recompute this
    // even if it does
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deferBody && editingMode === "richText") {
      let convertBody = () => {
        const doc = ckEditorBodyRef?.current?.getData({
          format: "application/vnd.atjson+verso",
        });
        if (doc) {
          setValue("body", serialize(name, doc));
        }
      };

      addTask?.({
        lifecycleContexts: [LifecycleContext.SAVE],
        lifecycleName: LifecycleStep.BEFORE_SAVE_VALIDATION,
        taskName: "convertSaveBody",
        runner: convertBody,
      });

      addTask?.({
        lifecycleContexts: [LifecycleContext.PUBLISH],
        lifecycleName: LifecycleStep.BEFORE_PUBLISH_VALIDATION,
        taskName: "convertPublishBody",
        runner: convertBody,
      });
    }

    if (editingMode === "markdown") {
      removeTask?.({
        lifecycleContexts: [LifecycleContext.SAVE],
        lifecycleName: LifecycleStep.BEFORE_SAVE_VALIDATION,
        taskName: "convertSaveBody",
      });
      removeTask?.({
        lifecycleContexts: [LifecycleContext.PUBLISH],
        lifecycleName: LifecycleStep.BEFORE_PUBLISH_VALIDATION,
        taskName: "convertPublishBody",
      });
    }
  }, [name, addTask, removeTask, setValue, deferBody, editingMode]);

  return (
    <>
      {assetSelectorToggle && resolvedAssetSelectorOptions && (
        <AssetSelector
          config={resolvedAssetSelectorOptions}
          currentOrganizationID={currentOrganization.organizationId}
          onClose={closeAssetSelector}
          onSubmit={onAssetSelectorSubmit}
          cdnHost={cdnHost}
          currentUser={currentUser}
          disableReturnFocus={true}
        />
      )}
      {batchViewToggle && contentSummariesData && (
        <ThemeProvider theme="dark">
          <TileViewPanel
            onClose={closeBatchView}
            ckEditorTiles={contentSummariesData}
            cdnHost={cdnHost}
            onSubmit={onSubmitBatchView}
            onBatchUpdate={onBatchUpdate}
            isBatchUpdating={batchUpdateResult.loading}
            creditRichTextConfig={resolvedConfig}
          />
        </ThemeProvider>
      )}
      <EditorField
        label={
          labelKey ? translateFieldName(labelKey) : translateFieldName(name)
        }
        id={`CKEditor__${name}`}
        errors={errors}
      >
        {isMarkdownEnabled && (
          <ModeToggle>
            <Button
              onClick={() => onEditingModeChange("richText")}
              aria-pressed={editingMode === "richText"}
              aria-label="Rich Text"
            >
              <RichTextIcon size="small" />
            </Button>
            <Button
              onClick={() => onEditingModeChange("markdown")}
              aria-pressed={editingMode === "markdown"}
              aria-label="Markdown"
            >
              <EmbedIcon size="small" />
            </Button>
          </ModeToggle>
        )}
        {editingMode === "richText" && !showInvalidState && versoDoc && (
          <Editor
            id={`CKEditor__${name}__editor`}
            value={versoDoc}
            onUpload={onUpload}
            onChange={onChange}
            onBodyChange={deferBody ? onBodyChange : undefined}
            reorderAssets={openBatchView}
            selectAssets={openAssetSelector}
            build={resolvedBuild}
            config={resolvedConfig}
            aria-invalid={!!errors.length}
            autogeneratedValue={autogeneratedValue}
            wordCount={numberOfWords}
            charCount={numberOfCharacters}
            hasLinkAutogen={hasLinkAutogen}
            onEditorReady={editorReady}
            ref={isBody ? ckEditorBodyRef : undefined}
            enablePrismXML={enablePrismXML ?? undefined}
          ></Editor>
        )}
        {editingMode === "richText" && showInvalidState && (
          <InvalidMarkdownMessage />
        )}
        {editingMode === "markdown" && (
          <MDTextArea
            id={`TextArea__${name}__editor`}
            onChange={(evt: ChangeEvent<HTMLTextAreaElement>) => {
              onChange(evt.target.value);
            }}
            value={md}
            multiline={true}
          />
        )}
      </EditorField>
    </>
  );
};
CKEditor.displayName = "Control(CKEditor)";

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required } from "@condenast/copilot-validators";
import { FormFor_content_MusicRelease } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";

const { object } = validators;

const musicreleaseValidators = object({
  name: required(),
});

export default function validateMusicRelease(
  musicrelease: FormFor_content_MusicRelease,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(musicrelease, build(musicreleaseValidators), context, env);
}

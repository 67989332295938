import { ValidationError } from "@condenast/cross-check";
import { categories } from "./categories";

export function channelsOrSections() {
  return categories({ taxonomyName: "channels" })
    .or(categories({ taxonomyName: "sections" }))
    .catch(([{ message }]) =>
      (message.details as ValidationError[][]).map((errors) => errors[0])
    );
}

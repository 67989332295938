import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { required, optional, integer } from "@condenast/copilot-validators";

import type { ValidationContext, ValidationEnvironment } from "@lib";
import type { FormFor_content_Venue } from "@types";

const { object } = validators;

const shipValidators = object({
  name: required(),
  cruiseLine: required().on("publish"),
  passengers: optional(integer()),
  cabins: optional(integer()),
  cabinsWithBalconies: optional(integer()),
  smallestCabin: optional(integer()),
  swimmingPools: optional(integer()),
  restaurants: optional(integer()),
});

export default function validateShip(
  ship: FormFor_content_Venue,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(ship, build(shipValidators), context, env);
}

import { useState, useCallback, createRef, useEffect } from "react";
import computeScrollIntoView from "compute-scroll-into-view";
import { KeyboardNavigationOptions } from "./index";

export const useTabListKeyboardNavigation = (
  itemsLength: number,
  options?: KeyboardNavigationOptions
) => {
  const defaultCursor =
    options?.defaultCursor && options?.defaultCursor >= 0
      ? options?.defaultCursor
      : 0;
  const [cursor, setCursor] = useState(defaultCursor);
  const [cursorMode, setCursorMode] = useState<"mouse" | "keyboard">("mouse");
  const tabRefs = new Array(itemsLength)
    .fill(null)
    .map(() => createRef<HTMLLIElement>());

  useEffect(() => {
    if (cursorMode === "keyboard" && cursor != null) {
      let selection = tabRefs[cursor];
      if (selection?.current) {
        let actions = computeScrollIntoView(selection.current, {
          scrollMode: "if-needed",
          block: "nearest",
          inline: "nearest",
        });
        actions.forEach(({ el, top }) => {
          el.scrollTop = top;
        });
      }
    }
  }, [cursorMode, cursor, tabRefs]);

  const onKeyDown = useCallback(
    (evt) => {
      let newCursor = cursor;
      switch (evt.key) {
        case "ArrowDown":
          if (cursor == null) {
            newCursor = -1;
          }
          newCursor = (newCursor + 1) % tabRefs.length;
          setCursor(newCursor);
          setCursorMode("keyboard");
          evt.preventDefault();
          evt.stopPropagation();
          break;
        case "ArrowUp":
          if (cursor == null) {
            newCursor = tabRefs.length;
          }
          newCursor = (newCursor - 1 + tabRefs.length) % tabRefs.length;
          setCursor(newCursor);
          setCursorMode("keyboard");
          evt.preventDefault();
          evt.stopPropagation();
          break;
        case "Home":
          setCursor(0);
          break;
        case "End":
          setCursor(tabRefs.length - 1);
          break;
        case "Enter":
          if (options?.onEnter) {
            options.onEnter(cursor);
          }
          break;
      }
    },
    [cursor, tabRefs, options]
  );

  const onMouseMove = useCallback((newCursor) => {
    setCursor(newCursor);
    setCursorMode("mouse");
  }, []);

  const onFocus = useCallback(() => setCursorMode("keyboard"), []);
  const onBlur = useCallback(() => setCursorMode("mouse"), []);

  return {
    tabRefs,
    cursor,
    cursorMode,
    onKeyDown,
    onMouseMove,
    onFocus,
    onBlur,
  };
};

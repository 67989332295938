import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required } from "@condenast/copilot-validators";

import type { ValidationContext, ValidationEnvironment } from "@lib";
import type { FormFor_content_Taxonomy } from "@types";

const { object } = validators;

const taxonomyValidators = object({
  name: required(),
  category: required().on("publish"),
  socialTitle: required().on("publish"),
  seo: object({
    description: required(),
    title: required(),
  }).on("publish"),
});

export default function validateTaxonomy(
  taxonomy: FormFor_content_Taxonomy,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(taxonomy, build(taxonomyValidators), context, env);
}

import {
  ContentSummaryFields,
  ContentSummary,
  ContentSummaryMetadata,
  CreateArticle_content_Article_advertiser_ContentConnection_edges_node_metadata_ContentSummaryExternalID as ContentSummaryExternalID,
  CreateArticle_content_Article_advertiser_ContentConnection_edges_node_metadata_ContentSummaryBundle as ContentSummaryBundle,
} from "@types";

export function normalizeContentSummary(
  data?: ContentSummaryFields | null,
  translateContentType?: (
    name: string,
    count: number | "many",
    fallback?: string
  ) => string
): ContentSummary | null {
  if (!data) return null;

  const contentType = data.contentType;
  const contentTypeLabel = translateContentType?.(contentType ?? "", 1) || "";
  const normalizedData = data
    ? {
        ...data,
        contentTypeLabel: contentTypeLabel,
        metadata:
          data.metadata?.map((metadataValue) => {
            return {
              ...metadataValue,
              type: metadataValue.__typename,
            } as ContentSummaryMetadata;
          }) ?? [],
      }
    : null;

  const bundleMetadata = normalizedData?.metadata?.find(
    (metadataItem) => metadataItem.type === "ContentSummaryBundle"
  ) as ContentSummaryBundle | undefined;

  const commerceMetadata = normalizedData?.metadata?.find(
    (metadataItem) => metadataItem.type === "ContentSummaryExternalID"
  ) as ContentSummaryExternalID | undefined;

  if (normalizedData?.contentType === "bundle" && bundleMetadata?.bundleType) {
    normalizedData.contentTypeLabel = bundleMetadata?.bundleType;
  }

  if (
    normalizedData?.contentType === "contentreference" &&
    commerceMetadata?.provider === "commerce-tools"
  ) {
    normalizedData.contentTypeLabel =
      translateContentType?.("commerceproduct", 1) || "";
  }

  return normalizedData;
}

export function serializeContentSummary(
  data: ContentSummary | null
): ContentSummaryFields | null {
  if (!data) return null;

  const { contentTypeLabel, ...serializedData } = data;
  return data
    ? ({
        __typename: "ContentSummary",
        ...serializedData,
        metadata: (data.metadata ?? []).map(({ type, ...metadataValue }) => {
          return {
            __typename: type,
            ...metadataValue,
          };
        }),
      } as ContentSummaryFields)
    : null;
}

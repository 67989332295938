import { ApolloError } from "@apollo/client";
import { Link, Toast } from "@components";
import { ComponentType } from "react";
import { FormattedMessage } from "react-intl";

function UnauthenticatedError() {
  return (
    <Toast
      type="error"
      action={
        <Link to={`/auth/login`} target="_blank">
          <FormattedMessage defaultMessage="Reauthenticate" />
        </Link>
      }
      details={
        <FormattedMessage defaultMessage="Your work will be ready to post when you come back!" />
      }
    >
      <FormattedMessage defaultMessage="It looks like you've been logged out." />
    </Toast>
  );
}

function InternalServerError() {
  return (
    <Toast type="error">
      <FormattedMessage
        defaultMessage="Sorry, something went wrong. Try reloading or please contact support."
        description="Generic error given when a network request fails"
      />
    </Toast>
  );
}

export function ServerError<T extends Record<string, unknown>>(props: {
  error: ApolloError;
  responses?: {
    [key: string]: ComponentType<{ context: T }>;
  };
}) {
  let graphQLErrors = props.error.graphQLErrors ?? [];
  let extension = graphQLErrors[0]?.extensions;
  let code = extension?.code;
  let Component: ComponentType<{ context: T }> = InternalServerError;
  if (code) {
    if (props.responses && props.responses[code]) {
      Component = props.responses[code];
    } else if (code === "UNAUTHENTICATED") {
      Component = UnauthenticatedError;
    }
  }

  return <Component context={extension?.context} />;
}
ServerError.displayName = "ServerError";

import { defineMessages } from "react-intl";

export const SEO_MESSAGES = defineMessages({
  "body-first-words.success": {
    description: "seoRules.body-first-words.success",
    defaultMessage: "Search keyword appears in first 30 words of body",
    //description: "seoRules.body-first-words.success",
  },
  "body-first-words.fail": {
    description: "seoRules.body-first-words.fail",
    defaultMessage:
      "Use the exact search keyword at the beginning of the body (first 30 words)",
  },
  "body-first-embed.fail": {
    description: "seoRules.body-first-embed.fail",
    defaultMessage:
      "Article may be rejected from Google News for having fewer than 150 words before the first embed",
  },
  "body-first-embed.success": {
    description: "seoRules.body-first-embed.success",
    defaultMessage: "First embed appears after 150 words",
  },
  "body-has-internal-link.fail": {
    description: "seoRules.body-has-internal-link.fail",
    defaultMessage: "Add 1-5 internal links to the body",
  },
  "body-has-internal-link.success": {
    description: "seoRules.body-has-internal-link.success",
    defaultMessage: "Body contains at least 5 internal links",
  },
  "body-has-internal-link.tierOne.success": {
    description: "seoRules.body-has-internal-link.tierOne.success",
    defaultMessage: "Body contains 1 internal link",
  },
  "body-has-internal-link.tierOne.improve": {
    description: "seoRules.body-has-internal-link.tierOne.improve",
    defaultMessage:
      "Add up to two more internal links to the body for more points",
  },
  "body-has-internal-link.tierTwo.success": {
    description: "seoRules.body-has-internal-link.tierTwo.success",
    defaultMessage: "Body contains 2-4 internal links",
  },
  "body-has-internal-link.tierTwo.improve": {
    description: "seoRules.body-has-internal-link.tierTwo.improve",
    defaultMessage:
      "Add up to three more internal links to the body for max points",
  },
  "body-has-internal-link-first.success": {
    description: "seoRules.body-has-internal-link-first.success",
    defaultMessage: "The first URL in the body is internal",
  },
  "body-has-internal-link-first.fail": {
    description: "seoRules.body-has-internal-link-first.fail",
    defaultMessage:
      "The first URL in the body should be internal, not external",
  },
  "body-has-keyword.success": {
    description: "seoRules.body-has-keyword.success",
    defaultMessage: "Search keyword appears twice in the body",
  },
  "body-has-keyword.fail": {
    description: "seoRules.body-has-keyword.fail",
    defaultMessage: "Use the search keyword at least twice in the body",
  },
  "body-has-more-internal-links.success": {
    description: "seoRules.body-has-more-internal-links.success",
    defaultMessage: "Body contains more internal URLs than external URLs",
  },
  "body-has-more-internal-links.fail": {
    description: "seoRules.body-has-more-internal-links.fail",
    defaultMessage: "Body contains more external URLs than internal URLs",
  },
  "body-has-videos.success": {
    description: "seoRules.body-has-videos.success",
    defaultMessage: "Body contains at least one CNE video",
  },
  "body-has-videos.fail": {
    description: "seoRules.body-has-videos.fail",
    defaultMessage: "Add a CNE video to the body",
  },
  "body-link-ratio.success": {
    description: "seoRules.body-link-ratio.success",
    defaultMessage: "Body averages 1 URL per 100 words",
  },
  "body-link-ratio.fail": {
    description: "seoRules.body-link-ratio.fail",
    defaultMessage: "Body should average 1 URL per 100 words",
  },
  "body-word-count.fail": {
    description: "seoRules.body-word-count.fail",
    defaultMessage:
      "You need more words in the body or captions to increase your score",
  },
  "body-word-count.improve": {
    description: "seoRules.body-word-count.improve",
    defaultMessage: "Add {numberToAdd} words to the body or captions",
  },
  "body-word-count.success": {
    description: "seoRules.body-word-count.success",
    defaultMessage: "Body and captions combined has more than 600 words",
  },
  "body-word-count.tierOne.improve": {
    description: "seoRules.body-word-count.tierOne.improve",
    defaultMessage:
      "Add {numberToAdd} words to the body or captions for more points",
  },
  "body-word-count.tierOne.success": {
    description: "seoRules.body-word-count.tierOne.success",
    defaultMessage: "Body and captions combined is between 100-299 words",
  },
  "body-word-count.tierTwo.improve": {
    description: "seoRules.body-word-count.tierTwo.improve",
    defaultMessage:
      "Add {numberToAdd} words to the body or captions for maximum points",
  },
  "body-word-count.tierTwo.success": {
    description: "seoRules.body-word-count.tierTwo.success",
    defaultMessage: "Body and captions combined is between 300-599 words",
  },
  "excessive-external-links.success": {
    description: "seoRules.excessive-external-links.success",
    defaultMessage: "Body has more than one external link per 200 words",
  },
  "excessive-external-links.fail": {
    description: "seoRules.excessive-external-links.fail",
    defaultMessage: "Body has at most 1 external link for every 200 words",
  },
  "body-has-broken-links.fail": {
    description: "seoRules.body-has-broken-links.fail",
    defaultMessage: "Body has broken links or links to staging by accident",
  },
  "has-images.success": {
    description: "seoRules.has-images.success",
    defaultMessage: "Content contains at least one image",
  },
  "has-images.fail": {
    description: "seoRules.has-images.fail",
    defaultMessage: "Embed an image in the body, or add a lede",
  },
  "hed-first-words.success": {
    description: "seoRules.hed-first-words.success",
    defaultMessage: "Search keyword starts at the beginning of the hed",
  },
  "hed-first-words.fail": {
    description: "seoRules.hed-first-words.fail",
    defaultMessage:
      "Move search keyword to beginning of the hed (first 3 words)",
  },
  "hed-has-keyword.success": {
    description: "seoRules.hed-has-keyword.success",
    defaultMessage: "All keywords used in hed",
  },
  "hed-has-keyword.fail": {
    description: "seoRules.hed-has-keyword.fail",
    defaultMessage: "Use all keywords in the hed for max points",
  },
  "hed-is-seo-title.success": {
    description: "seoRules.hed-is-seo-title.success",
    defaultMessage: "Hed and SEO title match",
  },
  "hed-is-seo-title.fail": {
    description: "seoRules.hed-is-seo-title.fail",
    defaultMessage:
      "Article may be rejected from Google News because the hed and SEO title do not match",
  },
  "image-alt-text-has-keywords.success": {
    description: "seoRules.image-alt-text-has-keywords.success",
    defaultMessage: "Lead image alt text contains SEO keyword phrase",
  },
  "image-alt-text-has-keywords.fail": {
    description: "seoRules.image-alt-text-has-keywords.fail",
    defaultMessage:
      "Update lead image's alt text to include SEO keyword phrase",
  },
  "link-has-keyword.fail": {
    description: "seoRules.link-has-keyword.fail",
    defaultMessage: "Remove keyword phrase from link text",
  },
  "seo-description-char-count.fail": {
    description: "seoRules.seo-description-char-count.fail",
    defaultMessage:
      "Edit the SEO description so that it is between 100-160 characters long",
  },
  "seo-description-char-count.success": {
    description: "seoRules.seo-description-char-count.success",
    defaultMessage: "SEO description is between 100-160 characters long",
  },
  "seo-description-has-keywords.success": {
    description: "seoRules.seo-description-has-keywords.success",
    defaultMessage: "Search keyword used in SEO description",
  },
  "seo-description-has-keywords.fail": {
    description: "seoRules.seo-description-has-keywords.fail",
    defaultMessage: "Use the exact search keyword in the SEO description",
  },
  "seo-title-first-words.success": {
    description: "seoRules.seo-title-first-words.success",
    defaultMessage: "Search keyword starts at beginning of SEO title",
  },
  "seo-title-first-words.fail": {
    description: "seoRules.seo-title-first-words.fail",
    defaultMessage:
      "Move search keyword to beginning of the SEO title (first 3 words)",
  },
  "odd-number-in-seo-title.success": {
    description: "seoRules.odd-number-in-seo-title.success",
    defaultMessage: "SEO title contains an odd number at the begining",
  },
  "odd-number-in-seo-title.fail": {
    description: "seoRules.odd-number-in-seo-title.fail",
    defaultMessage: "Update SEO title to include an odd number at the begining",
  },
  "odd-number-in-seo-title.tierOne.success": {
    description: "seoRules.odd-number-in-seo-title.tierOne.success",
    defaultMessage: "SEO title contains an even number at the begining",
  },
  "odd-number-in-seo-title.tierOne.improve": {
    description: "seoRules.odd-number-in-seo-title.tierOne.improve",
    defaultMessage:
      "Update number at the begining of the SEO title to an odd number",
  },
  "fields-exist.bodyMissing": {
    description: "seoRules.fields-exist.bodyMissing",
    defaultMessage: "Fill in the body",
  },
  "fields-exist.hedMissing": {
    description: "seoRules.fields-exist.hedMissing",
    defaultMessage: "Add a hed",
  },
  "fields-exist.internalLinkMissing": {
    description: "seoRules.fields-exist.internalLinkMissing",
    defaultMessage: "Add an internal link to the body",
  },
  "fields-exist.seoKeywordMissing": {
    description: "seoRules.fields-exist.seoKeywordMissing",
    defaultMessage: "Add a search keyword",
  },
  "fields-exist.seoDescriptionMissing": {
    description: "seoRules.fields-exist.seoDescriptionMissing",
    defaultMessage: "Add an SEO description",
  },
  "fields-exist.seoTitleMissing": {
    description: "seoRules.fields-exist.seoTitleMissing",
    defaultMessage: "Add an SEO title",
  },
  "seo-title-has-keyword.success": {
    description: "seoRules.seo-title-has-keyword.success",
    defaultMessage: "Exact search keyword used in the SEO Title",
  },
  "seo-title-has-keyword.fail": {
    description: "seoRules.seo-title-has-keyword.fail",
    defaultMessage:
      "Add a keyword to the SEO Title or add the exact phrase for max points",
  },
  "seo-title-has-keyword.tierOne.success": {
    description: "seoRules.seo-title-has-keyword.tierOne.success",
    defaultMessage: "At least one keyword is used in the SEO title",
  },
  "seo-title-has-keyword.tierOne.improve": {
    description: "seoRules.seo-title-has-keyword.tierOne.improve",
    defaultMessage: "Use all keywords in the SEO title for more points",
  },
});

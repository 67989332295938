import uuid from "uuid-random";
import { memo } from "react";
import { ContainerFieldsProps } from "../types";
import { MdEditor, CardList } from "../../index";
import { CardListProps, MdEditorProps } from "../utils";
const ContainerFields = memo(function (props: ContainerFieldsProps) {
  let {
    configs,
    currentOrganization,
    currentUser,
    permissions,
    errors,
    containerData,
    containerIndex,
    updateContainerByIndex,
    fieldStates,
  } = props;

  const SharedControlProps = {
    currentOrganization,
    currentUser,
    permissions,
    errors,
    id: uuid(),
    children: null,
  };

  return (
    <>
      {fieldStates.hed && (
        <MdEditor
          {...SharedControlProps}
          {...MdEditorProps}
          name="hed"
          labelKey="title"
          placeholderKey={"placeholder.articles.hed"}
          model={containerData as unknown as Record<string, unknown>}
          setValue={(key, value) => {
            let updatedContainer = {
              ...containerData,
              ...{ [key]: value },
            };
            updateContainerByIndex(updatedContainer, containerIndex);
          }}
          richTextConfiguration={configs?.hedRichTextConfiguration || null}
        />
      )}
      {fieldStates.dek && (
        <MdEditor
          {...SharedControlProps}
          {...MdEditorProps}
          name="dek"
          labelKey="introduction"
          placeholderKey={"placeholder.articles.dek"}
          model={containerData as unknown as Record<string, unknown>}
          setValue={(key, value) => {
            let updatedContainer = {
              ...containerData,
              ...{ [key]: value },
            };
            updateContainerByIndex(updatedContainer, containerIndex);
          }}
          richTextConfiguration={configs?.dekRichTextConfiguration || null}
        />
      )}
      {fieldStates.containerAsset && (
        <CardList
          {...SharedControlProps}
          {...CardListProps}
          name="containerAsset"
          labelKey="image"
          treatment="slat"
          model={containerData as unknown as Record<string, unknown>}
          setValue={(key, value) => {
            let updatedContainer = {
              ...containerData,
              ...{ [key]: value },
            };
            updateContainerByIndex(updatedContainer, containerIndex);
          }}
          assetSelectorConfigurationPath="assetSelector.ThumbnailUpload"
        />
      )}
    </>
  );
});

ContainerFields.displayName = "ContainerFields;";

export default ContainerFields;

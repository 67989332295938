import { PRESET_CATEGORY } from "@types";
import { PresetCategory } from "../ContainerOptions.types";

const presetCategoryMap = new Map([
  [PRESET_CATEGORY.TEXT_ONLY, "text-only"],
  [PRESET_CATEGORY.TEXT_OVERLAY, "text-overlay"],
  [PRESET_CATEGORY.SPLIT_SCREEN, "split-screen"],
  [PRESET_CATEGORY.TEXT_OVERLAY_VIDEO, "text-overlay-video"],
]);

export const getFormPath = (
  containerType: string,
  presetCategory?: PresetCategory
): string => {
  const basePath = `preview/bundle/${containerType}`;
  if (presetCategory && presetCategoryMap.has(presetCategory)) {
    return `${basePath}/${presetCategoryMap.get(presetCategory)}`;
  }
  return basePath;
};

import { ARIA, Button, Field, Toast } from "@components";
import { ThemeProvider } from "@contexts";
import { slugify } from "@lib";
import { useResizeEffect } from "@hooks";
import {
  CopyIcon,
  WandIcon,
  LockIcon,
  UnlockIcon,
  EllipsisIcon,
} from "@condenast/gemini/icons";
import styled, { DefaultTheme } from "styled-components";
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useState,
  useMemo,
} from "react";
import type { MenuItem } from "packages/gemini/src/components/ARIA/Menu/MenuItem/types";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl";

const ACTION_LABELS = defineMessages({
  copy: { defaultMessage: "Copy", description: "As in 'copy and paste'" },
  autofill: {
    defaultMessage: "Autofill",
    description:
      "Label for a button that automatically fills in the value for a field",
  },
  lock: {
    defaultMessage: "Lock",
    description: "'Lock' as a verb, for disabling further input into a field",
  },
  unlock: {
    defaultMessage: "Unlock",
    description:
      "Label for a button that enables input to a currently-disabled field",
  },
});

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "input"
    "message";
`;

const UriInputBox = styled.div<{ $locked: boolean; $focused: boolean }>`
  grid-area: input;
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) =>
    props.$focused
      ? `${props.theme.FocusRing}, ${props.theme.FieldFocusRing}`
      : props.theme.FieldRing};

  display: flex;
  align-items: top;

  background: ${(props) => props.theme.FieldBackground};

  ${(props) =>
    !props.$focused &&
    `&:hover {
      box-shadow: ${props.theme.FieldHoverRing};
    }`}

  .hasError & {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }

  ${(props) =>
    props.$locked &&
    `
    color: ${props.theme.ControlDisabledColor};
    background: ${props.theme.ControlDisabledBackground};
  `}

  button {
    margin-right: var(--spacing-xxs);
  }
`;

const TextContainer = styled.div<{ $locked: boolean }>`
  position: relative;
  flex: 1;
  margin: var(--spacing-xs) var(--spacing-sm);
  font: ${(props) => props.theme.FontBody};
  cursor: ${(props) => (props.$locked ? "default" : "text")};
`;

const NonEditableText = styled.span`
  position: absolute;
  color: ${(props) => props.theme.ControlDisabledColor};
`;

const EditableText = styled.div<{ $indentSize: number }>`
  outline: 0px solid transparent;
  text-indent: ${(props) => props.$indentSize}px;
  word-break: break-all;
`;

const ActionsContainer = styled.div`
  flex: 0 0 auto;
  margin: var(--spacing-xxs);
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: var(--spacing-sm);
  }
`;

const AutofillButton = styled(Button)`
  background: ${(props) => props.theme.AutofillButtonBackgroundColor};
  color: ${(props) => props.theme.AutofillButtonColor};

  &:not(:disabled):hover {
    background: ${(props) => props.theme.AutofillButtonHoverBackground};
    cursor: ${(props) => props.theme.AutofillButtonHoverCursor};
  }
`;

function LockToggle(props: {
  locked: boolean;
  onClick?: () => void;
  readonly?: boolean;
}) {
  const StateIcon = props.locked ? LockIcon : UnlockIcon;
  let intl = useIntl();
  if (props.onClick) {
    return (
      <Button
        onClick={props.onClick}
        disabled={props.readonly}
        aria-label={intl.formatMessage(
          props.locked ? ACTION_LABELS.unlock : ACTION_LABELS.lock
        )}
      >
        <StateIcon size="small" />
      </Button>
    );
  } else if (props.locked) {
    <Button
      disabled
      aria-label={intl.formatMessage({
        defaultMessage: "Locked",
        description:
          "'Locked' as an adjective, describing the state of a disabled field",
      })}
    >
      <StateIcon size="small" />
    </Button>;
  }

  return null;
}

function MaybeButton(props: ComponentProps<typeof Button>) {
  if (props.onClick) {
    return <Button {...props} />;
  }

  return null;
}

function Actions(props: {
  id: string;
  compact: boolean;
  autoFill?: () => void;
  onCopy?: () => void;
  locked: boolean;
  toggleLock?: () => void;
  autogeneratedValue?: string | null;
  readonly?: boolean;
}) {
  let {
    id,
    compact,
    autoFill,
    onCopy,
    locked,
    toggleLock,
    autogeneratedValue,
    readonly,
  } = props;
  let intl = useIntl();
  const actionItems = [
    {
      role: "action",
      value: { icon: <WandIcon size="small" />, label: ACTION_LABELS.autofill },
      onClick: autogeneratedValue ? autoFill : null,
    },
    {
      role: "action",
      value: { icon: <CopyIcon size="small" />, label: ACTION_LABELS.copy },
      onClick: onCopy,
    },
    {
      role: "action",
      value: locked
        ? { icon: <LockIcon size="small" />, label: ACTION_LABELS.unlock }
        : { icon: <UnlockIcon size="small" />, label: ACTION_LABELS.lock },
      onClick: readonly ? null : toggleLock,
    },
  ];
  let items = actionItems.filter((action) => !!action.onClick) as MenuItem<{
    icon: ReactNode;
    label: MessageDescriptor;
  }>[];
  if (items.length > 1 && compact) {
    return (
      <ARIA.MenuButton
        id={id}
        aria-label={intl.formatMessage({
          defaultMessage: "Actions menu",
          description: "Button to open a menu with actions the user can take",
        })}
        menu={{
          items,
          children({ icon, label }) {
            return (
              <StyledItem>
                {icon}
                <FormattedMessage {...label} />
              </StyledItem>
            );
          },
        }}
      >
        <EllipsisIcon size="small" />
      </ARIA.MenuButton>
    );
  } else {
    return (
      <>
        {autogeneratedValue && (
          <AutofillButton
            disabled={locked}
            onClick={autoFill}
            aria-label={intl.formatMessage(ACTION_LABELS.autofill)}
          >
            <WandIcon size="small" />
          </AutofillButton>
        )}
        <MaybeButton
          onClick={onCopy}
          aria-label={intl.formatMessage(ACTION_LABELS.copy)}
        >
          <CopyIcon size="small" />
        </MaybeButton>
        <LockToggle locked={locked} onClick={toggleLock} readonly={readonly} />
      </>
    );
  }
}

function autofilledTheme(
  theme: DefaultTheme,
  purpleify: (css: string) => string,
  doTransform?: boolean
) {
  let {
    FieldRing,
    FieldActiveRing,
    FieldFocusRing,
    FieldHoverRing,
    FocusRing,
  } = theme;

  return doTransform
    ? {
        ...theme,
        AutofillButtonBackgroundColor: "var(--color-purple-50)",
        AutofillButtonHoverBackground: "var(--color-purple-50)",
        AutofillButtonColor: "var(--color-white)",
        AutofillButtonHoverCursor: "default",
        FieldRing: purpleify(FieldRing),
        FieldActiveRing: purpleify(FieldActiveRing),
        FieldFocusRing: purpleify(FieldFocusRing),
        FieldHoverRing: purpleify(FieldHoverRing),
        FocusRing: purpleify(FocusRing),
      }
    : theme;
}

const MINIMUM_EDITABLE_WIDTH_PX = 100;
const THREE_BUTTONS_PX = 100;

export function PublishUrlField(props: {
  id: string;
  "aria-labelledby": string;
  hostname: string;
  nonEditableUriPart: string;
  editableUriPart: string;
  readonly?: boolean;
  showURIWarning?: boolean;
  onChange: (newUri: string) => void;
  autogeneratedValue?: string | null;
  allowUnicodeLetters?: boolean;
  allowUppercase?: boolean;
  onCopy?: () => void;
  locked: boolean;
  toggleLock?: () => void;
  errors?: {
    path: readonly string[];
    message: { name: string; message: string };
  }[];
}) {
  let {
    autogeneratedValue,
    allowUnicodeLetters,
    allowUppercase,
    onChange,
    locked,
    id,
    hostname,
    nonEditableUriPart,
    editableUriPart,
    readonly,
    showURIWarning,
  } = props;
  let [focused, setFocused] = useState(false);

  let [isAutogenerated, setIsAutogenerated] = useState(false);
  let autogenerate = useCallback(() => {
    if (autogeneratedValue) {
      setIsAutogenerated(true);
      onChange(
        slugify(autogeneratedValue, {
          allowAllLetterCharacters: allowUnicodeLetters,
          allowUppercase,
        }).toString()
      );
    }
  }, [onChange, autogeneratedValue, allowUnicodeLetters, allowUppercase]);

  let spyOnChange = useCallback(
    (newEditableUriPartvalue: string) => {
      if (newEditableUriPartvalue !== editableUriPart) {
        setIsAutogenerated(false);

        // if the user clears the editable part, interpret this as them
        // wanting to clear out the entire uri
        if (!newEditableUriPartvalue) {
          onChange("");
        } else {
          onChange(nonEditableUriPart + newEditableUriPartvalue);
        }
      }
    },
    [onChange, editableUriPart, nonEditableUriPart]
  );

  let [nonEditableTextRef, setNonEditableTextRef] =
    useState<HTMLSpanElement | null>(null);
  let [textContainerRef, setTextContainerRef] =
    useState<HTMLSpanElement | null>(null);
  let [compactActions, setCompactActions] = useState(true);

  let nonEditableTextWidth =
    useResizeEffect(
      nonEditableTextRef,
      (nonEditableText) => nonEditableText.clientWidth,
      []
    ) ?? 0;

  let textContainerWidth =
    useResizeEffect(
      textContainerRef,
      (textContainer) => textContainer.clientWidth,
      []
    ) ?? 0;

  useLayoutEffect(() => {
    let editableWidth = textContainerWidth - nonEditableTextWidth;
    if (editableWidth < MINIMUM_EDITABLE_WIDTH_PX) {
      setCompactActions(true);
    } else if (editableWidth > MINIMUM_EDITABLE_WIDTH_PX + THREE_BUTTONS_PX) {
      setCompactActions(false);
    }
  }, [nonEditableTextWidth, textContainerWidth]);

  const nonEditableText = useMemo(() => {
    let nonEditableText = hostname;
    if (nonEditableUriPart.length > 0) {
      nonEditableText += `${nonEditableUriPart}`;
    }
    return nonEditableText;
  }, [hostname, nonEditableUriPart]);

  return (
    <Wrapper {...props}>
      <ThemeProvider
        tint="purple"
        transform={(theme, purpleify) =>
          autofilledTheme(theme, purpleify, isAutogenerated)
        }
      >
        <UriInputBox $locked={locked} $focused={focused}>
          <TextContainer ref={setTextContainerRef} $locked={locked}>
            {locked ? (
              <>
                <NonEditableText ref={setNonEditableTextRef}></NonEditableText>
                <EditableText
                  id={id}
                  $indentSize={0}
                  aria-disabled={true}
                  aria-labelledby={props["aria-labelledby"]}
                >
                  {nonEditableText}
                  {editableUriPart}
                </EditableText>
              </>
            ) : (
              <>
                <NonEditableText ref={setNonEditableTextRef}>
                  {nonEditableText}
                </NonEditableText>
                <EditableText
                  id={id}
                  $indentSize={nonEditableTextWidth}
                  role="textbox"
                  aria-labelledby={props["aria-labelledby"]}
                  // @ts-expect-error "plaintext-only" isn't official
                  contentEditable={readonly ? false : "plaintext-only"}
                  suppressContentEditableWarning
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={(e) => {
                    setFocused(false);
                    let newEditableUriPartValue = slugify(
                      e.currentTarget.textContent || "",
                      {
                        allowAllLetterCharacters: allowUnicodeLetters,
                        allowUppercase,
                      }
                    ).toString();
                    spyOnChange(newEditableUriPartValue);
                  }}
                  onInput={() => {
                    setIsAutogenerated(false);
                  }}
                >
                  {editableUriPart}
                </EditableText>
              </>
            )}
          </TextContainer>
          <ActionsContainer>
            <Actions
              {...props}
              autoFill={autogeneratedValue ? autogenerate : undefined}
              id={`${id}-actions`}
              compact={!locked && compactActions}
            />
          </ActionsContainer>
        </UriInputBox>
        {showURIWarning && (
          <Toast type="error">
            <FormattedMessage
              defaultMessage="Changing the URL directory can cause SEO problems. Your suggested URL is: {suggestedValue}"
              values={{ suggestedValue: autogeneratedValue }}
            />
          </Toast>
        )}
      </ThemeProvider>
    </Wrapper>
  );
}

PublishUrlField.displayName = "PublishUrlField";

import { Spinner } from "@components";
import { useQuery } from "@apollo/client";
import {
  GetBrand,
  GetBrandVariables,
  GetConfigs,
  GetConfigsVariables,
  GetCurrentUser_currentUser,
  GetCurrentUser_currentUser_organizations,
} from "@types";
import { Route, Navigate, Routes } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import styled, { createGlobalStyle } from "styled-components";
import { NavBar } from "../NavBar";
import { MyProfile } from "./MyProfile";
import { MyTeam } from "./MyTeam";
import { UserEdit } from "./User/Edit";
import { UserCreate } from "./User/Create";
import { ContentCreate } from "./Create";
import { Dashboard } from "./Dashboard";
import { MediaUploadPage } from "./Create/Photos";
import { Form } from "./Form";
import { config, MEDIA_MAP } from "@lib";
import { Queries } from "@gql";
import { useV1Redirect } from "@hooks";
import { LiveStoryUpdatePage } from "../LiveStoryUpdatePage";
import { SearchPage } from "./Search";
import { ErrorPage } from "../ErrorPage";
import { useToast } from "@hooks";
import { useReactiveVar } from "@apollo/client";
import { useIntl } from "react-intl";

import { Variables } from "@gql";
import { BundleCreate } from "./Create/Bundles";

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.main`
  background: ${(props) => props.theme.PrimaryDisabledBackground};
`;

const PageWithNav = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
`;

const EditorButtonElevation = createGlobalStyle`
  body .ck-body-wrapper .ck-body .ck-block-toolbar-button {
    z-index: ${(props) => props.theme.ElevationAboveModal};
  }
}`;

export function Main(props: {
  currentUser: GetCurrentUser_currentUser;
  currentOrganization: GetCurrentUser_currentUser_organizations;
}) {
  const { currentUser, currentOrganization } = props;
  const intl = useIntl();
  const userId = currentUser.id;

  const [navRef, setNavRef] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    const navHeight = navRef?.offsetHeight;
    const rootScrollingElement = (document.scrollingElement ||
      document.documentElement) as HTMLElement | null;
    const inlineScrollPaddingTop = rootScrollingElement?.style.scrollPaddingTop;

    if (rootScrollingElement && navHeight) {
      const computedScrollPaddingTop =
        parseInt(getComputedStyle(rootScrollingElement).scrollPaddingTop, 10) ||
        0;
      rootScrollingElement.style.scrollPaddingTop = `${
        computedScrollPaddingTop + navHeight
      }px`;
    }

    return () => {
      if (rootScrollingElement) {
        rootScrollingElement.style.scrollPaddingTop =
          inlineScrollPaddingTop ?? "";
      }
    };
  }, [navRef]);

  const redirectLoading = useV1Redirect();

  let { data: configs, loading: configsLoading } = useQuery<
    GetConfigs,
    GetConfigsVariables
  >(Queries.GET_CONFIGS, {
    variables: {
      organizationId: currentOrganization.organizationId,
      userId,
    },
  });
  let { data: brand, loading: brandLoading } = useQuery<
    GetBrand,
    GetBrandVariables
  >(Queries.GET_BRAND, {
    variables: {
      organizationId: currentOrganization.organizationId,
    },
  });
  let contentTypes = brand?.brandConfiguration?.contentTypes ?? [];
  let prideLogo = configs?.configs.prideLogo ?? undefined;
  let commerceProductUrl = configs?.configs.enableCommerceProduct
    ? configs?.configs.commerceProductUrl
    : "";
  let v2VersoChannel = configs?.configs.v2VersoChannel;

  let trainingSignupLink: string | undefined;
  switch (currentOrganization?.metadata.copilotRegion) {
    case "eu":
      trainingSignupLink =
        "https://docs.google.com/forms/d/e/1FAIpQLScflCRtf5PyFmeNN8d_mOcCCjPafi5p6Pefu8Dt9JJjQDhs1g/viewform";
      break;
    case "us":
      trainingSignupLink =
        "https://docs.google.com/forms/d/e/1FAIpQLSek7SyJQWYz6rt7u2kpqU68GmuYsXxQUV-0zZ8GRzDXmQkZ5g/viewform";
      break;
  }
  let supportEmail =
    (currentOrganization?.companyId ?? "cn") === "cn"
      ? "formation-support@condenast.com"
      : "support@condenastint.com";

  let { boot, shutdown } = useIntercom();

  useEffect(() => {
    if (config.environment === "production") {
      boot({
        email: currentUser.email,
        userId: currentUser.id,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        company: {
          companyId: currentOrganization?.metadata.copilotCode,
          name: currentOrganization?.metadata.copilotCode,
        },
        customAttributes: {
          username: currentUser.userName,
          role: currentUser.role,
        },
      });
      return () => shutdown();
    }

    return;
  }, [
    currentUser,
    currentOrganization,
    currentOrganization?.metadata.copilotCode,
    boot,
    shutdown,
  ]);

  let isAuthenticated = useReactiveVar(Variables.isAuthenticatedVar);

  const showSessionExpiredErrorMessage = useToast({
    type: "error",
    details: intl.formatMessage({
      defaultMessage: `It looks like you've been logged out. Click to sign in from a new tab. Your work will be ready to post when you come back!`,
    }),
    children: intl.formatMessage({
      defaultMessage: "Login Session expired.",
    }),
    action: {
      linkProps: {
        to: `${
          config.environment === "development"
            ? "https://stg-copilot-app.condenast.io"
            : `${window.location.protocol}//${window.location.hostname}`
        }/auth/login`,
        target: "_blank",
        onClick: () => {
          Variables.isAuthenticatedVar(true);
        },
      },
      message: intl.formatMessage({
        defaultMessage: "Sign In",
      }),
    },
    onClose: () => {
      Variables.isAuthenticatedVar(true);
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      showSessionExpiredErrorMessage();
    }
  }, [isAuthenticated, showSessionExpiredErrorMessage]);

  return brandLoading || configsLoading ? (
    <SpinnerWrapper>
      <Spinner size="large" />
    </SpinnerWrapper>
  ) : (
    <PageWithNav>
      <NavBar
        ref={setNavRef}
        currentOrganization={currentOrganization}
        currentUser={currentUser}
        contentTypes={contentTypes}
        hostname={configs?.configs.copilotUrl}
        organizations={currentUser.organizations}
        trainingSignupLink={trainingSignupLink}
        enablePresets={configs?.configs.presets}
        prideLogo={prideLogo}
        supportEmail={{
          to: supportEmail,
          subject: "Support Issue",
          body: `User: ${props?.currentUser.userName}\nBrand: ${currentOrganization?.metadata.copilotCode}`,
        }}
        commerceProductUrl={commerceProductUrl || null}
        v2VersoChannel={v2VersoChannel}
      />
      <Wrapper>
        <Routes>
          <Route
            path="/"
            element={
              <Dashboard
                user={currentUser}
                currentOrganization={currentOrganization}
                config={configs}
              />
            }
          />
          <Route
            path="dashboard"
            element={
              <Dashboard
                user={currentUser}
                currentOrganization={currentOrganization}
                config={configs}
              />
            }
          />
          <Route
            path="users"
            element={<MyTeam currentOrganization={currentOrganization} />}
          />
          <Route
            path="users/profile"
            element={<MyProfile user={currentUser} />}
          />
          <Route
            path={`users/${userId}/edit`}
            element={
              <Navigate
                replace
                to={`/${currentOrganization?.metadata.copilotCode}/users/profile`}
              />
            }
          />
          <Route
            path="users/:userId/edit"
            element={
              <UserEdit
                currentOrganization={currentOrganization}
                currentUser={currentUser}
              />
            }
          />
          <Route
            path="users/create"
            element={
              <UserCreate
                currentOrganization={currentOrganization}
                currentUser={currentUser}
              />
            }
          />
          <Route
            path="photos/create"
            element={
              <MediaUploadPage
                currentUser={currentUser}
                currentOrganization={currentOrganization}
                loading={redirectLoading}
                allowedMediaTypes={[...MEDIA_MAP.photos, ...MEDIA_MAP.clips]}
                displayLabel="Upload Photos and Clips"
              />
            }
          />
          <Route
            path="cartoons/create"
            element={
              <MediaUploadPage
                currentUser={currentUser}
                currentOrganization={currentOrganization}
                loading={redirectLoading}
                allowedMediaTypes={[...MEDIA_MAP.cartoons]}
                displayLabel="Cartoons"
              />
            }
          />
          <Route
            path={`:contentType/create`}
            element={
              <ContentCreate
                currentUser={currentUser}
                currentOrganization={currentOrganization}
                loading={redirectLoading}
              />
            }
          />
          <Route
            path={`/pages/:bundleType/create`}
            element={
              <BundleCreate
                currentUser={currentUser}
                currentOrganization={currentOrganization}
                loading={redirectLoading}
              />
            }
          />
          <Route
            path={`livestories/:id/livestoryupdates/*`}
            element={
              <LiveStoryUpdatePage
                currentUser={currentUser}
                currentOrganization={currentOrganization}
                hostname={configs?.configs.copilotUrl}
              />
            }
          />
          <Route
            path={`:contentType/:id`}
            element={
              <Form
                currentUser={currentUser}
                contentTypes={contentTypes}
                seoConfig={brand?.brandConfiguration.seo ?? undefined}
                currentOrganization={currentOrganization}
                linkAutogen={configs?.configs.ckeditorLinkAutogen}
                linkAutogenConfig={configs?.configs.linkAutogenConfig}
              />
            }
          />
          <Route
            path={`pages/:bundleType/:id`}
            element={
              <Form
                currentUser={currentUser}
                contentTypes={contentTypes}
                seoConfig={brand?.brandConfiguration.seo ?? undefined}
                currentOrganization={currentOrganization}
                linkAutogen={configs?.configs.ckeditorLinkAutogen}
                linkAutogenConfig={configs?.configs.linkAutogenConfig}
              />
            }
          />
          <Route
            path={`/search`}
            element={
              <SearchPage
                user={currentUser}
                currentOrganization={currentOrganization}
              />
            }
          />
          <Route path="*" element={<ErrorPage />} />
        </Routes>

        <EditorButtonElevation />
      </Wrapper>
    </PageWithNav>
  );
}
Main.displayName = "Main";

import { UserRole } from "@types";
import {
  validate,
  ValidationError,
  ObjectModel,
  Task,
} from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required, email } from "@condenast/copilot-validators";

const ENV: ObjectModel = {
  get(object: unknown, key: string): unknown {
    if (object === null || object === undefined) return;
    return (object as { [key: string]: unknown })[key];
  },

  asList(object: unknown): Array<unknown | null> {
    if (Array.isArray(object)) {
      return object;
    } else {
      return [];
    }
  },
};

const { object } = validators;

const userValidators = object({
  firstName: required(),
  lastName: required(),
  email: required().andThen(email()),
  role: required(),
  loginProvider: required(),
});

export function validateUser(user: {
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  loginProvider: "copilot" | "okta";
  brandCodes: string[];
}): Task<ValidationError[]> {
  return validate(user, build(userValidators), null, ENV);
}

import { Checkbox as CheckboxImplementation, Field } from "@components";
import {
  ControlProps,
  FormFor_form_controls_CheckboxFormControl,
} from "@types";
import { useDefinedMessages, useUniqueId } from "@hooks";
import { get } from "@lib";
import { useCallback } from "react";

import styled from "styled-components";

const Wrapper = styled(Field)`
  display: flex;
  flex-direction: row;
  font-weight: normal;
`;

export function Checkbox(
  props: ControlProps<FormFor_form_controls_CheckboxFormControl>
) {
  const { name, setValue, model } = props;
  const uniqueId = useUniqueId();
  const { translateFieldName } = useDefinedMessages();

  const value = (get(model, name) as boolean | null) ?? false;

  const handleChange = useCallback(
    (val: boolean) => {
      setValue(name, val);
    },
    [name, setValue]
  );

  return (
    <Wrapper id={`MultiSelect-${uniqueId}`} label={translateFieldName(name)}>
      <CheckboxImplementation checked={value} onChange={handleChange} />
    </Wrapper>
  );
}
Checkbox.displayName = "Control(Checkbox)";

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { length, required, url } from "@condenast/copilot-validators";

import type { ValidationContext, ValidationEnvironment } from "@lib";
import type { FormFor_content_Gallery } from "@types";
import { edgesHaveNodes, publishURI } from "../validators";
import { noEmptyAccordionHeaders } from "../validators/markdown-accordions";

const { object } = validators;

const nativeGalleryValidators = object({
  hed: required(),
  body: object({
    content: noEmptyAccordionHeaders(),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  photosTout: object({
    edges: length({ min: 1 }),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  socialTitle: required().on("publish"),
  seo: object({
    description: required(),
    title: required(),
  }).on("publish"),
  items: edgesHaveNodes(),
  advertiser: object({
    edges: length({ min: 1 }),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  campaignUrl: url().on("publish"),
  bylineOption: required().on("publish"),
  bylineVariant: required().on("publish"),
}).andAlso(publishURI().on("publish"));

export default function validateNativeGallery(
  gallery: FormFor_content_Gallery,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(gallery, build(nativeGalleryValidators), context, env);
}

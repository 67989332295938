import styled from "styled-components";
import { ListFooter } from "./ListFooter";
import { ListHeader } from "./ListHeader";
import { ResultList } from "./ResultList";
import { Card, DateFilter } from "@components";
import {
  SearchFilters,
  Organization,
  DashboardRefresh,
  PageDetail,
  DashboardFilter,
  GetConfigs,
  GetCurrentUser_currentUser,
} from "@types";
import { useCallback, useState } from "react";
import { addDays, addHours, endOfDay, startOfDay } from "date-fns";
import moment from "moment";

const Section = styled(Card).attrs({ as: "section" })<{ $order: number }>`
  margin: 0;
  margin-block-start: var(--spacing-md);
  padding: var(--spacing-xxs) 0 0;
  min-height: 544px;
  position: relative;
  height: fit-content;

  @media (max-width: 768px) {
    margin: 0;
    margin-top: var(--spacing-sm);
    border-radius: var(--spacing-xxs);
    ${(props) => props.$order && `order: ${props.$order};`}
  }
  @media (max-width: 600px) {
    border-radius: unset;
    margin: 0;
  }
`;

type HeaderInfoType = {
  info: boolean;
  message: string;
};

const defaultPagination = {
  limit: 10,
  offset: 0,
  totalCount: 0,
} as PageDetail;

type SearchStoreFilter = SearchFilters & {
  "store-datefilter-type"?: string;
  "store-datefilter-label"?: string;
};

const setQueryForDateFilters = (storedFilters: SearchStoreFilter) => {
  let data = storedFilters;
  let filterType = data["store-datefilter-type"] || "Today";
  let dateFilters = {};
  if (filterType) {
    if (
      filterType === "Custom" &&
      data.publishdate_from &&
      data.publishdate_to
    ) {
      dateFilters = {
        publishdate_from: moment(
          startOfDay(new Date(data.publishdate_from))
        ).utc(),
        publishdate_to: moment(endOfDay(new Date(data.publishdate_to))).utc(),
        "store-datefilter-label":
          new Date(data.publishdate_from).toDateString() ===
          new Date(data.publishdate_to).toDateString()
            ? new Date(data.publishdate_from).toDateString()
            : new Date(data.publishdate_from).toDateString() +
              " - " +
              new Date(data.publishdate_to).toDateString(),
      };
    } else if (
      filterType === "leftRightArrow" &&
      data.publishdate_from &&
      data.publishdate_to
    ) {
      dateFilters = {
        publishdate_from: moment(
          startOfDay(new Date(data.publishdate_from))
        ).utc(),
        publishdate_to: moment(endOfDay(new Date(data.publishdate_to))).utc(),
        "store-datefilter-label": new Date(
          data.publishdate_from
        ).toDateString(),
      };
    } else if (filterType === "Today") {
      dateFilters = {
        publishdate_from: moment(startOfDay(new Date())).utc(),
        publishdate_to: moment(endOfDay(new Date())).utc(),
        "store-datefilter-label": new Date().toDateString(),
      };
    } else if (filterType === "Next Hour") {
      dateFilters = {
        publishdate_from: moment(new Date()).utc(),
        publishdate_to: moment(addHours(new Date(), 1)).utc(),
        "store-datefilter-label": filterType,
      };
    } else if (filterType === "Past Hour") {
      dateFilters = {
        publishdate_from: moment(addHours(new Date(), -1)).utc(),
        publishdate_to: moment(new Date()).utc(),
        "store-datefilter-label": filterType,
      };
    } else if (filterType === "Next 24 Hours") {
      dateFilters = {
        publishdate_from: moment(new Date()).utc(),
        publishdate_to: moment(addDays(new Date(), 1)).utc(),
        "store-datefilter-label": filterType,
      };
    } else if (filterType === "Past 24 Hours") {
      dateFilters = {
        publishdate_from: moment(addDays(new Date(), -1)).utc(),
        publishdate_to: moment(new Date()).utc(),
        "store-datefilter-label": filterType,
      };
    } else if (filterType === "Next 7 Days") {
      dateFilters = {
        publishdate_from: moment(new Date()).utc(),
        publishdate_to: moment(addDays(new Date(), 7)).utc(),
        "store-datefilter-label": filterType,
      };
    } else if (filterType === "Past 7 Days") {
      dateFilters = {
        publishdate_from: moment(addDays(new Date(), -7)).utc(),
        publishdate_to: moment(new Date()).utc(),
        "store-datefilter-label": filterType,
      };
    }
  }
  return {
    ...storedFilters,
    "store-datefilter-type": filterType,
    ...dateFilters,
  };
};

export const DashboardList = (props: {
  label: string;
  headerInfo?: HeaderInfoType | undefined;
  headerFilter: boolean;
  queryFilter: SearchStoreFilter;
  currentOrganization: Organization;
  paginationInfo: boolean;
  filterType: string;
  refreshAPIs: DashboardRefresh;
  setRefreshAPIs: (status: DashboardRefresh) => void;
  defaultFilters: DashboardFilter;
  currentUser: GetCurrentUser_currentUser;
  cssOrder: number;
  config?: GetConfigs | undefined;
  emptySearchResultMessage: string;
}) => {
  let {
    label,
    currentOrganization,
    headerInfo,
    queryFilter,
    headerFilter,
    paginationInfo,
    filterType,
    refreshAPIs,
    setRefreshAPIs,
    defaultFilters,
    currentUser,
    cssOrder,
    config,
  } = props;
  const [searchQueryFilter, setSearchQueryFilter] = useState(
    filterType === "scheduled"
      ? setQueryForDateFilters(queryFilter)
      : queryFilter
  );
  const [currentPageDetail, setCurrentPageDetail] = useState(defaultPagination);

  const handleDateChange = (start: Date, end: Date, type: string) => {
    setCurrentPageDetail(defaultPagination);
    updateSearchQueryFilter(
      {
        ...searchQueryFilter,
        publishdate_from: moment(start).toISOString(),
        publishdate_to: moment(end).toISOString(),
      },
      type
    );
  };

  const updateCurrentPageDetail = useCallback(
    (pageDetail: PageDetail) => {
      let { totalCount, limit, offset } = currentPageDetail;
      if (
        totalCount !== pageDetail.totalCount ||
        limit !== pageDetail.limit ||
        offset !== pageDetail.offset
      ) {
        setCurrentPageDetail(pageDetail);
      }
    },
    [currentPageDetail, setCurrentPageDetail]
  );

  const setLocalStorage = (filter: SearchFilters) => {
    let storedFilters = localStorage.getItem(`filters-${filterType}`);
    let filters = {
      [currentOrganization.metadata.copilotCode]: {
        ...filter,
      },
    };
    if (storedFilters) {
      filters = {
        ...JSON.parse(storedFilters),
        ...filters,
      };
    }
    localStorage.setItem(`filters-${filterType}`, JSON.stringify(filters));
  };

  const handleFilterChange = (
    filter: SearchFilters & { "store-datefilter-type"?: string }
  ) => {
    updateSearchQueryFilter(filter, filter["store-datefilter-type"]);
  };

  const updateSearchQueryFilter = (
    filter: SearchFilters,
    dateFilterType?: string
  ) => {
    let updateDateFilters =
      filterType === "scheduled"
        ? setQueryForDateFilters({
            ...filter,
            "store-datefilter-type": dateFilterType,
          })
        : filter;
    setLocalStorage(updateDateFilters);
    setSearchQueryFilter(updateDateFilters);
  };

  let filters = Object.keys(searchQueryFilter)
    .filter((key) => !key.startsWith("store-"))
    .reduce(
      (output, k) => ({
        ...output,
        [k]: searchQueryFilter[k as keyof SearchFilters],
      }),
      {} as SearchFilters
    );

  return (
    <Section $order={cssOrder}>
      <ListHeader
        label={label}
        headerInfo={headerInfo}
        headerFilter={headerFilter}
        updateSearchQueryFilter={handleFilterChange}
        preselectedFilters={searchQueryFilter}
        filterType={filterType}
        organizationId={currentOrganization.organizationId ?? null}
        defaultFilters={defaultFilters}
        currentUser={currentUser}
        config={config}
      />
      {filterType == "scheduled" && (
        <DateFilter
          handleDateChange={handleDateChange}
          preSelectedDates={
            filters.publishdate_from && filters.publishdate_to
              ? {
                  start: filters.publishdate_from,
                  end: filters.publishdate_to,
                  label: searchQueryFilter["store-datefilter-label"],
                }
              : undefined
          }
        />
      )}
      <ResultList
        cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
        updateCurrentPageDetail={(pageDetail: PageDetail) =>
          updateCurrentPageDetail(pageDetail)
        }
        refreshAPIs={refreshAPIs}
        setRefreshAPIs={setRefreshAPIs}
        filterType={filterType}
        variables={{
          organizationId: currentOrganization.organizationId ?? "",
          limit: currentPageDetail.limit,
          offset: currentPageDetail.offset,
          filters: filters,
        }}
      />

      <ListFooter
        paginationInfo={paginationInfo}
        pageDetail={currentPageDetail}
        updateCurrentPageDetail={updateCurrentPageDetail}
        queryFilter={filters}
        searchQueryFilter={searchQueryFilter}
        brandCode={currentOrganization.metadata.copilotCode}
      />
    </Section>
  );
};

DashboardList.displayName = "DashboardList";

import { Toast as ContextualInfoImplementation } from "./Toast";
import {
  ControlProps,
  FormFor_form_controls_ContextualInfoFormControl,
} from "@types";
import { FormattedMessage } from "react-intl";

export function ContextualInfo(
  props: ControlProps<FormFor_form_controls_ContextualInfoFormControl>
) {
  let content = { defaultMessage: props.content.defaultMessage, id: 1 };

  return (
    <ContextualInfoImplementation type={props.toastType}>
      <FormattedMessage {...content} />
    </ContextualInfoImplementation>
  );
}
ContextualInfo.displayName = "Control(ContextualInfo)";

import {
  BuildSwitcher,
  CSSReset,
  CSSVariables,
  Spinner,
  ToastDrawer,
  withCurrentUser,
  withAuthorizedCurrentOrganization,
} from "@components";
import {
  IntlProvider,
  ThemeProvider,
  SocketIOProvider,
  SnowplowProvider,
} from "@contexts";
import { config } from "@lib";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useMatch,
} from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { ReactNode } from "react";
import { ApolloProvider } from "@apollo/client";
import { GQLClient } from "@gql";

import { Preview } from "./Preview";
import { Main } from "./Main";
import { IntercomProvider } from "react-use-intercom";
import { PageLayoutPage } from "./PageLayoutPage";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const AuthenticatedPreview = withCurrentUser(
  withAuthorizedCurrentOrganization(Preview)
);
const AuthenticatedMain = withCurrentUser(
  withAuthorizedCurrentOrganization(Main)
);
const AuthenticatedPageLayoutPage = withCurrentUser(
  withAuthorizedCurrentOrganization(PageLayoutPage)
);

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Background = createGlobalStyle`
#application {
  background: ${(props) => props.theme.SurfaceColor};
}`;

const RouteThemeProvider = (props: { children: ReactNode }) => {
  let match = useMatch("/:copilotCode/preview/:collection/:id");
  return (
    <ThemeProvider theme={match ? "dark" : "light"}>
      {props.children}
    </ThemeProvider>
  );
};

const APP_ID = config.applicationId as string | undefined;
const ORG_ID = config.organizationId as string | undefined;

export const App = () => {
  let INTERCOM_APP_ID = "i38zvy17";

  return (
    <DndProvider backend={HTML5Backend}>
      <ApolloProvider client={GQLClient}>
        <IntlProvider locale="en-GB">
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <SocketIOProvider>
              <CSSReset />
              <CSSVariables />
              <Router>
                <RouteThemeProvider>
                  <SnowplowProvider>
                    <Routes>
                      <Route
                        path=":copilotCode/preview/:collection/:id"
                        element={
                          <AuthenticatedPreview
                            fallback={
                              <SpinnerWrapper>
                                <Spinner size="large" />
                              </SpinnerWrapper>
                            }
                          />
                        }
                      />
                      <Route
                        path=":copilotCode/pageLayout"
                        element={
                          <AuthenticatedPageLayoutPage
                            fallback={
                              <SpinnerWrapper>
                                <Spinner size="large" />
                              </SpinnerWrapper>
                            }
                          />
                        }
                      />
                      <Route
                        path=":copilotCode/*"
                        element={
                          <AuthenticatedMain
                            fallback={
                              <SpinnerWrapper>
                                <Spinner size="large" />
                              </SpinnerWrapper>
                            }
                          />
                        }
                      />
                    </Routes>
                  </SnowplowProvider>
                  <Background />
                  <ToastDrawer />
                  {APP_ID && ORG_ID && (
                    <BuildSwitcher
                      applicationId={APP_ID}
                      organizationId={ORG_ID}
                    />
                  )}
                </RouteThemeProvider>
              </Router>
              <div id="asset-selector"></div>
              <div id="image-editor"></div>
            </SocketIOProvider>
          </IntercomProvider>
        </IntlProvider>
      </ApolloProvider>
    </DndProvider>
  );
};

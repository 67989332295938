import type { PublishHelper } from "../types";
import type { TagpageFields_query } from "@types";

/**
 * Get the slug of the first category in the query
 *
 * Ex: if the categories is [
 * {
 *  "name": "5 Ingredients or Fewer",
 *  "slug": "5-ingredients-or-fewer"
 * },
 * {
 *  "name": "Simple Cooking",
 *  "slug": "simple-cooking"
 * },
 * {
 *  "name": "Tags",
 *  "slug": "tags"
 * }
 * ]
 * /5-ingredients-or-fewer
 *
 * @param model
 * @returns string | null
 */

export const queryCategory: PublishHelper = (model) => {
  const query = model["query"] as TagpageFields_query | null;
  const slug_name = query?.filters?.categories?.[0]?.slug || "";
  return decodeURI(slug_name) || null;
};

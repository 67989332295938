import { useDefinedMessages } from "@hooks";
import { get } from "@lib";
import type { InfoPageFields_allContributors_edges as allContributorsType } from "@types";
import { groupBy } from "lodash";
import { AutogenHelper } from "../types";

/**
 * Returns the names of all contributors, grouped by
 * their contributor type, and with some introductory text.
 *
 * Ex: if contributors is
 * [
 *  { type: "PHOTOGRAPHER", node: { name: "Eliza" } },
 *  { type: "PHOTOGRAPHER", node: { name: "Rebecca" } },
 *  { type: "PHOTOGRAPHER", node: { name: "Carmen" } },
 *  { type: "PORTRAITIST", node: { name: "Jonathan" } },
 * ]
 *
 * it will return "Photographs by Eliza, Rebecca and Carmen,
 * Portraits by Jonathan"
 *
 * @param model
 * @returns string | null
 */
export const useAllContributors: AutogenHelper = (model) => {
  let contributors = get(model, "allContributors.edges") as
    | allContributorsType[]
    | null;

  let groupedContributors = groupBy(contributors, "type");

  const { translateContributorCredit } = useDefinedMessages();

  return Object.entries(groupedContributors)
    .map(([contributorType, items]) => {
      let contributorNames = items.map((item) => item.node?.name ?? "");
      return translateContributorCredit(contributorType, contributorNames);
    })
    .join(", ");
};

import {
  Select as SelectImplementation,
  TextField as TextFieldImplementation,
} from "@components";
import { useDefinedMessages } from "@hooks";
import {
  ControlProps,
  FormFor_form_controls_OfferPriceFormControl,
} from "@types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { get } from "@lib";
import styled from "styled-components";
import { Row } from "../Row";

const FlexibleRow = styled(Row)`
  > * {
    flex: 1 1 calc(10 * var(--spacing-xs));
  }
`;

const COUNTRY_CURRENCY_MAP: Record<string, string> = {
  US: "USD",
  GB: "GBP",
};

export function OfferPrice(
  props: ControlProps<FormFor_form_controls_OfferPriceFormControl>
) {
  const { model, setValue, errors, countryOptions, currencyOptions } = props;
  const intl = useIntl();
  const { translateFieldName, translateSelectOption } = useDefinedMessages();

  const defaultCurrency = currencyOptions?.defaultValue ?? undefined;
  const countryCode = (get(model, "countryCode") as string | null) ?? undefined;
  const defaultCountryCode = countryOptions?.defaultValue ?? undefined;
  const currency = get(model, "currency") as string | null;
  const price = get(model, "price") as number | null;
  const [innerPrice, setInnerPrice] = useState<string>(
    price == null ? "" : `${price}`
  );
  const comparisonPrice = get(model, "comparisonPrice") as number | null;
  const [innerComparisonPrice, setInnerComparisonPrice] = useState<string>(
    comparisonPrice == null ? "" : `${comparisonPrice}`
  );

  useEffect(() => {
    setInnerPrice(price == null ? "" : `${price}`);
    setInnerComparisonPrice(
      comparisonPrice == null ? "" : `${comparisonPrice}`
    );
  }, [price, comparisonPrice]);

  const countryCodeLabel = translateFieldName("countryCode");
  const currencyLabel = translateFieldName("currency");
  const priceLabel = translateFieldName("price");
  const comparisonPriceLabel = translateFieldName("comparisonPrice");

  const onCountryCodeChange = useCallback(
    (value: string | undefined) => {
      setValue("countryCode", value ?? null);
      if (value) {
        const newCurrency = COUNTRY_CURRENCY_MAP[value] ?? null;
        if (newCurrency) {
          setValue("currency", newCurrency);
        }
      }
    },
    [setValue]
  );

  const onCurrencyChange = useCallback(
    (value: string | undefined) => setValue("currency", value ?? null),
    [setValue]
  );
  const onPriceChange = useCallback(
    (value: string) => {
      let numericValue: number | null = parseFloat(value);
      if (isNaN(numericValue)) {
        numericValue = null;
      }
      setValue("price", numericValue);
      setInnerPrice(numericValue == null ? "" : `${numericValue}`);
    },
    [setValue]
  );
  const onComparisonPriceChange = useCallback(
    (value: string) => {
      let numericValue: number | null = parseFloat(value);
      if (isNaN(numericValue)) {
        numericValue = null;
      }
      setValue("comparisonPrice", numericValue);
      setInnerComparisonPrice(numericValue == null ? "" : `${numericValue}`);
    },
    [setValue]
  );

  const transformedCountryOptions = useMemo(
    () =>
      (countryOptions?.options ?? []).map((countryCode) => ({
        label: translateSelectOption("countryCodes", countryCode),
        value: countryCode,
      })),
    [countryOptions, translateSelectOption]
  );

  const transformedCurrencyOptions = useMemo(
    () =>
      (currencyOptions?.options ?? []).map((currency) => ({
        label: translateSelectOption("currencyOptions", currency),
        value: currency,
      })),
    [currencyOptions, translateSelectOption]
  );

  const placeholder = intl.formatMessage({
    defaultMessage: "Select an Option",
    description: "Placeholder text for a Select component",
  });

  const currencyErrors = useMemo(
    () => errors.filter(({ path }) => path[0] === "currency"),
    [errors]
  );

  return (
    <FlexibleRow>
      {transformedCountryOptions.length > 0 && (
        <SelectImplementation
          id={`OfferPrice__countryCode`}
          label={countryCodeLabel}
          aria-label={countryCodeLabel}
          multiple={false}
          value={countryCode ?? defaultCountryCode}
          onChange={onCountryCodeChange}
          options={transformedCountryOptions}
          placeholder={placeholder}
        />
      )}
      <SelectImplementation
        id={`OfferPrice__currency`}
        label={currencyLabel}
        aria-label={currencyLabel}
        multiple={false}
        value={currency ?? defaultCurrency}
        onChange={onCurrencyChange}
        options={transformedCurrencyOptions}
        placeholder={placeholder}
        errors={currencyErrors}
      />
      <TextFieldImplementation
        id={`OfferPrice__price`}
        label={priceLabel}
        aria-label={priceLabel}
        onChange={setInnerPrice}
        onBlur={onPriceChange}
        value={innerPrice}
        inputMode={"decimal"}
        multiline={false}
      />
      <TextFieldImplementation
        id={`OfferPrice__comparisonPrice`}
        label={comparisonPriceLabel}
        aria-label={comparisonPriceLabel}
        onChange={setInnerComparisonPrice}
        onBlur={onComparisonPriceChange}
        value={innerComparisonPrice}
        inputMode={"decimal"}
        multiline={false}
      />
    </FlexibleRow>
  );
}

OfferPrice.displayName = "Control(OfferPrice)";

import { EditorInstance } from "@condenast/ckeditor5-build-condenast";
import { ValidationError } from "@condenast/cross-check";
import { useDefinedMessages, TaskPerContext } from "@hooks";
import { errorsForField } from "@lib";
import {
  FormFor_form_controls,
  FormPermissions,
  GetCurrentUser_currentUser,
  Organization,
} from "@types";
import { ReactElement, useMemo } from "react";
import * as Controls from "./Controls";
import { MissingControl } from "./MissingControl";

export function FormControl<Model>({
  controlId,
  controls,
  model,
  errors,
  setValue,
  setHasDeferredChanges,
  currentOrganization,
  currentUser,
  permissions,
  activeErrorPath,
  linkAutogen,
  linkAutogenConfigFields,
  addTask,
  removeTask,
  getBodyRefValue,
  setBodyRef,
}: {
  controlId: string;
  controls: FormFor_form_controls[];
  model: Model;
  errors: ValidationError[];
  setValue: <Key extends keyof Model>(key: Key, value: Model[Key]) => void;
  currentOrganization: Organization;
  currentUser: GetCurrentUser_currentUser;
  permissions: FormPermissions;
  linkAutogenConfigFields: string[];
  activeErrorPath?: readonly string[];
  linkAutogen: boolean;
  setHasDeferredChanges?: (hasDeferredChanges: boolean) => void;
  addTask?: (taskToAddPerContext: TaskPerContext) => void;
  removeTask?: (taskToRemovePerContext: TaskPerContext) => void;
  getBodyRefValue?: () => string;
  setBodyRef?: (bodyRef: React.MutableRefObject<EditorInstance | null>) => void;
}): ReactElement {
  const { translateFieldError } = useDefinedMessages();
  let control = controls.find((control) => control.id === controlId);
  let fieldName = (control as { name?: string } | undefined)?.name;
  const fieldErrors = useMemo(() => {
    let translatedErrors = errors.map((error) => ({
      ...error,
      message: translateFieldError(error.message),
      isActive: undefined,
    }));
    return fieldName
      ? errorsForField(translatedErrors, fieldName, activeErrorPath)
      : translatedErrors;
  }, [errors, fieldName, activeErrorPath, translateFieldError]);

  if (control == null) {
    return <span>No control found</span>;
  }
  let { __typename, id, children: childIds, ...options } = control;
  let componentName = __typename.replace(
    "FormControl",
    ""
  ) as keyof typeof Controls;
  let Control = Controls[componentName];
  let children = childIds
    ? childIds.map((childId) => (
        <FormControl
          key={childId}
          controlId={childId}
          controls={controls}
          model={model}
          errors={errors}
          setValue={setValue}
          setHasDeferredChanges={setHasDeferredChanges}
          addTask={addTask}
          removeTask={removeTask}
          currentOrganization={currentOrganization}
          currentUser={currentUser}
          permissions={permissions}
          activeErrorPath={activeErrorPath}
          linkAutogen={linkAutogen}
          linkAutogenConfigFields={linkAutogenConfigFields}
          getBodyRefValue={getBodyRefValue}
          setBodyRef={setBodyRef}
        />
      ))
    : [];

  if (Control) {
    return (
      // @ts-expect-error We may be passing more info here than necessary
      <Control
        key={id}
        id={`${__typename}-${id}`}
        model={model}
        errors={fieldErrors}
        setValue={setValue}
        setHasDeferredChanges={setHasDeferredChanges}
        addTask={addTask}
        removeTask={removeTask}
        currentOrganization={currentOrganization}
        currentUser={currentUser}
        permissions={permissions}
        linkAutogen={linkAutogen}
        linkAutogenConfigFields={linkAutogenConfigFields}
        getBodyRefValue={getBodyRefValue}
        setBodyRef={setBodyRef}
        {...options}
      >
        {children}
      </Control>
    );
  } else {
    return (
      <MissingControl control={control} key={id}>
        {children}
      </MissingControl>
    );
  }
}

FormControl.displayName = "FormControl";

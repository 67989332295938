import { isNotEqual } from "@condenast/copilot-validators";
import { ValidationError } from "@condenast/cross-check";
import { ValidationBuilder } from "@condenast/cross-check-dsl";

export function ifModelEquals(
  fieldName: string,
  fieldValue: string,
  validator: ValidationBuilder<unknown>
): ValidationBuilder<unknown> {
  return isNotEqual({ fieldName, fieldValue })
    .or(validator)
    .catch(
      (errors: any[]): ValidationError[] => errors[0].message.details?.[1]
    );
}

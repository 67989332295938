import type {
  FormFor_content_Contributor_socialMedia,
  SocialMediaInput,
} from "@types";

export function socialMediaItems(
  input?: FormFor_content_Contributor_socialMedia[]
): SocialMediaInput[] {
  const smItems: SocialMediaInput[] = [];
  input?.map((smObj: FormFor_content_Contributor_socialMedia) => {
    if (smObj.network?.trim() && smObj.handle?.trim()) {
      smItems.push({
        network: smObj.network.trim(),
        handle: smObj.handle.trim(),
      });
    }
  });
  return smItems;
}

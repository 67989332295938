import { useEffect, ReactNode } from "react";
import { useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { ARIA, UserMedallion } from "@components";
import { Queries, Mutations } from "@gql";
import { GetCurrentUser, LogOut, Organization } from "@types";
import { useMediaQuery } from "@hooks";
import { cookies, localstorage } from "@lib";

const StyledButton = styled(ARIA.MenuButton)`
  padding: var(--spacing-xxs);
  & + [role="menu"] {
    min-width: 15rem;
  }
` as typeof ARIA.MenuButton;

const StyledAvatarMenuIcon = styled(UserMedallion)`
  margin-right: var(--spacing-xs);
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
`;
const NameText = styled.p`
  font: ${(props) => props.theme.FontBody};
  line-height: 1.1rem;
`;
const SubText = styled.p`
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.SupportColor};
`;
const TextWrapper = styled.div`
  cursor: text;
`;
const ProfileMenuWrapper = styled.div`
  li:last-child {
    color: var(--color-red-30);

    &:focus,
    &:hover {
      background: var(--color-red-80);
    }
  }
`;

const getResolvedFullName = (firstName = "", lastName = "") => {
  return `${firstName} ${lastName}`.trim();
};

const RoleTranslations = defineMessages({
  contributor: {
    defaultMessage: "Contributor",
    description: "User role of contributor",
  },
  manager: {
    defaultMessage: "Manager",
    description: "User role of manager",
  },
  marketer: {
    defaultMessage: "Marketer",
    description: "User role of marketer",
  },
  admin: {
    defaultMessage: "Admin",
    description: "User role of administrator",
  },
  superadmin: {
    defaultMessage: "Super admin",
    description: "User role of superadmin",
  },
});

export function ProfileMenu(props: {
  hostname?: string;
  currentOrganization: Organization;
  className?: string;
}) {
  const { currentOrganization } = props;
  const intl = useIntl();
  const { data: authenticatedUser } = useQuery<GetCurrentUser>(
    Queries.GET_CURRENT_USER
  );

  const [logOut, { data, error }] = useMutation<LogOut>(Mutations.LOG_OUT);
  const size: "medium" | "regular" = useMediaQuery("(max-width: 500px)")
    ? "medium"
    : "regular";

  useEffect(() => {
    if (data?.logOut?.success) {
      cookies.remove("brand", "cp.username", "cp.role", "cp.userid");
      localstorage.remove("filters-scheduled", "filters-mywork");
      window.location.reload();
    } else if (error) {
      // TODO: handle failed log out request
    }
  }, [data, error]);

  // TODO: figure out support for international avatar display
  const resolvedFullName = getResolvedFullName(
    authenticatedUser?.currentUser?.firstName,
    authenticatedUser?.currentUser?.lastName
  );

  return (
    <ProfileMenuWrapper className={props.className}>
      <StyledButton<ReactNode>
        id="profile-menu"
        aria-label={intl.formatMessage({ defaultMessage: "Account" })}
        size={size}
        menu={{
          items: [
            {
              role: "item",
              value: (
                <AvatarWrapper>
                  <StyledAvatarMenuIcon
                    size="regular"
                    name={resolvedFullName}
                  />
                  <TextWrapper>
                    <NameText>{resolvedFullName}</NameText>
                    <SubText>
                      {authenticatedUser?.currentUser?.role &&
                        intl.formatMessage(
                          RoleTranslations[authenticatedUser?.currentUser.role]
                        )}
                    </SubText>
                  </TextWrapper>
                </AvatarWrapper>
              ),
            },
            { role: "separator" },
            {
              role: "link",
              to: `/${currentOrganization.metadata.copilotCode}/users/profile`,
              value: intl.formatMessage({ defaultMessage: "My Profile" }),
            },
            { role: "separator" },
            {
              role: "action",
              onClick: logOut,
              value: intl.formatMessage({ defaultMessage: "Log Out" }),
            },
          ],
          children: (item: ReactNode) => item,
        }}
      >
        <UserMedallion size={size} name={resolvedFullName} />
      </StyledButton>
    </ProfileMenuWrapper>
  );
}

import {
  RecipePreparationGroupFields,
  RecipePreparationGroupInput,
} from "@types";

export function preparationGroups(
  data: RecipePreparationGroupFields[]
): RecipePreparationGroupInput[] {
  return data.map(({ hed, steps, microSteps }) => ({
    hed: hed ?? undefined,
    steps:
      steps
        ?.filter((step) => step && step.description !== undefined)
        .map((step) => ({
          description: step ? step.description ?? "" : null,
        })) ?? [],
    microSteps:
      microSteps
        ?.filter((step) => step && step.description !== undefined)
        .map((step) => ({
          description: step ? step.description ?? "" : null,
        })) ?? [],
  }));
}

import { validate } from "@condenast/cross-check";
import build, {
  validators,
  ValidationBuilder,
} from "@condenast/cross-check-dsl";
import { url, required } from "@condenast/copilot-validators";

import type { Task, ValidationError } from "@condenast/cross-check";
import type { ValidationContext, ValidationEnvironment } from "@lib";
import type { FormFor_content_Product } from "@types";
import { publishURI } from "../validators";

const { object, items } = validators;

const productValidators = object({
  name: required(),
  offers: (
    items(
      object({
        offerType: required(),
        offerLink: required().andThen(url()),
        currency: required(),
      })
    ) as ValidationBuilder<unknown>
  ).catch((errors) => {
    return errors.map(({ path, message }) => ({
      path: path,
      message:
        message.name === "type" ? { name: "blank", details: null } : message,
    }));
  }),
}).andAlso(publishURI().on("publish"));

export default function validateProduct(
  product: FormFor_content_Product,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(product, build(productValidators), context, env);
}

import styled from "styled-components";
import { ThemeProvider } from "@contexts";
import { memo, useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useFocusTrap, useUniqueId, useScrollWhenDragging } from "@hooks";
import {
  BackIcon,
  CheckIcon,
  NoAssetIcon,
  EllipsisIcon,
  FullscreenIcon,
  CloseIcon,
} from "@condenast/gemini/icons";
import { Button, SearchResult, Takeover } from "@components";
import { FormattedMessage, useIntl } from "react-intl";
import { CuratedListItemData, CuratedListItemUpdateOperation } from "@types";
import { useDrag, useDrop } from "react-dnd";

const TileViewContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: ${(props) => props.theme.ElevationModal};
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
`;

const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-xs);
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
`;

const ActionTitle = styled.h3`
  font: ${(props) => props.theme.FontSubSectionHeading};
`;

const SelectAllButton = styled(Button)`
  margin-left: auto;
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  display: flex;
`;

const DropIndicator = styled.div`
  display: none;
  background-color: var(--color-blue-50);
  border-radius: calc(0.5 * var(--spacing-sm));
  width: calc(0.5 * var(--spacing-sm));
  height: 100%;
`;

const DropZone = styled.div`
  position: absolute;
  pointer-events: none;

  height: 100%;
  width: calc(50% + var(--spacing-sm) * 0.5);

  &.left {
    top: 0;
    left: calc(0% - var(--spacing-sm) * 0.5);

    ${DropIndicator} {
      position: absolute;
      left: calc(0% - var(--spacing-xxs));
    }
  }

  &.right {
    top: 0;
    left: calc(50%);

    ${DropIndicator} {
      position: absolute;
      left: calc(100% - var(--spacing-xxs));
    }
  }
`;

const TileItemList = styled.div`
  grid-area: item-list;
  position: relative;
  overflow-y: auto;
  height: 100vh;
  padding: var(--spacing-md);

  & ul {
    grid-template-columns: repeat(auto-fill, minmax(12.75rem, 1fr));
    gap: var(--spacing-sm);
  }

  &.asset-wrapper {
    max-width: 100%;
  }

  &.dragging {
    cursor: grabbing;
    ${DropZone} {
      pointer-events: auto;
    }
    ${DropZone}.hover ${DropIndicator} {
      display: block;
    }
  }
`;

const TileDragContainer = styled.li`
  position: relative;
  border-radius: ${(props) => props.theme.CornerRadius};
  width: 100%;
  height: 100%;
`;

const TileDragPlaceholder = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--color-blue-80);
  border: 2px solid var(--color-blue-50);
  border-radius: var(--spacing-xxs);
`;

const ResultSlatDiv = styled(Takeover.ResultSlat).attrs({ as: "div" })``;
const EmptyResultSlatDiv = styled(ResultSlatDiv)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: var(--spacing-sm);
  color: var(--color-gray-4);

  &:hover {
    background: ${(props) => props.theme.SkeletonColor};
  }

  .dragHandle {
    position: absolute;
    top: 0;
    left: 45%;
  }

  .styledIndexAndCheck {
    margin-left: auto;
  }

  .noAsset {
    position: absolute;
    left: 45%;
    top: 45%;
  }
`;

const SubmitBar = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.ElevationModal};
`;

type DragData = {
  index: number;
  item: CuratedListItemData & Pick<Required<CuratedListItemData>, "itemID">;
};

type CollectedData = {
  isDragging: boolean;
};

const DraggableTile = memo(function (
  props: {
    moveItem: (item: DragData, index: number) => void;
    onDragStart: () => void;
    onDragEnd: () => void;
    onMouseMove: (index: number) => void;
    onSelect: (itemID: string) => void;
    isSelectedResult: boolean;
    selectItemPrefix: string;
  } & DragData & { cdnHost?: string }
) {
  let {
    item,
    index,
    moveItem,
    onMouseMove,
    onDragStart,
    onDragEnd,
    onSelect,
    cdnHost,
    isSelectedResult,
    selectItemPrefix,
  } = props;
  let data = { item, index };
  let beforeRef = useRef<HTMLDivElement | null>(null);
  let afterRef = useRef<HTMLDivElement | null>(null);

  let [result, setResult] = useState(item.item);

  const [{ isDragging }, dragRef] = useDrag<DragData, DragData, CollectedData>(
    () => ({
      type: "SearchResult",
      item: () => data,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [data]
  );

  const [, dropBefore] = useDrop<DragData>(
    () => ({
      accept: "SearchResult",
      drop(item) {
        moveItem(item, index);
      },
    }),
    [index, moveItem]
  );

  const [, dropAfter] = useDrop<DragData>(
    () => ({
      accept: "SearchResult",
      drop(item) {
        moveItem(item, index + 1);
      },
    }),
    [index, moveItem]
  );

  /**
   * during drag and drop the browser calculates hover states and fires
   * mouse events kind of haphazardly. Additionally, scrolling causes
   * the cursor position to move without firing mouse events. Fortunately,
   * the drag-specific events are quite reliable--so we will need to use
   * those to calculate the hover states manually.
   */
  let captureBeforeEl = useCallback(
    (el: HTMLDivElement | null) => {
      beforeRef.current = el;
      dropBefore(el);
      if (el) {
        el.ondragover = () => beforeRef.current?.classList.add("hover");
        el.ondragleave = () => beforeRef.current?.classList.remove("hover");
        el.ondrop = () => beforeRef.current?.classList.remove("hover");
      }
    },
    [dropBefore]
  );

  let captureAfterEl = useCallback(
    (el: HTMLDivElement | null) => {
      afterRef.current = el;
      dropAfter(el);
      if (el) {
        el.ondragover = () => afterRef.current?.classList.add("hover");
        el.ondragleave = () => afterRef.current?.classList.remove("hover");
        el.ondrop = () => afterRef.current?.classList.remove("hover");
      }
    },
    [dropAfter]
  );

  useEffect(() => {
    let itemCopy = { ...item };
    if (itemCopy.hed && itemCopy.item) {
      itemCopy.item.title = {
        format: "markdown",
        content: itemCopy.hed,
      };
    }
    setResult(itemCopy.item);
  }, [item]);

  let itemNumber = index + 1;

  return (
    <TileDragContainer
      onDrag={onDragStart}
      onDragOver={onDragStart}
      onDragEnd={onDragEnd}
      onDrop={onDragEnd}
      ref={dragRef}
    >
      {isDragging ? (
        <TileDragPlaceholder />
      ) : result ? (
        <SearchResult
          as={ResultSlatDiv}
          id={`${selectItemPrefix}-${index}`}
          onMouseMove={() => {
            onMouseMove(index);
          }}
          onClick={() => {
            onSelect(item.itemID);
          }}
          aria-selected={isSelectedResult || undefined}
          result={result}
          treatment="asset-tile"
          cdnHost={cdnHost}
          draggable={true}
        >
          <Takeover.StyledIndexAndCheck>
            {isSelectedResult ? (
              <Takeover.StyledCheckIcon>
                <CheckIcon size="regular" />
              </Takeover.StyledCheckIcon>
            ) : (
              itemNumber
            )}
          </Takeover.StyledIndexAndCheck>
        </SearchResult>
      ) : (
        <EmptyResultSlatDiv
          onClick={() => {
            onSelect(item.itemID);
          }}
          aria-selected={isSelectedResult || undefined}
        >
          <EllipsisIcon className="dragHandle" size="regular" />
          <Takeover.StyledIndexAndCheck className="styledIndexAndCheck">
            {isSelectedResult ? (
              <Takeover.StyledCheckIcon>
                <CheckIcon size="regular" />
              </Takeover.StyledCheckIcon>
            ) : (
              itemNumber
            )}
          </Takeover.StyledIndexAndCheck>
          <NoAssetIcon className="noAsset" size="regular" />
        </EmptyResultSlatDiv>
      )}

      <DropZone ref={captureBeforeEl} className="left">
        <DropIndicator />
      </DropZone>
      <DropZone ref={captureAfterEl} className="right">
        <DropIndicator />
      </DropZone>
    </TileDragContainer>
  );
});

DraggableTile.displayName = "DraggableTile";

export const TileViewPanel = (props: {
  onClose: () => unknown;
  items: (CuratedListItemData &
    Pick<Required<CuratedListItemData>, "itemID">)[];
  cdnHost?: string;
  moveItem: (item: DragData, dropIndex: number) => void;
  onUpdate: (operations: CuratedListItemUpdateOperation[]) => void;
}) => {
  const { onClose, items, cdnHost, onUpdate, moveItem } = props;
  const intl = useIntl();

  const [selectedItemIDs, setSelectedItemIDs] = useState<string[]>([]);

  const clearSelectedItemIDs = useCallback(
    () => setSelectedItemIDs([]),
    [setSelectedItemIDs]
  );

  const [element, setElement] = useState<HTMLElement | null>(null);

  const [cursor, setCursor] = useState(0);
  const [cursorMode, setCursorMode] = useState<"mouse" | "keyboard">("mouse");

  const uid = useUniqueId();
  const selectItemPrefix = `select-item-${uid}`;

  const allSelected = useMemo(
    () => selectedItemIDs.length === items.length,
    [selectedItemIDs, items]
  );

  const onMouseMove = useCallback(
    (newCursor) => {
      setCursor(newCursor);
      setCursorMode("mouse");
    },
    [setCursor, setCursorMode]
  );

  useEffect(() => {
    if ("Intercom" in window) {
      Intercom("update", {
        hide_default_launcher: true,
      });
    }
    return () => {
      if ("Intercom" in window) {
        Intercom("update", {
          hide_default_launcher: false,
        });
      }
    };
  });

  const onSelect = useCallback(
    (itemIDToToggle: string) => {
      const index = selectedItemIDs.findIndex((selectedItemID) => {
        return selectedItemID === itemIDToToggle;
      });
      if (index >= 0) {
        setSelectedItemIDs([
          ...selectedItemIDs.slice(0, index),
          ...selectedItemIDs.slice(index + 1),
        ]);
      } else {
        setSelectedItemIDs([...selectedItemIDs, itemIDToToggle]);
      }
    },
    [selectedItemIDs, setSelectedItemIDs]
  );

  const toggleSelectAll = useCallback(() => {
    allSelected
      ? clearSelectedItemIDs()
      : setSelectedItemIDs(Array.from(items.map((item) => item.itemID)));
  }, [allSelected, clearSelectedItemIDs, setSelectedItemIDs, items]);

  const onActionAndSubmit = useCallback(
    (action?: "editCredit" | "delete") => {
      if (action == "delete") {
        const deleteOperations: CuratedListItemUpdateOperation[] =
          selectedItemIDs
            .reduce((itemsToDeleteByIndex: number[], selectedItemID) => {
              let itemIndexToDelete = items.findIndex(
                (item) => item.itemID === selectedItemID
              );
              if (itemIndexToDelete >= 0) {
                itemsToDeleteByIndex.push(itemIndexToDelete);
              }
              return itemsToDeleteByIndex;
            }, [])
            .sort()
            .reverse()
            .map((index) => [index, 1]);

        onUpdate(deleteOperations);
        setSelectedItemIDs([]);
        return;
      }
    },
    [items, selectedItemIDs, onUpdate]
  );

  useFocusTrap(element, onClose);

  const tileListRef = useRef<HTMLDivElement | null>(null);
  const reportDragStart = useCallback(
    () => tileListRef.current?.classList.add("dragging"),
    []
  );
  const reportDragEnd = useCallback(
    () => tileListRef.current?.classList.remove("dragging"),
    []
  );
  const scrollHandlers = useScrollWhenDragging(
    {
      dragZoneSize: 200,
      distancePerFrame: 100,
      msPerFrame: 120,
      scrollBehavior: "smooth",
    },
    tileListRef
  );

  return (
    <TileViewContainer ref={setElement}>
      <TitleGroup>
        <Button
          onClick={onClose}
          aria-label="Go Back"
          tabIndex={-1}
          aria-hidden="true"
        >
          <BackIcon size="regular" />
        </Button>
        <ActionTitle>
          {intl.formatMessage({
            defaultMessage: "Reorder Items",
            description: "Title for tile view panel",
          })}
        </ActionTitle>
        <SelectAllButton treatment="borderless" onClick={toggleSelectAll}>
          {allSelected ? (
            <>
              <CloseIcon size="regular" />
              <FormattedMessage
                defaultMessage="Deselect All"
                description="Label for button to deselect all items"
              />
            </>
          ) : (
            <>
              <FullscreenIcon size="regular" />
              <FormattedMessage
                defaultMessage="Select All"
                description="Label for button to select all items"
              />
            </>
          )}
        </SelectAllButton>
      </TitleGroup>
      <TileItemList ref={tileListRef} {...scrollHandlers}>
        <ThemeProvider theme="light">
          <Takeover.GridList
            tabIndex={0}
            aria-activedescendant={
              cursor !== null && cursor >= 0
                ? `${selectItemPrefix}-${cursor}`
                : undefined
            }
            cursorMode={cursorMode}
          >
            {items?.map((item, index) => {
              let isSelectedResult = selectedItemIDs.some((selectedItemID) => {
                return item.itemID === selectedItemID;
              });

              return (
                <DraggableTile
                  key={item.itemID}
                  index={index}
                  item={item}
                  cdnHost={cdnHost}
                  isSelectedResult={isSelectedResult}
                  onDragStart={reportDragStart}
                  onDragEnd={reportDragEnd}
                  moveItem={moveItem}
                  onMouseMove={onMouseMove}
                  onSelect={onSelect}
                  selectItemPrefix={selectItemPrefix}
                />
              );
            })}
          </Takeover.GridList>
        </ThemeProvider>
      </TileItemList>
      <SubmitBar>
        <Takeover.SubmitBar
          clearSelections={clearSelectedItemIDs}
          selectionCount={selectedItemIDs.length}
          onSubmit={onActionAndSubmit}
          action={["delete"]}
        />
      </SubmitBar>
    </TileViewContainer>
  );
};

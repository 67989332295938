import { ValidationError } from "@condenast/cross-check";
import {
  BasicValidator,
  ValidationBuilder,
  builderFor,
} from "@condenast/cross-check-dsl";

import { ParseAnnotation, is } from "@atjson/document";
import { GroupItem } from "@atjson/offset-annotations";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import VersoSource, { Group } from "@condenast/atjson-source-verso";

class MarkdownSlideshowsValidator extends BasicValidator<unknown> {
  static validatorName = "empty-slideshows";

  async validate(markdown: string): Promise<ValidationError[]> {
    const errors: ValidationError[] = [];

    const versoDoc =
      CopilotMarkdownSource.fromRaw(markdown).convertTo(VersoSource);

    const slideshows = versoDoc
      .where((a) => is(a, Group) && a.attributes.artDirection === "slideshow")
      .as("slideshow");

    let slideshowContents = slideshows.outerJoin(
      versoDoc.all().as("contents"),
      (slideshow, content) =>
        content.start >= slideshow.start &&
        content.end <= slideshow.end &&
        content !== slideshow &&
        !is(content, GroupItem) &&
        !is(content, ParseAnnotation)
    );

    slideshowContents.forEach(({ slideshow, contents }) => {
      if (contents.length === 0) {
        errors.push({
          path: [],
          message: { details: { slideshow }, name: "empty-slideshow" },
        });
      }
    });

    return errors;
  }
}

export function noEmptySlideshows(): ValidationBuilder<unknown> {
  return builderFor(MarkdownSlideshowsValidator)();
}

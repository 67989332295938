import { useLazyQuery, useQuery } from "@apollo/client";
import { Queries } from "@gql";
import { useDebouncedCallback, useDefinedMessages } from "@hooks";
import {
  ContentSummary,
  ContentSummaryFields,
  GetAssetSelectorConfig_configs_assetSelectorConfig as AssetSelectorConfig,
  GetBrand,
  GetBrandVariables,
  GetConfigs,
  GetConfigsVariables,
  GQLResponse,
  Search,
  SearchAdapter,
  SearchProvider,
  SearchVariables,
  MediaOverrideConnection,
  AssetSelectorSearchOptions,
  GetCurrentUser_currentUser,
  SearchFilters,
} from "@types";
import { normalizeContentSummary } from "@lib";
import { AssetSelector as AssetSelectorImplementation } from "../../../../AssetSelector";
import { useCallback, useMemo, useContext } from "react";
import { createPortal } from "react-dom";
import { transformDates } from "../../../../Main/Search/helpers";

import { createAssetSelectorAdapter } from "./adapters";
import { usePostProcessors } from "./postProcessors";
import { SnowplowContext } from "@contexts";

const SEARCH_DEBOUNCE_INTERVAL = 250; // milliseconds

export function AssetSelector(props: {
  onClose: () => void;
  currentOrganizationID: string;
  cdnHost: string;
  config: AssetSelectorConfig;
  onSubmit: (assets: ContentSummary[]) => void;
  currentUser: GetCurrentUser_currentUser;
  model?: Record<string, unknown> | undefined;
  setValue?: (
    name: string,
    updatedMediaoverrides: MediaOverrideConnection
  ) => void;
  disableReturnFocus?: boolean;
  enableVideoMediaOverrides?: boolean;
  initialSelections?: ContentSummaryFields[];
}) {
  const {
    onClose,
    onSubmit,
    currentOrganizationID,
    config,
    cdnHost,
    currentUser,
    model,
    setValue,
    disableReturnFocus,
    enableVideoMediaOverrides,
    initialSelections,
  } = props;
  const { translateContentType } = useDefinedMessages();
  const { trackContentEvent } = useContext(SnowplowContext);
  const portal = useMemo(() => {
    let container = document.querySelector("#asset-selector");
    if (container == null) {
      throw new Error("div#asset-selector is missing");
    }
    return container;
  }, []);

  let { data: brandConfigData } = useQuery<GetBrand, GetBrandVariables>(
    Queries.GET_BRAND,
    {
      variables: {
        organizationId: currentOrganizationID,
      },
      fetchPolicy: "cache-first",
    }
  );
  let { data: configData, loading: configLoading } = useQuery<
    GetConfigs,
    GetConfigsVariables
  >(Queries.GET_CONFIGS, {
    variables: {
      organizationId: currentOrganizationID,
      userId: currentUser.id,
    },
    fetchPolicy: "cache-first",
  });

  const dateRangeOptions = useMemo(() => {
    return configData?.configs?.searchFilterOptions?.dateRangeOptions ?? [];
  }, [configData]);

  const statusOptions = useMemo(() => {
    return configData?.configs?.searchFilterOptions.statusOptions ?? [];
  }, [configData]);

  const contentTypeConfigsForAdapter = useMemo(() => {
    return (
      brandConfigData?.brandConfiguration.contentTypes?.filter((contentType) =>
        config.contentTypes.some(
          (contentTypeName) => contentTypeName === contentType.value
        )
      ) || []
    );
  }, [brandConfigData?.brandConfiguration?.contentTypes, config.contentTypes]);

  const categoryConfigType = useMemo(() => {
    return configData?.configs?.search?.categories;
  }, [configData]);

  const [_lazySearch, searchResponse] = useLazyQuery<Search, SearchVariables>(
    Queries.SEARCH,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    }
  );

  const performSearch = useDebouncedCallback(
    (provider: SearchProvider, options: AssetSelectorSearchOptions) => {
      const {
        lang,
        types,
        status,
        contributors,
        collaborators,
        categories,
        adapter,
        page,
        sort,
        date,
        query,
        ...otherSearchOptions
      } = options;
      let dateFilterQueryParams = transformDates(options.date);

      let serializeSearchOptions = {
        ...otherSearchOptions,
        q: query,
      } as SearchFilters;

      if ("types" in options) {
        serializeSearchOptions.types = types?.join(",") || undefined;
      }

      if ("contributors" in options) {
        serializeSearchOptions.relid =
          contributors?.map((contributor) => contributor?.id).join(",") ||
          undefined;
      }

      if ("collaborators" in options) {
        serializeSearchOptions.privilege_user =
          currentUser.role === "contributor"
            ? currentUser.id
            : collaborators
                ?.map((collaborator) => collaborator?.id)
                .join(",") || undefined;
      }

      if ("status" in options) {
        serializeSearchOptions.status = options.status?.join(",") || undefined;
      }

      if ("categories" in options) {
        serializeSearchOptions.hierarchyIds =
          options.categories?.map((category) => category.id)?.join(",") ||
          undefined;
      }

      _lazySearch({
        variables: {
          organizationId: currentOrganizationID,
          provider: provider,
          limit: 50,
          filters: {
            q: serializeSearchOptions.q,
            display: "all",
            archived: false,
            d_o: "and",
            disableDecay:
              serializeSearchOptions.sort === "relevanceAllTime" ? true : false,
            sort:
              serializeSearchOptions.sort === "lastModified"
                ? "revisiondate desc"
                : serializeSearchOptions.sort === "lastPublished"
                ? "publishdate desc"
                : serializeSearchOptions.sort === "lastCreated"
                ? "createddate desc"
                : undefined,
            ...serializeSearchOptions,
            ...dateFilterQueryParams,
          },
        },
      });
    },
    SEARCH_DEBOUNCE_INTERVAL,
    [currentOrganizationID, currentUser.id]
  );

  const normalizedInitialSelections = useMemo(
    () =>
      initialSelections
        ?.filter(Boolean)
        .map((selection) =>
          normalizeContentSummary(selection, translateContentType)
        ) as ContentSummary[],
    [initialSelections, translateContentType]
  );
  const searchAdapters = useMemo(() => {
    const context = {
      search: performSearch,
      dateRangeOptions,
      statusOptions,
      categoryConfigType,
      contentTypes: contentTypeConfigsForAdapter,
      currentUser,
      organizationId: currentOrganizationID,
    };
    let contentAdapterTypesToCreate = contentTypeConfigsForAdapter?.map(
      (contentTypeConfig) => contentTypeConfig.value
    );
    contentAdapterTypesToCreate?.length > 1 &&
      contentAdapterTypesToCreate?.push("all");

    const contentAdapters = contentAdapterTypesToCreate.reduce(
      (contentAdapters, contentAdapterName) => {
        const contentAdapter = createAssetSelectorAdapter({
          id: contentAdapterName,
          adapterType: "content",
          context,
        });
        contentAdapter && contentAdapters.push(contentAdapter);
        return contentAdapters;
      },
      [] as SearchAdapter[]
    );

    const myWorkAdapter = createAssetSelectorAdapter({
      id: "mywork",
      adapterType: "mywork",
      context,
    }) as SearchAdapter;

    const externalAdapters = [] as SearchAdapter[];
    if (!configLoading) {
      config.externalTypes?.forEach((externalTypeName) => {
        if (externalTypeName === "getty" && !configData?.configs.v2Getty) {
          return;
        }
        if (
          externalTypeName === "gettyvideo" &&
          !configData?.configs.v2GettyVideo
        ) {
          return;
        }
        if (
          externalTypeName === "commerceproduct" &&
          !configData?.configs.enableCommerceProduct
        ) {
          return;
        }
        const adapter = createAssetSelectorAdapter({
          id: externalTypeName,
          adapterType: "external",
          context,
        });
        if (adapter) {
          externalAdapters.push(adapter);
        }
      });
    }

    return contentAdapterTypesToCreate?.length === 1 &&
      contentAdapterTypesToCreate[0] === "cnevideo"
      ? [...contentAdapters, ...externalAdapters]
      : [myWorkAdapter, ...contentAdapters, ...externalAdapters];
  }, [
    config,
    performSearch,
    configData,
    configLoading,
    contentTypeConfigsForAdapter,
    currentOrganizationID,
    currentUser,
    categoryConfigType,
    dateRangeOptions,
    statusOptions,
  ]);
  const enableSimilarProduct =
    configData?.configs.enableSimilarProductsSearch || false;
  const postProcessors = usePostProcessors(
    currentOrganizationID,
    config,
    enableSimilarProduct
  );
  const postProcessing = postProcessors.some(({ loading }) => loading);
  const submit = useCallback(
    async (assets) => {
      let processed = assets;
      for (const processor of postProcessors) {
        processed = await processor.process(processed);
      }

      onSubmit(processed);
      trackContentEvent("getty_image_added");
      onClose();
    },
    [onSubmit, postProcessors, onClose]
  );

  const normalizedSearchResponse = useMemo(() => {
    return {
      error: searchResponse.error,
      loading: searchResponse.loading,
      data: searchResponse.data
        ? {
            search: {
              results: searchResponse.data?.search.results.map((result) =>
                normalizeContentSummary(result, translateContentType)
              ),
              totalResults: searchResponse.data?.search.totalResults,
              limit: searchResponse.data?.search.limit,
              offset: searchResponse.data?.search.offset,
            },
          }
        : undefined,
      loadMore: () => {
        const limit = searchResponse.data?.search.limit ?? 50;
        const offset = searchResponse.data?.search.offset ?? 0;

        return searchResponse.fetchMore?.({
          variables: { offset: offset + limit },
          updateQuery: (previous: Search, { fetchMoreResult }) => {
            const newSearch = fetchMoreResult?.search;
            if (newSearch) {
              const previousSearchResults = previous.search.results;
              const { offset, totalResults, results } = newSearch;
              return {
                search: {
                  ...newSearch,
                  results: [
                    ...previousSearchResults.slice(0, offset),
                    ...results,
                    ...previousSearchResults.slice(
                      offset + results.length,
                      totalResults
                    ),
                  ],
                },
              };
            }
            return previous;
          },
        });
      },
    };
  }, [searchResponse]) as GQLResponse<{
    search: {
      results: ContentSummary[];
      totalResults: number;
      limit: number;
      offset: number;
    };
  }>;

  return createPortal(
    <AssetSelectorImplementation
      adapters={searchAdapters}
      onSubmit={submit}
      onClose={onClose}
      searchResponse={normalizedSearchResponse}
      limitSelection={config.limitSelection ?? undefined}
      cdnHost={cdnHost}
      submitting={postProcessing}
      publishing={postProcessing}
      model={model}
      setValue={setValue}
      currentOrganizationID={currentOrganizationID}
      userID={currentUser?.id}
      disableReturnFocus={disableReturnFocus}
      enableVideoMediaOverrides={enableVideoMediaOverrides}
      initialSelections={normalizedInitialSelections}
    />,
    portal
  );
}

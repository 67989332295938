import { defineMessages } from "react-intl";

export const TOOLTIP_OPTIONS = defineMessages({
  published: {
    defaultMessage: "Published on {date}",
    description: "Publish status tooltip",
  },
  scheduled: {
    defaultMessage: "Scheduled for {date}",
    description: "Scheduled status tooltip",
  },
  scheduled_republish: {
    defaultMessage: "Scheduled to be republished on {date}",
    description: "Scheduled republish status tooltip",
  },
});

import { forwardRef } from "react";
import { isLegacyBundleContainerConfig } from "../../utils";
import { BundleContainerProps } from "../../types";
import { MissingControl } from "../../../../MissingControl";
import { ContainerComponentMapping } from "./ContainerComponentMapping";

const ContainerSelector = forwardRef<HTMLDivElement, BundleContainerProps>(
  (props, ref) => {
    const containerConfig = props.configs?.containers?.find(
      (containerConfig) =>
        containerConfig.value === props.containerData.curationContainerType
    );
    let ContainerComponent;
    if (isLegacyBundleContainerConfig(containerConfig)) {
      ContainerComponent =
        ContainerComponentMapping[containerConfig.curationType];
    } else {
      ContainerComponent =
        ContainerComponentMapping[props.containerData.curationContainerType];
    }

    if (ContainerComponent) {
      return <ContainerComponent {...props} ref={ref} />;
    }

    return (
      <MissingControl
        control={{
          __typename: "BundleContainersFormControl",
          id: props.containerData.curationContainerType,
          children: null,
          configs: props.configs,
        }}
      >
        {null}
      </MissingControl>
    );
  }
);
ContainerSelector.displayName = "ContainerSelector";
export default ContainerSelector;

import { defineMessages } from "react-intl";

export const BYLINE_PREVIEW = defineMessages({
  brand_x_advertiser: {
    defaultMessage: "{brandName} X",
    description: "selectOptions.bylineOptions.brand_x_advertiser",
  },
  brand_presents_advertiser: {
    defaultMessage: "{brandName} Presents",
    description: "selectOptions.bylineOptions.brand_presents_advertiser",
  },
  branded_content: {
    defaultMessage: "Branded content by",
    description: "selectOptions.bylineOptions.branded_content",
  },
  created: {
    defaultMessage: "Created by {brandName} for",
    description: "selectOptions.bylineOptions.created",
  },
  in_collaboration: {
    defaultMessage: "In collaboration with",
    description: "selectOptions.bylineOptions.in_collaboration",
  },
  in_partnership: {
    defaultMessage: "In partnership with",
    description: "selectOptions.bylineOptions.in_partnership",
  },
  paid_post: {
    defaultMessage: "Paid post",
    description: "selectOptions.bylineOptions.paid_post",
  },
  paid_post_by_advertiser_brought_to_you_by_business_reporter: {
    defaultMessage:
      "Paid Post by {advertiserName}, Brought to you by Business Reporter",
    description:
      "selectOptions.bylineOptions.paid_post_by_advertiser_brought_to_you_by_business_reporter",
  },
  produced: {
    defaultMessage: "Produced by",
    description: "selectOptions.bylineOptions.produced",
  },
  produced_by_advertiser: {
    defaultMessage: "Produced by",
    description: "selectOptions.bylineOptions.produced_by_advertiser",
  },
  presented_by_advertiser: {
    defaultMessage: "Presented by",
    description: "selectOptions.bylineOptions.presented_by_advertiser",
  },
  produced_by_brand: {
    defaultMessage: "Produced by {brandName} with",
    description: "selectOptions.bylineOptions.produced_by_brand",
  },
  sponsored: {
    defaultMessage: "Sponsored content",
    description: "selectOptions.bylineOptions.sponsored",
  },
  sponsored_by: {
    defaultMessage: "Sponsored by",
    description: "selectOptions.bylineOptions.sponsored_by",
  },
  sponsored_content: {
    defaultMessage: "Sponsored content by",
    description: "selectOptions.bylineOptions.sponsored_content",
  },
  together_with: {
    defaultMessage: "Together with",
    description: "selectOptions.bylineOptions.sponsored_content",
  },
  advertisement_feature_with: {
    defaultMessage: "Advertisement Feature With",
    description: "selectOptions.bylineOptions.advertisement_feature_with",
  },
  original_content_by: {
    defaultMessage: "Original Content by",
    description: "selectOptions.bylineOptions.original_content_by",
  },
  advertisement_by_advertiser: {
    defaultMessage: "Advertisement by",
    description: "selectOptions.bylineOptions.advertisement_by_advertiser",
  },
  ad: {
    defaultMessage: "Ad",
    description: "selectOptions.bylineOptions.ad",
  },
  sponsored_by_advertiser: {
    defaultMessage: "Sponsored by",
    description: "selectOptions.bylineOptions.sponsored_by_advertiser",
  },
  in_partnership_with_advertiser: {
    defaultMessage: "In Partnership with",
    description: "selectOptions.bylineOptions.in_partnership_with_advertiser",
  },
  advertising: {
    defaultMessage: "Advertising",
    description: "selectOptions.bylineOptions.advertising",
  },
  advertisement: {
    defaultMessage: "Advertisement",
    description: "selectOptions.bylineOptions.Advertisement",
  },
  promoted_by_advertiser: {
    defaultMessage: "Promoted by",
    description: "selectOptions.bylineOptions.promoted_by_advertiser",
  },
  promotion: {
    defaultMessage: "Promotion",
    description: "selectOptions.bylineOptions.promotion",
  },
  paid_partnership_with_advertiser: {
    defaultMessage: "Paid Partnership with",
    description: "selectOptions.bylineOptions.paid_partnership_with_advertiser",
  },
  affiliate_partner: {
    defaultMessage: "Affiliate partner",
    description: "selectOptions.bylineOptions.affiliate_partner",
  },
  paid_promotion_by_advertiser: {
    defaultMessage: "Paid Promotion by",
    description: "selectOptions.bylineOptions.paid_promotion_by_advertiser",
  },
  special_feature: {
    defaultMessage: "Special Feature",
    description: "selectOptions.bylineOptions.special_feature",
  },
  published_by_advertiser: {
    defaultMessage: "Published by",
    description: "selectOptions.bylineOptions.published_by_advertiser",
  },
  PR: {
    defaultMessage: "PR",
    description: "selectOptions.bylineOptions.PR",
  },
});

import type { ApolloError } from "@apollo/client";
import type {
  GetCurrentUser_currentUser,
  Organizations_organizations,
} from "./__generated__";
import type { ReactNode } from "react";
import { ActionAccess, TaskPerContext } from "@hooks";
import { EditorInstance } from "@condenast/ckeditor5-build-condenast";
import { DistributionChannelName } from "@types";

export type FromGQL<T> = Omit<T, "__typename">;

export type ControlProps<T, Model = Record<string, unknown>> = Omit<
  T,
  "__typename" | "children"
> &
  AnonymousControlProps<Model>;

export type FormError = {
  path: string[];
  message: { name: string; message: string };
};

export type AnonymousControlProps<Model = Record<string, unknown>> = {
  children: ReactNode;
  model: Model;
  currentOrganization: Organizations_organizations;
  currentUser: GetCurrentUser_currentUser;
  permissions: ActionAccess<"publish" | "update">;
  errors: FormError[];
  setValue: {
    [K in keyof Model]: (key: K, value: Model[K]) => void;
  }[keyof Model];
  setHasDeferredChanges?: (hasDeferredChanges: boolean) => void;
  addTask?: (taskToAddPerContext: TaskPerContext) => void;
  removeTask?: (taskToRemovePerContext: TaskPerContext) => void;
  getBodyRefValue?: () => string;
  setBodyRef?: (bodyRef: React.MutableRefObject<EditorInstance | null>) => void;
};

/**
 * For when you're writing a hook that wraps a response from useQuery
 * T is the "data" type and E is the "error" type (Leave blank if you're just
 * passing along the ApolloError)
 *
 * e.g `useOrg(brandCode: string): GQLResponse<GetConfigs_config_orgConfig, Error>`
 */
export type GQLResponse<T, E = ApolloError> =
  | { loading: true; data?: never; error?: never; loadMore?: never }
  | { data: T; loading: false; error?: never; loadMore?: () => void }
  | { error: E; loading: false; data?: never; loadMore?: never };

/**
 * The type T, except fields in U have the type in U.
 * e.g: `enrichTitle(searchResult: TSearchResult): Override<TSearchResult, { title: VersoSource }>
 */
export type Override<T extends { [key in keyof U]: T[key] }, U> = Omit<
  T,
  keyof U
> &
  U;

type Primitive = string | number | boolean | undefined | null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>;
};
/**
 * Refer https://gist.github.com/ahuggins-nhs/826906a58e4c1e59306bc0792e7826d1
 */
export type DeepOmit<T, K> = T extends Primitive
  ? T
  : {
      [P in Exclude<keyof T, K>]: T[P] extends infer TP
        ? TP extends Primitive
          ? TP
          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          TP extends any[]
          ? DeepOmitArray<TP, K>
          : DeepOmit<TP, K>
        : never;
    };

export const findDistributionChannelsByName = <T extends { name: string }>(
  name: DistributionChannelName,
  distributionChannels?: T[]
): T | undefined => {
  return distributionChannels?.find(
    (distributionChannel) => distributionChannel.name === name
  );
};

import React from "react";
import { Button, ThemeProvider } from "@condenast/gemini";
import styled from "styled-components";
import { Toast } from "@components";

const BannerWrapper = styled(Toast)`
  &.with-details.with-action {
    grid-template-areas:
      " . .    . .       .       . . . . . ."
      " . icon . message message . . . . . ."
      " . .    . details details . . . action . ."
      " . .    . .            .  . . . action . ."
      " . .    . .            .  . . . . . .";
  }
  padding: var(--spacing-xs) 0 0 var(--spacing-xs);
  margin-top: var(--spacing-sm);
`;

const StyledButton = styled(Button)`
  min-width: 13rem;
  margin-top: 3%;

  svg {
    margin: auto;
    vertical-align: middle;
  }
`;
interface BannerProps {
  type?: "error" | "warning" | "success" | "informational";
  title: string;
  message: string;
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  resyncButtonIcon?: React.ReactNode;
}

export const Banner: React.FC<BannerProps> = ({
  type,
  title,
  message,
  label,
  onClick,
  resyncButtonIcon,
}) => {
  return (
    <BannerWrapper
      type={type || "warning"}
      details={message}
      action={
        onClick && (
          <ThemeProvider tint="blue">
            <StyledButton treatment="primary" onClick={onClick}>
              <div>
                {resyncButtonIcon}
                {label}
              </div>
            </StyledButton>
          </ThemeProvider>
        )
      }
    >
      {title}
    </BannerWrapper>
  );
};

Banner.displayName = "Banner";

import {
  AspectRatio,
  ContentConnectionFields,
  ContentSummaryFields,
  ContentSummary,
  ControlProps,
  FormFor_form_controls_EditMediaHeaderFormControl,
} from "@types";
import { useMemo, useCallback } from "react";
import { get, normalizeContentSummary } from "@lib";
import { ImageMetaData as EditMetaHeaderImplementation } from "@components";
import { Exif, NormalizedMetadata } from "@types";
import moment from "moment";
import { useDefinedMessages } from "@hooks";

function copyRightHelper(model: string, field: string) {
  let val = "——",
    items = model.split(";");

  if (items.length === 3) {
    switch (field) {
      case "notice":
        val = items[0];
        break;
      case "assignNum":
        val = items[1];
        break;
      case "rightsUsage":
        val = items[2];
        break;
    }
  }
  return val;
}

function getAssetType(assetTypeName: string) {
  if (assetTypeName) {
    switch (assetTypeName) {
      case "Clip":
        assetTypeName = "clips";
        break;
      case "Cartoon":
        assetTypeName = "cartoons";
        break;
      case "Photo":
        assetTypeName = "photos";
        break;
    }
  }
  return assetTypeName;
}

function createdDateHelper(data: string) {
  let date = data.split(":").join("/");
  return moment(date, "YYYY/MM/DD").format("MMMM Do, YYYY");
}

function normalizeExif(exif: Exif[]) {
  return exif.reduce(function (acc: { [key: string]: string }, itm: Exif) {
    acc[itm.tagName] = itm.tagValue ? itm.tagValue.toString() : "";
    return acc;
  }, {});
}
export function EditMediaHeader(
  props: ControlProps<FormFor_form_controls_EditMediaHeaderFormControl>
) {
  const { currentOrganization, model, setValue } = props;
  const cdnHost = useMemo(() => {
    return `https://${currentOrganization.metadata.mediaDomain}`;
  }, [currentOrganization]);

  const { translateContentType } = useDefinedMessages();

  let id = (get(model, "id") as string) ?? "";
  let filename = get(model, "filename") as string | null;

  let contentType = get(model, "__typename") as string;
  let assetTypeName = getAssetType(contentType);

  let thumbnails =
    (get(model, "thumbnails") as ContentConnectionFields) ?? null;

  const thumbnailsValue = useMemo(
    () =>
      (thumbnails?.edges.map((item) => item?.node) ??
        []) as ContentSummaryFields[],
    [thumbnails]
  );

  let backReferences =
    (get(model, "backReferences") as ContentConnectionFields) ?? null;

  const backReferencesValue = useMemo(
    () =>
      (backReferences?.edges ?? [])
        .map((item) =>
          normalizeContentSummary(item?.node, translateContentType)
        )
        .filter(Boolean) as ContentSummary[],
    [backReferences]
  );

  const normalizedThumbnailsValue = useMemo(
    () =>
      thumbnailsValue
        ?.map((thumbnail) =>
          normalizeContentSummary(thumbnail, translateContentType)
        )
        .filter(Boolean) as ContentSummary[],
    [thumbnailsValue]
  );
  const assetUrl = useMemo(() => {
    let assetType = assetTypeName;
    return `${cdnHost}/${assetType}/${id}/original/pass/${filename}?format=original`;
  }, [assetTypeName, cdnHost, id, filename]);

  let photosToutConnection = get(
    model,
    "photosTout"
  ) as ContentConnectionFields | null;
  let photosToutAsset = photosToutConnection?.edges?.[0]?.node?.asset;

  const aspectRatioArray = useMemo(() => {
    return (get(model, "aspectRatios") as AspectRatio[]) ?? [];
  }, [model]);

  const masterAspectRatio = useMemo(() => {
    return aspectRatioArray?.find((item) => {
      return item.name === "master";
    }) as AspectRatio;
  }, [aspectRatioArray]);

  const asset = useMemo(() => {
    return photosToutAsset
      ? photosToutAsset
      : {
          id: model.id as string,
          type: assetTypeName,
          filename: filename,
          altText: "",
          width: masterAspectRatio?.width,
          height: masterAspectRatio?.height,
        };
  }, [photosToutAsset, masterAspectRatio, assetTypeName, filename, model.id]);

  const isMasterAspectRatioMissingDimensions = useMemo(() => {
    return (
      masterAspectRatio &&
      (!masterAspectRatio.width || !masterAspectRatio.height)
    );
  }, [masterAspectRatio]);

  const updateMasterAspectRatioDimensions = useCallback(
    (img) => {
      let filteredAspectRatioArray = aspectRatioArray.filter(
        (aspectRatio) => aspectRatio.name !== "master"
      );
      let masterAspectRatioWithDimensions = {
        ...masterAspectRatio,
        height: img.height,
        width: img.width,
      };
      masterAspectRatioWithDimensions.name &&
        filteredAspectRatioArray.push(masterAspectRatioWithDimensions);
      setValue("aspectRatios", filteredAspectRatioArray);
    },
    [setValue, aspectRatioArray, masterAspectRatio]
  );

  const exifDataValue = useMemo(() => {
    let exifData = (get(model, "exif") as object) ?? [];
    let exif = (exifData as Exif[]) ?? [];
    return exif.length ? normalizeExif(exif) : {};
  }, [model]);

  const onChangePosterImage = (posterImage: ContentSummary) => {
    setValue("photosTout", {
      __typename: "ContentConnection",
      edges: [{ node: posterImage, __typename: "ContentToContentEdge" }],
    });
  };

  const exifMetaData = useMemo(() => {
    let defaultVal = "——";
    return [
      {
        key: "File Name",
        value: filename ? filename : defaultVal,
      },
      {
        key: "Dimensions",
        value:
          asset?.width && asset?.height
            ? `${asset.width} x ${asset.height} px`
            : defaultVal,
      },
      {
        key: "Date Created",
        value: exifDataValue["DateCreated"]
          ? createdDateHelper(exifDataValue["DateCreated"])
          : defaultVal,
      },
      {
        key: "Country",
        value: exifDataValue["Country-PrimaryLocationName"]
          ? exifDataValue["Country-PrimaryLocationName"]
          : defaultVal,
      },
      {
        key: "State",
        value: exifDataValue["Province-State"]
          ? exifDataValue["Province-State"]
          : defaultVal,
      },
      {
        key: "City",
        value: exifDataValue["City"] ? exifDataValue["City"] : defaultVal,
      },
      {
        key: "Copyright",
        value: exifDataValue["CopyrightNotice"]
          ? copyRightHelper(exifDataValue["CopyrightNotice"], "notice")
          : defaultVal,
      },
      {
        key: "Art Number",
        value: exifDataValue["CopyrightNotice"]
          ? copyRightHelper(exifDataValue["CopyrightNotice"], "assignNum")
          : defaultVal,
      },
      {
        key: "Rights usage",
        value: exifDataValue["CopyrightNotice"]
          ? copyRightHelper(exifDataValue["CopyrightNotice"], "rightsUsage")
          : defaultVal,
      },
    ] as NormalizedMetadata[];
  }, [asset, filename, exifDataValue]);

  return asset ? (
    <EditMetaHeaderImplementation
      id={id}
      cdnHost={cdnHost}
      photosTout={asset}
      exifMetaData={exifMetaData}
      assetUrl={assetUrl}
      thumbnails={normalizedThumbnailsValue}
      onChangePosterImage={onChangePosterImage}
      backReferences={backReferencesValue}
      contentType={contentType}
      onImageLoad={
        isMasterAspectRatioMissingDimensions
          ? updateMasterAspectRatioDimensions
          : undefined
      }
    />
  ) : (
    <></>
  );
}
EditMediaHeader.displayName = "Control(EditMediaHeader)";

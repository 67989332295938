import { useMutation } from "@apollo/client";
import { GallerySlideList } from "@components";
import { Mutations } from "@gql";
import type {
  ContentSummary,
  ContentSummaryFields,
  ControlProps,
  FormError,
  FormFor_content_Gallery_items_edges,
  FormFor_content_Gallery_items,
  FormFor_form_controls_SortableGalleryFormControl,
  UploadMedia,
  UploadMediaVariables,
  SlideUpdateOperation,
  UpdateMedia,
  UpdateMediaVariables,
} from "@types";
import {
  normalizeContentSummary,
  serializeContentSummary,
  MEDIA_MAP,
} from "@lib";
import update from "immutability-helper";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { AssetSelector, useCKEditorConfig } from "../-private";
import { useDeferredPromise, useDefinedMessages, useToast } from "@hooks";
import { useIntl } from "react-intl";

const CKEDITOR_BUILDS = {
  Minimalist: "minimalist",
  Inline: "inline",
  Body: "block",
  Block: "block",
} as const;

export function SortableGallery(
  props: ControlProps<FormFor_form_controls_SortableGalleryFormControl>
) {
  const {
    assetSelectorConfiguration,
    captionRichTextConfiguration,
    titleRichTextConfiguration,
    slideBodyRichTextConfiguration,
    currentOrganization,
    currentUser,
    model,
    setValue,
    errors,
    showSlideBody,
  } = props;

  const slideIDRef = useRef<number>(0);
  const authorName = `${currentUser.firstName} ${currentUser.lastName}`;

  const { translateContentType } = useDefinedMessages();

  const resolvedTitleRichTextConfig = useCKEditorConfig(
    titleRichTextConfiguration,
    currentOrganization,
    model.id as string
  );

  const resolvedTitleRichTextBuild =
    CKEDITOR_BUILDS[titleRichTextConfiguration?.build ?? "Minimalist"];

  const resolvedCaptionRichTextConfig = useCKEditorConfig(
    captionRichTextConfiguration,
    currentOrganization,
    model.id as string
  );

  const resolvedCaptionRichTextBuild =
    CKEDITOR_BUILDS[captionRichTextConfiguration?.build ?? "Minimalist"];

  const resolvedSlideBodyRichTextConfig = useCKEditorConfig(
    slideBodyRichTextConfiguration,
    currentOrganization,
    model.id as string
  );

  const resolvedSlideBodyRichTextBuild =
    CKEDITOR_BUILDS[slideBodyRichTextConfiguration?.build ?? "Block"];

  const [upload, uploadResult] = useMutation<UploadMedia, UploadMediaVariables>(
    Mutations.UPLOAD_MEDIA
  );

  const [batchUpdate, batchUpdateResult] = useMutation<
    UpdateMedia,
    UpdateMediaVariables
  >(Mutations.UPDATE_MEDIA);

  const { defer: deferSelectingAssets, deferRef: selectingAssets } =
    useDeferredPromise<ContentSummary[] | null>();

  const [assetSelectorContextualOptions, setAssetSelectorContextualOptions] =
    useState<{ limitSelection?: number } | undefined>(undefined);
  const [assetSelectorToggle, setAssetSelectorToggle] = useState(false);
  const resolvedAssetSelectorOptions = useMemo(
    () =>
      assetSelectorConfiguration
        ? {
            ...assetSelectorConfiguration,
            ...assetSelectorContextualOptions,
          }
        : null,
    [assetSelectorConfiguration, assetSelectorContextualOptions]
  );

  const modelValue = model["items"] as FormFor_content_Gallery_items | null;
  const slideErrors = useRef<FormError[][]>([]);
  useEffect(() => {
    const recomputedErrors = errors.reduce((sparseArray, { path, message }) => {
      if (path[0] === "items" && path[1] === "edges") {
        let index = parseInt(path[2]);
        if (!isNaN(index)) {
          sparseArray[index] = sparseArray[index] ?? [];
          sparseArray[index].push({
            message,
            path:
              path[3] === "node" ? ["item", ...path.slice(4)] : path.slice(3),
          });
        }
      }
      return sparseArray;
    }, [] as FormError[][]);
    slideErrors.current = recomputedErrors;
  }, [errors]);

  const decoratedValue = useMemo(() => {
    const edges = (modelValue?.edges ??
      []) as (FormFor_content_Gallery_items_edges & {
      _slideID?: string;
    })[];
    return edges.map((edge) => {
      return {
        ...edge,
        _slideID:
          edge.node?.id ?? edge._slideID ?? (slideIDRef.current++).toString(),
      };
    });
  }, [modelValue]);

  const normalizedValue = useMemo(() => {
    return decoratedValue.map(({ node, _slideID, ...slideFields }, index) => {
      return {
        ...slideFields,
        item: normalizeContentSummary(
          node as ContentSummaryFields | null,
          translateContentType
        ),
        slideID: _slideID,
        errors: slideErrors.current[index],
      };
    });
    // slideErrors.current is a mutable ref value and will not cause the component
    // to re-render, but we still need it as a dep key here since it gets updated
    // as a side effect of a different change that _does_ re-render the component
    // and at that time we want this memoized value to recompute
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decoratedValue, slideErrors.current]);

  const onAssetSelectorSubmit = useCallback(
    (assets: ContentSummary[]) => {
      selectingAssets.current?.resolve(assets);
    },
    [selectingAssets]
  );

  const intl = useIntl();

  const showBatchSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Credit updated",
      description: "Success notification when updating batch credit",
    }),
  });

  const showBatchErrorMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Sorry, something went wrong",
      description: "Error notification when updating batch credit",
    }),
  });

  const showRemovedDupesMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "One or more slides already exist in this gallery",
      description:
        "Notification message shown when adding duplicate items to a list of slides",
    }),
  });

  const onUpdate = useCallback(
    (operations: SlideUpdateOperation[]) => {
      const updatedSlideErrors = update(slideErrors.current, {
        $splice: operations.map(([index, removeCount, ...slides]) => [
          index,
          removeCount,
          ...slides.map((slide) => slide.errors ?? []),
        ]),
      });
      slideErrors.current = updatedSlideErrors;

      // Adding slide IDs to an array if they exist on the model
      let decoratedValueIds = decoratedValue.map(
        ({ node }) => node?.id ?? null
      );

      let removedDupes = false;

      const dedupedOperations: SlideUpdateOperation[] = operations.map(
        (operation) => {
          const [index, removeCount, ...slides] = operation;

          if (removeCount > 0) {
            // If this is a remove operation remove id's to keep decoratedValueIds updated
            decoratedValueIds.splice(index, removeCount);
          }
          const newSlides = slides.filter(
            (slide) =>
              !(slide?.item?.id && decoratedValueIds.includes(slide.item.id))
          );
          decoratedValueIds.splice(
            index,
            0,
            ...slides.map((slide) => slide.item?.id ?? null)
          );
          if (newSlides.length < slides.length) {
            removedDupes = true;
          }
          return [index, removeCount, ...newSlides];
        }
      );

      const edges = update(
        (decoratedValue ?? []) as (FormFor_content_Gallery_items_edges & {
          _slideID?: string;
        })[],
        {
          $splice: dedupedOperations.map(([index, removeCount, ...slides]) => [
            index,
            removeCount,
            ...slides.map(function serializeSlide(slide: {
              title?: string;
              caption?: string;
              body?: string;
              credit?: string;
              isActive?: boolean | null;
              item?: ContentSummary | null;
              slideID?: string;
            }): FormFor_content_Gallery_items_edges & { _slideID?: string } {
              return {
                __typename: "GalleryToItemEdge",
                title: slide.title,
                caption: slide.caption,
                body: slide.body,
                isActive: slide.isActive !== false,
                credit: slide.credit,
                _slideID:
                  slide.item?.id ??
                  slide.slideID ??
                  (slideIDRef.current++).toString(),
                node: serializeContentSummary(slide.item ?? null),
              };
            }),
          ]),
        }
      );

      if (removedDupes) {
        showRemovedDupesMessage();
      }

      setValue("items", update(modelValue, { edges: { $set: edges } }));
    },
    [decoratedValue, modelValue, setValue, showRemovedDupesMessage]
  );

  const uploadTypes = useMemo(
    () => [
      ...(assetSelectorConfiguration?.contentTypes?.includes("photo")
        ? MEDIA_MAP.photos
        : []),
      ...(assetSelectorConfiguration?.contentTypes?.includes("clip")
        ? MEDIA_MAP.clips
        : []),
    ],
    [assetSelectorConfiguration]
  );

  const openAssetSelector = useCallback(
    (options?: { limitSelection?: number }) => {
      setAssetSelectorContextualOptions(options);
      setAssetSelectorToggle(true);

      // resolve any unsettled promises before creating a new one
      selectingAssets.current?.resolve(null);
      return deferSelectingAssets().promise;
    },
    [deferSelectingAssets, selectingAssets, setAssetSelectorToggle]
  );

  const closeAssetSelector = useCallback(() => {
    selectingAssets.current?.resolve([]);
    setAssetSelectorToggle(false);
  }, [selectingAssets]);

  const onUpload = useCallback(
    async (files) => {
      let contentSummaries: Promise<ContentSummary | null>[] = [];
      for (let file of files) {
        contentSummaries.push(
          upload({
            variables: {
              organizationId: currentOrganization.organizationId,
              authorName,
              data: {
                file: file,
                fileSize: file.size,
              },
            },
          }).then((result) => {
            let asset = result.data?.uploadMedia;
            let normalized = normalizeContentSummary(
              asset,
              translateContentType
            );
            if (asset && normalized) {
              return normalized;
            } else return null;
          })
        );
      }
      return Promise.all(contentSummaries);
    },
    [authorName, currentOrganization.organizationId, upload]
  );

  const onBatchUpdate = useCallback(
    async (items: ContentSummary[], credit: string) => {
      const mediaItems = items.map((item) => {
        return {
          credit,
          id: item.id,
          authorName,
          contentType: item.contentType,
        };
      });

      try {
        const { data } = await batchUpdate({
          variables: {
            organizationId: currentOrganization.organizationId,
            data: mediaItems,
          },
        });

        if (data?.updateMedia?.success) {
          showBatchSuccessMessage();
        } else {
          showBatchErrorMessage();
        }
      } catch {
        showBatchErrorMessage();
      }
    },
    [
      authorName,
      currentOrganization.organizationId,
      batchUpdate,
      showBatchSuccessMessage,
      showBatchErrorMessage,
    ]
  );

  return (
    <>
      {assetSelectorToggle && resolvedAssetSelectorOptions && (
        <AssetSelector
          config={resolvedAssetSelectorOptions}
          currentOrganizationID={currentOrganization.organizationId}
          onClose={closeAssetSelector}
          onSubmit={onAssetSelectorSubmit}
          cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
          currentUser={currentUser}
        />
      )}
      <GallerySlideList
        onUpdate={onUpdate}
        selectAssets={openAssetSelector}
        onUpload={onUpload}
        isUploading={uploadResult.loading}
        onBatchUpdate={onBatchUpdate}
        isBatchUpdating={batchUpdateResult.loading}
        slides={normalizedValue}
        cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
        slideBodyRichTextConfig={resolvedSlideBodyRichTextConfig}
        slideBodyRichTextBuild={resolvedSlideBodyRichTextBuild}
        titleRichTextConfig={resolvedTitleRichTextConfig}
        titleRichTextBuild={resolvedTitleRichTextBuild}
        captionRichTextConfig={resolvedCaptionRichTextConfig}
        captionRichTextBuild={resolvedCaptionRichTextBuild}
        accept={uploadTypes}
        showSlideBody={showSlideBody}
      />
    </>
  );
}
SortableGallery.displayName = "Control(SortableGallery)";

import { useIntl, FormattedMessage } from "react-intl";
import styled, { DefaultTheme } from "styled-components";
import { PublishStatusIconV2, Chip } from "@components";
import { PublishedIcon } from "@condenast/gemini/icons";
import {
  PublishInfoFields,
  CreateGallery_content_Gallery_revisionInfo as RevisionInfo,
  PublishedRevisions,
} from "@types";
import { useDefinedMessages } from "@hooks";
import { ThemeProvider } from "@contexts";

const Wrapper = styled.div`
  cursor: pointer;
`;

const PublishStatusChip = styled(Chip)`
  border-radius: var(--spacing-xxs);
  padding: var(--spacing-xxs);
  margin: 0 var(--spacing-xs) 0 var(--spacing-xxs);
  background: ${(props) => props.theme.PrimaryBackground};
`;

const PublishStatusLabel = styled.span`
  font: ${(props) => props.theme.FontSmallStatement};
  margin-left: var(--spacing-xxs);
  color: ${(props) => props.theme.StatusPendingColor};
`;

const PublishStatusIconWrapper = styled.span`
  padding: var(--spacing-xxs);
  border-radius: var(--spacing-xxs);
  background: ${(props) => props.theme.PrimaryBackground};
`;

function transformYellows(theme: DefaultTheme) {
  return {
    ...theme,
    PrimaryBackground: "var(--color-yellow-70)",
  };
}

function transformGreens(theme: DefaultTheme) {
  return {
    ...theme,
    PrimaryBackground: "var(--color-green-70)",
  };
}

export function PublishStatus(props: {
  className?: string;
  publishInfo: PublishInfoFields;
  revisionInfo: RevisionInfo;
  publishedRevisions: PublishedRevisions[];
  handleOnClick: (status: boolean) => void;
}) {
  const intl = useIntl();
  const { translateToolTip } = useDefinedMessages();
  const { publishInfo, revisionInfo, publishedRevisions, handleOnClick } =
    props;
  const pubDate = publishInfo?.pubDate
    ? new Date(publishInfo.pubDate)
    : undefined;
  let isLive = new Date(publishInfo?.pubDate).getTime() < new Date().getTime();

  /** ensure story is published and scheduled is done */
  const isScheduled = () => {
    if (publishedRevisions?.length > 1) {
      const index = publishedRevisions.findIndex(
        ({ publishInfo: pubInfo }) => pubInfo?.version === publishInfo?.version
      );
      const sliceItems = publishedRevisions.slice(0, index);
      return !sliceItems.every(({ publishInfo: pubInfo }) => pubInfo?.expired);
    }
    return false;
  };

  const getScheduledDate = () => {
    if (isLive && isScheduled()) {
      const scheduledDateIndex = publishedRevisions.findIndex(
        ({ publishInfo: pubInfo }) => pubInfo?.version === publishInfo?.version
      );
      const sliceItems = publishedRevisions.slice(0, scheduledDateIndex);
      for (let i = sliceItems.length - 1; i >= 0; i--) {
        if (!sliceItems[i].publishInfo?.expired) {
          return sliceItems[i].publishInfo?.pubDate;
        }
      }
    }
    return pubDate;
  };

  const newDateString = (dateTime: Date | undefined) => {
    const month = intl.formatDate(dateTime, {
      month: "short",
    });
    const date = intl.formatDate(dateTime, {
      day: "numeric",
    });
    const year = intl.formatDate(dateTime, {
      year: "numeric",
    });
    const time = intl.formatDate(dateTime, {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return `${month} ${date}, ${year} at ${time?.toUpperCase()}`;
  };

  const getTooltipMessage = () => {
    let tooltip;
    if (isLive && !isScheduled()) {
      tooltip = translateToolTip("published", newDateString(pubDate));
    } else if (isLive && isScheduled()) {
      const scheduledDate = getScheduledDate();
      tooltip = translateToolTip(
        "scheduled_republish",
        newDateString(scheduledDate)
      );
    } else {
      tooltip = translateToolTip("scheduled", newDateString(pubDate));
    }

    return tooltip;
  };

  return !pubDate || isNaN(pubDate.getTime()) ? (
    <></>
  ) : (
    <>
      <ThemeProvider tint="green" transform={transformGreens}>
        {isLive && isScheduled() && (
          <PublishStatusIconWrapper>
            <PublishedIcon size="small" />
          </PublishStatusIconWrapper>
        )}
      </ThemeProvider>
      <Wrapper onClick={() => handleOnClick(true)}>
        <ThemeProvider
          tint={isLive && !isScheduled() ? "green" : "yellow"}
          transform={
            isLive && !isScheduled() ? transformGreens : transformYellows
          }
        >
          <PublishStatusChip size="small" aria-label={getTooltipMessage()}>
            {publishInfo && revisionInfo && (
              <PublishStatusIconV2
                publishInfo={publishInfo}
                latestRevision={revisionInfo.version}
                isScheduled={!!isScheduled()}
                size="small"
              />
            )}
            <PublishStatusLabel>
              {isLive && !isScheduled() ? (
                <FormattedMessage
                  defaultMessage="Published"
                  description="Publish status label"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Scheduled"
                  description="Scheduled status label"
                />
              )}
            </PublishStatusLabel>
          </PublishStatusChip>
        </ThemeProvider>
      </Wrapper>
    </>
  );
}

import {
  createContext,
  useCallback,
  useEffect,
  useRef,
  FC,
  ReactNode,
  useState,
} from "react";
import { Socket, io } from "socket.io-client";
import uuid from "uuid-random";

export const SocketIOContext = createContext<{
  getConnection: (host: string, path?: string) => Socket | null;
  clientId: string | null;
}>({ getConnection: () => null, clientId: null });
SocketIOContext.displayName = "SocketIOContext";

export const SocketIOProvider: FC<{ children: ReactNode }> = (props) => {
  const [clientId] = useState(uuid());
  const sockets = useRef<Record<string, Socket>>({});
  const getConnection = useCallback((host: string, path?: string) => {
    let url = host.endsWith("/") ? host.slice(0, host.length - 1) : host;
    url = path ? url + "/" + path : url;

    if (!sockets.current[url]) {
      sockets.current[url] = io(url, {
        reconnectionAttempts: 2,
        reconnectionDelay: 1500,
        transports: ["websocket"],
        extraHeaders: {
          "User-Agent": "copilot-app",
        },
      });
    }
    const socket = sockets.current[url];

    if (socket.disconnected) {
      socket.connect();
    }
    return socket;
  }, []);

  useEffect(() => {
    return () => {
      for (let socket of Object.values(sockets.current)) {
        socket.disconnect();
      }
    };
  }, []);

  return (
    <SocketIOContext.Provider value={{ getConnection, clientId }}>
      {props.children}
    </SocketIOContext.Provider>
  );
};

import { get, isEmpty } from "lodash";
import type {
  AspectRatio,
  MediaOverrides,
  MediaOverride,
  PhotoMediaOverride,
  PhotosOverrideEdge,
  PageLayoutSelectorConfig,
  PhotosLede,
  Trie,
  TrieValue,
  ToggleLevel,
  ToggleMap,
  TransformedLevelConfig,
} from "@types";

export function extractDecisionTree(
  currentToggleSelection: ToggleMap,
  toggleLevelHierarchy: ToggleLevel[],
  toggleDecisionTree: Trie,
  iteration: number
): TrieValue {
  if (iteration === 0) {
    return toggleDecisionTree;
  }
  let extractStr: string = currentToggleSelection[toggleLevelHierarchy[0]];
  for (let i = 1; i < iteration; i++) {
    extractStr = `${extractStr}.${
      currentToggleSelection[toggleLevelHierarchy[i]]
    }`;
  }
  return get(toggleDecisionTree, extractStr, {});
}

export function getLayoutOptions(
  currentToggleSelection: ToggleMap,
  toggleDecisionTree: Trie,
  config: PageLayoutSelectorConfig
): TransformedLevelConfig[] {
  const { toggleOptionsConfig, toggleLevelHierarchy } = config;
  const layoutOptions: TransformedLevelConfig[] = [];
  toggleLevelHierarchy.forEach((level: ToggleLevel, iteration: number) => {
    const levelConfig = toggleOptionsConfig[level];
    const transformedLevelConfig: TransformedLevelConfig = {
      ...levelConfig,
      options: [],
      optionsValue: [],
    };
    const extractedDecisionTree = extractDecisionTree(
      currentToggleSelection,
      toggleLevelHierarchy,
      toggleDecisionTree,
      iteration
    );
    levelConfig.options.forEach((optionConfig) => {
      if (
        typeof extractedDecisionTree === "object" &&
        extractedDecisionTree[optionConfig.value]
      ) {
        transformedLevelConfig.options.push(optionConfig);
        transformedLevelConfig.optionsValue.push(optionConfig.value);
      }
    });
    if (transformedLevelConfig.options.length > 1) {
      layoutOptions.push(transformedLevelConfig);
    }
  });
  return layoutOptions;
}

export function formatAspectRatioOptions(
  options: Array<{ label: string; value: string }>
) {
  const sorted = options.sort((a, b) => {
    const bSplit = b.value.split(":");
    const aSplit = a.value.split(":");
    const bDivide = Number(bSplit[0]) / Number(bSplit[1]);
    const aDivide = Number(aSplit[0]) / Number(aSplit[1]);

    // Non-numeric values (eg. "master") first
    // then wider ratios before narrower ratios
    if (isNaN(aDivide) || aDivide > bDivide) {
      return -1;
    } else if (isNaN(bDivide) || bDivide > aDivide) {
      return 1;
    } else {
      return 0;
    }
  });

  const formattedOptions = sorted.map((x) =>
    x.label === "master" ? { ...x, label: "Original" } : x
  );

  return formattedOptions;
}
export function getAspectRatioOptions(photosLede?: PhotosLede) {
  if (photosLede && !isEmpty(photosLede) && !photosLede.restrictCropping) {
    const { aspectRatios } = photosLede;
    return aspectRatios?.map((aspectRatio: AspectRatio) => ({
      label: aspectRatio.name,
      value: aspectRatio.name,
    }));
  }
  return [];
}

export function getPreSelectedMediaOverride(
  mediaOverrides: MediaOverrides[],
  breakPoint: string
): string {
  let photosLedeOverride: MediaOverride[] = [];
  let mediaOverrideInitialState = "";

  if (mediaOverrides) {
    mediaOverrides.forEach((override: MediaOverrides) => {
      if (override.relName === "photosLede") {
        photosLedeOverride = override.overrides;
      }
    });
    photosLedeOverride.forEach((override: MediaOverride) => {
      if (override.breakpoint === breakPoint)
        mediaOverrideInitialState = override.aspectRatio;
    });
  }
  return mediaOverrideInitialState;
}

export function getPreSelectedMediaOverrideV2(
  mediaOverrides: PhotosOverrideEdge | undefined,
  breakPoint: string
): string {
  let photosLedeOverride: PhotoMediaOverride[] = [];
  let mediaOverrideInitialState = "";

  if (
    mediaOverrides?.pagelayoutMediaOverrides &&
    mediaOverrides?.pagelayoutMediaOverrides.length
  ) {
    if (mediaOverrides.__typename === "PhotosOverrideEdge") {
      photosLedeOverride = mediaOverrides?.pagelayoutMediaOverrides;
    }
    photosLedeOverride.forEach((override: PhotoMediaOverride) => {
      if (override?.breakpoint === breakPoint)
        mediaOverrideInitialState = override.aspectRatio;
    });
  }
  return mediaOverrideInitialState;
}

export function updateMediaOverridesV2(
  mediaOverrides: PhotosOverrideEdge,
  override: PhotoMediaOverride
): PhotosOverrideEdge {
  let updatedMediaOverrides: PhotosOverrideEdge | undefined = mediaOverrides;
  if (mediaOverrides && mediaOverrides?.__typename === "PhotosOverrideEdge") {
    if (mediaOverrides?.pagelayoutMediaOverrides) {
      updatedMediaOverrides = {
        ...mediaOverrides,
        pagelayoutMediaOverrides: [
          ...mediaOverrides?.pagelayoutMediaOverrides.filter(
            ({ breakpoint }) => breakpoint !== override.breakpoint
          ),
          override,
        ],
      };
    }
  }
  return updatedMediaOverrides;
}

export function updateMediaOverrides(
  mediaOverrides: MediaOverrides[],
  override: MediaOverride
): MediaOverrides[] {
  let photosLedeExists = false;
  const updatedMediaOverrides = mediaOverrides.map((mediaOverride) => {
    if (mediaOverride.relName !== "photosLede") {
      return mediaOverride;
    }
    photosLedeExists = true;
    return {
      ...mediaOverride,
      overrides: [
        ...mediaOverride.overrides.filter(
          ({ breakpoint }) => breakpoint !== override.breakpoint
        ),
        override,
      ],
    };
  });

  if (!photosLedeExists) {
    updatedMediaOverrides.push({
      overrides: [override],
      relName: "photosLede",
    });
  }
  return updatedMediaOverrides;
}

import {
  useLayoutEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  FormFor_form,
  Organization,
  Changeset,
  GetCurrentUser_currentUser,
  DistributionChannel,
  ContentConnectionFields,
  PublishedRevisionResultFields,
  PublishInfoFields,
  GetBrand_brandConfiguration_contentTypes as ContentTypes,
  UpdateGallery_updateGallery_Gallery_distributionChannels as GQLDistributionChannel,
  SEOResult,
  FormFor_content,
  ParticipantData,
  GetConfigs,
  SyndicationFields_targets,
  ContentResyncData,
  GetBrand_brandConfiguration_seo,
  Organizations_organizations,
  FormFor_content_Article,
  FormFor_content_Gallery,
  ContentSummary,
  DistributionChannelConfig,
  CreateGallery_content_Gallery_revisionInfo as RevisionInfo,
  GetSyndicatedTargets,
  SyndicationTargetData,
} from "@types";
import {
  hasSEO,
  getSEOScore,
  setSEOField,
  normalizeContentSummary,
  capitalize,
  singularize,
} from "@lib";
import {
  Page,
  Title,
  SearchResult,
  ValidationSummary,
  PublishDialog,
  RoutableLink,
  ARIA,
  Button,
  UnsavedChangesDialog,
  PublishHistory,
  Banner,
  DeleteMedia,
  UsageInfo,
  SyndicatedInfoDialog,
  ViewOriginal,
} from "@components";
import { PublishStatus } from "../PublishStatus";
import { MobilePublishStatus } from "../MobilePublishStatus";
import { PublishDate } from "../PublishDate";
import {
  ActionAccess,
  useDefinedMessages,
  useResizeEffect,
  useUniqueId,
  useContentResync,
  TaskPerContext,
  LifecycleStep,
  LifecycleContext,
} from "@hooks";
import { ValidationError } from "@condenast/cross-check";
import { ActionBar } from "../ActionBar";
import { SEOScore } from "../ActionBar/SEOScore";
import { LangSwitcher } from "../LangSwitcher";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { Queries } from "@gql";
import { FormControl } from "../../../FormControl";
import { AnimatedEllipsisIcon, ReloadIcon } from "@condenast/gemini/icons";
import { EditorInstance } from "@condenast/ckeditor5-build-condenast";
import CopilotMarkdownRenderer from "@condenast/atjson-renderer-copilot-markdown";
import { SnowplowContext } from "@contexts";

const StyledSearchResult = styled(SearchResult)`
  word-break: break-all;
`;

const StoryHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ResultListContainer = styled.ul`
  grid-area: result-list;
  overflow-y: auto;
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  & > li:first-of-type {
    margin: 0;
  }
`;

const ResultSlat = styled.li`
  display: grid;
  grid-template-areas: "search-result";
  align-items: center;
  padding: 0;
  margin: var(--spacing-xxs) 0 0;
  cursor: default;
  user-select: none;
  position: relative;
  border-radius: var(--spacing-xxs);
  border: 1px solid ${(props) => props.theme.DividerColor};
`;

const BackreferenceItem = styled(RoutableLink)`
  text-decoration: none;
  color: ${(props) => {
    return props.theme.Color;
  }};
`;

const Dialog = styled(ARIA.Dialog)`
  overflow: scroll;
`;

export const DialogBody = styled.div`
  padding: var(--spacing-xxs) var(--spacing-md) var(--spacing-sm);
`;

type PublishData = {
  uri: string;
  authorName: string;
  pubDate?: string;
  distributionChannels?: DistributionChannel[];
};

const ContentPage = styled(Page)`
  background: ${({ theme }) => theme.PrimaryDisabledBackground};
  min-height: calc(100vh - var(--spacing-xxl));
  margin: 0 auto;
  max-width: min(${({ theme }) => theme.CardSize}, 100vw);
  width: 100%;
`;

const PageTitle = styled(Title)`
  padding-top: var(--spacing-sm);
`;

const StyledDate = styled.div`
  color: ${({ theme }) => theme.Color};
  font: ${({ theme }) => theme.FontSmallStatement};
  margin: var(--spacing-xs) 0;
`;

type FormPermissions = ActionAccess<"publish" | "update">;

const ALTERNATE_LANG_CONTENT_TYPES = ["article", "gallery"];

const CONTENT_TYPES_WITH_DIFF_VIEWER = ["article"];

const MEDIA_CONTENT_TYPES = ["photo", "clip", "cartoon"];

const CONTENT_TYPES_WITH_SYNDICATION = [
  "article",
  "gallery",
  "hotel",
  "recipe",
  "restaurant",
];

const CONTENT_TYPES_WITH_USAGE_INFO = [
  "article",
  "gallery",
  "infopage",
  "livestory",
  "product",
  "photo",
  "clip",
  "cartoon",
];

function buildForm<Model>(
  form: FormFor_form,
  model: Model,
  errors: ValidationError[],
  setValue: <Key extends keyof Model>(key: Key, value: Model[Key]) => void,
  currentOrganization: Organization,
  currentUser: GetCurrentUser_currentUser,
  permissions: FormPermissions,
  linkAutogen: boolean,
  linkAutogenConfigFields: string[],
  addTask: (taskToAddPerContext: TaskPerContext) => void,
  removeTask: (taskToRemovePerContext: TaskPerContext) => void,
  setBodyRef?: (bodyRef: React.MutableRefObject<EditorInstance | null>) => void,
  getBodyRefValue?: () => string,
  setHasDeferredChanges?: (hasDeferredChanges: boolean) => void,
  activeErrorPath?: readonly string[]
) {
  return form.children.map((id) => (
    <FormControl
      key={id}
      controlId={id}
      controls={form.controls}
      model={model}
      errors={errors}
      setValue={setValue}
      setHasDeferredChanges={setHasDeferredChanges}
      currentOrganization={currentOrganization}
      currentUser={currentUser}
      permissions={permissions}
      activeErrorPath={activeErrorPath}
      linkAutogen={linkAutogen}
      linkAutogenConfigFields={linkAutogenConfigFields}
      addTask={addTask}
      removeTask={removeTask}
      getBodyRefValue={getBodyRefValue}
      setBodyRef={setBodyRef}
    />
  ));
}
function normalizeDistributionChannel({
  name,
  metadata,
}: GQLDistributionChannel): DistributionChannel {
  return {
    name,
    metadata,
  };
}

function getPublishData<Model>(
  model: Model,
  user: GetCurrentUser_currentUser,
  defaultDistributionChannels?: GQLDistributionChannel[] | null
) {
  let record = model as Record<string, unknown>;
  let GQLDistributionChannels = record[
    "distributionChannels"
  ] as GQLDistributionChannel[];

  let normalizedDistributionChannels =
    GQLDistributionChannels && GQLDistributionChannels.length
      ? GQLDistributionChannels.map((GQLDistributionChannel) =>
          normalizeDistributionChannel(GQLDistributionChannel)
        )
      : defaultDistributionChannels?.map((defaultDistributionChannel) =>
          normalizeDistributionChannel(defaultDistributionChannel)
        );

  return {
    uri: (record["uri"] as string | null) ?? "",
    authorName: `${user.firstName} ${user.lastName}`,
    pubDate: (record["pubDate"] as string | null) ?? "",
    distributionChannels: normalizedDistributionChannels || [],
  };
}

function getPublishedRevisions<Model>(model: Model) {
  let record = model as Record<string, unknown>;
  return (
    (
      record["publishedRevisions"] as {
        results: PublishedRevisionResultFields[];
      } | null
    )?.results ?? []
  );
}

function getSyndicationTargets<Model>(model: Model) {
  let record = model as Record<string, unknown>;
  return (
    (
      record["syndication"] as {
        targets: SyndicationFields_targets[];
      } | null
    )?.targets ?? []
  );
}

export function ContentForm<
  Model extends FormFor_content & { entityMetadata?: { user: string[] } | null }
>(props: {
  title: string;
  changeset: Changeset<Model>;
  serverErrors: readonly Error[];
  validationErrors: ValidationError[];
  formDefinition: FormFor_form;
  onSubmit?: () => void;
  onPublish?: () => void;
  onPreview?: () => void;
  onViewLive?: () => void;
  publish: (publishData: PublishData) => Promise<void>;
  unpublish: () => void;
  onUnschedule: (version: number) => Promise<void>;
  deleteMedia: () => Promise<boolean>;
  currentOrganization: Organization;
  currentUser: GetCurrentUser_currentUser;
  contentTypeConfig?: ContentTypes;
  permissions: FormPermissions;
  isConnected: boolean;
  participants: ParticipantData[];
  linkAutogen?: boolean;
  linkAutogenConfigFields?: string[];
  addTask: (taskToAddPerContext: TaskPerContext) => void;
  removeTask: (taskToRemovePerContext: TaskPerContext) => void;
  seoConfig?: GetBrand_brandConfiguration_seo;
  isPublishable?: boolean;
  isArchived?: boolean;
  archive?: () => void;
  unarchive?: () => void;
}) {
  let {
    changeset,
    currentOrganization,
    currentUser,
    contentTypeConfig,
    onPublish,
    unpublish,
    onPreview,
    onSubmit,
    onViewLive,
    onUnschedule,
    deleteMedia,
    title,
    isConnected,
    participants,
    linkAutogen,
    linkAutogenConfigFields,
    addTask,
    removeTask,
    seoConfig,
    isPublishable,
    isArchived,
    archive,
    unarchive,
  } = props;
  let [data, setValue, { hasChanges }, setHasDeferredChanges] = changeset;
  const {
    translateContentType,
    translateSummaryErrors,
    translateResyncLabelType,
  } = useDefinedMessages();

  let [bodyRef, setBodyRef] =
    useState<React.MutableRefObject<EditorInstance | null>>();
  const revisionInfo = "revisionInfo" in data ? data.revisionInfo : null;
  const publishInfo = "publishInfo" in data ? data.publishInfo : null;
  const backReferences =
    "backReferences" in data
      ? (data.backReferences as ContentConnectionFields)
      : null;
  const seo = "seo" in data ? data.seo : null;
  const currentLang = "lang" in data ? data.lang ?? "" : "";

  const syndications = {
    canonicalUrl:
      "syndication" in data
        ? data.syndication?.canonicalUrl ?? undefined
        : undefined,
    sourceId:
      "syndication" in data
        ? data.syndication?.sourceId ?? undefined
        : undefined,
    source:
      "syndication" in data && data.syndication?.source
        ? data.syndication?.source
        : undefined,
    targets: getSyndicationTargets(data).map((target) => {
      return {
        syndicationAuthor: target.syndicationAuthor,
        createdAt: target.createdAt,
        brand: target.brand,
      };
    }),
  };

  const serializedBackReferences = backReferences?.edges.map(
    (backReferenceEdge) =>
      normalizeContentSummary(backReferenceEdge?.node, translateContentType)
  );

  const usageCount = serializedBackReferences?.length;
  const uid = useUniqueId();
  const selectItemPrefix = `backreference-item-${uid}`;
  const intl = useIntl();

  const { trackComponentEvent } = useContext(SnowplowContext);

  let [showPublishDialog, setShowPublishDialog] = useState(false);
  let [showUnpublishDialog, setShowUnpublishDialog] = useState(false);
  let [showPublishHistory, setShowPublishHistory] = useState(false);
  let [showDeleteMedia, setDeleteMedia] = useState(false);
  let [sheetState, setSheetState] = useState<"seo" | null>(null);
  let [actionBarCollapsed, setActionBarCollapsed] = useState(false);
  let params = useParams() as {
    copilotCode: string;
    contentType: string;
    bundleType: string;
    id: string;
  };
  const contentType = params.bundleType ? "bundles" : params.contentType;
  const previewUrl = `/${params.copilotCode}/preview/${contentType}/${params.id}`;
  const errors = translateSummaryErrors(props.validationErrors);
  const hasErrors = props.validationErrors.length || props.serverErrors.length;

  const [seoResults, setSEOResults] = useState<SEOResult[]>([]);
  const publishedRevisions = getPublishedRevisions(data);

  const [validationSummaryElement, setValidationSummaryElement] =
    useState<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (hasErrors && validationSummaryElement) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [hasErrors, validationSummaryElement]);

  const [activeErrorPath, setActiveErrorPath] = useState<
    readonly string[] | undefined
  >(undefined);

  // after re-rendering clear out the activeErrorPath
  useLayoutEffect(() => {
    if (activeErrorPath) {
      setActiveErrorPath(undefined);
    }
  }, [activeErrorPath, setActiveErrorPath]);

  const pubData = useMemo(
    () =>
      getPublishData(
        data,
        currentUser,
        contentTypeConfig?.publish?.defaultDistributionChannels
      ),
    [data, currentUser, contentTypeConfig]
  );

  const [isPublishing, setIsPublishing] = useState(false);

  let hostName = currentOrganization.url;
  if (hostName.slice(-1) !== "/") hostName = `${hostName}/`;

  async function updateSEOResults(
    data: FormFor_content_Article | FormFor_content_Gallery,
    currentOrganization: Organizations_organizations,
    seoConfig: GetBrand_brandConfiguration_seo | undefined
  ) {
    let seoScore = await getSEOScore(data, currentOrganization, seoConfig);
    setSEOResults(seoScore.results);
    return seoScore;
  }

  useEffect(() => {
    if (hasSEO(data)) {
      updateSEOResults(data, currentOrganization, seoConfig);
    }
    // run this effect once on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    addTask({
      lifecycleContexts: [LifecycleContext.SAVE, LifecycleContext.PUBLISH],
      lifecycleName: LifecycleStep.AFTER_SAVE_VALIDATION,
      taskName: "getSeoValue",
      runner: async () => {
        if (hasSEO(data)) {
          let updatedSeoScore = await updateSEOResults(
            data,
            currentOrganization,
            seoConfig
          );
          setValue(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            "seo",
            setSEOField(seo, { score: updatedSeoScore.actualTotal })
          );
        }
      },
    });
  }, [currentOrganization, data, addTask, seoConfig, setValue, seo]);

  useEffect(() => {
    if (props.validationErrors.length) {
      setIsPublishing(false);
    }
  }, [props.validationErrors.length]);

  useEffect(() => {
    addTask({
      lifecycleContexts: [LifecycleContext.PUBLISH],
      lifecycleName: LifecycleStep.AFTER_SAVE,
      taskName: "openPublishDialog",
      runner: () => {
        setShowPublishDialog(true);
      },
    });
  }, [addTask]);

  const actions = useMemo(() => {
    return {
      onSave:
        hasChanges && onSubmit
          ? () => {
              onSubmit?.();
            }
          : undefined,
      onPublish: onPublish
        ? () => {
            setIsPublishing(true);
            onPublish?.();
          }
        : undefined,
      publishing: isPublishing,
      previewURI: previewUrl,
      onPreview,
      liveURI:
        new Date(pubData.pubDate) < new Date() && pubData.uri
          ? `${hostName}${pubData.uri}`
          : "",
      onViewLive,
      showPublishHistory: () => setShowPublishHistory(true),
      showDeleteMedia: () => setDeleteMedia(true),
      ...(pubData.pubDate
        ? {
            onUnpublish: () => {
              if (serializedBackReferences?.length) {
                setShowUnpublishDialog(true);
              } else {
                unpublish();
              }
            },
          }
        : {}),
      archive: archive
        ? () => {
            archive?.();
          }
        : undefined,
      unarchive: unarchive
        ? () => {
            unarchive?.();
          }
        : undefined,
    };
  }, [
    isPublishing,
    previewUrl,
    onPreview,
    pubData.pubDate,
    pubData.uri,
    hostName,
    onViewLive,
    onPublish,
    hasChanges,
    onSubmit,
    serializedBackReferences?.length,
    unpublish,
    archive,
    unarchive,
  ]);

  const setSeoKeyword = useCallback(
    // eslint-disable-next-line
    // @ts-ignore "seo" isn't guaranteed to be on type Model
    // but it will be anytime this is called
    (keyword) => setValue("seo", setSEOField(seo, { keyword })),
    [setValue, seo]
  );

  const toggleSeoScoreCard = useCallback(
    () =>
      setSheetState((state) => {
        return state === "seo" ? null : "seo";
      }),
    []
  );

  const actionBarRef = useRef<HTMLDivElement | null>(null);
  const contentPageRef = useRef<HTMLDivElement | null>(null);

  const formWithSheetStyles = useResizeEffect(
    window,
    () => {
      const SHEET_PADDING = 16;
      const DETACH_SHEET_BREAKPOINT = 672;
      // skip if we haven't finished rendering
      if (!actionBarRef.current || !contentPageRef.current) return null;
      let contentPageSize = contentPageRef.current.getBoundingClientRect();
      let actionBarSize = actionBarRef.current.getBoundingClientRect();

      // skip if the action bar and the content form don't overlap at all
      if (contentPageSize.right + SHEET_PADDING <= actionBarSize.left)
        return null;

      let availableSpace = actionBarSize.left - SHEET_PADDING * 2;
      let width = Math.min(availableSpace, contentPageSize.width);
      let left = availableSpace - width - contentPageSize.left + SHEET_PADDING;
      let detachSheet = availableSpace < DETACH_SHEET_BREAKPOINT;

      return {
        adjustedFormWidth: width,
        adjustedFormLeft: left,
        detachSheet,
      };
    },
    [actionBarRef.current]
  );

  const handleKeyboardSave = useCallback(
    (evt: KeyboardEvent) => {
      if ((evt.metaKey || evt.ctrlKey) && evt.key === "s" && !evt.shiftKey) {
        evt.preventDefault();
        hasChanges && onSubmit?.();
      }
    },
    [onSubmit, hasChanges]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardSave);
    return () => {
      document.removeEventListener("keydown", handleKeyboardSave);
    };
  }, [handleKeyboardSave]);

  const filteredParticipants = useMemo(() => {
    return participants.filter(
      (participant) => participant.id !== currentUser.id
    );
  }, [participants, currentUser.id]);

  /** feature flag for lang switcher */

  let configsResponse = useQuery<GetConfigs>(Queries.GET_CONFIGS, {
    variables: {
      userId: currentUser.id,
      organizationId: currentOrganization?.organizationId,
    },
  });

  const syndicatedTargets = useQuery<GetSyndicatedTargets>(
    Queries.GET_SYNDICATED_TARGETS,
    {
      skip: !!syndications.sourceId,
      variables: {
        sourceId: data?.id,
      },
    }
  );

  const copilotUrl = configsResponse.data?.configs?.copilotUrl;
  const diffUrl =
    CONTENT_TYPES_WITH_DIFF_VIEWER.includes(contentTypeConfig?.value ?? "") &&
    revisionInfo?.version
      ? `${copilotUrl}/${params.copilotCode}/${contentType}/${params.id}/diff`
      : undefined;

  const alternateLanguage = configsResponse.data?.configs?.alternateLanguage;

  const showLangSwitcher =
    ALTERNATE_LANG_CONTENT_TYPES.includes(contentTypeConfig?.value ?? "") &&
    !!alternateLanguage;

  /** feature flag for content resync */
  const gallerySlidesResyncEnabled = useMemo(() => {
    return configsResponse?.data?.configs.galleryResyncBanner || false;
  }, [configsResponse]);

  /** feature flag for story headers */
  const storyHeader = useMemo(() => {
    return configsResponse?.data?.configs.storyHeader || false;
  }, [configsResponse]);

  const resyncContentData = useMemo(() => {
    return {
      syndication:
        "syndication" in data ? data.syndication ?? undefined : undefined,
      id: data.id,
      typename: data.__typename,
    } as ContentResyncData;
  }, [data]);

  const resyncContentUser = useMemo(() => {
    return {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
    };
  }, [currentUser]);

  const syndicationsData = useMemo(() => {
    if (syndications.sourceId) {
      return syndications;
    } else {
      const targets = syndicatedTargets?.data
        ?.getSyndicatedTargets as SyndicationTargetData[];
      return {
        ...syndications,
        targets:
          targets?.map(({ syndicationAuthor, brand, createdAt }) => ({
            syndicationAuthor,
            brand,
            createdAt,
          })) ?? [],
      };
    }
  }, [syndications, syndicatedTargets]);

  const { showResyncBanner, resyncRequest, isLoading, isTimeoutError } =
    useContentResync(
      resyncContentData,
      resyncContentUser,
      gallerySlidesResyncEnabled,
      currentUser,
      currentOrganization
    );

  const handleResyncRequest = useCallback(
    () => resyncRequest(),
    [resyncRequest]
  );

  const inProgressLabel = translateResyncLabelType("in_progress");
  const refreshLabel = translateResyncLabelType("refresh");

  const timeOutMessage = useMemo(() => {
    return isTimeoutError
      ? refreshLabel
      : translateResyncLabelType("resync_gallery");
  }, [isTimeoutError, refreshLabel, translateResyncLabelType]);

  const resyncBannerLabel = useMemo(() => {
    return isLoading ? inProgressLabel : timeOutMessage;
  }, [isLoading, timeOutMessage, inProgressLabel]);

  const resyncButtonIcon = () => {
    if (resyncBannerLabel === refreshLabel) {
      return <ReloadIcon size="regular" />;
    } else if (resyncBannerLabel === inProgressLabel) {
      return <AnimatedEllipsisIcon size="small" />;
    }
    return null;
  };

  const getBodyValue = useCallback(() => {
    let convertedBody = bodyRef?.current?.getData({
      format: "application/vnd.atjson+verso",
    });
    let renderedBody = convertedBody
      ? CopilotMarkdownRenderer.render(convertedBody).trim()
      : null;
    return renderedBody;
  }, [bodyRef]);

  const pubDate = publishInfo?.pubDate
    ? new Date(publishInfo.pubDate)
    : undefined;
  const author = revisionInfo?.authorName ?? "";
  const date = revisionInfo?.createdAt
    ? new Date(revisionInfo?.createdAt as string)
        .toLocaleString(undefined, {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "2-digit",
        })
        .replace("at ", "")
    : "";
  const backReferenceContentSummaries = useMemo(
    () =>
      (backReferences?.edges ?? [])
        .map((backReferenceEdge) =>
          normalizeContentSummary(backReferenceEdge?.node, translateContentType)
        )
        .filter(Boolean) as ContentSummary[],
    [backReferences]
  );

  const contentTitle = params.contentType
    ? translateContentType(
        singularize(params.contentType),
        1,
        capitalize(params.contentType)
      )
    : translateContentType(
        singularize(params.bundleType),
        1,
        capitalize(params.bundleType)
      );

  return (
    <ContentPage ref={contentPageRef} title={title}>
      <UnsavedChangesDialog
        hasChanges={hasChanges}
        message={
          <FormattedMessage defaultMessage="You have unsaved changes. Do you want to leave the page?" />
        }
        title={<FormattedMessage defaultMessage="Unsaved Changes" />}
      />
      <form
        autoComplete="off"
        noValidate
        style={
          (sheetState &&
            formWithSheetStyles &&
            !formWithSheetStyles.detachSheet &&
            !actionBarCollapsed && {
              position: "relative",
              width: formWithSheetStyles.adjustedFormWidth,
              left: formWithSheetStyles.adjustedFormLeft,
            }) ||
          undefined
        }
      >
        {showPublishDialog && (
          <PublishDialog
            onClose={() => {
              setIsPublishing(false);
              setShowPublishDialog(false);
            }}
            contentType={props.contentTypeConfig?.value || ""}
            distributionChannelsConfig={
              props.contentTypeConfig?.publish?.distributionChannels
            }
            hostname={`${props.currentOrganization.url}/`}
            copilotCode={currentOrganization.metadata.copilotCode}
            publish={async (publishData) => {
              await props.publish(publishData);
              setIsPublishing(false);
              setShowPublishDialog(false);
            }}
            publishData={pubData}
            scheduledPubDates={publishedRevisions
              .filter(({ publishInfo }) => publishInfo && !publishInfo.expired)
              .map(({ publishInfo }) => publishInfo?.pubDate)}
          />
        )}
        {showUnpublishDialog && (
          <Dialog
            title={intl.formatMessage({
              defaultMessage: "Linked Content",
              description: "Unpublish dialog heading",
            })}
            description={intl.formatMessage(
              {
                defaultMessage:
                  "{usageCount, plural, =1 {# item makes} other {# items make}} use of this content and will be affected by unpublishing it.",
                description: "Unpublish dialog description",
              },
              {
                usageCount,
                type: translateContentType(
                  params.contentType || params.bundleType,
                  1
                ),
              }
            )}
            size={"regular"}
            cancelButton={
              <Button
                onClick={() => {
                  setShowUnpublishDialog(false);
                }}
              >
                {intl.formatMessage({
                  defaultMessage: "Close",
                  description: "Close button in unpublish dialog",
                })}
              </Button>
            }
            submitButton={
              <Button
                treatment="primary"
                onClick={() => {
                  setShowUnpublishDialog(false);
                  unpublish();
                }}
              >
                {intl.formatMessage({
                  defaultMessage: "Unpublish",
                  description: "Unpublish button in unpublish dialog",
                })}
              </Button>
            }
            onClose={() => {
              setShowUnpublishDialog(false);
            }}
          >
            <DialogBody>
              <ResultListContainer tabIndex={0}>
                {backReferences?.edges.map((edge, index) => {
                  let backReferenceNode = normalizeContentSummary(
                    edge?.node,
                    translateContentType
                  );
                  return (
                    backReferenceNode?.editUrl && (
                      <ResultSlat
                        key={backReferenceNode?.id}
                        id={`${selectItemPrefix}-${index}`}
                      >
                        <BackreferenceItem
                          to={backReferenceNode?.editUrl ?? ""}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <StyledSearchResult
                            treatment="asset-selector"
                            result={backReferenceNode}
                            cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
                          />
                        </BackreferenceItem>
                      </ResultSlat>
                    )
                  );
                })}
              </ResultListContainer>
            </DialogBody>
          </Dialog>
        )}
        {showPublishHistory && (
          <PublishHistory
            publishedRevisions={
              publishedRevisions
                .map(({ publishInfo }) => publishInfo)
                .filter(Boolean) as PublishInfoFields[]
            }
            contentType={props.contentTypeConfig?.value || ""}
            syndications={syndicationsData}
            onClose={() => setShowPublishHistory(false)}
            unschedule={onUnschedule}
            uri={pubData.uri}
            consumerURL={currentOrganization.url}
            diffUrl={diffUrl}
          />
        )}
        {showDeleteMedia && (
          <DeleteMedia
            contentType={props.contentTypeConfig?.value || ""}
            onClose={() => setDeleteMedia(false)}
            deleteMedia={deleteMedia}
          />
        )}
        <ActionBar
          ref={actionBarRef}
          toggleSeoScorecard={hasSEO(data) ? toggleSeoScoreCard : undefined}
          seoScore={seo?.score ?? 0}
          sheetContents={
            sheetState === "seo"
              ? seo && (
                  <SEOScore
                    seo={seo}
                    setSeoKeyword={setSeoKeyword}
                    seoResults={seoResults}
                    currentOrganization={currentOrganization}
                    onClose={() => setSheetState(null)}
                  />
                )
              : null
          }
          detachSheet={formWithSheetStyles?.detachSheet ?? undefined}
          actions={actions}
          collapsed={actionBarCollapsed}
          setCollapsed={setActionBarCollapsed}
          isConnected={isConnected}
          participants={filteredParticipants}
          contentType={contentType ?? ""}
          isPublishable={isPublishable}
          userRole={currentUser.role}
          isArchived={isArchived}
        />
        <PageTitle>{contentTitle}</PageTitle>
        <>
          {revisionInfo && (
            <StyledDate>
              <FormattedMessage
                defaultMessage={"Last modified on {date} by {author}"}
                values={{
                  date: date,
                  author: author,
                }}
              />
            </StyledDate>
          )}
          <StoryHeaderWrapper>
            {showLangSwitcher && <LangSwitcher currentLang={currentLang} />}
            {!pubDate || isNaN(pubDate.getTime()) ? (
              <></>
            ) : (
              <>
                <PublishStatus
                  publishInfo={publishInfo as PublishInfoFields}
                  revisionInfo={revisionInfo as RevisionInfo}
                  publishedRevisions={publishedRevisions}
                  handleOnClick={setShowPublishHistory}
                />
                <MobilePublishStatus
                  publishInfo={publishInfo}
                  distributionChannelsConfig={
                    props.contentTypeConfig?.publish
                      ?.distributionChannels as DistributionChannelConfig[]
                  }
                  publishData={pubData}
                />
                <PublishDate
                  publishInfo={publishInfo as PublishInfoFields}
                  publishedRevisions={publishedRevisions}
                />
              </>
            )}
            {CONTENT_TYPES_WITH_USAGE_INFO.includes(
              contentTypeConfig?.value ?? ""
            ) && (
              <>
                <UsageInfo
                  backReferences={backReferenceContentSummaries}
                  contentType={contentTypeConfig?.value ?? ""}
                  cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
                />
              </>
            )}
            {storyHeader &&
              CONTENT_TYPES_WITH_SYNDICATION.includes(
                contentTypeConfig?.value ?? ""
              ) && (
                <>
                  <SyndicatedInfoDialog
                    syndications={syndicationsData}
                    handleOnClick={setShowPublishHistory}
                  />
                  <ViewOriginal syndications={syndicationsData} />
                </>
              )}
          </StoryHeaderWrapper>
        </>
        {showResyncBanner && (
          <Banner
            title={intl.formatMessage({
              defaultMessage: "Resync with the original gallery",
              description: "Content resync banner title",
            })}
            message={intl.formatMessage({
              defaultMessage:
                "Resyncing with the original gallery will pull in newly published gallery slides only. The new slides added to this gallery will be in draft status. Changes will not be applied to existing gallery slides or any other content.",
              description: "Content resync banner message",
            })}
            label={resyncBannerLabel}
            onClick={() => {
              trackComponentEvent("content_resync", "button_click");
              handleResyncRequest();
            }}
            resyncButtonIcon={resyncButtonIcon()}
          />
        )}
        {isArchived &&
          (MEDIA_CONTENT_TYPES.includes(contentTypeConfig?.value ?? "") ? (
            <Banner
              type="error"
              title={intl.formatMessage({
                defaultMessage:
                  "This media asset has been archived or deleted.",
                description: "This media asset has been archived or deleted.",
              })}
              message={intl.formatMessage({
                defaultMessage: "If it was deleted, you can no longer use it.",
                description: "If it was deleted, you can no longer use it.",
              })}
            />
          ) : (
            <Banner
              type="informational"
              title={intl.formatMessage({
                defaultMessage: "This content has been hidden.",
                description: "This content has been hidden.",
              })}
              message={intl.formatMessage({
                defaultMessage:
                  "You can no longer see it in the search results unless you enable 'View Hidden Content' in the search options.",
                description: "This content has been hidden.",
              })}
              label={"Show"}
              onClick={actions.unarchive}
            />
          ))}
        <div ref={setValidationSummaryElement}>
          {props.validationErrors.length ? (
            <ValidationSummary
              errors={errors}
              onClick={(error) => setActiveErrorPath(error.path)}
            />
          ) : props.serverErrors.length ? (
            <ValidationSummary
              errors={props.serverErrors.map((error) => ({
                path: [error.name],
                message: error.message,
              }))}
            />
          ) : null}
        </div>
        {buildForm(
          props.formDefinition,
          data,
          props.validationErrors,
          setValue,
          props.currentOrganization,
          props.currentUser,
          props.permissions,
          linkAutogen ? linkAutogen : false,
          linkAutogenConfigFields ?? [],
          addTask,
          removeTask,
          setBodyRef,
          getBodyValue,
          setHasDeferredChanges,
          activeErrorPath
        )}
      </form>
    </ContentPage>
  );
}
ContentForm.displayName = "ContentForm";

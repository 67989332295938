import { useIntl } from "react-intl";
import { Select } from "@components";
import { useDefinedMessages } from "@hooks";
import {
  ControlProps,
  getPreviewForm_form_controls_AspectRatioSelectFormControl,
} from "@types";
import styled from "styled-components";
import { get } from "lodash";
import { ViewMode } from "../../../Preview/ViewMode";

const StyleWrapper = styled.div`
  margin-block-start: var(--spacing-md);
`;

const Header = styled.h1`
  font: var(--font-small-statements);
  padding-bottom: var(--spacing-sm);
`;

const StyledSelect = styled(Select)`
  & ul[aria-invalid="false"] {
    border: 1px solid ${(props) => props.theme.DividerColor};
  }
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${(props) => props.theme.DividerColor};
  margin-bottom: var(--spacing-sm);
  margin-top: var(--spacing-sm);
`;

export function AspectRatioSelect(
  props: ControlProps<getPreviewForm_form_controls_AspectRatioSelectFormControl>
) {
  let { translateFieldName, translateSelectOption } = useDefinedMessages();
  const { name, apiConfigPath, model, options, setValue } = props;
  const desktopPath = `${name}.lg.aspectRatio`;
  const mobilePath = `${name}.sm.aspectRatio`;

  const intl = useIntl();
  const placeholder = intl.formatMessage({
    defaultMessage: "Select an option",
    description: "Placeholder text for a Select component",
  });

  const transformedOptions: Array<{ label: string; value: string }> =
    options?.options.map((option: string) => {
      return {
        label: translateSelectOption(apiConfigPath ?? "", option),
        value: option,
      };
    }) ?? [];

  return (
    <StyleWrapper>
      <Header>{translateFieldName("imageAspectRatio")}</Header>
      <StyledSelect
        id="aspectRatioSelect-desktop"
        label={translateFieldName("desktop")}
        placeholder={placeholder}
        value={(get(model, desktopPath) as string) ?? ""}
        options={transformedOptions}
        onChange={(value: string | undefined) => setValue(desktopPath, value)}
      />
      <ViewMode view="desktop" />
      <StyledSelect
        id="aspectRatioSelect-mobile"
        label={translateFieldName("mobile")}
        placeholder={placeholder}
        value={(get(model, mobilePath) as string) ?? ""}
        options={transformedOptions}
        onChange={(value: string | undefined) => setValue(mobilePath, value)}
      />
      <ViewMode view="mobile" />
      <Divider />
    </StyleWrapper>
  );
}
AspectRatioSelect.displayName = "Control(AspectRatioSelect)";

import get from "lodash/get";
import set from "lodash/set";
import { Spinner, Toast } from "@condenast/gemini";
import { useContentAccessControlMatrix } from "@hooks";
import {
  GetCurrentUser_currentUser,
  Organization,
  ChangesetState,
} from "@types";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { Queries } from "@gql";
import { FormattedMessage } from "react-intl";
import {
  ContainerModel,
  LayoutModel,
  PresetCategory,
} from "../ContainerOptions.types";
import { FormControl } from "../../../FormControl";
import { getFormPath } from "./Options.utils";
import { SaveButton } from "./Actions";

const SpinnerWrapper = styled.div`
  height: var(--spacing-xxl);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionsForm = styled.form``;

export type OptionsProps = {
  curationContainerType: string;
  currentUser: GetCurrentUser_currentUser;
  currentOrganization: Organization;
  changesetState: ChangesetState & {
    hasChanges: boolean;
  };
  model: ContainerModel;
  presetCategory?: PresetCategory;
  saving: boolean;
  setValue: <Key extends "renditionConfigs" | "componentConfigs">(
    key: Key,
    value: ContainerModel[Key]
  ) => void;
  save: (containerConfig: Partial<LayoutModel>) => void;
};

function Options({
  curationContainerType,
  currentOrganization,
  currentUser,
  changesetState,
  model,
  presetCategory,
  setValue,
  save,
  saving,
}: OptionsProps) {
  const { changedKeys, hasChanges, changes } = changesetState;
  const { data, loading, error } = useQuery(
    Queries.GET_PREVIEW_CONTAINER_FORM,
    {
      variables: {
        organizationId: currentOrganization.organizationId,
        contentType: getFormPath(curationContainerType, presetCategory),
      },
    }
  );

  const permissions = useContentAccessControlMatrix(
    currentOrganization,
    ["read", "update", "publish"],
    "bundle"
  )["bundle"];

  const saveValue = () => {
    const updatedObject = changedKeys.reduce<Partial<LayoutModel>>(
      (acc, key) => {
        const value = get(changes, key);
        set(acc, key, value);
        return acc;
      },
      {}
    );
    save(updatedObject);
  };

  if (error) {
    return (
      <Toast type="error">
        <FormattedMessage
          defaultMessage="Error loading layout options"
          description="Error given when Forms API request fails"
        />
      </Toast>
    );
  }

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner size="small" />
      </SpinnerWrapper>
    );
  }

  return (
    <OptionsForm>
      {data.form.children.map((id: string) => (
        <FormControl<ContainerModel>
          //TODO derive the remaining props (error path, error permissions etc) correctly
          key={id}
          controlId={id}
          controls={data.form.controls}
          model={model}
          errors={[]}
          setValue={setValue}
          currentOrganization={currentOrganization}
          currentUser={currentUser}
          permissions={permissions}
          activeErrorPath={["path"]}
          linkAutogen={false}
          linkAutogenConfigFields={[]}
        />
      ))}
      <SaveButton loading={saving} onClick={saveValue} disabled={!hasChanges} />
    </OptionsForm>
  );
}

export default Options;

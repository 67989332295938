import { PreviewSplitScreenImageLeftFullBleedWide } from "./PreviewSplitScreenImageLeftFullBleedWide";
import { PreviewTextOverlayCenterFullBleedGradientWide } from "./PreviewTextOverlayCenterFullBleedGradientWide";
import { PreviewSplitScreenImageRightFullBleedWide } from "./PreviewSplitScreenImageRightFullBleedWide";
import { PreviewTextAboveCenterFullBleedNoContributorWide } from "./PreviewTextAboveCenterFullBleedNoContributorWide";
import { PreviewTextBelowCenterFullBleedNoContributorWide } from "./PreviewTextBelowCenterFullBleedNoContributorWide";

import { PreviewSplitScreenImageLeftFullBleedNarrow } from "./PreviewSplitScreenImageLeftFullBleedNarrow";
import { PreviewTextOverlayCenterFullBleedGradientNarrow } from "./PreviewTextOverlayCenterFullBleedGradientNarrow";
import { PreviewSplitScreenImageRightFullBleedNarrow } from "./PreviewSplitScreenImageRightFullBleedNarrow";
import { PreviewTextAboveCenterFullBleedNoContributorNarrow } from "./PreviewTextAboveCenterFullBleedNoContributorNarrow";
import { PreviewTextBelowCenterFullBleedNoContributorNarrow } from "./PreviewTextBelowCenterFullBleedNoContributorNarrow";

import { PreviewTextAboveLeftGridWidthCardNoContributorNarrow } from "./PreviewTextAboveLeftGridWidthCardNoContributorNarrow";
import { PreviewTextAboveLeftGridWidthCardNoContributorWide } from "./PreviewTextAboveLeftGridWidthCardNoContributorWide";
import { PreviewTextAboveLeftSmallNoContributorNarrow } from "./PreviewTextAboveLeftSmallNoContributorNarrow";
import { PreviewTextAboveLeftSmallNoContributorWide } from "./PreviewTextAboveLeftSmallNoContributorWide";
import { PreviewTextAboveLeftGridWidthNarrow } from "./PreviewTextAboveLeftGridWidthNarrow";

import { PreviewTextAboveLeftGridWidthWide } from "./PreviewTextAboveLeftGridWidthWide";
import { PreviewTextAboveLeftFullBleedNarrow } from "./PreviewTextAboveLeftFullBleedNarrow";
import { PreviewTextAboveLeftFullBleedWide } from "./PreviewTextAboveLeftFullBleedWide";
import { PreviewTextAboveLeftGridWidthCardNarrow } from "./PreviewTextAboveLeftGridWidthCardNarrow";
import { PreviewTextAboveLeftGridWidthCardWide } from "./PreviewTextAboveLeftGridWidthCardWide";

import { PreviewTextAboveCenterFullBleedNarrow } from "./PreviewTextAboveCenterFullBleedNarrow";
import { PreviewTextAboveCenterFullBleedWide } from "./PreviewTextAboveCenterFullBleedWide";
import { PreviewTextAboveCenterFullBleedCardNarrow } from "./PreviewTextAboveCenterFullBleedCardNarrow";
import { PreviewTextAboveCenterFullBleedCardWide } from "./PreviewTextAboveCenterFullBleedCardWide";
import { PreviewTextAboveCenterGridWidthNarrow } from "./PreviewTextAboveCenterGridWidthNarrow";

import { PreviewTextAboveCenterGridWidthWide } from "./PreviewTextAboveCenterGridWidthWide";
import { PreviewTextAboveCenterGridWidthCardNarrow } from "./PreviewTextAboveCenterGridWidthCardNarrow";
import { PreviewTextAboveCenterGridWidthCardWide } from "./PreviewTextAboveCenterGridWidthCardWide";
import { PreviewTextAboveCenterWithRuleNarrow } from "./PreviewTextAboveCenterWithRuleNarrow";
import { PreviewTextAboveCenterWithRuleWide } from "./PreviewTextAboveCenterWithRuleWide";

import { PreviewTextAboveCenterSmallCardNarrow } from "./PreviewTextAboveCenterSmallCardNarrow";
import { PreviewTextAboveCenterSmallCardWide } from "./PreviewTextAboveCenterSmallCardWide";
import { PreviewTextBelowCenterGridWidthNarrow } from "./PreviewTextBelowCenterGridWidthNarrow";
import { PreviewTextBelowCenterGridWidthWide } from "./PreviewTextBelowCenterGridWidthWide";
import { PreviewTextBelowCenterFullBleedNarrow } from "./PreviewTextBelowCenterFullBleedNarrow";

import { PreviewTextBelowCenterFullBleedWide } from "./PreviewTextBelowCenterFullBleedWide";
import { PreviewTextBelowLeftFullBleedNarrow } from "./PreviewTextBelowLeftFullBleedNarrow";
import { PreviewTextBelowLeftFullBleedWide } from "./PreviewTextBelowLeftFullBleedWide";
import { PreviewTextBelowLeftGridWidthNoContributorNarrow } from "./PreviewTextBelowLeftGridWidthNoContributorNarrow";
import { PreviewTextBelowLeftGridWidthNoContributorWide } from "./PreviewTextBelowLeftGridWidthNoContributorWide";

import { PreviewSplitScreenImageRightInsetNarrow } from "./PreviewSplitScreenImageRightInsetNarrow";
import { PreviewSplitScreenImageRightInsetWide } from "./PreviewSplitScreenImageRightInsetWide";
import { PreviewSplitScreenImageLeftInsetNarrow } from "./PreviewSplitScreenImageLeftInsetNarrow";
import { PreviewSplitScreenImageLeftInsetWide } from "./PreviewSplitScreenImageLeftInsetWide";
import { PreviewTextAboveLeftSmallNoContributorWithRuleWide } from "./PreviewTextAboveLeftSmallNoContributorWithRuleWide";
import { PreviewTextAboveLeftSmallNoContributorWithRuleNarrow } from "./PreviewTextAboveLeftSmallNoContributorWithRuleNarrow";
import { ComponentType, SVGAttributes } from "react";

export type PreviewOption = "narrow" | "wide";

type PreviewOptions = {
  [key in PreviewOption]: ComponentType<
    SVGAttributes<SVGElement> & { size: "regular"; imageUrl?: string }
  >;
};

type CategoryPreviewConfig = {
  [key: string]: PreviewOptions;
};

export const categoryPreview: CategoryPreviewConfig = {
  TextAboveLeftSmall: {
    narrow: PreviewTextAboveLeftSmallNoContributorNarrow,
    wide: PreviewTextAboveLeftSmallNoContributorWide,
  },
  TextAboveLeftGridWidth: {
    narrow: PreviewTextAboveLeftGridWidthNarrow,
    wide: PreviewTextAboveLeftGridWidthWide,
  },
  TextAboveLeftFullBleed: {
    narrow: PreviewTextAboveLeftFullBleedNarrow,
    wide: PreviewTextAboveLeftFullBleedWide,
  },
  TextAboveLeftGridWidthCard: {
    narrow: PreviewTextAboveLeftGridWidthCardNarrow,
    wide: PreviewTextAboveLeftGridWidthCardWide,
  },
  TextAboveLeftGridWidthCardNoContributor: {
    narrow: PreviewTextAboveLeftGridWidthCardNoContributorNarrow,
    wide: PreviewTextAboveLeftGridWidthCardNoContributorWide,
  },
  TextAboveLeftSmallWithRule: {
    narrow: PreviewTextAboveLeftSmallNoContributorWithRuleNarrow,
    wide: PreviewTextAboveLeftSmallNoContributorWithRuleWide,
  },
  TextAboveCenterFullBleed: {
    narrow: PreviewTextAboveCenterFullBleedNarrow,
    wide: PreviewTextAboveCenterFullBleedWide,
  },
  TextAboveCenterFullBleedCard: {
    narrow: PreviewTextAboveCenterFullBleedCardNarrow,
    wide: PreviewTextAboveCenterFullBleedCardWide,
  },
  TextAboveCenterFullBleedNoContributor: {
    narrow: PreviewTextAboveCenterFullBleedNoContributorNarrow,
    wide: PreviewTextAboveCenterFullBleedNoContributorWide,
  },
  TextAboveCenterGridWidth: {
    narrow: PreviewTextAboveCenterGridWidthNarrow,
    wide: PreviewTextAboveCenterGridWidthWide,
  },
  TextAboveCenterGridWidthCard: {
    narrow: PreviewTextAboveCenterGridWidthCardNarrow,
    wide: PreviewTextAboveCenterGridWidthCardWide,
  },
  TextAboveCenterSmallWithRule: {
    narrow: PreviewTextAboveCenterWithRuleNarrow,
    wide: PreviewTextAboveCenterWithRuleWide,
  },
  TextAboveCenterGridWidthTopCardSmall: {
    narrow: PreviewTextAboveCenterSmallCardNarrow,
    wide: PreviewTextAboveCenterSmallCardWide,
  },
  TextBelowCenterGridWidth: {
    narrow: PreviewTextBelowCenterGridWidthNarrow,
    wide: PreviewTextBelowCenterGridWidthWide,
  },
  TextBelowCenterFullBleed: {
    narrow: PreviewTextBelowCenterFullBleedNarrow,
    wide: PreviewTextBelowCenterFullBleedWide,
  },
  TextBelowCenterFullBleedNoContributor: {
    narrow: PreviewTextBelowCenterFullBleedNoContributorNarrow,
    wide: PreviewTextBelowCenterFullBleedNoContributorWide,
  },
  TextBelowLeftFullBleed: {
    narrow: PreviewTextBelowLeftFullBleedNarrow,
    wide: PreviewTextBelowLeftFullBleedWide,
  },
  TextBelowLeftGridWidth: {
    narrow: PreviewTextBelowLeftGridWidthNoContributorNarrow,
    wide: PreviewTextBelowLeftGridWidthNoContributorWide,
  },
  TextOverlayCenterFullBleedGradient: {
    narrow: PreviewTextOverlayCenterFullBleedGradientNarrow,
    wide: PreviewTextOverlayCenterFullBleedGradientWide,
  },
  SplitScreenImageRightInset: {
    narrow: PreviewSplitScreenImageRightInsetNarrow,
    wide: PreviewSplitScreenImageRightInsetWide,
  },
  SplitScreenImageRightFullBleed: {
    narrow: PreviewSplitScreenImageRightFullBleedNarrow,
    wide: PreviewSplitScreenImageRightFullBleedWide,
  },
  SplitScreenImageLeftInset: {
    narrow: PreviewSplitScreenImageLeftInsetNarrow,
    wide: PreviewSplitScreenImageLeftInsetWide,
  },
  SplitScreenImageLeftFullBleed: {
    narrow: PreviewSplitScreenImageLeftFullBleedNarrow,
    wide: PreviewSplitScreenImageLeftFullBleedWide,
  },
  SubjectFocus: {
    narrow: PreviewTextAboveLeftGridWidthCardNoContributorNarrow,
    wide: PreviewTextAboveLeftGridWidthCardNoContributorWide,
  },
};

import { validators, ValidationBuilder } from "@condenast/cross-check-dsl";

import { optional, required } from "@condenast/copilot-validators";

const { object } = validators;

export function edgesHaveNodes(): ValidationBuilder<unknown> {
  return optional(
    object({
      edges: validators.items(
        object({ node: object({ id: required(), contentType: required() }) })
      ) as ValidationBuilder<unknown>,
    })
  ).catch((errors) =>
    errors.map(({ path }) => ({
      path,
      message: { details: null, name: "blank" },
    }))
  );
}

import {
  VersoTextAboveIcon,
  VersoTextBelowIcon,
  VersoTextOverlayIcon,
  VersoSplitLeftIcon,
  VersoSplitRightIcon,
  AlignLeftIcon,
  AlignCenterIcon,
  VersoStoryImageGridWidthIcon,
  VersoStoryImageFullBleedIcon,
  AssetSmallIcon,
} from "@condenast/gemini/icons";
import type {
  CategoryToggleMapping,
  ImagePreviewConfig,
  ToggleLevel,
  ToggleOptionsConfig,
} from "@types";

const categoryToggleMapping: CategoryToggleMapping = {
  SubjectFocus: {
    textArrangement: "Text Above",
    textAlignment: "Left",
    storyImageSize: "Grid Width",
    insetHeaderImage: "No",
    card: "Yes",
    contributorPhoto: "No",
    rule: "No",
  },
  TextAboveLeftSmall: {
    textArrangement: "Text Above",
    textAlignment: "Left",
    storyImageSize: "Small",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "No",
    rule: "No",
  },
  TextAboveLeftGridWidth: {
    textArrangement: "Text Above",
    textAlignment: "Left",
    storyImageSize: "Grid Width",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  TextAboveLeftFullBleed: {
    textArrangement: "Text Above",
    textAlignment: "Left",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  TextAboveLeftGridWidthCard: {
    textArrangement: "Text Above",
    textAlignment: "Left",
    storyImageSize: "Grid Width",
    insetHeaderImage: "No",
    card: "Yes",
    contributorPhoto: "Yes",
    rule: "No",
  },
  TextAboveLeftGridWidthCardNoContributor: {
    textArrangement: "Text Above",
    textAlignment: "Left",
    storyImageSize: "Grid Width",
    insetHeaderImage: "No",
    card: "Yes",
    contributorPhoto: "No",
    rule: "No",
  },
  TextAboveLeftSmallWithRule: {
    textArrangement: "Text Above",
    textAlignment: "Left",
    storyImageSize: "Small",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "No",
    rule: "Yes",
  },
  TextAboveCenterGridWidth: {
    textArrangement: "Text Above",
    textAlignment: "Center",
    storyImageSize: "Grid Width",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  TextAboveCenterGridWidthCard: {
    textArrangement: "Text Above",
    textAlignment: "Center",
    storyImageSize: "Grid Width",
    insetHeaderImage: "No",
    card: "Yes",
    contributorPhoto: "Yes",
    rule: "No",
  },
  TextAboveCenterFullBleed: {
    textArrangement: "Text Above",
    textAlignment: "Center",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  TextAboveCenterFullBleedCard: {
    textArrangement: "Text Above",
    textAlignment: "Center",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "No",
    card: "Yes",
    contributorPhoto: "Yes",
    rule: "No",
  },
  TextAboveCenterFullBleedNoContributor: {
    textArrangement: "Text Above",
    textAlignment: "Center",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "No",
    rule: "No",
  },
  TextAboveCenterGridWidthTopCardSmall: {
    textArrangement: "Text Above",
    textAlignment: "Center",
    storyImageSize: "Small",
    insetHeaderImage: "No",
    card: "Yes",
    contributorPhoto: "No",
    rule: "No",
  },
  TextAboveCenterSmallWithRule: {
    textArrangement: "Text Above",
    textAlignment: "Center",
    storyImageSize: "Small",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "No",
    rule: "Yes",
  },
  TextBelowCenterGridWidth: {
    textArrangement: "Text Below",
    textAlignment: "Center",
    storyImageSize: "Grid Width",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  TextBelowCenterFullBleed: {
    textArrangement: "Text Below",
    textAlignment: "Center",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  TextBelowCenterFullBleedNoContributor: {
    textArrangement: "Text Below",
    textAlignment: "Center",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "No",
    rule: "No",
  },
  TextBelowLeftFullBleed: {
    textArrangement: "Text Below",
    textAlignment: "Left",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  TextBelowLeftGridWidth: {
    textArrangement: "Text Below",
    textAlignment: "Left",
    storyImageSize: "Grid Width",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  TextOverlayCenterFullBleedGradient: {
    textArrangement: "Text Overlay",
    textAlignment: "Center",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  SplitScreenImageRightFullBleed: {
    textArrangement: "Text Left",
    textAlignment: "Center",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  SplitScreenImageRightInset: {
    textArrangement: "Text Left",
    textAlignment: "Center",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "Yes",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  SplitScreenImageLeftFullBleed: {
    textArrangement: "Text Right",
    textAlignment: "Center",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "No",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
  SplitScreenImageLeftInset: {
    textArrangement: "Text Right",
    textAlignment: "Center",
    storyImageSize: "Full Bleed",
    insetHeaderImage: "Yes",
    card: "No",
    contributorPhoto: "Yes",
    rule: "No",
  },
};

const toggleLevelHierarchy: ToggleLevel[] = [
  "textArrangement",
  "textAlignment",
  "storyImageSize",
  "insetHeaderImage",
  "contributorPhoto",
  "card",
  "rule",
];

const toggleOptionsConfig: ToggleOptionsConfig = {
  textArrangement: {
    key: "textArrangement",
    type: "button",
    value: "Text Arrangement",
    translation: "textArrangement",
    options: [
      {
        value: "Text Above",
        translation: "textAbove",
        icon: VersoTextAboveIcon,
      },
      {
        value: "Text Below",
        translation: "textBelow",
        icon: VersoTextBelowIcon,
      },
      {
        value: "Text Overlay",
        translation: "textOverlay",
        icon: VersoTextOverlayIcon,
      },
      {
        value: "Text Right",
        translation: "textRight",
        icon: VersoSplitLeftIcon,
      },
      {
        value: "Text Left",
        translation: "textLeft",
        icon: VersoSplitRightIcon,
      },
    ],
  },
  textAlignment: {
    key: "textAlignment",
    type: "button",
    value: "Text Alignment",
    translation: "textAlignment",
    options: [
      {
        value: "Left",
        translation: "alignLeft",
        icon: AlignLeftIcon,
      },
      {
        value: "Center",
        translation: "alignCenter",
        icon: AlignCenterIcon,
      },
    ],
  },
  storyImageSize: {
    key: "storyImageSize",
    type: "button",
    value: "Story Image Size",
    translation: "storyImageSize",
    options: [
      {
        value: "Small",
        translation: "imageSmall",
        icon: AssetSmallIcon,
      },
      {
        value: "Full Bleed",
        translation: "imageFullBleed",
        icon: VersoStoryImageFullBleedIcon,
      },
      {
        value: "Grid Width",
        translation: "imageGridWidth",
        icon: VersoStoryImageGridWidthIcon,
      },
    ],
  },
  insetHeaderImage: {
    key: "insetHeaderImage",
    type: "switch",
    value: "Inset Header Image",
    translation: "insetHeaderImage",
    options: [
      {
        value: "Yes",
        translation: "yes",
      },
      {
        value: "No",
        translation: "no",
      },
    ],
  },
  contributorPhoto: {
    key: "contributorPhoto",
    type: "switch",
    value: "Contributor Photo",
    translation: "contributorPhoto",
    options: [
      {
        value: "Yes",
        translation: "yes",
      },
      {
        value: "No",
        translation: "no",
      },
    ],
  },
  card: {
    key: "card",
    type: "switch",
    value: "Card",
    translation: "card",
    options: [
      {
        value: "Yes",
        translation: "yes",
      },
      {
        value: "No",
        translation: "no",
      },
    ],
  },
  rule: {
    key: "rule",
    type: "switch",
    value: "Rule",
    translation: "rule",
    options: [
      {
        value: "Yes",
        translation: "yes",
      },
      {
        value: "No",
        translation: "no",
      },
    ],
  },
};

const categoryImagePreview: ImagePreviewConfig = {
  SubjectFocus: {
    narrow: "preview-text-above-left-grid-width-card-no-contributor-narrow",
    wide: "preview-text-above-left-grid-width-card-no-contributor-wide",
  },
  TextAboveLeftSmall: {
    narrow: "preview-text-above-left-small-no-contributor-narrow",
    wide: "preview-text-above-left-small-no-contributor-wide",
  },
  TextAboveLeftGridWidth: {
    narrow: "preview-text-above-left-grid-width-narrow",
    wide: "preview-text-above-left-grid-width-wide",
  },
  TextAboveLeftFullBleed: {
    narrow: "preview-text-above-left-full-bleed-narrow",
    wide: "preview-text-above-left-full-bleed-wide",
  },
  TextAboveLeftGridWidthCard: {
    narrow: "preview-text-above-left-grid-width-card-narrow",
    wide: "preview-text-above-left-grid-width-card-wide",
  },
  TextAboveLeftGridWidthCardNoContributor: {
    narrow: "preview-text-above-left-grid-width-card-no-contributor-narrow",
    wide: "preview-text-above-left-grid-width-card-no-contributor-wide",
  },
  TextAboveLeftSmallWithRule: {
    narrow: "preview-text-above-left-small-no-contributor-with-rule-narrow",
    wide: "preview-text-above-left-small-no-contributor-with-rule-wide",
  },
  TextAboveCenterFullBleed: {
    narrow: "preview-text-above-center-full-bleed-narrow",
    wide: "preview-text-above-center-full-bleed-wide",
  },
  TextAboveCenterFullBleedCard: {
    narrow: "preview-text-above-center-full-bleed-card-narrow",
    wide: "preview-text-above-center-full-bleed-card-wide",
  },
  TextAboveCenterFullBleedNoContributor: {
    narrow: "preview-text-above-center-full-bleed-no-contributor-narrow",
    wide: "preview-text-above-center-full-bleed-no-contributor-wide",
  },
  TextAboveCenterGridWidth: {
    narrow: "preview-text-above-center-grid-width-narrow",
    wide: "preview-text-above-center-grid-width-wide",
  },
  TextAboveCenterGridWidthCard: {
    narrow: "preview-text-above-center-grid-width-card-narrow",
    wide: "preview-text-above-center-grid-width-card-wide",
  },
  TextAboveCenterSmallWithRule: {
    narrow: "preview-text-above-center-with-rule-narrow",
    wide: "preview-text-above-center-with-rule-wide",
  },
  TextAboveCenterGridWidthTopCardSmall: {
    narrow: "preview-text-above-center-small-card-narrow",
    wide: "preview-text-above-center-small-card-wide",
  },
  TextBelowCenterGridWidth: {
    narrow: "preview-text-below-center-grid-width-narrow",
    wide: "preview-text-below-center-grid-width-wide",
  },
  TextBelowCenterFullBleed: {
    narrow: "preview-text-below-center-full-bleed-narrow",
    wide: "preview-text-below-center-full-bleed-wide",
  },
  TextBelowCenterFullBleedNoContributor: {
    narrow: "preview-text-below-center-full-bleed-no-contributor-narrow",
    wide: "preview-text-below-center-full-bleed-no-contributor-wide",
  },
  TextBelowLeftFullBleed: {
    narrow: "preview-text-below-left-full-bleed-narrow",
    wide: "preview-text-below-left-full-bleed-wide",
  },
  TextBelowLeftGridWidth: {
    narrow: "preview-text-below-left-grid-width-no-contributor-narrow",
    wide: "preview-text-below-left-grid-width-no-contributor-wide",
  },
  TextOverlayCenterFullBleedGradient: {
    narrow: "preview-text-overlay-center-full-bleed-gradient-narrow",
    wide: "preview-text-overlay-center-full-bleed-gradient-wide",
  },
  SplitScreenImageRightInset: {
    narrow: "preview-split-screen-image-right-inset-narrow",
    wide: "preview-split-screen-image-right-inset-wide",
  },
  SplitScreenImageRightFullBleed: {
    narrow: "preview-split-screen-image-right-full-bleed-narrow",
    wide: "preview-split-screen-image-right-full-bleed-wide",
  },
  SplitScreenImageLeftInset: {
    narrow: "preview-split-screen-image-left-inset-narrow",
    wide: "preview-split-screen-image-left-inset-wide",
  },
  SplitScreenImageLeftFullBleed: {
    narrow: "preview-split-screen-image-left-full-bleed-narrow",
    wide: "preview-split-screen-image-left-full-bleed-wide",
  },
};

export const PageLayoutConfiguration = {
  categoryToggleMapping,
  toggleLevelHierarchy,
  toggleOptionsConfig,
  categoryImagePreview,
};

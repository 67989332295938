import type { SearchAdapterFactoryContext } from "../types";
import { ContentAdapterFactory } from "./content";
import { MyWorkAdapterFactory } from "./mywork";
import { GettyAdapterFactory } from "./getty";
import { GettyVideoAdapterFactory } from "./gettyvideo";
import { CommerceProductAdapterFactory } from "./commerceproduct";
import { SearchAdapter } from "@types";

const CONTENT_SELECTOR_ADAPTERS = {
  content: ContentAdapterFactory,
  mywork: MyWorkAdapterFactory,
  getty: GettyAdapterFactory,
  gettyvideo: GettyVideoAdapterFactory,
  commerceproduct: CommerceProductAdapterFactory,
};

function hasAdapter(
  name: string
): name is keyof typeof CONTENT_SELECTOR_ADAPTERS {
  return name in CONTENT_SELECTOR_ADAPTERS;
}
export function createAssetSelectorAdapter({
  id,
  adapterType,
  context,
}: {
  id: string;
  adapterType: "content" | "external" | "mywork";
  context: SearchAdapterFactoryContext;
}): SearchAdapter | null {
  const adapterFactoryType = adapterType === "content" ? "content" : id;

  if (!hasAdapter(adapterFactoryType)) {
    return null;
  }
  const factory = CONTENT_SELECTOR_ADAPTERS[adapterFactoryType];
  if (factory) {
    return factory(context, id);
  }
  return null;
}

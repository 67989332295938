import { FormattedMessage, useIntl } from "react-intl";
import { AddIcon, UploadIcon } from "@condenast/gemini/icons";
import { ARIA, Label } from "@components";
import { useCreateMenuAccess } from "./hooks";
import { CreateMenuGroups } from "../constants";
import { SnowplowContext } from "@contexts";
import { useDefinedMessages, useMediaQuery, useToast } from "@hooks";
import type {
  Organization,
  PresetsSearch,
  PresetsSearchVariables,
  CreateFromPreset,
  CreateFromPresetVariables,
  GetCurrentUser_currentUser,
  GetBrand_brandConfiguration_contentTypes as ContentTypes,
  GetBrand_brandConfiguration_contentTypes_subtypes as BundleSubtype,
} from "@types";
import { useMutation, useQuery } from "@apollo/client";
import { Queries, Mutations } from "@gql";
import { CreateFromPresetDialog } from "./CreateFromPresetDialog";
import type { CreateMenuItem, PresetData } from "./types";
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import pluralize from "pluralize";
import styled from "styled-components";

export const PRESETS_SEARCH_PARAMS = {
  hierarchy: "copilot/preset",
  nottypes: "category",
  types: "article",
  notcategory: "",
};

const StyledIcon = styled(UploadIcon)`
  margin: auto var(--spacing-xxs) auto 0;
`;

const StyledLabel = styled(Label)`
  display: flex;
`;

export function CreateMenu(props: {
  contentTypes: ContentTypes[];
  currentOrganization: Organization;
  currentUser: GetCurrentUser_currentUser;
  className?: string;
  enablePresets?: boolean;
  commerceProductUrl?: string | null;
  v2VersoChannel?: boolean;
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { trackContentEvent } = useContext(SnowplowContext);

  const {
    contentTypes,
    currentOrganization,
    currentUser,
    className,
    enablePresets,
    commerceProductUrl,
    v2VersoChannel,
  } = props;

  const { translateContentType } = useDefinedMessages();

  const isVersoChannelPresent = contentTypes.some(
    (contentType) =>
      contentType.label === "Bundle" &&
      contentType.subtypes &&
      contentType.subtypes.some(
        (subtype: BundleSubtype) => subtype.type === "Verso Channel"
      ) &&
      v2VersoChannel
  );

  let baseUrl = `/${currentOrganization.metadata.copilotCode}`;
  let createMenuItemMap = useCreateMenuAccess(currentOrganization, baseUrl);
  //adding the commerce product to the create menu list
  const createMenuItemList = [...createMenuItemMap.content];
  //checks if the commerceProductUrl exists, if it does then that means the enableCommerceProduct flag is turned on. In such case push an element for Commerce Product in createMenuItemList
  if (commerceProductUrl) {
    createMenuItemList.push({
      label: translateContentType("commerce product", 1),
      path: commerceProductUrl,
      value: "commerceproduct",
      section: CreateMenuGroups.CONTENT,
    });

    createMenuItemList.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  }
  if (isVersoChannelPresent) {
    createMenuItemMap.containers.push({
      label: translateContentType("channel", 1),
      path: `${baseUrl ?? ""}/pages/verso-channel/create`,
      value: "verso-channel",
      section: CreateMenuGroups.CONTAINERS,
    });
    createMenuItemMap.containers.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  }

  let isCreateMenuCollapsed = useMediaQuery("(max-width: 800px)");
  let size: "medium" | "regular" = useMediaQuery("(max-width: 500px)")
    ? "medium"
    : "regular";

  const [showPresetDialog, setShowPresetDialog] = useState(false);
  const [dialogHasErrors, setDialogHasErrors] = useState(false);
  const [dialogCreateMenuItem, setDialogCreateMenuItem] =
    useState<CreateMenuItem | null>(null);

  const { data } = useQuery<PresetsSearch, PresetsSearchVariables>(
    Queries.PRESETS_SEARCH,
    {
      variables: {
        organizationId: currentOrganization.organizationId,
        limit: 100,
        filters: {
          ...PRESETS_SEARCH_PARAMS,
        },
      },
      skip: !enablePresets,
    }
  );

  const openPresetDialog = useCallback(
    (menuItem: CreateMenuItem) => {
      setDialogCreateMenuItem(menuItem);
      setShowPresetDialog(true);
    },
    [setShowPresetDialog, setDialogCreateMenuItem]
  );
  const closePresetDialog = useCallback(() => {
    setShowPresetDialog(false);
    setDialogHasErrors(false);
  }, [setShowPresetDialog, setDialogHasErrors]);

  const [
    createFromPreset,
    { loading: createFromPresetLoading, error: createFromPresetError },
  ] = useMutation<CreateFromPreset, CreateFromPresetVariables>(
    Mutations.CREATE_FROM_PRESET
  );

  useEffect(() => {
    if (createFromPresetError) {
      setDialogHasErrors(true);
    }
  }, [createFromPresetError, setDialogHasErrors]);

  const showCreatingMessage = useToast({
    type: "informational",
    children: intl.formatMessage({
      defaultMessage: "Creating...",
      description: "Notification message to show an entity is creating",
    }),
  });

  const onSubmit = useCallback(
    async (data: { to: string } | PresetData) => {
      if ("to" in data) {
        closePresetDialog();
        navigate(data.to);
      } else {
        const { contentType, id } = data;
        showCreatingMessage();
        const createResult = await createFromPreset({
          variables: {
            organizationId: currentOrganization.organizationId,
            data: {
              contentType,
              id,
              authorName: `${currentUser.firstName} ${currentUser.lastName}`,
              entityMetadata: { user: [currentUser.id] },
            },
          },
        });
        const newId = createResult.data?.createFromPreset?.id;
        if (!newId || createResult.errors?.length) {
          setDialogHasErrors(true);
        } else {
          closePresetDialog();
          trackContentEvent(
            "create_from_preset",
            id,
            contentType,
            undefined,
            id,
            newId
          );
          navigate(`${baseUrl}/${pluralize(contentType)}/${newId}`);
        }
      }
    },
    [
      createFromPreset,
      navigate,
      baseUrl,
      showCreatingMessage,
      setDialogHasErrors,
      closePresetDialog,
      currentOrganization.organizationId,
      currentUser.id,
      currentUser.firstName,
      currentUser.lastName,
    ]
  );

  const presetsByContentType = useMemo(() => {
    return data?.search.results.reduce((map, { contentType, id, title }) => {
      if (!map[contentType]) {
        map[contentType] = [];
      }
      map[contentType].push({ contentType, id, title: title?.content });
      return map;
    }, {} as Record<string, PresetData[]>);
  }, [data]);

  let menuItems = useMemo(
    () => [
      {
        role: "link" as const,
        to: `/${currentOrganization.metadata.copilotCode}/photos/create`,
        value: (
          <StyledLabel>
            <StyledIcon size="small" />
            <FormattedMessage defaultMessage="Upload Photo / Clip" />
          </StyledLabel>
        ),
      },
      { role: "separator" as const },
      ...[
        {
          role: "group" as const,
          label: CreateMenuGroups.CONTENT,
          items: createMenuItemList.map((createMenuItem) => {
            return presetsByContentType?.[createMenuItem.value]?.length
              ? {
                  role: "action" as const,
                  value: <>{createMenuItem.label}</>,
                  onClick: () => openPresetDialog(createMenuItem),
                }
              : {
                  role: "link" as const,
                  target:
                    createMenuItem.value === "commerceproduct"
                      ? "_blank"
                      : undefined,
                  value: <>{createMenuItem.label}</>,
                  to: createMenuItem.path,
                };
          }),
        },
        {
          role: "group" as const,
          label: CreateMenuGroups.CONTAINERS,
          items: createMenuItemMap.containers.map((createMenuItem) => {
            return {
              role: "link" as const,
              value: <>{createMenuItem.label}</>,
              to: createMenuItem.path,
            };
          }),
        },
      ].filter((item) => item.items.length),
    ],
    [createMenuItemMap, presetsByContentType, openPresetDialog]
  );

  let hasMenuItems = menuItems.length > 0;

  let handleOnSearch = (items: ReactNode[], query: string) => {
    return items.find(
      (item) =>
        item?.toString().toLowerCase().indexOf(query.toLowerCase()) === 0
    );
  };

  return (
    <>
      <div className={className}>
        {hasMenuItems && (
          <ARIA.MenuButton
            id="create-menu"
            aria-label={intl.formatMessage({
              defaultMessage: "Create a new...",
            })}
            size={size}
            popper={{
              placement: isCreateMenuCollapsed ? "bottom" : "bottom-end",
              modifiers: [
                {
                  name: "preventOverflow",
                  options: { padding: 8 },
                },
              ],
            }}
            menu={{
              onSearch: handleOnSearch,
              items: menuItems,
              children: (item: ReactNode) => <>{item}</>,
            }}
          >
            <AddIcon size="regular" />
            {!isCreateMenuCollapsed && (
              <FormattedMessage defaultMessage="Create" />
            )}
          </ARIA.MenuButton>
        )}
      </div>
      {showPresetDialog &&
        dialogCreateMenuItem &&
        presetsByContentType?.[dialogCreateMenuItem.value] && (
          <CreateFromPresetDialog
            onClose={closePresetDialog}
            contentTypeLabel={dialogCreateMenuItem.label}
            presets={presetsByContentType[dialogCreateMenuItem.value]}
            path={dialogCreateMenuItem.path}
            onSubmit={onSubmit}
            loading={createFromPresetLoading}
            hasError={dialogHasErrors}
          />
        )}
    </>
  );
}

CreateMenu.displayName = "CreateMenu";

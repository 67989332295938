import {
  ControlProps,
  RecipePreparationGroupFields,
  RecipePreparationStepFields,
  FormFor_form_controls_RecipePreparationFormControl,
  PreparationGroupUpdateOperation,
  StepUpdateOperation,
  ContentSummary,
} from "@types";
import { useCallback, useMemo, useRef, useState } from "react";
import update from "immutability-helper";
import { RecipePreparationList } from "@components";
import { useDeferredPromise } from "@hooks";
import { AssetSelector, useCKEditorConfig, useCKUpload } from "../-private";

const CKEDITOR_BUILDS = {
  Minimalist: "minimalist",
  Inline: "inline",
  Body: "block",
  Block: "block",
} as const;

type ModelPreparationStep = RecipePreparationStepFields & {
  _stepID?: string;
  _stepNumber: number;
};

type ModelPreparationMicroStep = RecipePreparationStepFields & {
  _microStepID?: string;
  _microStepNumber: number;
};
type ModelPreparationGroup = Omit<
  RecipePreparationGroupFields,
  "steps" | "microSteps"
> & {
  _groupID?: string;
  steps: ModelPreparationStep[];
  microSteps: ModelPreparationMicroStep[];
};

type DecoratedPreparationStep = Required<ModelPreparationStep>;
type DecoratedPreparationMicroStep = Required<ModelPreparationMicroStep>;
type DecoratedPreparationGroup = Omit<
  Required<ModelPreparationGroup>,
  "steps" | "microSteps"
> & {
  steps: DecoratedPreparationStep[];
  microSteps: DecoratedPreparationMicroStep[];
};

export function RecipePreparation(
  props: ControlProps<FormFor_form_controls_RecipePreparationFormControl>
) {
  const {
    model,
    setValue,
    currentUser,
    preparationGroupRichTextConfiguration,
    preparationStepRichTextConfiguration,
    preparationStepAssetSelectorConfiguration,
    currentOrganization,
    showMicroSteps,
  } = props;

  const stepIDRef = useRef<number>(0);
  const microStepIDRef = useRef<number>(0);
  const groupIDRef = useRef<number>(0);
  const cdnHost = `https://${currentOrganization.metadata.mediaDomain}`;

  const modelPreparationGroups = model["preparationGroups"] as
    | ModelPreparationGroup[]
    | null;

  //Sets default behavior to be one group with one blank step, and, if microsteps
  //are supported, one blank microstep.
  const preparationGroups = useMemo(() => {
    return modelPreparationGroups?.length
      ? modelPreparationGroups
      : [
          {
            __typename: "RecipePreparationGroup" as const,
            hed: "",
            steps: [{} as ModelPreparationStep],
            microSteps: showMicroSteps ? [{} as ModelPreparationMicroStep] : [],
          } as ModelPreparationGroup,
        ];
  }, [modelPreparationGroups, showMicroSteps]);

  const decoratedPreparationGroups = useMemo(() => {
    let totalStepCount = 0;
    let totalMicroStepCount = 0;
    return preparationGroups.map(
      ({ _groupID, steps, microSteps, ...group }) =>
        ({
          ...group,
          steps: steps.map(({ _stepID, ...step }) => {
            totalStepCount++;
            return {
              ...step,
              _stepID: _stepID ?? (stepIDRef.current++).toString(),
              _stepNumber: totalStepCount,
            };
          }),
          microSteps: microSteps?.map(({ _microStepID, ...microStep }) => {
            totalMicroStepCount++;
            return {
              ...microStep,
              _microStepID:
                _microStepID ?? (microStepIDRef.current++).toString(),
              _microStepNumber: totalMicroStepCount,
            };
          }),
          _groupID: _groupID ?? (groupIDRef.current++).toString(),
        } as DecoratedPreparationGroup)
    );
  }, [preparationGroups, stepIDRef, microStepIDRef, groupIDRef]);

  const resolvedGroupRichTextBuild =
    CKEDITOR_BUILDS[
      preparationGroupRichTextConfiguration?.build ?? "Minimalist"
    ];

  const resolvedStepRichTextBuild =
    CKEDITOR_BUILDS[preparationStepRichTextConfiguration?.build ?? "Block"];

  const resolvedStepRichTextConfig = useCKEditorConfig(
    preparationStepRichTextConfiguration,
    currentOrganization,
    model.id as string
  );
  const onUpload = useCKUpload(currentOrganization, currentUser);

  const onGroupUpdate = useCallback(
    (operations: PreparationGroupUpdateOperation[]) => {
      const updatedPreparationGroups = update(decoratedPreparationGroups, {
        $splice: operations.map(([groupIndex, removeCount, group]) =>
          group
            ? [groupIndex, removeCount, group as DecoratedPreparationGroup]
            : [groupIndex, removeCount]
        ),
      });
      setValue("preparationGroups", updatedPreparationGroups);
    },
    [setValue, decoratedPreparationGroups]
  );

  const onStepUpdate = useCallback(
    (operations: StepUpdateOperation[]) => {
      const byGroupIndex = operations.reduce(
        (acc, [groupIndex, ingredientsIndex, removeCount, step]) => {
          if (!acc[groupIndex]) {
            acc[groupIndex] = [];
          }
          acc[groupIndex].push([
            ingredientsIndex,
            removeCount,
            step as DecoratedPreparationStep | undefined,
          ]);
          return acc;
        },
        {} as Record<number, [number, number, DecoratedPreparationStep?][]>
      );
      let updatedPreparationGroups = decoratedPreparationGroups;
      Object.entries(byGroupIndex).forEach(([groupIndex, ops]) => {
        updatedPreparationGroups = update(updatedPreparationGroups, {
          [groupIndex]: {
            steps: {
              $splice: ops.map(([stepIndex, removeCount, step]) =>
                step ? [stepIndex, removeCount, step] : [stepIndex, removeCount]
              ),
            },
          },
        });
      });
      setValue("preparationGroups", updatedPreparationGroups);
    },
    [setValue, decoratedPreparationGroups]
  );

  const onMicroStepUpdate = useCallback(
    (operations: StepUpdateOperation[]) => {
      const byGroupIndex = operations.reduce(
        (acc, [groupIndex, ingredientsIndex, removeCount, microStep]) => {
          if (!acc[groupIndex]) {
            acc[groupIndex] = [];
          }
          acc[groupIndex].push([
            ingredientsIndex,
            removeCount,
            microStep as DecoratedPreparationMicroStep | undefined,
          ]);
          return acc;
        },
        {} as Record<number, [number, number, DecoratedPreparationMicroStep?][]>
      );
      let updatedPreparationGroups = decoratedPreparationGroups;
      Object.entries(byGroupIndex).forEach(([groupIndex, ops]) => {
        updatedPreparationGroups = update(updatedPreparationGroups, {
          [groupIndex]: {
            microSteps: {
              $splice: ops.map(([stepIndex, removeCount, microStep]) =>
                microStep
                  ? [stepIndex, removeCount, microStep]
                  : [stepIndex, removeCount]
              ),
            },
          },
        });
      });
      setValue("preparationGroups", updatedPreparationGroups);
    },
    [setValue, decoratedPreparationGroups]
  );

  const { defer: deferSelectingAssets, deferRef: selectingAssets } =
    useDeferredPromise<ContentSummary[] | null>();
  const [assetSelectorContextualOptions, setAssetSelectorContextualOptions] =
    useState<{ types?: string[] } | undefined>(undefined);
  const [assetSelectorToggle, setAssetSelectorToggle] = useState(false);
  const resolvedAssetSelectorOptions = useMemo(() => {
    let typesToFilter =
      assetSelectorContextualOptions && assetSelectorContextualOptions.types;
    if (typesToFilter) {
      let configuredContentTypes =
        preparationStepAssetSelectorConfiguration?.contentTypes;
      let configuredExternalContentTypes =
        preparationStepAssetSelectorConfiguration?.externalTypes;
      let filteredContentTypes =
        configuredContentTypes?.filter((contentType) =>
          typesToFilter?.includes(contentType)
        ) || [];
      let filterExternalContentTypes =
        configuredExternalContentTypes?.filter((contentType) =>
          typesToFilter?.includes(contentType)
        ) || [];
      return preparationStepAssetSelectorConfiguration
        ? {
            ...preparationStepAssetSelectorConfiguration,
            ...{
              contentTypes: filteredContentTypes,
              externalTypes: filterExternalContentTypes,
            },
          }
        : null;
    }

    return preparationStepAssetSelectorConfiguration ?? null;
  }, [
    preparationStepAssetSelectorConfiguration,
    assetSelectorContextualOptions,
  ]);

  const openAssetSelector = useCallback(
    (options?: { types?: string[]; limitSelection?: number }) => {
      setAssetSelectorContextualOptions(options);
      setAssetSelectorToggle(true);

      // resolve any unsettled promises before creating a new one
      selectingAssets.current?.resolve(null);
      return deferSelectingAssets().promise;
    },
    [
      deferSelectingAssets,
      selectingAssets,
      setAssetSelectorToggle,
      setAssetSelectorContextualOptions,
    ]
  );

  const closeAssetSelector = useCallback(() => {
    selectingAssets.current?.resolve([]);

    setAssetSelectorToggle(false);
  }, [selectingAssets, setAssetSelectorToggle]);

  const onAssetSelectorSubmit = useCallback(
    (assets: ContentSummary[]) => {
      selectingAssets.current?.resolve(assets);
    },
    [selectingAssets]
  );
  return (
    <>
      {assetSelectorToggle && resolvedAssetSelectorOptions && (
        <AssetSelector
          config={resolvedAssetSelectorOptions}
          currentOrganizationID={currentOrganization.organizationId}
          onClose={closeAssetSelector}
          onSubmit={onAssetSelectorSubmit}
          cdnHost={cdnHost}
          currentUser={currentUser}
        />
      )}
      <RecipePreparationList
        onGroupUpdate={onGroupUpdate}
        onStepUpdate={onStepUpdate}
        onMicroStepUpdate={onMicroStepUpdate}
        preparationGroups={decoratedPreparationGroups}
        groupRichTextBuild={resolvedGroupRichTextBuild}
        stepRichTextBuild={resolvedStepRichTextBuild}
        stepRichTextConfig={resolvedStepRichTextConfig}
        onUpload={onUpload}
        showMicroSteps={showMicroSteps ?? false}
        selectAssets={openAssetSelector}
      />
    </>
  );
}

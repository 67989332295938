import { defineMessages } from "react-intl";

export const FIELD_LEGENDS = defineMessages({
  additionalContent: {
    defaultMessage: "Additional Content",
    description: "fieldsetLegends.additionalContent",
  },
  address: {
    defaultMessage: "Address",
    description: "fieldsetLegends.address",
  },
  advancedCriteria: {
    defaultMessage: "Advanced criteria",
    description: "fieldsetLegends.advancedCriteria",
  },
  awards: { defaultMessage: "Awards", description: "fieldsetLegends.awards" },
  bundleContainer: {
    defaultMessage: "Bundle Container",
    description: "fieldsetLegends.bundleContainer",
  },
  bundleSubtype: {
    defaultMessage: "Bundle Subtype",
    description: "fieldsetLegends.bundleSubtype",
  },
  campaign: {
    defaultMessage: "Campaign",
    description: "fieldsetLegends.campaign",
  },
  captionContest: {
    defaultMessage: "Caption Contest",
    description: "fieldsetLegends.captionContest",
  },
  categories: {
    defaultMessage: "Categories",
    description: "fieldsetLegends.categories",
  },
  collaborators: {
    defaultMessage: "Edited by",
    description: "fieldsetLegends.collaborators",
  },
  contactInformation: {
    defaultMessage: "Contact Information",
    description: "fieldsetLegends.contactInformation",
  },
  content: {
    defaultMessage: "Content",
    description: "fieldsetLegends.content",
  },
  contributors: {
    defaultMessage: "Byline",
    description: "fieldsetLegends.contributors",
  },
  crops: { defaultMessage: "Crops", description: "fieldsetLegends.crops" },
  desktopModePreview: {
    defaultMessage: "Show in Desktop Preview",
    description: "fieldsetLegends.desktopModePreview",
  },
  details: {
    defaultMessage: "Details",
    description: "fieldsetLegends.details",
  },
  eventDetails: {
    defaultMessage: "Event Details",
    description: "fieldsetLegends.eventDetails",
  },
  eventTime: {
    defaultMessage: "Event Time",
    description: "fieldsetLegends.eventTime",
  },
  eventVenue: {
    defaultMessage: "Event Venue",
    description: "fieldsetLegends.eventVenue",
  },
  exclusionCriteria: {
    defaultMessage: "Exclusion criteria",
    description: "fieldsetLegends.exclusionCriteria",
  },
  excludeFrom: {
    defaultMessage: "Exclude From:",
  },
  footer: { defaultMessage: "Footer", description: "fieldsetLegends.footer" },
  fullscreenModePreview: {
    defaultMessage: "Show in Responsive Preview",
    description: "fieldsetLegends.responsiveModePreview",
  },
  history: {
    defaultMessage: "History",
    description: "fieldsetLegends.history",
  },
  inclusionCriteria: {
    defaultMessage: "Inclusion criteria",
    description: "fieldsetLegends.inclusionCriteria",
  },
  ingredients: {
    defaultMessage: "Ingredients",
    description: "fieldsetLegends.ingredients",
  },
  interactiveOverride: {
    defaultMessage: "Interactive Override",
    description: "fieldsetLegends.interactiveOverride",
  },
  interludeOverride: {
    defaultMessage: "Interlude Override",
    description: "fieldsetLegends.interludeOverride",
  },
  relatedArtists: {
    defaultMessage: "Related Artists",
    description: "fieldsetLegends.relatedArtists",
  },
  items: { defaultMessage: "Items", description: "fieldsetLegends.items" },
  manufacturer: {
    defaultMessage: "Manufacturer",
    description: "fieldsetLegends.manufacturer",
  },
  mediaInfo: {
    defaultMessage: "Item info",
    description: "fieldsetLegends.mediaInfo",
  },
  miscellaneous: {
    defaultMessage: "Miscellaneous",
    description: "fieldsetLegends.miscellaneous",
  },
  mobileModePreview: {
    defaultMessage: "Show in Mobile Preview",
    description: "fieldsetLegends.mobileModePreview",
  },
  newQuery: {
    defaultMessage: "Search Query",
    description: "fieldsetLegends.searchQuery",
  },
  searchCriteria: {
    defaultMessage: "Search criteria",
    description: "fieldsetLegends.searchCriteria",
  },
  offers: { defaultMessage: "Offers", description: "fieldsetLegends.offers" },
  pageLayout: {
    defaultMessage: "Page Layout",
    description: "fieldsetLegends.pageLayout",
  },
  paymentInformation: {
    defaultMessage: "Payment Information",
    description: "fieldsetLegends.paymentInformation",
  },
  photos: { defaultMessage: "Photos", description: "fieldsetLegends.photos" },
  preparation: {
    defaultMessage: "Preparation",
    description: "fieldsetLegends.preperation",
  },
  preview: {
    defaultMessage: "Preview",
    description: "fieldsetLegends.preview",
  },
  previewInformation: {
    defaultMessage: "Preview information",
    description: "fieldsetLegends.previewInformation",
  },
  printFields: {
    defaultMessage: "Print",
    description: "fieldsetLegends.printFields",
  },
  homepageRecirc: {
    defaultMessage: "Homepage & Recirculation",
    description: "fieldsetLegends.homepageRecirc",
  },
  eventSchedule: {
    defaultMessage: "Event Schedule",
    description: "fieldsetLegends.eventSchedule",
  },
  sponsorship: {
    defaultMessage: "Sponsorship",
    description: "fieldsetLegends.sponsorship",
  },
  createLiveUpdate: {
    defaultMessage: "Create Live Update",
    description: "fieldsetLegends.createLiveUpdate",
  },
  promotion: {
    defaultMessage: "Promotion",
    description: "fieldsetLegends.promotion",
  },
  publishData: {
    defaultMessage: "Publishing",
    description: "fieldsetLegends.publishData",
  },
  publishInformation: {
    defaultMessage: "Publish information",
    description: "fieldsetLegends.publishInformation",
  },
  rating: {
    defaultMessage: "Rating",
    description: "fieldsetLegends.rating",
  },
  readMore: {
    defaultMessage: "Read More",
    description: "fieldsetLegends.readMore",
  },
  related: {
    defaultMessage: "Related Content",
    description: "fieldsetLegends.related",
  },
  readOnlyInformation: {
    defaultMessage: "Read-only Information",
    description: "fieldsetLegends.readOnlyInformation",
  },
  relatedProducts: {
    defaultMessage: "Related Products",
    description: "fieldsetLegends.relatedProducts",
  },
  relatedAudio: {
    defaultMessage: "Related Audio",
    description: "fieldsetLegends.relatedAudio",
  },
  seo: { defaultMessage: "SEO", description: "fieldsetLegends.seo" },
  settings: {
    defaultMessage: "Settings",
    description: "fieldsetLegends.settings",
  },
  social: { defaultMessage: "Social", description: "fieldsetLegends.social" },
  socialMedia: {
    defaultMessage: "Social media",
    description: "fieldsetLegends.social",
  },
  contributorSpotlight: {
    defaultMessage: "Contributor Spotlight",
    description: "fieldsetLegends.contributorSpotlight",
  },
  targetReference: {
    defaultMessage: "Target Reference",
    description: "fieldsetLegends.targetReference",
  },
  taxonomy: {
    defaultMessage: "Taxonomy",
    description: "fieldsetLegends.taxonomy",
  },
  location: {
    defaultMessage: "Location",
    description: "fieldsetLegends.location",
  },
  time: { defaultMessage: "Time", description: "fieldsetLegends.time" },
  yield: { defaultMessage: "Yield", description: "fieldsetLegends.yield" },
  meta: { defaultMessage: "Meta", description: "fieldsetLegends.meta" },
  audio: { defaultMessage: "Audio", description: "fieldNames.audio" },
  external: {
    defaultMessage: "External References",
    description: "fieldNames.external",
  },
  thirdPartyMedia: {
    defaultMessage: "Third Party Media",
    description: "fieldNames.thirdPartyMedia",
  },
  brandmag: { defaultMessage: "Brandmag", description: "fieldNames.brandmag" },
  adv: { defaultMessage: "Advertisement", description: "fieldNames.adv" },
  venues: { defaultMessage: "Venues", description: "fieldNames.venues" },
  contactPoint: {
    defaultMessage: "Contact Information",
    description: "fieldNames.contactPoint",
  },
  consent: { defaultMessage: "Consent", description: "fieldNames.consent" },
  designers: {
    defaultMessage: "Designers",
    description: "fieldNames.designers",
  },
  previousDesigners: {
    defaultMessage: "Previous Designers",
    description: "fieldNames.previousDesigners",
  },
});

import styled from "styled-components";
import { Label, Select } from "@components";
import { useDefinedMessages } from "@hooks";
import { EllipsisVerticalIcon } from "@condenast/gemini/icons";
import { CustomFilterWrapper } from "./CustomFilterWrapper";
import { FormattedMessage } from "react-intl";
import { AdvancedSearchOptions } from "../types";

const FilterWrapper = styled.div`
  margin-bottom: var(--spacing-xs);

  & {Label} {
    margin-bottom: var(--spacing-xxs);
  }
`;

const StyledSelect = styled(Select)`
  width: fit-content;
  grid-template-columns: 100%;
  grid-gap: 0px;
  ul {
    display: none;
  }
  ul[role="listbox"] {
    width: max-content;
    //44px is the height of the select box
    transform: translate(0px, 44px) !important;
  }
`;

export const MoreFilter = (props: {
  currentFilters: AdvancedSearchOptions;
  onChange: (filters: AdvancedSearchOptions) => void;
  syndicationEnabled: boolean;
  moreFilters?: React.ReactElement;
}) => {
  const { currentFilters, onChange, syndicationEnabled, moreFilters } = props;
  const { display } = currentFilters;
  const { translateFieldName } = useDefinedMessages();

  const displayOptions = [
    {
      value: "",
      label: translateFieldName("Exclude hidden content (default)"),
    },
    { value: "all", label: translateFieldName("Include hidden content") },
    { value: "archived", label: translateFieldName("Hidden content only") },
    {
      value: "issyndicatee",
      label: translateFieldName("View syndicated content only"),
    },
  ];

  return (
    <CustomFilterWrapper icon={EllipsisVerticalIcon} displayText={"More"}>
      <FilterWrapper>{moreFilters ? moreFilters : <></>}</FilterWrapper>
      {syndicationEnabled && (
        <FilterWrapper>
          <Label htmlFor="display-content">
            <FormattedMessage defaultMessage="Display Options" />
          </Label>
          <StyledSelect
            id="display-content"
            value={display || ""}
            onChange={(value: string | undefined) => {
              if (value !== null && value !== undefined && value !== display) {
                onChange({
                  display: value && value.length ? value : undefined,
                });
              }
            }}
            options={displayOptions?.map((option) => {
              return {
                label: option.label,
                value: option.value,
              };
            })}
          />
        </FilterWrapper>
      )}
    </CustomFilterWrapper>
  );
};

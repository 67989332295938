import { MainSearchAdapter } from "@types";
import { AllContentAdapterFactory } from "./all";
import { MediaContentAdapterFactory } from "./media";
import { EditorialContentAdapterFactory } from "./editorial";
import {
  MainSearchAdapterFactoryContext,
  MainSearchAdapterTypes,
} from "../types";

const adapters = {
  [MainSearchAdapterTypes.All]: AllContentAdapterFactory,
  [MainSearchAdapterTypes.Media]: MediaContentAdapterFactory,
  [MainSearchAdapterTypes.Editorial]: EditorialContentAdapterFactory,
};

function hasAdapter(name: string): name is keyof typeof adapters {
  return name in adapters;
}

function getAdapterFactory(name: string) {
  if (hasAdapter(name)) {
    return adapters[name];
  }
  return AllContentAdapterFactory;
}

export function createMainSearchAdapter(
  type: string,
  context: MainSearchAdapterFactoryContext
): MainSearchAdapter {
  const factory = getAdapterFactory(type);
  return factory(context);
}

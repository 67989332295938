import {
  GetConfigs,
  GetConfigsVariables,
  GetCurrentUser_currentUser,
  ParticipantData,
} from "@types";
import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { SocketIOContext } from "@contexts";
import * as Sentry from "@sentry/browser";
import { useQuery } from "@apollo/client";
import { Queries } from "@gql";

type Message = {
  event: string;
  data: unknown;
};

type PresenceData = {
  // Brand code
  brand: string;

  // ID of the content item
  id: string;

  participants: ParticipantData[];
};

type SaveData = {
  // transient uuid
  uuid: string;

  // User ID
  id: string;

  // User userName
  uid: string;

  // User full name
  fullName: string;

  // User Slack ID
  slackUserId: string;

  // revision metadata
  metaData: { author: string };

  space: {
    capabilities: { locking: boolean };
    id: string;
    brand: string;
  };
};

type SyndicationResyncAvailableData = {
  space: {
    capabilities: { locking: boolean };
    id: string;
    brand: string;
  };
};

type SyndicationResyncCompletedData = {
  space: {
    capabilities: { locking: boolean };
    id: string;
    brand: string;
  };
};

export function usePresence(
  userId: string,
  organizationId: string,
  namespace?: string
) {
  const { getConnection, clientId } = useContext(SocketIOContext);
  const socket = useRef<ReturnType<typeof getConnection>>(null);
  const [isConnected, setIsConnected] = useState(false);
  const messages = useRef<Message[]>([]);
  const [participants, setParticipants] = useState<ParticipantData[]>([]);
  const [saveData, setSaveData] = useState<SaveData | null>(null);
  const [syndicationResyncAvailableData, setSyndicationResyncAvailableData] =
    useState<SyndicationResyncAvailableData | null>(null);
  const [syndicationResyncCompletedData, setSyndicationResyncCompletedData] =
    useState<SyndicationResyncCompletedData | null>(null);

  const configResponse = useQuery<GetConfigs, GetConfigsVariables>(
    Queries.GET_CONFIGS,
    {
      variables: { organizationId, userId },
    }
  );

  const flush = useCallback(() => {
    if (socket.current?.connected) {
      while (messages.current.length) {
        const message = messages.current.shift();
        if (message) {
          socket.current?.emit(message.event, message.data);
        }
      }
    }
  }, []);

  const sendMessage = useCallback(
    (event: string, data?: unknown) => {
      messages.current.push({ event, data });
      flush();
    },
    [flush]
  );

  const join = useCallback(
    (id: string, brand: string, user: GetCurrentUser_currentUser) => {
      sendMessage("join", {
        uuid: clientId,
        id: user.id,
        uid: user.userName,
        fullName: `${user.firstName} ${user.lastName}`,
        slackUserId: user.slackUserId,
        space: {
          capabilities: { locking: false },
          id,
          brand,
        },
      });
    },
    [sendMessage, clientId]
  );

  const leave = useCallback(() => {
    sendMessage("leave");
  }, [sendMessage]);

  const notifySave = useCallback(
    (
      id: string,
      brand: string,
      user: GetCurrentUser_currentUser,
      metaData: { author: string }
    ) => {
      sendMessage("save", {
        uuid: clientId,
        id: user.id,
        uid: user.userName,
        fullName: `${user.firstName} ${user.lastName}`,
        slackUserId: user.slackUserId,
        space: {
          capabilities: { locking: false },
          id,
          brand,
        },
        metaData: metaData,
      } as SaveData);
    },
    [sendMessage, clientId]
  );

  useEffect(() => {
    if (!socket.current && configResponse.data) {
      socket.current = getConnection(
        configResponse.data.configs.copilotWSHost,
        namespace
      );

      socket.current?.on("connect", () => {
        setIsConnected(true);
        flush();
      });
      socket.current?.on("disconnect", () => {
        setIsConnected(false);
      });
      socket.current?.on("connect_error", (error: Error) => {
        Sentry.captureException(error);
      });
      socket.current?.on("change", (data: PresenceData) => {
        setParticipants(data.participants);
      });
      socket.current?.on("save", (data: SaveData) => {
        setSaveData(data);
      });
      socket.current?.on(
        "syndicationResyncAvailable",
        (data: SyndicationResyncAvailableData) => {
          setSyndicationResyncAvailableData(data);
        }
      );
      socket.current?.on(
        "syndicationResyncCompleted",
        (data: SyndicationResyncCompletedData) => {
          setSyndicationResyncCompletedData(data);
        }
      );
      return () => {
        socket.current?.off("connect");
        socket.current?.off("disconnect");
        socket.current?.off("connect_error");
      };
    } else {
      return () => {};
    }
  }, [configResponse.data, namespace, flush, getConnection]);

  useEffect(() => {
    return () => leave();
  }, [leave]);

  return {
    join,
    leave,
    notifySave,
    isConnected,
    saveData,
    participants,
    syndicationResyncAvailableData,
    syndicationResyncCompletedData,
  };
}

import {
  RichTextConfiguration,
  Organization,
  CKEditorConfiguration,
  MediaOverrideConnection,
  MediaOverrides,
  PhotoMediaOverride,
  PhotosLede_V1,
  VideosLede_V1,
  MediaNode,
  PhotosOverrideEdge,
  CneVideosOverrideEdge,
} from "@types";
import {
  encodeAffiliateParams,
  decodeAffiliateParams,
} from "@condenast/cna-st-codec";
import { removeNullValues } from "@lib";

const REDIRECTOR_URLS =
  /^(https?:)?\/\/([a-zA-Z0-9]+\.)?cna\.st\/affiliate-link\//;
const REDIRECTOR_URL_EXCEPTIONS = [
  /^(https?:\/\/)?shop-links\.co\//, // Narrativ
  /^(https?:\/\/)?www\.glamour\.com\//, // glamour
  /^(https?:\/\/)?(www\.)?bonsai\.to\//, // bonsai
];

function decodeAffiliateLink(encoded: string) {
  const { brand: organizationId, offerUrl } = decodeAffiliateParams(encoded);
  return [organizationId, offerUrl];
}

function encodeAffiliateLink(
  organizationId: string,
  offerUrl: string,
  contentId: string
) {
  return encodeAffiliateParams({
    origin: contentId,
    brand: organizationId,
    offerUrl,
  });
}

export function resolveCKEditorConfiguration(
  configs: RichTextConfiguration | null,
  organization: Organization
) {
  if (!configs) {
    return {};
  }

  let config: CKEditorConfiguration = {
    cdnHost: organization.metadata.mediaDomain,
    host: organization.url,
  };
  const { plugins, include, urlExpansions } = configs;
  if (plugins) {
    config.plugins = removeNullValues(plugins);
  }
  if (urlExpansions) {
    config.urlExpansions = urlExpansions.map(
      ({ pattern, type, height, width }) => ({
        pattern: pattern,
        type: type,
        height: height ?? undefined,
        width: width ?? undefined,
      })
    );
  }
  if (include) {
    config.include = include;
  }

  return config;
}

export function getCKEditorAffiliateLinkHandler(
  organizationId: string,
  contentId: string
) {
  return function ({ url, action }: { url: string; action: string }) {
    let matchRedirectorUrl = url.match(REDIRECTOR_URLS);
    let urlOut;
    if (action === "DECODE" && matchRedirectorUrl) {
      // Only decode redirector URLs
      let [, offerUrl] = decodeAffiliateLink(
        url.replace(matchRedirectorUrl[0], "").replace(/\?.*$/, "")
      );
      urlOut = offerUrl;
    } else if (action === "ENCODE" && organizationId) {
      // Only encode if not a redirector URL - double encode
      // OR if domain is NOT flagged as an exception
      let skipAction = REDIRECTOR_URL_EXCEPTIONS.some((exceptionRegx) =>
        url.match(exceptionRegx)
      );
      if (skipAction || matchRedirectorUrl) {
        urlOut = url;
      } else {
        let encodedLink = encodeAffiliateLink(organizationId, url, contentId);
        urlOut = `https://cna.st/affiliate-link/${encodedLink}`;
        // we may want to change this from a hard coded variable to using a
        // redirect endpoint that can be fetched from the editorial service
        // (as is done in ember). then again, the way we check matchRedirectUrl
        // relies on this static url.
      }
    } else {
      urlOut = url;
    }
    return {
      url: urlOut,
    };
  };
}

export const setMediaOverridesV2 = (
  OverridesValue: MediaOverrideConnection,
  OverrideEdge: CneVideosOverrideEdge | PhotosOverrideEdge,
  mOverrides: MediaOverrides[],
  photoLedeV1: PhotosLede_V1,
  videosLedeV1: VideosLede_V1
) => {
  let updatedMediaOverrides = {} as MediaOverrideConnection;
  if (OverridesValue?.edges.length) {
    if (
      OverrideEdge.relName === "photosLede" ||
      OverrideEdge.relName === "videosLede"
    ) {
      const newMediaOverrideEdge = OverridesValue.edges.filter(
        (el) =>
          el.node.id !== OverrideEdge.node.id &&
          el.__typename !== OverrideEdge.__typename
      );
      newMediaOverrideEdge.push(OverrideEdge);
      updatedMediaOverrides = {
        ...OverridesValue,
        edges: newMediaOverrideEdge,
      } as MediaOverrideConnection;
    }
  } else {
    const mediaOverrides_V1 = mOverrides[0];
    if (mediaOverrides_V1 && mediaOverrides_V1.relName === "photosLede") {
      updatedMediaOverrides = migrateMediaOverridesToV2(
        OverridesValue,
        mOverrides,
        photoLedeV1
      );
    }
    if (
      videosLedeV1?.edges[0]?.node?.id &&
      OverrideEdge?.relName === "photosLede"
    ) {
      updatedMediaOverrides = updateDefaultCneVideoControlsToV2(
        OverridesValue,
        videosLedeV1.edges[0]?.node?.id,
        updatedMediaOverrides
      );
    }
    if (OverrideEdge && OverrideEdge.relName === "photosLede") {
      const tempMediaOverrides = updatedMediaOverrides?.edges?.filter(
        (el) =>
          el.__typename !== "PhotosOverrideEdge" && el.relName !== "photosLede"
      );
      updatedMediaOverrides = {
        ...updatedMediaOverrides,
        edges: tempMediaOverrides
          ? [...tempMediaOverrides, OverrideEdge]
          : [OverrideEdge],
      };
    } else {
      const tempOverrides =
        updatedMediaOverrides?.edges && updatedMediaOverrides.edges;
      updatedMediaOverrides = {
        ...OverridesValue,
        edges: tempOverrides
          ? [...tempOverrides, { ...OverrideEdge }]
          : [{ ...OverrideEdge }],
        __typename: "MediaOverrideConnection",
      };
    }
  }
  return updatedMediaOverrides;
};

function migrateMediaOverridesToV2(
  OverridesValue: MediaOverrideConnection,
  mo_V1: MediaOverrides[],
  photoLede: PhotosLede_V1
) {
  let updatedMediaOverrides = {} as MediaOverrideConnection;
  const mediaOverrides_V1 = mo_V1[0];

  if (mediaOverrides_V1 && mediaOverrides_V1.relName === "photosLede") {
    const photosMO: PhotoMediaOverride[] = mediaOverrides_V1.overrides.map(
      (el) => {
        return {
          ...el,
          __typename: "PhotoMediaOverride",
        };
      }
    );
    if (photoLede?.edges.length) {
      const pEdge = {
        node: {
          id: photoLede?.edges[0]?.node?.id,
          __typename: "ContentSummary",
        },
        pagelayoutMediaOverrides: photosMO,
        __typename: "PhotosOverrideEdge",
        relName: "photosLede",
      } as PhotosOverrideEdge;
      updatedMediaOverrides = {
        ...OverridesValue,
        edges: [{ ...pEdge }],
        __typename: "MediaOverrideConnection",
      };
    }
  }

  return updatedMediaOverrides;
}

export const defaultCneVideosOverrideEdge = {
  node: {
    id: "",
    __typename: "ContentSummary",
  } as MediaNode,
  cneVideoOverrides: {
    disableAutoplay: false,
    muted: false,
    loopVideo: false,
    disableAds: false,
    continuousPlay: false,
    __typename: "CneVideoOverride",
  },
  relName: "videosLede",
  __typename: "CneVideosOverrideEdge",
} as CneVideosOverrideEdge;

function updateDefaultCneVideoControlsToV2(
  OverridesValue: MediaOverrideConnection,
  cneVideosLedeId: string,
  newMediaOverrideCon: MediaOverrideConnection
) {
  let updatedMediaOverrides = {} as MediaOverrideConnection;
  updatedMediaOverrides = {
    ...OverridesValue,
    edges: [
      ...newMediaOverrideCon.edges,
      {
        ...defaultCneVideosOverrideEdge,
        node: {
          id: cneVideosLedeId,
          __typename: "ContentSummary",
        },
      },
    ],
    __typename: "MediaOverrideConnection",
  };
  return updatedMediaOverrides;
}

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { required } from "@condenast/copilot-validators";
import { noEmptyAccordionHeaders } from "../validators/markdown-accordions";

import type { ValidationEnvironment, ValidationContext } from "@lib";
import type { FormFor_content_Livestoryupdate } from "@types";
import { noEmptySlideshows } from "../validators/markdown-slideshows";

const { object } = validators;

const livestoryupdateValidators = object({
  body: object({
    content: required()
      .andAlso(noEmptySlideshows())
      .andAlso(noEmptyAccordionHeaders()),
  }).catch(([{ message }]) => {
    return [{ path: [], message }];
  }),
}).catch((livestoryupdateErrors) => {
  let categoryTaxonomyErrors = livestoryupdateErrors.filter((error) =>
    error.path.find((pathItem) => pathItem === "categoryTaxonomies")
  );
  if (categoryTaxonomyErrors) {
    categoryTaxonomyErrors.forEach(
      (categoryTaxonomyError) =>
        (categoryTaxonomyError.path = [
          categoryTaxonomyError.path.join("").replace("categoryTaxonomies", ""),
        ])
    );
  }
  return livestoryupdateErrors;
});

export default function validatelivestoryupdate(
  livestoryupdate: FormFor_content_Livestoryupdate,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(
    livestoryupdate,
    build(livestoryupdateValidators),
    context,
    env
  );
}

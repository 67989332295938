import { ContentTypeIcon } from "@condenast/gemini/icons";
import type { SearchAdapterFactory } from "../types";
import { capitalize } from "@lib";
import { SearchProvider, AssetSelectorSearchOptions } from "@types";
import { useDefinedMessages } from "@hooks";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { pick } from "lodash";
import { ContentFilters } from "./contentFilters";

const Label: FC<{
  contentType?: string;
}> = (props) => {
  const { contentType } = props;

  const { translateContentType } = useDefinedMessages();
  return (
    <>
      {contentType ? (
        <>
          <ContentTypeIcon contentType={contentType} size="regular" />
          <span>
            {translateContentType(contentType, 1, capitalize(contentType))}
          </span>
        </>
      ) : (
        <span>
          <FormattedMessage defaultMessage="Copilot (All)" />
        </span>
      )}
    </>
  );
};

export const ContentAdapterFactory: SearchAdapterFactory = (
  context,
  adapterName
) => {
  const {
    search,
    dateRangeOptions,
    statusOptions,
    contentTypes,
    organizationId,
    currentUser,
    categoryConfigType,
  } = context;
  const contentTypeConfig = contentTypes.find(
    (type) => type.value === adapterName
  );
  const atLeastOnePublishableTypeConfigured = contentTypes.some(
    (type) => type.publish
  );

  return {
    id: adapterName,
    type: "content",
    onSearch: (filters: AssetSelectorSearchOptions) => {
      const baseAdapterFilters = [
        "sort",
        "query",
        "contributors",
        "collaborators",
      ];

      const publishableContentFilters = [
        "status",
        "types",
        "date",
        "categories",
      ];

      const curatedListFilters = ["curationSubType"];

      const additionalFilters = [];

      if (
        (adapterName === "all" && atLeastOnePublishableTypeConfigured) ||
        contentTypeConfig?.publish
      ) {
        additionalFilters.push(...publishableContentFilters);
      }
      if (adapterName === "curatedlist") {
        additionalFilters.push(...curatedListFilters);
      }

      const availableFiltersForAdapter = [
        ...baseAdapterFilters,
        ...additionalFilters,
      ];

      let filtersForAdapter = pick(filters, availableFiltersForAdapter);

      filtersForAdapter.types = contentTypeConfig
        ? [contentTypeConfig?.value]
        : filtersForAdapter.types ||
          contentTypes.map((contentTypeConfig) => contentTypeConfig.value);

      search(SearchProvider.content, filtersForAdapter);
    },
    Label: () => <Label contentType={contentTypeConfig?.value} />,
    Filters: ({ filters, setFilters, takeoverView }) => (
      <ContentFilters
        filters={filters}
        setFilters={setFilters}
        dateRangeOptions={dateRangeOptions}
        statusOptions={statusOptions}
        contentType={contentTypeConfig?.value}
        contentTypes={contentTypes}
        organizationId={organizationId}
        currentUser={currentUser}
        categoryConfigType={categoryConfigType}
        takeoverView={takeoverView}
      />
    ),
  };
};

import { useState, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { Select } from "@components";
import { Button, Checkbox } from "@condenast/gemini";
import { ChevronDownIcon, ChevronUpIcon } from "@condenast/gemini/icons";
import { useUniqueId } from "@hooks";
import {
  DistributionChannelName,
  findDistributionChannelsByName,
} from "@types";

export type DistributionChannel<T = unknown> = {
  name: DistributionChannelName;
  metadata?: T | null;
};

export type AppleNewsMetadata = {
  isPaid?: boolean;
};

export type WebOOMetadata = {
  searchable?: boolean;
  recommendable?: boolean;
};

export type DistributionChannelConfig = {
  name: DistributionChannelName;
  enabled: boolean | null;
  premiumEnabled?: boolean | null;
  webAndPremiumAllowed?: boolean | null;
};

const AppleNewsSelect = styled(Select)`
  max-width: 14rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  grid-gap: 0 var(--spacing-xs);
  grid-template-areas:
    "label control"
    "message message";

  label {
    font-size: var(--font-size);
    font-weight: normal;
  }
`;
const Accordion = styled.div`
  padding-top: var(--spacing-sm);
`;
const AccordionTrigger = styled(Button)`
  display: flex;
  color: black;
  padding: 0;
  align-items: center;
  width: 100%;
  svg {
    margin-left: auto;
    left: 0;
  }
  &:not(:disabled):hover,
  &:not(:disabled):active,
  &:not(:disabled)[aria-pressed="true"],
  &:not(:disabled)[aria-expanded="true"] {
    background-color: transparent;
    cursor: default;
    color: black;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  input:disabled + * {
    color: var(--color-gray-4);
  }
`;

const AccordionBody = styled.div`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-sm);
  margin-top: var(--spacing-sm);
  padding-top: var(--spacing-sm);
  border-top: 1px solid ${(props) => props.theme.DividerColor};
  grid-template-areas:
    "accordion-body-label"
    "accordion-body-controls";
`;

const AccordionBodyLabel = styled.span`
  grid-area: accordion-body-label;
`;

const AccordionBodyControls = styled.div`
  display: flex;
  grid-area: accordion-body-controls;
  align-items: center;
  gap: 6px;
`;

const DISTRIBUTION_CHANNEL_METADATA_NAMES = [
  "isPaid",
  "recommendable",
  "searchable",
] as const;

const [
  APPLE_NEWS_PREMIUM_METADATA_FLAG,
  RECCOMENDABLE_METADATA_FLAG,
  SEARCHABLE_METADATA_FLAG,
] = DISTRIBUTION_CHANNEL_METADATA_NAMES;

const APPLE_NEWS_FREE_SELECT_VALUE = "free";

const filterDistributionChannelsByName = (
  name: string,
  distributionChannels: DistributionChannel[]
): DistributionChannel[] => {
  return distributionChannels?.filter(
    (distributionChannel) => distributionChannel.name != name
  );
};

export function DistributionChannels(props: {
  distributionChannelsConfig: DistributionChannelConfig[];
  distributionChannels: DistributionChannel[];
  onChange: (distributionChannels: DistributionChannel[]) => void;
}) {
  const { distributionChannelsConfig, distributionChannels, onChange } = props;
  const intl = useIntl();
  const id = useUniqueId();
  const [isOpened, setIsOpened] = useState(true);
  const [
    publishWebConfig,
    publishAppleNewsConfig,
    publishFacebookNewsConfig,
    publishMobileAppConfig,
    publishWeb,
    publishAppleNews,
    publishFacebookNews,
    publishMobileApp,
  ] = [
    findDistributionChannelsByName(
      DistributionChannelName.WebOwnedAndOperated,
      distributionChannelsConfig
    ),
    findDistributionChannelsByName(
      DistributionChannelName.AppleNews,
      distributionChannelsConfig
    ),
    findDistributionChannelsByName(
      DistributionChannelName.FacebookNews,
      distributionChannelsConfig
    ),
    findDistributionChannelsByName(
      DistributionChannelName.MobileApp,
      distributionChannelsConfig
    ),
    findDistributionChannelsByName(
      DistributionChannelName.WebOwnedAndOperated,
      distributionChannels
    ) as DistributionChannel<WebOOMetadata>,
    findDistributionChannelsByName(
      DistributionChannelName.AppleNews,
      distributionChannels
    ) as DistributionChannel<AppleNewsMetadata>,
    findDistributionChannelsByName(
      DistributionChannelName.FacebookNews,
      distributionChannels
    ),
    findDistributionChannelsByName(
      DistributionChannelName.MobileApp,
      distributionChannels
    ),
  ];

  const isAppleNewsPremium =
    publishAppleNews && publishAppleNews?.metadata?.isPaid;

  const isAppleNewsPremiumConfigured =
    publishAppleNewsConfig && publishAppleNewsConfig.premiumEnabled;

  const webAndPremiumAllowed =
    publishAppleNewsConfig && publishAppleNewsConfig.webAndPremiumAllowed;

  const shouldDisablePublishToWebCheckbox =
    distributionChannels?.length === 1 &&
    distributionChannels[0].name ===
      DistributionChannelName.WebOwnedAndOperated;

  const onAppleNewsTypeSelect = useCallback(
    (value: string | undefined) => {
      let isPaid = value === APPLE_NEWS_PREMIUM_METADATA_FLAG;
      let updatedAppleNewsDistributionChannel: DistributionChannel<AppleNewsMetadata> =
        {
          name: DistributionChannelName.AppleNews,
          metadata: {
            isPaid,
          },
        };

      let filteredDistributionChannels = filterDistributionChannelsByName(
        DistributionChannelName.AppleNews,
        distributionChannels
      );
      if (isPaid) {
        if (!webAndPremiumAllowed) {
          filteredDistributionChannels = filterDistributionChannelsByName(
            DistributionChannelName.WebOwnedAndOperated,
            filteredDistributionChannels
          );
        }
      }

      onChange([
        ...filteredDistributionChannels,
        updatedAppleNewsDistributionChannel,
      ]);
    },
    [distributionChannels, webAndPremiumAllowed, onChange]
  );

  const appendDistributionChannel = useCallback(
    (
      name: DistributionChannelName,
      distributionChannels: DistributionChannel[]
    ): DistributionChannel[] => {
      let distributionChannelsCopy = [...distributionChannels];
      const metadata =
        name === DistributionChannelName.AppleNews ? { isPaid: false } : {};
      let newDistributionChannel: DistributionChannel = {
        name,
        metadata,
      };
      if (name === DistributionChannelName.WebOwnedAndOperated) {
        (newDistributionChannel.metadata as WebOOMetadata)[
          SEARCHABLE_METADATA_FLAG
        ] = true;
        (newDistributionChannel.metadata as WebOOMetadata)[
          RECCOMENDABLE_METADATA_FLAG
        ] = true;

        if (!webAndPremiumAllowed && publishAppleNews?.metadata?.isPaid) {
          let filteredDistributionChannels = filterDistributionChannelsByName(
            DistributionChannelName.AppleNews,
            distributionChannelsCopy
          );
          filteredDistributionChannels.push({
            name: DistributionChannelName.AppleNews,
            metadata: {},
          });
          distributionChannelsCopy = filteredDistributionChannels;
        }
      }
      return [...distributionChannelsCopy, newDistributionChannel];
    },
    [publishAppleNews?.metadata, webAndPremiumAllowed]
  );

  const toggleDistributionChannel = useCallback(
    (name: DistributionChannelName, enable: boolean) => {
      let newDistributionChannels = [];
      if (enable) {
        newDistributionChannels = appendDistributionChannel(
          name,
          distributionChannels
        );
      } else {
        newDistributionChannels = filterDistributionChannelsByName(
          name,
          distributionChannels
        );
      }
      return onChange(newDistributionChannels);
    },
    [appendDistributionChannel, onChange, distributionChannels]
  );

  useEffect(() => {
    if (!distributionChannels.length) {
      onChange(
        appendDistributionChannel(
          DistributionChannelName.WebOwnedAndOperated,
          distributionChannels
        )
      );
    }
  }, [onChange, distributionChannels, appendDistributionChannel]);

  const appleNewsSelectOptions = [
    {
      value: APPLE_NEWS_FREE_SELECT_VALUE,
      label: intl.formatMessage({
        defaultMessage: "Free",
        description: "Option to select Apple news free",
      }),
    },
    {
      value: APPLE_NEWS_PREMIUM_METADATA_FLAG,
      label: intl.formatMessage({
        defaultMessage: "Plus",
        description: "Option to select Apple news plus",
      }),
    },
  ];

  return (
    <Accordion>
      <AccordionTrigger
        id={`accordionTrigger-${id}`}
        treatment="borderless"
        aria-expanded={isOpened}
        aria-controls={`accordionBody-${id}`}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.preventDefault();
          event.stopPropagation();
          setIsOpened(!isOpened);
        }}
      >
        <span>
          <FormattedMessage defaultMessage="Advanced Settings" />
        </span>
        {isOpened ? (
          <ChevronUpIcon size="small" />
        ) : (
          <ChevronDownIcon size="small" />
        )}
      </AccordionTrigger>

      {isOpened && (
        <AccordionBody
          id={`accordionBody-${id}`}
          aria-labelledby={`accordionTrigger-${id}`}
        >
          <AccordionBodyLabel>
            <FormattedMessage defaultMessage="Distribution Channels" />
          </AccordionBodyLabel>
          <AccordionBodyControls>
            {publishWebConfig?.enabled && (
              <>
                <StyledCheckbox
                  aria-labelledby={`webLabel-${id}`}
                  disabled={shouldDisablePublishToWebCheckbox}
                  checked={!!publishWeb}
                  onChange={(enable: boolean) => {
                    toggleDistributionChannel(
                      DistributionChannelName.WebOwnedAndOperated,
                      enable
                    );
                  }}
                />
                <span id={`webLabel-${id}`}>
                  <FormattedMessage defaultMessage="Web" />
                </span>
              </>
            )}
            {publishAppleNewsConfig?.enabled && (
              <>
                <StyledCheckbox
                  aria-labelledby={`appleNewsLabel-${id}`}
                  checked={!!publishAppleNews}
                  onChange={(enable: boolean) => {
                    toggleDistributionChannel(
                      DistributionChannelName.AppleNews,
                      enable
                    );
                  }}
                />
                <span id={`appleNewsLabel-${id}`}>
                  <FormattedMessage defaultMessage="Apple News" />
                </span>
                {isAppleNewsPremiumConfigured && (
                  <AppleNewsSelect
                    value={
                      isAppleNewsPremium
                        ? APPLE_NEWS_PREMIUM_METADATA_FLAG
                        : APPLE_NEWS_FREE_SELECT_VALUE
                    }
                    aria-label={"Apple-news tier"}
                    multiple={false}
                    disabled={!publishAppleNews}
                    options={appleNewsSelectOptions}
                    id={`appleNewsOptions-${id}`}
                    onChange={onAppleNewsTypeSelect}
                  />
                )}
              </>
            )}

            {publishFacebookNewsConfig?.enabled && (
              <>
                <StyledCheckbox
                  aria-labelledby={`facebookNewsLabel-${id}`}
                  checked={!!publishFacebookNews}
                  onChange={(enable: boolean) => {
                    toggleDistributionChannel(
                      DistributionChannelName.FacebookNews,
                      enable
                    );
                  }}
                />
                <span id={`facebookNewsLabel-${id}`}>
                  <FormattedMessage defaultMessage="Facebook News" />
                </span>
              </>
            )}
            {publishMobileAppConfig?.enabled && (
              <>
                <StyledCheckbox
                  aria-labelledby={`mobileAppLabel-${id}`}
                  checked={!!publishMobileApp}
                  onChange={(enable: boolean) => {
                    toggleDistributionChannel(
                      DistributionChannelName.MobileApp,
                      enable
                    );
                  }}
                />
                <span id={`mobileAppLabel-${id}`}>
                  <FormattedMessage defaultMessage="Mobile App" />
                </span>
              </>
            )}
          </AccordionBodyControls>
        </AccordionBody>
      )}
    </Accordion>
  );
}
DistributionChannels.displayName = "DistributionChannels";

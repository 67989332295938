import { SetStateAction, useContext } from "react";
import { Link } from "@components";
import { useDefinedMessages } from "@hooks";
import { PreviewContext } from "../PreviewPage/PreviewPage.context";
import styled from "styled-components";

const StyleWrapper = styled.div`
  margin-block-end: var(--spacing-sm);
`;

const Message = styled.span`
  font: var(--font-small-statements);
  button[class*="Link"] {
    font: var(--font-small-statements);
    text-decoration: underline;
    color: ${(props) => props.theme.SubheadingColor};
  }
`;
export function ViewMode(props: {
  view: SetStateAction<"mobile" | "desktop" | "fullscreen">;
}) {
  let { translateFieldLegend } = useDefinedMessages();
  const { view } = props;
  const [viewMode, setViewMode] = useContext(PreviewContext);

  if (viewMode === view) return null;

  return (
    <StyleWrapper id={`§${view}-view-toggle`}>
      <Message>
        <Link
          as="button"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            setViewMode(view);
          }}
        >
          {translateFieldLegend(`${view}ModePreview`)}
        </Link>
      </Message>
    </StyleWrapper>
  );
}
ViewMode.displayName = "Control(ViewMode)";

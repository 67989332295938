import { useCallback, useMemo } from "react";
import { Checkbox, Field, Spinner } from "@components";
import { useDefinedMessages, useUniqueId } from "@hooks";
import {
  ControlProps,
  GetRootCategoryWithChildren,
  GetRootCategoryWithChildrenVariables,
  FormFor_form_controls_CategoryOptionsFormControl,
  FormFor_content_CuratedSearch_categoryTaxonomies as TCategoryTaxonomy,
} from "@types";
import { Queries } from "@gql";
import { useQuery } from "@apollo/client";
import styled from "styled-components";

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-xxs) 0;
  grid-template-rows: auto auto;
  grid-template-areas:
    "label"
    "control";
  margin: var(--spacing-xs) 0 var(--spacing-md) 0;
`;

const CheckBoxGroup = styled.div`
  display: flex;
  margin: var(--spacing-xxs);
  width: 100%;
  flex-direction: row;
  align-items: center;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  input:disabled + * {
    color: ${(props) => props.theme.SecondaryDisabledColor};
  }
`;

const StyledSpan = styled.span`
  margin: 0 var(--spacing-md) 0 var(--spacing-xxs);
`;

function isTaxonomyManagedByControl(slug: string, hierarchy: string) {
  return slug === hierarchy;
}

export function CategoryOptions(
  props: ControlProps<FormFor_form_controls_CategoryOptionsFormControl>
) {
  const { currentOrganization, model, setValue, hierarchy } = props;
  const { data: rootData, loading } = useQuery<
    GetRootCategoryWithChildren,
    GetRootCategoryWithChildrenVariables
  >(Queries.GET_ROOT_CATEGORY_WITH_CHILDREN, {
    variables: {
      organizationId: currentOrganization.organizationId,
      slug: hierarchy,
    },
  });

  const uniqueId = useUniqueId();
  const { translateFieldName } = useDefinedMessages();

  const children = useMemo(() => {
    return rootData?.rootCategoryWithChildren[0]?.content?.results ?? [];
  }, [rootData?.rootCategoryWithChildren]);

  const modelValue = useMemo(() => {
    return (model["categoryTaxonomies"] ?? []) as TCategoryTaxonomy[];
  }, [model]);

  const selections =
    modelValue.find(({ taxonomy }) =>
      isTaxonomyManagedByControl(taxonomy, hierarchy)
    )?.categories ?? [];

  const toggleSelections = useCallback(
    (categoryId: string, checked: boolean) => {
      const unmanagedTaxonomies = modelValue.filter(
        ({ taxonomy }) => !isTaxonomyManagedByControl(taxonomy, hierarchy)
      );

      const managedTaxonomy = modelValue.find(({ taxonomy }) =>
        isTaxonomyManagedByControl(taxonomy, hierarchy)
      );

      const newCategorySelection = children.find((child) => {
        return child.id === categoryId;
      });

      let newModelValue = [];

      if (checked) {
        newModelValue = [
          ...unmanagedTaxonomies,
          {
            taxonomy: hierarchy,
            categories: [
              ...(managedTaxonomy?.categories.filter(
                ({ id }) =>
                  newCategorySelection && id !== newCategorySelection.id
              ) ?? []),
              newCategorySelection,
            ],
          },
        ];
      } else {
        newModelValue = [
          ...unmanagedTaxonomies,
          {
            taxonomy: hierarchy,
            categories: [
              ...(managedTaxonomy?.categories.filter(
                ({ id }) => id !== categoryId
              ) ?? []),
            ],
          },
        ];
      }

      setValue("categoryTaxonomies", newModelValue);
    },
    [children, hierarchy, modelValue, setValue]
  );

  return children.length ? (
    <Wrapper
      id={`CategoryOptions-${uniqueId}`}
      label={translateFieldName(hierarchy)}
    >
      {loading ? (
        <SpinnerWrapper>
          <Spinner size="small" />
        </SpinnerWrapper>
      ) : (
        <CheckBoxGroup>
          {children.map((child) => {
            return (
              <CheckboxWrapper key={child.id}>
                <StyledCheckbox
                  aria-labelledby={`child-${child.id}`}
                  checked={!!selections.find(({ id }) => id === child.id)}
                  onChange={(checked: boolean) =>
                    toggleSelections(child.id, checked)
                  }
                />
                <StyledSpan id={`child-${child.id}`}>
                  {translateFieldName(child.name || "")}
                </StyledSpan>
              </CheckboxWrapper>
            );
          })}
        </CheckBoxGroup>
      )}
    </Wrapper>
  ) : (
    <></>
  );
}
CategoryOptions.displayName = "Control(CategoryOptions)";

import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  Search,
  SearchVariables,
  ContentSummary,
  DashboardRefresh,
  defaultRefreshType,
  PageDetail,
} from "@types";
import { useDebouncedCallback, useDefinedMessages } from "@hooks";
import { SearchResult, Spinner } from "@components";
import { Queries } from "@gql";
import { useLazyQuery } from "@apollo/client";
import { updateRefreshStatus } from "../Helper/helper-functions";
import { useNavigate } from "react-router-dom";
import { normalizeContentSummary } from "@lib";

const MessageWrapper = styled.h5`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Body = styled.div<{ noContent: boolean }>`
  min-height: 450px;
  background-color: var(--color-gray-5);
  ${(props) =>
    props.noContent
      ? "display: flex; justify-content: center; align-items: center;"
      : "height: fit-content;"}
`;

const SearchResultList = styled.ul`
  background-color: ${(props) => props.theme.Background};
`;

const SpinnerWrapper = styled.div`
  display: inline-block;
  transform: translate(-50%, -50%);
  top: 45%;
  left: 50%;
  position: absolute;
`;

const SEARCH_DEBOUNCE_INTERVAL = 300; // milliseconds

export function ResultList({
  cdnHost,
  updateCurrentPageDetail,
  refreshAPIs,
  setRefreshAPIs,
  filterType,
  variables,
}: {
  cdnHost: string | null | undefined;
  updateCurrentPageDetail: (currentPageDetail: PageDetail) => void;
  refreshAPIs: DashboardRefresh;
  setRefreshAPIs: (status: DashboardRefresh) => void;
  filterType: string;
  variables: SearchVariables;
}) {
  const [fetchedData, setFetchData] = useState<Search>();

  const [getSectionData, { loading, data, error }] = useLazyQuery<
    Search,
    SearchVariables
  >(Queries.SEARCH, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setFetchData(data);
      updateCurrentPageDetail({
        limit: variables.limit || 0,
        offset: variables.offset || 0,
        totalCount: data?.search.totalResults || 0,
      });
      updateRefreshStatus(setRefreshAPIs, filterType, refreshAPIs, "success");
    },
    onError: () => {
      updateRefreshStatus(setRefreshAPIs, filterType, refreshAPIs, "failed");
    },
  });

  const [searchVariables, setSearchVariables] = useState<
    SearchVariables | undefined
  >(undefined);

  useEffect(() => {
    if (JSON.stringify(variables) !== JSON.stringify(searchVariables)) {
      setSearchVariables(variables);
    }
  }, [variables, searchVariables]);

  const debounce = useDebouncedCallback(
    () => {
      if (
        !searchVariables ||
        JSON.stringify(variables) !== JSON.stringify(searchVariables)
      ) {
        return getSectionData({
          variables,
        });
      }
    },
    SEARCH_DEBOUNCE_INTERVAL,
    [searchVariables, variables]
  );

  useEffect(() => {
    debounce();
  }, [debounce]);

  useEffect(() => {
    const type = refreshAPIs[filterType] as defaultRefreshType;
    const { status, refreshState, name } = type;
    if (
      status === true &&
      filterType === name &&
      !loading &&
      refreshState === "idle"
    ) {
      getSectionData({
        variables,
      });
    } else if (
      status === true &&
      filterType === name &&
      loading &&
      refreshState === "idle"
    ) {
      updateRefreshStatus(setRefreshAPIs, filterType, refreshAPIs, "loading");
    }
  }, [
    loading,
    refreshAPIs,
    filterType,
    setRefreshAPIs,
    getSectionData,
    variables,
  ]);

  const navigate = useNavigate();

  const { translateContentType } = useDefinedMessages();

  const searchResult = useMemo(() => {
    if (error) {
      return (
        <MessageWrapper>
          <FormattedMessage
            defaultMessage={
              "Error fetching data. Please try again or contact support."
            }
          />
        </MessageWrapper>
      );
    } else if (loading && (!fetchedData || !fetchedData?.search.totalResults)) {
      return (
        <SpinnerWrapper>
          <Spinner size="small" />
        </SpinnerWrapper>
      );
    }

    const results = (fetchedData?.search?.results
      ?.map((result) => normalizeContentSummary(result, translateContentType))
      .filter(Boolean) ?? []) as ContentSummary[];

    // Check if there are valid search results
    if (cdnHost && results.length) {
      return (
        <SearchResultList>
          {results.map((item, index) => {
            return (
              <li key={item.id}>
                <SearchResult
                  to={
                    item?.editUrl ? new URL(item?.editUrl || "").pathname : ""
                  }
                  id={`${item.id}-${index}`}
                  result={item}
                  treatment="slat-result"
                  cdnHost={cdnHost}
                  onClick={() =>
                    item.editUrl && navigate(new URL(item.editUrl).pathname)
                  }
                  blur={loading}
                  loading={(loading && loading.toString()) || undefined}
                />
              </li>
            );
          })}
        </SearchResultList>
      );
    }

    // Default case when there are no results
    return (
      <MessageWrapper>
        <FormattedMessage
          defaultMessage={"No Recent Activity Found"}
          description={"Empty message for the My Work section"}
        />
      </MessageWrapper>
    );
  }, [cdnHost, fetchedData, loading, error, navigate]);

  return (
    <Body noContent={!fetchedData?.search?.results.length ? true : false}>
      {searchResult}
    </Body>
  );
}
ResultList.displayName = "ResultList";

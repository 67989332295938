import {
  BasicValidator,
  ValidationBuilder,
  builderFor,
} from "@condenast/cross-check-dsl";
import type { ContentConnectionFields, FormFor_content_Bundle } from "@types";
import { ValidationError } from "@condenast/cross-check";

class BundleValidator extends BasicValidator<unknown, void> {
  static validatorName = "bundle";

  async validate(value: FormFor_content_Bundle): Promise<ValidationError[]> {
    const errors: ValidationError[] = [];
    let advertiser: ContentConnectionFields =
      value.advertiser as ContentConnectionFields;

    if (value.isNative === true) {
      if (!advertiser || !advertiser.edges.length) {
        errors.push({
          path: ["advertiser", "edges"],
          message: { name: "tooShortLength", details: { min: 1 } },
        });
      }
      if (!value.campaignUrl) {
        errors.push({
          path: ["campaignUrl"],
          message: { name: "url", details: ["absoulte"] },
        });
      }
      if (!value.bylineOption) {
        errors.push({
          path: ["bylineOption"],
          message: { name: "blank", details: null },
        });
      }
      if (!value.bylineVariant) {
        errors.push({
          path: ["bylineVariant"],
          message: { name: "blank", details: null },
        });
      }
    }

    return errors;
  }
}

export function bundleValidations(): ValidationBuilder<unknown> {
  return builderFor(BundleValidator)();
}

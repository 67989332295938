import { useQuery } from "@apollo/client";
import { Queries } from "@gql";
import { singularize, config, transformToPascalCase } from "@lib";
import { GetConfigs, GetConfigs_configs, GetCurrentUser } from "@types";
import {
  useParams,
  useMatch,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { hasMutation } from "../useContentMutation";

const BUNDLE_TYPE_ROUTE_MAPPING: Record<string, string> = {
  "verso-channel": "channels",
};

const CONTENT_TYPE_FLAGS: Record<string, keyof GetConfigs_configs> = {
  activity: "v2Activities",
  advertiser: "v2NativeAdvertisers",
  article: "v2Articles",
  audiofile: "v2AudioFiles",
  bar: "v2Bars",
  brand: "v2Brands",
  bundle: "v2Bundles",
  business: "v2Businesses",
  cartoon: "v2Cartoons",
  clip: "v2Clips",
  contributor: "v2Contributors",
  cookbook: "v2Cookbooks",
  curatedlist: "v2CuratedLists",
  curatedsearch: "v2CuratedSearches",
  event: "v2Events",
  externallink: "v2ExternalLinks",
  external: "v2ExternalReferences",
  gallery: "v2Galleries",
  hotel: "v2Hotels",
  infopage: "v2InfoPages",
  livestory: "v2LiveStories",
  menu: "v2Menus",
  musicalbum: "v2MusicAlbums",
  musicgroup: "v2MusicGroups",
  musicrecording: "v2MusicRecordings",
  musicrelease: "v2MusicReleases",
  nativearticle: "v2NativeArticles",
  nativegallery: "v2NativeGalleries",
  nativerecipe: "v2NativeRecipes",
  nativevideo: "v2NativeVideos",
  person: "v2People",
  photo: "v2Photos",
  product: "v2Products",
  recipe: "v2Recipes",
  redirect: "v2Redirects",
  restaurant: "v2Restaurants",
  review: "v2Reviews",
  ship: "v2Ships",
  shop: "v2Shops",
  skiresort: "v2SkiResorts",
  spa: "v2Spas",
  tagpage: "v2TagPages",
  taxonomy: "v2Taxonomies",
  venue: "v2Venues",
  rcalist: "v2RcaLists",
  city: "v2Cities",
};

export function useV1Redirect() {
  let { copilotCode } = useParams() as { copilotCode: string };
  let [params] = useSearchParams();
  const navigate = useNavigate();

  const matchContentTypeRoute = useMatch(
    "/:copilotCode/:contentType/:idOrAction"
  );
  const matchSearchRoute = useMatch("/:copilotCode/search");
  const isUserRoute = useMatch("/:copilotCode/users/*") !== null;
  const isCommerceProductMFE =
    useMatch("/:copilotCode/commerce-product/*") !== null;
  const matchPageRoute = useMatch(":copilotCode/pages/:pageType/:idOrAction");

  let noRedirect =
    params.get("noRedirect") != null ||
    config.environment === "development" ||
    isUserRoute ||
    isCommerceProductMFE;

  let userResponse = useQuery<GetCurrentUser>(Queries.GET_CURRENT_USER, {
    skip: noRedirect,
  });

  let organization = userResponse.data?.currentUser?.organizations.find(
    (organization) => organization.metadata.copilotCode === copilotCode
  );

  let configsResponse = useQuery<GetConfigs>(Queries.GET_CONFIGS, {
    variables: {
      userId: userResponse.data?.currentUser?.id,
      organizationId: organization?.organizationId,
    },
    skip: noRedirect || !userResponse.data,
  });

  let loading = userResponse.loading || configsResponse.loading;

  if (noRedirect) {
    return loading;
  }

  if (configsResponse.data) {
    const { configs } = configsResponse.data;
    let shouldRedirect = false;
    let url = configs.copilotUrl + window.location.pathname;
    if (matchContentTypeRoute !== null && matchContentTypeRoute.params) {
      // check if content type route should be redirected
      let { contentType } = matchContentTypeRoute.params;
      let singularizedContentType = singularize(
        contentType || ""
      ).toLowerCase();
      shouldRedirect =
        configs[CONTENT_TYPE_FLAGS[singularizedContentType]] === false ||
        !hasMutation(singularizedContentType);
    } else if (matchSearchRoute !== null && configs.v2SearchPage === false) {
      // user is on search route and v2SearchPage flag is false
      shouldRedirect = true;
      if (window.location.search) {
        url =
          configs.copilotUrl +
          window.location.pathname +
          window.location.search;
      }
    } else if (matchPageRoute !== null && matchPageRoute.params.pageType) {
      const { copilotCode, pageType, idOrAction } = matchPageRoute.params;

      const redirectKey =
        Object.keys(BUNDLE_TYPE_ROUTE_MAPPING).find(
          (key) => BUNDLE_TYPE_ROUTE_MAPPING[key] === pageType
        ) || null;
      // feature flag name is the bundles type not the vanity url segment
      // eg. "v2VersoChannel" and not "v2Channels"
      let bundleTypeFeatureFlagSegment = redirectKey || pageType;
      let featureFlagForBundleType = `v2${transformToPascalCase(
        bundleTypeFeatureFlagSegment,
        "-"
      )}` as keyof GetConfigs_configs;
      if (!configs[featureFlagForBundleType]) {
        shouldRedirect = true;
        url = `${configs.copilotUrl}/${copilotCode}/bundles/${idOrAction}`;
      } else if (idOrAction === "create") {
        return loading;
      } else if (pageType in BUNDLE_TYPE_ROUTE_MAPPING) {
        const internalRedirectSegment = BUNDLE_TYPE_ROUTE_MAPPING[pageType];
        const newPath = `/${copilotCode}/pages/${internalRedirectSegment}/${idOrAction}`;
        navigate(newPath, { replace: true });
        return loading;
      }
    }

    if (shouldRedirect) {
      window.location.replace(url);
    }
  }
  return loading;
}

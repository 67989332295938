import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import {
  email,
  markdownLinks,
  optional,
  required,
} from "@condenast/copilot-validators";
import { FormFor_content_Person } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";

const { object } = validators;

function onlyRequireOne(path: string, errors: any[]) {
  if (errors.length === 5) {
    // this means all name fields failed validation!
    return [{ path: [path], message: { name: "blank", details: null } }];
  }
  return [];
}

function requireOne(pathForError: string, ...attributesToCheck: any[]) {
  let requiredProperties = attributesToCheck.reduce((obj, attribute) => {
    obj[attribute] = required();
    return obj;
  }, {});
  return object(requiredProperties).catch(
    onlyRequireOne.bind(null, pathForError)
  );
}

const personValidators = object({
  email: optional(email()),
  bio: markdownLinks().on("publish"),
}).andAlso(
  requireOne(
    "At least one name field",
    "givenName",
    "name",
    "additionalName",
    "alternateName",
    "familyName"
  )
);

export default function validatePerson(
  person: FormFor_content_Person,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(person, build(personValidators), context, env);
}

import type {
  ContentConnectionFields,
  RelationshipInput,
  ContentSummaryFields,
} from "@types";

export function contentConnection(
  input?: ContentConnectionFields
): RelationshipInput[] {
  return (
    input?.edges?.map((edge) => {
      const node = edge?.node as ContentSummaryFields;
      return {
        id: node.id,
        contentType: node.contentType,
      };
    }) ?? []
  );
}

import { Select as SelectImplementation } from "@components";
import { useDefinedMessages } from "@hooks";
import {
  ControlProps,
  FormFor_form_controls_ActivitySubtypeSelectFormControl,
  FormFor_form_controls_ActivitySubtypeSelectFormControl_subtypeOptions as SubtypeOption,
} from "@types";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { get } from "@lib";
import styled from "styled-components";
import { Row } from "../Row";

const FlexibleRow = styled(Row)`
  > * {
    flex: 1 calc(30 * var(--spacing-xs));
  }
`;

function getFeatureOptions(
  subtypeOptions: SubtypeOption[] | null,
  subtype: string | null,
  secondaryType: string | null
) {
  const optionsForSubtype = subtypeOptions?.find(
    (option) => option.subtype === subtype
  );
  const optionsForSecondaryType = optionsForSubtype?.secondaryTypeOptions?.find(
    (option) => option.secondaryType === secondaryType
  );
  return optionsForSecondaryType?.features?.length
    ? optionsForSecondaryType.features
    : optionsForSubtype?.features ?? [];
}

export function ActivitySubtypeSelect(
  props: ControlProps<FormFor_form_controls_ActivitySubtypeSelectFormControl>
) {
  const { model, setValue, errors, subtypeOptions } = props;
  const intl = useIntl();
  const { translateFieldName, translatePlaceholder, translateSelectOption } =
    useDefinedMessages();

  const subtype = get(model, "subtype") as string | null;
  const secondaryType = get(model, "secondaryType") as string | null;
  const features = get(model, "features") as string[];

  const subtypeLabel = translateFieldName("subtype");
  const secondaryTypeLabel = translateFieldName("secondaryType");
  const featuresLabel = translateFieldName("features");

  const onSubtypeChange = useCallback(
    (newSubtype) => {
      setValue("subtype", newSubtype);
      setValue("secondaryType", "");
      setValue("features", []);
    },
    [setValue]
  );

  const onSecondaryTypeChange = useCallback(
    (newSecondaryType) => {
      const newFeatureOptions = getFeatureOptions(
        subtypeOptions,
        subtype,
        newSecondaryType
      );
      const newFeatures = features.filter((feature) =>
        newFeatureOptions.includes(feature)
      );

      setValue("secondaryType", newSecondaryType);
      if (newFeatures.length !== features.length) {
        setValue("features", newFeatures);
      }
    },
    [setValue, subtypeOptions, subtype, features]
  );

  const onFeaturesChange = useCallback(
    (newFeatures) => {
      setValue("features", newFeatures);
    },
    [setValue]
  );

  const subtypeSelectOptions = useMemo(
    () =>
      subtypeOptions?.map(({ subtype }) => ({
        value: subtype,
        label: translateSelectOption("activitySubtypeOptions", subtype),
      })) ?? [],
    [subtypeOptions]
  );

  const placeholder = intl.formatMessage({
    defaultMessage: "Select an Option",
    description: "Placeholder text for a Select component",
  });
  const secondaryTypeSelectOptions = useMemo(() => {
    const optionsForSelectedSubtype = subtypeOptions?.find(
      (option) => option.subtype === subtype
    );
    return optionsForSelectedSubtype?.secondaryTypeOptions?.length
      ? [
          { value: "", label: placeholder },
          ...optionsForSelectedSubtype.secondaryTypeOptions.map(
            ({ secondaryType }) => ({
              value: secondaryType,
              label:
                translateSelectOption(
                  "activitySecondaryTypeOptions",
                  secondaryType
                ) ?? [],
            })
          ),
        ]
      : [];
  }, [subtypeOptions, subtype]);

  const featureSelectOptions = useMemo(() => {
    const featureOptions = getFeatureOptions(
      subtypeOptions,
      subtype,
      secondaryType
    );
    return featureOptions.map((feature) => ({
      value: feature,
      label: translateSelectOption("activityFeatureOptions", feature),
    }));
  }, [subtypeOptions, subtype, secondaryType]);

  const subtypeErrors = useMemo(
    () => errors.filter(({ path }) => path[0] === "subtype"),
    [errors]
  );
  const secondaryTypeErrors = useMemo(
    () => errors.filter(({ path }) => path[0] === "secondaryType"),
    [errors]
  );
  const featureErrors = useMemo(
    () => errors.filter(({ path }) => path[0] === "features"),
    [errors]
  );

  return subtypeSelectOptions.length ? (
    <FlexibleRow>
      <SelectImplementation
        id={`ActivitySubtypeSelect_subtype`}
        label={subtypeLabel}
        multiple={false}
        value={subtype ?? undefined}
        onChange={onSubtypeChange}
        options={subtypeSelectOptions}
        placeholder={placeholder}
        errors={subtypeErrors}
      />
      {secondaryTypeSelectOptions.length ? (
        <SelectImplementation
          id={`ActivitySubtypeSelect_secondaryType`}
          label={secondaryTypeLabel}
          multiple={false}
          value={secondaryType ?? ""}
          onChange={onSecondaryTypeChange}
          options={secondaryTypeSelectOptions}
          errors={secondaryTypeErrors}
        />
      ) : (
        <span></span>
      )}
      {featureSelectOptions.length ? (
        <SelectImplementation
          id={`ActivitySubtypeSelect_features`}
          label={featuresLabel}
          multiple={true}
          value={features}
          onChange={onFeaturesChange}
          options={featureSelectOptions}
          placeholder={translatePlaceholder("activity.features")}
          errors={featureErrors}
        />
      ) : (
        <span></span>
      )}
    </FlexibleRow>
  ) : (
    <></>
  );
}

ActivitySubtypeSelect.displayName = "Control(ActivitySubtypeSelect)";

import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  GetCurrentUser_currentUser,
  PageLayoutCategory,
  MediaOverrides,
  CategoryTaxonomyFields,
} from "@types";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { GET_CONFIGS } from "../../../gql/queries";
import { PageLayout } from "../FormControl/Controls";
import { usePostMessage } from "./usePostMessage";

const PageLayoutPageWrapper = styled.div`
  background-color: var(--color-white);

  & section {
    margin-block-start: 0;
    border-radius: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    max-width: 100%;

    & h2 {
      display: none;
    }

    & h2 + div {
      padding: 0;
      border: none;
    }
  }
`;

type LegacyPhotosLede = {
  aspectRatios: LegacyAspectRatio[];
  id: string;
  filename: string;
  restrictCropping: boolean;
};

type LegacyAspectRatio = {
  format: string;
  url: string;
  height: number;
  name: string;
  modifications?: LegacyModifications;
  override: boolean;
  width: number;
};

export type LegacyModifications = {
  crop: {
    height: number;
    width: number;
    x: number;
    y: number;
  };
};

export const PageLayoutPage = (props: {
  currentUser: GetCurrentUser_currentUser;
}) => {
  const [state, setState] = useState({
    versoSettings: [] as PageLayoutCategory[],
    photosLede: {} as LegacyPhotosLede,
    mediaOverrides: [] as MediaOverrides[],
  });

  const normalizedModel = useMemo(() => {
    return {
      categoryTaxonomies: [
        {
          taxonomy: "verso-settings",
          categories: state.versoSettings.map((versoSettingCategory) => {
            return {
              ...versoSettingCategory,
              hierarchy: versoSettingCategory.hierarchyString
                .split("/")
                .reverse()
                .map((hierarchyStringPart: string) => {
                  return { slug: hierarchyStringPart };
                }),
            };
          }),
        },
      ],
      photosLede: {
        edges: state.photosLede.aspectRatios
          ? [
              {
                node: {
                  aspectRatios: state.photosLede.aspectRatios || [],
                  id: state.photosLede.id,
                  asset: {
                    filename: state.photosLede.filename,
                  },
                },
              },
            ]
          : [],
      },
      mediaOverrides: state.mediaOverrides || [],
    };
  }, [state]);

  let { copilotCode } = useParams() as { copilotCode: string };
  let userId = props.currentUser.id;

  let organizations = props.currentUser.organizations;
  let currentOrganization = organizations.find(
    (organization) => organization.metadata.copilotCode === copilotCode
  );

  if (currentOrganization == null) {
    throw new Error(
      `User has no access to the organization with the copilotCode ${copilotCode}`
    );
  }
  const messageHandler = useCallback(
    (data) => {
      if (data.action === "state_update") {
        setState((state) => ({
          ...state,
          ...data.content,
        }));
      }
    },
    [setState]
  );
  const { data: configs, loading: configsLoading } = useQuery(GET_CONFIGS, {
    variables: {
      organizationId: currentOrganization.organizationId,
      userId,
    },
  });
  const sendMessage = usePostMessage(
    messageHandler,
    configs?.configs.copilotUrl
  );
  const updateState = useCallback(
    (key, value) => {
      if (key === "categoryTaxonomies") {
        let versoSettings = value.find(
          (categoryTaxonomy: CategoryTaxonomyFields) =>
            categoryTaxonomy.taxonomy === "verso-settings"
        ).categories as PageLayoutCategory[];

        let firstArticleContentHeaderVariation =
          versoSettings &&
          versoSettings.length &&
          versoSettings.find((versoSetting) => {
            let hierarchyPath = versoSetting.hierarchy
              .map((hierarchyCategory) => {
                return hierarchyCategory.slug;
              })
              .reverse()
              .join("/");

            return hierarchyPath.includes(
              "article-settings/ContentHeader/variation"
            );
          });
        if (firstArticleContentHeaderVariation) {
          sendMessage("settings_update", firstArticleContentHeaderVariation);
        }
      } else if (key === "mediaOverrides") {
        sendMessage("media_overrides_update", value);
      }
    },
    [sendMessage]
  );

  if (configsLoading) {
    return null;
  }

  const pageLayoutProps = {
    //TODO This will be removed once PageLayout moves to controls
    currentOrganization,
    currentUser: props.currentUser,
    model: normalizedModel,
    errors: [],
    children: [],
    permissions: {
      publish: true,
      update: true,
    },
    setValue: updateState,
  };
  return (
    <PageLayoutPageWrapper>
      <PageLayout {...pageLayoutProps} />
    </PageLayoutPageWrapper>
  );
};

import { Button } from "@components";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  AnimatedEllipsisIcon,
  BinIcon,
  EditIcon,
} from "@condenast/gemini/icons";
import { useCallback } from "react";

const StyledPublishtBar = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  position: fixed;
  right: var(--spacing-sm);
  bottom: var(--spacing-sm);
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  align-items: center;
  padding: var(--spacing-xs);
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.MenuShadow};
`;

const ClearButton = styled(Button)`
  color: ${(props) => props.theme.Color};
`;

const ActionButton = styled(Button)`
  background: ${(props) => props.theme.SkeletonColor};
`;

const Divider = styled.div`
  height: 40px;
  border-right: 1px solid ${(props) => props.theme.DividerColor};
`;

type PublishBarProps = {
  onPublish?: (action?: "delete" | "editCredit") => void;
  publishing?: boolean;
  clearSelections: () => void;
  selectionCount: number;
  limit?: number;
  action: string | string[];
};

export const PublishBar = (props: PublishBarProps) => {
  const intl = useIntl();
  const { selectionCount, clearSelections, onPublish, publishing, action } =
    props;

  const Publish = useCallback(
    (action?: "delete" | "editCredit") => {
      onPublish?.(action);
    },
    [onPublish]
  );

  let hasDelete = action === "delete" || action.includes("delete");
  let hasEdit = action === "editCredit" || action.includes("editCredit");
  let hasPublish = action === "publish" || action.includes("publish");

  return (
    <StyledPublishtBar>
      {hasDelete ? (
        <ActionButton
          onClick={() => {
            Publish("delete");
          }}
          treatment="primary"
        >
          <BinIcon size="regular" />
          <FormattedMessage defaultMessage="Delete" />
        </ActionButton>
      ) : (
        <></>
      )}
      {hasEdit ? (
        <ActionButton
          onClick={() => {
            Publish("editCredit");
          }}
          treatment="primary"
        >
          <EditIcon size="regular" />
          <FormattedMessage defaultMessage="Edit Credit" />
        </ActionButton>
      ) : (
        <></>
      )}
      {hasDelete || hasEdit ? <Divider /> : <></>}
      <ClearButton onClick={clearSelections} treatment="borderless">
        {props.limit == null ? (
          <FormattedMessage
            defaultMessage={"Cancel"}
            values={{
              count: selectionCount,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage={"{count}/{limit} items selected"}
            values={{
              count: selectionCount,
              limit: props.limit,
            }}
          />
        )}
      </ClearButton>
      {publishing ? (
        <Button
          treatment="primary"
          aria-label={intl.formatMessage({
            defaultMessage: "Loading",
            description: "Accessibility label for spinners",
          })}
        >
          <AnimatedEllipsisIcon size="small" />
        </Button>
      ) : hasPublish ? (
        <Button onClick={() => Publish()} treatment="primary">
          <FormattedMessage defaultMessage="Publish and Submit" />
        </Button>
      ) : (
        <></>
      )}
    </StyledPublishtBar>
  );
};

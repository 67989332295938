import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { required, url } from "@condenast/copilot-validators";

import { FormFor_content_Redirect } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";

const { object } = validators;

const redirectValidators = object({
  target: url("https").catch(([{ message }]) => {
    return [{ path: [], message: { ...message, name: "invalid-url" } }];
  }),
  // Duplicate publish URI validation no need for only Redirects form.
  uri: required()
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message: { ...message, name: "publish-uri" } }];
    }),
});

export default function validateRedirect(
  redirect: FormFor_content_Redirect,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(redirect, build(redirectValidators), context, env);
}

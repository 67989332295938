import { FC } from "react";
import { ContentTypeIcon } from "@condenast/gemini/icons";
import type { SearchAdapterFactory } from "../types";
import {
  SearchProvider,
  GettyVideoType,
  GettySortOrder,
  AssetSelectorSearchOptions,
} from "@types";
import { Select } from "@components";
import { useIntl } from "react-intl";
import { useDefinedMessages } from "@hooks";
import styled from "styled-components";
import { pick } from "lodash";

const FILTER_KEYS = ["gettyVideoType", "gettySortOrder", "query"];

const StyledSelect = styled(Select)`
  grid-template-columns: 100%;
`;

const GettyContainer = styled.ul`
  display: flex;

  ul {
    min-width: 15rem;

    li {
      text-decoration: unset;
      min-width: 15rem;
      width: max-content;
      margin-right: var(--spacing-xs);
    }
  }
  li {
    min-width: 15rem;
    margin-right: var(--spacing-xs);
  }
`;

const FilterLabelSpan = styled.span`
  font: ${(props) => props.theme.FontLabel};
  color: ${(props) => props.theme.PrimaryColor};
`;

const SelectedFilterSpan = styled.span`
  color: ${(props) => props.theme.PrimaryColor};
`;

const GettyVideoLabel = () => {
  const intl = useIntl();
  return (
    <>
      <ContentTypeIcon contentType="getty" size="regular" />
      <span>
        {intl.formatMessage({
          defaultMessage: "Getty Video",
          description: "Search adapter name for Getty video",
        })}
      </span>
    </>
  );
};

const GettyVideoFilters: FC<{
  filters: AssetSelectorSearchOptions;
  setFilters: (filters: AssetSelectorSearchOptions) => void;
}> = (props) => {
  const { translateSelectOption, translateFieldName } = useDefinedMessages();
  const { filters, setFilters } = props;
  return (
    <GettyContainer>
      <li>
        <StyledSelect
          aria-label={"Video Type"}
          id="video-type"
          value={filters.gettyVideoType || "editorial"}
          onChange={(value: string | undefined) => {
            if (value) {
              setFilters({ ...filters, gettyVideoType: value });
            }
          }}
          options={Object.values(GettyVideoType).map((videoType) => {
            return {
              label: translateSelectOption("gettyvideo.videoType", videoType),
              value: videoType as string,
            };
          })}
          multiple={false}
          placeholder={
            <>
              <FilterLabelSpan>
                {translateFieldName("Video Type: ")}
              </FilterLabelSpan>
              <SelectedFilterSpan>
                {translateSelectOption(
                  "gettyvideo.videoType",
                  filters.gettyVideoType || "editorial"
                )}
              </SelectedFilterSpan>
            </>
          }
          alwaysUsePlaceholder={true}
        />
      </li>
      <li>
        <StyledSelect
          aria-label={"Sort By"}
          id="sort-order"
          value={filters.gettySortOrder || "most_popular"}
          onChange={(value: string | undefined) => {
            if (value) {
              setFilters({ ...filters, gettySortOrder: value });
            }
          }}
          options={Object.values(GettySortOrder).map((sortOrder) => {
            return {
              label: translateSelectOption("getty.sortOrder", sortOrder),
              value: sortOrder as string,
            };
          })}
          multiple={false}
          placeholder={
            <>
              <FilterLabelSpan>
                {translateFieldName("Sort By: ")}
              </FilterLabelSpan>
              <SelectedFilterSpan>
                {translateSelectOption(
                  "getty.sortOrder",
                  filters.gettySortOrder || "most_popular"
                )}
              </SelectedFilterSpan>
            </>
          }
          alwaysUsePlaceholder={true}
        />
      </li>
    </GettyContainer>
  );
};

export const GettyVideoAdapterFactory: SearchAdapterFactory = ({ search }) => {
  return {
    id: "gettyvideo",
    type: "external",
    onSearch: (filters?: AssetSelectorSearchOptions) => {
      const gettyVideoFilters = pick(filters, FILTER_KEYS);
      search(SearchProvider.gettyvideo, gettyVideoFilters);
    },
    Label: () => <GettyVideoLabel />,
    Filters: ({ filters, setFilters }) => (
      <GettyVideoFilters filters={filters} setFilters={setFilters} />
    ),
  };
};

import { useMemo } from "react";
import { NextIcon } from "@condenast/gemini/icons";
import { Button } from "@components";
import { useIntl } from "react-intl";
import styled, { DefaultTheme } from "styled-components";
import { SyndicationData } from "@types";
import { ThemeProvider } from "@contexts";

const ShowSyndicatedInfoButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
  color: ${(props) => props.theme.Color};
  svg {
    left: 0;
    margin-right: var(--spacing-xxs);
  }
  span {
    font: ${(props) => props.theme.FontSmallStatement};
    font-weight: 500;
  }
  margin-right: var(--spacing-xs);
  text-decoration: underline;
`;

function transformBlue(theme: DefaultTheme) {
  return {
    ...theme,
    Color: "var(--color-blue-100)",
  };
}

export function SyndicatedInfoDialog(props: {
  handleOnClick: (status: boolean) => void;
  syndications: SyndicationData;
  className?: string;
}) {
  const intl = useIntl();

  const { handleOnClick, syndications, className } = props;

  const syndicatedCount = useMemo(
    () => syndications?.targets?.length || 0,
    [syndications]
  );
  const showSyndicatedCount = useMemo(
    () => !syndications.sourceId,
    [syndications]
  );

  return (
    <>
      {showSyndicatedCount && (
        <ThemeProvider tint="blue" transform={transformBlue}>
          <ShowSyndicatedInfoButton
            treatment="text"
            disabled={!syndicatedCount}
            onClick={() => handleOnClick(true)}
            className={className}
            id="SyndicatedInfoButton"
          >
            <NextIcon size="small" />
            <span>
              {intl.formatMessage(
                {
                  defaultMessage:
                    "Syndicated {syndicatedCount, plural, =1 {# time} other {# times}}",
                  description: "Syndicated info button",
                },
                {
                  syndicatedCount,
                }
              )}
            </span>
          </ShowSyndicatedInfoButton>
        </ThemeProvider>
      )}
    </>
  );
}
SyndicatedInfoDialog.displayName = "SyndicatedInfoDialog";

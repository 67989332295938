import styled from "styled-components";
import { useState } from "react";
import { Button } from "@components";
import { BulletedListIcon, DownloadIcon } from "@condenast/gemini/icons";
import { useIntl, FormattedMessage } from "react-intl";
import { ContainerOptions } from "../ContainerOptions";
import {
  GetBundle_bundle,
  GetCurrentUser_currentUser,
  GetCurrentUser_currentUser_organizations,
} from "@types";

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(var(--spacing-xxl) * 7);
  background-color: ${(props) => props.theme.Background};
  padding: var(--spacing-lg) 0;
  margin-top: calc(var(--spacing-xxs) / 2);
  height: 100%;

  div[class*="Toggle"] {
    border-top: none;
  }
`;

const MenuHeader = styled.div`
  padding-left: calc(var(--spacing-lg) * 2);
  padding-right: var(--spacing-sm);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const HeaderText = styled.h1`
  color: ${(props) => props.theme.Color};
  font: ${(props) => props.theme.FontBody};
  font-weight: 700;
`;

const MenuContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: var(--spacing-xxs);
  overflow-y: auto;
  padding-left: calc(var(--spacing-lg) * 2);
  padding-right: var(--spacing-sm);
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${(props) => props.theme.DividerColor};
  margin-bottom: var(--spacing-md);
`;

const OpenButton = styled(Button)`
  margin-top: var(--spacing-sm);
  margin-left: var(--spacing-md);
  width: calc(var(--spacing-sm) * 3.5);
  height: calc(var(--spacing-sm) * 3.5);
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.SecondaryActiveBackground};
`;

const CollapseIcon = styled(DownloadIcon)`
  transform: rotate(90deg);
`;

const containers = [
  {
    id: "63468624e6c856a4d4d5f79e",
    curationContainerType: "verso-flat-package",
    curationType: "smart-list",
    hed: "Books flat Package",
    componentPreset: "Books",
  },
  {
    id: "id2",
    curationContainerType: "verso-ticker",
    curationType: "list",
    hed: "Ticker",
  },
  {
    id: "634fb246b5cf26d3551005ff",
    curationContainerType: "verso-flat-package",
    curationType: "smart-list",
    hed: "News flat Package",
    componentPreset: "News",
  },
];

export type PreviewOptionsMenuProps = {
  bundleData: GetBundle_bundle;
  currentOrganization: GetCurrentUser_currentUser_organizations;
  currentUser: GetCurrentUser_currentUser;
  onSave: () => void;
};

export const PreviewOptionsMenu = ({
  bundleData,
  currentOrganization,
  currentUser,
  onSave,
}: PreviewOptionsMenuProps) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(true);

  const handleCollapse = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      {isOpen && (
        <MenuContainer id="preview-options-menu">
          <MenuHeader>
            <Header>
              <HeaderText>
                <FormattedMessage defaultMessage="Outline" />
              </HeaderText>
              <Button
                aria-label={intl.formatMessage({ defaultMessage: "Hide menu" })}
                onClick={handleCollapse}
              >
                <CollapseIcon size="regular" />
              </Button>
            </Header>
            <Divider />
          </MenuHeader>
          <MenuContent>
            <ContainerOptions
              bundleData={bundleData}
              currentOrganization={currentOrganization}
              currentUser={currentUser}
              onSave={onSave}
            />
          </MenuContent>
        </MenuContainer>
      )}
      {!isOpen && (
        <OpenButton
          aria-label={intl.formatMessage({ defaultMessage: "Open menu" })}
          onClick={handleCollapse}
        >
          <BulletedListIcon size="regular" />
        </OpenButton>
      )}
    </>
  );
};

PreviewOptionsMenu.displayName = "PreviewOptionsMenu";

PreviewOptionsMenu.defaultProps = {
  containers,
};

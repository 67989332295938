import { RecipeFields_servingSizeInfo, RecipeServingSizesInput } from "@types";

export function servingSizeInfo(
  servingSizeInfo: RecipeFields_servingSizeInfo
): RecipeServingSizesInput {
  const {
    servingSizeDescription,
    servingSizeUnit,
    servingSizeRangeFrom,
    servingSizeRangeTo,
  } = servingSizeInfo;

  return {
    servingSizeDescription: servingSizeDescription ?? "",
    servingSizeUnit: servingSizeUnit ?? "",
    servingSizeRangeFrom: servingSizeRangeFrom ?? null,
    servingSizeRangeTo: servingSizeRangeTo ?? null,
  };
}

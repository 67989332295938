import styled from "styled-components";
import { ThemeProvider } from "@contexts";
import {
  memo,
  Key,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useFocusTrap, useUniqueId, useScrollWhenDragging } from "@hooks";
import {
  BackIcon,
  CheckIcon,
  NoAssetIcon,
  EllipsisIcon,
  FullscreenIcon,
  CloseIcon,
} from "@condenast/gemini/icons";
import { Button, SearchResult, Takeover } from "@components";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ContentSummary,
  KeyedItem,
  UpdateOperation,
  CKEditorConfiguration,
} from "@types";
import { useDrag, useDrop } from "react-dnd";
import { BatchEditDialog } from "../BatchEditDialog";

const CONTENT_WITH_CREDIT = ["clip", "photo", "crossword", "cnevideo"];

const TileViewContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: ${(props) => props.theme.ElevationModal};
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
`;

const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-xs);
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
`;

const ActionTitle = styled.h3`
  font: ${(props) => props.theme.FontSubSectionHeading};
`;

const SelectAllButton = styled(Button)`
  margin-left: auto;
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  display: flex;
`;

const DropIndicator = styled.div`
  display: none;
  background-color: var(--color-blue-50);
  border-radius: calc(0.5 * var(--spacing-sm));
  width: calc(0.5 * var(--spacing-sm));
  height: 100%;
`;

const DropZone = styled.div`
  position: absolute;
  pointer-events: none;

  height: 100%;
  width: calc(50% + var(--spacing-sm) * 0.5);

  &.left {
    top: 0;
    left: calc(0% - var(--spacing-sm) * 0.5);

    ${DropIndicator} {
      position: absolute;
      left: calc(0% - var(--spacing-xxs));
    }
  }

  &.right {
    top: 0;
    left: calc(50%);

    ${DropIndicator} {
      position: absolute;
      left: calc(100% - var(--spacing-xxs));
    }
  }
`;

const TileSlideList = styled.div`
  grid-area: slide-list;
  position: relative;
  overflow-y: auto;
  height: 100vh;
  padding: var(--spacing-md);

  & ul {
    grid-template-columns: repeat(auto-fill, minmax(12.75rem, 1fr));
    gap: var(--spacing-sm);
  }

  &.asset-wrapper {
    max-width: 100%;
  }

  &.dragging {
    cursor: grabbing;
    ${DropZone} {
      pointer-events: auto;
    }
    ${DropZone}.hover ${DropIndicator} {
      display: block;
    }
  }
`;

const TileDragContainer = styled.li`
  position: relative;
  border-radius: ${(props) => props.theme.CornerRadius};
  width: 100%;
  height: 100%;
`;

const TileDragPlaceholder = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--color-blue-80);
  border: 2px solid var(--color-blue-50);
  border-radius: var(--spacing-xxs);
`;

const ResultSlatDiv = styled(Takeover.ResultSlat).attrs({ as: "div" })``;
const EmptyResultSlatDiv = styled(ResultSlatDiv)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: var(--spacing-sm);
  color: var(--color-gray-4);

  &:hover {
    background: ${(props) => props.theme.SkeletonColor};
  }

  .dragHandle {
    position: absolute;
    top: 0;
    left: 45%;
  }

  .styledIndexAndCheck {
    margin-left: auto;
  }

  .noAsset {
    position: absolute;
    left: 45%;
    top: 45%;
  }
`;

const SubmitBar = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.ElevationModal};
`;

type DragData = {
  index: number;
  tile: KeyedItem<ContentSummary>;
};

type CollectedData = {
  isDragging: boolean;
};

const DraggableTile = memo(function (
  props: {
    moveTile: (tile: DragData, index: number) => void;
    onDragStart: () => void;
    onDragEnd: () => void;
    onMouseMove: (index: number) => void;
    onSelect: (key: Key) => void;
    isSelectedResult: boolean;
    selectItemPrefix: string;
  } & DragData & { cdnHost?: string }
) {
  let {
    tile,
    index,
    moveTile,
    onMouseMove,
    onDragStart,
    onDragEnd,
    onSelect,
    cdnHost,
    isSelectedResult,
    selectItemPrefix,
  } = props;

  let data = { tile, index };
  let beforeRef = useRef<HTMLDivElement | null>(null);
  let afterRef = useRef<HTMLDivElement | null>(null);

  let [result, setResult] = useState(tile.value);

  const [{ isDragging }, dragRef] = useDrag<DragData, DragData, CollectedData>(
    () => ({
      type: "SearchResult",
      item: () => data,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [data]
  );

  const [, dropBefore] = useDrop<DragData>(
    () => ({
      accept: "SearchResult",
      drop(item) {
        moveTile(item, index);
      },
    }),
    [index, moveTile]
  );

  const [, dropAfter] = useDrop<DragData>(
    () => ({
      accept: "SearchResult",
      drop(item) {
        moveTile(item, index + 1);
      },
    }),
    [index, moveTile]
  );

  /**
   * during drag and drop the browser calculates hover states and fires
   * mouse events kind of haphazardly. Additionally, scrolling causes
   * the cursor position to move without firing mouse events. Fortunately,
   * the drag-specific events are quite reliable--so we will need to use
   * those to calculate the hover states manually.
   */
  let captureBeforeEl = useCallback(
    (el: HTMLDivElement | null) => {
      beforeRef.current = el;
      dropBefore(el);
      if (el) {
        el.ondragover = () => beforeRef.current?.classList.add("hover");
        el.ondragleave = () => beforeRef.current?.classList.remove("hover");
        el.ondrop = () => beforeRef.current?.classList.remove("hover");
      }
    },
    [dropBefore]
  );

  let captureAfterEl = useCallback(
    (el: HTMLDivElement | null) => {
      afterRef.current = el;
      dropAfter(el);
      if (el) {
        el.ondragover = () => afterRef.current?.classList.add("hover");
        el.ondragleave = () => afterRef.current?.classList.remove("hover");
        el.ondrop = () => afterRef.current?.classList.remove("hover");
      }
    },
    [dropAfter]
  );

  useEffect(() => {
    let tileCopy = { ...tile };
    setResult(tileCopy.value);
  }, [tile]);

  let tileNumber = index + 1;

  return (
    <TileDragContainer
      onDrag={onDragStart}
      onDragOver={onDragStart}
      onDragEnd={onDragEnd}
      onDrop={onDragEnd}
      ref={dragRef}
    >
      {isDragging ? (
        <TileDragPlaceholder />
      ) : result ? (
        <SearchResult
          as={ResultSlatDiv}
          id={`${selectItemPrefix}-${index}`}
          onMouseMove={() => {
            onMouseMove(index);
          }}
          onClick={() => {
            onSelect(tile.key);
          }}
          aria-selected={isSelectedResult || undefined}
          result={result}
          treatment="asset-tile"
          cdnHost={cdnHost}
          draggable={true}
        >
          <Takeover.StyledIndexAndCheck>
            {isSelectedResult ? (
              <Takeover.StyledCheckIcon>
                <CheckIcon size="regular" />
              </Takeover.StyledCheckIcon>
            ) : (
              tileNumber
            )}
          </Takeover.StyledIndexAndCheck>
        </SearchResult>
      ) : (
        <EmptyResultSlatDiv
          onClick={() => {
            onSelect(tile.key);
          }}
          aria-selected={isSelectedResult || undefined}
        >
          <EllipsisIcon className="dragHandle" size="regular" />
          <Takeover.StyledIndexAndCheck className="styledIndexAndCheck">
            {isSelectedResult ? (
              <Takeover.StyledCheckIcon>
                <CheckIcon size="regular" />
              </Takeover.StyledCheckIcon>
            ) : (
              tileNumber
            )}
          </Takeover.StyledIndexAndCheck>
          <NoAssetIcon className="noAsset" size="regular" />
        </EmptyResultSlatDiv>
      )}

      <DropZone ref={captureBeforeEl} className="left">
        <DropIndicator />
      </DropZone>
      <DropZone ref={captureAfterEl} className="right">
        <DropIndicator />
      </DropZone>
    </TileDragContainer>
  );
});

DraggableTile.displayName = "DraggableTile";

function getMediaItems(ids: Key[], tiles: KeyedItem<ContentSummary>[] | null) {
  return ids.reduce((mediaItems, id) => {
    const tile = tiles?.find((tile) => tile.key === id);
    if (tile?.value) {
      if (CONTENT_WITH_CREDIT.includes(tile.value.contentType)) {
        mediaItems.push(tile.value);
      }
    }

    return mediaItems;
  }, [] as ContentSummary[]);
}

export const TileViewPanel = (props: {
  onClose: () => unknown;
  ckEditorTiles: KeyedItem<ContentSummary>[] | null;
  cdnHost?: string;
  onSubmit: (operations: ContentSummary[] | null) => void;
  onBatchUpdate?: (items: ContentSummary[], credit: string) => Promise<void>;
  isBatchUpdating?: boolean;
  creditRichTextConfig: CKEditorConfiguration;
}) => {
  const {
    onClose,
    ckEditorTiles,
    cdnHost,
    onSubmit,
    onBatchUpdate,
    isBatchUpdating,
    creditRichTextConfig: captionRichTextConfig,
  } = props;
  const intl = useIntl();

  const [selectedTileIDs, setSelectedTileIDs] = useState<Key[]>([]);
  const [showBatchEditDialog, setShowBatchEditDialog] = useState(false);

  const clearSelectedTileIDs = useCallback(
    () => setSelectedTileIDs([]),
    [setSelectedTileIDs]
  );

  const [tiles, setTiles] = useState(ckEditorTiles);

  const [element, setElement] = useState<HTMLElement | null>(null);

  const [cursor, setCursor] = useState(0);
  const [cursorMode, setCursorMode] = useState<"mouse" | "keyboard">("mouse");

  const uid = useUniqueId();
  const selectItemPrefix = `select-item-${uid}`;

  const allSelected = useMemo(
    () => selectedTileIDs.length === tiles?.length,
    [selectedTileIDs, tiles]
  );

  const onMouseMove = useCallback(
    (newCursor) => {
      setCursor(newCursor);
      setCursorMode("mouse");
    },
    [setCursor, setCursorMode]
  );

  useEffect(() => {
    if ("Intercom" in window) {
      Intercom("update", {
        hide_default_launcher: true,
      });
    }
    return () => {
      if ("Intercom" in window) {
        Intercom("update", {
          hide_default_launcher: false,
        });
      }
    };
  });

  const selectionSupportsCredit = useMemo(() => {
    const selectedMedia = getMediaItems(selectedTileIDs, tiles);
    return selectedMedia.length > 0;
  }, [selectedTileIDs, tiles]);

  const onSelect = useCallback(
    (tileIDToToggle: Key) => {
      const index = selectedTileIDs.findIndex((selectedTileID) => {
        return selectedTileID === tileIDToToggle;
      });
      if (index >= 0) {
        setSelectedTileIDs([
          ...selectedTileIDs.slice(0, index),
          ...selectedTileIDs.slice(index + 1),
        ]);
      } else {
        setSelectedTileIDs([...selectedTileIDs, tileIDToToggle]);
      }
    },
    [selectedTileIDs, setSelectedTileIDs]
  );

  const moveTile = (tile: DragData, dropIndex: number) => {
    if (tile.index === dropIndex) return;
    let adjustedDropIndex = tile.index < dropIndex ? dropIndex - 1 : dropIndex;
    let tileItem = tiles?.splice(tile.index, 1)[0];
    if (tileItem) {
      tiles?.splice(adjustedDropIndex, 0, tileItem);
    }
    setTiles(tiles);
  };

  const onUpdate = useCallback(
    (deleteOperations?: UpdateOperation<ContentSummary | null>[]) => {
      if (deleteOperations && deleteOperations.length > 0) {
        for (let i = 0; i < deleteOperations.length; i++) {
          tiles?.splice(deleteOperations[i][0], deleteOperations[i][1]);
        }
      }
      setTiles(tiles);
    },
    [tiles]
  );

  const toggleSelectAll = useCallback(() => {
    allSelected
      ? clearSelectedTileIDs()
      : setSelectedTileIDs(Array.from(tiles?.map((tile) => tile.key) ?? []));
  }, [allSelected, clearSelectedTileIDs, setSelectedTileIDs, tiles]);

  const onActionAndSubmit = useCallback(
    (action?: "editCredit" | "delete" | "submit") => {
      if (action == "delete") {
        let deleteOperations: UpdateOperation<ContentSummary | null>[] =
          selectedTileIDs
            .reduce((tilesToDeleteByIndex: number[], selectedTileID) => {
              let tileIndexToDelete =
                tiles?.findIndex((tile) => tile.key === selectedTileID) ?? -1;
              if (tileIndexToDelete >= 0) {
                tilesToDeleteByIndex.push(tileIndexToDelete);
              }
              return tilesToDeleteByIndex;
            }, [])
            .sort()
            .reverse()
            .map((index) => [index, 1]);

        onUpdate(deleteOperations);
        setSelectedTileIDs([]);
        return;
      } else if (action == "editCredit") {
        setShowBatchEditDialog(true);
        return;
      } else {
        let assets: ContentSummary[] | null =
          tiles?.map((item) => item.value) ?? [];
        onSubmit(assets);
        return;
      }
    },
    [selectedTileIDs, onUpdate, tiles, onSubmit]
  );

  useFocusTrap(element, onClose);

  /**
   * updating react during a drag operation or while scrolling can
   * cause some janky visual behaviors, so for performance reasons we are sidestepping
   * the framework to apply this class.
   */
  const tileListRef = useRef<HTMLDivElement | null>(null);
  const reportDragStart = useCallback(
    () => tileListRef.current?.classList.add("dragging"),
    []
  );
  const reportDragEnd = useCallback(
    () => tileListRef.current?.classList.remove("dragging"),
    []
  );
  const scrollHandlers = useScrollWhenDragging(
    {
      dragZoneSize: 200,
      distancePerFrame: 100,
      msPerFrame: 120,
      scrollBehavior: "smooth",
    },
    tileListRef
  );

  const batchUpdate = useCallback(
    async (credit: string) => {
      const mediaItems = getMediaItems(selectedTileIDs, tiles);
      await onBatchUpdate?.(mediaItems, credit);
      setShowBatchEditDialog(false);
    },
    [selectedTileIDs, tiles, onBatchUpdate, setShowBatchEditDialog]
  );

  return (
    <TileViewContainer ref={setElement}>
      <TitleGroup>
        <Button
          onClick={onClose}
          aria-label="Go Back"
          tabIndex={-1}
          aria-hidden="true"
        >
          <BackIcon size="regular" />
        </Button>
        <ActionTitle>
          {intl.formatMessage({
            defaultMessage: "Reorder / Batch Credit",
            description: "Title for tile view panel",
          })}
        </ActionTitle>
        <SelectAllButton treatment="borderless" onClick={toggleSelectAll}>
          {allSelected ? (
            <>
              <CloseIcon size="regular" />
              <FormattedMessage
                defaultMessage="Deselect All"
                description="Label for button to deselect all tiles"
              />
            </>
          ) : (
            <>
              <FullscreenIcon size="regular" />
              <FormattedMessage
                defaultMessage="Select All"
                description="Label for button to select all tiles"
              />
            </>
          )}
        </SelectAllButton>
      </TitleGroup>
      <TileSlideList ref={tileListRef} {...scrollHandlers}>
        <ThemeProvider theme="light">
          <Takeover.GridList
            tabIndex={0}
            aria-activedescendant={
              cursor !== null && cursor >= 0
                ? `${selectItemPrefix}-${cursor}`
                : undefined
            }
            cursorMode={cursorMode}
          >
            {tiles?.map((tile, index) => {
              let isSelectedResult = selectedTileIDs.some((selectedTileID) => {
                return tile.key === selectedTileID;
              });

              return (
                <DraggableTile
                  key={tile.key}
                  index={index}
                  tile={tile}
                  cdnHost={cdnHost}
                  isSelectedResult={isSelectedResult}
                  onDragStart={reportDragStart}
                  onDragEnd={reportDragEnd}
                  moveTile={moveTile}
                  onMouseMove={onMouseMove}
                  onSelect={onSelect}
                  selectItemPrefix={selectItemPrefix}
                />
              );
            })}
          </Takeover.GridList>
        </ThemeProvider>
      </TileSlideList>
      {showBatchEditDialog && (
        <BatchEditDialog
          onBatchUpdate={batchUpdate}
          isBatchUpdating={isBatchUpdating}
          creditRichTextConfig={captionRichTextConfig}
          onClose={() => setShowBatchEditDialog(false)}
        />
      )}
      <SubmitBar>
        <Takeover.SubmitBar
          clearSelections={clearSelectedTileIDs}
          selectionCount={selectedTileIDs.length}
          onSubmit={onActionAndSubmit}
          action={[
            "delete",
            "submit",
            ...(selectionSupportsCredit ? ["editCredit"] : []),
          ]}
        />
      </SubmitBar>
    </TileViewContainer>
  );
};

import { ReactNode, useState } from "react";
import { FormFor_form_controls } from "@types";
import styled from "styled-components";
import { Button } from "@components";
import { ChevronRightIcon } from "@condenast/gemini/icons";

const Wrapper = styled.div`
  border-radius: ${(props) => props.theme.CornerRadius};
  padding: var(--spacing-sm);
  margin-block-start: var(--spacing-sm);
  margin-block-end: var(--spacing-sm);
  border: 1px dashed ${(props) => props.theme.FieldDisabledColor};
`;

const ComponentName = styled.code`
  font: ${(props) => props.theme.FontCode};
  background: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  padding: var(--spacing-xxs) var(--spacing-xs);
  box-shadow: ${(props) => props.theme.FieldRing};
`;

const Props = styled.code`
  font: ${(props) => props.theme.FontCode};
  margin-top: var(--spacing-sm);
  background: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  display: block;
  white-space: pre;
  padding: var(--spacing-xs);
  box-shadow: ${(props) => props.theme.FieldRing};
`;

export function MissingControl(props: {
  control: FormFor_form_controls;
  children: ReactNode;
}) {
  let { __typename, id, children: childIds, ...options } = props.control;
  let [showProps, setShowProps] = useState(false);
  let componentName = __typename.replace("FormControl", "");
  return (
    <Wrapper id={`MissingControl-${id}`}>
      <p>
        <ComponentName>{componentName}</ComponentName> &nbsp;
        <Button
          treatment="borderless"
          size="small"
          onClick={(evt) => {
            evt.preventDefault();
            setShowProps(!showProps);
          }}
        >
          Props <ChevronRightIcon size="small" />
        </Button>
      </p>
      {showProps && (
        <pre>
          <Props className="language-json">
            {JSON.stringify(options, null, 2)}
          </Props>
        </pre>
      )}
      {props.children}
    </Wrapper>
  );
}
MissingControl.displayName = "ContentForm(MissingControl)";

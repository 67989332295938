import {
  Button,
  Card,
  Field,
  CKEditor,
  Label,
  DraggableList,
  HighlightedText,
} from "@components";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  BinIcon,
  DraggableIcon,
} from "@condenast/gemini/icons";
import { useDefinedMessages, useUniqueId } from "@hooks";

import {
  BlockEditor,
  InlineEditor,
  MinimalistEditor,
} from "@condenast/ckeditor5-build-condenast";
import { useCallback, useEffect, useState, useMemo } from "react";
import CopilotMarkdownRenderer from "@condenast/atjson-renderer-copilot-markdown";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";
import {
  CKEditorConfiguration,
  ContentSummary,
  KeyedItem,
  PreparationGroupData,
  PreparationGroupUpdateOperation,
  StepData,
  StepUpdateOperation,
} from "@types";

const Section = styled(Card).attrs({ as: "section" })`
  display: relative;
  margin-block-start: var(--spacing-md);
  padding: var(--spacing-sm) 0 0;
`;

const ActionButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
`;

const ActionsBlock = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  margin: var(--spacing-md) auto 0;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 var(--spacing-sm) var(--spacing-sm);
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
`;

const HeaderActions = styled(ActionsBlock)`
  margin: 0;
  flex: 50%;
  justify-content: end;
`;

const Title = styled.h2`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
`;

const StepList = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto;
  margin: 0 auto;
  padding: var(--spacing-md);
  column-gap: var(--spacing-md);
  width: 100%;
  background: var(--color-gray-6);

  @media (max-width: 650px) {
    padding: var(--spacing-md) 0;
  }
`;

const StepLabel = styled(Label)`
  margin-bottom: var(--spacing-sm);
`;

const StepSectionContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.DividerColor};
  padding: var(--spacing-sm);

  ${ActionButton} {
    display: flex;
    margin: var(--spacing-sm) auto 0;
  }
`;

const StepItem = styled.div<{ $itemCollapsed: boolean }>`
  position: relative;
  display: flex;
  gap: var(--spacing-xs);
  width: 100%;
  cursor: grab;
  margin-top: var(--spacing-xs);
  align-items: center;
  justify-content: center;
  row-gap: ${({ $itemCollapsed }) =>
    $itemCollapsed ? `0` : `var(--spacing-sm)`};
`;

const CloseButton = styled(Button)`
  justify-content: center;
  align-content: center;
`;

const StepNumber = styled.div`
  margin-top: var(--spacing-xxs);
  font-size: ${(props) => props.theme.FontStatement};
  text-align: center;
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-shrink: 0;
`;

const GroupList = styled(Card)`
  padding: 0;
`;

const EditorField = styled(Field)`
  flex-grow: 1;
  height: fit-content;
  grid-template-areas:
    "label"
    "control"
    "message";

  .ck.ck-content.body {
    padding: 0px !important;
    min-height: fit-content;
    > * {
      margin: var(--spacing-sm);
      max-width: unset;
    }
  }
  cursor: default;
`;

const Editor = styled(CKEditor)`
  grid-area: control;
`;

const GroupHeader = styled.div`
  padding: var(--spacing-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-xs);

  @media (max-width: 650px) {
    ${HeaderActions} {
      flex: none;

      ${ActionButton} {
        display: none;
      }
    }
  }
`;

const GroupTitle = styled.div`
  flex: 50%;
  display: flex;
  gap: var(--spacing-sm);

  ${EditorField} {
    width: 100%;
  }
`;

const NoStepsMessage = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: var(--spacing-xl) 0;

  & h4 {
    font-size: 1.75rem;
    margin-bottom: var(--spacing-sm);
  }

  & span {
    max-width: 375px;
    line-height: initial;
    display: inline-block;
  }
`;

const StyledDraggableGroupList = styled(DraggableList)`
  gap: var(--spacing-md);
` as typeof DraggableList;

const StyledDraggableStepList = styled(DraggableList)`
  gap: var(--spacing-xxs);
` as typeof DraggableList;

const CollapseAllButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
`;

const PreparationActionBlock = styled.div<{ $itemCollapsed: boolean }>`
  display: flex;
  flex-direction: ${({ $itemCollapsed }) =>
    $itemCollapsed ? `row-reverse` : `column`};
  justify-content: center;
  align-items: center;
`;

const CollapseButton = styled(Button)`
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    flex-shrink: 0;
  }
`;

const CollapsedGroupHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-sm);
  font: var(--font-subhed);
  font-size: 1rem;

  & div {
    margin-right: var(--spacing-xs);
  }
`;

const CollapsedStepHeader = styled.div`
  display: flex;
  align-items: center;

  & div {
    margin-right: var(--spacing-xs);
  }
  flex: 1;
`;

const DraggableWrapper = styled.div<{
  $collapsed?: boolean;
}>`
  ${({ $collapsed }) =>
    $collapsed &&
    `> * {
    display: none;
  }`}
`;

const CollapsedGroupLabel = styled.label`
  white-space: nowrap;
  width: calc(var(--spacing-sm) * 30);
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  @media (min-width: 800px) and (max-width: 1000px) {
    width: calc(var(--spacing-sm) * 20);
  }
  @media (min-width: 400px) and (max-width: 800px) {
    width: calc(var(--spacing-sm) * 16);
  }
  @media (min-width: 100px) and (max-width: 400px) {
    width: calc(var(--spacing-sm) * 10);
  }
  line-height: var(--spacing-lg);
`;

const CollapsedStepLabel = styled.label`
  white-space: nowrap;
  width: calc(var(--spacing-sm) * 40);
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  @media (min-width: 800px) and (max-width: 1000px) {
    width: calc(var(--spacing-sm) * 20);
  }
  @media (min-width: 400px) and (max-width: 800px) {
    width: calc(var(--spacing-sm) * 16);
  }
  @media (min-width: 100px) and (max-width: 400px) {
    width: calc(var(--spacing-sm) * 10);
  }
  line-height: var(--spacing-lg);
`;

const StyledHighlightedText = styled(HighlightedText)`
  white-space: nowrap;
  width: calc(var(--spacing-sm) * 40);
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  @media (min-width: 800px) and (max-width: 1000px) {
    width: calc(var(--spacing-sm) * 20);
  }
  @media (min-width: 400px) and (max-width: 800px) {
    width: calc(var(--spacing-sm) * 16);
  }
  @media (min-width: 100px) and (max-width: 400px) {
    width: calc(var(--spacing-sm) * 10);
  }
  line-height: var(--spacing-lg);
`;

const PreparationActionsBlock = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
`;

const Builds = {
  block: BlockEditor,
  inline: InlineEditor,
  minimalist: MinimalistEditor,
};

type DecoratedStep = StepData & {
  _stepID: string;
  _stepNumber: number;
};

type DecoratedMicroStep = StepData & {
  _microStepID: string;
  _microStepNumber: number;
};

type DecoratedPreparationGroup = PreparationGroupData & {
  _groupID: string;
  steps: DecoratedStep[];
  microSteps: DecoratedMicroStep[];
};

type SelectAssets = (opts?: {
  types?: string[];
  limitSelection?: number;
}) => Promise<ContentSummary[] | null>;

function Step(props: {
  index: number;
  groupIndex: number;
  step: DecoratedStep;
  onClose: (groupIndex: number, index: number) => void;
  onChange: (gorupIndex: number, index: number, step: StepData) => void;
  stepRichTextBuild: keyof typeof Builds;
  stepRichTextConfig: CKEditorConfiguration;
  selectAssets?: SelectAssets;
  isPreparationStepCollapsed: boolean;
  toggleStepCollapsed: (step: DecoratedStep) => void;
}) {
  const {
    index,
    groupIndex,
    step,
    onClose,
    onChange,
    stepRichTextBuild,
    stepRichTextConfig,
    selectAssets,
    toggleStepCollapsed,
    isPreparationStepCollapsed,
  } = props;
  let intl = useIntl();
  const id = useUniqueId();

  // CKEditor requires an onChange that is stable across re-renders
  // so update local state on change and have a separate effect that
  // can be updated when props change
  const [description, setDescription] = useState<string | null>(null);
  const onDescriptionChange = useCallback(
    (doc) => {
      setDescription(CopilotMarkdownRenderer.render(doc).trim());
    },
    [setDescription]
  );
  useEffect(() => {
    if (description !== null) {
      onChange(groupIndex, index, {
        ...step,
        description,
      } as StepData);
    }
    // only run when the description value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);
  const descriptionValue = useMemo(
    () =>
      CopilotMarkdownSource.fromRaw(step.description ?? "").convertTo(
        VersoSource
      ),
    [step]
  );

  const [numberIsHovered, setNumberIsHovered] = useState(false);

  return (
    <StepItem
      onMouseOver={() => {
        setNumberIsHovered(true);
      }}
      onMouseOut={() => {
        setNumberIsHovered(false);
      }}
      $itemCollapsed={isPreparationStepCollapsed}
    >
      <StepNumber>
        {numberIsHovered ? (
          <DraggableIcon size="regular" className="draggable-icon" />
        ) : (
          <>{step._stepNumber}</>
        )}
      </StepNumber>
      {isPreparationStepCollapsed ? (
        <CollapsedStepHeader>
          {Object.keys(step).length === 1 ? (
            <CollapsedStepLabel>
              {step.description || (
                <FormattedMessage defaultMessage={"New Instruction (empty)"} />
              )}
            </CollapsedStepLabel>
          ) : (
            <>
              {step.description && (
                <StyledHighlightedText document={descriptionValue} />
              )}
            </>
          )}
        </CollapsedStepHeader>
      ) : (
        <EditorField id={`CKEditor__${id}__description`}>
          <Editor
            onChange={onDescriptionChange}
            id={`CKEditor__${id}__description`}
            build={stepRichTextBuild}
            config={stepRichTextConfig}
            value={descriptionValue}
            selectAssets={selectAssets}
          />
        </EditorField>
      )}
      <PreparationActionBlock $itemCollapsed={isPreparationStepCollapsed}>
        <CollapseButton
          aria-label={intl.formatMessage({
            defaultMessage: "Toggle Collapse",
          })}
          onClick={() => toggleStepCollapsed(step)}
        >
          {isPreparationStepCollapsed ? (
            <ChevronDownIcon size="regular" />
          ) : (
            <ChevronUpIcon size="regular" />
          )}
        </CollapseButton>
        <CloseButton
          aria-label={intl.formatMessage({
            defaultMessage: "Remove",
          })}
          onClick={() => onClose(index, groupIndex)}
        >
          <BinIcon size="regular" />
        </CloseButton>
      </PreparationActionBlock>
    </StepItem>
  );
}

function MicroStep(props: {
  index: number;
  groupIndex: number;
  step: DecoratedMicroStep;
  onClose: (groupIndex: number, index: number) => void;
  onChange: (gorupIndex: number, index: number, step: StepData) => void;
  stepRichTextBuild: keyof typeof Builds;
  stepRichTextConfig: CKEditorConfiguration;
  selectAssets?: SelectAssets;
  isPreparationMicroStepCollapsed: boolean;
  toggleMicroStepCollapsed: (step: DecoratedMicroStep) => void;
}) {
  const {
    index,
    groupIndex,
    step,
    onClose,
    onChange,
    stepRichTextBuild,
    stepRichTextConfig,
    selectAssets,
    toggleMicroStepCollapsed,
    isPreparationMicroStepCollapsed,
  } = props;
  let intl = useIntl();
  const id = useUniqueId();

  // CKEditor requires an onChange that is stable across re-renders
  // so update local state on change and have a separate effect that
  // can be updated when props change
  const [description, setDescription] = useState<string | null>(null);
  const onDescriptionChange = useCallback(
    (doc) => {
      setDescription(CopilotMarkdownRenderer.render(doc));
    },
    [setDescription]
  );
  useEffect(() => {
    if (description !== null) {
      onChange(groupIndex, index, {
        ...step,
        description,
      } as StepData);
    }
    // only run when the description value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);
  const descriptionValue = useMemo(
    () =>
      CopilotMarkdownSource.fromRaw(step.description ?? "").convertTo(
        VersoSource
      ),
    [step]
  );

  const [numberIsHovered, setNumberIsHovered] = useState(false);

  return (
    <StepItem
      onMouseOver={() => {
        setNumberIsHovered(true);
      }}
      onMouseOut={() => {
        setNumberIsHovered(false);
      }}
      $itemCollapsed={isPreparationMicroStepCollapsed}
    >
      <StepNumber>
        {numberIsHovered ? (
          <DraggableIcon size="regular" className="draggable-icon" />
        ) : (
          <>{step._microStepNumber}</>
        )}
      </StepNumber>
      {isPreparationMicroStepCollapsed ? (
        <CollapsedStepHeader>
          {Object.keys(step).length === 1 ? (
            <CollapsedStepLabel>
              {step.description || (
                <FormattedMessage defaultMessage={"New Step (empty)"} />
              )}
            </CollapsedStepLabel>
          ) : (
            <>
              {step.description && (
                <div>
                  <StyledHighlightedText document={descriptionValue} />
                </div>
              )}
            </>
          )}
        </CollapsedStepHeader>
      ) : (
        <EditorField id={`CKEditor__${id}__description`}>
          <Editor
            onChange={onDescriptionChange}
            id={`CKEditor__${id}__description`}
            build={stepRichTextBuild}
            config={stepRichTextConfig}
            value={descriptionValue}
            selectAssets={selectAssets}
          />
        </EditorField>
      )}
      <PreparationActionBlock $itemCollapsed={isPreparationMicroStepCollapsed}>
        <CollapseButton
          aria-label={intl.formatMessage({
            defaultMessage: "Toggle Collapse",
          })}
          onClick={() => toggleMicroStepCollapsed(step)}
        >
          {isPreparationMicroStepCollapsed ? (
            <ChevronDownIcon size="regular" />
          ) : (
            <ChevronUpIcon size="regular" />
          )}
        </CollapseButton>
        <CloseButton
          aria-label={intl.formatMessage({
            defaultMessage: "Remove",
          })}
          onClick={() => onClose(index, groupIndex)}
        >
          <BinIcon size="regular" />
        </CloseButton>
      </PreparationActionBlock>
    </StepItem>
  );
}

function StepSection(props: {
  onStepUpdate: (dedupedOperations: StepUpdateOperation[]) => void;
  showMicroSteps: boolean;
  groupIndex: number;
  group: DecoratedPreparationGroup;
  onStepClose: (groupIndex: number, index: number) => void;
  stepRichTextBuild: keyof typeof Builds;
  stepRichTextConfig: CKEditorConfiguration;
  selectAssets?: SelectAssets;
  toggleStepCollapsed: (step: DecoratedStep) => void;
  collapsedIDs: string[];
}) {
  let {
    showMicroSteps,
    onStepUpdate,
    groupIndex,
    group,
    onStepClose,
    stepRichTextBuild,
    stepRichTextConfig,
    selectAssets,
    toggleStepCollapsed,
    collapsedIDs,
  } = props;
  let steps = group.steps as DecoratedStep[];

  const keyedSteps = useMemo(
    () => steps.map((step) => ({ key: step._stepID, value: step })),
    [steps]
  );

  const moveStep = useCallback(
    (
      data: {
        index: number;
        item: KeyedItem<DecoratedStep>;
      },
      dropIndex: number
    ) => {
      if (data.index !== dropIndex) {
        const adjustedDropIndex =
          data.index < dropIndex ? dropIndex - 1 : dropIndex;
        onStepUpdate([
          [groupIndex, data.index, 1],
          [groupIndex, adjustedDropIndex, 0, data.item.value],
        ]);
      }
    },
    [onStepUpdate, groupIndex]
  );

  const onStepChange = useCallback(
    (groupIndex: number, index: number, step: StepData) => {
      onStepUpdate([[groupIndex, index ?? 0, 1, step]]);
    },
    [onStepUpdate]
  );

  return (
    <StepSectionContainer>
      {showMicroSteps && (
        <StepLabel>
          <FormattedMessage defaultMessage={"Instructions"} />
        </StepLabel>
      )}

      <StyledDraggableStepList items={keyedSteps} onMove={moveStep}>
        {(step, index) => (
          <Step
            index={index}
            groupIndex={groupIndex}
            step={step}
            onClose={onStepClose}
            onChange={onStepChange}
            stepRichTextBuild={stepRichTextBuild}
            stepRichTextConfig={stepRichTextConfig}
            selectAssets={selectAssets}
            toggleStepCollapsed={toggleStepCollapsed}
            isPreparationStepCollapsed={collapsedIDs.includes(
              `step:${step._stepID}`
            )}
          />
        )}
      </StyledDraggableStepList>
      <ActionButton
        size="medium"
        onClick={() => {
          onStepUpdate([[groupIndex, group.steps.length, 0, {}]]);
        }}
      >
        <AddIcon size="regular" />
        <FormattedMessage
          defaultMessage="Add Instruction"
          description="Add a blank instruction item to recipe"
        />
      </ActionButton>
    </StepSectionContainer>
  );
}

function PreparationGroup(props: {
  group: DecoratedPreparationGroup;
  groupIndex: number;
  treatment: "single" | "multiple";
  preparationGroups: DecoratedPreparationGroup[];
  onGroupUpdate: (operations: PreparationGroupUpdateOperation[]) => void;
  onStepUpdate: (operations: StepUpdateOperation[]) => void;
  onMicroStepUpdate: (operations: StepUpdateOperation[]) => void;
  groupRichTextBuild: keyof typeof Builds;
  stepRichTextBuild: keyof typeof Builds;
  stepRichTextConfig: CKEditorConfiguration;
  onStepClose: (groupIndex: number, index: number) => void;
  onMicroStepClose: (groupIndex: number, index: number) => void;
  showMicroSteps: boolean;
  selectAssets?: SelectAssets;
  collapsedIDs: string[];
  isGroupCollapsed: boolean;
  setCollapsedIDs: (value: React.SetStateAction<string[]>) => void;
  toggleIsGroupCollapsed: (group: DecoratedPreparationGroup) => void;
}) {
  let {
    group,
    groupIndex,
    onGroupUpdate,
    onStepUpdate,
    onMicroStepUpdate,
    groupRichTextBuild,
    stepRichTextBuild,
    stepRichTextConfig,
    onStepClose,
    onMicroStepClose,
    showMicroSteps,
    selectAssets,
    toggleIsGroupCollapsed,
    collapsedIDs,
    setCollapsedIDs,
    isGroupCollapsed,
    treatment,
  } = props;

  //set up hed ckeditor
  const { translateFieldName } = useDefinedMessages();
  let steps = group.steps as DecoratedStep[];
  let microSteps = group.microSteps as DecoratedMicroStep[];
  let groupHed = group.hed;

  // CKEditor requires an onChange that is stable across re-renders
  // so update local state on change and have a separate effect that
  // can be updated when props change
  const id = useUniqueId();
  const intl = useIntl();

  const [hed, setHed] = useState<string | null>(null);
  const onHedChange = useCallback(
    (doc) => {
      setHed(CopilotMarkdownRenderer.render(doc));
    },
    [setHed]
  );
  useEffect(() => {
    if (hed !== null) {
      onGroupUpdate([
        [groupIndex, 1, { ...group, hed } as PreparationGroupData],
      ]);
    }
    // only run when the description value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hed]);
  const hedValue = useMemo(
    () => CopilotMarkdownSource.fromRaw(groupHed ?? "").convertTo(VersoSource),
    [groupHed]
  );

  const onMicroStepChange = useCallback(
    (groupIndex: number, index: number, step: StepData) => {
      onMicroStepUpdate([[groupIndex, index ?? 0, 1, step]]);
    },
    [onMicroStepUpdate]
  );
  const keyedMicroSteps = useMemo(
    () =>
      microSteps?.map((microStep) => ({
        key: microStep._microStepID,
        value: microStep,
      })),
    [microSteps]
  );

  const moveMicroStep = useCallback(
    (
      data: {
        index: number;
        item: KeyedItem<DecoratedMicroStep>;
      },
      dropIndex: number
    ) => {
      if (data.index !== dropIndex) {
        const adjustedDropIndex =
          data.index < dropIndex ? dropIndex - 1 : dropIndex;
        onMicroStepUpdate([
          [groupIndex, data.index, 1],
          [groupIndex, adjustedDropIndex, 0, data.item.value],
        ]);
      }
    },
    [onMicroStepUpdate, groupIndex]
  );

  const toggleStepCollapsed = (step: DecoratedStep) => {
    if (collapsedIDs.includes(`step:${step._stepID}`)) {
      setCollapsedIDs([
        ...collapsedIDs.filter((id) => id !== `step:${step._stepID}`),
      ]);
    } else {
      setCollapsedIDs([...collapsedIDs, `step:${step._stepID}`]);
    }
  };

  const toggleMicroStepCollapsed = (step: DecoratedMicroStep) => {
    if (collapsedIDs.includes(`microstep:${step._microStepID}`)) {
      setCollapsedIDs([
        ...collapsedIDs.filter((id) => id !== `microstep:${step._microStepID}`),
      ]);
    } else {
      setCollapsedIDs([...collapsedIDs, `microstep:${step._microStepID}`]);
    }
  };

  return (
    <GroupList>
      {treatment === "single" ? (
        <StepSection
          showMicroSteps={showMicroSteps}
          onStepUpdate={onStepUpdate}
          groupIndex={groupIndex}
          group={group}
          onStepClose={onStepClose}
          stepRichTextBuild={stepRichTextBuild}
          stepRichTextConfig={stepRichTextConfig}
          selectAssets={selectAssets}
          toggleStepCollapsed={toggleStepCollapsed}
          collapsedIDs={collapsedIDs}
        />
      ) : (
        <>
          <GroupHeader>
            {isGroupCollapsed ? (
              <CollapsedGroupHeader>
                <CollapsedGroupLabel>
                  {hedValue.content || (
                    <FormattedMessage defaultMessage="New Group" />
                  )}
                </CollapsedGroupLabel>
              </CollapsedGroupHeader>
            ) : (
              <GroupTitle>
                <EditorField
                  label={translateFieldName("Group Name")}
                  id={`CKEditor__${id}__hed`}
                >
                  <Editor
                    onChange={onHedChange}
                    id={`CKEditor__${id}__hed`}
                    build={groupRichTextBuild}
                    value={hedValue}
                  />
                </EditorField>
              </GroupTitle>
            )}
            <HeaderActions>
              <ActionButton
                size="medium"
                onClick={() => {
                  onStepUpdate([[groupIndex, 0, 0, {}]]);
                  isGroupCollapsed && toggleIsGroupCollapsed(group);
                }}
              >
                <AddIcon size="regular" />
                <FormattedMessage
                  defaultMessage="Add Instruction"
                  description="Add a blank instruction item to recipe"
                />
              </ActionButton>
              {showMicroSteps && (
                <ActionButton
                  size="medium"
                  onClick={() => {
                    onMicroStepUpdate([[groupIndex, 0, 0, {}]]);
                  }}
                >
                  <AddIcon size="regular" />
                  <FormattedMessage
                    defaultMessage="Add Step"
                    description="Add a blank step item to recipe"
                  />
                </ActionButton>
              )}
              <Button
                onClick={() => {
                  onGroupUpdate([[groupIndex, 1]]);
                }}
                aria-label={intl.formatMessage({
                  defaultMessage: "Remove Group",
                })}
              >
                <BinIcon size="regular" />
              </Button>
              <CollapseButton
                aria-label={intl.formatMessage({
                  defaultMessage: "Toggle Collapse",
                })}
                onClick={() => toggleIsGroupCollapsed(group)}
              >
                {isGroupCollapsed ? (
                  <ChevronDownIcon size="regular" />
                ) : (
                  <ChevronUpIcon size="regular" />
                )}
              </CollapseButton>
            </HeaderActions>
          </GroupHeader>
          {steps && (
            <DraggableWrapper $collapsed={isGroupCollapsed}>
              <StepSection
                showMicroSteps={showMicroSteps}
                onStepUpdate={onStepUpdate}
                groupIndex={groupIndex}
                group={group}
                onStepClose={onStepClose}
                stepRichTextBuild={stepRichTextBuild}
                stepRichTextConfig={stepRichTextConfig}
                selectAssets={selectAssets}
                toggleStepCollapsed={toggleStepCollapsed}
                collapsedIDs={collapsedIDs}
              />
            </DraggableWrapper>
          )}
        </>
      )}
      {showMicroSteps && microSteps && (
        <DraggableWrapper $collapsed={isGroupCollapsed}>
          <StepSectionContainer>
            <StepLabel>
              <FormattedMessage defaultMessage={"Steps"} />
            </StepLabel>
            <StyledDraggableStepList
              items={keyedMicroSteps}
              onMove={moveMicroStep}
            >
              {(microStep, index) => (
                <MicroStep
                  index={index}
                  groupIndex={groupIndex}
                  step={microStep}
                  onClose={onMicroStepClose}
                  onChange={onMicroStepChange}
                  stepRichTextBuild={stepRichTextBuild}
                  stepRichTextConfig={stepRichTextConfig}
                  selectAssets={selectAssets}
                  toggleMicroStepCollapsed={toggleMicroStepCollapsed}
                  isPreparationMicroStepCollapsed={collapsedIDs.includes(
                    `microstep:${microStep._microStepID}`
                  )}
                />
              )}
            </StyledDraggableStepList>
            <ActionButton
              size="medium"
              onClick={() => {
                onMicroStepUpdate([
                  [groupIndex, group.microSteps.length, 0, {}],
                ]);
              }}
            >
              <AddIcon size="regular" />
              <FormattedMessage
                defaultMessage="Add Step"
                description="Add a blank step item to recipe"
              />
            </ActionButton>
          </StepSectionContainer>
        </DraggableWrapper>
      )}
    </GroupList>
  );
}

export function RecipePreparationList(props: {
  onGroupUpdate: (operations: PreparationGroupUpdateOperation[]) => void;
  onStepUpdate: (dedupedOperations: StepUpdateOperation[]) => void;
  onMicroStepUpdate: (operations: StepUpdateOperation[]) => void;
  onUpload?: (file: File) => Promise<{ id: string }>;
  preparationGroups: DecoratedPreparationGroup[];
  groupRichTextBuild: keyof typeof Builds;
  stepRichTextBuild: keyof typeof Builds;
  stepRichTextConfig: CKEditorConfiguration;
  showMicroSteps: boolean;
  selectAssets?: SelectAssets;
}) {
  let {
    onGroupUpdate,
    onStepUpdate,
    onMicroStepUpdate,
    preparationGroups,
    groupRichTextBuild,
    stepRichTextBuild,
    stepRichTextConfig,
    showMicroSteps,
    selectAssets,
  } = props;

  const removeStep = useCallback(
    (index: number, groupIndex: number) => {
      onStepUpdate([[groupIndex, index, 1]]);
    },
    [onStepUpdate]
  );

  const idCollectionArray = preparationGroups.reduce(
    (accumulator: string[], group) => {
      const stepIds = group.steps.map(
        (step: DecoratedStep) => `step:${step._stepID}`
      );
      const microStepIds =
        group?.microSteps?.map(
          (microStep: DecoratedMicroStep) =>
            `microstep:${microStep._microStepID}`
        ) || [];
      return accumulator.concat([
        ...stepIds,
        ...microStepIds,
        `group:${group._groupID}`,
      ]);
    },
    []
  );

  const [collapsedIDs, setCollapsedIDs] = useState<string[]>([]);

  const toggleAllCollapsed = () => {
    if (collapsedIDs.length) {
      setCollapsedIDs([]);
    } else {
      setCollapsedIDs([...idCollectionArray]);
    }
  };

  const toggleGroupCollapsed = (group: DecoratedPreparationGroup) => {
    if (collapsedIDs.includes(`group:${group._groupID}`)) {
      setCollapsedIDs([
        ...collapsedIDs.filter((id) => id !== `group:${group._groupID}`),
      ]);
    } else {
      setCollapsedIDs([...collapsedIDs, `group:${group._groupID}`]);
    }
  };

  const removeMicroStep = useCallback(
    (index: number, groupIndex: number) => {
      onMicroStepUpdate([[groupIndex, index, 1]]);
    },
    [onMicroStepUpdate]
  );

  const keyedGroups = useMemo(
    () =>
      preparationGroups.map((group) => ({
        key: group._groupID,
        value: group,
      })),
    [preparationGroups]
  );

  const moveGroup = useCallback(
    (
      data: {
        index: number;
        item: KeyedItem<DecoratedPreparationGroup>;
      },
      dropIndex: number
    ) => {
      if (data.index !== dropIndex) {
        const adjustedDropIndex =
          data.index < dropIndex ? dropIndex - 1 : dropIndex;
        onGroupUpdate([
          [data.index, 1],
          [adjustedDropIndex, 0, data.item.value],
        ]);
      }
    },
    [onGroupUpdate]
  );

  return (
    <Section>
      <Header>
        <Title>
          <FormattedMessage defaultMessage="Preparation" />
        </Title>
        <PreparationActionsBlock>
          <CollapseAllButton
            treatment="borderless"
            size="medium"
            onClick={toggleAllCollapsed}
          >
            {collapsedIDs.length ? (
              <FormattedMessage
                defaultMessage="Expand all"
                description="Expand all items in group"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Collapse all"
                description="Collapse all items in group"
              />
            )}
          </CollapseAllButton>
          <HeaderActions>
            <ActionButton
              size="medium"
              onClick={() => {
                onGroupUpdate([
                  [
                    0,
                    0,
                    { steps: [{}], microSteps: showMicroSteps ? [{}] : [] },
                  ],
                ]);
              }}
            >
              <AddIcon size="regular" />
              <FormattedMessage
                defaultMessage="Add Group"
                description="Add a blank group to recipe"
              />
            </ActionButton>
          </HeaderActions>
        </PreparationActionsBlock>
      </Header>
      <StepList>
        {keyedGroups.length ? (
          <StyledDraggableGroupList items={keyedGroups} onMove={moveGroup}>
            {(group, index) => (
              <PreparationGroup
                onGroupUpdate={onGroupUpdate}
                onStepUpdate={onStepUpdate}
                onMicroStepUpdate={onMicroStepUpdate}
                group={group}
                groupIndex={index}
                treatment={keyedGroups.length > 1 ? "multiple" : "single"}
                preparationGroups={preparationGroups}
                groupRichTextBuild={groupRichTextBuild}
                stepRichTextBuild={stepRichTextBuild}
                stepRichTextConfig={stepRichTextConfig}
                selectAssets={selectAssets}
                onStepClose={removeStep}
                onMicroStepClose={removeMicroStep}
                showMicroSteps={showMicroSteps}
                collapsedIDs={collapsedIDs}
                toggleIsGroupCollapsed={toggleGroupCollapsed}
                setCollapsedIDs={setCollapsedIDs}
                isGroupCollapsed={collapsedIDs.includes(
                  `group:${group._groupID}`
                )}
              />
            )}
          </StyledDraggableGroupList>
        ) : (
          <NoStepsMessage>
            <h4>
              <FormattedMessage
                defaultMessage="No Instructions Yet"
                description="Message for empty list of instructions"
              />
            </h4>
            <span>
              <FormattedMessage
                defaultMessage="Start creating your recipe by adding instructions."
                description="Descriptive text for how to add instructions to a recipe"
              />
            </span>
          </NoStepsMessage>
        )}
        <ActionsBlock>
          <ActionButton
            onClick={() => {
              onGroupUpdate([
                [
                  preparationGroups.length,
                  0,
                  { steps: [{}], microSteps: showMicroSteps ? [{}] : [] },
                ],
              ]);
            }}
          >
            <AddIcon size="regular" />
            <FormattedMessage
              defaultMessage="Add Group"
              description="Add a blank group to recipe"
            />
          </ActionButton>
        </ActionsBlock>
      </StepList>
    </Section>
  );
}

import { ComponentType } from "react";
import { ErrorPage, ErrorType } from "../App/ErrorPage";
import { useParams } from "react-router-dom";
import type { GetCurrentUser_currentUser } from "@types";

interface WithCurrentUserProps {
  currentUser: GetCurrentUser_currentUser | undefined;
}
export function withAuthorizedCurrentOrganization<
  Props extends WithCurrentUserProps
>(Component: ComponentType<Props>) {
  function ComponentWithAuthorizedCurrentOrganization(
    props: Omit<Props, "currentOrganization">
  ) {
    let { copilotCode } = useParams() as { copilotCode: string };
    let organizations = props.currentUser?.organizations;
    let authorizedCurrentOrganization = organizations?.find(
      (organization) => organization.metadata.copilotCode === copilotCode
    );
    if (!authorizedCurrentOrganization) {
      return <ErrorPage type={ErrorType.NotAuthorized} />;
    }
    return (
      /* @ts-expect-error currentOrganization isn't always defined on the component */
      <Component
        {...props}
        currentOrganization={authorizedCurrentOrganization}
      />
    );
  }
  ComponentWithAuthorizedCurrentOrganization.internalDisplayName = `withAuthorizedCurrentOrganization(${
    Component.displayName ?? "anonymous"
  })`;

  return ComponentWithAuthorizedCurrentOrganization;
}

import { useQuery } from "@apollo/client";
import { Queries } from "@gql";
import {
  GetCurrentUser,
  GetPermissionsMatrix,
  GetPermissionsMatrixVariables,
  GetPermissionsMatrix_permissions_contentPermissions_permissions as ContentPermissions,
  GetPermissionsMatrix_permissions_userPermissions_permissions as UserPermissions,
  Organization,
  UserRole,
} from "@types";
import { useMemo } from "react";

export type ActionAccess<
  Action extends Exclude<
    keyof ContentPermissions | keyof UserPermissions,
    "__typename"
  >
> = {
  [action in Action]: boolean | undefined;
};

export type ScopedAccess<
  Type extends string,
  Action extends Exclude<
    keyof ContentPermissions | keyof UserPermissions,
    "__typename"
  >
> = {
  [type in Type]: ActionAccess<Action>;
};

export function useContentAccessControlMatrix<
  Type extends string,
  Action extends Exclude<keyof ContentPermissions, "__typename">
>(
  organization: Organization,
  actions: Action[],
  types: Type[] | Type
): ScopedAccess<Type, Action>;
export function useContentAccessControlMatrix<
  Type extends string,
  Action extends Exclude<keyof ContentPermissions, "__typename">
>(
  organization: Organization,
  actions: Action[] | Action,
  types: Type[]
): ScopedAccess<Type, Action>;
export function useContentAccessControlMatrix<
  Type extends string,
  Action extends Exclude<keyof ContentPermissions, "__typename">
>(
  organization: Organization,
  actions: Action[] | Action,
  types: Type[] | Type
): ScopedAccess<Type, Action> {
  let { data: userResult } = useQuery<GetCurrentUser>(Queries.GET_CURRENT_USER);
  let { data: permissionsResult } = useQuery<
    GetPermissionsMatrix,
    GetPermissionsMatrixVariables
  >(Queries.GET_PERMISSIONS_MATRIX, {
    variables: {
      organizationId: organization.organizationId,
    },
  });

  return useMemo(() => {
    let rolePermissions = permissionsResult?.permissions?.find(
      (rolePerms) => rolePerms.role === userResult?.currentUser?.role
    );

    let objects = Array.isArray(types) ? types : [types];
    let verbs = Array.isArray(actions) ? actions : [actions];

    let matrix = objects.reduce((typeAccess, type) => {
      typeAccess[type] = verbs.reduce((access, verb) => {
        if (userResult?.currentUser?.role === UserRole.superadmin) {
          access[verb] = true;
        } else if (permissionsResult == null) {
          access[verb] = undefined;
        } else {
          const contentPermission = rolePermissions?.contentPermissions.find(
            ({ collectionName }) => collectionName === type
          );
          access[verb] = !!contentPermission?.permissions[verb];
        }
        return access;
      }, {} as ActionAccess<Action>);

      return typeAccess;
    }, {} as ScopedAccess<Type, Action>);

    return matrix;
  }, [permissionsResult, types, actions, userResult?.currentUser?.role]);
}

export function useContentAccessControl<
  Type extends string,
  Action extends Exclude<keyof ContentPermissions, "__typename">
>(organization: Organization, action: Action, type: Type): boolean | undefined {
  return useContentAccessControlMatrix(organization, action, [type])[type][
    action
  ];
}

export function useUserAccessControlMatrix<
  Action extends Exclude<keyof UserPermissions, "__typename">
>(
  organization: Organization,
  actions: Action[],
  roles: UserRole[] | UserRole
): ScopedAccess<UserRole, Action>;
export function useUserAccessControlMatrix<
  Action extends Exclude<keyof UserPermissions, "__typename">
>(
  organization: Organization,
  actions: Action[] | Action,
  roles: UserRole[]
): ScopedAccess<UserRole, Action>;
export function useUserAccessControlMatrix<
  Action extends Exclude<keyof UserPermissions, "__typename">
>(
  organization: Organization,
  actions: Action[] | Action,
  roles: UserRole[] | UserRole
): ScopedAccess<UserRole, Action> {
  let { data: userResult } = useQuery<GetCurrentUser>(Queries.GET_CURRENT_USER);
  let { data: permissionsResult } = useQuery<
    GetPermissionsMatrix,
    GetPermissionsMatrixVariables
  >(Queries.GET_PERMISSIONS_MATRIX, {
    variables: {
      organizationId: organization.organizationId,
    },
  });

  return useMemo(() => {
    let rolePermissions = permissionsResult?.permissions?.find(
      (rolePerms) => rolePerms.role === userResult?.currentUser?.role
    );

    let objects = Array.isArray(roles) ? roles : [roles];
    let verbs = Array.isArray(actions) ? actions : [actions];

    let matrix = objects.reduce((roleAccess, userRole) => {
      roleAccess[userRole] = verbs.reduce((access, verb) => {
        if (userResult?.currentUser?.role === UserRole.superadmin) {
          access[verb] = true;
        } else if (permissionsResult == null) {
          access[verb] = undefined;
        } else {
          const userPermission = rolePermissions?.userPermissions.find(
            ({ role }) => role === userRole
          );
          access[verb] = !!userPermission?.permissions[verb];
        }
        return access;
      }, {} as ActionAccess<Action>);

      return roleAccess;
    }, {} as ScopedAccess<UserRole, Action>);

    return matrix;
  }, [permissionsResult, roles, actions, userResult?.currentUser?.role]);
}

export function useUserAccessControl<
  Action extends Exclude<keyof UserPermissions, "__typename">
>(
  organization: Organization,
  action: Action,
  role: UserRole
): boolean | undefined {
  return useUserAccessControlMatrix<Action>(organization, action, [role])[role][
    action
  ];
}

export function get(object: unknown, key: string | number | symbol): unknown {
  if (object == null) {
    return null;
  }
  return typeof key === "string"
    ? key
        .split(".")
        .reduce(
          (parent, propertyName) =>
            (parent as { [key: string]: unknown })?.[propertyName],
          object
        )
    : (object as Record<number | symbol, unknown>)[key];
}

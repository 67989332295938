import { forwardRef, HTMLAttributes, ReactNode, Ref } from "react";
import { Helmet } from "react-helmet";
import styled, { createGlobalStyle } from "styled-components";

const StyledPage = styled.div`
  background: ${(props) => props.theme.SurfaceColor};
  color: ${(props) => props.theme.Color};
  // Extra padding at the bottom for the Intercom messenger
  padding-bottom: calc(var(--spacing-xxl) * 2);
`;

const Background = createGlobalStyle`
  body {
    background: ${(props) => props.theme.SurfaceColor};
    color: ${(props) => props.theme.Color};
  }
`;

export const Page = forwardRef(
  (
    props: {
      title: string;
      className?: string;
      children?: ReactNode;
    } & HTMLAttributes<HTMLDivElement>,
    ref: Ref<HTMLDivElement>
  ) => {
    let { children, className, title, ...divProps } = props;
    return (
      <StyledPage
        ref={ref}
        className={className}
        aria-live="polite"
        {...divProps}
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Background />
        {children}
      </StyledPage>
    );
  }
);

Page.displayName = "Page";

import { Field, SearchMultiselect } from "@components";
import { useIntl } from "react-intl";
import { useCallback } from "react";
import { Option } from "@types";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "@gql";
import { useDebouncedCallback } from "@hooks";
import styled from "styled-components";

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-xxs) 0;
  grid-template-rows: auto auto auto;
  height: fit-content;
  grid-template-areas:
    "label"
    "control"
    "."
    "message";
`;

export type Contributor = {
  id: string;
  title: { content: string | null };
};

interface ContributorData {
  id: string;
  title: { content: string | null };
}

function normalizeContributorToOption(
  contributor: Contributor
): Option<Contributor> {
  return {
    key: contributor.id,
    value: contributor,
    label: `${contributor.title.content}`,
  };
}

function normalizeContributorData(
  contributors: ContributorData[]
): Contributor[] {
  const transformedContributors: Contributor[] = contributors.map(
    (contributor) => ({
      id: contributor.id,
      title: { content: contributor.title?.content ?? "" },
    })
  );
  return transformedContributors;
}

function contributorToOption(
  category: ContributorData
): Option<ContributorData> {
  return {
    key: category.id,
    value: category,
    label: category.title?.content || "",
  };
}

type MultiSelect = {
  key: string;
  value: ContributorData;
  label: string;
};

export const ContributorSelector = (props: {
  setFilters: (
    selected: Array<string>,
    type: string,
    filtersToStore?: MultiSelect[]
  ) => void;
  preSelectedContributors: MultiSelect[];
  organizationId: string;
}) => {
  let intl = useIntl();
  const { setFilters, preSelectedContributors, organizationId } = props;
  const [_lazySearch, { data: searchData, loading }] = useLazyQuery(
    Queries.SEARCH
  );

  const preformSearch = useDebouncedCallback((query: string) => {
    if (!query) return;
    _lazySearch({
      variables: {
        organizationId,
        filters: {
          q: query || "",
          notcategory: "copilot/preset",
          display: "all",
          archived: false,
          qt: "contributorLookup",
          d_o: "and",
        },
      },
    });
  }, 250);

  const onChange = useCallback(
    (selected) => {
      let contributors = selected.map(contributorToOption);
      setFilters(
        selected.map((d: ContributorData) => d.id.toString()),
        "relid",
        contributors
      );
    },
    [setFilters]
  );

  const normalizedSearch: Option<ContributorData>[] =
    searchData && searchData?.search?.results
      ? normalizeContributorData(searchData.search.results).map(
          normalizeContributorToOption
        )
      : [];

  return (
    <Wrapper id={"contributors"} label={"Contributors"}>
      <SearchMultiselect
        label={intl.formatMessage({
          defaultMessage: "Contributors",
          description: "Filter Contributors label",
        })}
        aria-label={"select Label Name"}
        selections={preSelectedContributors}
        loading={loading}
        search={preformSearch}
        searchResults={normalizedSearch}
        onChange={onChange}
        multiple={true}
      />
    </Wrapper>
  );
};

ContributorSelector.displayName = "ContributorSelector";

import type {
  ContentSummaryFields,
  GetCurrentUser_currentUser,
  Organization,
  UploadMedia,
  UploadMediaVariables,
} from "@types";
import { useMutation } from "@apollo/client";
import { Mutations } from "@gql";
import {
  MediaUploader,
  UnsavedChangesDialog,
  Page,
  Title,
  Toast,
} from "@components";
import { useCallback, useState } from "react";
import { normalizeContentSummary, isDefined } from "@lib";
import { FormattedMessage, useIntl } from "react-intl";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { useDefinedMessages, useToast } from "@hooks";
import styled from "styled-components";

const ContentPage = styled(Page)`
  background: ${(props) => props.theme.PrimaryDisabledBackground};
  min-height: calc(100vh - var(--spacing-xxl));
  margin: 0 auto;
  max-width: min(${(props) => props.theme.CardSize}, 100vw);
  width: 100%;
`;

const PageTitle = styled(Title)`
  margin: var(--spacing-sm) 0;
`;

const Errors = styled.div`
  margin: var(--spacing-sm) 0;
`;

const ErrorList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;

  li {
    display: list-item;
  }
`;

export function MediaUploadPage(props: {
  currentUser: GetCurrentUser_currentUser;
  currentOrganization: Organization;
  loading?: boolean;
  allowedMediaTypes: string[];
  displayLabel: string;
}) {
  const { currentUser, currentOrganization, allowedMediaTypes, displayLabel } =
    props;
  const [assets, setAssets] = useState<ContentSummaryFields[]>([]);
  const [ongoingUploadCounter, setOngoingUploadCounter] = useState(0);
  const [uploadErrors, setUploadErrors] = useState<File[]>([]);
  const intl = useIntl();

  const { translateContentType } = useDefinedMessages();

  const [upload] = useMutation<UploadMedia, UploadMediaVariables>(
    Mutations.UPLOAD_MEDIA
  );

  const showIncorrectFileTypeMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Error uploading: Unsupported file type",
    }),
  });

  const uploadMultiple = useCallback(
    (files: FileList) => {
      const sharedVariables = {
        organizationId: currentOrganization.organizationId,
        authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      };
      for (let file of files) {
        setOngoingUploadCounter((n) => n + 1);
        let data =
          displayLabel === "Cartoons"
            ? { file, fileSize: file.size, collectionType: "cartoons" }
            : { file, fileSize: file.size };
        upload({
          variables: {
            ...sharedVariables,
            data: data,
          },
        })
          .then((uploadResult) => {
            setAssets((assets) => {
              if (uploadResult.data) {
                return [...assets, uploadResult.data.uploadMedia];
              } else {
                return assets;
              }
            });
            setUploadErrors((failedFiles) => {
              if (uploadResult.errors) {
                return [...failedFiles, file];
              } else {
                return failedFiles;
              }
            });
          })
          .catch(() => {
            setUploadErrors((failedFiles) => {
              return [...failedFiles, file];
            });
          })
          .finally(() => {
            setOngoingUploadCounter((n) => n - 1);
          });
      }
    },
    [upload, currentOrganization, currentUser, displayLabel]
  );

  return (
    <ContentPage title={intl.formatMessage({ defaultMessage: "New Photo" })}>
      <PageTitle>
        <FormattedMessage
          defaultMessage="{displayLabel}"
          values={{ displayLabel: displayLabel }}
        />
      </PageTitle>
      <UnsavedChangesDialog
        hasChanges={ongoingUploadCounter !== 0}
        title={<FormattedMessage defaultMessage="Unfinished Uploads" />}
        message={
          <FormattedMessage defaultMessage="Some files are not done uploading. If you leave the page, they may not be uploaded correctly. Do you want to leave the page?" />
        }
      />
      {uploadErrors.length ? (
        <Errors>
          <Toast
            type="error"
            details={
              <ErrorList>
                {uploadErrors.map((file) => (
                  <li key={file.name}>{file.name}</li>
                ))}
              </ErrorList>
            }
          >
            <FormattedMessage
              defaultMessage="Some files were not uploaded:"
              description="This message appears above a list of file names"
            />
          </Toast>
        </Errors>
      ) : null}
      <DndProvider backend={HTML5Backend}>
        <MediaUploader
          onUpload={uploadMultiple}
          accept={allowedMediaTypes}
          onUnsupportedFileType={showIncorrectFileTypeMessage}
          loading={ongoingUploadCounter !== 0}
          items={assets
            .map((asset) =>
              normalizeContentSummary(asset, translateContentType)
            )
            .filter(isDefined)}
          cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
        />
      </DndProvider>
    </ContentPage>
  );
}

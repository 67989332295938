import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { length, markdownLinks, required } from "@condenast/copilot-validators";

import type { ValidationContext, ValidationEnvironment } from "@lib";
import type {
  FormFor_content_MusicReview,
  FormFor_content_ProductReview,
} from "@types";
import { channelsOrSections, edgesHaveNodes, publishURI } from "../validators";
import { noEmptyAccordionHeaders } from "../validators/markdown-accordions";

const { object } = validators;

const productReviewValidators = object({
  hed: required(),
  body: object({
    content: markdownLinks().andAlso(noEmptyAccordionHeaders()),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  photosTout: object({
    edges: length({ min: 1 }),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  categoryTaxonomies: channelsOrSections().on("publish"),
  socialTitle: required().on("publish"),
  seo: object({
    description: required(),
    title: required(),
  }).on("publish"),
  items: edgesHaveNodes(),
})
  .andAlso(publishURI().on("publish"))
  .catch((reviewErrors) => {
    let categoryTaxonomyErrors = reviewErrors.filter((error) =>
      error.path.find((pathItem) => pathItem === "categoryTaxonomies")
    );
    if (categoryTaxonomyErrors) {
      categoryTaxonomyErrors.forEach(
        (categoryTaxonomyError) =>
          (categoryTaxonomyError.path = [
            categoryTaxonomyError.path
              .join("")
              .replace("categoryTaxonomies", ""),
          ])
      );
    }

    return reviewErrors;
  });

const musicReviewValidators = object({
  hed: required(),
  body: object({
    content: markdownLinks().andAlso(noEmptyAccordionHeaders()),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  photosTout: object({
    edges: length({ min: 1 }),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  categoryTaxonomies: channelsOrSections().on("publish"),
  contentSource: required().on("publish"),
  itemsReviewed: object({
    edges: length({ min: 1 }),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  socialTitle: required().on("publish"),
  seo: object({
    description: required(),
    title: required(),
  }).on("publish"),
})
  .andAlso(publishURI().on("publish"))
  .catch((reviewErrors) => {
    let categoryTaxonomyErrors = reviewErrors.filter((error) =>
      error.path.find((pathItem) => pathItem === "categoryTaxonomies")
    );
    if (categoryTaxonomyErrors) {
      categoryTaxonomyErrors.forEach(
        (categoryTaxonomyError) =>
          (categoryTaxonomyError.path = [
            categoryTaxonomyError.path
              .join("")
              .replace("categoryTaxonomies", ""),
          ])
      );
    }

    return reviewErrors;
  });

export default function validateReview(
  review: FormFor_content_ProductReview | FormFor_content_MusicReview,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  let validator = productReviewValidators;
  if (review.__typename === "MusicReview") {
    validator = musicReviewValidators;
  }
  return validate(review, build(validator), context, env);
}

import { ThumbnailField, TextField } from "@components";
import { Checkbox } from "@condenast/gemini";
import { Label } from "@condenast/gemini";
import styled from "styled-components";
import { useState } from "react";
import { useDefinedMessages } from "@hooks";
import { ThemeProvider } from "@contexts";
import {
  ContentSummary,
  ItemReviewedType,
  GetAssetSelectorConfig_configs_assetSelectorConfig as AssetSelectorConfig,
  FormError,
  GetCurrentUser_currentUser,
} from "@types";
import { AssetSelector } from "../App/FormControl/Controls/-private";

const Wrapper = styled.div`
  display: grid;
  border-radius: var(--spacing-xxs);
  box-shadow: ${(props) => props.theme.CardShadow};
  background: ${(props) => props.theme.Background};
  margin: 0 auto;
  padding: var(--spacing-sm);
  width: 100%;
  max-width: calc(var(--spacing-xl) * 25);
`;

const Title = styled("h2")`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
`;

const TitleWrapper = styled.div`
  display: grid;
  gap: var(--spacing-xxs);
  padding-bottom: var(--spacing-xs);
`;

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 15rem);
  grid-gap: var(--spacing-sm);
  background: ${(props) => props.theme.Background};
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  padding-top: var(--spacing-sm);
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

const AccoladesLabel = styled(Label)`
  margin-top: var(--spacing-sm);
  color: ${(props) => props.theme.Color};
`;

const StyledCheckbox = styled(Checkbox)`
  input:disabled + * {
    color: ${(props) => props.theme.SecondaryDisabledColor};
  }
`;

const StyledSpan = styled.span`
  margin: var(--spacing-xxs);
`;

type Accodlades = {
  key: string;
  label: string;
  disabled: boolean;
};

export function ItemReviewed(props: {
  assetSelectorConfig?: AssetSelectorConfig;
  organizationId: string;
  cdnHost: string;
  currentUser: GetCurrentUser_currentUser;
  id: string;
  reviewPhotosTout: ContentSummary | undefined | null;
  submitReviewPhoto: (assets: ContentSummary[]) => void;
  removeReviewPhoto: () => void;
  onRatingChange: (val: string) => void;
  onAccoladesChange: (enable: boolean, key: string) => void;
  reviewData?: ItemReviewedType;
  accolades: Accodlades[];
  ratingStep: string;
  ratingDisabled: boolean;
  errors?: FormError[];
  displayLabel?: string;
  message?: string;
}) {
  const { translateFieldName } = useDefinedMessages();
  const [assetSelectorToggle, setAssetSelectorToggle] = useState(false);
  const {
    assetSelectorConfig,
    organizationId,
    cdnHost,
    currentUser,
    id,
    reviewPhotosTout,
    submitReviewPhoto,
    removeReviewPhoto,
    onRatingChange,
    reviewData,
    onAccoladesChange,
    accolades,
    ratingStep,
    ratingDisabled,
    displayLabel = "",
    message = "",
  } = props;
  const translatedLabel = translateFieldName("Rating");

  return (
    <Wrapper>
      <ThemeProvider theme="light">
        <TitleWrapper>
          {<Title>{translateFieldName(displayLabel)}</Title>}
        </TitleWrapper>
        <ListWrapper>
          {assetSelectorToggle && assetSelectorConfig && (
            <AssetSelector
              config={assetSelectorConfig}
              currentOrganizationID={organizationId}
              onSubmit={(assets) => {
                submitReviewPhoto(assets);
                setAssetSelectorToggle(false);
              }}
              onClose={() => setAssetSelectorToggle(false)}
              cdnHost={cdnHost}
              currentUser={currentUser}
            />
          )}
          <ThumbnailField
            id={`ThumbnailField__${id}`}
            value={reviewPhotosTout}
            cdnHost={cdnHost}
            selectAsset={() => setAssetSelectorToggle(true)}
            removeAsset={removeReviewPhoto}
            uploadLimit={assetSelectorConfig?.limitSelection ?? undefined}
            errors={props.errors}
          />
          <RatingWrapper>
            <TextField
              id={`NumberField__Rating`}
              label={translatedLabel}
              aria-label={translatedLabel}
              onChange={onRatingChange}
              value={reviewData?.rating?.toString() || ""}
              disabled={ratingDisabled}
              placeholder={0}
              multiline={false}
              type="number"
              step={ratingStep}
              message={message}
            />
            {!!accolades?.length && (
              <AccoladesLabel>{translateFieldName("Accolades")}</AccoladesLabel>
            )}
            {accolades?.map((a: Accodlades) => {
              return (
                <CheckboxWrapper key={a.key}>
                  <StyledCheckbox
                    id={`accolades_${a.key}`}
                    aria-labelledby={`accolades_${a.key}`}
                    disabled={a.disabled}
                    checked={
                      (reviewData &&
                        !!reviewData[a.key as keyof ItemReviewedType]) ||
                      false
                    }
                    onChange={(enable: boolean) => {
                      onAccoladesChange(enable, a.key);
                    }}
                  />
                  <StyledSpan id={`webLabel-${a.key}`}>
                    {translateFieldName(a.label)}
                  </StyledSpan>
                </CheckboxWrapper>
              );
            })}
          </RatingWrapper>
        </ListWrapper>
      </ThemeProvider>
    </Wrapper>
  );
}

ItemReviewed.displayName = "ItemReviewed";

import type { GetBrand_brandConfiguration, FromGQL } from "@types";

type PreviewConfig = {
  hostname?: string;
  mountPoint?: string;
  urlPrefix?: string;
  urlSuffix?: string;
  useDesktopView?: boolean;
};

export function getPreviewURL(
  previewConfig: PreviewConfig,
  id: string,
  bundleType?: string
) {
  const { hostname, mountPoint, urlPrefix, urlSuffix } = previewConfig;
  if (!hostname) return null;

  const path = [mountPoint, urlPrefix, id, urlSuffix]
    .filter((pathPart) => !!pathPart)
    .join("/");

  let query = `status=draft&t=${Date.now()}`;
  if (bundleType) {
    query += `&type=${bundleType}`;
  }
  return `https://${hostname}/${path}?${query}`;
}

export function getPreviewConfig(
  configuration: FromGQL<GetBrand_brandConfiguration> | undefined,
  contentType: string,
  bundleType?: string
): PreviewConfig {
  if (!configuration) return {};
  const contentTypeConfig = configuration?.contentTypes?.find(
    (typeConfig) => typeConfig.value === contentType
  );
  const ctPreview = contentTypeConfig?.preview;
  const btPreview = bundleType
    ? contentTypeConfig?.subtypes?.find(
        (stypeConfig) => stypeConfig.type === bundleType
      )?.preview
    : undefined;

  return {
    hostname:
      btPreview?.hostname ??
      ctPreview?.hostname ??
      configuration?.hostnames?.preview,
    mountPoint: btPreview?.mountPoint ?? ctPreview?.mountPoint ?? "",
    urlPrefix: btPreview?.urlPrefix ?? ctPreview?.urlPrefix ?? "",
    urlSuffix: btPreview?.urlSuffix ?? ctPreview?.urlSuffix ?? "",
    useDesktopView:
      btPreview?.useDesktopView ?? ctPreview?.useDesktopView ?? false,
  };
}

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import {
  length,
  required,
  markdownLinks,
  url,
} from "@condenast/copilot-validators";

import { noEmptyAccordionHeaders } from "../validators/markdown-accordions";
import type { ValidationContext, ValidationEnvironment } from "@lib";
import type { FormFor_content_Article } from "@types";
import { publishURI } from "../validators";

const { object } = validators;

const nativeArticleValidators = object({
  hed: required(),
  body: object({
    content: required()
      .andAlso(markdownLinks())
      .andAlso(noEmptyAccordionHeaders()),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  photosTout: object({
    edges: length({ min: 1 }),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  socialTitle: required().on("publish"),
  seo: object({
    description: required(),
    title: required(),
  }).on("publish"),
  advertiser: object({
    edges: length({ min: 1 }),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  campaignUrl: url().on("publish"),
  bylineOption: required().on("publish"),
  bylineVariant: required().on("publish"),
}).andAlso(publishURI().on("publish"));

export default function validateNativeArticle(
  article: FormFor_content_Article,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(article, build(nativeArticleValidators), context, env);
}

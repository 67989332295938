import { DeepOmit, NullableAsOptional } from "@types";

export function removeNullValues<T extends object>(
  obj: T
): Partial<NullableAsOptional<T>> {
  return Object.entries(obj).reduce((partial, [key, val]) => {
    if (val === null) {
      return partial;
    }
    if (typeof val === "object") {
      if (Array.isArray(val)) {
        return {
          ...partial,
          [key]: val.map((item) =>
            typeof item === "object" ? removeNullValues(item) : item
          ),
        };
      } else {
        return {
          ...partial,
          [key]: removeNullValues(val),
        };
      }
    } else {
      return {
        ...partial,
        [key]: val,
      };
    }
  }, {});
}

export function removeTypeName<T extends object>(
  obj: T
): DeepOmit<T, "__typename"> {
  return Object.entries(obj).reduce((partial, [key, val]) => {
    if (key === "__typename") {
      return partial;
    }
    if (typeof val === "object" && val !== null) {
      if (Array.isArray(val)) {
        return {
          ...partial,
          [key]: val.map((item) =>
            item && typeof item === "object" ? removeTypeName(item) : item
          ),
        };
      } else {
        return {
          ...partial,
          [key]: removeTypeName(val),
        };
      }
    } else {
      return {
        ...partial,
        [key]: val,
      };
    }
  }, {}) as DeepOmit<T, "__typename">;
}

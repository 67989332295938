import type { ContentSerializers } from "../types";
import type {
  CuratedListInput,
  FormFor_content_CuratedList_textItems,
} from "@types";
import {
  contentConnection,
  allContributors,
  categoryTaxonomies,
  seo,
  textItems,
  connectedItems,
} from "../fields";
import { serializerFor, entityMetadata } from "../utils";

const CuratedListFields = {
  allContributors,
  categoryTaxonomies,
  photosLede: contentConnection,
  photosTout: contentConnection,
  related: contentConnection,
  targetRef: contentConnection,
  seo: seo,
  items: connectedItems,
} as const;

export const CuratedListSerializers: ContentSerializers<CuratedListInput> = {
  save: (changes, model, { currentUser }) => {
    if (!("hed" in model)) {
      return null;
    }
    return {
      ...serializerFor(CuratedListFields)(changes),
      textItems:
        "textItems" in model
          ? textItems(
              model.textItems as FormFor_content_CuratedList_textItems[]
            )
          : [],
      hed: model.hed,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
    return {
      hed: createTitle,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      textItems: [],
      entityMetadata: { user: [`${currentUser.id}`] },
      curationSubType: "AssetsOnly",
    };
  },
};

import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";
import CKEditorRenderer from "@condenast/atjson-renderer-ckeditor";
import CKEditorSourceHTML from "@condenast/atjson-source-ckeditor-html";

class EqualityError extends Error {
  constructor(
    public ckeditorDocument: CKEditorSourceHTML,
    public versoDocument: VersoSource
  ) {
    super(
      "Conversion between markdown and CKEditor data produced unequal documents"
    );
  }
}

export function hasConversionError(md: string): Error | undefined {
  try {
    let atjsonDoc = CopilotMarkdownSource.fromRaw(md).convertTo(VersoSource);
    let ckeditorValue;

    ckeditorValue = CKEditorRenderer.render(atjsonDoc);

    let ckeditorDoc =
      CKEditorSourceHTML.fromRaw(ckeditorValue).convertTo(VersoSource);

    if (!ckeditorDoc.equals(atjsonDoc)) {
      throw new EqualityError(ckeditorDoc, atjsonDoc);
    }
  } catch (conversionError) {
    return conversionError as Error;
  }

  return undefined;
}

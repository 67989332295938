import { FormFor_content } from "@types";
import * as FieldSerializers from "./fields";

export function serializerFor(descriptor: {
  [key: string]: typeof FieldSerializers[keyof typeof FieldSerializers];
}) {
  return (changes: Partial<FormFor_content>) => {
    return Object.entries(changes).reduce((o, [key, value]) => {
      if (key in descriptor) {
        const serializer = descriptor[key as keyof typeof descriptor];
        return { ...o, [key]: serializer(value) };
      }
      return { ...o, [key]: value };
    }, {});
  };
}

export function includeAllMetadataFields(
  model: FormFor_content,
  changes: Partial<FormFor_content>
) {
  let record = { ...model } as Record<string, unknown> | null | undefined;
  let allMetadataFields = {} as Record<string, unknown>;
  let metadataFields = ["uri", "excludeFromRecirc", "excludeFromSitemaps"];
  let changesetKeys = Object.keys(changes);
  let shouldIncludeAllMetadataFields = metadataFields.some((metadataField) =>
    changesetKeys.includes(metadataField)
  );

  if (shouldIncludeAllMetadataFields) {
    allMetadataFields.uri = record?.uri as string;
    allMetadataFields.excludeFromRecirc = record?.excludeFromRecirc as boolean;
    allMetadataFields.excludeFromSitemaps =
      record?.excludeFromSitemaps as boolean;
  }

  return allMetadataFields;
}

export function entityMetadata(model: FormFor_content, currentUserId: string) {
  const user = ("entityMetadata" in model && model.entityMetadata?.user) || [];

  return user.indexOf(currentUserId) > -1
    ? { user }
    : {
        user: [...user, currentUserId],
      };
}

import { ThemeProvider } from "@contexts";
import {
  ARIA,
  Button,
  Card,
  SearchControls,
  SearchResult,
  Spinner,
  Checkbox,
  FloatingActionBar,
  UsageInfoDialog,
} from "@components";
import { useDebouncedCallback } from "@hooks";
import pluralize from "pluralize";
import { SlatResult } from "@condenast/gemini";
import {
  Search as SearchResponse,
  ContentSummary,
  Search_search_results,
  GetBrand_brandConfiguration_contentTypes as ContentType,
  MainSearchAdapter,
  GetCurrentUser_currentUser,
  Duplicate,
  Archive,
  Unarchive,
  BulkArchive,
  BulkUnarchive,
} from "@types";
import { useEffect, useState, useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { SearchInput } from "./SearchInput";
import {
  BackIcon,
  NextIcon,
  NewTabIcon,
  EllipsisIcon,
  HideIcon,
  CloseIcon,
} from "@condenast/gemini/icons";
import { useMediaQuery, useDefinedMessages } from "@hooks";
import { useParams, useNavigate } from "react-router-dom";
import { FetchResult } from "@apollo/client";
import { AdvancedSearchOptions } from "../App/Main/Search/types";
import { normalizeContentSummary } from "@lib";

type MenuItem =
  | {
      value: string;
      role: "link";
      to: string;
      target: "_blank";
      onClick?: () => void;
    }
  | {
      role: "action";
      onClick: () => void;
      value: string;
    };

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: "header" "searchControls" "results";
  gap: var(--spacing-sm);
  padding-top: var(--spacing-md);
  margin: 0 auto;
  max-width: ${(props) => props.theme.CardSize};
  width: 100%;
`;

const Header = styled.div<{ $isMobile: boolean }>`
  grid-area: header;
  /*
  display: grid;
  gap: var(--spacing-md);*/
  padding: ${(props) => (props.$isMobile ? "0 var(--spacing-md)" : "0")};
`;

const Divider = styled.div`
  display: inline-block;
  height: 40px;
  border-right: 1px solid ${(props) => props.theme.DividerColor};
`;

const MenuIcon = styled(EllipsisIcon)`
  color: ${(props) => props.theme.Color};
`;

const MenuButton = styled(ARIA.MenuButton)`
  border: 1px solid ${(props) => props.theme.SelectButtonHoverBackgroundColor};
  background: ${(props) => props.theme.Background};
`;

const StyledSearchInput = styled(SearchInput)<{ $isMobile?: boolean }>`
  grid-area: search;
`;

const ResultsInfoHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.FieldDisabledColor};
  padding: var(--spacing-xs);
`;

const ResultsInfoFooter = styled(ResultsInfoHeader)`
  border-top: 1px solid ${(props) => props.theme.DividerColor};
`;

const TotalResults = styled.div`
  font: ${(props) => props.theme.FontStatement};
`;

const ShowBulkSelectionViewButton = styled(Button)``;

const SearchResultWrapper = styled(Card)`
  padding: 0;
  grid-area: results;
`;

const SearchResultList = styled.ul<{ $treatment: string }>`
  ${({ $treatment }) => {
    if ($treatment === "grid")
      return `
        display: grid;
        gap: var(--spacing-xs);
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        padding: var(--spacing-xs);
        &:focus {
          outline: none;
        }
      `;
    else return;
  }}
`;

const ResultsText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: var(--spacing-xxl);
  margin: 0 auto;
`;

const StyledSearchControls = styled(SearchControls)`
  grid-area: "searchControls";
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SelectAllCheckbox = styled(Checkbox)`
  padding: var(--spacing-xxs) 0;
`;

const SEARCH_DEBOUNCE_INTERVAL = 250; // milliseconds
const NON_DUPLICABLE_CONTENT_TYPES = ["photo", "clip", "cartoon"];
export const Search = (props: {
  currentUser: GetCurrentUser_currentUser;
  cdnHost: string;
  brandCode: string;
  brandURL: string;
  organizationId: string;
  contentTypes: ContentType[];
  enableLanguageFilters: boolean;
  syndicationEnabled: boolean;
  availableLanguages: (string | null)[];
  statusOptions: string[];
  dateRangeOptions: string[];
  sortByOptions: string[];
  searchResponse?: SearchResponse;
  searchLoading: boolean;
  searchError: Error | undefined;
  usageInfoResponse?: SearchResponse;
  usageInfoLoading: boolean;
  usageInfoError: Error | undefined;
  onGetUsageInfo: (id: string) => void;
  duplicate: (
    itemToDuplicate: Search_search_results
  ) => Promise<
    FetchResult<Duplicate, Record<string, any>, Record<string, any>>
  >;
  adapters: MainSearchAdapter[];
  selectedAdapter: MainSearchAdapter;
  categoryConfigType?: string | null;
  searchOptions: AdvancedSearchOptions;
  onSearchOptionsChange: (searchOptions: AdvancedSearchOptions) => void;
  pageLimit: number;
  archive: (
    itemToArchive: Search_search_results
  ) => Promise<FetchResult<Archive, Record<string, any>, Record<string, any>>>;
  unarchive: (
    itemToUnarchive: Search_search_results
  ) => Promise<
    FetchResult<Unarchive, Record<string, any>, Record<string, any>>
  >;
  bulkArchive: (
    itemToUnarchive: Search_search_results[]
  ) => Promise<
    FetchResult<BulkArchive, Record<string, any>, Record<string, any>>
  >;
  bulkUnarchive: (
    itemToUnarchive: Search_search_results[]
  ) => Promise<
    FetchResult<BulkUnarchive, Record<string, any>, Record<string, any>>
  >;
  setArchivedItem: React.Dispatch<
    React.SetStateAction<Search_search_results | undefined>
  >;
  isToastVisible: boolean;
  trackViewInUse?: (index: number) => void;
  trackNavigation?: (index: number) => void;
}) => {
  const {
    currentUser,
    cdnHost,
    brandCode,
    brandURL,
    organizationId,
    contentTypes,
    enableLanguageFilters,
    syndicationEnabled,
    availableLanguages,
    statusOptions,
    dateRangeOptions,
    sortByOptions,
    searchResponse,
    searchLoading,
    searchError,
    usageInfoResponse,
    usageInfoLoading,
    usageInfoError,
    onGetUsageInfo,
    duplicate,
    adapters,
    selectedAdapter,
    searchOptions,
    onSearchOptionsChange,
    pageLimit,
    categoryConfigType,
    archive,
    unarchive,
    bulkArchive,
    bulkUnarchive,
    setArchivedItem,
    isToastVisible,
    trackViewInUse,
    trackNavigation,
  } = props;
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width: 813px)");
  let params = useParams() as {
    copilotCode: string;
  };
  const [isBulkSelecting, setIsBulkSelecting] = useState(false);
  const [lastAddedBulkSelectionIndex, setLastAddedBulkSelectionIndex] =
    useState<number>(0);
  const [bulkSelections, setBulkSelections] = useState<Search_search_results[]>(
    []
  );
  // States to track Shift and esc key presses
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const [showUsageInfoDialog, setShowUsageInfoDialog] = useState(false);
  const [usageInfoItemContentType, setUsageInfoItemContentType] = useState("");
  const pageNumber = useMemo(() => {
    return searchOptions.page ? parseInt(searchOptions.page) : null;
  }, [searchOptions.page]);

  const hasNextPage = useMemo(() => {
    if (!pageNumber || !searchResponse?.search.totalResults) {
      return false;
    }
    return pageNumber * pageLimit <= searchResponse?.search.totalResults;
  }, [pageNumber, pageLimit, searchResponse?.search?.totalResults]);

  const resultCount = searchResponse?.search.totalResults || 0;
  const resultLimit = searchResponse?.search.limit || 0;
  const resultOffset = searchResponse?.search.offset || 0;

  const resultStart = resultOffset + 1 || 0;
  const resultEnd = Math.min(resultOffset + resultLimit, resultCount) || 0;

  const isItemInBulklSelections = (
    item: Search_search_results,
    selections: Search_search_results[]
  ) => selections.some((selection) => selection.id === item.id);

  const getLiveURL = useCallback(
    (item: Search_search_results) =>
      item.publishable &&
      new Date(item.pubDate) < new Date() &&
      item.uri &&
      `${brandURL}${item.uri}`,
    [brandURL]
  );

  const getPreviewURL = useCallback(
    (item: Search_search_results) => {
      let protocol = window.location.protocol;
      let host = window.location.host;
      return (
        item.publishable &&
        `${protocol}//${host}/${params.copilotCode}/preview/${item.contentType}/${item.id}`
      );
    },
    [params.copilotCode]
  );

  const handleDuplicateClick = useCallback(
    async (item: Search_search_results) => {
      const duplicateResponse = await duplicate(item);
      const newId = duplicateResponse.data?.duplicate?.id;
      if (!newId || duplicateResponse.errors?.length) {
        return;
      } else {
        navigate(`/${brandCode}/${pluralize(item.contentType)}/${newId}`);
      }
    },
    [brandCode, duplicate, navigate]
  );

  const handleArchiveItemClick = useCallback(
    async (item: Search_search_results) => {
      setArchivedItem(item);
      return archive(item);
    },
    [archive, setArchivedItem]
  );

  const handleUnarchiveItemClick = useCallback(
    async (item: Search_search_results) => {
      setArchivedItem(item);
      return unarchive(item);
    },
    [setArchivedItem, unarchive]
  );

  const handleGetUsageInfoClick = useCallback(
    (item: Search_search_results) => {
      onGetUsageInfo(item.id);
      setUsageInfoItemContentType(item.contentType);
      setShowUsageInfoDialog(true);
    },
    [onGetUsageInfo, setUsageInfoItemContentType, setShowUsageInfoDialog]
  );

  const exitBulkSelectionView = useCallback(() => {
    if ("Intercom" in window) {
      Intercom("update", {
        hide_default_launcher: false,
      });
    }
    setBulkSelections([]);
    setIsBulkSelecting(false);
    setLastAddedBulkSelectionIndex(0);
  }, []);

  // Event handlers for shift and meta key presses
  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Shift") setIsShiftPressed(true);
    };

    //locale, nativeEvent, isDefaultPrevented, isPropagationStopped, persist
    const handleKeyUp = (e: any) => {
      if (e.key === "Shift") setIsShiftPressed(false);
      if (isBulkSelecting && e.key === "Escape") {
        exitBulkSelectionView();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [isBulkSelecting, exitBulkSelectionView]);

  const isEntireCurrentPageSelected = useMemo(() => {
    return (
      !!searchResponse?.search.results.length &&
      bulkSelections.length >= searchResponse.search.results.length &&
      searchResponse.search.results.every((result) =>
        bulkSelections.some((selection) => selection.id === result.id)
      )
    );
  }, [bulkSelections, searchResponse?.search.results]);

  const bulkSelectionMenuItems = useMemo(() => {
    if (bulkSelections.length === 1) {
      let liveURL = getLiveURL(bulkSelections[0]);
      let previewURL = getPreviewURL(bulkSelections[0]);
      let index = searchResponse?.search.results.findIndex(
        (selection) => selection.id === bulkSelections[0].id
      );
      let menuItems: MenuItem[] = [
        {
          value: "Open in new tab",
          role: "link",
          to: bulkSelections[0].editUrl || "",
          target: "_blank",
          onClick: () =>
            (index != undefined && index != null && trackNavigation?.(index)) ||
            {},
        },
      ];
      if (liveURL) {
        menuItems.push({
          value: "View live",
          role: "link",
          to: liveURL,
          target: "_blank",
        });
      }

      if (previewURL) {
        menuItems.push({
          value: "Preview",
          role: "link",
          to: previewURL,
          target: "_blank",
        });
      }

      if (
        !NON_DUPLICABLE_CONTENT_TYPES.includes(bulkSelections[0].contentType)
      ) {
        menuItems.push({
          role: "action",
          onClick: async () => {
            await handleDuplicateClick(bulkSelections[0]);
          },
          value: "Duplicate",
        });
      }

      menuItems.push({
        role: "action",
        onClick: () => {
          handleGetUsageInfoClick(bulkSelections[0]);
        },
        value: "Where is this used?",
      });
      return menuItems;
    }
    return;
  }, [
    bulkSelections,
    getLiveURL,
    getPreviewURL,
    handleDuplicateClick,
    handleGetUsageInfoClick,
    searchResponse?.search.results,
    trackNavigation,
  ]);

  const toggleSelectAll = useCallback(
    (selectAll: boolean) => {
      if (selectAll) {
        // add current page
        const newSelections =
          searchResponse?.search.results.filter(
            (result) => !isItemInBulklSelections(result, bulkSelections)
          ) ?? [];
        setBulkSelections([...bulkSelections, ...newSelections]);
      } else {
        // remove current page
        const currentPageIds = new Set(
          searchResponse?.search.results.map((result) => result.id)
        );
        const newSelections = bulkSelections.filter(
          (selection) => !currentPageIds.has(selection.id)
        );
        setBulkSelections(newSelections);
      }
    },
    [bulkSelections, searchResponse?.search.results]
  );

  const toggleBulkSelection = useCallback(
    (bulkSelection: Search_search_results, bulkSelectionIndex: number) => {
      if (isShiftPressed) {
        // Toggle a range of items with the Shift+Command keys
        const startIndex = Math.min(
          lastAddedBulkSelectionIndex,
          bulkSelectionIndex
        );
        const endIndex = Math.max(
          lastAddedBulkSelectionIndex,
          bulkSelectionIndex
        );

        let newSelections = [...bulkSelections];
        let allInRangeSelected = true;
        let idsToRemove = new Set();

        for (let i = startIndex; i <= endIndex; i++) {
          const resultItem = searchResponse?.search.results[i];
          if (
            resultItem &&
            newSelections.findIndex(
              (selection) => selection.id === resultItem.id
            ) < 0
          ) {
            allInRangeSelected = false;
            newSelections.push(resultItem);
          } else if (allInRangeSelected) {
            idsToRemove.add(resultItem?.id);
          }
        }

        if (allInRangeSelected) {
          newSelections = newSelections.filter(
            (selection) => !idsToRemove.has(selection.id)
          );
        }

        setBulkSelections(newSelections);
      } else {
        if (
          bulkSelections.some((selection) => selection.id === bulkSelection.id)
        ) {
          setBulkSelections(
            bulkSelections.filter(
              (selection) => selection.id !== bulkSelection.id
            )
          );
        } else {
          setBulkSelections([...bulkSelections, bulkSelection]);
        }
      }
      setLastAddedBulkSelectionIndex(bulkSelectionIndex);
    },
    [
      bulkSelections,
      lastAddedBulkSelectionIndex,
      searchResponse?.search.results,
      isShiftPressed,
    ]
  );

  const [treatment, setTreatment] = useState<"slat" | "grid">(
    (localStorage.getItem(`${selectedAdapter.id}-tab-treatment`) as
      | "slat"
      | "grid") ??
      selectedAdapter.defaultTreatment ??
      "slat"
  );

  const setSavedTreatment = useCallback(
    (treatment: "slat" | "grid") => {
      localStorage.setItem(`${selectedAdapter.id}-tab-treatment`, treatment);
    },
    [selectedAdapter.id]
  );

  const queryOption = searchOptions?.query ?? "";
  const [searchQueryInputValue, setSearchQueryInputValue] =
    useState(queryOption);
  const debouncedQueryOptionChange = useDebouncedCallback(
    (query: string) => onSearchOptionsChange({ query }),
    SEARCH_DEBOUNCE_INTERVAL,
    [onSearchOptionsChange]
  );
  const onSearchQueryInputChange = useCallback(
    (query: string) => {
      setSearchQueryInputValue(query);
      debouncedQueryOptionChange(query);
    },
    [setSearchQueryInputValue, debouncedQueryOptionChange]
  );

  useEffect(() => {
    setSearchQueryInputValue(queryOption);
  }, [queryOption, setSearchQueryInputValue]);

  const { translateContentType } = useDefinedMessages();

  return (
    <Wrapper>
      <Header $isMobile={isMobile}>
        <StyledSearchInput
          loading={searchLoading}
          query={searchQueryInputValue}
          onChange={onSearchQueryInputChange}
          $isMobile={isMobile}
        />
        <StyledSearchControls
          currentUser={currentUser}
          organizationId={organizationId}
          contentTypes={contentTypes}
          syndicationEnabled={syndicationEnabled}
          availableLanguages={availableLanguages}
          enableLanguageFilters={enableLanguageFilters}
          statusOptions={statusOptions}
          sortByOptions={sortByOptions}
          dateRangeOptions={dateRangeOptions}
          adapters={adapters}
          selectedAdapter={selectedAdapter}
          treatment={treatment}
          setTreatment={(treatment: "grid" | "slat") => {
            setTreatment(treatment);
            setSavedTreatment(treatment);
          }}
          isMobile={isMobile}
          searchParams={searchOptions}
          onChange={onSearchOptionsChange}
          categoryConfigType={categoryConfigType}
        />
      </Header>
      <SearchResultWrapper>
        {searchLoading ? (
          <ResultsText>
            <Spinner size="large" />
          </ResultsText>
        ) : (
          <>
            <ResultsInfoHeader>
              <TotalResults>
                <FormattedMessage
                  defaultMessage="{start, number} - {end, number} of {total, number}"
                  values={{
                    total: resultCount,
                    start: resultStart,
                    end: resultEnd,
                  }}
                />
              </TotalResults>
              {isBulkSelecting ? (
                <>
                  <SelectAllCheckbox
                    size="large"
                    indeterminate={
                      bulkSelections.length > 0 && !isEntireCurrentPageSelected
                    }
                    checked={isEntireCurrentPageSelected}
                    onChange={toggleSelectAll}
                  />
                </>
              ) : (
                <ShowBulkSelectionViewButton
                  treatment="text"
                  aria-label="Toggle bulk select view"
                  disabled={searchResponse?.search.totalResults === 0}
                  onClick={() => {
                    if ("Intercom" in window) {
                      Intercom("update", {
                        hide_default_launcher: true,
                      });
                    }
                    setIsBulkSelecting(true);
                  }}
                >
                  <NewTabIcon size="regular" />
                  <FormattedMessage defaultMessage="Select" />
                </ShowBulkSelectionViewButton>
              )}
            </ResultsInfoHeader>
            <SearchResultList $treatment={treatment}>
              {!searchLoading &&
              !searchError &&
              searchResponse &&
              searchResponse.search.results.length > 0 ? (
                searchResponse.search.results.map(
                  (item: Search_search_results, index: number) => {
                    let isResultAddedToBulkSelection = bulkSelections.some(
                      (bulkSelection) => bulkSelection.id === item.id
                    );

                    let searchResultMenuItems: MenuItem[] = [
                      {
                        value: "Open in new tab",
                        role: "link",
                        to: item.editUrl || "",
                        target: "_blank",
                        onClick: () => trackNavigation?.(index) || {},
                      },
                    ];
                    let liveURL = getLiveURL(item);
                    let previewURL = getPreviewURL(item);
                    const isArchived = item.archived;

                    if (liveURL) {
                      searchResultMenuItems.push({
                        value: "View live",
                        role: "link",
                        to: liveURL,
                        target: "_blank",
                      });
                    }

                    if (previewURL) {
                      searchResultMenuItems.push({
                        value: "Preview",
                        role: "link",
                        to: previewURL,
                        target: "_blank",
                      });
                    }

                    if (isArchived) {
                      searchResultMenuItems.push({
                        role: "action",
                        onClick: async () => {
                          await handleUnarchiveItemClick(item);
                        },
                        value: "Show in search",
                      });
                    } else {
                      searchResultMenuItems.push({
                        role: "action",
                        onClick: async () => {
                          await handleArchiveItemClick(item);
                        },
                        value: "Hide from search",
                      });
                    }

                    if (
                      !NON_DUPLICABLE_CONTENT_TYPES.includes(item.contentType)
                    ) {
                      searchResultMenuItems.push({
                        role: "action",
                        onClick: async () => {
                          await handleDuplicateClick(item);
                        },
                        value: "Duplicate",
                      });
                    }

                    searchResultMenuItems.push({
                      role: "action",
                      onClick: () => {
                        handleGetUsageInfoClick(item);
                      },
                      value: "Where is this used?",
                    });

                    return (
                      <li key={item.id}>
                        <SearchResult
                          to={new URL(item?.editUrl || "").pathname}
                          id={`$select-item-${index}`}
                          result={
                            normalizeContentSummary(
                              item,
                              translateContentType
                            ) as ContentSummary
                          }
                          aria-selected={isResultAddedToBulkSelection}
                          treatment={
                            treatment === "slat" ? "slat-result" : "asset-tile"
                          }
                          selectionInitiated={isBulkSelecting}
                          cdnHost={cdnHost}
                          trackNavigation={() =>
                            trackNavigation && trackNavigation(index)
                          }
                          assetindex={index}
                          trackViewInUse={trackViewInUse}
                          onClick={() => toggleBulkSelection(item, index)}
                          hideIndex={true}
                          onKeyPress={() => {
                            // e.preventDefault();
                            // if (e.key === " ") {
                            //   if (isBulkSelecting) {
                            //     toggleBulkSelection(item, index);
                            //   } else {
                            //     //simulate click on MenuButton
                            //     let menuButton = document.getElementById(
                            //       `${item.id}-actions`
                            //     );
                            //     menuButton?.click();
                            //   }
                            // }
                          }}
                        >
                          <SlatResult.ActionsMenu
                            id={item.id}
                            actions={searchResultMenuItems}
                          />
                        </SearchResult>
                      </li>
                    );
                  }
                )
              ) : (
                <ResultsText>
                  {searchLoading ? (
                    <Spinner size="large" />
                  ) : searchError ? (
                    <FormattedMessage defaultMessage="Sorry, something went wrong. Please try searching again." />
                  ) : (
                    <FormattedMessage defaultMessage="There are 0 search results." />
                  )}
                </ResultsText>
              )}
            </SearchResultList>
            <ResultsInfoFooter>
              <TotalResults>
                <FormattedMessage
                  defaultMessage="{start, number} - {end, number} of {total, number}"
                  values={{
                    total: resultCount,
                    start: resultStart,
                    end: resultEnd,
                  }}
                />
              </TotalResults>
              <div>
                <Button
                  aria-label="Previous Page"
                  onClick={() =>
                    onSearchOptionsChange({
                      page:
                        pageNumber && pageNumber > 1
                          ? `${pageNumber - 1}`
                          : "1",
                    })
                  }
                  disabled={!pageNumber || pageNumber <= 1}
                >
                  <BackIcon size="regular" />
                </Button>
                <Button
                  aria-label="Next Page"
                  onClick={() =>
                    onSearchOptionsChange({
                      page: pageNumber ? `${pageNumber + 1}` : "1",
                    })
                  }
                  disabled={!hasNextPage}
                >
                  <NextIcon size="regular" />
                </Button>
              </div>
            </ResultsInfoFooter>
          </>
        )}
      </SearchResultWrapper>

      {isBulkSelecting && (
        <ThemeProvider theme="dark">
          <FloatingActionBar
            isToastVisible={isToastVisible}
            isBulkSelecting={isBulkSelecting}
          >
            <Button
              aria-label="Clear bulk selections"
              onClick={() => {
                exitBulkSelectionView();
              }}
            >
              <CloseIcon size="regular" />
            </Button>
            <Divider />
            <Button
              // disabled until action is implemented
              // aria-label="Review Selections"
              disabled={true}
              onClick={() => {}}
            >
              <FormattedMessage
                defaultMessage="{itemCount, plural, one {# item selected} other {# items selected}}"
                values={{ itemCount: bulkSelections.length }}
              />
            </Button>
            <Divider />
            {bulkSelections.length >= 1 && bulkSelections[0].archived ? (
              <Button
                aria-label="Show selections"
                disabled={bulkSelections.length === 0}
                onClick={() => bulkUnarchive(bulkSelections)}
              >
                <HideIcon size="regular" />
                <FormattedMessage defaultMessage="Show" />
              </Button>
            ) : (
              <Button
                aria-label="Hide selections"
                disabled={bulkSelections.length === 0}
                onClick={() => bulkArchive(bulkSelections)}
              >
                <HideIcon size="regular" />
                <FormattedMessage defaultMessage="Hide" />
              </Button>
            )}
            {bulkSelections.length === 1 && (
              <MenuButton
                treatment="secondary"
                aria-label="Actions menu"
                menu={{
                  theme: "light",
                  items: bulkSelectionMenuItems,
                  children: (item: string) => item,
                }}
              >
                <MenuIcon size="regular" />
              </MenuButton>
            )}
          </FloatingActionBar>
        </ThemeProvider>
      )}

      {(usageInfoLoading ||
        usageInfoError ||
        usageInfoResponse?.search.results) &&
        usageInfoItemContentType &&
        showUsageInfoDialog && (
          <UsageInfoDialog
            contentType={usageInfoItemContentType}
            cdnHost={cdnHost}
            usageInfoLoading={usageInfoLoading}
            usageInfoError={usageInfoError}
            backReferences={
              (usageInfoResponse?.search.results.map((result) =>
                normalizeContentSummary(result, translateContentType)
              ) ?? []) as ContentSummary[]
            }
            close={() => setShowUsageInfoDialog(false)}
          />
        )}
    </Wrapper>
  );
};

Search.displayName = "Search";

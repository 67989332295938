import { defineMessage, defineMessages } from "react-intl";

export const ERROR_SUMMARY_MESSAGES = defineMessages({
  unknown: {
    defaultMessage:
      "{field}: no translation was found for a validation error with the key {name}",
  },

  blank: {
    defaultMessage: "{field} can't be blank",
    description: "validationMessage.blank",
  },
  "blank-url": {
    defaultMessage: "{field} can't be blank",
    description: "validationMessage.blank-url",
  },
  "brand-required": {
    defaultMessage: "{field} can't be blank",
    description: "validationMessage.brand-required",
  },

  "canonical-url": {
    defaultMessage: "You must enter a canonical url",
    description: "validationMessage.canonical-url",
  },

  "categories-minimum": {
    defaultMessage: "{field} requires a selection for {categories}",
    description: "validationMessage.categories-minimum",
  },

  "channel-required": {
    defaultMessage: "{field} can't be blank",
    description: "validationMessage.channel-required",
  },

  "channels-allowed-match": {
    defaultMessage:
      "You must choose 1 category for {field} from {validCategorySlugsAndParents}",

    description: "validationMessage.channels-allowed-match",
  },

  "channels-allowed-maximum": {
    defaultMessage: "You must choose only 1 category for {field}",

    description: "validationMessage.channels-allowed-maximum",
  },

  "channels-allowed-minimum": {
    defaultMessage: "You must choose 1 category for {field}",

    description: "validationMessage.channels-allowed-minimum",
  },

  "story-type-allowed-minimum": {
    defaultMessage: "You must choose 1 Story Type from Categories",

    description: "validationMessage.story-type-allowed-minimum",
  },

  "clip-duration-error": {
    defaultMessage:
      "{field}: clip {key} must be less than or equal to {limit} seconds",

    description: "validationMessage.clip-duration-error",
  },

  "cnt-year": {
    defaultMessage: "{field} must be 4 digits, example: 2014",

    description: "validationMessage.cnt-year",
  },

  "contributor-required-article": {
    defaultMessage: "{field} must have at least one contributor",

    description: "validationMessage.contributor-required-article",
  },

  date: {
    defaultMessage: "{field}: invalid date",
    description: "validationMessage.date",
  },
  "distribution-channels.apple-news-disabled": {
    defaultMessage:
      "{field} is not configured to allow publishing to Apple News",

    description: "validationMessage.distribution-channels.apple-news-disabled",
  },

  "distribution-channels.apple-news-premium-disabled": {
    defaultMessage:
      "{field} is not configured to allow publishing to Apple News+",

    description:
      "validationMessage.distribution-channels.apple-news-premium-disabled",
  },

  "distribution-channels.web-apple-news-premium-disabled": {
    defaultMessage:
      "{field} is not configured to allow publishing to Web and Apple News+ at the same time",

    description:
      "validationMessage.distribution-channels.web-apple-news-premium-disabled",
  },

  "distribution-channels.web-disabled": {
    defaultMessage: "{field} not configured to allow publishing to web",

    description: "validationMessage.distribution-channels.web-disabled",
  },

  "duplicate-channels": {
    defaultMessage: "{field}: {property} ({value}) already exists.",

    description: "validationMessage.duplicate-channels",
  },

  "duplicate-contentTypes": {
    defaultMessage: "{field}: {property} ({value}) already exists.",

    description: "validationMessage.duplicate-contentTypes",
  },

  "duplicate-subchannels": {
    defaultMessage: "{field}: {property} ({value}) already exists.",

    description: "validationMessage.duplicate-subchannels",
  },

  "duplicate-templates": {
    defaultMessage: "{field}: {property} ({value}) already exists.",

    description: "validationMessage.duplicate-templates",
  },

  "editor-enabled": {
    defaultMessage: "{field} can't be blank",
    description: "validationMessage.editor-enabled",
  },

  email: {
    defaultMessage: "{field} is not a valid email",
    description: "validationMessage.email",
  },
  "empty-slideshow": {
    defaultMessage: "slideshows must have at least one filled slide",
    description: "validationMessage.empty-slideshow",
  },
  "empty-accordion-header": {
    defaultMessage: "accordion heading can't be blank",
    description: "validationMessage.empty-accordion-header",
  },
  "eventDate-required-address": {
    defaultMessage: "{field} is required if address is present",

    description: "validationMessage.eventDate-required-address",
  },

  "eventDate-required-livestream": {
    defaultMessage: "{field} is required if Livestream is available",

    description: "validationMessage.eventDate-required-livestream",
  },

  "file-size": {
    defaultMessage: "{field} cannot exceed {maxSize}MB.",

    description: "validationMessage.file-size",
  },

  "gallery-items": {
    defaultMessage: "A gallery must have at least 1 item",

    description: "validationMessage.gallery-items",
  },

  geo: {
    defaultMessage: "{field} is not a valid value of lat, long",

    description: "validationMessage.geo",
  },

  "geo.lat": {
    defaultMessage: "{field} must be between -90° and 90°",

    description: "validationMessage.geo.lat",
  },

  "geo.lng": {
    defaultMessage: "{field} must be between -180° and 180°",

    description: "validationMessage.geo.lng",
  },

  "inline-all-present": {
    defaultMessage: "All embedded items must be present, some do not exist",

    description: "validationMessage.inline-all-present",
  },

  integer: {
    defaultMessage: "{field} must be an integer",
    description: "validationMessage.integer",
  },
  invalid: {
    defaultMessage: "{field} is invalid",
    description: "validationMessage.invalid",
  },
  "invalid-number-or-percent": {
    defaultMessage: "{field} must be a number or %",

    description: "validationMessage.invalid-number-or-present",
  },

  "invalid-url": {
    defaultMessage: "{field} must be a full URL",

    description: "validationMessage.invalid-url",
  },

  invalidId: {
    defaultMessage: "{field}: {id} is not a valid id",

    description: "validationMessage.invalidId",
  },

  "is-iso-date-string": {
    defaultMessage: "{field} is not a valid date",

    description: "validationMessage.is-iso-date-string",
  },

  "items-hydrated": {
    defaultMessage:
      "{field}: one or more of your slides has corrupted data. Please refresh and try again.",

    description: "validationMessage.items-hydrated",
  },

  "markdown-links": {
    defaultMessage:
      "{field}: URLs need to follow this format: http://www.example.com. Update {invalidUrls}",

    description: "validationMessage.markdown-links",
  },

  match: {
    defaultMessage: "{field}: fields do not match",
    description: "validationMessage.match",
  },
  "missing-uri-parts": {
    defaultMessage: "{field}: uri segments are missing",

    description: "validationMessage.missing-uri-parts",
  },

  missingId: {
    defaultMessage: "{field}: content with the id: {id} could not be found",

    description: "validationMessage.missingId",
  },

  "nativeBundles.advertiser": {
    defaultMessage: "{field} must be present",

    description: "validationMessage.nativeBundles.advertiser",
  },

  "nativeBundles.campaignUrl": {
    defaultMessage: "{field} is not a valid url",

    description: "validationMessage.nativeBundles.advertiser",
  },

  "nativeBundles.bylineOption": {
    defaultMessage: "{field} can't be blank",

    description: "validationMessage.bylineOption",
  },

  "nativeBundles.bylineVariant": {
    defaultMessage: "{field} can't be blank",

    description: "validationMessage.bylineVariant",
  },

  "newsletter-date": {
    defaultMessage: "{field}: invalid date",
    description: "validationMessage.newsletter-date",
  },

  "newsletter-feature-content": {
    defaultMessage:
      "{field}: newsletter must have at least one feature content",

    description: "validationMessage.newletter-feature-content",
  },

  "newsletter-short-link": {
    defaultMessage: "{field}: newsletter must have at least one short link",

    description: "validationMessage.newsletter-short-link",
  },

  "no-replacement-chars": {
    defaultMessage: "{field} can't have empty asset groups",

    description: "validationMessage.no-replacement-chars",
  },

  notEqual: {
    defaultMessage: "{field} must be equal to {value}",

    description: "validationMessage.notEqual",
  },

  notGreaterThan: {
    defaultMessage: "{field} must be greater than {value}",

    description: "validationMessage.notGreaterThan",
  },

  notGreaterThanOrEqualTo: {
    defaultMessage: "{field} must be greater than or equal to {value}",

    description: "validationMessage.notGreaterThanOrEqualTo",
  },

  notInteger: {
    defaultMessage: "{field} must be an integer",
    description: "validationMessage.notInteger",
  },

  notLessThan: {
    defaultMessage: "{field} must be less than {value}",

    description: "validationMessage.notLessThan",
  },

  notLessThanOrEqualTo: {
    defaultMessage: "{field} must be less than or equal to {value}",

    description: "validationMessage.notLessThanOrEqualTo",
  },

  notNumber: {
    defaultMessage: "{field} must be a number",
    description: "validationMessage.notNumber",
  },
  notUnique: {
    defaultMessage: "{field} is already taken. Please choose a different one.",

    description: "validationMessage.notUnique",
  },

  "number-or-percentage": {
    defaultMessage: "{field} must be a number or %",

    description: "validationMessage.number-or-percentage",
  },

  "password-length": {
    defaultMessage: "{field} must be at least 8 characters long.",

    description: "validationMessage.password-length",
  },

  "password-validation": {
    defaultMessage:
      "{field} must contain at least one numeric digit and one uppercase character",

    description: "validationMessage.password-validation",
  },

  "photo-duration-error": {
    defaultMessage:
      "{field}: Gif {key} must be less than or equal to {limit} seconds",

    description: "validationMessage.photo-duration-error",
  },

  "photosTout-required": {
    defaultMessage: "{field}: Please add a site image before publishing",

    description: "validationMessage.photosTout-required",
  },

  "publish-config-valid": {
    defaultMessage: "publish configuration is not correct.",

    description: "validationMessage.publish-config-valid",
  },

  "publish-uri": {
    defaultMessage: "Must be published",
    description: "validationMessage.publish-uri",
  },
  range: {
    defaultMessage: "{field} must be in a valid range",
    description: "validationMessage.range",
  },

  "review-sync": {
    defaultMessage:
      "{field}: This version of the review is not the latest. To get the latest version, please use the breadcrumb to navigate back to the fashion show. Then, click the edit review button.",

    description: "validationMessage.review-sync",
  },

  "save-duplicate-channels": {
    defaultMessage: "There are duplicate channels",

    description: "validationMessage.save-duplicate-channels",
  },

  "save-duplicate-container": {
    defaultMessage: "There are duplicate container values or types",

    description: "validationMessage.save-duplicate-container",
  },

  "save-duplicate-contentTypes": {
    defaultMessage: "There are duplicate content types",

    description: "validationMessage.save-duplicate-contentTypes",
  },

  "save-duplicate-subchannels": {
    defaultMessage: '"{channel}" has duplicate subchannels',

    description: "validationMessage.save-duplicate-subchannels",
  },

  "save-duplicate-templates": {
    defaultMessage: '"{contentType}" has duplicate templates',

    description: "validationMessage.save-duplicate-templates",
  },

  "save-duplicate-type": {
    defaultMessage: "There are duplicate bundle subtype names",

    description: "validationMessage.save-duplicate-type",
  },

  "save-empty-container": {
    defaultMessage: "Container value or type can't be blank",

    description: "validationMessage.save-empty-container",
  },

  "save-empty-type": {
    defaultMessage: "Subtype name can't be blank",

    description: "validationMessage.save-empty-type",
  },

  "sections-allowed-minimum": {
    defaultMessage: "You must choose 1 category for {field}",

    description: "validationMessage.sections-allowed-minimum",
  },

  "server-content-conflict": {
    defaultMessage: "Another content item already exists at {message}",

    description: "validationMessage.servor-content-conflict",
  },

  "server-error": {
    defaultMessage: "{error}: {message}; {status}",

    description: "validationMessage.server-error",
  },

  slug: {
    defaultMessage: "{field} is not a valid slug",
    description: "validationMessage.slug",
  },
  subchannel: {
    defaultMessage: "Subchannel can't be blank",
    description: "validationMessage.subchannel",
  },

  "syndication-bad-request": {
    defaultMessage: "Error while syndicating: {message}",

    description: "validationMessage.syndicatin-bad-request",
  },

  "syndication-conflict": {
    defaultMessage: "Content has already been syndicated to the brand",

    description: "validationMessage.syndication-conflict",
  },

  "syndication-contributorsAuthor-required": {
    defaultMessage:
      "{field}: Cannot syndicate content without a contributor author",

    description: "validationMessage.syndication-contributorsAuthor-required",
  },

  "syndication-photosTout-required": {
    defaultMessage: "{field}: Cannot syndicate content without a site image",

    description: "validationMessage.syndication-photosTout-required",
  },

  tooLongLength: {
    defaultMessage: "{field} is too long (maximum {value} characters)",

    description: "validationMessage.tooLongLength",
  },

  tooManyItems: {
    defaultMessage: "No more than {maxItems} items allowed per upload",

    description: "validationMessage.tooManyItems",
  },

  tooShortLength: {
    defaultMessage: "{field} must be present",
    description: "validationMessage.tooShortLength",
  },

  tooShortMinLength: {
    defaultMessage: "{field} is too short (minimum {value} characters)",

    description: "validationMessage.tooShortMinLength",
  },

  "transcriptUpload.invalid-type": {
    defaultMessage: "Only .txt files are accepted",

    description: "validationMessage.transcriptUpload.invalid-type",
  },

  "transcriptUpload.upload-error": {
    defaultMessage: "Transcript upload failed",

    description: "validationMessage.transcriptUpload.upload-error",
  },

  "top-level-category-primary": {
    defaultMessage: "Transcript upload failed",

    description: "validationMessage.top-level-catergory-primary",
  },

  unicode: {
    defaultMessage: "{field} must be 6 digits",
    description: "validationMessage.unicode",
  },
  "uri-exists": {
    defaultMessage:
      'This URL is already in use. Please change the slug and click "Publish" again',

    description: "validationMessage.uri-exists",
  },

  "uri-format": {
    defaultMessage: "{field} format is invalid",
    description: "validationMessage.uri-format",
  },

  url: {
    defaultMessage: "{field} is not a valid url",
    description: "validationMessage.url",
  },
  "url-absolute-leading-slash": {
    defaultMessage:
      "{field} is not valid. Please preface with either a leading slash (/), http:// or https://.",

    description: "validationMessage.url-absolute-leading-slash",
  },

  wrongLength: {
    defaultMessage:
      "{field} is the wrong length (should be {value} characters)",

    description: "validationMessage.wrongLength",
  },

  year: {
    defaultMessage: "{field} must be 4 digits, example: 2014",

    description: "validationMessage.year",
  },

  "query.filters.categories": {
    defaultMessage: "Tag can't be blank",
    description: "validationMessage.query.filters.categories",
  },
  "query.filters.types": {
    defaultMessage: "Filter by content can't be blank",
    description: "validationMessage.query.filters.types",
  },
});

export const ERROR_SUMMARY_UNKNOWN = defineMessage({
  defaultMessage:
    "{field}: no translation was found for a validation error with the key {name}",
});

export const ERROR_FIELD_MESSAGES = defineMessages({
  blank: {
    defaultMessage: "Can't be blank",
    description: "validationMessage.blank",
  },
  "blank-url": {
    defaultMessage: "URL can't be blank",
    description: "validationMessage.blank-url",
  },
  "brand-required": {
    defaultMessage: "Can't be blank",
    description: "validationMessage.brand-required",
  },
  "canonical-url": {
    defaultMessage: "You must enter a canonical url",

    description: "validationMessage.canonical-url",
  },

  "categories-minimum": {
    defaultMessage: "Requires a selection for {categories}",

    description: "validationMessage.categories-minimum",
  },

  "channel-required": {
    defaultMessage: "Can't be blank",
    description: "validationMessage.channel-required",
  },
  "channels-allowed-match": {
    defaultMessage:
      "You must choose 1 category for channels from {validCategorySlugsAndParents}",

    description: "validationMessage.channels-allowed-match",
  },

  "channels-allowed-maximum": {
    defaultMessage: "You must choose only 1 category for channels",

    description: "validationMessage.channels-allowed-maximum",
  },

  "channels-allowed-minimum": {
    defaultMessage: "You must choose 1 category for channels",

    description: "validationMessage.allowed-minimum",
  },

  "clip-duration-error": {
    defaultMessage: "Clip {key} must be less than or equal to {limit} seconds",

    description: "validationMessage.clip-duration-error",
  },

  "cnt-year": {
    defaultMessage: "Must be 4 digits, example: 2014",

    description: "validationMessage.cnt-year",
  },

  "contributor-required-article": {
    defaultMessage: "Must have at least one contributor",

    description: "validationMessage.contributor-required-article",
  },

  date: {
    defaultMessage: "invalid date",
    description: "validationMessage.date",
  },
  "distribution-channels.apple-news-disabled": {
    defaultMessage: "Are not configured to allow publishing to Apple News",

    description: "validationMessage.distribution-channels.apple-news-disabled",
  },

  "distribution-channels.apple-news-premium-disabled": {
    defaultMessage: "Are not configured to allow publishing to Apple News+",

    description:
      "validationMessage.distribution-channels.apple-news-premium-disabled",
  },

  "distribution-channels.web-apple-news-premium-disabled": {
    defaultMessage:
      "Are not configured to allow publishing to Web and Apple News+ at the same time",

    description:
      "validationMessage.distribution-channels.web-apple-news-premium-disabled",
  },

  "distribution-channels.web-disabled": {
    defaultMessage: "Are not configured to allow publishing to web",

    description: "validationMessage.distribution-channels.web-disabled",
  },

  "duplicate-channels": {
    defaultMessage: "{property} ({value}) already exists.",

    description: "validationMessage.duplicate-channels",
  },

  "duplicate-contentTypes": {
    defaultMessage: "{property} ({value}) already exists.",

    description: "validationMessage.duplicate-contentTypes",
  },

  "duplicate-subchannels": {
    defaultMessage: "{property} ({value}) already exists.",

    description: "validationMessage.duplicate-subchannels",
  },

  "duplicate-templates": {
    defaultMessage: "{property} ({value}) already exists.",

    description: "validationMessage.duplicate-templates",
  },

  "editor-enabled": {
    defaultMessage: "Body can't be blank",
    description: "validationMessage.editor-enabled",
  },
  email: {
    defaultMessage: "Is not a valid email",
    description: "validationMessage.email",
  },
  "empty-slideshow": {
    defaultMessage: "slideshows must have at least one filled slide",
    description: "validationMessage.empty-slideshow",
  },
  "eventDate-required-address": {
    defaultMessage: "Is required if address is present",

    description: "validationMessage.eventDate-required-address",
  },

  "eventDate-required-livestream": {
    defaultMessage: "Is required if Livestream is available",

    description: "validationMessage.eventDate-required-livestream",
  },

  "file-size": {
    defaultMessage: "Cannot exceed {maxSize}MB.",
    description: "validationMessage.file-size",
  },

  "gallery-items": {
    defaultMessage: "A gallery must have at least 1 item",

    description: "validationMessage.gallery-items",
  },

  geo: {
    defaultMessage: "Is not a valid value of lat, long",
    description: "validationMessage.geo",
  },

  "geo.lat": {
    defaultMessage: "Must be between -90° and 90°",
    description: "validationMessage.geo.lat",
  },

  "geo.lng": {
    defaultMessage: "Must be between -180° and 180°",

    description: "validationMessage.geo.lng",
  },

  "inline-all-present": {
    defaultMessage: "All embedded items must be present, some do not exist",

    description: "validationMessage.inline-all-present",
  },

  integer: {
    defaultMessage: "Must be an integer",
    description: "validationMessage.integer",
  },
  invalid: {
    defaultMessage: "Is invalid",
    description: "validationMessage.invalid",
  },
  "invalid-number-or-percent": {
    defaultMessage: "Please enter a number or %",
    description: "validationMessage.invalid-number-or-percent",
  },

  "invalid-url": {
    defaultMessage: "Please enter the full URL",
    description: "validationMessage.invalid-url",
  },

  invalidId: {
    defaultMessage: "{id} is not a valid id",
    description: "validationMessage.invalidId",
  },
  "is-iso-date-string": {
    defaultMessage: "Is not a valid date",
    description: "validationMessage.is-iso-date-string",
  },

  "items-hydrated": {
    defaultMessage:
      "One or more of your slides has corrupted data. Please refresh and try again.",
    description: "validationMessage.items-hydrated",
  },

  "markdown-links": {
    defaultMessage:
      "URLs need to follow this format: http://www.example.com. Update {invalidUrls}",

    description: "validationMessage.markdown-links",
  },

  match: {
    defaultMessage: "Fields do not match",
    description: "validationMessage.match",
  },
  "missing-uri-parts": {
    defaultMessage: "Segments are missing",

    description: "validationMessage.missing-uri-parts",
  },

  missingId: {
    defaultMessage: "Content with the id: {id} could not be found",

    description: "validationMessage.missingId",
  },

  "nativeBundles.advertiser": {
    defaultMessage: "Advertiser must be present",
    description: "validationMessage.nativeBundles.advertiser",
  },

  "nativeBundles.campaignUrl": {
    defaultMessage: "Campaign URL is not a valid url",

    description: "validationMessage.nativeBundles.campaignUrl",
  },

  "nativeBundles.bylineOption": {
    defaultMessage: "Byline option can't be blank",

    description: "validationMessage.nativeBundles.bylineOption",
  },

  "nativeBundles.bylineVariant": {
    defaultMessage: "Byline variation can't be blank",

    description: "validationMessage.nativeBundles.bylineVariant",
  },

  "newsletter-date": {
    defaultMessage: "Invalid date",
    description: "validationMessage.newslatter-date",
  },
  "newsletter-feature-content": {
    defaultMessage: "Newsletter must have at least one feature content",

    description: "validationMessage.newsletter-deature-content",
  },

  "newsletter-short-link": {
    defaultMessage: "Newsletter must have at least one short link",

    description: "validationMessage.newsletter-short-link",
  },

  "no-replacement-chars": {
    defaultMessage: "Can't have empty asset groups",

    description: "validationMessage.o-replacement-chars",
  },

  notEqual: {
    defaultMessage: "Is not equal to {value}",
    description: "validationMessage.notEqual",
  },
  notGreaterThan: {
    defaultMessage: "Is not greater than {value}",

    description: "validationMessage.notGreaterThan",
  },

  notGreaterThanOrEqualTo: {
    defaultMessage: "Is not greater than or equal to {value}",

    description: "validationMessage.notGreaterThanOrEqualTo",
  },

  notInteger: {
    defaultMessage: "Is not an integer",
    description: "validationMessage.notInteger",
  },
  notLessThan: {
    defaultMessage: "Is not less than {value}",
    description: "validationMessage.notLessThan",
  },

  notLessThanOrEqualTo: {
    defaultMessage: "Is not less than or equal to {value}",
    description: "validationMessage.notLessThanOrEqualTo",
  },

  notNumber: {
    defaultMessage: "Is not a number",
    description: "validationMessage.notNumber",
  },
  notUnique: {
    defaultMessage: "Is already taken. Please choose a different one.",

    description: "validationMessage.notUnique",
  },

  "number-or-percentage": {
    defaultMessage: "Please enter a number or %",

    description: "validationMessage.number-or-percentage",
  },

  "password-length": {
    defaultMessage: "Must be at least 8 characters long.",

    description: "validationMessage.password-length",
  },

  "password-validation": {
    defaultMessage:
      "Must contain at least one numeric digit and one uppercase character",

    description: "validationMessage.password-validation",
  },

  "photo-duration-error": {
    defaultMessage: "Gif {key} must be less than or equal to {limit} seconds",

    description: "validationMessage.photo-duration-error",
  },

  "photosTout-required": {
    defaultMessage: "Please add a site image before publishing",

    description: "validationMessage.photosTout-required",
  },

  "publish-config-valid": {
    defaultMessage: "Publish configuration is not correct.",

    description: "validationMessage.publish-config-valid",
  },

  "publish-uri": {
    defaultMessage: "Must be published",
    description: "validationMessage.publush-uri",
  },
  range: {
    defaultMessage: "Must be in a valid range",
    description: "validationMessage.range",
  },
  "review-sync": {
    defaultMessage:
      "This version of the review is not the latest. To get the latest version, please use the breadcrumb to navigate back to the fashion show. Then, click the edit review button.",

    description: "validationMessage.review-sync",
  },

  "save-duplicate-channels": {
    defaultMessage: "There are duplicate channels",

    description: "validationMessage.save-duplicate-channels",
  },

  "save-duplicate-container": {
    defaultMessage: "There are duplicate container values or types",

    description: "validationMessage.save-duplicate-container",
  },

  "save-duplicate-contentTypes": {
    defaultMessage: "There are duplicate content types",

    description: "validationMessage.save-duplicate-contentTypes",
  },

  "save-duplicate-subchannels": {
    defaultMessage: '"{channel}" has duplicate subchannels',

    description: "validationMessage.save-duplicate-subchannels",
  },

  "save-duplicate-templates": {
    defaultMessage: '"{contentType}" has duplicate templates',

    description: "validationMessage.save-duplicate-templates",
  },

  "save-duplicate-type": {
    defaultMessage: "There are duplicate bundle subtype names",

    description: "validationMessage.save-duplicate-type",
  },

  "save-empty-container": {
    defaultMessage: "Container value or type can't be blank",

    description: "validationMessage.save-empty-container",
  },

  "save-empty-type": {
    defaultMessage: "Subtype name can't be blank",

    description: "validationMessage.save-empty-type",
  },

  "sections-allowed-minimum": {
    defaultMessage: "You must choose 1 category for sections",

    description: "validationMessage.allowed-minimum",
  },

  "server-content-conflict": {
    defaultMessage: "Another content item already exists at {message}",

    description: "validationMessage.server-content-conflict",
  },

  "server-error": {
    defaultMessage: "{error}: {message}; {status}",

    description: "validationMessage.server-error",
  },

  slug: {
    defaultMessage: "Is not a valid slug",
    description: "validationMessage.slug",
  },
  subchannel: {
    defaultMessage: "Subchannel can't be blank",
    description: "validationMessage.subchannel",
  },

  "syndication-bad-request": {
    defaultMessage: "Error while syndicating: {message}",

    description: "validationMessage.syndication-bad-request",
  },

  "syndication-conflict": {
    defaultMessage: "Content has already been syndicated to the brand",

    description: "validationMessage.syndication-conflict",
  },

  "syndication-contributorsAuthor-required": {
    defaultMessage: "Cannot syndicate content without a contributor author",

    description: "validationMessage.syndication-contributorsAuthor-required",
  },

  "syndication-photosTout-required": {
    defaultMessage: "Cannot syndicate content without a site image",

    description: "validationMessage.syndication-photosTout-required",
  },

  tooLongLength: {
    defaultMessage: "Is too long (maximum {value} characters)",

    description: "validationMessage.tooLongLength",
  },

  tooManyItems: {
    defaultMessage: "No more than {maxItems} items allowed per upload",

    description: "validationMessage.tooManyItems",
  },

  tooShortLength: {
    defaultMessage: "must be present",
    description: "validationMessage.tooShortLength",
  },
  tooShortMinLength: {
    defaultMessage: "Is too short (minimum {value} characters)",

    description: "validationMessage.tooShortMinLength",
  },

  "transcriptUpload.invalid-type": {
    defaultMessage: "Only .txt files are accepted",

    description: "validationMessage.transcriptUpload.invalid-type",
  },

  "transcriptUpload.upload-error": {
    defaultMessage: "Transcript upload failed",

    description: "validationMessage.transcriptUpload.upload-error",
  },

  "top-level-category-primary": {
    defaultMessage: "Transcript upload failed",

    description: "validationMessage.top-level-category-primary",
  },

  unicode: {
    defaultMessage: "Must be 6 digits",
    description: "validationMessage.unicode",
  },
  "uri-exists": {
    defaultMessage:
      'This URL is already in use. Please change the slug and click "Publish" again',

    description: "validationMessage.uri-exists",
  },

  "uri-format": {
    defaultMessage: "Format is invalid",
    description: "validationMessage.uri-format",
  },
  url: {
    defaultMessage: "Is not a valid url",
    description: "validationMessage.url",
  },
  "url-absolute-leading-slash": {
    defaultMessage:
      "Is not valid. Please preface with either a leading slash (/), http:// or https://.",

    description: "validationMessage.url-absolute-leading-slash",
  },

  wrongLength: {
    defaultMessage: "Is the wrong length (should be {value} characters)",

    description: "validationMessage.wrongLength",
  },

  year: {
    defaultMessage: "Must be 4 digits, example: 2014",
    description: "validationMessage.year",
  },

  "query.filters.types": {
    defaultMessage: "You must select a content type",
    description: "validationMessage.query.filters.types",
  },
});

export const ERROR_FIELD_UNKNOWN = defineMessage({
  defaultMessage:
    'No translation was found for a validation error with the key "{name}"',
});

import { useMemo } from "react";
import {
  GetBrand_brandConfiguration_contentTypes as ContentType,
  GetCurrentUser_currentUser,
} from "@types";
import { useIntl } from "react-intl";
import {
  CheckCircleIcon,
  ContentTypeArticleIcon,
} from "@condenast/gemini/icons";
import {
  MoreFilter,
  PeopleFilter,
  SearchDateFilter,
  ChannelFilter,
} from "../searchFilters";
import { useDefinedMessages } from "@hooks";
import { FilterPillList } from "../FilterPillList";
import {
  MainSearchAdapterFactory,
  AdvancedSearchOptions,
  GetAppliedFiltersOptions,
  AdapterFilterConfigs,
} from "../types";
import { StyledSelect, CustomFilterWrapper } from "./all";
import { getAppliedFilters, getAppliedFiltersCount } from "../helpers";

const EXCLUDED_TYPES = ["photo", "clip", "cartoon"];
const EXCLUDED_OPTIONS: AdapterFilterConfigs = {
  types: EXCLUDED_TYPES,
};
const ADAPTER_OPTIONS: GetAppliedFiltersOptions = {
  excludedOptions: EXCLUDED_OPTIONS,
  ignoredFilters: ["display"],
};

const EditorialListLabel = () => {
  const intl = useIntl();
  return (
    <>
      <span>
        {intl.formatMessage({
          defaultMessage: "Editorial",
          description: "Search adapter name for media contentTypes",
        })}
      </span>
    </>
  );
};

const MoreFilterList = (props: {
  searchParams: AdvancedSearchOptions;
  onChange: (updatedFilters: AdvancedSearchOptions) => void;
  contentTypes?: ContentType[];
  statusOptions?: string[];
  dateRangeOptions?: string[];
  availableLanguages?: (string | null)[];
  enableLanguageFilters?: boolean;
  organizationId: string;
  currentUser: GetCurrentUser_currentUser;
  mobileView?: boolean;
  syndicationEnabled: boolean;
  categoryConfigType?: string | null;
}) => {
  const { translateLanguage } = useDefinedMessages();
  const {
    searchParams,
    onChange,
    dateRangeOptions,
    availableLanguages,
    enableLanguageFilters,
    organizationId,
    mobileView,
    categoryConfigType,
  } = props;

  const intl = useIntl();

  return (
    <>
      <CustomFilterWrapper $isMobile={mobileView}>
        <SearchDateFilter
          selectedFilters={searchParams}
          onChange={(date) =>
            onChange({
              date,
            })
          }
          dateRangeOptions={dateRangeOptions}
          mobileView={mobileView}
        />
      </CustomFilterWrapper>
      {enableLanguageFilters && (
        <CustomFilterWrapper $isMobile={mobileView}>
          <StyledSelect
            id="search-lang"
            $isMobile={mobileView}
            label={intl.formatMessage({ defaultMessage: "Language" })}
            placeholder={intl.formatMessage({
              defaultMessage: "Language",
            })}
            value={searchParams?.lang || []}
            onChange={(lang: string[] | undefined) =>
              onChange({
                lang,
              })
            }
            options={
              availableLanguages?.map((lang) => {
                return {
                  label: translateLanguage(lang ?? ""),
                  value: lang ?? "",
                };
              }) ?? []
            }
          />
        </CustomFilterWrapper>
      )}
      <CustomFilterWrapper $isMobile={mobileView}>
        <ChannelFilter
          currentFilters={searchParams}
          onChange={onChange}
          organizationId={organizationId}
          categoryConfigType={categoryConfigType || ""}
          mobileView={mobileView}
        />
      </CustomFilterWrapper>
    </>
  );
};

const EditorialFilters = (props: {
  searchParams: AdvancedSearchOptions;
  onChange: (updatedFilters: AdvancedSearchOptions) => void;
  contentTypes?: ContentType[];
  statusOptions?: string[];
  dateRangeOptions?: string[];
  availableLanguages?: (string | null)[];
  enableLanguageFilters?: boolean;
  organizationId: string;
  currentUser: GetCurrentUser_currentUser;
  mobileView?: boolean;
  syndicationEnabled: boolean;
  categoryConfigType?: string | null;
}) => {
  const { translateContentType, translateSelectOption } = useDefinedMessages();
  const {
    searchParams,
    onChange,
    contentTypes,
    statusOptions,
    organizationId,
    currentUser,
    mobileView,
    syndicationEnabled,
  } = props;

  const intl = useIntl();

  return (
    <>
      <StyledSelect
        id="search-content-types"
        $isMobile={mobileView}
        label={
          mobileView ? intl.formatMessage({ defaultMessage: "Type" }) : null
        }
        placeholder={
          mobileView
            ? searchParams.types
              ? intl.formatMessage(
                  {
                    defaultMessage: "{count} selected",
                    description:
                      "Search filter placeholder when >= 1 options selected",
                  },
                  { count: searchParams.types.length }
                )
              : intl.formatMessage({
                  defaultMessage: "Select",
                  description:
                    "Search filter placeholder when no options selected",
                })
            : intl.formatMessage({ defaultMessage: "Type" })
        }
        multiple
        value={searchParams.types || []}
        onChange={(value: string[] | undefined) =>
          onChange({
            types: value,
          })
        }
        options={
          contentTypes
            ?.filter(
              (contentType) => !EXCLUDED_TYPES.includes(contentType.value)
            )
            .map((contentType) => {
              return {
                label: translateContentType(
                  contentType.value,
                  1,
                  contentType.label
                ),
                value: contentType.value,
              };
            })
            .sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            ) ?? []
        }
        icon={mobileView ? undefined : ContentTypeArticleIcon}
      />
      <StyledSelect
        id="search-status"
        $isMobile={mobileView}
        label={
          mobileView ? intl.formatMessage({ defaultMessage: "Status" }) : null
        }
        placeholder={
          mobileView
            ? searchParams?.status?.length
              ? intl.formatMessage(
                  {
                    defaultMessage: "{count} selected",
                    description:
                      "Search filter placeholder when >= 1 options selected",
                  },
                  { count: searchParams.status.length }
                )
              : intl.formatMessage({
                  defaultMessage: "Select",
                  description:
                    "Search filter placeholder when no options selected",
                })
            : intl.formatMessage({ defaultMessage: "Status" })
        }
        multiple
        value={searchParams.status || []}
        onChange={(value: string[] | undefined) =>
          onChange({
            status: value,
          })
        }
        options={
          statusOptions?.map((status) => {
            return {
              label: translateSelectOption("statusFilterOptions", status),
              value: status,
            };
          }) ?? []
        }
        icon={mobileView ? undefined : CheckCircleIcon}
      />
      <CustomFilterWrapper $isMobile={mobileView}>
        <PeopleFilter
          onChange={onChange}
          currentUser={currentUser}
          mobileView={mobileView}
          selectedFilters={searchParams}
          organizationId={organizationId}
        />
      </CustomFilterWrapper>
      {mobileView && <MoreFilterList {...props} />}
      {!mobileView && (
        <MoreFilter
          currentFilters={searchParams}
          onChange={onChange}
          syndicationEnabled={syndicationEnabled}
          moreFilters={<MoreFilterList {...props} />}
        />
      )}
    </>
  );
};

const EditorialAppliedFiltersList = (props: {
  searchParams: AdvancedSearchOptions;
  onChange: (updatedFilters: AdvancedSearchOptions) => void;
}) => {
  const { searchParams, onChange } = props;
  const selectedAdapterFilters = useMemo(() => {
    return getAppliedFilters(searchParams, ADAPTER_OPTIONS);
  }, [searchParams]);
  const selectedFiltersCount = useMemo(() => {
    return getAppliedFiltersCount(searchParams, ADAPTER_OPTIONS);
  }, [searchParams]);

  return (
    <FilterPillList
      searchParams={searchParams}
      onChange={onChange}
      totalSelections={selectedFiltersCount}
      selectedAdapterFilters={selectedAdapterFilters}
    />
  );
};

export const EditorialContentAdapterFactory: MainSearchAdapterFactory = ({
  search: searchFromContext,
  contentTypes,
}) => {
  return {
    id: "editorial",
    search: (searchParams) => {
      let filteredSearchParams = { ...searchParams };
      if (filteredSearchParams.types) {
        filteredSearchParams.types = filteredSearchParams.types
          .split(",")
          .filter((type) => !EXCLUDED_TYPES.includes(type))
          .join(",");
      }
      if (!filteredSearchParams.types?.length) {
        filteredSearchParams.types = contentTypes
          ?.filter((contentType) => !EXCLUDED_TYPES.includes(contentType.value))
          ?.map((contentType) => contentType.value)
          .join(",");
      }

      searchFromContext(filteredSearchParams);
    },
    isDefault: true,
    Label: () => <EditorialListLabel />,
    Filters: EditorialFilters,
    AppliedFiltersList: EditorialAppliedFiltersList,
    AppliedFiltersCount: (searchParams: AdvancedSearchOptions) => {
      return getAppliedFiltersCount(searchParams, ADAPTER_OPTIONS);
    },
    defaultTreatment: "slat",
  };
};

import { ReactNode, useContext } from "react";
import { ARIA, UserMedallion, withTooltip } from "@components";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { SnowplowContext } from "@contexts";

const Bubbles = styled.span`
  span:nth-child(n + 2) {
    margin-left: calc(-1 * var(--spacing-xs));
  }
`;

const UserMenuItem = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    flex-grow: 1;
  }
`;

function SlackIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.13275 0C4.35885 0.000570846 3.73253 0.62736 3.7331 1.39971C3.73253 2.17207 4.35943 2.79886 5.13333 2.79943H6.53355V1.40029C6.53413 0.627931 5.90723 0.00114169 5.13275 0C5.13333 0 5.13333 0 5.13275 0V0ZM5.13275 3.73333H1.39996C0.626055 3.7339 -0.000845084 4.36069 -0.000273095 5.13305C-0.00141707 5.9054 0.625483 6.53219 1.39938 6.53333H5.13275C5.90665 6.53276 6.53355 5.90597 6.53298 5.13362C6.53355 4.36069 5.90665 3.7339 5.13275 3.73333Z"
          fill="#36C5F0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0003 5.13305C14.0009 4.36069 13.374 3.7339 12.6001 3.73333C11.8262 3.7339 11.1993 4.36069 11.1999 5.13305V6.53333H12.6001C13.374 6.53276 14.0009 5.90597 14.0003 5.13305ZM10.267 5.13305V1.39971C10.2675 0.627931 9.64119 0.00114169 8.86729 0C8.09339 0.000570846 7.46649 0.62736 7.46706 1.39971V5.13305C7.46591 5.9054 8.09281 6.53219 8.86672 6.53333C9.64062 6.53276 10.2675 5.90597 10.267 5.13305Z"
          fill="#2EB67D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.86655 14C9.64045 13.9994 10.2673 13.3726 10.2668 12.6003C10.2673 11.8279 9.64045 11.2011 8.86655 11.2006H7.46632V12.6003C7.46574 13.3721 8.09264 13.9989 8.86655 14ZM8.86655 10.2661H12.5999C13.3738 10.2655 14.0007 9.63873 14.0001 8.86638C14.0013 8.09402 13.3744 7.46723 12.6005 7.46609H8.86712C8.09322 7.46666 7.46632 8.09345 7.46689 8.8658C7.46632 9.63873 8.09264 10.2655 8.86655 10.2661Z"
          fill="#ECB22E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.000290308 8.86639C-0.000281681 9.63875 0.626618 10.2655 1.40052 10.2661C2.17442 10.2655 2.80132 9.63875 2.80075 8.86639V7.46667H1.40052C0.626618 7.46725 -0.000281681 8.09404 0.000290308 8.86639ZM3.73366 8.86639V12.5997C3.73252 13.3721 4.35942 13.9989 5.13332 14C5.90722 13.9995 6.53412 13.3727 6.53355 12.6003V8.86753C6.53469 8.09518 5.90779 7.46839 5.13389 7.46725C4.35942 7.46725 3.73309 8.09404 3.73366 8.86639C3.73366 8.86639 3.73366 8.86696 3.73366 8.86639Z"
          fill="#E01E5A"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const InitialMedallion = withTooltip(styled(UserMedallion)``);

export function ConcurrentUsers(props: {
  id: string;
  users: { fullName: string; id: string; slackUserId?: string }[];
  children?: ReactNode;
  onClick?: (userID: string) => void;
}) {
  const { id, users, onClick } = props;
  const intl = useIntl();
  const moreMessage = intl.formatMessage(
    {
      defaultMessage: "{count} more",
      description:
        "Label for the number of extra users in the concurrent user dropdown",
    },
    { count: users.length - 2 }
  );

  const { trackComponentEvent } = useContext(SnowplowContext);

  return users.length ? (
    <ARIA.MenuButton
      id={id}
      treatment="borderless"
      size="small"
      menu={{
        items: users.map(({ fullName, id, slackUserId }) => {
          return slackUserId
            ? {
                role: "link",
                value: { fullName, id, slackUserId },
                href: `slack://user?team=T028CG04Y&id=${slackUserId}`,
                onClick: () => {
                  onClick?.(id);
                  trackComponentEvent("concurrent_edit", "slack-message");
                },
              }
            : {
                role: "action",
                value: { fullName, id, slackUserId },
                disabled: true,
                onClick: () => {
                  onClick?.(id);
                  trackComponentEvent("concurrent_edit", "button_click");
                },
              };
        }),
        children: ({ fullName, slackUserId }) => (
          <UserMenuItem>
            <span>{fullName}</span>
            {slackUserId && <SlackIcon />}
          </UserMenuItem>
        ),
      }}
    >
      <Bubbles>
        {(users.length <= 3 ? users : users.slice(0, 2)).map((user) => (
          <InitialMedallion
            key={user.id}
            name={user.fullName}
            size="regular"
            aria-label={user.fullName}
            tooltipPlacement="bottom"
          />
        ))}
        {users.length > 3 && (
          <InitialMedallion
            name={`+ ${users.length - 2}`}
            size="regular"
            aria-label={moreMessage}
          />
        )}
      </Bubbles>
    </ARIA.MenuButton>
  ) : (
    <></>
  );
}
ConcurrentUsers.displayName = "ConcurrentUsers";

import { useState, useRef, useMemo, useContext } from "react";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactCrop, { Crop } from "react-image-crop";
import styled from "styled-components";
import { BackIcon } from "@condenast/gemini/icons";
import { CopyIcon, DownloadIcon } from "@condenast/gemini/icons";
import { AspectRatio, Modifications } from "@types";
import { Button, Image, withTooltip } from "@components";
import { ThemeProvider, SnowplowContext } from "@contexts";
import { useClipboardCopy, useToast, useChangeset } from "@hooks";
import { useResizeEffect } from "@hooks";
import { useFocusTrap } from "@hooks";

import "react-image-crop/dist/ReactCrop.css";

type Asset = {
  id: string;
  type: string;
  filename: string;
  height: number;
  width: number;
  altText: string;
  publicURL: null;
};

type PercentageUnitAspectRatioCrops = {
  [key: string]: Crop;
};

const ImageEditorTakeoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  z-index: ${(props) => props.theme.ElevationModal};
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-sm);
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  min-height: 0;
`;

const Footer = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  justify-content: flex-end;
  align-items: center;
  padding: var(--spacing-sm);
  border-top: 1px solid ${(props) => props.theme.DividerColor};
  font: ${(props) => props.theme.FontSubSectionHeading};
`;

const AspectRatioListSidebar = styled.div`
  width: calc(--var(--spacing-sm) * 6.125);
  height: 100%;
  border-right: 1px solid ${(props) => props.theme.DividerColor};
  padding: var(--spacing-sm);
  overflow-y: auto;
`;

const AspectRatioList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-sm);
`;

const AspectRatioListItem = styled.li<{
  $isActive?: boolean;
  $isChanged?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: ${(props) => props.theme.CornerRadius};
  cursor: pointer;
  font: ${(props) => props.theme.FontInteractive};
  padding: var(--spacing-xs);
  ${(props) =>
    props.$isActive &&
    `background-color: ${props.theme.BorderlessActiveBackground}`};
  ${(props) => props.$isChanged && `color: ${props.theme.ControlOnBackground}`};

  &:hover {
    background-color: ${(props) => props.theme.Color};
    color: ${(props) => props.theme.Background};
  }
`;

const ImageEditor = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ImageEditorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-sm);
`;

const ImageSizeData = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const SpanDivider = styled.div`
  border-left: 1px solid ${(props) => props.theme.DividerColor};
  margin: 0 var(--spacing-sm);
  height: 100%;
  display: inline-flex;
`;

const ImageEditorActionsWrapper = styled.div`
  display: flex;
  gap: var(--spacing-xs);
`;

const StyledCopyButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.DividerColor};
`;

const StyledDownloadAnchor = styled.a`
  disp lay: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.DividerColor};
  padding: var(--spacing-xs);
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  color: ${(props) => props.theme.SecondaryColor};
`;

const StyledCopyIcon = styled(CopyIcon)`
  color: ${(props) => props.theme.SecondaryColor};
`;

const ImageEditorBody = styled.div<{
  $isEditorBodyNarrowerThanImage: boolean | null;
}>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 0;
  flex-direction: ${(props) =>
    props.$isEditorBodyNarrowerThanImage ? "row" : "column"};
  background: repeating-conic-gradient(
      var(--color-gray-3) 0% 25%,
      var(--color-gray-4) 0% 50%
    )
    50% / 20px 20px;
  margin: 0 var(--spacing-sm) var(--spacing-sm) var(--spacing-sm);

  .react-crop {
    max-height: 100%;
    width: ${(props) =>
      props.$isEditorBodyNarrowerThanImage ? "100%" : "initial"};
    height: ${(props) =>
      props.$isEditorBodyNarrowerThanImage ? "initial" : "100%"};

    .ReactCrop__child-wrapper {
      width: ${(props) =>
        props.$isEditorBodyNarrowerThanImage ? "100%" : "initial"};
      height: ${(props) =>
        props.$isEditorBodyNarrowerThanImage ? "initial" : "100%"};
    }
  }
`;

const StyledImage = styled(Image)`
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    background: none;
  }
`;

function convertPixelToPercentageCrop(
  asset: Asset,
  crop?: Modifications["crop"]
) {
  let originalHeight = asset.height;
  let originalWidth = asset.width;

  return {
    x: crop?.x ? (crop.x / originalWidth) * 100 : 0,
    y: crop?.y ? (crop.y / originalHeight) * 100 : 0,
    width: crop?.width ? (crop?.width / originalWidth) * 100 : 100,
    height: crop?.height ? (crop?.height / originalHeight) * 100 : 100,
    unit: "%" as const,
  };
}

function convertPercentageToPixelCrop(asset: Asset, percentageCrop: Crop) {
  return {
    x: (percentageCrop.x * asset.width) / 100,
    y: (percentageCrop.y * asset.height) / 100,
    width: (percentageCrop.width * asset.width) / 100,
    height: (percentageCrop.height * asset.height) / 100,
    unit: "px" as const,
  };
}

function getPixelUnitAspectRatioCrops(
  changeset: PercentageUnitAspectRatioCrops,
  changedKeys: string[],
  asset: Asset
) {
  return changedKeys.map((aspectRatioDimension) => {
    let { width, height, x, y } = roundCropDimensions(
      convertPercentageToPixelCrop(asset, changeset[aspectRatioDimension])
    );
    return {
      height,
      width,
      name: aspectRatioDimension,
      override: false,
      modifications: {
        crop: {
          height,
          width,
          x,
          y,
        },
      },
    };
  });
}

function roundCropDimensions(crop: Crop) {
  return {
    unit: crop.unit,
    x: Math.round(crop.x),
    y: Math.round(crop.y),
    width: Math.round(crop.width),
    height: Math.round(crop.height),
  };
}
const StyledDownloadAnchorWithTooltip = withTooltip(StyledDownloadAnchor);

export const ImageEditorTakeover = (props: {
  onClose: () => void;
  onSubmit: (value: AspectRatio[]) => void;
  aspectRatios: AspectRatio[];
  asset: Asset;
  cdnHost: string;
  selectedAspectRatioName: string;
}) => {
  const {
    onClose,
    onSubmit,
    aspectRatios,
    asset,
    cdnHost,
    selectedAspectRatioName,
  } = props;
  const intl = useIntl();

  const [takeoverElement, setTakeoverElement] = useState<HTMLElement | null>(
    null
  );
  useFocusTrap(takeoverElement, onClose);

  const ImageEditorBodyRef = useRef<HTMLDivElement>(null);

  const { trackContentEvent } = useContext(SnowplowContext);

  const percentageUnitAspectRatioCrops =
    useMemo<PercentageUnitAspectRatioCrops>(() => {
      return aspectRatios.reduce<PercentageUnitAspectRatioCrops>(
        (acc, aspectRatio) => {
          acc[aspectRatio.name] = convertPixelToPercentageCrop(
            asset,
            aspectRatio.modifications?.crop
          );

          return acc;
        },
        {}
      );
    }, [aspectRatios, asset]);

  let [changeset, setValue, { hasChanges, changedKeys }] = useChangeset(
    percentageUnitAspectRatioCrops
  );

  const [activeAspectRatioName, setActiveAspectRatioName] = useState(
    selectedAspectRatioName
  );

  const activeAspectRatio = useMemo(
    () => changeset[activeAspectRatioName],
    [activeAspectRatioName, changeset]
  );

  const activeAspectRatioDimensionsInPixels = useMemo(
    () =>
      roundCropDimensions(
        convertPercentageToPixelCrop(asset, activeAspectRatio)
      ),
    [asset, activeAspectRatio]
  );

  const isEditorBodyNarrowerThanImage = useResizeEffect(
    ImageEditorBodyRef,
    (imageEditorBodyRef) => {
      const { width: assetWidth, height: assetHeight } = asset;
      const { width: imageEditorBodyWidth, height: imageEditorBodyHeight } =
        imageEditorBodyRef.getBoundingClientRect();

      if (
        !assetWidth ||
        !assetHeight ||
        !imageEditorBodyWidth ||
        !imageEditorBodyHeight
      ) {
        return false;
      }

      const imageEditorBodyAspectRatio =
        imageEditorBodyWidth / imageEditorBodyHeight;
      const assetAspectRatio = assetWidth / assetHeight;

      // If the imageEditorBodyAspectRatio is less than the assetAspectRatio, it means
      // imageEditorBody is narrower than the image. We add 0.005 to account for image prematurely
      // expanding the imageEditorBody.
      return imageEditorBodyAspectRatio + 0.005 < assetAspectRatio;
    },
    [ImageEditorBodyRef]
  );

  useEffect(() => {
    if ("Intercom" in window) {
      Intercom("update", {
        hide_default_launcher: true,
      });
    }
    return () => {
      if ("Intercom" in window) {
        Intercom("update", {
          hide_default_launcher: false,
        });
      }
    };
  }, []);

  let activeAspectRatioUrl = useMemo(() => {
    let url = new URL(cdnHost);
    let pathParts = [
      asset.type,
      asset.id,
      "master",
      `w_${activeAspectRatioDimensionsInPixels.width}`,
      `h_${activeAspectRatioDimensionsInPixels.height}`,
      `x_${activeAspectRatioDimensionsInPixels.x}`,
      `y_${activeAspectRatioDimensionsInPixels.y}`,
      "c_limit",
      encodeURIComponent(asset?.filename || ""),
    ];
    url.pathname = pathParts.join("/");
    url.search = new URLSearchParams({ format: "original" }).toString();
    return url.toString();
  }, [
    cdnHost,
    asset.type,
    asset.id,
    asset.filename,
    activeAspectRatioDimensionsInPixels,
  ]);

  let aspect = useMemo(() => {
    let parts = activeAspectRatioName.split(":");
    let numerator = parseInt(parts[0]);
    let denominator = parseInt(parts[1]);
    return numerator / denominator;
  }, [activeAspectRatioName]);

  const copyUrl = useClipboardCopy(activeAspectRatioUrl);

  const copySuccessToast = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Image URL copied to clipboard.",
    }),
  });

  return (
    <ThemeProvider theme="dark">
      <ImageEditorTakeoverWrapper ref={setTakeoverElement}>
        <Header>
          <Button
            onClick={onClose}
            aria-label="Go Back"
            tabIndex={-1}
            aria-hidden="true"
          >
            <BackIcon size="regular" />
          </Button>
          <FormattedMessage defaultMessage={"Adjust Crops"} />
        </Header>
        <Body>
          <AspectRatioListSidebar>
            <AspectRatioList>
              {aspectRatios.map((aspectRatio, index) => (
                <AspectRatioListItem
                  $isActive={activeAspectRatioName === aspectRatio.name}
                  $isChanged={changedKeys.includes(aspectRatio.name)}
                  onClick={() => {
                    setActiveAspectRatioName(aspectRatio.name);
                  }}
                  key={index}
                >
                  {aspectRatio.name}
                </AspectRatioListItem>
              ))}
            </AspectRatioList>
          </AspectRatioListSidebar>
          <ImageEditor>
            <ImageEditorHeader>
              <ImageSizeData>
                <span>{`X: ${activeAspectRatioDimensionsInPixels.x}px Y: ${activeAspectRatioDimensionsInPixels.y}px`}</span>
                <SpanDivider />
                <span>{`W: ${activeAspectRatioDimensionsInPixels.width}px H: ${activeAspectRatioDimensionsInPixels.height}px`}</span>
              </ImageSizeData>
              <ImageEditorActionsWrapper>
                <StyledCopyButton
                  aria-label={intl.formatMessage({
                    defaultMessage: "Copy Image URL",
                    description: "Copy Image URL button",
                  })}
                  onClick={() => {
                    copyUrl();
                    copySuccessToast();
                  }}
                >
                  <StyledCopyIcon size="small" />
                </StyledCopyButton>
                <StyledDownloadAnchorWithTooltip
                  aria-label={intl.formatMessage({
                    defaultMessage: "Download Image",
                    description: "Download Image anchor",
                  })}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={activeAspectRatioUrl}
                  onClick={() => {
                    trackContentEvent("cropped_image_downloaded");
                  }}
                >
                  <StyledDownloadIcon size="small" />
                </StyledDownloadAnchorWithTooltip>
              </ImageEditorActionsWrapper>
            </ImageEditorHeader>
            <ImageEditorBody
              ref={ImageEditorBodyRef}
              $isEditorBodyNarrowerThanImage={isEditorBodyNarrowerThanImage}
            >
              <ReactCrop
                crop={activeAspectRatio}
                aspect={aspect}
                keepSelection={true}
                ruleOfThirds={true}
                className={"react-crop"}
                onChange={(_pixelDimensions: any, percentDimensions: any) => {
                  setValue(activeAspectRatioName, {
                    unit: "%",
                    x: percentDimensions.x,
                    y: percentDimensions.y,
                    width: percentDimensions.width,
                    height: percentDimensions.height,
                  });
                }}
              >
                <StyledImage
                  asset={asset}
                  cdnHost={cdnHost}
                  overflowStyle="fit"
                />
              </ReactCrop>
            </ImageEditorBody>
          </ImageEditor>
        </Body>
        <Footer>
          {changedKeys.length > 0 && (
            <FormattedMessage
              defaultMessage="{updateCount, plural, =1 {# Crop updated} other {# Crops updated}}"
              values={{
                updateCount: changedKeys.length,
              }}
            />
          )}

          <Button onClick={onClose}>
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <Button
            disabled={!hasChanges}
            onClick={() => {
              onSubmit(
                getPixelUnitAspectRatioCrops(changeset, changedKeys, asset)
              );
            }}
            treatment="primary"
            aria-label="Done"
            data-testid="submit-takeover-button"
          >
            <FormattedMessage defaultMessage={"Done"} />
          </Button>
        </Footer>
      </ImageEditorTakeoverWrapper>
    </ThemeProvider>
  );
};

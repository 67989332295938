import { ARIA, Label } from "@components";
import styled from "styled-components";
import { ContentTypeSelector } from "./ContentTypeSelector";
import { StatusSelector } from "./StatusSelector";
import { ContributorSelector } from "./ContributorSelector";
import { CollaboratorSelector } from "./CollaboratorSelector";
import { ChannelSelector } from "./ChannelSelector";
import { Button } from "../../index";
import { useMemo, useState } from "react";
import {
  SearchFilters,
  DashboardFilter,
  GetConfigs,
  GetCurrentUser_currentUser,
} from "@types";
import { FilterIcon } from "@condenast/gemini/icons";
import { useMediaQuery } from "@hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-xxs);
  padding-bottom: var(--spacing-xxs);
  padding-top: var(--spacing-xxs);
  min-width: 400px;
  max-width: 400px;
`;

const FilterButton = styled(Button)`
  top: var(--spacing-xs);
  right: var(--spacing-xs);
`;

const ClearBtnContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
`;

const ClearButton = styled(Button)`
  top: var(--spacing-xs);
  right: var(--spacing-xs);
  background: none;
  padding: var(--spacing-xs);
  gap: var(--spacing-xs);
  font-size: var(--font-size);
  color: var(--color-blue-50);
  align-items: flex-end;
  text-align: end;
  text-decoration: none;
  font-weight: 700;

  :not(:disabled):hover {
    background: none;
    text-decoration: none;
    border: none;
    box-shadow: none;
  }
  :not(:disabled):active {
    background: none;
    text-decoration: none;
    border: none;
    box-shadow: none;
    color: var(--color-blue-50);
  }
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & svg {
    margin-right: var(--spacing-sm);
  }
  position: relative;
`;

const AddItemButton = styled(ARIA.MenuButton)`
  color: var(--color-black);
  background: var(--color-gray-6);
  border: var(--color-gray-5) solid thin;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  ~ ul {
    > li {
      overflow: unset;
      margin: 0;
      padding-bottom: 0;
      padding-top: 0;
      cursor: default;
      &:focus {
        background: none;
      }
    }
    > li:first-of-type {
      overflow: hidden;
      position: sticky;
      top: 0;
      z-index: 999;
      background: ${(props) => props.theme.Background};
    }
    > li:last-of-type {
      position: sticky;
      background: ${(props) => props.theme.Background};
      bottom: 0;
      top: 5px;
    }
  }
`;

const ActionTitle = styled.p`
  font-family: var(--font-family-ibm-plex-sans);
  font-size: var(--font-size);
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  padding: var(--spacing-xs);
`;

const FilterIconCount = styled(Label)`
  font-family: var(--font-family-ibm-plex-sans);
  font-size: var(--font-size);
  letter-spacing: 0em;
  font-weight: 100;
`;

export const DashboardFilters = (props: {
  onApplyFilters: (filter: SearchFilters) => void;
  preselectedFilters: DashboardFilter;
  filterType: string;
  organizationId: string;
  defaultFilters: DashboardFilter;
  currentUser: GetCurrentUser_currentUser;
  config?: GetConfigs | undefined;
}) => {
  const {
    preselectedFilters,
    onApplyFilters,
    filterType,
    organizationId,
    defaultFilters,
    config,
    currentUser,
  } = props;
  const [selectedFilters, setSelectedFiters] = useState(preselectedFilters);
  const onSetFilters = (
    filters: Array<string>,
    type: string,
    filtersToStore?: unknown
  ) => {
    let preSelectedFilters: DashboardFilter = {};
    //if filter are removed
    if (type && (!filters || !filters.length)) {
      preSelectedFilters = {
        ...selectedFilters,
        [type]: undefined,
        [`store-${type}`]: undefined,
      };
    } else if (type) {
      preSelectedFilters = {
        ...selectedFilters,
        [type]: filters.toString(),
        [`store-${type}`]: filtersToStore,
      };
    }
    setSelectedFiters({ ...preSelectedFilters });
    return {};
  };

  const filterKeys: (keyof DashboardFilter)[] = [
    "types",
    "status",
    "relid",
    "privilege_user",
    "hierarchy",
  ];

  const filtersCount = (filters: DashboardFilter) =>
    filterKeys
      .map((key: string) => {
        let value = filters[key as keyof DashboardFilter];
        return (
          (value &&
            typeof value === "string" &&
            value.trim().split(",").length) ||
          0
        );
      })
      .reduce((a, b) => a + b, 0);

  const appliedFiltersCount = filtersCount(preselectedFilters);
  const selectedFiltersCount = filtersCount(selectedFilters);

  const size: "medium" | "regular" = useMediaQuery("(max-width: 300px)")
    ? "medium"
    : "regular";

  const resetFilters = () => {
    let filters = { ...selectedFilters };
    [
      ...filterKeys,
      "store-hierarchy",
      "store-privilege_user",
      "store-relid",
    ].forEach((key: string) => {
      filters[key as keyof DashboardFilter] = undefined;
    });
    setSelectedFiters({ ...filters, ...defaultFilters });
  };

  const onCloseFilters = (evt: { _reactName: string }) => {
    if (!evt._reactName) {
      setSelectedFiters(preselectedFilters);
    }
  };

  const menuItems = useMemo(() => {
    const items = [
      // MenuItems header
      {
        value: (
          <Container>
            <ActionTitle>
              {selectedFiltersCount
                ? `Filter (${selectedFiltersCount})`
                : "Filter"}
            </ActionTitle>
          </Container>
        ),
        role: "action",
        onClick: () => {},
      },
      {
        role: "separator",
      },
      // MenuItems items
      {
        value: (
          <Container>
            <ContentTypeSelector
              setFilters={onSetFilters}
              filterType={filterType}
              preSelectedContentTypes={
                (selectedFilters && selectedFilters.types?.trim().split(",")) ||
                []
              }
              organizationId={organizationId}
            />
          </Container>
        ),
        role: "action",
        onClick: () => {},
      },
      {
        value: (
          <Container>
            <ChannelSelector
              setFilters={onSetFilters}
              preSelectedChannels={
                (selectedFilters && selectedFilters["store-hierarchy"]) || []
              }
              organizationId={organizationId}
              config={config}
            />
          </Container>
        ),
        role: "action",
        onClick: () => {},
      },
      {
        value: (
          <Container>
            <ContributorSelector
              setFilters={onSetFilters}
              preSelectedContributors={
                (selectedFilters && selectedFilters["store-relid"]) || []
              }
              organizationId={organizationId}
            />
          </Container>
        ),
        role: "action",
        onClick: () => {},
      },
      // MenuItems footer
      {
        role: "separator",
      },
      {
        value: (
          <ClearBtnContainer>
            <ClearButton
              treatment={"primary"}
              onClick={() => {
                resetFilters();
              }}
              id={`resetFilters-${filterType}`}
            >
              {"Clear All"}
            </ClearButton>
            <FilterButton
              treatment={"primary"}
              onClick={() => {
                onApplyFilters(selectedFilters);
              }}
              id={`applyFilters-${filterType}`}
            >
              {"Apply Filters"}
            </FilterButton>
          </ClearBtnContainer>
        ),
        role: "action",
        onClick: () => {},
      },
    ];

    if (currentUser.role !== "contributor") {
      items.splice(-2, 0, {
        value: (
          <Container>
            <CollaboratorSelector
              setFilters={onSetFilters}
              preSelectedCollaborators={
                (selectedFilters && selectedFilters["store-privilege_user"]) ||
                []
              }
              organizationId={organizationId}
              currentUserId={currentUser.id}
            />
          </Container>
        ),
        role: "action",
        onClick: () => {},
      });
    }

    if (filterType === "mywork") {
      items.splice(2, 0, {
        value: (
          <Container>
            <StatusSelector
              setFilters={onSetFilters}
              preSelectedStatus={
                (selectedFilters &&
                  selectedFilters.status?.trim().split(",")) ||
                []
              }
            />
          </Container>
        ),
        role: "action",
        onClick: () => {},
      });
    }

    return items;
  }, [
    config,
    currentUser.id,
    currentUser.role,
    filterType,
    onApplyFilters,
    onSetFilters,
    organizationId,
    selectedFilters,
    selectedFiltersCount,
  ]);

  return (
    <AddItemButton
      size={size}
      aria-label={`Filters`}
      id={`filters-${filterType}`}
      closeWhenFocusedOutside={true}
      closeAttributeIds={[`applyFilters-${filterType}`]}
      menu={{
        items: menuItems,
        theme: "modal",
        children: (element: JSX.Element) => {
          return <StyledItem>{element}</StyledItem>;
        },
        hasInputFields: true,
        autofocus: false,
        onClose: onCloseFilters,
      }}
    >
      <FilterIcon size="regular" />{" "}
      <FilterIconCount>{`(${appliedFiltersCount})`}</FilterIconCount>
    </AddItemButton>
  );
};
DashboardFilters.displayName = "DashboardFilters";

import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { Mutations } from "@gql";
import {
  GetCurrentUser_currentUser as CurrentUser,
  Organization,
  UploadMedia,
  UploadMediaVariables,
} from "@types";

export function useCKUpload(
  currentOrganization: Organization,
  currentUser: CurrentUser
) {
  const intl = useIntl();
  const [upload] = useMutation<UploadMedia, UploadMediaVariables>(
    Mutations.UPLOAD_MEDIA
  );
  const authorName = `${currentUser.firstName} ${currentUser.lastName}`;
  const onUpload = useCallback(
    (file: File) =>
      upload({
        variables: {
          organizationId: currentOrganization.organizationId,
          authorName,
          data: {
            file,
            fileSize: file.size,
          },
        },
      }).then((result) => {
        let id = result.data?.uploadMedia.id;
        if (!id) {
          throw new Error(
            intl.formatMessage({
              defaultMessage: "Sorry, something went wrong",
              description: "Notification message for server errors",
            })
          );
        }
        return { id };
      }),
    [upload, currentOrganization, authorName, intl]
  );

  return onUpload;
}

import { PublishUrlField, Card } from "@components";
import { Button, Toggle } from "@condenast/gemini";
import { useQuery } from "@apollo/client";
import { useClipboardCopy, useToast } from "@hooks";
import { errorsForField } from "@lib";
import { ShareIcon } from "@condenast/gemini/icons";
import {
  ControlProps,
  GetConfigs,
  GetCurrentUser,
  FormFor_form_controls_PublishDataFormControl,
  SyndicationFields,
  PublishInfoFields,
} from "@types";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { usePublishUriData } from "./hooks/usePublishUriData";
import { Queries } from "@gql";
import { LifecycleStep, LifecycleContext } from "@hooks";

const Section = styled(Card).attrs({ as: "section" })`
  margin-block-start: var(--spacing-md);
`;

const Title = styled.h2`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
  margin-bottom: var(--spacing-sm);
`;

const ShareDraftField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.SkeletonColor};
  padding: var(--spacing-sm) var(--spacing-sm);
  margin-top: var(--spacing-md);

  & span {
    font: var(--font-label);
    margin: 0 var(--spacing-xs);
  }
`;

const ToggleBlock = styled.div`
  display: flex;
  align-items: center;
`;

export function PublishData(
  props: ControlProps<FormFor_form_controls_PublishDataFormControl>
) {
  const {
    model,
    errors,
    currentOrganization,
    permissions,
    setValue,
    autogenerateConfig,
    allowUnicodeLetters,
    allowUppercase,
    managePublishURIs,
    addTask,
    contentType,
  } = props;
  const defaultLockStatus = model.uri ? true : false;

  let [locked, setLocked] = useState(defaultLockStatus);
  let hostName = currentOrganization.url;

  const draftToggleValue = model.isGlobalDraft as boolean;

  const canEdit = permissions.publish;
  const intl = useIntl();
  const copilotCode = currentOrganization.metadata.copilotCode;
  const userResponse = useQuery<GetCurrentUser>(Queries.GET_CURRENT_USER);
  const configsResponse = useQuery<GetConfigs>(Queries.GET_CONFIGS, {
    variables: {
      userId: userResponse.data?.currentUser?.id,
      organizationId: currentOrganization.organizationId,
    },
  });

  const isDraftContentType =
    contentType === "article" ||
    contentType === "gallery" ||
    contentType === "hotel";

  const isLive =
    model.pubDate && new Date(model.pubDate as string) < new Date();
  const { sourceId } = (model.syndication as SyndicationFields) || "";
  const enableGlobalDrafts = configsResponse.data?.configs?.enableGlobalDrafts;

  const displayGlobalDraftsBlock =
    isDraftContentType && enableGlobalDrafts && !isLive && !sourceId;

  const encoreUrl = configsResponse.data?.configs?.encoreUrl;
  const publishInfo = model.publishInfo as PublishInfoFields;
  const isPublished = publishInfo && !publishInfo?.expired;

  if (hostName.slice(-1) !== "/") hostName = `${hostName}/`;

  const updateURI = useCallback(
    (newValue: string) => setValue("uri", newValue),
    [setValue]
  );

  const {
    uri,
    nonEditableUriPart,
    editableUriPart,
    autogenValue,
    defaultValue,
    isManaged,
    hasEditablePaths,
  } = usePublishUriData(
    autogenerateConfig,
    model,
    {
      allowAllLetterCharacters: allowUnicodeLetters ?? false,
      isPublished,
      managePublishURIs: managePublishURIs ?? false,
    },
    updateURI
  );

  const readonly = isManaged && !hasEditablePaths;
  const showURIWarning =
    !isPublished &&
    !isManaged &&
    hasEditablePaths &&
    !uri.startsWith(defaultValue);

  const copyUrl = useClipboardCopy(
    `${hostName}${nonEditableUriPart}${editableUriPart}` || ""
  );
  const copySuccessToast = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Publish URL copied to clipboard",
    }),
  });

  const setManagedURI = useCallback(() => {
    if (isManaged && !hasEditablePaths && !uri && autogenValue) {
      updateURI(autogenValue);
    }
    return;
  }, [isManaged, hasEditablePaths, uri, autogenValue, updateURI]);

  useEffect(() => {
    addTask?.({
      lifecycleContexts: [LifecycleContext.SAVE, LifecycleContext.PUBLISH],
      lifecycleName: LifecycleStep.BEFORE_SAVE_VALIDATION,
      taskName: "setAutogenURI",
      runner: setManagedURI,
    });
    addTask?.({
      lifecycleContexts: [LifecycleContext.PUBLISH],
      lifecycleName: LifecycleStep.BEFORE_PUBLISH_VALIDATION,
      taskName: "setAutogenURI",
      runner: setManagedURI,
    });
  }, [addTask, setManagedURI]);

  return (
    <Section id="§publish-data">
      <Title id="publish-data-title">
        <FormattedMessage
          defaultMessage="Publish URL"
          description="'Publish URL' is a noun, referring to the url where a piece of content will be available once published"
        />
      </Title>
      <PublishUrlField
        id="PublishData__uri"
        aria-labelledby="publish-data-title"
        hostname={hostName}
        editableUriPart={editableUriPart ?? ""}
        nonEditableUriPart={nonEditableUriPart ?? ""}
        onChange={updateURI}
        readonly={readonly}
        showURIWarning={showURIWarning}
        locked={readonly || locked}
        toggleLock={
          canEdit
            ? () => {
                setLocked((locked) => !locked);
              }
            : undefined
        }
        onCopy={() => {
          copyUrl();
          copySuccessToast();
        }}
        allowUnicodeLetters={allowUnicodeLetters || false}
        allowUppercase={allowUppercase || false}
        autogeneratedValue={autogenValue}
        errors={errorsForField(errors, "uri")}
      />
      {displayGlobalDraftsBlock && (
        <ShareDraftField>
          <ToggleBlock>
            <ShareIcon size="small" />
            <span>Share in Encore Global Drafts</span>
            <Toggle
              id="1"
              value={draftToggleValue}
              onChange={(value) => setValue("isGlobalDraft", value)}
            />
          </ToggleBlock>
          <Button
            treatment="borderless"
            size="small"
            as="a"
            href={`${encoreUrl}/globalDrafts?brands=${copilotCode}`}
            target="_blank"
          >
            View global drafts
          </Button>
        </ShareDraftField>
      )}
    </Section>
  );
}
PublishData.displayName = "Control(PublishData)";

import { ReactNode, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Card,
  Link,
  Page,
  PasswordField,
  Select,
  ServerError,
  Toast,
  Title,
  TextField,
  ValidationSummary,
} from "@components";
import { useChangeset, useDefinedMessages, useToast } from "@hooks";
import { GetCurrentUser_currentUser, UserRole } from "@types";
import styled from "styled-components";
import { ValidationError } from "@condenast/cross-check";
import { ApolloError, useMutation } from "@apollo/client";
import { Mutations } from "@gql";
import * as Sentry from "@sentry/browser";
import { validateUserMyProfile, formPropsGetter } from "@lib";

const Form = styled.form`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto;
  grid-template-areas: "head" "body";
  gap: var(--spacing-md);
  padding-top: var(--spacing-sm);
  margin: 0 auto;
  max-width: ${(props) => props.theme.NarrowCardSize};
  width: 100%;

  ${Card} {
    grid-area: body;
  }
`;

const Header = styled.div`
  grid-area: head;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, auto);
  gap: var(--spacing-sm);
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-sm);
`;

const FormControls = styled.footer`
  display: grid;
  justify-content: end;
  grid-template-columns: auto;
  padding-top: var(--spacing-md);
`;

type UserFormData = GetCurrentUser_currentUser & {
  oldPassword?: string;
  newPassword?: string;
  verifyPassword?: string;
};

export const MyProfile = (props: { user: GetCurrentUser_currentUser }) => {
  const [updateUser, { error, loading }] = useMutation(Mutations.UPDATE_USER);

  if (error) {
    Sentry.captureException(error);
  }

  return (
    <MyProfilePage
      user={props?.user}
      requestError={error}
      loading={loading}
      onSubmit={(data) => {
        if (Object.keys(data).length) {
          return updateUser({
            variables: { userId: props?.user.id, user: data },
          });
        }
        return Promise.resolve();
      }}
    />
  );
};

export const MyProfilePage = (props: {
  user: GetCurrentUser_currentUser;
  requestError: ApolloError | undefined;
  loading: boolean;
  onSubmit: (data: Partial<UserFormData>) => Promise<unknown>;
}) => {
  let intl = useIntl();
  let {
    translateFieldName,
    translateRoleName,
    translateFieldError,
    translateSummaryErrors,
  } = useDefinedMessages();
  let [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  let [user, setValue, { changes }] = useChangeset<UserFormData>(props.user);
  const showSaveSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Saved",
    }),
  });

  let formPropsForField = useMemo(
    () =>
      formPropsGetter(
        user,
        validationErrors,
        setValue,
        translateFieldName,
        translateFieldError
      ),
    [user, validationErrors, setValue, translateFieldName, translateFieldError]
  );

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: "My Profile",
      })}
    >
      <Form
        noValidate={true}
        onSubmit={(evt) => {
          evt.preventDefault();
          validateUserMyProfile(user).then((validationErrors) => {
            setValidationErrors(validationErrors);
            if (validationErrors.length === 0) {
              props.onSubmit(changes).then(showSaveSuccessMessage);
            }
          });
        }}
      >
        <Header>
          <Title>
            <FormattedMessage defaultMessage="My Profile" />
          </Title>
          {validationErrors.length > 0 && (
            <ValidationSummary
              errors={translateSummaryErrors(validationErrors)}
            />
          )}
          {props.requestError && <ServerError error={props.requestError} />}
        </Header>
        <Card size="narrow">
          <Row>
            <TextField {...formPropsForField("firstName")} multiline={false} />
            <TextField {...formPropsForField("lastName")} multiline={false} />
          </Row>
          <TextField
            {...formPropsForField("email")}
            type="email"
            multiline={false}
          />
          <Select
            {...formPropsForField("role")}
            onChange={() => {}}
            disabled={true}
            options={Object.values(UserRole).map((role) => {
              const translatedRole = translateRoleName(role as UserRole);
              return {
                label: translatedRole,
                value: role,
              };
            })}
          />
          <Select
            multiple={false}
            {...formPropsForField("locale")}
            onChange={(value) => setValue("locale", value as string)}
            value={user.locale as string | undefined}
            placeholder={intl.formatMessage({
              defaultMessage: "Select a locale",
            })}
            options={[
              {
                label: intl.formatMessage({
                  defaultMessage: "Deutsch (German)",
                }),
                value: "de-DE",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "English - USA",
                }),
                value: "en-US",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "English - International",
                }),
                value: "en-GB",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "Español (Spanish)",
                }),
                value: "es-ES",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "Français (French)",
                }),
                value: "fr-FR",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "Italiano (Italian)",
                }),
                value: "it-IT",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "日本語 (Japanese)",
                }),
                value: "ja-JP",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "Pусский (Russian)",
                }),
                value: "ru-RU",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "正体字 (Chinese - Simplified)",
                }),
                value: "zh-Hans",
              },
              {
                label: intl.formatMessage({
                  defaultMessage: "繁體字 (Chinese - Traditional)",
                }),
                value: "zh-Hant-TW",
              },
            ]}
          />
          {user.loginProvider === "okta" && (
            <Toast
              type="informational"
              action={
                <Link
                  as="a"
                  href="https://condenast-hub.okta-emea.com/enduser/settings"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    defaultMessage="Manage your account"
                    description="Link to Okta management"
                  />
                </Link>
              }
            >
              <FormattedMessage defaultMessage="Your email and password are managed by Okta." />
            </Toast>
          )}
          {user.loginProvider === "copilot" && (
            <>
              <PasswordField
                {...formPropsForField("oldPassword")}
                value={user.oldPassword ?? ""}
              />
              <PasswordField
                {...formPropsForField("newPassword")}
                value={user.newPassword ?? ""}
                help={
                  <FormattedMessage
                    defaultMessage={`<p>Your password must include at least:</p><ul><li>Eight characters</li><li>One number</li><li>One uppercase letter</li><li>One lowercase letter</li></ul>`}
                    values={{
                      p(chunk: ReactNode) {
                        return <p>{chunk}</p>;
                      },
                      ul(chunk: ReactNode) {
                        return <ul>{chunk}</ul>;
                      },
                      li(chunk: ReactNode) {
                        return <li>{chunk}</li>;
                      },
                    }}
                  />
                }
                autocomplete="new-password"
              />
              <PasswordField
                {...formPropsForField("verifyPassword")}
                value={user.verifyPassword ?? ""}
                autocomplete="new-password"
              />
            </>
          )}
          <FormControls>
            <Button
              treatment="primary"
              type="submit"
              disabled={Object.keys(changes).length === 0}
            >
              <FormattedMessage defaultMessage="Save" />
            </Button>
          </FormControls>
        </Card>
      </Form>
    </Page>
  );
};
MyProfilePage.displayName = "MyProfilePage";

import { useIntl } from "react-intl";
import { Select as SelectImplementation } from "@components";
import { useDefinedMessages } from "@hooks";
import { ControlProps, FormFor_form_controls_SelectFormControl } from "@types";
import { get } from "@lib";

export function Select(
  props: ControlProps<FormFor_form_controls_SelectFormControl>
) {
  const {
    name,
    setValue,
    contextualHelpKey,
    optionsPath,
    options,
    model,
    errors,
    multiple,
    noLabel,
    apiConfigPath,
  } = props;
  const { translateContextualHelp, translateFieldName, translateSelectOption } =
    useDefinedMessages();
  const value = (get(model, name) as string) ?? "";
  const selectLabelName = !noLabel ? translateFieldName(name) : "";
  const finalOptionsPath = optionsPath || apiConfigPath;
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    defaultMessage: "Select an Option",
    description: "Placeholder text for a Select component",
  });

  const transformedOptions =
    options?.options.map((option) => {
      return {
        label: translateSelectOption(finalOptionsPath ?? "", option),
        value: option,
        disabled: options?.disabledOptions?.includes(option),
      };
    }) ?? [];

  return (
    <SelectImplementation
      id={`Select__${name}`}
      label={selectLabelName}
      aria-label={translateFieldName(name)}
      {...(multiple
        ? {
            multiple: true,
            value: (get(model, name) as string[]) || [],
            onChange: (values: string[]) => setValue(name, values),
            placeholder: placeholder,
          }
        : {
            multiple: false,
            value: (get(model, name) as string) || "",
            onChange: (value: string | undefined) => setValue(name, value),
            placeholder: value ? value : placeholder,
          })}
      errors={errors}
      options={transformedOptions}
      help={
        contextualHelpKey
          ? translateContextualHelp(contextualHelpKey)
          : undefined
      }
    />
  );
}
Select.displayName = "Control(Select)";

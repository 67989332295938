import styled from "styled-components";
import type {
  PageLayoutCategory,
  ToggleMap,
  ImageUrlSelectState,
  VideosLede,
} from "@types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: calc(var(--spacing-xxs) * 2);
  background-color: ${(props) => props.theme.SearchImageBackground};
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const PreviewScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
  align-items: center;
  gap: var(--spacing-xs);
  width: 100%;
`;

const Video = styled.video`
  min-width: 400px;
  width: 100;
`;

export const VideoPreview = ({
  videoLede,
  lang,
}: {
  currentCategory?: PageLayoutCategory | undefined;
  imageUrl: ImageUrlSelectState | undefined;
  videoLede: VideosLede | undefined;
  previewProps?: ToggleMap | undefined;
  enableLivePreview?: boolean | undefined;
  lang?: string;
}) => {
  const videoType =
    videoLede?.feedGuid.split(".")[videoLede?.feedGuid.split(".").length - 1];
  return (
    <Wrapper>
      <PreviewScreenWrapper>
        {videoLede?.feedGuid && (
          <Video poster={videoLede.asset?.filename} controls>
            <track
              kind="captions"
              src={videoLede.transcriptUrl}
              srcLang={lang}
            ></track>
            <source src={videoLede.feedGuid} type={`video/` + videoType} />
          </Video>
        )}
      </PreviewScreenWrapper>
    </Wrapper>
  );
};

VideoPreview.displayName = "VideoPreview";

import { autogenerate } from "../utils/autogenerate";
import { useEffect, useMemo } from "react";

function shouldManageURIParts(
  uri: string,
  hasEditablePaths: boolean,
  defaultValue: string,
  isPublished?: boolean
) {
  // if the content item is published, we don't want to manage the URI parts
  if (isPublished) {
    return false;
  }

  // if the URI is not set, then we want to manage it
  if (uri === "") {
    return true;
  }

  // if every part of the autogenerate config is required, then the current
  // URI needs to match exactly
  if (!hasEditablePaths) {
    if (defaultValue === uri || defaultValue === uri + "/") {
      return true;
    } else {
      return false;
    }
  }

  // if some parts of the uri are to be inserted by the editor, then
  // check that the URI starts with the default value
  return uri.startsWith(defaultValue);
}

export function usePublishUriData(
  autogenerateConfig: string | null,
  model: Record<string, unknown>,
  options: {
    allowAllLetterCharacters?: boolean;
    isPublished?: boolean;
    managePublishURIs?: boolean;
  } = {
    allowAllLetterCharacters: false,
    isPublished: false,
    managePublishURIs: false,
  },
  onURIChange: (newURI: string) => void
) {
  const { allowAllLetterCharacters, isPublished, managePublishURIs } = options;

  const uri = (model.uri as string | null | undefined) ?? "";

  const hasEditablePaths = !(
    autogenerateConfig?.length &&
    autogenerateConfig.split("/").every((path) => path.startsWith("!"))
  );

  const autogenValue = autogenerate(autogenerateConfig, model, {
    allowAllLetterCharacters,
    withTrailingSlash: autogenerateConfig?.endsWith("/"),
  });

  const defaultValue =
    autogenerate(autogenerateConfig, model, {
      allowAllLetterCharacters,
      requiredOnly: true,
      withTrailingSlash: hasEditablePaths || autogenerateConfig?.endsWith("/"),
    }) ?? "";

  // only compute this when initializing - if we're in managing the URI we should
  // continue to do so even if the defaultValue and uri start to change
  const isManaged = useMemo(
    () =>
      managePublishURIs
        ? shouldManageURIParts(uri, hasEditablePaths, defaultValue, isPublished)
        : false,
    []
  );

  // if we're managing the URI, the noneditable part is the default value
  // if not, it's empty
  const nonEditableUriPart = useMemo(
    () => (isManaged ? defaultValue : ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValue]
  );

  // if we're managing the URI, the editable part is the part after the default value
  // if not, it's the whole uri
  const editableUriPart = useMemo(
    () => (isManaged ? uri.slice(defaultValue.length) : uri || defaultValue),
    // Only recompute this after the URI changes, as we want to keep the value
    // stable when only the default value is changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uri]
  );

  useEffect(
    () => {
      if (hasEditablePaths) {
        // In this case, the user is expected to fill in some part of the URI
        // When the nonEditable part changes, only update the URI if some
        // part of the editable portion is filled in
        if (editableUriPart && nonEditableUriPart + editableUriPart !== uri) {
          onURIChange(nonEditableUriPart + editableUriPart);
        }
      } else {
        // In this case, the entire URI is managed, so whenever the noneditable
        // portion changes, update the entire URI
        if (nonEditableUriPart + editableUriPart !== uri) {
          onURIChange(nonEditableUriPart + editableUriPart);
        }
      }
    },
    // Only run this effect when the nonEditable part changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nonEditableUriPart]
  );

  return {
    uri,
    nonEditableUriPart,
    editableUriPart,
    autogenValue,
    defaultValue,
    isManaged,
    hasEditablePaths,
  };
}

import { defineMessages } from "react-intl";

export const PLACEHOLDERS = defineMessages({
  "activities.name": {
    defaultMessage: "New Activity",
    description: "placeholder.activities.name",
  },
  "activity.features": {
    defaultMessage: "Select features",
    description: "placeholder.activity.features",
  },
  "advertisers.name": {
    defaultMessage: "New Advertiser",
    description: "placeholder.advertisers.name",
  },
  "airlines.name": {
    defaultMessage: "New Airline",
    description: "placeholder.airlines.name",
  },
  "airports.name": {
    defaultMessage: "New Airport",
    description: "placeholder.airports.name",
  },
  "articles.hed": {
    defaultMessage: "New Article",
    description: "placeholder.artiles.hed",
  },
  "audiofile.name": {
    defaultMessage: "New Audiofile",
    description: "placeholder.audiofile.name",
  },
  "bars.name": {
    defaultMessage: "New Bar",
    description: "placeholder.bars.name",
  },
  "brandPicker.select": {
    defaultMessage: "Select brands",
    description: "placeholder.brandPicker.select",
  },
  "brands.name": {
    defaultMessage: "New Brand",
    description: "placeholder.brands.name",
  },
  "bundleTypeSelector.hed": {
    defaultMessage: "Enter a headline to get started",
    description: "placeholder.bundleTypeSelector.hed",
  },
  "bundles.container.hed": {
    defaultMessage: "New Container",
    description: "placeholder.bundles.container.hed",
  },
  "bundles.hed": {
    defaultMessage: "New Bundle",
    description: "placeholder.bundles.hed",
  },
  "business.legalName": {
    defaultMessage: "Enter business name",
    description: "placeholder.legalName",
  },
  "business.editorialQuote": {
    defaultMessage: "Enter quote",
    description: "placeholder.editorialQuote",
  },
  "categoryAutocomplete.input": {
    defaultMessage: "Click in to see recommendations or type to search",
    description: "placeholder.categoryAutocomplete.input",
  },
  "categoryPicker.input": {
    defaultMessage: "Categories to Include",
    description: "placeholder.categoryPicker.input",
  },
  categorySelector: {
    defaultMessage: "Select Category",
    description: "placeholder.categorySelector",
  },
  "contentSelector.search": {
    defaultMessage: "Search",
    description: "placeholder.contentSelector.search",
  },
  "contentSelector.searchGiphy": {
    defaultMessage: "Search Giphy",
    description: "placeholder.contentSelector.searchGiphy",
  },
  "contentSelector.sort": {
    defaultMessage: "Sort by",
    description: "placeholder.contentSelector.sort",
  },
  "contentTypes.name": {
    defaultMessage: "Enter content type here",
    description: "placeholder.contentTypes.name",
  },
  "contentTypes.template": {
    defaultMessage: "Enter templates here",
    description: "placeholder.contentTypes.template",
  },
  contextualDek: {
    defaultMessage:
      "Enter contextual dek. If left blank, it will reflect site dek or main dek.",
    description: "placeholder.contextualDek",
  },
  contextualHed: {
    defaultMessage:
      "Enter contextual hed. If left blank, it will reflect site hed or main hed.",
    description: "placeholder.contextualHed",
  },
  "contributors.name": {
    defaultMessage: "New Contributor",
    description: "placeholder.contributors.name",
  },
  "cookbooks.eCommLink": {
    defaultMessage: "https://",
    description: "placeholder.cookbooks.eCommLink",
  },
  "curatedLists.hed": {
    defaultMessage: "New curated list",
    description: "placeholder.curatedLists.hed",
  },
  "curatedsearches.hed": {
    defaultMessage: "New curated search",
    description: "placeholder.curatedsearches.hed",
  },
  "destinations.name": {
    defaultMessage: "New Destination",
    description: "placeholder.destinations.name",
  },
  "embedEditor.iframeHeight": {
    defaultMessage: "Enter a number or percentage",
    description: "placeholder.embedEditor.iframeHeight",
  },
  "embedEditor.iframeWidth": {
    defaultMessage: "Enter a number or percentage",
    description: "placeholder.embedEditor.iframeWidth",
  },
  "embedEditor.socialUrl": {
    defaultMessage: "Include the embed's unique url",
    description: "placeholder.embedEditor.socialUrl",
  },
  enterSEO: {
    defaultMessage: "Enter at least two words to see search suggestions",
    description: "placeholder.enterSEO",
  },
  "external.hed": {
    defaultMessage: "New External Reference",
    description: "placeholder.external.hed",
  },
  "externalLinks.hed": {
    defaultMessage: "New external link",
    description: "placeholder.externalLinks.hed",
  },
  "galleries.hed": {
    defaultMessage: "New Gallery",
    description: "placeholder.galleries.hed",
  },
  "galleries.mediaInfo.altText": {
    defaultMessage: "Enter up to 8 words describing the image",
    description: "placeholder.galleries.mediaInfo.altText",
  },
  "galleries.mediaInfo.caption": {
    defaultMessage: "Enter a caption to live at the photo level",
    description: "placeholder.galleries.mediaInfo.caption",
  },
  "golfball.buyURL": {
    defaultMessage: "Enter buy url here",
    description: "placeholder.golfball.buyURL",
  },
  "golfball.hot": {
    defaultMessage: "Enter hot here",
    description: "placeholder.golfball.hot",
  },
  "golfball.loft": {
    defaultMessage: "Enter loft here",
    description: "placeholder.golfball.loft",
  },
  "golfball.manufacturerDescription": {
    defaultMessage: "Enter manufacturer description",
    description: "placeholder.golfball.manufacturerDescription",
  },
  "golfball.model": {
    defaultMessage: "Enter model here",
    description: "placeholder.golfball.model",
  },
  "golfball.name": {
    defaultMessage: "New Golf Ball",
    description: "placeholder.golfball.name",
  },
  "golfball.not": {
    defaultMessage: "Enter not here",
    description: "placeholder.golfball.not",
  },
  "golfball.oemURL": {
    defaultMessage: "Enter oem url here",
    description: "placeholder.golfball.oemUR",
  },
  "golfball.productDescription": {
    defaultMessage: "Enter product description",
    description: "placeholder.golfball.productDescription",
  },
  "golfball.streetPrice": {
    defaultMessage: "Enter street price here",
    description: "placeholder.golfball.streetPrice",
  },
  "golfball.year": {
    defaultMessage: "Enter year here",
    description: "placeholder.golfball.year",
  },
  "hotels.name": {
    defaultMessage: "New Hotel",
    description: "placeholder.hotels.name",
  },
  "infopages.hed": {
    defaultMessage: "New Info Page",
    description: "placeholder.infopages.hed",
  },
  interactiveOverride: {
    defaultMessage:
      "EX: https://brand.com/projects/interactive/.../project.html",
    description: "placeholder.interactiveOverride",
  },
  "livestoryUpdate.title": {
    defaultMessage: "New Livestory",
    description: "placeholder.liveStoryUpdate.title",
  },
  "nativeArticles.advertiser": {
    defaultMessage: "Type to search or create new sponsor",
    description: "placeholder.nativeArticles.advertiser",
  },
  "nativeArticles.hed": {
    defaultMessage: "New Article",
    description: "placeholder.nativeArticles.hed",
  },
  "newsletters.date": {
    defaultMessage: "YYYY-MM-DD",
    description: "placeholder.newsletters.date",
  },
  "newsletters.hed": {
    defaultMessage: "New Newsletter",
    description: "placeholder.newsletters.hed",
  },
  "newsletters.title": {
    defaultMessage: "New Newsletter",
    description: "placeholder.newsletters.title",
  },
  password: {
    defaultMessage: "{labelText}",
    description: "placeholder.password",
  },
  "photos.altText": {
    defaultMessage: "Enter up to 8 words describing the image",
    description: "placeholder.photos.altText",
  },
  "photos.caption": {
    defaultMessage: "Enter a caption to live at the photo level",
    description: "placeholder.photos.captio",
  },
  "previewBar.selectTemplate": {
    defaultMessage: "Select a template",
    description: "placeholder.previewBar.selectTemplate",
  },
  "products.name": {
    defaultMessage: "New Product",
    description: "placeholder.products.name",
  },
  "projectStatus.select": {
    defaultMessage: "Select a project status",
    description: "placeholder.projectStatus.select",
  },
  "publishDialog.selectQuickDate": {
    defaultMessage: "Schedule New",
    description: "placeholder.publishDialog.selectQuickDate",
  },
  "rcalists.hed": {
    defaultMessage: "New RCA List",
    description: "placeholder.rcalists.hed",
  },
  "recipes.activeTime": {
    defaultMessage: "Active",
    description: "placeholder.recipes.activeTime",
  },
  "recipes.hed": {
    defaultMessage: "New Recipe",
    description: "placeholder.recipes.hed",
  },
  "recipes.issueDate": {
    defaultMessage: "YYYY-MM-DD",
    description: "placeholder.recipes.issueDate",
  },
  "recipes.prepTime": {
    defaultMessage: "Prep",
    description: "placeholder.recipes.prepTime",
  },
  "recipes.servingSizeInfo.description": {
    defaultMessage: "Description",
    description: "placeholder.recipes.servingSizeInfo.description",
  },
  "recipes.servingSizeInfo.from": {
    defaultMessage: "From",
    description: "placeholder.recipes.servingSizeInfo.fro",
  },
  "recipes.servingSizeInfo.selectUnit": {
    defaultMessage: "Select a unit",
    description: "placeholder.recipes.servingSizeInfo.selectUnit",
  },
  "recipes.servingSizeInfo.to": {
    defaultMessage: "To",
    description: "placeholder.recipes.servingSizeInfo.to",
  },
  "recipes.totalTime": {
    defaultMessage: "Total",
    description: "placeholder.recipes.totalTime",
  },
  "restauratnts.name": {
    defaultMessage: "New Restaurant",
    description: "placeholder.restauratnts.name",
  },
  "reviews.hed": {
    defaultMessage: "New Review",
    description: "placeholder.reviews.hed",
  },
  "searchFilter.filterByCollaborator": {
    defaultMessage: "Filter by Collaborator",
    description: "placeholder.searchFilter.filterByCollaborator",
  },
  "searchFilterList.sort": {
    defaultMessage: "Sort By",
    description: "placeholder.searchFilterList.sort",
  },
  searchForContributors: {
    defaultMessage: "Start typing a name to add them",
    description: "placeholder.searchForContributors",
  },
  select: {
    defaultMessage: "Select an option",
    description: "placeholder.select",
  },
  "ships.name": {
    defaultMessage: "New Ship",
    description: "placeholder.ships.name",
  },
  "shops.name": {
    defaultMessage: "New Shop",
    description: "placeholder.shops.name",
  },
  "skiresorts.name": {
    defaultMessage: "New Ski Resort",
    description: "placeholder.skiresorts.name",
  },
  "spas.name": {
    defaultMessage: "New Spa",
    description: "placeholder.spas.name",
  },
  "specialreports.hed": {
    defaultMessage: "New Special Report",
    description: "placeholder.specialreports.hed",
  },
  "syndicationBar.selectTargetBrand": {
    defaultMessage: "Select a brand to syndicate",
    description: "placeholder.syndicationBar.selectTargetBrand",
  },
  tags: {
    defaultMessage: "Use return to separate tags",
    description: "placeholder.tags",
  },
  text: {
    defaultMessage: "Enter {labelText} here",
    description: "placeholder.text",
  },
  "tagpages.name": {
    defaultMessage: "Name",
    description: "placeholder.tagpages.name",
  },
  "tagpages.description": {
    defaultMessage: "Tag description",
    description: "placeholder.tagpages.description",
  },
  "venues.issueDate": {
    defaultMessage: "YYYY-MM-DD",
    description: "placeholder.venues.issueDate",
  },
});

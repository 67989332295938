import { endOfDay, startOfDay } from "date-fns";

function isValidISOString(str: string) {
  if (!str) return false;

  const parsedDate = new Date(str);
  return parsedDate.toISOString() === str;
}

const ONE_HOUR = 60 * 60 * 1000; /* ms */

const DATE_RANGES = {
  "to-now": {
    from: "*",
    to: "NOW",
  },
  "from-now": {
    from: "NOW",
    to: "*",
  },
  today: {
    from: startOfDay(new Date()).toISOString(),
    to: endOfDay(new Date()).toISOString(),
  },
  yesterday: {
    from: startOfDay(
      new Date(new Date().getTime() - ONE_HOUR * 24)
    ).toISOString(),
    to: endOfDay(new Date(new Date().getTime() - ONE_HOUR * 24)).toISOString(),
  },
  "past-hour": {
    from: new Date(new Date().getTime() - ONE_HOUR).toISOString(),
    to: new Date().toISOString(),
  },
  "past-24-hours": {
    from: new Date(new Date().getTime() - ONE_HOUR * 24).toISOString(),
    to: new Date().toISOString(),
  },
  "past-week": {
    from: new Date(new Date().getTime() - ONE_HOUR * 24 * 7).toISOString(),
    to: new Date().toISOString(),
  },
  "past-month": {
    from: new Date(new Date().getTime() - ONE_HOUR * 24 * 30).toISOString(),
    to: new Date().toISOString(),
  },
  "past-year": {
    from: new Date(new Date().getTime() - ONE_HOUR * 24 * 365).toISOString(),
    to: new Date().toISOString(),
  },
  "next-hour": {
    from: new Date().toISOString(),
    to: new Date(new Date().getTime() + ONE_HOUR).toISOString(),
  },
  "next-24-hours": {
    from: new Date().toISOString(),
    to: new Date(new Date().getTime() + ONE_HOUR * 24).toISOString(),
  },
  "next-7-days": {
    from: new Date().toISOString(),
    to: new Date(new Date().getTime() + ONE_HOUR * 24 * 7).toISOString(),
  },
};

export function transformDates(value: string | undefined) {
  if (!value) {
    return;
  }

  let range = {
    from: new Date().toISOString(),
    to: new Date().toISOString(),
  };

  const splitValue = value.split("to");

  let isValidDateRange =
    splitValue && splitValue.length === 2 && splitValue.every(isValidISOString);

  if (isValidDateRange) {
    //here I want to make sure that split value has two parts and they are both valid ISOStrings

    range.from = splitValue[0] < splitValue[1] ? splitValue[0] : splitValue[1];
    range.to = splitValue[0] < splitValue[1] ? splitValue[1] : splitValue[0];
  } else {
    range = DATE_RANGES[value as keyof typeof DATE_RANGES];
  }

  return range
    ? {
        [`publishdate_from`]: range.from,
        [`publishdate_to`]: range.to,
      }
    : undefined;
}

import { CuratedListItem } from "./CuratedListItem";
import { CuratedListItemActions } from "./CuratedListItemActions";
import styled from "styled-components";
import {
  ContentSummary,
  CKEditorConfiguration,
  CuratedListItemData,
  CuratedListItemUpdateOperation,
} from "@types";
import { useDefinedMessages, useScrollWhenDragging } from "@hooks";
import { ARIA, Button, Card, Spinner } from "@components";
import { AddIcon, AssetIcon } from "@condenast/gemini/icons";
import {
  useCallback,
  useState,
  useRef,
  ComponentProps,
  memo,
  MouseEvent,
} from "react";
import { FormattedMessage } from "react-intl";
import {
  BlockEditor,
  InlineEditor,
  MinimalistEditor,
} from "@condenast/ckeditor5-build-condenast";
import { useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import computeScrollIntoView from "compute-scroll-into-view";

const Section = styled(Card).attrs({ as: "section" })`
  display: relative;
  margin-block-start: var(--spacing-md);
  padding: var(--spacing-sm) 0 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 var(--spacing-sm) var(--spacing-sm);
`;

const Title = styled.h2`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
`;

const AddItemButton = styled(ARIA.MenuButton)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: var(--spacing-sm);
  }
`;

const NoItemsMessage = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: var(--spacing-xl) 0;

  & h1 {
    font-size: 1.75rem;
    margin-bottom: var(--spacing-sm);
  }

  & span {
    max-width: 375px;
    line-height: initial;
    display: inline-block;
  }
`;

const ActionButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
`;

const ActionsBlock = styled.div<{ $isHovered: boolean }>`
  width: 100%;
  max-width: 50.5rem;
  text-align: center;
  padding: var(--spacing-sm);
  margin: calc(var(--spacing-lg) - var(--spacing-sm)) auto 0;
  background: ${(props) => props.theme.Background};
  border: ${(props) =>
    props.$isHovered
      ? "2px dashed var(--color-blue-50)"
      : "2px dashed var(--color-gray-5)"};

  ${ActionButton} {
    margin: 0 var(--spacing-xs);
  }
`;

const DropIndicator = styled.div`
  display: none;
  background-color: var(--color-blue-50);
  border-radius: calc(0.5 * var(--spacing-sm));
  width: 100%;
  height: calc(0.5 * var(--spacing-sm));
  max-width: 50.5rem;
`;

const DropZone = styled.div`
  position: absolute;
  pointer-events: none;

  width: 100%;
  height: calc(50% + 0.75 * var(--spacing-sm));

  &.top {
    bottom: 50%;
    ${DropIndicator} {
      position: absolute;
      top: 0;
    }
  }

  &.bottom {
    top: 50%;
    ${DropIndicator} {
      position: absolute;
      bottom: 0;
    }
  }
`;

const ItemDragContainer = styled.div`
  position: relative;
  margin: calc(0.5 * var(--spacing-sm)) auto;
  max-width: 50.5rem;
  width: 100%;
`;

const Placeholder = styled(Card)`
  position: relative;
  display: grid;
  width: 100%;
  max-width: 50.5rem;
  min-height: 144px;
  padding-top: var(--spacing-sm);
  background: var(--color-blue-80);
  border: 2px solid var(--color-blue-50);
  box-shadow: none;
  justify-content: center;
  align-items: center;
`;

const ItemList = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto;
  margin: 0 auto;
  padding: var(--spacing-sm) 0 var(--spacing-lg) 0;
  column-gap: var(--spacing-md);
  width: 100%;
  background: var(--color-gray-6);

  &.dragging {
    cursor: grabbing;
    ${DropZone} {
      pointer-events: auto;
    }
    ${DropZone}.hover ${DropIndicator} {
      display: block;
    }
  }
`;

type DragData = {
  index: number;
  item: CuratedListItemData;
};

function isInteractiveElement(element: Element) {
  return (
    ["A", "INPUT", "BUTTON", "SELECT"].indexOf(element.tagName) >= 0 ||
    element.hasAttribute("contenteditable")
  );
}

function isContainedInInteractiveElement(target: EventTarget | null) {
  let currentElement = target instanceof Element ? target : null;
  let interactive = false;
  while (currentElement && !interactive) {
    interactive = isInteractiveElement(currentElement);
    currentElement = currentElement.parentElement;
  }
  return interactive;
}

const DraggableItem = memo(function (
  props: {
    moveItem: (item: DragData, index: number) => void;
    onDragStart: () => void;
    onDragEnd: () => void;
  } & DragData &
    ComponentProps<typeof CuratedListItem>
) {
  let { item, index, moveItem, onDragStart, onDragEnd, ...itemProps } = props;
  let data = { item, index };
  let beforeRef = useRef<HTMLDivElement | null>(null);
  let afterRef = useRef<HTMLDivElement | null>(null);
  const [draggable, setDraggable] = useState(true);

  const updateDraggableState = useCallback(
    (evt: MouseEvent) => {
      setDraggable(!isContainedInInteractiveElement(evt.target));
    },
    [setDraggable]
  );

  const enableDrag = useCallback(() => {
    setDraggable(true);
  }, [setDraggable]);

  const [, dragRef] = useDrag<DragData>(
    () => ({
      type: "CuratedListItem",
      item: () => data,
      canDrag: () => draggable,
    }),
    [data]
  );

  const [, dropBefore] = useDrop<DragData>(
    () => ({
      accept: "CuratedListItem",
      drop(item) {
        moveItem(item, index);
      },
    }),
    [index, moveItem]
  );

  const [, dropAfter] = useDrop<DragData>(
    () => ({
      accept: "CuratedListItem",
      drop(item) {
        moveItem(item, index + 1);
      },
    }),
    [index, moveItem]
  );

  let captureBeforeEl = useCallback(
    (el: HTMLDivElement | null) => {
      beforeRef.current = el;
      dropBefore(el);
      if (el) {
        el.ondragover = () => beforeRef.current?.classList.add("hover");
        el.ondragleave = () => beforeRef.current?.classList.remove("hover");
        el.ondrop = () => beforeRef.current?.classList.remove("hover");
      }
    },
    [dropBefore]
  );

  let captureAfterEl = useCallback(
    (el: HTMLDivElement | null) => {
      afterRef.current = el;
      dropAfter(el);
      if (el) {
        el.ondragover = () => afterRef.current?.classList.add("hover");
        el.ondragleave = () => afterRef.current?.classList.remove("hover");
        el.ondrop = () => afterRef.current?.classList.remove("hover");
      }
    },
    [dropAfter]
  );

  return (
    <ItemDragContainer
      ref={(node) => dragRef(node)}
      onDrag={onDragStart}
      onDragOver={onDragStart}
      onDragEnd={onDragEnd}
      onDrop={onDragEnd}
      onMouseDownCapture={updateDraggableState}
      onMouseUp={enableDrag}
    >
      <CuratedListItem index={index} item={item} {...itemProps} />
      <DropZone ref={captureBeforeEl} className="top">
        <DropIndicator />
      </DropZone>
      <DropZone ref={captureAfterEl} className="bottom">
        <DropIndicator />
      </DropZone>
    </ItemDragContainer>
  );
});

DraggableItem.displayName = "DraggableItem";

function getInsertionIndex<T>(items: T[], item: T) {
  const index = items.indexOf(item);
  return index >= 0 ? index : items.length;
}

const Builds = {
  block: BlockEditor,
  inline: InlineEditor,
  minimalist: MinimalistEditor,
};

export function CuratedListItems(props: {
  onUpdate: (operations: CuratedListItemUpdateOperation[]) => void;
  selectAssets: (opts?: {
    limitSelection?: number;
  }) => Promise<ContentSummary[] | null>;
  cdnHost?: string;
  items: (CuratedListItemData &
    Pick<Required<CuratedListItemData>, "itemID">)[];
  hedRichTextConfig: CKEditorConfiguration;
  hedRichTextBuild: keyof typeof Builds;
  dekRichTextConfig: CKEditorConfiguration;
  dekRichTextBuild: keyof typeof Builds;
  rubricRichTextBuild: keyof typeof Builds;
}) {
  const {
    items,
    selectAssets,
    onUpdate,
    cdnHost,
    dekRichTextConfig,
    dekRichTextBuild,
    hedRichTextConfig,
    hedRichTextBuild,
    rubricRichTextBuild,
  } = props;

  const { translateFieldLegend } = useDefinedMessages();

  const [lastInsertedIndex, setLastInsertedIndex] = useState<number | null>(
    null
  );

  const itemListRef = useRef<HTMLDivElement | null>(null);
  const reportDragStart = useCallback(
    () => itemListRef.current?.classList.add("dragging"),
    []
  );
  const reportDragEnd = useCallback(
    () => itemListRef.current?.classList.remove("dragging"),
    []
  );
  const scrollHandlers = useScrollWhenDragging({
    dragZoneSize: 200,
    distancePerFrame: 100,
    msPerFrame: 120,
    scrollBehavior: "smooth",
  });

  const moveItem = useCallback(
    (item: DragData, dropIndex: number) => {
      if (item.index === dropIndex) return;

      let adjustedDropIndex =
        item.index < dropIndex ? dropIndex - 1 : dropIndex;

      onUpdate([
        [item.index, 1],
        [adjustedDropIndex, 0, item.item],
      ]);
      setLastInsertedIndex(adjustedDropIndex);
    },
    [onUpdate]
  );

  const addFromAssets = useCallback(
    (addToTop) => {
      selectAssets()
        .then((assets) => {
          if (assets && assets.length) {
            if (addToTop) {
              onUpdate([[0, 0, ...assets.map((item) => ({ item }))]]);
              setLastInsertedIndex(assets.length - 1);
            } else {
              onUpdate([
                [items.length, 0, ...assets.map((item) => ({ item }))],
              ]);
              setLastInsertedIndex(items.length + assets.length - 1);
            }
          }
        })
        .catch(() => {});
    },
    [items, onUpdate, selectAssets, setLastInsertedIndex]
  );

  const addAssetToItem = useCallback(
    (item: CuratedListItemData) => {
      selectAssets({ limitSelection: 1 })
        .then((assets) => {
          if (!(assets && assets.length)) {
            return;
          }
          const [asset] = assets;
          const insertIndex = getInsertionIndex(items, item);
          onUpdate([[insertIndex, 1, update(item, { item: { $set: asset } })]]);
          setLastInsertedIndex(insertIndex);
        })
        .catch(() => {});
    },
    [items, onUpdate, selectAssets]
  );

  const onItemChange = useCallback(
    (item: CuratedListItemData, index: number) => {
      onUpdate([[index, 1, item]]);
      setLastInsertedIndex(null);
    },
    [onUpdate]
  );

  const removeItem = useCallback(
    (index) => {
      onUpdate([[index, 1]]);
      setLastInsertedIndex(null);
    },
    [onUpdate, setLastInsertedIndex]
  );

  const handleScrollToBottom = useCallback(() => {
    if (itemListRef.current) {
      const actions = computeScrollIntoView(itemListRef.current, {
        scrollMode: "if-needed",
        block: "end",
      });
      return actions.forEach(({ el, top }) => {
        el.scrollTo({ top, behavior: "smooth" });
      });
    }
    return null;
  }, []);

  const handleScrollToTop = useCallback(() => {
    if (itemListRef.current) {
      const actions = computeScrollIntoView(itemListRef.current, {
        scrollMode: "if-needed",
        block: "start",
      });
      return actions.forEach(({ el, top }) => {
        el.scrollTo({ top, behavior: "smooth" });
      });
    }
    return null;
  }, []);

  return (
    <Section>
      <Header>
        <Title>{translateFieldLegend("Items")}</Title>
        <AddItemButton
          size="medium"
          aria-label="Actions menu"
          menu={{
            items: [
              {
                value: (
                  <>
                    <AddIcon size="small" />
                    <FormattedMessage
                      defaultMessage="Blank Item"
                      description="Add a blank item"
                    />
                  </>
                ),
                role: "action",
                onClick: () => {
                  onUpdate([[0, 0, { item: null }]]);
                  setLastInsertedIndex(0);
                },
              },
              {
                value: (
                  <>
                    <AssetIcon size="small" />
                    <FormattedMessage
                      defaultMessage="Item from Asset"
                      description="Select assets to be added as item"
                    />
                  </>
                ),
                role: "action",
                onClick: () => {
                  addFromAssets(true);
                },
              },
            ],
            children: (element: JSX.Element) => {
              return <StyledItem>{element}</StyledItem>;
            },
          }}
        >
          <AddIcon size="regular" />
          <FormattedMessage
            defaultMessage="Add Items"
            description="Menu for adding items"
          />
        </AddItemButton>
      </Header>
      <ItemList ref={itemListRef} {...scrollHandlers}>
        {items.length ? (
          items.map((item, index) => {
            return item.isPlaceholder ? (
              <Placeholder>
                <Spinner
                  size="large"
                  $color={{
                    background: "var(--color-blue-40)",
                    foreground: "var(--color-blue-70)",
                  }}
                />
              </Placeholder>
            ) : (
              <DraggableItem
                key={item.itemID}
                index={index}
                item={item}
                cdnHost={cdnHost}
                selectAsset={addAssetToItem}
                removeItem={removeItem}
                onChange={onItemChange}
                isMostRecentlyInserted={index === lastInsertedIndex}
                hedRichTextConfig={hedRichTextConfig}
                hedRichTextBuild={hedRichTextBuild}
                dekRichTextConfig={dekRichTextConfig}
                dekRichTextBuild={dekRichTextBuild}
                rubricRichTextBuild={rubricRichTextBuild}
                onDragStart={reportDragStart}
                onDragEnd={reportDragEnd}
                moveItem={moveItem}
              />
            );
          })
        ) : (
          <NoItemsMessage>
            <h1>
              <FormattedMessage
                defaultMessage="No Items Yet"
                description="Message for empty list of items"
              />
            </h1>
            <span>
              <FormattedMessage
                defaultMessage="Select existing content items or start sketching
                  out with blank content."
                description="Descriptive text for how to add items"
              />
            </span>
          </NoItemsMessage>
        )}
        {items.length > 1 && (
          <CuratedListItemActions
            items={items}
            handleScrollToBottom={handleScrollToBottom}
            handleScrollToTop={handleScrollToTop}
            cdnHost={cdnHost}
            moveItem={moveItem}
            onUpdate={onUpdate}
          />
        )}

        <ActionsBlock $isHovered={true}>
          <ActionButton
            onClick={(evt) => {
              evt.preventDefault();
              addFromAssets(false);
            }}
          >
            <AssetIcon size="regular" />
            <FormattedMessage
              defaultMessage="Select Assets"
              description="Select assets to be added as items"
            />
          </ActionButton>
          <ActionButton
            onClick={(evt) => {
              evt.preventDefault();
              onUpdate([[items.length, 0, { item: null }]]);
              setLastInsertedIndex(items.length);
            }}
          >
            <AddIcon size="regular" />
            <FormattedMessage
              defaultMessage="Blank Item"
              description="Add a blank item"
            />
          </ActionButton>
        </ActionsBlock>
      </ItemList>
    </Section>
  );
}
CuratedListItems.displayName = "CuratedListItems";

import { defineMessages } from "react-intl";
import { UserRole } from "@types";

export const USER_ROLES = defineMessages({
  [UserRole.admin]: {
    defaultMessage: "Admin",
    description: "templates.permissions.table-header-users.admin",
  },
  [UserRole.contributor]: {
    defaultMessage: "Contributor",
    description: "templates.permissions.table-header-users.contributor",
  },
  [UserRole.manager]: {
    defaultMessage: "Manager",
    description: "templates.permissions.table-header-users.manager",
  },
  [UserRole.marketer]: {
    defaultMessage: "Marketer",
    description: "templates.permissions.table-header-users.marketer",
  },
  [UserRole.superadmin]: {
    defaultMessage: "Super admin",
  },
});

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required } from "@condenast/copilot-validators";
import { FormFor_content_CuratedSearch } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";
import { publishURI } from "../validators";

const { object } = validators;

const curatedSearchValidators = object({
  hed: required(),
}).andAlso(publishURI().on("publish"));

export default function validateCuratedSearch(
  curatedSearch: FormFor_content_CuratedSearch,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(curatedSearch, build(curatedSearchValidators), context, env);
}

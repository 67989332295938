//https://github.com/django/django/blob/master/django/contrib/admin/static/admin/js/urlify.js
export default {
  // currency
  "€": "euro",
  "₢": "cruzeiro",
  "₣": "french franc",
  "£": "pound",
  "₤": "lira",
  "₥": "mill",
  "₦": "naira",
  "₧": "peseta",
  "₨": "rupee",
  "₩": "won",
  "₪": "new shequel",
  "₫": "dong",
  "₭": "kip",
  "₮": "tugrik",
  "₯": "drachma",
  "₰": "penny",
  "₱": "peso",
  "₲": "guarani",
  "₳": "austral",
  "₴": "hryvnia",
  "₵": "cedi",
  "¢": "cent",
  "¥": "yen",
  円: "yen",
  "﷼": "rial",
  "₠": "ecu",
  "¤": "currency",
  "฿": "baht",
  $: "dollar",
  // symbols
  "&": "and",
  "|": "or",
  "—": "-",
  "–": "-",
  "‒": "-",
  "―": "-",
  // This includes full width tildes _and_ wave dashes (they look the same)
  "⁓": "-",
  "～": "-",
  "~": "-",
  "〜": "-",
} as Record<string, string>;

import { AdvancedSearchOptions, GetAppliedFiltersOptions } from "../types";
import { getAppliedFilters } from "./getAppliedFilters";
export function getAppliedFiltersCount(
  searchParams: AdvancedSearchOptions,
  options?: GetAppliedFiltersOptions
): number {
  let selectedAdapterFilters = getAppliedFilters(searchParams, options);

  return Object.values(selectedAdapterFilters).reduce((total, filterValue) => {
    if (Array.isArray(filterValue)) {
      total += filterValue.length;
    } else if (filterValue) {
      total++;
    }
    return total;
  }, 0);
}

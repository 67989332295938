import styled from "styled-components";
import { ThemeProvider } from "@contexts";
import { Button, FloatingActionBar } from "@components";
import { FormattedMessage } from "react-intl";
import { DownloadIcon, UploadIcon, GridIcon } from "@condenast/gemini/icons";
import { useState } from "react";
import { TileViewPanel } from "../TileViewPanel";
import {
  ContentSummary,
  CKEditorConfiguration,
  SlideData,
  SlideUpdateOperation,
} from "@types";
import {
  BlockEditor,
  InlineEditor,
  MinimalistEditor,
} from "@condenast/ckeditor5-build-condenast";

const Divider = styled.div`
  display: inline-block;
  height: 40px;
  border-right: 1px solid ${(props) => props.theme.DividerColor};
`;

const ReorderButton = styled(Button)`
  box-shadow: none;
  margin-left: var(--spacing-xxs);
`;

type DragData = {
  index: number;
  slide: SlideData;
};

const Builds = {
  block: BlockEditor,
  inline: InlineEditor,
  minimalist: MinimalistEditor,
};

export function GallerySlideActions(props: {
  slides: (SlideData & Pick<Required<SlideData>, "slideID">)[];
  handleScrollToBottom: () => void;
  handleScrollToTop: () => void;
  cdnHost?: string;
  onUpdate: (operations: SlideUpdateOperation[]) => void;
  onBatchUpdate?: (items: ContentSummary[], credit: string) => Promise<void>;
  isBatchUpdating?: boolean;
  moveSlide: (slide: DragData, dropIndex: number) => void;
  creditRichTextConfig: CKEditorConfiguration;
  creditRichTextBuild: keyof typeof Builds;
}) {
  const {
    slides,
    handleScrollToBottom,
    handleScrollToTop,
    cdnHost,
    onUpdate,
    moveSlide,
    onBatchUpdate,
    isBatchUpdating,
    creditRichTextConfig,
    creditRichTextBuild,
  } = props;
  const [isShowing, setShowing] = useState(false);

  return (
    <ThemeProvider theme="dark">
      <FloatingActionBar>
        <Button aria-label="Jump to Top" onClick={handleScrollToTop}>
          <UploadIcon size="regular" />
        </Button>
        <Button aria-label="Jump to Bottom" onClick={handleScrollToBottom}>
          <DownloadIcon size="regular" />
        </Button>
        <Divider />
        <ReorderButton
          aria-label="Reorder / Batch Credit"
          onClick={() => setShowing(true)}
        >
          <GridIcon size="regular" />
          <FormattedMessage defaultMessage="Reorder / Batch Credit" />
        </ReorderButton>
      </FloatingActionBar>
      {isShowing && (
        <TileViewPanel
          onClose={() => setShowing(false)}
          slides={slides}
          cdnHost={cdnHost}
          onUpdate={onUpdate}
          moveSlide={moveSlide}
          onBatchUpdate={onBatchUpdate}
          isBatchUpdating={isBatchUpdating}
          creditRichTextConfig={creditRichTextConfig}
          captionRichTextBuild={creditRichTextBuild}
        />
      )}
    </ThemeProvider>
  );
}

import { useMutation } from "@apollo/client";
import {
  ContentSummary,
  ImportGettyVideo,
  ImportGettyVideoVariables,
} from "@types";
import { Mutations } from "@gql";
import { normalizeContentSummary } from "@lib";
import { SearchPostProcessor } from "../types";
import { useDefinedMessages } from "@hooks";

export function useGettyVideoPostProcessor(
  organizationId: string
): SearchPostProcessor {
  const [importVideos, { loading }] = useMutation<
    ImportGettyVideo,
    ImportGettyVideoVariables
  >(Mutations.IMPORT_GETTY_VIDEO);

  const { translateContentType } = useDefinedMessages();

  return {
    loading,
    process: async (items: ContentSummary[]) => {
      const gettyVideos = items.filter(
        (item) => item.contentType === "gettyvideo"
      );
      if (!gettyVideos.length) {
        return items;
      }

      const result = await importVideos({
        variables: {
          organizationId,
          data: gettyVideos
            .filter((item) => item.contentType === "gettyvideo")
            .map((item) => ({
              id: item.id,
            })),
        },
      });

      const resultData = result.data?.importGettyVideo.map((result) =>
        normalizeContentSummary(result, translateContentType)
      );
      if (!resultData) {
        return items;
      }

      return items.map((item) =>
        item.contentType === "gettyvideo" ? resultData.shift() ?? null : item
      );
    },
  };
}

import type { ContentSerializers } from "../types";
import type { CuratedSearchInput } from "@types";
import { csquery, categoryTaxonomies, contentConnection, seo } from "../fields";
import { serializerFor, entityMetadata } from "../utils";

const CuratedSearchFields = {
  categoryTaxonomies,
  photosSocial: contentConnection,
  photosTout: contentConnection,
  seo: seo,
} as const;

export const CuratedSearchSerializers: ContentSerializers<CuratedSearchInput> =
  {
    save: (changes, model, { currentUser }) => {
      if (!("curatedSearchQuery" in model)) {
        return null;
      }
      if (!("hed" in model)) {
        return null;
      }
      return {
        ...serializerFor(CuratedSearchFields)(changes),
        hed: model.hed,
        curatedSearchQuery: csquery(model),
        includedCategoryTaxonomies: categoryTaxonomies(
          model.includedCategoryTaxonomies
        ),
        excludedCategoryTaxonomies: categoryTaxonomies(
          model.excludedCategoryTaxonomies
        ),
        authorName: `${currentUser.firstName} ${currentUser.lastName}`,
        entityMetadata: entityMetadata(model, currentUser.id),
      };
    },
    create: ({ currentUser, intl }) => {
      const currentDateTime = intl.formatDate(Date.now(), {
        year: "numeric",
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
      });
      const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
      return {
        hed: createTitle,
        authorName: `${currentUser.firstName} ${currentUser.lastName}`,
        curatedSearchQuery: { filters: {} },
        entityMetadata: { user: [`${currentUser.id}`] },
      };
    },
  };

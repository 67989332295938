import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { ApolloError } from "@apollo/client";

export enum ErrorType {
  NotAuthorized = "not-authorized",
  NotFound = "not-found",
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-top: 10vh;
`;

const NotFoundBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 360px;
  width: 100%;
  padding: var(--spacing-lg);
  background-color: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.CardShadow};
`;
const ErrorPageTitle = styled.span`
  text-align: center;
  margin-bottom: var(--spacing-sm);
  font: var(--font-section-heading);
`;

const ErrorPageMessage = styled.span`
  text-align: center;
  font: var(--font-body);
`;

const NotAuthorizedTitle = () => (
  <FormattedMessage
    defaultMessage="403: Not Authorized"
    description="Title text for the Not Authorized page"
  />
);

const NotFoundTitle = () => (
  <FormattedMessage
    defaultMessage="404: Not Found"
    description="Title text for the Not Found page"
  />
);

const NotAuthorizedMessage = () => (
  <FormattedMessage
    defaultMessage="You do not have the necessary permissions to view this page."
    description="Suggestion text for the Not Authorized page"
  />
);

const NotFoundMessage = () => (
  <FormattedMessage
    defaultMessage="Please check the URL, unfortunately the page you’re looking could not be found."
    description="Suggestion text for the Not Found page"
  />
);

export const ErrorPage = ({
  type,
  error,
}: {
  type?: ErrorType;
  error?: ApolloError;
}) => {
  const isAuthorizationError =
    type === ErrorType.NotAuthorized ||
    error?.message?.toLowerCase() === "not authorized";

  return (
    <Wrapper>
      <NotFoundBox>
        <ErrorPageTitle>
          {isAuthorizationError ? <NotAuthorizedTitle /> : <NotFoundTitle />}
        </ErrorPageTitle>
        <ErrorPageMessage>
          {isAuthorizationError ? (
            <NotAuthorizedMessage />
          ) : (
            <NotFoundMessage />
          )}
        </ErrorPageMessage>
      </NotFoundBox>
    </Wrapper>
  );
};

import { useCallback, useMemo, useState } from "react";
import {
  ControlProps,
  FormFor_form_controls_SEOFormControl,
  SeoFields_seo,
  SEOKeywords,
  SEOKeywordsVariables,
} from "@types";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Card,
  Field,
  TextField,
  Autogenerates,
  SEOKeywordField,
  Select,
} from "@components";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import PlainTextRenderer from "@atjson/renderer-plain-text";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "@gql";
import { useDebouncedCallback } from "@hooks";
import { fieldInPath, errorsForField } from "@lib";

const EXTERNAL_PARTNER = "external_partner";

const Section = styled(Card).attrs({ as: "section" })`
  margin-block-start: var(--spacing-md);
`;

const Title = styled.h2`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
  margin-bottom: var(--spacing-sm);
`;

const SEOCategoryField = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

function isKeywordSearchable(keyword: string) {
  return keyword.trim().split(" ").length > 1;
}
export function SEO(props: ControlProps<FormFor_form_controls_SEOFormControl>) {
  let {
    model,
    errors,
    setValue,
    currentOrganization,
    showCategoryAndKeywordFields,
    hedAutogenValue,
    dekAutogenValue,
  } = props;

  let SEO = model.seo as SeoFields_seo | null;
  let canonicalUrl = model["canonicalUrl"] as string | null;
  let currentCategory = SEO?.category || undefined;
  let intl = useIntl();

  let [categoryErrors, titleErrors, descriptionErrors] = useMemo(() => {
    return ["seo.category", "seo.title", "seo.description "].map((field) =>
      errors.filter((error) => fieldInPath(field, error.path))
    );
  }, [errors]);

  const canonicalUrlErrors = useMemo(
    () => errorsForField(errors, "canonicalUrl"),
    [errors]
  );
  const [shouldQueryKeywords, setShouldQueryKeywords] = useState<boolean>(true);

  let setSeoValue = useCallback(
    (seoField: keyof SeoFields_seo, value: string) => {
      setValue(
        "seo",
        Object.assign(
          {},
          SEO,
          { keyword: SEO?.keyword ?? "" },
          { [seoField]: value }
        )
      );
    },
    [setValue, SEO]
  );

  let setCategory = useCallback(
    (value: string | undefined) => {
      setSeoValue("category", value ?? "");
      setShouldQueryKeywords(true);
    },
    [setSeoValue]
  );

  let setTitle = useCallback(
    (value: string) => setSeoValue("title", value),
    [setSeoValue]
  );

  let setDescription = useCallback(
    (value: string) => setSeoValue("description", value),
    [setSeoValue]
  );

  let setKeyword = useCallback(
    (value: string) => setSeoValue("keyword", value),
    [setSeoValue]
  );

  const [_suggestKeywords, { data: keywordData, loading: keywordLoading }] =
    useLazyQuery<SEOKeywords, SEOKeywordsVariables>(Queries.SEO_KEYWORDS);
  const suggestKeywords = useCallback(
    (query) => {
      if (currentCategory && isKeywordSearchable(query)) {
        _suggestKeywords({
          variables: {
            query,
            seoCategory: currentCategory,
            organizationId: currentOrganization.organizationId,
          },
        });
        setShouldQueryKeywords(false);
      }
    },
    [
      _suggestKeywords,
      currentCategory,
      currentOrganization,
      setShouldQueryKeywords,
    ]
  );
  const debounceSuggestKeywords = useDebouncedCallback(
    (query: string) => suggestKeywords(query),
    600,
    [currentCategory, _suggestKeywords, currentOrganization]
  );
  const onKeywordInputChange = useCallback(
    (keyword: string) => {
      setKeyword(keyword);
      debounceSuggestKeywords(keyword);
    },
    [setKeyword, debounceSuggestKeywords]
  );
  const currentKeyword = SEO?.keyword ?? "";
  const shouldSearch = isKeywordSearchable(currentKeyword);

  const strippedAutogenValue = useCallback(
    (value: string) => {
      if (model[value]) {
        return PlainTextRenderer.render(
          CopilotMarkdownSource.fromRaw(model[value] as string)
        ).trim();
      }
      return null;
    },
    [model]
  );

  const showCanonicalUrlField = useMemo(
    () => model.contentSource === EXTERNAL_PARTNER,
    [model.contentSource]
  );

  return (
    <Section id="§seo">
      <Title>
        <FormattedMessage
          defaultMessage="SEO"
          description="acronym for Search Engine Optimization"
        />
      </Title>
      {showCategoryAndKeywordFields && (
        <>
          <SEOCategoryField
            id="seo-category"
            label={<FormattedMessage defaultMessage="SEO Category" />}
            errors={categoryErrors}
          >
            <Select
              id="seo-category"
              value={currentCategory}
              placeholder={intl.formatMessage({
                defaultMessage: "Select",
                description:
                  "Select as a verb. Placeholder text for a select form control",
              })}
              options={[
                {
                  label: intl.formatMessage({
                    defaultMessage: "Evergreen",
                    description:
                      "Publishing slang meaning 'always relevant', as opposed to 'News' which is only temporarily relevant",
                  }),
                  value: "evergreen",
                },
                {
                  label: intl.formatMessage({
                    defaultMessage: "News",
                    description: "term for content which is time-sensitive",
                  }),
                  value: "news",
                },
              ]}
              onChange={setCategory}
            />
          </SEOCategoryField>
          <SEOKeywordField
            id="seo-keyword"
            currentKeyword={currentKeyword}
            disabled={!currentCategory}
            onInput={onKeywordInputChange}
            suggestionsLoading={shouldSearch ? keywordLoading : false}
            suggestions={shouldSearch ? keywordData?.seoKeywords : []}
            onChange={setKeyword}
            onFocus={() => {
              if (shouldQueryKeywords) {
                suggestKeywords(currentKeyword);
              }
            }}
          />
        </>
      )}
      <Autogenerates
        onChange={setTitle}
        autogenValue={
          hedAutogenValue
            ? (strippedAutogenValue(hedAutogenValue) as string)
            : ((strippedAutogenValue("hed") ?? undefined) as string | undefined)
        }
        currentValue={SEO?.title || ""}
      >
        {(autogenProps) => (
          <TextField
            id="seo-title"
            label={<FormattedMessage defaultMessage="SEO Title" />}
            value={SEO?.title || ""}
            count={"characters"}
            errors={titleErrors}
            {...autogenProps}
          />
        )}
      </Autogenerates>
      <Autogenerates
        onChange={setDescription}
        autogenValue={
          dekAutogenValue
            ? (strippedAutogenValue(dekAutogenValue) as string)
            : ((strippedAutogenValue("dek") ?? undefined) as string | undefined)
        }
        currentValue={SEO?.description || ""}
      >
        {(autogenProps) => (
          <TextField
            multiline
            id="seo-description"
            label={<FormattedMessage defaultMessage="SEO Description" />}
            value={SEO?.description || ""}
            count="characters"
            errors={descriptionErrors}
            {...autogenProps}
          />
        )}
      </Autogenerates>
      {showCanonicalUrlField && (
        <TextField
          id="canonical-url"
          label={<FormattedMessage defaultMessage="Canonical URL" />}
          value={canonicalUrl || ""}
          errors={canonicalUrlErrors}
          onChange={(value: string) => setValue("canonicalUrl", value)}
        />
      )}
    </Section>
  );
}

SEO.displayName = "Control(SEO)";

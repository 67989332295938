import { useCallback, useState, useContext } from "react";
import { ARIA, Button } from "@components";
import { useUniqueId } from "@hooks";
import { ApolloError } from "@apollo/client";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { ScheduleDate } from "./ScheduleDate";
import { DistributionChannels } from "./DistributionChannels";
import { useDefinedMessages } from "@hooks";
import { SnowplowContext } from "@contexts";
import {
  PublishData,
  DistributionChannelConfig,
  DistributionChannel,
} from "@types";

const Dialog = styled(ARIA.Dialog)`
  overflow: visible;
`;

export const DialogBody = styled.div`
  padding: var(--spacing-xs) var(--spacing-md) var(--spacing-sm);
`;

const Uri = styled.div`
  font: ${(props) => props.theme.FontBody};
  margin-top: var(--spacing-sm);

  .label {
    font: ${(props) => props.theme.FontLabel};
    margin-bottom: var(--spacing-xs);
  }
`;

export const PublishDialog = (props: {
  publishData: PublishData;
  scheduledPubDates: string[];
  contentType: string;
  distributionChannelsConfig?: DistributionChannelConfig[] | null;
  hostname: string | undefined;
  publish: (publishData: PublishData) => void;
  onClose: () => void;
  requestError?: ApolloError | undefined;
  copilotCode: string;
}) => {
  const {
    publish,
    distributionChannelsConfig,
    hostname,
    publishData: { uri, authorName },
    scheduledPubDates,
    copilotCode,
    onClose,
  } = props;

  const intl = useIntl();
  const id = useUniqueId();
  const { translateContentType } = useDefinedMessages();
  const { trackContentEvent } = useContext(SnowplowContext);
  const [pubDate, setPubDate] = useState(props.publishData.pubDate);
  const [distributionChannels, setDistributionChannels] = useState(
    props.publishData.distributionChannels || []
  );
  const [submitElement, setSubmitElement] = useState<HTMLButtonElement | null>(
    null
  );
  const submit = useCallback(() => {
    submitElement?.setAttribute("disabled", "");
    publish({
      uri,
      authorName,
      pubDate,
      distributionChannels,
    });
  }, [uri, authorName, publish, submitElement, pubDate, distributionChannels]);

  const closeDialog = useCallback(() => {
    onClose();
    trackContentEvent("abandoned_published", "button_click");
  }, [onClose, trackContentEvent]);

  return (
    <Dialog
      title={intl.formatMessage(
        { defaultMessage: "Publish {type}" },
        { type: translateContentType(props.contentType, 1) }
      )}
      size={"regular"}
      cancelButton={
        <Button>
          <FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button in publish modal"
          />
        </Button>
      }
      submitButton={
        <Button
          treatment="primary"
          type="submit"
          onClick={submit}
          ref={setSubmitElement}
        >
          <FormattedMessage
            defaultMessage="Publish"
            description="Submit button in publish modal"
          />
        </Button>
      }
      onClose={closeDialog}
    >
      <DialogBody>
        <ScheduleDate
          copilotCode={copilotCode}
          pubDate={props.publishData.pubDate ?? ""}
          scheduledPubDates={scheduledPubDates}
          onChange={setPubDate}
          idPrefix={`PublishModal-${id}`}
        />
        <Uri>
          <p className="label">
            <FormattedMessage defaultMessage="URL" />
          </p>
          <p>
            {hostname}
            {uri}
          </p>
        </Uri>
        {distributionChannelsConfig?.find(
          (config) => config.enabled && config.name != "WebOwnedAndOperated"
        ) && (
          <DistributionChannels
            distributionChannelsConfig={distributionChannelsConfig}
            distributionChannels={distributionChannels}
            onChange={(distributionChannels: DistributionChannel[]) =>
              setDistributionChannels(distributionChannels)
            }
          />
        )}
      </DialogBody>
    </Dialog>
  );
};
PublishDialog.displayName = "PublishDialog";

import { useCallback, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { ARIA, Button, CKEditor, UploadButton } from "@components";
import { AnimatedEllipsisIcon, UploadIcon } from "@condenast/gemini/icons";
import { ThemeProvider } from "@contexts";
import type { CKEditorConfiguration } from "@types";

import styled from "styled-components";

import PrismXMLSource from "@atjson/source-prism";
import OffsetAnnotations from "@atjson/offset-annotations";
import VersoSource from "@condenast/atjson-source-verso";

const Dialog = styled(ARIA.Dialog)``;

const ImportPrismXmlButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const ReadonlyEditor = styled(CKEditor)`
  &.ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
    border-radius: 0;
  }
`;

const StyledUploadButton = styled(UploadButton)`
  flex: 0 0 auto;
`;

const Error = styled.div`
  padding-top: var(--spacing-xs);
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.SecondaryColor};
`;

interface PrismFileData {
  fileName: string;
  doc: VersoSource;
  xml: string;
}

export const PrismXMLImportButton = (props: {
  onSubmit: (doc: VersoSource) => Promise<void>;
  previewConfig: CKEditorConfiguration;
}) => {
  const { onSubmit, previewConfig } = props;
  const [prismFileData, setPrismFileData] = useState<PrismFileData | undefined>(
    undefined
  );
  const [showError, setShowError] = useState(false);
  const [importing, setImporting] = useState(false);

  const intl = useIntl();

  const onXmlFileSelect = useCallback(
    async (files: FileList) => {
      setPrismFileData(undefined);
      setShowError(false);

      try {
        const [file] = files;
        if (file) {
          const xml = await file.text();
          const doc = PrismXMLSource.fromRaw(xml).convertTo(
            OffsetAnnotations
          ) as VersoSource;
          doc.where({ type: "-atjson-unknown" }).remove();
          setPrismFileData({
            xml,
            doc,
            fileName: file.name,
          });
        }
      } catch {
        setShowError(true);
      }
    },
    [setPrismFileData, setShowError]
  );

  const close = useCallback(
    () => setPrismFileData(undefined),
    [setPrismFileData]
  );

  const submit = useCallback(async () => {
    if (prismFileData) {
      setImporting(true);
      await onSubmit(prismFileData.doc);

      setImporting(false);
      setPrismFileData(undefined);
    }
  }, [prismFileData, onSubmit, setPrismFileData, setImporting]);

  const noop = useCallback(() => {}, []);

  return (
    <>
      <StyledUploadButton
        accept=".xml"
        multiple={false}
        onChange={onXmlFileSelect}
      >
        {() => (
          <>
            <UploadIcon size="regular" />
            <FormattedMessage
              defaultMessage="Import Prism XML"
              description="Text on button to import a story body from a Prism XML document"
            />
          </>
        )}
      </StyledUploadButton>
      {prismFileData && (
        <Dialog
          title={intl.formatMessage(
            {
              defaultMessage: "Import from {file}",
              description: "Dialog title for importing a Prism XML file",
            },
            { file: prismFileData.fileName }
          )}
          size="regular"
          onClose={close}
          submitButton={
            <ImportPrismXmlButton onClick={submit} disabled={importing}>
              {importing ? (
                <AnimatedEllipsisIcon size="small" />
              ) : (
                <UploadIcon size="small" />
              )}
              <FormattedMessage
                defaultMessage="Import"
                description="Dialog submit button for importing a Prism XML file"
              />
            </ImportPrismXmlButton>
          }
        >
          <ReadonlyEditor
            readonly={true}
            config={previewConfig}
            build="block"
            value={prismFileData.doc}
            id=""
            onChange={noop}
          />
        </Dialog>
      )}
      {showError && (
        <ThemeProvider tint="red">
          <Error>
            <FormattedMessage
              defaultMessage="Unable to read file as Prism XML"
              description="Error message for reading Prism XML file"
            />
          </Error>
        </ThemeProvider>
      )}
    </>
  );
};

PrismXMLImportButton.displayName = "PrismXMLImportButton";

import { AssetSelectorHeader } from "./AssetSelectorHeader";
import { AssetSelectorAdapterList } from "./AssetSelectorAdapterList";
import { AssetSelectorResultList } from "./AssetSelectorResultList";
import styled from "styled-components";
import {
  GQLResponse,
  SearchAdapter,
  ContentSummary,
  VideosLede,
  MediaOverrideConnection,
  AssetSelectorSearchOptions,
} from "@types";
import {
  useCallback,
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
} from "react";
import {
  useDefinedMessages,
  useFocusTrap,
  useMediaQuery,
  useUniqueId,
} from "@hooks";
import { ThemeProvider } from "@contexts";
import { Takeover, EditPage, Button, Select } from "@components";
import { Queries, Variables } from "@gql";
import { useQuery } from "@apollo/client";
import { SnowplowContext } from "@contexts";
import {
  ChevronDownIcon,
  GridIcon,
  BulletedListIcon,
  RichTextIcon,
} from "@condenast/gemini/icons";
import { FormattedMessage, useIntl } from "react-intl";

const SORT_OPTIONS = [
  "relevanceLatest",
  "relevanceAllTime",
  "lastModified",
  "lastPublished",
  "lastCreated",
];

const AssetSelectorContainer = styled.div<{ $isMobileView: boolean }>`
  display: grid;
  position: fixed;
  ${({ $isMobileView }) => {
    if ($isMobileView) {
      return `
        grid-template-areas:
        ". . . . . ."
        ". title search-bar toggle counter ."
        ". tab-list tab-list tab-list tab-list ."
        ". divider divider divider divider ."
        ". filter-list filter-list filter-list filter-list ."
        ". result-list result-list result-list result-list ."
        ". . pagination pagination . .";
        grid-template-columns: auto calc(var(--spacing-sm) * 10) 1fr auto auto auto;
        grid-template-rows: auto auto auto auto auto auto 1fr;
      `;
    } else {
      return `
          grid-template-areas:
          ". . . . . ."
          ". title search-bar toggle counter ."
          ". tab-list filter-list filter-list filter-list ."
          ". tab-list result-list result-list result-list ."
          ". . pagination pagination . .";
          grid-template-columns: auto calc(var(--spacing-sm) * 15) 1fr auto auto auto;
          grid-template-rows: auto auto auto 1fr;
      `;
    }
  }}
  gap: var(--spacing-sm);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: ${({ theme }) => theme.ElevationModal};
  background-color: ${({ theme }) => theme.Background};
  color: ${({ theme }) => theme.Color};
`;

const TypeSelectorWrapper = styled.div`
  grid-area: tab-list;
`;

const TypeSelectorAction = styled(Button)`
  grid-area: tab-list;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${({ theme }) => theme.FieldBackground};
  color: ${({ theme }) => theme.FieldColor}; ;
`;

const ContentLabel = styled.div`
  display: flex;

  svg {
    left: 0;
    margin-right: var(--spacing-xs);
  }
`;

const AssetResultsContainer = styled.div`
  grid-area: result-list;
  position: relative;
  overflow-y: auto;
`;

const SelectedAdapterFiltersContainer = styled.div<{ $isMobileView: boolean }>`
  display: flex;
  flex-wrap: no-wrap;
  align-items: flex-start;
  gap: var(--spacing-lg);
  gap: ${({ $isMobileView }) =>
    $isMobileView ? "var(--spacing-sm)" : "var(--spacing-lg)"};
  grid-area: filter-list;

  & ul {
    list-style: none;

    &:focus {
      outline: none;
    }
  }
`;

const Divider = styled.hr`
  grid-area: divider;
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.DividerColor};
  margin-bottom: var(--spacing-sm);
  margin: var(--spacing-xs) 0;
`;

const RightAlignedContainer = styled.div<{ $isMobileView?: boolean }>`
  display: flex;
  margin-left: auto;
  align-self: flex-start;
  flex: ${({ $isMobileView }) => ($isMobileView ? 1 : 0)};
`;

const Toggle = styled.div`
  display: flex;
  grid-area: toggle;
`;

const GridListButton = styled(Button)`
  margin-left: var(--spacing-sm);
`;

export const StyledSelect = styled(Select)<{ $isMobile?: boolean }>`
  display: ${({ $isMobile }) => ($isMobile ? "block" : "inline-block")};
  grid-template-columns: 100%;
  grid-gap: 0px;
  ul[role="listbox"] {
    min-width: max-content;
    transform: ${({ $isMobile }) =>
      $isMobile ? "none" : "translate(0px, 44px) !important"};
  }
  ul:not([role="listbox"]) {
    display: ${({ $isMobile }) => ($isMobile ? "block" : "none")};
    padding: var(--spacing-xs) 0;
  }
  min-width: max-content;
  width: 100%;
  padding: ${({ $isMobile }) => ($isMobile ? "0 0 var(--spacing-xxs) 0" : "0")};
`;

const MobileSelect = styled(Select)`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  ul[role="listbox"] {
    min-width: max-content;
    // 44px is the height of the select box
    transform: translate(0px, 44px) !important;
  }
  button {
    background: ${({ theme }) => theme.BorderlessActiveBackground};
  }
  svg.input-icon,
  svg.select-chevron {
    left: auto;
    display: none;
  }
  label {
    display: flex;
    gap: var(--spacing-xs);
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.Color};
  }
`;

export const AssetSelector = (props: {
  adapters: SearchAdapter[];
  onSubmit: (value: ContentSummary[]) => Promise<void>;
  onClose: () => unknown;
  initialSelections?: ContentSummary[];
  limitSelection?: number;
  searchResponse: GQLResponse<{
    search: {
      results: ContentSummary[];
      limit: number;
      offset: number;
      totalResults: number;
    };
  }>;
  cdnHost: string;
  submitting?: boolean;
  publishing?: boolean;
  model?: Record<string, unknown> | undefined;
  setValue?:
    | ((name: string, updatedMediaoverrides: MediaOverrideConnection) => void)
    | undefined;
  enableVideoMediaOverrides?: boolean;
  currentOrganizationID?: string;
  userID?: string;
  disableReturnFocus?: boolean;
}) => {
  const {
    adapters,
    onSubmit,
    onClose,
    initialSelections,
    limitSelection,
    searchResponse,
    cdnHost,
    submitting,
    publishing,
    model,
    setValue,
    disableReturnFocus,
    enableVideoMediaOverrides,
  } = props;

  const uid = useUniqueId();
  const intl = useIntl();
  const { translateFieldName } = useDefinedMessages();

  const mobileView = useMediaQuery("(max-width: 875px)");
  const [isTakeoverOpened, setIsTakeoverOpened] = useState(false);

  const [element, setElement] = useState<HTMLElement | null>(null);
  useFocusTrap(element, onClose, {
    disableReturnFocus: disableReturnFocus ?? false,
  });
  const [videoLede, setVideoLede] = useState<VideosLede>();
  const [videosLedeSelection, setVideosLedeSelection] =
    useState<ContentSummary>();

  const [selections, setSelections] = useState<ContentSummary[]>(
    initialSelections ?? []
  );
  const [assetselected, setAssetselected] = useState<boolean>(false);
  const clearSelections = useCallback(() => setSelections([]), [setSelections]);

  const resultCount = searchResponse.data?.search.totalResults || 0;
  const resultLimit = searchResponse.data?.search.limit || 0;
  const resultOffset = searchResponse.data?.search.offset || 0;
  let hasSimilarProduct;
  const resultsShowing = Math.min(resultOffset + resultLimit, resultCount) || 0;
  const { trackComponentEvent } = useContext(SnowplowContext);

  if (searchResponse.data?.search.results) {
    hasSimilarProduct =
      !!searchResponse.data?.search.results.find((item) =>
        item.metadata?.find(
          (metadata) =>
            metadata.type === "ContentSummaryOffer" &&
            metadata.hasSimilarProduct
        )
      ) || false;
  }
  let { data } = useQuery(Queries.GET_LAST_ASSET_SELECTOR_ADAPTER);
  let lastAdapter = data?.lastAssetSelectorAdapter?.props;

  const onSubmitAndClose = useCallback(async () => {
    await onSubmit(selections);
    onClose();
    trackComponentEvent("asset_selector", "button_click", "submit");
  }, [onClose, onSubmit, selections, trackComponentEvent]);

  const onSelect = useCallback(
    async (asset) => {
      const index = selections.findIndex((selection) => {
        return selection.id === asset.id;
      });
      if (index >= 0) {
        setSelections([
          ...selections.slice(0, index),
          ...selections.slice(index + 1),
        ]);
      } else if (limitSelection === 1 && enableVideoMediaOverrides) {
        setVideoLede({
          id: asset?.id,
          asset: {
            filename: asset.asset?.publicURL,
          },
          cneId: asset.cneId,
          feedGuid: asset?.feedGuid,
          embedUrl: asset?.embedUrl,
          transcriptUrl: asset?.transcriptUrl,
        });
        setVideosLedeSelection(asset);
        setAssetselected(true);
      } else if (limitSelection === 1) {
        await onSubmit([asset]);
        onClose();
        trackComponentEvent("asset_selector", "button_click", "submit");
      } else {
        setSelections([...selections, asset]);
      }
    },
    [
      selections,
      limitSelection,
      enableVideoMediaOverrides,
      onSubmit,
      onClose,
      trackComponentEvent,
    ]
  );

  const [selectedAdapter, setSelectedAdapter] = useState<SearchAdapter>(
    (lastAdapter && adapters.find((adapter) => adapter.id === lastAdapter)) ||
      adapters.find((adapter) => adapter.id === "all") ||
      adapters[0]
  );

  const [selectedFilters, setSelectedFilters] =
    useState<AssetSelectorSearchOptions>({});

  const selectedSort = selectedFilters.sort || "relevanceLatest";
  const [treatment, setTreatment] = useState<"slat" | "grid">("slat");

  useEffect(
    () => {
      selectedAdapter.onSearch(selectedFilters);
      Variables.lastAssetSelectorAdapter(selectedAdapter?.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAdapter, selectedFilters, lastAdapter]
  );

  useEffect(() => {
    if ("Intercom" in window) {
      Intercom("update", {
        hide_default_launcher: true,
      });
    }
    return () => {
      if ("Intercom" in window) {
        Intercom("update", {
          hide_default_launcher: false,
        });
      }
    };
  });

  const loadAssestSelector = () => setAssetselected(false);

  const assetUpdateToModel = async () => {
    if (videosLedeSelection) {
      await onSubmit([videosLedeSelection]);
    }
  };

  useLayoutEffect(() => {
    if (!mobileView) {
      setIsTakeoverOpened(false);
    }
  }, [mobileView]);

  return (
    <ThemeProvider theme="dark">
      <AssetSelectorContainer $isMobileView={mobileView} ref={setElement}>
        <AssetSelectorHeader
          onClose={onClose}
          count={resultCount}
          query={selectedFilters.query || ""}
          onQueryChange={(query: string) => {
            setSelectedFilters({ ...selectedFilters, query });
          }}
        />
        {isTakeoverOpened || !mobileView ? (
          <AssetSelectorAdapterList
            adapters={adapters}
            onAdapterChange={setSelectedAdapter}
            selectedAdapter={selectedAdapter}
            handleOnClose={setIsTakeoverOpened}
          />
        ) : (
          <>
            <TypeSelectorWrapper tabIndex={0} role="tablist">
              <TypeSelectorAction
                id={`typeSelectorAction-${uid}`}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setIsTakeoverOpened(!isTakeoverOpened);
                }}
                aria-label={"Open type selector takeover"}
              >
                <ContentLabel>{selectedAdapter.Label()}</ContentLabel>
                <ChevronDownIcon size="small" />
              </TypeSelectorAction>
            </TypeSelectorWrapper>
            <Divider />
          </>
        )}
        {selectedAdapter.Filters && (
          <SelectedAdapterFiltersContainer $isMobileView={mobileView}>
            {selectedAdapter.Filters({
              filters: selectedFilters,
              setFilters: setSelectedFilters,
              takeoverView: mobileView,
            })}
            <RightAlignedContainer $isMobileView={mobileView}>
              {!mobileView ? (
                <StyledSelect
                  id="SortBySelector"
                  onChange={(value: string | undefined) => {
                    if (value) {
                      setSelectedFilters({ ...selectedFilters, sort: value });
                    }
                  }}
                  options={SORT_OPTIONS.map((option) => {
                    return {
                      label: translateFieldName(option),
                      value: option,
                    };
                  })}
                  value={selectedSort}
                  placeholder={
                    <FormattedMessage
                      // eslint-disable-next-line formatjs/enforce-placeholders
                      defaultMessage="<md>**Sort by:**</md> {selected}"
                      values={{ selected: translateFieldName(selectedSort) }}
                    />
                  }
                  alwaysUsePlaceholder={true}
                />
              ) : (
                <MobileSelect
                  id="SortBySelector"
                  onChange={(value: string | undefined) => {
                    if (value) {
                      setSelectedFilters({ ...selectedFilters, sort: value });
                    }
                  }}
                  options={SORT_OPTIONS.map((option) => {
                    return {
                      label: translateFieldName(option),
                      value: option,
                    };
                  })}
                  value={selectedSort}
                  placeholder={
                    <>
                      <RichTextIcon size="small" />
                      <FormattedMessage defaultMessage="Sort" />
                    </>
                  }
                  alwaysUsePlaceholder={true}
                />
              )}
              <Toggle>
                <GridListButton
                  onClick={(evt) => {
                    evt.preventDefault();
                    setTreatment?.("grid");
                  }}
                  aria-pressed={treatment === "grid"}
                  aria-label={intl.formatMessage({
                    defaultMessage: "Grid View",
                    description:
                      "Label for toggling search results to grid view",
                  })}
                >
                  <GridIcon size="regular" />
                </GridListButton>
                <GridListButton
                  onClick={(evt) => {
                    evt.preventDefault();
                    setTreatment?.("slat");
                  }}
                  aria-pressed={treatment === "slat"}
                  aria-label={intl.formatMessage({
                    defaultMessage: "List View",
                    description:
                      "Label for toggling search results to grid view",
                  })}
                >
                  <BulletedListIcon size="regular" />
                </GridListButton>
              </Toggle>
            </RightAlignedContainer>
          </SelectedAdapterFiltersContainer>
        )}
        <AssetResultsContainer>
          <AssetSelectorResultList
            searchResponse={searchResponse}
            selections={selections}
            count={resultCount}
            resultsShowing={resultsShowing}
            onSelect={onSelect}
            cdnHost={cdnHost}
            treatment={treatment}
            hasSimilarProduct={hasSimilarProduct || false}
            query={selectedFilters.query || ""}
          />
        </AssetResultsContainer>
        {assetselected && (
          <EditPage
            setValue={setValue}
            cancelCneUpload={onClose}
            goBack={loadAssestSelector}
            model={model}
            selectedAssetId={videoLede?.id}
            videosLede={videoLede}
            assetUpdateToModel={assetUpdateToModel}
          />
        )}
        {hasSimilarProduct ? (
          <Takeover.PublishBar
            onPublish={onSubmitAndClose}
            selectionCount={selections.length}
            limit={limitSelection}
            clearSelections={clearSelections}
            publishing={publishing}
            action="publish"
          />
        ) : (
          (!limitSelection || limitSelection > 1) && (
            <Takeover.SubmitBar
              onSubmit={onSubmitAndClose}
              selectionCount={selections.length}
              limit={limitSelection}
              clearSelections={clearSelections}
              submitting={submitting}
              action="submit"
            />
          )
        )}
      </AssetSelectorContainer>
    </ThemeProvider>
  );
};

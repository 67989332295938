import { Button } from "@components";
import { useCallback, useState, useRef } from "react";
import { Popper } from "react-popper";
import { CustomDateMenu } from "./CustomDateMenu";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@condenast/gemini/icons";
import styled from "styled-components";
import { useClickOutside } from "@hooks";
import { addDays, endOfDay } from "date-fns";

const Calender = styled(CalendarIcon)`
  align-items: center;
  color: var(--color-black);
  margin-right: var(----spacing-xs);
`;

const CustomButton = styled(Button)`
  color: var(--color-black);
  justify-content: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
`;

const Wrapper = styled.div`
  position: relative;
  display: grid;
  gap: var(--spacing-xxs);
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-gray-5);
  grid-template-columns: 1fr 8fr 1fr;
  padding-top: var(--spacing-xs);
`;

const PrevNextButton = styled(Button)`
  & svg {
    left: unset;
  }
`;

const LeftIcon = styled(ChevronLeftIcon)`
  justify-content: center;
  color: var(--color-black);
`;

const RightIcon = styled(ChevronRightIcon)`
  justify-content: center;
  color: var(--color-black);
`;

const PopperContent = styled.div`
  position: absolute;
  left: 25%;
  z-index: 1;
  top: 100%;
  @media (max-width: 650px) {
    position: absolute;
    left: 20%;
  }
`;

const DatePopper = styled(Popper)`
  position: absolute;
  top: 100%;
  left: 50%;
`;

export function DateFilter(props: {
  handleDateChange: (start: Date, end: Date, label: string) => void;
  preSelectedDates?: { start?: string; end?: string; label?: string };
}) {
  const { handleDateChange, preSelectedDates } = props;

  const [showPopper, setShowPopper] = useState<boolean>(false);
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null
  );

  const [startDate, setStartDate] = useState(
    preSelectedDates && preSelectedDates.start
      ? new Date(preSelectedDates.start)
      : new Date()
  );

  const [endDate, setEndDate] = useState(
    preSelectedDates && preSelectedDates.end
      ? new Date(preSelectedDates.end)
      : new Date()
  );

  const [label, setLabel] = useState<string>(
    (preSelectedDates && preSelectedDates.label) || startDate.toDateString()
  );

  const [date, setDate] = useState(
    preSelectedDates && preSelectedDates.start
      ? new Date(preSelectedDates.start)
      : new Date()
  );
  const popperButtonRef = useRef(null);

  const close = useCallback(() => {
    setShowPopper(false);
  }, [setShowPopper]);

  useClickOutside(wrapperElement, close);

  const changeDay = useCallback(
    (days: number) => {
      setDate(() => {
        const newDate: Date = addDays(startDate, days);
        setLabel(newDate.toDateString());
        setStartDate(newDate);
        const newEndDate = endOfDay(newDate);
        setEndDate(newEndDate);
        handleDateChange(newDate, newEndDate, "leftRightArrow");
        return newDate;
      });
    },
    [setLabel, date, setStartDate, handleDateChange]
  );

  return (
    <>
      <Wrapper
        style={
          label == startDate.toDateString()
            ? {}
            : { gridTemplateColumns: "10fr" }
        }
        ref={setWrapperElement}
      >
        {label == startDate.toDateString() && (
          <PrevNextButton treatment="borderless" onClick={() => changeDay(-1)}>
            <LeftIcon size={"regular"} />
          </PrevNextButton>
        )}
        <CustomButton
          treatment="text"
          onClick={() => setShowPopper(!showPopper)}
          ref={popperButtonRef}
        >
          <Calender size="regular" />
          {label}
        </CustomButton>
        {label == startDate.toDateString() && (
          <PrevNextButton treatment="borderless" onClick={() => changeDay(1)}>
            <RightIcon size={"regular"} />
          </PrevNextButton>
        )}
        {showPopper && (
          <DatePopper>
            {({ ref }) => (
              <PopperContent ref={ref}>
                <CustomDateMenu
                  handleDateChange={handleDateChange}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setShowPopper={setShowPopper}
                  label={label}
                  setLabel={setLabel}
                />
              </PopperContent>
            )}
          </DatePopper>
        )}
      </Wrapper>
    </>
  );
}

DateFilter.displayName = "DateFilter";

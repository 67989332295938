import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { App } from "@components";
import { config } from "@lib";
import snowplow from "@condenast/cn-snowplow-web";
import { initializeSnowplowTracker } from "@lib";

typeof window !== "undefined" &&
  window.location.search.indexOf("no_snowplow") < 0 &&
  snowplow.injectScript();
initializeSnowplowTracker({ collectorURL: config.snowplowEnv });

if (config.environment === "production") {
  Sentry.init({
    dsn: "https://9287585a57bd4b6f9dc604eaefddaf6f@o91515.ingest.sentry.io/5316938",
    integrations: [
      new BrowserTracing({
        beforeNavigate: (context) => {
          return {
            ...context,
            name: location.pathname
              .replace(/\/[a-zA-Z0-9]+\//, "/<brand>/")
              .replace(/\/[a-zA-Z]+\//, "/<contenttype>/")
              .replace(/\/[a-f\d]{24}\/?/, "/<id>/")
              .replace(/\/[a-zA-Z]+\//, "/<contenttype>/")
              .replace(/\/[a-f\d]{24}/, "/<id>"),
          };
        },
      }),
    ],
    // sample 40%
    tracesSampleRate: 0.4,
  });
}

ReactDOM.render(<App />, document.getElementById("application"));

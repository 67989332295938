import {
  DuplicateToggleConfigurationError,
  EmptyConfigurationError,
  MissingToggleConfigurationError,
  NoCategoriesError,
  SelectedCategoryError,
} from "./PageLayout.errors";
import { PageLayoutConfiguration } from "@lib";

import type {
  GetBrand_brandConfiguration_contentTypes,
  Trie,
  CategoryTaxonomyFields_categories,
  ToggleMap,
  CategoryToggleMapping,
  ToggleLevel,
} from "@types";

const TRIE_RESULT_KEY = Symbol("category");

export function createTrie(
  hierarchy: ToggleLevel[],
  configuredLayoutCategories: [string, ToggleMap][]
) {
  let trie: Trie = {};
  for (let [category, currentConfig] of configuredLayoutCategories) {
    let trieKeyArray = hierarchy.map((level) => currentConfig[level]);
    setInTrie(trie, trieKeyArray, category);
  }

  return trie;
}

function setInTrie(
  trie: Trie,
  [nextKey, ...restKeys]: string[],
  value: string
) {
  let nextKeyNoNull = nextKey === null ? "--noDisplay" : nextKey;
  if (nextKeyNoNull !== undefined) {
    if (!trie[nextKeyNoNull]) {
      trie[nextKeyNoNull] = {};
    }

    try {
      setInTrie(trie[nextKeyNoNull] as Trie, restKeys, value);
    } catch (duplicateError) {
      if (duplicateError instanceof DuplicateToggleConfigurationError) {
        duplicateError.addPathSegment(nextKeyNoNull);
      }
      throw duplicateError;
    }
  } else {
    if (trie[TRIE_RESULT_KEY]) {
      //TODO error handling
    }
    trie[TRIE_RESULT_KEY] = value;
  }
}

export function getInTrie(
  trie: Trie,
  [nextKey, ...restKeys]: string[]
): string {
  let nextKeyNoNull = nextKey === null ? "--noDisplay" : nextKey;
  if (nextKeyNoNull !== undefined) {
    if (!trie[nextKeyNoNull]) {
      let defaultKey = Object.keys(trie)[0];
      return getInTrie(trie[defaultKey] as Trie, restKeys);
    }
    return getInTrie(trie[nextKeyNoNull] as Trie, restKeys);
  }
  return trie[TRIE_RESULT_KEY] as string;
}

function getHierarchyPath(category: CategoryTaxonomyFields_categories) {
  return category.hierarchy
    .map((hierarchyCategory) => {
      return hierarchyCategory.slug;
    })
    .reverse()
    .join("/");
}

export const getCurrentCategory = (
  versoSettings: CategoryTaxonomyFields_categories[],
  articleConfig: GetBrand_brandConfiguration_contentTypes,
  categoryToggleMapping: CategoryToggleMapping
) => {
  if (!versoSettings) {
    return;
  }

  const category = versoSettings.find((setting) => {
    let hierarchyPath = getHierarchyPath(setting);
    return hierarchyPath.includes("article-settings/ContentHeader/variation");
  });

  if (!category) {
    return;
  }

  if (!articleConfig.versoHeaderLayouts?.includes(category.slug)) {
    throw new SelectedCategoryError(category);
  }

  if (!categoryToggleMapping[category.slug]) {
    throw new MissingToggleConfigurationError(category);
  }

  return category;
};

export const getLayoutCategories = (
  articleConfig: GetBrand_brandConfiguration_contentTypes,
  categoryToggleMapping: CategoryToggleMapping
) => {
  if (!articleConfig.versoHeaderLayouts?.length) {
    throw new EmptyConfigurationError();
  }

  let configuredLayoutCategories = Object.entries(categoryToggleMapping).filter(
    ([slug]) => {
      return articleConfig.versoHeaderLayouts?.includes(slug);
    }
  );

  if (!configuredLayoutCategories.length) {
    throw new NoCategoriesError();
  }

  return configuredLayoutCategories;
};

export const getCategoryFromToggles = (
  toggles: ToggleMap,
  toggleDecisionTree: Trie
) => {
  let togglesArray = PageLayoutConfiguration.toggleLevelHierarchy.map(
    (level) => toggles[level]
  );
  return getInTrie(toggleDecisionTree, togglesArray);
};

export const getUpdatedVersoSettings = (
  versoSettings: CategoryTaxonomyFields_categories[],
  category: CategoryTaxonomyFields_categories
) => {
  function isContentHeader(setting: CategoryTaxonomyFields_categories) {
    let hierarchyPath = getHierarchyPath(setting);
    return hierarchyPath.includes(
      "verso-settings/article-settings/ContentHeader/variation"
    );
  }

  if (!versoSettings) {
    return [category];
  }
  let settingsToKeep = versoSettings.filter((setting) => {
    return !isContentHeader(setting);
  });
  return [category, ...settingsToKeep];
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import { DatePicker, Field, Link, Select, TimePicker } from "@components";
import { useDefinedMessages } from "@hooks";

const ScheduleSelect = styled(Select)`
  max-width: 22rem;
`;

const StyledField = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "input";
`;

const DateField = styled(StyledField)`
  grid-template-columns: 12.5rem;
`;

const TimeField = styled(StyledField)`
  grid-template-columns: 7.5rem;
`;

const DateOrTimeDisplay = styled.span`
  grid-area: input;
  padding: var(--spacing-xs) 0;
  font: ${(props) => props.theme.FontBody};
`;

const StyledDatePicker = styled(DatePicker)`
  grid-area: input;
`;

const DateTimeFields = styled.div`
  display: flex;
  gap: var(--spacing-sm);
`;

export const ScheduleDate = (props: {
  pubDate: string;
  scheduledPubDates: string[];
  onChange(value: string): void;
  idPrefix: string;
  copilotCode: string;
}) => {
  const { pubDate, scheduledPubDates, idPrefix, onChange, copilotCode } = props;
  const intl = useIntl();
  const contextualHelpKey = "timeZoneHelper";
  const { translateContextualHelp } = useDefinedMessages();

  const [scheduledDate, setScheduledDate] = useState<string | undefined>(
    pubDate || scheduledPubDates[0] || "now"
  );

  const schedulingOptions = useMemo(() => {
    intl.formatDate(pubDate, {
      day: "numeric",
      month: "numeric",
      year: "2-digit",
    });
    const options = [
      {
        value: "now",
        label: intl.formatMessage({
          defaultMessage: "Now",
          description: "Option to schedule publishing for now in publish modal",
        }),
      },
    ];
    const now = new Date();
    if (pubDate && new Date(pubDate) <= now) {
      options.push({
        value: pubDate,
        label: intl.formatMessage(
          {
            defaultMessage: "Original publish date - {date}",
            description:
              "Option to schedule publishing for the current published date in publish modal",
          },
          {
            date: intl.formatDate(new Date(pubDate), {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            }),
          }
        ),
      });
    }

    options.push(
      ...scheduledPubDates
        .filter((scheduledDate) => new Date(scheduledDate) > now)
        .map((scheduledDate) => ({
          value: scheduledDate,
          label: intl.formatMessage(
            {
              defaultMessage: "Upcoming - {date} @ {time}",
              description:
                "Option to schedule publishing for an existing scheduled date",
            },
            {
              date: intl.formatDate(new Date(scheduledDate), {
                day: "numeric",
                month: "numeric",
                year: "2-digit",
              }),
              time: intl.formatDate(new Date(scheduledDate), {
                hour: "numeric",
                minute: "numeric",
                timeZoneName: "short",
              }),
            }
          ),
        })),
      {
        value: "",
        label: intl.formatMessage({
          defaultMessage: "Create new",
          description:
            "Option to schedule publishing for a new time in publish modal",
        }),
      }
    );
    return options;
  }, [pubDate, scheduledPubDates, intl]);

  const [newDate, setNewDate] = useState(
    pubDate ? new Date(pubDate) : new Date()
  );
  const localTimezone = useMemo(
    () =>
      intl
        .formatTimeToParts(new Date(), {
          timeZoneName: "short",
        })
        .find(({ type }) => type === "timeZoneName")?.value,
    [intl]
  );

  useEffect(() => {
    if (!pubDate && scheduledDate) {
      onChange(
        scheduledDate === "now" ? new Date().toISOString() : scheduledDate
      );
    }
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pubDate, onChange]);

  const onScheduledSelect = useCallback(
    (value: string | undefined) => {
      if (value != null) {
        setScheduledDate(value);
        if (value === "now") {
          onChange(new Date().toISOString());
        } else if (value === "") {
          setNewDate(new Date());
          onChange(new Date().toISOString());
        } else {
          onChange(value);
        }
      }
    },
    [onChange, setScheduledDate]
  );

  const onNewDateSelect = useCallback(
    (value: Date) => {
      const newDateAtCurrentTime = new Date(value);
      setNewDate(newDateAtCurrentTime);
      onChange(newDateAtCurrentTime.toISOString());
    },
    [setNewDate, onChange]
  );

  const onTimeChange = useCallback(
    (value: Date) => {
      setNewDate(value);
      onChange(value.toISOString());
    },
    [setNewDate, onChange]
  );
  return (
    <div>
      <ScheduleSelect
        value={scheduledDate}
        multiple={false}
        options={schedulingOptions}
        label={intl.formatMessage({
          defaultMessage: "Schedule",
          description: "Label for scheduling option in publish modal",
        })}
        id={`${idPrefix}-scheduleOptions`}
        onChange={onScheduledSelect}
      />
      <DateTimeFields data-managed-focus="true">
        <DateField
          label={intl.formatMessage({
            defaultMessage: "Date",
            description: "Label for date selection in publish modal",
          })}
          id={`${idPrefix}-publishDate`}
        >
          {scheduledDate ? (
            <DateOrTimeDisplay>
              {intl.formatDate(
                scheduledDate === "now"
                  ? new Date().toISOString()
                  : scheduledDate,
                {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                }
              )}
            </DateOrTimeDisplay>
          ) : (
            <div data-managed-focus="true">
              <StyledDatePicker
                id={`${idPrefix}-publishDate`}
                date={newDate}
                onChange={onNewDateSelect}
                locale={intl.locale}
                aria-label={intl.formatMessage({
                  defaultMessage: "Date",
                  description: "Label for date selection in publish modal",
                })}
              />
            </div>
          )}
        </DateField>
        <TimeField
          label={intl.formatMessage({
            defaultMessage: "Time",
            description: "Label for time selection in publish modal",
          })}
          id={`${idPrefix}-publishTime`}
        >
          {scheduledDate ? (
            <DateOrTimeDisplay>
              {intl.formatDate(
                scheduledDate === "now"
                  ? new Date().toISOString()
                  : scheduledDate,
                {
                  minute: "numeric",
                  hour: "numeric",
                }
              )}
            </DateOrTimeDisplay>
          ) : (
            <TimePicker
              id={`${idPrefix}-timePicker`}
              date={newDate}
              onChange={onTimeChange}
              aria-label={intl.formatMessage({
                defaultMessage: "Time",
                description: "Label for time selection in publish modal",
              })}
            />
          )}
        </TimeField>
        <StyledField
          label={intl.formatMessage({
            defaultMessage: "Zone",
            description: "Label for timezone in publish modal",
          })}
          id={`${idPrefix}-publishTimezone`}
          help={
            <>
              {contextualHelpKey
                ? translateContextualHelp(contextualHelpKey)
                : undefined}{" "}
              <Link to={`/${copilotCode}/users/profile`} target="_blank">
                <FormattedMessage defaultMessage="profile" />
              </Link>
            </>
          }
        >
          <DateOrTimeDisplay>{localTimezone}</DateOrTimeDisplay>
        </StyledField>
      </DateTimeFields>
    </div>
  );
};

import { gql } from "@apollo/client";
import * as Fragments from "./fragments";

export const GET_TOAST_PROPS = gql`
  query GetToastProps {
    toast @client {
      props
    }
  }
`;

export const GET_TOAST_TIMER = gql`
  query GetToastTimer {
    toast @client {
      timer
    }
  }
`;

export const GET_LAST_ASSET_SELECTOR_ADAPTER = gql`
  query GetLastAssetSelectorAdapter {
    lastAssetSelectorAdapter @client {
      props
    }
  }
`;

export const GET_BRAND = gql`
  ${Fragments.PublishConfig}
  ${Fragments.PreviewConfig}
  query GetBrand($organizationId: String!) {
    brandConfiguration(organizationId: $organizationId) {
      code
      name
      language {
        default
        alternate
        available
      }
      hostnames {
        consumer
        preview
      }
      seo {
        semRushLocale
        rulesets {
          type
          rules
        }
      }
      commerce {
        offerAutofillDomains
      }
      contentTypes {
        label
        value
        discoverable {
          quickSearch
          advancedSearch
          createMenu
        }
        preview {
          ...PreviewConfigFields
        }
        publish {
          ...PublishConfigFields
        }
        subtypes {
          type
          preview {
            ...PreviewConfigFields
          }
        }
        versoHeaderLayouts
        defaultVersoHeaderLayout
      }
    }
  }
`;

export const GET_BUNDLE = gql`
  query GetBundle($organizationId: String!, $id: String!) {
    bundle(organizationId: $organizationId, id: $id) {
      id
      revisionInfo {
        version
        authorName
        createdAt
      }
      bundleType
      containers {
        id
        curationContainerType
        type
        hed
      }
      publishInfo {
        version
      }
    }
  }
`;

export const GET_USERS = gql`
  ${Fragments.ORGANIZATIONS}
  query GetUsers($organizationId: String) {
    users(organizationId: $organizationId, deflate: ["Organization"]) {
      id
      ...Organizations
      createdAt
      firstName
      lastName
      email
      role
      status
      email
    }
  }
`;

export const GET_CURRENT_USER = gql`
  ${Fragments.ORGANIZATIONS}
  query GetCurrentUser {
    currentUser {
      id
      ...Organizations
      createdAt
      email
      firstName
      lastLogin
      lastName
      locale
      loginProvider
      modifiedAt
      role
      userName
      slackUserId
    }
  }
`;

export const GET_USER_BY_ID = gql`
  ${Fragments.ORGANIZATIONS}
  query GetUserById($userId: ID!, $organizationId: String) {
    user(userId: $userId, organizationId: $organizationId) {
      id
      status
      ...Organizations
      email
      firstName
      lastName
      loginProvider
      role
      userName
    }
  }
`;

export const GET_PERMISSIONS_MATRIX = gql`
  query GetPermissionsMatrix($organizationId: String) {
    permissions(organizationId: $organizationId) {
      role
      contentPermissions {
        collectionName
        permissions {
          create
          publish
          read
          search
          update
        }
      }
      userPermissions {
        role
        permissions {
          create
          publish
          read
          search
          update
        }
      }
    }
  }
`;

export const GET_CONFIGS = gql`
  query GetConfigs($organizationId: String!, $userId: String!) {
    configs(organizationId: $organizationId, userId: $userId) {
      id
      alternateLanguage
      aspectRatios
      bulkProductUpload
      bundleLayoutOptions
      copilotUrl
      encoreUrl
      copilotWSHost
      galleryResyncBanner
      enableGlobalDrafts
      floatingActionBar
      pageLayoutLivePreview
      pageLayoutUI
      presets
      prideLogo
      ckeditorLinkAutogen
      linkAutogenConfig {
        contentTypes
        fields {
          contentType
          fields
        }
      }
      mapboxPublicToken
      enableCommerceProduct
      commerceProductUrl
      enableSimilarProductsSearch
      enableSuggestedTags
      enableSyndication
      excludeSearchFilters
      enableTagnificent
      searchFilterOptions {
        statusOptions
        dateRangeOptions
      }
      search {
        categories
      }
      v2Activities
      v2Articles
      v2AudioFiles
      v2Bars
      v2Brands
      v2Businesses
      v2Bundles
      v2Cartoons
      v2Clips
      v2Contributors
      v2Cookbooks
      v2CuratedLists
      v2CuratedSearches
      v2Events
      v2ExternalLinks
      v2ExternalReferences
      v2Galleries
      v2Getty
      v2GettyVideo
      v2Hotels
      v2InfoPages
      v2LiveStories
      v2Menus
      v2MusicAlbums
      v2MusicGroups
      v2MusicRecordings
      v2MusicReleases
      v2NativeAdvertisers
      v2NativeArticles
      v2NativeGalleries
      v2NativeRecipes
      v2NativeVideos
      v2People
      v2Photos
      v2Products
      v2Recipes
      v2Redirects
      v2Restaurants
      v2Reviews
      v2Ships
      v2Shops
      v2SkiResorts
      v2Spas
      v2TagPages
      v2Taxonomies
      v2Venues
      v2RcaLists
      v2Cities
      enableMediaOverridesV2
      # Start of v2 bundle pages
      v2VersoChannel
      # Start of v2 non content type pages
      v2SearchPage
      storyHeader
      ckeditorAccordion
    }
  }
`;

export const GET_SELECT_OPTIONS = gql`
  query GetSelectOptions(
    $organizationId: String!
    $userId: String!
    $configurationPath: String!
  ) {
    configs(organizationId: $organizationId, userId: $userId) {
      id
      selectOptions(configurationPath: $configurationPath) {
        options
        defaultValue
      }
    }
  }
`;

export const GET_RICH_TEXT_CONFIG = gql`
  query GetRichTextConfig(
    $organizationId: String!
    $userId: String!
    $configurationPath: String!
  ) {
    configs(organizationId: $organizationId, userId: $userId) {
      id
      richText(configurationPath: $configurationPath) {
        build
        include
        plugins {
          alignment {
            options
          }
          clip {
            styles
          }
          copilotEmbed {
            styles
          }
          gallery {
            styles
            displays
          }
          paragraphDecorations
          photo {
            styles
            enableLinking
          }
          product {
            styles
          }
          pullquote {
            styles
            enableCredit
          }
          bodyStyle {
            showHelperText
          }
        }
        urlExpansions {
          pattern
          type
          width
          height
        }
      }
    }
  }
`;

export const GET_ASSET_SELECTOR_CONFIG = gql`
  query GetAssetSelectorConfig(
    $organizationId: String!
    $userId: String!
    $configurationPath: String!
  ) {
    configs(organizationId: $organizationId, userId: $userId) {
      id
      assetSelectorConfig(configurationPath: $configurationPath) {
        defaultType
        contentTypes
        externalTypes
        limitSelection
      }
    }
  }
`;

export const GET_FORM = gql`
  ${Fragments.ActivityFields}
  ${Fragments.AdvertiserFields}
  ${Fragments.ArticleFields}
  ${Fragments.AssetSelectorConfigurationFields}
  ${Fragments.AudioFileFields}
  ${Fragments.BrandFields}
  ${Fragments.BundleFields}
  ${Fragments.BusinessFields}
  ${Fragments.CartoonFields}
  ${Fragments.CKEditorLinkAutogenConfigurationFields}
  ${Fragments.ClipFields}
  ${Fragments.ContributorFields}
  ${Fragments.CookbookFields}
  ${Fragments.CulturalEventFields}
  ${Fragments.CuratedListFields}
  ${Fragments.CuratedSearchFields}
  ${Fragments.ExternalLinkFields}
  ${Fragments.ExternalReferenceFields}
  ${Fragments.GalleryFields}
  ${Fragments.InfoPageFields}
  ${Fragments.LiveStoryFields}
  ${Fragments.LivestoryupdateFields}
  ${Fragments.MusicAlbumFields}
  ${Fragments.MusicReleaseFields}
  ${Fragments.MusicReviewFields}
  ${Fragments.MusicGroupFields}
  ${Fragments.MusicRecordingFields}
  ${Fragments.PhotoFields}
  ${Fragments.ProductFields}
  ${Fragments.RecipeFields}
  ${Fragments.ProductReviewFields}
  ${Fragments.RecipeFields}
  ${Fragments.RedirectFields}
  ${Fragments.RichTextConfigurationFields}
  ${Fragments.TagpageFields}
  ${Fragments.TaxonomyFields}
  ${Fragments.VenueFields}
  ${Fragments.PersonFields}
  ${Fragments.RcalistFields}
  ${Fragments.EventFields}
  ${Fragments.CityFields}
  ${Fragments.PublishConfig}
  ${Fragments.PreviewConfig}
  query FormFor(
    $organizationId: String!
    $contentType: String!
    $id: ID!
    $revision: Int
  ) {
    form(organizationId: $organizationId, contentType: $contentType) {
      children
      controls {
        __typename
        id
        children
        ... on FieldsetFormControl {
          name
          description
        }
        ... on FieldsectionFormControl {
          name
          description
          style
        }
        ... on MdEditorFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          placeholderKey
          characterCount
          wordCount
          afmConfig
          disabled
          contextualHelp
          autogeneratePath
          autogenerateConfig
          richTextConfiguration {
            ...RichTextConfiguration
          }
          assetSelectorConfiguration {
            ...AssetSelectorConfiguration
          }
          ckeditorLinkAutogenConfiguration {
            ...CKEditorLinkAutogenConfiguration
          }
          enableMarkdown
        }
        ... on ArticleEditorFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          placeholderKey
          afmConfig
          contextualHelp
          autogeneratePath
          richTextConfiguration {
            ...RichTextConfiguration
          }
          assetSelectorConfiguration {
            ...AssetSelectorConfiguration
          }
          ckeditorLinkAutogenConfiguration {
            ...CKEditorLinkAutogenConfiguration
          }
          enablePrismXML
          enableMarkdown
        }
        ... on TextFieldFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          labelKey
          placeholderKey
          characterCount
          wordCount
          maxCount
          disabled
          contextualHelpKey
          autogeneratePath
          autogenerateConfig
        }
        ... on TextAreaFormControl {
          name
          label {
            defaultMessage
            description
          }
          placeholderKey
          characterCount
          wordCount
          maxCount
          disabled
          contextualHelpKey
          autogeneratePath
        }
        ... on TimezoneFormControl {
          contextualHelpKey
        }
        ... on GeoFieldFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
        }
        ... on MultiSelectFormControl {
          name
          label {
            defaultMessage
            description
          }
          labelKey
          noLabel
          adapterType
          strategy
          selectionLimit
        }
        ... on CategoryOptionsFormControl {
          hierarchy
        }
        ... on IfModelValueEqualsFormControl {
          path
          value
        }
        ... on CurationTypeSelectFormControl {
          name
          options {
            options
            defaultValue
          }
        }
        ... on CategorySelectorFormControl {
          name
          label {
            defaultMessage
            description
          }
          labelKey
          noLabel
          rootSlug
          notHierarchies
        }
        ... on TagSearchFormControl {
          name
          label {
            defaultMessage
            description
          }
          multiple
          noLabel
          optionsPath
          rootSlug
          notHierarchies
          options {
            options
          }
        }
        ... on SelectFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          optionsPath
          defaultValuePath
          hidePrompt
          disabled
          contextualHelpKey
          multiple
          options {
            options
            defaultValue
            disabledOptions
          }
          apiConfigPath
        }
        ... on ImageEditorFormControl {
          configuredAspectRatios
        }
        ... on ContentTypeSelectFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          optionsPath
          defaultValuePath
          hidePrompt
          disabled
          contextualHelpKey
          multiple
          options {
            options
            defaultValue
          }
        }
        ... on ColumnMultiSelectFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          customLabelPath
          optionsPath
        }
        ... on NumberFieldFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          disabled
          placeholder
          decimals
        }
        ... on CheckboxFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          disabled
        }
        ... on ThumbnailFormControl {
          name
          label {
            defaultMessage
            description
          }
          labelKey
          noLabel
          autogeneratePath
          treatment
          assetSelectorConfigurationPath
          assetSelectorConfiguration {
            ...AssetSelectorConfiguration
          }
        }
        ... on CheckboxGroupFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          optionsPath
        }
        ... on CardListFormControl {
          name
          label {
            defaultMessage
            description
          }
          labelKey
          noLabel
          disableSorting
          limit
          itemModel
          treatment
          assetSelectorConfigurationPath
          assetSelectorConfiguration {
            ...AssetSelectorConfiguration
          }
        }
        ... on CollectionWithCreateFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          collectionType
        }
        ... on ReplicatorFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          customModel
          titleProperty
          treatment
          defaultItems
          sortable
          defaultCurrency
        }
        ... on ColumnFormControl {
          treatment
        }
        ... on TagSelectorFormControl {
          name
          label {
            defaultMessage
            description
          }
          labelKey
          noLabel
          multiple
        }
        ... on CategoryAutocompleteFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
        }
        ... on TagsFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          suggestMethod
          disabled
          customLabel
        }
        ... on DatePickerFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          disabled
          timeZonePath
        }
        ... on TimePickerFormControl {
          name
          label {
            defaultMessage
            description
          }
          labelKey
          noLabel
          timeZonePath
        }
        ... on ReadOnlyFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
        }
        ... on RadioGroupFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          optionsPath
          disabledOptionsPath
          options {
            options
            defaultValue
            disabledOptions
          }
        }
        ... on TranscriptUploaderFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
        }
        ... on DurationFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          maxDuration
        }
        ... on EditMediaHeaderFormControl {
          children
        }
        ... on CollaboratorSelectorFormControl {
          name
          children
        }
        ... on MemberSharingFormControl {
          disabled
        }
        ... on ToggleFormControl {
          name
          label {
            defaultMessage
            description
          }
          message
          noLabel
          disabled
        }
        ... on ContextualInfoFormControl {
          name
          content {
            defaultMessage
            description
          }
          toastType
        }
        ... on SortableCuratedListItemsFormControl {
          assetSelectorConfigurationPath
          assetSelectorConfiguration {
            ...AssetSelectorConfiguration
          }
          hedRichTextConfiguration {
            ...RichTextConfiguration
          }
          dekRichTextConfiguration {
            ...RichTextConfiguration
          }
        }
        ... on SortableGalleryFormControl {
          showSlideBody
          assetSelectorConfigurationPath
          assetSelectorConfiguration {
            ...AssetSelectorConfiguration
          }
          titleRichTextConfiguration {
            ...RichTextConfiguration
          }
          captionRichTextConfiguration {
            ...RichTextConfiguration
          }
          slideBodyRichTextConfiguration {
            ...RichTextConfiguration
          }
        }
        ... on PublishDataFormControl {
          autogenerateConfig
          allowUnicodeLetters
          allowUppercase
          managePublishURIs
          contentType
        }
        ... on TagPublishDataFormControl {
          autogenerateConfig
          allowUnicodeLetters
          allowUppercase
          managePublishURIs
        }
        ... on ContributorFormControl {
          contributorTypeOptions(contentType: $contentType) {
            options
            defaultValue
          }
        }
        ... on ItemReviewedFormControl {
          children
          assetSelectorConfigurationPath
          assetSelectorConfiguration {
            ...AssetSelectorConfiguration
          }
          displayLabel
          ratingStep
          maximumRating
        }
        ... on RecipeIngredientsFormControl {
          ingredientRichTextConfiguration {
            ...RichTextConfiguration
          }
          ingredientGroupRichTextConfiguration {
            ...RichTextConfiguration
          }
          ingredientOptions
        }
        ... on RecipePreparationFormControl {
          preparationGroupRichTextConfiguration {
            ...RichTextConfiguration
          }
          preparationStepRichTextConfiguration {
            ...RichTextConfiguration
          }
          preparationStepAssetSelectorConfiguration {
            ...AssetSelectorConfiguration
          }
          showMicroSteps
        }
        ... on NativeRecipePreparationFormControl {
          preparationGroupRichTextConfiguration {
            ...RichTextConfiguration
          }
          preparationStepRichTextConfiguration {
            ...RichTextConfiguration
          }
          showMicroSteps
        }
        ... on OfferAutofillFormControl {
          name
        }
        ... on OfferPriceFormControl {
          countryOptions {
            options
            defaultValue
          }
          currencyOptions {
            options
            defaultValue
          }
        }
        ... on SEOFormControl {
          showCategoryAndKeywordFields
          hedAutogenValue
          dekAutogenValue
        }
        ... on ActivitySubtypeSelectFormControl {
          subtypeOptions {
            subtype
            secondaryTypeOptions {
              secondaryType
              features
            }
            features
          }
        }
        ... on TabListFormControl {
          children
        }
        ... on TabFormControl {
          name
        }
        ... on BundleContainersFormControl {
          configs {
            containers {
              ... on CoreBundleContainerConfig {
                value
                label
              }
              ... on LegacyBundleContainerConfig {
                value
                layoutOptions
                curationType
                assetTypeList
                itemLimit
                label
                defaultType
              }
            }
            bundleTypeConfigs {
              publish {
                ...PublishConfigFields
              }
              preview {
                ...PreviewConfigFields
              }
              containers
              type
            }
            contentSourceOptions {
              options
              defaultValue
              disabledOptions
            }
            logicalOperatorOptions {
              options
              defaultValue
              disabledOptions
            }
            contentTypeOptions {
              options
              defaultValue
              disabledOptions
            }
            languageOptions {
              options
              defaultValue
              disabledOptions
            }
            hedRichTextConfiguration {
              ...RichTextConfiguration
            }
            dekRichTextConfiguration {
              ...RichTextConfiguration
            }
          }
        }
      }
    }
    content(
      organizationId: $organizationId
      contentType: $contentType
      id: $id
      revision: $revision
    ) {
      __typename
      id
      ... on Activity {
        ...ActivityFields
      }
      ... on Advertiser {
        ...AdvertiserFields
      }
      ... on Article {
        ...ArticleFields
      }
      ... on AudioFile {
        ...AudioFileFields
      }
      ... on Brand {
        ...BrandFields
      }
      ... on Bundle {
        ...BundleFields
      }
      ... on Business {
        ...BusinessFields
      }
      ... on Cartoon {
        ...CartoonFields
      }
      ... on City {
        ...CityFields
      }
      ... on Clip {
        ...ClipFields
      }
      ... on Cookbook {
        ...CookbookFields
      }
      ... on Contributor {
        ...ContributorFields
      }
      ... on CulturalEvent {
        ...CulturalEventFields
      }
      ... on CuratedList {
        ...CuratedListFields
      }
      ... on CuratedSearch {
        ...CuratedSearchFields
      }
      ... on Event {
        ...EventFields
      }
      ... on ExternalLink {
        ...ExternalLinkFields
      }
      ... on External {
        ...ExternalReferenceFields
      }
      ... on Gallery {
        ...GalleryFields
      }
      ... on LiveStory {
        ...LiveStoryFields
      }
      ... on InfoPage {
        ...InfoPageFields
      }
      ... on MusicAlbum {
        ...MusicAlbumFields
      }
      ... on MusicGroup {
        ...MusicGroupFields
      }
      ... on MusicRelease {
        ...MusicReleaseFields
      }
      ... on MusicReview {
        ...MusicReviewFields
      }
      ... on MusicRecording {
        ...MusicRecordingFields
      }
      ... on Photo {
        ...PhotoFields
      }
      ... on Product {
        ...ProductFields
      }
      ... on ProductReview {
        ...ProductReviewFields
      }
      ... on Recipe {
        ...RecipeFields
      }
      ... on Redirect {
        ...RedirectFields
      }
      ... on TagPage {
        ...TagpageFields
      }
      ... on Taxonomy {
        ...TaxonomyFields
      }
      ... on Venue {
        ...VenueFields
      }
      ... on Person {
        ...PersonFields
      }
      ... on Rcalist {
        ...RcalistFields
      }
      ... on Livestoryupdate {
        ...LivestoryupdateFields
      }
    }
  }
`;

export const GET_CONTENT_SUMMARY = gql`
  ${Fragments.ContentSummaryFields}
  query GetContentSummary(
    $organizationId: String!
    $contentType: String!
    $id: ID!
    $revision: Int
  ) {
    contentSummary(
      organizationId: $organizationId
      contentType: $contentType
      id: $id
      revision: $revision
    ) {
      ...ContentSummaryFields
    }
  }
`;

export const GET_RECENT_SEARCH = gql`
  query GetRecentSearch($copilotCode: String!) {
    recentSearches(copilotCode: $copilotCode) @client {
      ... on RecentSearchResult {
        id
        type
        title {
          format
          content
        }
      }

      ... on RecentQuery {
        query
      }
    }
  }
`;

export const COUNT = gql`
  query Count($organizationId: ID!, $filters: SearchFilters!) {
    search(organizationId: $organizationId, limit: 0, filters: $filters) {
      totalResults
    }
  }
`;

export const GET_COMMERCE_FILTER_INPUT = gql`
  query GetCommerceFilterInput(
    $organizationId: ID!
    $provider: SearchProvider
  ) {
    commerceFilterInput(organizationId: $organizationId, provider: $provider) {
      brandInfo {
        key
        label
      }
      retailers {
        key
        name
      }
      categories {
        key
        label
        value
      }
      tags {
        id
        name
        key
      }
    }
  }
`;

export const SEARCH = gql`
  ${Fragments.ContentSummaryFields}
  query Search(
    $organizationId: ID!
    $provider: SearchProvider
    $limit: Int
    $offset: Int
    $filters: SearchFilters!
  ) {
    search(
      organizationId: $organizationId
      provider: $provider
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      limit
      offset
      totalResults
      results {
        ...ContentSummaryFields
      }
    }
  }
`;

export const PRESETS_SEARCH = gql`
  query PresetsSearch(
    $organizationId: ID!
    $limit: Int
    $filters: SearchFilters!
  ) {
    search(organizationId: $organizationId, limit: $limit, filters: $filters) {
      results {
        id
        title {
          format
          content
        }
        contentType
        revisionInfo {
          version
        }
      }
    }
  }
`;

export const GET_FRONT_END_APPLICATION_REFS = gql`
  query FrontEndApplicationRefs(
    $organizationId: ID!
    $applicationId: ID!
    $page: Int = 1
    $limit: Int = 20
  ) {
    frontEndApplication(organizationId: $organizationId, id: $applicationId) {
      githubRepositoryName
      githubRepositoryOwner
      refs(page: $page, limit: $limit, status: REMOTE) {
        totalResults
        page
        limit
        edges {
          node {
            id
            refName
            build {
              id
              createdAt
            }
          }
          githubBranchInformation {
            id
            isDefault
            githubPullRequest {
              id
              title
              baseRefName
              permalink
              reviewDecision
            }
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query GetCategory(
    $organizationId: String!
    $categoryId: String!
    $revision: String
  ) {
    category(
      organizationId: $organizationId
      categoryId: $categoryId
      revisionNumber: $revision
    ) {
      ...CategoryFields
    }
  }
  ${Fragments.CategoryFields}
`;

export const GET_CATEGORIES = gql`
  query GetCategories($organizationId: String!, $categoryIds: [String!]) {
    categories(organizationId: $organizationId, categoryIds: $categoryIds) {
      ...CategoryFields
    }
  }
  ${Fragments.CategoryFields}
`;

export const GET_ROOT_CATEGORY = gql`
  query GetRootCategory(
    $organizationId: String!
    $slug: String
    $notHierarchies: [String!]
  ) {
    rootCategory(
      organizationId: $organizationId
      rootSlug: $slug
      notHierarchies: $notHierarchies
    ) {
      id
      name
      slug
      meta {
        childCount
      }
      revision
      root {
        id
        name
        slug
        revision
      }
      hierarchy {
        id
        name
        slug
        revision
      }
      lang
    }
  }
`;

export const GET_ROOT_CATEGORY_WITH_CHILDREN = gql`
  query GetRootCategoryWithChildren($organizationId: String!, $slug: String!) {
    rootCategoryWithChildren(organizationId: $organizationId, rootSlug: $slug) {
      id
      name
      slug
      root {
        id
        name
        slug
        revision
      }
      hierarchy {
        id
        name
        slug
        revision
      }
      content(organizationId: $organizationId) {
        results {
          id
          name
          slug
          root {
            id
            name
            slug
            revision
          }
          hierarchy {
            id
            name
            slug
            revision
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_WITH_CHILDREN = gql`
  query GetCategoryChildren(
    $organizationId: String!
    $id: String!
    $revision: String
    $page: Int = 1
    $limit: Int = 50
  ) {
    category(
      organizationId: $organizationId
      categoryId: $id
      revisionNumber: $revision
    ) {
      id
      name
      slug
      revision
      content(organizationId: $organizationId, page: $page, limit: $limit) {
        totalResults
        page
        limit
        results {
          id
          name
          slug
          revision
          meta {
            childCount
          }
          content(organizationId: $organizationId, limit: 0) {
            totalResults
          }
          hierarchy {
            id
            name
            slug
            revision
          }
          root {
            id
            name
            slug
            revision
          }
        }
      }
      root {
        id
        name
        slug
        revision
      }
      hierarchy {
        id
        name
        slug
        revision
      }
      lang
    }
  }
`;

export const CATEGORY_SEARCH = gql`
  query CategorySearch(
    $organizationId: String!
    $filters: CategorySearchFilters!
  ) {
    categorySearch(organizationId: $organizationId, filters: $filters) {
      id
      name
      slug
      revision
      meta {
        childCount
      }
      content(organizationId: $organizationId, limit: 0) {
        totalResults
      }
      root {
        id
        name
        slug
        revision
      }
      hierarchy {
        id
        name
        slug
        revision
      }
      lang
    }
  }
`;

export const SEO_KEYWORDS = gql`
  query SEOKeywords(
    $organizationId: ID!
    $seoCategory: String!
    $query: String!
  ) {
    seoKeywords(
      organizationId: $organizationId
      seoCategory: $seoCategory
      query: $query
    ) {
      __typename
      keyword
      value
      trends
    }
  }
`;

export const CKEDITOR_DEFINITIONS = gql`
  query GetDefinitions(
    $brand: String
    $organizationId: String
    $consumerHost: String
  ) {
    definitions(
      brandCode: $brand
      organizationId: $organizationId
      consumerHost: $consumerHost
    )
  }
`;

export const GET_PREVIEW_CONTAINER_FORM = gql`
  query getPreviewForm($organizationId: String!, $contentType: String!) {
    form(organizationId: $organizationId, contentType: $contentType) {
      children
      controls {
        __typename
        id
        children
        ... on RadioButtonGroupFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          optionsPath
          disabledOptionsPath
          tooltipPlacement
          textPositionOptions: options {
            options {
              value
              iconName
            }
            defaultValue
            disabledOptions
          }
        }
        ... on StyleSettingFormControl {
          name
          optionsPath
          layoutConfigPath
          tooltipPlacement
          options {
            mobile {
              value
              iconName
            }
            tablet {
              value
              iconName
            }
            desktop {
              value
              iconName
            }
          }
        }
        ... on AspectRatioSelectFormControl {
          name
          apiConfigPath
          options {
            defaultValue
            options
          }
        }
        ... on ToggleFormControl {
          name
          label {
            defaultMessage
            description
          }
          message
          noLabel
          disabled
        }
        ... on FieldsetFormControl {
          name
          description
        }
        ... on SelectFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          optionsPath
          defaultValuePath
          hidePrompt
          disabled
          contextualHelpKey
          multiple
          options {
            options
            defaultValue
          }
          apiConfigPath
        }
      }
    }
  }
`;

export const GET_LIVE_UPDATE_FORM = gql`
  ${Fragments.RichTextConfigurationFields}
  ${Fragments.AssetSelectorConfigurationFields}
  query getLiveUpdateForm(
    $organizationId: String!
    $contentType: String!
    $id: ID!
  ) {
    form(organizationId: $organizationId, contentType: $contentType) {
      children
      controls {
        __typename
        id
        children
        ... on FieldsetFormControl {
          name
          description
        }
        ... on MdEditorFormControl {
          name
          label {
            defaultMessage
            description
          }
          wordCount
          afmConfig
          richTextConfiguration {
            ...RichTextConfiguration
          }
          assetSelectorConfiguration {
            ...AssetSelectorConfiguration
          }
        }
        ... on TextFieldFormControl {
          name
          placeholderKey
        }
        ... on MultiSelectFormControl {
          name
          label {
            defaultMessage
            description
          }
          noLabel
          adapterType
          strategy
          selectionLimit
        }
      }
    }
    content(
      organizationId: $organizationId
      contentType: "livestory"
      id: $id
    ) {
      ... on LiveStory {
        hed
      }
    }
  }
`;

export const GET_TRANSLATIONS = gql`
  query getTranslations($organizationId: ID!, $contentType: String!, $id: ID!) {
    getTranslations(
      organizationId: $organizationId
      contentType: $contentType
      id: $id
    ) {
      ... on Article {
        id
        lang
      }
      ... on Gallery {
        id
        lang
      }
    }
  }
`;

export const GET_LAYOUT_CONFIGS_DATA = gql`
  ${Fragments.RenditionConfigsFields}
  ${Fragments.ComponentConfigsFields}
  query GetLayoutConfigs(
    $organizationId: ID!
    $query: LayoutConfigsQueryInput!
  ) {
    layoutConfigs(organizationId: $organizationId, query: $query) {
      contentId
      contentRevision
      layouts {
        layoutId
        preset {
          componentConfigs {
            ...ComponentConfigsFields
          }
          id
          name
          presetCategory
          disableTimeOfPublishOptions
          renditionConfigs {
            ...RenditionConfigsFields
          }
        }
        componentConfigs {
          ...ComponentConfigsFields
        }
        renditionConfigs {
          ...RenditionConfigsFields
        }
      }
    }
  }
`;

export const GEOCODE_ADDRESS = gql`
  query GeocodeAddress($address: GeocodingInput!, $mapboxPublicToken: String!) {
    geocode(address: $address, mapboxPublicToken: $mapboxPublicToken) {
      latitude
      longitude
    }
  }
`;

export const RESYNC_DIFFSTATUS = gql`
  query ResyncDiffStatus($sourceId: String!, $targetId: String!) {
    diffStatus(sourceId: $sourceId, targetId: $targetId) {
      hasChanges
    }
  }
`;

export const PARROT_RESYNC = gql`
  query ParrotResync(
    $sourceId: String!
    $targetId: String!
    $syndicationAuthor: String!
  ) {
    resync(
      sourceId: $sourceId
      targetId: $targetId
      syndicationAuthor: $syndicationAuthor
    ) {
      message
      entity {
        id
      }
      code
    }
  }
`;

export const GET_LIVESTORY_UPDATES = gql`
  ${Fragments.LivestoryupdateFields}
  query GetLiveStoryUpdates(
    $organizationId: ID!
    $id: ID!
    $createdAtFrom: Date
    $limit: Int
  ) {
    getLiveStory(organizationId: $organizationId, id: $id) {
      ... on LiveStory {
        id
        updates: liveUpdates(
          organizationId: $organizationId
          id: $id
          createdAtFrom: $createdAtFrom
          limit: $limit
        ) {
          limit
          page
          totalResults
          edges {
            node {
              ...LivestoryupdateFields
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_AUTOFILL_DATA = gql`
  query GetProductAutofillData(
    $organizationId: ID!
    $productId: ID!
    $offerUrls: [String!]!
  ) {
    getProductAutofillData(
      organizationId: $organizationId
      productId: $productId
      offerUrls: $offerUrls
    ) {
      price
      salePrice
      isOutOfStock
      domainURL
      retailerName
      currency
      siteImage
      name
      requestURL
    }
  }
`;

export const GET_OEMBED = gql`
  query GetOEmbed($organizationId: ID!, $url: String!) {
    oembed(organizationId: $organizationId, url: $url) {
      type
      url
      width
      height
      html
      title
      author_name
    }
  }
`;

export const GET_TAG_SUGGESTIONS = gql`
  query GetTagSuggestions(
    $organizationId: String!
    $model: ArticleInput!
    $useV2: Boolean!
  ) {
    getTagSuggestions(
      organizationId: $organizationId
      model: $model
      useV2: $useV2
    ) {
      categories {
        id
        name
      }
    }
  }
`;

export const GET_SYNDICATED_TARGETS = gql`
  query GetSyndicatedTargets($sourceId: String, $targetId: String) {
    getSyndicatedTargets(sourceId: $sourceId, targetId: $targetId) {
      syndicationAuthor
      brand
      createdAt
    }
  }
`;

import { defineMessages } from "react-intl";

export const CONTRIBUTOR_TYPES = defineMessages({
  ADAPTATION_BY: {
    defaultMessage: "Adaptation Editors",
    description: "contributorTypes.contributorsAdaptationBy",
  },
  ADDITIONAL_REPORTING: {
    defaultMessage: "Additional Reporting by",
    description: "contributorTypes.contributorsAdditionalReporting",
  },
  ANIMATOR: {
    defaultMessage: "Animator",
    description: "contributorTypes.contributorsAnimator",
  },
  ARTIST: {
    defaultMessage: "Artist",
    description: "contributorTypes.contributorsArtist",
  },
  AS_TOLD_TO: {
    defaultMessage: "As told to",
    description: "contributorTypes.contributorsAsToldTo",
  },
  AUTHOR: {
    defaultMessage: "Author",
    description: "contributorTypes.contributorsAuthor",
  },
  CAPTIONIST: {
    defaultMessage: "Captionist",
    description: "contributorTypes.contributorsCaptionist",
  },
  CHEF: {
    defaultMessage: "Chef",
    description: "contributorTypes.contributorsChef",
  },
  CO_AUTHOR: {
    defaultMessage: "Co-Author",
    description: "contributorTypes.contributorsCoAuthor",
  },
  CONTRIBUTOR: {
    defaultMessage: "Contributor",
    description: "contributorTypes.contributorsContributor",
  },
  DEVELOPER: {
    defaultMessage: "Developer",
    description: "contributorTypes.contributorsDeveloper",
  },
  DIRECTOR: {
    defaultMessage: "Director",
    description: "contributorTypes.contributorsDirector",
  },
  EDITOR: {
    defaultMessage: "Editor",
    description: "contributorTypes.contributorsEditor",
  },
  FASHION_EDITOR: {
    defaultMessage: "Fashion Editor",
    description: "contributorTypes.contributorsFashionEditor",
  },
  FILM_BY: {
    defaultMessage: "Film By",
    description: "contributorTypes.contributorsFilmBy",
  },
  FOOD_STYLIST: {
    defaultMessage: "Food Stylist",
    description: "contributorTypes.contributorsFoodStylist",
  },
  HAIR: {
    defaultMessage: "Hair Stylist",
    description: "contributorTypes.contributorsHair",
  },
  ILLUSTRATOR: {
    defaultMessage: "Illustrator",
    description: "contributorTypes.contributorsIllustrator",
  },
  IN_CONVERSATION: {
    defaultMessage: "In Conversation",
    description: "contributorTypes.contributorsInConversation",
  },
  INTERACTIVE_BY: {
    defaultMessage: "Interactive By",
    description: "contributorTypes.contributorsMakeup",
  },
  INTRODUCER: {
    defaultMessage: "Introducer",
    description: "contributorTypes.contributorsIntroducer",
  },
  INTRODUCTION: {
    defaultMessage: "Introduction",
    description: "contributorTypes.contributorsIntroduction",
  },
  LICENSED: {
    defaultMessage: "Licensed",
    description: "contributorTypes.contributorsLicensed",
  },
  MAKEUP: {
    defaultMessage: "Makeup Artist",
    description: "contributorTypes.contributorsMakeup",
  },
  MEDICAL_REVIEWER: {
    defaultMessage: "Medically Reviewed by",
    description: "contributorTypes.contributorsMedicalReviewer",
  },
  NAILS: {
    defaultMessage: "Nails",
    description: "contributorTypes.contributorsNails",
  },
  PAINTER: {
    defaultMessage: "Painter",
    description: "contributorTypes.contributorsPainter",
  },
  PHOTOGRAPHER: {
    defaultMessage: "Photographer",
    description: "contributorTypes.contributorsPhtographer",
  },
  PODCAST_HOST: {
    defaultMessage: "Podcast Host",
    description: "contributorTypes.contributorsPodcastHost",
  },
  PORTRAITIST: {
    defaultMessage: "Portraitist",
    description: "contributorTypes.contributorsPortraitist",
  },
  PRODUCER: {
    defaultMessage: "Producer",
    description: "contributorTypes.contributorsProducer",
  },
  PROP_STYLIST: {
    defaultMessage: "Prop Stylist",
    description: "contributorTypes.contributorsPropStylist",
  },
  REPORTER: {
    defaultMessage: "Reporter",
    description: "contributorTypes.contributorsReporter",
  },
  REVIEWER: {
    defaultMessage: "Reviewer",
    description: "contributorTypes.Reviewer",
  },
  STYLIST: {
    defaultMessage: "Stylist",
    description: "contributorTypes.contributorsStylist",
  },
  TEXT_BY: {
    defaultMessage: "Text by",
    description: "contributorTypes.contributorsTextBy",
  },
  VIDEO_BY: {
    defaultMessage: "Video by",
    description: "contributorTypes.contributorsVideoBy",
  },
  VIDEO_PRODUCER: {
    defaultMessage: "Video Producer",
    description: "contributorTypes.contributorsVideoProducer",
  },
  VIDEOGRAPHER: {
    defaultMessage: "Videographer",
    description: "contributorTypes.contributorsVideographer",
  },
  VIDEO_DIRECTOR: {
    defaultMessage: "Video Director",
    description: "contributorTypes.contributorsVideoDirector",
  },
  COVER_SHOOT: {
    defaultMessage: "Cover Shoots",
    description: "contributorTypes.contributorsCoverShoot",
  },
  DIRECTED_BY: {
    defaultMessage: "Directed by",
    description: "contributorTypes.contributorsDirector",
  },
});

import { Children, ComponentProps, useState, isValidElement } from "react";
import {
  ControlProps,
  FormFor_form_controls_TabFormControl,
  FormFor_form_controls,
} from "@types";
import { FormControl } from "../..";
import { ARIA } from "@components";
import { useDefinedMessages } from "@hooks";
import styled from "styled-components";

const StyledTabList = styled(ARIA.TabList)`
  margin-top: var(--spacing-sm);
`;
export function TabList(props: ControlProps<FormFor_form_controls>) {
  const { translateFieldLegend } = useDefinedMessages();
  const tabs =
    Children.map(props.children, (child) => {
      if (!isValidElement(child)) {
        return;
      }

      const childProps = child.props as ComponentProps<typeof FormControl>;
      const childControls = childProps.controls.find(
        (control) => control.id === childProps.controlId
      ) as FormFor_form_controls_TabFormControl | undefined;

      if (!childControls || childControls.__typename !== "TabFormControl") {
        return;
      }

      return {
        id: `TabFormControl-${childControls.id}`,
        Label: translateFieldLegend(childControls.name),
      };
    })?.filter(Boolean) ?? [];
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  return (
    <StyledTabList
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    >
      {props.children}
    </StyledTabList>
  );
}
TabList.displayName = "Control(TabList)";

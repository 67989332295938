import { useMemo, useState, useEffect, memo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "@gql";
import { SearchResult, Spinner } from "@components";
import { AnimatedEllipsisIcon } from "@condenast/gemini/icons";
import {
  FormFor_content_Bundle_containers_searches,
  Search as SearchQueryType,
  SearchVariables,
  ContentSummary,
} from "@types";
import { normalizeContentSummary } from "@lib";
import { serializeSearch } from "../../../../utils";
import { SearchResultSlatContainer, SlatResult } from "@condenast/gemini";
import { TextButtonWithIcon } from "../../../";
import { useDefinedMessages } from "@hooks";

type SearchPreviewProps = {
  search: FormFor_content_Bundle_containers_searches;
  organizationId: string;
  cdnHost: string;
};

const SearchResponseInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: var(--spacing-xxl);
  margin: 0 auto;
`;

const StyledSearchResult = styled(SearchResult)`
  border: none;
  border-radius: var(--spacing-xxs);
  box-shadow: ${(props) => props.theme.CardShadow};
`;

const SearchPreviewList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  ${SearchResultSlatContainer}:hover {
    background: ${(props) => props.theme.PrimaryColor};
    cursor: default;
  }
`;

const SearchPreviewFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchResultPageInfo = styled.div`
  font: var(--font-statements);
  color: var(--color-gray-3);
`;

const SearchPreview = memo(function (props: SearchPreviewProps) {
  const { search, organizationId, cdnHost } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [searchResults, setSearchResults] = useState<ContentSummary[]>([]);

  const { translateContentType } = useDefinedMessages();

  const PAGE_SIZE = 5;

  const maxResultsCount = useMemo(
    () => Math.min(totalResults, search?.size ?? 0),
    [totalResults, search?.size]
  );
  const searchResultLimit = useMemo(() => {
    const remainingResultCount = (search?.size ?? 0) - searchResults.length;
    return Math.min(PAGE_SIZE, remainingResultCount);
  }, [searchResults.length, search?.size]);

  const searchFilters = useMemo(() => {
    return serializeSearch(search);
  }, [search]);

  const [executeSearch, { loading: searchLoading, error: searchError }] =
    useLazyQuery<SearchQueryType, SearchVariables>(Queries.SEARCH, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      onCompleted: (data) => {
        setTotalResults(data.search.totalResults);
        const normalizedSearchResults = data.search.results.map(
          (searchResult) =>
            normalizeContentSummary(
              searchResult,
              translateContentType
            ) as ContentSummary
        );
        setSearchResults((prevSearchResults) => [
          ...prevSearchResults,
          ...normalizedSearchResults,
        ]);
      },
    });

  useEffect(() => {
    setSearchResults([]);
    setTotalResults(0);
    setCurrentPage(1);
    executeSearch({
      variables: {
        organizationId,
        limit: Math.min(PAGE_SIZE, search?.size ?? 0),
        offset: 0,
        filters: searchFilters,
      },
    });
  }, [searchFilters, executeSearch, organizationId, search?.size]);

  const fetchNextPage = useCallback(() => {
    executeSearch({
      variables: {
        organizationId,
        limit: searchResultLimit,
        offset: currentPage * PAGE_SIZE,
        filters: searchFilters,
      },
    });
    const nextPageNumber = currentPage + 1;
    setCurrentPage(nextPageNumber);
  }, [
    searchResultLimit,
    searchFilters,
    currentPage,
    executeSearch,
    organizationId,
  ]);

  return (
    <>
      <SearchPreviewList>
        {searchResults.length > 0 ? (
          searchResults.map((item, index: number) => {
            return (
              <li key={item.id}>
                <StyledSearchResult
                  id={`$select-item-${index}`}
                  result={item}
                  treatment={"slat-result"}
                  cdnHost={cdnHost}
                  assetindex={index}
                >
                  <SlatResult.ResultIndex index={index + 1} />
                </StyledSearchResult>
              </li>
            );
          })
        ) : (
          <SearchResponseInfo>
            {searchLoading ? (
              <Spinner size="large" />
            ) : searchError ? (
              <FormattedMessage defaultMessage="Sorry, something went wrong. Please try updating your Search Query." />
            ) : (
              <FormattedMessage defaultMessage="There are 0 search results. Please try expanding your Search Query." />
            )}
          </SearchResponseInfo>
        )}
      </SearchPreviewList>

      {searchResults.length > 0 && (
        <SearchPreviewFooter>
          <SearchResultPageInfo>
            <FormattedMessage
              defaultMessage="1-{currentResultsCount} of {maxResultsCount}"
              values={{
                currentResultsCount: searchResults.length,
                maxResultsCount: maxResultsCount,
              }}
            />
          </SearchResultPageInfo>
          {searchResults.length !== maxResultsCount && (
            <TextButtonWithIcon
              treatment="text"
              onClick={fetchNextPage}
              disabled={searchLoading}
            >
              <FormattedMessage defaultMessage="Load more" />
              {searchLoading && <AnimatedEllipsisIcon size="small" />}
            </TextButtonWithIcon>
          )}
        </SearchPreviewFooter>
      )}
    </>
  );
});

export default SearchPreview;

import type { TypePolicy } from "@apollo/client";

export const Product: TypePolicy = {
  fields: {
    pros: {
      read(cache) {
        return (
          cache &&
          cache.map((data: string) => ({
            value: data,
          }))
        );
      },
    },
    cons: {
      read(cache) {
        return (
          cache &&
          cache.map((data: string) => ({
            value: data,
          }))
        );
      },
    },
  },
};

import { CreateVenue_content_Venue_geo, VenueGeoInput } from "@types";

export function geo(data: CreateVenue_content_Venue_geo): VenueGeoInput {
  let { lat, lng } = data;

  return {
    lat: lat,
    lng: lng,
  };
}

import { Field, TextField } from "@condenast/gemini";
import { ComponentProps, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Autogenerates } from "@components";

const GeoFieldContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;
`;

const MapboxImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: var(--spacing-xs);
`;

const LATITUDE_PATTERN = /\{lat\}/g;
const LONGITUDE_PATTERN = /\{long\}/g;
const HEIGHT_PATTERN = /\{height\}/g;
const WIDTH_PATTERN = /\{width\}/g;
const ZOOM_PATTERN = /\{zoom\}/g;

function isValidLatitude(latitude: unknown): latitude is number {
  return typeof latitude === "number" && Math.abs(latitude) <= 90;
}

function isValidLongitude(longitude: unknown): longitude is number {
  return typeof longitude === "number" && Math.abs(longitude) <= 180;
}

function parseLatLongString(latLong: string) {
  let [latitude, longitude] = latLong
    .split(",")
    .map((coord) => {
      let number = parseFloat(coord);
      return isNaN(number) ? null : number;
    })
    .concat([null]);

  return { latitude, longitude };
}

function displayLatLongValue(
  latitude: Coordinates["latitude"],
  longitude: Coordinates["longitude"]
) {
  return isValidLatitude(latitude) && isValidLongitude(longitude)
    ? `${latitude}, ${longitude}`
    : "";
}

type Coordinates = {
  latitude: number | null | undefined;
  longitude: number | null | undefined;
};

type GeoFieldProps = Omit<ComponentProps<typeof Field>, "children"> &
  Coordinates & {
    onChange: (coordinates: Coordinates) => void;
    autogenValue?: Coordinates | (() => Promise<Coordinates>);
    mapUrlPattern?: string;
    mapHeight?: string;
    mapWidth?: string;
    mapZoom?: string;
  };

export function GeoField(props: GeoFieldProps) {
  let {
    onChange,
    autogenValue,
    latitude,
    longitude,
    mapUrlPattern,
    mapHeight = "512",
    mapWidth = "512",
    mapZoom = "12",
  } = props;

  latitude = latitude || 0;
  longitude = longitude || 0;

  const intl = useIntl();
  const displayString = displayLatLongValue(latitude, longitude);
  const [internalValue, setInternalValue] = useState(displayString);

  useEffect(() => {
    setInternalValue(displayLatLongValue(latitude, longitude));
  }, [latitude, longitude]);

  const updateLatLong = useCallback(
    (value: string) => {
      onChange(parseLatLongString(value));
    },
    [onChange]
  );

  const getCoordinates = useCallback(async () => {
    const coordinates =
      typeof autogenValue === "function"
        ? await autogenValue?.()
        : autogenValue;
    return displayLatLongValue(
      coordinates?.latitude ?? 0,
      coordinates?.longitude ?? 0
    );
  }, [autogenValue]);

  const mapParametersAreValid =
    isValidLatitude(latitude) && isValidLongitude(longitude) && mapUrlPattern;
  const mapUrl =
    mapParametersAreValid && mapUrlPattern
      ? mapUrlPattern
          .replace(LATITUDE_PATTERN, `${latitude}`)
          .replace(LONGITUDE_PATTERN, `${longitude}`)
          .replace(HEIGHT_PATTERN, mapHeight)
          .replace(WIDTH_PATTERN, mapWidth)
          .replace(ZOOM_PATTERN, mapZoom)
      : undefined;

  return (
    <GeoFieldContainer>
      <Autogenerates onChange={updateLatLong} autogenValue={getCoordinates}>
        {(autogenProps) => (
          <TextField
            {...props}
            {...autogenProps}
            multiline={false}
            value={internalValue}
            onChange={(value) => {
              setInternalValue(value);
              autogenProps.dismissAutogenTheme();
            }}
            onBlur={updateLatLong}
          />
        )}
      </Autogenerates>
      {mapUrl && (
        <MapboxImage
          src={mapUrl}
          height={mapHeight}
          width={mapWidth}
          alt={intl.formatMessage(
            {
              defaultMessage: "Map of area around {latitude}, {longitude}",
              description: "components.geo-field.map.mapAltText",
            },
            { latitude, longitude }
          )}
        />
      )}
    </GeoFieldContainer>
  );
}
GeoField.displayName = "GeoField";

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required } from "@condenast/copilot-validators";
import { FormFor_content_MusicRecording } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";

const { object } = validators;

const musicRecordingValidators = object({
  name: required(),
});

export default function validateMusicRecording(
  musicrecording: FormFor_content_MusicRecording,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(
    musicrecording,
    build(musicRecordingValidators),
    context,
    env
  );
}

export function isDefined<T>(x: T | undefined | null): x is T {
  return x != null;
}

export function isKeyOf<T>(
  record: T,
  key: string | number | symbol
): key is keyof T {
  return key in record;
}

import { Spinner, UploadButton } from "@condenast/gemini";
import styled from "styled-components";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { FormattedMessage } from "react-intl";

const UploadRegion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: ${(props) => props.theme.FieldBackground};
  border-bottom: 2px dashed var(--color-gray-5);
  padding: 1rem;
  text-align: center;
  min-height: 110px;

  &:hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing},
      ${(props) => props.theme.CardShadow};
  }

  &.disabled {
    box-shadow: ${(props) => props.theme.FieldDisabledRing},
      ${(props) => props.theme.CardShadow};
    background-color: ${(props) => props.theme.ControlDisabledBackground};
  }
`;

const DragPrompt = styled.div`
  font: ${(props) => props.theme.FontStatement};
`;

export const MediaUpload = (props: {
  onUpload: (files: FileList) => void;
  accept: string[];
  onUnsupportedFileType: () => void;
  loading?: boolean;
}) => {
  const { onUpload, accept, onUnsupportedFileType, loading } = props;
  const [, dropRef] = useDrop(() => ({
    accept: NativeTypes.FILE,
    canDrop: (item: { files: File[]; dataTransfer: DataTransfer }) => {
      let isCorrectFileType = item.files.every((file) =>
        accept.includes(file.type)
      );
      if (!isCorrectFileType) {
        onUnsupportedFileType();
      }

      return isCorrectFileType;
    },
    drop(item) {
      onUpload(item.dataTransfer.files);
    },
  }));

  return (
    <UploadRegion ref={dropRef}>
      {loading ? (
        <Spinner
          size="small"
          $color={{ background: "transparent", foreground: "blue" }}
        />
      ) : (
        <>
          <UploadButton
            onChange={onUpload}
            accept={accept.join(",")}
            multiple
          />
          <DragPrompt role="presentation">
            <FormattedMessage
              defaultMessage="Drag media here to upload"
              description="Text on a region where files can be dragged and dropped."
            />
          </DragPrompt>
        </>
      )}
    </UploadRegion>
  );
};
MediaUpload.displayName = "MediaUpload";

import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import type { Changeset } from "@types";
import { get } from "@lib";
import { actions, equals, reducer } from "./useChangeset.utils";

export function useChangeset<T>(initialValue: T): Changeset<T> {
  let [{ changes, changedKeys }, dispatch] = useReducer(reducer, {
    changes: {},
    changedKeys: [],
  });
  let [hasDeferredChanges, setHasDeferredChanges] = useState(false);
  let setValue = useCallback(
    function <Key extends keyof T>(key: Key, value: T[Key]) {
      let nested = false;
      if (typeof key === "string") {
        nested = key.split(".").length > 1;
      }

      // we have all the changes that happened so far
      if (!nested && equals(get(initialValue, key), value)) {
        dispatch(actions.unset(key as string));
      } else {
        dispatch(actions.set(key as string, value, initialValue));
      }
    },
    [dispatch, initialValue]
  );

  // Clear the changeset when the initialValue changes
  useEffect(() => {
    if (Object.keys(changes).length > 0) {
      dispatch(actions.clear());
      setHasDeferredChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const resetChanges = useCallback(() => {
    dispatch(actions.clear());
    setHasDeferredChanges(false);
  }, [dispatch]);

  let stableReturnArray = useMemo(
    (): Changeset<T> => [
      {
        ...initialValue,
        ...changes,
      },
      setValue,
      {
        changedKeys,
        changes: changes as Partial<T>,
        hasChanges: hasDeferredChanges || changedKeys.length > 0,
        resetChanges,
      },
      setHasDeferredChanges,
    ],
    [
      initialValue,
      changes,
      changedKeys,
      setValue,
      hasDeferredChanges,
      setHasDeferredChanges,
      resetChanges,
    ]
  );

  return stableReturnArray;
}

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { required } from "@condenast/copilot-validators";

import type { ValidationEnvironment, ValidationContext } from "@lib";
import type { FormFor_content_Cartoon } from "@types";
import { publishURI } from "../validators";

const { object } = validators;

const cartoonValidators = object({
  title: required(),
}).andAlso(publishURI().on("publish"));

export default function validateCartoon(
  brand: FormFor_content_Cartoon,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(brand, build(cartoonValidators), context, env);
}

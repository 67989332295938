import {
  Button,
  Card,
  Image,
  CKEditor,
  Field,
  Metadata,
  RoutableLink,
} from "@components";
import {
  ContentTypeIcon,
  AssetIcon,
  CloseIcon,
  RedirectIcon,
  DraggableIcon,
  NoAssetIcon,
} from "@condenast/gemini/icons";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  CKEditorConfiguration,
  ContentSummary,
  CuratedListItemData,
  FormError,
} from "@types";
import { useDefinedMessages, useUniqueId } from "@hooks";
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  MouseEventHandler,
} from "react";
import computeScrollIntoView from "compute-scroll-into-view";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import CopilotMarkdownRenderer from "@condenast/atjson-renderer-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";
import {
  BlockEditor,
  InlineEditor,
  MinimalistEditor,
} from "@condenast/ckeditor5-build-condenast";

const CONTENT_IMAGE_DIMENSION = 124; //px

const Item = styled(Card)`
  position: relative;
  display: grid;
  column-gap: var(--spacing-md);
  row-gap: var(--spacing-sm);
  padding-top: var(--spacing-sm);
  width: 100%;

  @media (max-width: 650px) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "asset actions"
      "fields fields";
  }

  @media (min-width: 650px) {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "asset fields actions";
  }

  .draggable-icon {
    display: none;
    margin: 0 auto;
    color: var(--color-gray-4);
  }

  &:hover .draggable-icon {
    display: block;
  }
`;

const AssetField = styled.div`
  grid-area: asset;
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-xxs);
`;

const EmptyAssetWrapper = styled.div`
  position: relative;
  width: 10.75rem;
  height: 9.5rem;
  border: 1px solid var(--color-gray-5);
  border-radius: ${(props) => props.theme.CornerRadius};
  background: var(--color-gray-6);
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-xs);
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &[aria-invalid="true"] {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }
`;

const EditAsset = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 0;

  &:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    height: 100%;
    background: var(--color-gray-1);
    opacity: 50%;
    z-index: -1;
  }

  & svg {
    margin-right: var(--spacing-xxs);
  }
`;

const AssetWrapper = styled(EmptyAssetWrapper)`
  color: var(--color-gray-6);

  svg:not(${EditAsset} svg) {
    color: var(--color-gray-4);
  }

  &:hover {
    ${EditAsset} {
      display: flex;
    }
  }
`;

const ContentImage = styled.div`
  height: ${CONTENT_IMAGE_DIMENSION}px;
  margin: var(--spacing-sm) var(--spacing-sm);

  img {
    border-radius: ${(props) => props.theme.CornerRadius};
  }
`;

const ActionButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
`;

const ContextualFields = styled.div`
  grid-area: fields;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
`;

const MetadataDisplay = styled(RoutableLink)`
  grid-area: metadata;
  position: relative;
  width: 100%;
  border-radius: ${(props) => props.theme.CornerRadius};
  background: ${(props) => props.theme.SelectButtonBackgroundColor};
  padding: ${(props) => props.theme.SecondaryPadding};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;

  &:hover {
    background: ${(props) => props.theme.SelectButtonHoverBackgroundColor};
  }

  & div {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
  }
`;

const ActionContainer = styled.div`
  grid-area: actions;
  display: grid;
  height: fit-content;
  row-gap: var(--spacing-xs);
`;

const CloseButton = styled(Button)`
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  border-radius: var(--spacing-xs);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  & svg {
    margin: auto;
  }
`;

const ItemNumber = styled.div`
  font-size: ${(props) => props.theme.FontStatement};
  text-align: center;
  padding: ${(props) => props.theme.SecondarySmallPadding};
  border: 1px solid ${(props) => props.theme.DividerColor};

  width: var(--spacing-lg);
  height: var(--spacing-lg);
  border-radius: var(--spacing-xs);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

const EditorField = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

const Editor = styled(CKEditor)`
  grid-area: control;
`;

const EditorCaption = styled(CKEditor)`
  grid-area: control;
  display: table;
  width: 100%;
`;

const Builds = {
  block: BlockEditor,
  inline: InlineEditor,
  minimalist: MinimalistEditor,
};

export function EmptyAsset(props: {
  selectAsset: () => void;
  errors?: FormError[];
}) {
  const { selectAsset, errors } = props;
  return (
    <AssetField>
      <EmptyAssetWrapper aria-invalid={!!errors?.length}>
        <>
          <ActionButton
            onClick={(evt) => {
              evt.preventDefault();
              selectAsset();
            }}
          >
            <AssetIcon size="regular" />
            <FormattedMessage defaultMessage="Search" />
          </ActionButton>
        </>
      </EmptyAssetWrapper>
      {!!errors?.length && (
        <div>
          {errors.map(({ message: { name, message } }) => {
            return <p key={name}>{message}</p>;
          })}
        </div>
      )}
    </AssetField>
  );
}

export function Asset(props: {
  item: ContentSummary;
  cdnHost?: string;
  target: string;
  editUrl: string | null;
  errors?: FormError[];
}) {
  const intl = useIntl();
  const { item, cdnHost, target, editUrl, errors } = props;
  const { translateContentType } = useDefinedMessages();

  return (
    <AssetField>
      <AssetWrapper
        as={RoutableLink}
        target={target}
        to={editUrl ?? ""}
        aria-invalid={!!errors?.length}
        aria-label={intl.formatMessage(
          {
            defaultMessage: `Edit {type}`,
          },
          { type: translateContentType(item.contentType, 1) }
        )}
      >
        <EditAsset>
          <ContentTypeIcon contentType={item.contentType} size="regular" />
          <span>
            <FormattedMessage
              defaultMessage="Edit {type}"
              values={{ type: translateContentType(item.contentType, 1) }}
            />
          </span>
        </EditAsset>
        {!item.asset ? (
          <NoAssetIcon size="regular" />
        ) : (
          <ContentImage>
            <Image
              asset={item.asset}
              cdnHost={cdnHost}
              aspectRatio={"1:1"}
              modifications={{
                width: CONTENT_IMAGE_DIMENSION,
                height: CONTENT_IMAGE_DIMENSION,
              }}
            />
          </ContentImage>
        )}
      </AssetWrapper>
      {!!errors?.length && (
        <div>
          {errors.map(({ message: { name, message } }) => {
            return <p key={name}>{message}</p>;
          })}
        </div>
      )}
    </AssetField>
  );
}

function getErrorsForCuratedListField(
  errors: FormError[] | undefined,
  field: string
) {
  const errorsAtPath = errors?.filter(({ path }) => path[0] === field);
  return errorsAtPath && errorsAtPath.length > 0 ? errorsAtPath : undefined;
}

export function CuratedListItem(props: {
  index: number;
  item: CuratedListItemData;
  cdnHost?: string;
  selectAsset: (item: CuratedListItemData) => void;
  removeItem: (index: number) => void;
  onChange: (item: CuratedListItemData, index: number) => void;
  hedRichTextConfig: CKEditorConfiguration;
  hedRichTextBuild: keyof typeof Builds;
  dekRichTextConfig: CKEditorConfiguration;
  dekRichTextBuild: keyof typeof Builds;
  rubricRichTextBuild: keyof typeof Builds;
  itemBodyRichTextBuild?: keyof typeof Builds;
  itemBodyRichTextConfig?: CKEditorConfiguration;
  showItemBody?: boolean | null;
  isMostRecentlyInserted?: boolean;
}) {
  const {
    cdnHost,
    item,
    onChange,
    removeItem,
    selectAsset,
    hedRichTextConfig,
    hedRichTextBuild,
    dekRichTextConfig,
    dekRichTextBuild,
    rubricRichTextBuild,
    index,
    isMostRecentlyInserted,
  } = props;
  const intl = useIntl();
  const { translateFieldName, translateContentType } = useDefinedMessages();

  const scrollIntoView: (node: HTMLElement | null) => void = useCallback(
    (node) => {
      if (node && isMostRecentlyInserted) {
        let scrollActions = computeScrollIntoView(node, {
          scrollMode: "if-needed",
          block: "center",
        });
        scrollActions.forEach(({ el, top }) => {
          el.scrollTo({ top, behavior: "smooth" });
        });
      }
    },
    [isMostRecentlyInserted]
  );

  // CKEditor requires an onChange that is stable across re-renders
  // so update local state on change and have a separate effect that
  // can be updated when props change
  const [hed, setHed] = useState<string | null>(null);
  const onHedChange = useCallback(
    (doc) => {
      setHed(CopilotMarkdownRenderer.render(doc));
    },
    [setHed]
  );
  useEffect(() => {
    if (hed !== null) {
      onChange({ ...item, hed }, index);
    }
    // only run when the hed value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hed]);

  const [dek, setDek] = useState<string | null>(null);
  const onDekChange = useCallback(
    (doc) => {
      setDek(CopilotMarkdownRenderer.render(doc));
    },
    [setDek]
  );
  useEffect(() => {
    if (dek !== null) {
      onChange({ ...item, dek }, index);
    }
    // only run when the dek value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dek]);

  const [rubric, setRubric] = useState<string | null>(null);
  const onRubricChange = useCallback(
    (doc) => {
      setRubric(CopilotMarkdownRenderer.render(doc));
    },
    [setRubric]
  );
  useEffect(() => {
    if (rubric !== null) {
      onChange({ ...item, rubric }, index);
    }
    // only run when the rubric value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rubric]);

  const itemErrors = useMemo(
    () => getErrorsForCuratedListField(item.errors, "item"),
    [item.errors]
  );
  const hedErrors = useMemo(
    () => getErrorsForCuratedListField(item.errors, "hed"),
    [item.errors]
  );

  const dekErrors = useMemo(
    () => getErrorsForCuratedListField(item.errors, "dek"),
    [item.errors]
  );

  const rubricErrors = useMemo(
    () => getErrorsForCuratedListField(item.errors, "rubric"),
    [item.errors]
  );

  const itemSelectAsset = useCallback(() => {
    selectAsset(item);
  }, [item, selectAsset]);

  const onRemoveItem: MouseEventHandler = useCallback(
    (evt) => {
      evt.preventDefault();
      removeItem(index);
    },
    [removeItem, index]
  );

  const hedValue = useMemo(() => {
    return CopilotMarkdownSource.fromRaw(
      item.hed ?? item.item?.title?.content ?? ""
    ).convertTo(VersoSource);
  }, [item.hed, item.item?.title?.content]);

  const hedAutogenValue = useMemo(
    () =>
      item.item?.title?.content
        ? CopilotMarkdownSource.fromRaw(item.item?.title.content).convertTo(
            VersoSource
          )
        : undefined,
    [item.item?.title?.content]
  );

  const dekValue = useMemo(
    () => CopilotMarkdownSource.fromRaw(item.dek ?? "").convertTo(VersoSource),
    [item.dek]
  );
  const dekAutogenValue = useMemo(
    () =>
      item.item?.dek
        ? CopilotMarkdownSource.fromRaw(item.item?.dek).convertTo(VersoSource)
        : undefined,
    [item.item?.dek]
  );

  const rubricValue = useMemo(
    () =>
      CopilotMarkdownSource.fromRaw(item.rubric ?? "").convertTo(VersoSource),
    [item.rubric]
  );
  const rubricAutogenValue = useMemo(
    () =>
      item.item?.rubric
        ? CopilotMarkdownSource.fromRaw(item.item?.rubric).convertTo(
            VersoSource
          )
        : undefined,
    [item.item?.rubric]
  );
  const id = useUniqueId();

  return (
    <Item ref={scrollIntoView}>
      {!item.item || !item.item.editUrl ? (
        <EmptyAsset selectAsset={itemSelectAsset} errors={itemErrors} />
      ) : (
        <Asset
          target="_blank"
          editUrl={item.item.editUrl}
          item={item.item}
          cdnHost={cdnHost}
          errors={itemErrors}
        />
      )}
      <ContextualFields>
        <EditorField
          label={translateFieldName("Headline")}
          id={`CKEditor__${id}__headline`}
          errors={hedErrors}
        >
          <Editor
            onChange={onHedChange}
            id={`CKEditor__${id}__headline`}
            config={hedRichTextConfig}
            build={hedRichTextBuild}
            value={hedValue}
            autogeneratedValue={hedAutogenValue}
            aria-invalid={!!hedErrors?.length}
          />
        </EditorField>
        <EditorField
          label={translateFieldName("Dek")}
          id={`CKEditor__${id}__dek`}
          errors={dekErrors}
        >
          <Editor
            onChange={onDekChange}
            id={`CKEditor__${id}__dek`}
            config={dekRichTextConfig}
            build={dekRichTextBuild}
            value={dekValue}
            autogeneratedValue={dekAutogenValue}
            aria-invalid={!!dekErrors?.length}
          />
        </EditorField>
        <EditorField
          label={translateFieldName("Rubric")}
          id={`CKEditor__${id}__rubric`}
          errors={rubricErrors}
        >
          <EditorCaption
            onChange={onRubricChange}
            id={`CKEditor__${id}__rubric`}
            value={rubricValue}
            build={rubricRichTextBuild}
            autogeneratedValue={rubricAutogenValue}
            aria-invalid={!!rubricErrors?.length}
          />
        </EditorField>
        {item.item && item.item.editUrl && (
          <MetadataDisplay
            target="_blank"
            to={item.item.editUrl ?? ""}
            aria-label={intl.formatMessage(
              {
                defaultMessage: `Edit {type}`,
              },
              { type: translateContentType(item.item.contentType, 1) }
            )}
          >
            <div>
              <ContentTypeIcon
                contentType={item.item.contentType}
                size="regular"
              />
              {translateContentType(item.item.contentType, 1)}

              {item.item?.metadata && (
                <Metadata metadata={item.item?.metadata} />
              )}
            </div>
            <RedirectIcon size="regular" />
          </MetadataDisplay>
        )}
      </ContextualFields>
      <ActionContainer>
        <CloseButton
          size="small"
          aria-label={intl.formatMessage({
            defaultMessage: "Remove Item",
          })}
          onClick={onRemoveItem}
        >
          <CloseIcon size="small" />
        </CloseButton>
        <ItemNumber>{props.index + 1}</ItemNumber>
        <DraggableIcon size="regular" className="draggable-icon" />
      </ActionContainer>
    </Item>
  );
}

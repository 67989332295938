import { Accordion, Spinner } from "@condenast/gemini";
import { useChangeset } from "@hooks";
import {
  GetCurrentUser_currentUser,
  GetBundle_bundle,
  GetCurrentUser_currentUser_organizations,
} from "@types";
import styled from "styled-components";
import { useDefinedMessages } from "@hooks";
import { useContainerOptionsData } from "./useContainerOptionsData";
import {
  DecoratedContainer,
  SaveLayoutFunction,
} from "./ContainerOptions.types";
import { decorateContainers } from "./ContainerOptions.utils";
import { useMemo } from "react";
import Options from "./Options";
import Header from "./Header";

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${(props) => props.theme.DividerColor};
  margin-bottom: var(--spacing-md);
`;

const SpinnerWrapper = styled.div`
  height: var(--spacing-xxl);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerOptions = (props: {
  currentOrganization: GetCurrentUser_currentUser_organizations;
  bundleData: GetBundle_bundle;
  currentUser: GetCurrentUser_currentUser;
  onSave: () => void;
}) => {
  const { loading, layoutConfigs, save, saving } = useContainerOptionsData(
    props.currentOrganization,
    props.bundleData,
    props.onSave
  );

  const containers = useMemo(
    () => decorateContainers(props.bundleData, layoutConfigs),
    [layoutConfigs, props.bundleData]
  );

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner size="small" />
      </SpinnerWrapper>
    );
  }

  return (
    <Accordion>
      {containers.map((container, index) => {
        return (
          <AccordionPanel
            key={container.id}
            container={container}
            index={index}
            save={save}
            saving={saving}
            currentOrganization={props.currentOrganization}
            currentUser={props.currentUser}
          />
        );
      })}
    </Accordion>
  );
};

export const AccordionPanel = (props: {
  container: DecoratedContainer;
  index: number;
  currentOrganization: GetCurrentUser_currentUser_organizations;
  currentUser: GetCurrentUser_currentUser;
  saving: boolean;
  save: SaveLayoutFunction;
}) => {
  const {
    id,
    curationContainerType,
    hed,
    componentPreset,
    presetCategory,
    disableTimeOfPublishOptions,
    model: initialModel,
  } = props.container;

  const [model, setValue, changesetState] = useChangeset(initialModel);
  const { translateComponentPresetsOptions } = useDefinedMessages();

  const hasContainerOptions = Boolean(
    !disableTimeOfPublishOptions && componentPreset
  );
  return (
    <Accordion.Panel
      key={id}
      id={props.index}
      header={
        <Header
          title={hed}
          componentPreset={
            componentPreset && translateComponentPresetsOptions(componentPreset)
          }
          curationContainerType={curationContainerType}
          hasChanges={changesetState.hasChanges}
          hasContainerOptions={hasContainerOptions}
        />
      }
    >
      {hasContainerOptions && (
        <>
          <Divider />
          <Options
            curationContainerType={curationContainerType}
            currentOrganization={props.currentOrganization}
            currentUser={props.currentUser}
            save={props.save.bind(undefined, id)}
            saving={props.saving}
            changesetState={changesetState}
            setValue={setValue}
            model={model}
            presetCategory={presetCategory}
          />
        </>
      )}
    </Accordion.Panel>
  );
};

import { defineMessages } from "react-intl";

export const SELECT_OPTIONS = defineMessages({
  "language.en-GB": {
    defaultMessage: "🇬🇧 English - International",
  },
  "language.en-US": {
    defaultMessage: "🇺🇸 English - USA",
  },
  "language.es-ES": {
    defaultMessage: "🇪🇸 Español (Spanish)",
  },
  "language.de-DE": {
    defaultMessage: "🇩🇪 Deutsch (German)",
  },
  "language.fr-FR": {
    defaultMessage: "🇫🇷 Français (French)",
  },
  "language.it-IT": {
    defaultMessage: "🇮🇹 Italiano (Italian)",
  },
  "language.ja-JP": {
    defaultMessage: "🇯🇵 日本語 (Japanese)",
  },
  "language.ru-RU": {
    defaultMessage: "🇷🇺 Pусский (Russian)",
  },
  "language.zh-Hans": {
    defaultMessage: "🇨🇳 正体字 (Chinese - Simplified)",
  },
  "language.zh-Hant-TW": {
    defaultMessage: "🇹🇼 繁體字 (Chinese - Traditional)",
  },
  "activitySecondaryTypeOptions.active-fitness": {
    defaultMessage: "Active & Fitness",
    description: "selectOptions.activitySecondaryTypeOptions.active-fitness",
  },
  "activitySecondaryTypeOptions.beach": {
    defaultMessage: "Beach",
    description: "selectOptions.activitySecondaryTypeOptions.beach",
  },
  "activitySecondaryTypeOptions.cinema": {
    defaultMessage: "Cinema",
    description: "selectOptions.activitySecondaryTypeOptions.cinema",
  },
  "activitySecondaryTypeOptions.comedy": {
    defaultMessage: "Comedy",
    description: "selectOptions.activitySecondaryTypeOptions.comedy",
  },
  "activitySecondaryTypeOptions.concert": {
    defaultMessage: "Concert",
    description: "selectOptions.activitySecondaryTypeOptions.concert",
  },
  "activitySecondaryTypeOptions.farm": {
    defaultMessage: "Farm",
    description: "selectOptions.activitySecondaryTypeOptions.farm",
  },
  "activitySecondaryTypeOptions.gallery": {
    defaultMessage: "Gallery",
    description: "selectOptions.activitySecondaryTypeOptions.gallery",
  },
  "activitySecondaryTypeOptions.landmark": {
    defaultMessage: "Landmark",
    description: "selectOptions.activitySecondaryTypeOptions.landmark",
  },
  "activitySecondaryTypeOptions.mindfulness": {
    defaultMessage: "Mindfulness",
    description: "selectOptions.activitySecondaryTypeOptions.mindfulness",
  },
  "activitySecondaryTypeOptions.mountain": {
    defaultMessage: "Mountain",
    description: "selectOptions.activitySecondaryTypeOptions.mountain",
  },
  "activitySecondaryTypeOptions.museum": {
    defaultMessage: "Museum",
    description: "selectOptions.activitySecondaryTypeOptions.museum",
  },
  "activitySecondaryTypeOptions.national park": {
    defaultMessage: "National Park",
    description: "selectOptions.activitySecondaryTypeOptions.national park",
  },
  "activitySecondaryTypeOptions.park-garden": {
    defaultMessage: "Park - Garden",
    description: "selectOptions.activitySecondaryTypeOptions.park-garden",
  },
  "activitySecondaryTypeOptions.spa": {
    defaultMessage: "Spa",
    description: "selectOptions.activitySecondaryTypeOptions.spa",
  },
  "activitySecondaryTypeOptions.sports": {
    defaultMessage: "Sport",
    description: "selectOptions.activitySecondaryTypeOptions.sports",
  },
  "activitySecondaryTypeOptions.theater": {
    defaultMessage: "Theater",
    description: "selectOptions.activitySecondaryTypeOptions.theater",
  },
  "activitySecondaryTypeOptions.tour": {
    defaultMessage: "Tour",
    description: "selectOptions.activitySecondaryTypeOptions.tour",
  },
  "activitySecondaryTypeOptions.wellness retreat": {
    defaultMessage: "Wellness Retreat",
    description: "selectOptions.activitySecondaryTypeOptions.wellness retreat",
  },
  "activitySecondaryTypeOptions.yoga": {
    defaultMessage: "Yoga",
    description: "selectOptions.activitySecondaryTypeOptions.yoga",
  },
  "activitySubtypeOptions.Culture": {
    defaultMessage: "Culture",
    description: "selectOptions.activitySubtypeOptions.Culture",
  },
  "activitySubtypeOptions.Health-Wellness": {
    defaultMessage: "Health and Wellness",
    description: "selectOptions.activitySubtypeOptions.Health-Wellness",
  },
  "activitySubtypeOptions.Performance": {
    defaultMessage: "Performance",
    description: "selectOptions.activitySubtypeOptions.Performance",
  },
  "activitySubtypeOptions.Sports-Outdoors": {
    defaultMessage: "Sports & Outdoors",
    description: "selectOptions.activitySubtypeOptions.Sports-Outdoors",
  },
  "activitySubtypeOptions.Studio-Workshop": {
    defaultMessage: "Studios & Workshops",
    description: "selectOptions.activitySubtypeOptions.Studio-Workshop",
  },
  "activitySubtypeOptions.Theme-Park": {
    defaultMessage: "Theme Park",
    description: "selectOptions.activitySubtypeOptions.Theme-Park",
  },
  "activitySubtypeOptions.Winery-Brewery-Distillery": {
    defaultMessage: "Wineries, Breweries, and Distilleries",
    description:
      "selectOptions.activitySubtypeOptions.Winery-Brewery-Distillery",
  },
  "activityFeatureOptions.biking": {
    defaultMessage: "Biking",
    description: "selectOptions.activityFeatureOptions.biking",
  },
  "activityFeatureOptions.diving": {
    defaultMessage: "Diving",
    description: "selectOptions.activityFeatureOptions.diving",
  },
  "activityFeatureOptions.extreme": {
    defaultMessage: "Extreme",
    description: "selectOptions.activityFeatureOptions.extreme",
  },
  "activityFeatureOptions.facial": {
    defaultMessage: "Facial",
    description: "selectOptions.activityFeatureOptions.facial",
  },
  "activityFeatureOptions.fishing": {
    defaultMessage: "Fishing",
    description: "selectOptions.activityFeatureOptions.fishing",
  },
  "activityFeatureOptions.golf": {
    defaultMessage: "Golf",
    description: "selectOptions.activityFeatureOptions.golf",
  },
  "activityFeatureOptions.hiking": {
    defaultMessage: "Hiking",
    description: "selectOptions.activityFeatureOptions.hiking",
  },
  "activityFeatureOptions.manicure": {
    defaultMessage: "Manicure",
    description: "selectOptions.activityFeatureOptions.manicure",
  },
  "activityFeatureOptions.massage": {
    defaultMessage: "Massage",
    description: "selectOptions.activityFeatureOptions.massage",
  },
  "activityFeatureOptions.pedicure": {
    defaultMessage: "Pedicure",
    description: "selectOptions.activityFeatureOptions.pedicure",
  },
  "activityFeatureOptions.pool": {
    defaultMessage: "Pool",
    description: "selectOptions.activityFeatureOptions.pool",
  },
  "activityFeatureOptions.scrub": {
    defaultMessage: "Scrub",
    description: "selectOptions.activityFeatureOptions.scrub",
  },
  "activityFeatureOptions.skiing": {
    defaultMessage: "Skiing",
    description: "selectOptions.activityFeatureOptions.skiing",
  },
  "activityFeatureOptions.snowboard": {
    defaultMessage: "Snowboard",
    description: "selectOptions.activityFeatureOptions.snowboard",
  },
  "activityFeatureOptions.surfing": {
    defaultMessage: "Surfing",
    description: "selectOptions.activityFeatureOptions.surfing",
  },
  "activityFeatureOptions.tennis": {
    defaultMessage: "Tennis",
    description: "selectOptions.activityFeatureOptions.tennis",
  },
  "awardNameOptions.Best of Beauty": {
    defaultMessage: "Best of Beauty",
    description: "selectOptions.awardNameOptions.Best of Beauty",
  },
  "awardNameOptions.Best of Beauty Breakthrough": {
    defaultMessage: "Best of Beauty Breakthrough",
    description: "selectOptions.awardNameOptions.Best of Beauty Breakthrough",
  },
  "awardNameOptions.Readers' Choice": {
    defaultMessage: "Readers' Choice",
    description: "selectOptions.awardNameOptions.Readers' Choice",
  },
  "awardNameOptions.Readers' Choice Breakthrough": {
    defaultMessage: "Readers' Choice Breakthrough",
    description: "selectOptions.awardNameOptions.Readers' Choice Breakthrough",
  },
  "bylineOptions.brand_x_advertiser": {
    defaultMessage: "Brand X Advertiser",
    description: "selectOptions.bylineOptions.brand_x_advertiser",
  },
  "bylineOptions.brand_presents_advertiser": {
    defaultMessage: "Brand Presents Advertiser",
    description: "selectOptions.bylineOptions.brand_presents_advertiser",
  },
  "bylineOptions.branded_content": {
    defaultMessage: "Branded content by",
    description: "selectOptions.bylineOptions.branded_content",
  },
  "bylineOptions.created": {
    defaultMessage: "Created by brand for advertiser",
    description: "selectOptions.bylineOptions.created",
  },
  "bylineOptions.in_collaboration": {
    defaultMessage: "In collaboration with",
    description: "selectOptions.bylineOptions.in_collaboration",
  },
  "bylineOptions.in_partnership": {
    defaultMessage: "In partnership with",
    description: "selectOptions.bylineOptions.in_partnership",
  },
  "bylineOptions.paid_post": {
    defaultMessage: "Paid post",
    description: "selectOptions.bylineOptions.paid_post",
  },
  "bylineOptions.paid_post_by_advertiser_brought_to_you_by_business_reporter": {
    defaultMessage:
      "Paid Post by Advertiser, Brought to you by Business Reporter",
    description:
      "selectOptions.bylineOptions.paid_post_by_advertiser_brought_to_you_by_business_reporter",
  },
  "bylineOptions.original_content_by": {
    defaultMessage: "Original Content by",
    description: "selectOptions.bylineOptions.original_content_by",
  },
  "bylineOptions.produced": {
    defaultMessage: "Produced by",
    description: "selectOptions.bylineOptions.produced",
  },
  "bylineOptions.produced_by_advertiser": {
    defaultMessage: "Produced by",
    description: "selectOptions.bylineOptions.produced_by_advertiser",
  },
  "bylineOptions.presented_by_advertiser": {
    defaultMessage: "Presented by",
    description: "selectOptions.bylineOptions.presented_by_advertiser",
  },
  "bylineOptions.produced_by_brand": {
    defaultMessage: "Produced by brand with advertiser",
    description: "selectOptions.bylineOptions.produced_by_brand",
  },
  "bylineOptions.sponsored": {
    defaultMessage: "Sponsored content",
    description: "selectOptions.bylineOptions.sponsored",
  },
  "bylineOptions.sponsored_by": {
    defaultMessage: "Sponsored by",
    description: "selectOptions.bylineOptions.sponsored_by",
  },
  "bylineOptions.sponsored_content": {
    defaultMessage: "Sponsored content by",
    description: "selectOptions.bylineOptions.sponsored_content",
  },
  "bylineOptions.together_with": {
    defaultMessage: "Together with",
    description: "selectOptions.bylineOptions.sponsored_content",
  },
  "bylineOptions.advertisement_feature_with": {
    defaultMessage: "Advertisement Feature With",
    description: "selectOptions.bylineOptions.advertisement_feature_with",
  },
  "bylineOptions.advertisement_by_advertiser": {
    defaultMessage: "Advertisement by",
    description: "selectOptions.bylineOptions.advertisement_by_advertiser",
  },
  "bylineOptions.ad": {
    defaultMessage: "Ad",
    description: "selectOptions.bylineOptions.ad",
  },
  "bylineOptions.sponsored_by_advertiser": {
    defaultMessage: "Sponsored by",
    description: "selectOptions.bylineOptions.sponsored_by_advertiser",
  },
  "bylineOptions.in_partnership_with_advertiser": {
    defaultMessage: "In Partnership with",
    description: "selectOptions.bylineOptions.in_partnership_with_advertiser",
  },
  "bylineOptions.advertising": {
    defaultMessage: "Advertising",
    description: "selectOptions.bylineOptions.advertising",
  },
  "bylineOptions.advertisement": {
    defaultMessage: "Advertisement",
    description: "selectOptions.bylineOptions.advertisement",
  },
  "bylineOptions.promoted_by_advertiser": {
    defaultMessage: "Promoted by",
    description: "selectOptions.bylineOptions.promoted_by_advertiser",
  },
  "bylineOptions.promotion": {
    defaultMessage: "Promotion",
    description: "selectOptions.bylineOptions.promotion",
  },
  "bylineOptions.paid_partnership_with_advertiser": {
    defaultMessage: "Paid Partnership with",
    description: "selectOptions.bylineOptions.paid_partnership_with_advertiser",
  },
  "bylineOptions.published_by_advertiser": {
    defaultMessage: "Published by",
    description: "selectOptions.bylineOptions.published_by_advertiser",
  },
  "bylineOptions.affiliate_partner": {
    defaultMessage: "Affiliate partner",
    description: "selectOptions.bylineOptions.affiliate_partner",
  },
  "bylineOptions.paid_promotion_by_advertiser": {
    defaultMessage: "Paid Promotion by",
    description: "selectOptions.bylineOptions.paid_promotion_by_advertiser",
  },
  "bylineOptions.special_feature": {
    defaultMessage: "Special Feature",
    description: "selectOptions.bylineOptions.special_feature",
  },
  "bylineOptions.pr": {
    defaultMessage: "PR",
    description: "selectOptions.bylineOptions.pr",
  },
  "contentSourceOptions.bon_appetit": {
    defaultMessage: "Bon Appetit",
    description: "selectOptions.contentSourceOptions.bon_appetit",
  },
  "contentSourceOptions.climate_desk": {
    defaultMessage: "Climate Desk",
    description: "selectOptions.contentSourceOptions.climate_desk",
  },
  "contentSourceOptions.digital_syndication": {
    defaultMessage: "Digital syndication",
    description: "selectOptions.contentSourceOptions.digital_syndication",
  },
  "contentSourceOptions.epicurious": {
    defaultMessage: "Epicurious",
    description: "selectOptions.contentSourceOptions.epicurious",
  },
  "contentSourceOptions.external_agency": {
    defaultMessage: "External agency",
    description: "selectOptions.contentSourceOptions.external_agency",
  },
  "contentSourceOptions.external_partner": {
    defaultMessage: "External partner",
    description: "selectOptions.contentSourceOptions.external_partner",
  },
  "contentSourceOptions.gold_list": {
    defaultMessage: "Gold List",
    description: "selectOptions.contentSourceOptions.gold_list",
  },
  "contentSourceOptions.gourmet": {
    defaultMessage: "Gourmet",
    description: "selectOptions.contentSourceOptions.gourmet",
  },
  "contentSourceOptions.hot_list": {
    defaultMessage: "Hot List",
    description: "selectOptions.contentSourceOptions.hot_list",
  },
  "contentSourceOptions.licensed_partner": {
    defaultMessage: "Licensed partner",
    description: "selectOptions.contentSourceOptions.licensed_partner",
  },
  "contentSourceOptions.magazine": {
    defaultMessage: "Magazine",
    description: "selectOptions.contentSourceOptions.magazine",
  },
  "contentSourceOptions.quanta_magazine": {
    defaultMessage: "Quanta Magazine",
    description: "selectOptions.contentSourceOptions.quanta_magazine",
  },
  "contentSourceOptions.random_house": {
    defaultMessage: "Random House",
    description: "selectOptions.contentSourceOptions.random_house",
  },
  "contentSourceOptions.rca": {
    defaultMessage: "RCA",
    description: "selectOptions.contentSourceOptions.rca",
  },
  "contentSourceOptions.self": {
    defaultMessage: "Self",
    description: "selectOptions.contentSourceOptions.self",
  },
  "contentSourceOptions.slideshows": {
    defaultMessage: "Galleries",
    description: "selectOptions.contentSourceOptions.slideshows",
  },
  "contentSourceOptions.social_news_desk": {
    defaultMessage: "Social news desk",
    description: "selectOptions.contentSourceOptions.social_news_desk",
  },
  "contentSourceOptions.vogue_international": {
    defaultMessage: "Vogue Global Network",
    description: "selectOptions.contentSourceOptions.vogue_international",
  },
  "contentSourceOptions.web": {
    defaultMessage: "Web",
    description: "selectOptions.contentSourceOptions.web",
  },
  "contentSourceOptions.local_hub": {
    defaultMessage: "Local Hub",
    description: "selectOptions.contentSourceOptions.local_hub",
  },
  "contentTypeFilterOptions.article": {
    defaultMessage: "Article",
    description: "selectOptions.contentTypeFilterOptions.article",
  },
  "contentTypeFilterOptions.advertiser": {
    defaultMessage: "Advertiser",
    description: "selectOptions.contentTypeFilterOptions.advertiser",
  },
  "contentTypeFilterOptions.business": {
    defaultMessage: "Business",
    description: "selectOptions.contentTypeFilterOptions.business",
  },
  "contentTypeFilterOptions.brand": {
    defaultMessage: "Brand",
    description: "selectOptions.contentTypeFilterOptions.brand",
  },
  "contentTypeFilterOptions.bundle": {
    defaultMessage: "Bundle",
    description: "selectOptions.contentTypeFilterOptions.bundle",
  },
  "contentTypeFilterOptions.cnevideo": {
    defaultMessage: "CNE Video",
    description: "selectOptions.contentTypeFilterOptions.cnevideo",
  },
  "contentTypeFilterOptions.clip": {
    defaultMessage: "Clip",
    description: "selectOptions.contentTypeFilterOptions.clip",
  },
  "contentTypeFilterOptions.contributor": {
    defaultMessage: "Contributor",
    description: "selectOptions.contentTypeFilterOptions.contributor",
  },
  "contentTypeFilterOptions.curatedsearch": {
    defaultMessage: "Curated Search",
    description: "selectOptions.contentTypeFilterOptions.curatedsearch",
  },
  "contentTypeFilterOptions.curatedlist": {
    defaultMessage: "Curated List",
    description: "selectOptions.contentTypeFilterOptions.curatedlist",
  },
  "contentTypeFilterOptions.externallink": {
    defaultMessage: "External Link",
    description: "selectOptions.contentTypeFilterOptions.externallink",
  },
  "contentTypeFilterOptions.gallery": {
    defaultMessage: "Gallery",
    description: "selectOptions.contentTypeFilterOptions.gallery",
  },
  "contentTypeFilterOptions.infopage": {
    defaultMessage: "Info Page",
    description: "selectOptions.contentTypeFilterOptions.infopage",
  },
  "contentTypeFilterOptions.livestory": {
    defaultMessage: "Live Story",
    description: "selectOptions.contentTypeFilterOptions.livestory",
  },
  "contentTypeFilterOptions.nativearticle": {
    defaultMessage: "Native Article",
    description: "selectOptions.contentTypeFilterOptions.nativearticle",
  },
  "contentTypeFilterOptions.nativegallery": {
    defaultMessage: "Native Gallery",
    description: "selectOptions.contentTypeFilterOptions.nativegallery",
  },
  "contentTypeFilterOptions.nativerecipe": {
    defaultMessage: "Native Recipe",
    description: "selectOptions.contentTypeFilterOptions.nativerecipe",
  },
  "contentTypeFilterOptions.photo": {
    defaultMessage: "Photo",
    description: "selectOptions.contentTypeFilterOptions.photo",
  },
  "contentTypeFilterOptions.product": {
    defaultMessage: "Product",
    description: "selectOptions.contentTypeFilterOptions.product",
  },
  "contentTypeFilterOptions.redirect": {
    defaultMessage: "Redirect",
    description: "selectOptions.contentTypeFilterOptions.redirect",
  },
  "contentTypeFilterOptions.tagpage": {
    defaultMessage: "Tag Page",
    description: "selectOptions.contentTypeFilterOptions.tagpage",
  },
  "contentTypeFilterOptions.review": {
    defaultMessage: "Review",
    description: "selectOptions.contentTypeFilterOptions.review",
  },
  "contentTypeFilterOptions.recipe": {
    defaultMessage: "Recipe",
    description: "selectOptions.contentTypeFilterOptions.recipe",
  },
  "contentTypeFilterOptions.venue": {
    defaultMessage: "Venue",
    description: "selectOptions.contentTypeFilterOptions.venue",
  },
  "countryCodes.GB": {
    defaultMessage: "Great Britain",
    description: "selectOptions.countryCodes.GB",
  },
  "countryCodes.US": {
    defaultMessage: "United States",
    description: "selectOptions.countryCodes.US",
  },
  "curatedList.curationSubType.AssetsOnly": {
    defaultMessage: "Default",
    description: "selectOptions.curatedList.curationSubType.default",
  },
  "curatedList.curationSubType.LinkList": {
    defaultMessage: "Link List",
    description: "selectOptions.curatedList.curationSubType.linkList",
  },
  "curatedList.curationSubType.LinkBannerMarquee": {
    defaultMessage: "Link Banner - Marquee",
    description: "selectOptions.curatedList.curationSubType.marquee",
  },
  "curatedList.curationSubType.LinkBannerMultipleLinks": {
    defaultMessage: "Link Banner - Multiple Links",
    description: "selectOptions.curatedList.curationSubType.multipleLinks",
  },
  "curatedList.curationSubType.LinkBannerVisual": {
    defaultMessage: "Link Banner - Visual",
    description: "selectOptions.curatedList.curationSubType.visual",
  },
  "operatorLogicOptions.and": {
    defaultMessage: "AND",
    description: "selectOptions.operatorLogicOptions.and",
  },
  "operatorLogicOptions.or": {
    defaultMessage: "OR",
    description: "selectOptions.operatorLogicOptions.or",
  },
  "curationTypeOptions.AssetsOnly": {
    defaultMessage: "Assets only (default)",
    description: "selectOptions.curationTypeOptions.AssetsOnly",
  },
  "curationTypeOptions.LinkList": {
    defaultMessage: "Link list",
    description: "selectOptions.curationTypeOptions.LinkList",
  },
  "curationTypeOptions.LinkBannerMarquee": {
    defaultMessage: "Link Banner - Marquee",
    description: "selectOptions.curationTypeOptions.LinkBannerMarquee",
  },
  "curationTypeOptions.LinkBannerMultipleLinks": {
    defaultMessage: "Link Banner - Multiple Links",
    description: "selectOptions.curationTypeOptions.LinkBannerMultipleLinks",
  },
  "curationTypeOptions.LinkBannerVisual": {
    defaultMessage: "Visual Link Banner",
    description: "selectOptions.curationTypeOptions.LinkBannerVisual",
  },
  "currencyOptions.EUR": {
    defaultMessage: "€ - Euro",
    description: "selectOptions.currencyOptions.EUR",
  },
  "currencyOptions.GBP": {
    defaultMessage: "£ - Pound sterling",
    description: "selectOptions.currencyOptions.GBP",
  },
  "currencyOptions.INR": {
    defaultMessage: "₹ - Indian rupee",
    description: "selectOptions.currencyOptions.INR",
  },
  "currencyOptions.JPY": {
    defaultMessage: "¥ - Japanese yen",
    description: "selectOptions.currencyOptions.JPY",
  },
  "currencyOptions.MXN": {
    defaultMessage: "Mex$ - Mexican peso",
    description: "selectOptions.currencyOptions.MXN",
  },
  "currencyOptions.RUB": {
    defaultMessage: "₽ - Russian ruble",
    description: "selectOptions.currencyOptions.RUB",
  },
  "currencyOptions.SAR": {
    defaultMessage: "﷼ - Saudi riyal",
    description: "selectOptions.currencyOptions.SAR",
  },
  "currencyOptions.TWD": {
    defaultMessage: "NT$ - New Taiwan dollar",
    description: "selectOptions.currencyOptions.TWD",
  },
  "currencyOptions.USD": {
    defaultMessage: "$ - US Dollar",
    description: "selectOptions.currencyOptions.USD",
  },
  "focusModule.overlayTextPositionOptions.right": {
    defaultMessage: "Text-Right",
    description: "focusModule.overlayTextPositionOptions.right",
  },
  "focusModule.overlayTextPositionOptions.left": {
    defaultMessage: "Text-Left",
    description: "focusModule.overlayTextPositionOptions.left",
  },
  "focusModule.overlayTextPositionOptions.start": {
    defaultMessage: "Text-Top",
    description: "focusModule.overlayTextPositionOptions.start",
  },
  "focusModule.overlayTextPositionOptions.end": {
    defaultMessage: "Text-Bottom",
    description: "focusModule.overlayTextPositionOptions.end",
  },
  "focusModule.textPositionOptions.end": {
    defaultMessage: "Text Right",
    description: "selectOptions.focusModule.textPositionOptions.end",
  },
  "focusModule.textPositionOptions.start": {
    defaultMessage: "Text Left",
    description: "selectOptions.focusModule.textPositionOptions.start",
  },
  "getty.sortOrder.best_match": {
    defaultMessage: "Best Match",
    description: "selectOptions.getty.sortOrder.best_match",
  },
  "getty.sortOrder.most_popular": {
    defaultMessage: "Most Popular",
    description: "selectOptions.getty.sortOrder.most_popular",
  },
  "getty.sortOrder.newest": {
    defaultMessage: "Newest",
    description: "selectOptions.getty.sortOrder.newest",
  },
  "getty.sortOrder.oldest": {
    defaultMessage: "Oldest",
    description: "selectOptions.getty.sortOrder.oldest",
  },
  "getty.sortOrder.random": {
    defaultMessage: "Random",
    description: "selectOptions.getty.sortOrder.random",
  },
  "getty.imageType.editorial": {
    defaultMessage: "Editorial",
    description: "selectOptions.getty.imageType.editorial",
  },
  "getty.imageType.creative": {
    defaultMessage: "Creative",
    description: "selectOptions.getty.imageType.creative",
  },
  "gettyvideo.videoType.editorial": {
    defaultMessage: "Editorial",
    description: "selectOptions.gettyvideo.videoType.editorial",
  },
  "gettyvideo.videoType.creative": {
    defaultMessage: "Creative",
    description: "selectOptions.gettyvideo.videoType.creative",
  },
  "networkOptions.business.Instagram": {
    defaultMessage: "Instagram",
    description: "selectOptions.networkOptions.business.Instagram",
  },
  "networkOptions.contributor.Facebook": {
    defaultMessage: "Facebook",
    description: "selectOptions.networkOptions.contributor.Facebook",
  },
  "networkOptions.contributor.Google+": {
    defaultMessage: "Google+",
    description: "selectOptions.networkOptions.contributor.Google+",
  },
  "networkOptions.contributor.Instagram": {
    defaultMessage: "Instagram",
    description: "selectOptions.networkOptions.contributor.Instagram",
  },
  "networkOptions.contributor.LinkedIn": {
    defaultMessage: "LinkedIn",
    description: "selectOptions.networkOptions.contributor.LinkedIn",
  },
  "networkOptions.contributor.Pinterest": {
    defaultMessage: "Pinterest",
    description: "selectOptions.networkOptions.contributor.Pinterest",
  },
  "networkOptions.contributor.Threads": {
    defaultMessage: "Threads",
    description: "selectOptions.networkOptions.contributor.Threads",
  },
  "networkOptions.contributor.Tumblr": {
    defaultMessage: "Tumblr",
    description: "selectOptions.networkOptions.contributor.Tumblr",
  },
  "networkOptions.contributor.Twitter": {
    defaultMessage: "X",
    description: "selectOptions.networkOptions.contributor.Twitter",
  },
  "networkOptions.contributor.Vogue.com": {
    defaultMessage: "Vogue.com",
    description: "selectOptions.networkOptions.contributor.Vogue.com",
  },
  "networkOptions.contributor.WordPress": {
    defaultMessage: "WordPress",
    description: "selectOptions.networkOptions.contributor.WordPress",
  },
  "networkOptions.venue.Facebook": {
    defaultMessage: "Facebook",
    description: "selectOptions.networkOptions.venue.Facebook",
  },
  "networkOptions.venue.Twitter": {
    defaultMessage: "Twitter",
    description: "selectOptions.networkOptions.venue.Twitter",
  },
  "networkOptions.venue.LinkedIn": {
    defaultMessage: "LinkedIn",
    description: "selectOptions.networkOptions.venue.LinkedIn",
  },
  "networkOptions.venue.Instagram": {
    defaultMessage: "Instagram",
    description: "selectOptions.networkOptions.venue.Instagram",
  },
  "networkOptions.venue.Google+": {
    defaultMessage: "Google+",
    description: "selectOptions.networkOptions.venue.Google+",
  },
  "networkOptions.venue.Pinterest": {
    defaultMessage: "Pinterest",
    description: "selectOptions.networkOptions.venue.Pinterest",
  },
  "networkOptions.venue.Tumblr": {
    defaultMessage: "Tumblr",
    description: "selectOptions.networkOptions.venue.Tumblr",
  },
  "networkOptions.venue.Vogue.com": {
    defaultMessage: "Vogue.com",
    description: "selectOptions.networkOptions.venue.Vogue.com",
  },
  "networkOptions.venue.WordPress": {
    defaultMessage: "WordPress",
    description: "selectOptions.networkOptions.venue.WordPress",
  },
  "offerTypeOptions.PURCHASE": {
    defaultMessage: "Purchase",
    description: "selectOptions.offerTypeOptions.purchase",
  },
  "offerTypeOptions.TRIAL": {
    defaultMessage: "Trial",
    description: "selectOptions.offerTypeOptions.trial",
  },
  "paymentOptions.amex": {
    defaultMessage: "Amex",
    description: "selectOptions.paymentOptions.amex",
  },
  "paymentOptions.mc": {
    defaultMessage: "Mastercard",
    description: "selectOptions.paymentOptions.mc",
  },
  "paymentOptions.visa": {
    defaultMessage: "Visa",
    description: "selectOptions.paymentOptions.visa",
  },
  "paymentOptions.discover": {
    defaultMessage: "Discover",
    description: "selectOptions.paymentOptions.discover",
  },
  "paymentOptions.cash": {
    defaultMessage: "Cash",
    description: "selectOptions.paymentOptions.cash",
  },
  "paymentOptions.check": {
    defaultMessage: "Check",
    description: "selectOptions.paymentOptions.check",
  },
  "productTypeOptions.Acne treatment": {
    defaultMessage: "Acne treatment",
    description: "selectOptions.productTypeOptions.Acne treatment",
  },
  "productTypeOptions.Appliances": {
    defaultMessage: "Appliances",
    description: "selectOptions.productTypeOptions.Appliances",
  },
  "productTypeOptions.Cooking Tools": {
    defaultMessage: "Cooking Tools",
    description: "selectOptions.productTypeOptions.Cooking Tools",
  },
  "productTypeOptions.Cutlery": {
    defaultMessage: "Cutlery",
    description: "selectOptions.productTypeOptions.Cutlery",
  },
  "productTypeOptions.Ingredients": {
    defaultMessage: "Ingredients",
    description: "selectOptions.productTypeOptions.Ingredients",
  },
  "productTypeOptions.Pots & Pans": {
    defaultMessage: "Pots & Pans",
    description: "selectOptions.productTypeOptions.Pots & Pans",
  },
  "productTypeOptions.Servingware": {
    defaultMessage: "Servingware",
    description: "selectOptions.productTypeOptions.Servingware",
  },
  "productTypeOptions.Anti-Ager": {
    defaultMessage: "Anti-Ager",
    description: "selectOptions.productTypeOptions.Anti-Ager",
  },
  "productTypeOptions.At-home peel": {
    defaultMessage: "At-home peel",
    description: "selectOptions.productTypeOptions.At-home peel",
  },
  "productTypeOptions.Bath product": {
    defaultMessage: "Bath product",
    description: "selectOptions.productTypeOptions.Bath product",
  },
  "productTypeOptions.Blush": {
    defaultMessage: "Blush",
    description: "selectOptions.productTypeOptions.Blush",
  },
  "productTypeOptions.Body moisturizer": {
    defaultMessage: "Body moisturizer",
    description: "selectOptions.productTypeOptions.Body moisturizer",
  },
  "productTypeOptions.Body scrub": {
    defaultMessage: "Body scrub",
    description: "selectOptions.productTypeOptions.Body scrub",
  },
  "productTypeOptions.Body sunscreen": {
    defaultMessage: "Body sunscreen",
    description: "selectOptions.productTypeOptions.Body sunscreen",
  },
  "productTypeOptions.Bodywash": {
    defaultMessage: "Bodywash",
    description: "selectOptions.productTypeOptions.Bodywash",
  },
  "productTypeOptions.Bronzer": {
    defaultMessage: "Bronzer",
    description: "selectOptions.productTypeOptions.Bronzer",
  },
  "productTypeOptions.Brow groomer": {
    defaultMessage: "Brow groomer",
    description: "selectOptions.productTypeOptions.Brow groomer",
  },
  "productTypeOptions.Cleanser": {
    defaultMessage: "Cleanser",
    description: "selectOptions.productTypeOptions.Cleanser",
  },
  "productTypeOptions.Cleansing Conditioner": {
    defaultMessage: "Cleansing Conditioner",
    description: "selectOptions.productTypeOptions.Cleansing Conditioner",
  },
  "productTypeOptions.Cleansing wipes": {
    defaultMessage: "Cleansing wipes",
    description: "selectOptions.productTypeOptions.Cleansing wipes",
  },
  "productTypeOptions.Conditioner": {
    defaultMessage: "Conditioner",
    description: "selectOptions.productTypeOptions.Conditioner",
  },
  "productTypeOptions.Curl-definer": {
    defaultMessage: "Curl-definer",
    description: "selectOptions.productTypeOptions.Curl-definer",
  },
  "productTypeOptions.Default": {
    defaultMessage: "Default",
    description: "selectOptions.productTypeOptions.Default",
  },
  "productTypeOptions.Defrizzer": {
    defaultMessage: "Defrizzer",
    description: "selectOptions.productTypeOptions.Defrizzer",
  },
  "productTypeOptions.Deodorant": {
    defaultMessage: "Deodorant",
    description: "selectOptions.productTypeOptions.Deodorant",
  },
  "productTypeOptions.Dry shampoo": {
    defaultMessage: "Dry shampoo",
    description: "selectOptions.productTypeOptions.Dry shampoo",
  },
  "productTypeOptions.Do Not Recommend": {
    defaultMessage: "Do Not Recommend",
    description: "selectOptions.productTypeOptions.Do Not Recommend",
  },
  "productTypeOptions.Eye cream": {
    defaultMessage: "Eye cream",
    description: "selectOptions.productTypeOptions.Eye cream",
  },
  "productTypeOptions.Eye shadow": {
    defaultMessage: "Eye shadow",
    description: "selectOptions.productTypeOptions.Eye shadow",
  },
  "productTypeOptions.Eyeliner": {
    defaultMessage: "Eyeliner",
    description: "selectOptions.productTypeOptions.Eyeliner",
  },
  "productTypeOptions.Face Mask": {
    defaultMessage: "Face Mask",
    description: "selectOptions.productTypeOptions.Face Mask",
  },
  "productTypeOptions.Face scrub": {
    defaultMessage: "Face scrub",
    description: "selectOptions.productTypeOptions.Face scrub",
  },
  "productTypeOptions.Facial moisturizer": {
    defaultMessage: "Facial moisturizer",
    description: "selectOptions.productTypeOptions.Facial moisturizer",
  },
  "productTypeOptions.Facial sunscreen": {
    defaultMessage: "Facial sunscreen",
    description: "selectOptions.productTypeOptions.Facial sunscreen",
  },
  "productTypeOptions.False eyelashes": {
    defaultMessage: "False eyelashes",
    description: "selectOptions.productTypeOptions.False eyelashes",
  },
  "productTypeOptions.Foundation": {
    defaultMessage: "Foundation",
    description: "selectOptions.productTypeOptions.Foundation",
  },
  "productTypeOptions.Gel polish": {
    defaultMessage: "Gel polish",
    description: "selectOptions.productTypeOptions.Gel polish",
  },
  "productTypeOptions.Gel": {
    defaultMessage: "Gel",
    description: "selectOptions.productTypeOptions.Gel",
  },
  "productTypeOptions.Hair color": {
    defaultMessage: "Hair color",
    description: "selectOptions.productTypeOptions.Hair color",
  },
  "productTypeOptions.Hair Oil": {
    defaultMessage: "Hair Oil",
    description: "selectOptions.productTypeOptions.Hair Oil",
  },
  "productTypeOptions.Hair remover": {
    defaultMessage: "Hair remover",
    description: "selectOptions.productTypeOptions.Hair remover",
  },
  "productTypeOptions.Hair spray": {
    defaultMessage: "Hair spray",
    description: "selectOptions.productTypeOptions.Hair spray",
  },
  "productTypeOptions.Hair Tools": {
    defaultMessage: "Hair Tools",
    description: "selectOptions.productTypeOptions.Hair Tools",
  },
  "productTypeOptions.Hair/Scalp Treatment": {
    defaultMessage: "Hair/Scalp Treatment",
    description: "selectOptions.productTypeOptions.Hair/Scalp Treatment",
  },
  "productTypeOptions.Hand cream": {
    defaultMessage: "Hand cream",
    description: "selectOptions.productTypeOptions.Hand cream",
  },
  "productTypeOptions.Heat protectant": {
    defaultMessage: "Heat protectant",
    description: "selectOptions.productTypeOptions.Heat protectant",
  },
  "productTypeOptions.Highlighter": {
    defaultMessage: "Highlighter",
    description: "selectOptions.productTypeOptions.Highlighter",
  },
  "productTypeOptions.Home Fragrance": {
    defaultMessage: "Home Fragrance",
    description: "selectOptions.productTypeOptions.Home Fragrance",
  },
  "productTypeOptions.Leave-in Conditioner": {
    defaultMessage: "Leave-in Conditioner",
    description: "selectOptions.productTypeOptions.Leave-in Conditioner",
  },
  "productTypeOptions.Lip balm": {
    defaultMessage: "Lip balm",
    description: "selectOptions.productTypeOptions.Lip balm",
  },
  "productTypeOptions.Lip crayon": {
    defaultMessage: "Lip crayon",
    description: "selectOptions.productTypeOptions.Lip crayon",
  },
  "productTypeOptions.Lip gloss": {
    defaultMessage: "Lip gloss",
    description: "selectOptions.productTypeOptions.Lip gloss",
  },
  "productTypeOptions.Lip liner": {
    defaultMessage: "Lip liner",
    description: "selectOptions.productTypeOptions.Lip liner",
  },
  "productTypeOptions.Lipstick": {
    defaultMessage: "Lipstick",
    description: "selectOptions.productTypeOptions.Lipstick",
  },
  "productTypeOptions.Liquid lipstick": {
    defaultMessage: "Liquid lipstick",
    description: "selectOptions.productTypeOptions.Liquid lipstick",
  },
  "productTypeOptions.Makeup remover": {
    defaultMessage: "Makeup remover",
    description: "selectOptions.productTypeOptions.Makeup remover",
  },
  "productTypeOptions.Makeup Tools": {
    defaultMessage: "Makeup Tools",
    description: "selectOptions.productTypeOptions.Makeup Tools",
  },
  "productTypeOptions.Mascara": {
    defaultMessage: "Mascara",
    description: "selectOptions.productTypeOptions.Mascara",
  },
  "productTypeOptions.Mask": {
    defaultMessage: "Mask",
    description: "selectOptions.productTypeOptions.Mask",
  },
  "productTypeOptions.Men’s Fragrance": {
    defaultMessage: "Men’s Fragrance",
    description: "selectOptions.productTypeOptions.Men’s Fragrance",
  },
  "productTypeOptions.Mousse": {
    defaultMessage: "Mousse",
    description: "selectOptions.productTypeOptions.Mousse",
  },
  "productTypeOptions.Nail accessories": {
    defaultMessage: "Nail accessories",
    description: "selectOptions.productTypeOptions.Nail accessories",
  },
  "productTypeOptions.Nail care tools": {
    defaultMessage: "Nail care tools",
    description: "selectOptions.productTypeOptions.Nail care tools",
  },
  "productTypeOptions.Nail polish": {
    defaultMessage: "Nail polish",
    description: "selectOptions.productTypeOptions.Nail polish",
  },
  "productTypeOptions.Nail Treatment": {
    defaultMessage: "Nail Treatment",
    description: "selectOptions.productTypeOptions.Nail Treatment",
  },
  "productTypeOptions.Polish remover": {
    defaultMessage: "Polish remover",
    description: "selectOptions.productTypeOptions.Polish remover",
  },
  "productTypeOptions.Powder": {
    defaultMessage: "Powder",
    description: "selectOptions.productTypeOptions.Powder",
  },
  "productTypeOptions.Primer": {
    defaultMessage: "Primer",
    description: "selectOptions.productTypeOptions.Primer",
  },
  "productTypeOptions.Self tanner": {
    defaultMessage: "Self tanner",
    description: "selectOptions.productTypeOptions.Self tanner",
  },
  "productTypeOptions.Shampoo": {
    defaultMessage: "Shampoo",
    description: "selectOptions.productTypeOptions.Shampoo",
  },
  "productTypeOptions.Spot concealer": {
    defaultMessage: "Spot concealer",
    description: "selectOptions.productTypeOptions.Spot concealer",
  },
  "productTypeOptions.Styling cream": {
    defaultMessage: "Styling cream",
    description: "selectOptions.productTypeOptions.Styling cream",
  },
  "productTypeOptions.Texturizing Spray": {
    defaultMessage: "Texturizing Spray",
    description: "selectOptions.productTypeOptions.Texturizing Spray",
  },
  "productTypeOptions.Tinted moisturizer/BB Cream/CC Cream": {
    defaultMessage: "Tinted moisturizer/BB Cream/CC Cream",
    description:
      "selectOptions.productTypeOptions.Tinted moisturizer/BB Cream/CC Cream",
  },
  "productTypeOptions.Toothpaste/Whitener": {
    defaultMessage: "Toothpaste/Whitener",
    description: "selectOptions.productTypeOptions.Toothpaste/Whitener",
  },
  "productTypeOptions.Undereye concealer": {
    defaultMessage: "Undereye concealer",
    description: "selectOptions.productTypeOptions.Undereye concealer",
  },
  "productTypeOptions.Volumizer": {
    defaultMessage: "Volumizer",
    description: "selectOptions.productTypeOptions.Volumizer",
  },
  "productTypeOptions.Wave enhancer": {
    defaultMessage: "Wave enhancer",
    description: "selectOptions.productTypeOptions.Wave enhancer",
  },
  "productTypeOptions.Wax/pomade": {
    defaultMessage: "Wax/pomade",
    description: "selectOptions.productTypeOptions.Wax/pomade",
  },
  "productTypeOptions.Women’s Fragrance": {
    defaultMessage: "Women’s Fragrance",
    description: "selectOptions.productTypeOptions.Women’s Fragrance",
  },
  "productTypeOptions.Recommend": {
    defaultMessage: "Recommend",
    description: "selectOptions.productTypeOptions.Recommend",
  },
  "recipe.typeOptions.editorial": {
    defaultMessage: "Editorial",
    description: "selectOptions.recipe.typeOptions.editorial",
  },
  "recipe.typeOptions.member": {
    defaultMessage: "Member",
    description: "selectOptions.recipe.typeOptions.member",
  },
  "recipe.typeOptions.sponsored": {
    defaultMessage: "Sponsored",
    description: "selectOptions.recipe.typeOptions.sponsored",
  },
  "recipe.typeOptions.thirdparty": {
    defaultMessage: "Third Party",
    description: "selectOptions.recipe.typeOptions.thirdparty",
  },
  "person.genderOptions.M": {
    defaultMessage: "Male",
    description: "selectOptions.person.genderOptions.Male",
  },
  "person.genderOptions.F": {
    defaultMessage: "Female",
    description: "selectOptions.person.genderOptions.Female",
  },
  "person.roleOptions.CELEBRITY": {
    defaultMessage: "Celebrity",
    description: "selectOptions.person.roleOptions.Celebrity",
  },
  "person.roleOptions.DESIGNER": {
    defaultMessage: "Designer",
    description: "selectOptions.person.roleOptions.Designer",
  },
  "person.roleOptions.MODEL": {
    defaultMessage: "Model",
    description: "selectOptions.person.roleOptions.Model",
  },
  "tagOptions.Couples": {
    defaultMessage: "Couples",
    description: "selectOptions.tagOptions.Couples",
  },
  "tagOptions.Featured": {
    defaultMessage: "Featured",
    description: "selectOptions.tagOptions.Featured",
  },
  "tagOptions.Hall of Fame": {
    defaultMessage: "Hall of Fame",
    description: "selectOptions.tagOptions.HallofFame",
  },
  "tagOptions.Hollywood": {
    defaultMessage: "Hollywood",
    description: "selectOptions.tagOptions.Hollywood",
  },
  "tagOptions.Men": {
    defaultMessage: "Men",
    description: "selectOptions.tagOptions.Men",
  },
  "tagOptions.Originals": {
    defaultMessage: "Originals",
    description: "selectOptions.tagOptions.Originals",
  },
  "tagOptions.Personification of Best-Dressed": {
    defaultMessage: "Personification of Best-Dressed",
    description: "selectOptions.tagOptions.PersonificationofBest-Dressed",
  },
  "tagOptions.Professionals": {
    defaultMessage: "Professionals",
    description: "selectOptions.tagOptions.Professionals",
  },
  "tagOptions.Women": {
    defaultMessage: "Women",
    description: "selectOptions.tagOptions.Women",
  },
  "shipSizeOptions.small": {
    defaultMessage: "Small",
    description: "selectOptions.shipSizeOptions.small",
  },
  "shipSizeOptions.medium": {
    defaultMessage: "Medium",
    description: "selectOptions.shipSizeOptions.medium",
  },
  "shipSizeOptions.large": {
    defaultMessage: "Large",
    description: "selectOptions.shipSizeOptions.large",
  },
  "statusCodeOptions.301": {
    defaultMessage: "301 - Moved Permanently",
  },
  "statusCodeOptions.302": {
    defaultMessage: "302 - Found (temporary)",
  },
  "searchDateOptions.past-hour": { defaultMessage: "Past Hour" },
  "searchDateOptions.next-hour": { defaultMessage: "Next Hour" },
  "searchDateOptions.past-24-hours": { defaultMessage: "Past Day" },
  "searchDateOptions.next-24-hours": { defaultMessage: "Next 24 Hours" },
  "searchDateOptions.past-week": { defaultMessage: "Past Week" },
  "searchDateOptions.next-7-days": { defaultMessage: "Next 7 Days" },
  "searchDateOptions.past-month": { defaultMessage: "Past Month" },
  "searchDateOptions.past-year": { defaultMessage: "Past Year" },
  "searchDateOptions.from-now": { defaultMessage: "Scheduled" },
  "statusFilterOptions.published": { defaultMessage: "Published" },
  "statusFilterOptions.draft": { defaultMessage: "Draft" },
});

import { AdvancedSearchOptions, GetAppliedFiltersOptions } from "../types";

function filterStringOption(
  values?: string[],
  excludedOptions?: string[],
  allowedOptions?: string[]
) {
  let filteredValues = values?.length
    ? allowedOptions
      ? values.filter((value) => allowedOptions.includes(value))
      : excludedOptions
      ? values.filter((value) => !excludedOptions.includes(value))
      : values
    : values;
  return filteredValues?.length ? filteredValues : undefined;
}

export function getAppliedFilters(
  searchParams: AdvancedSearchOptions,
  options?: GetAppliedFiltersOptions // options can be undefined
): AdvancedSearchOptions {
  const filters: AdvancedSearchOptions = {
    date: searchParams.date,
    display: searchParams.display,
    lang: searchParams.lang,
    status: searchParams.status,
    types: filterStringOption(
      searchParams.types,
      options?.excludedOptions?.types,
      options?.allowedOptions?.types
    ),
    collaborators: searchParams.collaborators,
    contributors: searchParams.contributors,
    categories: searchParams.categories,
  };

  if (options?.ignoredFilters) {
    options.ignoredFilters.forEach((filter) => {
      delete filters[filter];
    });
  }

  Object.keys(filters).forEach((filterKey: string) => {
    let key = filterKey as keyof typeof filters;
    const filterValue = filters[key];

    if (filterValue === undefined) {
      delete filters[key];
      return;
    }
  });

  return filters;
}

import { createContext, FC, ReactNode, useEffect } from "react";
import { trackSelfDescribingEvent } from "@condenast/cn-snowplow-web";
import { Queries } from "@gql";
import { GetCurrentUser, FormError } from "@types";
import { useQuery } from "@apollo/client";
import { addGlobalContexts } from "@condenast/cn-snowplow-web";

const allSnowplowSchemas = {
  copilot_content_event:
    "iglu:com.condenast/copilot_content_event/jsonschema/2-0-0",
  copilot_component_tracking_event:
    "iglu:com.condenast/copilot_component_tracking_event/jsonschema/2-0-0",
  copilot_pageload_event:
    "iglu:com.condenast/copilot_pageload_event/jsonschema/3-0-0",
  field_validation_event:
    "iglu:com.condenast/field_validation_event/jsonschema/2-0-0",
  search_filter_event:
    "iglu:com.condenast/search_filter_event/jsonschema/4-0-0",
  copilot_features: "iglu:com.condenast/copilot_features/jsonschema/1-0-0",
} as any;

const trackDurationToReady = (
  durationFromLoad: number | null,
  durationFromRoute: number
) => {
  let payload = {
    duration_from_load: durationFromLoad,
    duration_from_route: durationFromRoute,
  };
  trackSelfDescribingEvent({
    event: {
      schema: allSnowplowSchemas["copilot_pageload_event"],
      data: payload,
    },
  });
};

const trackContentEvent = (
  type: string,
  assetId?: string,
  assetType?: string,
  milliseconds?: number,
  source_preset_id?: string,
  target_preset_id?: string,
  source_resync_id?: string,
  target_resync_id?: string
) => {
  let payload = {
    type: type,
    context: [
      {
        schema: allSnowplowSchemas["copilot_features"],
        data: {
          asset_id: assetId,
          asset_type: assetType,
          milliseconds: milliseconds,
          source_preset_id: source_preset_id,
          target_preset_id: target_preset_id,
          source_resync_id: source_resync_id,
          target_resync_id: target_resync_id,
        },
      },
    ],
  };
  trackSelfDescribingEvent({
    event: {
      schema: allSnowplowSchemas["copilot_content_event"],
      data: payload,
    },
  });
};

const trackSearchEvent = (
  type: string,
  subject: string,
  search_type: string,
  index?: number,
  search_keyword?: string,
  filter?: { name: string; label: string }[],
  result_type?: string,
  no_of_results_returned?: number,
  sort?: string
) => {
  let payload = {
    type: type,
    subject: subject,
    search_type: search_type,
    has_query: true,
    index: index,
    criteria: {
      search_keyword: search_keyword,
      sort: sort || "",
      filter: filter,
    },
    platform: "copilot",
    results_content: [
      {
        result_type: result_type,
        no_of_results_returned: no_of_results_returned,
      },
    ],
  };
  trackSelfDescribingEvent({
    event: {
      schema: allSnowplowSchemas["search_filter_event"],
      data: payload,
    },
  });
};

const trackComponentEvent = (
  component: string,
  type: string,
  label?: string
) => {
  let payload = {
    type: type,
    component: component,
    label: label,
  };
  trackSelfDescribingEvent({
    event: {
      schema: allSnowplowSchemas["copilot_component_tracking_event"],
      data: payload,
    },
  });
};

const trackFieldValidationEvent = (
  modelId: string,
  modelTypename: string,
  errors: Readonly<FormError[]>
) => {
  errors.map((error) => {
    let payload = {
      type: error.path[0] ?? "",
      validated: true,
      translated_message: error.message?.name,
      error: error.message?.message,
      platform: "copilot",
      context: [
        {
          schema: allSnowplowSchemas["copilot_features"],
          data: {
            asset_id: modelId,
            asset_type: modelTypename,
          },
        },
      ],
    };
    trackSelfDescribingEvent({
      event: {
        schema: allSnowplowSchemas["field_validation_event"],
        data: payload,
      },
    });
  });
};

export const SnowplowContext = createContext<{
  trackDurationToReady: typeof trackDurationToReady;
  trackContentEvent: typeof trackContentEvent;
  trackSearchEvent: typeof trackSearchEvent;
  trackComponentEvent: typeof trackComponentEvent;
  trackFieldValidationEvent: typeof trackFieldValidationEvent;
}>({
  trackDurationToReady: () => {},
  trackContentEvent: () => {},
  trackSearchEvent: () => {},
  trackComponentEvent: () => {},
  trackFieldValidationEvent: () => {},
});
SnowplowContext.displayName = "SnowplowContext";

export const SnowplowProvider: FC<{ children: ReactNode }> = (props) => {
  let { data: currentUserData } = useQuery<GetCurrentUser>(
    Queries.GET_CURRENT_USER
  );

  const copilotCode = location.pathname.split("/")[1];

  useEffect(() => {
    const cmsBasicConfig = {
      schema: "iglu:com.condenast/cms_basic/jsonschema/1-0-0",
      data: {
        username: currentUserData?.currentUser?.firstName,
        copilot_user_id: currentUserData?.currentUser?.id,
        cms_brand: copilotCode,
        content_source: "react",
      },
    };
    addGlobalContexts([cmsBasicConfig]);
  }, [copilotCode, currentUserData]);

  return (
    <SnowplowContext.Provider
      value={{
        trackDurationToReady,
        trackContentEvent,
        trackSearchEvent,
        trackComponentEvent,
        trackFieldValidationEvent,
      }}
    >
      {props.children}
    </SnowplowContext.Provider>
  );
};

import {
  newTracker,
  enableActivityTracking,
  enableLinkClickTracking,
} from "@condenast/cn-snowplow-web";

export const initializeSnowplowTracker = (props: { collectorURL: any }) => {
  // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/javascript-tracker/javascript-tracker-v3/tracker-setup/initialization-options/
  const snowplowConfig = {
    appId: "copilot",
    contexts: {
      performanceTiming: true,
      clientHints: true,
    },
    anonymousTracking: false,
    stateStorageStrategy: "none",
    discoverRootDomain: true,
    cookieSameSite: "None",
    cookieSecure: true,
    eventMethod: "post",
    postPath: "/com.condenast/yv8",
  };
  const appInfo = {
    appEnv: process.env.NODE_ENV,
    appVersion: "0.1.0",
    appName: "copilot-app",
  };
  // allow anonymous config keys to override if targeting consent detected
  newTracker(props.collectorURL, { ...snowplowConfig }, appInfo);
  enableActivityTracking({
    minimumVisitLength: 5,
    heartbeatDelay: 10,
  });
  enableLinkClickTracking();
  const setupRefresh = () => {
    let target = document.querySelector("body");
    if (MutationObserver && target) {
      let observer = new MutationObserver(() => {
        window.snowplowCN("refreshLinkClickTracking");
      });
      if (!target) {
        // Delay if body is not ready.
        window.setTimeout(setupRefresh, 250);
        return;
      }
      observer.observe(target, {
        subtree: true,
        childList: true,
      });
    }
    window.snowplowCN("refreshLinkClickTracking");
  };
  // Delay setup until after page load.
  window.setTimeout(setupRefresh, 250);
};

import type {
  ContentSummaryFields,
  MusicReviewInput,
  FormFor_content_MusicReview_itemsReviewed,
} from "@types";

export function itemsReviewed(
  input: FormFor_content_MusicReview_itemsReviewed
): MusicReviewInput[] {
  let reviewedFields = input?.edges
    ?.map((edge) => {
      const node = (edge?.node as ContentSummaryFields) ?? undefined;

      return (
        node && {
          isBestNewMusic: edge?.isBestNewMusic,
          isBestNewReissue: edge?.isBestNewReissue,
          node:
            (node && {
              id: node.id,
              contentType: node.contentType,
            }) ||
            undefined,
          relId: node && node.id,
          rating:
            edge?.rating && !isNaN(edge.rating)
              ? parseFloat(edge.rating.toString())
              : 0,
        }
      );
    })
    .filter((reviewedField) => reviewedField);
  return (
    (reviewedFields &&
      reviewedFields.length &&
      (reviewedFields as MusicReviewInput[])) ||
    []
  );
}

import { FormattedMessage, useIntl } from "react-intl";
import { Button, Select } from "@components";
import {
  GetBrand_brandConfiguration_contentTypes as ContentType,
  GetCurrentUser_currentUser,
  MainSearchAdapter,
} from "@types";
import styled from "styled-components";
import {
  ElementType,
  useDefinedMessages,
  useFocusTrap,
  useKeyboardNavigation,
  useUniqueId,
} from "@hooks";
import { useCallback, useMemo, useState } from "react";
import {
  GridIcon,
  BulletedListIcon,
  RichTextIcon,
  FilterIcon,
  CloseIcon,
} from "@condenast/gemini/icons";
import { AdvancedSearchOptions } from "../App/Main/Search/types";
const Wrapper = styled.div`
  width: 100%;
`;

const AdapterTabItem = styled.li`
  font-size: inherit;
  cursor: default;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
  margin-left: var(--spacing-xs);
  margin-right: var(--spacing-xs);
  cursor: pointer;

  &[aria-selected="true"] {
    border-bottom: 2px solid var(--color-blue-40);
    color: var(--color-blue-40);
    font-weight: 700;
  }

  &:first-of-type {
    margin-left: 0;
  }
`;

const AdapterTabList = styled.ul`
  grid-area: "tabs";
  display: flex;
  margin-bottom: var(--spacing-sm);
  list-style: none;
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};

  &:focus {
    outline: none;
  }

  &.input-mode-keyboard ${AdapterTabItem}.activedescendant {
    position: relative;
    z-index: 1;
    box-shadow: ${(props) => props.theme.FocusRing};
  }

  &.input-mode-mouse ${AdapterTabItem}[aria-selected="false"]:hover,
  &.input-mode-keyboard ${AdapterTabItem}[aria-selected="false"].activedescendant {
    background: ${(props) => props.theme.BorderlessHoverBackground};
  }
`;

const SelectList = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
`;

const StyledSelect = styled(Select)`
  margin-left: auto;
  background: transparent;
  grid-gap: 0px;
  button {
    background: transparent;
  }
`;

const RightAlign = styled.div`
  margin-left: auto;
  display: contents;
`;

const MobileMenu = styled.div`
  display: flex;
  gap: var(--spacing-xs);
`;

const MobileSelect = styled(Select)`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  ul[role="listbox"] {
    min-width: max-content;
    //44px is the height of the select box
    transform: translate(0px, 44px) !important;
  }
  svg.input-icon,
  svg.select-chevron {
    left: auto;
    display: none;
  }
  label {
    display: flex;
    gap: var(--spacing-xs);
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.Color};
  }
  label svg {
    height: 16px;
    width: 16px;
  }
`;

const MobileButton = styled(Button)`
  display: flex;
  gap: var(--spacing-xs);
  justify-content: center;
  flex-grow: 1;
  font: ${(props) => props.theme.FontBody};
  background: ${(props) => props.theme.Background};
  svg {
    left: auto;
    align-self: center;
    width: 16px;
    height: 16px;
  }
`;

const FilterTakeover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: ${(props) => props.theme.ElevationModal};
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
`;

const TakeoverHeader = styled.div`
  display: flex;
  height: 56px;
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
  width: 100%;
  top: 0;
  font: var(--font-subhed);
  padding: var(--spacing-sm);
  align-items: center;
  justify-content: space-between;
`;

const TakeoverFooter = styled.div`
  display: flex;
  height: 56px;
  border-top: 1px solid ${(props) => props.theme.DividerColor};
  width: 100%;
  bottom: 0;
  padding: var(--spacing-xs);
  align-items: center;
  justify-content: space-between;
`;

const TakeoverScrollable = styled.div`
  height: 100%;
  max-height: calc(100vh - 2 * 56px);
  overflow-y: scroll;
  padding: var(--spacing-md);
`;

const TakeoverFilters = styled.div`
  max-width: 480px;
  margin: auto;
`;

const CloseButton = styled(Button)``;

export const SearchControls = (props: {
  currentUser: GetCurrentUser_currentUser;
  organizationId: string;
  contentTypes: ContentType[];
  syndicationEnabled: boolean;
  availableLanguages: (string | null)[];
  enableLanguageFilters: boolean;
  statusOptions: string[];
  sortByOptions: string[];
  dateRangeOptions: string[];
  adapters: MainSearchAdapter[];
  selectedAdapter: MainSearchAdapter;
  treatment: "grid" | "slat";
  setTreatment: (treatment: "grid" | "slat") => void;
  isMobile: boolean;
  searchParams: AdvancedSearchOptions;
  categoryConfigType?: string | null;
  onChange: (searchParams: AdvancedSearchOptions) => void;
}) => {
  const {
    currentUser,
    organizationId,
    contentTypes,
    syndicationEnabled,
    availableLanguages,
    enableLanguageFilters,
    statusOptions,
    sortByOptions,
    dateRangeOptions,
    adapters,
    selectedAdapter,
    treatment,
    setTreatment,
    isMobile,
    categoryConfigType,
    searchParams,
    onChange,
  } = props;

  const intl = useIntl();
  const { translateFieldName } = useDefinedMessages();
  const [mobileFiltersToggle, setMobileFiltersToggle] =
    useState<boolean>(false);
  const [takeoverElement, setTakeoverElement] = useState<HTMLElement | null>(
    null
  );
  const selectedSort = searchParams.sort;

  const appliedFiltersCount = useMemo(() => {
    return selectedAdapter.AppliedFiltersCount(searchParams);
  }, [searchParams, selectedAdapter]);

  const {
    tabRefs,
    cursor,
    cursorMode,
    onKeyDown,
    onMouseMove,
    onFocus,
    onBlur,
  } = useKeyboardNavigation(ElementType.TabList, props.adapters.length, {
    defaultCursor: adapters.findIndex((i) => i.isDefault),
    onEnter: (cursor: number) => {
      onChange({ adapter: adapters[cursor].id });
    },
  });

  const uid = useUniqueId();
  const tabItemPrefix = `search-adapter-tab-item-${uid}`;

  const onClose = useCallback(() => {
    setMobileFiltersToggle(false);
  }, [setMobileFiltersToggle]);

  useFocusTrap(takeoverElement, onClose);

  return (
    <Wrapper>
      {isMobile && mobileFiltersToggle ? (
        <FilterTakeover ref={setTakeoverElement}>
          <TakeoverHeader>
            {appliedFiltersCount > 0 ? (
              <FormattedMessage
                defaultMessage="Filters ({count})"
                values={{ count: appliedFiltersCount }}
              />
            ) : (
              <FormattedMessage defaultMessage="Filters" />
            )}
            <CloseButton
              size="small"
              aria-label={intl.formatMessage({
                defaultMessage: "Close",
              })}
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon size="small" />
            </CloseButton>
          </TakeoverHeader>
          <TakeoverScrollable>
            <TakeoverFilters>
              <selectedAdapter.Filters
                searchParams={searchParams}
                onChange={onChange}
                contentTypes={contentTypes}
                statusOptions={statusOptions}
                dateRangeOptions={dateRangeOptions}
                enableLanguageFilters={enableLanguageFilters}
                availableLanguages={availableLanguages}
                organizationId={organizationId}
                currentUser={currentUser}
                syndicationEnabled={syndicationEnabled}
                mobileView={isMobile}
                categoryConfigType={categoryConfigType}
              />
            </TakeoverFilters>
          </TakeoverScrollable>
          <TakeoverFooter>
            <Button
              treatment="borderless"
              size="regular"
              onClick={() => {
                onChange({});
              }}
            >
              <FormattedMessage defaultMessage="Clear all" />
            </Button>
            <Button
              treatment="primary"
              size="regular"
              onClick={() => {
                onClose();
              }}
            >
              <FormattedMessage defaultMessage="Apply filters" />
            </Button>
          </TakeoverFooter>
        </FilterTakeover>
      ) : (
        <>
          <AdapterTabList
            className={`input-mode-${cursorMode}`}
            aria-activedescendant={
              cursor !== null && cursor >= 0
                ? `${tabItemPrefix}-${cursor}`
                : undefined
            }
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            role="tablist"
          >
            {adapters.map((item, index) => {
              return (
                <AdapterTabItem
                  key={item.id}
                  id={`${tabItemPrefix}-${item.id}`}
                  role="tab"
                  onMouseMove={() => {
                    onMouseMove(index);
                  }}
                  onClick={() => onChange({ adapter: item.id })}
                  ref={tabRefs[index]}
                  aria-selected={item.id === selectedAdapter.id}
                >
                  <span>
                    <item.Label />
                  </span>
                </AdapterTabItem>
              );
            })}
          </AdapterTabList>
          {!isMobile ? (
            <>
              <SelectList>
                <selectedAdapter.Filters
                  searchParams={searchParams}
                  onChange={onChange}
                  contentTypes={contentTypes}
                  statusOptions={statusOptions}
                  dateRangeOptions={dateRangeOptions}
                  enableLanguageFilters={enableLanguageFilters}
                  availableLanguages={availableLanguages}
                  organizationId={organizationId}
                  currentUser={currentUser}
                  syndicationEnabled={syndicationEnabled}
                  mobileView={isMobile}
                  categoryConfigType={categoryConfigType}
                />
                <RightAlign>
                  {sortByOptions && (
                    <StyledSelect
                      id={"SortBySelector"}
                      onChange={(sortOption?: string) =>
                        onChange({
                          sort: sortOption,
                        })
                      }
                      options={sortByOptions?.map((option) => {
                        return {
                          label: translateFieldName(option),
                          value: option,
                        };
                      })}
                      value={selectedSort || ""}
                      placeholder={
                        `Sort by: ` + translateFieldName(selectedSort || "")
                      }
                      alwaysUsePlaceholder={true}
                    />
                  )}
                  {treatment && (
                    <>
                      <Button
                        onClick={(evt) => {
                          evt.preventDefault();
                          setTreatment("grid");
                        }}
                        aria-pressed={treatment === "grid"}
                        aria-label={intl.formatMessage({
                          defaultMessage: "Grid View",
                          description:
                            "Label for toggling search results to grid view",
                        })}
                      >
                        <GridIcon size="regular" />
                      </Button>
                      <Button
                        onClick={(evt) => {
                          evt.preventDefault();
                          setTreatment("slat");
                        }}
                        aria-pressed={treatment === "slat"}
                        aria-label={intl.formatMessage({
                          defaultMessage: "List View",
                          description:
                            "Label for toggling search results to list view",
                        })}
                      >
                        <BulletedListIcon size="regular" />
                      </Button>
                    </>
                  )}
                </RightAlign>
              </SelectList>
              <selectedAdapter.AppliedFiltersList
                searchParams={searchParams}
                onChange={onChange}
              />
            </>
          ) : (
            <MobileMenu>
              <MobileButton
                onClick={() => {
                  setMobileFiltersToggle(true);
                }}
              >
                <FilterIcon size="regular" />
                {appliedFiltersCount > 0 ? (
                  <FormattedMessage
                    defaultMessage="Filters ({count})"
                    values={{ count: appliedFiltersCount }}
                  />
                ) : (
                  <FormattedMessage defaultMessage="Filters" />
                )}
              </MobileButton>
              {sortByOptions && (
                <MobileSelect
                  id={"SortBySelector"}
                  onChange={(option: string | undefined) =>
                    onChange({
                      sort: option,
                    })
                  }
                  options={sortByOptions?.map((option) => {
                    return { label: translateFieldName(option), value: option };
                  })}
                  value={selectedSort}
                  placeholder={
                    <>
                      <RichTextIcon size="small" />
                      <FormattedMessage defaultMessage="Sort" />
                    </>
                  }
                  alwaysUsePlaceholder={true}
                />
              )}
              {treatment && (
                <>
                  <Button
                    onClick={(evt) => {
                      evt.preventDefault();
                      setTreatment("grid");
                    }}
                    aria-pressed={treatment === "grid"}
                    aria-label="Grid View"
                  >
                    <GridIcon size="regular" />
                  </Button>
                  <Button
                    onClick={(evt) => {
                      evt.preventDefault();
                      setTreatment("slat");
                    }}
                    aria-pressed={treatment === "slat"}
                    aria-label="List View"
                  >
                    <BulletedListIcon size="regular" />
                  </Button>
                </>
              )}
            </MobileMenu>
          )}
        </>
      )}
    </Wrapper>
  );
};

SearchControls.displayName = "SearchControls";

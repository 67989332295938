import styled from "styled-components";
import { ThemeProvider } from "@contexts";
import { Button } from "@components";
import { FormattedMessage } from "react-intl";
import { DownloadIcon, UploadIcon, GridIcon } from "@condenast/gemini/icons";
import { useState } from "react";
import { TileViewPanel } from "../TileViewPanel";
import { CuratedListItemData, CuratedListItemUpdateOperation } from "@types";

const StyledActionBar = styled.div`
  position: sticky;
  bottom: var(--spacing-lg);
  z-index: calc(${(props) => props.theme.ElevationCard} - 1);
  right: 0;
  width: auto;

  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.CardShadow};
  background: ${(props) => props.theme.Background};

  display: flex;
  flex-direction: column;
  justify-self: center;
`;

const ButtonList = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: var(--spacing-xs);

  padding: var(--spacing-xs);

  & button {
    margin-right: var(--spacing-xxs);

    &:first-of-type {
      margin-left: var(--spacing-xxs);
    }
  }
`;

const Divider = styled.div`
  display: inline-block;
  height: 40px;
  border-right: 1px solid ${(props) => props.theme.DividerColor};
`;

const ReorderButton = styled(Button)`
  box-shadow: none;
  margin-left: var(--spacing-xxs);
`;

type DragData = {
  index: number;
  item: CuratedListItemData;
};

export function CuratedListItemActions(props: {
  items: (CuratedListItemData &
    Pick<Required<CuratedListItemData>, "itemID">)[];
  handleScrollToBottom: () => void;
  handleScrollToTop: () => void;
  cdnHost?: string;
  onUpdate: (operations: CuratedListItemUpdateOperation[]) => void;
  moveItem: (item: DragData, dropIndex: number) => void;
}) {
  const {
    items,
    handleScrollToBottom,
    handleScrollToTop,
    cdnHost,
    onUpdate,
    moveItem,
  } = props;
  const [isShowing, setShowing] = useState(false);

  return (
    <ThemeProvider theme="dark">
      <StyledActionBar>
        <ButtonList>
          <Button aria-label="Jump to Top" onClick={handleScrollToTop}>
            <UploadIcon size="regular" />
          </Button>
          <Button aria-label="Jump to Bottom" onClick={handleScrollToBottom}>
            <DownloadIcon size="regular" />
          </Button>
          <Divider />
          <ReorderButton
            aria-label="Reorder Items"
            onClick={() => setShowing(true)}
          >
            <GridIcon size="regular" />
            <FormattedMessage defaultMessage="Reorder Items" />
          </ReorderButton>
        </ButtonList>
      </StyledActionBar>
      {isShowing && (
        <TileViewPanel
          onClose={() => setShowing(false)}
          items={items}
          cdnHost={cdnHost}
          onUpdate={onUpdate}
          moveItem={moveItem}
        />
      )}
    </ThemeProvider>
  );
}

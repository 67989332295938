import { useState } from "react";
import { ContentSummary, FormError } from "@types";
import {
  Button,
  Image,
  RoutableLink,
  Spinner,
  UploadButton,
} from "@components";
import { useDefinedMessages } from "@hooks";
import {
  AssetIcon,
  ContentTypeIcon,
  NoAssetIcon,
} from "@condenast/gemini/icons";

import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";

const MEDIA_MAP = {
  clips: ["avi", "mov", "quicktime", "flv", "mpg", "mp4", "webm", "wmv"],
  photos: ["jpeg", "jpg", "png", "gif"],
};

const MEDIA_TYPES = ["photo", "clip"];

const CONTENT_IMAGE_DIMENSION = 124; //px
const MEDIA_IMAGE_HEIGHT = 188; //px
const MEDIA_IMAGE_WIDTH = 240; //px

let acceptTypes = [];

for (let types of Object.values(MEDIA_MAP)) {
  for (let type in types) {
    acceptTypes.push(`media/${type}`);
  }
}

const accept = acceptTypes.join(",");

const AssetField = styled.div`
  grid-area: asset;
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-xxs);
`;

const EmptyAssetWrapper = styled.div`
  position: relative;
  width: calc(10.75 * var(--spacing-sm));
  height: 9.5rem;
  border: 1px solid var(--color-gray-5);
  border-radius: ${(props) => props.theme.CornerRadius};
  background: var(--color-gray-6);
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-xs);
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &[aria-invalid="true"] {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }
`;

const EditAsset = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 0;

  &:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    height: 100%;
    background: var(--color-gray-1);
    opacity: 50%;
    z-index: -1;
  }

  & svg {
    margin-right: var(--spacing-xxs);
  }
`;

const AssetWrapper = styled(EmptyAssetWrapper)<{ $hasImageCredit: boolean }>`
  color: var(--color-gray-6);

  svg:not(${EditAsset} svg) {
    color: var(--color-gray-4);
  }

  &:hover {
    ${EditAsset} {
      display: flex;
    }
  }

  ${(props) =>
    props.$hasImageCredit &&
    `
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `}
`;

const MediaImageCredit = styled.div`
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.SupportColor};
  border: 1px solid ${(props) => props.theme.DividerColor};
  border-bottom-right-radius: ${(props) => props.theme.CornerRadius};
  border-bottom-left-radius: ${(props) => props.theme.CornerRadius};
  border-top: 0;
  width: calc(10.75 * var(--spacing-sm));
  padding: var(--spacing-sm) var(--spacing-xs);
  margin-top: -6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const MediaImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${MEDIA_IMAGE_HEIGHT}px;
`;

const ContentImage = styled.div`
  height: ${CONTENT_IMAGE_DIMENSION}px;
  margin: var(--spacing-sm) var(--spacing-sm);

  img {
    border-radius: ${(props) => props.theme.CornerRadius};
  }
`;

const ActionButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
`;

export function EmptyAsset(props: {
  selectAsset: () => void;
  onUpload: (files: FileList) => void;
  isUploading?: boolean;
  errors?: FormError[];
}) {
  const { selectAsset, onUpload, isUploading, errors } = props;
  let [isBeingUploadedTo, setIsbeingUploadedTo] = useState<boolean>(false);
  return (
    <AssetField>
      <EmptyAssetWrapper aria-invalid={!!errors?.length}>
        {isBeingUploadedTo ? (
          <Spinner size="large" />
        ) : (
          <>
            <ActionButton
              onClick={(evt) => {
                evt.preventDefault();
                selectAsset();
              }}
            >
              <AssetIcon size="regular" />
              <FormattedMessage defaultMessage="Search" />
            </ActionButton>
            <UploadButton
              onChange={(files: FileList) => {
                setIsbeingUploadedTo(true);
                onUpload(files);
              }}
              accept={accept}
              multiple={false}
              disabled={isUploading}
            />
          </>
        )}
      </EmptyAssetWrapper>
      {!!errors?.length && (
        <div>
          {errors.map(({ message: { name, message } }) => {
            return <p key={name}>{message}</p>;
          })}
        </div>
      )}
    </AssetField>
  );
}

export function Asset(props: {
  item: ContentSummary;
  cdnHost?: string;
  target: string;
  editUrl: string | null;
  errors?: FormError[];
}) {
  const intl = useIntl();
  const { item, cdnHost, target, editUrl, errors } = props;
  const isMedia = item && MEDIA_TYPES.includes(item.contentType);
  const { translateContentType } = useDefinedMessages();
  const hasImageCredit = !!item.credit;

  return (
    <AssetField>
      <AssetWrapper
        as={RoutableLink}
        target={target}
        to={editUrl ?? ""}
        aria-invalid={!!errors?.length}
        aria-label={intl.formatMessage(
          {
            defaultMessage: `Edit {type}`,
          },
          { type: translateContentType(item.contentType, 1) }
        )}
        $hasImageCredit={hasImageCredit}
      >
        <EditAsset>
          <ContentTypeIcon contentType={item.contentType} size="regular" />
          <span>
            <FormattedMessage
              defaultMessage="Edit {type}"
              values={{ type: translateContentType(item.contentType, 1) }}
            />
          </span>
        </EditAsset>
        {!item.asset ? (
          <NoAssetIcon size="regular" />
        ) : isMedia && item.asset ? (
          <MediaImage>
            <Image
              asset={item.asset}
              cdnHost={cdnHost}
              aspectRatio={"master"}
              modifications={{
                height: MEDIA_IMAGE_HEIGHT,
                width: MEDIA_IMAGE_WIDTH,
              }}
            />
          </MediaImage>
        ) : (
          <ContentImage>
            <Image
              asset={item.asset}
              cdnHost={cdnHost}
              aspectRatio={"1:1"}
              modifications={{
                width: CONTENT_IMAGE_DIMENSION,
                height: CONTENT_IMAGE_DIMENSION,
              }}
            />
          </ContentImage>
        )}
      </AssetWrapper>
      {!!errors?.length && (
        <div>
          {errors.map(({ message: { name, message } }) => {
            return <p key={name}>{message}</p>;
          })}
        </div>
      )}
      {isMedia && item.asset && hasImageCredit && (
        <MediaImageCredit>{item.credit?.content}</MediaImageCredit>
      )}
    </AssetField>
  );
}

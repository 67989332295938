import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required, url, length } from "@condenast/copilot-validators";
import { FormFor_content_Recipe } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";
import { publishURI } from "../validators";

const { object } = validators;

const nativeRecipeValidators = object({
  hed: required(),
  advertiser: object({
    edges: length({ min: 1 }),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  ingredientGroups: length({ min: 1 }).on("publish"),
  preparationGroups: length({ min: 1 }).on("publish"),
  campaignUrl: url().on("publish"),
  bylineOption: required().on("publish"),
  bylineVariant: required().on("publish"),
}).andAlso(publishURI().on("publish"));

export default function validateNativeRecipe(
  recipe: FormFor_content_Recipe,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(recipe, build(nativeRecipeValidators), context, env);
}

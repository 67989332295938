import {
  ControlProps,
  ContentSummaryFields,
  FormFor_form_controls_ItemReviewedFormControl,
  ItemReviewedType,
  ContentSummary,
  FormError,
  AssetSelectorConfiguration,
} from "@types";
import { useCallback, useMemo } from "react";
import { normalizeContentSummary, serializeContentSummary } from "@lib";
import { ItemReviewed as ItemReviewedImplementation } from "@components";
import { useDefinedMessages } from "@hooks";

const REGEX_DECIMAL_POSITIVE_NUM = /^[0-9]*\.?[0-9]*\.?$/;
const REGEX_FLOAT_REPLACE = /(?<=\..*)\./g;

export function ItemReviewed(
  props: ControlProps<FormFor_form_controls_ItemReviewedFormControl>
) {
  const {
    id,
    errors,
    currentOrganization,
    model,
    setValue,
    currentUser,
    assetSelectorConfiguration,
    ratingStep = 1,
    displayLabel,
    maximumRating = 0,
  } = props;

  const { translateContentType } = useDefinedMessages();

  const itemsReviewedModel = model as unknown as ItemReviewedType;
  const normalizedReviewPhoto = useMemo(
    () =>
      (itemsReviewedModel?.node as ContentSummaryFields) &&
      normalizeContentSummary(itemsReviewedModel?.node, translateContentType),
    [itemsReviewedModel]
  );

  const onRatingChange = useCallback(
    (val: string) => {
      if (val.length) {
        let rating = "";
        if (val.match(REGEX_DECIMAL_POSITIVE_NUM)) {
          rating = val.replace(REGEX_FLOAT_REPLACE, "");
          rating =
            parseFloat(rating) <= (maximumRating as number)
              ? Number.isInteger(ratingStep)
                ? parseInt(rating, 10).toString()
                : rating
              : "";
        }
        //to set only float or int
        rating && setValue("rating", rating);
      } else {
        //to empty the text box
        setValue("rating", "");
      }
    },
    [setValue, ratingStep, maximumRating]
  );

  const onAccoladesChange = useCallback(
    (val: boolean, key: string) => {
      setValue(key, val);
    },
    [setValue]
  );

  const removeReviewPhoto = () => {
    setValue("node", undefined);
  };

  const submitReviewPhoto = (assets: ContentSummary[]) => {
    let node =
      assets.map((asset: ContentSummary) => serializeContentSummary(asset)) ||
      [];
    node &&
      node.length &&
      setValue("node", { ...itemsReviewedModel, ...node[0] });
  };

  //to disable rating textfield or accolades radio btns
  const disableFields = useMemo(() => {
    return normalizedReviewPhoto ? false : true;
  }, [normalizedReviewPhoto]);

  let assetSelectorConfig = assetSelectorConfiguration
    ? ({
        ...assetSelectorConfiguration,
        limitSelection: 1,
      } as AssetSelectorConfiguration)
    : undefined;

  return (
    <ItemReviewedImplementation
      displayLabel={displayLabel || ""}
      id={id}
      organizationId={currentOrganization.organizationId}
      assetSelectorConfig={assetSelectorConfig}
      cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
      currentUser={currentUser}
      reviewPhotosTout={normalizedReviewPhoto}
      submitReviewPhoto={submitReviewPhoto}
      removeReviewPhoto={removeReviewPhoto}
      onRatingChange={onRatingChange}
      onAccoladesChange={onAccoladesChange}
      reviewData={itemsReviewedModel}
      errors={
        errors &&
        errors.filter(
          (d: FormError) => d.path.length && d.path.includes("itemsReviewed")
        )
      }
      accolades={
        //only for music review
        assetSelectorConfig?.defaultType === "musicrecording"
          ? [
              {
                key: "isBestNewMusic",
                label: "Is Best New Music",
                disabled: disableFields,
              },
              {
                key: "isBestNewReissue",
                label: "Is Best New Reissue",
                disabled: disableFields,
              },
            ]
          : []
      }
      ratingStep={ratingStep?.toString() || "1"}
      ratingDisabled={disableFields}
      message={maximumRating ? `Maximum Rating is ${maximumRating}` : ""}
    />
  );
}

ItemReviewed.displayName = "Control(ItemReviewed)";

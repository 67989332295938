import allCharMap from "./charmap";
import nonLetterMap from "./nonlettermap";

// eslint-disable-next-line
let UNICODE_SLUG = /[^\s%0-9-/\p{L}]/ug; // allow all letter characters and dashes
let SLUG = /[^\sA-Za-z0-9-/]/g; // only allow alphanumeric and dashes

export type Slug = string | SafeSlug;

export class SafeSlug {
  constructor(public value: string) {}

  get isSafeSlug() {
    return true;
  }

  toString() {
    return this.value;
  }
}

function isSafeSlug(slug: Slug): slug is SafeSlug {
  return typeof slug === "object" && "isSafeSlug" in slug && slug.isSafeSlug;
}

function slugSafe(slug: Slug) {
  if (isSafeSlug(slug)) {
    return slug;
  }

  return new SafeSlug(slug);
}

function maybeSlugSafe(slug: Slug, shouldWrap: boolean): Slug {
  if (shouldWrap) {
    return slugSafe(slug);
  }
  return slug;
}

export function slugify(
  string: Slug,
  options: {
    allowAllLetterCharacters?: boolean;
    allowUppercase?: boolean;
    safeSlug?: boolean;
  } = {}
) {
  // If the string is already a SafeSlug,
  // then we don't want to run slugify on it
  // again
  if (string && isSafeSlug(string)) {
    return string;
  }

  let { allowAllLetterCharacters, allowUppercase, safeSlug } = options;
  if (typeof string !== "string" || !string) {
    return maybeSlugSafe("", !!safeSlug);
  }

  let replacement = "-";
  let result = "";

  const charMap = allowAllLetterCharacters ? nonLetterMap : allCharMap;

  for (let i = 0; i < string.length; i++) {
    let ch = string[i];
    if (charMap[ch]) {
      ch = charMap[ch];
    }

    ch = ch.replace(/_/gi, "-");
    if (allowAllLetterCharacters) {
      ch = ch.replace(UNICODE_SLUG, "");
    } else {
      ch = ch.replace(SLUG, "");
    }

    result += ch;
  }

  result = result.replace(/^\s+|\s+$/g, ""); // trim leading/trailing spaces
  result = result.replace(/^-+|-+$/g, ""); // remove leading/trailing dashes
  result = result.replace(/[-\s]+/g, replacement); // convert spaces
  result = result.replace(/-{2,}/g, "-"); // remove any duplicate dashes
  result = result.replace(/\/{2,}/g, "/");
  result = allowUppercase ? result : result.toLowerCase(); // lowercase everything

  return maybeSlugSafe(result, !!safeSlug);
}

slugify.slugSafe = slugSafe;

import { useState, useCallback, useMemo, memo } from "react";
import styled from "styled-components";
import update from "immutability-helper";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ContentSummary,
  ContentSummaryFields,
  FormFor_content_Bundle_containers_curations_edges,
  FormFor_form_controls_BundleContainersFormControl_configs_containers_LegacyBundleContainerConfig as LegacyBundleContainerConfig,
  FormFor_content_Bundle_containers_curations_edges_node_metadata_ContentSummaryContributors as ContentSummaryContributors,
} from "@types";
import { useToast, useFieldToggles, useDefinedMessages } from "@hooks";
import { serializeContentSummary } from "@lib";
import {
  DraggableList,
  Button,
  FloatingActionBar,
  SearchResult,
} from "@components";
import { AddIcon } from "@condenast/gemini/icons";
import { ThemeProvider } from "@contexts";
import { AssetSelector } from "../../../../../-private";
import { BundleContainerProps } from "../../../../types";
import { SlatResult, SearchResultSlatContainer } from "@condenast/gemini";
import ContextualOverrideTakeover from "./ContextualOverrideTakeover";
import {
  ContainerBody,
  ContainerLabel,
  ContainerFields,
  EmptyContainerMessage,
  ContainerFooter,
  ContainerCard,
  ContainerHeader,
} from "../../../";

const StyledSearchResult = styled(SearchResult)`
  border: none;
  border-radius: var(--spacing-xxs);
  box-shadow: ${(props) => props.theme.CardShadow};
  margin: var(--spacing-xxs) 0;
`;

const StyledDraggableList = styled(DraggableList)`
  ${SearchResultSlatContainer}:hover {
    background: ${(props) => props.theme.PrimaryColor};
    box-shadow: ${(props) => props.theme.FieldActiveRing};
    cursor: grab;
  }
` as typeof DraggableList;

const ListContainer = memo<BundleContainerProps>((props) => {
  let {
    containerData,
    updateContainerByIndex,
    containerIndex,
    configs,
    currentOrganization,
    currentUser,
    permissions,
    errors,
    containerRefs,
    activeContainerKey,
    setActiveContainerKey,
    deleteContainer,
    duplicateContainer,
    moveContainer,
    totalContainerCount,
    isCollapsed,
    toggleContainerCollapsed,
  } = props;

  const intl = useIntl();
  const { translateContributorCredit } = useDefinedMessages();

  const showRemovedDupesMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "One or more selected items have already been added",
      description:
        "Notification message for when one or more selected items have already been added",
    }),
  });

  const { toggleField, resetToInitial, fieldStates, hasChanges } =
    useFieldToggles({
      hed: !!containerData.hed,
      dek: !!containerData.dek,
      containerAsset: !!containerData.containerAsset?.edges?.length,
    });

  const [isSelectingCurations, setIsSelectingCurations] = useState(false);
  const [curationsToEdit, setCurationsToEdit] = useState<
    FormFor_content_Bundle_containers_curations_edges[] | null
  >(null);

  const curations = useMemo(
    () => containerData?.curations?.edges || [],
    [containerData?.curations?.edges]
  );

  const cdnHost = useMemo(
    () => `https://${currentOrganization.metadata.mediaDomain}`,
    [currentOrganization]
  );

  const draggableCurations = useMemo(
    () =>
      curations
        ?.filter((curation) => curation !== null)
        .map((curation) => {
          return {
            key: curation?.node?.id || "",
            value: curation,
          };
        }),
    [curations]
  );

  const containerConfig = useMemo(() => {
    return configs?.containers?.find(
      (config) => config.value === containerData.curationContainerType
    ) as LegacyBundleContainerConfig;
  }, [configs?.containers, containerData.curationContainerType]);

  const curationLimitReached = useMemo(() => {
    if (!containerConfig.itemLimit) return false;
    return containerConfig.itemLimit - curations.length <= 0;
  }, [curations, containerConfig]);

  const addContentButtonLabel = useMemo(() => {
    return curationLimitReached
      ? intl.formatMessage({
          defaultMessage: "Item limit reached",
        })
      : intl.formatMessage({
          defaultMessage: "Add content",
        });
  }, [curationLimitReached, intl]);

  const addCurations = useCallback(
    (selectedCurations: ContentSummary[]) => {
      const existingCurationIDs = curations
        ? (curations.map((edge) => edge?.node?.id).filter(Boolean) as string[])
        : [];

      const filteredCurations = selectedCurations
        .filter((asset) => !existingCurationIDs.includes(asset.id))
        .map((asset) => ({
          node: serializeContentSummary(asset),
        })) as FormFor_content_Bundle_containers_curations_edges[];

      if (filteredCurations.length < selectedCurations.length) {
        showRemovedDupesMessage();
      }

      const updatedContainerData = update(containerData, {
        curations: {
          edges: { $push: filteredCurations },
        },
      });

      updateContainerByIndex(updatedContainerData, containerIndex);
      setCurationsToEdit(filteredCurations);
      setIsSelectingCurations(false);
    },
    [
      containerData,
      curations,
      updateContainerByIndex,
      containerIndex,
      showRemovedDupesMessage,
    ]
  );

  const reorderCurations = useCallback(
    (
      data: {
        index: number;
      },
      dropIndex: number
    ) => {
      if (data.index === dropIndex) return;
      const newCurations = [...curations];
      const [movedCuration] = newCurations.splice(data.index, 1);
      // Adjust the dropIndex if dragging from a lower index to a higher index
      const adjustedDropIndex =
        data.index < dropIndex ? dropIndex - 1 : dropIndex;
      newCurations.splice(adjustedDropIndex, 0, movedCuration);

      const updatedContainerData = update(containerData, {
        curations: {
          edges: { $set: newCurations },
        },
      });
      updateContainerByIndex(updatedContainerData, containerIndex);
    },
    [containerData, curations, updateContainerByIndex, containerIndex]
  );

  const updateCurations = useCallback(
    (updatedCurations: FormFor_content_Bundle_containers_curations_edges[]) => {
      const newCurations = curations.map((existingCuration) => {
        const updatedCuration = updatedCurations.find(
          (updatedCuration) =>
            updatedCuration?.node?.id === existingCuration?.node?.id
        );
        return updatedCuration || existingCuration;
      });

      const updatedContainerData = update(containerData, {
        curations: {
          edges: { $set: newCurations },
        },
      });
      updateContainerByIndex(updatedContainerData, containerIndex);
    },
    [containerData, curations, updateContainerByIndex, containerIndex]
  );

  const deleteCuration = useCallback(
    (curationToDelete: FormFor_content_Bundle_containers_curations_edges) => {
      const updatedContainerData = update(containerData, {
        curations: {
          edges: {
            $apply: (
              edges: FormFor_content_Bundle_containers_curations_edges[]
            ) =>
              edges.filter(
                (curation) => curation?.node?.id !== curationToDelete?.node?.id
              ),
          },
        },
      });
      updateContainerByIndex(updatedContainerData, containerIndex);
    },
    [containerData, updateContainerByIndex, containerIndex]
  );
  return (
    <>
      {curationsToEdit && curationsToEdit?.length > 0 && (
        <ContextualOverrideTakeover
          items={curationsToEdit}
          onUpdate={updateCurations}
          onClose={() => {
            setCurationsToEdit(null);
          }}
          cdnHost={cdnHost}
          configs={configs}
          currentOrganization={currentOrganization}
          currentUser={currentUser}
          permissions={permissions}
          errors={errors}
        />
      )}
      {isSelectingCurations && configs?.contentTypeOptions && (
        <AssetSelector
          config={{
            __typename: "AssetSelectorConfig",
            contentTypes: containerConfig?.assetTypeList || [],
            defaultType:
              containerConfig?.defaultType ||
              containerConfig?.assetTypeList?.[0] ||
              "",
            externalTypes: null,
            limitSelection: containerConfig?.itemLimit ?? null,
          }}
          currentOrganizationID={currentOrganization.organizationId}
          onSubmit={addCurations}
          onClose={() => setIsSelectingCurations(false)}
          cdnHost={cdnHost}
          currentUser={currentUser}
          initialSelections={curations
            .filter((edge) => !!edge.node)
            .map((edge) => edge.node as ContentSummaryFields)}
        />
      )}
      <ContainerCard
        key={containerData.containerKey}
        ref={(el: HTMLDivElement) => {
          if (el) {
            containerRefs.current[containerData.containerKey] = el;
          }
        }}
        containerKey={containerData.containerKey}
        activeContainerKey={activeContainerKey}
        setActiveContainerKey={setActiveContainerKey}
      >
        <ContainerHeader
          configs={configs}
          containerData={containerData}
          containerIndex={containerIndex}
          updateContainerByIndex={updateContainerByIndex}
          deleteContainer={deleteContainer}
          duplicateContainer={duplicateContainer}
          toggleField={toggleField}
          resetToInitial={resetToInitial}
          fieldStates={fieldStates}
          hasChanges={hasChanges}
          isCollapsed={isCollapsed}
          onBatchEdit={setCurationsToEdit}
        />
        <ContainerBody $isCollapsed={isCollapsed}>
          <ContainerFields
            containerData={containerData}
            updateContainerByIndex={updateContainerByIndex}
            containerIndex={containerIndex}
            configs={configs}
            currentOrganization={currentOrganization}
            currentUser={currentUser}
            permissions={permissions}
            errors={errors}
            fieldStates={fieldStates}
          />
          <ContainerLabel>
            {intl.formatMessage({
              defaultMessage: "Content",
              description: "Title of list container",
            })}
          </ContainerLabel>
          {curations.length ? (
            <StyledDraggableList
              items={draggableCurations}
              onMove={reorderCurations}
              orientation={"vertical"}
            >
              {(item, index) => {
                if (!item) return;

                const curationActionMenuItems = [
                  {
                    value: "Edit Contextual Overrides",
                    role: "action",
                    onClick: () => {
                      setCurationsToEdit([item]);
                    },
                  },
                  {
                    role: "link",
                    href: item.node?.editUrl,
                    target: "_blank",
                    value: "Edit Item",
                  },
                  {
                    value: "Delete",
                    role: "action",
                    onClick: () => deleteCuration(item),
                  },
                ];

                const contributorMetadata = item?.node?.metadata?.find(
                  (item) => item.__typename === "ContentSummaryContributors"
                ) as ContentSummaryContributors | null;

                const contributorsLabel =
                  contributorMetadata?.contributors
                    .map(({ type, names }) =>
                      translateContributorCredit(type, names)
                    )
                    .join(", ") ?? "";

                const hasContextualOverrides =
                  item.hed?.length || item.dek?.length;

                return (
                  <StyledSearchResult
                    result={
                      {
                        ...item.node,
                        title: item.hed?.length
                          ? {
                              format: "markdown",
                              content: item.hed,
                            }
                          : item.node?.title,
                      } as ContentSummary
                    }
                    treatment="slat-result"
                    cdnHost={cdnHost}
                    clampTitle={5}
                    hasContextualOverrides={hasContextualOverrides}
                  >
                    <SlatResult.ResultIndex index={index + 1} />
                    <SlatResult.Byline contributorsLabel={contributorsLabel} />
                    <SlatResult.ActionsMenu
                      id={item?.node?.id || ""}
                      actions={curationActionMenuItems}
                    />
                  </StyledSearchResult>
                );
              }}
            </StyledDraggableList>
          ) : (
            <EmptyContainerMessage>
              {intl.formatMessage({
                defaultMessage: "No Content added",
                description: "Message when no curations added",
              })}
            </EmptyContainerMessage>
          )}

          <ThemeProvider theme="dark">
            <FloatingActionBar>
              <Button
                aria-label={addContentButtonLabel}
                onClick={() => {
                  setIsSelectingCurations(true);
                }}
                disabled={curationLimitReached}
              >
                <AddIcon size="regular" />
                <FormattedMessage defaultMessage="Add content" />
              </Button>
            </FloatingActionBar>
          </ThemeProvider>
        </ContainerBody>
        <ContainerFooter
          moveContainer={moveContainer}
          containerIndex={containerIndex}
          totalContainerCount={totalContainerCount}
          isCollapsed={isCollapsed}
          toggleContainerCollapsed={toggleContainerCollapsed}
          containerKey={containerData.containerKey}
        />
      </ContainerCard>
    </>
  );
});
ListContainer.displayName = "ListContainer";
export default ListContainer;

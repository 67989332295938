import styled from "styled-components";
import { Button, Title } from "@components";
import { BackIcon } from "@condenast/gemini/icons";
import { useIntl, FormattedMessage } from "react-intl";
import { VideoConfiguration } from "../VideoConfiguration";
import {
  CneVideosOverrideEdge,
  VideosLede,
  MediaOverrideConnection,
  MediaNode,
} from "@types";
import { useCallback, useMemo, useState, useEffect } from "react";
import { ThemeProvider } from "@contexts";
import { get } from "@lib";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: inherit;
  max-width: 100vw;
  width: 100%;
  z-index: ${(props) => props.theme.ElevationModal};
`;

const CreateHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.Color};

  & button {
    color: inherit;
    margin-right: var(--spacing-sm);
  }
`;

const PageTitle = styled(Title)`
  padding: var(--spacing-sm) 0;
`;

const VideoConfigurationWrapper = styled.div`
  display: flex;
  padding: var(--spacing-sm) var(--spacing-sm);
  width: max-content;
  margin: auto;
`;

const StyledSubmitBar = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  position: fixed;
  right: var(--spacing-sm);
  bottom: var(--spacing-sm);
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  align-items: center;
  padding: var(--spacing-xs);
`;

const EditPageContainer = styled.div`
  display: grid;
  position: fixed;
  grid-template-areas:
    ". . . . . ."
    ". title search-bar toggle counter ."
    ". tab-list result-list result-list result-list ."
    ". . pagination pagination . .";
  grid-template-columns: auto calc(var(--spacing-sm) * 15) 1fr auto auto auto;
  grid-template-rows: auto auto auto 1fr;
  gap: var(--spacing-sm);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: ${(props) => props.theme.ElevationModal};
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
`;

const defaultVideoConfig = {
  disableAutoplay: false,
  muted: true,
  loopVideo: false,
  disableAds: false,
  continuousPlay: false,
};

const VIDEOS_LEDE_REL = "videosLede";

export function EditPage({
  cancelCneUpload,
  setValue,
  goBack,
  model,
  selectedAssetId,
  videosLede,
  assetUpdateToModel,
}: {
  cancelCneUpload: () => void;
  setValue:
    | ((name: string, updatedMediaoverrides: MediaOverrideConnection) => void)
    | undefined;
  goBack: () => void;
  model: Record<string, unknown> | undefined;
  videosLede: VideosLede | undefined;
  selectedAssetId: string | undefined | null;
  assetUpdateToModel: () => void;
}) {
  const intl = useIntl();

  const initialVideoConfig = useMemo(
    () =>
      (
        (
          get(model, "relMediaOverrides") as MediaOverrideConnection | null
        )?.edges.find(
          (edge) =>
            edge.relName === VIDEOS_LEDE_REL && edge.node.id === selectedAssetId
        ) as CneVideosOverrideEdge | null
      )?.cneVideoOverrides ?? defaultVideoConfig,
    [videosLede?.id]
  );
  const [videoConfig, setVideoConfig] = useState(initialVideoConfig);

  const updateMediaOverrides = useCallback(() => {
    const overridesValue = get(
      model,
      "relMediaOverrides"
    ) as MediaOverrideConnection;
    const newOverrides = {
      ...overridesValue,
      edges: overridesValue.edges
        .filter((edge) => edge.relName != VIDEOS_LEDE_REL)
        .concat({
          __typename: "CneVideosOverrideEdge",
          relName: VIDEOS_LEDE_REL,
          cneVideoOverrides: { __typename: "CneVideoOverride", ...videoConfig },
          node: { ...videosLede } as MediaNode,
        }),
    };
    setValue?.("relMediaOverrides", newOverrides);
    assetUpdateToModel();
  }, [videosLede, videoConfig, setValue, model, assetUpdateToModel]);

  useEffect(() => {
    if ("Intercom" in window) {
      Intercom("update", {
        hide_default_launcher: true,
      });
    }
    return () => {
      if ("Intercom" in window) {
        Intercom("update", {
          hide_default_launcher: false,
        });
      }
    };
  });

  return (
    <ThemeProvider theme="dark">
      <EditPageContainer>
        <Wrapper>
          <CreateHeader>
            <Button
              onClick={goBack}
              aria-label="Go Back"
              tabIndex={-1}
              aria-hidden="true"
            >
              <BackIcon size="regular" />
            </Button>
            <PageTitle>
              {intl.formatMessage({
                defaultMessage: "Configure Story Video",
              })}
            </PageTitle>
          </CreateHeader>
          <VideoConfigurationWrapper>
            <VideoConfiguration
              videoConfig={videoConfig}
              setVideoConfig={setVideoConfig}
              videosLede={videosLede}
            />
          </VideoConfigurationWrapper>
          <StyledSubmitBar>
            <Button onClick={cancelCneUpload}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button onClick={updateMediaOverrides} treatment="primary">
              <FormattedMessage defaultMessage="Submit" />
            </Button>
          </StyledSubmitBar>
        </Wrapper>
      </EditPageContainer>
    </ThemeProvider>
  );
}
EditPage.displayName = "EditPage";

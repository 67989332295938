import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, CategorySelector, Chip, Label } from "@components";
import { ContentTypeTaxonomyIcon, CloseIcon } from "@condenast/gemini/icons";
import { useIntl } from "react-intl";
import { useDebouncedCallback } from "@hooks";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { Queries } from "@gql";
import {
  Category,
  CategoryData,
  GetCategoryChildren,
  GetCategoryChildrenVariables,
  GetRootCategory,
  GetRootCategoryVariables,
  Taxonomy,
} from "@types";
import { AdvancedSearchOptions } from "../types";

const Wrapper = styled.div<{ $isMobile?: boolean }>`
  width: 100%;
  padding: ${(props) => (props.$isMobile ? "0 0 var(--spacing-xxs) 0" : "0")};
  margin-bottom: ${(props) =>
    props.$isMobile ? "var(--spacing-sm)" : "initial"}; ;
`;

const StyledCategorySelector = styled(CategorySelector)<{
  $takeoverView?: boolean;
}>`
  ${({ theme, $takeoverView }) => {
    return `
      > div {
        min-width: unset;
        svg.select-chevron {
          right: var(--spacing-xs);
        }
      }
      button {
        padding: var(--spacing-xs);
        padding-right: var(--spacing-lg);
        background: ${
          $takeoverView ? theme.SecondaryActiveBackground : theme.Background
        };

        &:not([disabled]):hover {
          background-color: ${
            $takeoverView ? theme.SecondaryActiveBackground : theme.Background
          };
          box-shadow: ${theme.FieldHoverRing};
        }
      }
      button label {
        display: flex;
        align-items: center;
        gap: var(--spacing-xs);
      }
    `;
  }}
`;

const MobileChips = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  font: var(--font-small-statements);
`;

const MobileChipList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-xs) 0;

  &.selection-list--empty {
    padding: 0;
  }
`;

const Selection = styled(Chip)`
  padding-right: var(--spacing-xxs);
  margin-right: var(--spacing-xxs);
  margin-bottom: var(--spacing-xxs);
  background-color: ${(props) => props.theme.MedallionBackground};

  button {
    background: transparent;
    &:not([disabled]):hover {
      background: transparent;
    }
  }
`;

const SelectionLabel = styled.span`
  margin-right: var(--spacing-xxs);
`;

type CategoryWithFilters = Category & { filters?: string[] };

function normalizeCategoryData(
  categories: CategoryData[],
  isSelectable?: boolean,
  noReverse?: boolean
): CategoryWithFilters[] {
  const transformedCategories: Category[] = categories?.map((category) => {
    let hierarchy = category.hierarchy.map((h) => ({
      name: h.name ?? "",
      slug: h.slug,
    }));
    let filters = hierarchy.map((h) => h.slug);
    return {
      id: category.id,
      name: category.name ?? "",
      slug: category.slug,
      hierarchy: noReverse ? hierarchy : hierarchy.reverse(),
      selectable: isSelectable,
      childCount: category.meta?.childCount ?? undefined,
      filters,
    };
  });

  return transformedCategories;
}

export const ChannelFilter = (props: {
  currentFilters: AdvancedSearchOptions;
  onChange: (updatedCategories: AdvancedSearchOptions) => void;
  organizationId: string;
  categoryConfigType: string;
  mobileView?: boolean;
  takeoverView?: boolean;
  hideLabel?: boolean;
}) => {
  const intl = useIntl();
  const {
    currentFilters,
    onChange,
    organizationId,
    categoryConfigType,
    mobileView,
    hideLabel,
    takeoverView,
  } = props;

  const [
    _lazySearchChannelsData,
    { data: channelsData, loading: loadingChannelsData },
  ] = useLazyQuery(Queries.CATEGORY_SEARCH);

  const performChannelSearch = useDebouncedCallback((query: string) => {
    if (!query) return;
    _lazySearchChannelsData({
      variables: {
        organizationId,
        filters: {
          q: query || "",
          hierarchy: categoryConfigType,
        },
      },
    });
  }, 250);

  const { data: rootData } = useQuery<
    GetRootCategory,
    GetRootCategoryVariables
  >(Queries.GET_ROOT_CATEGORY, {
    variables: {
      organizationId,
      slug: categoryConfigType,
    },
  });

  const roots =
    rootData && rootData.rootCategory
      ? normalizeCategoryData(rootData.rootCategory, false)
      : [];

  const Client = useApolloClient();
  const rootName = categoryConfigType && roots[0]?.name;

  const selectedCategoryTaxonomies = useMemo(
    () =>
      currentFilters.categories
        ? [
            {
              name: categoryConfigType,
              slug: categoryConfigType,
              categories: currentFilters.categories ?? [],
            },
          ]
        : [],
    [categoryConfigType, currentFilters.categories]
  );

  const selectedCategories = useMemo(
    () =>
      selectedCategoryTaxonomies.find(
        (taxonomy) => taxonomy.slug === categoryConfigType
      )?.categories || [],
    [selectedCategoryTaxonomies, categoryConfigType]
  );

  const mobilePlaceholder = selectedCategories.length
    ? intl.formatMessage(
        {
          defaultMessage: "{count} selected",
          description: "Search filter placeholder when >= 1 options selected",
        },
        { count: selectedCategories.length }
      )
    : intl.formatMessage({
        defaultMessage: "Select",
        description: "Search filter placeholder when no options selected",
      });
  const placeholder = rootName
    ? rootName
    : intl.formatMessage({
        defaultMessage: "Category",
        description: "Category search filter label",
      });

  const normalizedChannelSearch: Category[] = channelsData
    ? normalizeCategoryData(channelsData.categorySearch, true)
    : [];

  const onCategoriesChange = useCallback(
    (selectedTaxonomies: Taxonomy[]) => {
      onChange({
        categories: selectedTaxonomies.reduce(
          (selectedCategories, selectedTaxonomy) => {
            selectedCategories.push(...selectedTaxonomy.categories);
            return selectedCategories;
          },
          [] as Category[]
        ),
      });
    },
    [onChange, categoryConfigType]
  );

  return (
    <Wrapper $isMobile={mobileView}>
      {!hideLabel && <Label>{placeholder}</Label>}
      <StyledCategorySelector
        aria-invalid={false}
        $takeoverView={takeoverView}
        placeholder={
          mobileView ? (
            mobilePlaceholder
          ) : (
            <>
              <ContentTypeTaxonomyIcon size="small" />
              {placeholder}
            </>
          )
        }
        selections={selectedCategoryTaxonomies}
        search={performChannelSearch}
        searchResult={{
          loading: loadingChannelsData,
          data: normalizedChannelSearch,
        }}
        onChange={onCategoriesChange}
        roots={roots}
        hideCategorySlats={true}
        getChildren={(category) =>
          Client.query<GetCategoryChildren, GetCategoryChildrenVariables>({
            query: Queries.GET_CATEGORY_WITH_CHILDREN,
            variables: {
              organizationId,
              id: category.id,
              limit: category.childCount ?? 100,
            },
          }).then((result) => {
            const children = result.data.category.content
              ? result.data.category.content.results
              : [];
            const filteredChildren = children.filter((child) => {
              if (child.slug === "ContentHeader") {
                return !child.slug.includes("ContentHeader");
              }
              return child;
            });
            return normalizeCategoryData(filteredChildren, true);
          })
        }
      />
      {mobileView && selectedCategories?.length > 0 && (
        <MobileChips>
          <MobileChipList>
            {selectedCategories?.map((category) => {
              return (
                <Selection forwardedAs="li" key={category.slug} size="small">
                  <SelectionLabel>{category.name}</SelectionLabel>
                  <Button
                    type="button"
                    size="small"
                    aria-label={
                      intl.formatMessage(
                        {
                          defaultMessage: "Remove {item}",
                          description:
                            "button to remove an item from a list of selected items",
                        },
                        { item: category.name }
                      ) as string
                    }
                    onClick={() => {
                      onChange({
                        categories: selectedCategories?.filter(
                          (selectedCategory) =>
                            selectedCategory.id !== category.id
                        ),
                      });
                    }}
                  >
                    <CloseIcon size="small" />
                  </Button>
                </Selection>
              );
            })}
          </MobileChipList>
        </MobileChips>
      )}
    </Wrapper>
  );
};

import {
  ControlProps,
  FormFor_form_controls_TimePickerFormControl,
} from "@types";
import { useCallback, useMemo, useEffect } from "react";
import { TimePicker as TimePickerImplementation } from "@components";
import { get } from "@lib";
import { useDefinedMessages } from "@hooks";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

export function TimePicker(
  props: ControlProps<FormFor_form_controls_TimePickerFormControl>
) {
  const { translateFieldName } = useDefinedMessages();
  const { name, model, setValue, labelKey, timeZonePath } = props;
  const timePickerId = `time-picker-${name}`;

  const dateString = get(model, name) as string | null;
  const timeZone = timeZonePath && (get(model, timeZonePath) as string | null);
  const dateValue = useMemo(() => {
    if (dateString) {
      return new Date(dateString);
    }
    const blankDate = new Date();
    blankDate.setHours(0);
    blankDate.setMinutes(0);
    blankDate.setSeconds(0);
    blankDate.setMilliseconds(0);
    return blankDate;
  }, [dateString]);

  const localDateTime =
    (timeZone && utcToZonedTime(dateValue, timeZone)) || dateValue;

  const onDateSelect = useCallback(
    (value: Date) => {
      const newDateTime = timeZone ? zonedTimeToUtc(value, timeZone) : value;
      setValue(name, newDateTime);
    },
    [name, setValue, timeZone]
  );

  useEffect(() => {
    const newDateTime = timeZone
      ? zonedTimeToUtc(localDateTime, timeZone)
      : localDateTime;
    if (dateValue.getTime() !== newDateTime.getTime()) {
      setValue(name, newDateTime);
    }
  }, [timeZone, dateValue, setValue]);

  return (
    <TimePickerImplementation
      id={timePickerId}
      label={labelKey ? translateFieldName(labelKey) : translateFieldName(name)}
      onChange={onDateSelect}
      date={localDateTime}
    />
  );
}
TimePicker.displayName = "Control(TimePicker)";

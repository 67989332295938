import { ARIA, Button } from "@components";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useContext, useCallback } from "react";
import { SnowplowContext } from "@contexts";

const Dialog = styled(ARIA.Dialog)`
  overflow: visible;
`;

export const OverwriteWarningDialog = (props: {
  onForceSaveOverwrite: () => void;
  onOpenLatestRevision: () => void;
  onClose: () => void;
}) => {
  const { onClose, onForceSaveOverwrite, onOpenLatestRevision } = props;
  const intl = useIntl();
  const { trackComponentEvent } = useContext(SnowplowContext);
  const saveAnyway = useCallback(() => {
    onForceSaveOverwrite();
    trackComponentEvent(
      "content_overwrite_dialog",
      "button_click",
      "force_save_overwrite"
    );
  }, [onForceSaveOverwrite, trackComponentEvent]);

  const openLatest = useCallback(() => {
    onOpenLatestRevision();
    trackComponentEvent(
      "content_overwrite_dialog",
      "button_click",
      "open_latest_revision"
    );
  }, [onOpenLatestRevision, trackComponentEvent]);

  const closeDialog = useCallback(() => {
    onClose();
    trackComponentEvent("unsaved_changed_dialog", "close", "close");
  }, [onClose, trackComponentEvent]);

  return (
    <Dialog
      size={"regular"}
      title={intl.formatMessage({
        defaultMessage: "You're about to overwrite other changes",
        description: "Overwrite warning dialog heading",
      })}
      description={intl.formatMessage({
        defaultMessage:
          "This article was updated and you’re no longer on the latest version. To see the updates or manually add your changes, you can open the latest version in a new tab.",
        description: "Overwrite warning dialog description",
      })}
      cancelButton={
        <Button>
          <FormattedMessage
            defaultMessage="Go Back"
            description="Go Back button in overwrite warning dialog"
          />
        </Button>
      }
      deleteButton={
        <Button onClick={saveAnyway}>
          <FormattedMessage
            defaultMessage="Save anyway"
            description="Save anyway button in overwrite warning dialog"
          />
        </Button>
      }
      submitButton={
        <Button onClick={openLatest} treatment="primary" type="submit">
          <FormattedMessage
            defaultMessage="Open latest revision"
            description="Open latest revision button in overwrite warning dialog"
          />
        </Button>
      }
      onClose={closeDialog}
    />
  );
};
OverwriteWarningDialog.displayName = "OverwriteWarningDialog";

import React from "react";
import styled from "styled-components";

const Wrapper = styled.div<{
  $isToastVisible: boolean;
  $isBulkSelecting: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-self: center;
  position: ${({ $isBulkSelecting }) =>
    $isBulkSelecting ? "fixed" : "sticky"};
  bottom: ${({ $isToastVisible }) =>
    $isToastVisible ? "calc(var(--spacing-lg) + 80px)" : "var(--spacing-lg)"};
  z-index: calc(${({ theme }) => theme.ElevationCard} - 1);
  background-color: ${({ theme }) => theme.Background};
  color: ${({ theme }) => theme.Color};
  border-radius: ${({ theme }) => theme.CornerRadius};
  box-shadow: ${({ theme }) => theme.CardShadow};
  background: ${({ theme }) => theme.Background};
`;

// ButtonList component
const ButtonList = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs);

  & button {
    margin-right: var(--spacing-xxs);

    &:first-of-type {
      margin-left: var(--spacing-xxs);
    }
  }
`;

export const FloatingActionBar: React.FC<{
  isToastVisible?: boolean;
  isBulkSelecting?: boolean;
  children: React.ReactNode;
}> = ({ isToastVisible, isBulkSelecting, children }) => {
  return (
    <Wrapper
      $isToastVisible={isToastVisible || false}
      $isBulkSelecting={isBulkSelecting || false}
    >
      <ButtonList>{children}</ButtonList>
    </Wrapper>
  );
};

FloatingActionBar.displayName = "FloatingActionBar";

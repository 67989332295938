import { ReactNode, ComponentProps, useCallback } from "react";
import { Link } from "@components";
import { useQuery } from "@apollo/client";
import { Queries, Variables } from "@gql";

const { toastPropsVar, toastTimerVar } = Variables;
const { GET_TOAST_TIMER } = Queries;

/**
 * Due to how react and the apollo cache interact, we
 * cannot pass react nodes to the cache for toasts.
 * However, we often want to pass a <Link> object in toasts.
 * To get around this, useToast takes an object with
 * the action property being an actionInfo object.
 * This object can then be converted into a <Link>
 * after it has been taken out of the cache, which
 * is done in the toastDrawer.
 */

type ActionInfo = {
  message: string;
  linkProps?: ComponentProps<typeof Link>;
};

export type ToastProps = {
  type: "success" | "informational" | "warning" | "error";
  details?: string;
  action?: ActionInfo;
  className?: string;
  onClose?: () => void;
  children?: ReactNode | string;
};

function setToastProps(props: ToastProps, timer?: number) {
  toastPropsVar(props);

  window.clearTimeout(timer);
  let timerID: number | undefined = undefined;
  if (!props.onClose) {
    timerID = window.setTimeout(() => {
      toastPropsVar(undefined);
    }, 6000);
  }

  toastTimerVar(timerID);
}

export function useToast(props: ToastProps) {
  let { data } = useQuery(GET_TOAST_TIMER);
  const { type, details, action, className, onClose, children } = props;
  return useCallback(() => {
    setToastProps(
      { type, details, action, className, onClose, children },
      data?.toast.timer
    );
  }, [data, type, details, action, className, onClose, children]);
}

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { required, geo, optional } from "@condenast/copilot-validators";

import type { ValidationContext, ValidationEnvironment } from "@lib";
import type { FormFor_content_Venue } from "@types";

const { object } = validators;

const venueValidators = object({
  geo: optional(geo()),
}).andAlso(
  object({ name: required() })
    .or(object({ hed: required() }))
    .catch((errors) =>
      errors.map(() => ({
        path: ["name"],
        message: { name: "blank", details: null },
      }))
    )
);

export default function validateVenue(
  venue: FormFor_content_Venue,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(venue, build(venueValidators), context, env);
}

import { useCallback, useEffect } from "react";

export const usePostMessage = (
  messageHandler: (data: unknown) => void,
  origin: string
) => {
  const sendMessage = useCallback(
    (action: string, data: unknown = {}) => {
      if (window && window.parent) {
        window.parent.postMessage({ action, content: data }, origin);
      }
    },
    [origin]
  );
  useEffect(() => {
    if (!origin) {
      return;
    }
    const handleMessageFromParent = ({
      origin: messageOrigin,
      data,
    }: MessageEvent) => {
      if (messageOrigin !== origin) return;
      messageHandler(data);
    };
    window.addEventListener("message", handleMessageFromParent);
    sendMessage("iframe_loaded");
    return () => window.removeEventListener("message", handleMessageFromParent);
  }, [origin, sendMessage, messageHandler]);
  return sendMessage;
};

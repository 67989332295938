import { useMemo } from "react";
import {
  GetBrand_brandConfiguration_contentTypes as ContentType,
  GetCurrentUser_currentUser,
} from "@types";
import { useIntl } from "react-intl";
import {
  CheckCircleIcon,
  ContentTypeArticleIcon,
} from "@condenast/gemini/icons";
import { Select } from "@components";
import {
  MoreFilter,
  PeopleFilter,
  SearchDateFilter,
  ChannelFilter,
} from "../searchFilters";
import { FilterPillList } from "../FilterPillList";
import styled from "styled-components";
import { useDefinedMessages } from "@hooks";
import { MainSearchAdapterFactory, AdvancedSearchOptions } from "../types";
import { getAppliedFiltersCount } from "../helpers";

const ADAPTER_OPTIONS = {
  ignoredFilters: ["display" as const],
};

export const StyledSelect = styled(Select)<{ $isMobile?: boolean }>`
  min-width: max-content;
  padding: ${(props) => (props.$isMobile ? "0 0 var(--spacing-xxs) 0" : "0")};
  grid-template-columns: 100%;
  grid-gap: 0px;
  ul[role="listbox"] {
    min-width: max-content;
    transform: ${(props) =>
      //44px is the height of the select box
      props.$isMobile ? "none" : "translate(0px, 44px) !important"};
  }
  ul:not([role="listbox"]) {
    display: ${(props) => (props.$isMobile ? "block" : "none")};
    padding: var(--spacing-xs) 0;
  }
`;

export const CustomFilterWrapper = styled.div<{ $isMobile?: boolean }>`
  padding: ${(props) => (props.$isMobile ? "0 0 var(--spacing-xs) 0" : "0")};
`;

const AllListLabel = () => {
  const intl = useIntl();
  return (
    <span>
      {intl.formatMessage({
        defaultMessage: "All",
        description: "Search adapter name for all content",
      })}
    </span>
  );
};

const MoreFilterList = (props: {
  searchParams: AdvancedSearchOptions;
  onChange: (updatedFilters: AdvancedSearchOptions) => void;
  contentTypes?: ContentType[];
  statusOptions?: string[];
  dateRangeOptions?: string[];
  availableLanguages?: (string | null)[];
  enableLanguageFilters?: boolean;
  organizationId: string;
  currentUser: GetCurrentUser_currentUser;
  mobileView?: boolean;
  syndicationEnabled: boolean;
  categoryConfigType?: string | null;
}) => {
  const { translateLanguage } = useDefinedMessages();
  const {
    searchParams,
    onChange,
    dateRangeOptions,
    availableLanguages,
    enableLanguageFilters,
    organizationId,
    mobileView,
    categoryConfigType,
  } = props;

  const intl = useIntl();

  return (
    <>
      <CustomFilterWrapper $isMobile={mobileView}>
        <SearchDateFilter
          selectedFilters={searchParams}
          onChange={(date) =>
            onChange({
              date,
            })
          }
          dateRangeOptions={dateRangeOptions}
          mobileView={mobileView}
        />
      </CustomFilterWrapper>
      {enableLanguageFilters && (
        <CustomFilterWrapper $isMobile={mobileView}>
          <StyledSelect
            id="search-lang"
            $isMobile={mobileView}
            label={intl.formatMessage({ defaultMessage: "Language" })}
            placeholder={intl.formatMessage({
              defaultMessage: "Language",
            })}
            value={searchParams?.lang || []}
            onChange={(lang: string[] | undefined) =>
              onChange({
                lang,
              })
            }
            options={
              availableLanguages?.map((lang) => {
                return {
                  label: translateLanguage(lang ?? ""),
                  value: lang ?? "",
                };
              }) ?? []
            }
          />
        </CustomFilterWrapper>
      )}
      <CustomFilterWrapper $isMobile={mobileView}>
        <ChannelFilter
          currentFilters={searchParams}
          onChange={onChange}
          organizationId={organizationId}
          categoryConfigType={categoryConfigType || ""}
          mobileView={mobileView}
        />
      </CustomFilterWrapper>
    </>
  );
};

const AllFilters = (props: {
  searchParams: AdvancedSearchOptions;
  onChange: (updatedFilters: AdvancedSearchOptions) => void;
  contentTypes?: ContentType[];
  statusOptions?: string[];
  dateRangeOptions?: string[];
  availableLanguages?: (string | null)[];
  enableLanguageFilters?: boolean;
  organizationId: string;
  currentUser: GetCurrentUser_currentUser;
  mobileView?: boolean;
  syndicationEnabled: boolean;
  categoryConfigType?: string | null;
}) => {
  const { translateContentType, translateSelectOption } = useDefinedMessages();
  const {
    searchParams,
    onChange,
    contentTypes,
    statusOptions,
    organizationId,
    currentUser,
    mobileView,
    syndicationEnabled,
  } = props;

  const intl = useIntl();

  return (
    <>
      <StyledSelect
        id="search-content-types"
        $isMobile={mobileView}
        label={
          mobileView ? intl.formatMessage({ defaultMessage: "Type" }) : null
        }
        placeholder={
          mobileView
            ? searchParams.types
              ? intl.formatMessage(
                  {
                    defaultMessage: "{count} selected",
                    description:
                      "Search filter placeholder when >= 1 options selected",
                  },
                  { count: searchParams.types.length }
                )
              : intl.formatMessage({
                  defaultMessage: "Select",
                  description:
                    "Search filter placeholder when no options selected",
                })
            : intl.formatMessage({ defaultMessage: "Type" })
        }
        multiple
        value={searchParams.types || []}
        onChange={(updatedContentTypes: string[] | undefined) =>
          onChange({
            types: updatedContentTypes,
          })
        }
        options={
          contentTypes
            ?.map((contentType) => {
              return {
                label: translateContentType(
                  contentType.value,
                  1,
                  contentType.label
                ),
                value: contentType.value,
              };
            })
            .sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            ) ?? []
        }
        icon={mobileView ? undefined : ContentTypeArticleIcon}
      />
      <StyledSelect
        id="search-status"
        $isMobile={mobileView}
        label={
          mobileView ? intl.formatMessage({ defaultMessage: "Status" }) : null
        }
        placeholder={
          mobileView
            ? searchParams?.status?.length
              ? intl.formatMessage(
                  {
                    defaultMessage: "{count} selected",
                    description:
                      "Search filter placeholder when >= 1 options selected",
                  },
                  { count: searchParams.status.length }
                )
              : intl.formatMessage({
                  defaultMessage: "Select",
                  description:
                    "Search filter placeholder when no options selected",
                })
            : intl.formatMessage({ defaultMessage: "Status" })
        }
        multiple
        value={searchParams.status || []}
        onChange={(value: string[] | undefined) =>
          onChange({
            status: value,
          })
        }
        options={
          statusOptions?.map((status) => {
            return {
              label: translateSelectOption("statusFilterOptions", status),
              value: status,
            };
          }) ?? []
        }
        icon={mobileView ? undefined : CheckCircleIcon}
      />
      <CustomFilterWrapper $isMobile={mobileView}>
        <PeopleFilter
          onChange={onChange}
          currentUser={currentUser}
          mobileView={mobileView}
          selectedFilters={searchParams}
          organizationId={organizationId}
        />
      </CustomFilterWrapper>
      {mobileView && <MoreFilterList {...props} />}
      {!mobileView && (
        <MoreFilter
          currentFilters={searchParams}
          onChange={onChange}
          syndicationEnabled={syndicationEnabled}
          moreFilters={<MoreFilterList {...props} />}
        />
      )}
    </>
  );
};

const AllAppliedFiltersList = (props: {
  searchParams: AdvancedSearchOptions;
  onChange: (updatedFilters: AdvancedSearchOptions) => void;
}) => {
  const { searchParams, onChange } = props;
  const selectedFiltersCount = useMemo(() => {
    return getAppliedFiltersCount(searchParams, ADAPTER_OPTIONS);
  }, [searchParams]);
  return (
    <FilterPillList
      searchParams={searchParams}
      onChange={onChange}
      totalSelections={selectedFiltersCount}
    />
  );
};

export const AllContentAdapterFactory: MainSearchAdapterFactory = ({
  search: searchFromContext,
}) => {
  return {
    id: "all",
    search: (searchParams) => {
      searchFromContext(searchParams);
    },
    isDefault: true,
    Label: AllListLabel,
    Filters: AllFilters,
    AppliedFiltersList: AllAppliedFiltersList,
    AppliedFiltersCount: (searchParams: AdvancedSearchOptions) => {
      return getAppliedFiltersCount(searchParams, ADAPTER_OPTIONS);
    },
    defaultTreatment: "slat",
  };
};

import { useState } from "react";
import { useIntl } from "react-intl";
import { ARIA, Button } from "@components";
import {
  HelpIcon,
  RedirectIcon,
  FormationLightLogoFlatIcon,
  CopilotIcon,
  GoogleChromeLogoFlatIcon,
  CondeNastLogoIcon,
} from "@condenast/gemini/icons";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const ItemDiv = styled.div`
  display: flex;
`;

const ItemText = styled.div`
  line-height: normal;
`;

const LinkButton = styled(Button)`
  margin-left: -1rem;
  &:not(:disabled):active {
    box-shadow: none;
  }
  &:not(:disabled):focus {
    box-shadow: none;
  }
`;

const ItemHeader = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 2rem;
`;

const HelpButton = styled(Button)`
  flex-direction: row;
  color: var(--color-black);
  justify-content: right;
  align-items: right;
  padding-right: 0.7rem;
`;

const Dialog = styled(ARIA.Dialog)`
  padding-top: 1rem;
  min-height: 500px;
  border-radius: var(--spacing-xs);
  border-bottom: 0px;
`;

const DialogBody = styled.div`
  padding: var(--spacing-xs) var(--spacing-md) var(--spacing-sm);
  max-width: 100vw;
  overflow: auto;
  max-height: 100vh;
  border-bottom: 0px;
`;

const InfoItem = styled.div`
  margin-left: 1rem;
  white-space: normal;
`;

const StyledCopilotIcon = styled(CopilotIcon)`
  width: 30px;
  height: 30px;
`;

const StyledCopilotHelpIcon = styled(FormationLightLogoFlatIcon)`
  width: 30px;
  height: 30px;
`;

const StyledChromeIcon = styled(GoogleChromeLogoFlatIcon)`
  width: 30px;
  height: 30px;
`;

const StyledContentIcon = styled(CondeNastLogoIcon)`
  width: 30px;
  height: 30px;
`;

const Divider = styled.hr`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: var(--color-gray-5);
`;

export function HelpMenuButton(props: { className?: string }) {
  let intl = useIntl();
  let [dialog, setDialog] = useState(false);

  return (
    <div className={props.className}>
      <HelpButton
        id="help-menu-button"
        aria-label={intl.formatMessage({
          defaultMessage: "Help",
        })}
        size={"regular"}
        treatment="borderless"
        onClick={() => {
          setDialog(true);
        }}
      >
        <HelpIcon size="regular" />
      </HelpButton>
      {dialog && (
        <Dialog
          title={intl.formatMessage({ defaultMessage: "Copilot Resources" })}
          size={"regular"}
          onClose={() => {
            setDialog(false);
          }}
        >
          <Divider />
          <DialogBody>
            <ItemDiv>
              <StyledCopilotHelpIcon size="regular" />
              <InfoItem>
                <ItemHeader>Copilot Help Center</ItemHeader>
                <ItemText>
                  The Copilot Help center contains tutorials about how to use
                  different features of <br />
                  Copilot, as well as explainers for some of the terminology and
                  functionality.
                </ItemText>
                <LinkButton
                  treatment="text"
                  onClick={() => {
                    window.open(
                      "https://intercom.help/formation/en/collections/161495-copilot-help"
                    );
                  }}
                >
                  <FormattedMessage defaultMessage="Visit help center  ." />
                  <RedirectIcon size="regular" />
                </LinkButton>
              </InfoItem>
            </ItemDiv>
            <Divider />
            <ItemDiv>
              <StyledCopilotIcon />
              <InfoItem>
                <ItemHeader>Copilot Training</ItemHeader>
                <ItemText>
                  The Copilot training session is an hour-long, deep dive into
                  how to use the Copilot <br />
                  application. Trainings are offered multiple times a month.
                </ItemText>
                <LinkButton
                  onClick={() => {
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSek7SyJQWYz6rt7u2kpqU68GmuYsXxQUV-0zZ8GRzDXmQkZ5g/viewform"
                    );
                  }}
                  treatment="text"
                >
                  <FormattedMessage defaultMessage="Sign up for Copilot training  ." />
                  <RedirectIcon size="regular" />
                </LinkButton>
              </InfoItem>
            </ItemDiv>
            <Divider />
            <ItemDiv>
              <StyledChromeIcon size="regular" />
              <InfoItem>
                <ItemHeader>Copilot Chrome Extension</ItemHeader>
                <ItemText>
                  This Extension takes Copilot users from a brand website URL to
                  the corresponding edit <br />
                  page in Copilot.
                </ItemText>
                <LinkButton
                  onClick={() => {
                    window.open(
                      "https://chrome.google.com/webstore/detail/copilot-chrome-extension/lhlaljenmogghbebpeoemjcniedoenpa"
                    );
                  }}
                  treatment="text"
                >
                  <FormattedMessage defaultMessage="Get Copilot Chrome Extension  ." />
                  <RedirectIcon size="regular" />
                </LinkButton>
              </InfoItem>
            </ItemDiv>
            <Divider />
            <ItemDiv>
              <StyledContentIcon size="regular" />
              <InfoItem>
                <ItemHeader>Content Operations Resource Hub</ItemHeader>
                <ItemText>
                  The Content Operations Resource Hub gathers information,
                  documentation, links and
                  <br />
                  all kinds of materials from across the Content Operations
                  teams as well as the company at <br />
                  large.
                </ItemText>
                <LinkButton
                  onClick={() => {
                    window.open(
                      "https://sites.google.com/condenast.com/content-ops-resources-hub/home?pli=1&authuser=1"
                    );
                  }}
                  treatment="text"
                >
                  <FormattedMessage defaultMessage="Visit the Content Ops Resource Hub  ." />
                  <RedirectIcon size="regular" />
                </LinkButton>
              </InfoItem>
            </ItemDiv>
          </DialogBody>
        </Dialog>
      )}
    </div>
  );
}
HelpMenuButton.displayName = "NavBar.HelpMenuButton";

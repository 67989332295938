import { useMutation } from "@apollo/client";
import {
  ContentSummary,
  ImportGettyImage,
  ImportGettyImageVariables,
} from "@types";
import { Mutations } from "@gql";
import { normalizeContentSummary } from "@lib";
import { SearchPostProcessor } from "../types";
import { useDefinedMessages } from "@hooks";

export function useGettyPostProcessor(
  organizationId: string
): SearchPostProcessor {
  const [importImages, { loading }] = useMutation<
    ImportGettyImage,
    ImportGettyImageVariables
  >(Mutations.IMPORT_GETTY_IMAGE);

  const { translateContentType } = useDefinedMessages();

  return {
    loading,
    process: async (items: ContentSummary[]) => {
      const gettyImages = items.filter((item) => item.contentType === "getty");
      if (!gettyImages.length) {
        return items;
      }

      const result = await importImages({
        variables: {
          organizationId,
          data: gettyImages
            .filter((item) => item.contentType === "getty")
            .map((item) => ({
              id: item.id,
              height: item.asset?.height ?? undefined,
            })),
        },
      });

      const resultData = result.data?.importGettyImage.map((result) =>
        normalizeContentSummary(result, translateContentType)
      );
      if (!resultData) {
        return items;
      }

      return items.map((item) =>
        item.contentType === "getty" ? resultData.shift() ?? null : item
      );
    },
  };
}

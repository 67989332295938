// This file was automatically generated by generate-possible-types.js and should not be edited.
export const PossibleTypes = {
  DistributionChannelConfig: [
    "WebOwnedAndOperatedDistributionChannelConfig",
    "MobileAppDistributionChannelConfig",
    "FacebookNewsDistributionChannelConfig",
    "AppleNewsDistributionChannelConfig",
  ],
  IBundleContainerConfig: [
    "CoreBundleContainerConfig",
    "LegacyBundleContainerConfig",
  ],
  BundleContainerConfig: [
    "LegacyBundleContainerConfig",
    "CoreBundleContainerConfig",
  ],
  ContentSummaryMetadata: [
    "ContentSummaryOffer",
    "ContentSummaryRating",
    "ContentSummaryDimensions",
    "ContentSummaryCategory",
    "ContentSummarySlideCount",
    "ContentSummaryCNEVideo",
    "ContentSummaryContributors",
    "ContentSummaryExternalID",
    "ContentSummaryBundle",
  ],
  FormControl: [
    "FieldsetFormControl",
    "CurationTypeSelectFormControl",
    "FieldsectionFormControl",
    "RowFormControl",
    "ColumnFormControl",
    "IfModelValueEqualsFormControl",
    "MdEditorFormControl",
    "ArticleEditorFormControl",
    "TextFieldFormControl",
    "TextAreaFormControl",
    "PublishDataFormControl",
    "TagPublishDataFormControl",
    "GeoFieldFormControl",
    "MultiSelectFormControl",
    "CategorySelectorFormControl",
    "TagSearchFormControl",
    "CategoryOptionsFormControl",
    "SelectFormControl",
    "ContentTypeSelectFormControl",
    "ColumnMultiSelectFormControl",
    "NumberFieldFormControl",
    "CheckboxFormControl",
    "ThumbnailFormControl",
    "CheckboxGroupFormControl",
    "CardListFormControl",
    "CollectionWithCreateFormControl",
    "ReplicatorFormControl",
    "TagSelectorFormControl",
    "CategoryAutocompleteFormControl",
    "TagsFormControl",
    "ContributorFormControl",
    "SEOFormControl",
    "TagRecommendationsFormControl",
    "ChannelsFormControl",
    "DatePickerFormControl",
    "TimePickerFormControl",
    "TimezoneFormControl",
    "ReadOnlyFormControl",
    "RadioButtonGroupFormControl",
    "StyleSettingFormControl",
    "RadioGroupFormControl",
    "TranscriptUploaderFormControl",
    "BylinePreviewFormControl",
    "DurationFormControl",
    "PageLayoutFormControl",
    "NativeFieldsFormControl",
    "LayoutOptionsFormControl",
    "CurationsFormControl",
    "IncludeBundleTagsFormControl",
    "PublishInfoFormControl",
    "ValidationsFormControl",
    "CarouselFieldsetFormControl",
    "GalleryChildHeaderFormControl",
    "SlideCarouselFormControl",
    "GalleryDetailPaginationFormControl",
    "ContextualFieldsSubFormFormControl",
    "SlideCaptionFormControl",
    "SlideTagsFormControl",
    "SlideBodyFormControl",
    "ExcludeBundleTagsFormControl",
    "ApplePushNotificationFormControl",
    "PromoExperimentFormControl",
    "SortableGalleryFormControl",
    "SortableCuratedListItemsFormControl",
    "VideosLedeFormControl",
    "EditMediaHeaderFormControl",
    "CollaboratorSelectorFormControl",
    "ItemReviewedFormControl",
    "ImageEditorFormControl",
    "RecipePreparationFormControl",
    "NativeRecipePreparationFormControl",
    "MemberSharingFormControl",
    "ReadOnlyInformationFormControl",
    "ToggleFormControl",
    "ContextualInfoFormControl",
    "AspectRatioSelectFormControl",
    "RecipeIngredientsFormControl",
    "OfferAutofillFormControl",
    "OfferPriceFormControl",
    "CreateLivestoryupdatesBannerFormControl",
    "ActivitySubtypeSelectFormControl",
    "TabListFormControl",
    "TabFormControl",
    "BundleContainersFormControl",
  ],
  Translatable: ["Article", "Gallery"],
  FrontEndApplication: ["CommonFrontEndApplication"],
  FrontEndBuild: ["CommonFrontEndBuild"],
  FrontEndRef: ["CommonFrontEndRef"],
  LayoutMutationResponse: ["UpsertLayoutConfigsResponse"],
  Entity: ["LayoutConfigs", "BundleLayoutPreset"],
  LayoutPreset: ["BundleLayoutPreset"],
  MediaOverrides: ["CommonMediaOverrides"],
  MediaOverride: ["CommonMediaOverride"],
  IBusiness: ["Business"],
  IAddress: ["BusinessAddress"],
  IContactPoint: ["BusinessContactPoint"],
  IRecipe: ["Recipe"],
  HasQueryInput: ["TagPage"],
  Node: [
    "ContentSummary",
    "CommonFrontEndApplication",
    "CommonFrontEndBuild",
    "CommonFrontEndRef",
    "Activity",
    "Advertiser",
    "Article",
    "AudioFile",
    "Brand",
    "Bundle",
    "Business",
    "Cartoon",
    "City",
    "Clip",
    "Contact",
    "Contributor",
    "Cookbook",
    "CulturalEvent",
    "CuratedList",
    "CuratedSearch",
    "Event",
    "ExternalLink",
    "External",
    "Gallery",
    "InfoPage",
    "LiveStory",
    "Livestoryupdate",
    "MusicAlbum",
    "MusicGroup",
    "MusicRecording",
    "MusicRelease",
    "MusicReview",
    "Person",
    "Photo",
    "Product",
    "ProductReview",
    "ProductRating",
    "Rcalist",
    "Recipe",
    "Redirect",
    "TagPage",
    "Taxonomy",
    "Venue",
  ],
  Edge: [
    "FrontEndApplicationToBuildEdge",
    "FrontEndApplicationToRefEdge",
    "EmbedEdge",
    "CneVideosOverrideEdge",
    "PhotosOverrideEdge",
    "ContainerToCurationEdge",
    "ContainerToAssetEdge",
    "PlaceToContactEdge",
    "CuratedListItemToCuratedListEdge",
    "GalleryToItemEdge",
    "LiveStoryToSortedLiveUpdatesEdge",
    "MusicGroupToGenreEdge",
    "MusicReviewToMusicEdge",
    "ProductRatingToProductEdge",
    "ProductReviewToProductRatingEdge",
    "ProductReviewToProductEdge",
    "LedeEdge",
    "ToutEdge",
    "ContentToContentEdge",
    "ContentToContributorEdge",
    "MediaEdge",
    "SocialMediaEdge",
  ],
  Connection: [
    "FrontEndApplicationToRefsConnection",
    "FrontEndApplicationToBuildsConnection",
    "ContainerToCurationsConnection",
    "ContainerToAssetConnection",
    "PlaceToContactConnection",
    "CuratedListItemsToCuratedListConnection",
    "GalleryToItemsConnection",
    "LiveStoryToSortedLiveUpdatesConnection",
    "MusicReviewToMusicConnection",
    "ProductReviewToProductConnection",
    "EmbedsConnection",
    "ContentConnection",
    "ContentToContributorConnection",
    "SocialMediaConnection",
    "MediaConnection",
  ],
  Tout: ["Clip", "Photo"],
  Album: ["MusicAlbum"],
  Comment: [],
  Content: [
    "Activity",
    "Advertiser",
    "Article",
    "AudioFile",
    "Brand",
    "Bundle",
    "Business",
    "Cartoon",
    "City",
    "Clip",
    "Contributor",
    "Cookbook",
    "CulturalEvent",
    "CuratedList",
    "CuratedSearch",
    "Event",
    "ExternalLink",
    "External",
    "Gallery",
    "InfoPage",
    "LiveStory",
    "Livestoryupdate",
    "MusicAlbum",
    "MusicGroup",
    "MusicRecording",
    "MusicRelease",
    "MusicReview",
    "Person",
    "Photo",
    "Product",
    "ProductReview",
    "Rcalist",
    "Recipe",
    "Redirect",
    "TagPage",
    "Taxonomy",
    "Venue",
  ],
  HasCategories: ["Contributor", "Event"],
  HasContributors: [
    "Article",
    "Bundle",
    "Cookbook",
    "Event",
    "Gallery",
    "InfoPage",
    "LiveStory",
    "Livestoryupdate",
    "MusicGroup",
    "MusicReview",
    "ProductReview",
    "Recipe",
    "Taxonomy",
  ],
  HasMedia: [
    "Article",
    "Bundle",
    "Clip",
    "Contributor",
    "CulturalEvent",
    "CuratedList",
    "CuratedSearch",
    "ExternalLink",
    "External",
    "Gallery",
    "InfoPage",
    "LiveStory",
    "MusicReview",
    "Product",
    "ProductReview",
    "Rcalist",
    "Recipe",
  ],
  HasRecommendations: [
    "Article",
    "Gallery",
    "InfoPage",
    "MusicGroup",
    "MusicReview",
    "ProductReview",
    "Recipe",
  ],
  HasSEO: [
    "Activity",
    "Article",
    "Brand",
    "Bundle",
    "Business",
    "CuratedList",
    "CuratedSearch",
    "Gallery",
    "InfoPage",
    "LiveStory",
    "MusicReview",
    "Person",
    "ProductReview",
    "Recipe",
    "Taxonomy",
    "Venue",
  ],
  HasSyndication: [
    "Article",
    "Clip",
    "Contributor",
    "ExternalLink",
    "External",
    "Gallery",
    "Photo",
    "Recipe",
    "Venue",
  ],
  ILiveStory: ["LiveStory"],
  ILivestoryupdate: ["Livestoryupdate"],
  IOffer: ["LodgingOffer"],
  IReview: ["MusicReview", "ProductReview"],
  LinkBanner: [],
  Media: ["Cartoon", "Clip", "Photo"],
  MusicItem: ["MusicRecording", "MusicRelease"],
  Publishable: [
    "Activity",
    "Article",
    "AudioFile",
    "Brand",
    "Bundle",
    "Business",
    "Cartoon",
    "Contributor",
    "Cookbook",
    "CulturalEvent",
    "CuratedList",
    "CuratedSearch",
    "Event",
    "Gallery",
    "InfoPage",
    "LiveStory",
    "MusicGroup",
    "MusicReview",
    "Person",
    "Product",
    "ProductReview",
    "Rcalist",
    "Recipe",
    "Redirect",
    "TagPage",
    "Taxonomy",
    "Venue",
  ],
  Rating: ["ProductRating"],
  Recommendable: [
    "Activity",
    "Article",
    "Cartoon",
    "CuratedList",
    "CuratedSearch",
    "Gallery",
    "MusicGroup",
    "MusicReview",
    "Product",
    "ProductReview",
    "Recipe",
    "Venue",
  ],
  Review: ["MusicReview", "ProductReview"],
  Sponsorable: ["Article", "Bundle", "Gallery", "LiveStory"],
};

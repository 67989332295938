import {
  SearchMultiselect as MultiselectImplementation,
  Field,
} from "@components";
import {
  ControlProps,
  FormFor_form_controls_MultiSelectFormControl,
  Search,
  SearchFilters,
  SearchVariables,
  Option,
  ContentConnectionFields,
  ContentSummaryFields,
  ContentContributorFields_edges,
  Search_search_results,
  CONTRIBUTOR_TYPE,
  ContentContributorFields,
} from "@types";
import { useCallback, useMemo, useRef, Key } from "react";
import { useDebouncedCallback, useDefinedMessages, useUniqueId } from "@hooks";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "@gql";

import styled from "styled-components";
import { get, isDefined } from "@lib";

const SEARCH_DEBOUNCE_INTERVAL = 250; // milliseconds

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-xxs) 0;
  grid-template-rows: auto auto;
  grid-template-areas:
    "label"
    "control";
`;

type SearchMultiSelectAdapterDefinition<T> = {
  getSelections: (modelValue: unknown, createKey: KeyGenerator) => Option<T>[];
  getSearch: (
    searchImplementation: (filters: SearchFilters) => void
  ) => (query: string, filters?: Partial<SearchFilters>) => void;
  getSearchResults: (searchData: Search | undefined) => Option<T>[];
  setChange: (
    selections: T[],
    name: string,
    setValue: (key: string, value: unknown) => void
  ) => void;
};

function searchResultToContributorEdge(
  result: Search_search_results
): ContentContributorFields_edges {
  return {
    __typename: "ContentToContributorEdge",
    node: {
      __typename: "Contributor",
      id: result.id,
      name: result.title?.content || "",
    },
    type: CONTRIBUTOR_TYPE.DEFAULT,
  };
}

const Adapters = {
  brand: {
    getSelections: (modelValue) => {
      const brands = modelValue as ContentConnectionFields;
      return brands?.edges.map((edge) => {
        return edge?.node
          ? {
              key: edge.node.id,
              value: edge.node,
              label: edge.node.title?.content,
            }
          : null;
      });
    },
    getSearch: (search) => {
      return (query, filters = {}) =>
        search({
          q: query ?? "",
          types: "brand",
          ...filters,
        });
    },
    getSearchResults: (searchData) =>
      searchData?.search.results.map((item) => {
        return {
          key: item.id,
          value: item,
          label: item?.title?.content || "",
        };
      }) ?? [],
    setChange: (selections, name, setValue) => {
      setValue(name, {
        __typename: "ContentConnection",
        edges: selections.map((selection) => ({
          __typename: "ContentSummary",
          node: { ...selection },
        })),
      });
    },
  } as SearchMultiSelectAdapterDefinition<ContentSummaryFields>,
  contributor: {
    getSelections(modelValue) {
      const queryContributors = modelValue as ContentContributorFields | null;
      return queryContributors?.edges?.filter(isDefined).map((contributor) => ({
        key: contributor.node?.id,
        value: contributor,
        label: contributor.node?.name,
      }));
    },
    getSearch: (search) => {
      return (query, filters = {}) => {
        search({
          q: query ?? "",
          types: "contributor",
          status: "published",
          display: "all",
          qt: "contributorLookup",
          ...filters,
        });
      };
    },
    getSearchResults(searchData) {
      return searchData?.search.results
        .map(searchResultToContributorEdge)
        .map((edge) => ({
          key: edge.node?.id,
          label: edge.node?.name,
          value: edge,
        }));
    },
    setChange(selections, name, setValue) {
      setValue(name, { edges: selections } as ContentContributorFields);
    },
  } as SearchMultiSelectAdapterDefinition<ContentContributorFields_edges>,
  curatedSearchContributor: {
    getSelections: (modelValue) => {
      const queryContributors = modelValue as string[] | null;
      return queryContributors?.map((contributorName, index) => {
        return {
          key: index, // ok since these are not re-orderable
          value: contributorName,
          label: contributorName,
        };
      });
    },
    getSearch: (search) => {
      return (query, filters = {}) => {
        search({
          q: query ?? "",
          types: "contributor",
          status: "published",
          display: "all",
          qt: "contributorLookup",
          ...filters,
        });
      };
    },
    getSearchResults: (searchData) =>
      searchData?.search.results.map((item) => {
        return {
          key: item.id,
          value: item?.title?.content,
          label: item?.title?.content || "",
        };
      }) ?? [],
    setChange: (selections, name, setValue) => {
      setValue(name, selections);
    },
  } as SearchMultiSelectAdapterDefinition<string>,
  person: {
    getSelections: (modelValue) => {
      const people = modelValue as ContentConnectionFields;
      return people?.edges.map((edge) => {
        return edge?.node
          ? {
              key: edge.node.id,
              value: edge.node,
              label: edge.node.title?.content,
            }
          : null;
      });
    },
    getSearch: (search) => {
      return (query, filters = {}) =>
        search({
          q: query ?? "",
          types: "person",
          ...filters,
        });
    },
    getSearchResults: (searchData) =>
      searchData?.search.results.map((item) => {
        return {
          key: item.id,
          value: item,
          label: item?.title?.content || "",
        };
      }) ?? [],
    setChange: (selections, name, setValue) => {
      setValue(name, {
        __typename: "ContentConnection",
        edges: selections.map((selection) => ({
          __typename: "ContentSummary",
          node: { ...selection },
        })),
      });
    },
  } as SearchMultiSelectAdapterDefinition<ContentSummaryFields>,
  default: {
    getSelections: () => [],
    getSearch: () => () => {},
    getSearchResults: () => [],
    setChange: () => {},
  } as SearchMultiSelectAdapterDefinition<unknown>,
};

function hasMultiSelectAdapter(
  adapterType: string | null
): adapterType is keyof typeof Adapters {
  return (adapterType as string) in Adapters;
}

type KeyGenerator = () => Key;

export function MultiSelect<T>(
  props: ControlProps<FormFor_form_controls_MultiSelectFormControl>
) {
  const { name, labelKey, model, currentOrganization, adapterType, setValue } =
    props;
  const { translateFieldName } = useDefinedMessages();
  const selectLabelName = translateFieldName(labelKey ?? name);
  const uniqueId = useUniqueId();
  const itemIdRef = useRef<number>(0);
  const createKey = useCallback(() => itemIdRef.current++, []);
  const [_lazySearch, { data: searchData, loading, error }] = useLazyQuery<
    Search,
    SearchVariables
  >(Queries.SEARCH, {
    fetchPolicy: "network-only",
  });
  const errors = useMemo(() => (error ? [error] : undefined), [error]);

  const performSearch = useDebouncedCallback((filters: SearchFilters) => {
    if (!filters.q) return;
    _lazySearch({
      variables: {
        organizationId: currentOrganization.organizationId,
        filters,
      },
    });
  }, SEARCH_DEBOUNCE_INTERVAL);

  const adapter = (
    hasMultiSelectAdapter(adapterType)
      ? Adapters[adapterType]
      : Adapters.default
  ) as SearchMultiSelectAdapterDefinition<T>;

  const search = useMemo(
    () => adapter.getSearch(performSearch),
    [adapter, performSearch]
  );
  const modelValue = get(model, name);

  const selections = useMemo(
    () => adapter.getSelections(modelValue, createKey),
    [adapter, modelValue, createKey]
  );

  const searchResults = useMemo(
    () => adapter.getSearchResults(searchData),
    [adapter, searchData]
  );

  const onChange = useCallback(
    (selections: T[]) => {
      adapter.setChange(selections, name, setValue);
    },
    [adapter, name, setValue]
  );
  return (
    <Wrapper id={`MultiSelect-${uniqueId}`} label={selectLabelName}>
      <MultiselectImplementation
        label={selectLabelName}
        aria-label={selectLabelName}
        selections={selections}
        loading={loading}
        search={search}
        searchErrors={errors}
        searchResults={searchResults}
        onChange={onChange}
        multiple={props.selectionLimit ? props.selectionLimit > 1 : true}
        sortable={true}
      />
    </Wrapper>
  );
}
MultiSelect.displayName = "Control(MultiSelect)";

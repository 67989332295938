import { RecipeTimesInput, RecipeFields_times } from "@types";

export function times(times: RecipeFields_times): RecipeTimesInput {
  const { displayTime, prepTime, activeTime, totalTime } = times;

  return {
    displayTime: displayTime ?? "",
    prepTime: prepTime ?? "",
    activeTime: activeTime ?? "",
    totalTime: totalTime ?? "",
  };
}

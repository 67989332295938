import { useQuery } from "@apollo/client";
import type {
  GetBrand,
  GetBrandVariables,
  GetCurrentUser_currentUser_organizations,
  GetBrand_brandConfiguration_contentTypes,
} from "@types";
import { ApolloError } from "@apollo/client";

import { Queries } from "@gql";
import { NoArticleConfigError } from "./PageLayout.errors";

export const useArticleConfig = (
  currentOrganization: GetCurrentUser_currentUser_organizations
): {
  articleConfig: GetBrand_brandConfiguration_contentTypes | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  let {
    data: brand,
    loading,
    error,
  } = useQuery<GetBrand, GetBrandVariables>(Queries.GET_BRAND, {
    variables: {
      organizationId: currentOrganization.organizationId,
    },
  });

  let articleConfig;
  if (brand) {
    const contentTypes = brand.brandConfiguration?.contentTypes;
    articleConfig = contentTypes?.find((type) => type.value === "article");
    if (!articleConfig) {
      throw new NoArticleConfigError();
    }
  }

  return {
    articleConfig,
    loading,
    error,
  };
};

import { Field, PosterImageDialog, Button } from "@components";
import { Image, Label, Link } from "@condenast/gemini";
import styled from "styled-components";
import { ComponentProps, FC, useMemo, useState, useContext } from "react";
import { useDefinedMessages } from "@hooks";
import { ThemeProvider } from "@contexts";
import { AssetData, ContentSummary, NormalizedMetadata } from "@types";
import { FormattedMessage } from "react-intl";
import { SnowplowContext } from "@contexts";

const MIN_IMAGE_SIZE = 350;

const ImageWithMetadataWrapper = styled.div`
  flex-direction: column;
  width: calc(var(--spacing-xs) * 43.75);
  outline: 1px solid ${({ theme }) => theme.DividerColor};
  border-radius: ${({ theme }) => theme.CornerRadius};
  overflow: hidden;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${MIN_IMAGE_SIZE}px;
  min-height: ${MIN_IMAGE_SIZE}px;
  background-color: ${(props) => props.theme.Background};
`;

const SyledImage = styled(Image)`
  display: flex;
  height: unset;
`;

const MetadataContainer = styled.div`
  display: grid;
  flex: 1;
  line-height: 1.5;
  grid-template-columns: auto auto;
  grid-gap: 1em;
  padding: var(--spacing-sm);
`;

const StyledButton = styled(Button)`
  width: 100%;
  &:not(:disabled):hover,
  &:not(:disabled)[aria-pressed="true"],
  &:not(:disabled)[aria-expanded="true"],
  &:not(:disabled):active {
    background: transparent;
    text-decoration: underline;
  }
`;

const LabelledLink = styled(Link)`
  line-height: 1.5;
  margin: var(--spacing-xs) 0 0;
  display: block;
  :focus {
    box-shadow: none;
  }
`;

const SpanLabel = styled.label`
  overflow-wrap: anywhere;
`;

const MetadataLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

const SVGClip = styled.svg`
  display: inline-block;
  opacity: 0.2;
  width: 100%;
  height: 100px;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  margin-left: 10px;
`;

//on failure of loading poster image of a clip
function VideoIcon() {
  return (
    <SVGClip
      xmlns="http://www.w3.org/2000/svg"
      fill="#565656"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className="processing-media"
    >
      <path d="M7 11V6H3v5h4zm0 1.976V12H3v1h4v-.024zM7 5V4H3v1h4zm2-2h6v5.998c0 1.77-4.12-.401-4.12 2.231 0 1.125-.626 1.749-1.88 1.872V14a1 1 0 01-1 1H2a1 1 0 01-1-1V3a1 1 0 011-1h1a1 1 0 011-1h2a1 1 0 011 1h1a1 1 0 011 1zm0 1v1h1V4H9zm2 0v1h1V4h-1zm2 0v1h1V4h-1z" />
    </SVGClip>
  );
}

export const ImageMetaData: FC<
  Omit<ComponentProps<typeof Field>, "children"> & {
    photosTout: AssetData;
    exifMetaData: NormalizedMetadata[];
    cdnHost: string;
    id: string;
    assetUrl: string;
    thumbnails: ContentSummary[];
    onChangePosterImage: (poster: ContentSummary) => void;
    backReferences: ContentSummary[];
    contentType: string;
    onImageLoad?: (img: HTMLImageElement) => void;
  }
> = (props) => {
  const { translateFieldName } = useDefinedMessages();
  const {
    id,
    photosTout,
    cdnHost = "",
    exifMetaData,
    assetUrl,
    thumbnails,
    onChangePosterImage,
    onImageLoad,
  } = props;

  let [showAllMetadata, setShowAllMetadata] = useState(false);

  const { trackContentEvent } = useContext(SnowplowContext);

  let aboveFoldExifData = useMemo(() => {
    return exifMetaData.filter((d) =>
      ["File Name", "Dimensions", "Date Created"].includes(d.key)
    );
  }, [exifMetaData]);

  let belowFoldExifData = useMemo(() => {
    return exifMetaData.filter((d) => !aboveFoldExifData.includes(d));
  }, [exifMetaData, aboveFoldExifData]);

  let imageDimensions = useMemo(() => {
    let height = photosTout.height;
    let width = photosTout.width;

    if (
      width === null ||
      height === null ||
      width < MIN_IMAGE_SIZE ||
      height < MIN_IMAGE_SIZE
    ) {
      return {
        width,
        height,
      };
    }

    let aspectRatio = width / height;
    let newWidth = MIN_IMAGE_SIZE;
    let newHeight = newWidth / aspectRatio;

    return {
      width: newWidth,
      height: Math.round(newHeight),
    };
  }, [photosTout.width, photosTout.height]);

  return (
    <ThemeProvider theme="light">
      <div>
        <ImageWithMetadataWrapper>
          <ImageContainer>
            {!photosTout.id ? (
              <VideoIcon />
            ) : (
              <SyledImage
                asset={{
                  id: photosTout.id || "",
                  type: photosTout.type || "photos",
                  filename: photosTout.filename || "",
                  altText: photosTout.altText || "",
                  width: photosTout.width || null,
                  height: photosTout.height || null,
                  publicURL: null,
                }}
                overflowStyle="fit"
                modifications={{
                  height: imageDimensions.height,
                  width: imageDimensions.width,
                }}
                cdnHost={cdnHost}
                onImageLoad={onImageLoad}
              />
            )}
          </ImageContainer>
          <MetadataContainer>
            {aboveFoldExifData.map((d) => [
              <MetadataLabel key={"metadata_key_label_" + d.key}>
                <Label id={"metadata_key_" + d.key}>
                  {translateFieldName(d.key)}
                </Label>
              </MetadataLabel>,
              <MetadataLabel key={"metadata_val_label_" + d.key}>
                <SpanLabel id={"metadata_val_" + d.key}>{d.value}</SpanLabel>
              </MetadataLabel>,
            ])}
            {showAllMetadata && (
              <>
                {belowFoldExifData.map((d) => [
                  <MetadataLabel key={"metadata_key_label_" + d.key}>
                    <Label id={"metadata_key_" + d.key}>
                      {translateFieldName(d.key)}
                    </Label>
                  </MetadataLabel>,
                  <MetadataLabel key={"metadata_val_label_" + d.key}>
                    <SpanLabel id={"metadata_val_" + d.key}>
                      {d.value}
                    </SpanLabel>
                  </MetadataLabel>,
                ])}
              </>
            )}
          </MetadataContainer>

          <StyledButton
            treatment="borderless"
            onClick={() => setShowAllMetadata(!showAllMetadata)}
            aria-expanded={showAllMetadata}
          >
            {!showAllMetadata ? (
              <FormattedMessage defaultMessage="Show more" />
            ) : (
              <FormattedMessage defaultMessage="Show less" />
            )}
          </StyledButton>
        </ImageWithMetadataWrapper>

        <LabelledLink
          to={assetUrl}
          onClick={() => {
            trackContentEvent("original_image_viewed");
          }}
        >
          {translateFieldName("View Original")}
        </LabelledLink>
        {!!thumbnails?.length && (
          <PosterImageDialog
            id={id}
            photosTout={photosTout}
            cdnHost={cdnHost}
            thumbnails={thumbnails}
            onChangePosterImage={onChangePosterImage}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

ImageMetaData.displayName = "Control(ImageMetaData)";

import {
  ControlProps,
  FormFor_form_controls_ReadOnlyFormControl,
} from "@types";
import { Field } from "@components";
import { get } from "@lib";
import { useDefinedMessages } from "@hooks";

import styled from "styled-components";

const Wrapper = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

const DisplayText = styled.span`
  grid-area: control;
  overflow: scroll;
  font: ${(props) => props.theme.FontStatement};
`;

export function ReadOnly(
  props: ControlProps<FormFor_form_controls_ReadOnlyFormControl>
) {
  let { model, name, noLabel, errors } = props;
  const { translateFieldName } = useDefinedMessages();
  const value = (get(model, name) as string) ?? "";

  const translatedLabel = translateFieldName(name);

  return (
    <Wrapper
      id={`ReadOnly_${name}`}
      label={!noLabel ? translatedLabel : ""}
      aria-label={translatedLabel}
      errors={errors}
    >
      <DisplayText>{value}</DisplayText>
    </Wrapper>
  );
}
ReadOnly.displayName = "Control(ReadOnly)";

import type { ContentSerializers } from "../types";
import type { VenueInput, FormFor_content_Venue } from "@types";
import {
  allContributors,
  categoryTaxonomies,
  contentConnection,
  geo,
  lodgingOffers,
  seo,
  transformKeyValues,
  ratingBlock,
  socialMediaItems,
  venueAddress,
} from "../fields";

import { serializerFor, entityMetadata } from "../utils";

const VenueFields = {
  categoryTaxonomies,
  allContributors,
  seo,
  geo,
  lodgingOffers,
  address: venueAddress,
  phoneNumbers: transformKeyValues,
  urls: transformKeyValues,
  emails: transformKeyValues,
  photos: contentConnection,
  photosBadge: contentConnection,
  photosLede: contentConnection,
  photosTout: contentConnection,
  photosSocial: contentConnection,
  price: (value: string) => {
    const integerValue = parseInt(value);
    return isNaN(integerValue) ? null : integerValue;
  },
  ratingBlock,
  related: contentConnection,
  socialMedia: socialMediaItems,
  videosTout: contentConnection,
} as const;

function getVenueSerializer(venueType: string): ContentSerializers<VenueInput> {
  return {
    save: (changes, model, { currentUser, brandCode }) => {
      const nameOrHedFields =
        brandCode === "bon" && "hed" in model
          ? { hed: model.hed }
          : "name" in model
          ? { name: model.name }
          : {};

      const { phoneNumbers, urls, emails, lodgingOffers, ...serialized } =
        serializerFor(VenueFields)(changes) as Partial<FormFor_content_Venue>;

      return {
        ...nameOrHedFields,
        venueType,
        ...(serialized as Partial<VenueInput>),
        email: emails,
        phone: phoneNumbers,
        url: urls,
        offers: lodgingOffers,
        authorName: `${currentUser.firstName} ${currentUser.lastName}`,
        entityMetadata: entityMetadata(model, currentUser.id),
      };
    },
    create: ({ intl, currentUser, brandCode }) => {
      const currentDateTime = intl.formatDate(Date.now(), {
        year: "numeric",
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
      });
      const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
      return {
        // the Bon tsugu API requires a hed whereas others
        // require a name
        ...(brandCode === "bon" ? { hed: createTitle } : { name: createTitle }),
        venueType,
        address: {},
        authorName: `${currentUser.firstName} ${currentUser.lastName}`,
        entityMetadata: { user: [`${currentUser.id}`] },
      };
    },
  };
}

export const VenueSerializers = getVenueSerializer("venue");
export const BarSerializers = getVenueSerializer("bar");
export const HotelSerializers = getVenueSerializer("hotel");
export const RestaurantSerializers = getVenueSerializer("restaurant");
export const ShipSerializers = getVenueSerializer("ship");
export const ShopSerializers = getVenueSerializer("shop");
export const SkiResortSerializers = getVenueSerializer("skiresort");
export const SpaSerializers = getVenueSerializer("spa");

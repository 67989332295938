import {
  ControlProps,
  FormFor_form_controls_DatePickerFormControl,
} from "@types";
import { useCallback, useMemo } from "react";
import { DatePicker as DatePickerImplementation } from "@components";
import { useIntl } from "react-intl";
import { get } from "@lib";
import { useDefinedMessages } from "@hooks";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

export function DatePicker(
  props: ControlProps<FormFor_form_controls_DatePickerFormControl>
) {
  const { translateFieldName } = useDefinedMessages();
  const { name, model, setValue, timeZonePath } = props;
  const intl = useIntl();
  const datePickerId = `date-picker-${name}`;

  const dateString = get(model, name) as string | null;
  const dateValue = useMemo(
    () => (dateString ? new Date(dateString) : null),
    [dateString]
  );

  const timeZone = timeZonePath && (get(model, timeZonePath) as string | null);

  const onDateSelect = useCallback(
    (value: Date) => {
      const newDateTime = timeZone ? zonedTimeToUtc(value, timeZone) : value;
      setValue(name, newDateTime);
    },
    [name, setValue, timeZone]
  );

  const localDateTime =
    (dateValue && timeZone && utcToZonedTime(dateValue, timeZone)) || dateValue;

  return (
    <DatePickerImplementation
      id={datePickerId}
      label={translateFieldName(name)}
      onChange={onDateSelect}
      date={localDateTime}
      locale={intl.locale}
    />
  );
}
DatePicker.displayName = "Control(DatePicker)";

import { useMemo, useCallback, useEffect } from "react";
import uuid from "uuid-random";
import { FormattedMessage } from "react-intl";
import { BundleFields_containers_searches, FILTER_OPERATOR } from "@types";
import { Takeover, Button } from "@components";
import { useChangeset } from "@hooks";
import { ReloadIcon } from "@condenast/gemini/icons";
import {
  CategorySelector,
  TagSelector,
  TextField,
  MultiSelect,
  NumberField,
  Select,
  Fieldsection,
} from "../../../../../index";
import { SearchControlsTakeoverProps } from "../../../../types";
import {
  isLegacyBundleContainerConfig,
  FieldsectionProps,
  NumberFieldProps,
  TextFieldProps,
  SelectProps,
  MultiSelectProps,
  CategorySelectorProps,
  TagSelectorProps,
} from "../../../../utils";
import { TakeoverHeaderActions, ButtonGroup } from "../../../";

const SearchControlsTakeover: React.FC<SearchControlsTakeoverProps> = ({
  configs,
  currentOrganization,
  currentUser,
  permissions,
  errors,
  containerData,
  containerIndex,
  updateContainerByIndex,
  onClose,
}) => {
  let configuredDefaultSize = useMemo(() => {
    const containerConfig = configs?.containers?.find(
      (config) => config.value === containerData.curationContainerType
    );
    if (isLegacyBundleContainerConfig(containerConfig)) {
      return containerConfig.itemLimit;
    }
    return 10;
  }, [containerData, configs?.containers]);

  let searchData = useMemo(() => {
    return (containerData?.searches?.[0] || {
      __typename: "BundleSearch",
      sort: "publishDate desc",
      categoriesFilterOperator: FILTER_OPERATOR.or,
      notCategoriesFilterOperator: FILTER_OPERATOR.or,
    }) as unknown as Record<string, unknown>;
  }, [containerData]);

  let [changeset, setValue, { hasChanges, changes, resetChanges }] =
    useChangeset(searchData);
  const applySearchDataChanges = useCallback(() => {
    const updatedContainerData = {
      ...containerData,
    };
    updatedContainerData.searches = [
      { ...searchData, ...changes },
    ] as unknown as BundleFields_containers_searches[];
    updateContainerByIndex(updatedContainerData, containerIndex);
    onClose();
  }, [
    containerData,
    searchData,
    changes,
    updateContainerByIndex,
    containerIndex,
    onClose,
  ]);

  const SharedControlProps = {
    model: changeset,
    setValue,
    currentOrganization,
    currentUser,
    permissions,
    errors,
    id: uuid(),
    children: null,
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  return (
    <Takeover.TakeoverWrapper
      header={
        <TakeoverHeaderActions>
          <FormattedMessage defaultMessage="Add Search Query" />
          <ButtonGroup>
            <Button disabled={!hasChanges} onClick={resetChanges}>
              <ReloadIcon size="regular" />
              <FormattedMessage defaultMessage="Reset" />
            </Button>
            <Button
              treatment="primary"
              disabled={!hasChanges}
              onClick={applySearchDataChanges}
            >
              <FormattedMessage defaultMessage="Apply" />
            </Button>
          </ButtonGroup>
        </TakeoverHeaderActions>
      }
      body={
        <>
          <Fieldsection
            {...FieldsectionProps}
            {...SharedControlProps}
            name="searchCriteria"
            canCollapse={true}
          >
            <NumberField
              {...NumberFieldProps}
              {...SharedControlProps}
              name={"size"}
              placeholder={`${configuredDefaultSize}`}
            />
            <TextField
              {...TextFieldProps}
              {...SharedControlProps}
              name={"query"}
            />
            <Select
              {...SelectProps}
              {...SharedControlProps}
              options={configs?.contentTypeOptions || null}
              optionsPath={"contentTypeFilterOptions"}
              name={"types"}
              multiple={true}
            />

            <Select
              {...SelectProps}
              {...SharedControlProps}
              options={configs?.contentSourceOptions || null}
              optionsPath={"contentSourceOptions"}
              name={"contentSource"}
            />
            <TextField
              {...TextFieldProps}
              {...SharedControlProps}
              name={"issueDate"}
            />

            <MultiSelect
              {...MultiSelectProps}
              {...SharedControlProps}
              adapterType={"curatedSearchContributor"}
              name={"contributor"}
              strategy={"name"}
            />
            <Select
              {...SelectProps}
              {...SharedControlProps}
              name={"language"}
              options={configs?.languageOptions || null}
              optionsPath={"language"}
              multiple={true}
            />
          </Fieldsection>
          <Fieldsection
            {...FieldsectionProps}
            {...SharedControlProps}
            name="inclusionCriteria"
            canCollapse={true}
          >
            <Select
              {...SelectProps}
              {...SharedControlProps}
              options={configs?.logicalOperatorOptions || null}
              optionsPath={"operatorLogicOptions"}
              name={"categoriesFilterOperator"}
            />
            <CategorySelector
              {...CategorySelectorProps}
              {...SharedControlProps}
              name="includedCategoryTaxonomies"
              notHierarchies={["tags", "channels"]}
              rootSlug={null}
              labelKey="filterCategories"
            />
            <CategorySelector
              {...CategorySelectorProps}
              {...SharedControlProps}
              labelKey={"filterCategoriesChannels"}
              name={"includedCategoryTaxonomies"}
              notHierarchies={["copilot", "tags"]}
              rootSlug={"channels"}
            />

            <TagSelector
              {...TagSelectorProps}
              {...SharedControlProps}
              labelKey="filterCategoriesTags"
              name="includedCategoryTaxonomies"
            />
          </Fieldsection>
          <Fieldsection
            {...FieldsectionProps}
            {...SharedControlProps}
            name="exclusionCriteria"
            canCollapse={true}
          >
            <Select
              {...SelectProps}
              {...SharedControlProps}
              options={configs?.logicalOperatorOptions || null}
              optionsPath={"operatorLogicOptions"}
              name={"notCategoriesFilterOperator"}
            />
            <CategorySelector
              {...CategorySelectorProps}
              {...SharedControlProps}
              labelKey="excludeCategories"
              name="excludedCategoryTaxonomies"
              notHierarchies={["tags", "channels"]}
              rootSlug={null}
            />
            <CategorySelector
              {...CategorySelectorProps}
              {...SharedControlProps}
              labelKey={"excludeCategoriesChannels"}
              name={"excludedCategoryTaxonomies"}
              notHierarchies={["copilot", "tags"]}
              rootSlug={"channels"}
            />

            <TagSelector
              {...TagSelectorProps}
              {...SharedControlProps}
              labelKey="excludeCategoriesTags"
              name="excludedCategoryTaxonomies"
            />
          </Fieldsection>
        </>
      }
      onClose={onClose}
    />
  );
};

export default SearchControlsTakeover;

import { AspectRatioInput, AspectRatio } from "@types";

export function aspectRatios(input: AspectRatio[]): AspectRatioInput[] {
  return input.map((aspectRatio) => {
    const { name, width, height, url, format, override, modifications } =
      aspectRatio;
    const isMasterAspectRatio = name === "master";
    const coreAspectRatioProperties = {
      name: name,
      width: width,
      height: height,
      override: override || false,
    };
    return isMasterAspectRatio
      ? { ...coreAspectRatioProperties, url, format }
      : ({
          ...coreAspectRatioProperties,
          modifications: {
            crop: {
              height: modifications?.crop.height,
              width: modifications?.crop.width,
              x: modifications?.crop.x,
              y: modifications?.crop.y,
            },
          },
        } as AspectRatioInput);
  });
}

import { AwardInput } from "@types";

export function awards(input?: { name: string; date: string }[]): AwardInput[] {
  return (
    input?.map(({ name, date }) => ({
      name,
      date,
    })) ?? []
  );
}

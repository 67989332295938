import { Card, Button, Title, Spinner, ValidationSummary } from "@components";
import {
  AddIcon,
  AnimatedEllipsisIcon,
  BackIcon,
  CloseIcon,
  LinkIcon,
  NextIcon,
} from "@condenast/gemini/icons";
import {
  Changeset,
  FormFor_form,
  GetCurrentUser_currentUser,
  GetLiveStoryUpdates_getLiveStory_updates_edges,
  LivestoryupdateFields,
  Organization,
} from "@types";
import { ActionAccess, useMediaQuery } from "@hooks";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "@contexts";
import { useCallback, useLayoutEffect, useState } from "react";
import { FormControl } from "../../FormControl";
import { ValidationError } from "@condenast/cross-check";
import { LiveStoryFeed } from "../LiveStoryFeed";
import { Link } from "react-router-dom";
import type { EditorInstance } from "@condenast/ckeditor5-build-condenast";
import { ErrorPage } from "../../ErrorPage";
import { useDefinedMessages } from "@hooks";

const SpinnerWrapper = styled.div`
  height: var(--spacing-xxl);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: inherit;
  max-width: 100vw;
  width: 100%;
  z-index: ${(props) => props.theme.ElevationModal};
`;

const PageContainer = styled.div`
  position: relative;
  min-height: calc(100vh - var(--spacing-xxl));
  padding: var(--spacing-xl) var(--spacing-sm);
  margin: 0 auto;
  max-width: min(calc(var(--spacing-xxl) * 25), 100vw);
  width: 100%;
`;

const LiveStoryWrapper = styled.div<{
  $isMobile: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;

  ${(props) =>
    props.$isMobile &&
    `
    grid-template-columns: 1fr;
    grid-gap: 0;
  `}
`;

const CreateWrapper = styled(Card)<{
  $isMobile: boolean;
  $isCreateView: boolean;
}>`
  position: relative;

  ${(props) =>
    props.$isMobile &&
    `
    display: none;
  `}

  ${(props) =>
    props.$isCreateView &&
    props.$isMobile &&
    `
    display: flex;
    flex-direction: column;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    min-height: 100vh;
    z-index: ${props.theme.ElevationModal};
    overflow: hidden;
  `}
`;

const LiveStoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPlusButton = styled(Button)``;

const PageTitle = styled(Title)`
  padding: var(--spacing-sm) 0;
`;

const FeedWrapper = styled.div`
  display: block;
`;

const CreateHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.Color};
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};

  & button {
    color: inherit;
    margin-right: var(--spacing-sm);
  }
`;

const BackButton = styled(Button)`
  display: flex;
  align-items: center;
  outline: none;
  text-decoration: none;
  font: ${(props) => props.theme.FontSmallerHeading};
  color: ${(props) => props.theme.Color};

  & svg {
    margin-right: var(--spacing-sm);
  }

  &:hover {
    color: ${(props) => props.theme.SecondaryActiveColor};
  }
`;

const CreateFooter = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  justify-content: flex-end;
  align-items: center;
  padding: var(--spacing-sm);
  border-top: 1px solid ${(props) => props.theme.DividerColor};
  font: ${(props) => props.theme.FontSubSectionHeading};
`;

const LiveStoryForm = styled.form<{
  $isCreateView: boolean;
  $isMobile: boolean;
}>`
  display: flex;
  flex: 1;
  min-height: 0;

  & section {
    border-radius: 0;
    box-shadow: none;
    background: var(--color-white);
    margin-top: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
  }

  ${(props) =>
    props.$isCreateView &&
    props.$isMobile &&
    `
    & section {
      background: var(--color-gray-1);

      h2 {
        display: none;
      }

      label {
        color: var(--color-white);
      }
    }
  `}
`;

type FormPermissions = ActionAccess<"publish" | "update">;

export const LiveStoryUpdate = <Model extends LivestoryupdateFields>(props: {
  currentUser: GetCurrentUser_currentUser;
  currentOrganization: Organization;
  changeset: Changeset<Model>;
  validationErrors: ValidationError[];
  onCreate?: () => void;
  onRemove?: (
    update: Partial<LivestoryupdateFields>,
    model: LivestoryupdateFields
  ) => void;
  onCopyURI?: () => void;
  previousURL: string;
  formLoading: boolean;
  updatesLoading: boolean;
  error?: Error | undefined;
  form?: FormFor_form;
  permissions: FormPermissions;
  saveInProgress: boolean;
  liveUpdates: (GetLiveStoryUpdates_getLiveStory_updates_edges | null)[];
  livestoryHed: string;
  setBodyRef?: (bodyRef: React.MutableRefObject<EditorInstance | null>) => void;
}) => {
  const {
    currentOrganization,
    currentUser,
    validationErrors,
    changeset,
    onCreate,
    onRemove,
    onCopyURI,
    previousURL,
    formLoading,
    updatesLoading,
    error,
    form,
    permissions,
    saveInProgress,
    liveUpdates,
    livestoryHed,
    setBodyRef,
  } = props;

  const intl = useIntl();
  const [model, setValue] = changeset;
  const isMobile = useMediaQuery("(max-width: 900px)");
  const hasErrors = validationErrors.length;
  const { translateSummaryErrors } = useDefinedMessages();

  const errors = translateSummaryErrors(validationErrors);

  const [isCreateView, setCreateView] = useState(false);

  const toggleCreateView = () => {
    setCreateView(!isCreateView);
  };

  const [validationSummaryElement, setValidationSummaryElement] =
    useState<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (hasErrors && validationSummaryElement) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [hasErrors, validationSummaryElement]);

  const [activeErrorPath, setActiveErrorPath] = useState<
    readonly string[] | undefined
  >(undefined);

  // after re-rendering clear out the activeErrorPath
  useLayoutEffect(() => {
    if (activeErrorPath) {
      setActiveErrorPath(undefined);
    }
  }, [activeErrorPath, setActiveErrorPath]);

  const onHide = useCallback(
    (update) => onRemove?.({ showInFeed: false }, update),
    [onRemove]
  );
  return (
    <Wrapper>
      {error ? (
        <ErrorPage />
      ) : formLoading ? (
        <SpinnerWrapper>
          <Spinner size="small" />
        </SpinnerWrapper>
      ) : (
        <PageContainer>
          <BackButton
            treatment="borderless"
            as={Link}
            to={previousURL}
            size="small"
          >
            <BackIcon size="small" />
            <span>{livestoryHed}</span>
          </BackButton>
          <LiveStoryHeader>
            <PageTitle>
              Live Updates{" "}
              <Button aria-label="Copy link" onClick={onCopyURI}>
                <LinkIcon size="regular" />
              </Button>
            </PageTitle>
            {isMobile && (
              <StyledPlusButton
                treatment="primary"
                aria-label={intl.formatMessage({
                  defaultMessage: "Open Create",
                })}
                onClick={toggleCreateView}
              >
                <AddIcon size="regular" />
              </StyledPlusButton>
            )}
          </LiveStoryHeader>
          <LiveStoryWrapper $isMobile={isMobile}>
            <ThemeProvider theme={isMobile ? "dark" : "light"}>
              <CreateWrapper $isMobile={isMobile} $isCreateView={isCreateView}>
                {isCreateView && isMobile && (
                  <CreateHeader>
                    <Button
                      treatment="borderless"
                      onClick={() => {
                        setCreateView(false);
                      }}
                      size="small"
                    >
                      <BackIcon size="regular" />
                    </Button>
                    <PageTitle>
                      {intl.formatMessage({
                        defaultMessage: "Create Live Update",
                      })}
                    </PageTitle>
                  </CreateHeader>
                )}
                <div ref={setValidationSummaryElement}>
                  {errors.length && (
                    <ValidationSummary
                      errors={errors}
                      onClick={(error) => setActiveErrorPath(error.path)}
                    />
                  )}
                </div>
                <LiveStoryForm
                  $isCreateView={isCreateView}
                  $isMobile={isMobile}
                >
                  <ThemeProvider theme="light">
                    {form?.children.map((id: string) => {
                      return (
                        <FormControl
                          key={id}
                          controlId={id}
                          controls={form.controls}
                          model={model}
                          errors={validationErrors}
                          setValue={setValue}
                          currentOrganization={currentOrganization}
                          currentUser={currentUser}
                          permissions={permissions}
                          activeErrorPath={activeErrorPath}
                          linkAutogen={false}
                          linkAutogenConfigFields={[]}
                          setBodyRef={setBodyRef}
                        />
                      );
                    })}
                  </ThemeProvider>
                </LiveStoryForm>
                <CreateFooter>
                  {isMobile && (
                    <>
                      <Button aria-label="Copy link" onClick={onCopyURI}>
                        <LinkIcon size="regular" />
                        <FormattedMessage defaultMessage="Copy link" />
                      </Button>
                      <Button
                        aria-label="Cancel"
                        onClick={() => {
                          setCreateView(false);
                        }}
                      >
                        <CloseIcon size="regular" />
                        <FormattedMessage defaultMessage="Cancel" />
                      </Button>
                    </>
                  )}
                  <Button
                    aria-label="Send"
                    treatment="primary"
                    onClick={onCreate}
                    disabled={saveInProgress}
                  >
                    {saveInProgress ? (
                      <AnimatedEllipsisIcon size="small" />
                    ) : (
                      <NextIcon size="regular" />
                    )}
                    <FormattedMessage defaultMessage="Send" />
                  </Button>
                </CreateFooter>
              </CreateWrapper>
            </ThemeProvider>
            <FeedWrapper>
              <LiveStoryFeed
                onHideLiveStoryUpdate={onHide}
                liveUpdates={liveUpdates}
                loading={updatesLoading}
                currentOrganization={currentOrganization}
              />
            </FeedWrapper>
          </LiveStoryWrapper>
        </PageContainer>
      )}
    </Wrapper>
  );
};
LiveStoryUpdate.displayName = "LiveStoryUpdate";

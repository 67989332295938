import type { ContentSerializers } from "../types";
import type { ExternalInput } from "@types";
import {
  categoryTaxonomies,
  contentConnection,
  allContributors,
} from "../fields";
import { serializerFor, entityMetadata } from "../utils";

const ExternalReferenceFields = {
  allContributors,
  categoryTaxonomies,
  photosTout: contentConnection,
} as const;

export const ExternalReferenceSerializers: ContentSerializers<ExternalInput> = {
  save: (changes, model, { currentUser }) => {
    if (!("hed" in model)) {
      return null;
    }

    return {
      ...serializerFor(ExternalReferenceFields)(changes),
      hed: model.hed,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
    return {
      hed: createTitle,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

import {
  RecipeIngredientGroupFields,
  RecipeIngredientGroupInput,
} from "@types";

export function ingredientGroups(
  data: RecipeIngredientGroupFields[]
): RecipeIngredientGroupInput[] {
  return data.map(({ hed, ingredients }) => ({
    hed: hed ?? undefined,
    ingredients:
      ingredients.map((ingredient) => {
        if (!ingredient) {
          return null;
        }
        const {
          name,
          unit,
          unitSize,
          unitQuantity,
          ingredientSize,
          treatment,
          description,
          note,
        } = ingredient;
        return {
          name,
          unit,
          unitSize,
          unitQuantity,
          ingredientSize,
          treatment,
          description,
          note,
        };
      }) ?? [],
  }));
}

import { useQuery } from "@apollo/client";
import { ARIA } from "@components";
import { SettingsIcon } from "@condenast/gemini/icons";
import { Queries } from "@gql";
import {
  useContentAccessControl,
  useUserAccessControlMatrix,
  useMediaQuery,
} from "@hooks";
import {
  Organization,
  UserRole,
  GetCurrentUser_currentUser,
  GetConfigs,
  GetConfigsVariables,
} from "@types";

import { useIntl } from "react-intl";

export function SettingsMenu(props: {
  hostname?: string;
  currentOrganization: Organization;
  className?: string;
  currentUser?: GetCurrentUser_currentUser;
}) {
  const { hostname, currentOrganization, currentUser } = props;
  const intl = useIntl();
  const size: "medium" | "regular" = useMediaQuery("(max-width: 500px)")
    ? "medium"
    : "regular";
  const copilotCode = currentOrganization.metadata.copilotCode;
  let items: Array<
    | { role: "link"; href: string; value: string }
    | { role: "link"; to: string; value: string }
  > = [];

  const { data } = useQuery<GetConfigs, GetConfigsVariables>(
    Queries.GET_CONFIGS,
    {
      variables: {
        organizationId: currentOrganization.organizationId,
        userId: currentUser?.id || "",
      },
    }
  );

  if (
    useContentAccessControl(
      props.currentOrganization,
      "read",
      "runwaymoments"
    ) &&
    copilotCode == "vogue"
  ) {
    items.push({
      role: "link",
      href: `${hostname}/vogue/seasons`,
      value: intl.formatMessage({
        defaultMessage: "Runway",
      }),
    });
  }
  if (
    useContentAccessControl(props.currentOrganization, "read", "tagmanager")
  ) {
    items.push({
      role: "link",
      href: `${hostname}/${copilotCode}/category-manager/roots`,
      value: intl.formatMessage({
        defaultMessage: "Tag Manager",
      }),
    });
  }
  if (useContentAccessControl(props.currentOrganization, "read", "presets")) {
    items.push({
      role: "link",
      href: `${hostname}/${copilotCode}/presets`,
      value: intl.formatMessage({
        defaultMessage: "Templates",
        description: "Content type templates feature",
      }),
    });
  }
  const searchableRoles = useUserAccessControlMatrix(
    props.currentOrganization,
    "search",
    Object.values(UserRole)
  );
  if (Object.values(searchableRoles).some((access) => access.search)) {
    items.push({
      role: "link",
      to: `/${copilotCode}/users`,
      value: intl.formatMessage({
        defaultMessage: "My Team",
      }),
    });
  }
  if (currentUser?.role === "superadmin") {
    items.push({
      role: "link",
      href: `${hostname}/${copilotCode}/sitesettings/content-types`,
      value: intl.formatMessage({
        defaultMessage: "Brand Settings",
      }),
    });
    items.push({
      role: "link",
      href: `${hostname}/${copilotCode}/users/permissions`,
      value: intl.formatMessage({
        defaultMessage: "User Permissions",
      }),
    });
    items.push({
      role: "link",
      href: `${hostname}/${copilotCode}/feature-flags`,
      value: intl.formatMessage({
        defaultMessage: "Feature Flags",
      }),
    });
  }
  if (data?.configs.bulkProductUpload) {
    items.push({
      role: "link",
      href: `${hostname}/${copilotCode}/products/upload`,
      value: intl.formatMessage({
        defaultMessage: "Product CSV Upload",
      }),
    });
  }

  return (
    <div className={props.className}>
      <ARIA.MenuButton
        id="settings-menu"
        aria-label={intl.formatMessage({
          defaultMessage: "Settings",
        })}
        size={size}
        popper={{
          placement: size === "medium" ? "bottom" : "bottom-end",
        }}
        menu={{
          items,
          children: (item: string) => item,
        }}
      >
        <SettingsIcon size="regular" />
      </ARIA.MenuButton>
    </div>
  );
}

import { useDefinedMessages } from "@hooks";
import { ContentSummary } from "@types";
import { RoutableLink, Spinner, UploadButton } from "@condenast/gemini";
import { ContentTypeIcon } from "@condenast/gemini/icons";
import { FC } from "react";
import styled from "styled-components";
import { SearchResult } from "@components";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { FormattedMessage, useIntl } from "react-intl";

const EditAssetOverlay = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  color: var(--color-gray-6);

  &:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    height: 100%;
    background: var(--color-gray-1);
    opacity: 50%;
    z-index: -1;
  }

  & svg {
    margin-right: var(--spacing-xxs);
  }
`;

const LinkWithOverlay: FC<{
  linkTo: string;
  contentType: string;
  className?: string;
}> = (props) => {
  const { translateContentType } = useDefinedMessages();
  const intl = useIntl();

  const overlayMessage = intl.formatMessage(
    {
      defaultMessage: "Edit {contentType}",
      description:
        "'Edit' is a verb here, shortened from e.g 'Click here to edit this article' to 'Edit Article'",
    },
    { contentType: translateContentType(props.contentType, 1) }
  );
  return (
    <RoutableLink
      to={props.linkTo}
      className={props.className}
      target="_blank"
      aria-label={overlayMessage}
    >
      <EditAssetOverlay>
        <ContentTypeIcon contentType={props.contentType} size="regular" />
        <span>{overlayMessage}</span>
      </EditAssetOverlay>
      {props.children}
    </RoutableLink>
  );
};

const LinkTile = styled(LinkWithOverlay)`
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  color: ${(props) => props.theme.Color};

  &:hover {
    ${EditAssetOverlay} {
      display: flex;
    }
  }
`;

const EmptyTile = styled.div`
  position: relative;
  width: 15rem;
  height: 17rem;
  border: 2px dashed var(--color-gray-5);
  border-radius: ${(props) => props.theme.CornerRadius};

  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-xs);

  justify-content: center;
  align-items: center;

  &[aria-invalid="true"] {
    border: 2px dashed var(--color-red-50);
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
`;

const UploadRegion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  box-shadow: ${(props) => props.theme.FieldRing},
    ${(props) => props.theme.CardShadow};
  border-radius: 4px;
  background-color: ${(props) => props.theme.FieldBackground};
  padding: 2em;
  margin-bottom: 1rem;
  min-height: 135px;
  text-align: center;

  &:hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing},
      ${(props) => props.theme.CardShadow};
  }

  &.disabled {
    box-shadow: ${(props) => props.theme.FieldDisabledRing},
      ${(props) => props.theme.CardShadow};
    background-color: ${(props) => props.theme.ControlDisabledBackground};
  }
`;

const DragPrompt = styled.div`
  font: ${(props) => props.theme.FontInteractive};
`;

const ItemsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  & > * {
    background-color: ${(props) => props.theme.FieldBackground};
    box-shadow: ${(props) => props.theme.CardShadow};
  }
`;

export const MediaUploader = (props: {
  onUpload: (files: FileList) => void;
  accept: string[];
  onUnsupportedFileType: () => void;
  loading?: boolean;
  items: ContentSummary[];
  cdnHost?: string;
}) => {
  const { onUpload, accept, onUnsupportedFileType, loading, items, cdnHost } =
    props;
  const [, dropRef] = useDrop(() => ({
    accept: NativeTypes.FILE,
    canDrop: (item: { files: File[]; dataTransfer: DataTransfer }) => {
      let isCorrectFileType = item.files.every((file) =>
        accept.includes(file.type)
      );
      if (!isCorrectFileType) {
        onUnsupportedFileType();
      }

      return isCorrectFileType;
    },
    drop(item) {
      onUpload(item.dataTransfer.files);
    },
  }));

  return (
    <Wrapper>
      <UploadRegion ref={dropRef}>
        <DragPrompt role="presentation">
          <FormattedMessage
            defaultMessage="Drag files here"
            description="Text on a region where files can be dragged and dropped."
          />
        </DragPrompt>
        <UploadButton onChange={onUpload} accept={accept.join(",")} multiple />
      </UploadRegion>
      <ItemsList aria-live="polite" aria-busy={loading}>
        {items.map((item) => {
          return (
            <SearchResult
              treatment="asset-tile"
              assetAs={LinkTile}
              linkTo={item.editUrl}
              result={item}
              contentType={item.contentType}
              key={item.id}
              cdnHost={cdnHost}
            />
          );
        })}
        {loading && (
          <EmptyTile>
            <Spinner size="large" />
          </EmptyTile>
        )}
      </ItemsList>
    </Wrapper>
  );
};
MediaUploader.displayName = "MediaUploader";

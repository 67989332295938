import { GeoField as GeoFieldImplementation } from "@components";
import { useDefinedMessages } from "@hooks";
import { Queries } from "@gql";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  ControlProps,
  FormFor_form_controls_GeoFieldFormControl,
  GeocodeAddress,
  GeocodeAddressVariables,
  GetConfigs,
  GetConfigsVariables,
  VenueAddressFields,
  VenueGeoFields,
} from "@types";
import { useCallback, useMemo } from "react";

const MAPBOX_BASE_URL = "https://api.mapbox.com";
const MAPBOX_STATIC_MAP_STYLE = "cn-copilot/cjua0jlqw32fe1fm4vddc1bgp";

export function GeoField(
  props: ControlProps<FormFor_form_controls_GeoFieldFormControl>
) {
  const { model, name, errors, setValue } = props;
  const { translateFieldName } = useDefinedMessages();
  const { lat: latitude, lng: longitude } = (model.geo ?? {}) as VenueGeoFields;

  const { data: configData } = useQuery<GetConfigs, GetConfigsVariables>(
    Queries.GET_CONFIGS,
    {
      variables: {
        userId: props.currentUser.id,
        organizationId: props.currentOrganization.organizationId,
      },
    }
  );

  const mapboxPublicToken = configData?.configs.mapboxPublicToken;
  const mapUrlPattern = `${MAPBOX_BASE_URL}/styles/v1/${MAPBOX_STATIC_MAP_STYLE}/static/pin-s({long},{lat})/{long},{lat},{zoom},0,0/{height}x{width}?access_token=${mapboxPublicToken}`;

  const address = (model.address ?? {}) as VenueAddressFields;
  const addressMemo = useMemo(() => {
    const { __typename, ...addressMemo } = address;
    return addressMemo;
  }, [address]);

  const Client = useApolloClient();

  const computeLatLong = useCallback(async () => {
    const geocode = await Client.query<GeocodeAddress, GeocodeAddressVariables>(
      {
        query: Queries.GEOCODE_ADDRESS,
        variables: {
          address: addressMemo,
          mapboxPublicToken: mapboxPublicToken || "",
        },
      }
    );
    return geocode?.data?.geocode;
  }, [Client, addressMemo, mapboxPublicToken]);

  return (
    <GeoFieldImplementation
      id={`GeoField__${name}`}
      label={translateFieldName(name)}
      errors={errors}
      latitude={latitude}
      longitude={longitude}
      mapUrlPattern={mapUrlPattern}
      onChange={({ latitude, longitude }) => {
        setValue("geo", { lat: latitude, lng: longitude });
      }}
      autogenValue={mapboxPublicToken ? computeLatLong : undefined}
    />
  );
}

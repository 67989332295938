import { ReactElement } from "react";
import styled from "styled-components";

const TabsList = styled.ul`
  display: inline-block;
  list-style-type: none;
  padding: 0;
  text-align: center;
  gap: var(--spacing-xs);
  width: 100%;
  text-align: left;
  border-bottom: 1px solid var(--color-gray-5);
`;

const List = styled.li`
  display: inline-block;
  background-color: #1d1f20;
  color: var(--color-gray-1);
  cursor: pointer;
  line-height: 40px;
  font-size: var(--font-size);
  background-color: #fff;
  padding-right: var(--spacing-sm);
  &.active {
    transition: width 0.5s ease;
    font-weight: 500;
  }
  &::after {
    content: "";
    display: block;
    height: 3px;
    width: 0px;
    background: transparent;
    transition: width 0.5s ease, background-color 0.5s ease;
  }
  &.active:after {
    width: 100%;
    height: 2px;
    background-color: #3d5afe;
    margin-top: 1px;
  }
  &::before {
    display: block;
    content: attr(title);
    font-weight: 500;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

type ContainerType = {
  currentSelection?: number; ///Passing Optional Props
};

export const TabContent = styled.ul<ContainerType>`
  list-style-type: none;
  margin-block-start: var(--spacing-md);
  gap: calc(var(--spacing-xxs) * 2);
  li:nth-child(${({ currentSelection = 1 }) => currentSelection}) {
    display: block;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ReactTabs = (props: {
  tabIndex: number | 1;
  tabList: Array<string>;
  children: ReactElement;
  setCurrentIndex: (index: number) => void;
}) => {
  const { tabIndex, tabList, setCurrentIndex } = props;

  return (
    <>
      <TabsList role="tab" className={`${tabIndex}`}>
        {tabList?.map((El, i) => (
          <List
            role="tab"
            key={El}
            className={`${tabIndex === i + 1 ? "active" : ""}`}
            onClick={() => setCurrentIndex(i + 1)}
            title={`${El}`}
          >
            {El}
          </List>
        ))}
      </TabsList>
      <TabContent currentSelection={tabIndex}>{props.children}</TabContent>
    </>
  );
};

ReactTabs.displayName = "ReactTabs";

export const Tabs = styled.div``;

const ListDisplayNone = styled.li`
  display: none;
`;

export const Tab = ({
  tabId,
  children,
}: {
  tabId: number;
  children: ReactElement;
}) => {
  return (
    <ListDisplayNone aria-label={`tab_${tabId}`}>{children}</ListDisplayNone>
  );
};
Tab.displayName = "Tab";

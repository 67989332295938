import {
  GetCurrentUser_currentUser,
  GetBrand_brandConfiguration_contentTypes as ContentType,
} from "@types";
import { useIntl } from "react-intl";
import { ContentTypeArticleIcon } from "@condenast/gemini/icons";
import {
  AdvancedSearchOptions,
  MainSearchAdapterFactory,
  SearchPageURLParams,
  SearchParamKey,
  GetAppliedFiltersOptions,
} from "../types";
import { useDefinedMessages } from "@hooks";
import { FC, useMemo } from "react";
import { StyledSelect, CustomFilterWrapper } from "./all";
import { MoreFilter, PeopleFilter } from "../searchFilters";
import { FilterPillList } from "../FilterPillList";
import { getAppliedFiltersCount, getAppliedFilters } from "../helpers";

const MediaListLabel = () => {
  const intl = useIntl();
  return (
    <>
      <span>
        {intl.formatMessage({
          defaultMessage: "Media",
          description: "Search adapter name for media contentTypes",
        })}
      </span>
    </>
  );
};

const MEDIA_TYPES = ["photo", "clip", "cartoon"];
const IGNORED_FILTERS: SearchParamKey[] = ["display", "lang", "status"];
const ALLOWED_OPTIONS = {
  types: MEDIA_TYPES,
};
const ADAPTER_OPTIONS: GetAppliedFiltersOptions = {
  ignoredFilters: IGNORED_FILTERS,
  allowedOptions: ALLOWED_OPTIONS,
};

const MediaFilters: FC<{
  searchParams: AdvancedSearchOptions;
  onChange: (updatedFilters: AdvancedSearchOptions) => void;
  contentTypes?: ContentType[];
  statusOptions?: string[];
  dateRangeOptions?: string[];
  availableLanguages?: (string | null)[];
  enableLanguageFilters?: boolean;
  organizationId: string;
  currentUser: GetCurrentUser_currentUser;
  mobileView?: boolean;
  syndicationEnabled: boolean;
  categoryConfigType?: string | null;
}> = (props) => {
  const { translateContentType } = useDefinedMessages();
  const {
    searchParams,
    onChange,
    organizationId,
    currentUser,
    mobileView,
    syndicationEnabled,
    contentTypes,
  } = props;
  let intl = useIntl();

  return (
    <>
      <StyledSelect
        id="search-content-types"
        $isMobile={mobileView}
        label={
          mobileView ? intl.formatMessage({ defaultMessage: "Type" }) : null
        }
        placeholder={
          mobileView && searchParams?.types && searchParams?.types.length > 0
            ? intl.formatMessage(
                { defaultMessage: "{count} selected" },
                { count: searchParams?.types.length }
              )
            : intl.formatMessage({ defaultMessage: "Type" })
        }
        multiple
        value={searchParams?.types || []}
        onChange={(value: string[] | undefined) =>
          onChange({
            types: value,
          })
        }
        options={
          MEDIA_TYPES.filter((type) =>
            contentTypes?.some((contentType) => contentType.value === type)
          )
            .map((contentType) => {
              return {
                label: translateContentType(contentType ?? "", 1),
                value: contentType,
              };
            })
            .sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            ) ?? []
        }
        icon={mobileView ? undefined : ContentTypeArticleIcon}
      />
      <CustomFilterWrapper $isMobile={mobileView}>
        <PeopleFilter
          onChange={onChange}
          currentUser={currentUser}
          mobileView={mobileView}
          selectedFilters={searchParams}
          organizationId={organizationId}
        />
      </CustomFilterWrapper>

      {!mobileView && (
        <MoreFilter
          currentFilters={searchParams}
          onChange={onChange}
          syndicationEnabled={syndicationEnabled}
        />
      )}
    </>
  );
};

const MediaAppliedFiltersList = (props: {
  searchParams: AdvancedSearchOptions;
  onChange: (updatedFilters: AdvancedSearchOptions) => void;
}) => {
  const { searchParams, onChange } = props;
  const selectedAdapterFilters = useMemo(() => {
    return getAppliedFilters(searchParams, ADAPTER_OPTIONS);
  }, [searchParams]);
  const selectedFiltersCount = useMemo(() => {
    return getAppliedFiltersCount(searchParams, ADAPTER_OPTIONS);
  }, [searchParams]);
  return (
    <FilterPillList
      searchParams={searchParams}
      onChange={onChange}
      totalSelections={selectedFiltersCount}
      selectedAdapterFilters={selectedAdapterFilters}
    />
  );
};

export const MediaContentAdapterFactory: MainSearchAdapterFactory = ({
  search: searchFromContext,
}) => {
  return {
    id: "media",
    search: (searchParams) => {
      const mediaSearchParams = (Object.keys(searchParams) as SearchParamKey[])
        .filter((key) => !IGNORED_FILTERS.includes(key))
        .reduce((obj, key) => {
          obj[key] = searchParams[key];
          return obj;
        }, {} as SearchPageURLParams);
      if (mediaSearchParams.types?.length) {
        mediaSearchParams.types = mediaSearchParams.types
          .split(",")
          .filter((type: string) => MEDIA_TYPES.includes(type))
          .join(",");
      }
      if (!mediaSearchParams.types?.length) {
        mediaSearchParams.types = MEDIA_TYPES.join(",");
      }
      searchFromContext(mediaSearchParams);
    },
    isDefault: true,
    Label: () => <MediaListLabel />,
    Filters: MediaFilters,
    AppliedFiltersList: MediaAppliedFiltersList,
    AppliedFiltersCount: (searchParams: AdvancedSearchOptions) => {
      return getAppliedFiltersCount(searchParams, ADAPTER_OPTIONS);
    },
    defaultTreatment: "grid",
  };
};

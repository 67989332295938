import type { ContentSerializers } from "../types";
import type { ReviewInput } from "@types";
import {
  categoryTaxonomies,
  contentConnection,
  allContributors,
  seo,
  itemsReviewed,
} from "../fields";
import {
  serializerFor,
  includeAllMetadataFields,
  entityMetadata,
} from "../utils";

const ReviewFields = {
  allContributors,
  categoryTaxonomies,
  itemsReviewed: itemsReviewed,
  itemReviewed: contentConnection,
  backReferences: contentConnection,
  photosLede: contentConnection,
  photosTout: contentConnection,
  photosSocial: contentConnection,
  imageSocial: contentConnection,
  related: contentConnection,
  interludeOverride: contentConnection,
  audiofileAttachments: contentConnection,
  seo: seo,
} as const;

export const ReviewSerializers: ContentSerializers<ReviewInput> = {
  save: (changes, model, { currentUser }) => {
    if (!("hed" in model)) {
      return null;
    }
    let metadataFields = includeAllMetadataFields(model, changes);
    return {
      ...serializerFor(ReviewFields)(changes),
      ...metadataFields,
      hed: model.hed,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
    return {
      hed: createTitle,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

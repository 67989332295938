import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required, length } from "@condenast/copilot-validators";
import { FormFor_content_CuratedList } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";
import { publishURI, ifModelEquals, edgesHaveNodes } from "../validators";

const { object } = validators;

const curatedListValidators = object({
  hed: required(),
})
  .andAlso(
    ifModelEquals(
      "curationSubType",
      "LinkBannerVisual",
      object({
        items: edgesHaveNodes(),
      })
    )
  )
  .andAlso(
    ifModelEquals(
      "curationSubType",
      "LinkBannerMultipleLinks",
      object({
        items: edgesHaveNodes(),
      })
    )
  )
  .andAlso(
    ifModelEquals(
      "curationSubType",
      "LinkBannerMarquee",
      object({
        items: edgesHaveNodes(),
      })
    )
  )
  .andAlso(
    ifModelEquals(
      "curationSubType",
      "LinkBannerMultipleLinks",
      object({
        items: object({
          edges: length({ min: 1 }),
        }).on("publish"),
      })
    )
  )
  .andAlso(
    ifModelEquals(
      "curationSubType",
      "LinkBannerMarquee",
      object({
        items: object({
          edges: length({ min: 1 }),
        }).on("publish"),
      })
    )
  )
  .andAlso(publishURI().on("publish"));

export default function validateCuratedList(
  curatedSearch: FormFor_content_CuratedList,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(curatedSearch, build(curatedListValidators), context, env);
}

import { GetCurrentUser_currentUser } from "@types";
import { validate, ValidationError, ObjectModel } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import {
  required,
  email,
  match,
  optional,
  length,
  format,
} from "@condenast/copilot-validators";

type UserFormData = GetCurrentUser_currentUser & {
  oldPassword?: string;
  newPassword?: string;
  verifyPassword?: string;
};

const ENV: ObjectModel = {
  get(object: unknown, key: string): unknown {
    if (object === null || object === undefined) return;
    return (object as { [key: string]: unknown })[key];
  },

  asList(object: unknown): Array<unknown | null> {
    if (Array.isArray(object)) {
      return object;
    } else {
      return [];
    }
  },
};

function password() {
  return format(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])/)
    .catch(() => [
      {
        path: [],
        message: { name: "password-validation", details: undefined },
      },
    ])
    .andAlso(
      length({ min: 8 }).catch(() => [
        { path: [], message: { name: "password-length", details: undefined } },
      ])
    );
}

function matchPasswords(verifyPassword: string, newPassword: string) {
  return match(verifyPassword, newPassword).catch(() => [
    {
      path: ["verifyPassword"],
      message: { name: "match", details: undefined },
    },
  ]);
}
const { object } = validators;

const userValidators = object({
  firstName: required(),
  lastName: required(),
  userName: required(),
  email: required().andThen(email()),
  role: required(),
  newPassword: optional(password()),
}).andAlso(matchPasswords("verifyPassword", "newPassword"));

export function validateUserMyProfile(
  user: UserFormData
): Promise<ValidationError[]> {
  return validate(user, build(userValidators), null, ENV);
}

import { useMutation, useQuery } from "@apollo/client";
import { Mutations, Queries } from "@gql";
import {
  getTranslations,
  GetBrand,
  GetBrandVariables,
  Translate,
  TranslateVariables,
} from "@types";
import { getTranslations_getTranslations as translation } from "@types";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { byCopilotCode } from "@fixtures";
import { singularize } from "@lib";
import { LangSwitcherMenu } from "@components";
import styled from "styled-components";

const StyledLangSwitcherMenu = styled.div`
  button {
    border: 1px solid var(--color-gray-5);
    background: var(--color-white);
    padding: var(--spacing-xs) calc(2 * var(--spacing-xs));
    border-radius: calc(var(--spacing-xs) / 2);
    box-shadow: none;
    margin-right: var(--spacing-xs);
    color: var(--color-blue-90);
  }
`;

export function LangSwitcher(props: { currentLang: string }) {
  const { currentLang } = props;

  let params = useParams() as {
    copilotCode: string;
    contentType: string;
    id: string;
  };

  let { data: getTranslationsData } = useQuery<getTranslations>(
    Queries.GET_TRANSLATIONS,
    {
      variables: {
        organizationId: byCopilotCode(params.copilotCode).organizationId,
        contentType: singularize(params.contentType),
        id: params.id,
      },
    }
  );

  let existingTranslations = getTranslationsData?.getTranslations ?? [];

  let { data: brandConfigData } = useQuery<GetBrand, GetBrandVariables>(
    Queries.GET_BRAND,
    {
      variables: {
        organizationId: byCopilotCode(params.copilotCode).organizationId,
      },
    }
  );

  let availableLanguages =
    brandConfigData?.brandConfiguration?.language?.available ?? [];

  let existingLanguages = existingTranslations.map((existingLang) =>
    existingLang && "lang" in existingLang ? existingLang.lang : null
  );

  let newAvailableLanguages = availableLanguages.filter(
    (lang) => !existingLanguages.includes(lang)
  );

  const [submitTranslate] = useMutation<Translate, TranslateVariables>(
    Mutations.TRANSLATE
  );

  const translate = useCallback(
    async (targetLang: string) => {
      try {
        const response = await submitTranslate({
          variables: {
            organizationId: byCopilotCode(params.copilotCode).organizationId,
            id: params.id,
            contentType: singularize(params.contentType),
            data: { targetLang: targetLang },
          },
        });
        if (response.errors?.length) {
          return "";
        } else if (response.data) {
          return response.data?.translate?.id;
        } else {
          return "";
        }
      } catch (error) {
        return "";
      }
    },
    [submitTranslate, params]
  );

  function hasLang(
    translation: translation | null
  ): translation is { id: string; lang: string; __typename: any } {
    return translation ? "lang" in translation : false;
  }

  const getDestination = useCallback(
    async (lang: string, allTranslations: (translation | null)[]) => {
      let foundTranslation = allTranslations.find(
        (trans) => hasLang(trans) && trans.lang === lang
      );
      if (foundTranslation) {
        window.open(
          `${window.location.origin}/${params.copilotCode}/${params.contentType}/${foundTranslation.id}`,
          "_blank"
        );
      } else {
        translate(lang).then((data) => {
          window.open(
            `${window.location.origin}/${params.copilotCode}/${params.contentType}/${data}`,
            "_blank"
          );
        });
      }
    },
    [translate, params.contentType, params.copilotCode]
  );

  return availableLanguages?.length > 1 ? (
    <StyledLangSwitcherMenu>
      <LangSwitcherMenu
        currentLang={currentLang}
        existingLang={existingTranslations}
        newLang={newAvailableLanguages}
        onChange={getDestination}
      />
    </StyledLangSwitcherMenu>
  ) : (
    <></>
  );
}

LangSwitcher.displayName = "LangSwitcher";

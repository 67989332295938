import update, { Spec } from "immutability-helper";
import { get } from "./get";

function wrap(key: string, value: unknown) {
  const index = parseInt(key);
  if (!isNaN(index)) {
    const array = new Array(index);
    array[index] = value;
    return array;
  }
  return { [key]: value };
}

export function merge<T>(obj: T, path: string, value: unknown) {
  const parts = path.split(".");
  let fragment = value;
  let key = parts.pop();
  while (key) {
    fragment = wrap(key, fragment);
    const modelValue = parts.length ? get(obj, parts.join(".")) : obj;
    if (modelValue) {
      fragment = update(modelValue as Record<string | number, unknown>, {
        $merge: fragment as Record<string | number, unknown>,
      });
    }
    key = parts.pop();
  }
  return fragment as T;
}

export function unset<T>(obj: T, path: string) {
  const parts = path.split(".");
  let fragment = { $unset: [parts.pop() as string] } as unknown;
  let key = parts.pop();
  while (key) {
    fragment = wrap(key, fragment);
    key = parts.pop();
  }
  return update(obj, fragment as Spec<T>);
}

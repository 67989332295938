import { useEffect } from "react";
import { singularize } from "@lib";
import { GetCurrentUser_currentUser, Organization } from "@types";
import { Navigate, useParams } from "react-router-dom";
import { useContentMutation } from "@hooks";
import styled from "styled-components";
import { Spinner, ValidationSummary } from "@condenast/gemini";
import { useIntl } from "react-intl";
import { config } from "@lib";

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const ContentCreate = (props: {
  currentUser: GetCurrentUser_currentUser;
  currentOrganization: Organization;
  loading?: boolean;
}) => {
  const { currentOrganization, currentUser, loading } = props;
  const params = useParams() as {
    copilotCode: string;
    contentType: string;
  };
  const collectionName = params.contentType;
  const copilotCode = params.copilotCode;
  const contentType = singularize(collectionName ?? "");

  const [serialize, saveContent, saveResult] = useContentMutation(
    contentType,
    "create"
  );

  const intl = useIntl();

  useEffect(() => {
    if (!serialize || !saveContent || loading) {
      return;
    }

    const input = serialize({
      currentUser,
      intl,
      brandCode: currentOrganization.metadata.copilotCode,
      config: { hostnames: { consumer: currentOrganization.url } },
    });
    if (input != null) {
      saveContent({
        variables: {
          organizationId: currentOrganization.organizationId,
          data: input,
        },
      });
    }
  }, [serialize, saveContent, currentUser, currentOrganization, intl, loading]);

  const legacyContentTypes = ["bundle", "seasons", "runwaymoments", "packages"];

  if (legacyContentTypes.includes(contentType) && !saveResult) {
    config.environment === "production"
      ? window.location.replace(
          `https://copilot.condenast.io/${copilotCode}/${collectionName}/create`
        )
      : window.location.replace(
          `https://stg-copilot.condenast.io/${copilotCode}/${collectionName}/create`
        );
    return <></>;
  }

  return saveResult?.data?.content ? (
    <Navigate
      to={`/${copilotCode}/${collectionName}/${saveResult.data.content.id}`}
      replace={true}
    />
  ) : saveResult?.error ? (
    <ValidationSummary
      errors={[
        { message: saveResult.error.message, path: [saveResult.error.name] },
      ]}
    />
  ) : (
    <SpinnerWrapper>
      <Spinner size="large" />
    </SpinnerWrapper>
  );
};

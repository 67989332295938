import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required } from "@condenast/copilot-validators";
import { FormFor_content_MusicAlbum } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";

const { object } = validators;

const musicAlbumValidators = object({
  name: required(),
});

export default function validateMusicAlbum(
  musicalbum: FormFor_content_MusicAlbum,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(musicalbum, build(musicAlbumValidators), context, env);
}

import { gql } from "@apollo/client";
export const ORGANIZATIONS = gql`
  fragment Organizations on User {
    organizations {
      id
      organizationId
      url
      internalDisplayName
      companyId
      metadata {
        copilotCode
        copilotRegion
        mediaDomain
      }
    }
  }
`;

export const PublishConfig = gql`
  fragment PublishConfigFields on PublishConfig {
    distributionChannels {
      name
      enabled
      ... on AppleNewsDistributionChannelConfig {
        premiumEnabled
        webAndPremiumAllowed
      }
    }
    defaultDistributionChannels {
      name
      metadata
    }
  }
`;

export const PreviewConfig = gql`
  fragment PreviewConfigFields on PreviewConfig {
    hostname
    mountPoint
    urlPrefix
    urlSuffix
    useDesktopView
  }
`;
export const PublishInfoFields = gql`
  fragment PublishInfoFields on PublishInfo {
    expired
    version
    authorName
    pubDate
    createdAt
  }
`;

export const RevisionInfoFields = gql`
  fragment RevisionInfoFields on RevisionInfo {
    version
    createdAt
    authorName
  }
`;

export const ContentSummaryFields = gql`
  ${PublishInfoFields}
  ${RevisionInfoFields}
  fragment ContentSummaryFields on ContentSummary {
    id
    channel
    revisionInfo {
      ...RevisionInfoFields
    }
    publishQueue {
      ...PublishInfoFields
    }
    title {
      format
      content
    }
    caption {
      format
      content
    }
    description
    dek
    rubric
    contentType
    credit {
      format
      content
    }
    uri
    editUrl
    cneId
    feedGuid
    embedUrl
    transcriptUrl
    pubDate
    publishable
    publishInfo {
      ...PublishInfoFields
    }
    archived
    asset {
      id
      type
      filename
      altText
      height
      width
      publicURL
      credit {
        format
        content
      }
    }
    restrictCropping
    aspectRatios {
      name
      height
      width
      format
      duration
      url
      override
      modifications {
        crop {
          height
          width
          x
          y
        }
      }
    }
    metadata {
      ... on ContentSummaryOffer {
        count
        price
        currency
        seller
        isOutOfStock
        offerUrl
        hasSimilarProduct
      }

      ... on ContentSummaryRating {
        rating
      }

      ... on ContentSummaryDimensions {
        height
        width
      }

      ... on ContentSummaryCategory {
        category
      }

      ... on ContentSummarySlideCount {
        count
      }
      ... on ContentSummaryCNEVideo {
        premiereDate
        scriptEmbedUrl
      }
      ... on ContentSummaryContributors {
        contributors {
          type
          names
        }
      }
      ... on ContentSummaryExternalID {
        identifier
        provider
      }
      ... on ContentSummaryBundle {
        bundleType
      }
    }
  }
`;

export const VenueAddressFields = gql`
  fragment VenueAddressFields on VenueAddress {
    city
    country
    locality
    postalCode
    region
    state
    street
    streetExtended
    neighborhood
  }
`;

export const VenueGeoFields = gql`
  fragment VenueGeoFields on VenueGeoCoordinates {
    lat
    lng
  }
`;

export const LodgingOfferFields = gql`
  fragment LodgingOfferFields on LodgingOffer {
    seller {
      name
    }
    purchaseUri
    offerId
    shortUrl
  }
`;

export const OfferFields = gql`
  fragment OfferFields on Offer {
    offerId
    sellerName
    offerLink
    shortLink
    offerType
    price
    comparisonPrice
    currency
    isOutOfStock
    countryCode
    autoUpdate
  }
`;

export const ContentConnectionFields = gql`
  ${ContentSummaryFields}
  fragment ContentConnectionFields on ContentConnection {
    edges {
      node {
        ...ContentSummaryFields
      }
    }
  }
`;

export const PublishedRevisionResultFields = gql`
  ${PublishInfoFields}
  ${RevisionInfoFields}
  fragment PublishedRevisionResultFields on Publishable {
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
  }
`;

export const PublishedRevisionFields = gql`
  ${PublishedRevisionResultFields}
  fragment PublishedRevisionFields on PaginatedContent {
    limit
    page
    totalResults
    results {
      ...PublishedRevisionResultFields
    }
  }
`;

export const SyndicationFields = gql`
  fragment SyndicationFields on Syndication {
    sourceId
    canonicalUrl
    source
    targets {
      syndicationAuthor
      brand
      organizationId
      createdAt
    }
  }
`;

export const CategoryTaxonomyFields = gql`
  fragment CategoryTaxonomyFields on CategoryTaxonomy {
    taxonomy
    categories {
      id
      slug
      name
      root {
        id
        slug
        name
      }
      hierarchy {
        id
        slug
        name
      }
    }
  }
`;

export const ContentContributorFields = gql`
  fragment ContentContributorFields on ContentToContributorConnection {
    edges {
      node {
        name
        id
      }
      type
    }
  }
`;

export const SeoFields = gql`
  fragment SeoFields on HasSEO {
    seo {
      title
      description
      category
      keyword
      score
    }
  }
`;

export const SocialMediaFields = gql`
  fragment SocialMediaFields on SocialMedia {
    handle
    network
  }
`;

export const SearchFields = gql`
  fragment SearchFields on BundleSearch {
    query
    sort
    size
    title
    categoriesFilterOperator
    notCategoriesFilterOperator
    types
    contributor
    contentSource
    issueDate
    language
    includedCategoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    excludedCategoryTaxonomies {
      ...CategoryTaxonomyFields
    }
  }
`;

export const ActivityFields = gql`
  ${VenueAddressFields}
  ${VenueGeoFields}
  ${LodgingOfferFields}
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${SeoFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  fragment ActivityFields on Activity {
    id
    subtype
    secondaryType
    startDate
    endDate
    name
    body {
      content
      textFormat
    }
    description
    shortDescription
    promoHed
    promoDek
    address {
      ...VenueAddressFields
    }
    geo {
      ...VenueGeoFields
    }
    ...SeoFields
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    uri
    pubDate
    distributionChannels {
      name
      metadata
    }
    socialName
    socialDescription
    socialMedia {
      network
      handle
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    photos: connected(relname: ["photos"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    distributionChannels {
      name
      metadata
    }
    entityMetadata {
      user
    }
    hours
    ticketUrl
    editorsPick
    features
    emails: email
    phoneNumbers: phone
    urls: url
    lodgingOffers: offers {
      ...LodgingOfferFields
    }
    ratingBlock {
      overall
    }
    region
  }
`;

export const ContributorFields = gql`
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${SocialMediaFields}
  fragment ContributorFields on Contributor {
    id
    featuredBio
    name
    organization
    email
    title
    shortBio
    bio
    uri
    pubDate
    distributionChannels {
      name
      metadata
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishInfo {
      ...PublishInfoFields
    }
    socialMedia {
      ...SocialMediaFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    entityMetadata {
      user
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    featuredImg: connected(relname: ["featuredImg"]) {
      ...ContentConnectionFields
    }
    featuredStories: connected(relname: ["featuredStories"]) {
      ...ContentConnectionFields
    }
    relatedContent: connected(relname: ["relatedContent"]) {
      ...ContentConnectionFields
    }
  }
`;

export const ExternalLinkFields = gql`
  ${ContentContributorFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${RevisionInfoFields}
  fragment ExternalLinkFields on ExternalLink {
    id
    hed
    dek
    rubric
    url
    source
    revisionInfo {
      ...RevisionInfoFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
  }
`;

export const ExternalReferenceFields = gql`
  ${ContentContributorFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${RevisionInfoFields}
  fragment ExternalReferenceFields on External {
    id
    hed
    dek
    url
    source
    revisionInfo {
      ...RevisionInfoFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
  }
`;

export const AdvertiserFields = gql`
  ${ContentConnectionFields}
  ${RevisionInfoFields}
  fragment AdvertiserFields on Advertiser {
    id
    name
    url
    revisionInfo {
      ...RevisionInfoFields
    }
    photosLogo: connected(relname: ["photosLogo"]) {
      ...ContentConnectionFields
    }
    entityMetadata {
      user
    }
    lang
  }
`;

export const AudioFileFields = gql`
  ${ContentContributorFields}
  ${ContentConnectionFields}
  ${RevisionInfoFields}
  ${CategoryTaxonomyFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  fragment AudioFileFields on AudioFile {
    id
    name
    description
    transcriptUrl
    embedUrl
    durationInMs
    provider
    revisionInfo {
      ...RevisionInfoFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    lang
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    uri
    pubDate
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
  }
`;

export const InfoPageFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${SeoFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  fragment InfoPageFields on InfoPage {
    id
    hed
    dek
    body {
      content
      textFormat
    }
    uri
    pubDate
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    ...SeoFields
    socialTitle
    socialDescription
    contentSource
    ledeCaption
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    lang
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    entityMetadata {
      user
    }
    canonicalUrl
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    interludeOverride: connected(relname: ["interludeOverride"]) {
      ...ContentConnectionFields
    }
    backReferences: connected(relname: ["backReferences"]) {
      ...ContentConnectionFields
    }
    videosLede: connected(relname: ["videosLede"]) {
      ...ContentConnectionFields
    }
    inline: connected(relname: ["inline"]) {
      ...ContentConnectionFields
    }
  }
`;

export const GalleryFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${SeoFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${SyndicationFields}
  fragment GalleryFields on Gallery {
    id
    hed
    dek
    promoHed
    promoDek
    rubric
    body {
      content
      textFormat
    }
    items: connectedItems(limit: 100) {
      limit
      page
      totalResults
      edges {
        title
        caption
        body
        credit
        isActive
        node {
          id
          ...ContentSummaryFields
        }
      }
    }
    uri
    pubDate
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    ...SeoFields
    socialTitle
    socialDescription
    issueDate
    contentSource
    ledeCaption
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    lang
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    excludeFromRecirc
    excludeFromSitemaps
    entityMetadata {
      user
    }
    syndication {
      ...SyndicationFields
    }
    isGlobalDraft
    bylineOption
    bylineVariant
    campaignUrl
    canonicalUrl
    advertiser: connected(relname: ["advertiser"]) {
      ...ContentConnectionFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    imageSocial: connected(relname: ["imageSocial"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    relatedProducts: connected(relname: ["relatedProducts"]) {
      ...ContentConnectionFields
    }
    interludeOverride: connected(relname: ["interludeOverride"]) {
      ...ContentConnectionFields
    }
    backReferences: connected(relname: ["backReferences"]) {
      ...ContentConnectionFields
    }
    videosLede: connected(relname: ["videosLede"]) {
      ...ContentConnectionFields
    }
    inline: connected(relname: ["inline"]) {
      ...ContentConnectionFields
    }
  }
`;

export const ArticleFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${SeoFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${SyndicationFields}
  fragment ArticleFields on Article {
    id
    hed
    dek
    promoHed
    promoDek
    rubric
    body {
      content
      textFormat
    }
    uri
    pubDate
    printHed
    printDek
    longDek
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    ...SeoFields
    socialTitle
    socialDescription
    tags
    issueDate
    contentSource
    ledeCaption
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    lang
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    excludeFromRecirc
    excludeFromSitemaps
    entityMetadata {
      user
    }
    interactiveOverride
    mediaOverrides {
      relName
      overrides {
        breakpoint
        aspectRatio
      }
    }
    relMediaOverrides {
      page
      limit
      totalResults
      edges {
        node {
          id
        }
        ... on PhotosOverrideEdge {
          relName
          pagelayoutMediaOverrides {
            breakpoint
            aspectRatio
          }
        }
        ... on CneVideosOverrideEdge {
          relName
          cneVideoOverrides {
            disableAutoplay
            muted
            loopVideo
            disableAds
            continuousPlay
          }
        }
      }
    }
    syndication {
      ...SyndicationFields
    }
    isGlobalDraft
    bylineOption
    bylineVariant
    campaignUrl
    canonicalUrl
    socialShortUrl
    showInHomepage
    showInArchive
    showInRelated
    advertiser: connected(relname: ["advertiser"]) {
      ...ContentConnectionFields
    }
    brand: connected(relname: ["advertiser"]) {
      ...ContentConnectionFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    imageSocial: connected(relname: ["imageSocial"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    linkList: connected(relname: ["linkList"]) {
      ...ContentConnectionFields
    }
    linkBanner: connected(relname: ["linkBanner"]) {
      ...ContentConnectionFields
    }
    relatedAudio: connected(relname: ["relatedAudio"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    relatedProducts: connected(relname: ["relatedProducts"]) {
      ...ContentConnectionFields
    }
    interludeOverride: connected(relname: ["interludeOverride"]) {
      ...ContentConnectionFields
    }
    backReferences: connected(relname: ["backReferences"]) {
      ...ContentConnectionFields
    }
    videosLede: connected(relname: ["videosLede"]) {
      ...ContentConnectionFields
    }
    videosTout: connected(relname: ["videosTout"]) {
      ...ContentConnectionFields
    }
    inline: connected(relname: ["inline"]) {
      ...ContentConnectionFields
    }
    relatedArtists: connected(relname: ["relatedArtists"]) {
      ...ContentConnectionFields
    }
    venues: connected(relname: ["venues"]) {
      ...ContentConnectionFields
    }
    external: connected(relname: ["external"]) {
      ...ContentConnectionFields
    }
  }
`;

export const RedirectFields = gql`
  ${PublishedRevisionResultFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${PublishedRevisionFields}
  fragment RedirectFields on Redirect {
    id
    statusCode
    target
    organizationId
    uri
    pubDate
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    entityMetadata {
      user
    }
  }
`;

export const TagpageFields = gql`
  ${ContentConnectionFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  fragment TagpageFields on TagPage {
    id
    name
    description
    seoTitle
    seoDescription
    organizationId
    uri
    body {
      content
      textFormat
    }
    query {
      filters {
        types
        categories {
          id
          slug
          name
          hierarchy {
            name
            slug
          }
        }
      }
    }
    pubDate
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    entityMetadata {
      user
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
  }
`;

export const VenueFields = gql`
  ${VenueAddressFields}
  ${VenueGeoFields}
  ${LodgingOfferFields}
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${SeoFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${SyndicationFields}
  fragment VenueFields on Venue {
    id
    venueType
    subtype
    subType
    hed
    name
    body {
      content
      textFormat
    }
    dek
    description
    shortDescription
    promoHed
    promoDek
    address {
      ...VenueAddressFields
    }
    geo {
      ...VenueGeoFields
    }
    ...SeoFields
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    uri
    pubDate
    distributionChannels {
      name
      metadata
    }
    socialTitle
    syndication {
      ...SyndicationFields
    }
    isGlobalDraft
    socialDescription
    socialMedia {
      network
      handle
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    photos: connected(relname: ["photos"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    photosBadge: connected(relname: ["photosBadge"]) {
      ...ContentConnectionFields
    }
    videosTout: connected(relname: ["videosTout"]) {
      ...ContentConnectionFields
    }
    distributionChannels {
      name
      metadata
    }
    entityMetadata {
      user
    }
    hours
    ticketUrl
    editorsPick
    features
    rooms
    bars
    cabins
    cabinsWithBalconies
    cafes
    clubs
    passengers
    restaurants
    smallestCabin
    swimmingPools
    size
    cruiseLine
    numberOfLifts
    numberOfRuns
    cuisine
    reservations
    emails: email
    phoneNumbers: phone
    urls: url
    lodgingOffers: offers {
      ...LodgingOfferFields
    }
    ratingBlock {
      overall
    }
    region
    active
    reservationLink
    price
    paymentsAccepted
    socialShortUrl
    issueDate
  }
`;

export const TaxonomyFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${ContentContributorFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${SeoFields}
  fragment TaxonomyFields on Taxonomy {
    id
    name
    hed
    dek
    promoHed
    promoDek
    category
    organizationId
    uri
    pubDate
    socialTitle
    socialDescription
    distributionChannels {
      name
      metadata
    }
    ...SeoFields
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    entityMetadata {
      user
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    imageSocial: connected(relname: ["imageSocial"]) {
      ...ContentConnectionFields
    }
    photosBadge: connected(relname: ["photosBadge"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
  }
`;

export const RichTextConfigurationFields = gql`
  fragment RichTextConfiguration on RichTextConfig {
    build
    include
    plugins {
      alignment {
        options
      }
      clip {
        styles
      }
      copilotEmbed {
        styles
      }
      gallery {
        styles
        displays
      }
      paragraphDecorations
      photo {
        crops
        styles
        enableLinking
      }
      product {
        styles
      }
      pullquote {
        styles
        enableCredit
      }
      bodyStyle {
        showHelperText
      }
    }
    urlExpansions {
      pattern
      type
      width
      height
    }
  }
`;

export const AssetSelectorConfigurationFields = gql`
  fragment AssetSelectorConfiguration on AssetSelectorConfig {
    defaultType
    contentTypes
    externalTypes
    limitSelection
  }
`;
export const CKEditorLinkAutogenConfigurationFields = gql`
  fragment CKEditorLinkAutogenConfiguration on CKEditorLinkAutogenConfig {
    url
    rootSlug
    patternCap
    exclude
  }
`;

export const ClipFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${RevisionInfoFields}
  fragment ClipFields on Clip {
    id
    title
    notes
    credit
    filename
    caption
    altText
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    thumbnails: connected(relname: ["thumbnails"]) {
      ...ContentConnectionFields
    }
    exif {
      tagName
      tagValue
      tagGroup
    }
    aspectRatios {
      name
      height
      width
      format
      duration
      url
      override
      modifications {
        crop {
          height
          width
          x
          y
        }
      }
    }
    metadata {
      archived
    }
    renditions {
      original {
        height
        width
        resolution
        url
        publicUrl
      }
      gif {
        height
        width
        resolution
        url
        publicUrl
      }
      mp4 {
        height
        width
        resolution
        url
        publicUrl
      }
      webm {
        height
        width
        resolution
        url
        publicUrl
      }
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    entityMetadata {
      user
    }
  }
`;

export const PhotoFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${RevisionInfoFields}
  fragment PhotoFields on Photo {
    id
    title
    notes
    credit
    filename
    caption
    altText
    generatedAltText
    metadata {
      archived
    }
    restrictCropping
    revisionInfo {
      ...RevisionInfoFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    entityMetadata {
      user
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    backReferences: connected(relname: ["backReferences"]) {
      ...ContentConnectionFields
    }
    people: connected(relname: ["people"]) {
      ...ContentConnectionFields
    }
    aspectRatios {
      name
      height
      width
      format
      duration
      url
      override
      modifications {
        crop {
          height
          width
          x
          y
        }
      }
    }
  }
`;

export const BusinessFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${SeoFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  fragment BusinessFields on Business {
    id
    name
    legalName
    knowsAbout
    editorialQuote
    disableEditorialQuote
    description
    contactPoint {
      email
      telephone
      url
    }
    uri
    address {
      street
      streetExtended
      city
      state
      postalCode
      country
    }
    geo {
      lat
      lng
    }
    socialMedia {
      network
      handle
    }
    ...SeoFields
    revisionInfo {
      ...RevisionInfoFields
    }
    pubDate
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    publishInfo {
      ...PublishInfoFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    entityMetadata {
      user
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    photos: connected(relname: ["photos"]) {
      ...ContentConnectionFields
    }
    hasDataConsent
    issueDate
    contentSource
    excludeFromSitemaps
    excludeFromRecirc
  }
`;

export const CartoonFields = gql`
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  fragment CartoonFields on Cartoon {
    id
    title
    credit
    issueDate
    filename
    caption
    altText
    uri
    pubDate
    contentSource
    aNumber
    socialTitle
    socialDescription
    notes
    restrictCropping
    aspectRatios {
      name
      height
      width
      format
      duration
      url
      override
      modifications {
        crop {
          height
          width
          x
          y
        }
      }
    }
    contest {
      announceFinalistsDate
      announceFinalistsIssueDate
      announceWinnerDate
      submissionEndDate
      votingEndDate
    }
    distributionChannels {
      name
      metadata
    }
    metadata {
      archived
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    entityMetadata {
      user
    }
  }
`;

export const CookbookFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${ContentContributorFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${CategoryTaxonomyFields}
  fragment CookbookFields on Cookbook {
    id
    hed
    dek
    promoHed
    promoDek
    pubDate
    copyright
    copyrightDisplay
    eCommLink
    publisherDisplay: publisher
    copyrightDisplayAtJson {
      content
      contentType
      annotations
      schema
    }
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    uri
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    socialTitle
    socialDescription
    excludeFromSitemaps
    excludeFromRecirc
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    entityMetadata {
      user
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    imageSocial: connected(relname: ["imageSocial"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
  }
`;

export const CuratedListFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${ContentContributorFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${CategoryTaxonomyFields}
  ${SeoFields}
  fragment CuratedListFields on CuratedList {
    id
    curationSubType
    hed
    dek
    promoHed
    promoDek
    textItems {
      content
      textFormat
    }
    body {
      content
      textFormat
    }
    items: curatedlistItems(limit: 100) {
      limit
      page
      totalResults
      edges {
        hed
        dek
        rubric
        node {
          ...ContentSummaryFields
        }
      }
    }
    ...SeoFields
    seoTitle
    uri
    seoDescription
    pubDate
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    entityMetadata {
      user
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    targetRef: connected(relname: ["targetRef"]) {
      ...ContentConnectionFields
    }
  }
`;

export const CuratedSearchFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${ContentContributorFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${SeoFields}
  ${CategoryTaxonomyFields}
  fragment CuratedSearchFields on CuratedSearch {
    id
    hed
    dek
    body {
      content
      textFormat
    }
    curatedSearchQuery {
      filters {
        language
        contributor
        contentSource
        issueDate
        types
      }
      categoriesFilterOperator
      notCategoriesFilterOperator
      size
      sort
      q
    }
    ...SeoFields
    uri
    pubDate
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    entityMetadata {
      user
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    includedCategoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    excludedCategoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    includedCategoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    excludedCategoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    socialTitle
    socialDescription
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
  }
`;

export const CulturalEventFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${SeoFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  fragment CulturalEventFields on CulturalEvent {
    id
    hed
    body {
      content
      textFormat
    }
    uri
    pubDate
    startDate
    endDate
    expireDate
    eventDetails
    ticketUrl
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    entityMetadata {
      user
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
  }
`;

export const BrandFields = gql`
  ${ContentConnectionFields}
  ${ContentSummaryFields}
  ${CategoryTaxonomyFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${SeoFields}
  fragment BrandFields on Brand {
    id
    name
    description
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    photosLogo: connected(relname: ["photosLogo"]) {
      ...ContentConnectionFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    designers: connected(relname: ["designers"]) {
      ...ContentConnectionFields
    }
    previousDesigners: connected(relname: ["previousDesigners"]) {
      ...ContentConnectionFields
    }
    advertiser: connected(relname: ["advertiser"]) {
      ...ContentConnectionFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    ...SeoFields
    uri
    pubDate
  }
`;

export const ProductReviewFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${SeoFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  fragment ProductReviewFields on ProductReview {
    id
    hed
    dek
    summary
    promoHed
    promoDek
    rubric
    pros
    cons
    body {
      content
      textFormat
    }
    itemReviewed: connected(relname: ["itemReviewed"]) {
      ...ContentConnectionFields
    }
    itemsReviewed: reviewed(limit: 1) {
      limit
      page
      totalResults
      edges {
        relId
        rating
        node {
          ...ContentSummaryFields
        }
      }
    }
    uri
    pubDate
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    ...SeoFields
    socialTitle
    socialDescription
    issueDate
    contentSource
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    lang
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    entityMetadata {
      user
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    relatedProducts: connected(relname: ["relatedProducts"]) {
      ...ContentConnectionFields
    }
    interludeOverride: connected(relname: ["interludeOverride"]) {
      ...ContentConnectionFields
    }
    backReferences: connected(relname: ["backReferences"]) {
      ...ContentConnectionFields
    }
    inline: connected(relname: ["inline"]) {
      ...ContentConnectionFields
    }
  }
`;

export const RecipeIngredientFields = gql`
  fragment RecipeIngredientFields on RecipeIngredient {
    name
    unit
    unitSize
    unitQuantity
    ingredientSize
    treatment
    description
    note
  }
`;

export const RecipeIngredientGroupFields = gql`
  ${RecipeIngredientFields}
  fragment RecipeIngredientGroupFields on RecipeIngredientGroup {
    hed
    ingredients {
      ...RecipeIngredientFields
    }
  }
`;

export const RecipePreparationStepFields = gql`
  fragment RecipePreparationStepFields on RecipePreparationStep {
    description
  }
`;

export const RecipePreparationGroupFields = gql`
  ${RecipePreparationStepFields}
  fragment RecipePreparationGroupFields on RecipePreparationGroup {
    hed
    steps {
      ...RecipePreparationStepFields
    }
    microSteps {
      ...RecipePreparationStepFields
    }
  }
`;

export const RecipeFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${SeoFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${SyndicationFields}
  ${RecipeIngredientGroupFields}
  ${RecipePreparationGroupFields}
  fragment RecipeFields on Recipe {
    id
    hed
    dek
    body {
      content
      textFormat
    }
    promoHed
    promoDek
    contentSource
    issueDate
    issueYear
    issueMonth
    restaurant
    byline
    type
    rotdDate
    willMakeAgainPct: willMakeAgainPercentage
    aggregateRating
    source
    contentSource
    ledeCaption
    temperatures {
      displayTemp
      preheatTemp
      cookingTemp
    }
    times {
      displayTime
      prepTime
      activeTime
      totalTime
    }
    servingSizeInfo {
      servingSizeDescription
      servingSizeUnit
      servingSizeRangeFrom
      servingSizeRangeTo
    }
    ...SeoFields
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    ingredientGroups {
      ...RecipeIngredientGroupFields
    }
    preparationGroups {
      ...RecipePreparationGroupFields
    }
    socialTitle
    socialDescription
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    uri
    pubDate
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    entityMetadata {
      user
    }
    syndication {
      ...SyndicationFields
    }
    canonicalUrl
    bylineOption
    bylineVariant
    campaignUrl
    prepNotes
    prepNotesHed
    nutritionalInfo
    excludeFromSitemaps
    excludeFromRecirc
    advertiser: connected(relname: ["advertiser"]) {
      ...ContentConnectionFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    videosLede: connected(relname: ["videosLede"]) {
      ...ContentConnectionFields
    }
    cookbooks: connected(relname: ["cookbooks"]) {
      ...ContentConnectionFields
    }
    products: connected(relname: ["products"]) {
      ...ContentConnectionFields
    }
    imageSocial: connected(relname: ["imageSocial"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    photos: connected(relname: ["photos"]) {
      ...ContentConnectionFields
    }
    photosBadge: connected(relname: ["photosBadge"]) {
      ...ContentConnectionFields
    }
    interludeOverride: connected(relname: ["interludeOverride"]) {
      ...ContentConnectionFields
    }
    venue: connected(relname: ["venue"]) {
      ...ContentConnectionFields
    }
    ingredientProducts: connected(relname: ["ingredientProducts"]) {
      ...ContentConnectionFields
    }
  }
`;

export const MusicReviewFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${SeoFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  fragment MusicReviewFields on MusicReview {
    id
    hed
    dek
    summary
    promoHed
    promoDek
    rubric
    body {
      content
      textFormat
    }
    itemsReviewed: reviewed(limit: 1) {
      limit
      page
      totalResults
      edges {
        relId
        isBestNewMusic
        isBestNewReissue
        rating
        node {
          ...ContentSummaryFields
        }
      }
    }
    audiofileAttachments: connected(relname: ["audiofileAttachments"]) {
      ...ContentConnectionFields
    }
    uri
    pubDate
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    ...SeoFields
    socialTitle
    socialDescription
    issueDate
    contentSource
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    lang
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    entityMetadata {
      user
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    relatedProducts: connected(relname: ["relatedProducts"]) {
      ...ContentConnectionFields
    }
    interludeOverride: connected(relname: ["interludeOverride"]) {
      ...ContentConnectionFields
    }
    backReferences: connected(relname: ["backReferences"]) {
      ...ContentConnectionFields
    }
    inline: connected(relname: ["inline"]) {
      ...ContentConnectionFields
    }
  }
`;
export const AspectRatioFields = gql`
  fragment AspectRatioFields on BreakPointConfig {
    aspectRatio
  }
`;

export const RenditionConfigsFields = gql`
  ${AspectRatioFields}
  fragment RenditionConfigsFields on LayoutRenditionConfigs {
    name
    config {
      sm {
        ...AspectRatioFields
      }
      md {
        ...AspectRatioFields
      }
      lg {
        ...AspectRatioFields
      }
      xl {
        ...AspectRatioFields
      }
    }
  }
`;

export const SummaryItemFields = gql`
  fragment SummaryItemFields on LayoutComponentConfig {
    settings
    variation
  }
`;

export const ComponentConfigsFields = gql`
  ${SummaryItemFields}
  fragment ComponentConfigsFields on LayoutComponentConfigs {
    SummaryItem {
      ...SummaryItemFields
    }
    SummaryCollectionGrid {
      ...SummaryItemFields
    }
    SectionTitle {
      ...SummaryItemFields
    }
    ContentHeader {
      ...SummaryItemFields
    }
    SmartContainer {
      ...SummaryItemFields
    }
    SmartItem {
      ...SummaryItemFields
    }
  }
`;

export const LiveStoryFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${SeoFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  fragment LiveStoryFields on LiveStory {
    id
    hed
    dek
    promoHed
    promoDek
    rubric
    contextualHed
    contextualDek
    contextualRubric
    excludeFromRecirc
    excludeFromSitemaps
    ledeCaption
    contentSource
    body {
      content
      textFormat
    }
    uri
    pubDate
    liveStartDate
    liveEndDate
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    ...SeoFields
    socialTitle
    socialDescription
    sponsorByline
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    lang
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    entityMetadata {
      user
    }
    isSponsored
    advertiser: connected(relname: ["advertiser"]) {
      ...ContentConnectionFields
    }
    backReferences: connected(relname: ["backReferences"]) {
      ...ContentConnectionFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    videosLede: connected(relname: ["videosLede"]) {
      ...ContentConnectionFields
    }
  }
`;

export const LivestoryupdateFields = gql`
  ${ContentContributorFields}
  fragment LivestoryupdateFields on Livestoryupdate {
    id
    title
    body {
      content
      textFormat
    }
    liveStoryId
    showInFeed
    createdAt
    revisionCreatedAt
    revision
    lang
    isProgrammatic
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    entityMetadata {
      user
    }
  }
`;

export const ProductFields = gql`
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${OfferFields}
  fragment ProductFields on Product {
    id
    name
    productType
    description
    productPros: pros
    productCons: cons
    rating
    awards {
      date
      name
    }
    offers {
      ...OfferFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    uri
    pubDate
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    lang
    entityMetadata {
      user
    }
    brand: connected(relname: ["brand"]) {
      ...ContentConnectionFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    media: connected(relname: ["media"]) {
      ...ContentConnectionFields
    }
    backReferences: connected(relname: ["backReferences"]) {
      ...ContentConnectionFields
    }
  }
`;

export const MusicAlbumFields = gql`
  ${RevisionInfoFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  fragment MusicAlbumFields on MusicAlbum {
    id
    disambiguatingDescription
    name
    authorName
    isCompilation
    revisionInfo {
      ...RevisionInfoFields
    }
    entityMetadata {
      user
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    byArtist: connected(relname: ["byArtist"]) {
      ...ContentConnectionFields
    }
    artwork: connected(relname: ["artwork"]) {
      ...ContentConnectionFields
    }
  }
`;

export const MusicReleaseFields = gql`
  ${RevisionInfoFields}
  ${ContentConnectionFields}
  fragment MusicReleaseFields on MusicRelease {
    id
    disambiguatingDescription
    releaseDate
    name
    authorName
    revisionInfo {
      ...RevisionInfoFields
    }
    entityMetadata {
      user
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    publisher: connected(relname: ["publisher"]) {
      ...ContentConnectionFields
    }
    isReleaseOf: connected(relname: ["isReleaseOf"]) {
      ...ContentConnectionFields
    }
    artwork: connected(relname: ["artwork"]) {
      ...ContentConnectionFields
    }
  }
`;

export const MusicGroupFields = gql`
  ${ContentConnectionFields}
  ${ContentContributorFields}
  ${CategoryTaxonomyFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${SocialMediaFields}
  fragment MusicGroupFields on MusicGroup {
    id
    disambiguatingDescription
    description
    name
    authorName
    uri
    pubDate
    revisionInfo {
      ...RevisionInfoFields
    }
    entityMetadata {
      user
    }
    socialMedia {
      ...SocialMediaFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    distributionChannels {
      name
      metadata
    }
    publishInfo {
      ...PublishInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
  }
`;

export const RcalistFields = gql`
  ${CategoryTaxonomyFields}
  ${RevisionInfoFields}
  ${ContentConnectionFields}
  ${PublishInfoFields}
  ${PublishedRevisionFields}
  fragment RcalistFields on Rcalist {
    id
    hed
    dek
    year
    region
    uri
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    entityMetadata {
      user
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    publishInfo {
      ...PublishInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    distributionChannels {
      name
      metadata
    }
    relatedContent: connected(relname: ["relatedContent"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    touts: connected(limit: 1, relname: ["touts"]) {
      limit
      page
      totalResults
      edges {
        node {
          ...ContentSummaryFields
        }
      }
    }
    items: connected(limit: 100, relname: ["items"]) {
      limit
      page
      totalResults
      edges {
        node {
          ...ContentSummaryFields
        }
      }
    }
  }
`;

export const MusicRecordingFields = gql`
  ${RevisionInfoFields}
  ${ContentConnectionFields}
  fragment MusicRecordingFields on MusicRecording {
    id
    disambiguatingDescription
    releaseDate
    name
    authorName
    revisionInfo {
      ...RevisionInfoFields
    }
    entityMetadata {
      user
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    publisher: connected(relname: ["publisher"]) {
      ...ContentConnectionFields
    }
    byArtist: connected(relname: ["byArtist"]) {
      ...ContentConnectionFields
    }
  }
`;

export const PersonFields = gql`
  ${CategoryTaxonomyFields}
  ${SocialMediaFields}
  ${RevisionInfoFields}
  ${ContentConnectionFields}
  ${SeoFields}
  ${PublishInfoFields}
  ${PublishedRevisionFields}
  fragment PersonFields on Person {
    id
    name
    givenName
    familyName
    additionalName
    alternateName
    honorificPrefix
    honorificSuffix
    email
    bio
    uri
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    socialMedia {
      ...SocialMediaFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    entityMetadata {
      user
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    gender
    roles
    tags
    ...SeoFields
    publishInfo {
      ...PublishInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    distributionChannels {
      name
      metadata
    }
    relatedContent: connected(relname: ["relatedContent"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
  }
`;

export const CityFields = gql`
  ${ContentContributorFields}
  ${RevisionInfoFields}
  fragment CityFields on City {
    id
    name
    code
    tzdata
    authorName
    entityMetadata {
      user
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
  }
`;

export const EventFields = gql`
  ${CategoryTaxonomyFields}
  ${RevisionInfoFields}
  ${ContentConnectionFields}
  ${PublishInfoFields}
  ${PublishedRevisionFields}
  ${ContentContributorFields}
  fragment EventFields on Event {
    id
    hed
    dek
    description
    body {
      content
      textFormat
    }
    name
    url
    uri
    ticketUrl
    pubDate
    eventDetails
    eventRubric
    eventStatus
    ctaLabel
    startDate
    endDate
    expireDate
    timeZone
    location {
      name
      street
      city
      state
      country
      postalCode
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    entityMetadata {
      user
    }
    lastReviewed
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    publishInfo {
      ...PublishInfoFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    distributionChannels {
      name
      metadata
    }
    relatedContent: connected(relname: ["relatedContent"]) {
      ...ContentConnectionFields
    }
    related: connected(relname: ["related"]) {
      ...ContentConnectionFields
    }
    venues: connected(relname: ["venues"]) {
      ...ContentConnectionFields
    }
    relatedArtists: connected(relname: ["relatedArtists"]) {
      ...ContentConnectionFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
  }
`;

export const CategoryFields = gql`
  fragment CategoryFields on Category {
    id
    name
    slug
    revision
    meta {
      childCount
    }
    root {
      id
      name
      slug
      revision
    }
    hierarchy {
      id
      name
      slug
      revision
    }
  }
`;

export const BundleFields = gql`
  ${ContentSummaryFields}
  ${ContentConnectionFields}
  ${CategoryTaxonomyFields}
  ${ContentContributorFields}
  ${PublishedRevisionFields}
  ${PublishInfoFields}
  ${RevisionInfoFields}
  ${SearchFields}
  ${SeoFields}
  fragment BundleFields on Bundle {
    pubDate
    bundleType
    contentSource
    issueDate
    isNative
    bylineOption
    bylineVariant
    campaignUrl
    id
    organizationId
    lang
    allowDuplicates
    isProgrammatic
    uri

    body {
      content
      textFormat
    }
    dek
    hed
    promoHed
    promoDek
    rubric
    socialTitle
    socialDescription
    containers {
      curationContainerType
      componentPreset
      dek
      hed
      body {
        content
        textFormat
      }
      id
      layout
      photoId
      containerAsset {
        limit
        page
        totalResults
        edges {
          node {
            ...ContentSummaryFields
          }
        }
      }
      curations(limit: 100) {
        limit
        page
        totalResults
        edges {
          hed
          dek
          node {
            ...ContentSummaryFields
          }
        }
      }
      searches {
        ...SearchFields
      }
    }
    ...SeoFields
    distributionChannels {
      name
      metadata
    }
    entityMetadata {
      user
    }
    revisionInfo {
      ...RevisionInfoFields
    }
    categoryTaxonomies {
      ...CategoryTaxonomyFields
    }
    allContributors(limit: 100) {
      ...ContentContributorFields
    }
    publishedRevisions(limit: 100) {
      ...PublishedRevisionFields
    }
    publishInfo {
      ...PublishInfoFields
    }
    photosContainers: connected(relname: ["photosContainers"]) {
      ...ContentConnectionFields
    }
    photosTout: connected(relname: ["photosTout"]) {
      ...ContentConnectionFields
    }
    photosLede: connected(relname: ["photosLede"]) {
      ...ContentConnectionFields
    }
    photosSocial: connected(relname: ["photosSocial"]) {
      ...ContentConnectionFields
    }
    advertiser: connected(relname: ["advertiser"]) {
      ...ContentConnectionFields
    }
    inline: connected(relname: ["inline"]) {
      ...ContentConnectionFields
    }
  }
`;

import { useCallback, useMemo, useState } from "react";
import { useDebouncedCallback, useDefinedMessages } from "@hooks";
import {
  GetCurrentUser_currentUser,
  GetUsersVariables,
  Search,
  SearchVariables,
  GetUsers,
  GetUsers_users,
  Option,
  ContentSummary,
} from "@types";
import styled, { CSSProperties } from "styled-components";
import {
  Button,
  Field,
  SearchMultiselect,
  Toggle,
  Label,
  Chip,
} from "@components";
import { CloseIcon, PeopleIcon } from "@condenast/gemini/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { CustomFilterWrapper } from "./CustomFilterWrapper";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Queries } from "@gql";
import { normalizeContentSummary } from "@lib";
import filterUsers from "../../MyTeam";
import { AdvancedSearchOptions } from "../types";

const Wrapper = styled.div<{ $mobileView?: boolean; $takeoverView?: boolean }>`
  ${({ $mobileView, $takeoverView, theme }) => {
    return `
      margin-bottom: ${$mobileView ? "var(--spacing-sm)" : "initial"};
    
      button {
        background: ${
          $takeoverView ? theme.SecondaryActiveBackground : theme.Background
        };
      }
    `;
  }}
`;

const FilterWrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-xxs) 0;
  grid-template-rows: auto auto auto;
  height: fit-content;
  grid-template-areas:
    "label"
    "control"
    "."
    "message";
`;

const ToggleBlock = styled.div`
  display: flex;
  align-items: center;

  & span {
    margin-right: var(--spacing-xs);
  }
`;

const MobileChips = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  font: var(--font-small-statements);
`;

const MobileChipList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-xs) 0;

  &.selection-list--empty {
    padding: 0;
  }
`;

const Selection = styled(Chip)`
  padding-right: var(--spacing-xxs);
  margin-right: var(--spacing-xxs);
  margin-bottom: var(--spacing-xxs);
  background-color: ${({ theme }) => theme.MedallionBackground};

  button {
    background: transparent;
    &:not([disabled]):hover {
      background: transparent;
    }
  }
`;

const SelectionLabel = styled.span`
  margin-right: var(--spacing-xxs);
`;

function collaboratorToMultiSelectOption(
  collaborator: GetUsers_users | GetCurrentUser_currentUser
): Option<GetUsers_users | GetCurrentUser_currentUser> {
  return {
    key: collaborator.id,
    label: `${collaborator.firstName} ${collaborator.lastName}`,
    value: collaborator,
  };
}

function contributorToMultiSelectOption(
  contributor: ContentSummary
): Option<ContentSummary> {
  return {
    key: contributor.id,
    label: contributor.title?.content ?? "",
    value: contributor,
  };
}
export const PeopleFilter = (props: {
  mobileView?: boolean;
  currentUser: GetCurrentUser_currentUser;
  onChange: (updatedPeopleFilters: AdvancedSearchOptions) => void;
  organizationId: string;
  selectedFilters: AdvancedSearchOptions;
  buttonOverrideStyles?: CSSProperties;
  takeoverView?: boolean;
}) => {
  const intl = useIntl();
  const {
    mobileView,
    currentUser,
    onChange,
    selectedFilters,
    organizationId,
    takeoverView,
  } = props;
  const { translateContentType } = useDefinedMessages();
  const [
    contributorSearch,
    { data: contributorSearchData, loading: contributorSearchLoading },
  ] = useLazyQuery<Search, SearchVariables>(Queries.SEARCH);

  const { data: usersSearchData, loading: usersSearchLoading } = useQuery<
    GetUsers,
    GetUsersVariables
  >(Queries.GET_USERS, {
    variables: {
      organizationId,
    },
  });

  const onContributorSearch = useDebouncedCallback((query: string) => {
    contributorSearch({
      variables: {
        organizationId,
        filters: {
          q: query,
          types: "contributor",
          status: "published",
          display: "all",
          qt: "contributorLookup",
        },
      },
    });
  }, 250);

  const [collaboratorResults, setCollaboratorResults] = useState<
    GetUsers_users[]
  >([]);

  const onCollaboratorSearch = useCallback(
    (query: string) => {
      setCollaboratorResults(
        filterUsers(usersSearchData?.users || [], {
          query: query,
        })
      );
    },
    [usersSearchData?.users]
  );
  const selectedContributors = useMemo(() => {
    return selectedFilters?.contributors?.map((contributor) =>
      contributorToMultiSelectOption(contributor)
    );
  }, [selectedFilters.contributors]);

  const selectedCollaborators = useMemo(() => {
    return selectedFilters.collaborators?.map((collaborator) =>
      collaboratorToMultiSelectOption(collaborator)
    );
  }, [selectedFilters.collaborators]);

  const normalizedCollaboratorSearchResults = useMemo(() => {
    return collaboratorResults?.map((collaborator) =>
      collaboratorToMultiSelectOption(collaborator)
    );
  }, [collaboratorResults]);

  const isMyContentOnlyToggleEnabled = useMemo(() => {
    return (
      selectedFilters?.collaborators?.length === 1 &&
      selectedFilters?.collaborators[0].id === currentUser?.id
    );
  }, [selectedFilters?.collaborators, currentUser?.id]);

  const normalizedContributorSearchResults =
    contributorSearchData?.search?.results?.map((contributor) => {
      let normalized = normalizeContentSummary(
        contributor,
        translateContentType
      ) as ContentSummary;
      return contributorToMultiSelectOption(normalized);
    }) ?? [];

  const onCollaboratorChange = useCallback(
    (
      selectedCollaborators: (GetUsers_users | GetCurrentUser_currentUser)[]
    ) => {
      onChange({
        collaborators: selectedCollaborators.map((item) => item),
      });
    },
    [onChange]
  );

  const onContributorsChange = useCallback(
    (selectedContributors: ContentSummary[]) => {
      onChange({
        contributors: selectedContributors.map((item) => item),
      });
    },
    [onChange]
  );

  const totalSelections =
    (selectedCollaborators?.length ?? 0) + (selectedContributors?.length ?? 0);

  return (
    <Wrapper $mobileView={mobileView} $takeoverView={takeoverView}>
      {mobileView && (
        <Label>
          <FormattedMessage
            defaultMessage="People"
            description="Search filter label for People filter"
          />
        </Label>
      )}

      <CustomFilterWrapper
        icon={mobileView ? undefined : PeopleIcon}
        displayText={
          mobileView && totalSelections > 0
            ? intl.formatMessage(
                {
                  defaultMessage: "{count} selected",
                  description:
                    "Search filter placeholder when >= 1 options selected",
                },
                {
                  count: totalSelections,
                }
              )
            : mobileView
            ? intl.formatMessage({
                defaultMessage: "Select",
                description:
                  "Search filter placeholder when no options selected",
              })
            : intl.formatMessage({
                defaultMessage: "People",
              })
        }
        showArrow={true}
      >
        <FilterWrapper
          id={"search-contributors"}
          label={intl.formatMessage({
            defaultMessage: "Search Contributors",
            description: "Filter Contributors label",
          })}
        >
          <SearchMultiselect
            selections={selectedContributors || []}
            loading={contributorSearchLoading}
            search={(query) => {
              onContributorSearch(query);
            }}
            searchResults={normalizedContributorSearchResults}
            onChange={onContributorsChange}
            multiple={true}
          />
        </FilterWrapper>
        {currentUser.role !== "contributor" && (
          <>
            <FilterWrapper
              id={"search-collaborators"}
              label={intl.formatMessage({
                defaultMessage: "Search Collaborators",
                description: "Filter Collaborators label",
              })}
            >
              <SearchMultiselect
                selections={selectedCollaborators || []}
                loading={usersSearchLoading}
                search={onCollaboratorSearch}
                searchResults={normalizedCollaboratorSearchResults}
                onChange={onCollaboratorChange}
                multiple={true}
              />
            </FilterWrapper>
            <ToggleBlock>
              <span>
                <FormattedMessage defaultMessage="My Content Only" />
              </span>
              <Toggle
                id="1"
                value={isMyContentOnlyToggleEnabled}
                onChange={() => {
                  isMyContentOnlyToggleEnabled
                    ? onChange({ collaborators: [] })
                    : onChange({ collaborators: [currentUser] });
                }}
              />
            </ToggleBlock>
          </>
        )}
      </CustomFilterWrapper>
      {mobileView && (selectedContributors?.length ?? 0) > 0 && (
        <MobileChips>
          <FormattedMessage defaultMessage="Contributors" />
          <MobileChipList>
            {selectedContributors?.map((item) => {
              return (
                <Selection forwardedAs="li" key={item.key} size="small">
                  <SelectionLabel>{item?.label || item.value}</SelectionLabel>
                  <Button
                    type="button"
                    size="small"
                    aria-label={
                      intl.formatMessage(
                        {
                          defaultMessage: "Remove {item}",
                          description:
                            "button to remove an item from a list of selected items",
                        },
                        { item: item?.label || item.value }
                      ) as string
                    }
                    onClick={() => {
                      // remove the current selection from `value`
                      let newValue = selectedContributors
                        ?.filter((selection) => selection !== item)
                        .map((i) => i.value);
                      onContributorsChange(newValue);
                    }}
                  >
                    <CloseIcon size="small" />
                  </Button>
                </Selection>
              );
            })}
          </MobileChipList>
        </MobileChips>
      )}
      {mobileView && (selectedCollaborators?.length ?? 0) > 0 && (
        <MobileChips>
          <FormattedMessage defaultMessage="Collaborators" />
          <MobileChipList>
            {selectedCollaborators?.map((item) => {
              return (
                <Selection forwardedAs="li" key={item.key} size="small">
                  <SelectionLabel>{item?.label || item.value}</SelectionLabel>
                  <Button
                    type="button"
                    size="small"
                    aria-label={
                      intl.formatMessage(
                        {
                          defaultMessage: "Remove {item}",
                          description:
                            "button to remove an item from a list of selected items",
                        },
                        { item: item?.label || item.value }
                      ) as string
                    }
                    onClick={() => {
                      // remove the current selection from `value`
                      let newValue = selectedCollaborators
                        ?.filter((selection) => selection !== item)
                        .map((i) => i.value);
                      onCollaboratorChange(newValue);
                    }}
                  >
                    <CloseIcon size="small" />
                  </Button>
                </Selection>
              );
            })}
          </MobileChipList>
        </MobileChips>
      )}
    </Wrapper>
  );
};

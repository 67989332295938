import { singular, plural } from "pluralize";

export function capitalize<T extends string>(str: T): Capitalize<T> {
  let first = str[0];
  let rest = str.slice(1);

  return (first.toUpperCase() + rest) as Capitalize<T>;
}

export function singularize(str: string) {
  return singular(str);
}

export function pluralize(str: string) {
  return plural(str);
}

export function transformToPascalCase(input: string, tokenToSplitOn: string) {
  const words = input.split(tokenToSplitOn);
  return words
    .map((word) => {
      return capitalize(word);
    })
    .join("");
}

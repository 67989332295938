import { Button, Toast } from "@components";
import {
  ControlProps,
  FormFor_form_controls_RowFormControl as LiveStoryProps,
} from "@types";
import { ContentTypeLivestoryIcon } from "@condenast/gemini/icons";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled, { DefaultTheme } from "styled-components";
import { ThemeProvider } from "@contexts";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const UpdateBanner = styled(Toast)`
  &.with-details.with-action {
    grid-template-areas:
      ". . . . . . . . ."
      ". . icon message message . action . ."
      ". . details details details . action . ."
      ". . . . . . . . .";
  }
`;

const ActionWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BannerType = {
  unset: "warning",
  after: "warning",
  during: "success",
  before: "informational",
} as const;

const Tints = {
  unset: "yellow",
  after: "yellow",
  during: "green",
  before: "blue",
} as const;

const BANNER_MESSAGES = defineMessages({
  unset: {
    defaultMessage:
      "This Live Story does not have Event Times yet, but updates can still be created in the Feed Editor",
    description: "LiveStory banner message when a start time has not been set",
  },
  before: {
    defaultMessage:
      "The live feed for this content is scheduled to begin at {time} on {date}",
    description: "LiveStory banner message when the event has not yet started",
  },
  during: {
    defaultMessage:
      "The live feed for this content is now active — create updates in the Feed Editor",
    description:
      "LiveStory banner message when the event has started but not yet ended",
  },
  after: {
    defaultMessage:
      "The live feed for this content is stale but updates can still be created in the Feed Editor",
    description: "LiveStory banner message when the event has ended",
  },
});

function transformYellows(theme: DefaultTheme) {
  return {
    ...theme,
    PrimaryBackground: "var(--color-yellow-40)",
    PrimaryHoverBackground: "var(--color-yellow-30)",
    PrimaryActiveBackground: "var(--color-yellow-20)",
    FocusRing: "0 0 0 3px var(--color-yellow-70)",
  };
}

export function CreateLivestoryupdatesBanner(
  props: ControlProps<LiveStoryProps>
) {
  const { model, currentOrganization } = props;
  const createLivestoryUpdatesHref = useMemo(
    () =>
      `/${currentOrganization.metadata.copilotCode}/livestories/${model.id}/livestoryupdates/create`,
    [model.id, currentOrganization.metadata.copilotCode]
  );
  let modelStartDate = model?.liveStartDate
    ? new Date(model?.liveStartDate as string)
    : undefined;
  let modelEndDate = model?.liveEndDate
    ? new Date(model?.liveEndDate as string)
    : undefined;
  let currentDate = new Date();

  const time = !modelStartDate
    ? "unset"
    : modelEndDate && modelEndDate < currentDate
    ? "after"
    : modelStartDate <= currentDate
    ? "during"
    : "before";

  let timeOptions = {
    hour: "numeric" as const,
    minute: "numeric" as const,
  };
  let dateOptions = {
    year: "numeric" as const,
    month: "long" as const,
    day: "numeric" as const,
  };

  const intl = useIntl();
  const startTime = intl.formatTime(modelStartDate, timeOptions);
  const startDate = intl.formatDate(modelStartDate, dateOptions);

  return (
    <UpdateBanner
      type={BannerType[time]}
      details={
        <FormattedMessage
          {...BANNER_MESSAGES[time]}
          values={{ time: startTime, date: startDate }}
        />
      }
      action={
        <ThemeProvider
          tint={Tints[time]}
          transform={Tints[time] === "yellow" ? transformYellows : undefined}
        >
          <ActionWrapper>
            <Button
              treatment="primary"
              as={Link}
              to={createLivestoryUpdatesHref}
            >
              <FormattedMessage defaultMessage="Create Live Updates" />
            </Button>
          </ActionWrapper>
        </ThemeProvider>
      }
      icon={ContentTypeLivestoryIcon}
    >
      <FormattedMessage defaultMessage="Live Story" />
    </UpdateBanner>
  );
}
CreateLivestoryupdatesBanner.displayName =
  "Control(CreateLivestoryupdatesBanner)";

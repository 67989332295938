import { useUniqueId } from "@hooks";
import { ComponentPropsWithoutRef, useCallback } from "react";
import styled from "styled-components";
import {
  VersoSplitLeftIcon,
  VersoSplitRightIcon,
  DesktopIcon,
  MobileIcon,
  DownloadIcon,
  TextLeftIcon,
  TextRightIcon,
  TextTopIcon,
} from "@condenast/gemini/icons";
import { withTooltip } from "@components";
import { Placement } from "@popperjs/core";

const Icons = {
  VersoSplitLeftIcon,
  VersoSplitRightIcon,
  DownloadIcon,
  TextTopIcon,
  TextLeftIcon,
  TextRightIcon,
  MobileIcon,
  DesktopIcon,
};

const RadioContainer = styled.div<{ $direction: "row" | "column" }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => props.$direction};
  gap: var(--spacing-xs);
`;

const RadioContent = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xxs);
  min-width: var(--spacing-lg);
  min-height: var(--spacing-lg);
  gap: var(--spacing-xs);
  border: solid 1px;
  border-radius: ${(props) => props.theme.CornerRadius};
  border-color: transparent;
`;

const RadioItem = withTooltip(styled.label<{ tooltipPlacement?: Placement }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: min-content;
  height: min-content;

  &:hover {
    &[aria-checked="false"] {
      &[aria-disabled="false"] {
        & * {
          background: ${(props) => props.theme.BorderlessHoverBackground};
          box-shadow: none;
          cursor: pointer;
        }

        ${RadioContent} {
          border-color: ${(props) => props.theme.BorderlessActiveBackground};
        }
      }
    }
  }
`);

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;

  &:not(:disabled) + * {
    background-color: transparent;
  }

  &:not(:disabled):checked + * {
    background-color: ${(props) => props.theme.BorderlessActiveBackground};
  }

  &:disabled + * {
    background-color: ${(props) => props.theme.FieldDisabledColor};
  }

  &:focus + * {
    box-shadow: ${(props) => props.theme.FocusRing};
  }
`;

const Icon = ({ iconName }: { iconName?: keyof typeof Icons }) => {
  if (iconName && Icons[iconName]) {
    const Component = Icons[iconName];
    return <Component size="regular" />;
  }
  return <></>;
};

type RadioOption = ComponentPropsWithoutRef<"input"> & {
  label?: string;
  value: string;
  iconName?: keyof typeof Icons;
};

type RadioButtonGroupProps = Omit<
  ComponentPropsWithoutRef<"div">,
  "onChange"
> & {
  name?: string;
  value?: string;
  onChange: (value: string) => void;
  direction?: "row" | "column";
  options: Array<RadioOption> | undefined;
  noLabel: boolean;
  tooltipPlacement?: Placement;
};

export function RadioButtonGroup(props: RadioButtonGroupProps) {
  let {
    onChange,
    direction,
    options,
    value,
    name: inputName,
    noLabel,
    tooltipPlacement,
    ...forwardProps
  } = props;

  let sharedId = useUniqueId();
  let name = inputName || `radio_${sharedId}`;

  let handleOnChange = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <RadioContainer
      role="radiogroup"
      $direction={direction || "row"}
      {...forwardProps}
    >
      {options?.map((option) => {
        let { iconName, label, value: optionValue, ...inputProps } = option;
        let disabled = option.disabled || false;

        let optionId = `${name}_${optionValue}`;
        let isChecked = optionValue === value;
        return (
          <RadioItem
            htmlFor={optionId}
            key={optionId}
            aria-checked={isChecked}
            aria-disabled={disabled}
            aria-label={label || optionValue}
            tooltipPlacement={tooltipPlacement}
          >
            <RadioInput
              {...inputProps}
              id={optionId}
              type="radio"
              role="radio"
              name={name}
              value={optionValue}
              defaultChecked={isChecked}
              onChange={handleOnChange}
              disabled={disabled}
            />
            <RadioContent>
              {!noLabel ? label : ""}
              {<Icon iconName={iconName} />}
            </RadioContent>
          </RadioItem>
        );
      })}
    </RadioContainer>
  );
}

RadioButtonGroup.displayName = "RadioButtonGroup";

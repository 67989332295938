import { Button, Spinner, Title } from "@components";
import {
  AnimatedEllipsisIcon,
  BackIcon,
  CloseIcon,
  LinkIcon,
  NextIcon,
} from "@condenast/gemini/icons";
import {
  FormFor_form,
  GetCurrentUser_currentUser,
  GetLiveStoryUpdates_getLiveStory_updates_edges,
  LivestoryupdateFields,
  Organization,
} from "@types";
import { ActionAccess, useChangeset, useFocusTrap } from "@hooks";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "@contexts";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { FormControl } from "../../FormControl";
import { ValidationError } from "@condenast/cross-check";
import { useNavigate, useParams } from "react-router-dom";

const SpinnerWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UpdateEditTakeoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  padding: var(--spacing-sm);
  z-index: ${(props) => props.theme.ElevationModal};
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
`;

const EditHeader = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-sm);
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};

  & button {
    color: inherit;
    margin-right: var(--spacing-sm);
  }

  & div {
    margin: 0;
  }
`;

const PageTitle = styled(Title)`
  padding: var(--spacing-sm) 0;
`;

const EditFooter = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  justify-content: flex-end;
  align-items: center;
  padding: var(--spacing-sm);
  border-top: 1px solid ${(props) => props.theme.DividerColor};
  font: ${(props) => props.theme.FontSubSectionHeading};
`;

const LiveStoryForm = styled.form`
  display: flex;
  flex: 1;
  min-height: 0;
  position: relative;
  overflow-y: auto;

  & section {
    border-radius: 0;
    box-shadow: none;
    background: var(--color-gray-1);
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: min(calc(var(--spacing-xxl) * 25), 100vw);

    label {
      color: var(--color-white);
    }
  }
`;

type FormPermissions = ActionAccess<"publish" | "update">;

export const LiveStoryEditUpdate = (props: {
  onSave: (
    update: Partial<LivestoryupdateFields>,
    model: LivestoryupdateFields
  ) => void;
  onCopyURI?: () => void;
  currentUser: GetCurrentUser_currentUser;
  currentOrganization: Organization;
  validationErrors: ValidationError[];
  formLoading: boolean;
  permissions: FormPermissions;
  saveInProgress: boolean;
  form?: FormFor_form;
  previousURL: string;
  liveUpdates: (GetLiveStoryUpdates_getLiveStory_updates_edges | null)[];
}) => {
  const {
    onSave,
    currentOrganization,
    currentUser,
    validationErrors,
    permissions,
    saveInProgress,
    onCopyURI,
    form,
    previousURL,
    formLoading,
    liveUpdates,
  } = props;

  const intl = useIntl();
  const navigate = useNavigate();

  const { updateId } = useParams() as {
    updateId: string;
  };

  const currentUpdate = liveUpdates.find(
    (update) => update?.node?.id === updateId
  );

  let updateDataChangeset = useChangeset(
    currentUpdate?.node ?? ({} as LivestoryupdateFields)
  );

  let [model, setValue, changeSet] = updateDataChangeset;

  const [activeErrorPath, setActiveErrorPath] = useState<
    readonly string[] | undefined
  >(undefined);

  // after re-rendering clear out the activeErrorPath
  useLayoutEffect(() => {
    if (activeErrorPath) {
      setActiveErrorPath(undefined);
    }
  }, [activeErrorPath, setActiveErrorPath]);

  const [takeoverElement, setTakeoverElement] = useState<HTMLElement | null>(
    null
  );

  const sendUpdate = useCallback(() => {
    onSave?.(changeSet.changes, model);
  }, [onSave, changeSet.changes, model]);

  const onClose = useCallback(() => {
    navigate(`${previousURL}/livestoryupdates/create`);
  }, [navigate, previousURL]);

  useFocusTrap(takeoverElement, onClose);

  useEffect(() => {
    if ("Intercom" in window) {
      Intercom("update", {
        hide_default_launcher: true,
      });
    }
    return () => {
      if ("Intercom" in window) {
        Intercom("update", {
          hide_default_launcher: false,
        });
      }
    };
  }, []);

  return formLoading ? (
    <SpinnerWrapper>
      <Spinner size="large" />
    </SpinnerWrapper>
  ) : (
    <ThemeProvider theme="dark">
      <UpdateEditTakeoverWrapper ref={setTakeoverElement}>
        <EditHeader>
          <Button treatment="borderless" onClick={onClose} size="small">
            <BackIcon size="regular" />
          </Button>
          <PageTitle>
            {intl.formatMessage({
              defaultMessage: "Edit Live Update",
            })}
          </PageTitle>
        </EditHeader>
        <LiveStoryForm>
          <ThemeProvider theme="light">
            {form?.children.map((id: string) => {
              return (
                <FormControl
                  key={id}
                  controlId={id}
                  controls={form.controls}
                  model={model}
                  errors={validationErrors}
                  setValue={setValue}
                  currentOrganization={currentOrganization}
                  currentUser={currentUser}
                  permissions={permissions}
                  activeErrorPath={activeErrorPath}
                  linkAutogen={false}
                  linkAutogenConfigFields={[]}
                />
              );
            })}
          </ThemeProvider>
        </LiveStoryForm>
        <EditFooter>
          <Button aria-label="Copy link" onClick={onCopyURI}>
            <LinkIcon size="regular" />
            <FormattedMessage defaultMessage="Copy link" />
          </Button>
          <Button aria-label="Cancel" onClick={onClose}>
            <CloseIcon size="regular" />
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <Button
            treatment="primary"
            onClick={() => {
              sendUpdate();
              onClose();
            }}
            disabled={saveInProgress}
          >
            {saveInProgress ? (
              <AnimatedEllipsisIcon size="small" />
            ) : (
              <NextIcon size="regular" />
            )}
            <FormattedMessage defaultMessage="Send" />
          </Button>
        </EditFooter>
      </UpdateEditTakeoverWrapper>
    </ThemeProvider>
  );
};
LiveStoryEditUpdate.displayName = "LiveStoryEditUpdate";

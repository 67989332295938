import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { optional, required, url } from "@condenast/copilot-validators";

import type { ValidationEnvironment, ValidationContext } from "@lib";
import type { FormFor_content_AudioFile } from "@types";
import { publishURI } from "../validators";

const { object } = validators;

const audiofileValidators = object({
  name: required(),
  embedUrl: required(url("absolute", "leading-slash")).catch(
    ([{ message }]) => {
      return [
        {
          path: [],
          message: { ...message, name: "url-absolute-leading-slash" },
        },
      ];
    }
  ),
  transcriptUrl: optional(url("absolute", "leading-slash")).catch(
    ([{ message }]) => {
      return [
        {
          path: [],
          message: { ...message, name: "url-absolute-leading-slash" },
        },
      ];
    }
  ),
}).andAlso(publishURI().on("publish"));

export default function validateAudioFile(
  audiofile: FormFor_content_AudioFile,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(audiofile, build(audiofileValidators), context, env);
}

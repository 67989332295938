import type { PublishHelper } from "../types";
import type { CategoryTaxonomyFields } from "@types";

/**
 * Get the primary channel or section. This is the direct descendant
 * of the channel or section root for the primary category
 *
 * Ex: if the primary category is /channels/gear/drones
 * then the primary channel is "gear"
 *
 * @param model
 * @returns string | null
 */
export const primaryChannel: PublishHelper = (model) => {
  const categoryTaxonomies = model["categoryTaxonomies"] as
    | CategoryTaxonomyFields[]
    | null;
  const taxonomy =
    categoryTaxonomies?.find(({ taxonomy }) => taxonomy === "sections") ??
    categoryTaxonomies?.find(({ taxonomy }) => taxonomy === "channels");

  const hierarchy = taxonomy?.categories?.[0]?.hierarchy;
  const primaryCategory = hierarchy?.[hierarchy.length - 2];
  return primaryCategory?.slug ?? null;
};

import { Button, Spinner } from "@condenast/gemini";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const StyledButton = styled(Button)`
  margin-top: var(--spacing-md);
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface SaveButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean;
}

export function SaveButton({ loading, ...forwardProps }: SaveButtonProps) {
  return (
    <StyledButton treatment="primary" {...forwardProps}>
      {loading ? (
        <Spinner size="small" />
      ) : (
        <FormattedMessage defaultMessage="Save" />
      )}
    </StyledButton>
  );
}

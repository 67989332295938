import { useState, useMemo, memo } from "react";
import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  NumberedListAscendingIcon,
} from "@condenast/gemini/icons";
import {
  FormFor_form_controls_BundleContainersFormControl_configs as BundleContainersFormControlConfigs,
  FormFor_form_controls_BundleContainersFormControl_configs_containers as BundleContainerConfig,
} from "@types";
import { Button } from "@components";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import BundleOverviewTakeover from "./BundleOverviewTakeover";
import AddContainersTakeover from "./AddContainersTakeover";
import { KeyedContainer } from "../../types";

const ActionsBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-xs);
`;

const AddButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
  margin-left: auto;
`;

const CollapseToggleButton = styled(Button)`
  display: flex;
  color: ${(props) => props.theme.SecondaryColor};
`;

const OverviewButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
`;

function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export const BundleContainersActions = memo(function (props: {
  configs: BundleContainersFormControlConfigs | null;
  bundleType: string;
  containers: KeyedContainer[];
  addContainer: (containerConfig: BundleContainerConfig) => void;
  deleteContainer: (containerKey: string) => void;
  moveContainer: (sourceIndex: number, destinationIndex: number) => void;
  setContainerKeyToScrollTo: (key: string | null) => void;
  collapseAllContainers: () => void;
  expandAllContainers: () => void;
  areAllContainersCollapsed?: boolean;
  scrollToMainFieldSet: () => void;
  mainFieldSetSummary: {
    hed: string;
    dek: string;
  };
}) {
  let {
    configs,
    bundleType,
    containers,
    addContainer,
    deleteContainer,
    moveContainer,
    setContainerKeyToScrollTo,
    collapseAllContainers,
    expandAllContainers,
    areAllContainersCollapsed,
    mainFieldSetSummary,
    scrollToMainFieldSet,
  } = props;

  const [bundleOverviewTakeoverOpen, setBundleOverviewTakeoverOpen] =
    useState(false);
  const [addContainerTakeoverOpen, setAddContainerakeoverOpen] =
    useState(false);

  const configuredContainers = useMemo(
    () =>
      configs?.bundleTypeConfigs
        ?.find((bundleTypeConfig) => bundleTypeConfig.type === bundleType)
        ?.containers?.map((containerName) =>
          configs?.containers?.find(
            (containerConfig) => containerConfig.value === containerName
          )
        )
        ?.filter(isDefined),
    [configs?.bundleTypeConfigs, configs?.containers, bundleType]
  );

  return (
    <>
      {bundleOverviewTakeoverOpen && (
        <BundleOverviewTakeover
          configs={configs}
          containers={containers}
          moveContainer={moveContainer}
          deleteContainer={deleteContainer}
          setContainerKeyToScrollTo={setContainerKeyToScrollTo}
          scrollToMainFieldSet={scrollToMainFieldSet}
          mainFieldSetSummary={mainFieldSetSummary}
          onClose={() => setBundleOverviewTakeoverOpen(false)}
        />
      )}
      {addContainerTakeoverOpen && (
        <AddContainersTakeover
          allowedContainers={configuredContainers}
          addContainer={addContainer}
          onClose={() => setAddContainerakeoverOpen(false)}
        />
      )}
      <ActionsBlock>
        <OverviewButton
          disabled={!containers.length}
          onClick={() => {
            setBundleOverviewTakeoverOpen(true);
          }}
        >
          <NumberedListAscendingIcon size="regular" />
          <FormattedMessage defaultMessage="Overview" />
        </OverviewButton>
        <CollapseToggleButton
          treatment="borderless"
          size="medium"
          disabled={!containers.length}
          onClick={
            areAllContainersCollapsed
              ? expandAllContainers
              : collapseAllContainers
          }
        >
          {areAllContainersCollapsed ? (
            <>
              <ChevronDownIcon size="regular" />
              <FormattedMessage
                defaultMessage="Expand all"
                description="Expand all items"
              />
            </>
          ) : (
            <>
              <ChevronUpIcon size="regular" />
              <FormattedMessage
                defaultMessage="Collapse all"
                description="Collapse all items"
              />
            </>
          )}
        </CollapseToggleButton>
        <AddButton
          size="medium"
          onClick={() => {
            setAddContainerakeoverOpen(true);
          }}
        >
          <AddIcon size="regular" />
          <FormattedMessage
            defaultMessage="Add Container"
            description="Add a container"
          />
        </AddButton>
      </ActionsBlock>
    </>
  );
});

BundleContainersActions.displayName = "BundleContainersActions";

import { useMemo, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ARIA,
  Button,
  Card,
  Title,
  Page,
  Select,
  Link,
  Toast,
  Toggle,
  TextField,
  ServerError,
  Spinner,
  ValidationSummary,
} from "@components";
import {
  useChangeset,
  useToast,
  useDefinedMessages,
  useUserAccessControlMatrix,
} from "@hooks";
import {
  GetCurrentUser_currentUser as AuthenticatedUser,
  GetUserById,
  GetUserById_user,
  GetUserByIdVariables,
  UserRole,
  UserInput,
  UserStatus,
  updateUser,
  SendEmailNotification,
  UserLoginProvider,
  Organization,
} from "@types";
import styled from "styled-components";
import { compareBrandOptions } from "../utils";
import { ValidationError } from "@condenast/cross-check";
import { Queries, Mutations } from "@gql";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { ThemeProvider } from "@contexts";
import * as Sentry from "@sentry/browser";
import { UserStatusChip } from "../../UserStatusChip";
import { UserEmailTakenError } from "../UserEmailTakenError";
import { validateUser, formPropsGetter } from "@lib";
import { ErrorPage } from "../../../ErrorPage";

const UserName = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0 var(--spacing-xs);
  grid-template-areas: "name chip";
  align-items: center;
`;

const Header = styled.div`
  grid-area: head;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, auto);
  gap: var(--spacing-sm);
`;

const Form = styled.form`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto;
  grid-template-areas: "head" "body";
  gap: var(--spacing-md);
  padding-top: var(--spacing-sm);
  margin: 0 auto;
  max-width: ${(props) => props.theme.NarrowCardSize};
  width: 100%;

  ${Card} {
    grid-area: body;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-sm);
`;

const FormControls = styled.footer`
  display: flex;
  justify-content: space-between;
  padding-top: var(--spacing-md);
  & button + button {
    margin-left: var(--spacing-xs);
  }
`;

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const UserEdit = (props: {
  currentOrganization: Organization;
  currentUser: AuthenticatedUser;
}) => {
  let { currentUser, currentOrganization } = props;
  const { userId } = useParams() as { userId: string };
  const {
    data,
    loading: getUserLoading,
    error: getUserError,
  } = useQuery<GetUserById, GetUserByIdVariables>(Queries.GET_USER_BY_ID, {
    variables: {
      userId: userId,
      organizationId:
        currentUser.role === UserRole.superadmin
          ? undefined
          : currentOrganization.organizationId,
    },
  });

  // A 403 indicates that the user is restricted from reading the requested user
  const isRestricted = getUserError?.graphQLErrors?.some(
    (error) => error.extensions?.response?.code === 403
  );
  const [resendInviteEmail, { error: resendInviteEmailError }] =
    useMutation<SendEmailNotification>(Mutations.SEND_NOTIFICATION_EMAIL);

  const [deactivateUser, { error: deactivateError }] = useMutation(
    Mutations.DEACTIVATE_USER
  );
  const [activateUser, { error: activateError }] = useMutation(
    Mutations.ACTIVATE_USER
  );
  const [resetTotp, { error: resetTotpError }] = useMutation(
    Mutations.RESET_TOTP
  );

  const [updateUser, { error: updateUserError, loading }] =
    useMutation<updateUser>(Mutations.UPDATE_USER);

  let requestError =
    updateUserError ||
    activateError ||
    deactivateError ||
    resendInviteEmailError ||
    resetTotpError;

  if (requestError) {
    Sentry.captureException(requestError);
  }

  const permissions = useUserAccessControlMatrix(
    props.currentOrganization,
    "update",
    Object.values(UserRole)
  );
  const readOnly = data?.user
    ? permissions[data.user.role].update === false
    : false;

  return isRestricted ? (
    <Navigate
      replace
      to={`/${currentOrganization.metadata.copilotCode}/users/profile`}
    />
  ) : data?.user ? (
    <UserEditPage
      user={data.user}
      currentOrganization={currentOrganization}
      readOnly={readOnly}
      requestError={requestError}
      currentUser={currentUser}
      onActivateUser={() => activateUser({ variables: { userId } })}
      onDeactivateUser={() => deactivateUser({ variables: { userId } })}
      onResendInvite={() => resendInviteEmail({ variables: { userId } })}
      onResetTotp={() => resetTotp({ variables: { userId } })}
      loading={loading}
      onSubmit={(data) => {
        if (Object.keys(data).length) {
          return updateUser({
            variables: { userId, user: data },
          });
        }
        return Promise.resolve({});
      }}
    />
  ) : getUserLoading ? (
    <SpinnerWrapper>
      <Spinner size="large" />
    </SpinnerWrapper>
  ) : (
    <ErrorPage />
  );
};

export const UserEditPage = (props: {
  user: GetUserById_user;
  currentOrganization: Organization;
  requestError: ApolloError | undefined;
  loading: boolean;
  currentUser: AuthenticatedUser;
  readOnly?: boolean;
  onResendInvite: () => Promise<unknown>;
  onActivateUser: () => Promise<unknown>;
  onDeactivateUser: () => Promise<unknown>;
  onResetTotp: () => Promise<unknown>;
  onSubmit: (data: UserInput) => Promise<{
    data?: updateUser;
  }>;
}) => {
  let intl = useIntl();
  let {
    currentOrganization,
    requestError,
    onResendInvite,
    onActivateUser,
    onDeactivateUser,
    onResetTotp,
    readOnly,
    currentUser,
  } = props;
  let [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  const userData = useMemo(
    () => ({
      ...props.user,
      brandCodes: props.user.organizations.map(
        (org) => org.metadata.copilotCode
      ),
    }),
    [props.user]
  );
  let [user, setValue, { changes }] = useChangeset(userData);
  let [totpModalOpen, setTotpModalOpen] = useState(false);
  let {
    translateFieldName,
    translateRoleName,
    translateFieldError,
    translateSummaryErrors,
  } = useDefinedMessages();

  const showSaveSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Saved",
    }),
  });

  let formPropsForField = useMemo(
    () =>
      formPropsGetter(
        user,
        validationErrors,
        setValue,
        translateFieldName,
        translateFieldError
      ),
    [user, validationErrors, setValue, translateFieldName, translateFieldError]
  );
  let organizations = [
    ...props.currentUser.organizations,
    ...props.user.organizations,
  ].reduce((deduped, org) => {
    if (!deduped.some((o) => o.organizationId === org.organizationId)) {
      return [...deduped, org];
    }
    return deduped;
  }, [] as Organization[]);

  let displayName = `${props.user.firstName} ${props.user.lastName}`;

  const createableRoles = useUserAccessControlMatrix(
    props.currentOrganization,
    "create",
    Object.values(UserRole)
  );
  const roleOptions = useMemo(
    () =>
      Object.values(UserRole)
        .filter((role) => createableRoles[role]?.create)
        .map((role) => ({
          label: translateRoleName(role),
          value: role,
        })),
    [createableRoles, translateRoleName]
  );
  let resendInviteToast = useToast({
    type: "success",
    children: intl.formatMessage(
      {
        defaultMessage: "An invitation email has been sent to {email}.",
      },
      { email: props.user.email }
    ),
  });

  let resetTotpSuccessToast = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "User's two factor authentication has been reset.",
    }),
  });

  return (
    <Page title={displayName}>
      {totpModalOpen && (
        <ARIA.AlertDialog
          size="narrow"
          title={
            <FormattedMessage
              defaultMessage="Reset 2FA"
              description="Button to reset the user's two-factor authentication, abbreviated as '2FA' (also known as a time-based one-time password)"
            />
          }
          description={
            <FormattedMessage
              defaultMessage={
                "The user will be unable to log in until they finish resetting their two-factor authentication (2FA) credentials. An email will be sent with instructions for them to complete the process."
              }
            />
          }
          onClose={() => setTotpModalOpen(false)}
          cancelButton={
            <Button>
              <FormattedMessage defaultMessage={"Cancel"} />
            </Button>
          }
          submitButton={
            <Button
              type="submit"
              treatment="primary"
              onClick={() => {
                onResetTotp().then(resetTotpSuccessToast);
                setTotpModalOpen(false);
              }}
            >
              <FormattedMessage defaultMessage={"Reset"} />
            </Button>
          }
        />
      )}
      <Form
        noValidate
        onSubmit={(evt) => {
          evt.preventDefault();
          validateUser(user).then((validationErrors) => {
            setValidationErrors(validationErrors);
            if (validationErrors.length === 0) {
              props.onSubmit(changes).then((result) => {
                if (result.data) {
                  showSaveSuccessMessage();
                }
              });
            }
          });
        }}
      >
        <Header>
          <Title
            breadcrumbs={[
              <Link
                to={`/${currentOrganization.metadata.copilotCode}/users`}
                key="my-team"
              >
                <FormattedMessage defaultMessage="My Team" />
              </Link>,
            ]}
          >
            <UserName>
              {displayName}
              <UserStatusChip status={user.status} />
            </UserName>
          </Title>
          {validationErrors.length > 0 && (
            <ValidationSummary
              errors={translateSummaryErrors(validationErrors)}
            />
          )}
          {props.user.status === UserStatus.pending && (
            <Toast
              type="warning"
              action={
                <Link
                  as="button"
                  type="button"
                  onClick={() => {
                    onResendInvite().then(() => {
                      resendInviteToast();
                    });
                  }}
                >
                  <FormattedMessage defaultMessage="Resend invite" />
                </Link>
              }
            >
              <FormattedMessage
                defaultMessage="{name} has not yet accepted their Copilot invite."
                values={{ name: displayName }}
              />
            </Toast>
          )}
          {requestError && (
            <ServerError
              error={requestError}
              responses={{ USER_EMAIL_TAKEN: UserEmailTakenError }}
            />
          )}
        </Header>

        <Card size="narrow">
          <Row>
            <TextField
              {...formPropsForField("firstName")}
              multiline={false}
              readOnly={readOnly}
            />
            <TextField
              {...formPropsForField("lastName")}
              multiline={false}
              readOnly={readOnly}
            />
          </Row>
          <TextField
            {...formPropsForField("email")}
            multiline={false}
            type="email"
            readOnly={readOnly}
          />
          <Select
            {...formPropsForField("role")}
            onChange={(value: string | undefined) => {
              setValue("role", value as UserRole);

              // clears out brands when user selects Super Admin
              if (value === UserRole.superadmin) {
                setValue("brandCodes", []);
              }
            }}
            options={roleOptions}
            disabled={readOnly}
          />
          {user.role !== UserRole.superadmin && (
            <Select
              {...formPropsForField("brandCodes")}
              multiple={true}
              placeholder={intl.formatMessage({
                defaultMessage: "Select brands",
              })}
              options={organizations
                .map((organization) => {
                  return {
                    label: organization.internalDisplayName,
                    value: organization.metadata.copilotCode,
                    disabled: !currentUser.organizations.some(
                      (org) =>
                        org.organizationId === organization.organizationId
                    ),
                  };
                })
                .sort(compareBrandOptions)}
              label={intl.formatMessage({
                defaultMessage: "Brands",
                description: "User brands label",
              })}
            />
          )}
          <Toggle
            {...formPropsForField("loginProvider")}
            value={user.loginProvider === UserLoginProvider.okta}
            onChange={(value: any) => {
              setValue(
                "loginProvider",
                value ? UserLoginProvider.okta : UserLoginProvider.copilot
              );
            }}
            message="Requires a Condé Nast or brand email address"
            disabled={readOnly}
          />
          {!readOnly && (
            <FormControls>
              {user.status === UserStatus.deactivated ? (
                <Button onClick={onActivateUser}>
                  <FormattedMessage defaultMessage="Reactivate" />
                </Button>
              ) : (
                <ThemeProvider tint="red">
                  <Button onClick={onDeactivateUser}>
                    <FormattedMessage defaultMessage="Deactivate" />
                  </Button>
                </ThemeProvider>
              )}
              <div>
                <Button
                  disabled={user.loginProvider === UserLoginProvider.okta}
                  onClick={() => setTotpModalOpen(true)}
                >
                  <FormattedMessage
                    defaultMessage="Reset 2FA"
                    description="Button to reset the user's two-factor authentication, abbreviated as '2FA' (also known as a time-based one-time password)"
                  />
                </Button>
                <Button
                  treatment="primary"
                  type="submit"
                  disabled={Object.keys(changes).length === 0}
                >
                  <FormattedMessage defaultMessage="Save" />
                </Button>
              </div>
            </FormControls>
          )}
        </Card>
      </Form>
    </Page>
  );
};
UserEditPage.displayName = "UserEditPage";

import { TextField as TextFieldImplementation, Button } from "@components";
import { WandIcon, AnimatedEllipsisIcon } from "@condenast/gemini/icons";
import { useDefinedMessages, useToast } from "@hooks";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { Queries, Mutations } from "@gql";
import {
  ControlProps,
  GetBrand,
  GetBrandVariables,
  FormFor_form_controls_OfferAutofillFormControl,
  GetProductAutofillData,
  ProductFields_photosTout_ContentConnection as PhotosTout,
  UploadMedia,
  UploadMediaVariables,
} from "@types";
import styled from "styled-components";
import { useCallback, useMemo, useContext } from "react";
import { get } from "@lib";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { MainModelContext } from "@contexts";
import { fetch } from "cross-fetch";
const DEFAULT_NAME_SUBSTRING = "UNTITLED /";

const ButtonWrapper = styled.div<{ $treatment?: string }>`
  display: flex;
  justify-content: end;
`;
const fetchImageFile = async (url: string, fileName: string) => {
  try {
    let response = await fetch(url);
    if (response.ok) {
      let arrayBuffer = await response.arrayBuffer();
      let byteArray = new Uint8Array(arrayBuffer);
      return new File([byteArray], fileName, {
        type: "image/jpeg",
      });
    }
  } catch (err) {
    return null;
  }
  return null;
};
export function OfferAutofill(
  props: ControlProps<FormFor_form_controls_OfferAutofillFormControl>
) {
  const intl = useIntl();
  const { mainModel, setMainModelValue } = useContext(MainModelContext);
  const { name, model, setValue, errors, currentOrganization, currentUser } =
    props;

  const [upload] = useMutation<UploadMedia, UploadMediaVariables>(
    Mutations.UPLOAD_MEDIA
  );

  const { translateFieldName } = useDefinedMessages();

  const id = get(mainModel, "id") as string;
  const photosTout = get(mainModel, "photosTout") as PhotosTout | null;
  const nameFieldFromMainModel = get(mainModel, "name") as string | null;

  const label = translateFieldName(name);
  const value = get(model, name) as string | null;
  const offerLink = get(model, "offerLink") as string | null;
  let {
    data: brand,
    loading: brandConfigsLoading,
    error: brandConfigsError,
  } = useQuery<GetBrand, GetBrandVariables>(Queries.GET_BRAND, {
    variables: {
      organizationId: currentOrganization.organizationId,
    },
  });

  const autofillErrorToast = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Error during product autofill.",
    }),
  });

  const autofillSuccessToast = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Product successfully autofilled.",
    }),
  });

  const showAutofillButton = useMemo(() => {
    if (brandConfigsLoading || brandConfigsError || !brand || !offerLink) {
      return false;
    }
    let offerAutofillDomains =
      brand.brandConfiguration?.commerce?.offerAutofillDomains;
    try {
      let url = new URL(offerLink);
      return (
        mainModel &&
        offerLink &&
        offerAutofillDomains &&
        offerAutofillDomains.length > 0 &&
        (offerAutofillDomains.indexOf("*") === 0 ||
          offerAutofillDomains.indexOf(url.host.replace("www.", "")) > -1)
      );
    } catch (e) {
      return false;
    }
  }, [mainModel, brandConfigsLoading, brandConfigsError, brand, offerLink]);

  const [getProductAutofillData, { loading: getProductAutofillQueryLoading }] =
    useLazyQuery<GetProductAutofillData>(Queries.GET_PRODUCT_AUTOFILL_DATA, {
      fetchPolicy: "network-only",
      onCompleted: async (data) => {
        if (!data.getProductAutofillData) {
          autofillErrorToast();
          return;
        }

        let { price, salePrice, isOutOfStock, retailerName, siteImage, name } =
          data.getProductAutofillData;
        setValue("autoUpdate", false);
        retailerName !== null && setValue("sellerName", retailerName);
        salePrice !== null &&
          setValue("comparisonPrice", Math.round(salePrice));
        let priceToAutofill = price ?? salePrice;
        priceToAutofill !== null &&
          setValue("price", Math.round(priceToAutofill));
        isOutOfStock !== null && setValue("isOutOfStock", isOutOfStock);

        //fill out fields on the main product model
        if (setMainModelValue && siteImage) {
          let shouldAutofillName = nameFieldFromMainModel
            ?.toUpperCase()
            .startsWith(DEFAULT_NAME_SUBSTRING);
          shouldAutofillName && setMainModelValue("name", name);

          let fileName = /[^/]*$/.exec(siteImage)?.[0];
          let shouldAutofillPhotosTout =
            siteImage && fileName && photosTout?.edges.length === 0;

          if (shouldAutofillPhotosTout && fileName) {
            let file = await fetchImageFile(siteImage, fileName);
            let createPhotoResponse =
              file?.size &&
              (await upload({
                variables: {
                  organizationId: currentOrganization.organizationId,
                  authorName: `${currentUser.firstName} ${currentUser.lastName}`,
                  data: { file, fileSize: file.size },
                },
              }));
            createPhotoResponse &&
              createPhotoResponse.data &&
              setMainModelValue("photosTout", {
                __typename: "ContentConnection",
                edges: [
                  {
                    __typename: "ContentToContentEdge",
                    node: {
                      ...createPhotoResponse.data.uploadMedia,
                      __typename: "ContentSummary",
                    },
                  },
                ],
              });
          }
        }

        autofillSuccessToast();
      },
      onError: () => {
        autofillErrorToast();
      },
    });

  const onChange = useCallback(
    (newValue) => {
      setValue(name, newValue);
    },
    [name, setValue]
  );

  const autofill = useCallback(async () => {
    getProductAutofillData({
      variables: {
        organizationId: currentOrganization.organizationId,
        productId: id,
        offerUrls: [offerLink],
      },
    });
  }, [
    id,
    currentOrganization.organizationId,
    offerLink,
    getProductAutofillData,
  ]);

  return (
    <>
      <TextFieldImplementation
        id={`OfferAutofill__${name}`}
        label={label}
        aria-label={label}
        value={value}
        errors={errors}
        multiline={false}
        onChange={onChange}
      />
      {showAutofillButton && (
        <ButtonWrapper>
          <Button
            treatment="primary"
            onClick={autofill}
            disabled={getProductAutofillQueryLoading}
          >
            {getProductAutofillQueryLoading ? (
              <AnimatedEllipsisIcon size="small" />
            ) : (
              <WandIcon size="regular" />
            )}

            <FormattedMessage defaultMessage="Autofill" />
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
}

OfferAutofill.displayName = "Control(OfferAutofill)";

import { useState, useCallback } from "react";
import styled from "styled-components";
import { ARIA } from "@condenast/gemini";
import { CustomDateRangePicker } from "./CustomRange";
import { usePopper } from "react-popper";
import { CheckIcon } from "@condenast/gemini/icons";
import { useMediaQuery } from "@hooks";
import {
  addDays,
  subDays,
  addHours,
  subHours,
  startOfDay,
  endOfDay,
} from "date-fns";

const Header = styled.div`
  display: grid;
  gap: var(--spacing-xxs);
  padding-bottom: var(--spacing-xs);
  grid-template-columns: 8fr 2fr;
`;

const FilterWrapper = styled.div`
  display: flex;
  background: ${(props) => props.theme.Background};
  gap: var(--spacing-xxs);
  align-items: stretch;
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.5),
    5px 0 5px -5px rgba(0, 0, 0, 0.5), 0 5px 5px -5px rgba(0, 0, 0, 0.5);
  @media (max-width: 650px) {
    padding-bottom: var(--spacing-xs);
  }
`;

const DateMenu = styled(ARIA.Menu)`
  & ~ [role="menu"] {
    max-height: 398px;
    overflow-y: auto;
  }
  background: ${(props) => props.theme.Background};
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
  font-weight: 400;
  font-size: ${(props) => props.theme.FontStatement};
  line-height: 24px;
  box-shadow: none;
  border-radius: 4px;
`;

const CustomRangeWrapper = styled.div`
  background: ${(props) => props.theme.Background};
  padding-right: 16px;
  padding-left: 16px;
  color: ${(props) => props.theme.Color};
  border-radius: 4px;
  border-left: 1px solid ${(props) => props.theme.DividerColor};
`;

const Title = styled.h2`
  color: ${(props) => props.theme.Color};
  font: ${(props) => props.theme.FontSubSectionHeading};
  padding: var(--spacing-xs);
  font-size: 18px;
`;

const CustomLabel = styled.div`
  display: grid;
  gap: var(--spacing-xxs);
  padding-top: var(--spacing-s);
  grid-template-columns: 9fr 1fr;
`;

export function CustomDateMenu(props: {
  handleDateChange: (start: Date, end: Date, type: string) => void;
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
  setShowPopper: (popper: boolean) => void;
  label: string;
  setLabel: (label: string) => void;
}) {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setShowPopper,
    setLabel,
    handleDateChange,
    ...menu
  } = props;

  const [showDatePicker, setshowDatePicker] = useState(false);
  let isMobile = useMediaQuery("(max-width: 650px)");

  const [buttonElement, setButtonElement] = useState<HTMLElement | null>(null);
  const [menuElement, setMenuElement] = useState<HTMLInputElement | null>(null);
  const [isPopperPositioned, setPopperPositioned] = useState<boolean>(false);

  const onPopperFirstUpdate = useCallback(() => {
    setPopperPositioned(true);
  }, [setPopperPositioned]);

  let { styles, attributes } = usePopper(buttonElement, menuElement, {
    onFirstUpdate: onPopperFirstUpdate,
  });

  return (
    <>
      <Header>
        <FilterWrapper>
          {isMobile && showDatePicker ? null : (
            <DateMenu
              {...menu}
              ref={setButtonElement}
              id="date"
              items={[
                {
                  role: "action",
                  value: showDatePicker ? (
                    <CustomLabel>
                      {"Custom Range"}{" "}
                      <CheckIcon size={"small"} color={"blue"} />
                    </CustomLabel>
                  ) : (
                    "Custom Range"
                  ),
                  onClick: () => {
                    setshowDatePicker(true);
                    setShowPopper(!showDatePicker);
                  },
                },
                {
                  role: "action",
                  value: "Today",
                  onClick: () => {
                    const newStartDate = startOfDay(new Date());
                    const newEndDate = endOfDay(new Date());
                    setStartDate(startOfDay(new Date()));
                    setEndDate(endOfDay(new Date()));
                    setShowPopper(false);
                    handleDateChange(newStartDate, newEndDate, "Today");
                    setLabel(newStartDate.toDateString());
                  },
                },
                {
                  role: "action",
                  value: "Next Hour",
                  onClick: () => {
                    setStartDate(new Date());
                    setEndDate(addHours(new Date(), 1));
                    handleDateChange(
                      new Date(),
                      addHours(new Date(), 1),
                      "Next Hour"
                    );
                    setShowPopper(false);
                    setLabel("Next Hour");
                  },
                },
                {
                  role: "action",
                  value: "Past Hour",
                  onClick: () => {
                    setStartDate(subHours(new Date(), 1));
                    setEndDate(new Date());
                    handleDateChange(
                      subHours(new Date(), 1),
                      new Date(),
                      "Past Hour"
                    );
                    setShowPopper(false);
                    setLabel("Past Hour");
                  },
                },
                {
                  role: "action",
                  value: "Next 24 Hours",
                  onClick: () => {
                    setStartDate(new Date());
                    setEndDate(addDays(new Date(), 1));
                    handleDateChange(
                      new Date(),
                      addDays(new Date(), 1),
                      "Next 24 Hours"
                    );
                    setShowPopper(false);
                    setLabel("Next 24 Hours");
                  },
                },
                {
                  role: "action",
                  value: "Past 24 Hours",
                  onClick: () => {
                    setStartDate(subDays(new Date(), 1));
                    setEndDate(new Date());
                    handleDateChange(
                      subDays(new Date(), 1),
                      new Date(),
                      "Past 24 Hours"
                    );
                    setShowPopper(false);
                    setLabel("Past 24 Hours");
                  },
                },
                {
                  role: "action",
                  value: "Next 7 Days",
                  onClick: () => {
                    setStartDate(new Date());
                    setEndDate(addDays(new Date(), 7));
                    handleDateChange(
                      new Date(),
                      addDays(new Date(), 7),
                      "Next 7 Days"
                    );
                    setShowPopper(false);
                    setLabel("Next 7 Days");
                  },
                },
                {
                  role: "action",
                  value: "Past 7 Days",
                  onClick: () => {
                    setStartDate(subDays(new Date(), 7));
                    setEndDate(new Date());
                    handleDateChange(
                      subDays(new Date(), 7),
                      new Date(),
                      "Past 7 Days"
                    );
                    setShowPopper(false);
                    setLabel("Past 7 Days");
                  },
                },
              ]}
              autofocus={isPopperPositioned}
              {...attributes.popper}
            >
              {(item: any) => item}
            </DateMenu>
          )}

          {showDatePicker && (
            <CustomRangeWrapper>
              <Title>Custom Range</Title>
              <CustomDateRangePicker
                selectsRange={[startDate, endDate]}
                ref={setMenuElement}
                style={styles.popper}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setShowPopper={setShowPopper}
                setLabel={setLabel}
                handleDateChange={handleDateChange}
              ></CustomDateRangePicker>
            </CustomRangeWrapper>
          )}
        </FilterWrapper>
      </Header>
    </>
  );
}
CustomDateMenu.displayName = "CustomDateMenu";

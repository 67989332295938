import { get } from "@lib";
import * as helpers from "../../../../../../lib/computeAutogen";

function helperNameToHookName(helperName: string) {
  return "use" + helperName.charAt(0).toUpperCase() + helperName.slice(1);
}

function hasHelper(helperName: string): helperName is keyof typeof helpers {
  return helperName in helpers;
}

function helperLookup(model: Record<string, unknown>, helperName: string) {
  let name = helperNameToHookName(helperName);
  return hasHelper(name) ? helpers[name](model) : null;
}

export function autogenerate(
  autogeneratePath: string | null,
  autogenerateConfig: string | null,
  model: Record<string, unknown>
): string | null {
  let modelValue;
  if (autogenerateConfig) {
    const [, helperName] = autogenerateConfig.split(/[@$]/) ?? [];
    modelValue = helperLookup(model, helperName) as string | null;
  } else {
    modelValue = autogeneratePath
      ? (get(model, autogeneratePath) as string)
      : null;
  }
  return modelValue;
}

import type { ComponentType } from "react";
import styled from "styled-components";
import { ARIA } from "@components";
import { Rect, Placement } from "@popperjs/core";
import { useIntl } from "react-intl";

const Container = styled.div<{ $environment: string | undefined }>`
  position: relative;

  svg {
    width: var(--spacing-md);
    height: var(--spacing-md);
    filter: ${(props) =>
      props.$environment === "production" ? "grayscale(0)" : "grayscale(1)"};
  }

  & > ul {
    border-radius: 0 0 ${(props) => props.theme.CornerRadius} 0;
  }

  [role="menu"] {
    min-width: auto;
  }
`;

const ToolSwitcherButton = styled(ARIA.MenuButton)`
  &:not(:disabled):focus {
    box-shadow: ${(props) => props.theme.FocusRing};
  }

  border-radius: 0;
  width: calc(var(--spacing-sm) * 3.5);
  height: calc(var(--spacing-sm) * 3.5);
  background: transparent;
  padding: var(--spacing-sm);

  svg {
    left: auto;
  }

  &:not(:disabled):hover {
    background: ${(props) => props.theme.BorderlessHoverBackground};
    cursor: pointer;
  }

  &:not(:disabled):active {
    background: ${(props) => props.theme.BorderlessActiveBackground};
    color: ${(props) => props.theme.SecondaryActiveColor};
  }
` as typeof ARIA.MenuButton;

const ToolItem = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  gap: var(--spacing-sm);
  align-items: center;
  padding-right: var(--spacing-md);
`;

function placeOverButton(props: {
  popper: Rect;
  reference: Rect;
  placement: Placement;
}): [number | null | undefined, number | null | undefined] {
  return [0, -1 * props.reference.height];
}

export function ToolSwitcherMenuButton<
  Tool extends {
    name: string;
    href: string;
    icon: ComponentType;
  }
>(props: {
  tool: Tool["name"];
  tools: Tool[];
  environment?: string;
  className?: string;
}) {
  let intl = useIntl();
  let currentTool = props.tools.find((tool) => tool.name === props.tool);
  if (currentTool == null) {
    throw new Error(`${props.tool} is not a valid tool name.`);
  }

  let otherTools = props.tools.filter((tool) => tool !== currentTool);

  return (
    <Container $environment={props.environment} className={props.className}>
      <ToolSwitcherButton
        id="tool-switcher"
        aria-label={intl.formatMessage({
          defaultMessage: "Switch tools",
        })}
        popper={{
          placement: "bottom-start",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: placeOverButton,
              },
            },
            {
              name: "preventOverflow",
              options: {
                padding: 0,
              },
            },
          ],
        }}
        menu={{
          theme: "dark",
          items: [
            {
              role: "action",
              onClick() {},
              value: currentTool,
            } as const,
            ...otherTools.map(
              (tool) =>
                ({
                  role: "link",
                  href: tool.href,
                  rel: "noreferrer noopener",
                  value: tool,
                } as const)
            ),
          ],
          onSearch(tools, query) {
            return tools.find(
              (tool) =>
                tool.name.toLowerCase().indexOf(query.toLowerCase()) === 0
            );
          },
          children(tool) {
            return (
              <ToolItem>
                <tool.icon aria-hidden={true} />
                <span>{tool.name}</span>
              </ToolItem>
            );
          },
        }}
      >
        <currentTool.icon />
      </ToolSwitcherButton>
    </Container>
  );
}
ToolSwitcherMenuButton.displayName = "ToolSwitcherMenuButton";

import styled from "styled-components";
import { memo, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { ARIA, Button } from "@components";
import { ContainerHeaderProps } from "../types";
import { ContainerConfigTakeover, BundleContainerSummary } from "./";
import { SettingsIcon, EllipsisIcon } from "@condenast/gemini/icons";
import { getContainerSummary } from "../utils";

type ContainerHeaderMenuItem = {
  role: "action";
  onClick: () => void;
  value: string;
};

const ContainerName = styled.span`
  font: var(--font-subhed);
`;

const ContainerHeaderWrapper = styled.div`
  display: flex;
  text-align: right;
  align-items: center;
  padding: var(--spacing-sm);
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
`;

const ContainerActions = styled.div`
  align-items: center;
  display: flex;
  gap: var(--spacing-xs);
`;

const MenuIcon = styled(EllipsisIcon)`
  color: ${(props) => props.theme.Color};
`;

const MenuButton = styled(ARIA.MenuButton)`
  border: 1px solid ${(props) => props.theme.SelectButtonHoverBackgroundColor};
  background: ${(props) => props.theme.Background};
`;

const ContainerHeader = memo(function (props: ContainerHeaderProps) {
  const {
    containerData,
    configs,
    deleteContainer,
    duplicateContainer,
    toggleField,
    resetToInitial,
    fieldStates,
    hasChanges,
    containerIndex,
    updateContainerByIndex,
    isCollapsed,
    onBatchEdit,
  } = props;
  const intl = useIntl();
  const [containerConfigTakeoverOpen, setContainerConfigTakeoverOpen] =
    useState(false);
  const containerName =
    configs?.containers?.find(
      (config) => config.value === containerData.curationContainerType
    )?.label || containerData.curationContainerType;

  const curations = useMemo(
    () => containerData?.curations?.edges || [],
    [containerData?.curations?.edges]
  );

  const containerHeaderMenuItems = useMemo(() => {
    let menuItems: ContainerHeaderMenuItem[] = [];

    menuItems.push({
      role: "action",
      onClick: () => {
        deleteContainer(containerData.containerKey);
      },
      value: intl.formatMessage({
        defaultMessage: "Delete",
      }),
    });

    menuItems.push({
      role: "action",
      onClick: () => {
        duplicateContainer(containerData.containerKey);
      },
      value: intl.formatMessage({
        defaultMessage: "Duplicate",
      }),
    });

    if (onBatchEdit && curations.length) {
      menuItems.push({
        role: "action",
        onClick: () => {
          onBatchEdit(curations);
        },
        value: intl.formatMessage({
          defaultMessage: "Override Content",
        }),
      });
    }
    return menuItems;
  }, [
    intl,
    deleteContainer,
    duplicateContainer,
    containerData.containerKey,
    onBatchEdit,
    curations,
  ]);

  return (
    <>
      {containerConfigTakeoverOpen && (
        <ContainerConfigTakeover
          containerData={containerData}
          containerIndex={containerIndex}
          updateContainerByIndex={updateContainerByIndex}
          configs={configs}
          toggleField={toggleField}
          resetToInitial={resetToInitial}
          fieldStates={fieldStates}
          hasChanges={hasChanges}
          onClose={() => {
            setContainerConfigTakeoverOpen(false);
          }}
        />
      )}
      <ContainerHeaderWrapper>
        <ContainerName>{containerName}</ContainerName>
        <ContainerActions>
          {isCollapsed && (
            <BundleContainerSummary>
              {getContainerSummary(props.containerData)}
            </BundleContainerSummary>
          )}

          <Button
            aria-label={intl.formatMessage({
              defaultMessage: "Configure Container",
              description: "Button used to Configure Container",
            })}
            onClick={() => {
              setContainerConfigTakeoverOpen(true);
            }}
          >
            <SettingsIcon size="regular" />
          </Button>
          <MenuButton
            treatment="secondary"
            aria-label={intl.formatMessage({
              defaultMessage: "Actions menu",
              description: "Container Actions menu",
            })}
            menu={{
              theme: "light",
              items: containerHeaderMenuItems,
              children: (item: string) => item,
            }}
          >
            <MenuIcon size="regular" />
          </MenuButton>
        </ContainerActions>
      </ContainerHeaderWrapper>
    </>
  );
});

ContainerHeader.displayName = "ContainerHeader";

export default ContainerHeader;

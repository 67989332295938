import {
  useMutation,
  useQuery,
  useApolloClient,
  ApolloError,
} from "@apollo/client";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Mutations, Queries } from "@gql";
import { asList, get, singularize, getContentValidator } from "@lib";
import {
  FormFor,
  FormFor_content,
  GetCurrentUser_currentUser,
  Organization,
  Publish,
  PublishVariables,
  Unpublish,
  UnpublishVariables,
  Archive,
  ArchiveVariables,
  Unarchive,
  UnarchiveVariables,
  GetBrand_brandConfiguration_contentTypes as ContentTypes,
  Count,
  CountVariables,
  GetBrand_brandConfiguration_seo,
  DistributionChannel,
  SearchFilters,
  GetConfigs_configs_linkAutogenConfig,
  PublishedRevisionResultFields,
  DeletePhoto,
  DeletePhotoVariables,
  DeleteClip,
  DeleteClipVariables,
  DeleteCartoon,
  DeleteCartoonVariables,
  ContentSummaryArchivedData,
  FormError,
} from "@types";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { ContentForm } from "./ContentForm";
import { ErrorPage } from "../../ErrorPage";
import {
  useChangeset,
  useContentAccessControlMatrix,
  useContentMutation,
  useToast,
  usePresence,
  useDefinedMessages,
  useFormLifecycle,
  LifecycleContext,
  LifecycleStep,
} from "@hooks";
import { ValidationError } from "@condenast/cross-check";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";
import PlainTextRenderer from "@atjson/renderer-plain-text";
import styled from "styled-components";
import { Spinner, OverwriteWarningDialog } from "@components";
import { SnowplowContext } from "@contexts";
import { pluralize } from "@lib";

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

function hasContentOverwriteError(error: ApolloError) {
  const graphQLErrors = error && error.graphQLErrors;
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      if (
        error.extensions &&
        error.extensions.response &&
        error.extensions.response.status === 412
      ) {
        return true;
      }
    }
  }
  return false;
}

function getRevisionNumber(content: FormFor_content | null | undefined) {
  return get(content, "revisionInfo.version") as number | undefined | null;
}

function getPublishedRevisions<Model>(model?: Model) {
  if (model === undefined) {
    return [];
  }
  let record = model as Record<string, unknown>;
  return (
    (
      record["publishedRevisions"] as {
        results: PublishedRevisionResultFields[];
      } | null
    )?.results ?? []
  );
}

const stableInitialContentData = {} as FormFor_content;

export const Form = (props: {
  contentTypes: ContentTypes[];
  currentUser: GetCurrentUser_currentUser;
  currentOrganization: Organization;
  linkAutogenConfig?: GetConfigs_configs_linkAutogenConfig;
  seoConfig?: GetBrand_brandConfiguration_seo;
  linkAutogen?: boolean;
}) => {
  const {
    currentOrganization,
    currentUser,
    contentTypes,
    seoConfig,
    linkAutogen,
    linkAutogenConfig,
  } = props;

  type ContentRouteParams = {
    copilotCode: string;
    contentType: string;
    id: string;
  };

  type BundleRouteParams = {
    copilotCode: string;
    id: string;
    bundleType: string;
  };
  const params = useParams() as ContentRouteParams | BundleRouteParams;
  const copilotCode = params.copilotCode;

  let collectionName: string;

  if ("bundleType" in params) {
    collectionName = "bundles";
  } else {
    collectionName = params.contentType.toLowerCase();
  }
  const contentType = singularize(collectionName ?? "");
  const contentTypeConfig = contentTypes.find(
    (contentTypeConfig) => contentTypeConfig.value === contentType
  );
  const contentId = params.id;
  let [forceSaveOverwrite, setForceSaveOverwrite] = useState(false);
  const { addTask, removeTask, run } = useFormLifecycle(contentType, contentId);

  const hasLinkAutogen = useMemo(
    () => linkAutogen && linkAutogenConfig?.contentTypes.includes(contentType),
    [linkAutogen, contentType, linkAutogenConfig?.contentTypes]
  );

  const linkAutogenFields: string[] = useMemo(
    () =>
      ((linkAutogenConfig?.fields ?? []).find(
        (field) => field?.contentType === contentType
      )?.fields as string[]) ?? [],
    [linkAutogenConfig?.fields, contentType]
  );

  const permissions = useContentAccessControlMatrix(
    currentOrganization,
    ["read", "update", "publish"],
    collectionName
  )[collectionName];

  const hasPermissionToPublish = useMemo(
    () => permissions.publish,
    [permissions]
  );
  const hasPermissionToSave = useMemo(() => permissions.update, [permissions]);

  const { data, loading, error } = useQuery<FormFor>(Queries.GET_FORM, {
    variables: {
      organizationId: currentOrganization.organizationId,
      contentType: contentType,
      id: contentId,
    },
  });

  const { data: contentSummaryData } = useQuery<ContentSummaryArchivedData>(
    Queries.GET_CONTENT_SUMMARY,
    {
      variables: {
        organizationId: currentOrganization.organizationId,
        contentType: contentType,
        id: contentId,
      },
    }
  );

  const { join, notifySave, saveData, isConnected, participants } = usePresence(
    currentUser.id,
    currentOrganization.organizationId,
    "spaces"
  );

  const publishedRevisions = getPublishedRevisions(data);
  const isRevisionPublished = publishedRevisions.some(
    ({ publishInfo }) =>
      publishInfo &&
      publishInfo.version === getRevisionNumber(data?.content) &&
      !publishInfo.expired
  );

  const [isArchived, setIsArchived] = useState(
    contentSummaryData?.contentSummary.archived
  );

  const intl = useIntl();
  const { translateContentType } = useDefinedMessages();
  const { trackContentEvent, trackFieldValidationEvent } =
    useContext(SnowplowContext);

  let translatedContentType = translateContentType(
    contentType,
    1
  ).toLowerCase();

  const showHasBeenSavedMessage = useToast({
    type: "warning",
    action: {
      linkProps: {
        to: window.location.pathname,
        target: "_blank",
      },
      message: intl.formatMessage({
        defaultMessage: `Open the latest version in a new tab, where you can see changes.`,
      }),
    },
    details: intl.formatMessage(
      {
        defaultMessage: `This {contentType} was updated, so you're no longer on the lastest version.`,
      },
      { name: saveData?.fullName ?? "", contentType: translatedContentType }
    ),
    children: intl.formatMessage(
      {
        defaultMessage: `{name} has made changes.`,
      },
      { name: saveData?.fullName ?? "", contentType: translatedContentType }
    ),
    onClose: () => {},
  });

  const showHasBeenUnarchivedMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: `Show On Search Successful.`,
    }),
    onClose: () => {},
  });

  useEffect(() => {
    join(contentId, copilotCode, currentUser);
  }, [contentId, copilotCode, currentUser, join]);

  useEffect(() => {
    setIsArchived(contentSummaryData?.contentSummary.archived);
  }, [contentSummaryData?.contentSummary.archived]);

  //makes sure that after closing toast, the toast doesn't show until there has been a new save
  const [lastSave, setLastSave] = useState(saveData);
  useEffect(() => {
    if (saveData && lastSave !== saveData) {
      showHasBeenSavedMessage();
      setLastSave(saveData);
    }
  }, [saveData, lastSave, setLastSave, showHasBeenSavedMessage]);
  let modelDataChangeset = useChangeset(
    data?.content ?? stableInitialContentData
  );
  let revisionNumber = getRevisionNumber(data?.content);

  let [model] = modelDataChangeset;

  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    []
  );
  const [serverErrors, setServerErrors] = useState<readonly Error[]>([]);

  const Client = useApolloClient();
  const validationEnvironment = useMemo(
    () => ({
      asList,
      get,
      count: async (options: {
        uri?: string | null;
        notid?: string | null;
      }) => {
        const filters: SearchFilters = {
          status: "published",
          ...options,
        };
        const results = await Client.query<Count, CountVariables>({
          query: Queries.COUNT,
          variables: {
            organizationId: currentOrganization.organizationId,
            filters,
          },
          fetchPolicy: "network-only",
        });
        return results.data.search;
      },
    }),
    [Client, currentOrganization]
  );
  let validate = useMemo(
    () =>
      model
        ? getContentValidator(
            contentType,
            currentOrganization.metadata.copilotCode,
            model,
            validationEnvironment
          )
        : undefined,
    [model, validationEnvironment, contentType]
  );
  let [startSaveTs, setStartSaveTs] = useState<number | undefined>(undefined);
  let [startPublishTs, setStartPublishTs] = useState<number | undefined>(
    undefined
  );

  const showSaveSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Saved",
    }),
  });

  const showValidationErrorMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Validation errors occured",
      description: "Notification message for validation errors",
    }),
  });

  const showServerErrorMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Sorry, something went wrong",
      description: "Notification message for server errors",
    }),
  });

  const showSavingMessage = useToast({
    type: "informational",
    children: intl.formatMessage({
      defaultMessage: "Saving...",
      description: "Notification message to show an entity is saving",
    }),
  });

  const showPublishingMessage = useToast({
    type: "informational",
    children: intl.formatMessage({
      defaultMessage: "Publishing...",
      description: "Notification message to show an entity is publishing",
    }),
  });

  const showUnpublishingMessage = useToast({
    type: "informational",
    children: intl.formatMessage({
      defaultMessage: "Unpublishing...",
      description: "Notification message to show an entity is unpublishing",
    }),
  });

  const showUnschedulingMessage = useToast({
    type: "informational",
    children: intl.formatMessage({
      defaultMessage: "Unscheduling...",
      description:
        "Notification message to show an entity is unscheduling a revision",
    }),
  });

  const showUnscheduleSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Unscheduled",
      description:
        "Notification message to show an entity is unscheduling a revision",
    }),
  });

  const [serialize, saveContent] = useContentMutation(contentType, "save");
  const hasValidationErrors = validationErrors.length > 0;
  const hasServerErrors = serverErrors.length > 0;

  let [showOverwriteDialog, setShowOverwriteDialog] = useState(false);

  const clearErrors = useCallback(() => {
    if (hasValidationErrors) {
      setValidationErrors([]);
    }
    if (hasServerErrors) {
      setServerErrors([]);
    }
  }, [hasValidationErrors, hasServerErrors]);

  useEffect(() => {
    addTask({
      lifecycleContexts: [LifecycleContext.SAVE, LifecycleContext.PUBLISH],
      lifecycleName: LifecycleStep.BEFORE_SAVE_VALIDATION,
      taskName: "beforeSaveValidation",
      runner: () => {
        clearErrors();
        setStartSaveTs(Date.now());
        setStartPublishTs(Date.now());
      },
    });

    addTask({
      lifecycleContexts: [LifecycleContext.PUBLISH],
      lifecycleName: LifecycleStep.BEFORE_PUBLISH_VALIDATION,
      taskName: "clearErrors",
      runner: clearErrors,
    });
  }, [addTask, clearErrors]);

  useEffect(() => {
    addTask({
      lifecycleContexts: [LifecycleContext.SAVE, LifecycleContext.PUBLISH],
      lifecycleName: LifecycleStep.SAVE_VALIDATION,
      taskName: "saveValidate",
      runner: async () => {
        const errors = await (validate?.("save") ?? []);
        if (errors.length) {
          trackFieldValidationEvent(
            contentId,
            contentType,
            errors as FormError[]
          );
          setValidationErrors(errors);
          showValidationErrorMessage();
          return false;
        }
        return;
      },
    });
  }, [
    addTask,
    showValidationErrorMessage,
    validate,
    trackFieldValidationEvent,
  ]);

  useEffect(() => {
    addTask({
      lifecycleContexts: [LifecycleContext.PUBLISH],
      lifecycleName: LifecycleStep.PUBLISH_VALIDATION,
      taskName: "publishValidate",
      runner: async () => {
        const errors = await (validate?.("publish") ?? []);
        if (errors.length) {
          trackFieldValidationEvent(
            contentId,
            contentType,
            errors as FormError[]
          );
          setValidationErrors(errors);
          return false;
        }
        return;
      },
    });
  }, [addTask, validate, clearErrors, trackFieldValidationEvent]);

  useEffect(() => {
    addTask({
      lifecycleContexts: [LifecycleContext.SAVE, LifecycleContext.PUBLISH],
      lifecycleName: LifecycleStep.SAVE,
      taskName: "save",
      runner: async (context) => {
        if (
          context === LifecycleContext.PUBLISH &&
          !isRevisionPublished &&
          !modelDataChangeset[2].hasChanges
        ) {
          return;
        }

        if (!serialize || !saveContent) {
          showServerErrorMessage();
          return false;
        }

        const input = serialize(
          modelDataChangeset[2].changes,
          modelDataChangeset[0],
          {
            currentUser,
            intl,
            brandCode: currentOrganization.metadata.copilotCode,
            config: {
              hostnames: { consumer: currentOrganization.url },
            },
          }
        );
        if (input != null) {
          showSavingMessage();
          try {
            const response = await saveContent({
              variables: {
                organizationId: currentOrganization.organizationId,
                id: contentId,
                data: input,
              },
              context: {
                etag: forceSaveOverwrite
                  ? null
                  : revisionNumber
                  ? `W/"${contentId}:${revisionNumber}"`
                  : null,
              },
            });

            if (response.errors?.length) {
              setServerErrors(response.errors);
              showServerErrorMessage();
              return false;
            } else if (response.data) {
              showSaveSuccessMessage();

              notifySave(contentId, copilotCode, currentUser, {
                author:
                  "revisionInfo" in model
                    ? model.revisionInfo.authorName ?? ""
                    : "",
              });
              trackContentEvent("notify_saved", contentId, contentType);
              if (startSaveTs) {
                trackContentEvent(
                  "saved",
                  contentId,
                  contentType,
                  Date.now() - startSaveTs
                );
              }
              return;
            }
          } catch (error) {
            if (error instanceof ApolloError) {
              if (hasContentOverwriteError(error)) {
                setShowOverwriteDialog(true);
                trackContentEvent("unpublished", contentId, contentType);
                return false;
              }
              setServerErrors([
                {
                  ...error,
                  message:
                    error?.graphQLErrors[0]?.extensions?.response.body
                      .message || error.message,
                },
              ]);
            } else if (error instanceof Error) {
              setServerErrors([error]);
            } else if (typeof error === "string") {
              setServerErrors([new Error(error)]);
            }
            showServerErrorMessage();
            return false;
          }
        }
        return;
      },
    });
  }, [
    addTask,
    contentId,
    contentType,
    copilotCode,
    currentOrganization.organizationId,
    currentOrganization.url,
    currentUser,
    forceSaveOverwrite,
    intl,
    isRevisionPublished,
    model,
    modelDataChangeset,
    notifySave,
    revisionNumber,
    saveContent,
    serialize,
    showSaveSuccessMessage,
    showSavingMessage,
    showServerErrorMessage,
    startSaveTs,
    trackContentEvent,
  ]);

  const isPublishable =
    contentType === "bundle" ||
    contentTypes.find(
      (item) => item.value && item.value === contentType && item.publish
    );

  const showPublishSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Published",
    }),
  });

  const showUnpublishSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Unpublished",
    }),
  });

  const [submitPublish] = useMutation<Publish, PublishVariables>(
    Mutations.PUBLISH
  );

  const [submitArchive] = useMutation<Archive, ArchiveVariables>(
    Mutations.ARCHIVE
  );

  const [submitUnarchive] = useMutation<Unarchive, UnarchiveVariables>(
    Mutations.UNARCHIVE
  );

  const publish = useCallback(
    async ({ uri, authorName, pubDate, distributionChannels }) => {
      showPublishingMessage();
      let content = data?.content as Record<string, unknown> | null | undefined;
      if (!distributionChannels?.length) {
        distributionChannels = [
          {
            name: "WebOwnedAndOperated",
          },
        ];
      }
      let webOwnedAndOperatedDistributionChannel = distributionChannels.find(
        (distributionChannel: DistributionChannel) =>
          distributionChannel.name === "WebOwnedAndOperated"
      );

      webOwnedAndOperatedDistributionChannel.metadata = {
        recommendable: content?.excludeFromRecirc === true ? false : true,
        searchable: content?.excludeFromSitemaps === true ? false : true,
      };
      try {
        const response = await submitPublish({
          variables: {
            organizationId: currentOrganization.organizationId,
            id: contentId,
            contentType,
            data: {
              authorName,
              uri,
              distributionChannels,
              pubDate: pubDate ?? new Date(),
            },
          },
        });
        if (response.errors?.length) {
          setServerErrors(response.errors);
          showServerErrorMessage();
        } else if (response.data) {
          showPublishSuccessMessage();
          trackContentEvent(
            "published",
            contentId,
            contentType,
            startPublishTs ? Date.now() - startPublishTs : undefined
          );
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          setServerErrors([
            {
              ...error,
              message:
                error?.graphQLErrors[0]?.extensions?.response.body.message ||
                error.message,
            },
          ]);
        } else if (error instanceof Error) {
          setServerErrors([error]);
        } else if (typeof error === "string") {
          setServerErrors([new Error(error)]);
        }
        showServerErrorMessage();
      }
    },
    [
      submitPublish,
      contentType,
      contentId,
      currentOrganization,
      showPublishingMessage,
      showPublishSuccessMessage,
      showServerErrorMessage,
      data?.content,
      startPublishTs,
      trackContentEvent,
    ]
  );

  const authorName = `${currentUser.firstName} ${currentUser.lastName}`;
  const archive = useCallback(async () => {
    const response = await submitArchive({
      variables: {
        organizationId: currentOrganization.organizationId,
        id: contentId,
        contentType,
        authorName: authorName,
      },
    });
    setIsArchived(!!response?.data?.archive);
  }, [isArchived, setIsArchived, authorName]);

  const unarchive = useCallback(async () => {
    const response = await submitUnarchive({
      variables: {
        organizationId: currentOrganization.organizationId,
        id: contentId,
        contentType,
        authorName: authorName,
      },
    });
    setIsArchived(!!response?.data?.unarchive);
    showHasBeenUnarchivedMessage();
  }, [isArchived, setIsArchived, showHasBeenUnarchivedMessage, authorName]);

  const [submitUnpublish] = useMutation<Unpublish, UnpublishVariables>(
    Mutations.UNPUBLISH
  );
  const unpublish = useCallback(async () => {
    showUnpublishingMessage();
    if (hasServerErrors) {
      setServerErrors([]);
    }
    try {
      const response = await submitUnpublish({
        variables: {
          organizationId: currentOrganization.organizationId,
          id: contentId,
          contentType,
        },
      });
      if (response.errors?.length) {
        setServerErrors(response.errors);
        showServerErrorMessage();
        return false;
      } else if (response.data) {
        showUnpublishSuccessMessage();
        trackContentEvent("unpublished", contentId, contentType);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        setServerErrors([
          {
            ...error,
            message:
              error?.graphQLErrors[0]?.extensions?.response.body.message ||
              error.message,
          },
        ]);
      } else if (error instanceof Error) {
        setServerErrors([error]);
      } else if (typeof error === "string") {
        setServerErrors([new Error(error)]);
      }
      showServerErrorMessage();
      return false;
    }
  }, [
    submitUnpublish,
    contentType,
    contentId,
    currentOrganization,
    showServerErrorMessage,
    setServerErrors,
    hasServerErrors,
    showUnpublishingMessage,
    showUnpublishSuccessMessage,
    trackContentEvent,
  ]);

  const viewLive = useCallback(
    // eslint-disable-next-line no-console
    () => console.log("Opening live URL in new tab"),
    []
  );
  const preview = useCallback(() => {
    const previewUrl = `${copilotCode}/preview/${contentType}/${contentId}`;
    // eslint-disable-next-line no-console
    console.log("previewUrl", previewUrl);
    trackContentEvent("previewed", contentId, contentType);
  }, [copilotCode, contentType, contentId, trackContentEvent]);

  const onOpenLatestRevision = useCallback(() => {
    let protocol = window.location.protocol;
    let host = window.location.host;
    let uri = `${protocol}//${host}/${copilotCode}/${pluralize(
      contentType
    )}/${contentId}`;
    window.open(uri, "_blank");
  }, [copilotCode, contentType, contentId]);

  const unschedule = useCallback(
    async (revision: number) => {
      showUnschedulingMessage();
      if (hasServerErrors) {
        setServerErrors([]);
      }
      try {
        const response = await submitUnpublish({
          variables: {
            organizationId: currentOrganization.organizationId,
            id: contentId,
            contentType,
            revision,
          },
        });
        if (response.data) {
          showUnscheduleSuccessMessage();
        } else {
          if (response.errors?.length) {
            setServerErrors(response.errors);
          }
          showServerErrorMessage();
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          setServerErrors([
            {
              ...error,
              message:
                error?.graphQLErrors[0]?.extensions?.response.body.message ||
                error.message,
            },
          ]);
        } else if (error instanceof Error) {
          setServerErrors([error]);
        } else if (typeof error === "string") {
          setServerErrors([new Error(error)]);
        }
        showServerErrorMessage();
      }
    },
    [
      submitUnpublish,
      contentType,
      contentId,
      currentOrganization,
      showServerErrorMessage,
      setServerErrors,
      hasServerErrors,
      showUnschedulingMessage,
      showUnscheduleSuccessMessage,
    ]
  );

  const [deletePhoto] = useMutation<DeletePhoto, DeletePhotoVariables>(
    Mutations.DELETE_PHOTO
  );
  const [deleteClip] = useMutation<DeleteClip, DeleteClipVariables>(
    Mutations.DELETE_CLIP
  );
  const [deleteCartoon] = useMutation<DeleteCartoon, DeleteCartoonVariables>(
    Mutations.DELETE_CARTOON
  );

  const deleteMedia = useCallback(async () => {
    if (hasServerErrors) {
      setServerErrors([]);
    }

    const mutation =
      contentType === "photo"
        ? deletePhoto
        : contentType === "clip"
        ? deleteClip
        : contentType === "cartoon"
        ? deleteCartoon
        : undefined;

    if (!mutation) {
      throw new Error(`Delete mutation not supported for type ${contentType}.`);
    }

    try {
      const response = await mutation({
        variables: {
          organizationId: currentOrganization.organizationId,
          contentType,
          id: contentId,
        },
      });
      if (response) {
        return true;
      } else {
        showServerErrorMessage();
        return false;
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        setServerErrors([
          {
            ...error,
            message:
              error?.graphQLErrors[0]?.extensions?.response.body.message ||
              error.message,
          },
        ]);
      } else if (error instanceof Error) {
        setServerErrors([error]);
      } else if (typeof error === "string") {
        setServerErrors([new Error(error)]);
      }
      showServerErrorMessage();
      return false;
    }
  }, [
    deletePhoto,
    deleteCartoon,
    deleteClip,
    contentType,
    contentId,
    currentOrganization,
    showServerErrorMessage,
    setServerErrors,
    hasServerErrors,
  ]);

  const title = useMemo(() => {
    // TODO: read this from data.summary field instead
    const record = data?.content as Record<string, string> | undefined;
    if (record?.["hed"]) {
      return PlainTextRenderer.render(
        CopilotMarkdownSource.fromRaw(record.hed).convertTo(VersoSource)
      );
    } else {
      const content =
        record?.["name"] || record?.["title"] || record?.["searchTitle"];
      return content ?? null;
    }
  }, [data]);

  return (
    <>
      {data?.form && data?.content ? (
        <>
          {showOverwriteDialog && (
            <OverwriteWarningDialog
              onClose={() => {
                setShowOverwriteDialog(false);
              }}
              onOpenLatestRevision={onOpenLatestRevision}
              onForceSaveOverwrite={() => {
                setShowOverwriteDialog(false);
                setForceSaveOverwrite(true);
                run(LifecycleContext.SAVE);
              }}
            />
          )}
          <ContentForm
            title={title ?? ""}
            currentUser={currentUser}
            permissions={permissions}
            currentOrganization={currentOrganization}
            contentTypeConfig={contentTypeConfig}
            formDefinition={data.form}
            changeset={modelDataChangeset}
            serverErrors={serverErrors}
            validationErrors={validationErrors}
            onPreview={preview}
            onViewLive={viewLive}
            isConnected={isConnected}
            participants={participants}
            onSubmit={
              hasPermissionToSave
                ? () => {
                    run(LifecycleContext.SAVE);
                  }
                : undefined
            }
            onPublish={
              isPublishable && hasPermissionToPublish
                ? () => {
                    run(LifecycleContext.PUBLISH);
                  }
                : undefined
            }
            onUnschedule={unschedule}
            publish={publish}
            unpublish={unpublish}
            deleteMedia={deleteMedia}
            linkAutogen={hasLinkAutogen}
            linkAutogenConfigFields={linkAutogenFields}
            addTask={addTask}
            removeTask={removeTask}
            seoConfig={seoConfig}
            isPublishable={isPublishable ? true : false}
            isArchived={isArchived}
            archive={archive}
            unarchive={unarchive}
          />
        </>
      ) : loading ? (
        <SpinnerWrapper>
          <Spinner size="large" />
        </SpinnerWrapper>
      ) : (
        <ErrorPage error={error} />
      )}
    </>
  );
};

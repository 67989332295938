import { CreateEvent_content_Event_location, LocationInput } from "@types";

export function location(
  data: CreateEvent_content_Event_location
): LocationInput {
  let { name, street, city, country, postalCode, state } = data;
  return {
    name: name || "",
    street: street || "",
    city: city || "",
    country: country || "",
    postalCode: postalCode || "",
    state: state || "",
  };
}

import { get, slugify } from "@lib";
import * as helpers from "../helpers";

const FORWARD_SLASHES = /\//g;

function hasHelper(helperName: string): helperName is keyof typeof helpers {
  return helperName in helpers;
}

function modelLookup(
  model: Record<string, unknown>,
  path: string,
  options: { allowAllLetterCharacters?: boolean; safeSlug?: boolean }
) {
  const modelValue = get(model, path);
  return modelValue != null
    ? slugify(String(modelValue).replace(FORWARD_SLASHES, "-"), options)
    : null;
}

function helperLookup(model: Record<string, unknown>, helperName: string) {
  if (hasHelper(helperName)) {
    return helpers[helperName](model);
  }
  return null;
}

// a value with an optional `required` prefix !
// and an optional `type` prefix `:` or `@`
const PATH_PART_PATTERN = /^(!)?([:@])?(.*)$/;

function parsePathPart(pathPart: string): {
  isRequired: boolean;
  type: "modelLookup" | "helperLookup" | "literal";
  value: string;
} {
  const [, requiredMatch, typeMatch, value] =
    pathPart.match(PATH_PART_PATTERN) ?? [];
  const isRequired = !!requiredMatch;
  const type =
    typeMatch === ":"
      ? "modelLookup"
      : typeMatch === "@"
      ? "helperLookup"
      : "literal";
  return {
    isRequired,
    type,
    value,
  };
}

export function autogenerate(
  autogenerateConfig: string | null,
  model: Record<string, unknown>,
  options: {
    allowAllLetterCharacters?: boolean;
    safeSlug?: boolean;
    requiredOnly?: boolean;
    withTrailingSlash?: boolean;
  } = {}
): string | null {
  let computed =
    autogenerateConfig &&
    autogenerateConfig
      .split("/")
      .map((part) => {
        const { type, isRequired, value } = parsePathPart(part);

        if (options.requiredOnly && !isRequired) {
          return null;
        }

        switch (type) {
          case "modelLookup":
            return modelLookup(model, value, options);
          case "helperLookup":
            return helperLookup(model, value);
          default:
            return value;
        }
      })
      .filter(Boolean)
      .join("/");

  if (computed?.length && options.withTrailingSlash) {
    computed += "/";
  }
  return computed;
}

import {
  ControlProps,
  FormFor_form_controls_DurationFormControl,
} from "@types";
import { useCallback } from "react";
import { Duration as DurationImplementation } from "@components";
import { get, millisecondsToHours } from "@lib";
import { useDefinedMessages } from "@hooks";

export function Duration(
  props: ControlProps<FormFor_form_controls_DurationFormControl>
) {
  const { translateFieldName } = useDefinedMessages();
  const { setValue, name, id, model, maxDuration, errors } = props;
  let durationProp = get(model, name) as number;
  let durationInMs = durationProp === null ? undefined : durationProp;
  let formattedMaxDur = maxDuration && millisecondsToHours(maxDuration);
  let maxDurationMsg = formattedMaxDur
    ? `Maximum Duration is ${formattedMaxDur}`
    : "";

  const onChange = useCallback(
    (newDuration?: number) => {
      let duration =
        newDuration === null ||
        newDuration === undefined ||
        newDuration.toString().trim().length === 0
          ? null
          : !isNaN(Number(newDuration))
          ? Math.min(Number(newDuration), maxDuration ?? Infinity)
          : newDuration;
      setValue(name, duration);
    },
    [name, setValue, maxDuration]
  );

  return (
    <DurationImplementation
      label={translateFieldName(name)}
      id={id}
      duration={durationInMs}
      onChange={onChange}
      placeholder={"00:00:00"}
      errors={errors}
      message={maxDurationMsg}
      maxDuration={maxDuration || undefined}
    />
  );
}
Duration.displayName = "Control(Duration)";

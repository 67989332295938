import { useContext, useMemo, ReactNode } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

import {
  BlueskyEmbed as BlueskyEmbedAnnotation,
  FacebookEmbed as FacebookEmbedAnnotation,
  GiphyEmbed as GiphyEmbedAnnotation,
  IframeEmbed as IframeEmbedAnnotation,
  InstagramEmbed as InstagramEmbedAnnotation,
  Link as LinkAnnotation,
  MastodonEmbed as MastodonEmbedAnnotation,
  PinterestEmbed as PinterestEmbedAnnotation,
  ThreadsEmbed as ThreadsEmbedAnnotation,
  TikTokEmbed as TikTokEmbedAnnotation,
  TwitterEmbed as TwitterEmbedAnnotation,
  VideoEmbed as VideoEmbedAnnotation,
} from "@atjson/offset-annotations";
import VersoSource, {
  Button as ButtonAnnotation,
  ClipEmbed as ClipEmbedAnnotation,
  CNEPlaylistEmbed as CNEPlaylistEmbedBlock,
  CNEVideoEmbed as CNEVideoEmbedBlock,
  ArticleEmbed,
  GalleryEmbed,
  PhotoEmbed as PhotoEmbedAnnotation,
  CartoonEmbed,
  ContributorEmbed,
  ExternalLinkEmbed,
  HotelEmbed,
  LiveStoryEmbed,
  RecipeEmbed,
  ReviewEmbed,
  ProductEmbed,
} from "@condenast/atjson-source-verso";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import HTMLRenderer from "@atjson/renderer-html";
import { PropsOf, Slice } from "@atjson/renderer-react";

import { LiveStoryContext } from "../LiveStory.context";
import { useLiveStoryEmbed } from "../useLiveStoryEmbed";
import { useDefinedMessages } from "@hooks";
import { BlockAnnotation } from "@atjson/document";

const StyledAnchor = styled.a`
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  text-decoration: none;
  &::-moz-focus-inner {
    border: 0;
  }

  display: inline;
  align-items: center;
  background: transparent;
  color: ${(props) => props.theme.SecondaryColor};
  cursor: pointer;
  font: ${(props) => props.theme.FontInteractive};
  text-decoration: none;
  border-radius: ${(props) => props.theme.CornerRadius};

  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    box-shadow: ${(props) => props.theme.FocusRing};
  }
`;
const Paragraph = styled.p``;
const Bold = styled.strong`
  font-weight: bold;
`;
const Italic = styled.em`
  font-style: italic;
`;
const SmallCaps = styled.span`
  font-variant: all-small-caps;
`;
const Strikethrough = styled.span`
  text-decoration: line-through;
`;
const Subscript = styled.sub`
  padding: ${(props) => (props.children ? "" : "")};
`;
const Superscript = styled.sup``;
const LineBreak = styled.br``;
const Link = ({ children, rel, target, url }: PropsOf<LinkAnnotation>) => (
  <StyledAnchor href={url} rel={rel} target={target}>
    {children}
  </StyledAnchor>
);
const Button = ({ url, title }: PropsOf<ButtonAnnotation>) => (
  <cn-button url={url}>{title}</cn-button>
);
const CnePlaylistEmbed = ({ id }: PropsOf<CNEPlaylistEmbedBlock>) => (
  <cne-embed uri={`script/playlist/${id}.js?autoplay=1&muted=1`}></cne-embed>
);
const CneVideoEmbed = ({ id }: PropsOf<CNEVideoEmbedBlock>) => (
  <cne-embed uri={`script/video/${id}.js?autoplay=1&muted=1`}></cne-embed>
);
const FacebookEmbed = ({ url }: PropsOf<FacebookEmbedAnnotation>) => (
  <facebook-embed url={url}></facebook-embed>
);
const GiphyEmbed = ({ url }: PropsOf<GiphyEmbedAnnotation>) => (
  <giphy-embed url={url}></giphy-embed>
);
const InstagramEmbed = ({ url }: PropsOf<InstagramEmbedAnnotation>) => (
  <instagram-embed url={url}></instagram-embed>
);
const MastodonEmbed = ({ url }: PropsOf<MastodonEmbedAnnotation>) => (
  <mastodon-embed url={url}></mastodon-embed>
);
const BlueskyEmbed = ({ url, uri, cid }: PropsOf<BlueskyEmbedAnnotation>) => (
  <bluesky-embed url={url} uri={uri} cid={cid}></bluesky-embed>
);
const PinterestEmbed = ({ url }: PropsOf<PinterestEmbedAnnotation>) => (
  <pinterest-embed url={url}></pinterest-embed>
);
const ThreadsEmbed = ({ url }: PropsOf<ThreadsEmbedAnnotation>) => (
  <threads-embed url={url}></threads-embed>
);
const TiktokEmbed = ({ url }: PropsOf<TikTokEmbedAnnotation>) => (
  <tiktok-embed url={url}></tiktok-embed>
);
const TwitterEmbed = ({ url }: PropsOf<TwitterEmbedAnnotation>) => (
  <twitter-embed url={url}></twitter-embed>
);
const VideoEmbed = ({ url }: PropsOf<VideoEmbedAnnotation>) => (
  <video-embed url={url}></video-embed>
);
const IframeEmbed = ({ url }: PropsOf<IframeEmbedAnnotation>) => (
  <iframe-embed url={url}></iframe-embed>
);
const ClipEmbed = (props: PropsOf<ClipEmbedAnnotation>) => {
  const { id, caption } = props;
  const { currentOrganization } = useContext(LiveStoryContext);
  const { data } = useLiveStoryEmbed(
    currentOrganization?.organizationId ?? "",
    "clip",
    id as string
  );
  const cdnHost = `https://${currentOrganization?.metadata.mediaDomain}`;
  const imageUrl = data?.contentSummary?.asset
    ? `${cdnHost}/clips/${data.contentSummary.asset.id}/:aspect_ratio/:modifications/${data.contentSummary.asset.filename}`
    : "";
  const videoUrl = data?.contentSummary?.asset
    ? `${cdnHost}/clips/${data.contentSummary.id}/:aspect_ratio/:modifications/${data.contentSummary.description}`
    : "";
  const altText = data?.contentSummary?.asset?.altText ?? "";
  const rawCredit = data?.contentSummary?.credit?.content;
  const credit = useMemo(() => {
    const creditDoc = rawCredit
      ? CopilotMarkdownSource.fromRaw(rawCredit).convertTo(VersoSource)
      : null;
    creditDoc?.where((a) => a.type === "paragraph").remove();
    return HTMLRenderer.render(creditDoc);
  }, [rawCredit]);

  return (
    <cn-clip
      image-url={imageUrl}
      video-url={videoUrl}
      alt={altText}
      credit={credit}
    >
      {caption ? (
        <cn-caption>
          <Slice value={caption} fallback={caption}></Slice>
        </cn-caption>
      ) : (
        <></>
      )}
    </cn-clip>
  );
};
const PhotoEmbed = (props: PropsOf<PhotoEmbedAnnotation>) => {
  const { id, caption } = props;
  const intl = useIntl();
  const { currentOrganization } = useContext(LiveStoryContext);
  const { data, error } = useLiveStoryEmbed(
    currentOrganization?.organizationId ?? "",
    "photo",
    id as string
  );
  const cdnHost = `https://${currentOrganization?.metadata.mediaDomain}`;
  const imageUrl = data?.contentSummary?.asset
    ? `${cdnHost}/photos/${data.contentSummary.asset.id}/:aspect_ratio/:modifications/${data.contentSummary.asset.filename}`
    : "";
  const altText = data?.contentSummary?.asset?.altText ?? "";
  const rawCredit = data?.contentSummary?.credit?.content;
  const credit = useMemo(() => {
    const creditDoc = rawCredit
      ? CopilotMarkdownSource.fromRaw(rawCredit).convertTo(VersoSource)
      : null;
    creditDoc?.where((a) => a.type === "paragraph").remove();
    return HTMLRenderer.render(creditDoc);
  }, [rawCredit]);
  const { translateContentType } = useDefinedMessages();
  const errorMessage = intl.formatMessage(
    {
      defaultMessage: "Error loading {type} with id {id}",
      description:
        "Error message when loading a content embed in the live story feed",
    },
    {
      type: translateContentType("photo", 1),
      id,
    }
  );

  return (
    <cn-photo
      image-url={imageUrl}
      alt={altText}
      credit={credit}
      errorMessage={error ? errorMessage : undefined}
    >
      {caption ? (
        <cn-caption>
          <Slice value={caption} fallback={caption}></Slice>
        </cn-caption>
      ) : (
        <></>
      )}
    </cn-photo>
  );
};

function embed<
  Block extends BlockAnnotation<{ id: string }>,
  Props = PropsOf<Block>,
  Slot extends string | number | symbol = keyof Props
>(type: string, options: { displayName: string; slot: Slot }) {
  const CopilotEmbedInstance = (
    props: Props & { id: string } & Record<Slot, string>
  ) => {
    let caption = options.slot in props ? props[options.slot] : undefined;
    return <CopilotEmbed id={props.id} type={type} caption={caption} />;
  };
  CopilotEmbedInstance.displayName = options.displayName;
  return CopilotEmbedInstance;
}

function sponsoredEmbed<
  Block extends BlockAnnotation<{
    id: string;
    isSponsored?: boolean;
    dek?: string;
  }>,
  Props extends {
    id: string;
    isSponsored?: boolean;
    dek?: string;
  } = PropsOf<Block>
>(type: string, sponsoredType: string, options: { displayName: string }) {
  const CopilotEmbedInstance = (props: Props) => {
    return (
      <CopilotEmbed
        id={props.id}
        type={props.isSponsored ? sponsoredType : type}
        caption={props.dek}
      />
    );
  };
  CopilotEmbedInstance.displayName = options.displayName;
  return CopilotEmbedInstance;
}

function CopilotEmbed(props: { id: string; type: string; caption?: string }) {
  const { id, type, caption } = props;
  const intl = useIntl();
  const { currentOrganization } = useContext(LiveStoryContext);
  const { data, error } = useLiveStoryEmbed(
    currentOrganization?.organizationId ?? "",
    type,
    id
  );
  const cdnHost = `https://${currentOrganization?.metadata.mediaDomain}`;
  const imageUrl = data?.contentSummary?.asset
    ? `${cdnHost}/photos/${data.contentSummary.asset.id}/:aspect_ratio/:modifications/${data.contentSummary.asset.filename}`
    : "";
  const rawTitle = data?.contentSummary?.title?.content;
  const title = useMemo(() => {
    const titleDoc = rawTitle
      ? CopilotMarkdownSource.fromRaw(rawTitle).convertTo(VersoSource)
      : null;
    titleDoc?.where((a) => a.type === "paragraph").remove();
    return HTMLRenderer.render(titleDoc);
  }, [rawTitle]);
  const { translateContentType } = useDefinedMessages();
  const translatedType = translateContentType(type, 1);
  const errorMessage = intl.formatMessage(
    {
      defaultMessage: "Error loading {type} with id {id}",
      description:
        "Error message when loading a content embed in the live story feed",
    },
    {
      type: translatedType,
      id,
    }
  );

  return (
    <copilot-embed
      title={title}
      image-url={imageUrl}
      channel={translatedType}
      error={error ? errorMessage?.toString() : undefined}
    >
      {caption ? (
        <cn-caption>
          <Slice value={caption} />
        </cn-caption>
      ) : (
        <></>
      )}
    </copilot-embed>
  );
}

export const components = {
  Bold,
  BlueskyEmbed,
  Button,
  ClipEmbed,
  CnePlaylistEmbed,
  CneVideoEmbed,
  FacebookEmbed,
  ArticleEmbed: sponsoredEmbed<ArticleEmbed>("article", "nativearticle", {
    displayName: "ArticleEmbed",
  }),
  CartoonEmbed: embed<CartoonEmbed>("cartoon", {
    displayName: "CartoonEmbed",
    slot: "caption",
  }),
  ContributorEmbed: embed<ContributorEmbed>("contributor", {
    displayName: "ContributorEmbed",
    slot: "bio",
  }),
  GalleryEmbed: sponsoredEmbed<GalleryEmbed>("gallery", "nativegallery", {
    displayName: "GalleryEmbed",
  }),
  ExternalLinkEmbed: embed<ExternalLinkEmbed>("externallink", {
    displayName: "ExternalLinkEmbed",
    slot: "dek",
  }),
  HotelEmbed: embed<HotelEmbed>("hotel", {
    displayName: "HotelEmbed",
    slot: "dek",
  }),
  LiveStoryEmbed: embed<LiveStoryEmbed>("livestory", {
    displayName: "LiveStoryEmbed",
    slot: "dek",
  }),
  RecipeEmbed: sponsoredEmbed<RecipeEmbed>("recipe", "nativerecipe", {
    displayName: "RecipeEmbed",
  }),
  ReviewEmbed: embed<ReviewEmbed>("review", {
    displayName: "ReviewEmbed",
    slot: "dek",
  }),
  ProductEmbed: embed<ProductEmbed>("product", {
    displayName: "ProductEmbed",
    slot: "description",
  }),
  GiphyEmbed,
  InstagramEmbed,
  IframeEmbed,
  Italic,
  LineBreak,
  Link,
  MastodonEmbed,
  Paragraph,
  PhotoEmbed,
  PinterestEmbed,
  SmallCaps,
  Strikethrough,
  Subscript,
  Superscript,
  ThreadsEmbed,
  TiktokEmbed,
  TwitterEmbed,
  VideoEmbed,
  Default: (props: { children: ReactNode }) => <>{props.children}</>,
};

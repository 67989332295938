import { Button, Chip, Label } from "@components";
import { useCallback, useState, useRef } from "react";
import { Popper } from "react-popper";
import { CustomDateMenu } from "./CustomDateMenu";
import {
  CalendarIcon,
  ChevronDownIcon,
  CloseIcon,
} from "@condenast/gemini/icons";
import styled from "styled-components";
import { useClickOutside, useDefinedMessages } from "@hooks";
import { FormattedMessage, useIntl } from "react-intl";
import { AdvancedSearchOptions } from "../../types";

const Calendar = styled(CalendarIcon)`
  height: var(--spacing-sm);
  width: var(--spacing-sm);
  align-items: center;
  color: ${(props) => props.theme.Color};
`;

const LabelContainer = styled.div`
  display: flex;
  align-items center;
  justify-content: space-between;
  flex-basis: 95%;
`;

const Arrow = styled(ChevronDownIcon)`
  height: var(--spacing-sm);
  width: var(--spacing-sm);
`;

const StyledButton = styled(Button)<{
  $takeoverView?: boolean;
  $isOpen: boolean;
}>`
  ${({ theme, $takeoverView, $isOpen }) => {
    return `
      display: flex;
      align-items center;
      border: 0;
      margin: 0;
      font: var(--font-body);
      width: 100%;
      min-height: var(--spacing-xl);
      color: ${theme.Color};
      border-radius: ${theme.CornerRadius};
      box-shadow: ${theme.FieldRing};
      background: ${
        $takeoverView ? theme.SecondaryActiveBackground : theme.Background
      };
      padding: var(--spacing-xs) var(--spacing-sm);

      &:not([disabled]):hover {
        box-shadow: ${theme.FieldHoverRing};
        cursor: initial;
        text-decoration: none;
      }

      &:not([disabled]):active {
        box-shadow: ${theme.FieldActiveRing};
        background: ${theme.Background};
      }

      &:not([disabled]):focus,
      &:not([disabled])[aria-expanded="true"] {
        box-shadow: ${theme.FieldFocusRing},
          ${theme.FocusRing};
        outline: none;
        background: ${theme.Background};
      }

      & ${Arrow} {
        height: var(--spacing-sm);
        width: var(--spacing-sm);
        align-items: center;
        left: calc(1 * var(--spacing-xs));
        color: ${theme.Color};
        transform:  ${$isOpen ? "rotate(-180deg)" : "rotate(0)"};
      }
    `;
  }}
`;

const Wrapper = styled.div<{ $mobileView?: boolean }>`
  position: relative;
  margin-bottom: ${(props) =>
    props.$mobileView ? "var(--spacing-sm)" : "initial"}; ;
`;

const PopperContent = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  top: calc(100% + 5px);
  @media (max-width: 650px) {
    position: absolute;
    left: 20%;
  }
`;

const MobileChipList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-xs) 0;

  &.selection-list--empty {
    padding: 0;
  }
`;

const Selection = styled(Chip)`
  padding-right: var(--spacing-xxs);
  margin-right: var(--spacing-xxs);
  margin-bottom: var(--spacing-xxs);
  background-color: ${(props) => props.theme.MedallionBackground};

  button {
    background: transparent;
    &:not([disabled]):hover {
      background: transparent;
    }
  }
`;

const SelectionLabel = styled.span`
  margin-right: var(--spacing-xxs);
`;

const DatePopper = styled(Popper)``;

export function SearchDateFilter(props: {
  selectedFilters: AdvancedSearchOptions;
  onChange: (dateRangeOption: string | undefined) => void;
  dateRangeOptions: string[] | undefined;
  mobileView?: boolean;
  takeoverView?: boolean;
  hideLabel?: boolean;
}) {
  const {
    selectedFilters,
    onChange,
    dateRangeOptions,
    mobileView,
    hideLabel,
    takeoverView,
  } = props;

  const intl = useIntl();
  const { translateSelectOption } = useDefinedMessages();

  const [showPopper, setShowPopper] = useState<boolean>(false);
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null
  );

  const popperButtonRef = useRef(null);

  const close = useCallback(() => {
    setShowPopper(false);
  }, [setShowPopper]);

  useClickOutside(wrapperElement, close);

  const currentDateSelection = selectedFilters.date ?? "";

  const selectedDates = currentDateSelection.split("to");
  const dateFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  } as const;

  const startDate =
    selectedDates.length === 2 &&
    selectedDates[0] &&
    intl.formatDate(selectedDates[0], dateFormatOptions);
  const endDate =
    selectedDates.length === 2 &&
    selectedDates[1] &&
    intl.formatDate(selectedDates[1], dateFormatOptions);

  const currentDateLabel =
    startDate && endDate
      ? startDate === endDate
        ? startDate
        : intl.formatMessage(
            {
              defaultMessage: "{start} to {end}",
              description: "Label for Selected custom date range",
            },
            {
              start: startDate,
              end: endDate,
            }
          )
      : translateSelectOption("searchDateOptions", currentDateSelection);

  return (
    <Wrapper $mobileView={mobileView} ref={setWrapperElement}>
      {!hideLabel && (
        <Label>
          <FormattedMessage defaultMessage="Date" />
        </Label>
      )}
      <StyledButton
        onClick={() => setShowPopper(!showPopper)}
        ref={popperButtonRef}
        $isOpen={showPopper}
        $takeoverView={takeoverView}
      >
        <Calendar size="regular" />
        <LabelContainer>
          <span>
            <FormattedMessage defaultMessage="Select" />
          </span>
          <Arrow size="small" />
        </LabelContainer>
      </StyledButton>
      {showPopper && (
        <DatePopper>
          {({ ref }) => (
            <PopperContent ref={ref}>
              <CustomDateMenu
                selectedFilters={selectedFilters}
                dateRangeOptions={dateRangeOptions}
                setShowPopper={setShowPopper}
                onChange={onChange}
              />
            </PopperContent>
          )}
        </DatePopper>
      )}
      {mobileView && currentDateSelection && (
        //todo fix this. Selections now need to be passed down to individual filters for mobile
        <MobileChipList>
          <Selection forwardedAs="li" key={currentDateSelection} size="small">
            <SelectionLabel>
              {currentDateLabel || currentDateSelection}
            </SelectionLabel>
            <Button
              type="button"
              size="small"
              aria-label={
                intl.formatMessage(
                  {
                    defaultMessage: "Remove {item}",
                    description:
                      "button to remove an item from a list of selected items",
                  },
                  { item: currentDateLabel || currentDateSelection }
                ) as string
              }
              onClick={() => {
                onChange(undefined);
              }}
            >
              <CloseIcon size="small" />
            </Button>
          </Selection>
        </MobileChipList>
      )}
    </Wrapper>
  );
}

SearchDateFilter.displayName = "SearchDateFilter";

import { ARIA, Button, RoutableLink } from "@components";
import { useIntl } from "react-intl";
import { SearchResult, Spinner } from "@components";
import { useDefinedMessages } from "@hooks";
import styled from "styled-components";
import { ContentSummary } from "@types";
import { useMemo } from "react";

const StyledSearchResult = styled(SearchResult)`
  word-break: break-all;
`;

const ResultListContainer = styled.ul`
  grid-area: result-list;
  overflow-y: auto;
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  & > li:first-of-type {
    margin: 0;
  }
`;

const ResultSlat = styled.li`
  display: grid;
  grid-template-areas: "search-result";
  align-items: center;
  padding: 0;
  margin: var(--spacing-xxs) 0 0;
  cursor: default;
  user-select: none;
  position: relative;
  border-radius: var(--spacing-xxs);
  border: 1px solid var(--color-gray-5);
`;

const BackreferenceItem = styled(RoutableLink)`
  text-decoration: none;
  color: ${(props) => {
    return props.theme.Color;
  }};
`;

const Dialog = styled(ARIA.Dialog)`
  overflow: auto;
`;

const DialogBody = styled.div`
  padding: var(--spacing-xxs) var(--spacing-md) var(--spacing-sm);
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center; /* Align horizontally */
  align-items: center;
`;
export function UsageInfoDialog(props: {
  contentType: string;
  cdnHost?: string;
  usageInfoLoading?: boolean;
  usageInfoError?: Error;
  backReferences?: ContentSummary[];
  close: () => void;
}) {
  const intl = useIntl();
  const { translateContentType } = useDefinedMessages();
  const {
    contentType,
    cdnHost,
    usageInfoLoading,
    usageInfoError,
    backReferences,
    close,
  } = props;
  const usageCount = backReferences?.length || 0;
  const description = useMemo(() => {
    return backReferences && !usageInfoLoading && !usageInfoError
      ? intl.formatMessage(
          {
            defaultMessage:
              "This {type} is used in {usageCount, plural, =1 {# piece} other {# pieces}} of content",
            description: "Usage info dialog description",
          },
          {
            usageCount,
            type: translateContentType(contentType, 1),
          }
        )
      : "";
  }, [
    usageCount,
    backReferences,
    contentType,
    intl,
    translateContentType,
    usageInfoLoading,
    usageInfoError,
  ]);

  return (
    <Dialog
      title={intl.formatMessage(
        {
          defaultMessage: "{type} usage",
          description: "Usage info dialog heading",
        },
        {
          type: translateContentType(contentType, 1),
        }
      )}
      description={description}
      size={"regular"}
      submitButton={
        <Button
          onClick={() => {
            close();
          }}
        >
          {intl.formatMessage({
            defaultMessage: "Done",
            description: "Done button in usage info modal",
          })}
        </Button>
      }
      onClose={() => {
        close();
      }}
    >
      <DialogBody>
        {usageInfoLoading ? (
          <SpinnerWrapper>
            <Spinner size="large" />
          </SpinnerWrapper>
        ) : usageInfoError ? (
          <div>
            {intl.formatMessage({
              defaultMessage: "Error loading usage info",
              description: "Error loading usage info message",
            })}
          </div>
        ) : (
          <ResultListContainer tabIndex={0}>
            {backReferences?.map((backReference) => {
              return (
                backReference.editUrl && (
                  <ResultSlat key={backReference.id}>
                    <BackreferenceItem
                      to={backReference.editUrl ?? ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledSearchResult
                        treatment="asset-selector"
                        result={backReference}
                        cdnHost={cdnHost}
                      />
                    </BackreferenceItem>
                  </ResultSlat>
                )
              );
            })}
          </ResultListContainer>
        )}
      </DialogBody>
    </Dialog>
  );
}
UsageInfoDialog.displayName = "UsageInfoDialog";

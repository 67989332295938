import { useMemo, Fragment, useCallback } from "react";
import { useDefinedMessages } from "@hooks";
import styled from "styled-components";
import { Button, Label, Toggle } from "@components";
import { FormattedMessage } from "react-intl";
import type {
  PageLayoutCategory,
  AspectRatioSelectState,
  ImageUrlSelectState,
  PageLayoutSelectorConfig,
  PhotosLede,
  MediaOverrides,
  PhotosOverrideEdge,
  Trie,
  ToggleMap,
  ToggleLevel,
  TransformedLevelConfig,
  PhotoMediaOverride,
  MediaNode,
} from "@types";
import {
  getAspectRatioOptions,
  getLayoutOptions,
  updateMediaOverrides,
  getPreSelectedMediaOverride,
  getPreSelectedMediaOverrideV2,
} from "./PageLayoutSelector.utils";
import { PageLayoutPreview } from "./PageLayoutPreview";

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: ${(props) => props.theme.ToastBackground};
  border: 1px solid ${(props) => props.theme.DividerColor};
  overflow-y: hidden;
  border-radius: var(--spacing-xxs);
`;

const PageLayoutSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  height: auto;
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-sm);
  max-width: calc(var(--spacing-lg) * 7);
  border-right: 1px solid ${(props) => props.theme.DividerColor};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  align-items: flex-start;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: var(--spacing-xxs);
`;

const IconStyledButton = styled(Button)`
  padding: var(--spacing-xxs);
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  margin-right: var(--spacing-xxs);
  background-color: ${(props) =>
    props.treatment === "primary"
      ? props.theme.BorderlessActiveBackground
      : props.theme.ToastBackground};

  svg {
    color: ${(props) => props.theme.SubheadingColor};
  }

  &:not(:disabled):hover {
    background-color: ${(props) => props.theme.BorderlessHoverBackground};
  }
`;

const ResetStyledButton = styled(Button)`
  width: 100%;
  display: block;
  margin-top: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid ${(props) => props.theme.DividerColor};
`;

export function PageLayoutSelector({
  currentToggleSelection,
  toggleDecisionTree,
  currentCategory,
  config,
  enableMediaOverridesV2,
  relMediaOverrides,
  setMediaOverridesV2,
  mediaOverrides,
  photosLede,
  enableLivePreview,
  setPageLayoutCategory,
  setMediaOverrides,
  resetToDefault,
  mediaDomain,
}: {
  currentToggleSelection: ToggleMap;
  toggleDecisionTree: Trie;
  currentCategory: PageLayoutCategory;
  config: PageLayoutSelectorConfig;
  enableMediaOverridesV2: boolean | undefined;
  mediaOverrides: MediaOverrides[];
  setMediaOverrides: (mediaOverrides: MediaOverrides[]) => void;
  relMediaOverrides: PhotosOverrideEdge | undefined;
  setMediaOverridesV2: (PhotosOverrideEdge: PhotosOverrideEdge) => void;
  photosLede?: PhotosLede;
  enableLivePreview: boolean;
  setPageLayoutCategory: (level: string, option: string) => void;
  resetToDefault: () => void;
  mediaDomain: string;
}) {
  let { translatePageLayout } = useDefinedMessages();
  const layoutOptions = useMemo(
    (): TransformedLevelConfig[] =>
      getLayoutOptions(currentToggleSelection, toggleDecisionTree, config),
    [currentToggleSelection, toggleDecisionTree, config]
  );

  const handleButtonClick = (levelKey: ToggleLevel, toggleOption: string) => {
    setPageLayoutCategory(levelKey, toggleOption);
  };

  const handleToggleChange = (
    value: boolean,
    levelKey: ToggleLevel,
    toggleOptions: string[]
  ) => {
    const [onTrue, onFalse] = toggleOptions;
    setPageLayoutCategory(levelKey, value ? onTrue : onFalse);
  };

  const aspectRatioOptions = getAspectRatioOptions(photosLede);

  const aspectRatioValue = useMemo<AspectRatioSelectState>(() => {
    if (enableMediaOverridesV2 && relMediaOverrides) {
      return {
        lg: getPreSelectedMediaOverrideV2(relMediaOverrides, "lg"),
        sm: getPreSelectedMediaOverrideV2(relMediaOverrides, "sm"),
      };
    } else {
      return {
        lg: getPreSelectedMediaOverride(mediaOverrides, "lg"),
        sm: getPreSelectedMediaOverride(mediaOverrides, "sm"),
      };
    }
  }, [mediaOverrides, relMediaOverrides, enableMediaOverridesV2]);

  let imageUrl: ImageUrlSelectState = { sm: undefined, lg: undefined };
  if (photosLede?.asset.filename && mediaDomain) {
    imageUrl = {
      sm: `https://${mediaDomain}/photos/${photosLede.id}/${aspectRatioValue.sm}/w_500,h_500,c_limit/${photosLede.asset.filename}`,
      lg: `https://${mediaDomain}/photos/${photosLede.id}/${aspectRatioValue.lg}/w_500,h_500,c_limit/${photosLede.asset.filename}`,
    };
  }

  const onChangeAspectRatio = useCallback(
    (breakpoint: string | undefined, aspectRatio: string | undefined) => {
      if (breakpoint && mediaOverrides) {
        const updatedMediaOverrides = updateMediaOverrides(mediaOverrides, {
          breakpoint,
          aspectRatio: aspectRatio ?? "",
        });
        setMediaOverrides(updatedMediaOverrides);
      }
    },
    [mediaOverrides, setMediaOverrides]
  );

  const onChangeAspectRatioV2 = useCallback(
    (breakpoint: string | undefined, aspectRatio: string | undefined) => {
      if (breakpoint) {
        let overridesV2;
        if (relMediaOverrides) {
          overridesV2 = relMediaOverrides?.pagelayoutMediaOverrides.filter(
            (el) => el.breakpoint !== breakpoint
          )[0];
        } else {
          mediaOverrides?.length &&
            mediaOverrides.forEach((el) => {
              if (el.relName === "photosLede") {
                overridesV2 = el.overrides.filter(
                  (el) => el.breakpoint !== breakpoint
                )[0];
              }
            });
        }
        let pLMediaOverrides = [
          {
            __typename: "PhotoMediaOverride",
            breakpoint,
            aspectRatio: aspectRatio ?? "",
          },
        ] as PhotoMediaOverride[];
        if (overridesV2) {
          pLMediaOverrides = [
            { ...overridesV2, __typename: "PhotoMediaOverride" },
            ...pLMediaOverrides,
          ];
        }
        const PhotosOverrideEdge = {
          node: {
            id: photosLede?.id,
            __typename: "CommonMediaOverride",
          } as MediaNode,
          pagelayoutMediaOverrides: pLMediaOverrides,
          relName: "photosLede",
          __typename: "PhotosOverrideEdge",
        } as PhotosOverrideEdge;

        setMediaOverridesV2(PhotosOverrideEdge);
      }
    },
    [relMediaOverrides, setMediaOverridesV2, mediaOverrides, photosLede?.id]
  );

  return (
    <Wrapper>
      <PageLayoutSelectorWrapper>
        {layoutOptions.map(
          (level: TransformedLevelConfig, levelIndex: number) => (
            <Row key={levelIndex}>
              {level.type === "button" ? (
                <Fragment>
                  <Label>{translatePageLayout(level.translation)}</Label>
                  <ButtonGroup>
                    {level.options.map((option, optionIndex) => (
                      <IconStyledButton
                        key={optionIndex}
                        aria-label={translatePageLayout(option.translation)}
                        onClick={() =>
                          handleButtonClick(level.key, option.value)
                        }
                        treatment={
                          currentToggleSelection[level.key] === option.value
                            ? "primary"
                            : "borderless"
                        }
                      >
                        {option?.icon ? <option.icon size="regular" /> : <></>}
                      </IconStyledButton>
                    ))}
                  </ButtonGroup>
                </Fragment>
              ) : (
                <Toggle
                  id={level.value}
                  label={translatePageLayout(level.translation)}
                  onChange={(value: boolean) =>
                    handleToggleChange(value, level.key, level.optionsValue)
                  }
                  value={
                    currentToggleSelection[level.key] === level.optionsValue[0]
                  }
                />
              )}
            </Row>
          )
        )}
        <ResetStyledButton onClick={() => resetToDefault()}>
          <FormattedMessage defaultMessage="Reset to Default" />
        </ResetStyledButton>
      </PageLayoutSelectorWrapper>
      <PageLayoutPreview
        previewProps={currentToggleSelection}
        enableLivePreview={enableLivePreview}
        currentCategory={currentCategory}
        onChangeAspectRatio={
          enableMediaOverridesV2 ? onChangeAspectRatioV2 : onChangeAspectRatio
        }
        aspectRatioOptions={aspectRatioOptions}
        aspectRatioValue={aspectRatioValue}
        imageUrl={imageUrl}
      />
    </Wrapper>
  );
}

PageLayoutSelector.displayName = "PageLayoutSelector";

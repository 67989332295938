import type { ContentSerializers } from "../types";
import type { RedirectInput } from "@types";
import { serializerFor, entityMetadata } from "../utils";
import { statusCode } from "../fields";

const RedirectFields = {
  statusCode: statusCode,
};

export const RedirectSerializers: ContentSerializers<RedirectInput> = {
  save: (changes, model, { currentUser }) => {
    if (!("target" in model) && !("statusCode" in model)) {
      return null;
    }
    return {
      target: model.target,
      statusCode: model.statusCode,
      ...serializerFor(RedirectFields)(changes),
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, config }) => {
    return {
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      target: config.hostnames.consumer,
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

import { useMemo } from "react";
import { RedirectIcon } from "@condenast/gemini/icons";
import { Button } from "@components";
import { useIntl } from "react-intl";
import styled, { DefaultTheme } from "styled-components";
import { SyndicationData } from "@types";
import { ThemeProvider } from "@contexts";

const ShowViewOriginalButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
  color: ${(props) => props.theme.Color};
  svg {
    left: 0;
    margin-right: var(--spacing-xxs);
  }
  span {
    font: ${(props) => props.theme.FontSmallStatement};
    font-weight: 500;
  }
  text-decoration: underline;
`;

function transformBlue(theme: DefaultTheme) {
  return {
    ...theme,
    Color: "var(--color-blue-100)",
  };
}

export function ViewOriginal(props: {
  syndications: SyndicationData;
  className?: string;
}) {
  const intl = useIntl();
  const { syndications, className } = props;

  const uri = useMemo(() => syndications.canonicalUrl, [syndications]);
  const showViewOriginal = useMemo(() => syndications.sourceId, [syndications]);

  return (
    <>
      {showViewOriginal && (
        <ThemeProvider tint="blue" transform={transformBlue}>
          <ShowViewOriginalButton
            treatment="text"
            as="a"
            href={uri}
            target="_blank"
            rel="noreferrer noopener"
            className={className}
          >
            <RedirectIcon size="small" />
            <span>
              {intl.formatMessage({
                defaultMessage: "View Original",
                description: "View Original info button",
              })}
            </span>
          </ShowViewOriginalButton>
        </ThemeProvider>
      )}
    </>
  );
}
ViewOriginal.displayName = "ViewOriginal";

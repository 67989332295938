let escapedConfig = document
  .querySelector(`meta[name="config"]`)
  ?.getAttribute("content");

let config: Record<string, number | string | boolean> = {};

if (escapedConfig) {
  config = JSON.parse(unescape(escapedConfig));
}

export { config };

import { useState, useEffect, useCallback, useMemo, useContext } from "react";
import {
  ResyncDiffStatus,
  ContentResyncErrorMessages,
  ContentResyncHook,
  ContentResyncData,
  ContentResyncToastHook,
  ContentResyncToastProps,
  ContentResyncCurrentUser,
  GetCurrentUser_currentUser,
  Organization,
} from "@types";
import { usePresence, useToast } from "@hooks";
import { SnowplowContext } from "@contexts";
import { useApolloClient, useQuery, ApolloQueryResult } from "@apollo/client";
import { Queries, Variables } from "@gql";
import { useIntl } from "react-intl";
const { toastPropsVar } = Variables;

const errorTypes: ContentResyncErrorMessages = {
  "400": "error",
  "500": "error",
  "404": "not_found",
  "409": "warning",
};

function useResyncRequest(
  data: ContentResyncData,
  user: ContentResyncCurrentUser
): () => Promise<ApolloQueryResult<any>> {
  const client = useApolloClient();
  const sourceId = data.syndication?.sourceId;

  const variables = {
    syndicationAuthor: `${user.lastName} ${user.firstName}`,
    sourceId,
    targetId: data.id,
  };

  return function () {
    return client.query({
      query: Queries.PARROT_RESYNC,
      variables,
    });
  };
}

const useToastMessages: ContentResyncToastHook = () => {
  const intl = useIntl();
  const onClose = () => {};

  const showResyncSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Gallery slides have been added",
      description: "display message once resync of content is done",
    }),
  });

  const showResyncFailedMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Gallery resync failed",
      description: "display message when resync is failed",
    }),
    onClose,
  });

  const showResyncNotAvailableMessage = useToast({
    type: "informational",
    children: intl.formatMessage({
      defaultMessage: "Resyncing is not available!",
      description: "display a message when resync is not available!",
    }),
  });

  const showResyncTimeoutMessage = useToast({
    type: "warning",
    children: intl.formatMessage({
      defaultMessage: "Resync took longer than expected.",
      description: "Resync timed out title",
    }),
    details: intl.formatMessage({
      defaultMessage: "Please click Refresh to check status",
      description: "Resync timed out description",
    }),
    onClose,
  });

  return (props: ContentResyncToastProps) => {
    switch (props.type) {
      case "success":
        showResyncSuccessMessage();
        break;
      case "error":
        showResyncFailedMessage();
        break;
      case "not_found":
        showResyncNotAvailableMessage();
        break;
      case "warning":
        showResyncTimeoutMessage();
        break;
      default:
        break;
    }
  };
};

const useResyncError = () => {
  const [isTimeoutError, setIsTimeoutError] = useState(false);
  const showToastMessage = useToastMessages();

  const onResyncError = (error: any) => {
    if (error instanceof Error) {
      const errorMessage = error?.message;
      const errorType = Object.keys(errorTypes).find((type) =>
        errorMessage?.includes(type)
      );

      if (errorType) {
        const toastType = errorTypes[errorType];
        showToastMessage({ type: toastType });
      } else {
        showToastMessage({ type: "warning" });
        setIsTimeoutError(true);
      }
    }
  };

  return { onResyncError, isTimeoutError };
};

const useResyncSocketMessages = (
  currentUser: GetCurrentUser_currentUser,
  currentOrganization: Organization,
  refetch: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const client = useApolloClient();
  const showToastMessage = useToastMessages();
  const { syndicationResyncAvailableData, syndicationResyncCompletedData } =
    usePresence(currentUser.id, currentOrganization.organizationId, "spaces");
  useEffect(() => {
    const onResyncAvailable = async () => {
      try {
        await refetch();
      } catch (error) {
        // don't do anything
      }
    };
    if (syndicationResyncAvailableData) onResyncAvailable();
  }, [syndicationResyncAvailableData]);

  useEffect(() => {
    const onResyncComplete = async () => {
      try {
        await client.refetchQueries({
          include: ["FormFor"],
        });
        await refetch();
        showToastMessage({ type: "success" });
      } catch (error) {
        // don't do anything
      } finally {
        setIsLoading(false);
      }
    };
    if (syndicationResyncCompletedData) onResyncComplete();
  }, [syndicationResyncCompletedData]);
};

function useDiffStatus(
  data: ContentResyncData,
  gallerySlidesResyncEnabled: boolean
) {
  const { data: diffStatusData, refetch } = useQuery<ResyncDiffStatus>(
    Queries.RESYNC_DIFFSTATUS,
    {
      fetchPolicy: "network-only",
      skip: !(
        data?.typename === "Gallery" &&
        data.syndication?.sourceId &&
        gallerySlidesResyncEnabled
      ),
      variables: {
        sourceId: data.syndication?.sourceId,
        targetId: data.id,
      },
    }
  );

  const [showResyncBanner, setShowResyncBanner] = useState(
    !!diffStatusData?.diffStatus.hasChanges
  );

  useEffect(() => {
    if (
      data?.typename === "Gallery" &&
      data.syndication?.sourceId &&
      gallerySlidesResyncEnabled
    ) {
      setShowResyncBanner(!!diffStatusData?.diffStatus.hasChanges);
    }
  }, [data, diffStatusData, gallerySlidesResyncEnabled]);

  return { showResyncBanner, refetch };
}

export function useContentResync(
  data: ContentResyncData,
  user: ContentResyncCurrentUser,
  gallerySlidesResyncEnabled: boolean,
  currentUser: GetCurrentUser_currentUser,
  currentOrganization: Organization
): ContentResyncHook {
  const client = useApolloClient();
  const { trackComponentEvent } = useContext(SnowplowContext);
  const showToastMessage = useToastMessages();
  const { showResyncBanner, refetch } = useDiffStatus(
    data,
    gallerySlidesResyncEnabled
  );
  const resyncRequestSubmit = useResyncRequest(data, user);
  const [isLoading, setIsLoading] = useState(false);
  const { onResyncError, isTimeoutError } = useResyncError();
  useResyncSocketMessages(
    currentUser,
    currentOrganization,
    refetch,
    setIsLoading
  );

  const resyncRequest = useCallback(async () => {
    if (isLoading) return;
    const eventType = isTimeoutError ? "Refresh" : "click";
    trackComponentEvent("content_resync", eventType);
    toastPropsVar(undefined);
    setIsLoading(true);
    try {
      await resyncRequestSubmit();
    } catch (error) {
      onResyncError(error);
    }
  }, [data, user, client, refetch, showToastMessage]);

  const hookResult = useMemo(() => {
    return {
      resyncRequest,
      showResyncBanner,
      isLoading,
      isTimeoutError,
    };
  }, [resyncRequest, showResyncBanner, isLoading, isTimeoutError]);

  return hookResult;
}

import {
  ContentContributorFields,
  ContentContributorFields_edges_node,
  ContributorRelationshipInput,
} from "@types";

export function allContributors(
  input?: ContentContributorFields | null
): ContributorRelationshipInput[] | null {
  return (
    input?.edges?.map((edge) => {
      const node = edge?.node as ContentContributorFields_edges_node;
      return {
        id: node.id,
        contributorType: edge?.type ?? "",
        name: node.name,
      };
    }) ?? null
  );
}

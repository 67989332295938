import {
  ControlProps,
  FormFor_form_controls_TimezoneFormControl,
} from "@types";
import { FormattedMessage, useIntl } from "react-intl";
import { Field } from "@components";
import styled from "styled-components";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const StyledField = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "input";

  & button {
    margin-left: var(--spacing-xxs);
  }
`;

const TimezoneDisplay = styled.span`
  grid-area: input;
  padding: var(--spacing-xs) 0;
  font: ${(props) => props.theme.FontBody};
`;

export function Timezone(
  props: ControlProps<FormFor_form_controls_TimezoneFormControl>
) {
  const { id, currentOrganization } = props;
  const intl = useIntl();

  const copilotCode = currentOrganization.metadata.copilotCode;

  const localTimezone = useMemo(
    () =>
      intl
        .formatTimeToParts(new Date(), {
          timeZoneName: "short",
        })
        .find(({ type }) => type === "timeZoneName")?.value,
    [intl]
  );

  return (
    <StyledField
      label={intl.formatMessage({
        defaultMessage: "Zone",
        description: "Label for Timezone field",
      })}
      id={`${id}-Timezone`}
      help={
        <FormattedMessage
          defaultMessage="The timezone name is translated according to your system language. You can change your system language in your <a>profile</a>"
          description="contextualHelp.timeZoneHelper"
          values={{
            a: (link: string) => (
              <Link
                target="_blank"
                to={`/${copilotCode}/users/profile`}
                rel="noreferrer"
              >
                {link}
              </Link>
            ),
          }}
        />
      }
    >
      <TimezoneDisplay>{localTimezone}</TimezoneDisplay>
    </StyledField>
  );
}
Timezone.displayName = "Control(Timezone)";

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
// import { required } from "@condenast/copilot-validators";
import { FormFor_content_Rcalist } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";

const { object } = validators;

const rcalistValidators = object({});

export default function validateRcalist(
  rcalist: FormFor_content_Rcalist,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(rcalist, build(rcalistValidators), context, env);
}

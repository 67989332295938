import {
  Button,
  Card,
  CKEditor,
  Field,
  Metadata,
  RoutableLink,
} from "@components";
import {
  ContentTypeIcon,
  CloseIcon,
  RedirectIcon,
  DraggableIcon,
} from "@condenast/gemini/icons";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { CKEditorConfiguration, FormError, SlideData } from "@types";
import { useDefinedMessages, useUniqueId } from "@hooks";
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  MouseEventHandler,
} from "react";
import computeScrollIntoView from "compute-scroll-into-view";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import CopilotMarkdownRenderer from "@condenast/atjson-renderer-copilot-markdown";
import PlainTextRenderer from "@atjson/renderer-plain-text";
import VersoSource from "@condenast/atjson-source-verso";
import {
  BlockEditor,
  InlineEditor,
  MinimalistEditor,
} from "@condenast/ckeditor5-build-condenast";
import { Asset, EmptyAsset } from "./Asset";
import { useInView } from "react-intersection-observer";

const Slide = styled(Card)`
  position: relative;
  display: grid;
  column-gap: var(--spacing-md);
  row-gap: var(--spacing-sm);
  padding-top: var(--spacing-sm);
  width: 100%;

  @media (max-width: 650px) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "asset actions"
      "fields fields";
  }

  @media (min-width: 650px) {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "asset fields actions";
  }

  .draggable-icon {
    display: none;
    margin: 0 auto;
    color: var(--color-gray-4);
  }

  &:hover .draggable-icon {
    display: block;
  }
`;

const ContextualFields = styled.div`
  grid-area: fields;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
`;

const MetadataDisplay = styled(RoutableLink)`
  grid-area: metadata;
  position: relative;
  width: 100%;
  border-radius: ${(props) => props.theme.CornerRadius};
  background: ${(props) => props.theme.SelectButtonBackgroundColor};
  padding: ${(props) => props.theme.SecondaryPadding};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;

  &:hover {
    background: ${(props) => props.theme.SelectButtonHoverBackgroundColor};
  }

  & div {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
  }
`;

const ActionContainer = styled.div`
  grid-area: actions;
  display: grid;
  height: fit-content;
  row-gap: var(--spacing-xs);
`;

const CloseButton = styled(Button)`
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  border-radius: var(--spacing-xs);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  & svg {
    margin: auto;
  }
`;

const SlideNumber = styled.div`
  font-size: ${(props) => props.theme.FontStatement};
  text-align: center;
  padding: ${(props) => props.theme.SecondarySmallPadding};
  border: 1px solid ${(props) => props.theme.DividerColor};

  min-width: var(--spacing-lg);
  height: var(--spacing-lg);
  border-radius: var(--spacing-xs);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

const EditorField = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

const Editor = styled(CKEditor)`
  grid-area: control;
`;

const EditorCaption = styled(CKEditor)`
  grid-area: control;
  display: table;
  width: 100%;

  p[data-placeholder] {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Builds = {
  block: BlockEditor,
  inline: InlineEditor,
  minimalist: MinimalistEditor,
};

function getErrorsForSlideField(
  errors: FormError[] | undefined,
  field: string
) {
  const errorsAtPath = errors?.filter(({ path }) => path[0] === field);
  return errorsAtPath && errorsAtPath.length > 0 ? errorsAtPath : undefined;
}

type GallerySlideProps = {
  index: number;
  slide: SlideData;
  cdnHost?: string;
  selectAsset: (slide: SlideData) => void;
  removeSlide: (index: number) => void;
  onChange: (slide: SlideData, index: number) => void;
  onUpload: (files: FileList, slide: SlideData) => void;
  isUploading?: boolean;
  titleRichTextConfig: CKEditorConfiguration;
  titleRichTextBuild: keyof typeof Builds;
  captionRichTextConfig: CKEditorConfiguration;
  captionRichTextBuild: keyof typeof Builds;
  slideBodyRichTextBuild?: keyof typeof Builds;
  slideBodyRichTextConfig?: CKEditorConfiguration;
  showSlideBody?: boolean | null;
  isMostRecentlyInserted?: boolean;
};

function LoadedGallerySlide(props: GallerySlideProps) {
  const {
    cdnHost,
    isUploading,
    slide,
    onChange,
    onUpload,
    removeSlide,
    selectAsset,
    titleRichTextConfig,
    titleRichTextBuild,
    captionRichTextConfig,
    captionRichTextBuild,
    slideBodyRichTextBuild,
    slideBodyRichTextConfig,
    showSlideBody,
    index,
    isMostRecentlyInserted,
  } = props;
  const intl = useIntl();
  const { translateFieldName, translateContentType } = useDefinedMessages();

  const scrollIntoView: (node: HTMLElement | null) => void = useCallback(
    (node) => {
      if (node && isMostRecentlyInserted) {
        let scrollActions = computeScrollIntoView(node, {
          scrollMode: "if-needed",
          block: "center",
        });
        scrollActions.forEach(({ el, top }) => {
          el.scrollTo({ top, behavior: "smooth" });
        });
      }
    },
    [isMostRecentlyInserted]
  );

  const [slideBodyWordCount, setSlideBodyWordCount] = useState(0);
  const [slideCaptionWordCount, setSlideCaptionWordCount] = useState(0);
  const slideBodyRichTextConfigWithWordCount = useMemo(
    () => ({
      ...slideBodyRichTextConfig,
      plugins: {
        ...(slideBodyRichTextConfig?.plugins ?? {}),
        wordCount: {
          onUpdate: (stats: { words: number }) => {
            setSlideBodyWordCount(stats.words);
          },
        },
      },
    }),
    [slideBodyRichTextConfig]
  );
  const captionRichTextConfigWithWordCount = useMemo(
    () => ({
      ...captionRichTextConfig,
      plugins: {
        ...(captionRichTextConfig?.plugins ?? {}),
        wordCount: {
          onUpdate: (stats: { words: number }) => {
            setSlideCaptionWordCount(stats.words);
          },
        },
      },
    }),
    [captionRichTextConfig]
  );

  // CKEditor requires an onChange that is stable across re-renders
  // so update local state on change and have a separate effect that
  // can be updated when props change
  const [title, setTitle] = useState<string | null>(null);
  const onTitleChange = useCallback(
    (doc) => {
      setTitle(CopilotMarkdownRenderer.render(doc));
    },
    [setTitle]
  );
  useEffect(() => {
    if (title !== null) {
      onChange({ ...slide, title }, index);
    }
    // only run when the title value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const [caption, setCaption] = useState<string | null>(null);
  const onCaptionChange = useCallback(
    (doc) => {
      setCaption(CopilotMarkdownRenderer.render(doc));
    },
    [setCaption]
  );
  useEffect(() => {
    if (caption !== null) {
      onChange({ ...slide, caption }, index);
    }
    // only run when the caption value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caption]);

  const [slideBody, setSlideBody] = useState<string | null>(null);
  const onSlideBodyChange = useCallback(
    (doc) => {
      setSlideBody(CopilotMarkdownRenderer.render(doc).trim());
    },
    [setSlideBody]
  );

  useEffect(() => {
    if (slideBody !== null) {
      onChange({ ...slide, body: slideBody }, index);
    }
    // only run when the body value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideBody]);
  const itemErrors = useMemo(
    () => getErrorsForSlideField(slide.errors, "item"),
    [slide.errors]
  );
  const titleErrors = useMemo(
    () => getErrorsForSlideField(slide.errors, "title"),
    [slide.errors]
  );

  const captionErrors = useMemo(
    () => getErrorsForSlideField(slide.errors, "caption"),
    [slide.errors]
  );

  const slideSelectAsset = useCallback(() => {
    selectAsset(slide);
  }, [slide, selectAsset]);

  const slideOnUpload = useCallback(
    (files: FileList) => {
      onUpload(files, slide);
    },
    [slide, onUpload]
  );

  const onRemoveSlide: MouseEventHandler = useCallback(
    (evt) => {
      evt.preventDefault();
      removeSlide(index);
    },
    [removeSlide, index]
  );

  const titleValue = useMemo(
    () =>
      CopilotMarkdownSource.fromRaw(slide.title ?? "").convertTo(VersoSource),
    [slide.title]
  );
  const titleAutogenValue = useMemo(
    () =>
      slide.item?.title?.content
        ? CopilotMarkdownSource.fromRaw(slide.item?.title.content).convertTo(
            VersoSource
          )
        : undefined,
    [slide.item?.title?.content]
  );

  const captionValue = useMemo(
    () =>
      CopilotMarkdownSource.fromRaw(slide.caption ?? "").convertTo(VersoSource),
    [slide.caption]
  );
  const captionAutogenValue = useMemo(
    () =>
      slide.item?.caption?.content
        ? CopilotMarkdownSource.fromRaw(slide.item?.caption.content).convertTo(
            VersoSource
          )
        : undefined,
    [slide.item?.caption?.content]
  );
  const captionPlaceHolderValue = useMemo(() => {
    const captionContentTypes = ["photo"];
    if (
      captionAutogenValue &&
      captionContentTypes.includes(slide.item?.contentType as string)
    ) {
      return PlainTextRenderer.render(captionAutogenValue).trim();
    }

    return null;
  }, [slide.item?.contentType, captionAutogenValue]);

  const slideBodyValue = useMemo(
    () =>
      CopilotMarkdownSource.fromRaw(slide.body ?? "").convertTo(VersoSource),
    [slide.body]
  );

  const id = useUniqueId();

  return (
    <Slide ref={scrollIntoView}>
      {!slide.item || !slide.item.editUrl ? (
        <EmptyAsset
          selectAsset={slideSelectAsset}
          onUpload={slideOnUpload}
          isUploading={isUploading}
          errors={itemErrors}
        />
      ) : (
        <Asset
          target="_blank"
          editUrl={slide.item.editUrl}
          item={slide.item}
          cdnHost={cdnHost}
          errors={itemErrors}
        />
      )}
      <ContextualFields>
        <EditorField
          label={translateFieldName("Slide Title")}
          id={`CKEditor__${id}__title`}
          errors={titleErrors}
        >
          <Editor
            onChange={onTitleChange}
            id={`CKEditor__${id}__title`}
            config={titleRichTextConfig}
            build={titleRichTextBuild}
            value={titleValue}
            autogeneratedValue={titleAutogenValue}
            aria-invalid={!!titleErrors?.length}
          />
        </EditorField>
        <EditorField
          label={translateFieldName("Slide Caption")}
          id={`CKEditor__${id}__caption`}
          errors={captionErrors}
        >
          <EditorCaption
            onChange={onCaptionChange}
            id={`CKEditor__${id}__caption`}
            config={captionRichTextConfigWithWordCount}
            wordCount={slideCaptionWordCount}
            build={captionRichTextBuild}
            value={captionValue}
            autogeneratedValue={captionAutogenValue}
            placeholder={captionPlaceHolderValue}
            aria-invalid={!!captionErrors?.length}
          />
        </EditorField>
        {showSlideBody && slideBodyRichTextBuild && (
          <EditorField
            label={translateFieldName("Slide Body")}
            id={`CKEditor__${id}__slide__body`}
          >
            <Editor
              onChange={onSlideBodyChange}
              id={`CKEditor__${id}__slide__body`}
              config={slideBodyRichTextConfigWithWordCount}
              wordCount={slideBodyWordCount}
              build={slideBodyRichTextBuild}
              value={slideBodyValue}
            />
          </EditorField>
        )}
        {slide.item && slide.item.editUrl && (
          <MetadataDisplay
            target="_blank"
            to={slide.item.editUrl ?? ""}
            aria-label={intl.formatMessage(
              {
                defaultMessage: `Edit {type}`,
              },
              { type: translateContentType(slide.item.contentType, 1) }
            )}
          >
            <div>
              <ContentTypeIcon
                contentType={slide.item.contentType}
                size="regular"
              />
              {translateContentType(slide.item.contentType, 1)}

              {slide.item?.metadata && (
                <Metadata metadata={slide.item?.metadata} />
              )}
            </div>
            <RedirectIcon size="regular" />
          </MetadataDisplay>
        )}
      </ContextualFields>
      <ActionContainer>
        <CloseButton
          size="small"
          aria-label={intl.formatMessage({
            defaultMessage: "Remove Slide",
          })}
          onClick={onRemoveSlide}
        >
          <CloseIcon size="small" />
        </CloseButton>
        <SlideNumber>{props.index + 1}</SlideNumber>
        <DraggableIcon size="regular" className="draggable-icon" />
      </ActionContainer>
    </Slide>
  );
}

const SlidePlaceholder = styled(Card)`
  min-height: calc(25.5 * var(--spacing-sm));
`;

export function GallerySlide(props: GallerySlideProps) {
  const [inViewRef, isVisible] = useInView({
    rootMargin: "40px",
    // Threshold is in the range [0, 1]. It is the percentage of the item
    // that needs to be in the viewport in order to be considered visible.
    // With the rootMargin, this means the item is marked as visible when it
    // is off-screen by less than 40px
    threshold: 0,
    triggerOnce: true,
    // If intersection observer is not supported, treat items as inView
    fallbackInView: true,
  });

  return (
    <div ref={inViewRef}>
      {isVisible ? <LoadedGallerySlide {...props} /> : <SlidePlaceholder />}
    </div>
  );
}

import { CreateCuratedSearch_content_CuratedSearch } from "@types";

export function csquery(input: CreateCuratedSearch_content_CuratedSearch) {
  const { filters } = input?.curatedSearchQuery || input;

  return {
    filters: {
      language: filters?.language,
      types: filters?.types ?? [],
      contentSource: filters?.contentSource ? filters?.contentSource : "",
      issueDate: filters?.issueDate || "",
      contributor: filters?.contributor,
    },
    categoriesFilterOperator:
      input?.curatedSearchQuery?.categoriesFilterOperator,
    notCategoriesFilterOperator:
      input?.curatedSearchQuery?.notCategoriesFilterOperator,
    size: input?.curatedSearchQuery?.size || 20,
    sort: input?.curatedSearchQuery?.sort || "",
    q: input?.curatedSearchQuery?.q || "",
  };
}

import {
  DashboardRefresh,
  defaultRefreshState,
  defaultRefreshType,
} from "@types";
/**
 *
 * @param setRefreshAPIs function hook on Dashboard page to update refresh status of the relative API call
 * @param sectionName Section Name of the API Call
 * @param refreshAPIs refresh hook
 */
export const updateRefreshStatus = (
  setRefreshAPIs: (status: DashboardRefresh) => void,
  sectionName: string,
  refreshAPIs: DashboardRefresh,
  APIState: defaultRefreshState
) => {
  if (sectionName in refreshAPIs) {
    const updatedRefreshStatus = {
      ...refreshAPIs[sectionName],
    } as defaultRefreshType;

    if (updatedRefreshStatus.status === true && APIState === "loading") {
      const newRefreshAPIs = { ...refreshAPIs } as DashboardRefresh;
      updatedRefreshStatus.refreshState = "loading";
      newRefreshAPIs[sectionName] = updatedRefreshStatus;
      setRefreshAPIs(newRefreshAPIs);
    } else if (updatedRefreshStatus.status === true && APIState === "success") {
      const newRefreshAPIs = { ...refreshAPIs } as DashboardRefresh;
      updatedRefreshStatus.status = false;
      updatedRefreshStatus.refreshState = "success";
      newRefreshAPIs[sectionName] = updatedRefreshStatus;
      setRefreshAPIs(newRefreshAPIs);
    } else if (updatedRefreshStatus.status === true && APIState === "failed") {
      const newRefreshAPIs = { ...refreshAPIs } as DashboardRefresh;
      updatedRefreshStatus.status = false;
      updatedRefreshStatus.refreshState = "failed";
      newRefreshAPIs[sectionName] = updatedRefreshStatus;
      setRefreshAPIs(newRefreshAPIs);
    }
  }
};

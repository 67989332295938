import { defineMessages, FormattedMessage } from "react-intl";
import { Chip } from "@components";
import { ThemeProvider } from "@contexts";
import { UserStatus } from "@types";
import styled from "styled-components";

const Translations = defineMessages({
  [UserStatus.active]: {
    defaultMessage: "Active",
  },
  [UserStatus.deactivated]: {
    defaultMessage: "Deactivated",
  },
  [UserStatus.pending]: {
    defaultMessage: "Pending",
  },
});

const StatusChip = styled(Chip)`
  color: ${(props) => {
    if (props.theme._tint === "red") {
      return props.theme.StatusDisabledColor;
    } else if (props.theme._tint === "green") {
      return props.theme.StatusSuccessColor;
    } else {
      return props.theme.StatusPendingColor;
    }
  }};
  background: ${(props) => {
    if (props.theme._tint === "red") {
      return props.theme.StatusDisabledBackground;
    } else if (props.theme._tint === "green") {
      return props.theme.StatusSuccessBackground;
    } else {
      return props.theme.StatusPendingBackground;
    }
  }};
`;

export function UserStatusChip(props: { status: UserStatus }) {
  return (
    <ThemeProvider
      tint={
        props.status === UserStatus.active
          ? "green"
          : props.status === UserStatus.pending
          ? "yellow"
          : "red"
      }
    >
      <StatusChip size="small">
        <FormattedMessage {...Translations[props.status]} />
      </StatusChip>
    </ThemeProvider>
  );
}
UserStatusChip.displayName = "UserStatusChip";

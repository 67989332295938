import { Field, RadioGroup as RadioGroupImplementation } from "@components";
import { useDefinedMessages } from "@hooks";
import {
  ControlProps,
  FormFor_form_controls_RadioGroupFormControl,
} from "@types";
import { get } from "@lib";
import styled from "styled-components";

const FieldWrapper = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

const StyledRadioGroup = styled(RadioGroupImplementation)`
  grid-area: control;
`;

export function RadioGroup(
  props: ControlProps<FormFor_form_controls_RadioGroupFormControl>
) {
  const { name, setValue, optionsPath, options, model, errors } = props;
  const { translateFieldName, translateSelectOption } = useDefinedMessages();
  const value = (get(model, name) as string) ?? "";
  const translatedLabel = translateFieldName(name);

  const transformedOptions =
    options?.options.map((option) => {
      return {
        label: translateSelectOption(optionsPath ?? "", option),
        value: option,
        disabled: options?.disabledOptions?.includes(option),
      };
    }) ?? [];

  return (
    <FieldWrapper
      label={translatedLabel}
      id={`RadioGroup__${name}`}
      errors={errors}
    >
      <StyledRadioGroup
        aria-label={translateFieldName(name)}
        value={value}
        onChange={(value: string | undefined) => setValue(name, value)}
        options={transformedOptions}
      />
    </FieldWrapper>
  );
}
RadioGroup.displayName = "Control(RadioGroup)";

import { BrandOptions } from "@types";

export function compareBrandOptions(a: BrandOptions, b: BrandOptions) {
  let optA = a.label?.toLowerCase();
  let optB = b.label?.toLowerCase();
  if (!optA && !optB) {
    return 0;
  }
  if (!optA) {
    return -1;
  }
  if (!optB) {
    return 1;
  }
  if (optA < optB) {
    return -1;
  }
  if (optA > optB) {
    return 1;
  }
  return 0;
}

import { useCallback, useMemo } from "react";
import { Field, SearchMultiselect } from "@components";
import { useDebouncedCallback, useUniqueId } from "@hooks";
import {
  Category,
  Option,
  ControlProps,
  FormFor_form_controls_TagSearchFormControl,
} from "@types";
import { Queries } from "@gql";
import { useLazyQuery } from "@apollo/client";
import styled from "styled-components";
import { errorsForField, get } from "@lib";

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-xxs) 0;
  grid-template-rows: auto auto;
  grid-template-areas:
    "label"
    "control";
`;

const SEARCH_DEBOUNCE_INTERVAL = 250; // milliseconds

interface CategoryData {
  name: string | null;
  id: string;
  slug: string;
  hierarchy: { name: string | null; slug: string }[];
  meta?: {
    childCount: number | null;
  } | null;
  root: { name: string | null; slug: string };
}

function getHierarchyTree(category: Category): string {
  let label = "";
  category.hierarchy.forEach((item, index) => {
    if (index === category.hierarchy.length - 1) label += ` ${item.name}`;
    else label += ` ${item.name} >`;
  });
  return label;
}

function normalizeCategoryToOption(category: Category): Option<Category> {
  return {
    key: category.id,
    value: category,
    label: getHierarchyTree(category),
  };
}

function categoryToOption(category: Category): Option<Category> {
  return {
    key: category.id,
    value: category,
    label: category.name || "",
  };
}

function normalizeCategoryData(categories: CategoryData[]): Category[] {
  const transformedCategories: Category[] = categories.map((category) => ({
    id: category.id,
    name: category.name ?? "",
    slug: category.slug,
    hierarchy: category.hierarchy
      .map((h) => ({ name: h.name ?? "", slug: h.slug }))
      .reverse(),
    childCount: category.meta?.childCount ?? undefined,
    isTag: false,
  }));
  return transformedCategories;
}

function serializeCategories(categories: Category[]): CategoryData[] {
  return categories.map(({ id, name, slug, hierarchy }) => ({
    id,
    name,
    slug,
    hierarchy: [...hierarchy].reverse(),
    root: hierarchy[0],
  }));
}

export function TagSearch(
  props: ControlProps<FormFor_form_controls_TagSearchFormControl>
) {
  const { currentOrganization, model, errors, name, options, setValue } = props;
  const uniqueId = useUniqueId();
  const tagErrors = errorsForField(errors, "categoriesTags");
  const defaultContent: string[] = options?.options || [];

  const modelValue = useMemo(
    () => get(model, name) as Category[],
    [model, name]
  );

  const [_lazySearch, { data: searchData, loading, error }] = useLazyQuery(
    Queries.CATEGORY_SEARCH
  );

  const selections = modelValue?.map(categoryToOption);

  const preformSearch = useDebouncedCallback((query: string) => {
    if (!query) return;
    _lazySearch({
      variables: {
        organizationId: currentOrganization.organizationId,
        filters: {
          q: query || "",
          hierarchy: "cne-video,tags",
          d_o: "or",
          isroot: false,
          limit: 50,
        },
      },
    });
  }, SEARCH_DEBOUNCE_INTERVAL);

  const normalizedSearch: Option<Category>[] = searchData
    ? normalizeCategoryData(searchData.categorySearch).map(
        normalizeCategoryToOption
      )
    : [];

  const searchError = error ? [error] : undefined;
  const onChange = useCallback(
    (selections: Category[]) => {
      const categories: CategoryData[] = serializeCategories(selections);
      setValue(name, categories);
      let selectedContentTypes: string[] = [];
      if (categories.length) {
        selectedContentTypes =
          categories[0].hierarchy[
            categories[0].hierarchy.length - 1
          ].slug?.toLowerCase() === "cne-video"
            ? ["cnevideo"]
            : defaultContent;
      }
      setValue("query.filters.types", selectedContentTypes);
    },
    [setValue, name]
  );

  return (
    <Wrapper id={`TagSearch-${uniqueId}`} label="Tag" errors={tagErrors}>
      <SearchMultiselect
        aria-invalid={!!tagErrors.length}
        selections={selections}
        search={preformSearch}
        searchResults={normalizedSearch}
        searchErrors={searchError}
        loading={loading}
        multiple={false}
        onChange={onChange}
      />
    </Wrapper>
  );
}
TagSearch.displayName = "Control(TagSearch)";

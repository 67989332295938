import { Button, Select } from "@components";
import { startOfDay, endOfDay, getMonth, getYear } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@condenast/gemini/icons";
import { useState, forwardRef, Ref, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

const DateWrapper = styled.div`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: var(--spacing-xxs);
  float: right;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 var(--spacing-xxs);
`;

const StyledSelect = styled(Select)`
  min-width: calc(var(--spacing-xxl) * 2.7);
`;

export const CustomDateRangePicker = forwardRef(function (
  props: {
    style: object;
    setShowPopper: (item: boolean) => void;
    onChange: (dateFilterSelection: string) => void;
  },
  forwardRef: Ref<HTMLInputElement>
) {
  const { onChange, setShowPopper } = props;
  const [internalStartDate, setInternalStartDate] = useState(
    startOfDay(new Date())
  );
  const [internalEndDate, setInternalEndDate] = useState(endOfDay(new Date()));
  // Creating the years array - 1990 to three years past current year
  const startYear = 1990;
  const endYear = new Date().getFullYear() + 3;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  ).reverse();
  const intl = useIntl();

  const monthOptions = useMemo(
    () =>
      Array.from({ length: 12 }, (_, index) => ({
        label: intl.formatDate(new Date(2000, index, 1), {
          month: "long",
        }),
        value: index.toString(),
      })),
    [intl]
  );

  const onDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setInternalStartDate(start);
    setInternalEndDate(end);
  };

  const onApply = () => {
    // const adjustedEndDate = new Date(internalEndDate);
    // adjustedEndDate.setDate(internalEndDate.getDate() + 1);
    // I want to remove the time from the date to make the url more readable
    // onChange(
    //   `${internalStartDate.toISOString().slice(0, 10)},${adjustedEndDate
    //     .toISOString()
    //     .slice(0, 10)}`
    // );
    onChange(
      internalStartDate && internalEndDate
        ? `${internalStartDate.toISOString()}to${endOfDay(
            internalEndDate
          ).toISOString()}`
        : `${internalStartDate.toISOString()}to${endOfDay(
            internalStartDate
          ).toISOString()}`
    );
    setShowPopper(false);
  };

  return (
    <>
      <DateWrapper ref={forwardRef}>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <HeaderWrapper>
              <Button
                aria-label={intl.formatMessage({
                  defaultMessage: "Previous Month",
                  description:
                    "Label for going to the previous month in a calendar",
                })}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <ChevronLeftIcon size="small" />
              </Button>
              <StyledSelect
                id="month-select"
                options={monthOptions}
                value={getMonth(date).toString()}
                onChange={(value: string | undefined) =>
                  changeMonth(Number(value))
                }
              />
              <Select
                id="year-select"
                options={years.map((option) => ({
                  label: option.toString(),
                  value: option.toString(),
                }))}
                value={getYear(date).toString()}
                onChange={(value) => changeYear(Number(value))}
              />
              <Button
                aria-label={intl.formatMessage({
                  defaultMessage: "Next Month",
                  description:
                    "Label for going to the next month in a calendar",
                })}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <ChevronRightIcon size="small" />
              </Button>
            </HeaderWrapper>
          )}
          selected={internalStartDate}
          onChange={onDateChange}
          startDate={internalStartDate}
          endDate={internalEndDate}
          selectsRange
          inline
          showDisabledMonthNavigation
        />
      </DateWrapper>
      <ButtonGroup>
        <Button
          onClick={() => {
            setShowPopper(false);
          }}
        >
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button treatment="primary" onClick={onApply}>
          <FormattedMessage defaultMessage="Apply" />
        </Button>
      </ButtonGroup>
    </>
  );
});
CustomDateRangePicker.displayName = "CustomDateRangePicker";

import type { ContentSerializers } from "../types";
import type { ContributorInput } from "@types";
import {
  categoryTaxonomies,
  contentConnection,
  socialMediaItems,
} from "../fields";
import {
  serializerFor,
  includeAllMetadataFields,
  entityMetadata,
} from "../utils";

const ContributorFields = {
  categoryTaxonomies,
  photosTout: contentConnection,
  socialMedia: socialMediaItems,
  featuredImg: contentConnection,
  featuredStories: contentConnection,
  relatedContent: contentConnection,
} as const;

export const ContributorSerializers: ContentSerializers<ContributorInput> = {
  save: (changes, model, { currentUser }) => {
    if (!("name" in model)) {
      return null;
    }
    let metadataFields = includeAllMetadataFields(model, changes);
    return {
      name: model.name,
      ...serializerFor(ContributorFields)(changes),
      ...metadataFields,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
    return {
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      name: createTitle,
      socialMedia: [],
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

import styled from "styled-components";
import { NextIcon, BackIcon } from "@condenast/gemini/icons";
import { Button } from "@components";
import { SearchFilters } from "@types";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { isEqual } from "lodash";

const Footer = styled.div<{ showCompletePaginationInfo: boolean }>`
  position: relative;
  bottom: 0;
  padding: var(--spacing-xs) var(--spacing-sm);
  width: 100%;
  display: grid;
  border-top: 1px solid var(--color-gray-5);
  ${(props) =>
    props.showCompletePaginationInfo
      ? "grid-template-columns: 4fr 3fr 1fr;"
      : "grid-template-columns: 4fr; text-align: center;"}

  @media (max-width: 2560px) {
    ${(props) =>
      props.showCompletePaginationInfo &&
      "grid-template-columns:  3fr 3fr 1fr;"}
  }

  @media (max-width: 1280px) {
    ${(props) =>
      props.showCompletePaginationInfo &&
      "grid-template-columns:  2fr 3fr 1fr;"}
  }

  @media (max-width: 1024px) {
    ${(props) =>
      props.showCompletePaginationInfo &&
      "grid-template-columns:  3.5fr 3.5fr 2fr;"}
  }

  @media (max-width: 600px) {
    ${(props) =>
      props.showCompletePaginationInfo &&
      "grid-template-columns:  3.5fr 3fr 2fr;"}
  }

  @media (max-width: 360px) {
    ${(props) =>
      props.showCompletePaginationInfo &&
      "grid-template-columns: 4fr 3.5fr 2.5fr;"}
  }
`;

const FooterTitle = styled.h4`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: var(--color-blue-40);
  font-weight: 700;
  font-size: 14px;
  padding: var(--spacing-xxs) var(--spacing-xxs);

  a {
    text-decoration: none;
    color: inherit;
    &:visited,
    &:active {
      color: inherit;
    }
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`;

const ButtonWrapper = styled.span`
  text-align: right;
  width: fit-content;
  margin: auto;
`;

const PaginationButton = styled(Button)`
  color: var(--color-black);
  grid-template-columns: unset;
`;

const Count = styled.h5`
  padding: var(--spacing-xxs) var(--spacing-xxs);
  font: ${(props) => props.theme.FontSubSectionHeading};
  font-size: 14px;
  font-weight: 400;
`;

const Pagination = styled.div`
  width: fit-content;
  display: grid;
  justify-self: right;
`;

type PageDetail = {
  limit: number;
  offset: number;
  totalCount: number;
};

type Filters = SearchFilters & {
  collaborator?: string;
  date?: string;
};

type SearchFiltersStore = SearchFilters & {
  "store-relid"?: [];
  "store-privilege_user"?: [];
  "store-datefilter-label"?: string;
  "store-datefilter-type"?: string;
};

type locationState = {
  value: string;
  label: string;
};

type searchFilter = locationState & {
  key: string;
};

export const ListFooter = (props: {
  paginationInfo: boolean | null | undefined;
  pageDetail: PageDetail;
  updateCurrentPageDetail: (currentPageDetail: PageDetail) => void;
  queryFilter: Filters;
  brandCode: string;
  searchQueryFilter: SearchFiltersStore;
}) => {
  let {
    paginationInfo,
    pageDetail,
    updateCurrentPageDetail,
    queryFilter,
    brandCode,
    searchQueryFilter,
  } = props;

  const filterKeys: (keyof Filters)[] = [
    "types",
    "status",
    "relid",
    "privilege_user",
    "hierarchy",
    "publishdate_from",
  ];

  const [locationState, setLocationState] = useState<{
    users: locationState[];
    contributor: locationState[];
  }>({
    users: [],
    contributor: [],
  });

  const getFilteredData = useCallback(
    (data: searchFilter[], type: string): locationState[] => {
      let filteredData = data.map((k) => ({
        value: k.key,
        label: k.label,
      }));
      if (
        !isEqual(
          type === "user" ? locationState?.users : locationState?.contributor,
          filteredData
        )
      ) {
        return filteredData;
      }
      return [];
    },
    [locationState]
  );

  useEffect(() => {
    if (
      Object.keys(searchQueryFilter)?.length &&
      "store-privilege_user" in searchQueryFilter &&
      searchQueryFilter["store-privilege_user"]
    ) {
      const filteredData = getFilteredData(
        searchQueryFilter["store-privilege_user"] as searchFilter[],
        "user"
      );
      if (filteredData.length) {
        setLocationState({
          ...locationState,
          users: filteredData,
        });
      }
    }
    if (
      Object.keys(searchQueryFilter)?.length &&
      "store-relid" in searchQueryFilter &&
      searchQueryFilter["store-relid"]
    ) {
      const filteredData = getFilteredData(
        searchQueryFilter["store-relid"] as searchFilter[],
        "relid"
      );
      if (filteredData.length) {
        setLocationState({
          ...locationState,
          contributor: filteredData,
        });
      }
    }
  }, [searchQueryFilter, locationState, getFilteredData]);

  let searchQuery = ``;
  filterKeys.forEach((key) => {
    let k = key;
    if (queryFilter[key]) {
      let encodedUri = encodeURIComponent(queryFilter[key] || "");
      if (key === "privilege_user") {
        k = "collaborator";
      }
      if (key === "relid") {
        k = "contributor";
      }
      let searchStr = `${k}=${encodedUri}`;
      if (key === "publishdate_from") {
        let dateType = searchQueryFilter?.["store-datefilter-type"];
        searchStr =
          dateType && dateType === "Custom"
            ? `date=${new Date(
                searchQueryFilter?.publishdate_from ?? ""
              ).toISOString()},${new Date(
                searchQueryFilter?.publishdate_to ?? ""
              ).toISOString()}`
            : `date=${dateType?.replace(/ /g, "-").toLowerCase()}`;
      }
      if (!searchQuery.includes("?")) {
        searchQuery += `?${searchStr}`;
      } else {
        searchQuery += `&${searchStr}`;
      }
    }
  });

  let upperLimit;
  if (pageDetail.offset === 0) {
    upperLimit = pageDetail.limit;
  } else if (pageDetail.offset + pageDetail.limit > pageDetail.totalCount) {
    upperLimit = pageDetail.totalCount;
  } else {
    upperLimit = pageDetail.offset + pageDetail.limit;
  }

  let pageDetailCount =
    pageDetail && pageDetail.totalCount && upperLimit > pageDetail.totalCount
      ? `${pageDetail.offset + 1} - ${pageDetail.totalCount}`
      : `${pageDetail.offset + 1} - ${upperLimit} of ${pageDetail.totalCount}`;

  return (
    <>
      {pageDetail && pageDetail.totalCount ? (
        <Footer showCompletePaginationInfo={paginationInfo ?? false}>
          <FooterTitle>
            <Link
              to={{
                pathname: `/${brandCode}/search`,
                search: searchQuery,
              }}
              state={locationState}
            >
              View All
            </Link>
          </FooterTitle>
          {paginationInfo && (
            <>
              <Pagination>
                <Count>{pageDetailCount}</Count>
              </Pagination>
              <ButtonWrapper>
                <PaginationButton
                  treatment="borderless"
                  key="backButton"
                  size="small"
                  disabled={!pageDetail.offset && true}
                  onClick={(evt) => {
                    evt.preventDefault();
                    updateCurrentPageDetail({
                      limit: pageDetail.limit,
                      offset:
                        pageDetail.offset > pageDetail.limit
                          ? pageDetail.offset - pageDetail.limit
                          : 0,
                      totalCount: pageDetail.totalCount,
                    });
                  }}
                >
                  <BackIcon size="regular" />{" "}
                </PaginationButton>
                <PaginationButton
                  treatment="borderless"
                  size="small"
                  key="nextButton"
                  disabled={
                    (upperLimit === pageDetail.totalCount ||
                      upperLimit > pageDetail.totalCount) &&
                    true
                  }
                  onClick={(evt) => {
                    evt.preventDefault();
                    updateCurrentPageDetail({
                      limit: pageDetail.limit,
                      offset:
                        pageDetail.offset + pageDetail.limit <
                        pageDetail.totalCount
                          ? pageDetail.limit + pageDetail.offset
                          : pageDetail.totalCount - pageDetail.limit,
                      totalCount: pageDetail.totalCount,
                    });
                  }}
                >
                  <NextIcon size="regular" />
                </PaginationButton>
              </ButtonWrapper>
            </>
          )}
        </Footer>
      ) : (
        <></>
      )}
    </>
  );
};

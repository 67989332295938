import pluralize from "pluralize";
import { useContentAccessControlMatrix, useDefinedMessages } from "@hooks";
import { CreateMenuGroups } from "../../constants";
import type { CreateMenuItem, CreateMenuAccessReturnValue } from "../types";

import type {
  GetBrand_brandConfiguration_contentTypes as ContentTypes,
  GetBrand,
  GetBrandVariables,
  Organization,
} from "@types";
import { Queries } from "@gql";
import { useQuery } from "@apollo/client";

export function useCreateMenuAccess(
  organization: Organization,
  baseUrl?: string
): CreateMenuAccessReturnValue {
  const { translateContentType } = useDefinedMessages();
  const { data: brand } = useQuery<GetBrand, GetBrandVariables>(
    Queries.GET_BRAND,
    {
      variables: {
        organizationId: organization.organizationId,
      },
    }
  );

  let CONTENT_TYPE_TO_GROUP_MAP: Record<string, CreateMenuGroups> = {
    bundle: CreateMenuGroups.CONTAINERS,
    curatedlist: CreateMenuGroups.CONTAINERS,
    tagpage: CreateMenuGroups.CONTAINERS,
    package: CreateMenuGroups.CONTAINERS,
    photo: CreateMenuGroups.ASSETS,
    clip: CreateMenuGroups.ASSETS,
  };

  const pluralizedContentTypes = (
    brand?.brandConfiguration?.contentTypes || []
  ).map((r) => pluralize(r.value));
  const accessControlMatrix = useContentAccessControlMatrix(
    organization,
    "create",
    pluralizedContentTypes
  );

  let resolvedContentTypes: CreateMenuItem[] = (
    brand?.brandConfiguration?.contentTypes || []
  )
    .filter((contentType: ContentTypes) => {
      return contentType.discoverable && contentType.discoverable.createMenu;
    })
    .map((contentType: ContentTypes) => {
      return {
        label: translateContentType(contentType.value, 1, contentType.label),
        value: contentType.value,
        path: `${baseUrl ?? ""}/${pluralize(String(contentType.value))}/create`,
        section:
          CONTENT_TYPE_TO_GROUP_MAP[contentType.value] ||
          CreateMenuGroups.CONTENT,
      };
    });

  const createMenuItemMapInitialValues: CreateMenuAccessReturnValue = {
    assets: [],
    containers: [],
    content: [],
  };

  const createMenuItemMap = (resolvedContentTypes || [])
    .sort((a, b) => a.label.localeCompare(b.label))
    .reduce((acc, c) => {
      const hasCreatePermissions =
        accessControlMatrix[pluralize(c.value)].create;
      if (!hasCreatePermissions) {
        return acc;
      }
      switch (c.section) {
        case CreateMenuGroups.ASSETS:
          acc.assets.push(c);
          break;
        case CreateMenuGroups.CONTAINERS:
          acc.containers.push(c);
          break;
        default:
          acc.content.push(c);
          break;
      }
      return acc;
    }, createMenuItemMapInitialValues);

  return createMenuItemMap;
}

import type { ContentSerializers } from "../types";
import type { BusinessInput } from "@types";
import {
  contentConnection,
  categoryTaxonomies,
  socialMediaItems,
  venueAddress,
  seo,
  geo,
  businessContactPoint,
} from "../fields";
import {
  includeAllMetadataFields,
  serializerFor,
  entityMetadata,
} from "../utils";

const BusinessFields = {
  categoryTaxonomies,
  photosTout: contentConnection,
  photosLede: contentConnection,
  photos: contentConnection,
  socialMedia: socialMediaItems,
  address: venueAddress,
  seo: seo,
  geo,
  contactPoint: businessContactPoint,
} as const;

export const BusinessSerializers: ContentSerializers<BusinessInput> = {
  save: (changes, model, { currentUser }) => {
    let metadataFields = includeAllMetadataFields(model, changes);

    return {
      ...serializerFor(BusinessFields)(changes),
      ...metadataFields,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      hasDataConsent:
        "hasDataConsent" in model && model.hasDataConsent
          ? model.hasDataConsent
          : false,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
    return {
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      name: createTitle,
      hasDataConsent: false,
      socialMedia: [],
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

import { ARIA, BarChart, Field } from "@components";
import { SEOKeywords_seoKeywords } from "@types";
import { SearchIcon } from "@condenast/gemini/icons";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const MessageContainer = styled.div`
  padding: ${(props) => props.theme.SecondaryTightPadding};
  text-align: center;
`;

function isKeywordSearchable(keyword: string) {
  return keyword.trim().split(" ").length > 1;
}

const SEOKeywordEmptyMessage = (props: { value: string }) => {
  const { value } = props;
  // TODO: this is not locale sensitive but matches the behavior in the legacy app
  return (
    <MessageContainer>
      {!isKeywordSearchable(value) ? (
        <FormattedMessage defaultMessage="Enter at least two words to see top performing phrases" />
      ) : (
        <FormattedMessage defaultMessage="No results" />
      )}
    </MessageContainer>
  );
};

const StyledKeywordField = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

const SEOKeywordSuggestion = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-xxs);
  gap: var(--spacing-xxs);

  [aria-selected] & {
    background: ${(props) => props.theme.BorderlessHoverBackground};
  }
`;

const SEOKeywordSuggestionKeyword = styled.span`
  flex-grow: 1;
`;

const SEOKeywordTrends = styled(BarChart)`
  flex-shrink: 0;
`;

export function SEOKeywordField(props: {
  id: string;
  currentKeyword: string;
  onChange: (value: string) => void;
  suggestionsLoading?: boolean;
  suggestions?: SEOKeywords_seoKeywords[];
  onInput: (input: string) => void;
  onFocus: () => void;
  disabled?: boolean;
}) {
  const {
    id,
    currentKeyword,
    onChange,
    suggestions,
    suggestionsLoading,
    onInput,
    onFocus,
    disabled,
  } = props;

  const month = useMemo(() => new Date().getMonth(), []);
  const onSelect = useCallback(
    (item: SEOKeywords_seoKeywords) => onChange(item.keyword),
    [onChange]
  );

  return (
    <StyledKeywordField
      id={id}
      label={<FormattedMessage defaultMessage="SEO Keyword" />}
    >
      <ARIA.Combobox
        value={currentKeyword}
        icon={SearchIcon}
        EmptyMessage={SEOKeywordEmptyMessage}
        onChange={onInput}
        loading={suggestionsLoading}
        items={suggestions?.map((suggestion) => ({
          role: "option",
          value: suggestion,
        }))}
        onSelect={onSelect}
        onFocus={onFocus}
        disabled={disabled}
      >
        {({ keyword, value, trends }) => (
          <SEOKeywordSuggestion>
            <SEOKeywordSuggestionKeyword>{keyword}</SEOKeywordSuggestionKeyword>
            <span>{value}</span>
            {trends ? (
              <SEOKeywordTrends
                width={32}
                height={16}
                data={trends}
                fill={(_, index) => (index === month ? "#DA1E28" : "#778BFE")}
              ></SEOKeywordTrends>
            ) : (
              <></>
            )}
          </SEOKeywordSuggestion>
        )}
      </ARIA.Combobox>
    </StyledKeywordField>
  );
}

import { useMutation } from "@apollo/client";
import { Mutations } from "@gql";
import {
  CreateActivity,
  CreateActivityVariables,
  CreateAdvertiser,
  CreateAdvertiserVariables,
  CreateArticle,
  CreateArticleVariables,
  CreateAudioFile,
  CreateAudioFileVariables,
  CreateBrand,
  CreateBrandVariables,
  CreateBundle,
  CreateBundleVariables,
  CreateBusiness,
  CreateBusinessVariables,
  CreateCity,
  CreateCityVariables,
  CreateContributor,
  CreateContributorVariables,
  CreateCookbook,
  CreateCookbookVariables,
  CreateCuratedList,
  CreateCuratedListVariables,
  CreateCuratedSearch,
  CreateCuratedSearchVariables,
  CreateEvent,
  CreateEventVariables,
  CreateExternalLink,
  CreateExternalLinkVariables,
  CreateExternalReference,
  CreateExternalReferenceVariables,
  CreateGallery,
  CreateGalleryVariables,
  CreateInfoPage,
  CreateInfoPageVariables,
  CreateLiveStory,
  CreateLivestoryupdate,
  CreateLivestoryupdateVariables,
  CreateLiveStoryVariables,
  CreateMusicAlbum,
  CreateMusicAlbumVariables,
  CreateMusicGroup,
  CreateMusicGroupVariables,
  CreateMusicRecording,
  CreateMusicRecordingVariables,
  CreateMusicRelease,
  CreateMusicReleaseVariables,
  CreateNativeArticle,
  CreateNativeArticleVariables,
  CreateNativeGallery,
  CreateNativeGalleryVariables,
  CreateNativeRecipe,
  CreateNativeRecipeVariables,
  CreatePerson,
  CreatePersonVariables,
  CreateProduct,
  CreateProductVariables,
  CreateRcalist,
  CreateRcalistVariables,
  CreateRecipe,
  CreateRecipeVariables,
  CreateRedirect,
  CreateRedirectVariables,
  CreateReview,
  CreateReviewVariables,
  createTagpage,
  createTagpageVariables,
  CreateTaxonomy,
  CreateTaxonomyVariables,
  CreateVenue,
  CreateVenueVariables,
  UpdateActivity,
  UpdateActivityVariables,
  UpdateAdvertiser,
  UpdateAdvertiserVariables,
  UpdateArticle,
  UpdateArticleVariables,
  UpdateAudioFile,
  UpdateAudioFileVariables,
  UpdateBrand,
  UpdateBrandVariables,
  UpdateBundle,
  UpdateBundleVariables,
  UpdateBusiness,
  UpdateBusinessVariables,
  UpdateCartoon,
  UpdateCartoonVariables,
  UpdateCity,
  UpdateCityVariables,
  UpdateClip,
  UpdateClipVariables,
  UpdateContributor,
  UpdateContributorVariables,
  UpdateCookbook,
  UpdateCookbookVariables,
  UpdateCuratedList,
  UpdateCuratedListVariables,
  UpdateCuratedSearch,
  UpdateCuratedSearchVariables,
  UpdateEvent,
  UpdateEventVariables,
  UpdateExternalLink,
  UpdateExternalLinkVariables,
  UpdateExternalReference,
  UpdateExternalReferenceVariables,
  UpdateGallery,
  UpdateGalleryVariables,
  UpdateInfoPage,
  UpdateInfoPageVariables,
  UpdateLiveStory,
  UpdateLivestoryupdate,
  UpdateLivestoryupdateVariables,
  UpdateLiveStoryVariables,
  UpdateMusicAlbum,
  UpdateMusicAlbumVariables,
  UpdateMusicGroup,
  UpdateMusicGroupVariables,
  UpdateMusicRecording,
  UpdateMusicRecordingVariables,
  UpdateMusicRelease,
  UpdateMusicReleaseVariables,
  UpdateNativeArticle,
  UpdateNativeArticleVariables,
  UpdateNativeGallery,
  UpdateNativeGalleryVariables,
  UpdateNativeRecipe,
  UpdateNativeRecipeVariables,
  UpdatePerson,
  UpdatePersonVariables,
  UpdatePhoto,
  UpdatePhotoVariables,
  UpdateProduct,
  UpdateProductVariables,
  UpdateRcalist,
  UpdateRcalistVariables,
  UpdateRecipe,
  UpdateRecipeVariables,
  UpdateRedirect,
  UpdateRedirectVariables,
  UpdateReview,
  UpdateReviewVariables,
  updateTagpage,
  updateTagpageVariables,
  UpdateTaxonomy,
  UpdateTaxonomyVariables,
  UpdateVenue,
  UpdateVenueVariables,
} from "@types";
import * as serializers from "./serializers/content";

type MutationTypes = {
  activity: {
    save: [UpdateActivity, UpdateActivityVariables];
    create: [CreateActivity, CreateActivityVariables];
  };
  advertiser: {
    save: [UpdateAdvertiser, UpdateAdvertiserVariables];
    create: [CreateAdvertiser, CreateAdvertiserVariables];
  };
  article: {
    save: [UpdateArticle, UpdateArticleVariables];
    create: [CreateArticle, CreateArticleVariables];
  };
  nativearticle: {
    save: [UpdateNativeArticle, UpdateNativeArticleVariables];
    create: [CreateNativeArticle, CreateNativeArticleVariables];
  };
  cartoon: {
    save: [UpdateCartoon, UpdateCartoonVariables];
    create: [];
  };
  contributor: {
    save: [UpdateContributor, UpdateContributorVariables];
    create: [CreateContributor, CreateContributorVariables];
  };
  curatedlist: {
    save: [UpdateCuratedList, UpdateCuratedListVariables];
    create: [CreateCuratedList, CreateCuratedListVariables];
  };
  externallink: {
    save: [UpdateExternalLink, UpdateExternalLinkVariables];
    create: [CreateExternalLink, CreateExternalLinkVariables];
  };
  external: {
    save: [UpdateExternalReference, UpdateExternalReferenceVariables];
    create: [CreateExternalReference, CreateExternalReferenceVariables];
  };
  gallery: {
    save: [UpdateGallery, UpdateGalleryVariables];
    create: [CreateGallery, CreateGalleryVariables];
  };
  nativegallery: {
    save: [UpdateNativeGallery, UpdateNativeGalleryVariables];
    create: [CreateNativeGallery, CreateNativeGalleryVariables];
  };
  infopage: {
    save: [UpdateInfoPage, UpdateInfoPageVariables];
    create: [CreateInfoPage, CreateInfoPageVariables];
  };
  photo: {
    save: [UpdatePhoto, UpdatePhotoVariables];
    create: [];
  };
  product: {
    save: [UpdateProduct, UpdateProductVariables];
    create: [CreateProduct, CreateProductVariables];
  };
  recipe: {
    save: [UpdateRecipe, UpdateRecipeVariables];
    create: [CreateRecipe, CreateRecipeVariables];
  };
  nativerecipe: {
    save: [UpdateNativeRecipe, UpdateNativeRecipeVariables];
    create: [CreateNativeRecipe, CreateNativeRecipeVariables];
  };
  redirect: {
    save: [UpdateRedirect, UpdateRedirectVariables];
    create: [CreateRedirect, CreateRedirectVariables];
  };
  tagpage: {
    save: [updateTagpage, updateTagpageVariables];
    create: [createTagpage, createTagpageVariables];
  };
  brand: {
    save: [UpdateBrand, UpdateBrandVariables];
    create: [CreateBrand, CreateBrandVariables];
  };
  review: {
    save: [UpdateReview, UpdateReviewVariables];
    create: [CreateReview, CreateReviewVariables];
  };
  venue: {
    save: [UpdateVenue, UpdateVenueVariables];
    create: [CreateVenue, CreateVenueVariables];
  };
  bar: {
    save: [UpdateVenue, UpdateVenueVariables];
    create: [CreateVenue, CreateVenueVariables];
  };
  hotel: {
    save: [UpdateVenue, UpdateVenueVariables];
    create: [CreateVenue, CreateVenueVariables];
  };
  restaurant: {
    save: [UpdateVenue, UpdateVenueVariables];
    create: [CreateVenue, CreateVenueVariables];
  };
  ship: {
    save: [UpdateVenue, UpdateVenueVariables];
    create: [CreateVenue, CreateVenueVariables];
  };
  shop: {
    save: [UpdateVenue, UpdateVenueVariables];
    create: [CreateVenue, CreateVenueVariables];
  };
  skiresort: {
    save: [UpdateVenue, UpdateVenueVariables];
    create: [CreateVenue, CreateVenueVariables];
  };
  spa: {
    save: [UpdateVenue, UpdateVenueVariables];
    create: [CreateVenue, CreateVenueVariables];
  };
  audiofile: {
    save: [UpdateAudioFile, UpdateAudioFileVariables];
    create: [CreateAudioFile, CreateAudioFileVariables];
  };
  clip: {
    save: [UpdateClip, UpdateClipVariables];
    create: [];
  };
  taxonomy: {
    save: [UpdateTaxonomy, UpdateTaxonomyVariables];
    create: [CreateTaxonomy, CreateTaxonomyVariables];
  };
  cookbook: {
    save: [UpdateCookbook, UpdateCookbookVariables];
    create: [CreateCookbook, CreateCookbookVariables];
  };
  curatedsearch: {
    save: [UpdateCuratedSearch, UpdateCuratedSearchVariables];
    create: [CreateCuratedSearch, CreateCuratedSearchVariables];
  };
  person: {
    save: [UpdatePerson, UpdatePersonVariables];
    create: [CreatePerson, CreatePersonVariables];
  };
  musicalbum: {
    save: [UpdateMusicAlbum, UpdateMusicAlbumVariables];
    create: [CreateMusicAlbum, CreateMusicAlbumVariables];
  };
  musicrecording: {
    save: [UpdateMusicRecording, UpdateMusicRecordingVariables];
    create: [CreateMusicRecording, CreateMusicRecordingVariables];
  };
  musicgroup: {
    save: [UpdateMusicGroup, UpdateMusicGroupVariables];
    create: [CreateMusicGroup, CreateMusicGroupVariables];
  };
  musicrelease: {
    save: [UpdateMusicRelease, UpdateMusicReleaseVariables];
    create: [CreateMusicRelease, CreateMusicReleaseVariables];
  };
  rcalist: {
    save: [UpdateRcalist, UpdateRcalistVariables];
    create: [CreateRcalist, CreateRcalistVariables];
  };
  livestory: {
    save: [UpdateLiveStory, UpdateLiveStoryVariables];
    create: [CreateLiveStory, CreateLiveStoryVariables];
  };
  livestoryupdate: {
    save: [UpdateLivestoryupdate, UpdateLivestoryupdateVariables];
    create: [CreateLivestoryupdate, CreateLivestoryupdateVariables];
  };
  event: {
    save: [UpdateEvent, UpdateEventVariables];
    create: [CreateEvent, CreateEventVariables];
  };
  city: {
    save: [UpdateCity, UpdateCityVariables];
    create: [CreateCity, CreateCityVariables];
  };
  business: {
    save: [UpdateBusiness, UpdateBusinessVariables];
    create: [CreateBusiness, CreateBusinessVariables];
  };
  bundle: {
    save: [UpdateBundle, UpdateBundleVariables];
    create: [CreateBundle, CreateBundleVariables];
  };
};

type MutationTypeOf<
  T extends keyof MutationTypes,
  C extends "save" | "create"
> = MutationTypes[T][C];

const ContentMutations = {
  activity: {
    save: Mutations.SAVE_ACTIVITY,
    create: Mutations.CREATE_ACTIVITY,
  },
  advertiser: {
    save: Mutations.SAVE_ADVERTISER,
    create: Mutations.CREATE_ADVERTISER,
  },
  article: {
    save: Mutations.SAVE_ARTICLE,
    create: Mutations.CREATE_ARTICLE,
  },
  nativearticle: {
    save: Mutations.SAVE_NATIVE_ARTICLE,
    create: Mutations.CREATE_NATIVE_ARTICLE,
  },
  bundle: {
    save: Mutations.SAVE_BUNDLE,
    create: Mutations.CREATE_BUNDLE,
  },
  cartoon: {
    save: Mutations.SAVE_CARTOON,
    create: Mutations.UPDATE_MEDIA,
  },
  gallery: {
    save: Mutations.SAVE_GALLERY,
    create: Mutations.CREATE_GALLERY,
  },
  nativegallery: {
    save: Mutations.SAVE_NATIVE_GALLERY,
    create: Mutations.CREATE_NATIVE_GALLERY,
  },
  contributor: {
    save: Mutations.SAVE_CONTRIBUTOR,
    create: Mutations.CREATE_CONTRIBUTOR,
  },
  curatedlist: {
    save: Mutations.SAVE_CURATEDLIST,
    create: Mutations.CREATE_CURATEDLIST,
  },
  externallink: {
    save: Mutations.SAVE_EXTERNALLINK,
    create: Mutations.CREATE_EXTERNALLINK,
  },
  external: {
    save: Mutations.SAVE_EXTERNALREFERENCE,
    create: Mutations.CREATE_EXTERNALREFERENCE,
  },
  infopage: {
    save: Mutations.SAVE_INFOPAGE,
    create: Mutations.CREATE_INFOPAGE,
  },
  photo: {
    save: Mutations.SAVE_PHOTO,
    create: Mutations.UPDATE_MEDIA,
  },
  product: {
    save: Mutations.SAVE_PRODUCT,
    create: Mutations.CREATE_PRODUCT,
  },
  recipe: {
    save: Mutations.SAVE_RECIPE,
    create: Mutations.CREATE_RECIPE,
  },
  nativerecipe: {
    save: Mutations.SAVE_NATIVE_RECIPE,
    create: Mutations.CREATE_NATIVE_RECIPE,
  },
  redirect: {
    save: Mutations.SAVE_REDIRECT,
    create: Mutations.CREATE_REDIRECT,
  },
  tagpage: {
    save: Mutations.SAVE_TAGPAGE,
    create: Mutations.CREATE_TAGPAGE,
  },
  brand: {
    save: Mutations.SAVE_BRAND,
    create: Mutations.CREATE_BRAND,
  },
  review: {
    save: Mutations.SAVE_REVIEW,
    create: Mutations.CREATE_REVIEW,
  },
  venue: {
    save: Mutations.SAVE_VENUE,
    create: Mutations.CREATE_VENUE,
  },
  bar: {
    save: Mutations.SAVE_VENUE,
    create: Mutations.CREATE_VENUE,
  },
  hotel: {
    save: Mutations.SAVE_VENUE,
    create: Mutations.CREATE_VENUE,
  },
  restaurant: {
    save: Mutations.SAVE_VENUE,
    create: Mutations.CREATE_VENUE,
  },
  ship: {
    save: Mutations.SAVE_VENUE,
    create: Mutations.CREATE_VENUE,
  },
  shop: {
    save: Mutations.SAVE_VENUE,
    create: Mutations.CREATE_VENUE,
  },
  skiresort: {
    save: Mutations.SAVE_VENUE,
    create: Mutations.CREATE_VENUE,
  },
  spa: {
    save: Mutations.SAVE_VENUE,
    create: Mutations.CREATE_VENUE,
  },
  audiofile: {
    save: Mutations.SAVE_AUDIOFILE,
    create: Mutations.CREATE_AUDIOFILE,
  },
  clip: {
    save: Mutations.SAVE_CLIP,
    create: Mutations.UPDATE_MEDIA,
  },
  taxonomy: {
    save: Mutations.SAVE_TAXONOMY,
    create: Mutations.CREATE_TAXONOMY,
  },
  cookbook: {
    save: Mutations.SAVE_COOKBOOK,
    create: Mutations.CREATE_COOKBOOK,
  },
  curatedsearch: {
    save: Mutations.SAVE_CURATEDSEARCH,
    create: Mutations.CREATE_CURATEDSEARCH,
  },
  person: {
    save: Mutations.SAVE_PERSON,
    create: Mutations.CREATE_PERSON,
  },
  musicalbum: {
    save: Mutations.SAVE_MUSICALBUM,
    create: Mutations.CREATE_MUSICALBUM,
  },
  musicrecording: {
    save: Mutations.SAVE_MUSICRECORDING,
    create: Mutations.CREATE_MUSICRECORDING,
  },
  musicgroup: {
    save: Mutations.SAVE_MUSICGROUP,
    create: Mutations.CREATE_MUSICGROUP,
  },
  musicrelease: {
    save: Mutations.SAVE_MUSICRELEASE,
    create: Mutations.CREATE_MUSICRELEASE,
  },
  rcalist: {
    save: Mutations.SAVE_RCALIST,
    create: Mutations.CREATE_RCALIST,
  },
  livestory: {
    save: Mutations.SAVE_LIVESTORY,
    create: Mutations.CREATE_LIVESTORY,
  },
  livestoryupdate: {
    save: Mutations.SAVE_LIVESTORY_UPDATE,
    create: Mutations.CREATE_LIVESTORY_UPDATE,
  },
  event: {
    save: Mutations.SAVE_EVENT,
    create: Mutations.CREATE_EVENT,
  },
  city: {
    save: Mutations.SAVE_CITY,
    create: Mutations.CREATE_CITY,
  },
  business: {
    save: Mutations.SAVE_BUSINESS,
    create: Mutations.CREATE_BUSINESS,
  },
};

export function hasMutation(
  contentType: string
): contentType is keyof typeof ContentMutations {
  return contentType in ContentMutations;
}

export function useContentMutation<
  T extends keyof typeof ContentMutations,
  C extends "save" | "create"
>(contentType: T | string, context: C) {
  const mutation = hasMutation(contentType)
    ? ContentMutations[contentType][context]
    : null;
  const [save, saveResult] = useMutation<
    MutationTypeOf<T, C>[0],
    MutationTypeOf<T, C>[1]
  >(mutation ?? Mutations.LOG_OUT);

  if (!hasMutation(contentType)) {
    return [null, null, null];
  }
  const serializer = serializers[contentType][context];
  return [serializer, save, saveResult] as const;
}

import { useState } from "react";
import { Card, Select, TextField } from "@components";
import styled from "styled-components";
import type { SocialMediaOptions } from "@types";

const SelectBoxWrap = styled.div`
  width: 20%;
`;

const SearchBoxWarp = styled.div`
  width: 80%;
`;

const SearchPanelWrapper = styled(Card)`
  max-width: calc(var(--spacing-xl) * 100);
  padding: 0;
  box-shadow: none;
  display: flex;
`;

export const SocialMediaSelector = (props: {
  id: string;
  label?: string;
  socialMediaOptions: SocialMediaOptions[];
}) => {
  const { id, socialMediaOptions } = props;
  const [dropDownValue, setDropDownValue] = useState(
    socialMediaOptions[0].value
  );
  const [urlFieldValue, setUrlFieldValue] = useState("");

  return (
    <>
      <SearchPanelWrapper>
        <SelectBoxWrap>
          <Select
            id={`${id}-selectSocialMedia`}
            value={dropDownValue}
            aria-label="selectSocialMedia"
            options={socialMediaOptions}
            onChange={(value) => {
              if (value) {
                setDropDownValue(value);
              }
            }}
          />
        </SelectBoxWrap>
        <SearchBoxWarp>
          <TextField
            id={`${id}-urlField`}
            aria-label="urlField"
            value={urlFieldValue}
            onChange={(val: string) => {
              setUrlFieldValue(String(val).trim());
            }}
            multiline={false}
          />
        </SearchBoxWarp>
      </SearchPanelWrapper>
    </>
  );
};

SocialMediaSelector.displayName = "SocialMediaSelector";

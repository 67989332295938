import { FC } from "react";
import { CKEditor } from "../CKEditor";
import {
  ControlProps,
  FormFor_form_controls_ArticleEditorFormControl,
} from "@types";

export const ArticleEditor: FC<
  ControlProps<FormFor_form_controls_ArticleEditorFormControl> & {
    linkAutogen: boolean;
    linkAutogenConfigFields: string[];
  }
> = (props) => {
  const { children, linkAutogen, linkAutogenConfigFields, ...forwardProps } =
    props;

  const name = "body";

  return (
    <CKEditor
      {...forwardProps}
      id={`CKEditor__${name}__editor`}
      name={name}
      afmConfig={`articles.${name}`}
      disabled={false}
      characterCount={false}
      wordCount={true}
      noLabel={null}
      placeholderKey={null}
      contextualHelp={null}
      autogeneratePath={null}
      autogenerateConfig={null}
      label={null}
      linkAutogen={linkAutogen}
      linkAutogenConfigFields={linkAutogenConfigFields}
    >
      {children}
    </CKEditor>
  );
};
ArticleEditor.displayName = "Control(ArticleEditor)";

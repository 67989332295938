import { ComponentType, ReactNode, useState } from "react";
import { useQuery } from "@apollo/client";
import { Queries } from "@gql";
import type { GetCurrentUser } from "@types";
import * as Sentry from "@sentry/browser";
import { IntlProvider } from "@contexts";
import { Link, Toast } from "@components";
import { defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";

const Stacktrace = styled.ul`
  li:not(:first-child) {
    padding-left: var(--spacing-sm);
  }
`;

const ERROR_CODES = defineMessages({
  GRAPHQL_PARSE_ERROR: {
    defaultMessage: "GraphQL syntax error",
  },
  GRAPHQL_VALIDATION_FAILED: {
    defaultMessage: "Invalid GraphQL operation",
  },
  BAD_USER_INPUT: {
    defaultMessage: "Incorrect GraphQL input",
  },
  UNAUTHENTICATED: {
    defaultMessage: "Not authenticated",
  },
  FORBIDDEN: {
    defaultMessage: "Access not allowed",
  },
  PERSISTED_QUERY_NOT_FOUND: {
    defaultMessage: "GraphQL query not found",
  },
  PERSISTED_QUERY_NOT_SUPPORTED: {
    defaultMessage: "GraphQL query not supported",
  },
  INTERNAL_SERVER_ERROR: {
    defaultMessage: "Server error",
  },
});

export function withCurrentUser<Props>(Component: ComponentType<Props>) {
  function ComponentWithCurrentUser(
    props: Omit<Props, "currentUser"> & { fallback: ReactNode }
  ) {
    let { data, loading, error } = useQuery<GetCurrentUser>(
      Queries.GET_CURRENT_USER
    );
    let { fallback, ...forwardProps } = props;
    let [showStacktrace, setShowStacktrace] = useState(false);

    if (error) {
      if (error.message === "401: Unauthorized") {
        window.location.replace("/auth/login");
        return <>{fallback}</>;
      }

      Sentry.captureException(error);

      let graphQLError = error.graphQLErrors[0];
      let code = (graphQLError?.extensions?.code ??
        "INTERNAL_SERVER_ERROR") as keyof typeof ERROR_CODES;

      return (
        <Toast
          type="error"
          details={
            showStacktrace ? (
              <Stacktrace>
                {graphQLError?.extensions?.exception.stacktrace.map(
                  (message: string, index: number) => (
                    <li key={index}>{message}</li>
                  )
                )}
              </Stacktrace>
            ) : (
              error.message
            )
          }
          action={
            graphQLError && (
              <Link
                as="button"
                onClick={() => setShowStacktrace(!showStacktrace)}
              >
                {showStacktrace ? "Hide stacktrace" : "Show stacktrace"}
              </Link>
            )
          }
        >
          <FormattedMessage {...ERROR_CODES[code]} />
        </Toast>
      );
    } else if (loading) {
      return <>{fallback}</>;
    } else {
      return (
        <IntlProvider locale={data?.currentUser?.locale ?? "en-GB"}>
          {/* @ts-expect-error currentUser isn't always defined on the component */}
          <Component {...forwardProps} currentUser={data?.currentUser} />
        </IntlProvider>
      );
    }
  }
  ComponentWithCurrentUser.displayName = `withCurrentUser(${
    Component.displayName ?? "anonymous"
  })`;

  return ComponentWithCurrentUser;
}

import { ThemeProvider } from "@contexts";
import { useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import { ARIA } from "@condenast/gemini";
import { CustomDateRangePicker } from "./CustomRange";
import { usePopper } from "react-popper";
import { CheckIcon } from "@condenast/gemini/icons";
import { useDefinedMessages, useMediaQuery } from "@hooks";
import { FormattedMessage } from "react-intl";
import { MenuItem } from "packages/gemini/src/components/ARIA/Menu/MenuItem/types";
import { AdvancedSearchOptions } from "../../types";
const Header = styled.div`
  display: grid;
  gap: var(--spacing-xxs);
  padding-bottom: var(--spacing-xs);
  grid-template-columns: 8fr 2fr;
`;

const FilterWrapper = styled.div`
  display: flex;
  background: ${(props) => props.theme.Background};
  gap: var(--spacing-xxs);
  align-items: stretch;
  border-radius: var(--spacing-xxs);
  box-shadow: 0 0 var(--spacing-xs) rgba(43, 58, 68, 0.4);

  @media (max-width: 650px) {
    padding-bottom: var(--spacing-xs);
  }
`;

const DateMenu = styled(ARIA.Menu)`
  & ~ [role="menu"] {
    max-height: 398px;
    overflow-y: auto;
  }
  background: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  font: ${(props) => props.theme.FontStatement};
  line-height: 24px;
  box-shadow: none;
  border-radius: ${(props) => props.theme.CornerInsetRadius};
`;

const CustomRangeWrapper = styled.div`
  background: ${(props) => props.theme.Background};
  padding: var(--spacing-xs);
  color: ${(props) => props.theme.Color};
  border-radius: ${(props) => props.theme.CornerInsetRadius};
  border-left: 1px solid ${(props) => props.theme.DividerColor};
`;

const Title = styled.h2`
  color: ${(props) => props.theme.Color};
  font: ${(props) => props.theme.FontSubSectionHeading};
  padding: var(--spacing-xs);
  font-size: ${(props) => props.theme.FontStatement};
`;

const CustomLabel = styled.div`
  display: grid;
  gap: var(--spacing-xxs);
  padding-top: var(--spacing-s);
  grid-template-columns: 9fr 1fr;
`;

export function CustomDateMenu(props: {
  selectedFilters: AdvancedSearchOptions;
  setShowPopper: (popper: boolean) => void;
  onChange: (dateRangeOption: string | undefined) => void;
  dateRangeOptions: string[] | undefined;
}) {
  const {
    setShowPopper,
    onChange,
    dateRangeOptions,
    selectedFilters,
    ...menu
  } = props;

  const { translateSelectOption } = useDefinedMessages();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const isMobile = useMediaQuery("(max-width: 813px)");

  const [buttonElement, setButtonElement] = useState<HTMLElement | null>(null);
  const [menuElement, setMenuElement] = useState<HTMLInputElement | null>(null);
  const [isPopperPositioned, setPopperPositioned] = useState<boolean>(false);

  const onPopperFirstUpdate = useCallback(() => {
    setPopperPositioned(true);
  }, [setPopperPositioned]);

  let { styles, attributes } = usePopper(buttonElement, menuElement, {
    onFirstUpdate: onPopperFirstUpdate,
  });

  const menuItems = useMemo(() => {
    const items: MenuItem<unknown>[] = [
      {
        role: "action",
        value: showDatePicker ? (
          <CustomLabel>
            <FormattedMessage defaultMessage="Custom Range" />
            <CheckIcon size={"small"} color={"blue"} />
          </CustomLabel>
        ) : (
          <FormattedMessage defaultMessage="Custom Range" />
        ),
        onClick: () => {
          setShowDatePicker(true);
          setShowPopper(!showDatePicker);
        },
      },
    ];
    if (dateRangeOptions) {
      dateRangeOptions.forEach((dateRangeOption) => {
        const optionLabel = translateSelectOption(
          "searchDateOptions",
          dateRangeOption
        );
        items.push({
          role: "action",
          value: (
            <CustomLabel>
              {optionLabel}
              {selectedFilters.date === dateRangeOption && (
                <CheckIcon size={"small"} color={"blue"} />
              )}
            </CustomLabel>
          ),
          onClick: () => {
            setShowPopper(false);
            selectedFilters.date === dateRangeOption
              ? onChange(undefined)
              : onChange(dateRangeOption);
          },
        });
      });
    }
    return items;
  }, [
    selectedFilters,
    dateRangeOptions,
    onChange,
    setShowPopper,
    showDatePicker,
    translateSelectOption,
  ]);

  return (
    <Header>
      <FilterWrapper>
        {isMobile && showDatePicker ? null : (
          <DateMenu
            {...menu}
            ref={setButtonElement}
            id="date"
            items={menuItems}
            autofocus={isPopperPositioned}
            {...attributes.popper}
          >
            {(item: any) => item}
          </DateMenu>
        )}

        {showDatePicker && (
          <ThemeProvider theme="light">
            <CustomRangeWrapper>
              <Title>
                <FormattedMessage defaultMessage="Custom Range" />
              </Title>
              <CustomDateRangePicker
                ref={setMenuElement}
                style={styles.popper}
                setShowPopper={setShowPopper}
                onChange={onChange}
              ></CustomDateRangePicker>
            </CustomRangeWrapper>
          </ThemeProvider>
        )}
      </FilterWrapper>
    </Header>
  );
}
CustomDateMenu.displayName = "CustomDateMenu";

import { Link, Toast } from "@components";
import { Queries } from "@gql";
import { GetCurrentUser, UserRole } from "@types";
import { useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

export function UserEmailTakenError(props: {
  context: { id: string; email: string };
}) {
  let { data } = useQuery<GetCurrentUser>(Queries.GET_CURRENT_USER);
  let { copilotCode } = useParams() as { copilotCode: string };

  return (
    <Toast
      type="error"
      action={
        data?.currentUser?.role === UserRole.superadmin && (
          <Link
            to={`/${copilotCode}/users/${props.context.id}/edit`}
            target="_blank"
          >
            <FormattedMessage defaultMessage="Go to existing account" />
          </Link>
        )
      }
      details={
        data?.currentUser?.role === UserRole.superadmin ? (
          <FormattedMessage
            defaultMessage="This email is already associated with a Copilot account. Edit the existing account to manage brand access or change their role."
            values={{ email: props.context.email }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="This email is already associated with a Copilot account. To edit their existing account, please contact Product Support via the Copilot chat bubble."
            values={{ email: props.context.email }}
          />
        )
      }
    >
      <FormattedMessage
        defaultMessage="{email} has been taken"
        values={{
          email: props.context.email,
        }}
      />
    </Toast>
  );
}

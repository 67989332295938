import styled, { css } from "styled-components";

export const Column = styled.div<{ treatment: string }>`
  display: flex;
  flex-direction: column;
  flex: 0;
  ${({ treatment }) =>
    treatment === "expandable" &&
    css`
      flex: 1;
    `}
`;

Column.defaultProps = {
  treatment: "default",
};

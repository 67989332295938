import { gql } from "@apollo/client";
import * as Fragments from "./fragments";

export const LOG_OUT = gql`
  mutation LogOut {
    logOut {
      success
    }
  }
`;

export const RESET_TOTP = gql`
  mutation ResetTotp($userId: String!) {
    resetTotp(userId: $userId) {
      success
    }
  }
`;

export const SEND_NOTIFICATION_EMAIL = gql`
  mutation SendEmailNotification($userId: String!) {
    sendEmailNotification(userId: $userId) {
      success
    }
  }
`;

export const UPDATE_USER = gql`
  ${Fragments.ORGANIZATIONS}
  mutation updateUser($userId: String!, $user: UserInput!) {
    updateUser(userId: $userId, user: $user) {
      user {
        id
        brandCodes
        email
        firstName
        lastName
        locale
        loginProvider
        modifiedAt
        role
        ...Organizations
      }
    }
  }
`;

export const CREATE_USER = gql`
  ${Fragments.ORGANIZATIONS}
  mutation CreateUser($user: CreateUserInput!) {
    createUser(user: $user) {
      user {
        id
        brandCodes
        email
        firstName
        lastName
        locale
        loginProvider
        modifiedAt
        role
        ...Organizations
      }
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation deactivateUser($userId: String!) {
    deactivateUser(userId: $userId) {
      user {
        id
        status
      }
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation activateUser($userId: String!) {
    activateUser(userId: $userId) {
      user {
        id
        status
      }
    }
  }
`;

export const CREATE_ACTIVITY = gql`
  ${Fragments.ActivityFields}
  mutation CreateActivity($organizationId: ID!, $data: ActivityInput!) {
    content: createActivity(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Activity {
        ...ActivityFields
      }
    }
  }
`;

export const SAVE_ACTIVITY = gql`
  ${Fragments.ActivityFields}
  mutation UpdateActivity(
    $organizationId: ID!
    $id: String!
    $data: ActivityInput!
  ) {
    updateActivity(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Activity {
        ...ActivityFields
      }
    }
  }
`;

export const CREATE_ARTICLE = gql`
  ${Fragments.ArticleFields}
  mutation CreateArticle($organizationId: ID!, $data: ArticleInput!) {
    content: createArticle(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Article {
        ...ArticleFields
      }
    }
  }
`;

export const SAVE_ARTICLE = gql`
  ${Fragments.ArticleFields}
  mutation UpdateArticle(
    $organizationId: ID!
    $id: String!
    $data: ArticleInput!
  ) {
    updateArticle(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Article {
        ...ArticleFields
      }
    }
  }
`;

export const CREATE_NATIVE_ARTICLE = gql`
  ${Fragments.ArticleFields}
  mutation CreateNativeArticle($organizationId: ID!, $data: ArticleInput!) {
    content: createNativeArticle(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Article {
        ...ArticleFields
      }
    }
  }
`;

export const SAVE_NATIVE_ARTICLE = gql`
  ${Fragments.ArticleFields}
  mutation UpdateNativeArticle(
    $organizationId: ID!
    $id: String!
    $data: ArticleInput!
  ) {
    updateNativeArticle(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Article {
        ...ArticleFields
      }
    }
  }
`;

export const CREATE_CONTRIBUTOR = gql`
  ${Fragments.ContributorFields}
  mutation CreateContributor($organizationId: ID!, $data: ContributorInput!) {
    content: createContributor(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Contributor {
        ...ContributorFields
      }
    }
  }
`;

export const SAVE_CONTRIBUTOR = gql`
  ${Fragments.ContributorFields}
  mutation UpdateContributor(
    $organizationId: ID!
    $id: String!
    $data: ContributorInput!
  ) {
    updateContributor(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Contributor {
        ...ContributorFields
      }
    }
  }
`;

export const CREATE_EXTERNALLINK = gql`
  ${Fragments.ExternalLinkFields}
  mutation CreateExternalLink($organizationId: ID!, $data: ExternalLinkInput!) {
    content: createExternalLink(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on ExternalLink {
        ...ExternalLinkFields
      }
    }
  }
`;

export const SAVE_EXTERNALLINK = gql`
  ${Fragments.ExternalLinkFields}
  mutation UpdateExternalLink(
    $organizationId: ID!
    $id: String!
    $data: ExternalLinkInput!
  ) {
    updateExternalLink(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on ExternalLink {
        ...ExternalLinkFields
      }
    }
  }
`;

export const CREATE_EXTERNALREFERENCE = gql`
  ${Fragments.ExternalReferenceFields}
  mutation CreateExternalReference(
    $organizationId: ID!
    $data: ExternalInput!
  ) {
    content: createExternalReference(
      organizationId: $organizationId
      data: $data
    ) {
      __typename
      id
      ... on External {
        ...ExternalReferenceFields
      }
    }
  }
`;

export const SAVE_EXTERNALREFERENCE = gql`
  ${Fragments.ExternalReferenceFields}
  mutation UpdateExternalReference(
    $organizationId: ID!
    $id: String!
    $data: ExternalInput!
  ) {
    updateExternalReference(
      organizationId: $organizationId
      id: $id
      data: $data
    ) {
      __typename
      id
      ... on External {
        ...ExternalReferenceFields
      }
    }
  }
`;

export const CREATE_INFOPAGE = gql`
  ${Fragments.InfoPageFields}
  mutation CreateInfoPage($organizationId: ID!, $data: InfoPageInput!) {
    content: createInfoPage(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on InfoPage {
        ...InfoPageFields
      }
    }
  }
`;

export const SAVE_INFOPAGE = gql`
  ${Fragments.InfoPageFields}
  mutation UpdateInfoPage(
    $organizationId: ID!
    $id: String!
    $data: InfoPageInput!
  ) {
    updateInfoPage(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on InfoPage {
        ...InfoPageFields
      }
    }
  }
`;

export const CREATE_GALLERY = gql`
  ${Fragments.GalleryFields}
  mutation CreateGallery($organizationId: ID!, $data: GalleryInput!) {
    content: createGallery(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Gallery {
        ...GalleryFields
      }
    }
  }
`;

export const SAVE_GALLERY = gql`
  ${Fragments.GalleryFields}
  mutation UpdateGallery(
    $organizationId: ID!
    $id: String!
    $data: GalleryInput!
  ) {
    updateGallery(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Gallery {
        ...GalleryFields
      }
    }
  }
`;

export const CREATE_NATIVE_GALLERY = gql`
  ${Fragments.GalleryFields}
  mutation CreateNativeGallery($organizationId: ID!, $data: GalleryInput!) {
    content: createNativeGallery(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Gallery {
        ...GalleryFields
      }
    }
  }
`;

export const SAVE_NATIVE_GALLERY = gql`
  ${Fragments.GalleryFields}
  mutation UpdateNativeGallery(
    $organizationId: ID!
    $id: String!
    $data: GalleryInput!
  ) {
    updateNativeGallery(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Gallery {
        ...GalleryFields
      }
    }
  }
`;

export const PUBLISH = gql`
  ${Fragments.PublishInfoFields}
  ${Fragments.PublishedRevisionFields}
  mutation Publish(
    $organizationId: ID!
    $contentType: String!
    $id: ID!
    $data: PublishInput!
  ) {
    publish(
      organizationId: $organizationId
      contentType: $contentType
      id: $id
      data: $data
    ) {
      id
      __typename
      ... on Publishable {
        uri
        pubDate
        distributionChannels {
          name
          metadata
        }
        publishedRevisions(limit: 100) {
          ...PublishedRevisionFields
        }
        publishInfo {
          ...PublishInfoFields
        }
      }
    }
  }
`;

export const UNPUBLISH = gql`
  ${Fragments.PublishInfoFields}
  ${Fragments.PublishedRevisionFields}
  mutation Unpublish(
    $organizationId: ID!
    $id: ID!
    $contentType: String!
    $revision: Int
  ) {
    unpublish(
      organizationId: $organizationId
      id: $id
      contentType: $contentType
      revision: $revision
    ) {
      id
      __typename
      ... on Publishable {
        uri
        pubDate
        distributionChannels {
          name
          metadata
        }
        publishedRevisions(limit: 100) {
          ...PublishedRevisionFields
        }
        publishInfo {
          ...PublishInfoFields
        }
      }
    }
  }
`;

export const ARCHIVE = gql`
  ${Fragments.ContentSummaryFields}
  mutation Archive(
    $organizationId: ID!
    $contentType: String!
    $id: String!
    $authorName: String!
  ) {
    archive(
      organizationId: $organizationId
      contentType: $contentType
      id: $id
      authorName: $authorName
    ) {
      ...ContentSummaryFields
    }
  }
`;

export const BULK_ARCHIVE = gql`
  ${Fragments.ContentSummaryFields}
  mutation BulkArchive(
    $organizationId: ID!
    $data: [ArchiveItems!]!
    $authorName: String!
  ) {
    bulkArchive(
      organizationId: $organizationId
      data: $data
      authorName: $authorName
    ) {
      ...ContentSummaryFields
    }
  }
`;

export const UNARCHIVE = gql`
  ${Fragments.ContentSummaryFields}
  mutation Unarchive(
    $organizationId: ID!
    $contentType: String!
    $id: String!
    $authorName: String!
  ) {
    unarchive(
      organizationId: $organizationId
      contentType: $contentType
      id: $id
      authorName: $authorName
    ) {
      ...ContentSummaryFields
    }
  }
`;

export const BULK_UNARCHIVE = gql`
  ${Fragments.ContentSummaryFields}
  mutation BulkUnarchive(
    $organizationId: ID!
    $data: [ArchiveItems!]!
    $authorName: String!
  ) {
    bulkUnarchive(
      organizationId: $organizationId
      data: $data
      authorName: $authorName
    ) {
      ...ContentSummaryFields
    }
  }
`;

export const UPLOAD_MEDIA = gql`
  ${Fragments.ContentSummaryFields}
  mutation UploadMedia(
    $organizationId: ID!
    $authorName: String!
    $data: MediaUploadInput!
  ) {
    uploadMedia(
      organizationId: $organizationId
      authorName: $authorName
      data: $data
    ) {
      ...ContentSummaryFields
    }
  }
`;

export const UPLOAD_AUDIOTRANSCRIPT = gql`
  mutation UploadAudioTranscript(
    $organizationId: ID!
    $data: MediaUploadInput!
  ) {
    uploadAudioTranscript(organizationId: $organizationId, data: $data) {
      publicUrl
    }
  }
`;

export const UPDATE_MEDIA = gql`
  mutation UpdateMedia($organizationId: ID!, $data: [BulkMediaInput!]!) {
    updateMedia(organizationId: $organizationId, data: $data) {
      success
    }
  }
`;

export const CREATE_REDIRECT = gql`
  ${Fragments.RedirectFields}
  mutation CreateRedirect($organizationId: ID!, $data: RedirectInput!) {
    content: createRedirect(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Redirect {
        ...RedirectFields
      }
    }
  }
`;

export const SAVE_REDIRECT = gql`
  ${Fragments.RedirectFields}
  mutation UpdateRedirect(
    $organizationId: ID!
    $id: String!
    $data: RedirectInput!
  ) {
    updateRedirect(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Redirect {
        ...RedirectFields
      }
    }
  }
`;

export const CREATE_TAGPAGE = gql`
  ${Fragments.TagpageFields}
  mutation createTagpage($organizationId: ID!, $data: TagpageInput!) {
    content: createTagpage(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on TagPage {
        ...TagpageFields
      }
    }
  }
`;

export const SAVE_TAGPAGE = gql`
  ${Fragments.TagpageFields}
  mutation updateTagpage(
    $organizationId: ID!
    $id: String!
    $data: TagpageInput!
  ) {
    updateTagpage(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on TagPage {
        ...TagpageFields
      }
    }
  }
`;

export const CREATE_TAXONOMY = gql`
  ${Fragments.TaxonomyFields}
  mutation CreateTaxonomy($organizationId: ID!, $data: TaxonomyInput!) {
    content: createTaxonomy(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Taxonomy {
        ...TaxonomyFields
      }
    }
  }
`;

export const SAVE_TAXONOMY = gql`
  ${Fragments.TaxonomyFields}
  mutation UpdateTaxonomy(
    $organizationId: ID!
    $id: String!
    $data: TaxonomyInput!
  ) {
    updateTaxonomy(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Taxonomy {
        ...TaxonomyFields
      }
    }
  }
`;

export const IMPORT_GETTY_IMAGE = gql`
  ${Fragments.ContentSummaryFields}
  mutation ImportGettyImage($organizationId: ID!, $data: [GettyImportInput!]!) {
    importGettyImage(organizationId: $organizationId, data: $data) {
      ...ContentSummaryFields
    }
  }
`;

export const IMPORT_GETTY_VIDEO = gql`
  ${Fragments.ContentSummaryFields}
  mutation ImportGettyVideo($organizationId: ID!, $data: [GettyImportInput!]!) {
    importGettyVideo(organizationId: $organizationId, data: $data) {
      ...ContentSummaryFields
    }
  }
`;

export const IMPORT_CONTENT_REFERENCE = gql`
  ${Fragments.ContentSummaryFields}
  mutation ImportContentReference(
    $organizationId: ID!
    $data: [ContentReferenceImportInput!]!
  ) {
    importContentReference(organizationId: $organizationId, data: $data) {
      ...ContentSummaryFields
    }
  }
`;

export const IMPORT_PRODUCT = gql`
  ${Fragments.ContentSummaryFields}
  mutation ImportProduct($organizationId: ID!, $data: [ProductImportInput!]!) {
    importProduct(organizationId: $organizationId, data: $data) {
      ...ContentSummaryFields
    }
  }
`;

export const CREATE_BRAND = gql`
  ${Fragments.BrandFields}
  mutation CreateBrand($organizationId: ID!, $data: BrandInput!) {
    content: createBrand(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Brand {
        ...BrandFields
      }
    }
  }
`;

export const SAVE_BRAND = gql`
  ${Fragments.BrandFields}
  mutation UpdateBrand($organizationId: ID!, $id: String!, $data: BrandInput!) {
    updateBrand(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Brand {
        ...BrandFields
      }
    }
  }
`;

export const CREATE_ADVERTISER = gql`
  ${Fragments.AdvertiserFields}
  mutation CreateAdvertiser($organizationId: ID!, $data: AdvertiserInput!) {
    content: createAdvertiser(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Advertiser {
        ...AdvertiserFields
      }
    }
  }
`;

export const SAVE_ADVERTISER = gql`
  ${Fragments.AdvertiserFields}
  mutation UpdateAdvertiser(
    $organizationId: ID!
    $id: String!
    $data: AdvertiserInput!
  ) {
    updateAdvertiser(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Advertiser {
        ...AdvertiserFields
      }
    }
  }
`;

export const TRANSLATE = gql`
  mutation Translate(
    $organizationId: ID!
    $id: ID!
    $contentType: String!
    $data: TranslateInput!
  ) {
    translate(
      organizationId: $organizationId
      id: $id
      contentType: $contentType
      data: $data
    ) {
      id
    }
  }
`;

export const CREATE_REVIEW = gql`
  ${Fragments.ProductReviewFields}
  ${Fragments.MusicReviewFields}
  mutation CreateReview($organizationId: ID!, $data: ReviewInput!) {
    content: createReview(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on ProductReview {
        ...ProductReviewFields
      }
      ... on MusicReview {
        ...MusicReviewFields
      }
    }
  }
`;

export const SAVE_REVIEW = gql`
  ${Fragments.ProductReviewFields}
  ${Fragments.MusicReviewFields}
  mutation UpdateReview(
    $organizationId: ID!
    $id: String!
    $data: ReviewInput!
  ) {
    updateReview(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on ProductReview {
        ...ProductReviewFields
      }
      ... on MusicReview {
        ...MusicReviewFields
      }
    }
  }
`;

export const CREATE_VENUE = gql`
  ${Fragments.VenueFields}
  mutation CreateVenue($organizationId: ID!, $data: VenueInput!) {
    content: createVenue(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Venue {
        ...VenueFields
      }
    }
  }
`;

export const SAVE_VENUE = gql`
  ${Fragments.VenueFields}
  mutation UpdateVenue($organizationId: ID!, $id: String!, $data: VenueInput!) {
    updateVenue(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Venue {
        ...VenueFields
      }
    }
  }
`;

export const CREATE_CURATEDSEARCH = gql`
  ${Fragments.CuratedSearchFields}
  mutation CreateCuratedSearch(
    $organizationId: ID!
    $data: CuratedSearchInput!
  ) {
    content: createCuratedSearch(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on CuratedSearch {
        ...CuratedSearchFields
      }
    }
  }
`;

export const SAVE_CURATEDSEARCH = gql`
  ${Fragments.CuratedSearchFields}
  mutation UpdateCuratedSearch(
    $organizationId: ID!
    $id: String!
    $data: CuratedSearchInput!
  ) {
    updateCuratedSearch(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on CuratedSearch {
        ...CuratedSearchFields
      }
    }
  }
`;

export const CREATE_CURATEDLIST = gql`
  ${Fragments.CuratedListFields}
  mutation CreateCuratedList($organizationId: ID!, $data: CuratedListInput!) {
    content: createCuratedList(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on CuratedList {
        ...CuratedListFields
      }
    }
  }
`;

export const SAVE_CURATEDLIST = gql`
  ${Fragments.CuratedListFields}
  mutation UpdateCuratedList(
    $organizationId: ID!
    $id: String!
    $data: CuratedListInput!
  ) {
    updateCuratedList(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on CuratedList {
        ...CuratedListFields
      }
    }
  }
`;

export const CREATE_AUDIOFILE = gql`
  ${Fragments.AudioFileFields}
  mutation CreateAudioFile($organizationId: ID!, $data: AudioFileInput!) {
    content: createAudioFile(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on AudioFile {
        ...AudioFileFields
      }
    }
  }
`;

export const SAVE_AUDIOFILE = gql`
  ${Fragments.AudioFileFields}
  mutation UpdateAudioFile(
    $organizationId: ID!
    $id: String!
    $data: AudioFileInput!
  ) {
    updateAudioFile(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on AudioFile {
        ...AudioFileFields
      }
    }
  }
`;

export const CREATE_RECIPE = gql`
  ${Fragments.RecipeFields}
  mutation CreateRecipe($organizationId: ID!, $data: RecipeInput!) {
    content: createRecipe(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Recipe {
        ...RecipeFields
      }
    }
  }
`;

export const CREATE_NATIVE_RECIPE = gql`
  ${Fragments.RecipeFields}
  mutation CreateNativeRecipe($organizationId: ID!, $data: RecipeInput!) {
    content: createNativeRecipe(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Recipe {
        ...RecipeFields
      }
    }
  }
`;

export const SAVE_NATIVE_RECIPE = gql`
  ${Fragments.RecipeFields}
  mutation UpdateNativeRecipe(
    $organizationId: ID!
    $id: String!
    $data: RecipeInput!
  ) {
    updateNativeRecipe(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Recipe {
        ...RecipeFields
      }
    }
  }
`;

export const SAVE_CARTOON = gql`
  ${Fragments.CartoonFields}
  mutation UpdateCartoon(
    $organizationId: ID!
    $id: String!
    $data: CartoonInput!
  ) {
    updateCartoon(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Cartoon {
        ...CartoonFields
      }
    }
  }
`;

export const DELETE_CARTOON = gql`
  mutation DeleteCartoon(
    $organizationId: ID!
    $contentType: String!
    $id: String!
  ) {
    deleteMedia(
      organizationId: $organizationId
      contentType: $contentType
      id: $id
    )
  }
`;

export const SAVE_CLIP = gql`
  ${Fragments.ClipFields}
  mutation UpdateClip($organizationId: ID!, $id: String!, $data: ClipInput!) {
    updateClip(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Clip {
        ...ClipFields
      }
    }
  }
`;

export const DELETE_CLIP = gql`
  mutation DeleteClip(
    $organizationId: ID!
    $contentType: String!
    $id: String!
  ) {
    deleteMedia(
      organizationId: $organizationId
      contentType: $contentType
      id: $id
    )
  }
`;

export const SAVE_PHOTO = gql`
  ${Fragments.PhotoFields}
  mutation UpdatePhoto($organizationId: ID!, $id: String!, $data: PhotoInput!) {
    updatePhoto(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Photo {
        ...PhotoFields
      }
    }
  }
`;

export const DELETE_PHOTO = gql`
  mutation DeletePhoto(
    $organizationId: ID!
    $contentType: String!
    $id: String!
  ) {
    deleteMedia(
      organizationId: $organizationId
      contentType: $contentType
      id: $id
    )
  }
`;

export const SAVE_RECIPE = gql`
  ${Fragments.RecipeFields}
  mutation UpdateRecipe(
    $organizationId: ID!
    $id: String!
    $data: RecipeInput!
  ) {
    updateRecipe(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Recipe {
        ...RecipeFields
      }
    }
  }
`;

export const CREATE_COOKBOOK = gql`
  ${Fragments.CookbookFields}
  mutation CreateCookbook($organizationId: ID!, $data: CookbookInput!) {
    content: createCookbook(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Cookbook {
        ...CookbookFields
      }
    }
  }
`;

export const SAVE_COOKBOOK = gql`
  ${Fragments.CookbookFields}
  mutation UpdateCookbook(
    $organizationId: ID!
    $id: String!
    $data: CookbookInput!
  ) {
    updateCookbook(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Cookbook {
        ...CookbookFields
      }
    }
  }
`;

export const SAVE_LAYOUT_CONFIGS = gql`
  mutation upsertLayoutConfigs(
    $organizationId: ID!
    $query: LayoutConfigsQueryInput
    $data: LayoutConfigsInput!
  ) {
    upsertLayoutConfigs(
      organizationId: $organizationId
      query: $query
      data: $data
    ) {
      code
      success
      message
    }
  }
`;

export const CREATE_FROM_PRESET = gql`
  mutation CreateFromPreset($organizationId: ID!, $data: DuplicateInput!) {
    createFromPreset(organizationId: $organizationId, data: $data) {
      id
    }
  }
`;

export const DUPLICATE = gql`
  mutation Duplicate($organizationId: ID!, $data: DuplicateInput!) {
    duplicate(organizationId: $organizationId, data: $data) {
      id
    }
  }
`;

export const CREATE_MUSICRELEASE = gql`
  ${Fragments.MusicReleaseFields}
  mutation CreateMusicRelease($organizationId: ID!, $data: MusicReleaseInput!) {
    content: createMusicRelease(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on MusicRelease {
        ...MusicReleaseFields
      }
    }
  }
`;

export const SAVE_MUSICRELEASE = gql`
  ${Fragments.MusicReleaseFields}
  mutation UpdateMusicRelease(
    $organizationId: ID!
    $id: String!
    $data: MusicReleaseInput!
  ) {
    updateMusicRelease(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on MusicRelease {
        ...MusicReleaseFields
      }
    }
  }
`;

export const CREATE_MUSICGROUP = gql`
  ${Fragments.MusicGroupFields}
  mutation CreateMusicGroup($organizationId: ID!, $data: MusicGroupInput!) {
    content: createMusicGroup(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on MusicGroup {
        ...MusicGroupFields
      }
    }
  }
`;

export const SAVE_MUSICGROUP = gql`
  ${Fragments.MusicGroupFields}
  mutation UpdateMusicGroup(
    $organizationId: ID!
    $id: String!
    $data: MusicGroupInput!
  ) {
    updateMusicGroup(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on MusicGroup {
        ...MusicGroupFields
      }
    }
  }
`;

export const CREATE_RCALIST = gql`
  ${Fragments.RcalistFields}
  mutation CreateRcalist($organizationId: ID!, $data: RcalistInput!) {
    content: createRcalist(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Rcalist {
        ...RcalistFields
      }
    }
  }
`;

export const SAVE_RCALIST = gql`
  ${Fragments.RcalistFields}
  mutation UpdateRcalist(
    $organizationId: ID!
    $id: String!
    $data: RcalistInput!
  ) {
    updateRcalist(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Rcalist {
        ...RcalistFields
      }
    }
  }
`;

export const CREATE_MUSICALBUM = gql`
  ${Fragments.MusicAlbumFields}
  mutation CreateMusicAlbum($organizationId: ID!, $data: MusicAlbumInput!) {
    content: createMusicAlbum(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on MusicAlbum {
        ...MusicAlbumFields
      }
    }
  }
`;

export const SAVE_MUSICALBUM = gql`
  ${Fragments.MusicAlbumFields}
  mutation UpdateMusicAlbum(
    $organizationId: ID!
    $id: String!
    $data: MusicAlbumInput!
  ) {
    updateMusicAlbum(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on MusicAlbum {
        ...MusicAlbumFields
      }
    }
  }
`;

export const CREATE_MUSICRECORDING = gql`
  ${Fragments.MusicRecordingFields}
  mutation CreateMusicRecording(
    $organizationId: ID!
    $data: MusicRecordingInput!
  ) {
    content: createMusicRecording(
      organizationId: $organizationId
      data: $data
    ) {
      __typename
      id
      ... on MusicRecording {
        ...MusicRecordingFields
      }
    }
  }
`;

export const SAVE_MUSICRECORDING = gql`
  ${Fragments.MusicRecordingFields}
  mutation UpdateMusicRecording(
    $organizationId: ID!
    $id: String!
    $data: MusicRecordingInput!
  ) {
    updateMusicRecording(
      organizationId: $organizationId
      id: $id
      data: $data
    ) {
      __typename
      id
      ... on MusicRecording {
        ...MusicRecordingFields
      }
    }
  }
`;

export const CREATE_PRODUCT = gql`
  ${Fragments.ProductFields}
  mutation CreateProduct($organizationId: ID!, $data: ProductInput!) {
    content: createProduct(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Product {
        ...ProductFields
      }
    }
  }
`;

export const SAVE_PRODUCT = gql`
  ${Fragments.ProductFields}
  mutation UpdateProduct(
    $organizationId: ID!
    $id: String!
    $data: ProductInput!
  ) {
    updateProduct(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Product {
        ...ProductFields
      }
    }
  }
`;

export const CREATE_LIVESTORY = gql`
  ${Fragments.LiveStoryFields}
  mutation CreateLiveStory($organizationId: ID!, $data: LiveStoryInput!) {
    content: createLiveStory(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on LiveStory {
        ...LiveStoryFields
      }
    }
  }
`;

export const SAVE_LIVESTORY = gql`
  ${Fragments.LiveStoryFields}
  mutation UpdateLiveStory(
    $organizationId: ID!
    $id: String!
    $data: LiveStoryInput!
  ) {
    updateLiveStory(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on LiveStory {
        ...LiveStoryFields
      }
    }
  }
`;

export const CREATE_LIVESTORY_UPDATE = gql`
  ${Fragments.LivestoryupdateFields}
  mutation CreateLivestoryupdate(
    $organizationId: ID!
    $data: LivestoryupdateInput!
  ) {
    content: createLivestoryupdate(
      organizationId: $organizationId
      data: $data
    ) {
      __typename
      id
      ... on Livestoryupdate {
        ...LivestoryupdateFields
      }
    }
  }
`;

export const SAVE_LIVESTORY_UPDATE = gql`
  ${Fragments.LivestoryupdateFields}
  mutation UpdateLivestoryupdate(
    $organizationId: ID!
    $id: String!
    $data: LivestoryupdateInput!
  ) {
    updateLivestoryupdate(
      organizationId: $organizationId
      id: $id
      data: $data
    ) {
      __typename
      id
      ... on Livestoryupdate {
        ...LivestoryupdateFields
      }
    }
  }
`;

export const CREATE_PERSON = gql`
  ${Fragments.PersonFields}
  mutation CreatePerson($organizationId: ID!, $data: PersonInput!) {
    content: createPerson(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Person {
        ...PersonFields
      }
    }
  }
`;

export const SAVE_PERSON = gql`
  ${Fragments.PersonFields}
  mutation UpdatePerson(
    $organizationId: ID!
    $id: String!
    $data: PersonInput!
  ) {
    updatePerson(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Person {
        ...PersonFields
      }
    }
  }
`;

export const CREATE_CITY = gql`
  ${Fragments.CityFields}
  mutation CreateCity($organizationId: ID!, $data: CityInput!) {
    content: createCity(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on City {
        ...CityFields
      }
    }
  }
`;

export const SAVE_CITY = gql`
  ${Fragments.CityFields}
  mutation UpdateCity($organizationId: ID!, $id: String!, $data: CityInput!) {
    updateCity(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on City {
        ...CityFields
      }
    }
  }
`;

export const CREATE_EVENT = gql`
  ${Fragments.EventFields}
  mutation CreateEvent($organizationId: ID!, $data: EventInput!) {
    content: createEvent(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Event {
        ...EventFields
      }
    }
  }
`;

export const SAVE_EVENT = gql`
  ${Fragments.EventFields}
  mutation UpdateEvent($organizationId: ID!, $id: String!, $data: EventInput!) {
    updateEvent(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Event {
        ...EventFields
      }
    }
  }
`;

export const CREATE_BUSINESS = gql`
  ${Fragments.BusinessFields}
  mutation CreateBusiness($organizationId: ID!, $data: BusinessInput!) {
    content: createBusiness(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Business {
        ...BusinessFields
      }
    }
  }
`;

export const SAVE_BUSINESS = gql`
  ${Fragments.BusinessFields}
  mutation UpdateBusiness(
    $organizationId: ID!
    $id: String!
    $data: BusinessInput!
  ) {
    updateBusiness(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Business {
        ...BusinessFields
      }
    }
  }
`;

export const CREATE_BUNDLE = gql`
  ${Fragments.BundleFields}
  mutation CreateBundle($organizationId: ID!, $data: BundleInput!) {
    content: createBundle(organizationId: $organizationId, data: $data) {
      __typename
      id
      ... on Bundle {
        ...BundleFields
      }
    }
  }
`;

export const SAVE_BUNDLE = gql`
  ${Fragments.BundleFields}
  mutation UpdateBundle(
    $organizationId: ID!
    $id: String!
    $data: BundleInput!
  ) {
    updateBundle(organizationId: $organizationId, id: $id, data: $data) {
      __typename
      id
      ... on Bundle {
        ...BundleFields
      }
    }
  }
`;

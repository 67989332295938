export function transformProConValues(
  input?: { key: string; value: string }[]
): string[] | null {
  const filteredList =
    input
      ?.map((d: { key: string; value: string }) => d.value)
      .filter(Boolean) || [];

  return filteredList.length > 0 ? filteredList : null;
}

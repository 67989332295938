import { AnimatedEllipsisIcon, WandIcon } from "@condenast/gemini/icons";
import { ReactNode, useCallback, useEffect, useState } from "react";
import styled, { DefaultTheme } from "styled-components";
import { ThemeProvider } from "@contexts";
import { Button } from "@components";

const AutogenWrapper = styled.div`
  position: relative;
`;

const AutogenButton = styled(Button)`
  position: absolute;
  background: ${(props) => props.theme.AutofillButtonBackgroundColor};
  color: ${(props) => props.theme.AutofillButtonColor};

  &:not(:disabled):hover {
    background: ${(props) => props.theme.AutofillButtonHoverBackground};
    cursor: ${(props) => props.theme.AutofillButtonHoverCursor};
  }
`;

function autofilledTheme(
  theme: DefaultTheme,
  purpleify: (css: string) => string,
  doTransform?: boolean
) {
  let {
    FieldRing,
    FieldActiveRing,
    FieldFocusRing,
    FieldHoverRing,
    FocusRing,
    SecondaryColor,
  } = theme;

  return doTransform
    ? {
        ...theme,
        AutofillButtonBackgroundColor: "var(--color-purple-50)",
        AutofillButtonHoverBackground: "var(--color-purple-50)",
        AutofillButtonColor: "var(--color-white)",
        AutofillButtonHoverCursor: "default",
        FieldRing: purpleify(FieldRing),
        FieldActiveRing: purpleify(FieldActiveRing),
        FieldFocusRing: purpleify(FieldFocusRing),
        FieldHoverRing: purpleify(FieldHoverRing),
        FocusRing: purpleify(FocusRing),
        SecondaryColor: purpleify(SecondaryColor),
      }
    : theme;
}

type Autogenerator<T> = () => Promise<T>;
function isAutogenerator<T>(
  autogenValue: T | Autogenerator<T>
): autogenValue is Autogenerator<T> {
  return typeof autogenValue === "function";
}

const Message = styled.label`
  display: grid;
  grid-template-columns: 1fr auto;
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.SecondaryColor};
  margin-top: calc(-1 * var(--spacing-sm));
  margin-bottom: var(--spacing-sm);
`;

export function Autogenerates<T>(props: {
  onChange: (arg: T) => void;
  autogenValue?: T | Autogenerator<T>;
  currentValue?: T;
  comparator?: (value1: T, value2: T) => boolean;
  autogenMessage?: string;
  children: (autogenProps: {
    onChange: (arg: T) => void;
    dismissAutogenTheme: () => void;
    autogenButton?: HTMLButtonElement | null;
  }) => ReactNode;
}) {
  let {
    onChange,
    autogenValue,
    currentValue,
    autogenMessage,
    comparator,
    children,
  } = props;
  let [isAutogenerating, setIsAutogenerating] = useState(false);

  let [isAutogenerated, setIsAutogenerated] = useState<boolean | undefined>(
    false
  );
  useEffect(() => {
    if (isAutogenerator(autogenValue)) {
      return; // don't eagerly compute in this case
    }
    if (autogenValue == undefined || currentValue == undefined) {
      return;
    }
    if (comparator) {
      setIsAutogenerated(comparator(autogenValue, currentValue));
    } else {
      setIsAutogenerated(autogenValue === currentValue);
    }
  }, [autogenValue, currentValue]);

  let dismissIsAutogenerated = useCallback(() => {
    setIsAutogenerated(false);
  }, []);

  let [buttonEl, setButtonEl] = useState<HTMLButtonElement | null>(null);

  let spyOnChange = useCallback(
    (value: T) => {
      setIsAutogenerated(false);
      onChange(value);
    },
    [onChange]
  );

  let autogenerate = useCallback(async () => {
    if (autogenValue !== undefined) {
      if (isAutogenerator(autogenValue)) {
        setIsAutogenerating(true);
        const autogeneratedValue = await autogenValue();
        setIsAutogenerating(false);
        setIsAutogenerated(true);
        onChange(autogeneratedValue);
      } else {
        setIsAutogenerated(true);
        onChange(autogenValue);
      }
    }
  }, [onChange, autogenValue]);

  let autogenThemeTransform = useCallback(
    (theme, purpleify) => autofilledTheme(theme, purpleify, isAutogenerated),
    [isAutogenerated]
  );

  return autogenValue ? (
    <AutogenWrapper>
      <ThemeProvider tint="purple" transform={autogenThemeTransform}>
        {children({
          onChange: spyOnChange,
          dismissAutogenTheme: dismissIsAutogenerated,
          autogenButton: buttonEl,
        })}
        <AutogenButton
          size="medium"
          ref={setButtonEl}
          aria-label="Autofill"
          onClick={autogenerate}
          disabled={isAutogenerating}
        >
          {isAutogenerating ? (
            <AnimatedEllipsisIcon size="regular" />
          ) : (
            <WandIcon size="regular" />
          )}
        </AutogenButton>
        {isAutogenerated && autogenMessage ? (
          <Message>{autogenMessage}</Message>
        ) : null}
      </ThemeProvider>
    </AutogenWrapper>
  ) : (
    <>{children({ onChange, dismissAutogenTheme: dismissIsAutogenerated })}</>
  );
}
Autogenerates.displayName = "Autogenerates";

import { CartoonContestInput, FormFor_content_Cartoon_contest } from "@types";

export function cartoonContest(
  input?: FormFor_content_Cartoon_contest
): CartoonContestInput {
  return {
    announceFinalistsDate: input?.announceFinalistsDate,
    announceFinalistsIssueDate: input?.announceFinalistsIssueDate,
    announceWinnerDate: input?.announceWinnerDate,
    submissionEndDate: input?.submissionEndDate,
    votingEndDate: input?.votingEndDate,
  };
}

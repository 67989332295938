import { Card, Toggle, Toast, Image, UnsavedChangesDialog } from "@components";
import {
  DashboardRefresh,
  GetCurrentUser_currentUser,
  Organization,
  SearchFilters,
  UploadMedia,
  Search,
  SearchVariables,
  Asset,
  UploadMediaVariables,
  ContentSummaryFields,
  defaultRefreshType,
} from "@types";
import { useState, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { MediaUpload } from "./MediaUpload";
import { Queries } from "@gql";
import { useLazyQuery } from "@apollo/client";
import { updateRefreshStatus } from "../DashboardList/Helper/helper-functions";

import { MEDIA_MAP } from "@lib";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { useMutation } from "@apollo/client";
import { Mutations } from "@gql";
import { useToast } from "@hooks";
import { Link } from "react-router-dom";

const ResultList = styled.div<{ blur: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  gap: 4px;
  height: fit-content;

  @media (max-width: 768px) {
    margin-left: 48px;
  }
  @media (max-width: 600px) {
    margin-left: 16px;
  }
  @media (max-width: 360px) {
    margin-left: 36px;
  }
  opacity: ${(props) => (props.blur ? 0.5 : 1)};
`;

const ImageThumbnail = styled.div`
  position: relative;
  text-align: center;
  color: white;
  padding: var(--spacing-xs);
  width: 138px;
  hieght: 138px;

  & div {
    background: rgba(23, 23, 23, 0.8);
    position: absolute;
    bottom: 10px;
    line-height: var(--spacing-sm);
    padding: var(--spacing-xxs) var(--spacing-xs);
    border-bottom-left-radius: var(--spacing-xs);
  }

  & img {
    border-radius: var(--spacing-xs);
  }
`;

const UploadsWrapper = styled(Card)`
  min-height: 156px;
  min-width: 1088px;
  margin-block-start: var(--spacing-md);
  height: fit-content;
  padding: unset;
  margin: unset;
  margin-block-start: var(--spacing-md);
  max-width: none;
  @media (max-width: 1280px) {
    min-width: fit-content;
  }

  @media (max-width: 768px) {
    margin-top: var(--spacing-sm);
    border-radius: var(--spacing-xxs);
  }
  @media (max-width: 600px) {
    border-radius: unset;
  }
`;

const Header = styled.div`
  display: grid;
  padding: var(--spacing-sm);
  gap: 10px;
  border-bottom: 2px dashed var(--color-gray-5);
  grid-template-columns: 8fr 2fr;
`;

const Title = styled.h2`
  font: ${(props) => props.theme.FontSmallerHeading};
`;

const UploadsToggle = styled.div`
  grid-column-start: -1;
`;

const UploadSection = styled.div`
  min-height: 110px;
`;

const ResultSection = styled.div`
  width: 100%
  margin: var(--spacing-xs);
  min-height: 154px;
  border-bottom: 1px solid var(--color-gray-5);
  display: flex;
  justify-items: center;
`;

const Errors = styled.div`
  margin: var(--spacing-sm) 0;
`;

const ErrorList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;

  li {
    display: list-item;
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FooterTitle = styled.h4`
  font: ${(props) => props.theme.FontLabel};
  color: var(--color-blue-40);
  margin: var(--spacing-xs);
  padding: var(--spacing-xxs) var(--spacing-xxs);
  a {
    text-decoration: none;
  }
`;

const media_filter = {
  display: "all",
  types: "photo,clip",
  notcategory: "copilot/preset",
  nottypes: "cnevideos,categories,pushnotifications,livestoryupdates",
  archived: false,
} as SearchFilters;

const ALLOWED_MEDIA_TYPES: string[] = [...MEDIA_MAP.photos, ...MEDIA_MAP.clips];

export const DashboardUploadList = (props: {
  currentUser: GetCurrentUser_currentUser;
  currentOrganization: Organization;
  filterType: string;
  refreshAPIs: DashboardRefresh;
  setRefreshAPIs: (status: DashboardRefresh) => void;
}) => {
  const {
    currentUser,
    currentOrganization,
    filterType,
    refreshAPIs,
    setRefreshAPIs,
  } = props;
  const [ongoingUploadCounter, setOngoingUploadCounter] = useState(0);
  const [uploadErrors, setUploadErrors] = useState<File[]>([]);
  const intl = useIntl();
  const [viewURL, setViewURL] = useState("/search?query=&types=clip%2Cphoto");
  const [myUploads, setMyUploads] = useState<boolean>(false);
  const [mediaFilter, setMediaFilter] = useState<SearchFilters>(media_filter);
  const [fetchedData, setFetchData] = useState<Search>();
  const [uploadData, setUploadData] = useState<ContentSummaryFields>();

  const [getSectionData, { data, loading }] = useLazyQuery<
    Search,
    SearchVariables
  >(Queries.SEARCH, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setFetchData(data);
      updateRefreshStatus(setRefreshAPIs, filterType, refreshAPIs, "success");
    },
    onError: () => {
      setFetchData(undefined);
      updateRefreshStatus(setRefreshAPIs, filterType, refreshAPIs, "failed");
    },
    variables: {
      organizationId: currentOrganization.organizationId,
      limit: 8,
      offset: 0,
      filters: mediaFilter,
    },
  });

  useEffect(() => {
    getSectionData();
  }, []);

  useEffect(() => {
    if (uploadData && fetchedData != undefined) {
      let fetchedResult = [...fetchedData.search.results];
      fetchedResult = fetchedResult.slice(0, 7);
      let ResultList = {
        limit: fetchedData.search.limit,
        offset: 0,
        totalResults: fetchedData.search.totalResults,
        __typename: fetchedData.search.__typename,
        results: [uploadData, ...fetchedResult],
      };
      setFetchData({ search: ResultList });
    }
  }, [uploadData]);

  useEffect(() => {
    const type = refreshAPIs[filterType] as defaultRefreshType;
    const { status, refreshState, name } = type;
    if (
      status === true &&
      filterType === name &&
      !loading &&
      refreshState === "idle"
    ) {
      getSectionData();
    } else if (
      status === true &&
      filterType === name &&
      loading &&
      refreshState === "idle"
    ) {
      updateRefreshStatus(setRefreshAPIs, filterType, refreshAPIs, "loading");
    }
  }, [refreshAPIs, filterType, setRefreshAPIs, getSectionData, loading]);

  const handleToggleChange = (value: boolean) => {
    setMyUploads(value);
    if (value) {
      setViewURL(
        "/search?collaborator=" + currentUser.id + "types=clip%2Cphoto"
      );
      setMediaFilter({ ...mediaFilter, privilege_user: currentUser.id });
    } else {
      setViewURL("/search?types=clip%2Cphoto");
      setMediaFilter({ ...media_filter });
    }
  };

  const [upload] = useMutation<UploadMedia, UploadMediaVariables>(
    Mutations.UPLOAD_MEDIA
  );

  const showIncorrectFileTypeMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Error uploading: Unsupported file type",
    }),
  });

  const uploadMultiple = useCallback(
    (files: FileList) => {
      const sharedVariables = {
        organizationId: currentOrganization.organizationId,
        authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      };
      for (let file of files) {
        setOngoingUploadCounter((n) => n + 1);
        upload({
          variables: {
            ...sharedVariables,
            data: { file, fileSize: file.size },
          },
        })
          .then((uploadResult) => {
            if (uploadResult.data?.uploadMedia) {
              setUploadData(uploadResult.data.uploadMedia);
            } else if (uploadResult.errors) {
              setUploadErrors((failedFiles) => {
                return [...failedFiles, file];
              });
            }
          })
          .catch(() => {
            setUploadErrors((failedFiles) => {
              return [...failedFiles, file];
            });
          })
          .finally(() => {
            setOngoingUploadCounter((n) => n - 1);
          });
      }
    },
    [upload, currentOrganization, currentUser]
  );

  return (
    <>
      <UploadsWrapper>
        <Header>
          <Title>
            <FormattedMessage defaultMessage="Uploads" />
          </Title>
          <UploadsToggle>
            <Toggle
              id="MyUploadToggle"
              label="My Uploads"
              onChange={(value: boolean) => handleToggleChange(value)}
              value={myUploads}
            />
          </UploadsToggle>
        </Header>
        <UploadSection>
          <UnsavedChangesDialog
            hasChanges={ongoingUploadCounter !== 0}
            title={<FormattedMessage defaultMessage="Unfinished Uploads" />}
            message={
              <FormattedMessage defaultMessage="Some files are not done uploading. If you leave the page, they may not be uploaded correctly. Do you want to leave the page?" />
            }
          />
          {uploadErrors.length ? (
            <Errors>
              <Toast
                type="error"
                details={
                  <ErrorList>
                    {uploadErrors.map((file) => (
                      <li key={file.name}>{file.name}</li>
                    ))}
                  </ErrorList>
                }
              >
                <FormattedMessage
                  defaultMessage="Some files were not uploaded:"
                  description="This message appears above a list of file names"
                />
              </Toast>
            </Errors>
          ) : null}
          <DndProvider backend={HTML5Backend}>
            <MediaUpload
              onUpload={uploadMultiple}
              accept={ALLOWED_MEDIA_TYPES}
              onUnsupportedFileType={showIncorrectFileTypeMessage}
              loading={ongoingUploadCounter !== 0}
            />
          </DndProvider>
        </UploadSection>
        {fetchedData?.search?.results.length ? (
          <>
            <ResultSection>
              <ResultList blur={loading}>
                {fetchedData?.search?.results.map((item) => {
                  return (
                    <ImageThumbnail key={item.id}>
                      <a
                        href={
                          item.editUrl
                            ? item.editUrl
                            : item.contentType + "s/" + item.id
                        }
                      >
                        <Image
                          asset={item.asset as Asset}
                          overflowStyle="fill"
                          cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
                          aspectRatio={"1:1"}
                          modifications={{ width: 122, height: 122 }}
                        />
                      </a>
                      <div>
                        {item.contentType.charAt(0).toUpperCase() +
                          item.contentType.slice(1)}
                      </div>
                    </ImageThumbnail>
                  );
                })}
              </ResultList>
            </ResultSection>
            <Footer>
              <FooterTitle>
                <Link
                  to={"/" + currentOrganization.metadata.copilotCode + viewURL}
                >
                  View All
                </Link>
              </FooterTitle>
            </Footer>
          </>
        ) : (
          <></>
        )}
      </UploadsWrapper>
    </>
  );
};

import { defineMessages } from "react-intl";

export const CONTEXTUAL_HELP = defineMessages({
  hideFromSearch: {
    defaultMessage:
      "This will not hide content from Google, but may impact its searchability. Excluded content can still be searched in Copilot.",
    description: "contextualHelp.hideFromSearch",
  },
  exclusionHelper: {
    defaultMessage:
      "Use AND to limit results to content that must contain all of your criteria. Use OR to broaden the search to contain at least one of your criteria.",
    description: "contextualHelp.exclusionHelper",
  },
  inclusionHelper: {
    defaultMessage:
      "Use AND to limit results to content that must contain all of your criteria. Use OR to broaden the search to contain at least one of your criteria.",
    description: "contextualHelp.inclusionHelper",
  },
  longDekHelper: {
    defaultMessage: "This field is known as longDek in the Content API",
    description: "contextualHelp.longDekHelper",
  },
  seoCategory: {
    defaultMessage:
      "Choose News for timely or event-based content. Choose Evergreen for content that will always be relevant or of interest. Ex. Choose Evergreen if you envision this content being shared 3-6 months after publication.",
    description: "contextualHelp.seoCategory",
  },
  statusCodeHelper: {
    defaultMessage:
      "If you are unsure, select 301. If you think this redirect should be a 302 please confirm with your SEO contact.",
    description: "contextualHelp.statusCodeHelper",
  },
  targetHelper: {
    defaultMessage:
      "If your brand uses trailing slashes, add a trailing slash to the end of the URL in this field. If you are unsure please confirm with your SEO contact.",
    description: "contextualHelp.targetHelper",
  },
});

import { ValidationError } from "@condenast/cross-check";
import {
  BasicValidator,
  ValidationBuilder,
  builderFor,
} from "@condenast/cross-check-dsl";
import type { ValidationEnvironment } from "@lib";
import type { FormFor_content } from "@types";

class PublishURIValidator extends BasicValidator<unknown, void> {
  static validatorName = "publish-uri";

  async validate(value: FormFor_content): Promise<ValidationError[]> {
    const errors: ValidationError[] = [];
    if ("uri" in value) {
      const { id, uri } = value;
      if (!uri) {
        errors.push({
          path: ["uri"],
          message: { name: "blank", details: null },
        });
      } else {
        const countResults = await (this.env as ValidationEnvironment).count({
          uri,
          notid: id,
        });
        if (countResults && countResults.totalResults !== 0) {
          errors.push({
            path: ["uri"],
            message: { name: "notUnique", details: null },
          });
        }
      }
    }
    return errors;
  }
}

export function publishURI(): ValidationBuilder<unknown> {
  return builderFor(PublishURIValidator)();
}

import {
  Button,
  Card,
  Link,
  Page,
  Select,
  ServerError,
  TextField,
  Title,
  Toggle,
  ValidationSummary,
} from "@components";
import { useState, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { compareBrandOptions } from "../utils";
import {
  UserLoginProvider,
  UserRole,
  GetCurrentUser_currentUser as AuthenticatedUser,
  CreateUser,
  CreateUserInput,
  CreateUserVariables,
  Organization,
} from "@types";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import {
  useChangeset,
  useDefinedMessages,
  useToast,
  useUserAccessControlMatrix,
} from "@hooks";
import { ValidationError } from "@condenast/cross-check";
import { Mutations } from "@gql";
import { useMutation, ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { UserEmailTakenError } from "../UserEmailTakenError";
import { validateUser, formPropsGetter } from "@lib";

const Form = styled.form`
  display: grid;
  grid-template-rows: auto auto auto;
  margin: 0 auto;
  gap: var(--spacing-md);
  padding-top: var(--spacing-sm);
  max-width: ${(props) => props.theme.NarrowCardSize};
  width: 100%;
  grid-template-areas:
    "head"
    "body";

  ${Card} {
    grid-area: body;
  }
`;

const Header = styled.header`
  grid-area: head;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, auto);
  gap: var(--spacing-sm);
`;

const FormControls = styled.footer`
  display: flex;
  justify-content: end;
  padding-top: var(--spacing-md);
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-sm);
`;

export const UserCreate = (props: {
  currentOrganization: Organization;
  currentUser: AuthenticatedUser;
}) => {
  let [createUser, result] = useMutation<CreateUser, CreateUserVariables>(
    Mutations.CREATE_USER
  );
  const userCreateOnSubmit = (data: CreateUserInput) => {
    return createUser({ variables: { user: data } });
  };
  const copilotCode = props.currentOrganization.metadata.copilotCode;

  const permissions = useUserAccessControlMatrix(
    props.currentOrganization,
    "create",
    Object.values(UserRole)
  );
  const isRestricted = Object.values(permissions).every(
    (accessControl) => accessControl.create === false
  );

  if (result.error) {
    Sentry.captureException(result.error);
  }

  return isRestricted ? (
    <Navigate replace to={`/${copilotCode}/users/profile`} />
  ) : (
    <>
      <UserCreatePage
        error={result?.error}
        onSubmit={userCreateOnSubmit}
        {...props}
      ></UserCreatePage>
      {result.data?.createUser?.user?.id && (
        <Navigate
          replace
          to={`/${copilotCode}/users/${result.data.createUser.user.id}/edit`}
        />
      )}
    </>
  );
};

export const UserCreatePage = (props: {
  currentUser: AuthenticatedUser;
  currentOrganization: Organization;
  error?: ApolloError;
  onSubmit: (user: CreateUserInput) => Promise<{
    data?: CreateUser | null;
  }>;
}) => {
  const { currentOrganization, error, onSubmit } = props;
  const intl = useIntl();
  let [errors, setErrors] = useState<ValidationError[]>([]);
  let {
    translateFieldName,
    translateRoleName,
    translateFieldError,
    translateSummaryErrors,
  } = useDefinedMessages();
  const userData = useMemo(
    () => ({
      firstName: "",
      lastName: "",
      email: "",
      role: UserRole.contributor,
      loginProvider: UserLoginProvider.copilot,
      brandCodes: [currentOrganization.metadata.copilotCode],
    }),
    [currentOrganization]
  );
  const [user, setValue] = useChangeset(userData);
  let formPropsForField = useMemo(
    () =>
      formPropsGetter(
        user,
        errors,
        setValue,
        translateFieldName,
        translateFieldError
      ),
    [user, errors, setValue, translateFieldName, translateFieldError]
  );

  const createableRoles = useUserAccessControlMatrix(
    props.currentOrganization,
    "create",
    Object.values(UserRole)
  );

  const roleOptions = useMemo(
    () =>
      Object.values(UserRole)
        .filter((role) => createableRoles[role]?.create)
        .map((role) => ({
          label: translateRoleName(role),
          value: role,
        })),
    [createableRoles, translateRoleName]
  );

  const showSaveSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Created",
    }),
  });

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: "Create User",
      })}
    >
      <Form
        noValidate
        onSubmit={(evt) => {
          evt.preventDefault();
          validateUser(user).then((validationErrors) => {
            setErrors(validationErrors);
            if (validationErrors.length === 0) {
              onSubmit(user).then((result) => {
                if (result.data) {
                  showSaveSuccessMessage();
                }
              });
            }
          });
        }}
      >
        <Header>
          <Title
            breadcrumbs={[
              <Link
                to={`/${currentOrganization.metadata.copilotCode}/users`}
                key="my-team"
              >
                <FormattedMessage defaultMessage="My Team" />
              </Link>,
            ]}
          >
            <FormattedMessage defaultMessage="Create User" />
          </Title>
          {errors.length > 0 && (
            <ValidationSummary errors={translateSummaryErrors(errors)} />
          )}
          {error && (
            <ServerError
              error={error}
              responses={{ USER_EMAIL_TAKEN: UserEmailTakenError }}
            />
          )}
        </Header>
        <Card size="narrow">
          <Row>
            <TextField {...formPropsForField("firstName")} multiline={false} />
            <TextField {...formPropsForField("lastName")} multiline={false} />
          </Row>
          <TextField {...formPropsForField("email")} multiline={false} />
          <Select
            {...formPropsForField("role")}
            value={user.role}
            onChange={(value: string | undefined) => {
              setValue("role", value as UserRole);

              // clears out brands when user selects Super Admin
              if (value === UserRole.superadmin) {
                setValue("brandCodes", []);
              }
            }}
            options={roleOptions}
            multiple={false}
          />
          <Select
            {...formPropsForField("brandCodes")}
            multiple={true}
            placeholder={intl.formatMessage({
              defaultMessage: "Select brands",
            })}
            disabled={user.role === UserRole.superadmin}
            options={props.currentUser.organizations
              .map((organization) => {
                return {
                  label: organization.internalDisplayName,
                  value: organization.metadata.copilotCode,
                };
              })
              .sort(compareBrandOptions)}
            label={intl.formatMessage({
              defaultMessage: "Brands",
              description: "User brands label",
            })}
          />
          <Toggle
            {...formPropsForField("loginProvider")}
            value={user.loginProvider === "okta"}
            onChange={(value) =>
              setValue(
                "loginProvider",
                value ? UserLoginProvider.okta : UserLoginProvider.copilot
              )
            }
            message={intl.formatMessage({
              defaultMessage: "Requires a Condé Nast or brand email address",
            })}
          />
          <FormControls>
            <Button type="submit" treatment="primary">
              <FormattedMessage defaultMessage="Create" />
            </Button>
          </FormControls>
        </Card>
      </Form>
    </Page>
  );
};

UserCreatePage.displayName = "UserCreatePage";

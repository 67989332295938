import type { ContentSerializers } from "../types";
import type { ClipInput } from "@types";
import {
  contentConnection,
  allContributors,
  categoryTaxonomies,
} from "../fields";
import { serializerFor, entityMetadata } from "../utils";

const ClipFields = {
  allContributors,
  photosTout: contentConnection,
  categoryTaxonomies,
} as const;

export const ClipSerializers: ContentSerializers<ClipInput> = {
  save: (changes, model, { currentUser }) => {
    return {
      ...serializerFor(ClipFields)(changes),
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser }) => {
    return {
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

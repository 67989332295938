import { useMutation } from "@apollo/client";
import {
  ContentSummary,
  ImportProduct,
  ImportProductVariables,
  ContentSummaryFields_metadata_ContentSummaryOffer as ContentSummaryOffer,
} from "@types";
import { Mutations } from "@gql";
import { normalizeContentSummary } from "@lib";
import { SearchPostProcessor } from "../types";
import { useDefinedMessages } from "@hooks";

export function useProductPostProcessor(
  organizationId: string
): SearchPostProcessor {
  const [importProduct, { loading }] = useMutation<
    ImportProduct,
    ImportProductVariables
  >(Mutations.IMPORT_PRODUCT);

  const { translateContentType } = useDefinedMessages();

  return {
    loading,
    process: async (items: ContentSummary[]) => {
      const content = items.filter((item) => {
        const offerMetadata = item.metadata?.find(
          ({ type }) => type === "ContentSummaryOffer"
        ) as ContentSummaryOffer | undefined;
        return (
          item.contentType === "product" && offerMetadata?.hasSimilarProduct
        );
      });
      if (!content.length) {
        return items;
      }
      const result = await importProduct({
        variables: {
          organizationId,
          data: content.map((item) => {
            const offerData = item.metadata?.find(
              ({ type }) => type === "ContentSummaryOffer"
            ) as ContentSummaryOffer | undefined;
            return {
              name: item?.title?.content,
              description: item?.caption?.content,
              offers: offerData
                ? [
                    {
                      currency: offerData.currency,
                      price: offerData.price?.toString(),
                      seller: {
                        name: offerData.seller,
                      },
                      purchaseUri: offerData.offerUrl,
                      autoUpdate: false,
                    },
                  ]
                : [],
              imageUrl: item.asset?.publicURL,
              meta: {
                collectionName: "products",
                modelName: "product",
              },
            };
          }),
        },
      });
      const resultData = result.data?.importProduct.map((result) =>
        normalizeContentSummary(result, translateContentType)
      );
      if (!resultData) {
        return items;
      }

      return items.map((item) =>
        item.contentType === "product" ? resultData.shift() ?? null : item
      );
    },
  };
}

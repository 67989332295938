import { LodgingOfferFields, VenueOfferInput } from "@types";

export function lodgingOffers(input?: LodgingOfferFields[]): VenueOfferInput[] {
  return (
    input?.map(({ seller, purchaseUri }) => ({
      seller: seller ? { name: seller.name } : undefined,
      purchaseUri,
    })) ?? []
  );
}

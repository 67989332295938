import styled from "styled-components";
import { ThemeProvider } from "@contexts";
import type {
  PageLayoutCategory,
  ImageUrlSelectState,
  PageLayoutSelectorConfig,
  VideosLede,
  Trie,
  ToggleMap,
} from "@types";
import { VideoPreview } from "./VideoPreview";
import { Toggle } from "@components";
import { useDefinedMessages } from "@hooks";

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: ${(props) => props.theme.ToastBackground};
  border: 1px solid ${(props) => props.theme.DividerColor};

  overflow-y: hidden;
  border-radius: var(--spacing-xxs);
`;

const VideoStorySelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-sm);
  max-width: calc(var(--spacing-lg) * 7);
  border-right: 1px solid ${(props) => props.theme.DividerColor};
`;

const ToggleWrapper = styled.div`
  padding: var(--spacing-xs) 0;
  border-top: 1px solid var(--color-gray-5);
  &:first-child {
    border-top: none;
  }
`;

type VideoConfig = {
  disableAutoplay: boolean;
  muted: boolean;
  loopVideo: boolean;
  disableAds: boolean;
  continuousPlay: boolean;
};

const TOGGLES = [
  "disableAutoplay",
  "muted",
  "loopVideo",
  "disableAds",
  "continuousPlay",
] as (keyof VideoConfig)[];

export function VideoConfiguration({
  currentToggleSelection,
  currentCategory,
  videosLede,
  enableLivePreview,
  lang,
  videoConfig,
  setVideoConfig,
}: {
  currentToggleSelection?: ToggleMap;
  toggleDecisionTree?: Trie;
  currentCategory?: PageLayoutCategory;
  config?: PageLayoutSelectorConfig;
  videoConfig: VideoConfig;
  setVideoConfig: (videoConfig: VideoConfig) => void;
  videosLede: VideosLede | undefined;
  enableLivePreview?: boolean;
  setPageLayoutCategory?: (level: string, option: string) => void;
  resetToDefault?: () => void;
  mediaDomain?: string;
  lang?: string;
}) {
  const { translateFieldName } = useDefinedMessages();
  let imageUrl: ImageUrlSelectState = { sm: undefined, lg: undefined };

  return (
    <ThemeProvider theme="light">
      <Wrapper>
        <VideoStorySelectorWrapper>
          {TOGGLES.map((name, index) => {
            return (
              <ToggleWrapper key={name + `_` + index}>
                <Toggle
                  id={name + `_` + index}
                  label={translateFieldName(name)}
                  onChange={(value: boolean) => {
                    const newConfig = { ...videoConfig };
                    newConfig[name] = value;
                    if (name === "disableAutoplay" && !value) {
                      newConfig.muted = true;
                    }
                    if (name === "loopVideo" && value) {
                      newConfig.continuousPlay = false;
                    }
                    if (name === "continuousPlay" && value) {
                      newConfig.loopVideo = false;
                    }

                    setVideoConfig(newConfig);
                  }}
                  disabled={
                    (name == "muted" && !videoConfig.disableAutoplay) ||
                    (name == "continuousPlay" && videoConfig.loopVideo) ||
                    (name == "loopVideo" && videoConfig.continuousPlay)
                  }
                  value={videoConfig[name]}
                />
              </ToggleWrapper>
            );
          })}
        </VideoStorySelectorWrapper>
        <VideoPreview
          previewProps={currentToggleSelection}
          enableLivePreview={enableLivePreview}
          currentCategory={currentCategory}
          imageUrl={imageUrl}
          videoLede={videosLede}
          lang={lang}
        />
      </Wrapper>
    </ThemeProvider>
  );
}

VideoConfiguration.displayName = "VideoConfiguration";

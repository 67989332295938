import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { markdownLinks, required } from "@condenast/copilot-validators";
import { noEmptyAccordionHeaders } from "../../../validators/markdown-accordions";

import type { ValidationEnvironment, ValidationContext } from "@lib";
import type { FormFor_content_Article } from "@types";
import { channelsOrSections, publishURI } from "../../../validators";

const { object } = validators;

const articleValidators = object({
  hed: required(),
  body: object({
    content: required()
      .andAlso(markdownLinks())
      .andAlso(noEmptyAccordionHeaders()),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  categoryTaxonomies: channelsOrSections().on("publish"),
})
  .andAlso(publishURI().on("publish"))
  .catch((articleErrors) => {
    let categoryTaxonomyErrors = articleErrors.filter((error) =>
      error.path.find((pathItem) => pathItem === "categoryTaxonomies")
    );
    if (categoryTaxonomyErrors) {
      categoryTaxonomyErrors.forEach(
        (categoryTaxonomyError) =>
          (categoryTaxonomyError.path = [
            categoryTaxonomyError.path
              .join("")
              .replace("categoryTaxonomies", ""),
          ])
      );
    }

    return articleErrors;
  });

export default function vvalidateArticle(
  article: FormFor_content_Article,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(article, build(articleValidators), context, env);
}

import { ReactNode } from "react";
import { Queries } from "@gql";
import { useQuery } from "@apollo/client";
import type {
  FrontEndApplicationRefs,
  FrontEndApplicationRefsVariables,
  FrontEndApplicationRefs_frontEndApplication_refs_edges as RefEdge,
} from "@types";
import { config, isKeyOf } from "@lib";
import styled from "styled-components";
import { defineMessages, FormattedMessage } from "react-intl";
import { ARIA, Button } from "@components";
import {
  RedirectIcon,
  PrimaryIcon,
  ChevronUpIcon,
} from "@condenast/gemini/icons";

const ENVIRONMENTS = defineMessages({
  development: {
    defaultMessage: "Development",
  },
  ci: {
    defaultMessage: "CI",
  },
  staging: {
    defaultMessage: "Staging",
  },
  production: {
    defaultMessage: "Production",
  },
});

const BuildSwitcherMenuItem = styled.div<{ $gridTemplateAreas: string[] }>`
  display: grid;
  column-gap: var(--spacing-xs);
  row-gap: var(--spacing-xxs);
  grid-template-columns: 1fr auto;
  grid-template-rows: ${(props) =>
    props.$gridTemplateAreas.map(() => "auto").join(" ")};
  grid-template-areas: ${(props) =>
    props.$gridTemplateAreas.map((area) => `"${area}"`).join("")};
  align-items: center;
`;

const BranchContainer = styled.div`
  grid-area: branch;
  & > a {
    background: ${(props) => props.theme.BorderlessHoverBackground};
    font: ${(props) => props.theme.FontCode};
    color: ${(props) => props.theme.Color};

    &:not(:disabled):active {
      color: ${(props) => props.theme.Color};
    }
  }
`;

const Title = styled.span`
  grid-area: title;
  font: ${(props) => props.theme.FontLabel};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    margin-left: var(--spacing-xxs);
    vertical-align: middle;
    display: inline-block;
  }
`;

const Commit = styled.span`
  grid-area: commit;
  font: ${(props) => props.theme.FontCode};
`;

const StyledButton = styled(ARIA.MenuButton)`
  padding: var(--spacing-xxs) var(--spacing-xs);
  border-radius: var(--spacing-sm);
  font: ${(props) => props.theme.FontLabel};
  position: fixed;
  bottom: var(--spacing-sm);
  right: var(--spacing-sm);
  max-width: calc(100vw - var(--spacing-lg));

  &,
  &:not(:disabled):hover,
  &:not(:disabled)[aria-expanded="true"] {
    color: ${(props) => props.theme.MedallionColor};
    background: ${(props) => props.theme.MedallionBackground};
  }

  &:not(:disabled)[aria-expanded="true"] > svg {
    transform: rotate(180deg);
  }

  ${Title} {
    grid-area: initial;
  }

  & + ul li {
    max-width: calc(100vw - var(--spacing-lg));
  }
`;

function getBuildLink(branchName: string | undefined) {
  if (branchName === env || branchName == null || branchName === "main") {
    return `http://stg-copilot.condenast.io${window.location.pathname}`;
  }
  return `http://stg-copilot-multibranch.condenast.io${window.location.pathname}?branch=${branchName}`;
}

function Ref(props: {
  data: RefEdge;
  githubRepositoryOwner: string;
  githubRepositoryName: string;
}) {
  let pullRequest = props.data.githubBranchInformation?.githubPullRequest;
  let ref = props.data.node;
  let branchUrl = `https://github.com/${props.githubRepositoryOwner}/${
    props.githubRepositoryName
  }/compare/${props.data.node?.refName ?? ""}`;

  if (ref == null) {
    throw new Error("Refs must have a node");
  }

  return (
    <BuildSwitcherMenuItem
      $gridTemplateAreas={
        pullRequest ? ["title commit", "branch branch"] : ["branch commit"]
      }
    >
      {pullRequest && <Title>{pullRequest.title}</Title>}
      <BranchContainer>
        <Button<"a">
          size="small"
          treatment="borderless"
          as="a"
          href={pullRequest?.permalink ?? branchUrl}
          target="_blank"
          rel="noreferrer nofollow"
          onClick={(evt) => evt.stopPropagation()}
        >
          {ref.refName}
          <RedirectIcon size="small" />
        </Button>
      </BranchContainer>
      <Commit>{ref.build.id.slice(0, 7)}</Commit>
    </BuildSwitcherMenuItem>
  );
}
Ref.displayName = "BuildSwitcher__Ref";

const env = isKeyOf(ENVIRONMENTS, config.environment as string)
  ? (config.environment as keyof typeof ENVIRONMENTS)
  : "ci";

function DeployedBuild() {
  return (
    <BuildSwitcherMenuItem $gridTemplateAreas={["title commit"]}>
      <Title>
        <FormattedMessage {...ENVIRONMENTS[env]} />
        <PrimaryIcon size="small" />
      </Title>
      <Commit>{((config.version ?? "") as string).slice(0, 7)}</Commit>
    </BuildSwitcherMenuItem>
  );
}
DeployedBuild.displayName = "BuildSwitcher__DeployedBuild";

export function BuildSwitcher(props: {
  organizationId: string;
  applicationId: string;
}) {
  const { data, loading } = useQuery<
    FrontEndApplicationRefs,
    FrontEndApplicationRefsVariables
  >(Queries.GET_FRONT_END_APPLICATION_REFS, {
    variables: {
      organizationId: props.organizationId,
      applicationId: props.applicationId,
      page: 1,
      limit: 50,
    },
  });

  if (
    config.environment === "production" ||
    loading ||
    data?.frontEndApplication == null
  ) {
    return <></>;
  }

  let title: ReactNode;
  let options: Array<RefEdge | null> = [null];

  let refs = data?.frontEndApplication?.refs?.edges ?? [];

  if (window.location.search.indexOf("branch=") > -1) {
    title = new URL(location.href).searchParams.get("branch");
    options.push(...refs);
  } else {
    title = (
      <>
        <FormattedMessage {...ENVIRONMENTS[env]} />
        <PrimaryIcon size="small" />
      </>
    );
    options.push(...refs);
  }

  return (
    <StyledButton
      id="build-switcher"
      size="small"
      menu={{
        theme: "modal",
        items: options.map(
          (item) =>
            ({
              role: "link",
              value: item,
              href: getBuildLink(item?.node?.refName),
              rel: "noreferrer noopener",
            } as const)
        ),
        children(item: RefEdge | null) {
          return item ? (
            <Ref
              data={item}
              githubRepositoryOwner={
                data?.frontEndApplication?.githubRepositoryOwner ?? ""
              }
              githubRepositoryName={
                data?.frontEndApplication?.githubRepositoryName ?? ""
              }
            />
          ) : (
            <DeployedBuild />
          );
        },
      }}
      popper={{
        placement: "top-end",
        strategy: "fixed",
      }}
    >
      <Title>{title}</Title>
      <ChevronUpIcon size="small" />
    </StyledButton>
  );
}

BuildSwitcher.displayName = "BuildSwitcher";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { ToggleHelpIcon } from "./ToggleHelpIcon";

const EditorRegion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  grid-area: control;

  padding: var(--spacing-xl);

  background: ${(props) => props.theme.PrimaryDisabledBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) => props.theme.Color};
  word-break: break-word;
  border: none;
`;

const HelpText = styled.p`
  font: var(--font-body);
`;

export const InvalidMarkdownMessage = () => {
  return (
    <EditorRegion>
      <ToggleHelpIcon />
      <HelpText>
        <FormattedMessage
          defaultMessage={
            "This field contains code that can’t be viewed in Rich Text mode. Switch to Markdown mode to edit this field."
          }
        />
      </HelpText>
    </EditorRegion>
  );
};

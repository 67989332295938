import type { CategoryTaxonomyFields_categories } from "@types";

export class NoCategoriesError extends Error {
  constructor() {
    super(
      "None of the configured verso header settings categories were found under verso-settings/article-settings/ContentHeader/variation"
    );
  }
}

export class EmptyConfigurationError extends Error {
  constructor() {
    super("Brand has not enabled any Verso header settings.");
  }
}

export class DefaultCategoryError extends Error {
  constructor(defaultCategorySlug: string) {
    super(
      `The configured default page layout category slug, ${defaultCategorySlug}, does not match any existing category.`
    );
  }
}

export class SelectedCategoryError extends Error {
  category: CategoryTaxonomyFields_categories;
  constructor(category: CategoryTaxonomyFields_categories) {
    super(
      `This brand does not support the Verso header setting ${category.slug}.`
    );

    this.category = category;
  }
}

export class MissingToggleConfigurationError extends Error {
  category: CategoryTaxonomyFields_categories;
  constructor(category: CategoryTaxonomyFields_categories) {
    super(
      `There is no category-toggle-mapping configured for the selected layout ${category.slug}.`
    );

    this.category = category;
  }
}

export class DuplicateToggleConfigurationError extends Error {
  conflict: { path: string[]; categories: string[] };
  constructor(category1: string, category2: string) {
    super(
      `Two categories, ${category1} and ${category2}, have the same toggle settings in the category-toggle-mapping config.`
    );

    this.conflict = {
      path: [],
      categories: [category1, category2],
    };
  }

  addPathSegment(key: string) {
    this.conflict.path = [key, ...this.conflict.path];
  }
}

export class NoArticleConfigError extends Error {
  constructor() {
    super("This brand does not contain an article content type configuration");
  }
}

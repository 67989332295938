import { useRef } from "react";
import type { FC, RefObject } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@hooks";
import type {
  GetBrand,
  GetBrandVariables,
  GetBrand_brandConfiguration,
  GetBundle,
  GetBundleVariables,
  GetCurrentUser_currentUser,
  FromGQL,
  Organization,
  GetConfigs,
  GetConfigsVariables,
} from "@types";
import { singularize } from "@lib";
import { Queries } from "@gql";
import { useQuery, ApolloError } from "@apollo/client";
import { getPreviewConfig, getPreviewURL } from "./Preview.utils";
import { PreviewPage } from "./PreviewPage";
import { PreviewIframe } from "./PreviewIframe";
import { PreviewOptionsMenu } from "./OptionsMenu";

function reloadIframe(ref: RefObject<HTMLIFrameElement>) {
  if (ref.current) {
    /**
     * Since preview iframe domain is different than copilot domain, due to same-origin policy we cannot access its content window.
     * So employing this hack to reload iframe.
     *
     * ref: https://developer.mozilla.org/en-US/docs/Web/Security/Same-origin_policy
     */
    const { src } = ref.current;
    ref.current.src = src;
  }
}

const BundlePreview: FC<{
  configuration: FromGQL<GetBrand_brandConfiguration> | undefined;
  contentType: string;
  id: string;
  currentUser: GetCurrentUser_currentUser;
  currentOrganization: Organization;
  brandError: ApolloError | undefined;
}> = ({
  configuration,
  contentType,
  id,
  currentUser,
  currentOrganization,
  brandError,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const {
    data,
    loading,
    error: bundleError,
  } = useQuery<GetBundle, GetBundleVariables>(Queries.GET_BUNDLE, {
    variables: { id, organizationId: currentOrganization.organizationId },
  });

  const { data: configs } = useQuery<GetConfigs, GetConfigsVariables>(
    Queries.GET_CONFIGS,
    {
      variables: {
        organizationId: currentOrganization.organizationId,
        userId: currentUser.id,
      },
    }
  );

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const bundleType = data?.bundle?.bundleType;
  const previewConfig = getPreviewConfig(
    configuration,
    contentType,
    bundleType
  );
  const previewURL = getPreviewURL(previewConfig, id, bundleType) || "";
  const defaultViewMode = previewConfig.useDesktopView ? "desktop" : "mobile";
  const isVersoHomePage = bundleType === "Verso Homepage";
  const enableBundleLayoutOptions =
    configs?.configs.bundleLayoutOptions && isVersoHomePage;
  const renderPreviewOptions = enableBundleLayoutOptions && data;

  return (
    <PreviewPage
      previewURL={previewURL}
      defaultViewMode={defaultViewMode}
      isError={brandError || bundleError}
      onRefresh={reloadIframe.bind(null, iframeRef)}
      contentType={contentType}
      contentId={id}
    >
      {renderPreviewOptions && (
        <PreviewOptionsMenu
          bundleData={data.bundle}
          currentOrganization={currentOrganization}
          currentUser={currentUser}
          onSave={reloadIframe.bind(null, iframeRef)}
        />
      )}
      <PreviewIframe
        previewURL={previewURL}
        isLoading={loading}
        isMobile={isMobile}
        ref={iframeRef}
      />
    </PreviewPage>
  );
};

export function Preview(props: { currentUser: GetCurrentUser_currentUser }) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { copilotCode, collection, id } = useParams() as {
    copilotCode: string;
    collection: string;
    id: string;
  };
  const currentOrganization = props.currentUser.organizations.find(
    (organization) => {
      return organization.metadata.copilotCode === copilotCode;
    }
  );

  if (currentOrganization == null) {
    throw new Error(
      `User has no access to the organization with the copilotCode ${copilotCode}`
    );
  }

  let {
    data,
    loading,
    error: brandError,
  } = useQuery<GetBrand, GetBrandVariables>(Queries.GET_BRAND, {
    variables: {
      organizationId: currentOrganization.organizationId,
    },
  });
  const contentType = singularize(collection);
  const previewConfig = getPreviewConfig(data?.brandConfiguration, contentType);
  const previewURL = getPreviewURL(previewConfig, id) || "";
  const defaultViewMode = previewConfig.useDesktopView ? "desktop" : "mobile";

  if (contentType === "bundle") {
    return (
      <BundlePreview
        configuration={data?.brandConfiguration}
        contentType={contentType}
        id={id}
        currentUser={props.currentUser}
        currentOrganization={currentOrganization}
        brandError={brandError}
      />
    );
  }

  return (
    <PreviewPage
      previewURL={previewURL}
      defaultViewMode={defaultViewMode}
      isError={brandError}
      contentType={contentType}
      contentId={id}
    >
      <PreviewIframe
        previewURL={previewURL}
        isLoading={loading}
        isMobile={isMobile}
      />
    </PreviewPage>
  );
}

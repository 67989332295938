import type { History, Transition } from "history";
import {
  ContextType,
  useContext,
  useCallback,
  useState,
  useLayoutEffect,
  ReactNode,
} from "react";
import {
  Navigator as BaseNavigator,
  // react-router v6 hides the "block" function from the history API,
  // so we need to dig into the internals a bit.
  UNSAFE_NavigationContext as NavigationContext,
} from "react-router-dom";
import { ARIA, Button } from "@condenast/gemini";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { SnowplowContext } from "@contexts";

const DialogBody = styled.div`
  padding: var(--spacing-xs) var(--spacing-md) var(--spacing-sm);
`;

interface Navigator extends BaseNavigator {
  block: History["block"];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: Navigator;
};

/**
 * This component uses unstable APIs within React Router, which will definitely be removed or relocated in future versions.
 * If this breaks, consult https://github.com/remix-run/react-router/issues/8139 to find the current state of the relevant
 * features.
 */
export function UnsavedChangesDialog(props: {
  hasChanges?: boolean;
  title: ReactNode;
  message: ReactNode;
}) {
  let [blockedTransition, setBlockedTransition] = useState<Transition | null>(
    null
  );

  const { trackComponentEvent } = useContext(SnowplowContext);

  let confirm = useCallback(() => {
    blockedTransition?.retry();
    setBlockedTransition(null);
    trackComponentEvent("unsaved_changed_dialog", "button_click", "confirm");
  }, [blockedTransition]);
  let cancel = useCallback(() => {
    setBlockedTransition(null);
    trackComponentEvent("unsaved_changed_dialog", "button_click", "cancel");
  }, []);

  const { navigator } = useContext(
    NavigationContext
  ) as NavigationContextWithBlock;

  useLayoutEffect(() => {
    if (!props.hasChanges) {
      return;
    }

    const unblock = navigator.block((transition) => {
      setBlockedTransition({
        ...transition,
        retry() {
          unblock();
          transition.retry();
        },
      });
    });

    return unblock;
  }, [navigator, props.hasChanges]);

  return (
    blockedTransition && (
      <ARIA.Dialog
        position="top"
        size="narrow"
        title={props.title}
        submitButton={
          <Button treatment="primary" onClick={confirm}>
            <FormattedMessage defaultMessage="Yes" />
          </Button>
        }
        cancelButton={
          <Button onClick={cancel}>
            <FormattedMessage defaultMessage="No" />
          </Button>
        }
        onClose={cancel}
      >
        <DialogBody>{props.message}</DialogBody>
      </ARIA.Dialog>
    )
  );
}
UnsavedChangesDialog.displayName = "UnsavedChangesDialog";

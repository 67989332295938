import styled, { DefaultTheme } from "styled-components";
import {
  PublishedIcon,
  ScheduledIcon,
  PublishedWithChangesIcon,
  ScheduledWithChangesIcon,
} from "@condenast/gemini/icons";
import { ThemeProvider } from "@contexts";

import { withTooltip } from "@components";
import { PublishInfo } from "@types";

export const StatusIconV2 = withTooltip(
  styled.span`
    color: ${(props) => props.theme.StatusPendingColor};
    circle {
      fill: ${(props) => props.theme.Background};
    }
  `
);

function transformReds(theme: DefaultTheme) {
  return {
    ...theme,
    Background: "var(--color-red-50)",
  };
}

export function PublishStatusIconV2(props: {
  publishInfo: PublishInfo;
  size: "small";
  latestRevision: number;
  isScheduled: boolean;
}) {
  let { publishInfo, latestRevision, isScheduled, size, ...forwardProps } =
    props;
  let hasEdits = publishInfo.version < latestRevision;
  let isLive = new Date(publishInfo?.pubDate).getTime() < new Date().getTime();
  let Icon;
  if (isLive && !isScheduled) {
    if (hasEdits) {
      Icon = PublishedWithChangesIcon;
    } else {
      Icon = PublishedIcon;
    }
  } else {
    if (hasEdits) {
      Icon = ScheduledWithChangesIcon;
    } else {
      Icon = ScheduledIcon;
    }
  }

  return (
    <ThemeProvider
      tint={hasEdits ? "red" : "gray"}
      transform={hasEdits ? transformReds : undefined}
    >
      <StatusIconV2>
        <Icon size={props.size} {...forwardProps} />
      </StatusIconV2>
    </ThemeProvider>
  );
}

PublishStatusIconV2.displayName = "PublishStatusIconV2";

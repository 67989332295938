import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { required } from "@condenast/copilot-validators";

import type { ValidationContext, ValidationEnvironment } from "@lib";
import type { FormFor_content_Brand } from "@types";

const { object } = validators;

const brandValidators = object({
  name: required(),
});

export default function validateBrand(
  brand: FormFor_content_Brand,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(brand, build(brandValidators), context, env);
}

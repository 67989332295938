import type { PublishHelper } from "../types";
import type { TagpageFields_query } from "@types";

/**
 * Get the name of TagPage from the parent category
 *
 * Ex: if the categories is [
 * {
 *  "name": "5 Ingredients or Fewer",
 *  "slug": "5-ingredients-or-fewer"
 * },
 * {
 *  "name": "Simple Cooking",
 *  "slug": "simple-cooking"
 * },
 * {
 *  "name": "Tags",
 *  "slug": "tags"
 * }
 * ]:
 * - /simple-cooking/5-ingredients-or-fewer
 *
 * @param model
 * @returns string | null
 */
export const primaryQueryCategory: PublishHelper = (model) => {
  const query = model["query"] as TagpageFields_query | null;
  const hierarchy = query?.filters?.categories?.[0]?.hierarchy || [];

  if (hierarchy.length > 2) {
    const primarySlug = hierarchy[hierarchy.length - 2].slug;
    return decodeURI(primarySlug);
  } else {
    return null;
  }
};

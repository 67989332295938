import { useCallback, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { pluralize } from "@lib";
import { SearchResult, Chip, ARIA, RoutableLink } from "@components";
import { Queries } from "@gql";
import { SearchIcon, NewlineIcon } from "@condenast/gemini/icons";
import { useLazyQuery } from "@apollo/client";
import { ComboboxItem } from "@condenast/gemini";
import {
  useContentAccessControlMatrix,
  useDebouncedCallback,
  useDefinedMessages,
} from "@hooks";
import { useRecentSearches } from "./hooks/useRecentSearches";
import type {
  GetBrand_brandConfiguration_contentTypes as TContentType,
  GetRecentSearch_recentSearches as TRecentSearch,
  Search_search_results as TSearchResult,
  Organization,
} from "@types";

import { MainSearchItem, RESULT_TYPES, AdvancedQuery } from "./types";
import { SnowplowContext } from "@contexts";

const QUICK_SEARCH_FIELDS = ["hed", "title", "name"];
export const QUICK_SEARCH_PARAMS = {
  d_o: "and",
  sort: "score asc",
  customRank: QUICK_SEARCH_FIELDS.map((field) => `${field}^5.0`).join(","),
};

const QUICK_SEARCH_DEBOUNCE_INTERVAL = 250; // milliseconds

const Result = styled(RoutableLink)`
  display: block;
  padding: 0;
  text-decoration: none;
  color: ${(props) => {
    return props.theme.Color;
  }};

  &:focus {
    outline: none;
  }

  [aria-selected] & {
    background: ${(props) => props.theme.BorderlessHoverBackground};
  }

  [aria-orientation="horizontal"] [role="option"][aria-selected] & {
    background: none;
    div {
      box-shadow: ${(props) => props.theme.FocusRing};
    }
  }
`;

const SearchLink = styled(RoutableLink)`
  padding: 0;
  text-decoration: none;
  color: ${(props) => {
    return props.theme.Color;
  }};

  &:focus {
    outline: none;
  }
  .action-tooltip {
    display: none;
    align-items: center;
    color: ${(props) => props.theme.PlaceholderColor};
  }
  [aria-selected] & {
    background: ${(props) => props.theme.BorderlessHoverBackground};
    .action-tooltip {
      display: inline-flex;
    }
  }
`;

const Query = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: "content content . newline";
  padding: var(--spacing-sm) var(--spacing-sm);

  .query-content {
    font: ${(props) => props.theme.FontStatement};
    display: inline-flex;
    grid-area: content;
    align-items: center;

    svg {
      margin-right: var(--spacing-xs);
    }
  }

  .action-tooltip {
    display: none;
    grid-area: newline;
    align-items: center;
    color: ${(props) => props.theme.PlaceholderColor};
  }

  [aria-selected] & {
    .action-tooltip {
      display: inline-flex;
    }
  }
`;

const RecentQueryOption = styled(Query)`
  padding: var(--spacing-xs) var(--spacing-sm);
`;

const StyledCombobox = styled(ARIA.Combobox)`
  max-width: calc(var(--spacing-xxs) * 220);

  &[aria-expanded="false"]:not([aria-busy]) {
    max-width: calc(var(--spacing-xxs) * 110);
  }

  @media (max-width: 650px) {
    &:not(:focus-within):not([aria-busy])[aria-expanded="false"] > div {
      border-radius: ${(props) => props.theme.CornerRadius};
      box-shadow: none;
      background: transparent;
      color: transparent;
      width: var(--spacing-xl);
      height: var(--spacing-xl);

      input {
        cursor: pointer;
        width: var(--spacing-xl);
        height: var(--spacing-xl);
        padding: 0;

        &::placeholder {
          opacity: 0;
        }
      }

      button {
        display: none;
      }

      .inputIcon {
        pointer-events: none;
        color: ${(props) => props.theme.Color};
        left: calc(50% - 8px);
      }

      &:hover {
        box-shadow: none;
        background-color: ${(props) => props.theme.BorderlessHoverBackground};
      }
    }
  }

  @media (max-width: 500px) {
    &:not(:focus-within):not([aria-busy])[aria-expanded="false"] > div {
      height: var(--spacing-lg);
      width: var(--spacing-lg);

      input {
        height: var(--spacing-lg);
        width: var(--spacing-lg);
      }
    }
  }

  ul[role="group"] {
    padding: var(--spacing-xs) 0 0 0;
  }

  ul[aria-orientation="horizontal"] {
    gap: var(--spacing-xs);
    padding: var(--spacing-xs) var(--spacing-sm) var(--spacing-sm);
  }

  li > span[role="presentation"] {
    padding: 0 var(--spacing-sm);
  }
`;

export function MainSearch(props: {
  contentTypes?: TContentType[];
  currentOrganization: Organization;
  hostname?: string;
  className?: string;
}) {
  const { contentTypes, currentOrganization, hostname, className } = props;
  const copilotCode = currentOrganization.metadata.copilotCode;
  const intl = useIntl();
  const { translateContentType } = useDefinedMessages();
  const { trackSearchEvent } = useContext(SnowplowContext);
  const cdnHost = `${window.location.protocol}//${currentOrganization.metadata.mediaDomain}`;

  //Spec for tracking search item selection:
  //https://cnissues.atlassian.net/browse/COPILOT-9421:
  function trackSelection(selection: MainSearchItem, index: number) {
    switch (selection.__typename) {
      case "Query":
        trackSearchEvent(
          "search",
          "search",
          "navbar_search",
          index,
          selection.query
        );
        break;
      case "RecentQuery":
        trackSearchEvent(
          "search",
          "search",
          "navbar_recent_search",
          index,
          selection.query
        );
        break;
      case "RecentSearchResult":
        trackSearchEvent(
          "search",
          "search",
          "navbar_recent_search_result",
          index
        );
        break;
      case "AdvancedQuery":
        trackSearchEvent(
          "selected",
          "filter",
          "navbar_type_search",
          index,
          selection.query,
          selection.filters.map((item) => {
            return { name: item?.filterName, label: item?.value };
          })
        );
        break;
      default:
        trackSearchEvent("search", "search", "navbar_default_search", index);
        break;
    }
  }

  let contentTypeValues = contentTypes?.map((contentType) => contentType.value);
  let pluralizedContentTypeValues =
    contentTypeValues?.map((contentTypeValue) => pluralize(contentTypeValue)) ||
    [];
  let contentTypePermissions = useContentAccessControlMatrix(
    currentOrganization,
    "search",
    pluralizedContentTypeValues
  );

  let allowedContentTypes = contentTypes
    ?.filter((contentTypeConfig) => {
      let pluralizedValue = pluralize(contentTypeConfig.value);
      return (
        contentTypePermissions[pluralizedValue]["search"] &&
        contentTypeConfig.discoverable?.advancedSearch
      );
    })
    .map(({ label, value }) => {
      return {
        label: translateContentType(value, 1, label),
        value,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label, intl.locale));

  const [getRecentSearches, addRecentSearch] = useRecentSearches(
    currentOrganization.metadata.copilotCode
  );

  let initialOptions = getDefaultOptions(getRecentSearches(), contentTypes);
  let [defaultOptions, setDefaultOptions] =
    useState<ComboboxItem<MainSearchItem>[]>(initialOptions);

  let [searchOptions, setSearchOptions] =
    useState<ComboboxItem<MainSearchItem>[]>();

  let [query, setQuery] = useState<string | null>(null);

  let [_lazySearch, { data, loading }] = useLazyQuery(Queries.SEARCH, {
    fetchPolicy: "network-only",
  });
  let nottypesArray = ["categories"];

  contentTypes?.forEach((contentType) => {
    if (!contentType.discoverable || !contentType.discoverable.quickSearch) {
      nottypesArray.push(contentType.value);
    }
  });
  let doSearch = useDebouncedCallback(
    (query) => {
      _lazySearch({
        variables: {
          organizationId: currentOrganization.organizationId,
          limit: 4,
          filters: {
            q: query || "",
            nottypes: nottypesArray.join(),
            ...QUICK_SEARCH_PARAMS,
          },
        },
      });
    },
    QUICK_SEARCH_DEBOUNCE_INTERVAL,
    [currentOrganization]
  );

  function getDefaultOptions(
    recentSearches: TRecentSearch[],
    contentTypes?: TContentType[]
  ) {
    let defaultSearchOptions: ComboboxItem<MainSearchItem>[] = [
      {
        role: "option",
        value: {
          __typename: RESULT_TYPES.query,
          query: "",
        },
      },
    ];
    if (recentSearches.length) {
      let recentSearchGroup = {
        label: intl.formatMessage({ defaultMessage: "Recent Searches" }),
        items: recentSearches.map((recentSearch) => {
          return {
            role: "option" as const,
            value: recentSearch,
          };
        }),
        role: "group" as const,
      };
      defaultSearchOptions.push(recentSearchGroup);
    }
    if (contentTypes && contentTypes.length) {
      let cannedSearchGroup = {
        label: intl.formatMessage({
          defaultMessage: "Explore by Content Type",
        }),
        items:
          allowedContentTypes?.map((contentTypeConfig) => {
            let { value, label } = contentTypeConfig;
            return {
              role: "option" as const,
              value: {
                __typename: RESULT_TYPES.advancedQuery,
                name: label,
                query: "",
                filters: [
                  {
                    filterName: "types" as const,
                    value,
                  },
                ],
              },
            };
          }) || [],
        role: "group" as const,
        orientation: "horizontal" as const,
      };
      defaultSearchOptions.push(cannedSearchGroup);
    }
    return defaultSearchOptions;
  }

  useEffect(() => {
    if (query) {
      let searchOptions: ComboboxItem<MainSearchItem>[] = [
        {
          role: "option",
          value: {
            __typename: RESULT_TYPES.query,
            query,
          },
        },
      ];
      data?.search.results.forEach((searchResult: TSearchResult) => {
        searchOptions.push({
          role: "option",
          value: searchResult,
        });
      });
      setSearchOptions(searchOptions);
    }
  }, [query, data, translateContentType]);

  const change = useCallback(
    (value) => {
      setQuery(value);
      doSearch(value);
    },
    [setQuery, doSearch]
  );

  const select = (value: MainSearchItem, index: number) => {
    trackSelection(value, index);
    switch (value.__typename) {
      case RESULT_TYPES.advancedQuery:
        return;
      case RESULT_TYPES.recentQuery:
      case RESULT_TYPES.query: {
        setDefaultOptions(
          getDefaultOptions(addRecentSearch(value), contentTypes)
        );
        window.location.assign(
          `${hostname}/${copilotCode}/search?query=${value.query}`
        );
        break;
      }
      case RESULT_TYPES.recentSearchResult:
        setDefaultOptions(
          getDefaultOptions(addRecentSearch(value), contentTypes)
        );
        window.location.assign(
          `${hostname}/${copilotCode}/${pluralize(value.type)}/${value.id}`
        );
        break;
      case RESULT_TYPES.searchResult: {
        setDefaultOptions(
          getDefaultOptions(addRecentSearch(value), contentTypes)
        );
        value.editUrl && window.location.assign(value.editUrl);
        break;
      }
    }

    setQuery(null);
    return "";
  };

  return (
    <StyledCombobox
      items={query ? searchOptions : defaultOptions}
      loading={loading}
      onChange={change}
      onSelect={select}
      icon={SearchIcon}
      placeholder={intl.formatMessage({
        defaultMessage: "Search for articles, galleries, and more",
      })}
      aria-label={intl.formatMessage({
        defaultMessage: "Search",
      })}
      className={className}
    >
      {(searchItem: MainSearchItem | AdvancedQuery) => {
        switch (searchItem.__typename) {
          case RESULT_TYPES.query:
            return (
              <Result
                to={`${hostname}/${copilotCode}/search?query=${searchItem.query}`}
              >
                <Query>
                  <div className="query-content">
                    {searchItem.query && <SearchIcon size="small" />}
                    {searchItem.query || "View all content"}
                  </div>
                  <div className="action-tooltip">
                    <NewlineIcon size="small" />
                  </div>
                </Query>
              </Result>
            );
          case RESULT_TYPES.recentQuery:
            return (
              <Result
                to={`${hostname}/${copilotCode}/search?query=${searchItem.query}`}
              >
                <RecentQueryOption>
                  <div className="query-content">
                    <SearchIcon size="small" />
                    {searchItem.query}
                  </div>
                  <div className="action-tooltip">
                    <NewlineIcon size="small" />
                  </div>
                </RecentQueryOption>
              </Result>
            );
          case RESULT_TYPES.recentSearchResult:
            return (
              <SearchResult
                as={SearchLink}
                to={`${hostname}/${copilotCode}/${pluralize(searchItem.type)}/${
                  searchItem.id
                }`}
                result={{
                  ...searchItem,
                  contentType: searchItem.type,
                  contentTypeLabel: translateContentType(searchItem.type, 1),
                  editUrl: null,
                  asset: null,
                  metadata: null,
                  description: null,
                  cneId: null,
                  embedUrl: null,
                  feedGuid: null,
                  transcriptUrl: null,
                  channel: null,
                  publishQueue: null,
                }}
                treatment="recent"
              >
                <div className="action-tooltip">
                  <NewlineIcon size="small" />
                </div>
              </SearchResult>
            );
          case RESULT_TYPES.advancedQuery: {
            let cannedSearchURL = new URL(
              `${hostname}/${copilotCode}/search?query=`
            );
            searchItem.filters.forEach((cannedSearchFilter) => {
              cannedSearchURL.searchParams.append(
                cannedSearchFilter?.filterName,
                cannedSearchFilter?.value
              );
            });
            return (
              <Result to={cannedSearchURL.toString()}>
                <Chip>{searchItem?.name ?? ""}</Chip>
              </Result>
            );
          }
          default: {
            searchItem;
            return (
              <SearchResult
                as={SearchLink}
                to={searchItem.editUrl}
                cdnHost={cdnHost}
                result={{
                  ...searchItem,
                  contentTypeLabel: translateContentType(
                    searchItem.contentType,
                    1
                  ),
                  metadata: null,
                }}
                treatment="quick"
              >
                <div className="action-tooltip">
                  <NewlineIcon size="small" />
                </div>
              </SearchResult>
            );
          }
        }
      }}
    </StyledCombobox>
  );
}
MainSearch.displayName = "MainSearch";

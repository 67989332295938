import { FormattedDate, FormattedTime } from "react-intl";
import styled from "styled-components";
import { PublishInfoFields, PublishedRevisions } from "@types";

const StyledDate = styled.div`
  color: var(--color-black);
  font: ${(props) => props.theme.FontSmallStatement};
  margin-right: var(--spacing-xs);
`;

export function PublishDate(props: {
  className?: string;
  publishInfo: PublishInfoFields;
  publishedRevisions: PublishedRevisions[];
}) {
  const { publishInfo, publishedRevisions } = props;
  const pubDate = publishInfo?.pubDate
    ? new Date(publishInfo.pubDate)
    : undefined;
  let isLive = new Date(publishInfo?.pubDate).getTime() < new Date().getTime();

  /** ensure story is published and scheduled is done */
  const isScheduled = () => {
    if (publishedRevisions?.length > 1) {
      const index = publishedRevisions.findIndex(
        ({ publishInfo: pubInfo }) => pubInfo?.version === publishInfo?.version
      );
      const sliceItems = publishedRevisions.slice(0, index);
      return !sliceItems.every(({ publishInfo: pubInfo }) => pubInfo?.expired);
    }
    return false;
  };

  const getScheduledDate = () => {
    if (isLive && isScheduled()) {
      const scheduledDateIndex = publishedRevisions.findIndex(
        ({ publishInfo: pubInfo }) => pubInfo?.version === publishInfo?.version
      );
      const sliceItems = publishedRevisions.slice(0, scheduledDateIndex);
      for (let i = sliceItems.length - 1; i >= 0; i--) {
        if (!sliceItems[i].publishInfo?.expired) {
          return sliceItems[i].publishInfo?.pubDate;
        }
      }
    }
    return pubDate;
  };

  return !pubDate || isNaN(pubDate.getTime()) ? (
    <></>
  ) : (
    <StyledDate>
      <FormattedDate
        value={new Date(getScheduledDate())}
        year="numeric"
        month="long"
        day="numeric"
      />
      &nbsp;
      <FormattedTime
        value={new Date(getScheduledDate())}
        hour="numeric"
        minute="2-digit"
      />
    </StyledDate>
  );
}

import type { ContentSerializers } from "../types";
import type { MusicReleaseInput } from "@types";
import { contentConnection } from "../fields";
import { serializerFor, entityMetadata } from "../utils";

const MusicReleaseFields = {
  photosTout: contentConnection,
  isReleaseOf: contentConnection,
  publisher: contentConnection,
  artwork: contentConnection,
} as const;

export const MusicReleaseSerializers: ContentSerializers<MusicReleaseInput> = {
  save: (changes, model, { currentUser }) => {
    return {
      ...serializerFor(MusicReleaseFields)(changes),
      name: ("name" in model && model.name) || "",
      disambiguatingDescription:
        ("disambiguatingDescription" in model &&
          model.disambiguatingDescription) ||
        "",
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
    return {
      disambiguatingDescription: "",
      name: createTitle,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

import { FC, ChangeEvent } from "react";
import { useQuery } from "@apollo/client";
import { Queries } from "@gql";
import {
  GetCommerceFilterInputVariables,
  GetCommerceFilterInput,
} from "@types";
import { ContentTypeIcon } from "@condenast/gemini/icons";
import type { SearchAdapterFactory } from "../types";
import { useIntl } from "react-intl";
import { SearchProvider, AssetSelectorSearchOptions } from "@types";
import styled from "styled-components";
import { Select, Input, Spinner } from "@components";
import { pick } from "lodash";
const FILTER_KEYS = [
  "retailer",
  "brand",
  "category",
  "category",
  "query",
  "tag",
  "minPrice",
  "maxPrice",
];

const StyledSelect = styled(Select)`
  grid-template-columns: 100%;
`;

const CommerceFilterContainer = styled.ul`
  display: flex;
  ul {
    min-width: 15rem;
    li {
      text-decoration: unset;
      min-width: 15rem;
      width: max-content;
      margin-right: var(--spacing-xs);
    }
  }
  li {
    min-width: 15rem;
    margin-right: var(--spacing-xs);
  }
`;

const StyledInput = styled(Input)`
  grid-template-columns: 100%;
  margin-top: 3px;
`;

const FilterContainerLoader = styled.div`
  display: flex;
  justify-content: center;
`;

const CommerceProductLabel: FC = () => {
  const intl = useIntl();
  return (
    <>
      <ContentTypeIcon contentType="product" size="regular" />
      <span>
        {intl.formatMessage({
          defaultMessage: "Commerce Products",
          description: "Search adapter name for Commerce Products",
        })}
      </span>
    </>
  );
};

const CommerceProductFilters: FC<{
  filters: AssetSelectorSearchOptions;
  setFilters: (filters: AssetSelectorSearchOptions) => void;
  currentOrganizationID: string;
}> = (props) => {
  const intl = useIntl();
  const { filters, setFilters, currentOrganizationID } = props;
  const { loading: commerceFilterLoader, data } = useQuery<
    GetCommerceFilterInput,
    GetCommerceFilterInputVariables
  >(Queries.GET_COMMERCE_FILTER_INPUT, {
    variables: {
      organizationId: currentOrganizationID,
      provider: SearchProvider.commerceTools,
    },
  });

  const onChangePrice = (evt: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [evt.target.name]: +evt.target.value });
  };

  if (!commerceFilterLoader && data?.commerceFilterInput) {
    const { categories, brandInfo, retailers, tags } =
      data?.commerceFilterInput;
    return (
      <CommerceFilterContainer>
        <li>
          <StyledSelect
            id="retailer"
            value={filters?.retailer}
            onChange={(value: string | undefined) => {
              if (value) {
                setFilters({ ...filters, retailer: value });
              }
            }}
            options={
              retailers?.map(({ name, key }) => {
                return {
                  label: name,
                  value: key,
                };
              }) || []
            }
            multiple={false}
            placeholder={intl.formatMessage({
              defaultMessage: "Select Retailer",
              description: "Placeholder text for  Select a Retailer",
            })}
          />
        </li>
        <li>
          <StyledSelect
            id="brand"
            value={filters?.brand}
            onChange={(value: string | undefined) => {
              if (value) {
                setFilters({ ...filters, brand: value });
              }
            }}
            options={
              brandInfo?.map(({ label, key }) => {
                return {
                  label: label,
                  value: key,
                };
              }) || []
            }
            multiple={false}
            placeholder={intl.formatMessage({
              defaultMessage: "Select Brand",
              description: "Placeholder text for  Select a Brand",
            })}
          />
        </li>
        <li>
          <StyledSelect
            id="category"
            value={filters?.category}
            onChange={(value: string | undefined) => {
              if (value) {
                setFilters({ ...filters, category: value });
              }
            }}
            options={
              categories?.map(({ label, key }) => {
                return {
                  label: label,
                  value: key || "",
                  disabled: undefined,
                };
              }) || []
            }
            multiple={false}
            placeholder={intl.formatMessage({
              defaultMessage: "Select Category",
              description: "Placeholder text for  Select a Category",
            })}
          />
        </li>
        <li>
          <StyledSelect
            id="tag"
            value={filters?.tag}
            onChange={(value: string | undefined) => {
              if (value) {
                setFilters({ ...filters, tag: value });
              }
            }}
            options={
              tags?.map(({ id, name }) => {
                return {
                  label: name,
                  value: id || "",
                  disabled: undefined,
                };
              }) || []
            }
            multiple={false}
            placeholder={intl.formatMessage({
              defaultMessage: "Select Tag",
              description: "Placeholder text for  Select a Tag",
            })}
          />
        </li>
        <li>
          <div className="form-control">
            <StyledInput
              id="min-price"
              type="number"
              name="minPrice"
              value={filters?.minPrice}
              onChange={onChangePrice}
              placeholder={intl.formatMessage({
                defaultMessage: "Enter Min Price",
                description: "Placeholder number for  Minimum price filter",
              })}
            />
          </div>
        </li>
        <li>
          <div className="form-control">
            <StyledInput
              id="max-price"
              type="number"
              name="maxPrice"
              value={filters?.maxPrice}
              onChange={onChangePrice}
              placeholder={intl.formatMessage({
                defaultMessage: "Enter Max Price",
                description: "Placeholder number for  Maximum price filter",
              })}
            />
          </div>
        </li>
      </CommerceFilterContainer>
    );
  } else {
    return (
      <FilterContainerLoader>
        <Spinner size="large" />
      </FilterContainerLoader>
    );
  }
};

export const CommerceProductAdapterFactory: SearchAdapterFactory = ({
  search,
  organizationId,
}) => {
  return {
    id: "contentreference",
    type: "external",
    onSearch: (filters?: AssetSelectorSearchOptions) => {
      const commerceFilter = pick(filters, FILTER_KEYS);
      search(SearchProvider.commerceTools, {
        ...commerceFilter,
        types: ["contentreference"],
      });
    },
    Label: () => <CommerceProductLabel />,
    Filters: ({ filters, setFilters }) => (
      <CommerceProductFilters
        filters={filters}
        setFilters={setFilters}
        currentOrganizationID={organizationId}
      />
    ),
  };
};

import { useMemo, memo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SearchIcon } from "@condenast/gemini/icons";
import { ThemeProvider } from "@contexts";
import { Button, FloatingActionBar, withTooltip } from "@components";
import { useFieldToggles } from "@hooks";
import SearchControlsTakeover from "./SearchControlsTakeover";
import SearchPreview from "./SearchPreview";
import { BundleContainerProps } from "../../../../types";
import {
  ContainerBody,
  EmptyContainerMessage,
  ContainerFields,
  ContainerFooter,
  ContainerCard,
  ContainerHeader,
} from "../../../";
import styled from "styled-components";

const BetaMessageSpan = styled.span`
  color: ${(props) => props.theme.SecondaryColor};
`;

const SearchPreviewLabel = withTooltip(styled.span`
  font: var(--font-label);
  display: inline-flex;
  gap: var(--spacing-xs);
  max-width: fit-content;
  &[aria-label] {
    white-space: pre-wrap;
  }
`);

const SearchContainer = memo<BundleContainerProps>((props) => {
  let {
    containerData,
    updateContainerByIndex,
    containerIndex,
    configs,
    currentOrganization,
    currentUser,
    permissions,
    errors,
    containerRefs,
    activeContainerKey,
    setActiveContainerKey,
    deleteContainer,
    duplicateContainer,
    moveContainer,
    totalContainerCount,
    isCollapsed,
    toggleContainerCollapsed,
  } = props;
  const intl = useIntl();
  const [searchControlsTakeoverOpen, setSearchControlsTakeoverOpen] =
    useState(false);
  const { toggleField, resetToInitial, fieldStates, hasChanges } =
    useFieldToggles({
      hed: !!containerData.hed,
      dek: !!containerData.dek,
      containerAsset: !!containerData.containerAsset?.edges?.length,
    });

  const configuredSearch = useMemo(
    () => containerData.searches?.[0],
    [containerData]
  );

  const cdnHost = useMemo(
    () => `https://${currentOrganization.metadata.mediaDomain}`,
    [currentOrganization]
  );

  return (
    <>
      {searchControlsTakeoverOpen && (
        <SearchControlsTakeover
          containerData={containerData}
          updateContainerByIndex={updateContainerByIndex}
          containerIndex={containerIndex}
          configs={configs}
          currentOrganization={currentOrganization}
          currentUser={currentUser}
          permissions={permissions}
          errors={errors}
          onClose={() => {
            setSearchControlsTakeoverOpen(false);
          }}
        />
      )}
      <ContainerCard
        key={containerData.containerKey}
        ref={(el: HTMLDivElement) => {
          if (el) {
            containerRefs.current[containerData.containerKey] = el;
          }
        }}
        containerKey={containerData.containerKey}
        activeContainerKey={activeContainerKey}
        setActiveContainerKey={setActiveContainerKey}
      >
        <ContainerHeader
          configs={configs}
          containerData={containerData}
          containerIndex={containerIndex}
          updateContainerByIndex={updateContainerByIndex}
          deleteContainer={deleteContainer}
          duplicateContainer={duplicateContainer}
          toggleField={toggleField}
          resetToInitial={resetToInitial}
          fieldStates={fieldStates}
          hasChanges={hasChanges}
          isCollapsed={isCollapsed}
        />
        <ContainerBody $isCollapsed={isCollapsed}>
          <ContainerFields
            containerData={containerData}
            updateContainerByIndex={updateContainerByIndex}
            containerIndex={containerIndex}
            configs={configs}
            currentOrganization={currentOrganization}
            currentUser={currentUser}
            permissions={permissions}
            errors={errors}
            fieldStates={fieldStates}
          />
          <SearchPreviewLabel
            aria-label={intl.formatMessage({
              defaultMessage: "Beta Feature",
              description: "Search preview beta label",
            })}
            aria-details={intl.formatMessage({
              defaultMessage:
                "Search results preview may occasionally differ from the content shown on the live page",
              description: "Search preview beta details",
            })}
          >
            <FormattedMessage defaultMessage="Search Results Preview." />
            <BetaMessageSpan>
              <FormattedMessage defaultMessage="BETA" />
            </BetaMessageSpan>
          </SearchPreviewLabel>
          {configuredSearch ? (
            <SearchPreview
              search={configuredSearch}
              organizationId={currentOrganization.organizationId}
              cdnHost={cdnHost}
            />
          ) : (
            <EmptyContainerMessage>
              {intl.formatMessage({
                defaultMessage: "No Search Query added",
                description: "Message when search query defined",
              })}
            </EmptyContainerMessage>
          )}
          <ThemeProvider theme="dark">
            <FloatingActionBar>
              <Button
                aria-label={intl.formatMessage({
                  defaultMessage: "Configure search query",
                  description: "Button used Configure search query",
                })}
                onClick={() => {
                  setSearchControlsTakeoverOpen(true);
                }}
              >
                <SearchIcon size="regular" />
                <FormattedMessage defaultMessage="Search query" />
              </Button>
            </FloatingActionBar>
          </ThemeProvider>
        </ContainerBody>
        <ContainerFooter
          moveContainer={moveContainer}
          containerIndex={containerIndex}
          totalContainerCount={totalContainerCount}
          isCollapsed={isCollapsed}
          toggleContainerCollapsed={toggleContainerCollapsed}
          containerKey={containerData.containerKey}
        />
      </ContainerCard>
    </>
  );
});

SearchContainer.displayName = "SearchContainer";
export default SearchContainer;

import { useDefinedMessages, useUniqueId } from "@hooks";
import {
  AnonymousControlProps,
  ContentConnectionFields,
  ContentConnectionFields_edges_node_asset,
} from "@types";
import { useMemo } from "react";
import styled from "styled-components";
import { Image } from "@components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-xs);
  background: ${(props) => props.theme.SkeletonColor};
  padding: var(--spacing-sm);
  text-transform: uppercase;
  font: ${(props) => props.theme.FontSmallStatement};
`;

const AdvertiserContent = styled.div`
  margin-top: var(--spacing-xs);
`;

export function BylinePreview({
  model,
  currentOrganization,
}: AnonymousControlProps) {
  const uniqueId = useUniqueId();
  const brandName = currentOrganization.internalDisplayName;
  const cdnHost = currentOrganization.metadata.mediaDomain;

  const advertiserModelValue = useMemo(
    () => (model.advertiser as ContentConnectionFields)?.edges?.[0]?.node,
    [model.advertiser]
  );

  const advertiserLogo =
    advertiserModelValue?.asset as ContentConnectionFields_edges_node_asset;
  const advertiserName = advertiserModelValue?.title?.content;
  const { translateBylinePreview } = useDefinedMessages();

  return (
    <Wrapper id={`CategorySelector-${uniqueId}`}>
      <span>
        {translateBylinePreview(
          model.bylineOption as string,
          brandName,
          advertiserName as string
        )}
      </span>
      <AdvertiserContent>
        {model.bylineVariant == "text" ? (
          <span>{advertiserName}</span>
        ) : (
          advertiserLogo && (
            <Image
              cdnHost={`https://${cdnHost}`}
              asset={advertiserLogo}
              modifications={{ width: 50, height: 50 }}
            />
          )
        )}
      </AdvertiserContent>
    </Wrapper>
  );
}
BylinePreview.displayName = "Control(BylinePreview)";

import { useCallback, useMemo } from "react";
import { Button } from "@components";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  CloseIcon,
  AnimatedEllipsisIcon,
  BinIcon,
  EditIcon,
} from "@condenast/gemini/icons";

const StyledSubmitBar = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  position: fixed;
  right: var(--spacing-sm);
  bottom: var(--spacing-sm);
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  align-items: center;
  padding: var(--spacing-xs);
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.MenuShadow};
`;

const ClearButton = styled(Button)`
  color: ${(props) => props.theme.Color};
`;

const ActionButton = styled(Button)`
  background: ${(props) => props.theme.SkeletonColor};
`;

const Divider = styled.div`
  height: 40px;
  border-right: 1px solid ${(props) => props.theme.DividerColor};
`;

type SubmitBarProps = {
  onSubmit?: (action?: "delete" | "editCredit") => void;
  submitting?: boolean;
  clearSelections: () => void;
  selectionCount: number;
  limit?: number;
  action: string | string[];
};

export const SubmitBar = (props: SubmitBarProps) => {
  const intl = useIntl();
  const {
    selectionCount,
    clearSelections,
    onSubmit,
    submitting,
    action,
    limit,
  } = props;

  const submit = useCallback(
    (action?: "delete" | "editCredit") => {
      onSubmit?.(action);
    },
    [onSubmit]
  );

  const selectionLimitExceeded = useMemo(() => {
    if (!limit) return false;
    return selectionCount > limit;
  }, [selectionCount, limit]);

  let hasDelete = action === "delete" || action.includes("delete");
  let hasEdit = action === "editCredit" || action.includes("editCredit");
  let hasSubmit = action === "submit" || action.includes("submit");

  return (
    <StyledSubmitBar>
      {hasDelete ? (
        <ActionButton
          onClick={() => {
            submit("delete");
          }}
          treatment="primary"
        >
          <BinIcon size="regular" />
          <FormattedMessage defaultMessage="Delete" />
        </ActionButton>
      ) : (
        <></>
      )}
      {hasEdit ? (
        <ActionButton
          onClick={() => {
            submit("editCredit");
          }}
          treatment="primary"
        >
          <EditIcon size="regular" />
          <FormattedMessage defaultMessage="Edit Credit" />
        </ActionButton>
      ) : (
        <></>
      )}
      {hasDelete || hasEdit ? <Divider /> : <></>}
      <ClearButton onClick={clearSelections} treatment="borderless">
        <CloseIcon size="regular" />
        {props.limit == null ? (
          <FormattedMessage
            defaultMessage={
              "{count, plural, =0 {No items selected} one {{count} item selected} other {{count} items selected} }"
            }
            values={{
              count: selectionCount,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage={"{count}/{limit} items selected"}
            values={{
              count: selectionCount,
              limit: props.limit,
            }}
          />
        )}
      </ClearButton>
      {submitting ? (
        <Button
          treatment="primary"
          aria-label={intl.formatMessage({
            defaultMessage: "Loading",
            description: "Accessibility label for spinners",
          })}
        >
          <AnimatedEllipsisIcon size="small" />
        </Button>
      ) : hasSubmit ? (
        <Button
          onClick={() => submit()}
          treatment="primary"
          disabled={selectionLimitExceeded}
        >
          <FormattedMessage defaultMessage="Submit" />
        </Button>
      ) : (
        <></>
      )}
    </StyledSubmitBar>
  );
};

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import {
  // arraySome,
  // hasCanonicalUrl,
  // inlineAllPresent,
  // isTrue,
  // required,
  // metadata,
  // optional,
  // url,
  // length,
  markdownLinks,
  required,
} from "@condenast/copilot-validators";

import { noEmptyAccordionHeaders } from "../validators/markdown-accordions";
import type { ValidationContext, ValidationEnvironment } from "@lib";
import type { FormFor_content_InfoPage } from "@types";
import { publishURI } from "../validators";

const { object } = validators;

const infopageValidators = object({
  hed: required(),
  body: object({
    content: required()
      .andAlso(markdownLinks())
      .andAlso(noEmptyAccordionHeaders()),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
})
  .andAlso(publishURI().on("publish"))
  .catch((infopageErrors) => {
    let categoryTaxonomyErrors = infopageErrors.filter((error) =>
      error.path.find((pathItem) => pathItem === "categoryTaxonomies")
    );
    if (categoryTaxonomyErrors) {
      categoryTaxonomyErrors.forEach(
        (categoryTaxonomyError) =>
          (categoryTaxonomyError.path = [
            categoryTaxonomyError.path
              .join("")
              .replace("categoryTaxonomies", ""),
          ])
      );
    }

    return infopageErrors;
  });

export default function validateInfoPage(
  infopage: FormFor_content_InfoPage,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(infopage, build(infopageValidators), context, env);
}

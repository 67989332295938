import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required, length } from "@condenast/copilot-validators";
import { FormFor_content_Recipe } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";
import { publishURI } from "../validators";
import { noEmptyAccordionHeaders } from "../validators/markdown-accordions";

const { object } = validators;

const recipeValidators = object({
  hed: required(),
  body: object({
    content: noEmptyAccordionHeaders(),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  ingredientGroups: length({ min: 1 }).on("publish"),
  preparationGroups: length({ min: 1 }).on("publish"),
}).andAlso(publishURI().on("publish"));

export default function validateRecipe(
  recipe: FormFor_content_Recipe,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(recipe, build(recipeValidators), context, env);
}

import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  CategorySelector as CategorySelectorImplementation,
  Field,
} from "@components";
import { useDefinedMessages, useDebouncedCallback, useUniqueId } from "@hooks";
import {
  Category,
  Taxonomy,
  ControlProps,
  GetRootCategory,
  GetRootCategoryVariables,
  FormFor_form_controls_CategorySelectorFormControl,
  GetCategoryChildren,
  GetCategoryChildrenVariables,
  CategoryData,
  FormFor_content_CuratedSearch_categoryTaxonomies as TCategoryTaxonomy,
} from "@types";
import { Queries } from "@gql";
import { useQuery, useApolloClient, useLazyQuery } from "@apollo/client";
import styled from "styled-components";
import { capitalize } from "@lib";

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-xxs) 0;
  grid-template-rows: auto auto;
  grid-template-areas:
    "label"
    "control";
`;

const SEARCH_DEBOUNCE_INTERVAL = 250; // milliseconds

function normalizeCategoryData(
  categories: CategoryData[],
  isSelectable?: boolean
): Category[] {
  const transformedCategories: Category[] = categories.map((category) => ({
    id: category.id,
    name: category.name ?? "",
    slug: category.slug,
    hierarchy: category.hierarchy
      .map((h) => ({ name: h.name ?? "", slug: h.slug }))
      .reverse(),
    selectable: isSelectable,
    childCount: category.meta?.childCount ?? undefined,
  }));

  return transformedCategories;
}

function serializeCategories(categories: Category[]): CategoryData[] {
  return categories.map(({ id, name, slug, hierarchy }) => ({
    id,
    name,
    slug,
    hierarchy: [...hierarchy].reverse(),
    root: hierarchy[0],
  }));
}

function isNotHierarchies(
  category: CategoryData,
  notHierarchies: string[] | null
) {
  const hierarchyPath = category.hierarchy
    .map((hierarchyCategory) => {
      return hierarchyCategory.slug;
    })
    .reverse()
    .join("/");

  return !notHierarchies?.some((notHierarchyPath) =>
    hierarchyPath.startsWith(notHierarchyPath)
  );
}

function isTaxonomyManagedByControl(
  slug: string,
  rootSlug: string | null,
  notHierarchies: string[] | null
) {
  if (rootSlug) {
    return slug === rootSlug;
  }
  if (notHierarchies) {
    return !notHierarchies.includes(slug);
  }
  return true;
}

function errorsForCategoryField<T extends { path: readonly string[] }>(
  errors: T[],
  rootSlugs: string[]
): T[] {
  return errors.filter(({ path }) => {
    return rootSlugs.some((slug) =>
      path.includes(`categories${capitalize(slug)}`)
    );
  });
}

export function CategorySelector(
  props: ControlProps<FormFor_form_controls_CategorySelectorFormControl>
) {
  const {
    currentOrganization,
    rootSlug,
    notHierarchies,
    model,
    errors,
    name,
    labelKey,
    setValue,
  } = props;
  const { data: rootData } = useQuery<
    GetRootCategory,
    GetRootCategoryVariables
  >(Queries.GET_ROOT_CATEGORY, {
    variables: {
      organizationId: currentOrganization.organizationId,
      slug: rootSlug,
      notHierarchies: notHierarchies,
    },
  });
  const roots = rootData
    ? normalizeCategoryData(rootData.rootCategory, false)
    : [];

  const fieldErrors = errorsForCategoryField(
    errors,
    roots.map((root) => root.slug)
  );

  const Client = useApolloClient();
  const uniqueId = useUniqueId();
  const intl = useIntl();
  const rootName = rootSlug && roots[0]?.name;
  const placeholder = rootName
    ? intl.formatMessage(
        {
          defaultMessage: "Select {rootName}",
          description:
            "Placeholder text for a category selector on a single root",
        },
        {
          rootName,
        }
      )
    : intl.formatMessage({
        defaultMessage: "Select Category",
        description:
          "Placeholder text for a category selector on multiple roots",
      });

  const { translateFieldName } = useDefinedMessages();

  const modelValue = useMemo(() => {
    return (model[
      ["CuratedSearch", "Article", "BundleSearch"].includes(
        model.__typename as string
      )
        ? name
        : "categoryTaxonomies"
    ] ?? []) as TCategoryTaxonomy[];
  }, [name, model]);

  const selections = modelValue
    .filter(
      ({ taxonomy, categories }) =>
        isTaxonomyManagedByControl(taxonomy, rootSlug, notHierarchies) &&
        categories.length
    )
    .map(({ categories }) => {
      const { name, slug } = categories[0].root;
      const filteredCategories = categories.filter((category: CategoryData) =>
        isNotHierarchies(category, notHierarchies)
      ) as CategoryData[];
      return {
        name,
        slug,
        categories: normalizeCategoryData(filteredCategories),
      };
    })
    .filter(({ categories }) => categories.length > 0) as Taxonomy[];

  const [_lazySearch, { data: searchData, loading, error }] = useLazyQuery(
    Queries.CATEGORY_SEARCH
  );

  const preformSearch = useDebouncedCallback((query) => {
    if (!query) return;

    _lazySearch({
      variables: {
        organizationId: currentOrganization.organizationId,
        filters: {
          q: query || "",
          hierarchy: rootSlug,
          notcategory: notHierarchies,
        },
      },
    });
  }, SEARCH_DEBOUNCE_INTERVAL);

  const normalizedSearch: Category[] = searchData
    ? normalizeCategoryData(searchData.categorySearch, true)
    : [];

  const searchError = error ? [error] : undefined;

  const onChange = useCallback(
    (selections: Taxonomy[]) => {
      const updatedTaxonomies = selections.map(({ slug, categories }) => {
        const existingTaxonomy = modelValue.find(
          ({ taxonomy }) => taxonomy === slug
        );
        const existingCategoriesNotManagedByControl: CategoryData[] =
          existingTaxonomy?.categories?.filter(
            (category) => !isNotHierarchies(category, notHierarchies)
          ) ?? [];
        return {
          taxonomy: slug,
          categories: [
            ...serializeCategories(categories),
            ...existingCategoriesNotManagedByControl,
          ],
        };
      });

      setValue(
        ["CuratedSearch", "Article", "BundleSearch"].includes(
          model.__typename as string
        )
          ? name
          : "categoryTaxonomies",
        [
          ...modelValue.filter(
            ({ taxonomy }) =>
              !isTaxonomyManagedByControl(taxonomy, rootSlug, notHierarchies)
          ),
          ...updatedTaxonomies,
        ]
      );
    },
    [name, modelValue, setValue, rootSlug, notHierarchies, model.__typename]
  );

  return (
    <Wrapper
      id={`CategorySelector-${uniqueId}`}
      label={translateFieldName(labelKey ?? name)}
      errors={fieldErrors}
    >
      <CategorySelectorImplementation
        aria-invalid={!!fieldErrors.length}
        placeholder={placeholder}
        selections={selections}
        search={preformSearch}
        searchResult={{
          loading,
          errors: searchError,
          data: normalizedSearch,
        }}
        onChange={onChange}
        roots={roots}
        getChildren={(category) =>
          Client.query<GetCategoryChildren, GetCategoryChildrenVariables>({
            query: Queries.GET_CATEGORY_WITH_CHILDREN,
            variables: {
              organizationId: currentOrganization.organizationId,
              id: category.id,
              limit: category.childCount ?? 100,
            },
          }).then((result) => {
            const children = result.data.category.content
              ? result.data.category.content.results
              : [];
            const filteredChildren = children.filter((child) => {
              if (child.slug === "ContentHeader") {
                return !child.slug.includes("ContentHeader");
              }
              return child;
            });
            return normalizeCategoryData(filteredChildren, true);
          })
        }
      />
    </Wrapper>
  );
}
CategorySelector.displayName = "Control(CategorySelector)";

import { Select } from "@components";
import styled from "styled-components";
import { ThemeProvider } from "@contexts";
import { ContentTypePhotoIcon } from "@condenast/gemini/icons";
import { useDefinedMessages, useUniqueId } from "../../../hooks";
import { LabelToScreenSize } from "./AspectRatioSelect.util";

const Wrapper = styled.div`
  width: 14rem;
  height: 3.5rem;
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  border-radius: ${(props) => props.theme.CornerRadius};
  padding: var(--spacing-xs);
`;

const SelectWrapper = styled(Select)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & button {
    padding: var(--spacing-xs);
  }
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: var(--spacing-xs);
  margin-right: var(--spacing-md);
`;

const LabelText = styled.div`
  font: ${(props) => props.theme.FontBody};
  font-weight: 700;
  padding-left: var(--spacing-xs);
`;

type AspectRatioSelectProps = {
  options: Array<{ label: string; value: string }>;
  label: string;
  value: string | undefined;
  onChange: (
    breakpoint: string | undefined,
    aspectRatio: string | undefined
  ) => void;
};

export const AspectRatioSelect = ({
  options,
  label,
  value,
  onChange,
}: AspectRatioSelectProps) => {
  const id = useUniqueId();
  const { translatePageLayout } = useDefinedMessages();

  const selectLabel = (
    <Label>
      <ContentTypePhotoIcon size="regular" />
      <LabelText>{translatePageLayout(label)}</LabelText>
    </Label>
  );

  return (
    <ThemeProvider theme="dark">
      <Wrapper>
        <SelectWrapper
          label={selectLabel}
          id={`AspectRatioSelect-${id}`}
          aria-label={translatePageLayout("aspectRatioAriaLabel")}
          value={value}
          onChange={(value: string | undefined) =>
            onChange(
              LabelToScreenSize[label as keyof typeof LabelToScreenSize],
              value
            )
          }
          placeholder="Select"
          disabled={!options.length}
          options={options}
        />
      </Wrapper>
    </ThemeProvider>
  );
};

AspectRatioSelect.displayName = "AspectRatioSelect";

import { FC, useContext, useState } from "react";
import styled from "styled-components";
import { SnowplowContext } from "@contexts";
import { useClipboardCopy, useToast } from "@hooks";
import { Page } from "@components";
import { Header } from "../Header";
import { useIntl, FormattedMessage } from "react-intl";
import { ReloadIcon } from "@condenast/gemini/icons";
import { Button } from "@components";
import { PreviewContext } from "./PreviewPage.context";

const PreviewPageSkeleton = styled(Page)`
  height: calc(100vh - env(safe-area-inset-bottom));
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-sm);

  .error {
    font: ${(props) => props.theme.FontSmallerHeading};
    margin-bottom: var(--spacing-md);
  }
`;

const StyledPreviewPage = styled(Page)`
  height: calc(100vh - env(safe-area-inset-bottom));
  display: flex;
  flex-direction: column;
  background-color: var(--color-black);
  padding-bottom: 0;
`;

const StyledPreviewContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

export function PreviewError() {
  const intl = useIntl();
  return (
    <PreviewPageSkeleton
      title={intl.formatMessage({
        defaultMessage: "Preview",
      })}
    >
      <div className="error" role="alert">
        <FormattedMessage defaultMessage="We could not load this preview page." />
      </div>
      <Button treatment="primary" onClick={() => location.reload()}>
        <ReloadIcon size="regular" />
        <FormattedMessage defaultMessage="Reload Page" />
      </Button>
    </PreviewPageSkeleton>
  );
}

export const PreviewPage: FC<{
  previewURL: string;
  defaultViewMode?: "mobile" | "desktop" | "fullscreen";
  isError?: Error | null;
  children?: React.ReactNode;
  onRefresh?: () => void;
  contentType?: string;
  contentId?: string;
}> = ({
  children,
  previewURL,
  defaultViewMode,
  isError,
  onRefresh,
  contentType,
  contentId,
}) => {
  const intl = useIntl();
  const copyPreviewURLToClipboard = useClipboardCopy(previewURL);
  const { trackContentEvent } = useContext(SnowplowContext);

  const [viewMode, setViewMode] = useState(defaultViewMode ?? "mobile");

  const showOnCopyMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Share link copied to clipboard",
    }),
  });

  const copyPreviewURL = () => {
    copyPreviewURLToClipboard();
    trackContentEvent("previewed", contentId, contentType);
    showOnCopyMessage();
  };

  if (isError) {
    return <PreviewError />;
  }

  return (
    <PreviewContext.Provider value={[viewMode, setViewMode]}>
      <StyledPreviewPage
        title={intl.formatMessage({
          defaultMessage: "Preview",
        })}
      >
        <Header onCopy={copyPreviewURL} onRefresh={onRefresh} />
        <StyledPreviewContainer>{children}</StyledPreviewContainer>
      </StyledPreviewPage>
    </PreviewContext.Provider>
  );
};

import { ContentTypeIcon } from "@condenast/gemini/icons";
import type { SearchAdapterFactory } from "../types";
import { SearchProvider, AssetSelectorSearchOptions } from "@types";
import { useIntl } from "react-intl";
import { ThemeProvider } from "@contexts";
import { pick } from "lodash";
import { ContentFilters } from "./contentFilters";

const FILTER_KEYS = [
  "sort",
  "status",
  "types",
  "date",
  "curationSubType",
  "query",
  "categories",
];

const MyWorkLabel = () => {
  const intl = useIntl();
  return (
    <>
      <ContentTypeIcon contentType="person" size="regular" />
      <span>
        {intl.formatMessage({
          defaultMessage: "My Work",
          description: "Search adapter name for My Work",
        })}
      </span>
    </>
  );
};

export const MyWorkAdapterFactory: SearchAdapterFactory = (
  context,
  adapterName
) => {
  const {
    search,
    dateRangeOptions,
    statusOptions,
    contentTypes,
    organizationId,
    currentUser,
    categoryConfigType,
  } = context;
  return {
    id: adapterName,
    type: "mywork",
    onSearch: (filters: AssetSelectorSearchOptions) => {
      const filtersForAdapter = pick(filters, FILTER_KEYS);
      filtersForAdapter.collaborators = [context.currentUser];
      filtersForAdapter.types = filtersForAdapter?.types?.length
        ? filtersForAdapter.types
        : contentTypes.map((contentTypeConfig) => contentTypeConfig.value);
      search(SearchProvider.mywork, filtersForAdapter);
    },
    Label: () => <MyWorkLabel />,
    Filters: ({ filters, setFilters }) => (
      <ThemeProvider theme="light">
        <ContentFilters
          filters={filters}
          setFilters={setFilters}
          dateRangeOptions={dateRangeOptions}
          statusOptions={statusOptions}
          contentTypes={contentTypes}
          organizationId={organizationId}
          currentUser={currentUser}
          hidePeopleFilter={true}
          categoryConfigType={categoryConfigType}
        />
      </ThemeProvider>
    ),
  };
};

import { makeVar } from "@apollo/client";
import { Toast } from "@components";
import { ComponentProps } from "react";

export const toastPropsVar = makeVar<ComponentProps<typeof Toast> | undefined>(
  undefined
);
export const toastTimerVar = makeVar<number | undefined>(undefined);

export const lastAssetSelectorAdapter = makeVar<string | undefined>(undefined);

export const isAuthenticatedVar = makeVar(true);

export const openTakeoverIDVar = makeVar<string | null>(null);

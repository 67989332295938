import { useIntl } from "react-intl";
import { Page, Search } from "@components";
import { useApolloClient, gql } from "@apollo/client";
import {
  GetBrand,
  GetBrandVariables,
  GetConfigs,
  GetCurrentUser_currentUser,
  Organization,
  Duplicate,
  DuplicateVariables,
  Search_search_results,
  GetUsers,
  GetUsersVariables,
  Search as SearchQueryType,
  SearchVariables,
  GetCategoriesVariables,
  GetCategories,
  Archive,
  BulkArchive,
  ArchiveVariables,
  BulkArchiveVariables,
  Unarchive,
  UnarchiveVariables,
  BulkUnarchive,
  BulkUnarchiveVariables,
} from "@types";
import { SearchPageURLParams, AdvancedSearchOptions } from "./types";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { Queries, Mutations } from "@gql";
import { useQueryParams, useToast } from "@hooks";
import { transformDates } from "./helpers";
import {
  useCallback,
  useEffect,
  useMemo,
  useContext,
  useRef,
  useState,
} from "react";
import { createMainSearchAdapter } from "./adapters";
import { CategoryFields } from "../../../../gql/fragments";
import { SnowplowContext } from "@contexts";

const PAGE_LIMIT = 50;

const SortOptions = [
  "relevanceLatest",
  "relevanceAllTime",
  "lastModified",
  "lastPublished",
  "lastCreated",
];

const DefaultQueryParams = {
  page: "1",
  sort: "relevanceLatest",
  adapter: "all",
};

export const SearchPage = (props: {
  user: GetCurrentUser_currentUser;
  currentOrganization: Organization;
}) => {
  const intl = useIntl();

  const { trackSearchEvent } = useContext(SnowplowContext);

  const { data: toastData } = useQuery(Queries.GET_TOAST_PROPS);
  const isToastVisible = !!toastData?.toast.props;

  const { user, currentOrganization } = props;

  const organizationId = props.currentOrganization.organizationId;

  const configsResponse = useQuery<GetConfigs>(Queries.GET_CONFIGS, {
    variables: {
      userId: user?.id,
      organizationId,
    },
  });
  const { data: brand } = useQuery<GetBrand, GetBrandVariables>(
    Queries.GET_BRAND,
    {
      variables: {
        organizationId,
      },
    }
  );

  const dateRangeOptions = useMemo(() => {
    return (
      configsResponse.data?.configs?.searchFilterOptions?.dateRangeOptions ?? []
    );
  }, [configsResponse]);

  const statusOptions = useMemo(() => {
    return (
      configsResponse.data?.configs?.searchFilterOptions.statusOptions ?? []
    );
  }, [configsResponse]);

  /** Types that should not be returned on search */
  const excludeSearchFilters = useMemo(() => {
    return configsResponse.data?.configs?.excludeSearchFilters;
  }, [configsResponse]);

  /** feature flag for alternate languages */
  const languageFiltersEnabled = useMemo(() => {
    return !!configsResponse.data?.configs?.alternateLanguage;
  }, [configsResponse]);

  /** feature flag for syndicated content */
  const syndicationEnabled = useMemo(() => {
    return !!configsResponse.data?.configs?.enableSyndication;
  }, [configsResponse]);

  const categoryConfigType = useMemo(() => {
    return configsResponse.data?.configs?.search?.categories;
  }, [configsResponse]);

  const contentTypes = useMemo(() => {
    return (
      brand?.brandConfiguration?.contentTypes?.filter(
        (item) => !excludeSearchFilters?.includes(item.value)
      ) ?? []
    );
  }, [brand, excludeSearchFilters]);

  const availableLanguages =
    brand?.brandConfiguration?.language?.available ?? [];

  const [urlSearchParams, setUrlSearchParams] =
    useQueryParams<SearchPageURLParams>();
  const searchParams = useMemo(() => {
    return { ...DefaultQueryParams, ...urlSearchParams };
  }, [urlSearchParams]);

  // ============ Start useSearchControls Hook ==============
  const { data: usersSearchData } = useQuery<GetUsers, GetUsersVariables>(
    Queries.GET_USERS,
    {
      variables: {
        organizationId,
      },
      skip: user.role === "contributor",
    }
  );

  const client = useApolloClient();

  const cachedContributors = useMemo(() => {
    if (!searchParams.contributors || searchParams.contributors.length === 0) {
      return null;
    }

    return searchParams.contributors
      .split(",")
      .map((contributorId) => {
        try {
          const cachedContributorFragment = client.readFragment({
            id: `ContentSummary:${contributorId}`,
            fragment: gql`
              fragment ContentSummaryFragment on ContentSummary {
                id
                title {
                  content
                }
              }
            `,
          });

          return cachedContributorFragment;
        } catch {
          // Contributor not in cache, need to hydrate from search
          return null;
        }
      })
      .filter(Boolean); // Remove null values from the array
  }, [searchParams?.contributors, client]);

  const cachedCategories = useMemo(() => {
    if (!searchParams?.categories || searchParams.categories.length === 0) {
      return null;
    }

    return searchParams.categories
      .split(",")
      .map((categoryId) => {
        try {
          const cachedCategoryFragment = client.readFragment({
            id: `Category:${categoryId}`,
            fragment: CategoryFields,
          });
          return cachedCategoryFragment;
        } catch {
          // Contributor not in cache, need to hydrate from search
          return null;
        }
      })
      .filter(Boolean); // Remove null values from the array
  }, [searchParams?.categories, client]);

  const skipSelectedContributorSearch = useMemo(() => {
    return (
      !organizationId ||
      !searchParams.contributors?.length ||
      cachedContributors?.length === searchParams.contributors?.length
    );
  }, [organizationId, searchParams?.contributors, cachedContributors]);

  const { data: selectedContributorsSearchData } = useQuery<
    SearchQueryType,
    SearchVariables
  >(Queries.SEARCH, {
    variables: {
      organizationId,
      filters: {
        types: "contributor",
        display: "all",
        qt: "contributorLookup",
        id: searchParams.contributors,
      },
    },
    skip: skipSelectedContributorSearch,
  });

  const skipSelectedCategoriesSearch = useMemo(() => {
    return (
      !organizationId ||
      !searchParams.categories?.length ||
      cachedCategories?.length === searchParams.categories?.split(",").length
    );
  }, [organizationId, searchParams.categories, cachedCategories]);

  const { data: selectedCategoriesSearchData } = useQuery<
    GetCategories,
    GetCategoriesVariables
  >(Queries.GET_CATEGORIES, {
    variables: {
      organizationId,
      categoryIds: searchParams.categories?.split(","),
    },
    skip: skipSelectedCategoriesSearch,
  });

  const selectedCollaborators = useMemo(() => {
    return usersSearchData?.users?.filter((user) =>
      searchParams.collaborators
        ?.split(",")
        .find((collaboratorId) => collaboratorId === user?.id)
    );
  }, [searchParams.collaborators, usersSearchData?.users]);

  const hydratedSearchOptions = useMemo(() => {
    let params: AdvancedSearchOptions = {
      ...searchParams,
      lang: searchParams.lang?.split(",") || undefined,
      status: searchParams.status?.split(",") || undefined,
      types: searchParams.types?.split(",") || undefined,
      collaborators: undefined,
      contributors: undefined,
      categories: undefined,
    };

    if (searchParams.contributors) {
      params.contributors = skipSelectedContributorSearch
        ? cachedContributors
        : selectedContributorsSearchData?.search?.results;
    }

    if (searchParams.collaborators) {
      if (user.role !== "contributor") {
        params.collaborators = selectedCollaborators;
      }
    }

    if (searchParams.categories) {
      params.categories = skipSelectedCategoriesSearch
        ? cachedCategories
        : selectedCategoriesSearchData?.categories;
    }

    return params;
  }, [
    searchParams,
    skipSelectedContributorSearch,
    cachedContributors,
    selectedContributorsSearchData?.search?.results,
    user.role,
    selectedCollaborators,
    skipSelectedCategoriesSearch,
    cachedCategories,
    selectedCategoriesSearchData?.categories,
  ]);

  const lastSearchAction = useRef({ type: "", subject: "" });

  const onSearchOptionsChange = useCallback(
    (searchOptions: AdvancedSearchOptions) => {
      const {
        lang,
        types,
        status,
        contributors,
        collaborators,
        categories,
        adapter,
        page,
        sort,
        ...otherSearchOptions
      } = searchOptions;
      let newSearchParams = {
        ...otherSearchOptions,
      } as SearchPageURLParams;
      if ("lang" in searchOptions) {
        newSearchParams.lang = lang?.join(",") || undefined;
      }
      if ("status" in searchOptions) {
        newSearchParams.status = status?.join(",") || undefined;
      }
      if ("types" in searchOptions) {
        newSearchParams.types = types?.join(",") || undefined;
      }
      if ("contributors" in searchOptions) {
        newSearchParams.contributors =
          contributors?.map((contributor) => contributor?.id).join(",") ||
          undefined;
      }
      if ("collaborators" in searchOptions) {
        newSearchParams.collaborators =
          collaborators?.map((collaborator) => collaborator?.id).join(",") ||
          undefined;
      }
      if ("categories" in searchOptions) {
        newSearchParams.categories =
          categories?.map((category) => category?.id).join(",") || undefined;
      }

      // unset query params if they match the default values
      if ("adapter" in searchOptions) {
        newSearchParams.adapter =
          adapter === DefaultQueryParams.adapter ? undefined : adapter;
      }
      if ("page" in searchOptions) {
        newSearchParams.page =
          page === DefaultQueryParams.page ? undefined : page;
      }
      if ("sort" in searchOptions) {
        newSearchParams.sort =
          sort === DefaultQueryParams.sort ? undefined : sort;
      }

      // remember which options changed so we can send the correct tracking event
      const isSearch = "query" in newSearchParams;
      const isSort = "sort" in newSearchParams;
      lastSearchAction.current = {
        type: isSearch ? "search" : "selected",
        subject: isSearch ? "search" : isSort ? "sort" : "filter",
      };
      setUrlSearchParams(newSearchParams);
    },
    [setUrlSearchParams, urlSearchParams]
  );
  // ============ End useSearchControls Hook ==============

  const [
    _lazySearch,
    { data: searchResponse, loading: searchLoading, error: searchError },
  ] = useLazyQuery<SearchQueryType, SearchVariables>(Queries.SEARCH, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first", // This was done to not trigger an additional query when updating an item (like archive)
  });

  const search = useCallback(
    (searchParams: SearchPageURLParams) => {
      let dateFilterQueryParams = transformDates(searchParams.date);

      const filters = {
        q: searchParams.query,
        notcategory: "copilot/preset",
        view: "edit",
        d_o: "or",
        nottypes: excludeSearchFilters?.join(","),
        disableDecay: searchParams.sort === "relevanceAllTime" ? true : false,

        sort:
          searchParams.sort === "lastModified"
            ? "revisiondate desc"
            : searchParams.sort === "lastPublished"
            ? "publishdate desc"
            : searchParams.sort === "lastCreated"
            ? "createddate desc"
            : undefined,
        types: searchParams?.types,
        status: searchParams?.status,
        lang: searchParams.lang,
        display: searchParams.display || "all",
        archived: searchParams.display ? undefined : false,
        relid: searchParams?.contributors,
        hierarchyIds: searchParams?.categories,
        privilege_user:
          user.role === "contributor" ? user.id : searchParams?.collaborators,
        ...dateFilterQueryParams,
      };

      _lazySearch({
        variables: {
          organizationId: organizationId,
          offset: (parseInt(searchParams?.page || "1") - 1 || 0) * PAGE_LIMIT,
          limit: PAGE_LIMIT,
          filters,
        },
      });
    },
    [excludeSearchFilters, user.role, user.id, _lazySearch, organizationId]
  );

  useEffect(() => {
    const { query, sort, ...options } = searchParams;
    if (searchError) {
      trackSearchEvent(
        "search",
        "search",
        "main_search_error",
        undefined,
        query,
        Object.entries(options).map(([key, value]) => ({
          name: key,
          label: value ?? "",
        }))
      );
    } else if (searchResponse) {
      trackSearchEvent(
        lastSearchAction.current.type,
        lastSearchAction.current.subject,
        "advanced_search",
        undefined,
        query,
        Object.entries(options).map(([key, value]) => ({
          name: key,
          label: value ?? "",
        })),
        undefined,
        searchResponse?.search?.totalResults,
        sort
      );
    }
    // only fire this when we have a search error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchError, searchResponse]);

  const [
    _lazyUsageInfoSearch,
    {
      data: usageInfoResponse,
      loading: usageInfoLoading,
      error: usageInfoError,
    },
  ] = useLazyQuery<SearchQueryType, SearchVariables>(Queries.SEARCH);

  let onGetUsageInfo = useCallback(
    (id: string) => {
      _lazyUsageInfoSearch({
        variables: {
          organizationId: organizationId,
          limit: PAGE_LIMIT,
          filters: {
            notcategory: "copilot/preset",
            view: "edit",
            nottypes: excludeSearchFilters?.join(","),
            display: "all",
            relid: id,
          },
        },
      });
    },
    [_lazyUsageInfoSearch, excludeSearchFilters, organizationId]
  );

  const showDuplicatingMessage = useToast({
    type: "informational",
    children: intl.formatMessage({
      defaultMessage: "Duplicating...",
      description: "Notification message to show an entity is being duplicated",
    }),
  });

  const showDuplicationErrorMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Error while duplicating",
      description: "Error message to show when error during duplication",
    }),
  });

  const [duplicateMutation] = useMutation<Duplicate, DuplicateVariables>(
    Mutations.DUPLICATE
  );

  const duplicate = useCallback(
    async (itemToDuplicate: Search_search_results) => {
      const { contentType, id, revisionInfo } = itemToDuplicate;
      const duplicationResponse = await duplicateMutation({
        variables: {
          organizationId: organizationId,
          data: {
            contentType,
            id,
            authorName: `${user.firstName} ${user.lastName}`,
            entityMetadata: { user: [user.id] },
            revision: revisionInfo?.version,
          },
        },
      });
      const newId = duplicationResponse.data?.duplicate?.id;
      if (!newId || duplicationResponse.errors?.length) {
        showDuplicationErrorMessage();
      } else {
        showDuplicatingMessage();
      }
      return duplicationResponse;
    },
    [
      duplicateMutation,
      organizationId,
      user.firstName,
      user.lastName,
      user.id,
      showDuplicationErrorMessage,
      showDuplicatingMessage,
    ]
  );

  const [archivedItem, setArchivedItem] = useState<Search_search_results>();

  const showArchiveMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Story hidden from search",
      description: "Notification message to show an entity is being archived",
    }),
    action: {
      linkProps: {
        as: "button",
        onClick: () => {
          if (archivedItem) {
            unarchive(archivedItem);
          }
        },
      },
      message: intl.formatMessage({
        defaultMessage: "Undo",
      }),
    },
  });

  const showUnarchiveMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Story shown on search",
      description: "Notification message to show an entity is being unarchived",
    }),
    action: {
      linkProps: {
        as: "button",
        onClick: () => {
          if (archivedItem) {
            archive(archivedItem);
          }
        },
      },
      message: intl.formatMessage({
        defaultMessage: "Undo",
      }),
    },
  });

  const [archiveMutation] = useMutation<Archive, ArchiveVariables>(
    Mutations.ARCHIVE
  );
  const authorName = `${user.firstName} ${user.lastName}`;

  const archive = useCallback(
    async (itemToArchive: Search_search_results) => {
      const { contentType, id } = itemToArchive;
      const archiveResponse = await archiveMutation({
        variables: {
          organizationId: currentOrganization.organizationId,
          id,
          contentType,
          authorName: authorName,
        },
      });
      showArchiveMessage();
      return archiveResponse;
    },
    [archiveMutation, currentOrganization.organizationId, showArchiveMessage]
  );

  const [unarchiveMutation] = useMutation<Unarchive, UnarchiveVariables>(
    Mutations.UNARCHIVE
  );

  const unarchive = useCallback(
    async (itemToUnarchive: Search_search_results) => {
      const { contentType, id } = itemToUnarchive;
      const unarchiveResponse = await unarchiveMutation({
        variables: {
          organizationId: currentOrganization.organizationId,
          id,
          contentType,
          authorName: authorName,
        },
      });
      showUnarchiveMessage();
      return unarchiveResponse;
    },
    [
      currentOrganization.organizationId,
      showUnarchiveMessage,
      unarchiveMutation,
    ]
  );

  const [bulkArchiveMutation] = useMutation<BulkArchive, BulkArchiveVariables>(
    Mutations.BULK_ARCHIVE
  );

  const bulkArchive = useCallback(
    async (bulkSelections: Search_search_results[]) => {
      const updatedItems = bulkSelections.map(({ id, contentType }) => ({
        id,
        contentType,
      }));

      const archiveResponse = await bulkArchiveMutation({
        variables: {
          organizationId: currentOrganization.organizationId,
          data: updatedItems,
          authorName: authorName,
        },
      });
      showArchiveMessage();
      return archiveResponse;
    },
    [
      bulkArchiveMutation,
      currentOrganization.organizationId,
      showArchiveMessage,
    ]
  );

  const [bulkUnarchiveMutation] = useMutation<
    BulkUnarchive,
    BulkUnarchiveVariables
  >(Mutations.BULK_UNARCHIVE);

  const bulkUnarchive = useCallback(
    async (bulkSelections: Search_search_results[]) => {
      const updatedItems = bulkSelections.map(({ id, contentType }) => ({
        id,
        contentType,
      }));

      const archiveResponse = await bulkUnarchiveMutation({
        variables: {
          organizationId: currentOrganization.organizationId,
          data: updatedItems,
          authorName: authorName,
        },
      });
      showUnarchiveMessage();
      return archiveResponse;
    },
    [
      bulkUnarchiveMutation,
      currentOrganization.organizationId,
      showUnarchiveMessage,
    ]
  );

  const searchAdapters = useMemo(() => {
    const context = {
      search,
      contentTypes,
    };

    const types = ["all", "media", "editorial"];
    return types.map((type) => createMainSearchAdapter(type, context));
  }, [search, contentTypes]);

  const selectedAdapter = useMemo(() => {
    const selectedAdapter = searchAdapters.find(
      (adapter) => adapter.id === searchParams.adapter
    );
    const defaultAdapter =
      searchAdapters.find((adapter) => adapter.isDefault) || searchAdapters[0];
    return selectedAdapter || defaultAdapter;
  }, [searchAdapters, searchParams.adapter]);

  useEffect(() => {
    selectedAdapter?.search(searchParams);
  }, [searchParams, selectedAdapter]);

  const trackResultInView = useCallback(
    (index: number) => {
      const { query, sort, ...options } = searchParams;
      if (searchError) {
        trackSearchEvent(
          "result_in_view",
          "search",
          "main_search_error",
          undefined,
          query,
          Object.entries(options).map(([key, value]) => ({
            name: key,
            label: value ?? "",
          }))
        );
      } else if (searchResponse) {
        trackSearchEvent(
          "result_in_view",
          lastSearchAction.current.subject,
          "advanced_search",
          index,
          query,
          Object.entries(options).map(([key, value]) => ({
            name: key,
            label: value ?? "",
          })),
          undefined,
          searchResponse?.search?.totalResults,
          sort
        );
      }
    },
    [searchError, searchParams, searchResponse, trackSearchEvent]
  );

  const trackNavigation = useCallback(
    (index: number) => {
      const { query, sort, ...options } = searchParams;
      trackSearchEvent(
        "click_result",
        lastSearchAction.current.subject,
        "advanced_search",
        index,
        query,
        Object.entries(options).map(([key, value]) => ({
          name: key,
          label: value ?? "",
        })),
        undefined,
        searchResponse?.search?.totalResults,
        sort
      );
    },
    // only fire this when clicked
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchResponse]
  );

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: "Search",
      })}
    >
      <Search
        trackViewInUse={trackResultInView}
        trackNavigation={(index: number) => trackNavigation(index)}
        currentUser={user}
        cdnHost={`${window.location.protocol}//${currentOrganization.metadata.mediaDomain}`}
        brandCode={currentOrganization.metadata.copilotCode}
        brandURL={`${props.currentOrganization.url}/`}
        organizationId={organizationId}
        contentTypes={contentTypes}
        enableLanguageFilters={languageFiltersEnabled}
        syndicationEnabled={syndicationEnabled}
        availableLanguages={availableLanguages}
        statusOptions={statusOptions}
        dateRangeOptions={dateRangeOptions}
        sortByOptions={SortOptions}
        searchResponse={searchResponse}
        searchLoading={searchLoading}
        searchError={searchError}
        usageInfoResponse={usageInfoResponse}
        usageInfoLoading={usageInfoLoading}
        usageInfoError={usageInfoError}
        onGetUsageInfo={onGetUsageInfo}
        duplicate={duplicate}
        adapters={searchAdapters}
        selectedAdapter={selectedAdapter}
        searchOptions={hydratedSearchOptions}
        onSearchOptionsChange={onSearchOptionsChange}
        pageLimit={PAGE_LIMIT}
        categoryConfigType={categoryConfigType}
        archive={archive}
        unarchive={unarchive}
        bulkArchive={bulkArchive}
        bulkUnarchive={bulkUnarchive}
        setArchivedItem={setArchivedItem}
        isToastVisible={isToastVisible}
      />
    </Page>
  );
};

SearchPage.displayName = "SearchPage";

import { useCallback, useMemo, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { RemoveCircleIcon, DraggableIcon } from "@condenast/gemini/icons";
import { Takeover, Card, DraggableList, Button } from "@components";
import { FormFor_form_controls_BundleContainersFormControl_configs } from "@types";
import { KeyedContainer } from "../../types";
import { getContainerSummary } from "../../utils";

const OverviewTakeoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

const ContainerOverview = styled(Card)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md) var(--spacing-sm);
  overflow: hidden;
  max-width: unset;
  margin: unset;

  &:hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing};
    cursor: pointer;
  }
`;

const MainFieldSetOverview = styled(ContainerOverview)`
  background: ${(props) => props.theme.SurfaceColor};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
`;

const OverviewItemHeading = styled.div`
  font: var(--font-subhed);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OverviewItemSummary = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: var(--font-small-statements);
  color: var(--color-gray-3);
`;
const StyledDraggableIcon = styled(DraggableIcon)`
  flex-shrink: 0;
`;

const StyledRemoveButton = styled(Button)`
  flex-shrink: 0;
`;

const StyledDraggableList = styled(DraggableList)`
  ${ContainerOverview} {
    margin: var(--spacing-xs) 0;
  }
` as typeof DraggableList;

export function BundleOverviewTakeover(props: {
  containers: KeyedContainer[];
  configs: FormFor_form_controls_BundleContainersFormControl_configs | null;
  onClose: () => void;
  moveContainer: (sourceIndex: number, destinationIndex: number) => void;
  deleteContainer: (containerKey: string) => void;
  setContainerKeyToScrollTo: (key: string | null) => void;
  scrollToMainFieldSet: () => void;
  mainFieldSetSummary: {
    hed: string;
  };
}) {
  const intl = useIntl();
  const {
    configs,
    containers,
    moveContainer,
    deleteContainer,
    setContainerKeyToScrollTo,
    onClose,
    scrollToMainFieldSet,
    mainFieldSetSummary,
  } = props;

  useEffect(() => {
    containers.length === 0 && onClose();
  }, [containers, onClose]);

  const draggableContainers = useMemo(
    () =>
      containers?.map((container: KeyedContainer) => {
        return {
          key: container.containerKey,
          value: container,
        };
      }) ?? [],
    [containers]
  );

  const moveItem = useCallback(
    (
      data: {
        index: number;
      },
      dropIndex: number
    ) => {
      // Adjust the dropIndex if dragging from a lower index to a higher index
      const adjustedDropIndex =
        data.index < dropIndex ? dropIndex - 1 : dropIndex;

      moveContainer(data.index, adjustedDropIndex);
    },
    [moveContainer]
  );

  return (
    <Takeover.TakeoverWrapper
      header={<FormattedMessage defaultMessage="Overview" />}
      body={
        <OverviewTakeoverWrapper>
          <MainFieldSetOverview onClick={scrollToMainFieldSet}>
            <ContentWrapper>
              <OverviewItemHeading>
                {intl.formatMessage({
                  defaultMessage: "Page Details",
                  description: "Label page details section",
                })}
              </OverviewItemHeading>
              <OverviewItemSummary>
                {mainFieldSetSummary.hed}
              </OverviewItemSummary>
            </ContentWrapper>
          </MainFieldSetOverview>
          <StyledDraggableList items={draggableContainers} onMove={moveItem}>
            {(container: KeyedContainer, index: number) => {
              const containerLabel =
                configs?.containers?.find(
                  (config) => config.value === container.curationContainerType
                )?.label || container.curationContainerType;
              return (
                <ContainerOverview
                  key={container.containerKey}
                  onClick={() =>
                    setContainerKeyToScrollTo(container.containerKey)
                  }
                >
                  <StyledDraggableIcon
                    size="regular"
                    className="draggable-icon"
                  />
                  <ContentWrapper>
                    <OverviewItemHeading>
                      {`${index + 1}. ${containerLabel}`}
                    </OverviewItemHeading>
                    <OverviewItemSummary>
                      {getContainerSummary(container)}
                    </OverviewItemSummary>
                  </ContentWrapper>
                  <StyledRemoveButton
                    aria-label={intl.formatMessage({
                      defaultMessage: "Remove",
                      description: "Label for removing container",
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteContainer(container.containerKey);
                    }}
                  >
                    <RemoveCircleIcon size="small" />
                  </StyledRemoveButton>
                </ContainerOverview>
              );
            }}
          </StyledDraggableList>
        </OverviewTakeoverWrapper>
      }
      onClose={onClose}
    />
  );
}

export default BundleOverviewTakeover;

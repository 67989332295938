import type { ContentSerializers } from "../types";
import type { ArticleInput } from "@types";
import {
  categoryTaxonomies,
  contentConnection,
  relMediaOverridesConnection,
  allContributors,
  seo,
} from "../fields";
import {
  serializerFor,
  includeAllMetadataFields,
  entityMetadata,
} from "../utils";

const ArticleFields = {
  allContributors,
  categoryTaxonomies,
  advertiser: contentConnection,
  backReferences: contentConnection,
  photosLede: contentConnection,
  photosTout: contentConnection,
  photosSocial: contentConnection,
  imageSocial: contentConnection,
  linkList: contentConnection,
  linkBanner: contentConnection,
  relMediaOverrides: relMediaOverridesConnection,
  related: contentConnection,
  relatedAudio: contentConnection,
  relatedArtists: contentConnection,
  relatedProducts: contentConnection,
  interludeOverride: contentConnection,
  videosLede: contentConnection,
  seo: seo,
} as const;

export const ArticleSerializers: ContentSerializers<ArticleInput> = {
  save: (changes, model, { currentUser }) => {
    if (!("hed" in model)) {
      return null;
    }
    let metadataFields = includeAllMetadataFields(model, changes);

    return {
      ...serializerFor(ArticleFields)(changes),
      ...metadataFields,
      hed: model.hed,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
    return {
      hed: createTitle,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import type { ValidationEnvironment, ValidationContext } from "@lib";
import type { FormFor_content_Gallery } from "@types";
import { channelsOrSections, publishURI } from "../../../validators";
import { categories } from "../../../validators/categories";

const { object } = validators;

const galleryValidators = object({
  categoryTaxonomies: channelsOrSections()
    .andAlso(
      categories({
        taxonomyName: "story-type",
      })
    )
    .on("publish"),
})
  .andAlso(publishURI().on("publish"))
  .catch((galleryErrors) => {
    let categoryTaxonomyErrors = galleryErrors.filter((error) =>
      error.path.find((pathItem) => pathItem === "categoryTaxonomies")
    );
    if (categoryTaxonomyErrors) {
      categoryTaxonomyErrors.forEach(
        (categoryTaxonomyError) =>
          (categoryTaxonomyError.path = [
            categoryTaxonomyError.path
              .join("")
              .replace("categoryTaxonomies", ""),
          ])
      );
    }

    return galleryErrors;
  });

export default function validateGallery(
  gallery: FormFor_content_Gallery,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(gallery, build(galleryValidators), context, env);
}

import { Field } from "@components";
import { ThemeProvider } from "@contexts";
import styled from "styled-components";
import {
  useCallback,
  useState,
  ComponentProps,
  FC,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import { timeToMilliseconds, millisecondsToHours, prefixZero } from "@lib";

const Wrapper = styled(Field)`
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "input"
    "message";
`;

const DurationInputBox = styled.div`
  grid-area: input;
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => `${props.theme.FieldRing}`};

  display: flex;
  align-items: top;

  background: ${(props) => props.theme.FieldBackground};

  &:hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing};
  }

  &:active {
    box-shadow: ${(props) => props.theme.FieldActiveRing};
  }

  &:focus-within {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;
  }

  .hasError & {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }

  button {
    margin-right: var(--spacing-xxs);
  }
`;

const TextContainer = styled.div`
  position: relative;
  flex: 1;
  margin: var(--spacing-xs) var(--spacing-sm);
  font: ${(props) => props.theme.FontBody};
`;

const EditableText = styled.input`
  outline: 0px solid transparent;
  word-break: break-all;
  width: 100%;
  background: transparent;
  border: 0;
  font: inherit;
  color: inherit;
  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.PlaceholderColor};
  }
`;

const NUM_WIT_COLON_REGEX = /^([:]\d+|\d+([:]\d+)+|\d+([:]\d+)?)$/;
const TWO_DIG_ONLY_REGEX = /^\d{2}$/;
const TWO_DIG_WIT_COLON_REGEX = /^\d{1,2}:\d{2}$/;
const SPLIT_DIG_TWO_REGEX = /(?=(?:..)*$)/;

export const Duration: FC<
  Omit<ComponentProps<typeof Field>, "children"> & {
    id: string;
    duration?: number;
    onChange: (newDuration?: number) => void;
    placeholder?: string;
    maxDuration?: number;
  }
> = (props) => {
  let { onChange, id, duration, placeholder = "", maxDuration } = props;
  let [cursor, setCursorPos] = useState(0);
  let [keyCode, setKeyCode] = useState("Blur");

  const [formatedDuration, setFormatedDuration] = useState(
    duration
      ? millisecondsToHours(duration)
      : duration === 0
      ? millisecondsToHours(0)
      : ""
  );

  const formatToHHMMSS = useCallback((val: Array<string>) => {
    let hr = val[0] && val[0].toString();
    let mm = val[1] && val[1].toString();
    let ss = val[2] && val[2].toString();

    let regex = SPLIT_DIG_TWO_REGEX;
    //1234607 -> 123:46:07
    //1234 ->00:12:34
    //12333 -> 01:23:33
    if (val.length === 1) {
      let splitTime = val[0].split(regex).reverse();
      if (splitTime.length >= 3) {
        ss = splitTime[0];
        mm = splitTime[1];
        hr = splitTime.slice(2, splitTime.length).reverse().join("");
      } else if (splitTime.length === 2) {
        ss = splitTime[0];
        hr = "0";
        mm = splitTime.slice(1, 2).reverse().join("");
      } else {
        ss = hr;
        mm = hr = "0";
      }
    }
    //21:0206 -> 21:02:06
    //21:6206 -> 22:02:06
    //24:61120 -> 34:11:20
    //22:50 -> 00:22:50
    //1:2 => 00:01:02
    else if (val.length === 2) {
      if (mm.length >= 2) {
        let splitTime = mm.split(regex).reverse();
        if (splitTime.length >= 3) {
          ss = splitTime[0];
          mm = splitTime.slice(1, splitTime.length).reverse().join("");
        } else if (splitTime.length === 2) {
          ss = splitTime[0];
          mm = splitTime.slice(1, 2).reverse().join("");
        } else {
          ss = mm;
          mm = hr;
          hr = "0";
        }
      } else if (mm.length === 1) {
        ss = mm;
        mm = hr;
        hr = "0";
      }
    }
    //21::30 => 21:00:30 => 75630000
    //::003030 => 00:30:30 => 1830000
    //::30 => 00:00:30 => 30000
    //::3003 => 00:30:03 => 1803000
    else if (val.length === 3 && !hr && !mm) {
      let splitTime = val[2].split(regex).reverse();
      if (splitTime.length >= 3) {
        ss = splitTime[0];
        mm = splitTime[1];
        hr = splitTime.slice(2, splitTime.length).reverse().join("");
      } else if (splitTime.length === 2) {
        ss = splitTime[0];
        mm = splitTime.slice(1, 2).reverse().join("");
      }
    }
    let formattedStr = [
      (hr && prefixZero(hr)) || "00",
      (mm && prefixZero(mm)) || "00",
      (ss && prefixZero(ss)) || "00",
    ];
    return formattedStr;
  }, []);

  //this called onchange event of text field, arrow up down and on blur event
  const convertToMilliseconds = useCallback(
    (value: string) => {
      //if the entered text is not in time format(hh:mm:ss)
      //format it and convert to milliseconds
      let units =
        (value && value.trim().length && formatToHHMMSS(value.split(":"))) ||
        [];
      let ms =
        (units.length === 3 &&
          timeToMilliseconds(units[0], units[1], units[2])) ||
        0;
      //if entered value is more than given maxDuration
      if (ms && maxDuration && ms > maxDuration) {
        ms = maxDuration;
      }
      value && value.length ? onChange(ms) : onChange(undefined);
      return value && value.length ? ms : undefined;
    },
    [formatToHHMMSS, maxDuration, onChange]
  );

  const onBlurConvertToMilliSec = useCallback(() => {
    let ms = convertToMilliseconds(formatedDuration);
    //if the entered text is not in the form of hh:mm:ss then format it to hh:mm:ss
    //to display in the text box
    ms !== undefined ? setFormatedDuration(millisecondsToHours(ms)) : "";
  }, [convertToMilliseconds, formatedDuration]);

  let onChangeformatValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      //if not backspace or delete
      if (
        keyCode !== "Backspace" &&
        keyCode !== "ArrowDown" &&
        keyCode !== "ArrowUp"
      ) {
        if (!value.match(NUM_WIT_COLON_REGEX) && !value.match(/^[:\d]*$/g)) {
          return;
        } else if (
          value.match(TWO_DIG_ONLY_REGEX) ||
          (value.match(TWO_DIG_WIT_COLON_REGEX) && value.split(":").length < 3)
        ) {
          value += ":";
        }
      }
      let ms = convertToMilliseconds(value);
      //if entered text is more than maxduration, set the value of text field to maxDuration
      value && value.length && ms !== undefined && ms === maxDuration
        ? setFormatedDuration(millisecondsToHours(ms))
        : setFormatedDuration(value);
      setCursorPos(cursor + 1);
    },
    [keyCode, convertToMilliseconds, maxDuration, cursor]
  );

  const incrementOrDecrementOnArrowUpDwn = useCallback(
    (value: string, cursorpos: number, e: KeyboardEvent<HTMLInputElement>) => {
      let unit = value.split(":");
      if (
        unit[0] &&
        cursorpos <= unit[0].length &&
        cursorpos >= 0 &&
        e.currentTarget.value
      ) {
        let hhInt = parseInt(unit[0]);
        //hh
        let hh =
          e.code === "ArrowUp"
            ? hhInt + 1
            : hhInt > 0 && e.code === "ArrowDown"
            ? hhInt - 1
            : hhInt;
        unit[0] = prefixZero(hh.toString());
      } else if (
        unit[1] &&
        cursorpos <= Number(unit[1].length + unit[0].length + 1) &&
        cursorpos > unit[0].length
      ) {
        let mmInt = parseInt(unit[1]);
        //mm
        let mm =
          e.code === "ArrowUp"
            ? mmInt + 1
            : mmInt > 0 && e.code === "ArrowDown"
            ? mmInt - 1
            : mmInt;
        unit[1] = prefixZero(mm.toString());
      } else if (
        unit[2] &&
        cursorpos <=
          Number(unit[1].length + unit[2].length + unit[0].length + 2) &&
        cursorpos > unit[1].length
      ) {
        let ssInt = parseInt(unit[2]);
        //ss
        let ss =
          e.code === "ArrowUp"
            ? ssInt + 1
            : ssInt > 0 && e.code === "ArrowDown"
            ? ssInt - 1
            : ssInt;
        unit[2] = prefixZero(ss.toString());
      }
      return unit;
    },
    []
  );

  const handleKeys = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      setKeyCode(e.code);
      let cursorpos = e.currentTarget.selectionStart || 0;
      if (e.code === "ArrowLeft") {
        cursorpos = cursorpos - 1;
      } else if (e.code === "ArrowRigt") {
        cursorpos = cursorpos + 1;
      }
      setCursorPos(cursorpos);
      if (e.code === "ArrowUp" || e.code === "ArrowDown") {
        //handle up and down arrow
        e.preventDefault();
        let unit = e.currentTarget.value.split(":");
        let value = e.currentTarget.value;

        //if the field is blank
        if (!unit[0]) {
          value = "01:00:00";
        } else if (!unit[1]) {
          value = unit[0] + ":00:00";
        } else if (!unit[2]) {
          value = unit[0] + ":" + unit[1] + ":00";
        }

        unit = incrementOrDecrementOnArrowUpDwn(value, cursorpos, e);

        value =
          prefixZero(unit[0]) +
          ":" +
          prefixZero(unit[1]) +
          ":" +
          prefixZero(unit[2]);
        setCursorPos(cursorpos);
        //call onChange event of form control
        let ms = convertToMilliseconds(value);
        //if entered text is more than maxduration, set the value of text field to maxDuration
        value && value.length && ms !== undefined && ms === maxDuration
          ? setFormatedDuration(millisecondsToHours(ms))
          : setFormatedDuration(value);
      }
    },
    [incrementOrDecrementOnArrowUpDwn, convertToMilliseconds, maxDuration]
  );

  return (
    <Wrapper {...props}>
      <ThemeProvider tint="purple">
        <DurationInputBox>
          <TextContainer>
            <EditableText
              id={id}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              onKeyDown={handleKeys}
              onChange={onChangeformatValue}
              onBlur={onBlurConvertToMilliSec}
              value={formatedDuration}
              placeholder={placeholder}
              autoComplete={"off"}
              ref={(input) =>
                (keyCode === "ArrowDown" || keyCode === "ArrowUp") &&
                input &&
                (input.selectionStart = input.selectionEnd = cursor)
              }
              aria-invalid={!!props.errors?.length}
            />
          </TextContainer>
        </DurationInputBox>
      </ThemeProvider>
    </Wrapper>
  );
};

Duration.displayName = "Duration";

import { FormFor_content_Venue_ratingBlock, VenueRatingInput } from "@types";

export function ratingBlock(
  data: FormFor_content_Venue_ratingBlock
): VenueRatingInput {
  let { overall } = data;

  return {
    overall,
  };
}

import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";
import { get } from "@lib";
import { FormattedText } from "@types";
import * as helpers from "../../../../../lib/computeAutogen";

function helperNameToHookName(helperName: string) {
  return "use" + helperName.charAt(0).toUpperCase() + helperName.slice(1);
}

function hasHelper(helperName: string): helperName is keyof typeof helpers {
  return helperName in helpers;
}

function helperLookup(model: Record<string, unknown>, helperName: string) {
  let name = helperNameToHookName(helperName);
  return hasHelper(name) ? helpers[name](model) : null;
}

export function autogenerate(
  autogeneratePath: string | null,
  autogenerateConfig: string | null,
  model: Record<string, unknown>
): VersoSource | undefined {
  let modelValue;
  if (autogenerateConfig) {
    const [, helperName] = autogenerateConfig.split(/[@$]/) ?? [];
    modelValue = helperLookup(model, helperName) as string;
  } else {
    modelValue = autogeneratePath
      ? (get(model, autogeneratePath) as string | FormattedText | null)
      : null;
  }

  const md =
    typeof modelValue === "string" ? modelValue : modelValue?.content ?? "";
  return md
    ? CopilotMarkdownSource.fromRaw(md).convertTo(VersoSource)
    : undefined;
}

import { useIntl } from "react-intl";
import { Select as SelectImplementation } from "@components";
import { useDefinedMessages } from "@hooks";
import {
  ControlProps,
  FormFor_form_controls_ContentTypeSelectFormControl,
} from "@types";
import { get } from "@lib";

export function ContentTypeSelect(
  props: ControlProps<FormFor_form_controls_ContentTypeSelectFormControl>
) {
  const {
    name,
    setValue,
    contextualHelpKey,
    options,
    model,
    errors,
    multiple,
    noLabel,
  } = props;
  const { translateContextualHelp, translateFieldName, translateContentType } =
    useDefinedMessages();

  const value = (get(model, name) as string) ?? "";
  const selectLabelName = !noLabel ? translateFieldName(name) : "";
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    defaultMessage: "Select an Option",
    description: "Placeholder text for a Select component",
  });

  const transformedOptions =
    options?.options.map((content) => {
      return {
        label: translateContentType(content, 1),
        value: content,
      };
    }) || [];

  return (
    <SelectImplementation
      id={`ContentTypeSelect__${name}`}
      label={selectLabelName}
      aria-label={translateFieldName(name)}
      {...(multiple
        ? {
            multiple: true,
            value: (get(model, name) as string[]) || [],
            onChange: (values: string[]) => setValue(name, values),
            placeholder: placeholder,
          }
        : {
            multiple: false,
            value: (get(model, name) as string) || "",
            onChange: (value: string | undefined) => setValue(name, value),
            placeholder: value ? value : placeholder,
          })}
      errors={errors}
      options={transformedOptions}
      help={
        contextualHelpKey
          ? translateContextualHelp(contextualHelpKey)
          : undefined
      }
    />
  );
}
ContentTypeSelect.displayName = "Control(ContentTypeSelect)";

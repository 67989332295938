import {
  ControlProps,
  FormFor_form_controls_TagPublishDataFormControl,
} from "@types";
import { PublishData } from "../PublishData";
import type { TagpageFields_query } from "@types";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
export function TagPublishData(
  props: ControlProps<FormFor_form_controls_TagPublishDataFormControl>
) {
  let {
    model,
    errors,
    currentOrganization,
    permissions,
    setValue,
    autogenerateConfig,
    allowUnicodeLetters,
    children,
    currentUser,
    allowUppercase,
    managePublishURIs,
  } = props;

  const query = model["query"] as TagpageFields_query | null;
  const hierarchy = query?.filters?.categories?.[0]?.hierarchy || [];
  const topLevelTag =
    hierarchy?.[hierarchy.length - 1]?.slug === "cne-video"
      ? hierarchy?.[hierarchy.length - 2]?.slug
      : null;

  const newAutogenerateConfig = useMemo(() => {
    if (topLevelTag === "cne-video-tags") {
      return `video/tags/@queryCategory`;
    }
    if (topLevelTag === "cne-video-category") {
      return `video/category/@queryCategory`;
    }
    if (topLevelTag === "cne-video-series") {
      return `video/series/@queryCategory`;
    }
    return autogenerateConfig;
  }, [topLevelTag, autogenerateConfig]);

  let params = useParams() as {
    contentType: string;
  };
  const contentType = params.contentType;

  return (
    <PublishData
      model={model}
      autogenerateConfig={newAutogenerateConfig}
      currentOrganization={currentOrganization}
      errors={errors}
      permissions={permissions}
      setValue={setValue}
      allowUnicodeLetters={allowUnicodeLetters}
      managePublishURIs={managePublishURIs}
      id="§publish-data"
      currentUser={currentUser}
      allowUppercase={allowUppercase}
      contentType={contentType}
    >
      {children}
    </PublishData>
  );
}
TagPublishData.displayName = "Control(TagPublishData)";

import { defineMessages } from "react-intl";

export const PAGE_LAYOUT = defineMessages({
  textArrangement: { defaultMessage: "Text Arrangement" },
  textAlignment: { defaultMessage: "Text Alignment" },
  storyImageSize: { defaultMessage: "Story Image Size" },
  insetHeaderImage: { defaultMessage: "Inset Header Image" },
  contributorPhoto: { defaultMessage: "Contributor Photo" },
  card: { defaultMessage: "Card" },
  rule: { defaultMessage: "Rule" },
  textAbove: { defaultMessage: "Text Above" },
  textBelow: { defaultMessage: "Text Below" },
  textOverlay: { defaultMessage: "Text Overlay" },
  textLeft: { defaultMessage: "Text Left" },
  textRight: { defaultMessage: "Text Right" },
  alignLeft: { defaultMessage: "Left" },
  alignCenter: { defaultMessage: "Center" },
  alignRight: { defaultMessage: "Right" },
  imageSmall: { defaultMessage: "Small" },
  imageFullBleed: { defaultMessage: "Full Bleed" },
  imageGridWidth: { defaultMessage: "Grid Width" },
  aspectRatioDesktop: { defaultMessage: "Desktop" },
  aspectRatioMobile: { defaultMessage: "Mobile" },
  aspectRatioAriaLabel: { defaultMessage: "Select Aspect Ratio" },
  defaultImageText: { defaultMessage: "Header Image" },
});

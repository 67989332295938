export const cookies = {
  get(name: string) {
    let cookiePairs = document.cookie.split(";");
    let pair = cookiePairs
      .map((pairString) => pairString.trim().split("="))
      .find(([key]) => key === name);
    return pair && pair[1];
  },

  set(values: { [key: string]: string }) {
    Object.keys(values).forEach((key) => {
      let value = values[key];
      document.cookie = `${key}=${value}; path=/; max-age=31536000;`;
    });
  },

  remove(...keys: string[]) {
    let date = new Date();
    date.setMilliseconds(date.getMilliseconds() - 86400);
    keys.forEach((key) => {
      document.cookie = `${key}=; path=/; expires=${date.toUTCString()};`;
    });
  },
};

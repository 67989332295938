import styled from "styled-components";
import { useIntl } from "react-intl";
import { useCallback } from "react";
import { useDebouncedCallback } from "@hooks";
import {
  GetCategoryChildren,
  GetCategoryChildrenVariables,
  GetRootCategory,
  GetRootCategoryVariables,
  Taxonomy,
  CategoryData,
  GetConfigs,
} from "@types";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { Queries } from "@gql";
import { Category } from "@types";
import { CategorySelector, Field } from "@components";

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-xxs) 0;
  grid-template-rows: auto auto auto;
  height: fit-content;
  grid-template-areas:
    "label"
    "control"
    "."
    "message";

  > div {
    > div {
      width: 100%;
    }
  }
`;

export type Contributor = {
  id: string;
  title: { content: string | null };
};

type CategoryWithFilters = Category & { filters?: string[] };

function normalizeCategoryData(
  categories: CategoryData[],
  isSelectable?: boolean,
  noReverse?: boolean
): CategoryWithFilters[] {
  const transformedCategories: Category[] = categories?.map((category) => {
    let hierarchy = category.hierarchy.map((h) => ({
      name: h.name ?? "",
      slug: h.slug,
    }));
    let filters = hierarchy.map((h) => h.slug);
    return {
      id: category.id,
      name: category.name ?? "",
      slug: category.slug,
      hierarchy: noReverse ? hierarchy : hierarchy.reverse(),
      selectable: isSelectable,
      childCount: category.meta?.childCount ?? undefined,
      filters,
    };
  });

  return transformedCategories;
}

function isTaxonomyManagedByControl(
  slug: string,
  rootSlug: string | null,
  notHierarchies: string[] | null
) {
  if (rootSlug) {
    return slug === rootSlug;
  }
  if (notHierarchies) {
    return !notHierarchies.includes(slug);
  }
  return true;
}

function isNotHierarchies(category: Category, notHierarchies: string[] | null) {
  const hierarchyPath = category.hierarchy
    .map((hierarchyCategory) => {
      return hierarchyCategory.slug;
    })
    .reverse()
    .join("/");

  return !notHierarchies?.some((notHierarchyPath) =>
    hierarchyPath.startsWith(notHierarchyPath)
  );
}

export const ChannelSelector = (props: {
  setFilters: (
    selected: Array<string>,
    type: string,
    filtersToStore: Taxonomy[]
  ) => void;
  preSelectedChannels: Taxonomy[];
  organizationId: string;
  config: GetConfigs | undefined;
}) => {
  let intl = useIntl();
  const { setFilters, preSelectedChannels, organizationId, config } = props;
  const [
    _lazySearchChannelsData,
    { data: channelsData, loading: loadingChannelsData },
  ] = useLazyQuery(Queries.CATEGORY_SEARCH);
  const categoryConfigType = config?.configs?.search?.categories || "";

  const preformChannelSearch = useDebouncedCallback((query: string) => {
    if (!query) return;
    _lazySearchChannelsData({
      variables: {
        organizationId,
        filters: {
          q: query || "",
          hierarchy: categoryConfigType,
        },
      },
    });
  }, 250);

  const { data: rootData } = useQuery<
    GetRootCategory,
    GetRootCategoryVariables
  >(Queries.GET_ROOT_CATEGORY, {
    variables: {
      organizationId,
      slug: categoryConfigType,
    },
  });

  const roots =
    rootData && rootData.rootCategory
      ? normalizeCategoryData(rootData.rootCategory, false)
      : [];

  const Client = useApolloClient();
  const rootName = categoryConfigType && roots[0]?.name;
  const placeholder = rootName
    ? intl.formatMessage(
        {
          defaultMessage: "Select {rootName}",
          description:
            "Placeholder text for a category selector on a single root",
        },
        {
          rootName,
        }
      )
    : intl.formatMessage(
        {
          defaultMessage: "Select",
          description:
            "Select as a verb. Placeholder text for a select form control",
        },
        {
          rootName,
        }
      );

  const normalizedChannelSearch: Category[] = channelsData
    ? normalizeCategoryData(channelsData.categorySearch, true)
    : [];

  const onChangeChannel = useCallback(
    (selected: Taxonomy[]) => {
      let selectedChannels = selected
        .filter(
          (taxonomy: Taxonomy) =>
            isTaxonomyManagedByControl(
              categoryConfigType,
              categoryConfigType,
              null
            ) && taxonomy.categories.length
        )
        .map((data) => {
          const { name, slug } = data.categories[0];
          const filteredCategories = data.categories.filter(
            (category: Category) => isNotHierarchies(category, null)
          ) as CategoryData[];
          let normailizedCategories = normalizeCategoryData(
            filteredCategories,
            false,
            true
          );
          return {
            name,
            slug,
            categories: normailizedCategories,
            filters: normailizedCategories.map((category) =>
              category["filters"]
                ?.toString()
                .toLowerCase()
                .replace(/ /g, "_")
                .replace(/,/g, "/")
            ),
          };
        })
        .filter((d) => d.categories.length > 0);
      setFilters(
        selectedChannels.map(({ filters }) =>
          categoryConfigType === "sections"
            ? filters.toString().replace(/\bcategories\b/g, "sections")
            : filters.toString()
        ),
        "hierarchy",
        selectedChannels
      );
    },
    [setFilters]
  );

  return (
    <Wrapper
      id={categoryConfigType}
      label={
        categoryConfigType
          ? categoryConfigType === "channels"
            ? "Channels"
            : "Categories"
          : ""
      }
    >
      <CategorySelector
        aria-invalid={false}
        placeholder={placeholder}
        selections={preSelectedChannels}
        search={preformChannelSearch}
        searchResult={{
          loading: loadingChannelsData,
          data: normalizedChannelSearch,
        }}
        onChange={onChangeChannel}
        roots={roots}
        getChildren={(category) =>
          Client.query<GetCategoryChildren, GetCategoryChildrenVariables>({
            query: Queries.GET_CATEGORY_WITH_CHILDREN,
            variables: {
              organizationId,
              id: category.id,
              limit: category.childCount ?? 100,
            },
          }).then((result) => {
            const children = result.data.category.content
              ? result.data.category.content.results
              : [];
            const filteredChildren = children.filter((child) => {
              if (child.slug === "ContentHeader") {
                return !child.slug.includes("ContentHeader");
              }
              return child;
            });
            return normalizeCategoryData(filteredChildren, true);
          })
        }
      />
    </Wrapper>
  );
};
ChannelSelector.displayName = "ChannelSelector";

import { FC } from "react";
import styled from "styled-components";
import { Card } from "@components";
import { ThemeProvider } from "@contexts";
import {
  CautionIcon,
  CheckCircleIcon,
  DangerIcon,
  InfoIcon,
} from "@condenast/gemini/icons";

const BaseToast = styled.div`
  font: ${(props) => props.theme.FontLabel};
  background: ${(props) => props.theme.ToastTintedBackground};
  padding: var(--spacing-xs) 0;
  color: ${(props) => props.theme.Color};
  border-radius: ${(props) => props.theme.CornerRadius};
  display: grid;
  row-gap: var(--spacing-xs);
  & > svg {
    grid-area: icon;
  }
  ul {
    list-style-type: disc;
    margin-block-start: var(--spacing-sm);
    &:not(:last-child) {
      margin-block-end: var(--spacing-sm);
    }

    li {
      margin-left: var(--spacing-sm);
    }
  }
  b {
    font-weight: 700;
  }
  i {
    font-style: italic;
  }

  column-gap: var(--spacing-sm);
  grid-template-columns: auto auto 1fr auto;
  grid-template-areas:
    ". .    .       ."
    ". icon message ."
    ". .    .       .";

  border-left: var(--spacing-xxs) solid
    ${(props) => props.theme.ToastBorderColor};
  & > svg {
    color: ${(props) => props.theme.ToastIconColor};
  }

  box-shadow: ${(props) => props.theme.CardShadow};

  ${Card} & {
    box-shadow: none;
  }
`;

const Message = styled.strong`
  grid-area: message;
  font: ${(props) => props.theme.FontLabel};
`;

const Tints = {
  success: "green",
  informational: "blue",
  warning: "yellow",
  error: "red",
} as const;

const Icons = {
  success: CheckCircleIcon,
  informational: InfoIcon,
  warning: CautionIcon,
  error: DangerIcon,
} as const;

export const Toast: FC<{
  type: "success" | "informational" | "warning" | "error";
  className?: string;
  onClose?: () => void;
}> = (props) => {
  let Icon = Icons[props.type];

  return (
    <ThemeProvider tint={Tints[props.type]}>
      <BaseToast role="alert" aria-atomic="true" className={props.className}>
        <Icon size="small" />
        <Message>{props.children}</Message>
      </BaseToast>
    </ThemeProvider>
  );
};

import { Fragment, AriaAttributes } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import {
  Page,
  Button,
  Toggle,
  Spinner,
  Table,
  Input,
  Chip,
  RoutableLink,
} from "@components";
import {
  useDefinedMessages,
  useLocationSort,
  useQueryParams,
  useUserAccessControlMatrix,
} from "@hooks";
import { Navigate } from "react-router-dom";
import {
  GetUsers,
  GetUsers_users,
  GetUsersVariables,
  UserStatus,
  UserRole,
  Organization,
} from "@types";
import { Queries } from "@gql";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { isKeyOf } from "@lib";
import { UserStatusChip } from "../UserStatusChip";
import { SearchIcon } from "@condenast/gemini/icons";

const TeamPageContiner = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: auto;
  grid-template-areas: "head" "seach-bar" "actions" "body";
  gap: var(--spacing-md);
  padding-top: var(--spacing-sm);
  padding-left: var(--spacing-md);
  padding-right: var(--spacing-md);
  margin: 0 auto;
  max-width: ${(props) => props.theme.CardSize};
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  grid-area: head;
`;

const SearchContainer = styled.div`
  grid-area: seach-bar;
  position: relative;
  svg {
    pointer-events: none;
    position: absolute;
    left: calc(var(--spacing-xs) * 1.5);
    top: calc(var(--spacing-xs) * 1.5);
  }
  ${Input} {
    padding: ${(props) => props.theme.SecondaryPaddingWithLeftIcon};
    &::placeholder {
      opacity: 1;
      color: ${(props) => props.theme.PlaceholderColor};
    }
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button {
      display: none;
    }
  }
`;

const Name = styled(Table.Cell)`
  display: inline-grid;
  grid-template-columns: auto auto auto 1fr;
  gap: var(--spacing-xs);
`;

const SearchField = (
  props: {
    value: string;
    onChange: (value: string) => unknown;
    placeholder: string;
  } & AriaAttributes
) => {
  let { value, onChange, ...forwardProps } = props;
  return (
    <SearchContainer>
      <SearchIcon size="small" role="presentation" />
      <Input
        type="search"
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
        {...forwardProps}
      />
    </SearchContainer>
  );
};

const ActionsBlock = styled.div`
  grid-area: actions;
  display: flex;
  justify-content: flex-end;
`;

const PageTitle = styled.h1`
  font: var(--font-page-heading);
`;

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const BrandsChip = styled(Chip)`
  color: var(--color-gray-1);
  background: var(--color-gray-5);
`;

const RolePriority = {
  [UserRole.contributor]: 1,
  [UserRole.marketer]: 2,
  [UserRole.manager]: 3,
  [UserRole.admin]: 4,
  [UserRole.superadmin]: 5,
};

enum Order {
  ascending = "ascending",
  descending = "descending",
}

type Direction = "ascending" | "descending" | "none";

interface SortOrder {
  field: string;
  order: Order;
}

const FIELD_ORDERING = {
  firstName: {
    initial: Order.ascending,
    reverse: Order.descending,
  },
  lastName: {
    initial: Order.ascending,
    reverse: Order.descending,
  },
  role: {
    initial: Order.ascending,
    reverse: Order.descending,
  },
  createdAt: {
    initial: Order.descending,
    reverse: Order.ascending,
  },
};

export default function filterUsers(
  data: GetUsers_users[],
  variables: {
    query?: string;
    sortOrder?: SortOrder[];
    filters?: {
      status?: UserStatus[];
    };
  }
) {
  let users = [...data];
  if (variables.query) {
    let fullQuery = variables.query.toLocaleLowerCase().trim();
    let queryTerms = fullQuery.split(/\s+/g);
    users = users.filter((user) => {
      let nameParts = [
        ...user.firstName.toLocaleLowerCase().split(/\s+/g),
        ...user.lastName.toLocaleLowerCase().split(/\s+/g),
      ];

      let allQueryTermsMatchSomePart = queryTerms.every((query) =>
        nameParts.some((part) => part.indexOf(query) === 0)
      );

      let emailSubstringMatch =
        user.email.toLocaleLowerCase().indexOf(fullQuery) !== -1;

      return allQueryTermsMatchSomePart || emailSubstringMatch;
    });
  }

  if (variables.filters != null) {
    if (variables.filters.status != null) {
      let status = variables.filters.status;
      users = users.filter((user) => status.indexOf(user.status) !== -1);
    }
  }

  if (variables.sortOrder) {
    let sort = variables.sortOrder;

    users.sort((a, b) => {
      for (let i = 0; i < sort.length; i++) {
        let { field, order } = sort[i];
        if (isKeyOf(a, field)) {
          let result =
            field === "role"
              ? compare(RolePriority[a[field]], RolePriority[b[field]])
              : compare(a[field], b[field]);
          if (result !== 0) {
            return result * (order === "descending" ? -1 : 1);
          }
        }
      }
      return 0;
    });
  }

  return users;
}

function compare(lhs: unknown, rhs: unknown) {
  if (isString(lhs) && isString(rhs)) {
    return lhs.toLocaleLowerCase().localeCompare(rhs.toLocaleLowerCase());
  } else if (isNumber(lhs) && isNumber(rhs)) {
    return lhs === rhs ? 0 : lhs < rhs ? -1 : 1;
  }
  return 0;
}

function isString(value: unknown): value is string {
  return typeof value === "string";
}

function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

function order(field: string, direction: "initial" | "reverse"): SortOrder {
  if (!isKeyOf(FIELD_ORDERING, field)) {
    throw new Error(`Ordering is not specified for ${field}`);
  }
  return {
    field,
    order: FIELD_ORDERING[field][direction],
  };
}

export const MyTeam = (props: { currentOrganization: Organization }) => {
  const copilotCode = props.currentOrganization.metadata.copilotCode;
  const { data } = useQuery<GetUsers, GetUsersVariables>(Queries.GET_USERS, {
    variables: { organizationId: props.currentOrganization.organizationId },
  });

  const permissions = useUserAccessControlMatrix(
    props.currentOrganization,
    "search",
    Object.values(UserRole)
  );
  const isRestricted = Object.values(permissions).every(
    (accessControl) => accessControl.search === false
  );

  let [queryParams, setQueryParams] = useQueryParams<{
    q?: string | null;
    status?: string[];
    sort?: string;
  }>();

  const defaultSortField = "firstName"; // Setting the default sorted column-name for useLocationSort
  const [currentSortedField, currentDirection] =
    useLocationSort(defaultSortField);

  const sortOrder: SortOrder[] =
    currentSortedField === defaultSortField
      ? [
          order("firstName", currentDirection),
          order("lastName", currentDirection),
          order("createdAt", currentDirection),
        ]
      : [
          order(currentSortedField, currentDirection),
          order("firstName", currentDirection),
          order("lastName", currentDirection),
        ];

  const getColumnDirection = (columnName: string): Direction => {
    const isActive = columnName === currentSortedField;
    const currentColumnDirection = isActive
      ? currentDirection === "reverse"
        ? "descending"
        : "ascending"
      : "none";

    return currentColumnDirection;
  };

  const handleSort = (columnName: string, activeDirection: Direction): void => {
    const newSortString =
      activeDirection === "ascending" ? `-${columnName}` : columnName;
    return setQueryParams(
      {
        sort: newSortString,
      },
      false
    );
  };

  return isRestricted ? (
    <Navigate replace to={`/${copilotCode}/users/profile`} />
  ) : data?.users ? (
    <MyTeamPage
      currentOrganization={props.currentOrganization}
      getColumnDirection={getColumnDirection}
      handleSort={handleSort}
      search={{
        q: queryParams.q ?? "",
        status: queryParams.status as UserStatus[],
      }}
      setSearchParams={setQueryParams}
      sortOrder={sortOrder}
      users={data.users}
    />
  ) : (
    <SpinnerWrapper>
      <Spinner size="large" />
    </SpinnerWrapper>
  );
};

export const TeamTableData = (props: {
  users: GetUsers_users[];
  currentOrganization: Organization;
}) => {
  let intl = useIntl();
  let { users } = props;
  let { copilotCode } = props.currentOrganization.metadata;
  let { translateRoleName } = useDefinedMessages();

  return (
    <Fragment>
      {users.map((u) => {
        let displayName = `${u.firstName} ${u.lastName}`;
        let translatedRole = translateRoleName(u.role);

        return (
          <Table.Row
            key={u.id}
            as={RoutableLink}
            to={`/${copilotCode}/users/${u.id}/edit`}
          >
            <Name>
              {displayName}
              {u.role !== "superadmin" && (
                <BrandsChip
                  size="small"
                  aria-label={
                    u.organizations.length > 1 &&
                    intl.formatMessage({
                      defaultMessage: "Brands",
                    })
                  }
                  aria-details={
                    u.organizations.length > 1 &&
                    intl.formatList(
                      u.organizations.map((org) => org.internalDisplayName),
                      { style: "long", type: "conjunction" }
                    )
                  }
                >
                  <FormattedMessage
                    defaultMessage="{assignedBrandsCount, plural, one {{assignedBrand}} other {{assignedBrandsCount} brands}}"
                    values={{
                      assignedBrandsCount: u.organizations.length,
                      assignedBrand:
                        u.organizations[0].internalDisplayName ?? "",
                    }}
                  />
                </BrandsChip>
              )}
              {u.status !== UserStatus.active && (
                <UserStatusChip status={u.status} />
              )}
            </Name>
            <Table.Cell>{translatedRole}</Table.Cell>
            <Table.Cell>
              <FormattedDate
                value={u.createdAt ? new Date(Date.parse(u.createdAt)) : ""}
                year="numeric"
                month="long"
                day="numeric"
                hour="numeric"
                minute="numeric"
              />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Fragment>
  );
};

export const MyTeamPage = ({
  currentOrganization,
  getColumnDirection,
  handleSort,
  search: { q: query, status = [UserStatus.active, UserStatus.pending] },
  setSearchParams,
  sortOrder,
  users,
}: {
  currentOrganization: Organization;
  getColumnDirection: (columnName: string) => Direction;
  handleSort: (columnName: string, activeDirection: Direction) => void;
  search: {
    q: string;
    status?: UserStatus[];
  };
  setSearchParams: (params: { q?: string; status?: UserStatus[] }) => void;
  sortOrder: SortOrder[];
  users: GetUsers_users[];
}) => {
  let intl = useIntl();

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: "My Team",
        description: "Title used for my team page",
      })}
    >
      <TeamPageContiner>
        <Header>
          <PageTitle>
            {intl.formatMessage({
              defaultMessage: "My Team",
              description: "Title used for my team page",
            })}
          </PageTitle>
          <Button
            as={RoutableLink}
            to={`/${currentOrganization.metadata.copilotCode}/users/create`}
            treatment="primary"
          >
            <FormattedMessage defaultMessage="New User" />
          </Button>
        </Header>
        <SearchField
          aria-label={intl.formatMessage({
            defaultMessage: "Search users",
          })}
          placeholder={intl.formatMessage({
            defaultMessage: "Search users",
          })}
          value={query}
          onChange={(newQuery: string) => setSearchParams({ q: newQuery })}
        />
        <ActionsBlock>
          <Toggle
            id="include-deactivated-users"
            label={intl.formatMessage({
              defaultMessage: "Show deactivated users",
            })}
            value={status.indexOf(UserStatus.deactivated) !== -1}
            onChange={() => {
              if (status.indexOf(UserStatus.deactivated) !== -1) {
                setSearchParams({
                  status: [],
                });
              } else {
                setSearchParams({
                  status: [
                    UserStatus.active,
                    UserStatus.deactivated,
                    UserStatus.pending,
                  ],
                });
              }
            }}
          />
        </ActionsBlock>
        <Table gridTemplateColumns="3fr 1fr 2fr">
          <Table.Head>
            <Table.Row>
              <Table.Header
                sort="name"
                activeDirection={getColumnDirection("firstName")}
                onSort={(currentDirection: Direction) =>
                  handleSort("firstName", currentDirection)
                }
              >
                <FormattedMessage defaultMessage="Name" />
              </Table.Header>
              <Table.Header
                sort="role"
                activeDirection={getColumnDirection("role")}
                onSort={(currentDirection: Direction) =>
                  handleSort("role", currentDirection)
                }
              >
                <FormattedMessage defaultMessage="Role" />
              </Table.Header>
              <Table.Header
                sort="created at"
                activeDirection={getColumnDirection("createdAt")}
                onSort={(currentDirection: Direction) =>
                  handleSort("createdAt", currentDirection)
                }
              >
                <FormattedMessage defaultMessage="Date Created" />
              </Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <TeamTableData
              users={filterUsers(users, {
                query,
                sortOrder,
                filters: {
                  status,
                },
              })}
              currentOrganization={currentOrganization}
            />
          </Table.Body>
        </Table>
      </TeamPageContiner>
    </Page>
  );
};

MyTeamPage.displayName = "MyTeamPage";

import { OfferFields, OfferInput } from "@types";

export function offers(input?: OfferFields[]): OfferInput[] {
  return (
    input?.map(
      ({
        sellerName,
        offerLink,
        offerType,
        price,
        comparisonPrice,
        currency,
        isOutOfStock,
        countryCode,
        autoUpdate,
      }) => ({
        sellerName,
        offerLink,
        offerType,
        price: price ?? undefined,
        comparisonPrice: comparisonPrice ?? undefined,
        currency,
        isOutOfStock: !!isOutOfStock,
        countryCode: countryCode ?? "",
        autoUpdate: !!autoUpdate,
      })
    ) ?? []
  );
}

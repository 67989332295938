import { memo } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  NextIcon,
} from "@condenast/gemini/icons";
import { Button } from "@components";
import { TextButtonWithIcon } from "./";
import { ContainerFooterProps } from "../types";

const ContainerFooterWrapper = styled.div`
  display: flex;
  text-align: right;
  align-items: center;
  padding: var(--spacing-sm);
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.DividerColor};
`;

const MoveContainerButtons = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
  font: var(--font-button);
`;

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  border: 1px solid ${(props) => props.theme.DividerColor};
  border-radius: var(--spacing-xxs);
  padding: 0;
`;

const DownIcon = styled(NextIcon)`
  transform: rotate(90deg);
`;

const UpIcon = styled(NextIcon)`
  transform: rotate(-90deg);
`;

const ContainerFooter = memo(function (props: ContainerFooterProps) {
  const {
    moveContainer,
    containerIndex,
    totalContainerCount,
    isCollapsed,
    toggleContainerCollapsed,
    containerKey,
  } = props;
  const intl = useIntl();

  return (
    <ContainerFooterWrapper>
      <MoveContainerButtons>
        <StyledButton
          aria-label={intl.formatMessage({
            defaultMessage: "Move container down",
            description: "Button used Move container down",
          })}
          disabled={containerIndex === totalContainerCount - 1}
          onClick={() => {
            moveContainer(containerIndex, containerIndex + 1);
          }}
        >
          <DownIcon size="regular" />
        </StyledButton>
        {containerIndex + 1}
        <StyledButton
          aria-label={intl.formatMessage({
            defaultMessage: "Move container up",
            description: "Button used Move container up",
          })}
          disabled={containerIndex === 0}
          onClick={() => {
            moveContainer(containerIndex, containerIndex - 1);
          }}
        >
          <UpIcon size="regular" />
        </StyledButton>
      </MoveContainerButtons>
      <TextButtonWithIcon
        treatment="text"
        onClick={() => {
          toggleContainerCollapsed(containerKey);
        }}
      >
        {isCollapsed ? (
          <>
            <FormattedMessage defaultMessage="View More" />
            <ChevronDownIcon size="small" />
          </>
        ) : (
          <>
            <FormattedMessage defaultMessage="View Less" />
            <ChevronUpIcon size="small" />
          </>
        )}
      </TextButtonWithIcon>
    </ContainerFooterWrapper>
  );
});

ContainerFooter.displayName = "ContainerFooter;";

export default ContainerFooter;

import type {
  GalleryFields_items,
  GalleryItemInput,
  ContentSummaryFields,
} from "@types";

export function galleryItems(input?: GalleryFields_items): GalleryItemInput[] {
  return (
    input?.edges?.reduce((accumulatedItems, item) => {
      let node = item?.node as ContentSummaryFields;
      if (item && node) {
        let { isActive, title, caption, body, credit } = item;
        accumulatedItems.push({
          isActive,
          title,
          caption,
          body,
          credit,
          node: { id: node.id, contentType: node.contentType },
        });
      }
      return accumulatedItems;
    }, [] as GalleryItemInput[]) ?? []
  );
}

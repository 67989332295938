import styled from "styled-components";
import { Button, Label } from "@components";

export const ContainerBody = styled.div<{ $isCollapsed?: boolean }>`
  display: ${(props) => (props.$isCollapsed ? "none" : "grid")};
  margin: 0 auto;
  padding: var(--spacing-sm) var(--spacing-lg);
  row-gap: var(--spacing-sm);
  width: 100%;
  background: var(--color-gray-6);
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const ContainerLabel = styled.span`
  font: var(--font-label);
`;

export const EmptyContainerMessage = styled.span`
  font: var(--font-small-statements);
`;

export const TakeoverHeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-xs);
`;

export const TextButtonWithIcon = styled(Button)`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  svg {
    left: auto;
    align-self: center;
  }
`;

export const BundleContainerSummary = styled(Label)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  width: 75%;
  font-weight: bold;
  text-align: right;
  -webkit-line-clamp: 2;
`;

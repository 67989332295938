import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { optional, required, geo } from "@condenast/copilot-validators";

import type { ValidationContext, ValidationEnvironment } from "@lib";
import type { FormFor_content_Venue } from "@types";

const { object } = validators;

const spaValidators = object({
  name: required(),
  geo: optional(geo()),
});

export default function validateSpa(
  spa: FormFor_content_Venue,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(spa, build(spaValidators), context, env);
}

import { Select, Button } from "@components";
import {
  AssetSelectorSearchOptions,
  GetBrand_brandConfiguration_contentTypes,
  GetCurrentUser_currentUser,
  curationSubType,
} from "@types";
import { useDefinedMessages, useFocusTrap } from "@hooks";
import { FC, useCallback, useState } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CheckCircleIcon,
  CloseIcon,
  ContentTypeArticleIcon,
  FilterIcon,
} from "@condenast/gemini/icons";
import {
  PeopleFilter,
  SearchDateFilter,
  ChannelFilter,
  CustomFilterWrapper,
} from "../../../../../Main/Search/searchFilters";

const FiltersContainer = styled.div<{ $isTakeoverView?: boolean }>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);

  > div {
    width: ${({ $isTakeoverView }) => ($isTakeoverView ? "100%" : "initial")};

    > button {
      background: ${({ theme }) => theme.BorderlessActiveBackground};

      + div {
        background: ${({ theme }) => theme.SecondaryActiveBackground};
      }
    }
  }
`;

export const StyledSelect = styled(Select)<{ $isMobile?: boolean }>`
  display: ${(props) => (props.$isMobile ? "block" : "inline-block")};
  grid-template-columns: 100%;
  grid-gap: 0px;
  ul[role="listbox"] {
    min-width: max-content;
    transform: ${(props) =>
      //44px is the height of the select box
      props.$isMobile ? "none" : "translate(0px, 44px) !important"};
  }
  ul:not([role="listbox"]) {
    display: ${(props) => (props.$isMobile ? "block" : "none")};
    padding: var(--spacing-xs) 0;
  }
  min-width: max-content;
  width: 100%;
  padding: ${(props) => (props.$isMobile ? "0 0 var(--spacing-xxs) 0" : "0")};

  label {
    color: ${(props) => props.theme.PrimaryColor};
  }
`;

const ResetButton = styled(Button)`
  color: var(--color-blue-60);
`;

const FilterLabelSpan = styled.span`
  color: ${(props) => props.theme.Color};
`;

const SelectedFilterSpan = styled.span`
  color: ${(props) => props.theme.Color};
`;

const MobileButton = styled(Button)`
  ${({ theme }) => {
    return `
      display: flex;
      align-items center;
      gap: var(--spacing-xs);
      justify-content: center;
      flex-grow: 1;
      font: var(--font-body);
      width: 100%;
      min-height: var(--spacing-xl);
      color: ${theme.Color};
      border-radius: ${theme.CornerRadius};
      box-shadow: ${theme.CardShadow};
      background: ${theme.BorderlessActiveBackground};
      padding: var(--spacing-xs) var(--spacing-sm);

      &:not([disabled]):hover {
        cursor: initial;
        box-shadow: ${theme.FieldHoverRing};
        background: ${theme.BorderlessActiveBackground};
      }

      &:not([disabled]):active {
        box-shadow: ${theme.FieldActiveRing};
        background: ${theme.Background};
      }

      &:not([disabled]):focus,
      &:not([disabled])[aria-expanded="true"] {
        box-shadow: ${theme.FieldFocusRing},
          ${theme.FocusRing};
        outline: none;
        background: ${theme.Background};
      }

      svg {
        color: ${theme.Color};
        left: auto;
        align-self: center;
        width: 16px;
        height: 16px;
      }
    `;
  }}
`;

const FilterTakeover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: ${({ theme }) => theme.ElevationModal};
  background-color: ${({ theme }) => theme.SecondaryActiveBackground};
  color: ${({ theme }) => theme.Color};
`;

const TakeoverHeader = styled.div`
  display: flex;
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.DividerColor};
  width: 100%;
  top: 0;
  font: var(--font-subhed);
  padding: var(--spacing-sm);
  align-items: center;
  justify-content: space-between;
`;

const TakeoverFooter = styled.div`
  display: flex;
  height: 56px;
  border-top: 1px solid ${({ theme }) => theme.DividerColor};
  width: 100%;
  bottom: 0;
  padding: var(--spacing-xs);
  align-items: center;
  justify-content: space-between;
`;

const TakeoverScrollable = styled.div`
  height: 100%;
  max-height: calc(100vh - 2 * 56px);
  padding: var(--spacing-md);
`;

const TakeoverFilters = styled.div`
  max-width: 480px;
  margin: auto;
`;

const CloseButton = styled(Button)``;

export const ContentFilters: FC<{
  filters: AssetSelectorSearchOptions;
  setFilters: (filters: AssetSelectorSearchOptions) => void;
  dateRangeOptions: string[];
  statusOptions: string[];
  contentTypes: GetBrand_brandConfiguration_contentTypes[];
  contentType?: string;
  organizationId: string;
  currentUser: GetCurrentUser_currentUser;
  hidePeopleFilter?: boolean;
  categoryConfigType?: string | null;
  takeoverView?: boolean;
}> = (props) => {
  const { translateContentType, translateSelectOption, translateFieldName } =
    useDefinedMessages();

  const {
    filters,
    setFilters,
    dateRangeOptions,
    statusOptions,
    contentTypes,
    organizationId,
    currentUser,
    hidePeopleFilter,
    categoryConfigType,
    takeoverView,
  } = props;
  const intl = useIntl();

  const mobileView = true;
  const [mobileFiltersToggle, setMobileFiltersToggle] =
    useState<boolean>(false);
  const [takeoverElement, setTakeoverElement] = useState<HTMLElement | null>(
    null
  );

  const selectedContentTypeConfig = contentTypes.find(
    (contentTypeConfig) => contentTypeConfig.value === props.contentType
  );

  const atLeastOnePublishableTypeConfigured = contentTypes.some(
    (contentTypeConfig) => contentTypeConfig.publish
  );

  const showPublishableTypeFilters =
    !selectedContentTypeConfig || selectedContentTypeConfig?.publish;

  const filterCount = Object.keys(filters)
    .map((key) => {
      let filterValue = filters[key as keyof AssetSelectorSearchOptions];
      if (key === "types" && selectedContentTypeConfig) {
        filterValue = [];
      } else if (key === "status" && !showPublishableTypeFilters) {
        filterValue = [];
      } else if (key === "people" && hidePeopleFilter) {
        filterValue = [];
      } else if (
        key === "curationSubType" &&
        (!selectedContentTypeConfig ||
          selectedContentTypeConfig?.value === "curatedlist") &&
        filterValue &&
        typeof filterValue === "string" &&
        filterValue !== "AssetsOnly"
      ) {
        filterValue = [filterValue];
      } else if (
        key === "date" &&
        showPublishableTypeFilters &&
        typeof filterValue === "string"
      ) {
        filterValue = [filterValue];
      }
      return filterValue && Array.isArray(filterValue) ? filterValue.length : 0;
    })
    .reduce((a, b) => a + b, 0);

  const onClose = useCallback(() => {
    setMobileFiltersToggle(false);
  }, [setMobileFiltersToggle]);

  useFocusTrap(takeoverElement, onClose);

  return selectedContentTypeConfig?.value === "cnevideo" ? (
    <></>
  ) : (
    <FiltersContainer $isTakeoverView={takeoverView}>
      {takeoverView ? (
        <>
          <MobileButton
            onClick={() => {
              setMobileFiltersToggle(true);
            }}
          >
            <FilterIcon size="regular" />
            {filterCount > 0 ? (
              <FormattedMessage
                defaultMessage="Filters ({count})"
                values={{ count: filterCount }}
              />
            ) : (
              <FormattedMessage defaultMessage="Filters" />
            )}
          </MobileButton>
          {mobileFiltersToggle && (
            <FilterTakeover ref={setTakeoverElement}>
              <TakeoverHeader>
                <FormattedMessage defaultMessage="Filters" />
                <CloseButton
                  size="small"
                  aria-label={intl.formatMessage({
                    defaultMessage: "Close",
                  })}
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon size="small" />
                </CloseButton>
              </TakeoverHeader>
              <TakeoverScrollable>
                <TakeoverFilters>
                  {!selectedContentTypeConfig && (
                    <StyledSelect
                      id="search-content-types"
                      $isMobile={mobileView}
                      label={
                        mobileView
                          ? intl.formatMessage({ defaultMessage: "Type" })
                          : null
                      }
                      placeholder={
                        filters.types?.length
                          ? intl.formatMessage(
                              {
                                defaultMessage: "Types ({count})",
                                description:
                                  "Search filter placeholder when >= 1 options selected",
                              },
                              { count: filters.types.length }
                            )
                          : intl.formatMessage({
                              defaultMessage: "Types",
                              description:
                                "Search filter placeholder when no options selected",
                            })
                      }
                      multiple
                      value={filters.types || []}
                      onChange={(updatedContentTypes: string[] | undefined) =>
                        setFilters({ ...filters, types: updatedContentTypes })
                      }
                      options={
                        contentTypes
                          ?.map((contentType) => {
                            return {
                              label: translateContentType(
                                contentType.value,
                                1,
                                contentType.label
                              ),
                              value: contentType.value,
                            };
                          })
                          .sort((a, b) =>
                            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                          ) ?? []
                      }
                      icon={mobileView ? undefined : ContentTypeArticleIcon}
                    />
                  )}
                  {showPublishableTypeFilters &&
                    atLeastOnePublishableTypeConfigured && (
                      <StyledSelect
                        id="search-status"
                        $isMobile={mobileView}
                        label={
                          mobileView
                            ? intl.formatMessage({ defaultMessage: "Status" })
                            : null
                        }
                        placeholder={
                          filters?.status?.length
                            ? intl.formatMessage(
                                {
                                  defaultMessage: "Status ({count})",
                                  description:
                                    "Search filter placeholder when >= 1 options selected",
                                },
                                { count: filters.status.length }
                              )
                            : intl.formatMessage({ defaultMessage: "Status" })
                        }
                        multiple
                        value={filters.status || []}
                        onChange={(value: string[] | undefined) =>
                          setFilters({
                            ...filters,
                            status: value,
                          })
                        }
                        options={
                          statusOptions?.map((status) => {
                            return {
                              label: translateSelectOption(
                                "statusFilterOptions",
                                status
                              ),
                              value: status,
                            };
                          }) ?? []
                        }
                        icon={mobileView ? undefined : CheckCircleIcon}
                      />
                    )}
                  {!hidePeopleFilter && (
                    <PeopleFilter
                      onChange={(peopleFilterValue) => {
                        setFilters({ ...filters, ...peopleFilterValue });
                      }}
                      currentUser={currentUser}
                      mobileView={mobileView}
                      takeoverView={takeoverView}
                      selectedFilters={filters}
                      organizationId={organizationId}
                    />
                  )}
                  {showPublishableTypeFilters &&
                    atLeastOnePublishableTypeConfigured && (
                      <SearchDateFilter
                        selectedFilters={filters}
                        onChange={(date) =>
                          setFilters({
                            ...filters,
                            date,
                          })
                        }
                        dateRangeOptions={dateRangeOptions}
                        mobileView={mobileView}
                        takeoverView={takeoverView}
                      />
                    )}
                  {showPublishableTypeFilters &&
                    atLeastOnePublishableTypeConfigured && (
                      <ChannelFilter
                        currentFilters={filters}
                        onChange={(updatedFilters) => {
                          if (updatedFilters.categories) {
                            setFilters({
                              ...filters,
                              categories: updatedFilters.categories,
                            });
                          }
                        }}
                        organizationId={organizationId}
                        categoryConfigType={categoryConfigType || ""}
                        mobileView={mobileView}
                        takeoverView={takeoverView}
                      />
                    )}
                  {selectedContentTypeConfig?.value === "curatedlist" && (
                    <StyledSelect
                      aria-label={"Curation Type"}
                      id="curation-type"
                      value={filters.curationSubType || "AssetsOnly"}
                      onChange={(value: string | undefined) => {
                        if (value) {
                          setFilters({ ...filters, curationSubType: value });
                        }
                      }}
                      options={Object.values(curationSubType).map((type) => {
                        return {
                          label: translateSelectOption(
                            "curatedList.curationSubType",
                            type
                          ),
                          value: type as string,
                        };
                      })}
                      label={
                        mobileView
                          ? intl.formatMessage({
                              defaultMessage: "Curation Type",
                            })
                          : null
                      }
                      multiple={false}
                      placeholder={
                        <>
                          <FilterLabelSpan>
                            {translateFieldName("Curation Type: ")}
                          </FilterLabelSpan>
                          <SelectedFilterSpan>
                            {translateSelectOption(
                              "curatedList.curationSubType",
                              filters.curationSubType || "AssetsOnly"
                            )}
                          </SelectedFilterSpan>
                        </>
                      }
                      alwaysUsePlaceholder={true}
                    />
                  )}
                </TakeoverFilters>
              </TakeoverScrollable>
              <TakeoverFooter>
                <ResetButton
                  treatment="text"
                  disabled={Object.keys(filters).length === 0}
                  onClick={() => {
                    setFilters({});
                  }}
                >
                  {intl.formatMessage({ defaultMessage: "Reset" })}
                </ResetButton>
                <Button
                  treatment="primary"
                  size="regular"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <FormattedMessage defaultMessage="Apply filters" />
                </Button>
              </TakeoverFooter>
            </FilterTakeover>
          )}
        </>
      ) : (
        <>
          <CustomFilterWrapper
            icon={FilterIcon}
            displayText={filterCount ? `Filters (${filterCount})` : "Filters"}
            isTakeoverView={takeoverView}
          >
            {!selectedContentTypeConfig && (
              <StyledSelect
                id="search-content-types"
                $isMobile={mobileView}
                label={
                  mobileView
                    ? intl.formatMessage({ defaultMessage: "Type" })
                    : null
                }
                placeholder={
                  filters.types?.length
                    ? intl.formatMessage(
                        {
                          defaultMessage: "Types ({count})",
                          description:
                            "Search filter placeholder when >= 1 options selected",
                        },
                        { count: filters.types.length }
                      )
                    : intl.formatMessage({
                        defaultMessage: "Types",
                        description:
                          "Search filter placeholder when no options selected",
                      })
                }
                multiple
                value={filters.types || []}
                onChange={(updatedContentTypes: string[] | undefined) =>
                  setFilters({ ...filters, types: updatedContentTypes })
                }
                options={
                  contentTypes
                    ?.map((contentType) => {
                      return {
                        label: translateContentType(
                          contentType.value,
                          1,
                          contentType.label
                        ),
                        value: contentType.value,
                      };
                    })
                    .sort((a, b) =>
                      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                    ) ?? []
                }
                icon={mobileView ? undefined : ContentTypeArticleIcon}
              />
            )}
            {showPublishableTypeFilters && atLeastOnePublishableTypeConfigured && (
              <StyledSelect
                id="search-status"
                $isMobile={mobileView}
                label={
                  mobileView
                    ? intl.formatMessage({ defaultMessage: "Status" })
                    : null
                }
                placeholder={
                  filters?.status?.length
                    ? intl.formatMessage(
                        {
                          defaultMessage: "Status ({count})",
                          description:
                            "Search filter placeholder when >= 1 options selected",
                        },
                        { count: filters.status.length }
                      )
                    : intl.formatMessage({ defaultMessage: "Status" })
                }
                multiple
                value={filters.status || []}
                onChange={(value: string[] | undefined) =>
                  setFilters({
                    ...filters,
                    status: value,
                  })
                }
                options={
                  statusOptions?.map((status) => {
                    return {
                      label: translateSelectOption(
                        "statusFilterOptions",
                        status
                      ),
                      value: status,
                    };
                  }) ?? []
                }
                icon={mobileView ? undefined : CheckCircleIcon}
              />
            )}
            {!hidePeopleFilter && (
              <PeopleFilter
                onChange={(peopleFilterValue) => {
                  setFilters({ ...filters, ...peopleFilterValue });
                }}
                currentUser={currentUser}
                mobileView={mobileView}
                selectedFilters={filters}
                organizationId={organizationId}
              />
            )}
            {showPublishableTypeFilters && atLeastOnePublishableTypeConfigured && (
              <SearchDateFilter
                selectedFilters={filters}
                onChange={(date) =>
                  setFilters({
                    ...filters,
                    date,
                  })
                }
                dateRangeOptions={dateRangeOptions}
                mobileView={mobileView}
              />
            )}
            {showPublishableTypeFilters && atLeastOnePublishableTypeConfigured && (
              <ChannelFilter
                currentFilters={filters}
                onChange={(updatedFilters) => {
                  if (updatedFilters.categories) {
                    setFilters({
                      ...filters,
                      categories: updatedFilters.categories,
                    });
                  }
                }}
                organizationId={organizationId}
                categoryConfigType={categoryConfigType || ""}
                mobileView={mobileView}
              />
            )}
            {selectedContentTypeConfig?.value === "curatedlist" && (
              <StyledSelect
                aria-label={"Curation Type"}
                id="curation-type"
                value={filters.curationSubType || "AssetsOnly"}
                onChange={(value: string | undefined) => {
                  if (value) {
                    setFilters({ ...filters, curationSubType: value });
                  }
                }}
                options={Object.values(curationSubType).map((type) => {
                  return {
                    label: translateSelectOption(
                      "curatedList.curationSubType",
                      type
                    ),
                    value: type as string,
                  };
                })}
                label={
                  mobileView
                    ? intl.formatMessage({ defaultMessage: "Curation Type" })
                    : null
                }
                multiple={false}
                placeholder={
                  <>
                    <FilterLabelSpan>
                      {translateFieldName("Curation Type: ")}
                    </FilterLabelSpan>
                    <SelectedFilterSpan>
                      {translateSelectOption(
                        "curatedList.curationSubType",
                        filters.curationSubType || "AssetsOnly"
                      )}
                    </SelectedFilterSpan>
                  </>
                }
                alwaysUsePlaceholder={true}
              />
            )}
          </CustomFilterWrapper>
          <ResetButton
            treatment="text"
            disabled={Object.keys(filters).length === 0}
            onClick={() => {
              setFilters({});
            }}
          >
            {intl.formatMessage({ defaultMessage: "Reset" })}
          </ResetButton>
        </>
      )}
    </FiltersContainer>
  );
};

import { Select } from "@components";
import { useIntl } from "react-intl";

export const StatusSelector = (props: {
  setFilters: (selected: Array<string>, type: string) => void;
  preSelectedStatus: Array<string>;
}) => {
  let intl = useIntl();
  const { preSelectedStatus, setFilters } = props;

  return (
    <Select
      id={"status"}
      multiple={true}
      placeholder={intl.formatMessage({
        defaultMessage: "Select",
        description:
          "Select as a verb. Placeholder text for a select form control",
      })}
      options={[
        { label: "Published", value: "published" },
        { label: "Draft", value: "draft" },
      ]}
      label={intl.formatMessage({
        defaultMessage: "Status",
        description: "Filter Status label",
      })}
      onChange={(selected) => {
        setFilters(selected, "status");
      }}
      value={preSelectedStatus}
      errors={[]}
    />
  );
};

StatusSelector.displayName = "StatusSelector";

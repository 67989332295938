import { AnimatedEllipsisIcon, SearchIcon } from "@condenast/gemini/icons";
import {
  forwardRef,
  useCallback,
  ChangeEvent,
  ComponentPropsWithoutRef,
} from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.ToastBackground};
  [contentEditable="true"]:empty:not(:focus):before {
    content: attr(data-ph);
    color: ${(props) => props.theme.PlaceholderColor};
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  position: relative;
  grid-area: input;
  align-items: center;
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  width: 100%;
  &:hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing};
  }
  &:focus-within {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
  }
`;

const StyledSearchInput = styled.input`
  vertical-align: middle;
  font: ${(props) => props.theme.FontStatement};
  outline: 0px solid transparent;
  border-radius: ${(props) => props.theme.CornerRadius};
  border: 1px solid ${(props) => props.theme.DividerColor};
  line-height: var(--spacing-md);
  padding: var(--spacing-xs) var(--spacing-xl);
  display: inline-block;
  width: 100%;
  min-width: 200px;
  &:focus {
    outline: none;
  }
  &:focus-within {
    outline: none;
  }
  cursor: text;
`;

export type SearchInputProps = ComponentPropsWithoutRef<
  typeof StyledSearchInput
> & {
  loading: boolean;
  query: string | null;
  onChange: (value: string) => void;
};

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  function (props, forwardedRef) {
    const { loading, query, onChange } = props;

    let intl = useIntl();

    const inputChange = useCallback(
      (evt: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(evt.target.value);
      },
      [onChange]
    );

    return (
      <Wrapper>
        <SearchInputWrapper>
          {loading ? (
            <AnimatedEllipsisIcon
              size="small"
              className="inputIcon loading"
              style={{
                left: "13px",
                position: "absolute",
                top: "13px",
              }}
            />
          ) : (
            <SearchIcon
              size="small"
              className="inputIcon"
              style={{
                left: "13px",
                position: "absolute",
                top: "13px",
              }}
            />
          )}
          <StyledSearchInput
            id={`SearchInput`}
            contentEditable={true}
            onInput={(el: ChangeEvent<HTMLInputElement>) => {
              inputChange(el);
            }}
            value={query ?? ""}
            ref={forwardedRef}
            placeholder={intl.formatMessage({
              defaultMessage:
                "Search by “keywords”, URL or ID to find content, media and more",
            })}
          ></StyledSearchInput>
        </SearchInputWrapper>
      </Wrapper>
    );
  }
);
SearchInput.displayName = "SearchInput";

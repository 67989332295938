import type {
  Search_search_results as SearchResult,
  GetRecentSearch_recentSearches as RecentSearch,
} from "@types";

export const RESULT_TYPES = {
  query: "Query",
  advancedQuery: "AdvancedQuery",
  recentQuery: "RecentQuery",
  recentSearchResult: "RecentSearchResult",
  searchResult: "ContentSummary",
} as const;

type Filter = {
  filterName: "types";
  value: string;
};

export type QueryItem = {
  __typename: "Query";
  query: string;
};

export type AdvancedQuery = {
  __typename: "AdvancedQuery";
  query: string;
  filters: Filter[];
  name: string;
};

export type RecentSearchCandidate = SearchResult | RecentSearch | QueryItem;

export type MainSearchItem = RecentSearchCandidate | AdvancedQuery;

import { Button, Input } from "@components";
import { SearchIcon, BackIcon } from "@condenast/gemini/icons";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { AriaAttributes, ChangeEvent, useCallback } from "react";

const TitleGroup = styled.div`
  grid-area: title;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--spacing-xxs);
`;

const SearchTitle = styled.h2`
  font: ${(props) => props.theme.FontPageHeading};
`;

const CountLabel = styled.label`
  grid-area: counter;
  display: grid;
  align-items: center;
`;

const SearchContainer = styled.div`
  grid-area: search-bar;
  position: relative;
  color: var(--color-white);
  svg {
    pointer-events: none;
    position: absolute;
    left: calc(var(--spacing-xs) * 1.5);
    top: calc(var(--spacing-xs) * 1.5);
  }
  ${Input} {
    background-color: ${(props) => props.theme.BorderlessActiveBackground};
    padding: ${(props) => props.theme.SecondaryPaddingWithLeftIcon};
    &::placeholder {
      opacity: 1;
      color: ${(props) => props.theme.PlaceholderColor};
    }
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button {
      display: none;
    }
  }
`;

const SearchField = (
  props: {
    value: string;
    onChange: (query: string) => void;
    placeholder: string;
  } & AriaAttributes
) => {
  let { onChange, value, ...forwardProps } = props;
  const change = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <SearchContainer>
      <SearchIcon size="small" role="presentation" />
      <Input value={value} type="search" onChange={change} {...forwardProps} />
    </SearchContainer>
  );
};

type AssetSelectorHeaderProps = {
  onClose: () => unknown;
  onQueryChange: (query: string) => void;
  query: string;
  count: number;
};

export const AssetSelectorHeader = (props: AssetSelectorHeaderProps) => {
  let { onClose, query, count, onQueryChange } = props;
  let intl = useIntl();

  const close = useCallback(
    (evt) => {
      evt.preventDefault();
      onClose();
    },
    [onClose]
  );

  return (
    <>
      <TitleGroup>
        <Button
          onClick={close}
          aria-label="Go Back"
          tabIndex={-1}
          aria-hidden="true"
        >
          <BackIcon size="regular" />
        </Button>
        <SearchTitle>
          {intl.formatMessage({
            defaultMessage: "Search",
            description: "Title for asset selector search panel",
          })}
        </SearchTitle>
      </TitleGroup>
      <SearchField
        aria-label={intl.formatMessage({
          defaultMessage: "Search",
        })}
        placeholder={intl.formatMessage({
          defaultMessage: "Search",
        })}
        value={query}
        onChange={onQueryChange}
      />
      <CountLabel>
        <FormattedMessage
          defaultMessage="{count, plural, =0 {No results} one {{count} result} other {{count, number} results}}"
          values={{ count }}
        />
      </CountLabel>
    </>
  );
};

import {
  ErrorMessage,
  ValidationError as XCValidationError,
} from "@condenast/cross-check";
import type { FormFor_content } from "@types";
import type {
  ValidationEnvironment,
  ValidationContext,
  ValidatorFunction,
} from "@lib";
import { brandedContentValidations, contentValidations } from ".";
import { fieldInPath } from "@lib";

type ValidationError<T> = { path: readonly string[] } & T;

export function errorsForField<T>(
  errors: ValidationError<T>[],
  field: string,
  activeErrorPath?: readonly string[]
): (ValidationError<T> & { isActive?: boolean })[] {
  let filteredErrors = errors.filter((error) => fieldInPath(field, error.path));
  return activeErrorPath?.join(".") === field
    ? filteredErrors.map((error) => ({
        ...error,
        isActive: true,
      }))
    : filteredErrors;
}

/**
 * The definitions of `id`, `value`, `onChange`, and `errors` all have sensible defaults in terms of the given `field`
 * so this helper provides those.
 * @param user - the user data the form is modifying
 * @param errors - the possibly-empty list of validation errors
 * @param setter - a function to update the user data, like the one returned by useChangeset
 * @returns - a function that takes a string key of the user object and returns sensible props
 *            for a form component controlling that field
 */
export function formPropsGetter<T, F extends string & keyof T>(
  data: T,
  errors: XCValidationError[],
  setter: (key: F, value: T[F]) => void,
  translateFieldName: (fieldName: string) => string,
  translateFieldError: (errors: ErrorMessage) => {
    name: string;
    message: string;
  }
) {
  return <LF extends F>(field: LF) => ({
    id: field,
    value: data[field],
    onChange: (value: T[LF]) => setter(field, value),
    errors: errorsForField(errors, field).map((error) => ({
      ...error,
      message: translateFieldError(error.message),
    })),
    label: translateFieldName(field),
  });
}

export function getContentValidator(
  contentType: string,
  brand: string,
  content: FormFor_content,
  env: ValidationEnvironment
) {
  if (contentType in contentValidations) {
    let coreValidator = contentValidations[
      contentType as keyof typeof contentValidations
    ] as ValidatorFunction;

    let brandedContentValidators =
      brandedContentValidations[
        brand as keyof typeof brandedContentValidations
      ];
    let brandedContentValidator = brandedContentValidators
      ? (brandedContentValidators[
          contentType as keyof typeof brandedContentValidators
        ] as ValidatorFunction)
      : null;

    let validator = brandedContentValidator ?? coreValidator;

    return (validationContext: ValidationContext) =>
      validator(content, validationContext, env);
  } else {
    return undefined;
  }
}

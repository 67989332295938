import { RadioButtonGroup as RadioGroupImplementation } from "@components";
import { useDefinedMessages } from "@hooks";
import {
  ControlProps,
  getPreviewForm_form_controls_RadioButtonGroupFormControl,
} from "@types";
import { get } from "@lib";
import styled from "styled-components";
import {
  VersoSplitLeftIcon,
  VersoSplitRightIcon,
} from "@condenast/gemini/icons";
import { Placement } from "@popperjs/core";

const FieldWrapper = styled.div`
  margin-block-start: var(--spacing-md);
`;

const Header = styled.h1`
  font: var(--font-small-statements);
  padding-bottom: var(--spacing-sm);
`;
const StyledRadioGroup = styled(RadioGroupImplementation)``;

const Icons = {
  VersoSplitLeftIcon,
  VersoSplitRightIcon,
};

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${(props) => props.theme.DividerColor};
  margin-bottom: var(--spacing-sm);
  margin-top: var(--spacing-sm);
`;

export function RadioButtonGroup(
  props: ControlProps<getPreviewForm_form_controls_RadioButtonGroupFormControl>
) {
  const {
    name,
    setValue,
    textPositionOptions,
    model,
    optionsPath,
    tooltipPlacement,
  } = props;
  const { translateFieldName, translateSelectOption } = useDefinedMessages();
  const value = (get(model, name) as string) ?? "";
  const translatedLabel = translateFieldName(name);

  const transformedOptions = textPositionOptions.options.map((option) => {
    return {
      label: translateSelectOption(optionsPath ?? "", option.value),
      value: option.value,
      iconName: option.iconName as keyof typeof Icons,
    };
  });

  return (
    <FieldWrapper>
      <Header>{translatedLabel}</Header>
      <StyledRadioGroup
        aria-label={translatedLabel}
        value={value}
        onChange={(value: string | undefined) => setValue(name, value)}
        options={transformedOptions}
        noLabel={true}
        tooltipPlacement={tooltipPlacement as Placement}
      />
      <Divider />
    </FieldWrapper>
  );
}
RadioButtonGroup.displayName = "Control(RadioButtonGroup)";

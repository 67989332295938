import styled from "styled-components";
import type {
  PageLayoutCategory,
  AspectRatioSelectState,
  ToggleMap,
  ImageUrlSelectState,
} from "@types";
import { categoryPreview } from "./assets";
import { NoPreviewImageError } from "./PageLayoutPreview.errors";
import { AspectRatioSelect } from "../AspectRatioSelect";
import { PreviewViewPort } from "./PreviewViewPort";
import { formatAspectRatioOptions } from "../PageLayoutSelector.utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: calc(var(--spacing-xxs) * 2);
  gap: calc(var(--spacing-xxs) * 2);
  background-color: ${(props) => props.theme.SearchImageBackground};
  flex-wrap: wrap;
  justify-content: center;
`;

const PreviewScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
  align-items: center;
  gap: var(--spacing-xs);
`;

const PreviewScreen = styled.div`
  border-radius: ${(props) => props.theme.CornerRadius};
  overflow: hidden;
  background-color: ${(props) => props.theme.Background};
  box-shadow: ${(props) => props.theme.CardShadow};
  box svg {
    display: block;
  }
`;

export const PageLayoutPreview = ({
  currentCategory,
  onChangeAspectRatio,
  aspectRatioOptions,
  aspectRatioValue,
  imageUrl,
  previewProps,
  enableLivePreview,
}: {
  currentCategory: PageLayoutCategory;
  onChangeAspectRatio: (
    breakpoint: string | undefined,
    aspectRatio: string | undefined
  ) => void;
  aspectRatioOptions: Array<{ label: string; value: string }>;
  aspectRatioValue: AspectRatioSelectState;
  imageUrl: ImageUrlSelectState;
  previewProps: ToggleMap;
  enableLivePreview: boolean;
}) => {
  const categorySlug = currentCategory.slug;
  const PreviewDesktop = categoryPreview[categorySlug]["wide"];
  const PreviewMobile = categoryPreview[categorySlug]["narrow"];

  if (!PreviewDesktop) {
    throw new NoPreviewImageError(categorySlug, "wide");
  }

  if (!PreviewMobile) {
    throw new NoPreviewImageError(categorySlug, "narrow");
  }

  return (
    <Wrapper>
      <PreviewScreenWrapper>
        {enableLivePreview ? (
          <PreviewViewPort
            isDesktop={false}
            previewProps={previewProps}
            imageUrl={imageUrl.sm}
          />
        ) : (
          <PreviewScreen>
            <PreviewMobile size="regular" />
          </PreviewScreen>
        )}
        <AspectRatioSelect
          label="aspectRatioMobile"
          options={formatAspectRatioOptions(aspectRatioOptions)}
          onChange={onChangeAspectRatio}
          value={aspectRatioValue?.sm}
        />
      </PreviewScreenWrapper>
      <PreviewScreenWrapper>
        {enableLivePreview ? (
          <PreviewViewPort
            isDesktop={true}
            previewProps={previewProps}
            imageUrl={imageUrl.lg}
          />
        ) : (
          <PreviewScreen>
            <PreviewDesktop size="regular" />
          </PreviewScreen>
        )}
        <AspectRatioSelect
          label="aspectRatioDesktop"
          options={formatAspectRatioOptions(aspectRatioOptions)}
          onChange={onChangeAspectRatio}
          value={aspectRatioValue?.lg}
        />
      </PreviewScreenWrapper>
    </Wrapper>
  );
};

PageLayoutPreview.displayName = "PageLayoutPreview";

import { Field, SearchMultiselect } from "@components";
import { useIntl } from "react-intl";
import { useCallback, useState } from "react";
import { GetUsersVariables, GetCurrentUser_currentUser } from "@types";
import { useQuery } from "@apollo/client";
import { Queries } from "@gql";
import styled from "styled-components";

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-xxs) 0;
  grid-template-rows: auto auto auto;
  height: fit-content;
  grid-template-areas:
    "label"
    "control"
    "."
    "message";
`;

function usersToOption(
  users: GetCurrentUser_currentUser & { disabled?: boolean }
): MultiSelect {
  return {
    key: users.id,
    value: users,
    label: users.firstName + " " + users.lastName,
    disabled: false,
  };
}

type MultiSelect = {
  key: string;
  value: GetCurrentUser_currentUser;
  label: string;
  disabled?: boolean;
};

function filterCollaborator(
  data: GetCurrentUser_currentUser[],
  variables: {
    query?: string;
  }
) {
  let users = [...data];
  if (variables.query) {
    let fullQuery = variables.query.toLocaleLowerCase().trim();
    let queryTerms = fullQuery.split(/\s+/g);
    users = users.filter((user) => {
      let nameParts = [
        ...user.firstName.toLocaleLowerCase().split(/\s+/g),
        ...user.lastName.toLocaleLowerCase().split(/\s+/g),
      ];

      let allQueryTermsMatchSomePart = queryTerms.every((query) =>
        nameParts.some((part) => part.indexOf(query) === 0)
      );

      let emailSubstringMatch =
        user.email.toLocaleLowerCase().indexOf(fullQuery) !== -1;

      return allQueryTermsMatchSomePart || emailSubstringMatch;
    });
  }
  return users;
}

export const CollaboratorSelector = (props: {
  setFilters?: (
    selected: Array<string>,
    type: string,
    filtersToStore: MultiSelect[]
  ) => void;
  preSelectedCollaborators: MultiSelect[];
  organizationId: string;
  currentUserId: string;
}) => {
  let intl = useIntl();
  const { setFilters, preSelectedCollaborators, organizationId } = props;

  const { data: userData, loading: loadingUserData } = useQuery<
    { users: GetCurrentUser_currentUser[] },
    GetUsersVariables
  >(Queries.GET_USERS, {
    variables: {
      organizationId,
    },
  });

  const [userResults, setUserResults] = useState([] as MultiSelect[]);

  const preformUserSearch = useCallback(
    (query: string) => {
      if (!query) return;
      let users: MultiSelect[] =
        filterCollaborator(userData?.users || [], {
          query: query,
        }).map((user) => usersToOption(user)) || [];
      setUserResults(users);
    },
    [setUserResults, userData]
  );

  const onChangeCollab = useCallback(
    (selected: GetCurrentUser_currentUser[]) => {
      let users = selected.map((user) => usersToOption(user));
      setFilters &&
        setFilters(
          selected.map((d: GetCurrentUser_currentUser) => d.id),
          "privilege_user",
          users
        );
    },
    [setFilters]
  );

  return (
    <Wrapper id={"collaborators"} label={"Collaborators"}>
      <SearchMultiselect
        label={intl.formatMessage({
          defaultMessage: "Collaborators",
          description: "Filter Collaborators label",
        })}
        selections={preSelectedCollaborators}
        loading={loadingUserData}
        search={preformUserSearch}
        searchResults={userResults}
        onChange={onChangeCollab}
        multiple={true}
      />
    </Wrapper>
  );
};

CollaboratorSelector.displayName = "CollaboratorSelector";

import { run } from "@condenast/copilot-seo-rules";
import {
  ContentConnectionFields,
  ContentSummaryFields,
  FormFor_content,
  FormFor_content_Gallery,
  GetBrand_brandConfiguration_seo,
  Organization,
  SeoFields_seo,
  SEOPayload,
  isSEOScorable,
} from "@types";

function isGallery(model: isSEOScorable): model is FormFor_content_Gallery {
  return model.__typename === "Gallery";
}

function getItemPayload(model: isSEOScorable) {
  if (isGallery(model)) {
    return (
      model.items?.edges.map((edge) => {
        const node = edge?.node as ContentSummaryFields | undefined;
        return {
          body: edge?.body,
          caption: edge?.caption,
          embedded: node && {
            caption: node.caption?.content,
            description: node.description,
          },
        };
      }) ?? []
    );
  }
  return [];
}

function getPhotosLedePayload(model: isSEOScorable) {
  return (
    (model.photosLede as ContentConnectionFields)?.edges?.map((edge) => {
      const photo = (edge?.node ?? null) as ContentSummaryFields | null;
      return photo
        ? {
            id: photo.id,
            altText: photo.asset?.altText ?? "",
          }
        : null;
    }) ?? []
  );
}

export function getPayloadFromModel(
  model: isSEOScorable,
  currentOrganization: Organization,
  seo?: GetBrand_brandConfiguration_seo
) {
  let payload: SEOPayload = {
    ...model,
    body: model.body?.content,
    brand: currentOrganization.metadata.copilotCode,
    type: model.__typename,
    lang: model.lang ?? "",
    brandHostUrl: currentOrganization.url,
    items: getItemPayload(model),
    photosLede: getPhotosLedePayload(model),
    seo: {
      keyword: model.seo?.keyword ?? "",
      category: model.seo?.category ?? "",
    },
    seoLocale: seo?.semRushLocale ?? model.lang ?? "",
    seoTitle: model.seo?.title ?? "",
    seoDescription: model.seo?.description ?? "",
    seoKeyword: model.seo?.keyword ?? "",
    seoCategory: model.seo?.category ?? "",
  };
  return payload;
}

export async function getSEOScore(
  model: isSEOScorable,
  currentOrganization: Organization,
  seo?: GetBrand_brandConfiguration_seo
) {
  let payload = getPayloadFromModel(model, currentOrganization, seo);
  let brandRules = seo?.rulesets ?? undefined;
  return await run(payload, brandRules);
}

export function setSEOField(
  prevSEO: SeoFields_seo | undefined | null,
  newValues: Partial<SeoFields_seo>
): SeoFields_seo {
  return Object.assign(
    {
      __typename: "SEO",
      title: null,
      category: null,
      description: null,
      keyword: null,
      score: null,
    },
    prevSEO,
    newValues
  );
}

export function hasSEO(model: FormFor_content): model is isSEOScorable {
  return model.__typename == "Article" || model.__typename == "Gallery";
}

import {
  useState,
  FC,
  ComponentPropsWithoutRef,
  useCallback,
  ChangeEvent,
  MouseEvent,
} from "react";
import { Field, Input, Button } from "@components";
import { HideIcon, PreviewIcon as ShowIcon } from "@condenast/gemini/icons";
import styled from "styled-components";
import { useIntl, defineMessages } from "react-intl";

const TOGGLE_BUTTON_LABEL = defineMessages({
  hide: {
    defaultMessage: "Hide password",
    description:
      "button to change a text field to show a series of dots instead of the plain text",
  },
  show: {
    defaultMessage: "Show password",
    description:
      "button to change a password field to show plain text instead of a series of dots",
  },
});

const PasswordInput = styled(Input)`
  // gotta make room for the icon button, so long text doesn't run under it
  //                  icon +  button padding, 2 sides + desired spacing
  padding-right: calc(16px + (var(--spacing-xxs) * 2) + var(--spacing-xxs));
`;

const Wrapper = styled(Field)`
  position: relative;
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";

  ${PasswordInput} + button {
    // put the button on the right side, vertically centered in the input field
    position: absolute;
    // height of a Label + icon bttn padding  + small icon height / 2
    top: calc(var(--spacing-md) + var(--spacing-xxs) + (16px / 2));
    // icon bttn padding  + desired inset
    right: calc(var(--spacing-xxs) + var(--spacing-xxs));
  }
`;

const not = (x: boolean) => !x;

export const PasswordField: FC<
  {
    value: string;
    onChange: (value: string) => void;
  } & Omit<ComponentPropsWithoutRef<typeof Field>, "children"> &
    Omit<ComponentPropsWithoutRef<typeof Input>, "type">
> = (props) => {
  let [hidePassword, setHidePassword] = useState(true);
  let intl = useIntl();
  let { onChange, ...forwardProps } = props;

  let change = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      onChange(evt.target.value);
    },
    [onChange]
  );

  // fully memoize the toggle function so we don't need to rebuild it on every keystroke
  let toggleHide = useCallback((evt: MouseEvent<HTMLButtonElement>) => {
    setHidePassword(not);
    evt.preventDefault();
  }, []);

  return (
    <Wrapper {...forwardProps}>
      <PasswordInput
        {...forwardProps}
        type={hidePassword ? "password" : "text"}
        aria-invalid={!!forwardProps.errors?.length}
        onChange={change}
      />
      <Button
        type="button"
        size="small"
        aria-label={intl.formatMessage(
          TOGGLE_BUTTON_LABEL[hidePassword ? "show" : "hide"]
        )}
        onClick={toggleHide}
      >
        {hidePassword ? <ShowIcon size="small" /> : <HideIcon size="small" />}
      </Button>
    </Wrapper>
  );
};
PasswordField.displayName = "PasswordField";

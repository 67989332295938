import styled from "styled-components";

export const Row = styled.div<{ children: unknown[] }>`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  & > * {
    flex: 1;
  }

  section {
    min-width: 320px;
  }

  @media (max-width: 599px) {
    gap: 0.5rem;
  }
`;

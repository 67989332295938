import { CreateVenue_content_Venue_address, VenueAddressInput } from "@types";

export function venueAddress(
  data: CreateVenue_content_Venue_address
): VenueAddressInput {
  let {
    city,
    country,
    locality,
    postalCode,
    region,
    state,
    street,
    streetExtended,
    neighborhood,
  } = data;

  return {
    city,
    country,
    locality,
    postalCode,
    region,
    state,
    street,
    streetExtended,
    neighborhood,
  };
}

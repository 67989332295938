import { useMemo, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { ContainerConfigTakeoverProps } from "../types";
import { useChangeset, useDefinedMessages } from "@hooks";
import { Button, Label, Select, Takeover, Toggle } from "@components";
import { ReloadIcon } from "@condenast/gemini/icons";
import { isLegacyBundleContainerConfig } from "../utils";
import { TakeoverHeaderActions, ButtonGroup } from "./";

import uuid from "uuid-random";

const ContainerConfigTakeoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;

const FieldToggleWarning = styled.div`
  font: ${(props) => props.theme.FontStatement};
`;

const FieldTogglesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  & > * {
    width: fit-content;
    padding: var(--spacing-xs);
    border: 1px solid ${(props) => props.theme.DividerColor};
    border-radius: var(--spacing-xxs);
    grid-gap: 0 var(--spacing-xs);
    grid-template-areas: "control label";
  }
`;

const ContainerConfigTakeover: React.FC<ContainerConfigTakeoverProps> = ({
  containerData,
  configs,
  updateContainerByIndex,
  containerIndex,
  onClose,
  toggleField,
  fieldStates,
  resetToInitial: resetToInitialFieldToggles,
  hasChanges: hasFieldToggleChanges,
}) => {
  const intl = useIntl();
  const { translateFieldName } = useDefinedMessages();

  const [changeset, setValue, { hasChanges, changes, resetChanges }] =
    useChangeset(containerData);

  const containerType = useMemo(
    () => containerData?.curationContainerType,
    [containerData?.curationContainerType]
  );

  const legacyBundleContainerConfigs = useMemo(() => {
    return configs?.containers?.filter(isLegacyBundleContainerConfig) || [];
  }, [configs?.containers]);

  const containerConfig = useMemo(
    () =>
      legacyBundleContainerConfigs?.find(
        (legacyContainerConfig) => legacyContainerConfig.value === containerType
      ),
    [legacyBundleContainerConfigs, containerType]
  );

  const resetAllChanges = useCallback(() => {
    resetChanges();
    resetToInitialFieldToggles();
  }, [resetChanges, resetToInitialFieldToggles]);

  const applyContainerConfigChanges = useCallback(() => {
    const updatedContainerData = {
      ...containerData,
      ...changes,
    };
    updateContainerByIndex(updatedContainerData, containerIndex);
    onClose();
  }, [containerData, changes, updateContainerByIndex, containerIndex, onClose]);

  const containerLayoutOptions = useMemo(() => {
    return containerConfig?.layoutOptions?.map((option) => ({
      label: option,
      value: option,
    }));
  }, [containerConfig?.layoutOptions]);

  const containerTypeOptions = useMemo(() => {
    return legacyBundleContainerConfigs
      ?.filter(
        (legacyBundleContainerConfig) =>
          legacyBundleContainerConfig.curationType ===
          containerConfig?.curationType
      )
      ?.map(({ value, label }) => ({
        label,
        value,
      }));
  }, [containerConfig?.curationType, legacyBundleContainerConfigs]);

  return (
    <Takeover.TakeoverWrapper
      header={
        <TakeoverHeaderActions>
          <FormattedMessage defaultMessage="Customize" />
          <ButtonGroup>
            <Button
              disabled={!hasChanges && !hasFieldToggleChanges}
              onClick={resetAllChanges}
            >
              <ReloadIcon size="regular" />
              <FormattedMessage defaultMessage="Reset" />
            </Button>
            <Button
              treatment="primary"
              disabled={!hasChanges && !hasFieldToggleChanges}
              onClick={applyContainerConfigChanges}
            >
              <FormattedMessage defaultMessage="Apply" />
            </Button>
          </ButtonGroup>
        </TakeoverHeaderActions>
      }
      body={
        <ContainerConfigTakeoverWrapper>
          <FieldToggleWarning>
            <FormattedMessage defaultMessage="Please note that removing a field or using a layout with a smaller capacity than the current one will remove existing content" />
          </FieldToggleWarning>
          <Label>
            <FormattedMessage defaultMessage={"Fields"} />
          </Label>
          <FieldTogglesWrapper>
            <Toggle
              id={`hed-${uuid()}`}
              label={<FormattedMessage defaultMessage="Title" />}
              onChange={(value) => {
                if (!value) {
                  setValue("hed", "");
                }
                toggleField("hed", value);
              }}
              value={fieldStates.hed}
            />
            <Toggle
              id={`dek-${uuid()}`}
              label={<FormattedMessage defaultMessage="Introduction" />}
              onChange={(value) => {
                if (!value) {
                  setValue("dek", "");
                }
                toggleField("dek", value);
              }}
              value={fieldStates.dek}
            />
            <Toggle
              id={`containerAsset-${uuid()}`}
              label={<FormattedMessage defaultMessage="Image" />}
              onChange={(value) => {
                if (!value) {
                  setValue("containerAsset", null);
                }
                toggleField("containerAsset", value);
              }}
              value={fieldStates.containerAsset}
            />
          </FieldTogglesWrapper>

          <Select
            id="container-type"
            disabled={!containerTypeOptions.length}
            label={translateFieldName("Container Type")}
            placeholder={intl.formatMessage({
              defaultMessage: "Select Container Type",
            })}
            options={containerTypeOptions || []}
            value={changeset.curationContainerType || ""}
            onChange={(value) => setValue("curationContainerType", value || "")}
          />
          <Select
            id="layout-option"
            disabled={!containerLayoutOptions?.length}
            label={translateFieldName("Layout Options")}
            placeholder={
              containerLayoutOptions?.length
                ? intl.formatMessage({
                    defaultMessage: "Select Layout Option",
                  })
                : intl.formatMessage({
                    defaultMessage: "No Layout Options Configured",
                  })
            }
            options={containerLayoutOptions || []}
            value={changeset.layout || ""}
            onChange={(value) => setValue("layout", value || "")}
          />
        </ContainerConfigTakeoverWrapper>
      }
      onClose={onClose}
    />
  );
};

export default ContainerConfigTakeover;

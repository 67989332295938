import {
  ContentConnectionFields,
  ControlProps,
  FormFor_form_controls_CardListFormControl,
  ContentSummary,
  UploadMedia,
  UploadMediaVariables,
  ContentSummaryFields,
  GetAssetSelectorConfig_configs_assetSelectorConfig,
  ContentConnectionFields_edges,
  FormError,
} from "@types";
import { useMutation } from "@apollo/client";
import { Mutations } from "@gql";
import {
  ReactNode,
  useCallback,
  useMemo,
  useState,
  memo,
  Key,
  useEffect,
} from "react";
import { AssetSelector } from "../-private";
import {
  Button,
  Label,
  ThumbnailField,
  UploadButton,
  DraggableList,
} from "@components";
import styled, { css } from "styled-components";
import { useDefinedMessages, useMediaQuery, useToast } from "@hooks";
import {
  serializeContentSummary,
  normalizeContentSummary,
  MEDIA_MAP,
} from "@lib";
import { FormattedMessage, useIntl } from "react-intl";
import { AddIcon } from "@condenast/gemini/icons";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

const Wrapper = styled.div`
  margin-bottom: var(--spacing-sm);
`;

const ListLabel = styled(Label)`
  margin-bottom: var(--spacing-xs);
`;

const ListWrapper = styled.div<{ $treatment?: string }>`
  background: ${(props) => props.theme.Background};
  ${(props) =>
    props.$treatment === "slat"
      ? css`
          display: flex;
          flex-direction: column;
          gap: var(--spacing-xs);
        `
      : css`
          display: grid;
          grid-template-columns: repeat(auto-fit, 15rem);
          grid-gap: var(--spacing-sm);
        `}
`;

const DraggableListWrapper = styled.div<{ $treatment?: string }>`
  ${(props) =>
    props.$treatment === "card" &&
    css`
      @media (min-width: 650px) {
        & > ul {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: var(--spacing-sm);
        }
      }
    `}
`;

const MobileListContainer = styled.div`
  position: relative;
  height: 17rem;
  left: calc(-1 * var(--spacing-sm));
`;

const MobileListWrapper = styled.div`
  position: absolute;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  left: 0;
  z-index: 0;
  width: 100vw;

  & > div {
    padding-right: var(--spacing-sm);
    &:first-child {
      padding-left: var(--spacing-sm);
    }
  }
`;

const AddContentSection = styled.div<{ $empty: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-top: ${(props) => (props.$empty ? "none" : "var(--spacing-sm)")};
`;

const AddButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
  display: inline-flex;
`;

const StyledUploadButton = styled(UploadButton)`
  margin-left: var(--spacing-sm);
`;

const UploadMessage = styled.p`
  font: var(--font-small-statements);
  margin-left: auto;
`;

function List(props: { children: ReactNode; treatment?: string }) {
  let isTouchDevice = useMediaQuery("(hover: none), (hover: on-demand)");
  if (isTouchDevice) {
    return (
      <MobileListContainer>
        <MobileListWrapper>{props.children}</MobileListWrapper>
      </MobileListContainer>
    );
  } else {
    return (
      <ListWrapper $treatment={props.treatment}>{props.children}</ListWrapper>
    );
  }
}

const ThumbnailList = memo(
  (props: {
    slides: (ContentConnectionFields_edges | null)[];
    errors: FormError[];
    onAssetSelect: (selectedIndex: number) => void;
    removeAsset: (selectedIndex: number) => void;
    cdnHost: string;
    treatment?: string;
    updateDrag: (assets?: (ContentConnectionFields_edges | null)[]) => void;
  }) => {
    const {
      cdnHost,
      errors,
      slides,
      onAssetSelect,
      removeAsset,
      treatment,
      updateDrag,
    } = props;

    const [slideOptions, setSlideOptions] =
      useState<(ContentConnectionFields_edges | null)[]>(slides);

    const { translateContentType } = useDefinedMessages();

    const moveItem = useCallback(
      (
        data: {
          index: number;
        },
        dropIndex: number
      ) => {
        if (data.index !== dropIndex) {
          let itemsToMove = [...slideOptions];
          let sourceElement = itemsToMove[data.index];
          itemsToMove.splice(data.index, 1);
          itemsToMove.splice(dropIndex, 0, sourceElement);
          setSlideOptions([...itemsToMove]);
          updateDrag([...itemsToMove]);
        }
      },
      [slideOptions]
    );

    useEffect(() => {
      setSlideOptions(slides);
    }, [slides]);

    const thumbnailItems = useMemo(() => {
      return slideOptions.map((node, index, arr) => {
        const item = node?.node as ContentSummaryFields;
        const normalizedValue = normalizeContentSummary(
          item,
          translateContentType
        );

        const itemID = item.id ?? "blank";
        const count = arr
          .slice(0, index)
          .filter(() => itemID === item?.id ?? "blank").length;
        const key = `${itemID}${count > 0 ? `-${count}` : ""}` as Key;
        return {
          key: key as Key,
          value: { key, value: normalizedValue },
        };
      });
    }, [slideOptions]);

    return (
      <DraggableListWrapper $treatment={treatment}>
        <DraggableList
          items={thumbnailItems}
          onMove={moveItem}
          orientation={treatment === "card" ? "horizontal" : "vertical"}
        >
          {(
            item: {
              key: Key | null | undefined;
              value: ContentSummary | null | undefined;
            },
            index: number
          ) => (
            <ThumbnailField
              key={item.key}
              id={`ThumbnailField__${item.key}`}
              value={item.value}
              cdnHost={cdnHost}
              selectAsset={() => onAssetSelect(index)}
              removeAsset={() => removeAsset(index)}
              errors={errors}
              assetindex={index + 1}
              treatment={treatment}
            />
          )}
        </DraggableList>
      </DraggableListWrapper>
    );
  }
);

ThumbnailList.displayName = "ThumbnailList";

export function CardList(
  props: ControlProps<FormFor_form_controls_CardListFormControl>
) {
  const {
    name,
    labelKey,
    noLabel,
    currentOrganization,
    currentUser,
    model,
    setValue,
    errors,
    limit,
    assetSelectorConfiguration,
    treatment,
  } = props;
  const { translateFieldName } = useDefinedMessages();

  const authorName = `${currentUser.firstName} ${currentUser.lastName}`;
  const [assetSelectorToggle, setAssetSelectorToggle] = useState(false);
  const [insertAtIndex, setInsertAtIndex] = useState<number | null>(null);
  const modelValue = model[name] as ContentConnectionFields | null;

  const intl = useIntl();

  const showRemovedDupesMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "One or more assets was already added",
      description:
        "Notification message shown when adding duplicate items to a list",
    }),
  });

  let assetSelectorConfig = assetSelectorConfiguration
    ? ({
        ...assetSelectorConfiguration,
        limitSelection:
          limit && modelValue ? limit - modelValue.edges.length : null,
      } as GetAssetSelectorConfig_configs_assetSelectorConfig)
    : null;

  const submitAssets = useCallback(
    (assets: ContentSummary[]) => {
      let removedDupes = false;
      const modelValueIds: string[] = [];
      if (modelValue && modelValue.edges) {
        for (const edge of modelValue.edges) {
          if (edge) {
            modelValueIds.push(edge.node?.id as string);
          }
        }
      }
      const newAssets = assets
        .filter((asset) => {
          if (!modelValueIds.includes(asset.id)) {
            return asset;
          }
          removedDupes = true;
          return;
        })
        .map(
          (asset) =>
            ({
              node: serializeContentSummary(asset),
            } as ContentConnectionFields_edges)
        );

      newAssets?.length > 0 &&
        setValue(name, {
          ...modelValue,
          edges:
            modelValue && insertAtIndex != null
              ? modelValue.edges
                  .slice(0, insertAtIndex)
                  .concat(newAssets)
                  .concat(modelValue.edges.slice(insertAtIndex + 1))
              : [...newAssets],
        });

      if (removedDupes) {
        showRemovedDupesMessage();
      }

      setInsertAtIndex(null);
    },
    [modelValue, setValue, name, insertAtIndex, showRemovedDupesMessage]
  );

  const [upload, uploadResult] = useMutation<UploadMedia, UploadMediaVariables>(
    Mutations.UPLOAD_MEDIA
  );
  const uploadTypes = useMemo(
    () => [
      ...(assetSelectorConfiguration?.contentTypes?.includes("photo")
        ? MEDIA_MAP.photos
        : []),
      ...(assetSelectorConfiguration?.contentTypes?.includes("clip")
        ? MEDIA_MAP.clips
        : []),
    ],
    [assetSelectorConfiguration]
  );

  const [, dropRef] = useDrop(
    () => ({
      accept: NativeTypes.FILE,
      canDrop: (item: { files: File[]; dataTransfer: DataTransfer }) => {
        let withinLimit =
          !limit ||
          (modelValue
            ? modelValue.edges?.length + item.files.length <= limit
            : item.files.length <= limit);

        let isCorrectFileType = item.files.every((file) =>
          (uploadTypes as string[])?.includes(file.type)
        );

        let canDrop = !!(
          uploadTypes?.length > 0 &&
          withinLimit &&
          isCorrectFileType
        );

        return canDrop;
      },
      collect: (monitor) => ({
        isHovered: monitor.isOver(),
      }),
      drop(item) {
        upload({
          variables: {
            organizationId: currentOrganization.organizationId,
            authorName,
            data: {
              file: item.files[0],
              fileSize: item.files[0].size,
            },
          },
        }).then((result) => {
          let asset = result.data?.uploadMedia;
          if (asset && modelValue) {
            setValue(name, {
              ...modelValue,
              edges: [...modelValue.edges, { node: asset }],
            });
          }
        });
      },
    }),
    [limit, modelValue, uploadTypes, upload]
  );
  const openAssetSelectorAtIndex = useCallback(
    (index: number) => {
      setInsertAtIndex(index);
      setAssetSelectorToggle(true);
    },
    [setInsertAtIndex, setAssetSelectorToggle]
  );
  const removeAssetAtIndex = useCallback(
    (index: number) =>
      modelValue?.edges
        ? setValue(name, {
            __typename: "ContentConnection",
            edges: [
              ...modelValue.edges.slice(0, index),
              ...modelValue.edges.slice(index + 1),
            ],
          })
        : undefined,
    [modelValue?.edges, setValue]
  );

  const updateDrag = (assets?: (ContentConnectionFields_edges | null)[]) => {
    setValue(name, {
      __typename: "ContentConnection",
      edges: assets || [],
    });

    return null;
  };

  return (
    <Wrapper>
      {!noLabel && (
        <ListLabel>
          {labelKey ? translateFieldName(labelKey) : translateFieldName(name)}
        </ListLabel>
      )}
      <List treatment={treatment ?? undefined}>
        {assetSelectorToggle && assetSelectorConfig && (
          <AssetSelector
            config={assetSelectorConfig}
            currentOrganizationID={currentOrganization.organizationId}
            onSubmit={submitAssets}
            onClose={() => {
              setAssetSelectorToggle(false);
              setInsertAtIndex(null);
            }}
            cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
            currentUser={currentUser}
          />
        )}
        {modelValue?.edges && (
          <ThumbnailList
            slides={modelValue.edges}
            treatment={treatment ?? undefined}
            errors={errors}
            onAssetSelect={openAssetSelectorAtIndex}
            removeAsset={removeAssetAtIndex}
            cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
            updateDrag={updateDrag}
          />
        )}
      </List>
      {(!limit ||
        !modelValue ||
        (modelValue && modelValue.edges?.length < limit)) && (
        <AddContentSection
          $empty={!modelValue || (modelValue && modelValue.edges?.length < 1)}
          ref={dropRef}
        >
          <AddButton
            onClick={() => {
              setInsertAtIndex(modelValue ? modelValue.edges?.length : 0);
              setAssetSelectorToggle(true);
            }}
          >
            <AddIcon size="regular" />
            <FormattedMessage defaultMessage="Add" />
          </AddButton>
          {uploadTypes.length > 1 && (
            <>
              <StyledUploadButton
                onChange={(files) =>
                  upload({
                    variables: {
                      organizationId: currentOrganization.organizationId,
                      authorName,
                      data: {
                        file: files[0],
                        fileSize: files[0].size,
                      },
                    },
                  }).then((result) => {
                    let asset = result.data?.uploadMedia;
                    if (asset && modelValue) {
                      setValue(name, {
                        ...modelValue,
                        edges: [...modelValue.edges, { node: asset }],
                      });
                    }
                  })
                }
                accept={uploadTypes.join(",")}
                disabled={uploadResult.loading}
                multiple={
                  assetSelectorConfiguration?.limitSelection
                    ? assetSelectorConfiguration?.limitSelection > 0
                    : false
                }
              />
              <UploadMessage>
                <FormattedMessage defaultMessage="Drag media here to upload" />
              </UploadMessage>
            </>
          )}
        </AddContentSection>
      )}
    </Wrapper>
  );
}
CardList.displayName = "Control(CardList)";

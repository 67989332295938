import { defineMessages } from "react-intl";

export const CONTENT_TYPES = defineMessages({
  activity: {
    defaultMessage: "{count, plural, =1 {Activity} other {Activities}}",
    description: "contentTypes.activity",
  },

  advertiser: {
    defaultMessage:
      "{count, plural, =1 {Native Advertiser} other {Native Advertisers}}",
    description: "contentTypes.advertiser",
  },

  agency: {
    defaultMessage: "{count, plural, =1 {Agency} other {Agencies}}",
    description: "contentTypes.agency",
  },

  airline: {
    defaultMessage: "{count, plural, =1 {Airline} other {Airlines}}",
    description: "contentTypes.airline",
  },

  airport: {
    defaultMessage: "{count, plural, =1 {Airport} other {Airports}}",
    description: "contentTypes.airport",
  },

  article: {
    defaultMessage: "{count, plural, =1 {Article} other {Articles}}",
    description: "contentTypes.article",
  },

  audio: {
    defaultMessage: "{count, plural, =1 {Audio} other {Audio}}",
    description: "contentTypes.audio",
  },

  audiofile: {
    defaultMessage: "{count, plural, =1 {Audio File} other {Audio Files}}",
    description: "contentTypes.audiofile",
  },

  bar: {
    defaultMessage: "{count, plural, =1 {Bar} other {Bars}}",
    description: "contentTypes.bar",
  },

  brand: {
    defaultMessage: "{count, plural, =1 {Brand} other {Brands}}",
    description: "contentTypes.brand",
  },

  bundle: {
    defaultMessage: "{count, plural, =1 {Bundle} other {Bundles}}",
    description: "contentTypes.bundle",
  },

  business: {
    defaultMessage: "{count, plural, =1 {Business} other {Businesses}}",
    description: "contentTypes.business",
  },

  cartoon: {
    defaultMessage: "{count, plural, =1 {Cartoon} other {Cartoons}}",
    description: "contentTypes.cartoon",
  },

  city: {
    defaultMessage: "{count, plural, =1 {City} other {Cities}}",
    description: "contentTypes.city",
  },

  clip: {
    defaultMessage: "{count, plural, =1 {Clip} other {Clips}}",
    description: "contentTypes.clip",
  },

  channel: {
    defaultMessage: "{count, plural, =1 {Channel} other {Channels}}",
    description: "contentTypes.channel",
  },

  cnevideo: {
    defaultMessage: "{count, plural, =1 {CNE Video} other {CNE Videos}}",
    description: "contentTypes.cnevideo",
  },

  contributor: {
    defaultMessage: "{count, plural, =1 {Contributor} other {Contributors}}",
    description: "contentTypes.contributor",
  },

  commerceproduct: {
    defaultMessage:
      "{count, plural, =1 {Commerce Product} other {Commerce Products}}",
    description: "contentTypes.commerceproduct",
  },

  cookbook: {
    defaultMessage: "{count, plural, =1 {Cookbook} other {Cookbooks}}",
    description: "contentTypes.cookbook",
  },

  curatedlist: {
    defaultMessage: "{count, plural, =1 {Curated List} other {Curated Lists}}",
    description: "contentTypes.cureatedlist",
  },

  curatedsearch: {
    defaultMessage:
      "{count, plural, =1 {Curated Search} other {Curated Searches}}",
    description: "contentTypes.curatedsearch",
  },

  destination: {
    defaultMessage: "{count, plural, =1 {Destination} other {Destinations}}",
    description: "contentTypes.",
  },

  event: {
    defaultMessage: "{count, plural, =1 {Event} other {Events}}",
    description: "contentTypes.",
  },

  external: {
    defaultMessage:
      "{count, plural, =1 {External Reference} other {External References}}",
    description: "contentTypes.external",
  },

  externallink: {
    defaultMessage:
      "{count, plural, =1 {External Link} other {External Links}}",
    description: "contentTypes.externallink",
  },

  fashiongallery: {
    defaultMessage:
      "{count, plural, =1 {Fashion Gallery} other {Fashion Galleries}}",
    description: "contentTypes.fashiongallery",
  },

  fashionshow: {
    defaultMessage: "{count, plural, =1 {Fashion Show} other {Fashion Shows}}",
    description: "contentTypes.fashionshow",
  },

  gallery: {
    defaultMessage: "{count, plural, =1 {Gallery} other {Galleries}}",
    description: "contentTypes.gallery",
  },

  galleryItem: {
    defaultMessage: "{count, plural, =1 {GalleryItem} other {Slideshows}}",
    description: "contentTypes.galleryItem",
  },

  getty: {
    defaultMessage: "{count, plural, =1 {Getty Image} other {Getty Images}}",
    description: "contentTypes.getty",
  },

  gettyvideo: {
    defaultMessage: "{count, plural, =1 {Getty Video} other {Getty Videos}}",
    description: "contentTypes.gettyvideo",
  },

  goldlist: {
    defaultMessage: "{count, plural, =1 {Gold List} other {Gold Lists}}",
    description: "contentTypes.goldlist",
  },

  golfball: {
    defaultMessage: "{count, plural, =1 {Golf Ball} other {Golf Balls}}",
    description: "contentTypes.golfball",
  },

  golfclub: {
    defaultMessage: "{count, plural, =1 {Golf Club} other {Golf Clubs}}",
    description: "contentTypes.golfclub",
  },

  hotel: {
    defaultMessage: "{count, plural, =1 {Hotel} other {Hotels}}",
    description: "contentTypes.hotel",
  },

  hotlist: {
    defaultMessage: "{count, plural, =1 {Hot List} other {Hot Lists}}",
    description: "contentTypes.hotlist",
  },

  house: {
    defaultMessage: "{count, plural, =1 {House} other {Houses}}",
    description: "contentTypes.house",
  },

  infopage: {
    defaultMessage: "{count, plural, =1 {Info Page} other {Info Pages}}",
    description: "contentTypes.infopage",
  },

  list: {
    defaultMessage: "{count, plural, =1 {Curated List} other {Curated Lists}}",
    description: "contentTypes.list",
  },

  livestory: {
    defaultMessage: "{count, plural, =1 {Live Story} other {Live Stories}}",
    description: "contentTypes.livestory",
  },

  menu: {
    defaultMessage: "{count, plural, =1 {Menu} other {Menus}}",
    description: "contentTypes.menu",
  },

  musicalbum: {
    defaultMessage: "{count, plural, =1 {Music Album} other {Music Albums}}",
    description: "contentTypes.musicalbum",
  },

  musicgroup: {
    defaultMessage: "{count, plural, =1 {Music Group} other {Music Groups}}",
    description: "contentTypes.musicgroup",
  },

  musicrecording: {
    defaultMessage:
      "{count, plural, =1 {Music Recording} other {Music Recordings}}",
    description: "contentTypes.musicrecording",
  },

  musicrelease: {
    defaultMessage:
      "{count, plural, =1 {Music Release} other {Music Releases}}",
    description: "contentTypes.musicrelease",
  },

  nativearticle: {
    defaultMessage:
      "{count, plural, =1 {Native Article} other {Native Articles}}",
    description: "contentTypes.nativearticle",
  },

  nativegallery: {
    defaultMessage:
      "{count, plural, =1 {Native Gallery} other {Native Galleries}}",
    description: "contentTypes.nativegallery",
  },

  nativerecipe: {
    defaultMessage:
      "{count, plural, =1 {Native Recipe} other {Native Recipes}}",
    description: "contentTypes.nativerecipe",
  },

  nativevideo: {
    defaultMessage: "{count, plural, =1 {Native Video} other {Native Videos}}",
    description: "contentTypes.nativevideo",
  },

  newsletter: {
    defaultMessage: "{count, plural, =1 {Newsletter} other {Newsletters}}",
    description: "contentTypes.newsletter",
  },

  package: {
    defaultMessage: "{count, plural, =1 {Package} other {Packages}}",
    description: "contentTypes.package",
  },

  person: {
    defaultMessage: "{count, plural, =1 {Person} other {People}}",
    description: "contentTypes.person",
  },

  photo: {
    defaultMessage: "{count, plural, =1 {Photo} other {Photos}}",
    description: "contentTypes.photo",
  },

  product: {
    defaultMessage: "{count, plural, =1 {Product} other {Products}}",
    description: "contentTypes.product",
  },

  pushnotification: {
    defaultMessage:
      "{count, plural, =1 {Push Notification} other {Push Notifications}}",
    description: "contentTypes.pushnotification",
  },

  rcalist: {
    defaultMessage: "{count, plural, =1 {RCA List} other {RCA Lists}}",
    description: "contentTypes.rcalist",
  },

  recipe: {
    defaultMessage: "{count, plural, =1 {Recipe} other {Recipes}}",
    description: "contentTypes.recipe",
  },

  restaurant: {
    defaultMessage: "{count, plural, =1 {Restaurant} other {Restaurants}}",
    description: "contentTypes.restaurant",
  },

  review: {
    defaultMessage: "{count, plural, =1 {Review} other {Reviews}}",
    description: "contentTypes.review",
  },

  runwaymoment: {
    defaultMessage:
      "{count, plural, =1 {Runway Moment} other {Runway Moments}}",
    description: "contentTypes.runawaymoment",
  },

  season: {
    defaultMessage: "{count, plural, =1 {Season} other {Seasons}}",
    description: "contentTypes.season",
  },

  ship: {
    defaultMessage: "{count, plural, =1 {Ship} other {Ships}}",
    description: "contentTypes.ship",
  },

  shop: {
    defaultMessage: "{count, plural, =1 {Shop} other {Shops}}",
    description: "contentTypes.shop",
  },

  skiresort: {
    defaultMessage: "{count, plural, =1 {Ski Resort} other {Ski Resorts}}",
    description: "contentTypes.skiresort",
  },

  spa: {
    defaultMessage: "{count, plural, =1 {Spa} other {Spas}}",
    description: "contentTypes.spa",
  },

  specialreport: {
    defaultMessage:
      "{count, plural, =1 {Special Report} other {Special Reports}}",
    description: "contentTypes.specialreport",
  },

  taxonomy: {
    defaultMessage: "{count, plural, =1 {Taxonomy} other {Taxonomies}}",
    description: "contentTypes.taxonomy",
  },

  venue: {
    defaultMessage: "{count, plural, =1 {Venue} other {Venues}}",
    description: "contentTypes.venue",
  },

  video: {
    defaultMessage: "{count, plural, =1 {Video} other {Videos}}",
    description: "contentTypes.video",
  },

  redirect: {
    defaultMessage: "{count, plural, =1 {Redirect} other {Redirects}}",
    description: "contentTypes.redirect",
  },

  tagpage: {
    defaultMessage: "{count, plural, =1 {Tag Page} other {Tag Pages}}",
    description: "contentTypes.tagpage",
  },
});

import { useCallback, useMemo, useState } from "react";
import { SearchMultiselect } from "@components";
import {
  ControlProps,
  FormFor_form_controls_CollaboratorSelectorFormControl,
  GetUsers,
  GetUsersVariables,
  GetUsers_users,
} from "@types";
import { useQuery } from "@apollo/client";
import { Queries } from "@gql";
import { get } from "@lib";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface UserOption {
  key: string;
  value: User;
  label: string;
}

function userToUserOption(user: User) {
  return {
    key: user.id,
    value: user,
    label: `${user.firstName} ${user.lastName}`,
  };
}

function filterUsers(users: User[], query?: string) {
  if (!query) {
    return [];
  }
  let fullQuery = query.toLocaleLowerCase().trim();
  let queryTerms = fullQuery.split(/\s+/g);
  return users.filter((user) => {
    let nameParts = [
      ...user.firstName.toLocaleLowerCase().split(/\s+/g),
      ...user.lastName.toLocaleLowerCase().split(/\s+/g),
    ];

    let allQueryTermsMatchSomePart = queryTerms.every((query) =>
      nameParts.some((part) => part.indexOf(query) === 0)
    );

    let emailSubstringMatch =
      user.email.toLocaleLowerCase().indexOf(fullQuery) !== -1;

    return allQueryTermsMatchSomePart || emailSubstringMatch;
  });
}

export function CollaboratorSelector(
  props: ControlProps<FormFor_form_controls_CollaboratorSelectorFormControl>
) {
  const { currentOrganization, model, name, setValue } = props;
  const { data: allUsers, loading: loadingUserData } = useQuery<
    GetUsers,
    GetUsersVariables
  >(Queries.GET_USERS, {
    variables: { organizationId: currentOrganization.organizationId },
  });

  const modelValue = useMemo(() => {
    return (get(model, name) ?? []) as string[];
  }, [model, name]);

  const [userResults, setUserResults] = useState<UserOption[]>([]);

  const nonEditableCollaborators = useMemo(() => {
    if (!allUsers) {
      return [];
    }

    return (modelValue ?? []).filter(
      (userId) => allUsers.users?.findIndex((user) => user.id === userId) === -1
    );
  }, [allUsers, modelValue]);

  const collaborators = useMemo(() => {
    if (!allUsers) {
      return [];
    }
    return (modelValue ?? [])
      .map((userId) => allUsers.users?.find((user) => user.id === userId))
      .filter(Boolean) as GetUsers_users[];
  }, [allUsers, modelValue]);

  const updateCollaborators = useCallback(
    (selected: { id: string }[]) => {
      setValue(name, [
        ...nonEditableCollaborators,
        ...selected.map((user) => user.id),
      ]);
    },
    [name, nonEditableCollaborators, setValue]
  );

  const search = useCallback(
    (query) => {
      setUserResults(
        filterUsers(allUsers?.users || [], query).map(userToUserOption)
      );
    },
    [allUsers]
  );

  return (
    <SearchMultiselect
      selections={collaborators.map(userToUserOption)}
      loading={loadingUserData}
      search={search}
      searchResults={userResults}
      onChange={updateCollaborators}
      multiple={true}
    />
  );
}

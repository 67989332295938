import {
  useEffect,
  useMemo,
  Ref,
  ComponentPropsWithoutRef,
  useState,
  useCallback,
  forwardRef,
  useContext,
} from "react";
import styled, { DefaultTheme, css } from "styled-components";
import pluralize from "pluralize";
import {
  BlockEditor,
  EditorConstructor,
  InlineEditor,
  MinimalistEditor,
} from "@condenast/ckeditor5-build-condenast";
import type { EditorInstance } from "@condenast/ckeditor5-build-condenast";
import { ThemeProvider } from "@contexts";
import Renderer from "@condenast/atjson-renderer-ckeditor";
import VersoSource from "@condenast/atjson-source-verso";
import { get, memo, propagateRef } from "@lib";
import {
  ContentSummary,
  CKEditorConfiguration,
  Search_search_results_metadata_ContentSummaryCNEVideo as ContentSummaryCNEVideo,
} from "@types";
import { WandIcon } from "@condenast/gemini/icons";
import { Button, PrismXMLImportButton } from "@components";
import { FormattedMessage } from "react-intl";
import { SnowplowContext } from "@contexts";
import { Skeleton } from "@condenast/gemini";

const EditorWrapper = styled.div<{
  $autogenerated: boolean;
  $padRightRail: boolean;
  $build: string;
}>`
  position: relative;
  grid-area: control;

  & .ck.ck-content {
    transition: none;
    background: ${(props) => props.theme.FieldBackground};
    border-radius: ${(props) => props.theme.CornerRadius};
    box-shadow: ${(props) => props.theme.FieldRing};
    color: ${(props) => props.theme.Color};
    word-break: break-word;
    border: none;
    animation: none;
    transition: none;
  }
  & .ck.ck-content:not(.body) {
    padding: ${(props) =>
      props.$padRightRail
        ? `${props.theme.SecondaryPaddingWithRightIcon}`
        : props.theme.SecondaryPadding};
    ${(props) =>
      props.$build === "block"
        ? css`
            padding-block-start: 0;
            padding-block-end: 0;
          `
        : ""}
  }
  & .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-blurred {
    box-shadow: ${(props) => props.theme.FieldRing};
    border: none;
    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorRing};
    }
  }
  & .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-blurred:hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing};
    border: none;
    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorHoverRing};
    }
  }
  & .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border: none;
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorFocusRing};
    }
  }
  i {
    font-style: italic;
  }
  strong {
    font-weight: 600;
  }
  ol,
  ul {
    list-style: revert;
    padding-inline-start: var(--spacing-sm);
    margin-block-start: var(--spacing-md);
    margin-block-end: var(--spacing-md);
  }
`;

const EditorDiv = styled.div`
  display: block;
  &.hide {
    display: none;
  }
`;

const EditorFooterWrapper = styled.div`
  grid-area: message;
  display: flex;
  justify-content: space-between;
`;

const EditorFooterGroupWrapper = styled.div`
  display: flex;
  column-gap: var(--spacing-xs);
`;

const AutogenButton = styled(Button)<{ $autogenerated?: boolean }>`
  position: absolute;
  top: var(--spacing-xxs);
  right: var(--spacing-xs);
  background: ${(props) => props.theme.AutofillButtonBackgroundColor};
  color: ${(props) => props.theme.AutofillButtonColor};

  &:not(:disabled):hover {
    background: ${(props) => props.theme.AutofillButtonHoverBackground};
  }
`;
const AutoLinkingButton = styled(Button)`
  display: inline-flex;
  align-items: center;

  background: ${(props) => props.theme.AutofillButtonBackgroundColor};
  color: ${(props) => props.theme.AutofillButtonColor};

  &:not(:disabled):hover {
    background: ${(props) => props.theme.AutofillButtonHoverBackground};
  }
`;

const StyledWordCount = styled.div<{
  $autolinking: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.SupportColor};
`;

const EditorSkeleton = styled.div`
  transition: none;
  background: ${(props) => props.theme.FieldBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) => props.theme.Color};
  border: none;
  animation: none;
`;

const InlineTextBoxSkeleton = styled.div`
  padding: ${(props) => props.theme.SecondaryPadding};
`;

const BlockTextBoxSkeleton = styled.div`
  // 0.6em * 1.5 is the size of the ckeditor padding for text in block editors
  padding: calc(0.6em * 1.5) var(--spacing-sm);
`;

const BodyTextBoxSkeleton = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 90%;
  // 44 rem is the max-width of the text inside the body editor in ckeditor
  max-width: 44rem;
  // 28 rem is the minimum body editor height in ckeditor
  height: 28rem;
  // 0.6em * 1.5 is the size of the ckeditor padding for text in block editors
  // 3 rem is additional padding ckeditor uses for the body editor specifically
  padding-top: calc(3rem + 0.6em * 1.5);
`;

const ShortMockText = styled(Skeleton)`
  width: 80%;
  height: var(--spacing-sm);
  margin: calc(var(--spacing-sm) * 0.25) 0;
`;

const LongMockText = styled(Skeleton)`
  height: var(--spacing-sm);
  margin: calc(var(--spacing-sm) * 0.25) 0;
`;

const Builds = {
  block: BlockEditor,
  inline: InlineEditor,
  minimalist: MinimalistEditor,
};

const READONLY_LOCK_ID = "readonly-lock";

function buildConfiguration(
  Build: EditorConstructor,
  configuration: CKEditorConfiguration,
  placeholderText?: string
) {
  let { cdnHost, host, plugins, include, ...config } = configuration;

  let photoConfig = { ...plugins?.photo };
  if (include && !include.includes("Link")) {
    photoConfig.enableLinking = false;
  }

  const pluginList = include
    ? [
        ...(Build.requiredPlugins ?? []),
        ...(Build.optionalPlugins ?? []).filter((plugin) =>
          include?.includes(plugin.pluginName)
        ),
      ]
    : [...(Build.requiredPlugins ?? []), ...(Build.optionalPlugins ?? [])];
  return {
    consumerHostname: host,
    mediaHostname: cdnHost,
    plugins: pluginList,
    ...plugins,
    photo: photoConfig,
    ...config,
    placeholder: placeholderText,
  };
}

function autofilledTheme(
  theme: DefaultTheme,
  purpleify: (css: string) => string,
  doTransform?: boolean
) {
  let {
    FieldRing,
    FieldActiveRing,
    FieldFocusRing,
    FieldHoverRing,
    FocusRing,
  } = theme;

  return doTransform
    ? {
        ...theme,
        AutofillButtonBackgroundColor: "var(--color-purple-50)",
        AutofillButtonColor: "var(--color-white)",
        AutofillButtonHoverBackground: "var(--color-purple-50)",
        FieldRing: purpleify(FieldRing),
        FieldActiveRing: purpleify(FieldActiveRing),
        FieldFocusRing: purpleify(FieldFocusRing),
        FieldHoverRing: purpleify(FieldHoverRing),
        FocusRing: purpleify(FocusRing),
      }
    : theme;
}

function _getEmbedData(asset: ContentSummary) {
  switch (asset.contentType) {
    case undefined:
    case null:
    case "cnevideo": {
      let cneVideoMetadata = asset.metadata?.find(
        (metadataItem) => metadataItem.type === "ContentSummaryCNEVideo"
      ) as ContentSummaryCNEVideo | undefined;
      if (cneVideoMetadata && cneVideoMetadata.scriptEmbedUrl) {
        let url = cneVideoMetadata?.scriptEmbedUrl?.startsWith("//")
          ? new URL(window.location.protocol + cneVideoMetadata.scriptEmbedUrl)
          : new URL(cneVideoMetadata?.scriptEmbedUrl || "");
        return {
          embedType: "cne-embed",
          uri: url.pathname.substring(1),
        };
      }
      return {
        embedType: "cne-embed",
      };
    }
    case "clip":
      return {
        embedType: "clip",
        copilotid: asset.id,
      };
    case "photo":
      return {
        embedType: "photo",
        copilotid: asset.id,
      };
    case "product":
      return {
        embedType: "product",
        copilotid: asset.id,
      };
    case "gallery":
      return {
        embedType: "gallery",
        copilotid: asset.id,
      };
    case "contentreference":
      return {
        embedType: "contentreference",
        referenceType: "product",
        copilotid: asset.id,
        uri: `/${pluralize(asset.contentType)}/${asset.id}`,
      };
    default:
      return {
        assetType: asset.contentType,
        copilotid: asset.id,
        copilottype: asset.contentType,
        embedType: "copilot-embed",
        uri: `/${pluralize(asset.contentType)}/${asset.id}`,
      };
  }
}

export const CKEditor = memo(
  forwardRef(function CKEditor(
    props: ComponentPropsWithoutRef<"div"> & {
      id: string;
      className?: string;
      value: VersoSource;
      onChange: (value: VersoSource) => unknown;
      onBodyChange?: () => void;
      reorderAssets?:
        | ((opts?: {
            data?: { type: string; id: string }[];
          }) => Promise<ContentSummary[] | null>)
        | null;
      selectAssets?:
        | ((opts?: {
            types?: string[];
            limitSelection?: number;
          }) => Promise<ContentSummary[] | null>)
        | null;
      build: keyof typeof Builds;
      hasLinkAutogen?: boolean;
      config?: CKEditorConfiguration;
      autogeneratedValue?: VersoSource;
      onUpload?: (file: File) => Promise<{ id: string }>;
      wordCount?: number | null;
      charCount?: number | null;
      onEditorReady?: () => void;
      readonly?: boolean;
      enablePrismXML?: boolean;
      placeholder?: string;
    },
    ref?: Ref<EditorInstance>
  ) {
    const {
      value,
      onChange,
      onBodyChange,
      reorderAssets,
      selectAssets,
      build,
      config,
      autogeneratedValue,
      onUpload,
      wordCount,
      charCount,
      hasLinkAutogen,
      enablePrismXML,
      onEditorReady,
      readonly = false,
      placeholder,
      ...forwardProps
    } = props;

    let [editorElement, setEditorElement] = useState<HTMLDivElement | null>(
      null
    );
    let [isAutogenerated, setIsAutogenerated] = useState<boolean>(false);

    // We only want to compute this _once_ to set the HTML
    // for CKEditor's initial state
    let initialValue = useMemo(
      () => Renderer.render(value),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
    let renderedAutogeneratedValue = useMemo(
      () => (autogeneratedValue ? Renderer.render(autogeneratedValue) : null),
      [autogeneratedValue]
    );
    let Build = useMemo(() => {
      return Builds[build];
    }, [build]);
    // This configuration must also only be set once
    const resolvedConfiguration = useMemo(
      () => buildConfiguration(Build, config ?? {}, placeholder),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
    useEffect(() => {
      if (!autogeneratedValue) {
        return;
      }
      if (
        autogeneratedValue.content.length === 0 ||
        value.content.length === 0
      ) {
        return;
      }
      setIsAutogenerated(autogeneratedValue.equals(value));
    }, [autogeneratedValue, value, setIsAutogenerated]);

    // Ref to the editor itself
    const [editor, setEditor] = useState<EditorInstance | null>(null);

    const { trackComponentEvent } = useContext(SnowplowContext);

    const autogenerate = useCallback(() => {
      if (editor) {
        editor.setData(renderedAutogeneratedValue ?? "");
        setIsAutogenerated(true);
        trackComponentEvent(
          "autogen",
          "button_click",
          "render_autogenerate_value"
        );
      }
    }, [editor, renderedAutogeneratedValue]);
    const autogenerateLinks = useCallback(() => {
      if (editor && hasLinkAutogen) {
        editor.execute("autogenerateLinks");
        trackComponentEvent("autogen", "button_click", "autogenerate_links");
      }
    }, [editor, hasLinkAutogen]);

    const appendPrismData = useCallback(
      (doc: VersoSource) =>
        new Promise<void>((resolve) => {
          if (editor) {
            const currentData = Renderer.render(
              editor.getData({ format: "application/vnd.atjson+verso" })
            );
            const newData = Renderer.render(doc);

            editor.setData(currentData + newData);
            resolve();
          }
        }),
      [editor]
    );

    const prismConfig = useMemo(() => {
      return {
        ...config,
        bodyStyle: { showHelperText: false },
      };
    }, [config]);
    const onContentSelectorClick = useCallback(
      async (_event, success, options) => {
        let assets = selectAssets && (await selectAssets(options));

        let embedData = assets?.reduce((embeds, asset) => {
          let embedData = _getEmbedData(asset);
          if (embedData) {
            embeds.push(embedData);
          }
          return embeds;
        }, [] as unknown[]);
        success(embedData);
        return true;
      },
      [selectAssets]
    );

    useEffect(() => {
      editor?.on("content-selector-click", onContentSelectorClick);
      return () => {
        editor?.off("content-selector-click", onContentSelectorClick);
      };
    }, [onContentSelectorClick, editor]);

    const onReorderAssetsClick = useCallback(
      async (_event, success, options) => {
        let assets = reorderAssets && (await reorderAssets(options));

        let embedData = assets?.reduce((embeds, asset) => {
          let embedData = _getEmbedData(asset);
          if (embedData) {
            embeds.push(embedData);
          }
          return embeds;
        }, [] as unknown[]);
        success(embedData);
        return true;
      },
      [reorderAssets]
    );

    useEffect(() => {
      editor?.on("reorder-batch-click", onReorderAssetsClick);
      return () => {
        editor?.off("reorder-batch-click", onReorderAssetsClick);
      };
    }, [onReorderAssetsClick, editor]);

    const onEditorUpload = useCallback(
      async (_event, file, success, error) => {
        if (onUpload) {
          try {
            const { id } = await onUpload(file);
            success({ copilotid: id });
          } catch (e) {
            error({
              errorMessage:
                (get(e, "message") as string) ||
                (get(e, "errorMessage") as string) ||
                "Unknown error",
            });
          }
        }
      },
      [onUpload]
    );

    useEffect(() => {
      editor?.on("upload", onEditorUpload);
      return () => {
        editor?.off("upload", onEditorUpload);
      };
    }, [onEditorUpload, editor]);

    useEffect(() => {
      if (editor && editor.isReadOnly !== readonly) {
        if (readonly) {
          editor.enableReadOnlyMode(READONLY_LOCK_ID);
        } else {
          editor.disableReadOnlyMode(READONLY_LOCK_ID);
        }
      }
    }, [readonly, editor]);

    const onEditorChange = useCallback(() => {
      if (onBodyChange) {
        return onBodyChange();
      }
      const data = editor?.getData({
        format: "application/vnd.atjson+verso",
      });
      setIsAutogenerated(false);
      data && onChange(data);
    }, [editor, onChange, onBodyChange]);

    useEffect(() => {
      editor?.model.document.on("change:data", onEditorChange);
      return () => {
        editor?.model.document.off("change:data", onEditorChange);
      };
    }, [onEditorChange, editor]);

    useEffect(() => {
      let editorInstance: EditorInstance | undefined;

      if (editorElement) {
        Build.create(editorElement, resolvedConfiguration).then((editor) => {
          if (ref && "current" in ref) {
            propagateRef(ref, editor);
          }
          editorInstance = editor;
          setEditor(editorInstance);

          if (onEditorReady) {
            onEditorReady();
          }
        });
      }

      return () => {
        if (editorInstance) {
          editorInstance.destroy();
          setEditor((currentEditor) => {
            if (currentEditor === editorInstance) {
              return null;
            } else {
              return currentEditor;
            }
          });
        }
      };
    }, [Build, ref, resolvedConfiguration, editorElement]);

    const IsBodyEditor = config?.include?.includes("ContentSelector");
    const autofilledThemeTransform = useCallback(
      (theme: DefaultTheme, purpleify: (css: string) => string) =>
        autofilledTheme(theme, purpleify, isAutogenerated),
      [isAutogenerated]
    );
    const editorIsInitializing = !editor || editor.state === "initializing";
    return (
      <ThemeProvider tint="purple" transform={autofilledThemeTransform}>
        <EditorWrapper
          $autogenerated={isAutogenerated}
          $padRightRail={!!renderedAutogeneratedValue}
          $build={build}
        >
          {editorIsInitializing && (
            <EditorSkeleton>
              {IsBodyEditor ? (
                <BodyTextBoxSkeleton>
                  <LongMockText label="Body Text Loading 1" />
                  <LongMockText label="Body Text Loading 2" />
                  <ShortMockText label="Body Text Loading 3" />
                  <br />
                  <LongMockText label="Body Text Loading 4" />
                  <br />
                  <LongMockText label="Body Text Loading 5" />
                  <ShortMockText label="Body Text Loading 6" />
                </BodyTextBoxSkeleton>
              ) : build === "block" ? (
                <BlockTextBoxSkeleton>
                  <ShortMockText label="Block Text Loading" />
                </BlockTextBoxSkeleton>
              ) : (
                <InlineTextBoxSkeleton>
                  <ShortMockText label="Text Loading" />
                </InlineTextBoxSkeleton>
              )}
            </EditorSkeleton>
          )}
          <EditorDiv className={editorIsInitializing ? "hide" : ""}>
            <>
              <div
                ref={setEditorElement}
                {...forwardProps}
                dangerouslySetInnerHTML={{ __html: initialValue }}
              />

              {autogeneratedValue && (
                <AutogenButton
                  $autogenerated={isAutogenerated}
                  size="medium"
                  aria-label="Autofill"
                  onClick={autogenerate}
                >
                  <WandIcon size="regular" />
                </AutogenButton>
              )}
            </>
          </EditorDiv>
        </EditorWrapper>

        <EditorFooterWrapper>
          <EditorFooterGroupWrapper>
            {hasLinkAutogen && (
              <AutoLinkingButton
                aria-label="Autolinking"
                onClick={autogenerateLinks}
              >
                <WandIcon size="regular" />
                <FormattedMessage defaultMessage="Generate links to tag pages" />
              </AutoLinkingButton>
            )}
            {enablePrismXML && (
              <PrismXMLImportButton
                onSubmit={appendPrismData}
                previewConfig={prismConfig}
              />
            )}
          </EditorFooterGroupWrapper>
          <EditorFooterGroupWrapper>
            {wordCount != 0 && wordCount && (
              <StyledWordCount $autolinking={!!hasLinkAutogen}>
                <FormattedMessage
                  defaultMessage="{count, plural, =1 {# word} other {# words}}"
                  values={{
                    count: wordCount,
                  }}
                />
              </StyledWordCount>
            )}
            {charCount != 0 && charCount && (
              <StyledWordCount $autolinking={!!hasLinkAutogen}>
                <FormattedMessage
                  defaultMessage="{count, plural, =1 {# char} other {# chars}}"
                  values={{
                    count: charCount,
                  }}
                />
              </StyledWordCount>
            )}
          </EditorFooterGroupWrapper>
        </EditorFooterWrapper>
      </ThemeProvider>
    );
  })
);

import { useMemo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
import {
  Spinner,
  PageLayoutSelector,
  withErrorBoundary,
  Card,
  VideoConfiguration,
} from "@components";
import type {
  AnonymousControlProps,
  Category,
  GetConfigs,
  GetConfigsVariables,
  AspectRatio,
  CategoryTaxonomyFields,
  ContentConnectionFields,
  CategoryTaxonomyFields_categories,
  MediaOverrides,
  MediaOverrideConnection,
  PhotosOverrideEdge,
  CneVideosOverrideEdge,
  MediaNode,
  PhotosLede_V1,
  VideosLede_V1,
  CneVideoOverride,
} from "@types";
import { DefaultCategoryError, NoCategoriesError } from "./PageLayout.errors";
import { useArticleConfig } from "./useArticleConfig";
import { PageLayoutConfiguration, get } from "@lib";
import { setMediaOverridesV2, defaultCneVideosOverrideEdge } from "../utils";
import {
  createTrie,
  getCurrentCategory,
  getLayoutCategories,
  getCategoryFromToggles,
  getUpdatedVersoSettings,
} from "./PageLayout.utils";
import { useQuery } from "@apollo/client";
import { Queries } from "@gql";
import { useUniqueId } from "@hooks";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactTabs, Tabs, Tab } from "../../../../../components/Tabs";

const { categoryToggleMapping, toggleLevelHierarchy, toggleOptionsConfig } =
  PageLayoutConfiguration;

const PageLayoutWrapper = styled(Card).attrs({ as: "section" })`
  margin-block-start: var(--spacing-md);
`;

const Title = styled.h2`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
  margin-bottom: var(--spacing-sm);
`;

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const NoVideoMessage = styled.p`
  text-align: center;
`;

export function PageLayoutComponent({
  model,
  currentOrganization,
  setValue,
  currentUser,
}: AnonymousControlProps) {
  let { data: configs, loading: configLoading } = useQuery<
    GetConfigs,
    GetConfigsVariables
  >(Queries.GET_CONFIGS, {
    variables: {
      organizationId: currentOrganization.organizationId,
      userId: currentUser.id,
    },
  });
  const uniqueId = useUniqueId();
  const intl = useIntl();
  const [defaultTabIndex, setDefaultTabIndex] = useState(1);

  const {
    articleConfig,
    loading: articleConfigLoading,
    error: configError,
  } = useArticleConfig(currentOrganization);

  const configuredLayoutCategories = useMemo(
    () =>
      articleConfig &&
      getLayoutCategories(articleConfig, categoryToggleMapping),
    [articleConfig]
  );

  const {
    data: pageLayoutCategories,
    loading: pageLayoutCategoriesLoading,
    error: pageLayoutCategoriesError,
  } = useQuery(Queries.CATEGORY_SEARCH, {
    variables: {
      organizationId: currentOrganization.organizationId,
      filters: {
        hierarchy: "verso-settings/article-settings/ContentHeader/variation",
        limit: 1000,
      },
    },
  });

  let availableLayoutCategories = useMemo(() => {
    if (!pageLayoutCategoriesLoading) {
      let availableLayoutCategories =
        pageLayoutCategories.categorySearch.filter((category: Category) => {
          const hierarchyString = category.hierarchy
            .map((hierarchy) => hierarchy.slug)
            .reverse()
            .join("/");
          return (
            hierarchyString !==
            "verso-settings/article-settings/ContentHeader/variation"
          );
        });
      if (
        availableLayoutCategories.filter((category: Category) =>
          configuredLayoutCategories
            ?.map(([slug]) => slug)
            .includes(category.slug)
        ).length === 0
      ) {
        throw new NoCategoriesError();
      }
      return availableLayoutCategories;
    }
    return;
  }, [
    pageLayoutCategories,
    configuredLayoutCategories,
    pageLayoutCategoriesLoading,
  ]);

  const enableLivePreview = configs?.configs.pageLayoutLivePreview || false;
  const enableVideoConfigurationTab =
    configs?.configs?.enableMediaOverridesV2 || false;
  const mediaDomain: string = currentOrganization.metadata.mediaDomain;

  const categoryTaxonomies = useMemo(
    () => (model.categoryTaxonomies ?? []) as CategoryTaxonomyFields[],
    [model.categoryTaxonomies]
  );

  const versoSettings = useMemo(
    () =>
      categoryTaxonomies.find(({ taxonomy }) => taxonomy === "verso-settings")
        ?.categories || [],
    [categoryTaxonomies]
  );

  let normalizedMediaOverrides = useMemo(() => {
    const mediaOverridesModelValue =
      (model.mediaOverrides as MediaOverrides[]) || [];
    return mediaOverridesModelValue.map((mediaOverride) => {
      return {
        relName: mediaOverride.relName,
        overrides:
          mediaOverride.overrides.map((override) => {
            return {
              breakpoint: override?.breakpoint || "",
              aspectRatio: override?.aspectRatio || "",
            };
          }) || [],
      };
    });
  }, [model.mediaOverrides]);

  let normalizedPhotosLede = useMemo(() => {
    const photosLedeModelValue = (model.photosLede as ContentConnectionFields)
      ?.edges?.[0]?.node;
    const configsAspectRatios = configs?.configs.aspectRatios || [];
    if (!isEmpty(photosLedeModelValue)) {
      return {
        id: photosLedeModelValue?.id || "",
        asset: {
          filename: photosLedeModelValue?.asset?.filename || "",
        },
        restrictCropping: photosLedeModelValue?.restrictCropping || false,
        aspectRatios:
          photosLedeModelValue?.aspectRatios?.reduce((acc, aspectRatio) => {
            if (
              (aspectRatio?.name &&
                (configsAspectRatios.length === 0 ||
                  configsAspectRatios.includes(aspectRatio.name))) ||
              aspectRatio?.name === "master"
            ) {
              acc.push({
                format: aspectRatio.format || "",
                url: aspectRatio.url || "",
                height: aspectRatio.height || 200,
                name: aspectRatio.name,
                modifications: aspectRatio.modifications
                  ? {
                      crop: {
                        height: aspectRatio?.modifications?.crop?.height || 200,
                        width: aspectRatio?.modifications?.crop?.height || 200,
                        x: aspectRatio?.modifications?.crop?.x || 0,
                        y: aspectRatio?.modifications?.crop?.y || 0,
                      },
                    }
                  : undefined,
                override: aspectRatio.override || false,
                width: aspectRatio.width || 200,
              });
            }
            return acc;
          }, [] as AspectRatio[]) || [],
      };
    }
    return;
  }, [model.photosLede, configs?.configs.aspectRatios]);

  let normalizedVideosLede = useMemo(() => {
    const videosLedeModelValue = (model.videosLede as ContentConnectionFields)
      ?.edges?.[0]?.node;
    if (!isEmpty(videosLedeModelValue)) {
      return {
        id: videosLedeModelValue?.id || "",
        asset: {
          filename: videosLedeModelValue?.asset?.publicURL || "",
        },
        cneId: videosLedeModelValue?.cneId || "",
        feedGuid: videosLedeModelValue?.feedGuid || "",
        embedUrl: videosLedeModelValue?.embedUrl || "",
        transcriptUrl: videosLedeModelValue?.transcriptUrl || "",
      };
    }
    return;
  }, [model.videosLede]);

  const videoLedeOverrides = useMemo(() => {
    if (normalizedVideosLede) {
      const OverridesValue =
        (get(model, "relMediaOverrides") as MediaOverrideConnection) || {};
      if (OverridesValue?.edges.length) {
        let videoOverrideEdge;
        for (let edge of OverridesValue.edges) {
          const node = get(
            edge as PhotosOverrideEdge | CneVideosOverrideEdge,
            "node"
          ) as MediaNode;
          if (node.id === normalizedVideosLede?.id) {
            videoOverrideEdge = edge as CneVideosOverrideEdge;
          }
        }
        if (!videoOverrideEdge) {
          videoOverrideEdge = {
            ...defaultCneVideosOverrideEdge,
            node: {
              id: normalizedVideosLede.id,
              __typename: "ContentSummary",
            },
          } as CneVideosOverrideEdge;
        }
        return videoOverrideEdge;
      } else {
        return defaultCneVideosOverrideEdge;
      }
    }
    return;
  }, [model, normalizedVideosLede]);

  const photoLedeOverrides = useMemo(() => {
    if (normalizedPhotosLede) {
      const OverridesValue =
        (get(model, "relMediaOverrides") as MediaOverrideConnection) || {};
      if (OverridesValue?.edges) {
        for (let edge of OverridesValue.edges) {
          if (edge && "pagelayoutMediaOverrides" in edge) {
            const overrideEdge = edge as PhotosOverrideEdge;
            const node = get(
              edge as PhotosOverrideEdge | CneVideosOverrideEdge,
              "node"
            ) as MediaNode;
            if (node.id === normalizedPhotosLede?.id) return overrideEdge;
          }
        }
      }
    }
    return;
  }, [model, normalizedPhotosLede]);

  useEffect(() => {
    const newTabIndex =
      enableVideoConfigurationTab &&
      normalizedVideosLede &&
      !normalizedPhotosLede
        ? 2
        : 1;
    setDefaultTabIndex(newTabIndex);
  }, [normalizedVideosLede, normalizedPhotosLede, enableVideoConfigurationTab]);

  let normalizedCurrentCategory = useMemo(() => {
    const currentCategory =
      articleConfig &&
      getCurrentCategory(versoSettings, articleConfig, categoryToggleMapping);

    if (!isEmpty(currentCategory)) {
      return {
        id: currentCategory?.id || "",
        slug: currentCategory?.slug || "",
        name: currentCategory?.name || "",
        hierarchy:
          currentCategory?.hierarchy.map((descendantCategory) => {
            return {
              slug: descendantCategory.slug,
              name: descendantCategory.name || "",
            };
          }) || [],
        hierarchyString:
          currentCategory?.hierarchy
            .map((descendantCategory) => {
              return descendantCategory.slug;
            })
            .reverse()
            .join("/") || "",
      };
    }
    return;
  }, [versoSettings, articleConfig]);

  let defaultCategory = useMemo(() => {
    if (!availableLayoutCategories) return;
    let defaultCategory = availableLayoutCategories.find(
      (category: Category) => {
        return category.slug === articleConfig?.defaultVersoHeaderLayout;
      }
    );
    if (!defaultCategory) {
      throw new DefaultCategoryError(
        articleConfig?.defaultVersoHeaderLayout ?? ""
      );
    }
    return defaultCategory;
  }, [articleConfig?.defaultVersoHeaderLayout, availableLayoutCategories]);

  const toggleDecisionTree = useMemo(
    () =>
      configuredLayoutCategories &&
      createTrie(toggleLevelHierarchy, configuredLayoutCategories),
    [configuredLayoutCategories]
  );

  const setCategoryTaxonomies = useCallback(
    (category: CategoryTaxonomyFields_categories) => {
      const updatedVersoSettings = getUpdatedVersoSettings(
        versoSettings,
        category
      );

      setValue("categoryTaxonomies", [
        ...categoryTaxonomies.filter(({ taxonomy }) => {
          return taxonomy !== "verso-settings";
        }),
        ...[
          {
            taxonomy: "verso-settings",
            categories: updatedVersoSettings,
          },
        ],
      ]);
    },
    [categoryTaxonomies, setValue, versoSettings]
  );

  useEffect(() => {
    if (!normalizedCurrentCategory && defaultCategory) {
      setCategoryTaxonomies(defaultCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [normalizedCurrentCategory, defaultCategory]);

  const currentToggleSelection = useMemo(
    () =>
      normalizedCurrentCategory &&
      categoryToggleMapping[normalizedCurrentCategory.slug],
    [normalizedCurrentCategory]
  );

  const setMediaOverrides = useCallback(
    (mediaOverrides: MediaOverrides[]) => {
      setValue("mediaOverrides", mediaOverrides);
    },
    [setValue]
  );

  const updateMediaOverridesV2 = (
    OverrideEdge: CneVideosOverrideEdge | PhotosOverrideEdge
  ) => {
    const OverridesValue = get(
      model,
      "relMediaOverrides"
    ) as MediaOverrideConnection;
    const mediaOverrides = get(model, "mediaOverrides") as MediaOverrides[];
    const updatedMediaOverrides = setMediaOverridesV2(
      OverridesValue,
      OverrideEdge,
      mediaOverrides,
      get(model, "photosLede") as PhotosLede_V1,
      get(model, "videosLede") as VideosLede_V1
    );
    setValue("relMediaOverrides", updatedMediaOverrides);
  };

  const updateVideoMediaOverrides = useCallback(
    (videoConfiguration: Omit<CneVideoOverride, "__typename">) => {
      const overridesValue = get(
        model,
        "relMediaOverrides"
      ) as MediaOverrideConnection;
      if (videoLedeOverrides) {
        const updatedMediaOverrides = setMediaOverridesV2(
          overridesValue,
          {
            ...videoLedeOverrides,
            cneVideoOverrides: {
              __typename: "CneVideoOverride",
              ...videoConfiguration,
            },
          },
          get(model, "mediaOverrides") as MediaOverrides[],
          get(model, "photosLede") as PhotosLede_V1,
          get(model, "videosLede") as VideosLede_V1
        );
        setValue("relMediaOverrides", updatedMediaOverrides);
      }
    },
    [setValue, model]
  );

  const setPageLayoutCategory = useCallback(
    (level: string, toggleOption: string) => {
      // if (!this._recordingStarted && window.hasOwnProperty("hj")) {
      //   hj("trigger", "verso_header_selection");
      //   this._recordingStarted = true;
      // } //TODO what to do about this ?
      let newToggleSelection = Object.assign({}, currentToggleSelection, {
        [level]: toggleOption,
      });
      let newCategory =
        toggleDecisionTree &&
        getCategoryFromToggles(newToggleSelection, toggleDecisionTree);
      let newCategoryObject = availableLayoutCategories.find(
        (setting: Category) => {
          return setting.slug === newCategory;
        }
      );

      if (!newCategoryObject) {
        return;
      }
      setCategoryTaxonomies(newCategoryObject);
    },
    [
      availableLayoutCategories,
      currentToggleSelection,
      toggleDecisionTree,
      setCategoryTaxonomies,
    ]
  );

  const resetToDefault = useCallback(() => {
    if (!defaultCategory) {
      return;
    }
    setCategoryTaxonomies(defaultCategory);
  }, [defaultCategory, setCategoryTaxonomies]);

  if (
    articleConfigLoading ||
    configLoading ||
    pageLayoutCategoriesLoading ||
    pageLayoutCategoriesError ||
    configError
  ) {
    return (
      <SpinnerWrapper>
        <Spinner size="large" />
      </SpinnerWrapper>
    );
  }
  if (
    !versoSettings ||
    !versoSettings.length ||
    !normalizedCurrentCategory ||
    !toggleDecisionTree ||
    !currentToggleSelection ||
    !normalizedCurrentCategory
  ) {
    return null;
  }

  return (
    <PageLayoutWrapper id={`content-header-${uniqueId}`}>
      <Title id="content-header-title">
        <FormattedMessage defaultMessage="Content Header" />
      </Title>
      <ReactTabs
        tabIndex={defaultTabIndex}
        setCurrentIndex={setDefaultTabIndex}
        tabList={
          enableVideoConfigurationTab
            ? ["Page Layout", "Story Video Configuration"]
            : ["Page Layout"]
        }
      >
        <Tabs>
          <Tab tabId={1}>
            <PageLayoutSelector
              toggleDecisionTree={toggleDecisionTree}
              currentToggleSelection={currentToggleSelection}
              setPageLayoutCategory={setPageLayoutCategory}
              setMediaOverrides={setMediaOverrides}
              setMediaOverridesV2={updateMediaOverridesV2}
              config={{ toggleOptionsConfig, toggleLevelHierarchy }}
              mediaOverrides={normalizedMediaOverrides}
              enableMediaOverridesV2={
                configs?.configs?.enableMediaOverridesV2 || false
              }
              relMediaOverrides={photoLedeOverrides}
              photosLede={normalizedPhotosLede}
              enableLivePreview={enableLivePreview}
              currentCategory={normalizedCurrentCategory}
              resetToDefault={resetToDefault}
              mediaDomain={mediaDomain}
            />
          </Tab>
          {enableVideoConfigurationTab && (
            <Tab tabId={2}>
              {normalizedVideosLede && videoLedeOverrides ? (
                <VideoConfiguration
                  toggleDecisionTree={toggleDecisionTree}
                  currentToggleSelection={currentToggleSelection}
                  setPageLayoutCategory={setPageLayoutCategory}
                  config={{ toggleOptionsConfig, toggleLevelHierarchy }}
                  videoConfig={videoLedeOverrides.cneVideoOverrides}
                  setVideoConfig={updateVideoMediaOverrides}
                  videosLede={normalizedVideosLede}
                  enableLivePreview={enableLivePreview}
                  currentCategory={normalizedCurrentCategory}
                  resetToDefault={resetToDefault}
                  mediaDomain={mediaDomain}
                  lang={(get(model, "lang") as string) ?? ""}
                />
              ) : (
                <NoVideoMessage>
                  {intl.formatMessage({
                    defaultMessage: "No Story Video Selected",
                    description: "No Video Lede is selected.",
                  })}
                </NoVideoMessage>
              )}
            </Tab>
          )}
        </Tabs>
      </ReactTabs>
    </PageLayoutWrapper>
  );
}

PageLayoutComponent.displayName = "PageLayout";

export const PageLayout =
  withErrorBoundary<AnonymousControlProps>(PageLayoutComponent);

import React, { FC } from "react";

type MainModelProviderProps = {
  mainModel?: Record<string, unknown>;
  setMainModelValue?: (key: string, value: unknown) => void;
};
export const MainModelContext = React.createContext<MainModelProviderProps>({
  mainModel: undefined,
  setMainModelValue: undefined,
});

export const MainModelProvider: FC<MainModelProviderProps> = ({
  children,
  mainModel,
  setMainModelValue,
}) => {
  return (
    <MainModelContext.Provider value={{ mainModel, setMainModelValue }}>
      {children}
    </MainModelContext.Provider>
  );
};

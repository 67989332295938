import type { ContentSerializers } from "../types";
import type { ProductInput, FormFor_content_Product } from "@types";
import {
  allContributors,
  awards,
  categoryTaxonomies,
  contentConnection,
  offers,
  transformProConValues,
} from "../fields";
import {
  serializerFor,
  includeAllMetadataFields,
  entityMetadata,
} from "../utils";

const ProductFields = {
  allContributors,
  awards,
  brand: contentConnection,
  media: contentConnection,
  offers,
  productPros: transformProConValues,
  productCons: transformProConValues,
  photosTout: contentConnection,
  categoryTaxonomies,
} as const;

export const ProductSerializers: ContentSerializers<ProductInput> = {
  save: (changes, model, { currentUser }) => {
    if (!("name" in model)) {
      return null;
    }
    const authorName = `${currentUser.firstName} ${currentUser.lastName}`;
    const metadataFields = includeAllMetadataFields(model, changes);

    const { productPros, productCons, ...serialized } = serializerFor(
      ProductFields
    )(changes) as Partial<FormFor_content_Product>;

    return {
      ...metadataFields,
      ...(serialized as Partial<ProductInput>),
      pros: productPros,
      cons: productCons,
      authorName,
      name: model.name,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const authorName = `${currentUser.firstName} ${currentUser.lastName}`;
    const createTitle = `Untitled / ${authorName} / ${currentDateTime}`;

    return {
      authorName,
      entityMetadata: { user: [`${currentUser.id}`] },
      name: createTitle,
    };
  },
};

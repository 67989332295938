import type { ContentSerializers } from "../types";
import type { BrandInput } from "@types";
import { contentConnection, categoryTaxonomies, seo } from "../fields";
import { serializerFor, entityMetadata } from "../utils";

const BrandFields = {
  photosLogo: contentConnection,
  photosTout: contentConnection,
  categoryTaxonomies,
  designers: contentConnection,
  advertiser: contentConnection,
  previousDesigners: contentConnection,
  seo: seo,
} as const;

export const BrandSerializers: ContentSerializers<BrandInput> = {
  save: (changes, model, { currentUser }) => {
    if (!("name" in model)) {
      return null;
    }
    return {
      ...serializerFor(BrandFields)(changes),
      name: model.name?.trim(),
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
    return {
      name: createTitle,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      description: "",
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

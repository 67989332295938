import { useState } from "react";
import {
  ControlProps,
  FormFor_form_controls_FieldsectionFormControl,
} from "@types";
import { useDefinedMessages } from "@hooks";
import styled from "styled-components";
import { Button } from "@components";
import { ChevronDownIcon, ChevronUpIcon } from "@condenast/gemini/icons";
import { useIntl } from "react-intl";
const Header = styled.div`
  &.heading {
    &:not(:first-child) {
      border-top: 1px solid ${(props) => props.theme.DividerColor};
      margin-top: var(--spacing-sm);
      padding-top: var(--spacing-sm);
    }

    padding-bottom: var(--spacing-sm);
  }

  &.label {
    margin-top: var(--spacing-sm);
  }

  display: grid;
  gap: var(--spacing-xxs);
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleText = styled.h2<{ type: string }>`
  font: ${(props) =>
    props.type === "heading"
      ? props.theme.FontSubSectionHeading
      : props.theme.FontLabel};
  color: ${(props) => props.theme.Color};
`;

const Description = styled.p`
  font: ${(props) => props.theme.FontStatement};
  color: ${(props) => props.theme.SubheadingColor};
`;

export function Fieldsection(
  props: ControlProps<FormFor_form_controls_FieldsectionFormControl> & {
    className?: string;
    canCollapse?: boolean;
  }
) {
  const intl = useIntl();
  let { translateFieldDescription, translateFieldLegend } =
    useDefinedMessages();
  let [isCollapsed, setIsCollapsed] = useState(false);
  if (props.name === "") {
    return <>{props.children}</>;
  }

  const chevronLabel = isCollapsed
    ? intl.formatMessage({ defaultMessage: "Expand" })
    : intl.formatMessage({ defaultMessage: "Collapse" });

  return (
    <>
      <Header id={`§${props.name}`} className={props.style || "heading"}>
        <Title>
          <TitleText type={props.style || "heading"}>
            {props.name !== "main" && translateFieldLegend(props.name)}
          </TitleText>
          {props.canCollapse && (
            <Button
              aria-label={chevronLabel}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {isCollapsed ? (
                <ChevronDownIcon size="regular" />
              ) : (
                <ChevronUpIcon size="regular" />
              )}
            </Button>
          )}
        </Title>

        {props.description && (
          <Description>
            {translateFieldDescription(props.description)}
          </Description>
        )}
      </Header>
      {!isCollapsed && props.children}
    </>
  );
}
Fieldsection.displayName = "Control(Fieldsection)";

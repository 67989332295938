import has from "lodash/has";
import { get, merge, unset as unsetPath } from "@lib";
import type { ChangesetState } from "@types";

function set(key: string, value: unknown, initialValue: unknown) {
  return {
    action: "SET",
    key,
    value,
    initialValue,
  } as const;
}

function unset(key: string) {
  return {
    action: "UNSET",
    key,
  } as const;
}

function clear() {
  return {
    action: "CLEAR",
  } as const;
}

export const actions = {
  clear,
  set,
  unset,
};

type ActionType = ReturnType<typeof set | typeof unset | typeof clear>;

export function equals(oldValue: unknown, newValue: unknown): boolean {
  if (oldValue == null && newValue == null) {
    return true;
  }
  if (typeof oldValue === typeof newValue) {
    if (Array.isArray(oldValue) && Array.isArray(newValue)) {
      return (
        oldValue.length === newValue.length &&
        oldValue.every((item, index) => equals(item, newValue[index]))
      );
    } else {
      return oldValue === newValue;
    }
  }
  return false;
}

function getChangedKeys(
  state: ChangesetState,
  action: ActionType
): Pick<ChangesetState, "changedKeys"> {
  switch (action.action) {
    case "SET": {
      const filteredKeys = state.changedKeys.filter((key) => {
        return key !== action.key && !key.startsWith(`${action.key}.`);
      });
      const changedKeys = [...filteredKeys, action.key];
      return {
        changedKeys,
      };
    }
    case "UNSET": {
      let changedKeys = state.changedKeys.filter((key) => key !== action.key);
      return {
        changedKeys,
      };
    }
    case "CLEAR": {
      return {
        changedKeys: [],
      };
    }
  }
}

function getChanges(
  state: ChangesetState,
  action: ActionType
): Pick<ChangesetState, "changes"> {
  switch (action.action) {
    case "SET": {
      if (action.key.includes(".")) {
        const parentKey = action.key.split(".")[0];
        let fragmentToMerge;
        if (!has(state.changes, parentKey)) {
          const childFragment = get(
            action.initialValue as Record<string | number, unknown>,
            parentKey
          );
          fragmentToMerge = merge(
            { [parentKey]: childFragment },
            action.key,
            action.value
          );
        } else {
          fragmentToMerge = merge(state.changes, action.key, action.value);
        }
        return {
          changes: {
            ...state.changes,
            ...(fragmentToMerge as Record<string | number, unknown>),
          },
        };
      }
      return {
        changes: {
          ...state.changes,
          [action.key]: action.value,
        },
      };
    }
    case "UNSET": {
      const changes = unsetPath(state.changes, action.key);
      return {
        changes,
      };
    }
    case "CLEAR": {
      return {
        changes: {},
      };
    }
  }
}

export function reducer(state: ChangesetState, action: ActionType) {
  return Object.assign(
    {},
    getChanges(state, action),
    getChangedKeys(state, action)
  );
}

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required } from "@condenast/copilot-validators";
import { FormFor_content_MusicGroup } from "@types";
import type { ValidationContext, ValidationEnvironment } from "@lib";
import { publishURI } from "../validators";

const { object } = validators;

const musicgroupValidators = object({
  name: required(),
}).andAlso(publishURI().on("publish"));

export default function validateMusicGroup(
  musicgroup: FormFor_content_MusicGroup,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(musicgroup, build(musicgroupValidators), context, env);
}

import styled from "styled-components";
import { ThemeProvider } from "@contexts";
import { memo, useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useFocusTrap, useUniqueId, useScrollWhenDragging } from "@hooks";
import {
  BackIcon,
  CheckIcon,
  NoAssetIcon,
  EllipsisIcon,
  FullscreenIcon,
  CloseIcon,
} from "@condenast/gemini/icons";
import { Button, SearchResult, Takeover } from "@components";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CKEditorConfiguration,
  ContentSummary,
  SlideData,
  SlideUpdateOperation,
} from "@types";
import { useDrag, useDrop } from "react-dnd";
import { BatchEditDialog } from "../BatchEditDialog";
import {
  BlockEditor,
  InlineEditor,
  MinimalistEditor,
} from "@condenast/ckeditor5-build-condenast";

const CONTENT_WITH_CREDIT = ["clip", "photo", "crossword", "cnevideo"];

const TileViewContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: ${(props) => props.theme.ElevationModal};
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
`;

const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-xs);
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
`;

const ActionTitle = styled.h3`
  font: ${(props) => props.theme.FontSubSectionHeading};
`;

const SelectAllButton = styled(Button)`
  margin-left: auto;
  background-color: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  display: flex;
`;

const DropIndicator = styled.div`
  display: none;
  background-color: var(--color-blue-50);
  border-radius: calc(0.5 * var(--spacing-sm));
  width: calc(0.5 * var(--spacing-sm));
  height: 100%;
`;

const DropZone = styled.div`
  position: absolute;
  pointer-events: none;

  height: 100%;
  width: calc(50% + var(--spacing-sm) * 0.5);

  &.left {
    top: 0;
    left: calc(0% - var(--spacing-sm) * 0.5);

    ${DropIndicator} {
      position: absolute;
      left: calc(0% - var(--spacing-xxs));
    }
  }

  &.right {
    top: 0;
    left: calc(50%);

    ${DropIndicator} {
      position: absolute;
      left: calc(100% - var(--spacing-xxs));
    }
  }
`;

const TileSlideList = styled.div`
  grid-area: slide-list;
  position: relative;
  overflow-y: auto;
  height: 100vh;
  padding: var(--spacing-md);

  & ul {
    grid-template-columns: repeat(auto-fill, minmax(12.75rem, 1fr));
    gap: var(--spacing-sm);
  }

  &.asset-wrapper {
    max-width: 100%;
  }

  &.dragging {
    cursor: grabbing;
    ${DropZone} {
      pointer-events: auto;
    }
    ${DropZone}.hover ${DropIndicator} {
      display: block;
    }
  }
`;

const TileDragContainer = styled.li`
  position: relative;
  border-radius: ${(props) => props.theme.CornerRadius};
  width: 100%;
  height: 100%;
`;

const TileDragPlaceholder = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--color-blue-80);
  border: 2px solid var(--color-blue-50);
  border-radius: var(--spacing-xxs);
`;

const ResultSlatDiv = styled(Takeover.ResultSlat).attrs({ as: "div" })``;
const EmptyResultSlatDiv = styled(ResultSlatDiv)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: var(--spacing-sm);
  color: var(--color-gray-4);

  &:hover {
    background: ${(props) => props.theme.SkeletonColor};
  }

  .dragHandle {
    position: absolute;
    top: 0;
    left: 45%;
  }

  .styledIndexAndCheck {
    margin-left: auto;
  }

  .noAsset {
    position: absolute;
    left: 45%;
    top: 45%;
  }
`;

const SubmitBar = styled.div`
  position: fixed;
  z-index: ${(props) => props.theme.ElevationModal};
`;

type DragData = {
  index: number;
  slide: SlideData & Pick<Required<SlideData>, "slideID">;
};

type CollectedData = {
  isDragging: boolean;
};

const DraggableTile = memo(function (
  props: {
    moveSlide: (slide: DragData, index: number) => void;
    onDragStart: () => void;
    onDragEnd: () => void;
    onMouseMove: (index: number) => void;
    onSelect: (slideID: string) => void;
    isSelectedResult: boolean;
    selectItemPrefix: string;
  } & DragData & { cdnHost?: string }
) {
  let {
    slide,
    index,
    moveSlide,
    onMouseMove,
    onDragStart,
    onDragEnd,
    onSelect,
    cdnHost,
    isSelectedResult,
    selectItemPrefix,
  } = props;
  let data = { slide, index };
  let beforeRef = useRef<HTMLDivElement | null>(null);
  let afterRef = useRef<HTMLDivElement | null>(null);

  let [result, setResult] = useState(slide.item);

  const [{ isDragging }, dragRef] = useDrag<DragData, DragData, CollectedData>(
    () => ({
      type: "SearchResult",
      item: () => data,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [data]
  );

  const [, dropBefore] = useDrop<DragData>(
    () => ({
      accept: "SearchResult",
      drop(item) {
        moveSlide(item, index);
      },
    }),
    [index, moveSlide]
  );

  const [, dropAfter] = useDrop<DragData>(
    () => ({
      accept: "SearchResult",
      drop(item) {
        moveSlide(item, index + 1);
      },
    }),
    [index, moveSlide]
  );

  /**
   * during drag and drop the browser calculates hover states and fires
   * mouse events kind of haphazardly. Additionally, scrolling causes
   * the cursor position to move without firing mouse events. Fortunately,
   * the drag-specific events are quite reliable--so we will need to use
   * those to calculate the hover states manually.
   */
  let captureBeforeEl = useCallback(
    (el: HTMLDivElement | null) => {
      beforeRef.current = el;
      dropBefore(el);
      if (el) {
        el.ondragover = () => beforeRef.current?.classList.add("hover");
        el.ondragleave = () => beforeRef.current?.classList.remove("hover");
        el.ondrop = () => beforeRef.current?.classList.remove("hover");
      }
    },
    [dropBefore]
  );

  let captureAfterEl = useCallback(
    (el: HTMLDivElement | null) => {
      afterRef.current = el;
      dropAfter(el);
      if (el) {
        el.ondragover = () => afterRef.current?.classList.add("hover");
        el.ondragleave = () => afterRef.current?.classList.remove("hover");
        el.ondrop = () => afterRef.current?.classList.remove("hover");
      }
    },
    [dropAfter]
  );

  useEffect(() => {
    let slideCopy = { ...slide };
    if (slideCopy.title && slideCopy.item) {
      slideCopy.item.title = {
        format: "markdown",
        content: slideCopy.title,
      };
    }
    setResult(slideCopy.item);
  }, [slide]);

  let slideNumber = index + 1;

  return (
    <TileDragContainer
      onDrag={onDragStart}
      onDragOver={onDragStart}
      onDragEnd={onDragEnd}
      onDrop={onDragEnd}
      ref={dragRef}
    >
      {isDragging ? (
        <TileDragPlaceholder />
      ) : result ? (
        <SearchResult
          as={ResultSlatDiv}
          id={`${selectItemPrefix}-${index}`}
          onMouseMove={() => {
            onMouseMove(index);
          }}
          onClick={() => {
            onSelect(slide.slideID);
          }}
          aria-selected={isSelectedResult || undefined}
          result={result}
          treatment="asset-tile"
          cdnHost={cdnHost}
          draggable={true}
        >
          <Takeover.StyledIndexAndCheck>
            {slideNumber}
          </Takeover.StyledIndexAndCheck>
        </SearchResult>
      ) : (
        <EmptyResultSlatDiv
          onClick={() => {
            onSelect(slide.slideID);
          }}
          aria-selected={isSelectedResult || undefined}
        >
          <EllipsisIcon className="dragHandle" size="regular" />
          <Takeover.StyledIndexAndCheck className="styledIndexAndCheck">
            {isSelectedResult ? (
              <Takeover.StyledCheckIcon>
                <CheckIcon size="regular" />
              </Takeover.StyledCheckIcon>
            ) : (
              slideNumber
            )}
          </Takeover.StyledIndexAndCheck>
          <NoAssetIcon className="noAsset" size="regular" />
        </EmptyResultSlatDiv>
      )}

      <DropZone ref={captureBeforeEl} className="left">
        <DropIndicator />
      </DropZone>
      <DropZone ref={captureAfterEl} className="right">
        <DropIndicator />
      </DropZone>
    </TileDragContainer>
  );
});

DraggableTile.displayName = "DraggableTile";

const Builds = {
  block: BlockEditor,
  inline: InlineEditor,
  minimalist: MinimalistEditor,
};

function getMediaItems(
  ids: string[],
  slides: (SlideData & Pick<Required<SlideData>, "slideID">)[]
) {
  return ids.reduce((mediaItems, id) => {
    const slide = slides.find((slide) => slide.slideID === id);
    if (slide?.item) {
      if (CONTENT_WITH_CREDIT.includes(slide.item.contentType)) {
        mediaItems.push(slide.item);
      }
    }

    return mediaItems;
  }, [] as ContentSummary[]);
}

export const TileViewPanel = (props: {
  onClose: () => unknown;
  slides: (SlideData & Pick<Required<SlideData>, "slideID">)[];
  cdnHost?: string;
  moveSlide: (slide: DragData, dropIndex: number) => void;
  onUpdate: (operations: SlideUpdateOperation[]) => void;
  onBatchUpdate?: (items: ContentSummary[], credit: string) => Promise<void>;
  isBatchUpdating?: boolean;
  creditRichTextConfig: CKEditorConfiguration;
  captionRichTextBuild: keyof typeof Builds;
}) => {
  const {
    onClose,
    slides,
    cdnHost,
    onUpdate,
    moveSlide,
    onBatchUpdate,
    isBatchUpdating,
    creditRichTextConfig: captionRichTextConfig,
    captionRichTextBuild,
  } = props;
  const intl = useIntl();

  const [selectedSlideIDs, setSelectedSlideIDs] = useState<string[]>([]);
  const [showBatchEditDialog, setShowBatchEditDialog] = useState(false);

  const clearSelectedSlideIDs = useCallback(
    () => setSelectedSlideIDs([]),
    [setSelectedSlideIDs]
  );

  const [element, setElement] = useState<HTMLElement | null>(null);

  const [cursor, setCursor] = useState(0);
  const [cursorMode, setCursorMode] = useState<"mouse" | "keyboard">("mouse");

  const uid = useUniqueId();
  const selectItemPrefix = `select-item-${uid}`;

  const allSelected = useMemo(
    () => selectedSlideIDs.length === slides.length,
    [selectedSlideIDs, slides]
  );

  const onMouseMove = useCallback(
    (newCursor) => {
      setCursor(newCursor);
      setCursorMode("mouse");
    },
    [setCursor, setCursorMode]
  );

  useEffect(() => {
    if ("Intercom" in window) {
      Intercom("update", {
        hide_default_launcher: true,
      });
    }
    return () => {
      if ("Intercom" in window) {
        Intercom("update", {
          hide_default_launcher: false,
        });
      }
    };
  });

  const selectionSupportsCredit = useMemo(() => {
    const selectedMedia = getMediaItems(selectedSlideIDs, slides);
    return selectedMedia.length > 0;
  }, [selectedSlideIDs, slides]);

  const onSelect = useCallback(
    (slideIDToToggle: string) => {
      const index = selectedSlideIDs.findIndex((selectedSlideID) => {
        return selectedSlideID === slideIDToToggle;
      });
      if (index >= 0) {
        setSelectedSlideIDs([
          ...selectedSlideIDs.slice(0, index),
          ...selectedSlideIDs.slice(index + 1),
        ]);
      } else {
        setSelectedSlideIDs([...selectedSlideIDs, slideIDToToggle]);
      }
    },
    [selectedSlideIDs, setSelectedSlideIDs]
  );

  const toggleSelectAll = useCallback(() => {
    allSelected
      ? clearSelectedSlideIDs()
      : setSelectedSlideIDs(Array.from(slides.map((slide) => slide.slideID)));
  }, [allSelected, clearSelectedSlideIDs, setSelectedSlideIDs, slides]);

  const onActionAndSubmit = useCallback(
    (action?: "editCredit" | "delete") => {
      if (action == "delete") {
        const deleteOperations: SlideUpdateOperation[] = selectedSlideIDs
          .reduce((slidesToDeleteByIndex: number[], selectedSlideID) => {
            let slideIndexToDelete = slides.findIndex(
              (slide) => slide.slideID === selectedSlideID
            );
            if (slideIndexToDelete >= 0) {
              slidesToDeleteByIndex.push(slideIndexToDelete);
            }
            return slidesToDeleteByIndex;
          }, [])
          .sort()
          .reverse()
          .map((index) => [index, 1]);

        onUpdate(deleteOperations);
        setSelectedSlideIDs([]);
        return;
      }
      if (action == "editCredit") {
        setShowBatchEditDialog(true);
        return;
      }
    },
    [slides, selectedSlideIDs, onUpdate]
  );

  useFocusTrap(element, onClose);

  /**
   * updating react during a drag operation or while scrolling can
   * cause some janky visual behaviors, so for performance reasons we are sidestepping
   * the framework to apply this class.
   */
  const tileListRef = useRef<HTMLDivElement | null>(null);
  const reportDragStart = useCallback(
    () => tileListRef.current?.classList.add("dragging"),
    []
  );
  const reportDragEnd = useCallback(
    () => tileListRef.current?.classList.remove("dragging"),
    []
  );
  const scrollHandlers = useScrollWhenDragging(
    {
      dragZoneSize: 200,
      distancePerFrame: 100,
      msPerFrame: 120,
      scrollBehavior: "smooth",
    },
    tileListRef
  );

  const batchUpdate = useCallback(
    async (credit: string) => {
      const mediaItems = getMediaItems(selectedSlideIDs, slides);
      await onBatchUpdate?.(mediaItems, credit);
      setShowBatchEditDialog(false);
    },
    [selectedSlideIDs, slides, onBatchUpdate, setShowBatchEditDialog]
  );

  return (
    <TileViewContainer ref={setElement}>
      <TitleGroup>
        <Button
          onClick={onClose}
          aria-label="Go Back"
          tabIndex={-1}
          aria-hidden="true"
        >
          <BackIcon size="regular" />
        </Button>
        <ActionTitle>
          {intl.formatMessage({
            defaultMessage: "Reorder / Batch Credit",
            description: "Title for tile view panel",
          })}
        </ActionTitle>
        <SelectAllButton treatment="borderless" onClick={toggleSelectAll}>
          {allSelected ? (
            <>
              <CloseIcon size="regular" />
              <FormattedMessage
                defaultMessage="Deselect All"
                description="Label for button to deselect all slides"
              />
            </>
          ) : (
            <>
              <FullscreenIcon size="regular" />
              <FormattedMessage
                defaultMessage="Select All"
                description="Label for button to select all slides"
              />
            </>
          )}
        </SelectAllButton>
      </TitleGroup>
      <TileSlideList ref={tileListRef} {...scrollHandlers}>
        <ThemeProvider theme="light">
          <Takeover.GridList
            tabIndex={0}
            aria-activedescendant={
              cursor !== null && cursor >= 0
                ? `${selectItemPrefix}-${cursor}`
                : undefined
            }
            cursorMode={cursorMode}
          >
            {slides?.map((slide, index) => {
              let isSelectedResult = selectedSlideIDs.some(
                (selectedSlideID) => {
                  return slide.slideID === selectedSlideID;
                }
              );

              return (
                <DraggableTile
                  key={slide.slideID}
                  index={index}
                  slide={slide}
                  cdnHost={cdnHost}
                  isSelectedResult={isSelectedResult}
                  onDragStart={reportDragStart}
                  onDragEnd={reportDragEnd}
                  moveSlide={moveSlide}
                  onMouseMove={onMouseMove}
                  onSelect={onSelect}
                  selectItemPrefix={selectItemPrefix}
                />
              );
            })}
          </Takeover.GridList>
        </ThemeProvider>
      </TileSlideList>
      {onBatchUpdate && showBatchEditDialog && (
        <BatchEditDialog
          onBatchUpdate={batchUpdate}
          isBatchUpdating={isBatchUpdating}
          creditRichTextConfig={captionRichTextConfig}
          creditRichTextBuild={captionRichTextBuild}
          onClose={() => setShowBatchEditDialog(false)}
        />
      )}
      <SubmitBar>
        <Takeover.SubmitBar
          clearSelections={clearSelectedSlideIDs}
          selectionCount={selectedSlideIDs.length}
          onSubmit={onActionAndSubmit}
          action={[
            "delete",
            ...(onBatchUpdate && selectionSupportsCredit ? ["editCredit"] : []),
          ]}
        />
      </SubmitBar>
    </TileViewContainer>
  );
};

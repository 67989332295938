import type { CategoryTaxonomyFields, CategoryTaxonomyInput } from "@types";

export function categoryTaxonomies(
  input?: (CategoryTaxonomyFields | null)[]
): CategoryTaxonomyInput[] {
  return (
    input?.map((categoryTaxonomy) => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      taxonomy: categoryTaxonomy!.taxonomy,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      categories: categoryTaxonomy!.categories.map(({ id }) => ({
        id,
        contentType: "category",
      })),
    })) ?? []
  );
}

import { GallerySlide } from "./GallerySlide";
import { GallerySlideActions } from "./GallerySlideActions";
import styled from "styled-components";
import {
  ContentSummary,
  CKEditorConfiguration,
  SlideData,
  SlideUpdateOperation,
} from "@types";
import { useDefinedMessages, useToast, useScrollWhenDragging } from "@hooks";
import { ARIA, Button, Card, Spinner, UploadButton } from "@components";
import { AddIcon, AssetIcon, UploadIcon } from "@condenast/gemini/icons";
import {
  useCallback,
  useState,
  useRef,
  ComponentProps,
  memo,
  MouseEvent,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  BlockEditor,
  InlineEditor,
  MinimalistEditor,
} from "@condenast/ckeditor5-build-condenast";
import { useDrag, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import update from "immutability-helper";
import computeScrollIntoView from "compute-scroll-into-view";

const Section = styled(Card).attrs({ as: "section" })`
  display: relative;
  margin-block-start: var(--spacing-md);
  padding: var(--spacing-sm) 0 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 var(--spacing-sm) var(--spacing-sm);
`;

const Title = styled.h2`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
`;

const AddSlideButton = styled(ARIA.MenuButton)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
`;

const StyledItem = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: var(--spacing-sm);
  }
`;

const NoSlidesMessage = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: var(--spacing-xl) 0;

  & h1 {
    font-size: 1.75rem;
    margin-bottom: var(--spacing-sm);
  }

  & span {
    max-width: 375px;
    line-height: initial;
    display: inline-block;
  }
`;

const ActionButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
`;

const ActionsBlock = styled.div<{ $isHovered: boolean }>`
  width: 100%;
  max-width: 50.5rem;
  text-align: center;
  padding: var(--spacing-sm);
  margin: calc(var(--spacing-lg) - var(--spacing-sm)) auto 0;
  background: ${(props) => props.theme.Background};
  border: ${(props) =>
    props.$isHovered
      ? "2px dashed var(--color-blue-50)"
      : "2px dashed var(--color-gray-5)"};

  ${ActionButton} {
    margin: 0 var(--spacing-xs);
  }
`;

const UploadMessage = styled.p`
  font: var(--font-small-statements);
  margin: var(--spacing-sm) 0 0 0;
`;

const DropIndicator = styled.div`
  display: none;
  background-color: var(--color-blue-50);
  border-radius: calc(0.5 * var(--spacing-sm));
  width: 100%;
  height: calc(0.5 * var(--spacing-sm));
  max-width: 50.5rem;
`;

const DropZone = styled.div`
  position: absolute;
  pointer-events: none;

  width: 100%;
  height: calc(50% + 0.75 * var(--spacing-sm));

  &.top {
    bottom: 50%;
    ${DropIndicator} {
      position: absolute;
      top: 0;
    }
  }

  &.bottom {
    top: 50%;
    ${DropIndicator} {
      position: absolute;
      bottom: 0;
    }
  }
`;

const SlideDragContainer = styled.div`
  position: relative;
  margin: calc(0.5 * var(--spacing-sm)) auto;
  max-width: 50.5rem;
  width: 100%;
`;

const Placeholder = styled(Card)`
  position: relative;
  display: grid;
  width: 100%;
  max-width: 50.5rem;
  min-height: 144px;
  padding-top: var(--spacing-sm);
  background: var(--color-blue-80);
  border: 2px solid var(--color-blue-50);
  box-shadow: none;
  justify-content: center;
  align-items: center;
`;

const SlideList = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto;
  margin: 0 auto;
  padding: var(--spacing-sm) 0 var(--spacing-lg) 0;
  column-gap: var(--spacing-md);
  width: 100%;
  background: var(--color-gray-6);

  &.dragging {
    cursor: grabbing;
    ${DropZone} {
      pointer-events: auto;
    }
    ${DropZone}.hover ${DropIndicator} {
      display: block;
    }
  }
`;

type DragData = {
  index: number;
  slide: SlideData;
};

function isInteractiveElement(element: Element) {
  return (
    ["A", "INPUT", "BUTTON", "SELECT"].indexOf(element.tagName) >= 0 ||
    element.hasAttribute("contenteditable")
  );
}

function isContainedInInteractiveElement(target: EventTarget | null) {
  let currentElement = target instanceof Element ? target : null;
  let interactive = false;
  while (currentElement && !interactive) {
    interactive = isInteractiveElement(currentElement);
    currentElement = currentElement.parentElement;
  }
  return interactive;
}

const DraggableSlide = memo(function (
  props: {
    moveSlide: (slide: DragData, index: number) => void;
    onDragStart: () => void;
    onDragEnd: () => void;
    addFromUpload: (files: FileList, index?: number) => void;
    allowUploads: boolean;
    accept?: string[];
  } & DragData &
    ComponentProps<typeof GallerySlide>
) {
  let {
    slide,
    index,
    moveSlide,
    onDragStart,
    onDragEnd,
    allowUploads,
    accept,
    addFromUpload,
    ...slideProps
  } = props;
  let data = { slide, index };
  let beforeRef = useRef<HTMLDivElement | null>(null);
  let afterRef = useRef<HTMLDivElement | null>(null);
  let [uploadDropIndex, setUploadDropIndex] = useState<number | undefined>(
    undefined
  );
  const [draggable, setDraggable] = useState(true);

  const updateDraggableState = useCallback(
    (evt: MouseEvent) => {
      setDraggable(!isContainedInInteractiveElement(evt.target));
    },
    [setDraggable]
  );

  const enableDrag = useCallback(() => {
    setDraggable(true);
  }, [setDraggable]);

  const [, dragRef] = useDrag<DragData>(
    () => ({
      type: "GallerySlide",
      item: () => data,
      canDrag: () => draggable,
    }),
    [data]
  );

  const [, dropBefore] = useDrop<DragData>(
    () => ({
      accept: "GallerySlide",
      drop(item) {
        moveSlide(item, index);
      },
    }),
    [index, moveSlide]
  );

  const [, dropAfter] = useDrop<DragData>(
    () => ({
      accept: "GallerySlide",
      drop(item) {
        moveSlide(item, index + 1);
      },
    }),
    [index, moveSlide]
  );

  let intl = useIntl();

  let showIncorrectFileTypeMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Error uploading: Unsupported file type",
    }),
  });

  const [, uploadDropRef] = useDrop<{
    files: File[];
    dataTransfer: DataTransfer;
  }>(
    {
      accept: NativeTypes.FILE,
      canDrop: (item) => {
        let isCorrectFileType = item.files.every((file) =>
          accept?.includes(file.type)
        );
        if (!isCorrectFileType) {
          showIncorrectFileTypeMessage();
        }
        let canDrop = !!(allowUploads && isCorrectFileType);

        return canDrop;
      },
      drop(item) {
        addFromUpload(item.dataTransfer.files, uploadDropIndex);
      },
    },
    [allowUploads, uploadDropIndex]
  );

  /**
   * during drag and drop the browser calculates hover states and fires
   * mouse events kind of haphazardly. Additionally, scrolling causes
   * the cursor position to move without firing mouse events. Fortunately,
   * the drag-specific events are quite reliable--so we will need to use
   * those to calculate the hover states manually.
   */
  let captureBeforeEl = useCallback(
    (el: HTMLDivElement | null) => {
      beforeRef.current = el;
      dropBefore(el);
      if (el) {
        el.ondragover = () => beforeRef.current?.classList.add("hover");
        el.ondragleave = () => beforeRef.current?.classList.remove("hover");
        el.ondrop = () => beforeRef.current?.classList.remove("hover");
      }
    },
    [dropBefore]
  );

  let captureAfterEl = useCallback(
    (el: HTMLDivElement | null) => {
      afterRef.current = el;
      dropAfter(el);
      if (el) {
        el.ondragover = () => afterRef.current?.classList.add("hover");
        el.ondragleave = () => afterRef.current?.classList.remove("hover");
        el.ondrop = () => afterRef.current?.classList.remove("hover");
      }
    },
    [dropAfter]
  );

  return (
    <SlideDragContainer
      ref={(node) => dragRef(uploadDropRef(node))}
      onDrag={onDragStart}
      onDragOver={onDragStart}
      onDragEnd={onDragEnd}
      onDrop={onDragEnd}
      onMouseDownCapture={updateDraggableState}
      onMouseUp={enableDrag}
    >
      <GallerySlide index={index} slide={slide} {...slideProps} />
      <DropZone
        ref={captureBeforeEl}
        onDragOver={() => {
          setUploadDropIndex(index);
        }}
        className="top"
      >
        <DropIndicator />
      </DropZone>
      <DropZone
        ref={captureAfterEl}
        onDragOver={() => {
          setUploadDropIndex(index + 1);
        }}
        className="bottom"
      >
        <DropIndicator />
      </DropZone>
    </SlideDragContainer>
  );
});

DraggableSlide.displayName = "DraggableSlide";

function getInsertionIndex<T>(items: T[], item: T) {
  const index = items.indexOf(item);
  return index >= 0 ? index : items.length;
}

const Builds = {
  block: BlockEditor,
  inline: InlineEditor,
  minimalist: MinimalistEditor,
};

export function GallerySlideList(props: {
  onUpdate: (operations: SlideUpdateOperation[]) => void;
  selectAssets: (opts?: {
    limitSelection?: number;
  }) => Promise<ContentSummary[] | null>;
  onUpload: (files: FileList) => Promise<(ContentSummary | null)[]>;
  onBatchUpdate?: (items: ContentSummary[], credit: string) => Promise<void>;
  isBatchUpdating?: boolean;
  isUploading: boolean;
  cdnHost?: string;
  slides: (SlideData & Pick<Required<SlideData>, "slideID">)[];
  titleRichTextConfig: CKEditorConfiguration;
  titleRichTextBuild: keyof typeof Builds;
  captionRichTextConfig: CKEditorConfiguration;
  captionRichTextBuild: keyof typeof Builds;
  slideBodyRichTextBuild?: keyof typeof Builds;
  slideBodyRichTextConfig?: CKEditorConfiguration;
  showSlideBody?: boolean | null;
  accept?: string[];
}) {
  const {
    slides,
    selectAssets,
    onUpdate,
    onUpload,
    onBatchUpdate,
    isBatchUpdating,
    cdnHost,
    captionRichTextConfig,
    captionRichTextBuild,
    titleRichTextConfig,
    titleRichTextBuild,
    slideBodyRichTextBuild,
    slideBodyRichTextConfig,
    showSlideBody,
    accept,
    isUploading,
  } = props;

  const { translateFieldLegend } = useDefinedMessages();
  const [uploadElement, setUploadElement] = useState<HTMLButtonElement | null>(
    null
  );

  const [lastInsertedIndex, setLastInsertedIndex] = useState<number | null>(
    null
  );

  const [addToTop, setAddToTop] = useState<boolean>(false);

  /**
   * updating react during a drag operation or while scrolling can
   * cause some janky visual behaviors, so for performance reasons we are sidestepping
   * the framework to apply this class.
   */
  const slideListRef = useRef<HTMLDivElement | null>(null);
  const reportDragStart = useCallback(
    () => slideListRef.current?.classList.add("dragging"),
    []
  );
  const reportDragEnd = useCallback(
    () => slideListRef.current?.classList.remove("dragging"),
    []
  );
  const scrollHandlers = useScrollWhenDragging({
    dragZoneSize: 200,
    distancePerFrame: 100,
    msPerFrame: 120,
    scrollBehavior: "smooth",
  });

  const moveSlide = useCallback(
    (slide: DragData, dropIndex: number) => {
      if (slide.index === dropIndex) return;

      // once we remove the slide from its current position, the targeted drop position might have moved
      let adjustedDropIndex =
        slide.index < dropIndex ? dropIndex - 1 : dropIndex;

      onUpdate([
        [slide.index, 1],
        [adjustedDropIndex, 0, slide.slide],
      ]);
      setLastInsertedIndex(adjustedDropIndex);
    },
    [onUpdate]
  );

  let intl = useIntl();

  let showFileTooLargeMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Error uploading: File too large",
    }),
  });

  let showUploadErrorMessage = useToast({
    type: "error",
    children: intl.formatMessage({ defaultMessage: "Error uploading" }),
  });

  const addFromUpload = useCallback(
    (files: FileList, index?: number) => {
      //adds a placeholder slide while uploading
      let placeholderIndex = index ?? (addToTop ? 0 : slides.length);
      slides.splice(placeholderIndex, 0, {
        isPlaceholder: true,
        slideID: `placeholder_${placeholderIndex}`,
      });

      onUpload(files)
        .then((assets) => {
          if (assets) {
            if (addToTop) {
              onUpdate([
                [
                  index ? index : 0,
                  0,
                  ...assets
                    .filter((asset) => asset != null)
                    .map((asset) => ({
                      item: asset,
                    })),
                ],
              ]);
              setLastInsertedIndex(index ? index : assets.length - 1);
              setAddToTop(false);
            } else {
              onUpdate([
                [
                  index ? index : slides.length,
                  0,
                  ...assets
                    .filter((asset) => asset != null)
                    .map((asset) => ({
                      item: asset,
                    })),
                ],
              ]);
              setLastInsertedIndex(
                index ? index : slides.length + assets.length - 1
              );
            }
          }
        })
        // upload can reject
        .catch((e) => {
          if (e.message.includes("Request Entity Too Large")) {
            showFileTooLargeMessage();
          } else {
            showUploadErrorMessage();
          }
          //remove placeholder slide
          slides.splice(placeholderIndex, 1);
        });
    },
    [
      slides,
      onUpdate,
      onUpload,
      setLastInsertedIndex,
      addToTop,
      showFileTooLargeMessage,
      showUploadErrorMessage,
    ]
  );

  const addFromAssets = useCallback(
    (addToTop) => {
      selectAssets()
        .then((items) => {
          if (items && items.length) {
            if (addToTop) {
              onUpdate([[0, 0, ...items.map((item) => ({ item }))]]);
              setLastInsertedIndex(items.length - 1);
            } else {
              onUpdate([
                [slides.length, 0, ...items.map((item) => ({ item }))],
              ]);
              setLastInsertedIndex(slides.length + items.length - 1);
            }
          }
        })
        // adding assets can reject
        .catch(() => {});
    },
    [slides, onUpdate, selectAssets, setLastInsertedIndex]
  );

  const uploadAssetToSlide = useCallback(
    (files: FileList, slide: SlideData) => {
      onUpload(files)
        .then((assets) => {
          if (assets[0]) {
            const insertIndex = getInsertionIndex(slides, slide);
            onUpdate([
              [insertIndex, 1, update(slide, { item: { $set: assets[0] } })],
            ]);
            setLastInsertedIndex(insertIndex);
          }
        })
        // upload can reject
        .catch((e) => {
          if (e.message.includes("Request Entity Too Large")) {
            showFileTooLargeMessage();
          } else {
            showUploadErrorMessage();
          }
        });
    },
    [
      slides,
      onUpdate,
      onUpload,
      showFileTooLargeMessage,
      showUploadErrorMessage,
    ]
  );

  const addAssetToSlide = useCallback(
    (slide: SlideData) => {
      selectAssets({ limitSelection: 1 })
        .then((items) => {
          if (!(items && items.length)) {
            return;
          }
          const [item] = items;
          const insertIndex = getInsertionIndex(slides, slide);
          onUpdate([[insertIndex, 1, update(slide, { item: { $set: item } })]]);
          setLastInsertedIndex(insertIndex);
        })
        // adding assets can reject
        .catch(() => {});
    },
    [slides, onUpdate, selectAssets]
  );

  const onSlideChange = useCallback(
    (slide: SlideData, index: number) => {
      onUpdate([[index, 1, slide]]);
      setLastInsertedIndex(null);
    },
    [onUpdate]
  );

  const removeSlide = useCallback(
    (index) => {
      onUpdate([[index, 1]]);
      setLastInsertedIndex(null);
    },
    [onUpdate, setLastInsertedIndex]
  );

  const allowUploads = !!onUpload && !!accept?.length;

  let showIncorrectFileTypeMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Error uploading: Unsupported file type",
    }),
  });

  const [{ isHovered }, dropRef] = useDrop(
    () => ({
      accept: NativeTypes.FILE,
      canDrop: (item: { files: File[]; dataTransfer: DataTransfer }) => {
        let isCorrectFileType = item.files.every((file) =>
          accept?.includes(file.type)
        );
        if (!isCorrectFileType) {
          showIncorrectFileTypeMessage();
        }

        let canDrop = !!(allowUploads && isCorrectFileType);

        return canDrop;
      },
      collect: (monitor) => ({
        isHovered: monitor.isOver(),
      }),
      drop(item) {
        addFromUpload(item.dataTransfer.files);
      },
    }),
    [allowUploads, onUpload, addFromUpload]
  );

  const handleScrollToBottom = useCallback(() => {
    if (slideListRef.current) {
      const actions = computeScrollIntoView(slideListRef.current, {
        scrollMode: "if-needed",
        block: "end",
      });
      return actions.forEach(({ el, top }) => {
        el.scrollTo({ top, behavior: "smooth" });
      });
    }
    return null;
  }, []);

  const handleScrollToTop = useCallback(() => {
    if (slideListRef.current) {
      const actions = computeScrollIntoView(slideListRef.current, {
        scrollMode: "if-needed",
        block: "start",
      });
      return actions.forEach(({ el, top }) => {
        el.scrollTo({ top, behavior: "smooth" });
      });
    }
    return null;
  }, []);

  return (
    <Section>
      <Header>
        <Title>{translateFieldLegend("Gallery Slides")}</Title>
        <AddSlideButton
          size="medium"
          aria-label="Actions menu"
          disabled={isUploading}
          menu={{
            items: [
              {
                value: (
                  <>
                    <AddIcon size="small" />
                    <FormattedMessage
                      defaultMessage="Blank Slide"
                      description="Add a blank slide to a gallery"
                    />
                  </>
                ),
                role: "action",
                onClick: () => {
                  onUpdate([[0, 0, { item: null }]]);
                  setLastInsertedIndex(0);
                },
              },
              {
                value: (
                  <>
                    <AssetIcon size="small" />
                    <FormattedMessage
                      defaultMessage="Slide from Asset"
                      description="Select assets to be added as slides to a gallery"
                    />
                  </>
                ),
                role: "action",
                onClick: () => {
                  addFromAssets(true);
                },
              },
              {
                value: (
                  <>
                    <UploadIcon size="small" />
                    <FormattedMessage
                      defaultMessage="Slide from Upload"
                      description="Upload media to be added as slides to a gallery"
                    />
                  </>
                ),
                role: "action",
                onClick: () => {
                  setAddToTop(true);
                  uploadElement?.click();
                },
              },
            ],
            children: (element: JSX.Element) => {
              return <StyledItem>{element}</StyledItem>;
            },
          }}
        >
          <AddIcon size="regular" />
          <FormattedMessage
            defaultMessage="Add Slides"
            description="Menu for adding slides to a gallery"
          />
        </AddSlideButton>
      </Header>
      <SlideList ref={slideListRef} {...scrollHandlers}>
        {slides.length ? (
          slides.map((slide, index) => {
            return slide.isPlaceholder ? (
              <Placeholder>
                <Spinner
                  size="large"
                  $color={{
                    background: "var(--color-blue-40)",
                    foreground: "var(--color-blue-70)",
                  }}
                />
              </Placeholder>
            ) : (
              <DraggableSlide
                key={slide.slideID}
                index={index}
                slide={slide}
                cdnHost={cdnHost}
                selectAsset={addAssetToSlide}
                removeSlide={removeSlide}
                onChange={onSlideChange}
                onUpload={uploadAssetToSlide}
                isUploading={isUploading}
                isMostRecentlyInserted={index === lastInsertedIndex}
                titleRichTextConfig={titleRichTextConfig}
                titleRichTextBuild={titleRichTextBuild}
                captionRichTextConfig={captionRichTextConfig}
                captionRichTextBuild={captionRichTextBuild}
                onDragStart={reportDragStart}
                onDragEnd={reportDragEnd}
                moveSlide={moveSlide}
                addFromUpload={addFromUpload}
                allowUploads={allowUploads}
                accept={accept}
                slideBodyRichTextBuild={slideBodyRichTextBuild}
                slideBodyRichTextConfig={slideBodyRichTextConfig}
                showSlideBody={showSlideBody}
              />
            );
          })
        ) : (
          <NoSlidesMessage>
            <h1>
              <FormattedMessage
                defaultMessage="No Slides Yet"
                description="Message for empty list of gallery slides"
              />
            </h1>
            <span>
              <FormattedMessage
                defaultMessage="Select existing content items, upload images or start sketching
                  out your gallery with blank content."
                description="Descriptive text for how to add items to a gallery"
              />
            </span>
          </NoSlidesMessage>
        )}
        {slides.length > 1 && (
          <GallerySlideActions
            slides={slides}
            handleScrollToBottom={handleScrollToBottom}
            handleScrollToTop={handleScrollToTop}
            cdnHost={cdnHost}
            moveSlide={moveSlide}
            onUpdate={onUpdate}
            onBatchUpdate={onBatchUpdate}
            isBatchUpdating={isBatchUpdating}
            creditRichTextConfig={titleRichTextConfig}
            creditRichTextBuild={titleRichTextBuild}
          />
        )}

        <ActionsBlock ref={dropRef} $isHovered={isHovered}>
          <ActionButton
            onClick={(evt) => {
              evt.preventDefault();
              addFromAssets(false);
            }}
            disabled={isUploading}
          >
            <AssetIcon size="regular" />
            <FormattedMessage
              defaultMessage="Select Assets"
              description="Select assets to be added as slides to a gallery"
            />
          </ActionButton>
          <UploadButton
            ref={setUploadElement}
            onChange={addFromUpload}
            accept={accept?.join(",") ?? ""}
            multiple={true}
            disabled={isUploading}
          />
          <ActionButton
            onClick={(evt) => {
              evt.preventDefault();
              onUpdate([[slides.length, 0, { item: null }]]);
              setLastInsertedIndex(slides.length);
            }}
            disabled={isUploading}
          >
            <AddIcon size="regular" />
            <FormattedMessage
              defaultMessage="Blank Slide"
              description="Add a blank slide to a gallery"
            />
          </ActionButton>
          <UploadMessage>
            <FormattedMessage defaultMessage="Drag media here to upload" />
          </UploadMessage>
        </ActionsBlock>
      </SlideList>
    </Section>
  );
}
GallerySlideList.displayName = "GallerySlideList";

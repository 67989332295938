import { useCallback, useMemo, useContext } from "react";
import { SearchMultiselect, Field } from "@components";
import { useDefinedMessages, useDebouncedCallback, useUniqueId } from "@hooks";
import {
  Category,
  ControlProps,
  FormFor_form_controls_TagSelectorFormControl,
  CategoryTaxonomyFields as TCategoryTaxonomy,
  Option,
  GetTagSuggestions,
  GetTagSuggestionsVariables,
  BodyInput,
  GetConfigs,
  GetConfigsVariables,
} from "@types";
import { Queries } from "@gql";
import { useLazyQuery, useQuery } from "@apollo/client";
import styled from "styled-components";
import { errorsForField } from "@lib";
import { useParams } from "react-router-dom";
import { byCopilotCode } from "@fixtures";
import { SnowplowContext } from "@contexts";

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-xxs) 0;
  grid-template-rows: auto auto;
  grid-template-areas:
    "label"
    "control";
`;

const SEARCH_DEBOUNCE_INTERVAL = 250; // milliseconds

interface CategoryData {
  name: string | null;
  id: string;
  slug?: string;
  meta?: {
    childCount: number | null;
  } | null;
}

function normalizeCategoryData(
  categories: (CategoryData | null)[]
): Category[] {
  const transformedCategories: Category[] = categories
    ?.filter((s): s is CategoryData => Boolean(s))
    .map((category) => ({
      id: category.id,
      name: category.name ?? "",
      slug: "",
      hierarchy: [],
      childCount: category.meta?.childCount ?? undefined,
      isTag: false,
    }));
  return transformedCategories;
}

function serializeCategories(categories: Category[]): CategoryData[] {
  return categories.map(({ id, name, slug, hierarchy }) => ({
    id,
    name,
    slug,
    hierarchy: [...hierarchy].reverse(),
    root: hierarchy[0],
  }));
}

function categoryToOption(category: Category): Option<Category> {
  return {
    key: category.id,
    value: category,
    label: category.name || "",
  };
}

export function TagSelector(
  props: ControlProps<FormFor_form_controls_TagSelectorFormControl>
) {
  const {
    currentOrganization,
    model,
    errors,
    name,
    labelKey,
    setValue,
    multiple,
    getBodyRefValue,
    currentUser,
  } = props;
  const uniqueId = useUniqueId();
  const tagErrors = useMemo(
    () => errorsForField(errors, "categoriesTags"),
    [errors]
  );

  const { translateFieldName } = useDefinedMessages();

  const { trackComponentEvent } = useContext(SnowplowContext);

  const modelValue = useMemo(() => {
    return (model[
      ["CuratedSearch", "Article", "BundleSearch"].includes(
        model.__typename as string
      )
        ? name
        : "categoryTaxonomies"
    ] ?? []) as TCategoryTaxonomy[];
  }, [name, model]);

  const selections = useMemo(
    () =>
      modelValue
        .find(({ taxonomy }) => taxonomy === "tags")
        ?.categories.map((item) => {
          const { id, slug, name, hierarchy } = item;
          const category = {
            id,
            slug,
            name,
            hierarchy,
            root: hierarchy[0],
          } as Category;

          return categoryToOption(category);
        }),
    [modelValue]
  );

  const [_lazySearch, { data: searchData, loading, error }] = useLazyQuery(
    Queries.CATEGORY_SEARCH
  );

  const performSearch = useDebouncedCallback((query?: string) => {
    if (!query) return;
    _lazySearch({
      variables: {
        organizationId: currentOrganization.organizationId,
        filters: {
          q: query || "",
          hierarchy: "tags",
          d_o: "and",
          isroot: false,
          limit: 50,
        },
      },
    });
  }, SEARCH_DEBOUNCE_INTERVAL);

  const searchResult = useMemo(
    () => ({
      loading,
      errors: error ? [error] : undefined,
      data: searchData ? normalizeCategoryData(searchData.categorySearch) : [],
    }),
    [loading, error, searchData]
  );

  let params = useParams() as {
    copilotCode: string;
    contentType: string;
    id: string;
  };

  let { data: configs } = useQuery<GetConfigs, GetConfigsVariables>(
    Queries.GET_CONFIGS,
    {
      variables: {
        organizationId: currentOrganization.organizationId,
        userId: currentUser.id,
      },
    }
  );

  //This is the feature flag for tag suggestions
  let enableTagSuggestions = configs?.configs.enableSuggestedTags ?? undefined;

  //This is the feature flag to use the new tag suggestions API, Tagnificent
  let useTagnificent = configs?.configs.enableTagnificent ?? false;

  const [submitGetTagSuggestions, tagSuggestionResult] = useLazyQuery<
    GetTagSuggestions,
    GetTagSuggestionsVariables
  >(Queries.GET_TAG_SUGGESTIONS);

  const suggestedTagsSearch = useCallback(async () => {
    let modelBody = model?.body as BodyInput;
    submitGetTagSuggestions({
      variables: {
        organizationId: byCopilotCode(params.copilotCode).organizationId,
        model: {
          hed: (model?.hed as string) ?? "",
          dek: (model?.dek as string) ?? "",
          body: {
            content: getBodyRefValue ? getBodyRefValue() : "",
            textFormat: modelBody?.textFormat ?? "MARKDOWN",
          },
          authorName: (model?.authorName as string) ?? "",
        },
        useV2: useTagnificent,
      },
    });
  }, [submitGetTagSuggestions, params, model, getBodyRefValue]);

  const tagSuggestionSearchResult = useMemo(
    () => ({
      loading: tagSuggestionResult.loading,
      data: tagSuggestionResult.data
        ? normalizeCategoryData(
            tagSuggestionResult.data?.getTagSuggestions?.categories ?? []
          )
        : [],
    }),
    [loading, error, searchData, tagSuggestionResult]
  );

  const onChange = useCallback(
    (selections: Category[]) => {
      setValue(
        ["CuratedSearch", "Article", "BundleSearch"].includes(
          model.__typename as string
        )
          ? name
          : "categoryTaxonomies",
        [
          ...modelValue.filter(({ taxonomy }) => taxonomy !== "tags"),
          {
            __typename: "CategoryTaxonomy",
            taxonomy: "tags",
            categories: serializeCategories(selections),
          },
        ]
      );
    },
    [name, modelValue, setValue, model.__typename]
  );

  return (
    <Wrapper
      id={`TagSelector-${uniqueId}`}
      label={translateFieldName(labelKey ?? name ?? "Tags")}
      errors={tagErrors}
    >
      <SearchMultiselect
        aria-invalid={!!tagErrors.length}
        selections={selections || []}
        search={performSearch}
        searchResults={searchResult.data.map(categoryToOption)}
        loading={searchResult.loading}
        searchErrors={searchResult.errors}
        multiple={multiple !== false}
        onChange={onChange}
        hasSuggestedTags={enableTagSuggestions}
        suggestedTagsSearch={suggestedTagsSearch}
        suggestedTagsResults={tagSuggestionSearchResult.data.map(
          categoryToOption
        )}
        suggestedTagsLoading={tagSuggestionSearchResult.loading}
        sortable={true}
        trackEvent={trackComponentEvent}
      />
    </Wrapper>
  );
}
TagSelector.displayName = "Control(TagSelector)";

import { useCallback } from "react";

export const useClipboardCopy = (str: string) =>
  useCallback(() => {
    if (str) {
      if (document.execCommand) {
        const el = document.createElement("textarea");
        el.style.width = "0";
        el.style.height = "0";
        // Mark this element as a "transient" element
        // so hooks like useFocusTrap will ignore it
        el.dataset.transient = "true";
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      } else if (navigator.clipboard) {
        navigator.clipboard.writeText(str);
      } else {
        let event = new CustomEvent("copy", { detail: str });
        document.dispatchEvent(event);
      }
    }
  }, [str]);

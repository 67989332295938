import { ARIA, Button, RadioGroup, Select, Toast } from "@components";
import { AnimatedEllipsisIcon } from "@condenast/gemini/icons";
import { useCallback, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";

import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import PlainTextRenderer from "@atjson/renderer-plain-text";

import type { PresetData } from "../types";

const Dialog = styled(ARIA.Dialog)`
  overflow: visible;
`;

const DialogBody = styled.div`
  padding: var(--spacing-xs) var(--spacing-md) var(--spacing-sm);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;

const ButtonWithLoadingState = styled(Button)`
  svg {
    left: auto;
    align-self: center;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  label {
    font: ${(props) => props.theme.FontStatement};
  }
`;

function toPresetValue(preset: PresetData) {
  return `${preset.contentType}/${preset.id}`;
}

function toPresetLabel(preset: PresetData) {
  return PlainTextRenderer.render(
    CopilotMarkdownSource.fromRaw(preset.title || "")
  );
}

function fromPresetValue(value: string, presets: PresetData[]) {
  const [contentType, id] = value?.split("/");
  return presets.find((p) => p.contentType === contentType && p.id === id);
}

export function CreateFromPresetDialog(props: {
  contentTypeLabel: string;
  path: string;
  onClose: () => void;
  onSubmit: (data: { to: string } | PresetData) => void;
  presets: PresetData[];
  loading?: boolean;
  hasError?: boolean;
}) {
  const {
    contentTypeLabel,
    onClose,
    onSubmit,
    presets,
    path,
    loading,
    hasError,
  } = props;
  const intl = useIntl();
  const [createOption, setCreateOption] = useState<string>("blank");
  const [presetOption, setPresetOption] = useState<PresetData | undefined>(
    undefined
  );
  const options = useMemo(
    () =>
      presets
        .map((preset) => ({
          value: toPresetValue(preset),
          label: toPresetLabel(preset),
        }))
        .sort(({ label: label1 }, { label: label2 }) => {
          return label1.localeCompare(label2, intl.locale);
        }),
    [presets, intl.locale]
  );
  const onSelectChange = useCallback(
    (selectValue: string | undefined) => {
      if (selectValue) {
        const preset = fromPresetValue(selectValue, presets);
        setPresetOption(preset);
      } else {
        setPresetOption(undefined);
      }
    },
    [setPresetOption, presets]
  );
  return (
    <Dialog
      title={intl.formatMessage(
        { defaultMessage: "Create {contentTypeLabel}" },
        { contentTypeLabel }
      )}
      size="narrow"
      cancelButton={
        <Button>
          <FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button in create from preset dialog"
          />
        </Button>
      }
      submitButton={
        <ButtonWithLoadingState
          treatment="primary"
          type="submit"
          disabled={loading || (createOption === "preset" && !presetOption)}
          onClick={() => {
            if (createOption === "preset" && presetOption) {
              onSubmit(presetOption);
            } else {
              onSubmit({ to: path });
            }
          }}
        >
          {loading ? (
            <AnimatedEllipsisIcon size="small" />
          ) : (
            <FormattedMessage
              defaultMessage={"Create"}
              description="Submit button in create from preset dialog"
            />
          )}
        </ButtonWithLoadingState>
      }
      onClose={onClose}
    >
      <DialogBody>
        {hasError && (
          <Toast type="error">
            <FormattedMessage
              defaultMessage="Sorry, something went wrong. Try reloading or please contact support."
              description="Generic error given when a network request fails"
            />
          </Toast>
        )}
        <StyledRadioGroup
          id="preset-create-options"
          value={createOption}
          direction="column"
          aria-label={intl.formatMessage({
            defaultMessage: "Select an option for creating a new item",
            description:
              "Accessibility label for create options in the preset dialog",
          })}
          options={[
            {
              label: (
                <FormattedMessage
                  defaultMessage="Start from a blank {contentTypeLabel}"
                  description="Option to create a blank content item in the preset dialog"
                  values={{ contentTypeLabel }}
                />
              ),
              value: "blank",
            },
            {
              label: (
                <FormattedMessage
                  defaultMessage="Start from an existing template"
                  description="Option to create a content item from a preset in the preset dialog"
                />
              ),
              value: "preset",
              disabled: !options.length,
            },
          ]}
          onChange={setCreateOption}
        />
        <FormattedMessage
          defaultMessage="Save time by starting with certain fields already filled in"
          description="Explanation text for the option to create a content item from a preset"
        />
        <Select
          disabled={!options.length || createOption !== "preset"}
          aria-label={intl.formatMessage({
            defaultMessage: "Preset templates",
            description:
              "Accessibility label for preset template select in the preset dialog",
          })}
          multiple={false}
          id="preset-select-options"
          options={options}
          onChange={onSelectChange}
          value={presetOption ? toPresetValue(presetOption) : undefined}
          placeholder={intl.formatMessage({
            defaultMessage: "Select Template",
            description:
              "Placeholder for preset template dropdown in the preset dialog",
          })}
        />
      </DialogBody>
    </Dialog>
  );
}

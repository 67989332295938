import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import {
  required,
  geo,
  optional,
  array,
  url,
  email,
  markdownLinks,
} from "@condenast/copilot-validators";
import { channelsOrSections, publishURI } from "../validators";

import type { ValidationContext, ValidationEnvironment } from "@lib";
import type { FormFor_content_Activity } from "@types";

const { object } = validators;

const activityValidators = object({
  name: required(),
  subtype: required()
    .on("save")
    .catch((errors) =>
      errors.map(({ path }) => ({
        path,
        message: { details: null, name: "blank" },
      }))
    ),
  geo: optional(geo()),
  url: optional(array(optional(url()))),
  email: optional(array(optional(email()))),
  shortDescription: optional(markdownLinks()),
  categoryTaxonomies: channelsOrSections().on("publish"),
}).andAlso(publishURI().on("publish"));

export default function validateActivity(
  activity: FormFor_content_Activity,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(activity, build(activityValidators), context, env);
}

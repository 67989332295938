import { useQueryParams } from "@hooks";

export function useLocationSort(defaultSort?: string) {
  let [queryParams] = useQueryParams<{ sort?: string }>();
  let currentSort = queryParams.sort ?? defaultSort ?? "";
  let direction: "initial" | "reverse" =
    currentSort[0] === "-" ? "reverse" : "initial";
  const sortedField = currentSort.replace(/^-/, "");
  return [sortedField, direction] as const;
}

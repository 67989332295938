import { defineMessages } from "react-intl";

export const PRESET_NAMES = defineMessages({
  "Inset Inverse - top story": {
    defaultMessage: "Inset Inverse",
  },
  "Text-Overlay - top story": {
    defaultMessage: "Text-Overlay",
  },
  "Standard - top story": {
    defaultMessage: "Standard",
  },
  "Text-Only - top story": {
    defaultMessage: "Text-Only",
  },
  "Inset Standard - top story": {
    defaultMessage: "Inset Standard",
  },
  "Inverse - top story": {
    defaultMessage: "Inverse",
  },
  "Text-Overlay-Video - top story": {
    defaultMessage: "Text-Overlay Video",
  },
});

import type { PublishHelper } from "../types";
import type { CategoryTaxonomyFields } from "@types";

/**
 * Get the primary subchannel or subsection. This is the first
 * child of the primary channel that we can find (not necessarily
 * present in the primary category)
 *
 * Ex: if the categories are:
 * - /channels/gear
 * - /channels/news/technology
 * - /channels/gear/products/cameras
 * - /channels/gear/reviews
 *
 * Then the primary channel is `gear` and the primary subchannel
 * is `products`, the first child of `gear` found in any category
 *
 * @param model
 * @returns string | null
 */
export const primarySubChannel: PublishHelper = (model) => {
  const categoryTaxonomies = model["categoryTaxonomies"] as
    | CategoryTaxonomyFields[]
    | null;
  const taxonomy =
    categoryTaxonomies?.find(({ taxonomy }) => taxonomy === "sections") ??
    categoryTaxonomies?.find(({ taxonomy }) => taxonomy === "channels");
  const hierarchy = taxonomy?.categories?.[0]?.hierarchy;

  const primaryChannelCategory = hierarchy?.[hierarchy.length - 2];
  const primarySubchannelCategory =
    primaryChannelCategory &&
    taxonomy?.categories?.find(
      ({ hierarchy }) =>
        hierarchy.length > 2 &&
        hierarchy[hierarchy.length - 2].slug === primaryChannelCategory.slug
    );
  return primarySubchannelCategory?.slug ?? null;
};

import { useIntl } from "react-intl";
import styled from "styled-components";
import {
  PublishInfoFields,
  PublishData,
  DistributionChannelConfig,
  DistributionChannelName,
  findDistributionChannelsByName,
} from "@types";
import { PublishedIcon } from "@condenast/gemini/icons";

export const Wrapper = styled.div`
  display: flex;
  background: var(--color-green-70);
  border-radius: var(--spacing-xxs);
  padding: var(--spacing-xxs);
  justify-content: center;
  align-items: center;
  margin-right: var(--spacing-xs);
`;

// TODO: extract smaller tag CSS var to theme token
const PublishStatusLabel = styled.span`
  font: var(--font-smaller-tag);
  color: var(--color-gray-1);
  margin: 0 0 0 var(--spacing-xxs);
`;

export function MobilePublishStatus(props: {
  className?: string;
  publishInfo: PublishInfoFields | null;
  publishData: PublishData;
  distributionChannelsConfig?: DistributionChannelConfig[];
}) {
  const { className, publishInfo, publishData, distributionChannelsConfig } =
    props;

  const intl = useIntl();
  const distributionChannels = publishData.distributionChannels;
  const pubDate = publishInfo?.pubDate
    ? new Date(publishInfo.pubDate)
    : undefined;
  const publishMobileAppConfig = findDistributionChannelsByName(
    DistributionChannelName.MobileApp,
    distributionChannelsConfig
  );
  const publishMobileApp = findDistributionChannelsByName(
    DistributionChannelName.MobileApp,
    distributionChannels
  );

  return !pubDate || isNaN(pubDate.getTime()) ? (
    <></>
  ) : (
    <>
      {publishMobileAppConfig?.enabled && publishMobileApp && (
        <Wrapper className={className} id="MobileAppStatus">
          <PublishedIcon size="small" />
          <PublishStatusLabel>
            {
              <>
                {intl.formatMessage({
                  defaultMessage: "Mobile App",
                  description: "Publish status for mobile app",
                })}
              </>
            }
          </PublishStatusLabel>
        </Wrapper>
      )}
    </>
  );
}

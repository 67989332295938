import { useCallback, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Page, DashboardList, DashboardUploadList, Button } from "@components";
import {
  RefreshIcon,
  AnimatedEllipsisCircleIcon,
} from "@condenast/gemini/icons";
import {
  GetCurrentUser_currentUser,
  SearchFilters,
  Organization,
  DashboardRefresh,
  defaultRefreshType,
  GetConfigs,
} from "@types";
import styled from "styled-components";
import { useToast } from "@hooks";
import { DashboardFooter } from "./DashboardFooter";

const ContentPage = styled(Page)`
  background: ${(props) => props.theme.PrimaryDisabledBackground};
  min-height: calc(100vh - var(--spacing-xxl));
  margin: 0 auto;
  width: 100%;
  max-width: min(calc(var(--spacing-xxl) * 25), 100vw);
  @media (max-width: 600px) {
    padding: unset;
    border-radius: unset;
  }
`;

const HomePage = styled(Page)`
  padding: var(--spacing-sm) 0;
  max-width: calc(var(--spacing-xxl) * 50);
  @media (max-width: 992px) {
    padding: unset;
  }
  @media (max-width: 768px) {
    padding: var(--spacing-sm) var(--spacing-sm);
  }
  @media (max-width: 600px) {
    padding: unset;
  }
`;

const MainContainer = styled.div`
  padding: var(--spacing-md) var(--spacing-lg);
  &:focus {
    outline: none;
    box-shadow: ${(props) => props.theme.FocusRing};
  }
  @media (max-width: 1280px) {
    padding: unset;
    padding: var(--spacing-md) var(--spacing-md);
  }
  @media (max-width: 768px) {
    padding: unset;
  }
  @media (max-width: 600px) {
    padding: var(--spacing-sm) 0;
    padding-bottom: unset;
  }
`;

const Title = styled.h2`
  font: ${(props) => props.theme.FontPageHeading};
  color: ${(props) => props.theme.Color};
  line-height: 40px;
  flex: auto;
  @media (max-width: 600px) {
    border-radius: unset;
  }
`;

const Body = styled.div`
  display: flex;
  gap: var(--spacing-md);
  gap: var(--spacing-sm);
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
`;

const Header = styled.div`
  display: flex;
  @media (max-width: 600px) {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
`;

const CustomRefreshIcon = styled(RefreshIcon)`
  stroke: ${(props) => props.theme.SecondaryColor};
`;

const RefreshButton = styled(Button)`
  align-items: center;
`;

const INITIAL_REFRESH_STATUS = {
  scheduled: {
    name: "scheduled",
    status: false,
    refreshState: "idle",
  },
  mywork: {
    name: "mywork",
    status: false,
    refreshState: "idle",
  },
  uploads: {
    name: "uploads",
    status: false,
    refreshState: "idle",
  },
} as DashboardRefresh;

export const Dashboard = (props: {
  user: GetCurrentUser_currentUser;
  currentOrganization: Organization;
  config?: GetConfigs | undefined;
}) => {
  let intl = useIntl();
  let { user, currentOrganization, config } = props;
  const [refreshAPIs, setRefreshAPIs] = useState<DashboardRefresh>(
    INITIAL_REFRESH_STATUS
  );
  const [showMessage, setShowMessage] = useState(false);

  const defaultScheduledFilters = {
    display: "all",
    nottypes:
      "externallink,photo,cnevideo,pushnotification,categories,clip,cartoon,audiofile,product,contributor",
    sort: "publishdate desc",
    archived: false,
    notcategory: "copilot/preset",
    privilege_user: user.role === "contributor" ? user.id : undefined,
  } as SearchFilters;

  let storedFilters = localStorage.getItem(`filters-scheduled`);
  let parseFilters = storedFilters && JSON.parse(storedFilters);
  storedFilters =
    parseFilters &&
    Object.keys(parseFilters).length &&
    parseFilters[currentOrganization.metadata.copilotCode];

  let myWorkFilters = localStorage.getItem(`filters-mywork`);
  let parseMyWorkFilters = myWorkFilters && JSON.parse(myWorkFilters);
  let storedMyWorkFilters =
    parseMyWorkFilters &&
    Object.keys(parseMyWorkFilters).length &&
    parseMyWorkFilters[currentOrganization.metadata.copilotCode];

  const defaultMyWorkFilters = {
    display: "all",
    sort: "revisiondate desc",
    archived: false,
    notcategory: "copilot/preset",
    privilege_user: user.role === "contributor" ? user.id : undefined,
  } as SearchFilters;

  const scheduled_filter =
    (storedFilters as SearchFilters) || defaultScheduledFilters;

  const mywork_filter = storedMyWorkFilters as SearchFilters;

  const checkRefreshStatus = () =>
    Object.keys(refreshAPIs).every((k) => {
      const section = refreshAPIs[k] as defaultRefreshType;
      if (section.status) {
        return false;
      }
      return true;
    });

  const refreshDashboardAPIs = () => {
    let fetchInProgress = false;
    if (!checkRefreshStatus()) {
      fetchInProgress = true;
      return;
    }

    if (!fetchInProgress) {
      let allSegments = { ...refreshAPIs } as DashboardRefresh;
      Object.keys(allSegments).forEach((seg) => {
        allSegments[seg] = {
          ...allSegments[seg],
          status: true,
        } as defaultRefreshType;
      });
      setRefreshAPIs(allSegments);
    }
  };

  let RefreshButtonWrapper;
  if (checkRefreshStatus()) {
    RefreshButtonWrapper = (
      <RefreshButton onClick={refreshDashboardAPIs} treatment="borderless">
        <CustomRefreshIcon size="small" />
        <FormattedMessage defaultMessage="Refresh" />
      </RefreshButton>
    );
  } else {
    RefreshButtonWrapper = (
      <RefreshButton disabled={true} treatment="borderless">
        <AnimatedEllipsisCircleIcon size="small" />
        <FormattedMessage defaultMessage="Refresh in progress" />
      </RefreshButton>
    );
  }

  const showSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Success",
      description:
        "Once all the API's on dashboard and fetched and sections are refreshed, 'Success' toast is shown.",
    }),
  });

  const checkRefreshState = useCallback(
    () =>
      Object.keys(refreshAPIs).every((k) => {
        const section = refreshAPIs[k] as defaultRefreshType;
        if (section.refreshState === "success") return true;
        return false;
      }),
    [refreshAPIs]
  );

  useEffect(() => {
    if (checkRefreshState() && !showMessage) {
      setShowMessage(true);
    }
  }, [refreshAPIs, checkRefreshState, showMessage]);

  useEffect(() => {
    if (checkRefreshState() && showMessage) {
      setShowMessage(false);
      showSuccessMessage();
      let allSegments = { ...refreshAPIs } as DashboardRefresh;
      Object.keys(allSegments).forEach((seg) => {
        allSegments[seg] = {
          ...allSegments[seg],
          refreshState: "idle",
        } as defaultRefreshType;
      });
      setRefreshAPIs(allSegments);
    }
  }, [showMessage, checkRefreshState, showSuccessMessage, refreshAPIs]);

  const homepageTitle = intl.formatMessage({
    defaultMessage: "Home",
    description: "Page title of the Home page",
  });

  return (
    <ContentPage title={homepageTitle}>
      <HomePage title={homepageTitle}>
        <MainContainer>
          <Header>
            <Title>{homepageTitle}</Title>
            {RefreshButtonWrapper}
          </Header>
          <Body>
            <DashboardList
              currentOrganization={props.currentOrganization}
              label={intl.formatMessage({
                defaultMessage: "Publishing Schedule",
                description:
                  "Label for the Scheduled Publish section of the homepage",
              })}
              queryFilter={{
                ...defaultScheduledFilters,
                ...scheduled_filter,
              }}
              headerInfo={{ info: true, message: "This is a info icon" }}
              headerFilter={true}
              paginationInfo={true}
              filterType={refreshAPIs.scheduled.name}
              refreshAPIs={refreshAPIs}
              setRefreshAPIs={setRefreshAPIs}
              defaultFilters={defaultScheduledFilters}
              currentUser={user}
              cssOrder={2}
              config={config}
              emptySearchResultMessage={intl.formatMessage({
                defaultMessage: "No Recent Activity Found",
                description: "Empty message for the My Work section",
              })}
            />
            <DashboardList
              currentOrganization={props.currentOrganization}
              label={intl.formatMessage({
                defaultMessage: "Activity",
                description: "Label for the Activity section of the homepage",
              })}
              queryFilter={mywork_filter ? mywork_filter : defaultMyWorkFilters}
              headerFilter={true}
              paginationInfo={false}
              filterType={refreshAPIs.mywork.name}
              refreshAPIs={refreshAPIs}
              setRefreshAPIs={setRefreshAPIs}
              defaultFilters={defaultMyWorkFilters}
              currentUser={user}
              config={config}
              emptySearchResultMessage={intl.formatMessage({
                defaultMessage: "No Recent Activity Found",
                description: "Empty message for the My Work section",
              })}
              cssOrder={1}
            />
          </Body>
          <DashboardUploadList
            currentUser={user}
            currentOrganization={props.currentOrganization}
            filterType={refreshAPIs.uploads.name}
            refreshAPIs={refreshAPIs}
            setRefreshAPIs={setRefreshAPIs}
          />
          <DashboardFooter />
        </MainContainer>
      </HomePage>
    </ContentPage>
  );
};
Dashboard.displayName = "Dashboard";

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";
import { required } from "@condenast/copilot-validators";

import type { ValidationEnvironment } from "@lib";
import type { FormFor_content_Cookbook } from "@types";

const { object } = validators;

const cookbookValidators = object({
  hed: required(),
  copyrightDisplay: required(),
});

export default function validateCookbook(
  cookbook: FormFor_content_Cookbook,
  context: "publish",
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(cookbook, build(cookbookValidators), context, env);
}

import { useMutation } from "@apollo/client";
import { EditPage, ThumbnailField, Spinner } from "@components";
import { Mutations, Queries } from "@gql";
import { useDefinedMessages } from "@hooks";
import { MEDIA_MAP } from "@lib";
import {
  ControlProps,
  ContentConnectionFields,
  ContentSummaryFields,
  FormFor_form_controls_ThumbnailFormControl,
  UploadMedia,
  UploadMediaVariables,
  FieldNames,
  MediaOverrideConnection,
  VideosLede,
  GetConfigs,
  GetConfigsVariables,
  MediaOverrides,
  ContentSummary,
} from "@types";
import { normalizeContentSummary, serializeContentSummary, get } from "@lib";
import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { AssetSelector } from "../-private";
import styled from "styled-components";

const COLLECTIONS_WITH_VIDEO_MEDIA_OVERRIDES = ["articles"];

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
export function Thumbnail(
  props: ControlProps<FormFor_form_controls_ThumbnailFormControl>
) {
  const {
    id,
    name,
    labelKey,
    errors,
    assetSelectorConfiguration,
    autogeneratePath,
    currentOrganization,
    currentUser,
    model,
    setValue,
  } = props;
  let { data: configs, loading: configLoading } = useQuery<
    GetConfigs,
    GetConfigsVariables
  >(Queries.GET_CONFIGS, {
    variables: {
      organizationId: currentOrganization.organizationId,
      userId: currentUser.id,
    },
  });

  const { contentType = "" } = useParams();
  const { translateFieldName, translateContentType } = useDefinedMessages();
  const authorName = `${currentUser.firstName} ${currentUser.lastName}`;

  const [upload, uploadResult] = useMutation<UploadMedia, UploadMediaVariables>(
    Mutations.UPLOAD_MEDIA
  );
  const [assetSelectorToggle, setAssetSelectorToggle] = useState(false);
  const [cneVideoEditModal, setCneVideoEditModal] = useState(false);
  const [mediaLede, setMediaLede] = useState<VideosLede | null>(null);
  const [mediaLedeId, setMediaLedeId] = useState<string | null>(null);

  const modelValue = model[name] as ContentConnectionFields;
  const value = useMemo(
    () => (modelValue?.edges?.[0]?.node as ContentSummaryFields) ?? undefined,
    [modelValue]
  );
  const normalizedValue = useMemo(
    () => normalizeContentSummary(value, translateContentType),
    [value]
  );
  const uploadTypes = useMemo(
    () => [
      ...(assetSelectorConfiguration?.contentTypes?.includes("photo")
        ? MEDIA_MAP.photos
        : []),
      ...(assetSelectorConfiguration?.contentTypes?.includes("clip")
        ? MEDIA_MAP.clips
        : []),
    ],
    [assetSelectorConfiguration]
  );

  const autogenerateValue = autogeneratePath
    ? (model[autogeneratePath] as ContentConnectionFields)
    : null;

  const autofill = useCallback(() => {
    if (autogenerateValue?.edges?.length) {
      setValue(name, autogenerateValue);
    }
  }, [autogenerateValue, name, setValue]);

  useEffect(() => {
    if (
      normalizedValue &&
      normalizedValue.contentType === "cnevideo" &&
      name === "videosLede"
    ) {
      if (normalizedValue?.cneId) {
        setMediaLede({
          id: normalizedValue?.id,
          asset: {
            filename: normalizedValue.asset?.publicURL ?? "",
          },
          cneId: normalizedValue?.cneId,
          feedGuid: normalizedValue?.feedGuid ?? "",
          embedUrl: normalizedValue?.embedUrl ?? "",
          transcriptUrl: normalizedValue?.transcriptUrl ?? "",
        });
        (!mediaLedeId || mediaLedeId !== normalizedValue.id) &&
          setMediaLedeId(normalizedValue?.id);
      }
    }
    if (
      normalizedValue &&
      normalizedValue.contentType === "photo" &&
      name === "photosLede"
    ) {
      (!mediaLedeId || mediaLedeId !== normalizedValue.id) &&
        setMediaLedeId(normalizedValue?.id);
    }
  }, [normalizedValue, name, model, mediaLedeId]);

  const loadEditModal = () => setCneVideoEditModal(false);

  const removeAsset = (mediaLedeId: string) => {
    const removeElement = (
      OverridesValue: MediaOverrideConnection,
      ledeId: string,
      ledeName: string
    ) => {
      setValue("relMediaOverrides", {
        ...OverridesValue,
        edges: OverridesValue.edges.filter(
          (el) => el.node.id !== ledeId && el.relName !== ledeName
        ),
      });
      setValue(name, {
        ...modelValue,
        edges: [],
      });
      const mediaOverrides = model?.mediaOverrides as MediaOverrides[];
      if (name === "photosLede" && mediaOverrides.length) {
        setValue("mediaOverrides", []);
      }
    };

    const OverridesValue = get(
      model,
      "relMediaOverrides"
    ) as MediaOverrideConnection;
    if (OverridesValue?.edges.length) {
      removeElement(OverridesValue, mediaLedeId ? mediaLedeId : "", name);
    } else {
      setValue(name, {
        ...modelValue,
        edges: [],
      });
    }
  };

  const submitAssets = (assets: ContentSummary[]) => {
    const relMediaOverrides = get(
      model,
      "relMediaOverrides"
    ) as MediaOverrideConnection;
    if (name === "photosLede" && relMediaOverrides?.edges.length) {
      const newMediaOverridesV2 = relMediaOverrides?.edges?.filter(
        (el) =>
          el.__typename !== "PhotosOverrideEdge" && el.relName !== "photosLede"
      );
      setValue("relMediaOverrides", {
        ...relMediaOverrides,
        edges: newMediaOverridesV2,
      });
    }
    setValue(props.name, {
      ...modelValue,
      edges: assets.map((asset) => ({
        node: serializeContentSummary(asset),
      })),
    });
    setAssetSelectorToggle(false);
  };

  const enableVideoMediaOverrides =
    name === "videosLede" &&
    !!configs?.configs?.enableMediaOverridesV2 &&
    COLLECTIONS_WITH_VIDEO_MEDIA_OVERRIDES.includes(contentType);

  if (configLoading) {
    return (
      <SpinnerWrapper>
        <Spinner size="large" />
      </SpinnerWrapper>
    );
  }
  if (cneVideoEditModal && mediaLede?.id && enableVideoMediaOverrides) {
    return (
      <EditPage
        videosLede={mediaLede}
        goBack={loadEditModal}
        cancelCneUpload={loadEditModal}
        setValue={setValue}
        model={model}
        selectedAssetId={mediaLede?.id}
        assetUpdateToModel={() => setCneVideoEditModal(false)}
      />
    );
  } else if (assetSelectorToggle && assetSelectorConfiguration) {
    return (
      <AssetSelector
        config={assetSelectorConfiguration}
        currentOrganizationID={currentOrganization.organizationId}
        onSubmit={submitAssets}
        model={model}
        setValue={setValue}
        onClose={() => setAssetSelectorToggle(false)}
        cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
        currentUser={currentUser}
        enableVideoMediaOverrides={enableVideoMediaOverrides}
      />
    );
  } else {
    return (
      <ThumbnailField
        id={`ThumbnailField__${id}`}
        label={
          labelKey ? translateFieldName(labelKey) : translateFieldName(name)
        }
        value={normalizedValue}
        fieldName={name as FieldNames}
        cdnHost={`https://${currentOrganization.metadata.mediaDomain}`}
        editCneAsset={() => setCneVideoEditModal(true)}
        selectAsset={() => setAssetSelectorToggle(true)}
        removeAsset={() => removeAsset(mediaLedeId ?? "")}
        autofill={autogenerateValue?.edges?.length ? autofill : undefined}
        errors={errors}
        isUploading={uploadResult.loading}
        showEdit={
          configs?.configs?.enableMediaOverridesV2
            ? configs?.configs?.enableMediaOverridesV2
            : false
        }
        onUpload={(files) =>
          upload({
            variables: {
              organizationId: currentOrganization.organizationId,
              authorName,
              data: {
                file: files[0],
                fileSize: files[0].size,
              },
            },
          }).then((result) => {
            let asset = result.data?.uploadMedia;
            if (asset) {
              setValue(name, { ...modelValue, edges: [{ node: asset }] });
            }
          })
        }
        accept={uploadTypes}
        uploadLimit={1}
      />
    );
  }
}

Thumbnail.displayName = "Control(Thumbnail)";

import styled from "styled-components";
import { useMediaQuery, useUniqueId } from "@hooks";
import { SearchAdapter } from "@types";
import { Button } from "@components";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  CopilotIcon,
} from "@condenast/gemini/icons";
import { useState, useMemo } from "react";
import { useIntl } from "react-intl";

const StyledCopilotIcon = styled(CopilotIcon)`
  height: 1.5rem;
  width: 1.5rem;
  & path {
    fill: ${(props) => props.theme.PrimaryColor};
  }
`;
const AssetSelectorAdapterListContainer = styled.div<{
  $isTakeOverView: boolean;
}>`
  grid-area: tab-list;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: var(--spacing-xxs);

  ${({ theme, $isTakeOverView }) => {
    if ($isTakeOverView) {
      return `
        position: absolute;
        top: 0;
        left: 0;
        z-index: ${theme.ElevationModal};
        width: calc(100vw - var(--spacing-sm));
        height: 100vh;
        background: inherit;
        margin-top: calc(-4 * var(--spacing-sm));
        padding: 0;
      `;
    } else return;
  }}
`;

const TabItem = styled.li`
  font-size: inherit;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border-radius: ${(props) => props.theme.CornerRadius};
  padding: var(--spacing-xs);
  cursor: pointer;

  &[aria-selected="true"] {
    background: ${(props) => props.theme.BorderlessActiveBackground};
  }
`;

const TabList = styled.ul<{ $isTakeOverView: boolean }>`
  list-style: none;

  &:focus {
    outline: none;
  }

  &.input-mode-keyboard ${TabItem}.activedescendant {
    position: relative;
    z-index: 1;
    box-shadow: ${(props) => props.theme.FocusRing};
  }

  &.input-mode-mouse ${TabItem}[aria-selected="false"]:hover,
  &.input-mode-keyboard ${TabItem}[aria-selected="false"].activedescendant {
    background: ${(props) => props.theme.BorderlessHoverBackground};
  }

  ${({ $isTakeOverView }) => {
    if ($isTakeOverView) {
      return `
        width: 100%;
        margin: 0px auto;
        padding: var(--spacing-xl);
      `;
    } else return;
  }}
`;

const AccordionTriggerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AllAdapterLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
`;

const ContentAdapterTabItem = styled(TabItem)`
  padding-left: var(--spacing-xl);
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: var(--spacing-xs);
  }
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${(props) => props.theme.DividerColor};
  margin-bottom: var(--spacing-sm);
  margin: var(--spacing-xs) 0;
`;

const TypeTakeoverHeader = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-sm);
`;

const TypeTitle = styled.h2`
  font: ${(props) => props.theme.FontSubSectionHeading};
`;

const CloseButton = styled(Button)`
  margin-left: auto;
`;

type AssetSelectorAdapterListProps = {
  onAdapterChange: (adapter: SearchAdapter) => void;
  adapters: SearchAdapter[];
  selectedAdapter: SearchAdapter;
  handleOnClose: (status: boolean) => void;
};

export const AssetSelectorAdapterList = (
  props: AssetSelectorAdapterListProps
) => {
  const { adapters, onAdapterChange, selectedAdapter, handleOnClose } = props;
  const intl = useIntl();
  const uid = useUniqueId();
  const tabItemPrefix = `tab-item-${uid}`;
  const takeOverView = useMediaQuery("(max-width: 875px)");

  const [isOpened, setIsOpened] = useState(true);

  const allContentAdapter = useMemo(
    () => adapters.find((adapter) => adapter.id === "all"),
    [adapters]
  );

  const contentAdapters = useMemo(
    () =>
      adapters.filter((adapter) => {
        return adapter.type === "content" && adapter.id !== "all";
      }),
    [adapters]
  );

  const myWorkAdapter = useMemo(
    () => adapters.find((adapter) => adapter.type === "mywork"),
    [adapters]
  );

  const externalAdapters = useMemo(
    () => adapters.filter((adapter) => adapter.type === "external"),
    [adapters]
  );

  return (
    <AssetSelectorAdapterListContainer $isTakeOverView={takeOverView}>
      {takeOverView && (
        <>
          <TypeTakeoverHeader>
            <TypeTitle>
              {intl.formatMessage({
                defaultMessage: "Select Type",
                description: "Select type to search for in asset selector",
              })}
            </TypeTitle>
            <CloseButton
              aria-label={intl.formatMessage({
                defaultMessage: "Close",
                description: "Close type select",
              })}
              onClick={() => handleOnClose(false)}
            >
              <CloseIcon size="small" />
            </CloseButton>
          </TypeTakeoverHeader>
          <Divider />
        </>
      )}
      <TabList tabIndex={0} role="tablist" $isTakeOverView={takeOverView}>
        {myWorkAdapter && (
          <>
            <TabItem
              onClick={() => {
                onAdapterChange(myWorkAdapter);
                handleOnClose(false);
              }}
              aria-selected={selectedAdapter.id === myWorkAdapter.id}
            >
              <ItemWrapper>{myWorkAdapter.Label()}</ItemWrapper>
            </TabItem>

            <Divider />
          </>
        )}

        {allContentAdapter && (
          <TabItem
            onClick={() => {
              onAdapterChange(allContentAdapter);
              handleOnClose(false);
            }}
            aria-selected={selectedAdapter.id === allContentAdapter.id}
          >
            <AllAdapterLabelContainer>
              <StyledCopilotIcon />
              {allContentAdapter.Label()}
            </AllAdapterLabelContainer>
            <AccordionTriggerContainer>
              <Button
                id={`accordionTrigger-${uid}`}
                aria-controls={`accordionBody-${uid}`}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setIsOpened(!isOpened);
                }}
                aria-label={isOpened ? "Collapse" : "Expand"}
              >
                {isOpened ? (
                  <ChevronUpIcon size="small" />
                ) : (
                  <ChevronDownIcon size="small" />
                )}
              </Button>
            </AccordionTriggerContainer>
          </TabItem>
        )}

        {isOpened &&
          contentAdapters.map((adapter, index) => {
            return (
              <ContentAdapterTabItem
                key={index}
                id={`${tabItemPrefix}-${index}`}
                role="tab"
                onClick={() => {
                  onAdapterChange(adapter);
                  handleOnClose(false);
                }}
                aria-selected={selectedAdapter.id === adapter.id}
              >
                <ItemWrapper>{adapter.Label()}</ItemWrapper>
              </ContentAdapterTabItem>
            );
          })}
        {externalAdapters.map((item, index) => {
          return (
            <TabItem
              key={index}
              id={`${tabItemPrefix}-${index}`}
              role="tab"
              onClick={() => {
                onAdapterChange(item);
                handleOnClose(false);
              }}
              aria-selected={selectedAdapter.id === item.id}
            >
              <ItemWrapper>{item.Label()}</ItemWrapper>
            </TabItem>
          );
        })}
      </TabList>
    </AssetSelectorAdapterListContainer>
  );
};

import { Button, Select } from "@components";
import { useDefinedMessages } from "@hooks";
import { endOfDay, getMonth, getYear, startOfDay } from "date-fns";
import moment from "moment";
import { useState, forwardRef, Ref, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { ChevronLeftIcon, ChevronRightIcon } from "@condenast/gemini/icons";

const DateWrapper = styled.div`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: var(--spacing-xxs);
  float: right;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 var(--spacing-xxs);
`;

const StyledSelect = styled(Select)`
  min-width: calc(var(--spacing-xxl) * 2.7);
`;

export const CustomDateRangePicker = forwardRef(function (
  props: {
    selectsRange: Array<Date>;
    style: object;
    startDate: Date;
    setStartDate: (date: Date) => void;
    endDate: Date;
    setEndDate: (date: Date) => void;
    setShowPopper: (item: boolean) => void;
    setLabel: (item: string) => void;
    handleDateChange: (start: Date, end: Date, type: string) => void;
  },
  forwardRef: Ref<HTMLInputElement>
) {
  const {
    setEndDate,
    setStartDate,
    setShowPopper,
    setLabel,
    handleDateChange,
  } = props;
  const { translateFieldName } = useDefinedMessages();
  const intl = useIntl();
  const [internalStartDate, setinternalStartDate] = useState(new Date());
  const [internalEndDate, setinternalEndDate] = useState(new Date());
  const years = Array.from(
    { length: moment().year() - 1989 },
    (_, index) => 1990 + index
  ).reverse();

  const monthOptions = useMemo(
    () =>
      Array.from({ length: 12 }, (_, index) => ({
        label: intl.formatDate(new Date(2000, index, 1), {
          month: "long",
        }),
        value: index.toString(),
      })),
    [intl]
  );

  const onDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setinternalStartDate(start);
    setinternalEndDate(end);
  };

  const onApply = () => {
    setStartDate(internalStartDate);
    setEndDate(internalEndDate);
    handleDateChange(
      startOfDay(internalStartDate),
      internalEndDate ? endOfDay(internalEndDate) : endOfDay(internalStartDate),
      "Custom"
    );
    let label = internalEndDate
      ? internalStartDate.toDateString() +
        " - " +
        internalEndDate.toDateString()
      : internalStartDate.toDateString();
    setLabel(label);
    setShowPopper(false);
  };

  return (
    <>
      <DateWrapper ref={forwardRef}>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <HeaderWrapper>
              <Button
                aria-label={intl.formatMessage({
                  defaultMessage: "Previous Month",
                  description:
                    "Label for going to the previous month in a calendar",
                })}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <ChevronLeftIcon size="small" />
              </Button>
              <StyledSelect
                id="month-select"
                options={monthOptions}
                value={getMonth(date).toString()}
                onChange={(value: string | undefined) =>
                  changeMonth(Number(value))
                }
              />
              <Select
                id="year-select"
                options={years.map((option) => ({
                  label: translateFieldName(option.toString()),
                  value: option.toString(),
                }))}
                value={getYear(date).toString()}
                onChange={(value) => changeYear(Number(value))}
              />
              <Button
                aria-label={intl.formatMessage({
                  defaultMessage: "Next Month",
                  description:
                    "Label for going to the next month in a calendar",
                })}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <ChevronRightIcon size="small" />
              </Button>
            </HeaderWrapper>
          )}
          selected={internalStartDate}
          onChange={onDateChange}
          startDate={internalStartDate}
          endDate={internalEndDate}
          selectsRange
          inline
        />
      </DateWrapper>
      <ButtonGroup>
        <Button
          onClick={() => {
            setShowPopper(false);
          }}
        >
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button treatment="primary" onClick={onApply}>
          <FormattedMessage defaultMessage="Apply" />
        </Button>
      </ButtonGroup>
    </>
  );
});
CustomDateRangePicker.displayName = "CustomDateRangePicker";

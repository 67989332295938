import { useLazyQuery } from "@apollo/client";
import { ContributorSelector as ContributorSelectorImplementation } from "@condenast/gemini";
import { ContributorSelection } from "packages/gemini/src/components/ContributorSelector/types";
import { Queries } from "@gql";
import {
  ControlProps,
  GalleryFields_allContributors as TContributorSelection,
  GalleryFields_allContributors_edges,
  CONTRIBUTOR_TYPE,
  SearchVariables,
  Search,
  FormFor_form_controls_ContributorFormControl,
} from "@types";
import { useDebouncedCallback, useDefinedMessages } from "@hooks";
import { useCallback } from "react";

const SEARCH_DEBOUNCE_INTERVAL = 250; // milliseconds

const normalizeSearchData = (data: Search) => {
  let normalizedSearchData: ContributorSelection[] = [];
  if (data.search) {
    normalizedSearchData = data.search.results.map((option) => {
      return {
        id: option.id,
        name: option.title?.content,
        role: "",
        roleLabel: "",
      };
    });
  }
  return normalizedSearchData;
};

export function Contributor(
  props: ControlProps<FormFor_form_controls_ContributorFormControl>
) {
  const { model, setValue, contributorTypeOptions, currentOrganization } =
    props;

  const { translateContributorType } = useDefinedMessages();

  const modelValue = (model.allContributors ?? {}) as TContributorSelection;
  const selections = (modelValue && modelValue?.edges)?.map((edge) => {
    //This change is to show the Default TypeOption as Contributor in the UI
    const type = edge?.type === "DEFAULT" ? "CONTRIBUTOR" : edge?.type;
    const { id, name } = edge?.node || {};
    return {
      id,
      name,
      role: type,
      roleLabel: translateContributorType(type || ""),
    } as ContributorSelection;
  });
  const [_lazySearch, { data: searchData, loading, error }] = useLazyQuery<
    Search,
    SearchVariables
  >(Queries.SEARCH, { fetchPolicy: "network-only" });

  const onChange = useCallback(
    (searchSelections: ContributorSelection[]) => {
      let updatedContributors = [] as GalleryFields_allContributors_edges[];
      if (searchSelections.length) {
        const currSel = searchSelections[searchSelections.length - 1];
        const dupSel = selections?.find(
          (d) =>
            d.id === currSel.id &&
            ("contributors" + d.role).toLowerCase() ===
              currSel.role.toLowerCase()
        );
        if (dupSel) {
          return;
        }
        currSel.role = currSel.role.replace("contributors", "").toUpperCase();
        updatedContributors = searchSelections.map((updatedContributor) => {
          return {
            __typename: "ContentToContributorEdge",
            node: {
              __typename: "Contributor",
              id: updatedContributor.id,
              name: updatedContributor.name || "",
            },
            type: (updatedContributor.role as CONTRIBUTOR_TYPE) || null,
          };
        });
      }
      setValue("allContributors", { edges: updatedContributors });
    },
    [setValue, selections]
  );

  const performSearch = useDebouncedCallback((query: string | undefined) => {
    if (!query) return;

    _lazySearch({
      variables: {
        organizationId: currentOrganization.organizationId,
        filters: {
          q: query || "",
          types: "contributor",
          status: "published",
          display: "all",
          qt: "contributorLookup",
        },
      },
    });
  }, SEARCH_DEBOUNCE_INTERVAL);

  const searchError = error ? [error] : undefined;

  const normalizedSearch: ContributorSelection[] = searchData
    ? normalizeSearchData(searchData)
    : [];

  const typeOptions =
    contributorTypeOptions?.options.map((option) => {
      //This change is to show the Default TypeOption as Contributor in the UI
      let opt = option === "DEFAULT" ? "Contributor" : option;
      return {
        label: translateContributorType(opt),
        value: opt,
        key: opt,
      };
    }) || [];
  const defaultType = typeOptions.find(
    (option) => option.value === contributorTypeOptions?.defaultValue
  );

  return !typeOptions.length ? (
    <></>
  ) : (
    <ContributorSelectorImplementation
      selections={selections || []}
      search={performSearch}
      searchResult={{
        data: normalizedSearch,
        loading: loading,
        errors: searchError,
      }}
      onChange={onChange}
      contributorTypeOptions={typeOptions}
      defaultContributorType={defaultType}
    />
  );
}

Contributor.displayName = "Control(Contributor)";

import { Fieldset as FieldsetImpl } from "@components";
import { useDefinedMessages } from "@hooks";
import {
  ControlProps,
  FormFor_form_controls_FieldsetFormControl,
} from "@types";

export function Fieldset(
  props: ControlProps<FormFor_form_controls_FieldsetFormControl>
) {
  let { translateFieldDescription, translateFieldLegend } =
    useDefinedMessages();

  return (
    <FieldsetImpl
      name={props.name}
      title={
        props.name === "main" ? undefined : translateFieldLegend(props.name)
      }
      description={
        props.description
          ? translateFieldDescription(props.description)
          : undefined
      }
    >
      {props.children}
    </FieldsetImpl>
  );
}
Fieldset.displayName = "Control(Fieldset)";

import { useMemo } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { AspectRatio } from "@types";
import { CropIcon, LockIcon } from "@condenast/gemini/icons";
import { Image } from "@components";
import { ImageProps } from "@condenast/gemini/src/components/Image";

const StyledCropIcon = styled(CropIcon)`
  color: ${(props) => props.theme.PrimaryColor};
`;

const StyledLockIcon = styled(LockIcon)`
  color: ${(props) => props.theme.PrimaryColor};
`;

const AspectRatioTileWrapper = styled.div`
  width: calc(var(--spacing-sm) * 11.25);
  height: calc(var(--spacing-sm) * 13.25);
  display: flex;
  padding: 1px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-gray-6);
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
`;

const AspectRatioImageContainer = styled.div`
  height: calc(var(--spacing-sm) * 9.25);
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
`;

const AspectRatioImagePreview = styled.div<{
  dimensions: {
    width: number;
    height: number;
  };
}>`
  width: ${(props) => `${props.dimensions.width}px`};
  height: ${(props) => `${props.dimensions.height}px`};
  overflow: hidden;
  position: relative;
`;
interface StyledImageProps extends ImageProps {
  imageDimensionsAndOffsets?: ImageDimensionAndOffset;
}

const StyledImage = styled(Image)<StyledImageProps>`
  position: absolute;
  margin-top: ${(props) =>
    props.imageDimensionsAndOffsets?.y
      ? `-${props.imageDimensionsAndOffsets.y}px`
      : "0px"};
  margin-left: ${(props) =>
    props.imageDimensionsAndOffsets?.x
      ? `-${props.imageDimensionsAndOffsets.x}px`
      : "0px"};
  height: ${(props) =>
    props.imageDimensionsAndOffsets?.height
      ? `${props.imageDimensionsAndOffsets.height}px`
      : "0px"};
  width: ${(props) =>
    props.imageDimensionsAndOffsets?.width
      ? `${props.imageDimensionsAndOffsets.width}px`
      : "0px"};
`;

const Overlay = styled.div<{ $restrictCropping: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-scrim);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.$restrictCropping ? "1" : "0")};
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
    cursor: ${(props) => (props.$restrictCropping ? "not-allowed" : "pointer")};
  }
`;

const OverlayText = styled.span`
  color: ${(props) => props.theme.PrimaryColor}; ;
`;

const AspectRatioInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - var(--spacing-sm) * 9.25);
  text-align: center;
  row-gap: var(--spacing-xxs);
  border-bottom-left-radius: ${(props) => props.theme.CornerRadius};
  border-bottom-right-radius: ${(props) => props.theme.CornerRadius};
  background-color: ${(props) => props.theme.Background};
`;

const AspectRatioName = styled.span`
  font: var(--font-button);
`;
const AspectRatioDimensions = styled.span`
  font: var(--font-body);
`;

type Asset = {
  type: string;
  id: string;
  filename: string;
  altText: string;
  width: number;
  height: number;
  publicURL: null;
};

type Crop =
  | {
      x: number;
      y: number;
      width: number;
      height: number;
    }
  | undefined;

interface ImageDimensionAndOffset {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

interface StyledImageProps extends ImageProps {
  imageDimensionsAndOffsets?: ImageDimensionAndOffset;
}

const AspectRatioPreviewMaxHeight = 148;
const AspectRatioPreviewMaxWidth = 180;

const getImageDimensionAndOffset = (asset: Asset, crop?: Crop) => {
  if (!crop) return;

  const { x, y, width, height } = crop;
  const referenceSize =
    width > height ? AspectRatioPreviewMaxWidth : AspectRatioPreviewMaxHeight;
  const scaleFactor = Math.min(
    1,
    referenceSize / (width > height ? width : height)
  );

  return {
    x: x * scaleFactor,
    y: y * scaleFactor,
    width: asset.width * scaleFactor,
    height: asset.height * scaleFactor,
  };
};

const getCropPreviewDimensions = (aspectRatio: AspectRatio) => {
  const [widthAspectRatio, heightAspectRatio] = aspectRatio.name
    .split(":")
    .map(Number);
  const { width: cropWidth, height: cropHeight } = aspectRatio.modifications
    ?.crop || {
    width: AspectRatioPreviewMaxWidth,
    height: AspectRatioPreviewMaxHeight,
  };

  const isLandscape = widthAspectRatio > heightAspectRatio;
  const maxSize = isLandscape
    ? AspectRatioPreviewMaxWidth
    : AspectRatioPreviewMaxHeight;

  if (
    (isLandscape && cropWidth <= AspectRatioPreviewMaxWidth) ||
    (!isLandscape && cropHeight <= AspectRatioPreviewMaxHeight)
  ) {
    return { width: cropWidth, height: cropHeight };
  }

  const cropPreviewWidth = isLandscape
    ? maxSize
    : Math.round((maxSize * widthAspectRatio) / heightAspectRatio);

  const cropPreviewHeight = isLandscape
    ? Math.round((maxSize * heightAspectRatio) / widthAspectRatio)
    : maxSize;

  return { width: cropPreviewWidth, height: cropPreviewHeight };
};

export const AspectRatioTile = (props: {
  aspectRatio: AspectRatio;
  asset: Asset;
  restrictCropping: boolean;
  cdnHost: string;
  onClick: (selectedAspectRatio: AspectRatio) => void;
}) => {
  let { aspectRatio, asset, restrictCropping, cdnHost, onClick } = props;
  let cropPreviewDimensions = useMemo(
    () => getCropPreviewDimensions(aspectRatio),
    [aspectRatio]
  );
  let imageDimensionsAndOffsets = useMemo(
    () => getImageDimensionAndOffset(asset, aspectRatio.modifications?.crop),
    [aspectRatio.modifications?.crop, asset]
  );

  return (
    <AspectRatioTileWrapper
      data-testid={`aspect-ratio-tile-${aspectRatio.name}`}
    >
      <AspectRatioImageContainer>
        <AspectRatioImagePreview dimensions={cropPreviewDimensions}>
          <StyledImage
            asset={asset}
            cdnHost={cdnHost}
            overflowStyle="fit"
            imageDimensionsAndOffsets={imageDimensionsAndOffsets}
          />
        </AspectRatioImagePreview>
        <Overlay
          $restrictCropping={restrictCropping}
          onClick={() => {
            if (restrictCropping) return;
            onClick(aspectRatio);
          }}
        >
          {restrictCropping ? (
            <>
              <StyledLockIcon size={"regular"} />
              <OverlayText>
                <FormattedMessage defaultMessage={"Cropping restricted"} />
              </OverlayText>
            </>
          ) : (
            <>
              <StyledCropIcon size={"regular"} />
              <OverlayText>
                <FormattedMessage defaultMessage={"Adjust Crop"} />
              </OverlayText>
            </>
          )}
        </Overlay>
      </AspectRatioImageContainer>
      <AspectRatioInfo>
        <AspectRatioName>{aspectRatio.name}</AspectRatioName>
        <AspectRatioDimensions>{`(${aspectRatio.width} x ${aspectRatio.height})`}</AspectRatioDimensions>
      </AspectRatioInfo>
    </AspectRatioTileWrapper>
  );
};

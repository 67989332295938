import { useState } from "react";
import { LinkIcon } from "@condenast/gemini/icons";
import { Button, UsageInfoDialog } from "@components";
import { useIntl } from "react-intl";
import styled, { DefaultTheme } from "styled-components";
import { ContentSummary } from "@types";
import { ThemeProvider } from "@contexts";

const ShowUsageInfoButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
  color: ${(props) => props.theme.Color};
  svg {
    left: 0;
    margin-right: var(--spacing-xxs);
  }
  span {
    font: ${(props) => props.theme.FontSmallStatement};
    font-weight: 500;
  }
  margin-right: var(--spacing-xs);
  text-decoration: underline;
`;

function transformBlue(theme: DefaultTheme) {
  return {
    ...theme,
    Color: "var(--color-blue-100)",
  };
}

const UsageInfoLinkIcon = styled(LinkIcon)`
  display: inline;
  border-radius: 50%;
`;

export function UsageInfo(props: {
  contentType: string;
  cdnHost?: string;
  backReferences: ContentSummary[];
  className?: string;
}) {
  const intl = useIntl();

  const { contentType, cdnHost, backReferences, className } = props;

  const usageCount = backReferences.length;

  const [showUsageInfoDialog, setShowUsageInfoDialog] = useState(false);

  return (
    <>
      {showUsageInfoDialog && (
        <UsageInfoDialog
          contentType={contentType}
          cdnHost={cdnHost}
          backReferences={backReferences}
          close={() => setShowUsageInfoDialog(false)}
        />
      )}
      {
        <ThemeProvider tint="blue" transform={transformBlue}>
          <ShowUsageInfoButton
            treatment="text"
            disabled={!usageCount}
            id="UsageInfoButton"
            onClick={() => setShowUsageInfoDialog(true)}
            className={className}
          >
            <UsageInfoLinkIcon size="small" />
            <span>
              {intl.formatMessage(
                {
                  defaultMessage:
                    "Used in {usageCount, plural, =1 {# place} other {# places}}",
                  description: "Usage info button",
                },
                {
                  usageCount,
                }
              )}
            </span>
          </ShowUsageInfoButton>
        </ThemeProvider>
      }
    </>
  );
}
UsageInfo.displayName = "UsageInfo";

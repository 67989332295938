import { useMemo } from "react";
import { SearchMultiselect, Field } from "@components";
import { useDefinedMessages, useUniqueId } from "@hooks";
import { ControlProps, FormFor_form_controls_TagsFormControl } from "@types";
import styled from "styled-components";

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-xxs) 0;
  grid-template-rows: auto auto;
  grid-template-areas:
    "label"
    "control";
`;

export function WorkflowTags(
  props: ControlProps<FormFor_form_controls_TagsFormControl>
) {
  const { model, name, customLabel = "" } = props;
  const uniqueId = useUniqueId();

  const { translateFieldName } = useDefinedMessages();

  const modelValue = useMemo(() => {
    return (model[name] ?? []) as string[];
  }, [model, name]);

  const selections = useMemo(
    () =>
      modelValue.map((item) => {
        return {
          key: item,
          value: item,
          label: item,
          disabled: true,
        };
      }),
    [modelValue]
  );

  return (
    <Wrapper
      id={`${customLabel}-${uniqueId}`}
      label={translateFieldName(customLabel || "")}
    >
      <SearchMultiselect
        selections={selections || []}
        multiple={false}
        disabled={true}
      />
    </Wrapper>
  );
}
WorkflowTags.displayName = "Control(WorkflowTags)";

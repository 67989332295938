import {
  encodeAffiliateParams,
  decodeAffiliateParams,
} from "@condenast/cna-st-codec";
import { useCallback, useMemo } from "react";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";

import { useApolloClient } from "@apollo/client";
import { Queries } from "@gql";
import {
  CKEditorConfiguration,
  CKEditorLinkAutogenConfiguration,
  CKEditorAsset,
  ContentSummaryFields,
  ContentSummaryFields_asset,
  ContentSummaryFields_metadata_ContentSummaryCategory as ContentSummaryCategory,
  ContentSummaryFields_metadata_ContentSummaryContributors as ContentSummaryContributors,
  ContentSummaryFields_metadata_ContentSummaryOffer as ContentSummaryOffer,
  GetContentSummary,
  GetContentSummaryVariables,
  GetDefinitions,
  GetDefinitionsVariables,
  Organization,
  RichTextConfiguration,
  Search,
  SearchProvider,
  SearchVariables,
  TextFormat,
  GetOEmbed,
  GetOEmbedVariables,
} from "@types";
import { useDefinedMessages } from "@hooks";
import { pluralize, removeNullValues } from "@lib";

const REDIRECTOR_URLS =
  /^(https?:)?\/\/([a-zA-Z0-9]+\.)?cna\.st\/affiliate-link\//;
const REDIRECTOR_URL_EXCEPTIONS = [
  /^(https?:\/\/)?shop-links\.co\//, // Narrativ
  /^(https?:\/\/)?www\.glamour\.com\//, // glamour
  /^(https?:\/\/)?(www\.)?bonsai\.to\//, // bonsai
];

function resolveCKEditorConfiguration(
  configs: RichTextConfiguration | null,
  organization: Organization
) {
  if (!configs) {
    return {};
  }

  let config: CKEditorConfiguration = {
    cdnHost: organization.metadata.mediaDomain,
    host: organization.url,
  };
  const { plugins, include, urlExpansions } = configs;
  if (plugins) {
    config.plugins = removeNullValues(plugins);
  }
  if (urlExpansions) {
    config.urlExpansions = urlExpansions.map(
      ({ pattern, type, height, width }) => ({
        pattern: pattern,
        type: type,
        height: height ?? undefined,
        width: width ?? undefined,
      })
    );
  }
  if (include) {
    config.include = include;
  }

  return config;
}

function getCKEditorAffiliateLinkHandler(
  organizationId: string,
  contentId: string
) {
  return function ({ url, action }: { url: string; action: string }) {
    let matchRedirectorUrl = url.match(REDIRECTOR_URLS);
    let urlOut;
    if (action === "DECODE" && matchRedirectorUrl) {
      // Only decode redirector URLs
      let { offerUrl } = decodeAffiliateParams(
        url.replace(matchRedirectorUrl[0], "").replace(/\?.*$/, "")
      );
      urlOut = offerUrl;
    } else if (action === "ENCODE" && organizationId) {
      // Only encode if not a redirector URL - double encode
      // OR if domain is NOT flagged as an exception
      let skipAction = REDIRECTOR_URL_EXCEPTIONS.some((exceptionRegx) =>
        url.match(exceptionRegx)
      );
      if (skipAction || matchRedirectorUrl) {
        urlOut = url;
      } else {
        let encodedLink = encodeAffiliateParams({
          origin: contentId,
          brand: organizationId,
          offerUrl: url,
        });
        urlOut = `https://cna.st/affiliate-link/${encodedLink}`;
        // we may want to change this from a hard coded variable to using a
        // redirect endpoint that can be fetched from the editorial service
        // (as is done in ember). then again, the way we check matchRedirectUrl
        // relies on this static url.
      }
    } else {
      urlOut = url;
    }
    return {
      url: urlOut,
    };
  };
}
function toInlineVersoSourceDocument(
  text:
    | {
        content: string;
        format: TextFormat;
      }
    | null
    | undefined
): VersoSource | undefined {
  if (text == null) {
    return undefined;
  }
  if (text.format === TextFormat.markdown) {
    const doc = CopilotMarkdownSource.fromRaw(text.content).convertTo(
      VersoSource
    );
    doc.where((a) => a.type === "paragraph").remove();
    return doc;
  } else {
    return new VersoSource({
      content: text.content,
      annotations: [],
    });
  }
}

function toCKEditorEmbeddedAsset(
  asset: ContentSummaryFields_asset | null,
  cdnHost: string
): CKEditorAsset | undefined {
  const embeddedAsset = asset
    ? {
        __typename: "Photo",
        id: asset.id,
        imageUrl: `${cdnHost}/${pluralize(asset.type)}/${
          asset.id
        }/:aspect_ratio/:modifications/${asset.filename}`,
        altText: asset.altText ?? undefined,
        editUrl: "",
        credit: toInlineVersoSourceDocument(asset.credit),
      }
    : undefined;
  return embeddedAsset;
}

function convertPhoto(item: ContentSummaryFields | null, cdnHost: string) {
  return item
    ? {
        __typename: "Photo",
        id: item.id,
        editUrl: item.editUrl,
        imageUrl: `${cdnHost}/photos/${item.id}/:aspect_ratio/:modifications/${item.description}`,
        altText: item.asset?.altText ?? "",
        caption: toInlineVersoSourceDocument(item.caption),
        credit: toInlineVersoSourceDocument(item.credit),
        doNotCrop: item.restrictCropping,
      }
    : null;
}
function convertClip(item: ContentSummaryFields | null, cdnHost: string) {
  return item
    ? {
        __typename: "Clip",
        id: item.id,
        editUrl: item.editUrl,
        imageUrl: item.asset
          ? `${cdnHost}/clips/${item.asset.id}/:aspect_ratio/:modifications/${item.asset.filename}`
          : "",
        videoUrl: `${cdnHost}/clips/${item.id}/:aspect_ratio/:modifications/${item.description}`,
        altText: item.asset?.altText ?? "",
        caption: toInlineVersoSourceDocument(item.caption),
        credit: toInlineVersoSourceDocument(item.credit),
      }
    : null;
}
function convertProduct(item: ContentSummaryFields | null, cdnHost: string) {
  if (!item) {
    return null;
  }
  const offerMetadata = item.metadata?.find(
    ({ __typename }) => __typename === "ContentSummaryOffer"
  ) as ContentSummaryOffer | null;
  return {
    __typename: "Product",
    id: item.id,
    type: "product",
    title: toInlineVersoSourceDocument(item.title),
    editUrl: item.editUrl,
    asset: toCKEditorEmbeddedAsset(item.asset, cdnHost),
    offers: offerMetadata
      ? [
          {
            sellerName: offerMetadata.seller,
            price: offerMetadata.price,
            currency: offerMetadata.currency,
            isOutOfStock: offerMetadata.isOutOfStock,
            // TODO: comparison price
          },
        ]
      : [],
  };
}
function convertContent(
  item: ContentSummaryFields | null,
  cdnHost: string,
  translateContributorCredit: (type: string, names: string[]) => string
) {
  if (!item) {
    return null;
  }
  const channelMetadata = item.metadata?.find(
    ({ __typename }) => __typename === "ContentSummaryCategory"
  ) as ContentSummaryCategory | null;
  const contributorMetadata = item.metadata?.find(
    ({ __typename }) => __typename === "ContentSummaryContributors"
  ) as ContentSummaryContributors | null;
  return {
    __typename: item.publishable ? "PublishableContent" : "EmbedabbleContent",
    id: item.id,
    type: item.contentType,
    title: toInlineVersoSourceDocument(item.title),
    editUrl: item.editUrl,
    // TODO: liveUrl would be needed for internal links
    asset: toCKEditorEmbeddedAsset(item.asset, cdnHost),
    byline:
      contributorMetadata?.contributors
        .map(({ type, names }) => translateContributorCredit(type, names))
        .join(", ") ?? "",
    channels: channelMetadata
      ? [
          {
            name: channelMetadata.category,
          },
        ]
      : [],
  };
}
function convertContentReference(item: ContentSummaryFields | null) {
  if (!item) {
    return null;
  }
  const offerMetadata = item.metadata?.find(
    ({ __typename }) => __typename === "ContentSummaryOffer"
  ) as ContentSummaryOffer | null;
  return {
    __typename: "Product",
    id: item.id,
    type: "contentreference",
    title: toInlineVersoSourceDocument(item.title),
    editUrl: item.editUrl,
    asset: {
      __typename: "Photo",
      altText: item.title?.content,
      imageUrl: item?.asset?.publicURL,
      caption: CopilotMarkdownSource.fromRaw(
        item.title?.content || ""
      ).convertTo(VersoSource),
    },
    offers: offerMetadata
      ? [
          {
            sellerName: offerMetadata.seller,
            price: offerMetadata.price,
            currency: offerMetadata.currency,
            isOutOfStock: offerMetadata.isOutOfStock,
            // comparisonPrice: parseFloat(offerMetadata.comparisonPrice || 0),
            // TODO: comparison price
          },
        ]
      : [],
  };
}
export function useCKEditorConfig(
  richTextConfiguration: RichTextConfiguration | null,
  currentOrganization: Organization,
  contentId: string,
  options?: {
    autogenConfig?: CKEditorLinkAutogenConfiguration;
    setWordCount?: (count: number) => void;
    setCharCount?: (count: number) => void;
  }
) {
  const { autogenConfig, setWordCount, setCharCount } = options ?? {};
  const { translateContributorCredit } = useDefinedMessages();

  const Client = useApolloClient();
  const getContentSummary = useCallback(
    (contentType, id) => {
      return Client.query<GetContentSummary, GetContentSummaryVariables>({
        query: Queries.GET_CONTENT_SUMMARY,
        variables: {
          organizationId: currentOrganization.organizationId,
          contentType,
          id,
        },
      });
    },
    [Client, currentOrganization.organizationId]
  );
  const getContentReferenceDetails = useCallback(
    async (contentType, id) => {
      return Client.query<Search, SearchVariables>({
        query: Queries.SEARCH,
        variables: {
          organizationId: currentOrganization.organizationId,
          filters: { id, types: contentType },
          provider: SearchProvider.commerceTools,
        },
      });
    },
    [Client, currentOrganization.organizationId]
  );
  const getDefinitions = useCallback(async () => {
    let {
      data: { definitions },
    } = await Client.query<GetDefinitions, GetDefinitionsVariables>({
      query: Queries.CKEDITOR_DEFINITIONS,
      variables: {
        brand: currentOrganization.metadata.copilotCode,
        organizationId: currentOrganization.organizationId,
        consumerHost: currentOrganization.url,
      },
    });
    return definitions;
  }, [currentOrganization, Client]);

  const getOEmbed = useCallback(
    async (url: string) => {
      let {
        data: { oembed },
      } = await Client.query<GetOEmbed, GetOEmbedVariables>({
        query: Queries.GET_OEMBED,
        variables: {
          organizationId: currentOrganization.organizationId,
          url,
        },
      });
      return oembed;
    },
    [currentOrganization, Client]
  );

  const resolvedConfig = useMemo(() => {
    const hasContent =
      richTextConfiguration?.include?.includes("ContentSelector");
    const cdnHost = `https://${currentOrganization.metadata.mediaDomain}`;
    return richTextConfiguration
      ? {
          ...resolveCKEditorConfiguration(
            richTextConfiguration,
            currentOrganization
          ),
          ...(autogenConfig ? { link: { autogen: autogenConfig } } : {}),
          gql: {
            ...(hasContent
              ? {
                  photo: async ({ id }: { id: string }) => {
                    const result = await getContentSummary("photo", id);
                    return convertPhoto(result.data.contentSummary, cdnHost);
                  },
                  clip: async ({ id }: { id: string }) => {
                    const result = await getContentSummary("clip", id);
                    return convertClip(result.data.contentSummary, cdnHost);
                  },
                  content: async ({
                    id,
                    type,
                  }: {
                    id: string;
                    type: string;
                  }) => {
                    const result = await getContentSummary(type, id);
                    if (type === "product") {
                      return convertProduct(
                        result.data.contentSummary,
                        cdnHost
                      );
                    }
                    if (type === "contentreference") {
                      return convertContentReference(
                        result.data.contentSummary
                      );
                    }

                    return convertContent(
                      result.data.contentSummary,
                      cdnHost,
                      translateContributorCredit
                    );
                  },
                }
              : {}),
            ...(autogenConfig
              ? {
                  linkAutogenPatterns: async () => {
                    try {
                      return await getDefinitions();
                    } catch (error) {
                      throw new Error(`Could not load pattern definitions`);
                    }
                  },
                }
              : {}),
            affiliateLink: getCKEditorAffiliateLinkHandler(
              currentOrganization.organizationId,
              contentId
            ),
            oembed: ({ url }: { url: string }) => {
              return getOEmbed(url);
            },
          },
          ...(setWordCount || setCharCount
            ? {
                wordCount: {
                  onUpdate: (stats: { characters: number; words: number }) => {
                    setWordCount?.(stats.words);
                    setCharCount?.(stats.characters);
                  },
                },
              }
            : {}),
        }
      : {};
  }, [
    richTextConfiguration,
    currentOrganization,
    contentId,
    autogenConfig,
    setWordCount,
    setCharCount,
    translateContributorCredit,
    getContentSummary,
    getContentReferenceDetails,
    getDefinitions,
    getOEmbed,
  ]);

  return resolvedConfig;
}

import type { ContentSerializers } from "../types";
import type { CartoonInput } from "@types";
import {
  allContributors,
  categoryTaxonomies,
  cartoonContest,
  aspectRatios,
} from "../fields";
import { serializerFor, entityMetadata } from "../utils";

const CartoonFields = {
  allContributors,
  aspectRatios,
  categoryTaxonomies,
  contest: cartoonContest,
} as const;

export const CartoonSerializers: ContentSerializers<CartoonInput> = {
  save: (changes, model, { currentUser }) => {
    let issueDate = "";
    if ("categoryTaxonomies" in model && model.categoryTaxonomies.length) {
      const taxonomyIssueDate = model.categoryTaxonomies.filter(
        (el) => el?.taxonomy === "issues"
      );
      issueDate = taxonomyIssueDate[0]?.categories?.length
        ? `${taxonomyIssueDate[0].categories[0].name}`
        : "";
    }

    return {
      ...serializerFor(CartoonFields)(changes),
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      issueDate,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser }) => {
    return {
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

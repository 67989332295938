import {
  Count,
  CountVariables,
  FormFor,
  FormFor_content_Livestoryupdate,
  GetCurrentUser_currentUser,
  LivestoryupdateFields,
  LivestoryupdateInput,
  Organization,
  SearchFilters,
  GetLiveStoryUpdates,
  GetLiveStoryUpdatesVariables,
} from "@types";
import {
  useChangeset,
  useClipboardCopy,
  useContentAccessControlMatrix,
  useContentMutation,
  useToast,
} from "@hooks";
import { useIntl } from "react-intl";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { useCallback, useMemo, useState, useEffect } from "react";
import { ApolloError, useApolloClient, useQuery } from "@apollo/client";
import { Queries } from "@gql";
import { ValidationError } from "@condenast/cross-check";
import { ValidationContext, asList, get, getContentValidator } from "@lib";
import { LiveStoryUpdate } from "./LiveStoryUpdate";
import { LiveStoryEditUpdate } from "./LiveStoryEditUpdate";
import { EditorInstance } from "@condenast/ckeditor5-build-condenast";

const BLANK_LIVESTORY_UPDATE_DATA = {
  __typename: "Livestoryupdate",
  title: "",
  body: {
    content: "",
    textFormat: "MARKDOWN",
  },
  liveStoryId: "",
  showInFeed: true,
  allContributors: null,
  entityMetadata: null,
} as FormFor_content_Livestoryupdate;

const LIVE_STORY_UPDATE_LIMIT = 200;

export const LiveStoryUpdatePage = (props: {
  currentUser: GetCurrentUser_currentUser;
  currentOrganization: Organization;
  hostname?: string;
}) => {
  const { currentUser, currentOrganization, hostname } = props;
  const intl = useIntl();

  const { copilotCode, id } = useParams() as {
    copilotCode: string;
    id: string;
  };

  let [bodyRef, setBodyRef] =
    useState<React.MutableRefObject<EditorInstance | null>>();

  const { data, loading: liveStoryUpdateFormLoading } = useQuery<FormFor>(
    Queries.GET_LIVE_UPDATE_FORM,
    {
      variables: {
        organizationId: currentOrganization.organizationId,
        contentType: "livestoryupdate",
        id: id,
      },
    }
  );

  const [
    mostRecentLivestoryUpdateCreatedAtFrom,
    setMostRecentLivestoryUpdateCreatedAtFrom,
  ] = useState<string | undefined>();

  const {
    data: livestoryUpdatesData,
    loading: liveStoryUpdatesLoading,
    startPolling: startPollingRecentUpdates,
    stopPolling: stopPollingRecentUpdates,
  } = useQuery<GetLiveStoryUpdates, GetLiveStoryUpdatesVariables>(
    Queries.GET_LIVESTORY_UPDATES,
    {
      variables: {
        organizationId: currentOrganization.organizationId,
        id,
        limit: LIVE_STORY_UPDATE_LIMIT,
        createdAtFrom: mostRecentLivestoryUpdateCreatedAtFrom,
      },
    }
  );
  const liveUpdates = livestoryUpdatesData?.getLiveStory?.updates?.edges ?? [];

  let mostRecentUpdateCreatedAt = useMemo(
    () =>
      livestoryUpdatesData?.getLiveStory?.updates?.edges?.[0]?.node?.createdAt,
    [livestoryUpdatesData]
  );

  useEffect(() => {
    if (mostRecentUpdateCreatedAt) {
      let mostRecentUpdateCreatedAtDate = new Date(mostRecentUpdateCreatedAt);
      mostRecentUpdateCreatedAtDate.setMilliseconds(
        mostRecentUpdateCreatedAtDate.getMilliseconds() + 1
      );
      setMostRecentLivestoryUpdateCreatedAtFrom(
        mostRecentUpdateCreatedAtDate.toISOString()
      );
    }
  }, [mostRecentUpdateCreatedAt]);

  useEffect(() => {
    startPollingRecentUpdates(2000);

    return () => {
      stopPollingRecentUpdates();
    };
  }, [startPollingRecentUpdates, stopPollingRecentUpdates]);

  let [livestoryUpdate, setLivestoryUpdate] = useState({
    ...BLANK_LIVESTORY_UPDATE_DATA,
    id: id,
  });

  let modelDataChangeset = useChangeset(livestoryUpdate);

  let [model, , { changes }] = modelDataChangeset;

  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    []
  );
  const [serverErrors, setServerErrors] = useState<readonly Error[]>([]);

  const Client = useApolloClient();
  const validationEnvironment = useMemo(
    () => ({
      asList,
      get,
      count: async (options: {
        uri?: string | null;
        notid?: string | null;
      }) => {
        const filters: SearchFilters = {
          status: "published",
          ...options,
        };
        const results = await Client.query<Count, CountVariables>({
          query: Queries.COUNT,
          variables: {
            organizationId: currentOrganization.organizationId,
            filters,
          },
          fetchPolicy: "network-only",
        });
        return results.data.search;
      },
    }),
    [Client, currentOrganization]
  );

  let validate = useCallback(
    (model: LivestoryupdateFields | undefined, context: ValidationContext) => {
      if (!model) return undefined;

      const validator = getContentValidator(
        "livestoryupdate",
        currentOrganization.metadata.copilotCode,
        model,
        validationEnvironment
      );

      return validator?.(context);
    },
    [validationEnvironment]
  );

  const showSaveSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "The post will appear in the feed shortly.",
    }),
  });

  const showRemoveSuccessMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "The post will no longer appear in the feed.",
    }),
  });

  const showValidationErrorMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Validation errors occured.",
      description: "Notification message for validation errors",
    }),
  });

  const showServerErrorMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Sorry, something went wrong",
      description: "Notification message for server errors",
    }),
  });

  const hasValidationErrors = validationErrors.length > 0;
  const hasServerErrors = serverErrors.length > 0;

  const clearErrors = useCallback(() => {
    if (hasValidationErrors) {
      setValidationErrors([]);
    }
    if (hasServerErrors) {
      setServerErrors([]);
    }
  }, [hasValidationErrors, hasServerErrors]);

  const [serializeLivestoryupdate, createLivestoryupdate, createResult] =
    useContentMutation("livestoryupdate", "create");

  const createUpdate = useCallback(async () => {
    if (
      !serializeLivestoryupdate ||
      !createLivestoryupdate ||
      liveStoryUpdateFormLoading
    ) {
      showServerErrorMessage();
      return;
    }

    clearErrors();

    const errors = await (validate?.(model, "save") ?? []);
    if (errors.length) {
      setValidationErrors(errors);
      showValidationErrorMessage();
      return;
    }

    const input = serializeLivestoryupdate(
      {
        currentUser,
        intl,
        brandCode: currentOrganization.metadata.copilotCode,
        config: { hostnames: { consumer: currentOrganization.url } },
      },
      {
        ...(changes as FormFor_content_Livestoryupdate),
        liveStoryId: id,
      }
    );

    if (input != null) {
      try {
        const response = await createLivestoryupdate({
          variables: {
            organizationId: currentOrganization.organizationId,
            data: input,
          },
        });
        if (response?.errors?.length) {
          setServerErrors(response.errors);
          showServerErrorMessage();
          return;
        } else if (response?.data) {
          showSaveSuccessMessage();
          setLivestoryUpdate({ ...BLANK_LIVESTORY_UPDATE_DATA });
          bodyRef?.current?.setData("");
          return;
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          setServerErrors([
            {
              ...error,
              message:
                error?.graphQLErrors[0]?.extensions?.response.body.message ||
                error.message,
            },
          ]);
        } else if (error instanceof Error) {
          setServerErrors([error]);
        } else if (typeof error === "string") {
          setServerErrors([new Error(error)]);
        }
        showServerErrorMessage();
        return;
      }
      return;
    }
  }, [
    serializeLivestoryupdate,
    createLivestoryupdate,
    liveStoryUpdateFormLoading,
    clearErrors,
    validate,
    model,
    currentUser,
    intl,
    currentOrganization.url,
    currentOrganization.organizationId,
    changes,
    id,
    showServerErrorMessage,
    showValidationErrorMessage,
    showSaveSuccessMessage,
    bodyRef,
  ]);

  const [serializeLivestoryupdateSave, saveLivestoryupdate, saveResult] =
    useContentMutation("livestoryupdate", "save");

  const saveUpdate = useCallback(
    async (
      update: Partial<LivestoryupdateFields>,
      model: LivestoryupdateFields
    ) => {
      if (
        !serializeLivestoryupdateSave ||
        !saveLivestoryupdate ||
        liveStoryUpdateFormLoading
      ) {
        showServerErrorMessage();
        return;
      }

      clearErrors();

      const errors = await (validate?.(model, "save") ?? []);
      if (errors.length) {
        setValidationErrors(errors);
        showValidationErrorMessage();
        return;
      }

      const input = serializeLivestoryupdateSave(update, model, {
        currentUser,
        intl,
        brandCode: currentOrganization.metadata.copilotCode,
        config: { hostnames: { consumer: currentOrganization.url } },
      }) as LivestoryupdateInput;

      if (input != null) {
        try {
          const response = await saveLivestoryupdate({
            variables: {
              organizationId: currentOrganization.organizationId,
              id: model.id,
              data: input,
            },
          });
          if (response?.errors?.length) {
            setServerErrors(response.errors);
            showServerErrorMessage();
            return;
          } else if (response?.data) {
            showSaveSuccessMessage();
            return;
          }
        } catch (error) {
          if (error instanceof ApolloError) {
            setServerErrors([
              {
                ...error,
                message:
                  error?.graphQLErrors[0]?.extensions?.response.body.message ||
                  error.message,
              },
            ]);
          } else if (error instanceof Error) {
            setServerErrors([error]);
          } else if (typeof error === "string") {
            setServerErrors([new Error(error)]);
          }
          showServerErrorMessage();
          return;
        }
        return;
      }
    },
    [
      serializeLivestoryupdateSave,
      saveLivestoryupdate,
      currentOrganization,
      currentUser,
      intl,
      liveStoryUpdateFormLoading,
      showSaveSuccessMessage,
      showServerErrorMessage,
      showValidationErrorMessage,
      validate,
      clearErrors,
    ]
  );

  const removeUpdate = useCallback(
    async (
      update: Partial<LivestoryupdateFields>,
      model: LivestoryupdateFields
    ) => {
      if (
        !serializeLivestoryupdateSave ||
        !saveLivestoryupdate ||
        liveStoryUpdateFormLoading
      ) {
        showServerErrorMessage();
        return;
      }

      clearErrors();

      const input = serializeLivestoryupdateSave(update, model, {
        currentUser,
        intl,
        brandCode: currentOrganization.metadata.copilotCode,
        config: { hostnames: { consumer: currentOrganization.url } },
      }) as LivestoryupdateInput;

      if (input != null) {
        try {
          const response = await saveLivestoryupdate({
            variables: {
              organizationId: currentOrganization.organizationId,
              id: model.id,
              data: input,
            },
          });
          if (response?.errors?.length) {
            setServerErrors(response.errors);
            showServerErrorMessage();
            return;
          } else if (response?.data) {
            showRemoveSuccessMessage();
            return;
          }
        } catch (error) {
          if (error instanceof ApolloError) {
            setServerErrors([
              {
                ...error,
                message:
                  error?.graphQLErrors[0]?.extensions?.response.body.message ||
                  error.message,
              },
            ]);
          } else if (error instanceof Error) {
            setServerErrors([error]);
          } else if (typeof error === "string") {
            setServerErrors([new Error(error)]);
          }
          showServerErrorMessage();
          return;
        }
        return;
      }
    },
    [
      serializeLivestoryupdateSave,
      saveLivestoryupdate,
      liveStoryUpdateFormLoading,
      clearErrors,
      currentUser,
      intl,
      currentOrganization.url,
      currentOrganization.organizationId,
      showServerErrorMessage,
      showRemoveSuccessMessage,
    ]
  );

  const { pathname } = useLocation();
  const copyToClipboard = useClipboardCopy(`${hostname}${pathname}`);

  const showOnCopyMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Share link copied to clipboard",
    }),
  });

  const copyURI = () => {
    copyToClipboard();
    showOnCopyMessage();
  };

  const previousUrl = `/${copilotCode}/livestories/${id}`;

  const permissions = useContentAccessControlMatrix(
    currentOrganization,
    ["read", "update", "publish"],
    "livestoryupdates"
  )["livestoryupdates"];

  const liveStoryHed =
    data?.content?.__typename === "LiveStory" ? data.content.hed : "";

  return (
    <>
      <LiveStoryUpdate
        currentUser={props.currentUser}
        currentOrganization={props.currentOrganization}
        changeset={modelDataChangeset}
        validationErrors={validationErrors}
        onCreate={createUpdate}
        onRemove={removeUpdate}
        onCopyURI={copyURI}
        previousURL={previousUrl}
        formLoading={liveStoryUpdateFormLoading}
        updatesLoading={liveStoryUpdatesLoading}
        form={data?.form}
        permissions={permissions}
        saveInProgress={createResult?.loading ?? saveResult?.loading ?? false}
        liveUpdates={liveUpdates}
        livestoryHed={liveStoryHed}
        setBodyRef={setBodyRef}
      />
      <Routes>
        <Route
          path={`:updateId/edit`}
          element={
            <LiveStoryEditUpdate
              onSave={saveUpdate}
              onCopyURI={copyURI}
              currentUser={currentUser}
              formLoading={liveStoryUpdateFormLoading}
              currentOrganization={currentOrganization}
              saveInProgress={false}
              permissions={permissions}
              validationErrors={validationErrors}
              form={data?.form}
              liveUpdates={liveUpdates}
              previousURL={previousUrl}
            />
          }
        />
      </Routes>
    </>
  );
};

LiveStoryUpdatePage.displayName = "LiveStoryUpdatePage";

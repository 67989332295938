import {
  ContainerInput,
  ContainerCurationInput,
  BundleFields_containers,
  BundleFields_containers_curations_edges,
  BundleFields_containers_searches,
  BundleSearchInput,
  ContentSummaryFields,
} from "@types";
import { categoryTaxonomies } from "./";

export function bundleContainers(
  input: BundleFields_containers[]
): ContainerInput[] {
  return input.map((container) => {
    const containerAssetID = container?.containerAsset?.edges[0]?.node?.id;
    const containerAssetContentType =
      container?.containerAsset?.edges[0]?.node?.contentType;

    return {
      id: container.id,
      componentPreset: container.componentPreset,
      curationContainerType: container.curationContainerType,
      dek: container.dek,
      hed: container.hed,
      body: container.body && {
        textFormat: container.body.textFormat,
        content: container.body.content,
      },
      layout: container.layout,
      containerAsset:
        containerAssetID && containerAssetContentType
          ? { id: containerAssetID, contentType: containerAssetContentType }
          : null,
      curations: container.curations && mapCurations(container.curations.edges),
      searches: container.searches && mapSearches(container.searches),
    };
  });
}

function mapCurations(
  edges: (BundleFields_containers_curations_edges | null)[]
): ContainerCurationInput[] {
  return (
    edges?.reduce((accumulatedCurations, curation) => {
      let node = curation?.node as ContentSummaryFields;
      if (curation && node) {
        let { hed, dek } = curation;
        accumulatedCurations.push({
          hed,
          dek,
          node: {
            id: node.id,
            contentType: node.contentType,
          },
        });
      }
      return accumulatedCurations;
    }, [] as ContainerCurationInput[]) ?? []
  );
}

function mapSearches(
  searches: (BundleFields_containers_searches | null)[]
): BundleSearchInput[] {
  return searches.map((search) => {
    return search
      ? {
          query: search.query,
          sort: search.sort,
          size: search.size,
          title: search.title,
          types: search.types,
          contributor: search.contributor,
          contentSource: search.contentSource,
          issueDate: search.issueDate,
          language: search.language,
          categoriesFilterOperator: search.categoriesFilterOperator,
          notCategoriesFilterOperator: search.notCategoriesFilterOperator,
          includedCategoryTaxonomies:
            search.includedCategoryTaxonomies &&
            categoryTaxonomies(search.includedCategoryTaxonomies),
          excludedCategoryTaxonomies:
            search.excludedCategoryTaxonomies &&
            categoryTaxonomies(search.excludedCategoryTaxonomies),
        }
      : {};
  });
}

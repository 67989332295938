import styled from "styled-components";
import { Button, Card, Label } from "@components";
import {
  RedirectIcon,
  CopilotRainbowIcon,
  IntercomChatLogoIcon,
  FormationLightLogoFlatIcon,
  GoogleChromeLogoFlatIcon,
} from "@condenast/gemini/icons";
import { FormattedMessage } from "react-intl";

const FooterWrapper = styled(Card).attrs({ as: "section" })`
  margin: 0;
  margin-block-start: var(--spacing-md);
  padding: var(--spacing-sm) 0 0;
  position: relative;
  height: fit-content;
  max-width: none;
  @media (max-width: 768px) {
    margin: 0;
    margin-top: var(--spacing-sm);
    border-radius: var(--spacing-xxs);
  }
  @media (max-width: 600px) {
    border-radius: unset;
    margin: 0;
    margin: var(--spacing-sm) 0;
  }
`;

const ResourceContainer = styled.div`
  padding: 0 0 var(--spacing-sm);
  font: ${(props) => props.theme.FontSmallerHeading};
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
  padding-left: var(--spacing-sm);
`;

const LinkBtnContainer = styled.div`
  padding: var(--spacing-xs);
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const LinkContainer = styled.div`
  padding: var(--spacing-sm);
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  color: var(--color-gray-1);
  font-weight: 400;
  margin: var(--spacing-xs);
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
`;

const StyledLabel = styled(Label)`
  font-weight: 400;
  margin-right: var(--spacing-sm);
`;

const StyledCopilotRainbowIcon = styled(CopilotRainbowIcon)`
  width: 24px;
  height: 24px;
  margin-left: var(--spacing-sm);
`;

const StyledChromeIcon = styled(GoogleChromeLogoFlatIcon)`
  width: 24px;
  height: 24px;
  margin-left: var(--spacing-sm);
`;

const StyledCopilotHelpIcon = styled(FormationLightLogoFlatIcon)`
  width: 24px;
  height: 24px;
  margin-left: var(--spacing-sm);
`;

const StyledAnchorTag = styled.a`
  text-decoration: none;
  color: var(--color-black);
`;

const SupportBtnContainer = styled.div`
  padding: var(--spacing-xs);
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SupportText = styled.div`
  color: var(--color-black);
  font-weight: 700;
  margin: var(--spacing-xs);
  border: 0;
  box-shadow: none;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

const IntercomContatinerMain = styled.div`
  border: none;
  max-width: 36px;
  width: 36px;
  max-height: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgb(29, 161, 242);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
  -webkit-transition: -webkit-transform 167ms cubic-bezier(0.33, 0, 0, 1);
  -webkit-transition: transform 167ms cubic-bezier(0.33, 0, 0, 1);
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1);
  box-sizing: content-box;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: var(--spacing-xs);
`;

const IntercomContatiner = styled.div`
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  -webkit-transition: -webkit-transform 100ms linear, opacity 80ms linear;
  -webkit-transition: transform 100ms linear, opacity 80ms linear;
  transition: transform 100ms linear, opacity 80ms linear;
  padding-top: var(--spacing-sm);
  text-align: center;
  display: block;
  top: 0;
`;

const StyledIntercomIcon = styled(IntercomChatLogoIcon)`
  width: 18px;
  height: 18px;
  fill: white;
`;

export const DashboardFooter = () => {
  return (
    <>
      <FooterWrapper>
        <ResourceContainer>{"Resources"}</ResourceContainer>
        <LinkContainer>
          <LinkBtnContainer>
            <StyledAnchorTag
              target="_blank"
              href={
                "https://intercom.help/formation/en/collections/161495-copilot-help"
              }
            >
              <StyledButton>
                <StyledCopilotHelpIcon size="regular" />
                <StyledLabel>
                  <FormattedMessage
                    defaultMessage={"Visit the Copilot help center"}
                  />
                </StyledLabel>
                <RedirectIcon size="regular" />
              </StyledButton>
            </StyledAnchorTag>
            <StyledAnchorTag
              target="_blank"
              href={
                "https://docs.google.com/forms/d/e/1FAIpQLSek7SyJQWYz6rt7u2kpqU68GmuYsXxQUV-0zZ8GRzDXmQkZ5g/viewform"
              }
            >
              <StyledButton>
                <StyledCopilotRainbowIcon />
                <StyledLabel>
                  <FormattedMessage
                    defaultMessage={"Sign up for Copilot Training"}
                  />
                </StyledLabel>
                <RedirectIcon size="regular" />
              </StyledButton>
            </StyledAnchorTag>
            <StyledAnchorTag
              target="_blank"
              href={
                "https://chrome.google.com/webstore/detail/copilot-chrome-extension/lhlaljenmogghbebpeoemjcniedoenpa"
              }
            >
              <StyledButton>
                <StyledChromeIcon size="regular" />
                <StyledLabel>
                  <FormattedMessage
                    defaultMessage={"Get the Copilot Chrome Extension"}
                  />
                </StyledLabel>
                <RedirectIcon size="regular" />
              </StyledButton>
            </StyledAnchorTag>
          </LinkBtnContainer>
          <SupportBtnContainer>
            <StyledLabel>
              <FormattedMessage
                defaultMessage={
                  "Having an issue or not able to find what you need?"
                }
              />
            </StyledLabel>
            <SupportText>
              <IntercomContatinerMain>
                <IntercomContatiner>
                  <StyledIntercomIcon size="regular" />
                </IntercomContatiner>
              </IntercomContatinerMain>
              <FormattedMessage defaultMessage={"Contact Live Support"} />
            </SupportText>
          </SupportBtnContainer>
        </LinkContainer>
      </FooterWrapper>
    </>
  );
};

DashboardFooter.displayName = "DashboardFooter";

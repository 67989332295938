import {
  ContentSummary,
  CreateArticle_content_Article_advertiser_ContentConnection_edges_node_metadata_ContentSummaryExternalID as ContentSummaryExternalID,
  CreateArticle_content_Article_advertiser_ContentConnection_edges_node_metadata_ContentSummaryBundle as ContentSummaryBundle,
  FieldNames,
} from "@types";
import { ARIA, Field, UploadButton } from "@components";
import { Button } from "@condenast/gemini";
import { ContentTypeIcon } from "@condenast/gemini/icons";
import { RoutableLink, SearchResult, Spinner } from "@components";
import { useDefinedMessages, useToast } from "@hooks";
import { ComponentProps, FC, useCallback, useContext } from "react";
import { EllipsisIcon, WandIcon, AssetIcon } from "@condenast/gemini/icons";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { SnowplowContext } from "@contexts";
import { memo } from "@lib";

const Wrapper = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

const MenuIcon = styled(EllipsisIcon)`
  color: ${(props) => props.theme.Color};
`;

const EmptyTile = styled.div<{ $isHovered: boolean }>`
  position: relative;
  width: 15rem;
  height: 20rem;
  border: ${(props) =>
    props.$isHovered
      ? "2px dashed var(--color-blue-50)"
      : "2px dashed var(--color-gray-5)"};
  border-radius: ${(props) => props.theme.CornerRadius};

  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-xs);

  justify-content: center;
  align-items: center;

  &[aria-invalid="true"] {
    border: 2px dashed var(--color-red-50);
  }
`;

const ActionButton = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
`;

const MenuButton = styled(ARIA.MenuButton)`
  border: 1px solid ${(props) => props.theme.SelectButtonHoverBackgroundColor};
  background: ${(props) => props.theme.Background};
`;

const UploadMessage = styled.p`
  position: absolute;
  bottom: 1rem;
  font: var(--font-small-statements);
  text-align: center;
`;

const AutoFill = styled.div`
  position: absolute;
  top: var(--spacing-sm);
  right: var(--spacing-sm);

  button {
    box-shadow: ${(props) => props.theme.FieldRing};
  }
`;

const EditAssetOverlay = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  color: var(--color-gray-6);

  &:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    height: 100%;
    background: var(--color-gray-1);
    opacity: 50%;
    z-index: -1;
  }

  & svg {
    margin-right: var(--spacing-xxs);
  }
`;

const LinkWithOverlay: FC<{
  linkTo: string;
  contentType: string;
  className?: string;
}> = (props) => {
  const { translateContentType } = useDefinedMessages();
  return (
    <RoutableLink to={props.linkTo} className={props.className} target="_blank">
      <EditAssetOverlay>
        <ContentTypeIcon contentType={props.contentType} size="regular" />
        <span>Edit {translateContentType(props.contentType, 1)}</span>
      </EditAssetOverlay>
      {props.children}
    </RoutableLink>
  );
};

const LinkTile = styled(LinkWithOverlay)`
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  color: ${(props) => props.theme.Color};

  &:hover {
    ${EditAssetOverlay} {
      display: flex;
    }
  }
`;

const StyledSearchResult = styled(SearchResult)`
  border: 1px solid ${(props) => props.theme.DividerColor};
  border-radius: ${(props) => props.theme.CornerRadius};
`;

const TileWrapper = styled.div`
  max-width: 15rem;
`;

export const EmptyThumbnail: FC<
  Omit<ComponentProps<typeof Field>, "children"> & {
    autofill?: () => void;
    selectAsset: () => void;
    onUpload?: (files: FileList) => Promise<void>;
    uploadLimit?: number;
    isUploading?: boolean;
    accept?: string[];
  }
> = (props) => {
  const {
    autofill,
    selectAsset,
    onUpload,
    uploadLimit,
    isUploading,
    accept,
    ...fieldProps
  } = props;
  const allowUploads = !!onUpload && !!accept?.length;
  let intl = useIntl();

  const { trackContentEvent } = useContext(SnowplowContext);

  let showTooManyFilesMessage = useToast({
    type: "error",
    children: "Error uploading: Too many files",
  });

  let showIncorrectFileTypeMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Error uploading: Unsupported file type",
    }),
  });

  let showFileTooLargeMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Error uploading: File too large",
    }),
  });

  let showUploadErrorMessage = useToast({
    type: "error",
    children: intl.formatMessage({ defaultMessage: "Error uploading" }),
  });

  const addFromUpload = useCallback(
    (files: FileList) => {
      onUpload?.(files)
        // upload can reject
        .catch((e) => {
          if (e.message.includes("Request Entity Too Large")) {
            showFileTooLargeMessage();
          } else {
            showUploadErrorMessage();
          }
        });
      trackContentEvent("uploaded");
    },
    [onUpload, showFileTooLargeMessage, showUploadErrorMessage]
  );

  const [{ isHovered }, dropRef] = useDrop(
    () => ({
      accept: NativeTypes.FILE,
      canDrop: (item: { files: File[]; dataTransfer: DataTransfer }) => {
        let withinLimit = !uploadLimit || item.files.length <= uploadLimit;
        if (!withinLimit) {
          showTooManyFilesMessage();
        }

        let isCorrectFileType = item.files.every((file) =>
          accept?.includes(file.type)
        );
        if (!isCorrectFileType) {
          showIncorrectFileTypeMessage();
        }

        let canDrop = !!(allowUploads && withinLimit && isCorrectFileType);

        return canDrop;
      },
      collect: (monitor) => ({
        isHovered: monitor.isOver(),
      }),
      drop(item) {
        addFromUpload(item.dataTransfer.files);
      },
    }),
    [allowUploads, uploadLimit, onUpload]
  );
  return (
    <Wrapper {...fieldProps}>
      <EmptyTile
        ref={dropRef}
        aria-invalid={!!props.errors?.length}
        $isHovered={isHovered && allowUploads}
      >
        {isUploading ? (
          <Spinner size="large" />
        ) : (
          <>
            {autofill && (
              <AutoFill>
                <Button onClick={autofill} aria-label="autofill" size="small">
                  <WandIcon size="regular" />
                </Button>
              </AutoFill>
            )}
            <ActionButton onClick={selectAsset}>
              <AssetIcon size="regular" />
              <FormattedMessage defaultMessage="Search" />
            </ActionButton>
            {allowUploads && (
              <>
                <UploadButton
                  onChange={addFromUpload}
                  accept={accept.join(",")}
                  multiple={uploadLimit !== 1}
                  disabled={isUploading}
                />
                <UploadMessage>
                  <FormattedMessage defaultMessage="Drag media here to upload" />
                </UploadMessage>
              </>
            )}
          </>
        )}
      </EmptyTile>
    </Wrapper>
  );
};

export const ThumbnailField: FC<
  Omit<ComponentProps<typeof Field>, "children"> & {
    value: ContentSummary | undefined | null;
    cdnHost: string;
    editCneAsset?: () => void;
    selectAsset: () => void;
    removeAsset: () => void;
    autofill?: () => void;
    onUpload?: (files: FileList) => Promise<void>;
    uploadLimit?: number;
    accept?: string[];
    isUploading?: boolean;
    assetindex?: number;
    fieldName?: FieldNames | undefined | null;
    treatment?: string;
    showEdit?: boolean;
  }
> = memo((props) => {
  const {
    value,
    cdnHost,
    editCneAsset,
    selectAsset,
    removeAsset,
    autofill,
    onUpload,
    uploadLimit,
    isUploading,
    accept,
    assetindex,
    fieldName,
    treatment,
    showEdit,
    ...fieldProps
  } = props;

  let commerceMetadata: ContentSummaryExternalID | undefined;
  let bundleMetadata: ContentSummaryBundle | undefined;
  if (value) {
    commerceMetadata = value.metadata?.find(
      (metadataItem) => metadataItem.type === "ContentSummaryExternalID"
    ) as ContentSummaryExternalID | undefined;

    bundleMetadata = value.metadata?.find(
      (metadataItem) => metadataItem.type === "ContentSummaryBundle"
    ) as ContentSummaryBundle | undefined;
  }

  let intl = useIntl();

  let menuItems = [] as ComponentProps<typeof ARIA.Menu>["items"];

  if (value?.editUrl) {
    menuItems.push({
      value: intl.formatMessage({
        defaultMessage: "Edit",
      }),
      role: "link",
      to: value.editUrl,
      target: "_blank",
    });
  }

  if (
    fieldName === "videosLede" &&
    value?.contentType === "cnevideo" &&
    showEdit &&
    editCneAsset
  ) {
    menuItems.push({
      value: intl.formatMessage({
        defaultMessage: "Edit Video Controls",
      }),
      role: "action",
      onClick: editCneAsset,
    });
  }
  menuItems.push(
    {
      value: intl.formatMessage({
        defaultMessage: "Replace",
      }),
      role: "action",
      onClick: selectAsset,
    },
    {
      value: intl.formatMessage({
        defaultMessage: "Remove",
      }),
      role: "action",
      onClick: removeAsset,
    }
  );

  return !value ? (
    <EmptyThumbnail {...props} />
  ) : (
    <Field {...fieldProps}>
      {treatment === "slat" ? (
        <StyledSearchResult
          treatment="asset-selector"
          result={value}
          cdnHost={cdnHost}
          assetindex={assetindex}
        >
          <MenuButton
            id={`${props.id}-actions`}
            size="small"
            treatment="secondary"
            aria-label="Actions menu"
            menu={{
              items: menuItems,
              children: (item: string) => item,
            }}
          >
            <MenuIcon size="regular" />
          </MenuButton>
        </StyledSearchResult>
      ) : (
        <TileWrapper>
          <SearchResult
            assetAs={value.editUrl ? LinkTile : undefined}
            linkTo={value.editUrl}
            contentTypeLabel={value.contentTypeLabel}
            contentType={
              value.contentType === "contentreference" &&
              commerceMetadata?.provider === "commerce-tools"
                ? "commerceproduct"
                : value.contentType === "bundle" && bundleMetadata?.bundleType
                ? bundleMetadata?.bundleType
                : value.contentType
            }
            treatment="asset-tile"
            result={value}
            cdnHost={cdnHost}
            assetindex={assetindex}
          >
            <MenuButton
              id={`${props.id}-actions`}
              size="small"
              treatment="secondary"
              aria-label="Actions menu"
              menu={{
                items: menuItems,
                children: (item: string) => item,
              }}
            >
              <MenuIcon size="regular" />
            </MenuButton>
          </SearchResult>
        </TileWrapper>
      )}
    </Field>
  );
});
ThumbnailField.displayName = "ThumbnailField";

import { defineMessages } from "react-intl";

export const LANGUAGES = defineMessages({
  "en-GB": {
    defaultMessage: "🇬🇧 English - International",
  },
  "en-US": {
    defaultMessage: "🇺🇸 English - USA",
  },
  "es-ES": {
    defaultMessage: "🇪🇸 Español (Spanish)",
  },
  "de-DE": {
    defaultMessage: "🇩🇪 Deutsch (German)",
  },
  "fr-FR": {
    defaultMessage: "🇫🇷 Français (French)",
  },
  "it-IT": {
    defaultMessage: "🇮🇹 Italiano (Italian)",
  },
  "ja-JP": {
    defaultMessage: "🇯🇵 日本語 (Japanese)",
  },
  "ru-RU": {
    defaultMessage: "🇷🇺 Pусский (Russian)",
  },
  "zh-Hans": {
    defaultMessage: "🇨🇳 正体字 (Chinese - Simplified)",
  },
  "zh-Hant-TW": {
    defaultMessage: "🇹🇼 繁體字 (Chinese - Traditional)",
  },
});

import type { ContentSerializers } from "../types";
import type { RecipeInput } from "@types";
import {
  allContributors,
  categoryTaxonomies,
  contentConnection,
  ingredientGroups,
  preparationGroups,
  seo,
  temperatures,
  times,
  servingSizeInfo,
} from "../fields";
import {
  serializerFor,
  includeAllMetadataFields,
  entityMetadata,
} from "../utils";

const RecipeFields = {
  allContributors,
  photosTout: contentConnection,
  related: contentConnection,
  cookbooks: contentConnection,
  photosSocial: contentConnection,
  imageSocial: contentConnection,
  photosLede: contentConnection,
  photosBadge: contentConnection,
  photos: contentConnection,
  interludeOverride: contentConnection,
  videosLede: contentConnection,
  venue: contentConnection,
  products: contentConnection,
  ingredientProducts: contentConnection,
  categoryTaxonomies,
  ingredientGroups,
  preparationGroups,
  seo,
  temperatures,
  times,
  servingSizeInfo,
} as const;

export const RecipeSerializers: ContentSerializers<RecipeInput> = {
  save: (changes, model, { currentUser }) => {
    if (!("hed" in model)) {
      return null;
    }
    const authorName = `${currentUser.firstName} ${currentUser.lastName}`;
    const metadataFields = includeAllMetadataFields(model, changes);
    return {
      ...serializerFor(RecipeFields)(changes),
      ...metadataFields,
      authorName,
      hed: model.hed,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const authorName = `${currentUser.firstName} ${currentUser.lastName}`;
    const createTitle = `Untitled / ${authorName} / ${currentDateTime}`;

    return {
      authorName,
      entityMetadata: { user: [`${currentUser.id}`] },
      hed: createTitle,
    };
  },
};

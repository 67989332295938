import { ContextualOverrideTakeoverProps } from "../../../../types";
import uuid from "uuid-random";
import { MdEditor } from "../../../../../index";
import { Takeover, Button } from "@components";
import { useChangeset } from "@hooks";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { MdEditorProps } from "../../../../utils";
import { SearchResult } from "@components";
import { ContentSummary } from "@types";
import { SearchResultSlatContainer, CheckIconWrapper } from "@condenast/gemini";

const StyledSearchResult = styled(SearchResult)`
  border-radius: var(--spacing-xxs);
  border: 1px solid ${(props) => props.theme.DividerColor};
`;

const ContextualOverrideTakeoverHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-right: var(--spacing-xs);
`;

const ContextualOverrideTakeoverBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

const ContextualOverrideCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;

const SearchResultWrapper = styled.div`
  ${SearchResultSlatContainer}:hover {
    ${CheckIconWrapper} {
      display: none;
    }
    background: ${(props) => props.theme.PrimaryColor};
    cursor: initial;
  }
`;
const ContextualOverrideTakeover: React.FC<ContextualOverrideTakeoverProps> = ({
  items,
  onUpdate,
  onClose,
  cdnHost,
  configs,
  currentOrganization,
  currentUser,
  permissions,
  errors,
}) => {
  let [changeset, setValue, { hasChanges }] = useChangeset(items);

  const SharedControlProps = {
    currentOrganization,
    currentUser,
    permissions,
    errors,
    id: uuid(),
    children: null,
  };

  return (
    <Takeover.TakeoverWrapper
      header={
        <ContextualOverrideTakeoverHeader>
          <FormattedMessage
            defaultMessage="{itemCount, plural, one {Edit Item} other {Edit Items}}"
            values={{ itemCount: items.length }}
          />

          <Button
            treatment="primary"
            disabled={!hasChanges}
            onClick={() => {
              onUpdate(Object.values(changeset));
              onClose();
            }}
          >
            <FormattedMessage defaultMessage="Save" />
          </Button>
        </ContextualOverrideTakeoverHeader>
      }
      body={
        <ContextualOverrideTakeoverBody>
          {Object.entries(changeset).map(([index, item]) => {
            if (!item || !item.node) return null;
            return (
              <ContextualOverrideCard key={index}>
                <SearchResultWrapper>
                  <StyledSearchResult
                    result={item.node as ContentSummary}
                    treatment="slat-result"
                    cdnHost={cdnHost}
                    clampTitle={5}
                    key={index}
                  />
                </SearchResultWrapper>

                <MdEditor
                  {...SharedControlProps}
                  {...MdEditorProps}
                  name="hed"
                  labelKey="title"
                  placeholderKey={"placeholder.articles.hed"}
                  model={item as unknown as Record<string, unknown>}
                  setValue={(key, value) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore our setValue type is incorrect now that nested keys are allowed
                    setValue(`${index}.${key}`, value);
                  }}
                  richTextConfiguration={
                    configs?.hedRichTextConfiguration || null
                  }
                  autogeneratePath={"node.title.content"}
                />
                <MdEditor
                  {...SharedControlProps}
                  {...MdEditorProps}
                  name="dek"
                  labelKey="introduction"
                  placeholderKey={"placeholder.articles.dek"}
                  model={item as unknown as Record<string, unknown>}
                  setValue={(key, value) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore our setValue type is incorrect now that nested keys are allow
                    setValue(`${index}.${key}`, value);
                  }}
                  richTextConfiguration={
                    configs?.dekRichTextConfiguration || null
                  }
                  autogeneratePath={"node.dek"}
                />
              </ContextualOverrideCard>
            );
          })}
        </ContextualOverrideTakeoverBody>
      }
      onClose={onClose}
    />
  );
};

export default ContextualOverrideTakeover;

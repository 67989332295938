import { ContextualHelp, Label } from "@components";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import styled from "styled-components";
import { DashboardFilters } from "../DashboardFilters";
import {
  DashboardFilter,
  GetConfigs,
  SearchFilters,
  GetCurrentUser_currentUser,
} from "@types";
import {
  EditIcon,
  PublishedIcon,
  ScheduledIcon,
  PublishedWithChangesIcon,
  ScheduledWithChangesIcon,
} from "@condenast/gemini/icons";
import { ReactElement } from "react";
import { Toggle } from "@condenast/gemini";
import { useState, useCallback } from "react";

const Header = styled.div`
  display: grid;
  gap: var(--spacing-xxs);
  padding-bottom: var(--spacing-xxs);
  border-bottom: 1px solid var(--color-gray-5);
  grid-template-columns: 8fr 2fr;
`;

const Title = styled.h2`
  padding-right: 16px;
  padding-left: 16px;
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: var(--color-black);
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
`;

const Filter = styled.div`
  padding-right: var(--spacing-sm);
  grid-column-start: -1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContextualHelpInfoContainer = styled.div`
  padding: var(--spacing-sm);
`;

const LegendHeader = styled(Label)`
  font-family: var(--font-family-ibm-plex-sans);
  margin-left: var(--spacing-xs);
  max-width: 260px;
  font-weight: 100;
`;

const LegendLabel = styled(Label)`
  font-family: var(--font-family-ibm-plex-sans);
  font-size: var(--font-asset-title);
  letter-spacing: 0em;
  font-weight: 700;
`;

const Legends = styled(Label)`
  font-family: var(--font-family-ibm-plex-sans);
  font-size: var(--font-asset-title);
  letter-spacing: 0em;
  font-weight: 100;
  text-align: center;
  margin-left: var(--spacing-xs);
`;

const LegendsContainer = styled.div`
  display: flex;
  padding-top: var(--spacing-xs);
  align-items: center;
  justify-content: flex-start;
`;

const IconWrapper = styled.div`
  border-radius: var(--spacing-xxs);
  padding: var(--spacing-xxs);
  background-color: var(${(props) => props.color});

  > span {
    display: flex;
  }
`;

const IconSpan = styled.span`
  circle {
    fill: var(--color-red-50);
  }
`;

const StyledToggle = styled(Toggle)`
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xs);
`;

const ToggleLabel = styled(Label)`
  font-family: var(--font-family-ibm-plex-sans);
  font-size: var(--font-asset-title);
  letter-spacing: 0em;
  font-weight: 700;
`;

type HeaderInfoType = {
  info: boolean;
  message: string;
};

const ScheduledListContextualHelp = () => {
  const legends = (props: {
    Icon?: ReactElement;
    label: MessageDescriptor;
    color: string;
  }) => (
    <LegendsContainer>
      <IconWrapper color={props.color}>{props.Icon}</IconWrapper>
      <Legends>
        <FormattedMessage {...props.label} />
      </Legends>
    </LegendsContainer>
  );

  return (
    <ContextualHelp id="Header">
      <LegendHeader>
        <FormattedMessage defaultMessage="Content that has been published and scheduled by the brand site will appear in descending order from the published date or range selected" />
      </LegendHeader>
      <ContextualHelpInfoContainer>
        <LegendLabel>
          <FormattedMessage defaultMessage="Legend" />
        </LegendLabel>
        {legends({
          Icon: <EditIcon size="small" />,
          label: { id: "Draft", defaultMessage: "Draft" },
          color: "--color-gray-6",
        })}
        {legends({
          Icon: <PublishedIcon size="small" />,
          label: { id: "Published", defaultMessage: "Published" },
          color: "--color-green-90",
        })}
        {legends({
          Icon: (
            <IconSpan>
              <PublishedWithChangesIcon size="small" />
            </IconSpan>
          ),
          label: {
            id: "Published, with unpublished edits",
            defaultMessage: "Published, with unpublished edits",
          },
          color: "--color-green-90",
        })}
        {legends({
          Icon: <ScheduledIcon size="small" />,
          label: { id: "Scheduled", defaultMessage: "Scheduled" },
          color: "--color-yellow-90",
        })}
        {legends({
          Icon: (
            <IconSpan>
              <ScheduledWithChangesIcon size="small" />
            </IconSpan>
          ),
          label: {
            id: "Scheduled, with unpublished edits",
            defaultMessage: "Scheduled, with unpublished edits",
          },
          color: "--color-yellow-90",
        })}
      </ContextualHelpInfoContainer>
    </ContextualHelp>
  );
};

export const ListHeader = (props: {
  label: string;
  headerInfo?: HeaderInfoType | undefined;
  headerFilter?: boolean | undefined;
  updateSearchQueryFilter: (filter: SearchFilters) => void;
  preselectedFilters: SearchFilters;
  filterType: string;
  organizationId: string;
  defaultFilters: DashboardFilter;
  currentUser: GetCurrentUser_currentUser;
  config?: GetConfigs | undefined;
}) => {
  let { label, headerInfo, ...filterProps } = props;
  let { currentUser } = filterProps;

  const [myWorkOnly, setMyWorkOnly] = useState(
    filterProps.preselectedFilters.privilege_user === currentUser.id
      ? true
      : false
  );

  const applyFilters = (filter: SearchFilters) => {
    setMyWorkOnly(filter.privilege_user === currentUser.id);
    props.updateSearchQueryFilter(filter);
  };

  const toggleMyWorkFilter = useCallback(() => {
    let filters: DashboardFilter = { ...filterProps.preselectedFilters };

    filters.privilege_user = myWorkOnly ? undefined : currentUser.id;
    filters["store-privilege_user"] = myWorkOnly
      ? undefined
      : [
          {
            key: currentUser.id,
            value: currentUser,
            label: `${currentUser.firstName} ${currentUser.lastName}`,
            disabled: false,
          },
        ];

    applyFilters(filters);
    return null;
  }, [filterProps.preselectedFilters, myWorkOnly, currentUser]);

  return (
    <Header>
      <Title>
        {label}
        {headerInfo?.info && (
          <ScheduledListContextualHelp></ScheduledListContextualHelp>
        )}
      </Title>
      <Filter>
        {filterProps.filterType === "mywork" &&
        currentUser.role !== "contributor" ? (
          <>
            <ToggleLabel>
              <FormattedMessage defaultMessage={"My work only"} />
            </ToggleLabel>
            <StyledToggle
              id={"mywork"}
              onChange={toggleMyWorkFilter}
              value={myWorkOnly}
            />
          </>
        ) : (
          <></>
        )}
        <DashboardFilters onApplyFilters={applyFilters} {...filterProps} />
      </Filter>
    </Header>
  );
};

ListHeader.displayName = "ListHeader";

import {
  SearchFilters,
  BundleFields_containers_searches,
  FormFor_form_controls_BundleContainersFormControl_configs_containers,
  FormFor_form_controls_BundleContainersFormControl_configs_containers_LegacyBundleContainerConfig as LegacyBundleContainerConfig,
  FILTER_OPERATOR,
  FormFor_content_CuratedSearch_categoryTaxonomies as TCategoryTaxonomy,
  FormFor_content_Bundle_containers,
} from "@types";
import { KeyedContainer } from "./types";

export const isLegacyBundleContainerConfig = (
  containerConfig?: FormFor_form_controls_BundleContainersFormControl_configs_containers
): containerConfig is LegacyBundleContainerConfig => {
  if (!containerConfig) return false;
  return containerConfig.__typename === "LegacyBundleContainerConfig";
};

export const isKeyedContainer = (
  container: FormFor_content_Bundle_containers | KeyedContainer
): container is KeyedContainer => {
  return "containerKey" in container;
};

export const createContainer = (containerData: {
  containerKey: string;
  curationContainerType: string;
  itemLimit?: number;
}): KeyedContainer => {
  const defaultValues = {
    __typename: "Container" as const,
    componentPreset: null,
    dek: null,
    hed: null,
    body: null,
    layout: null,
    photoId: null,
    curations: {
      __typename: "ContainerToCurationsConnection" as const,
      page: 1,
      limit: 100,
      totalResults: 0,
      edges: [],
    },
    searches: null,
    containerAsset: null,
  };
  return {
    ...defaultValues,
    ...containerData,
  };
};

const mapCategoryIds = (categoryTaxonomies: TCategoryTaxonomy[]) =>
  categoryTaxonomies
    .flatMap((categoryTaxonomy) =>
      categoryTaxonomy?.categories.map((cat) => cat?.id)
    )
    .join(",");

export const serializeSearch = (
  search: BundleFields_containers_searches
): SearchFilters => {
  const searchVariables: SearchFilters = {
    nottypes: "pushnotification,categories,livestoryupdate",
    view: "live",
  };

  // Direct mappings
  if (search.query) searchVariables.q = search.query;
  if (search.sort) searchVariables.sort = search.sort;
  if (search.types) searchVariables.types = search.types.join(",");
  if (search.contentSource)
    searchVariables.contentSource = search.contentSource;
  if (search.issueDate) searchVariables.issueDate = search.issueDate;
  if (search.contributor)
    searchVariables.contributor = search.contributor.join(",");
  if (search.language) searchVariables.lang = search.language.join(",");

  // Category mappings
  if (
    search.includedCategoryTaxonomies &&
    search.includedCategoryTaxonomies.length > 0
  ) {
    const includedCategoryIds = mapCategoryIds(
      search.includedCategoryTaxonomies
    );

    if (search.categoriesFilterOperator === FILTER_OPERATOR.or) {
      searchVariables.categoryIds = includedCategoryIds;
    } else if (search.categoriesFilterOperator === FILTER_OPERATOR.and) {
      searchVariables.categoryIds_and = includedCategoryIds;
    }
  }

  if (
    search.excludedCategoryTaxonomies &&
    search.excludedCategoryTaxonomies.length > 0
  ) {
    const excludedCategoryIds = mapCategoryIds(
      search.excludedCategoryTaxonomies
    );

    if (search.notCategoriesFilterOperator === FILTER_OPERATOR.or) {
      searchVariables.notcategoryIds = excludedCategoryIds;
    } else if (search.notCategoriesFilterOperator === FILTER_OPERATOR.and) {
      searchVariables.notcategoryIds_and = excludedCategoryIds;
    }
  }

  return searchVariables;
};

export const FieldsectionProps = {
  style: null,
  description: null,
};

export const NumberFieldProps = {
  label: null,
  placeholder: null,
  disabled: false,
  noLabel: null,
  decimals: null,
};

export const TextFieldProps = {
  characterCount: false,
  wordCount: false,
  label: null,
  disabled: false,
  noLabel: null,
  labelKey: null,
  placeholderKey: null,
  maxCount: null,
  contextualHelpKey: null,
  autogeneratePath: null,
  autogenerateConfig: null,
};

export const SelectProps = {
  label: null,
  disabled: false,
  noLabel: null,
  contextualHelpKey: null,
  defaultValuePath: null,
  hidePrompt: false,
  apiConfigPath: null,
  multiple: null,
};

export const MultiSelectProps = {
  label: null,
  disabled: false,
  noLabel: null,
  labelKey: null,
  selectionLimit: null,
};

export const CategorySelectorProps = {
  label: null,
  noLabel: null,
};

export const TagSelectorProps = {
  label: null,
  noLabel: null,
  multiple: null,
};

export const MdEditorProps = {
  label: null,
  noLabel: null,
  characterCount: true,
  wordCount: false,
  afmConfig: null,
  disabled: false,
  contextualHelp: null,
  autogeneratePath: null,
  autogenerateConfig: null,
  assetSelectorConfiguration: null,
  ckeditorLinkAutogenConfiguration: null,
  enableMarkdown: false,
  linkAutogen: false,
  linkAutogenConfigFields: [],
  enablePrismXML: null,
};

export const CardListProps = {
  label: null,
  labelKey: null,
  noLabel: false,
  disableSorting: true,
  limit: 1,
  itemModel: null,
  treatment: null,
  assetSelectorConfigurationPath: null,
  // todo resolve this as part of BundleContainers control config
  assetSelectorConfiguration: {
    defaultType: "photo",
    contentTypes: ["clip", "photo"],
    externalTypes: ["getty"],
    limitSelection: 1,
    __typename: "AssetSelectorConfig" as const,
  },
};

export const getContainerSummary = (containerData: KeyedContainer) => {
  let title = containerData?.hed ? `[${containerData.hed}];` : "";

  if (
    !containerData?.hed &&
    !containerData?.curations?.edges?.length &&
    !containerData?.searches?.length
  ) {
    return "Empty Container";
  }

  let curationsCount = containerData?.curations?.edges?.length;
  let searchesCount = containerData?.searches?.[0]?.size;

  if (curationsCount) {
    return `${title} ${curationsCount.toString()} curations`;
  }

  if (searchesCount) {
    return `${title} ${searchesCount.toString()} results`;
  }

  return title;
};

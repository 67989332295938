import styled, { css } from "styled-components";
import { Spinner } from "@components";
import { useIntl } from "react-intl";
import { useContext, forwardRef, Ref } from "react";
import { PreviewContext } from "../PreviewPage/PreviewPage.context";

interface StyledProps {
  $viewMode?: string;
  $isMobile?: boolean;
}

const IframeWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  @media (min-width: 800px) {
    padding: 2px var(--spacing-lg);
  }
`;

const Iframe = styled.iframe<StyledProps>`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.FieldColor};
  box-shadow: ${(props) => props.theme.NonInteractiveRing};
  ${({ $viewMode, $isMobile }) =>
    $viewMode === "desktop" &&
    !$isMobile &&
    css`
      width: 1200px;
      height: 675px;
    `}

  ${({ $viewMode }) =>
    $viewMode === "mobile" &&
    css`
      width: 375px;
      height: 667px;
    `}
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type PreviewIframeProps = {
  isLoading: boolean;
  previewURL: string;
  isMobile: boolean;
  iframeRef?: React.RefObject<HTMLIFrameElement>;
};

export const PreviewIframe = forwardRef(
  (
    { isLoading, previewURL, isMobile }: PreviewIframeProps,
    ref: Ref<HTMLIFrameElement>
  ) => {
    const intl = useIntl();
    const [viewMode] = useContext(PreviewContext);

    return (
      <IframeWrapper>
        {isLoading ? (
          <SpinnerWrapper>
            <Spinner className="iframe-loading" size="large" />
          </SpinnerWrapper>
        ) : (
          <Iframe
            ref={ref}
            className="preview-iframe"
            src={previewURL}
            $viewMode={viewMode}
            $isMobile={isMobile}
            title={intl.formatMessage({
              defaultMessage: "Preview pane",
              description: "Live preview of your web page",
            })}
          />
        )}
      </IframeWrapper>
    );
  }
);

PreviewIframe.displayName = "PreviewIframe";

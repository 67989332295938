import { FC } from "react";
import styled from "styled-components";
import { Link, Toast } from "@components";
import { useQuery } from "@apollo/client";
import { Variables, Queries } from "@gql";
import { ToastProps } from "@hooks";

const Drawer = styled.div`
  position: fixed;
  bottom: var(--spacing-md);
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: ${(props) => props.theme.ElevationModal + 1};
`;

const StyledToast = styled(Toast)`
  max-width: 21.5rem;
  background: ${(props) => props.theme.ToastBackground};
  box-shadow: ${(props) => props.theme.MenuShadow};
`;

function convertAction(props: ToastProps) {
  return {
    type: props.type,
    details: props.details,
    action: props.action?.linkProps ? (
      <Link {...props.action?.linkProps}>{props.action?.message}</Link>
    ) : (
      props.action?.message
    ),
    className: props.className,
    onClose: props.onClose,
    children: props.children,
  };
}

export const ToastDrawer: FC = function () {
  let { data } = useQuery(Queries.GET_TOAST_PROPS);

  if (data?.toast.props) {
    data = convertAction(data.toast.props);
    let { onClose, ...props } = data;
    let close: undefined | (() => void) = undefined;
    if (onClose != null) {
      close = () => {
        Variables.toastPropsVar(undefined);
        onClose && onClose();
      };
    }

    return (
      <Drawer role="region" aria-live="polite" aria-atomic="true">
        <StyledToast onClose={close} {...props} />
      </Drawer>
    );
  } else {
    return (
      <Drawer role="region" aria-live="polite" aria-atomic="true"></Drawer>
    );
  }
};
ToastDrawer.displayName = "ToastDrawer";

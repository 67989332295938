import {
  Organizations_organizations,
  SeoFields_seo,
  SEOKeywords,
  SEOKeywordsVariables,
  SEOResult,
} from "@types";
import {
  Button,
  ContextualHelp,
  Label,
  useDebouncedCallback,
  useMediaQuery,
} from "@condenast/gemini";
import styled from "styled-components";
import { useCallback, useState } from "react";
import {
  CautionIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  CloseIcon,
  DangerIcon,
} from "@condenast/gemini/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { useDefinedMessages } from "@hooks";
import { AttachedSheet, DetachedSheet } from "./..";
import { SEOKeywordField } from "@components";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "@gql";

// // const DOT = String.fromCharCode(8226);

const CloseButton = styled(Button)`
  margin-left: auto;
`;

const FlexCloseButton = styled(Button)`
  height: fit-content;
  display: none;
`;

const TitleAndKeyword = styled.div`
  flex: 2;
`;

const ResultsScrollContainer = styled.div`
  flex: 3;
`;

const SEOPoint = styled.div`
  display: inline-block;
  border-radius: var(--spacing-xxs);
  padding: var(--spacing-xxs);
  font: var(--font-small-statements);
  font-weight: 600;
  background-color: ${(props) => props.theme.SurfaceColor};
  height: var(--spacing-md);
  width: var(--spacing-md);
  text-align: center;
`;

const SEOTitle = styled.div`
  font: var(--font-subhed);
  display: flex;
  align-items: center;

  ${SEOPoint} {
    margin-left: 10px;
  }

  &.good ${SEOPoint} {
    background-color: var(--color-green-50);
    color: var(--color-white);
  }
  &.average ${SEOPoint} {
    background-color: var(--color-yellow-50);
    color: var(--color-gray-1);
  }
  &.bad ${SEOPoint} {
    background-color: var(--color-red-50);
    color: var(--color-white);
  }
`;

const KeywordContainer = styled.div`
  margin: var(--spacing-xs) 0;
`;

const SEOSuggestion = styled.div`
  li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: var(--spacing-xs) var(--spacing-sm);
    position: relative;

    &.incomplete {
      ${SEOPoint} {
        background: var(--color-yellow-50);
      }

      ::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: var(--color-yellow-40);
        margin-left: -11px;
      }
    }
  }
  li:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.DividerColor};
  }
  li > ${SEOPoint} {
    margin-left: auto;
  }
`;

const SEOComplete = styled(SEOSuggestion)<{ $open: boolean }>`
  background: ${(props) => props.theme.SurfaceColor};
  margin-top: var(--spacing-md);
  svg {
    margin-top: var(--spacing-xxs);
    transform: ${(props) => (props.$open ? `rotate(180deg)` : `rotate(0deg)`)};
    cursor: pointer;
  }
  li:first-of-type {
    border-top: 1px solid ${(props) => props.theme.DividerColor};
  }
`;

const SEORule = styled.div`
  display: inline-block;
`;

const Chevron = styled(ChevronDownIcon)`
  float: right;
`;

const ContextualInfo = styled.div`
  [aria-label="More info"],
  [aria-label="More info"]:not(:disabled)[aria-pressed="true"],
  [aria-label="More info"]:active {
    color: var(--color-blue-50);
  }
  .popper {
    max-width: 290px;
  }
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
  padding: var(--spacing-xs);
  font-weight: 700;
  svg {
    display: inline;
    left: 0px;
    margin-right: var(--spacing-xxs);
  }
`;

const SEOGradeInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: var(--spacing-xxs);
  overflow: hidden;
  margin-bottom: var(--spacing-sm);

  div:first-of-type {
    background: var(--color-red-80);
    svg {
      color: var(--color-red-50);
    }
  }
  div:nth-of-type(2) {
    background: var(--color-yellow-80);
    svg {
      color: var(--color-yellow-40);
    }
  }
  div:last-of-type {
    background: var(--color-green-80);
    svg {
      color: var(--color-green-50);
    }
  }
`;

const SEOCompleteLabel = styled(Label)`
  width: 100%;
  padding: var(--spacing-xxs) var(--spacing-sm);
`;

const RulesContainer = styled.div`
  width: 100%;
  padding: var(--spacing-sm) 0;
`;

const SEODivider = styled.div`
  border: 0;
`;

const SEOScoreWrapper = styled.div`
  height: 100%;

  ${AttachedSheet} & {
    padding: var(--spacing-sm);
    overflow: auto;

    ${CloseButton} {
      position: unset;
      margin-left: auto;
    }
  }

  @media (min-width: 651px) {
    ${DetachedSheet} & {
      display: flex;

      ${CloseButton} {
        display: none;
      }

      ${FlexCloseButton} {
        display: flex;
        position: sticky;
        top: var(--spacing-sm);
      }

      ${TitleAndKeyword} {
        padding: var(--spacing-sm);
      }

      ${ResultsScrollContainer} {
        padding-right: var(--spacing-sm);
        overflow: auto;
        display: flex;
      }

      ${RulesContainer} {
        ::after {
          display: block;
          content: "";
          padding: var(--spacing-sm) 0 0 0;
        }
      }

      ${SEODivider} {
        padding-left: var(--spacing-sm);
        padding-right: var(--spacing-xs);
        border-left: 1px solid ${(props) => props.theme.DividerColor};
      }
    }
  }

  @media (max-width: 650px) {
    overflow: auto;

    ${SEOTitle} {
      padding: var(--spacing-xxs) var(--spacing-sm);
      border-bottom: 1px solid ${(props) => props.theme.DividerColor};
    }

    ${KeywordContainer} {
      padding: 0 var(--spacing-sm);
    }

    ${ResultsScrollContainer} {
      padding: 0 var(--spacing-sm) var(--spacing-sm) var(--spacing-sm);
    }

    ${RulesContainer} {
      padding: 0px;
    }
  }
`;

function scoreRange(score: number) {
  if (score >= 0 && score <= 49) {
    return "bad";
  }

  if (score >= 50 && score <= 79) {
    return "average";
  }

  if (score >= 80) {
    return "good";
  }

  return undefined;
}
function hasScore(result: SEOResult) {
  return result.name !== "fields-exist";
}

function realScore(result: SEOResult) {
  return result.actual < result.expected ? result.actual : result.expected;
}

function ruleCompleted(result: SEOResult) {
  return hasScore(result) ? result.key === "success" : false;
}

function isKeywordSearchable(keyword: string) {
  return keyword.trim().split(" ").length > 1;
}

export function SEOScore(props: {
  currentOrganization: Organizations_organizations;
  seo: Partial<SeoFields_seo>;
  setSeoKeyword: (keyword: string) => void;
  seoResults: SEOResult[];
  onClose: () => void;
}) {
  let [showCompletedRules, setShowCompletedRules] = useState<boolean>(true);
  let {
    currentOrganization,
    seo: { score, category, keyword },
    setSeoKeyword,
    seoResults,
    onClose,
  } = props;
  let { translateSEORule } = useDefinedMessages();
  let intl = useIntl();

  let incompleteResults = seoResults?.filter(
    (result) => !ruleCompleted(result)
  );
  let completedResults = seoResults?.filter(ruleCompleted);

  const [shouldQueryKeywords, setShouldQueryKeywords] = useState<boolean>(true);

  const [_suggestKeywords, { data: keywordData, loading: keywordLoading }] =
    useLazyQuery<SEOKeywords, SEOKeywordsVariables>(Queries.SEO_KEYWORDS);
  const suggestKeywords = useCallback(
    (query) => {
      if (category && isKeywordSearchable(query)) {
        _suggestKeywords({
          variables: {
            query,
            seoCategory: category,
            organizationId: currentOrganization.organizationId,
          },
        });
        setShouldQueryKeywords(false);
      }
    },
    [_suggestKeywords, category, currentOrganization, setShouldQueryKeywords]
  );
  const debounceSuggestKeywords = useDebouncedCallback(
    (query: string) => suggestKeywords(query),
    100,
    [category, _suggestKeywords, currentOrganization]
  );
  const onKeywordInputChange = useCallback(
    (keyword: string) => {
      setSeoKeyword(keyword);
      debounceSuggestKeywords(keyword);
    },
    [setSeoKeyword, debounceSuggestKeywords]
  );
  const currentKeyword = keyword ?? "";
  const shouldSearch = isKeywordSearchable(currentKeyword);

  let isMobile = useMediaQuery("(max-width: 650px)");

  return (
    <SEOScoreWrapper>
      <TitleAndKeyword>
        <SEOTitle className={scoreRange(score ?? 0)}>
          <FormattedMessage defaultMessage="SEO Score" />
          <SEOPoint>{score ?? 0}</SEOPoint>
          <ContextualInfo>
            <ContextualHelp
              id="ContextualHelpSEO"
              placement={isMobile ? "bottom" : "right-start"}
            >
              <SEOGradeInfo>
                <Column>
                  <DangerIcon size="small" />
                  <FormattedMessage defaultMessage="< 50" />
                </Column>
                <Column>
                  <CautionIcon size="small" />
                  <FormattedMessage defaultMessage="< 80" />
                </Column>
                <Column>
                  <CheckCircleIcon size="small" />
                  <FormattedMessage defaultMessage="< 100" />
                </Column>
              </SEOGradeInfo>
              <FormattedMessage
                defaultMessage="SOAR will rate your content’s SEO from 0 to 100 based on the
              completion of optimization tasks."
              />
            </ContextualHelp>
          </ContextualInfo>

          <CloseButton
            aria-label={intl.formatMessage({
              defaultMessage: "Close",
              description: "Close as in 'close the popup'",
            })}
            onClick={onClose}
          >
            <CloseIcon size={isMobile ? "small" : "regular"} />
          </CloseButton>
        </SEOTitle>

        <KeywordContainer>
          <SEOKeywordField
            id="sheet__seo-keyword"
            currentKeyword={currentKeyword}
            onInput={onKeywordInputChange}
            suggestionsLoading={shouldSearch ? keywordLoading : false}
            suggestions={shouldSearch ? keywordData?.seoKeywords : []}
            onChange={setSeoKeyword}
            onFocus={() => {
              if (shouldQueryKeywords) {
                suggestKeywords(currentKeyword);
              }
            }}
          />
        </KeywordContainer>
      </TitleAndKeyword>
      <ResultsScrollContainer>
        <RulesContainer>
          <SEODivider>
            <SEOSuggestion>
              {incompleteResults?.map((result, index) => {
                return (
                  <li
                    key={index}
                    className={hasScore(result) ? "complete" : "incomplete"}
                  >
                    <SEORule>{translateSEORule(result)}</SEORule>
                    {hasScore(result) ? (
                      <SEOPoint>{result.expected}</SEOPoint>
                    ) : (
                      <SEOPoint>&ndash;</SEOPoint>
                    )}
                  </li>
                );
              })}
            </SEOSuggestion>
            {completedResults?.length ? (
              <SEOComplete $open={showCompletedRules}>
                <SEOCompleteLabel
                  onClick={() => {
                    setShowCompletedRules(!showCompletedRules);
                  }}
                >
                  <FormattedMessage defaultMessage="Completed Suggestions" />
                  <Chevron size="small" />
                </SEOCompleteLabel>
                {showCompletedRules &&
                  completedResults?.map((result, index) => {
                    return (
                      <li key={index}>
                        <SEORule>{translateSEORule(result)}</SEORule>
                        {hasScore(result) && (
                          <SEOPoint>{realScore(result)}</SEOPoint>
                        )}
                      </li>
                    );
                  })}
              </SEOComplete>
            ) : null}
          </SEODivider>
        </RulesContainer>
        <FlexCloseButton
          aria-label={intl.formatMessage({
            defaultMessage: "Close",
            description: "Close as in 'close the popup'",
          })}
          onClick={onClose}
        >
          <CloseIcon size="small" />
        </FlexCloseButton>
      </ResultsScrollContainer>
    </SEOScoreWrapper>
  );
}

SEOScore.displayName = "SEOScore";

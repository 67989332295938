import { useMutation } from "@apollo/client";
import {
  ContentSummary,
  ImportContentReference,
  ImportContentReferenceVariables,
  ContentSummaryFields_metadata_ContentSummaryExternalID as ContentSummaryExternalID,
} from "@types";
import { Mutations } from "@gql";
import { normalizeContentSummary } from "@lib";
import { SearchPostProcessor } from "../types";
import { useDefinedMessages } from "@hooks";

export function useCommerceProductPostProcessor(
  organizationId: string
): SearchPostProcessor {
  const [importContentReference, { loading }] = useMutation<
    ImportContentReference,
    ImportContentReferenceVariables
  >(Mutations.IMPORT_CONTENT_REFERENCE);

  const { translateContentType } = useDefinedMessages();

  return {
    loading,
    process: async (items: ContentSummary[]) => {
      const contentReferences = items.filter(
        (item) => item.contentType === "contentreference"
      );
      if (!contentReferences.length) {
        return items;
      }
      const result = await importContentReference({
        variables: {
          organizationId,
          data: contentReferences.map((item) => {
            const { identifier, provider } = item.metadata?.find(
              ({ type }) => type === "ContentSummaryExternalID"
            ) as unknown as ContentSummaryExternalID;
            return {
              identifier,
              provider,
              title: item?.title?.content,
              description: item?.description,
              meta: {
                collectionName: "contentreferences",
                modelName: "contentreference",
              },
            };
          }),
        },
      });
      const resultData = result.data?.importContentReference.map((result) =>
        normalizeContentSummary(result, translateContentType)
      );
      if (!resultData) {
        return items;
      }

      return items.map((item) =>
        item.contentType === "contentreference"
          ? resultData.shift() ?? null
          : item
      );
    },
  };
}

import {
  GetCurrentUser_currentUser as AuthenticatedUser,
  Organization,
  UserRole,
  UserLoginProvider,
} from "@types";
import organizations from "./organizations.json";

/**
 * Returns a client-side organization object given a copilotCode
 * @param copilotCode The copilotCode in the organization's metadata
 */
export function byCopilotCode(
  copilotCode: string,
  environment: "ci" | "staging" | "production" = "production"
): Organization {
  let organization = organizations.find(
    (org) =>
      org.metadata.copilotCode === copilotCode &&
      org.environment === environment
  );
  if (
    organization == null ||
    organization.metadata.copilotCode == null ||
    organization.metadata.mediaDomain == null
  ) {
    throw new Error(
      `No organization was found with the copilotCode ${copilotCode}`
    );
  }

  // Format as organization GQL
  return {
    __typename: "Organization",
    id: organization.organizationId,
    organizationId: organization.organizationId,
    url: organization.url,
    internalDisplayName: organization.internalDisplayName,
    companyId: organization.companyId ?? null,
    metadata: {
      __typename: "OrganizationMetadata",
      copilotCode: organization.metadata.copilotCode,
      copilotRegion: organization.metadata.copilotRegion ?? null,
      mediaDomain: organization.metadata.mediaDomain,
    },
  };
}

export function createAuthenticatedUser(
  user?: Partial<AuthenticatedUser>
): AuthenticatedUser {
  return {
    __typename: "User" as const,
    id: "test",
    firstName: "Eustace",
    lastName: "Tilley",
    userName: "etilley",
    email: "eustace.tilley@newyorker.com",
    role: UserRole.manager,
    loginProvider: UserLoginProvider.copilot,
    locale: "en-US",
    organizations: [
      byCopilotCode("tny"),
      byCopilotCode("wrd"),
      byCopilotCode("bon"),
    ],
    createdAt: "2021-06-21T20:13:12.100Z",
    lastLogin: "2021-06-21T20:13:12.100Z",
    modifiedAt: "2021-06-21T20:13:12.100Z",
    slackUserId: "12345",
    ...user,
  };
}

export function createSuperAdminUser(
  user?: Partial<AuthenticatedUser>
): AuthenticatedUser {
  return {
    __typename: "User" as const,
    id: "test",
    firstName: "Eustace",
    lastName: "Tilley",
    userName: "etilley",
    email: "eustace.tilley@newyorker.com",
    role: UserRole.superadmin,
    loginProvider: UserLoginProvider.copilot,
    locale: "en-US",
    organizations: [
      byCopilotCode("tny"),
      byCopilotCode("wrd"),
      byCopilotCode("bon"),
    ],
    createdAt: "2021-06-21T20:13:12.100Z",
    lastLogin: "2021-06-21T20:13:12.100Z",
    modifiedAt: "2021-06-21T20:13:12.100Z",
    slackUserId: "12345",
    ...user,
  };
}

export function byCopilotName(
  copilotName: string,
  environment: "ci" | "staging" | "production" = "production"
): Organization {
  let organization = organizations.find(
    (org) =>
      org.metadata.copilotName === copilotName &&
      org.environment === environment
  );
  if (
    organization == null ||
    organization.metadata.copilotName == null ||
    organization.metadata.mediaDomain == null
  ) {
    throw new Error(
      `No organization was found with the copilotName ${copilotName}`
    );
  }

  // Format as organization GQL
  return {
    __typename: "Organization",
    companyId: organization.companyId ?? null,
    id: organization.organizationId,
    organizationId: organization.organizationId,
    url: organization.url,
    internalDisplayName: organization.internalDisplayName,
    metadata: {
      __typename: "OrganizationMetadata",
      copilotCode: organization.metadata.copilotCode,
      copilotRegion: organization.metadata.copilotRegion ?? null,
      mediaDomain: organization.metadata.mediaDomain,
    },
  };
}

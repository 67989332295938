import { SeoFields_seo, SEOInput } from "@types";

export function seo(data: SeoFields_seo): SEOInput {
  let { __typename, category, keyword, ...seoInput } = data;

  return {
    ...seoInput,
    category: category ? category : undefined,
    keyword: keyword ?? "",
  };
}

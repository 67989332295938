import { useQuery } from "@apollo/client";
import { GetContentSummary, GetContentSummaryVariables } from "@types";
import { Queries } from "@gql";

export const useLiveStoryEmbed = (
  organizationId: string,
  contentType: string,
  id: string
) => {
  const { data, loading, error } = useQuery<
    GetContentSummary,
    GetContentSummaryVariables
  >(Queries.GET_CONTENT_SUMMARY, {
    variables: {
      organizationId,
      contentType,
      id,
    },
    skip: !organizationId || !id,
  });

  return { data, loading, error };
};

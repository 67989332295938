import { defineMessages } from "react-intl";

export const FIELD_DESCRIPTIONS = defineMessages({
  additionalContent: {
    defaultMessage:
      "Content added here will be displayed around the main body and override any automated one.",
    description: "fieldsetDescriptions.additionalContent",
  },
  collaborators: {
    defaultMessage:
      "This list automatically includes everyone who has opened and saved this story. These names will not published in the Byline.",
    description: "fieldsetDescriptions.collaborators",
  },
  homepage: {
    defaultMessage:
      "Article details added here will appear on the site homepage & recirculation unit.",
    description: "fieldsetDescriptions.homepage",
  },
  liveSchedule: {
    defaultMessage:
      "Scheduling added here will set the Live indicator for this story.",
    description: "fieldsetDescriptions.liveSchedule",
  },
  print: {
    defaultMessage:
      "Use the below to differentiate the article details on print or mirror digital",
    description: "fieldsetDescriptions.print",
  },
  readMore: {
    defaultMessage:
      "Content added here will be shown in the Read More section by overriding the recommendation engine.",
    description: "fieldsetDescriptions.readMore",
  },
  relatedAudio: {
    defaultMessage: "Add audio to accompany article on the iOS app.",
    description: "fieldsetDescriptions.relatedAudio",
  },
});

import {
  TextField as TextFieldImplementation,
  Autogenerates,
} from "@components";
import { useDefinedMessages } from "@hooks";
import {
  ControlProps,
  FormFor_form_controls_TextFieldFormControl,
} from "@types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { get } from "@lib";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import PlainTextRenderer from "@atjson/renderer-plain-text";
import { autogenerate } from "./helpers/autogenerate";

export function TextField(
  props: ControlProps<FormFor_form_controls_TextFieldFormControl>
) {
  let {
    model,
    name,
    setValue,
    noLabel,
    autogeneratePath,
    placeholderKey,
    contextualHelpKey,
    errors,
    disabled,
    characterCount,
    wordCount,
    maxCount,
    labelKey,
    autogenerateConfig,
  } = props;
  const {
    translateContextualHelp,
    translateFieldName,
    translatePlaceholder,
    translateAutogeneratedMessages,
  } = useDefinedMessages();
  const value = (get(model, name) as string) ?? "";
  const [localValue, setLocalValue] = useState(value);

  const textFieldLabelName = !noLabel
    ? translateFieldName(labelKey ?? name)
    : "";

  const onChange = useCallback(
    (value: string) => {
      setLocalValue(value);
      setValue(name, value);
    },
    [setValue, name]
  );

  useEffect(() => setLocalValue(value), [value]);

  let modelValue =
    autogeneratePath || autogenerateConfig
      ? autogenerate(
          autogeneratePath ?? null,
          autogenerateConfig ?? null,
          model
        )
      : null;

  const autogenValue = useMemo(() => {
    if (modelValue) {
      return PlainTextRenderer.render(
        CopilotMarkdownSource.fromRaw(modelValue as string)
      ).trim();
    }
  }, [autogeneratePath, autogenerateConfig, model]);

  const autogeneratedMessage = translateAutogeneratedMessages(name);

  return (
    <Autogenerates
      autogenValue={autogenValue}
      onChange={onChange}
      currentValue={localValue}
      autogenMessage={autogeneratedMessage}
    >
      {(autogenProps) => {
        return (
          <TextFieldImplementation
            id={`TextField__${name}`}
            label={textFieldLabelName}
            aria-label={translateFieldName(name)}
            {...autogenProps}
            placeholder={
              placeholderKey
                ? translatePlaceholder(
                    placeholderKey.replace(/^placeholder\./, "")
                  )
                : undefined
            }
            help={
              contextualHelpKey
                ? translateContextualHelp(contextualHelpKey)
                : undefined
            }
            value={localValue}
            errors={errors}
            disabled={disabled}
            count={
              characterCount ? "characters" : wordCount ? "words" : undefined
            }
            maxCount={maxCount}
            multiline={false}
          />
        );
      }}
    </Autogenerates>
  );
}
TextField.displayName = "Control(TextField)";

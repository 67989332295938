import { useIntl } from "react-intl";
import { Select as SelectImplementation } from "@components";
import { useDefinedMessages } from "@hooks";
import {
  ControlProps,
  FormFor_form_controls_CurationTypeSelectFormControl,
} from "@types";
import { get } from "@lib";

export function CurationTypeSelect(
  props: ControlProps<FormFor_form_controls_CurationTypeSelectFormControl>
) {
  const { name, setValue, options, model, errors } = props;
  const { translateFieldName, translateSelectOption } = useDefinedMessages();
  const value = (get(model, name) as string) ?? options?.defaultValue ?? "";
  const selectLabelName = translateFieldName(name);
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    defaultMessage: "Select a curation type option",
    description: "Placeholder text for a curation type select component",
  });

  const transformedOptions =
    options?.options.map((value) => {
      return {
        label: translateSelectOption("curationTypeOptions", value),
        value: value,
      };
    }) || [];

  return (
    <SelectImplementation
      id={`Select__${name}`}
      label={selectLabelName}
      aria-label={selectLabelName}
      value={value}
      onChange={(value: string | undefined) => setValue(name, value)}
      placeholder={value ? value : placeholder}
      errors={errors}
      options={transformedOptions}
    />
  );
}
CurationTypeSelect.displayName = "Control(CurationTypeSelect)";

import { useCallback, useMemo, useState, useContext } from "react";
import { ARIA, Button, CKEditor, Field } from "@components";
import { useUniqueId } from "@hooks";
import { ApolloError } from "@apollo/client";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { ThemeProvider } from "@contexts";
import { useDefinedMessages } from "@hooks";
import { CKEditorConfiguration } from "@types";
import {
  BlockEditor,
  InlineEditor,
  MinimalistEditor,
} from "@condenast/ckeditor5-build-condenast";
import { AnimatedEllipsisIcon } from "@condenast/gemini/icons";
import CopilotMarkdownRenderer from "@condenast/atjson-renderer-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";
import { SnowplowContext } from "@contexts";

const Dialog = styled(ARIA.Dialog)`
  overflow: visible;
  color: initial;
`;

export const DialogBody = styled.div`
  padding: var(--spacing-xs) var(--spacing-md) var(--spacing-sm);
`;

const EditorField = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

const Editor = styled(CKEditor)`
  grid-area: control;
`;

const ButtonWithLoadingState = styled(Button)`
  svg {
    left: auto;
    align-self: center;
  }
`;

const Builds = {
  block: BlockEditor,
  inline: InlineEditor,
  minimalist: MinimalistEditor,
};

export const BatchEditDialog = (props: {
  onBatchUpdate: (credit: string) => Promise<void>;
  isBatchUpdating?: boolean;
  onClose: () => void;
  requestError?: ApolloError | undefined;
  creditRichTextConfig: CKEditorConfiguration;
  creditRichTextBuild: keyof typeof Builds;
}) => {
  const {
    onClose,
    onBatchUpdate,
    isBatchUpdating,
    creditRichTextConfig,
    creditRichTextBuild,
  } = props;

  const intl = useIntl();
  const id = useUniqueId();
  const { translateFieldName } = useDefinedMessages();
  const { trackContentEvent } = useContext(SnowplowContext);

  const [credit, setCredit] = useState("");

  const submit = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      onBatchUpdate(credit);
      trackContentEvent("batch_update_credit");
    },
    [onBatchUpdate, credit]
  );

  const intialCreditValue = useMemo(
    () => new VersoSource({ content: "", annotations: [] }),
    []
  );

  const onCreditChange = useCallback(
    (value) => {
      setCredit(CopilotMarkdownRenderer.render(value));
    },
    [setCredit]
  );

  return (
    <ThemeProvider theme="light">
      <Dialog
        title={intl.formatMessage({ defaultMessage: "Edit Credit" })}
        description={`This action will overwrite any existing credits.`}
        size={"regular"}
        cancelButton={
          <Button>
            <FormattedMessage
              defaultMessage="Cancel"
              description="Cancel button in bulk edit modal"
            />
          </Button>
        }
        submitButton={
          <ButtonWithLoadingState
            treatment="primary"
            type="submit"
            onClick={submit}
            disabled={isBatchUpdating}
          >
            {isBatchUpdating ? (
              <AnimatedEllipsisIcon size="small" />
            ) : (
              <FormattedMessage
                defaultMessage={"Update credit"}
                description="Submit button in bulk edit modal"
              />
            )}
          </ButtonWithLoadingState>
        }
        onClose={onClose}
      >
        <DialogBody>
          <EditorField
            label={translateFieldName("Credit")}
            id={`CKEditor__${id}__credit`}
          >
            <Editor
              onChange={onCreditChange}
              id={`CKEditor__${id}__caption`}
              config={creditRichTextConfig}
              build={creditRichTextBuild}
              value={intialCreditValue}
            />
          </EditorField>
        </DialogBody>
      </Dialog>
    </ThemeProvider>
  );
};

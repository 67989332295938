import { Toggle as ToggleImplementation, Label } from "@components";
import { useDefinedMessages } from "@hooks";
import { get } from "@lib";
import { ControlProps, FormFor_form_controls_ToggleFormControl } from "@types";
import styled from "styled-components";
import { Row } from "../Row";

export const StyleWrapper = styled.div`
  margin: var(--spacing-xxs) 0;
  padding-top: var(--spacing-xxs);
  border-top: 1px solid var(--color-gray-5);

  ${Label} {
    font: ${(props) => props.theme.FontBody};
  }

  //horizantally placed toggles will not have a border above them
  ${Row} > & {
    border: none;
  }
`;
export function Toggle(
  props: ControlProps<FormFor_form_controls_ToggleFormControl>
) {
  let { translateFieldName } = useDefinedMessages();
  let value = get(props.model, props.name) as boolean;
  let label = translateFieldName(props.name);

  return (
    <StyleWrapper>
      <ToggleImplementation
        id={props.id}
        value={value}
        label={label}
        message={props.message}
        disabled={props.disabled}
        onChange={(value) => props.setValue(props.name, value)}
      />
    </StyleWrapper>
  );
}
Toggle.displayName = "Control(Toggle)";

import type { TypePolicy } from "@apollo/client";

export const Activity: TypePolicy = {
  fields: {
    phone: {
      read(cache) {
        return (
          cache &&
          cache.map((data: string) => ({
            value: data,
          }))
        );
      },
    },
    email: {
      read(cache) {
        return (
          cache &&
          cache.map((data: string) => ({
            value: data,
          }))
        );
      },
    },
    url: {
      read(cache) {
        return (
          cache &&
          cache.map((data: string) => ({
            value: data,
          }))
        );
      },
    },
  },
};

import { useDefinedMessages } from "@hooks";
import { get } from "@lib";
import type { InfoPageFields_allContributors_edges as allContributorsType } from "@types";
import { AutogenHelper } from "../types";

/**
 * Returns the names of all authors, prepended with some introductory text
 *
 * @param model
 * @returns string | null
 */
export const useContributorsAuthor: AutogenHelper = (model) => {
  let authors = get(model, "allContributors.edges") as
    | allContributorsType[]
    | null;

  const { translateContributorCredit } = useDefinedMessages();

  return authors && authors.length > 0
    ? translateContributorCredit(
        "author",
        authors?.map((item) => item.node?.name ?? "") ?? []
      ).toString()
    : null;
};

import { Mutations, Queries } from "@gql";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetBundle_bundle,
  GetLayoutConfigs,
  Organization,
  upsertLayoutConfigs,
  upsertLayoutConfigsVariables,
} from "@types";
import { LayoutConfigTransformer } from "./ContainerOptions.utils";
import { useCallback, useMemo } from "react";
import { SaveLayoutFunction } from "./ContainerOptions.types";
import { merge } from "lodash";

export const useContainerOptionsData = (
  currentOrganization: Organization,
  bundleData: GetBundle_bundle,
  onSave: () => void
) => {
  const contentId = bundleData.id;
  const contentRevision = bundleData.revisionInfo.version;

  const {
    data: layoutConfigData,
    loading,
    error: layoutConfigError,
  } = useQuery<GetLayoutConfigs>(Queries.GET_LAYOUT_CONFIGS_DATA, {
    variables: {
      organizationId: currentOrganization.organizationId,
      query: {
        contentId,
        contentRevision,
      },
    },
  });

  const layoutConfigs = useMemo(() => {
    if (!layoutConfigData?.layoutConfigs) {
      return null;
    }
    return LayoutConfigTransformer.fromGraphql(layoutConfigData.layoutConfigs);
  }, [layoutConfigData]);

  const [layoutConfigMutation, { loading: saving, error: savingError }] =
    useMutation<upsertLayoutConfigs, upsertLayoutConfigsVariables>(
      Mutations.SAVE_LAYOUT_CONFIGS,
      {
        refetchQueries: ["GetLayoutConfigs"],
      }
    );

  const save: SaveLayoutFunction = useCallback(
    async (containerId, containerConfig) => {
      if (!layoutConfigs) {
        return;
      }
      const existingConfig = layoutConfigs.layouts[containerId];
      const newConfig = merge({}, existingConfig, containerConfig);
      const payload = LayoutConfigTransformer.toGraphql({
        ...layoutConfigs,
        layouts: {
          ...layoutConfigs.layouts,
          [containerId]: newConfig,
        },
      });

      await layoutConfigMutation({
        variables: {
          organizationId: currentOrganization.organizationId,
          query: {
            contentId,
            contentRevision,
          },
          data: payload,
        },
      });
      onSave();
    },
    [
      contentId,
      contentRevision,
      currentOrganization.organizationId,
      layoutConfigs,
      layoutConfigMutation,
      onSave,
    ]
  );

  return {
    loading,
    layoutConfigs,
    save,
    saving,
    errors: [layoutConfigError, savingError],
  };
};

export const MEDIA_MAP = {
  clips: [
    "video/avi",
    "video/mov",
    "video/quicktime",
    "video/flv",
    "video/mpg",
    "video/mp4",
    "video/webm",
    "video/wmv",
  ],
  photos: ["image/jpeg", "image/jpg", "image/png", "image/gif"],
  cartoons: ["image/jpeg", "image/jpg", "image/png", "image/gif"],
} as const;

import type { ContentSerializers } from "../types";
import type { ActivityInput, FormFor_content_Activity } from "@types";
import {
  allContributors,
  categoryTaxonomies,
  contentConnection,
  geo,
  lodgingOffers,
  seo,
  transformKeyValues,
  ratingBlock,
  venueAddress,
} from "../fields";

import { serializerFor, entityMetadata } from "../utils";

const ActivityFields = {
  categoryTaxonomies,
  allContributors,
  seo,
  geo,
  lodgingOffers,
  address: venueAddress,
  phoneNumbers: transformKeyValues,
  urls: transformKeyValues,
  emails: transformKeyValues,
  photos: contentConnection,
  photosLede: contentConnection,
  photosTout: contentConnection,
  photosSocial: contentConnection,
  ratingBlock,
  related: contentConnection,
} as const;

export const ActivitySerializers: ContentSerializers<ActivityInput> = {
  save: (changes, model, { currentUser }) => {
    if (!("name" in model)) {
      return null;
    }
    const { phoneNumbers, urls, emails, lodgingOffers, ...serialized } =
      serializerFor(ActivityFields)(
        changes
      ) as Partial<FormFor_content_Activity>;

    return {
      name: model.name,
      ...(serialized as Partial<ActivityInput>),
      email: emails,
      phone: phoneNumbers,
      url: urls,
      offers: lodgingOffers,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
    return {
      name: createTitle,
      address: {},
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: { user: [`${currentUser.id}`] },
    };
  },
};

import { useCallback, useState, useEffect, useReducer } from "react";

export enum LifecycleContext {
  SAVE = "save",
  PUBLISH = "publish",
}

export enum LifecycleStep {
  BEFORE_SAVE_VALIDATION = "beforeSaveValidation",
  SAVE_VALIDATION = "saveValidation",
  AFTER_SAVE_VALIDATION = "afterSaveValidation",
  SAVE = "save",
  AFTER_SAVE = "afterSave",
  BEFORE_PUBLISH_VALIDATION = "beforePublishValidation",
  PUBLISH_VALIDATION = "publishValidation",
  AFTER_PUBLISH_VALIDATION = "afterPublishValidation",
  PUBLISH = "publish",
  AFTER_PUBLISH = "afterPublish",
}

const ContextLifecycles = {
  [LifecycleContext.SAVE]: [
    LifecycleStep.BEFORE_SAVE_VALIDATION,
    LifecycleStep.SAVE_VALIDATION,
    LifecycleStep.AFTER_SAVE_VALIDATION,
    LifecycleStep.SAVE,
    LifecycleStep.AFTER_SAVE,
  ],
  [LifecycleContext.PUBLISH]: [
    LifecycleStep.BEFORE_PUBLISH_VALIDATION,
    LifecycleStep.PUBLISH_VALIDATION,
    LifecycleStep.AFTER_PUBLISH_VALIDATION,
    LifecycleStep.BEFORE_SAVE_VALIDATION,
    LifecycleStep.SAVE_VALIDATION,
    LifecycleStep.AFTER_SAVE_VALIDATION,
    LifecycleStep.SAVE,
    LifecycleStep.AFTER_SAVE,
    LifecycleStep.PUBLISH,
    LifecycleStep.AFTER_PUBLISH,
  ],
};

export type Runner = (
  lifecycleContext: LifecycleContext | undefined
) => Promise<boolean | void> | boolean | void;

export interface LifecycleStepTask {
  lifecycleContext: LifecycleContext;
  lifecycleName: LifecycleStep;
  taskName: string;
  runner?: Runner;
}

export interface TaskPerContext
  extends Omit<LifecycleStepTask, "lifecycleContext"> {
  lifecycleContexts: LifecycleContext[];
}

function areTasksEqual(
  left: LifecycleStepTask,
  right: LifecycleStepTask
): boolean {
  return (
    left.lifecycleContext === right.lifecycleContext &&
    left.lifecycleName === right.lifecycleName &&
    left.taskName === right.taskName
  );
}

function nextLifecycleStep(
  context: LifecycleContext,
  step: LifecycleStep
): LifecycleStep | undefined {
  const contextSteps = ContextLifecycles[context];
  const currentIndex = contextSteps.indexOf(step);

  if (
    typeof currentIndex === "number" &&
    currentIndex + 1 < contextSteps.length
  ) {
    return contextSteps[currentIndex + 1];
  } else {
    return;
  }
}

function spread(taskPerContext: TaskPerContext): LifecycleStepTask[] {
  return taskPerContext.lifecycleContexts.map((context) => ({
    lifecycleContext: context,
    lifecycleName: taskPerContext.lifecycleName,
    taskName: taskPerContext.taskName,
    runner: taskPerContext.runner,
  }));
}

function makeIdentifier(contentType: string, id: string): string {
  return `${contentType}:${id}`;
}

type LifeCycleState = {
  tasks: LifecycleStepTask[];
  currentContext?: LifecycleContext;
  currentStep?: LifecycleStep;
};

type LifeCycleAction =
  | ["addTask", TaskPerContext]
  | ["removeTask", TaskPerContext]
  | ["runLifecycle", LifecycleContext]
  | ["nextLifecycleStep"]
  | ["abortLifecycle"]
  | ["reset"];

function emptyState(): LifeCycleState {
  return { tasks: [] };
}

async function runLifecycleStep(
  tasks: LifecycleStepTask[],
  currentContext: LifecycleContext,
  currentStep: LifecycleStep
): Promise<LifeCycleAction> {
  const tasksToRun = tasks.filter(
    (task) =>
      task.lifecycleContext === currentContext &&
      task.lifecycleName === currentStep
  );

  if (tasksToRun.length) {
    for (let i = 0; i < tasksToRun.length; i++) {
      let runnerValue = await tasksToRun[i].runner?.(currentContext);
      if (runnerValue === false) {
        return ["abortLifecycle"];
      }
    }
  }

  return ["nextLifecycleStep"];
}

function reducer(
  state: LifeCycleState,
  action: LifeCycleAction
): LifeCycleState {
  let [type, argument] = action;

  if (type === "reset") {
    return emptyState();
  }

  if (type === "abortLifecycle") {
    return {
      ...state,
      currentContext: undefined,
      currentStep: undefined,
    };
  }

  if (type === "addTask") {
    const tasksToAdd = spread(argument as TaskPerContext);
    const existingTasks = state.tasks;
    const filteredTasks = existingTasks.filter(
      (task) => !tasksToAdd.some((taskToAdd) => areTasksEqual(task, taskToAdd))
    );
    let newTasks = [...filteredTasks, ...tasksToAdd];

    return {
      ...state,
      tasks: newTasks,
    };
  }

  if (type === "removeTask") {
    let tasksToRemove = spread(argument as TaskPerContext);
    let existingTasks = state.tasks;
    let tasksAlreadyExist = existingTasks.some((existingTask) =>
      tasksToRemove.some((taskToRemove) =>
        areTasksEqual(existingTask, taskToRemove)
      )
    );

    if (tasksAlreadyExist) {
      return {
        ...state,
        tasks: existingTasks.filter(
          (existingTask) =>
            !tasksToRemove.some((taskToRemove) =>
              areTasksEqual(existingTask, taskToRemove)
            )
        ),
      };
    } else {
      // make sure not to change the value if we aren't doing anything
      return state;
    }
  }

  if (type === "runLifecycle") {
    return {
      ...state,
      currentContext: argument as LifecycleContext,
      currentStep: ContextLifecycles[argument as LifecycleContext][0],
    };
  }

  if (type === "nextLifecycleStep") {
    if (!(state.currentContext && state.currentStep)) return state;

    let nextStep = nextLifecycleStep(state.currentContext, state.currentStep);

    if (!nextStep) {
      return { ...state, currentContext: undefined, currentStep: undefined };
    }

    return {
      ...state,
      currentStep: nextStep,
    };
  }

  return state;
}

export const useFormLifecycle = (contentType: string, id: string) => {
  const [formIdentifier, setFormIdentifier] = useState<string>(
    makeIdentifier(contentType, id)
  );

  const [{ tasks, currentContext, currentStep }, dispatch] = useReducer(
    reducer,
    emptyState()
  );

  useEffect(() => {
    let newId = makeIdentifier(contentType, id);
    if (newId !== formIdentifier) {
      setFormIdentifier(newId);
      dispatch(["reset"]);
    }
  }, [contentType, id, formIdentifier]);

  useEffect(() => {
    if (!currentContext || !currentStep) return;
    runLifecycleStep(tasks, currentContext, currentStep).then((action) =>
      dispatch(action)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContext, currentStep]);

  const addTask = useCallback((taskToAddPerContext: TaskPerContext) => {
    dispatch(["addTask", taskToAddPerContext]);
  }, []);

  const removeTask = useCallback((taskToRemovePerContext: TaskPerContext) => {
    dispatch(["removeTask", taskToRemovePerContext]);
  }, []);

  const run = useCallback((lifecycleContext: LifecycleContext) => {
    dispatch(["runLifecycle", lifecycleContext]);
  }, []);

  return {
    addTask,
    removeTask,
    run,
    lifecycleTasks: tasks,
  };
};

import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { length, required } from "@condenast/copilot-validators";

import type { ValidationEnvironment, ValidationContext } from "@lib";
import type { FormFor_content_Bundle } from "@types";
import { publishURI } from "../validators";

const { object } = validators;

const bundleValidators = object({
  hed: required(),
  photosTout: object({
    edges: length({ min: 1 }),
  })
    .on("publish")
    .catch(([{ message }]) => {
      return [{ path: [], message }];
    }),
  promoHed: required().on("publish"),
}).andAlso(publishURI().on("publish"));

export default function validateBundle(
  bundle: FormFor_content_Bundle,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(bundle, build(bundleValidators), context, env);
}

import { ARIA, Card, UserMedallion } from "@components";
import styled from "styled-components";
import { AnimatedEllipsisIcon, EllipsisIcon } from "@condenast/gemini/icons";
import { useIntl, FormattedMessage } from "react-intl";
import { memo } from "react";
import {
  FormFor_content_Livestoryupdate,
  GetLiveStoryUpdates_getLiveStory_updates_edges,
  Organization,
} from "@types";
import { isDefined } from "@lib";
import { LiveStoryContext } from "../LiveStory.context";
import { components } from "../LiveStoryUpdateRenderer";

import ReactRenderer, { ReactRendererProvider } from "@atjson/renderer-react";
import VersoSource from "@condenast/atjson-source-verso";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";

const EmptyFeed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: var(--spacing-md);
  font: ${(props) => props.theme.FontStatement};

  & div {
    margin-bottom: var(--spacing-md);
  }
`;

const FeedList = styled.ul`
  & li {
    margin-bottom: var(--spacing-sm);
  }
`;

const UpdateCard = styled(Card)`
  max-width: 575px;
`;

const UpdateHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-sm);
  gap: var(--spacing-xs);
`;

const StyledUserMedallion = styled(UserMedallion)`
  background-color: ${(props) => props.theme.PrimaryBackground};
  color: ${(props) => props.theme.PrimaryColor};
  margin-right: var(--spacing-xs);
`;

const UpdatedHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font: ${(props) => props.theme.FontSmallStatement};

  & span {
    color: ${(props) => props.theme.SupportColor};
  }
`;

const UpdateActions = styled(ARIA.MenuButton)`
  margin-left: auto;

  & svg {
    transform: rotate(90deg);
  }

  & #update-actions-menu {
    min-width: calc(10 * var(--spacing-xxs));
  }
`;

const UpdateTile = styled.div`
  font: ${(props) => props.theme.FontSmallerHeading};
  margin-bottom: var(--spacing-sm);
`;
const UpdateBody = styled.div`
  > *:not(:last-child) {
    margin-bottom: var(--spacing-sm);
  }

  copilot-embed {
    box-shadow: ${(props) => props.theme.CardShadow};
  }

  p {
    font: ${(props) => props.theme.FontBody};
  }
`;

const LiveStoryFeedItem = memo(
  (props: {
    update: FormFor_content_Livestoryupdate;
    hide: (update: FormFor_content_Livestoryupdate) => void;
  }) => {
    const { update, hide } = props;
    const intl = useIntl();
    const leadContributor =
      update.allContributors?.edges?.find(isDefined)?.node;

    return (
      <li>
        <UpdateCard>
          <UpdateHeader>
            {leadContributor?.name ? (
              <StyledUserMedallion size="regular" name={leadContributor.name} />
            ) : (
              <></>
            )}
            <UpdatedHeaderText>
              {leadContributor?.name ? (
                <div>{leadContributor.name}</div>
              ) : (
                <></>
              )}
              <span>
                {intl.formatDate(update.createdAt, {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </span>
            </UpdatedHeaderText>
            <UpdateActions
              id="update-actions"
              size="small"
              aria-label={intl.formatMessage({
                defaultMessage: "Update Actions",
              })}
              menu={{
                items: [
                  {
                    value: intl.formatMessage({
                      defaultMessage: "Edit",
                    }),
                    role: "link",
                    to: `${update.id}/edit`,
                  },
                  {
                    value: intl.formatMessage({
                      defaultMessage: "Remove",
                    }),
                    role: "action",
                    onClick: () => hide(update),
                  },
                ],
                children: (item: string) => item,
              }}
            >
              <EllipsisIcon size="regular" />
            </UpdateActions>
          </UpdateHeader>
          <UpdateTile>
            {update.title
              ? ReactRenderer.render(
                  CopilotMarkdownSource.fromRaw(update.title).convertTo(
                    VersoSource
                  )
                )
              : ""}
          </UpdateTile>
          <UpdateBody>
            {update.body?.content
              ? ReactRenderer.render(
                  CopilotMarkdownSource.fromRaw(update.body.content).convertTo(
                    VersoSource
                  )
                )
              : ""}
          </UpdateBody>
        </UpdateCard>
      </li>
    );
  }
);

LiveStoryFeedItem.displayName = "LiveStoryFeedItem";

export const LiveStoryFeed = (props: {
  onHideLiveStoryUpdate: (update: FormFor_content_Livestoryupdate) => void;
  liveUpdates: (GetLiveStoryUpdates_getLiveStory_updates_edges | null)[];
  loading: boolean;
  currentOrganization: Organization;
}) => {
  const { liveUpdates, loading, currentOrganization, onHideLiveStoryUpdate } =
    props;

  return (
    <>
      {loading ? (
        <UpdateCard>
          <AnimatedEllipsisIcon size="small" />
        </UpdateCard>
      ) : liveUpdates.length ? (
        <FeedList>
          <LiveStoryContext.Provider value={{ currentOrganization }}>
            <ReactRendererProvider value={components}>
              {liveUpdates
                .map((liveUpdatesEdge) => liveUpdatesEdge?.node)
                .filter(isDefined)
                .filter((update) => update.showInFeed)
                .map((update) => (
                  <LiveStoryFeedItem
                    update={update}
                    hide={onHideLiveStoryUpdate}
                    key={update.id}
                  />
                ))}
            </ReactRendererProvider>
          </LiveStoryContext.Provider>
        </FeedList>
      ) : (
        <EmptyFeed>
          <div>
            <FormattedMessage defaultMessage="Use the Live Updates form to start the live feed" />
          </div>
          <div>
            <FormattedMessage
              defaultMessage="Begin live coverage by sending an update with text, images,
            and/or embeds using the Live Updates form."
            />
          </div>
        </EmptyFeed>
      )}
    </>
  );
};

LiveStoryFeed.displayName = "LiveStoryFeed";

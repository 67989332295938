import { ReactNode, useCallback } from "react";
import {
  IntlContext,
  IntlProvider as GeminiIntlProvider,
} from "@condenast/gemini";
import English from "./__generated__/en-GB.json";
import "@formatjs/intl-listformat/polyfill";
import "@formatjs/intl-listformat/locale-data/en";
export { IntlContext };

export const IntlProvider = (props: {
  locale: string;
  children: ReactNode;
}) => {
  let load = useCallback(function (languageCode, regionOrScriptCode) {
    switch (languageCode) {
      // German
      case "de":
        return Promise.all([
          import("./__generated__/de-DE.json"),
          import("@formatjs/intl-listformat/locale-data/de"),
        ]).then(([module]) => {
          return module.default;
        });
      // English
      case "en":
        // Pseudo locale
        if (regionOrScriptCode === "us") {
          return import("./__generated__/en-US.json").then((module) => {
            return module.default;
          });
        } else if (regionOrScriptCode === "xa") {
          return import("./__generated__/en-XA.json").then((module) => {
            return module.default;
          });
        } else {
          return English;
        }
      // Spanish
      case "es":
        return Promise.all([
          import("./__generated__/es-ES.json"),
          import("@formatjs/intl-listformat/locale-data/es"),
        ]).then(([module]) => {
          return module.default;
        });
      // French
      case "fr":
        return Promise.all([
          import("./__generated__/fr-FR.json"),
          import("@formatjs/intl-listformat/locale-data/fr"),
        ]).then(([module]) => {
          return module.default;
        });
      // Italian
      case "it":
        return Promise.all([
          import("./__generated__/it-IT.json"),
          import("@formatjs/intl-listformat/locale-data/it"),
        ]).then(([module]) => {
          return module.default;
        });
      // Russian
      case "ru":
        return Promise.all([
          import("./__generated__/ru-RU.json"),
          import("@formatjs/intl-listformat/locale-data/ru"),
        ]).then(([module]) => {
          return module.default;
        });
      // Japanese
      case "ja":
        return Promise.all([
          import("./__generated__/ja-JP.json"),
          import("@formatjs/intl-listformat/locale-data/ja"),
        ]).then(([module]) => {
          return module.default;
        });
      // Chinese
      case "zh":
        if (regionOrScriptCode === "hans") {
          // Simplified script
          return Promise.all([
            import("./__generated__/zh-Hans.json"),
            import("@formatjs/intl-listformat/locale-data/zh-Hans"),
          ]).then(([module]) => {
            return module.default;
          });
        } else if (regionOrScriptCode === "hant") {
          // Traditional script
          return Promise.all([
            import("./__generated__/zh-Hant-TW.json"),
            import("@formatjs/intl-listformat/locale-data/zh-Hant"),
          ]).then(([module]) => {
            return module.default;
          });
        }
        return English;
      default:
        return English;
    }
  }, []);
  return (
    <GeminiIntlProvider locale={props.locale} load={load}>
      {props.children}
    </GeminiIntlProvider>
  );
};

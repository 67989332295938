import type { ContentSerializers } from "../types";
import type { PersonInput } from "@types";
import {
  contentConnection,
  categoryTaxonomies,
  seo,
  socialMediaItems,
} from "../fields";
import { serializerFor, entityMetadata } from "../utils";

const PersonFields = {
  categoryTaxonomies,
  photosTout: contentConnection,
  seo: seo,
  related: contentConnection,
  relatedContent: contentConnection,
  agencies: contentConnection,
  socialMedia: socialMediaItems,
} as const;

export const PersonSerializers: ContentSerializers<PersonInput> = {
  save: (changes, model, { currentUser }) => {
    return {
      ...serializerFor(PersonFields)(changes),
      name: ("name" in model && model.name) || "",
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: entityMetadata(model, currentUser.id),
    };
  },
  create: ({ currentUser, intl, brandCode }) => {
    const currentDateTime = intl.formatDate(Date.now(), {
      year: "numeric",
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    const createTitle = `Untitled / ${currentUser.firstName} ${currentUser.lastName} / ${currentDateTime}`;
    return {
      name: createTitle,
      authorName: `${currentUser.firstName} ${currentUser.lastName}`,
      entityMetadata: { user: [`${currentUser.id}`] },
      ...(brandCode === "vogue" ? { roles: [] } : {}),
    };
  },
};

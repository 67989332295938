import { TextField as TextFieldImplementation } from "@components";
import { useDefinedMessages } from "@hooks";
import {
  ControlProps,
  FormFor_form_controls_NumberFieldFormControl,
} from "@types";
import { useCallback, useState, useEffect } from "react";
import { get } from "@lib";

export function NumberField(
  props: ControlProps<FormFor_form_controls_NumberFieldFormControl>
) {
  let {
    model,
    name,
    setValue,
    noLabel,
    errors,
    disabled,
    placeholder,
    decimals,
  } = props;
  const { translateFieldName } = useDefinedMessages();
  const [localValue, setLocalValue] = useState<string>(
    decimals !== null
      ? (model[name] as number)?.toFixed(decimals)
      : (model[name] as number | null)?.toString() ?? ""
  );

  const translatedLabel = translateFieldName(name);

  const onChange = useCallback(
    (value: string) => {
      let numericValue = null;
      if (value !== "") {
        numericValue = decimals
          ? parseFloat(parseFloat(value).toFixed(decimals))
          : parseFloat(value);
        const valueWithoutTrailingDecimal =
          value.indexOf(".") === value.length - 1
            ? value.slice(0, value.length - 1)
            : value;
        if (
          isNaN(numericValue) ||
          numericValue.toString() !== valueWithoutTrailingDecimal
        ) {
          return;
        }
      }
      setLocalValue(value);
      setValue(name, numericValue);
    },
    [setValue, name]
  );

  useEffect(() => {
    let value = get(model, name) as number | null;

    if (decimals !== null && value !== null) {
      value = parseFloat(parseFloat(localValue)?.toFixed(decimals));
    }
    if (value !== parseFloat(localValue)) {
      setLocalValue(value == null ? "" : value.toString());
    }
  }, [model]);

  return (
    <TextFieldImplementation
      id={`NumberField__${name}`}
      label={!noLabel ? translatedLabel : ""}
      aria-label={translatedLabel}
      onChange={onChange}
      value={localValue}
      errors={errors}
      disabled={disabled}
      placeholder={placeholder}
      multiline={false}
      inputMode={"decimal"}
    />
  );
}
NumberField.displayName = "Control(NumberField)";

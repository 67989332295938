import { RadioButtonGroup } from "@components";
import { useDefinedMessages } from "@hooks";
import {
  ControlProps,
  getPreviewForm_form_controls_StyleSettingFormControl,
  getPreviewForm_form_controls_StyleSettingFormControl_options_mobile,
} from "@types";
import { get } from "lodash";
import styled from "styled-components";
import { Placement } from "@popperjs/core";
import {
  DownloadIcon,
  TextLeftIcon,
  TextRightIcon,
  TextTopIcon,
} from "@condenast/gemini/icons";

const FieldWrapper = styled.div`
  margin-block-start: var(--spacing-md);
`;

const Fieldset = styled.fieldset`
  padding-bottom: var(--spacing-sm);
`;

const Header = styled.div`
  font: var(--font-small-statements);
  padding-bottom: var(--spacing-sm);
`;

const Legend = styled.legend`
  font: ${(props) => props.theme.FontLabel};
  padding-bottom: var(--spacing-xs);
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${(props) => props.theme.DividerColor};
  margin-bottom: var(--spacing-sm);
`;
export function StyleSetting(
  props: ControlProps<getPreviewForm_form_controls_StyleSettingFormControl>
) {
  const {
    name,
    setValue,
    options,
    model,
    optionsPath,
    tooltipPlacement,
    layoutConfigPath,
  } = props;
  const { translateFieldName, translateSelectOption } = useDefinedMessages();
  const translatedLabel = translateFieldName(name);
  const mobilePath = `${layoutConfigPath}.default.0.${name}`;
  const tabletPath = `${layoutConfigPath}.default.1.${name}`;
  const desktopPath = `${layoutConfigPath}.default.2.${name}`;

  const Icons = {
    DownloadIcon,
    TextTopIcon,
    TextLeftIcon,
    TextRightIcon,
  };

  const transformOptions = (
    options:
      | getPreviewForm_form_controls_StyleSettingFormControl_options_mobile[]
      | null
      | undefined
  ) =>
    options?.map((option) => ({
      label: translateSelectOption(optionsPath ?? "", option.value),
      value: option.value,
      iconName: option.iconName as keyof typeof Icons,
    }));

  return (
    <FieldWrapper>
      <Header>{translatedLabel}</Header>
      {Boolean(options?.desktop) && (
        <Fieldset>
          <Legend>{translateFieldName("desktop")}</Legend>
          <RadioButtonGroup
            aria-label={translatedLabel}
            value={(get(model, desktopPath) as string) ?? ""}
            onChange={(value: string | undefined) =>
              setValue(desktopPath, value)
            }
            options={transformOptions(options?.desktop)}
            noLabel={true}
            tooltipPlacement={(tooltipPlacement || "bottom") as Placement}
          />
        </Fieldset>
      )}
      {Boolean(options?.tablet) && (
        <Fieldset>
          <Legend>{translateFieldName("tablet")}</Legend>
          <RadioButtonGroup
            aria-label={translatedLabel}
            value={(get(model, tabletPath) as string) ?? ""}
            onChange={(value: string | undefined) =>
              setValue(tabletPath, value)
            }
            options={transformOptions(options?.tablet)}
            noLabel={true}
            tooltipPlacement={(tooltipPlacement || "bottom") as Placement}
          />
        </Fieldset>
      )}
      {Boolean(options?.mobile) && (
        <Fieldset>
          <Legend>{translateFieldName("mobile")}</Legend>
          <RadioButtonGroup
            aria-label={translatedLabel}
            value={(get(model, mobilePath) as string) ?? ""}
            onChange={(value: string | undefined) =>
              setValue(mobilePath, value)
            }
            options={transformOptions(options?.mobile)}
            noLabel={true}
            tooltipPlacement={(tooltipPlacement || "bottom") as Placement}
          />
        </Fieldset>
      )}
      <Divider />
    </FieldWrapper>
  );
}

StyleSetting.displayName = "Control(StyleSetting)";

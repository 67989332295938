import {
  ControlProps,
  FormFor_form_controls_RecipeIngredientsFormControl,
  RecipeIngredientGroupFields,
  RecipeIngredientFields,
} from "@types";
import { useCallback, useMemo, useRef } from "react";
import {
  GroupUpdateOperation,
  IngredientUpdateOperation,
} from "src/types/recipe";
import { RecipeIngredientsList } from "@components";
import { useCKEditorConfig } from "../-private";
import update from "immutability-helper";

const CKEDITOR_BUILDS = {
  Minimalist: "minimalist",
  Inline: "inline",
  Body: "block",
  Block: "block",
} as const;

type ModelIngredient = RecipeIngredientFields & {
  _ingredientID?: string;
};

type ModelGroup = Omit<RecipeIngredientGroupFields, "ingredients"> & {
  _groupID?: string;
  ingredients: ModelIngredient[];
};

type DecoratedIngredient = Required<ModelIngredient>;
type DecoratedGroup = Omit<Required<ModelGroup>, "ingredients"> & {
  ingredients: DecoratedIngredient[];
};

export function RecipeIngredients(
  props: ControlProps<FormFor_form_controls_RecipeIngredientsFormControl>
) {
  const {
    model,
    setValue,
    currentOrganization,
    ingredientRichTextConfiguration,
    ingredientGroupRichTextConfiguration,
    ingredientOptions,
  } = props;

  const ingredientIDRef = useRef<number>(0);
  const groupIDRef = useRef<number>(0);

  let modelIngredientGroups = model["ingredientGroups"] as ModelGroup[] | null;

  //Sets default behavior to be one group with one blank ingredient.
  const ingredientGroups = useMemo(() => {
    return modelIngredientGroups?.length
      ? modelIngredientGroups
      : [
          {
            __typename: "RecipeIngredientGroup" as const,
            hed: "",
            ingredients: [{} as ModelIngredient],
          } as ModelGroup,
        ];
  }, [modelIngredientGroups]);

  const decoratedIngredientGroups = useMemo(
    () =>
      ingredientGroups.map(({ _groupID, ingredients, ...group }) => {
        return {
          ...group,
          _groupID: _groupID ?? (groupIDRef.current++).toString(),
          ingredients: ingredients.map(({ _ingredientID, ...ingredient }) => ({
            ...ingredient,
            _ingredientID:
              _ingredientID ?? (ingredientIDRef.current++).toString(),
          })),
        } as DecoratedGroup;
      }),
    [ingredientGroups, groupIDRef, ingredientIDRef]
  );

  const resolvedIngredientRichTextBuild =
    CKEDITOR_BUILDS[ingredientRichTextConfiguration?.build ?? "Minimalist"];

  const resolvedIngredientRichTextConfig = useCKEditorConfig(
    ingredientRichTextConfiguration,
    currentOrganization,
    model.id as string
  );
  const resolvedIngredientGroupRichTextBuild =
    CKEDITOR_BUILDS[
      ingredientGroupRichTextConfiguration?.build ?? "Minimalist"
    ];

  const formattedOptions =
    ingredientOptions?.map((unit: string) => {
      return { label: unit, value: unit };
    }) ?? [];

  const onGroupUpdate = useCallback(
    (operations: GroupUpdateOperation[]) => {
      const updatedIngredientGroups = update(decoratedIngredientGroups, {
        $splice: operations.map(([groupIndex, removeCount, group]) =>
          group
            ? [groupIndex, removeCount, group as DecoratedGroup]
            : [groupIndex, removeCount]
        ),
      });
      setValue("ingredientGroups", updatedIngredientGroups);
    },
    [setValue, decoratedIngredientGroups]
  );

  const onIngredientUpdate = useCallback(
    (operations: IngredientUpdateOperation[]) => {
      const byGroupIndex = operations.reduce(
        (acc, [groupIndex, ingredientsIndex, removeCount, ingredient]) => {
          if (!acc[groupIndex]) {
            acc[groupIndex] = [];
          }
          acc[groupIndex].push([
            ingredientsIndex,
            removeCount,
            ingredient as DecoratedIngredient | undefined,
          ]);
          return acc;
        },
        {} as Record<number, [number, number, DecoratedIngredient?][]>
      );
      let updatedIngredientGroups = decoratedIngredientGroups;
      Object.entries(byGroupIndex).forEach(([groupIndex, ops]) => {
        updatedIngredientGroups = update(decoratedIngredientGroups, {
          [groupIndex]: {
            ingredients: {
              $splice: ops.map(([ingredientIndex, removeCount, ingredient]) =>
                ingredient
                  ? [ingredientIndex, removeCount, ingredient]
                  : [ingredientIndex, removeCount]
              ),
            },
          },
        });
      });
      setValue("ingredientGroups", updatedIngredientGroups);
    },
    [setValue, decoratedIngredientGroups]
  );

  return (
    <RecipeIngredientsList
      ingredientGroups={decoratedIngredientGroups}
      onGroupUpdate={onGroupUpdate}
      onIngredientUpdate={onIngredientUpdate}
      ingredientRichTextBuild={resolvedIngredientRichTextBuild}
      ingredientRichTextConfig={resolvedIngredientRichTextConfig}
      ingredientGroupRichTextBuild={resolvedIngredientGroupRichTextBuild}
      unitOptions={formattedOptions}
    />
  );
}

RecipeIngredients.displayName = "Control(RecipeIngredients)";

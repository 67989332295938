import { defineMessages } from "react-intl";

export const CONTRIBUTOR_CREDITS = defineMessages({
  ADAPTATION_BY: {
    defaultMessage: "Adaptation by {names}",
    description: "contributorCredits.contributors-adaptation-by",
  },
  ADDITIONAL_REPORTING: {
    defaultMessage: "Additional reporting by {names}",
    description: "contributorCredits.contributors-additional-reporting",
  },
  ANIMATOR: {
    defaultMessage: "Animated by {names}",
    description: "contributorCredits.contributors-animator",
  },
  ARTIST: {
    defaultMessage: "Art by {names}",
    description: "contributorCredits.contributors-artist",
  },
  AS_TOLD_TO: {
    defaultMessage: "As told to {names}",
    description: "contributorCredits.contributors-as-told-to",
  },
  AUTHOR: {
    defaultMessage: "Authored by {names}",
    description: "contributorCredits.contributors-author",
  },
  CAPTIONIST: {
    defaultMessage: "Captioned by {names}",
    description: "contributorCredits.contributors-captionist",
  },
  CHEF: {
    defaultMessage: "Prepared by {names}",
    description: "contributorCredits.contributors-chef",
  },
  CO_AUTHOR: {
    defaultMessage: "Co-authored by {names}",
    description: "contributorCredits.contributors-co-author",
  },
  CONTRIBUTOR: {
    defaultMessage: "With contributions from {name}",
    description: "contributorCredits.contributors-contributor",
  },
  DEFAULT: {
    defaultMessage: "by {names}",
    description: "contributorCredits.contributors-default",
  },
  DEVELOPER: {
    defaultMessage: "Developed by {names}",
    description: "contributorCredits.contributors-developer",
  },
  DIRECTOR: {
    defaultMessage: "Directed by {names}",
    description: "contributorCredits.contributors-director",
  },
  EDITOR: {
    defaultMessage: "Edited by {names}",
    description: "contributorCredits.contributors-editor",
  },
  FASHION_EDITOR: {
    defaultMessage: "Fashion edits by {names}",
    description: "contributorCredits.contributors-fashion-editor",
  },
  FILM_BY: {
    defaultMessage: "Film by {names}",
    description: "contributorCredits.contributors-film-by",
  },
  FOOD_STYLIST: {
    defaultMessage: "Food styling by {names}",
    description: "contributorCredits.contributors-food-stylist",
  },
  HAIR: {
    defaultMessage: "Hair by {names}",
    description: "contributorCredits.contributors-hair",
  },
  ILLUSTRATOR: {
    defaultMessage: "Illustrations by {names}",
    description: "contributorCredits.contributors-illustrator",
  },
  IN_CONVERSATION: {
    defaultMessage: "In conversation with {names}",
    description: "contributorCredits.contributors-in-conversation",
  },
  INTERACTIVE_BY: {
    defaultMessage: "Interactive by {names}",
    description: "contributorCredits.contributors-interctive-by",
  },
  INTRODUCER: {
    defaultMessage: "Introductions by {names}",
    description: "contributorCredits.contributors-introducer",
  },
  INTRODUCTION: {
    defaultMessage: "Introductions by {names}",
    description: "contributorCredits.contributors-introduction",
  },
  LICENSED: {
    defaultMessage: "Licensed by {names}",
    description: "contributorCredits.contributors-licensed",
  },
  MAKEUP: {
    defaultMessage: "Makeup by {names}",
    description: "contributorCredits.contributors-makeup",
  },
  MEDICAL_REVIEWER: {
    defaultMessage: "Medically reviewed by {names}",
    description: "contributorCredits.contributors-medical-reviewer",
  },
  NAILS: {
    defaultMessage: "Nails by {names}",
    description: "contributorCredits.contributors-nails",
  },
  PAINTER: {
    defaultMessage: "Painted by {names}",
    description: "contributorCredits.contributors-painter",
  },
  PHOTOGRAPHER: {
    defaultMessage: "Photo by {names}",
    description: "contributorCredits.contributors-photographer",
  },
  PODCAST_HOST: {
    defaultMessage: "Hosted by {names}",
    description: "contributorCredits.contributors-podcast-host",
  },
  PORTRAITIST: {
    defaultMessage: "Portraits by {names}",
    description: "contributorCredits.contributors-portraitist",
  },
  PRODUCER: {
    defaultMessage: "Produced by {names}",
    description: "contributorCredits.contributors-producer",
  },
  PROP_STYLIST: {
    defaultMessage: "Prop styling by {names}",
    description: "contributorCredits.contributors-prop-stylist",
  },
  REPORTER: {
    defaultMessage: "Reported by {names}",
    description: "contributorCredits.contributors-reporter",
  },
  STYLIST: {
    defaultMessage: "Styling by {names}",
    description: "contributorCredits.contributors-stylist",
  },
  TEXT_BY: {
    defaultMessage: "Text by {names}",
    description: "contributorCredits.contributors-text-by",
  },
  VIDEO_BY: {
    defaultMessage: "Video by {names}",
    description: "contributorCredits.contributors-video-by",
  },
  VIDEO_PRODUCER: {
    defaultMessage: "Video produced by {names}",
    description: "contributorCredits.contributors-video-producer",
  },
  VIDEOGRAPHER: {
    defaultMessage: "Video by {names}",
    description: "contributorCredits.contributors-videographer",
  },
});

import { Autogenerates, TextField } from "@components";
import { useDefinedMessages } from "@hooks";
import { get } from "@lib";
import {
  ControlProps,
  FormFor_form_controls_TextAreaFormControl,
} from "@types";
import { useCallback, useEffect, useMemo, useState } from "react";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import PlainTextRenderer from "@atjson/renderer-plain-text";

export function TextArea(
  props: ControlProps<FormFor_form_controls_TextAreaFormControl>
) {
  let {
    model,
    name,
    setValue,
    autogeneratePath,
    contextualHelpKey,
    placeholderKey,
    maxCount,
    errors,
    disabled,
    characterCount,
    wordCount,
  } = props;
  const { translateContextualHelp, translateFieldName, translatePlaceholder } =
    useDefinedMessages();
  const value = (get(model, name) as string) ?? "";
  const [localValue, setLocalValue] = useState(value);

  const onChange = useCallback(
    (value: string) => {
      setLocalValue(value);
      setValue(name, value);
    },
    [setValue, name]
  );

  useEffect(() => setLocalValue(value), [value]);

  const autogenValue = useMemo(() => {
    if (autogeneratePath && get(model, autogeneratePath)) {
      return PlainTextRenderer.render(
        CopilotMarkdownSource.fromRaw(get(model, autogeneratePath) as string)
      ).trim();
    }
  }, [autogeneratePath, model]);

  return (
    <Autogenerates
      autogenValue={autogenValue}
      onChange={onChange}
      currentValue={localValue}
    >
      {(autogenProps) => (
        <TextField
          id={`TextArea__${name}`}
          label={translateFieldName(name)}
          placeholder={
            placeholderKey
              ? translatePlaceholder(
                  placeholderKey.replace(/^placeholder\./, "")
                )
              : undefined
          }
          help={
            contextualHelpKey
              ? translateContextualHelp(contextualHelpKey)
              : undefined
          }
          value={localValue}
          errors={errors}
          disabled={disabled}
          count={
            characterCount ? "characters" : wordCount ? "words" : undefined
          }
          maxCount={maxCount}
          multiline={true}
          {...autogenProps}
        />
      )}
    </Autogenerates>
  );
}
TextArea.displayName = "Control(TextArea)";

import styled from "styled-components";
import { GQLResponse, ContentSummary } from "@types";
import { Button, SearchResult, Spinner } from "@components";
import { ThemeProvider } from "@contexts";
import { AnimatedEllipsisIcon, NoAssetIcon } from "@condenast/gemini/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { useUniqueId } from "@hooks";
import {
  useState,
  useMemo,
  createRef,
  useLayoutEffect,
  useCallback,
  KeyboardEvent,
} from "react";
import computeScrollIntoView from "compute-scroll-into-view";

const Wrapper = styled.div`
  grid-area: result-list;
  position: relative;
  overflow-y: auto;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const SimilarProductWrapper = styled.div`
  background-color: white;
  color: black;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const SimilarProductMessage = styled.div`
  font: ${(props) => props.theme.FontSectionHeading};
`;

const SimilarProductPublishMessage = styled.div`
  font: ${(props) => props.theme.FontSmallerHeading};
`;

const PaginationWrapper = styled.div`
  grid-area: pagination;
  text-align: center;
  padding-block-end: calc(var(--spacing-md) * 5);

  & button {
    margin-left: var(--spacing-sm);

    & svg {
      margin-left: var(--spacing-sm);
    }
  }
`;

const SearchResultList = styled.ul<{
  $treatment: string;
  cursorMode: "mouse" | "keyboard";
}>`
  margin-bottom: var(--spacing-xl);
  ${({ $treatment }) => {
    if ($treatment === "grid")
      return `
        display: grid;
        gap: var(--spacing-xs);
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        padding: var(--spacing-xs);
        &:focus {
          outline: none;
        }
      `;
    else return;
  }}
  [id=${(props) => props["aria-activedescendant"]}] {
    background: var(--color-gray-6);
    span {
      visibility: visible;
    }

    ${(props) =>
      props.cursorMode === "keyboard" && `box-shadow: ${props.theme.FocusRing}`}
  }
`;
const StyledNoAssetIcon = styled(NoAssetIcon)`
  width: 4rem;
  height: 4rem;

  & path {
    fill: white;
  }
`;

const NoResultsMessageContainer = styled.span`
  padding-left: var(--spacing-sm);
  font: var(--font-subhed);
`;

export const AssetSelectorResultList = (props: {
  selections: ContentSummary[];
  onSelect: (asset: ContentSummary) => void;
  limitSelection?: number;
  searchResponse: GQLResponse<{
    search: {
      results: ContentSummary[];
      limit: number;
      offset: number;
      totalResults: number;
    };
  }>;
  hasSimilarProduct?: boolean;
  treatment: "slat" | "grid";
  cdnHost: string;
  query: string;
  count: number;
  resultsShowing: number;
}) => {
  const {
    selections,
    onSelect,
    searchResponse,
    cdnHost,
    query,
    treatment,
    hasSimilarProduct,
    count,
    resultsShowing,
  } = props;

  const intl = useIntl();
  const [cursor, setCursor] = useState(0);
  const [cursorMode, setCursorMode] = useState<"mouse" | "keyboard">("mouse");

  const [listElement, setListElement] = useState<HTMLUListElement | null>(null);

  const selectItemRefs = useMemo(() => {
    return searchResponse?.data?.search.results.map(() =>
      createRef<HTMLLIElement>()
    );
  }, [searchResponse?.data]);

  const uid = useUniqueId();
  const selectItemPrefix = `select-item-${uid}`;

  useLayoutEffect(() => {
    if (
      document.activeElement &&
      document.activeElement === listElement &&
      cursorMode === "keyboard" &&
      cursor != null &&
      selectItemRefs
    ) {
      let selection = selectItemRefs[cursor];
      if (selection?.current) {
        let actions = computeScrollIntoView(selection.current, {
          scrollMode: "if-needed",
          block: "nearest",
          inline: "nearest",
        });
        actions.forEach(({ el, top }) => {
          el.scrollTop = top;
        });
      }
    }
  }, [cursorMode, cursor, selectItemRefs, listElement]);

  const onKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLUListElement>) => {
      if (!(searchResponse.data && selectItemRefs)) {
        return;
      }
      let newCursor = cursor;
      let searchResults = searchResponse.data.search.results;
      switch (evt.key) {
        case "ArrowDown":
          if (cursor == null) {
            newCursor = -1;
          }
          newCursor = (newCursor + 1) % selectItemRefs.length;
          setCursor(newCursor);
          setCursorMode("keyboard");
          evt.preventDefault();
          evt.stopPropagation();
          break;
        case "ArrowUp":
          if (cursor == null) {
            newCursor = selectItemRefs.length;
          }
          newCursor =
            (newCursor - 1 + selectItemRefs.length) % selectItemRefs.length;
          setCursor(newCursor);
          setCursorMode("keyboard");
          evt.preventDefault();
          evt.stopPropagation();
          break;
        case "Home":
          setCursor(0);
          break;
        case "End":
          setCursor(selectItemRefs.length - 1);
          break;
        case "Space":
          onSelect(searchResults[cursor]);
          break;
        case "Enter":
          onSelect(searchResults[cursor]);
          break;
      }
    },
    [
      cursor,
      selectItemRefs,
      setCursor,
      setCursorMode,
      searchResponse.data,
      onSelect,
    ]
  );

  const onMouseMove = useCallback(
    (newCursor) => {
      setCursor(newCursor);
      setCursorMode("mouse");
    },
    [setCursor, setCursorMode]
  );

  return (
    <Wrapper>
      <ThemeProvider theme="light">
        {hasSimilarProduct && (
          <SimilarProductWrapper>
            <SimilarProductMessage>
              <FormattedMessage
                defaultMessage={"Product found in our external feed"}
              />
            </SimilarProductMessage>
            <SimilarProductPublishMessage>
              <FormattedMessage defaultMessage={"Publish to add the asset"} />
            </SimilarProductPublishMessage>
          </SimilarProductWrapper>
        )}
        {searchResponse.loading ? (
          <MessageWrapper>
            <Spinner size="large" />
          </MessageWrapper>
        ) : searchResponse.data &&
          searchResponse.data?.search.results.length === 0 ? (
          <MessageWrapper>
            <StyledNoAssetIcon size="regular" />
            <NoResultsMessageContainer>
              {intl.formatMessage({
                defaultMessage: "No results found",
                description: "Search has returned 0 results",
              })}
            </NoResultsMessageContainer>
          </MessageWrapper>
        ) : (
          <SearchResultList
            tabIndex={0}
            $treatment={treatment}
            cursorMode={cursorMode}
            onKeyDown={onKeyDown}
            aria-activedescendant={
              cursor !== null && cursor >= 0
                ? `${selectItemPrefix}-${cursor}`
                : undefined
            }
            ref={setListElement}
          >
            {searchResponse?.data?.search.results.map((item, index) => {
              let isSelectedResult = selections.some((selection) => {
                return selection.id === item.id;
              });
              return (
                <li key={item.id}>
                  <SearchResult
                    id={`${selectItemPrefix}-${index}`}
                    onMouseMove={() => {
                      onMouseMove(index);
                    }}
                    onClick={() => onSelect(item)}
                    aria-selected={isSelectedResult || undefined}
                    result={item}
                    treatment={
                      treatment === "slat" ? "slat-result" : "asset-tile"
                    }
                    cdnHost={cdnHost}
                    query={query}
                  />
                </li>
              );
            })}
          </SearchResultList>
        )}
      </ThemeProvider>
      {(searchResponse?.data?.search?.results ?? []).length > 0 && (
        <PaginationWrapper>
          <span>
            <FormattedMessage
              defaultMessage="Showing {resultsShowing, number} of {count, number}"
              values={{
                resultsShowing: resultsShowing,
                count: count,
              }}
            />
          </span>
          {searchResponse.loadMore && resultsShowing < count && (
            <Button onClick={searchResponse.loadMore}>
              {searchResponse.loading ? (
                <AnimatedEllipsisIcon size="small" />
              ) : (
                <FormattedMessage defaultMessage="Load More" />
              )}
            </Button>
          )}
        </PaginationWrapper>
      )}
    </Wrapper>
  );
};

import { useMemo, useState, useContext } from "react";
import { AspectRatio } from "@types";
import styled from "styled-components";
import { Toggle, ContextualHelp, Card } from "@components";
import { FormattedMessage } from "react-intl";
import { useDefinedMessages } from "@hooks";
import { ImageEditorTakeover } from "./ImageEditorTakeover";
import { AspectRatioTile } from "./AspectRatioTile";
import { SnowplowContext } from "@contexts";

const Section = styled(Card).attrs({ as: "section" })`
  margin-block-start: var(--spacing-md);
`;

const Header = styled.div`
  display: flex;
  padding-bottom: var(--spacing-sm);
  justify-content: space-between;
`;

const Title = styled.h2`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
`;

const RestrictCroppingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledContextualHelp = styled(ContextualHelp)`
  margin: 0 var(--spacing-xs);
  [role="status"] {
    max-width: calc(var(--spacing-sm) * 25);
  }
`;

const AspectRatioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(var(--spacing-sm) * 11.25));
  grid-gap: var(--spacing-sm);
  width: 100%;
`;

export function ImageEditor(props: {
  id: string;
  type: string;
  filename: string;
  altText: string;
  aspectRatios: AspectRatio[];
  cdnHost: string;
  restrictCropping: boolean;
  originalImage: AspectRatio;
  setValue: (key: string, value: unknown) => void;
  onTakeoverSubmit: (updatedAspectRatioCrops: AspectRatio[]) => void;
}) {
  let {
    id,
    type,
    filename,
    altText,
    aspectRatios,
    cdnHost,
    restrictCropping,
    originalImage,
    setValue,
    onTakeoverSubmit,
  } = props;

  const fieldSectionName = "crops";
  let { translateFieldLegend } = useDefinedMessages();

  let [showImageEditorTakeover, setShowImageEditorTakeover] = useState(false);
  let [selectedAspectRatio, setSelectedAspectRatio] =
    useState<AspectRatio | null>(null);

  const { trackContentEvent } = useContext(SnowplowContext);

  const { width, height } = originalImage;
  const asset = useMemo(() => {
    return {
      type,
      id,
      filename,
      altText,
      width,
      height,
      publicURL: null,
    };
  }, [width, height, type, id, filename, altText]);

  return (
    <>
      <Section id={fieldSectionName}>
        <Header>
          <Title>{translateFieldLegend(fieldSectionName)}</Title>
          <RestrictCroppingWrapper>
            <FormattedMessage defaultMessage={"Do Not Crop"} />
            <StyledContextualHelp id="ContextualHelpRestrictCropping">
              <FormattedMessage
                defaultMessage={`Toggle on if the photo shouldn’t be cropped. 
              This will maintain the photo’s original aspect ratio. 
              Homepage crop settings will not be applied.`}
              />
            </StyledContextualHelp>
            <Toggle
              id={asset.id}
              value={restrictCropping}
              onChange={(value) => {
                setValue("restrictCropping", value);
              }}
            />
          </RestrictCroppingWrapper>
        </Header>
        <AspectRatioGrid>
          {aspectRatios.map((aspectRatio, index) => (
            <AspectRatioTile
              key={index}
              aspectRatio={aspectRatio}
              restrictCropping={restrictCropping}
              cdnHost={cdnHost}
              asset={asset}
              onClick={(selectedAspectRatio) => {
                setSelectedAspectRatio(selectedAspectRatio);
                setShowImageEditorTakeover(true);
                trackContentEvent("replace_aspect_ratio");
              }}
            />
          ))}
        </AspectRatioGrid>
      </Section>
      {showImageEditorTakeover && selectedAspectRatio && (
        <ImageEditorTakeover
          onClose={() => {
            setShowImageEditorTakeover(false);
          }}
          onSubmit={(updatedAspectRatioCrops) => {
            onTakeoverSubmit(updatedAspectRatioCrops);
            setShowImageEditorTakeover(false);
          }}
          aspectRatios={aspectRatios}
          asset={asset}
          cdnHost={cdnHost}
          selectedAspectRatioName={selectedAspectRatio.name}
        />
      )}
    </>
  );
}
ImageEditor.displayName = "ImageEditor";

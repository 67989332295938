import type { ContentSerializers } from "../types";
import type { LivestoryupdateInput } from "@types";
import { serializerFor, entityMetadata } from "../utils";
import { allContributors } from "../fields";

const LivestoryupdateFields = {
  allContributors,
} as const;

export const LivestoryupdateSerializers: ContentSerializers<LivestoryupdateInput> =
  {
    save: (changes, model, { currentUser }) => {
      if (!("title" in model)) {
        return null;
      }
      if (!("liveStoryId" in model)) {
        return null;
      }
      return {
        ...serializerFor(LivestoryupdateFields)(changes),
        liveStoryId: model.liveStoryId,
        title: model.title,
        authorName: `${currentUser.firstName} ${currentUser.lastName}`,
        entityMetadata: entityMetadata(model, currentUser.id),
      };
    },
    create: ({ currentUser }, model) => {
      if (!model || !("liveStoryId" in model)) {
        return null;
      }
      return {
        liveStoryId: model?.liveStoryId,
        title: model.title ?? "",
        ...serializerFor(LivestoryupdateFields)(model),
        authorName: `${currentUser.firstName} ${currentUser.lastName}`,
        entityMetadata: { user: [`${currentUser.id}`] },
      };
    },
  };

import { useEffect } from "react";
import { GetCurrentUser_currentUser, Organization, BundleInput } from "@types";
import { Navigate, useParams } from "react-router-dom";
import { useContentMutation } from "@hooks";
import styled from "styled-components";
import { Spinner, ValidationSummary } from "@condenast/gemini";
import { useIntl } from "react-intl";
import { capitalize } from "@lib";

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const BundleCreate = (props: {
  currentUser: GetCurrentUser_currentUser;
  currentOrganization: Organization;
  loading?: boolean;
}) => {
  const { currentOrganization, currentUser, loading } = props;
  const params = useParams() as {
    copilotCode: string;
    bundleType: string;
  };
  const copilotCode = params.copilotCode;
  const bundleType = params.bundleType;

  const [serialize, createBundle, createBundleResult] = useContentMutation(
    "bundle",
    "create"
  );

  const intl = useIntl();

  useEffect(() => {
    if (!serialize || !createBundle || loading) {
      return;
    }

    const input = serialize({
      currentUser,
      intl,
      brandCode: currentOrganization.metadata.copilotCode,
      config: { hostnames: { consumer: currentOrganization.url } },
    }) as BundleInput;
    if (input != null) {
      createBundle({
        variables: {
          organizationId: currentOrganization.organizationId,
          data: {
            ...input,
            bundleType: bundleType.split("-").map(capitalize).join(" "),
          },
        },
      });
    }
  }, [
    serialize,
    createBundle,
    currentUser,
    currentOrganization,
    intl,
    loading,
    bundleType,
  ]);

  return createBundleResult?.data?.content ? (
    <Navigate
      to={`/${copilotCode}/pages/${bundleType}/${createBundleResult.data.content.id}`}
      replace={true}
    />
  ) : createBundleResult?.error ? (
    <ValidationSummary
      errors={[
        {
          message: createBundleResult.error.message,
          path: [createBundleResult.error.name],
        },
      ]}
    />
  ) : (
    <SpinnerWrapper>
      <Spinner size="large" />
    </SpinnerWrapper>
  );
};

import {
  BusinessContactPointInput,
  CreateBusiness_content_Business_contactPoint,
} from "@types";

export function businessContactPoint(
  data: CreateBusiness_content_Business_contactPoint
): BusinessContactPointInput {
  let { email, telephone, url } = data;

  return {
    email,
    telephone,
    url,
  };
}

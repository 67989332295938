import { Task, validate, ValidationError } from "@condenast/cross-check";
import build, { validators } from "@condenast/cross-check-dsl";

import { required, url } from "@condenast/copilot-validators";

import type { ValidationEnvironment, ValidationContext } from "@lib";
import type { FormFor_content_Advertiser } from "@types";

const { object } = validators;

const advertiserValidators = object({
  name: required(),
  url: required(url("absolute", "leading-slash")).catch(([{ message }]) => {
    return [
      {
        path: [],
        message: { ...message, name: "url-absolute-leading-slash" },
      },
    ];
  }),
});

export default function validateAdvertiser(
  brand: FormFor_content_Advertiser,
  context: ValidationContext,
  env: ValidationEnvironment
): Task<ValidationError[]> {
  return validate(brand, build(advertiserValidators), context, env);
}

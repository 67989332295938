import {
  useMemo,
  useCallback,
  ReactNode,
  Ref,
  forwardRef,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  FormattedMessage,
  useIntl,
  defineMessage,
  MessageDescriptor,
} from "react-intl";
import { ParticipantData } from "@types";
import styled from "styled-components";
import {
  EllipsisIcon,
  AnimatedEllipsisIcon,
  UploadIcon,
  HamburgerIcon,
  CautionIcon,
  CheckCircleIcon,
  DangerIcon,
  SaveIcon,
  PreviewIcon,
} from "@condenast/gemini/icons";
import {
  ARIA,
  Button,
  RoutableLink,
  ConcurrentUsers,
  Spinner,
} from "@components";
import { useMediaQuery } from "@hooks";
import { SnowplowContext } from "@contexts";

const Dialog = styled(ARIA.Dialog)`
  overflow: visible;
  padding-top: 1rem;
`;

const DialogBody = styled.div`
  padding: var(--spacing-xs) var(--spacing-md) var(--spacing-sm);
  overflow: auto;
  line-height: normal;
`;

const CollapseIcon = styled(UploadIcon)`
  transform: rotate(90deg);
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledActionBar = styled.div<{ $pinned: boolean }>`
  position: fixed;
  top: 65px;
  z-index: ${(props) => props.theme.ElevationCard};
  right: var(--spacing-sm);
  width: ${(props) => (props.$pinned ? "auto" : "min-content")};
  border-radius: ${(props) => (props.$pinned ? 0 : props.theme.CornerRadius)};
  box-shadow: ${(props) => props.theme.ActionBarShadow};
  background: ${(props) => props.theme.Background};

  display: flex;
  flex-direction: column;

  @media (max-width: 650px) {
    position: sticky;
    top: 56px;
  }

  @media (max-width: 500px) {
    position: sticky;
    top: 49px;
  }

  &.collapsed {
    display: inline-block;
    padding: 0;
  }

  &.attached-sheet:not(.collapsed) {
    bottom: var(--spacing-xs);
  }
`;

const ButtonList = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: var(--spacing-xs);

  padding: var(--spacing-xs);
  white-space: nowrap;
`;

const StyledButton = styled(ARIA.MenuButton)`
  & ~ [role="menu"] {
    max-height: 398px;
    overflow-y: auto;
  }
`;

const HamburgerButton = styled(Button)`
  padding: var(--spacing-sm);
  appearance: none;
  border: 0;
`;

let CheckSEO = styled(CheckCircleIcon)`
  color: var(--color-green-50);
`;

let CautionSEO = styled(CautionIcon)`
  color: var(--color-yellow-40);
`;

let DangerSEO = styled(DangerIcon)`
  color: var(--color-red-50);
`;

const Icons = {
  good: CheckSEO,
  average: CautionSEO,
  bad: DangerSEO,
};

function scoreRange(score: number) {
  if (score >= 0 && score <= 49) {
    return "bad";
  }

  if (score >= 50 && score <= 79) {
    return "average";
  }

  if (score >= 80) {
    return "good";
  }

  return undefined;
}

const SEOButton = styled(Button)`
  display: flex;
  align-items: center;

  & svg {
    left: 0px;
    margin-left: var(--spacing-xxs);
    display: inline;
  }

  &.focus,
  &.focus:hover {
    background: var(--color-blue-80);
    text-decoration: none;
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;
  }
`;

const PublishButton = styled(Button)`
  display: flex;
  align-items: center;

  &:active,
  &:hover,
  &:focus {
    background: ${(props) => props.theme.PrimaryActiveBackground};
  }
`;

export const AttachedSheet = styled.div`
  border-top: 1px solid ${(props) => props.theme.DividerColor};
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
`;

export const DetachedSheet = styled.div`
  position: fixed;
  bottom: var(--spacing-xs);
  left: var(--spacing-xs);
  right: var(--spacing-xs);

  height: 33vh;

  @media (min-width: 651px) {
    min-height: 230px;
  }

  @media (max-width: 650px) {
    min-height: 288px;
  }

  z-index: ${(props) => props.theme.ElevationCard};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.ActionBarShadow};
  background: ${(props) => props.theme.Background};
`;

type Actions = {
  onSave?: () => void;
  onPublish?: () => void;
  publishing?: boolean;
  onUnpublish?: () => void;
  archive?: () => void;
  unarchive?: () => void;
  previewURI?: string;
  onPreview?: () => void;
  liveURI?: string;
  onViewLive?: () => void;
  showPublishHistory?: () => void;
  showDeleteMedia?: () => void;
};

type ActionBarProps = {
  className?: string;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  toggleSeoScorecard?: () => void;
  actions?: Actions;
  sheetContents?: ReactNode;
  detachSheet?: boolean;
  seoScore?: number;
  isConnected: boolean;
  participants: ParticipantData[];
  contentType: string | null;
  isPublishable?: boolean;
  isArchived?: boolean;
  userRole: string;
};

function ActionBarImpl(props: ActionBarProps, ref: Ref<HTMLDivElement>) {
  const intl = useIntl();
  const {
    className,
    actions,
    toggleSeoScorecard,
    sheetContents,
    detachSheet,
    collapsed,
    setCollapsed,
    seoScore,
    isConnected,
    participants,
    contentType,
    isPublishable,
    isArchived,
    userRole,
  } = props;
  const classNames = [];

  const isPinned = useMediaQuery("(max-width: 650px)");
  const compactView = useMediaQuery("(max-width: 425px)");
  const [isSaving, setIsSaving] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);

  const { trackComponentEvent } = useContext(SnowplowContext);
  let scoreGrade: "good" | "average" | "bad" =
    scoreRange(seoScore ?? 0) ?? "bad";
  let Icon = Icons[scoreGrade];

  const menuItems = useMemo(() => {
    const items = [];
    if (isPinned && compactView && toggleSeoScorecard) {
      items.push({
        role: "action",
        onClick: toggleSeoScorecard,
        value: defineMessage({
          defaultMessage: "SEO",
          description: "acronym for Search Engine Optimization",
        }),
      });
    }
    if (actions?.onUnpublish && actions?.onPublish) {
      items.push({
        role: "action",
        onClick: actions.onUnpublish,
        value: defineMessage({
          defaultMessage: "Unpublish",
          description: "Reverse the publishing of a piece of content",
        }),
      });
    }
    if (actions?.liveURI) {
      items.push({
        role: "link",
        href: actions.liveURI,
        target: "_blank",
        onClick: () => {
          actions.onViewLive?.();
        },
        value: defineMessage({
          defaultMessage: "View Live",
          description:
            "View the live version of a piece of content on the owned and operated website",
        }),
      });
    }
    if (actions?.showPublishHistory && contentType && isPublishable) {
      items.push({
        role: "action",
        onClick: actions.showPublishHistory,
        value: defineMessage({
          defaultMessage: "Publish History",
          description: "Show the publish history for a piece of content",
        }),
      });
    }
    if (!isArchived) {
      items.push({
        role: "action",
        onClick: () => setConfirmArchive(true),
        value: defineMessage({
          defaultMessage: "Hide from Search",
          description: "Hide from Search",
        }),
      });
    }
    if (isArchived) {
      items.push({
        role: "action",
        onClick: () => {
          setIsLoadingSpinner(true);
          actions && actions.unarchive && actions.unarchive();
        },
        value: defineMessage({
          defaultMessage: "Show On Search",
          description: "Show On Search",
        }),
      });
    }
    if (
      actions?.showDeleteMedia &&
      userRole === "superadmin" &&
      contentType &&
      ["clips", "photos", "cartoons"].includes(contentType)
    ) {
      items.push({
        role: "action",
        onClick: actions.showDeleteMedia,
        value: defineMessage({
          defaultMessage: "Delete",
          description: "Delete media asset and archive this content",
        }),
      });
    }
    return items;
  }, [
    isPinned,
    compactView,
    toggleSeoScorecard,
    actions,
    isPublishable,
    contentType,
    userRole,
    setConfirmArchive,
    isArchived,
  ]);

  if (collapsed && !isPinned) {
    classNames.push("collapsed");
  }

  if (sheetContents) {
    classNames.push(detachSheet ? "detached-sheet" : "attached-sheet");
  }

  const save = useCallback(
    async (evt) => {
      if (actions?.onSave) {
        setIsSaving(true);
        evt.preventDefault();
        await actions.onSave?.();
        setIsSaving(false);
      }
    },
    [actions]
  );

  useEffect(() => {
    if ("Intercom" in window && sheetContents) {
      Intercom("update", {
        hide_default_launcher: true,
      });
    }
    return () => {
      if ("Intercom" in window) {
        Intercom("update", {
          hide_default_launcher: false,
        });
      }
    };
  });

  useEffect(() => {
    if (isLoadingSpinner) {
      setIsLoadingSpinner(false);
    }
  }, [isArchived]);

  return (
    <>
      {isLoadingSpinner ? (
        <SpinnerWrapper>
          <Spinner className="iframe-loading" size="large" />
        </SpinnerWrapper>
      ) : (
        <StyledActionBar
          className={[...classNames, className].join(" ")}
          ref={ref}
          $pinned={isPinned}
        >
          {collapsed && !isPinned ? (
            <HamburgerButton
              treatment="primary"
              aria-label={intl.formatMessage({ defaultMessage: "Open" })}
              onClick={() => setCollapsed(false)}
              tooltipPlacement="bottom"
            >
              <HamburgerIcon size="regular" />
            </HamburgerButton>
          ) : (
            <>
              <ButtonList>
                {menuItems.length ? (
                  <StyledButton
                    treatment="borderless"
                    aria-label={intl.formatMessage({
                      defaultMessage: "Action Menu",
                    })}
                    size="regular"
                    id="actions-menu"
                    tooltipPlacement="bottom"
                    menu={{
                      theme: "modal",
                      items: menuItems,
                      children(label: MessageDescriptor) {
                        return <FormattedMessage {...label} />;
                      },
                    }}
                    popper={{
                      placement: "bottom-start",
                      strategy: "fixed",
                    }}
                  >
                    <EllipsisIcon size="regular" />
                  </StyledButton>
                ) : (
                  <></>
                )}
                {isConnected && (
                  <ConcurrentUsers
                    onClick={() => {
                      "click";
                    }}
                    id={"ConcurrentUsers"}
                    users={participants}
                  />
                )}
                {!compactView && toggleSeoScorecard && (
                  <SEOButton
                    onClick={() => {
                      toggleSeoScorecard();
                      trackComponentEvent(
                        "seo",
                        "button_click",
                        "seo_scorecard"
                      );
                    }}
                    className={sheetContents ? "focus" : ""}
                  >
                    <FormattedMessage defaultMessage="SEO" />
                    <Icon size="small" />
                  </SEOButton>
                )}
                {actions &&
                  actions.onPreview &&
                  contentType &&
                  isPublishable &&
                  actions.previewURI && (
                    <Button
                      as={RoutableLink}
                      to={actions.previewURI}
                      target="_blank"
                      onClick={actions.onPreview}
                    >
                      {compactView ? (
                        <PreviewIcon
                          style={{ position: "unset" }}
                          aria-label={intl.formatMessage({
                            defaultMessage: "Preview",
                            description:
                              "As a verb, preview the live version of a piece of content",
                          })}
                          size="regular"
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Preview"
                          description="As a verb, preview the live version of a piece of content"
                        />
                      )}
                    </Button>
                  )}
                <Button onClick={save} disabled={isSaving || !actions?.onSave}>
                  {compactView ? (
                    <SaveIcon
                      style={{ position: "unset" }}
                      aria-label={intl.formatMessage({
                        defaultMessage: "Save",
                        description: "Saving a piece of content",
                      })}
                      size="regular"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Save"
                      description="Saving a piece of content"
                    />
                  )}
                </Button>
                {actions?.onPublish && (
                  <PublishButton
                    treatment="primary"
                    onClick={(evt) => {
                      actions?.onPublish?.();
                      evt.preventDefault();
                      evt.stopPropagation();
                    }}
                    className={
                      actions.publishing || actions.liveURI ? "active" : ""
                    }
                  >
                    {actions.publishing && (
                      <AnimatedEllipsisIcon size={"small"} />
                    )}
                    <FormattedMessage defaultMessage="Publish" />
                  </PublishButton>
                )}
                {!isPinned && (
                  <Button
                    aria-label={intl.formatMessage({
                      defaultMessage: "Minimize",
                      description: "Minimize acton bar",
                    })}
                    tooltipPlacement="bottom"
                    onClick={() => setCollapsed(true)}
                  >
                    <CollapseIcon size="regular" />
                  </Button>
                )}
              </ButtonList>
              {sheetContents && !detachSheet && (
                <div style={{ position: "relative", flex: 1 }}>
                  <AttachedSheet>{sheetContents}</AttachedSheet>
                </div>
              )}
            </>
          )}
        </StyledActionBar>
      )}
      {sheetContents && detachSheet && (
        <DetachedSheet>{sheetContents}</DetachedSheet>
      )}
      {confirmArchive && (
        <Dialog
          title={intl.formatMessage({
            defaultMessage: "Do you want to hide this content in Copilot?",
          })}
          size={"regular"}
          cancelButton={
            <Button>
              <FormattedMessage
                defaultMessage="Cancel"
                description="Cancel button for arvhive operation"
              />
            </Button>
          }
          submitButton={
            <Button
              treatment="primary"
              type="button"
              onClick={() => {
                actions && actions.archive && actions.archive();
                setConfirmArchive(false);
                setIsLoadingSpinner(true);
              }}
            >
              <FormattedMessage
                defaultMessage="Confirm"
                description="Confirm delete button for archive operation"
              />
            </Button>
          }
          onClose={() => setConfirmArchive(false)}
        >
          <DialogBody>
            By clicking Confirm, this content will be hidden for all Copilot
            <br />
            users from search results and content listings.
            <br />
            <br />
            You can find this content only by selecting &quot;Include Hidden
            Content&quot; from the Search page options. <br />
            <br />
            Note: This feature refers to Copilot Search only and will not have
            any impact on Google, <br />
            brand websites, or other front-end search tools.
            <br />
          </DialogBody>
        </Dialog>
      )}
    </>
  );
}

ActionBarImpl.displayName = "ActionBar";

export const ActionBar = forwardRef(ActionBarImpl);

import { ARIA, Button } from "@components";
import { useToast } from "@hooks";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";

const Dialog = styled(ARIA.Dialog)`
  overflow: visible;
`;

const DialogBody = styled.div`
  padding: var(--spacing-xs) var(--spacing-md) var(--spacing-sm);
  max-width: 100vw;
  overflow: auto;
`;

export const DeleteMedia = (props: {
  onClose: () => void;
  contentType: string;
  deleteMedia: () => Promise<boolean>;
}) => {
  const intl = useIntl();
  const showDeleteSuccessMessage = useToast({
    type: "success",
    details: intl.formatMessage({
      defaultMessage:
        "The media asset has been deleted and will no longer be visible",
    }),
    children: intl.formatMessage({
      defaultMessage: "Delete success",
    }),
  });
  const showDeleteFailMessage = useToast({
    type: "error",
    children: intl.formatMessage({
      defaultMessage: "Delete failed, please try again ",
    }),
  });

  const deleteMessage = async () => {
    const res = await props.deleteMedia();
    if (res) {
      showDeleteSuccessMessage();
      props.onClose();
    } else {
      showDeleteFailMessage();
      props.onClose();
    }
  };

  return (
    <Dialog
      title={intl.formatMessage({ defaultMessage: "Permanently Delete?" })}
      size={"regular"}
      cancelButton={
        <Button>
          <FormattedMessage
            defaultMessage="Cancel"
            description="Cancel button in delete modal"
          />
        </Button>
      }
      submitButton={
        <Button treatment="primary" type="button" onClick={deleteMessage}>
          <FormattedMessage
            defaultMessage="Confirm"
            description="Confirm delete button in delete modal"
          />
        </Button>
      }
      onClose={props.onClose}
    >
      <DialogBody>
        {intl.formatMessage({
          defaultMessage:
            "Deleting will remove this asset from Copilot and our sites. Please be sure to remove the asset from everywhere it is currently used. This cannot be undone.",
        })}
      </DialogBody>
    </Dialog>
  );
};
DeleteMedia.displayName = "DeleteMedia";

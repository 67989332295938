import { removeTypeName } from "@lib";
import type {
  DeepOmit,
  GetBundle_bundle,
  GetLayoutConfigs_layoutConfigs,
  GetLayoutConfigs_layoutConfigs_layouts_renditionConfigs,
} from "@types";
import { mergeWith } from "lodash";
import type {
  DecoratedContainer,
  LayoutConfigModel,
  LayoutRenditionConfigs,
} from "./ContainerOptions.types";

export class LayoutConfigTransformer {
  private static renditionConfigsFromGraphql(
    renditionConfigs:
      | DeepOmit<
          GetLayoutConfigs_layoutConfigs_layouts_renditionConfigs,
          "__typename"
        >[]
      | null
  ) {
    return (renditionConfigs ?? []).reduce((acc, { name, config }) => {
      acc[name] = config;
      return acc;
    }, {} as LayoutRenditionConfigs);
  }

  static renditionConfigsToGraphql(
    renditionConfigs?: LayoutRenditionConfigs | null
  ) {
    if (!renditionConfigs) {
      return renditionConfigs;
    }
    return Object.entries(renditionConfigs).map(([name, config]) => {
      return {
        name,
        config,
      };
    });
  }

  static fromGraphql(
    layoutConfigs: GetLayoutConfigs_layoutConfigs
  ): LayoutConfigModel {
    const layoutConfigStripped = removeTypeName(layoutConfigs);
    return {
      ...layoutConfigStripped,
      layouts: layoutConfigStripped.layouts.reduce<
        LayoutConfigModel["layouts"]
      >((acc, layout) => {
        if (!layout) {
          return acc;
        }
        const { renditionConfigs, ...rest } = layout;
        acc[layout.layoutId] = {
          ...rest,
          renditionConfigs: this.renditionConfigsFromGraphql(renditionConfigs),
          preset: {
            ...rest.preset,
            renditionConfigs: this.renditionConfigsFromGraphql(
              rest.preset.renditionConfigs
            ),
          },
        };
        return acc;
      }, {}),
    };
  }

  static toGraphql(layoutConfig: LayoutConfigModel) {
    return {
      ...layoutConfig,
      layouts: Object.values(layoutConfig.layouts).map(
        ({ preset, renditionConfigs, ...layout }) => {
          return {
            ...layout,
            presetId: preset.id,
            renditionConfigs: this.renditionConfigsToGraphql(renditionConfigs),
          };
        }
      ),
    };
  }
}

function mergeHandler(presetValue: unknown, layoutConfigValue: unknown) {
  if (layoutConfigValue === null) {
    return presetValue;
  }
  if (Array.isArray(layoutConfigValue)) {
    return layoutConfigValue;
  }
  return undefined;
}

function getMergedPresetsWithLayouts(
  containerId: string,
  layoutConfig: LayoutConfigModel | null
) {
  const layoutData = layoutConfig?.layouts[containerId];

  if (!layoutData) {
    return {
      presetCategory: null,
      disableTimeOfPublishOptions: false,
      model: {
        componentConfigs: null,
        renditionConfigs: null,
      },
    };
  }

  const { preset, ...layout } = layoutData;

  const componentConfigs = mergeWith(
    {},
    preset.componentConfigs,
    layout.componentConfigs,
    mergeHandler
  );

  const renditionConfigs = mergeWith(
    {},
    preset.renditionConfigs,
    layout.renditionConfigs,
    mergeHandler
  );

  return {
    componentPreset: preset.name,
    presetCategory: preset.presetCategory,
    disableTimeOfPublishOptions: preset.disableTimeOfPublishOptions,
    model: {
      componentConfigs,
      renditionConfigs,
    },
  };
}

export function decorateContainers(
  bundleData: GetBundle_bundle,
  layoutConfig: LayoutConfigModel | null
): DecoratedContainer[] {
  const containers = bundleData.containers || [];
  return containers
    ?.filter((container) => Boolean(container.hed))
    .map((container) => ({
      ...container,
      ...getMergedPresetsWithLayouts(container.id, layoutConfig),
    }));
}

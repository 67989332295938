import { ValidationError } from "@condenast/cross-check";
import {
  BasicValidator,
  ValidationBuilder,
  builderFor,
} from "@condenast/cross-check-dsl";

import { SliceAnnotation, is } from "@atjson/document";
import { Accordion } from "@atjson/offset-annotations";

import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";

class MarkdownAccordionsValidator extends BasicValidator<unknown> {
  static validatorName = "empty-accordion-headers";

  async validate(markdown: string): Promise<ValidationError[]> {
    const errors: ValidationError[] = [];
    const versoDoc =
      CopilotMarkdownSource.fromRaw(markdown).convertTo(VersoSource);
    const accordions = versoDoc.where((a) => is(a, Accordion)).as("accordions");
    const sliceAnnotations = versoDoc
      .where((a) => is(a, SliceAnnotation))
      .as("sliceAnnotations");
    sliceAnnotations.outerJoin(accordions, (slice, accordion) => {
      let accordionAttributeItems = accordion?.attributes?.items;
      let header = accordionAttributeItems.filter(
        (item: any) => item.header === slice.id
      );
      if (header.length) {
        if (slice.start + 1 === slice.end) {
          errors.push({
            path: [],
            message: { details: {}, name: "empty-accordion-header" },
          });
        }
      }
      return header.length;
    });
    return errors;
  }
}

export function noEmptyAccordionHeaders(): ValidationBuilder<unknown> {
  return builderFor(MarkdownAccordionsValidator)();
}

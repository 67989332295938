import { defineMessages } from "react-intl";

export const FIELD_NAMES = defineMessages({
  active: {
    defaultMessage: "Active",
    description: "fieldNames.active",
  },
  additional: {
    defaultMessage: "Additional",
    description: "fieldNames.aditional",
  },
  additionalName: {
    defaultMessage: "Middle Name",
    description: "fieldNames.additionalName",
  },
  "address.city": {
    defaultMessage: "City",
    description: "fieldNames.address.city",
  },
  "address.country": {
    defaultMessage: "Country",
    description: "fieldNames.address.country",
  },
  "address.locality": {
    defaultMessage: "Locality",
    description: "fieldNames.address.locality",
  },
  "address.neighborhood": {
    defaultMessage: "Neighborhood",
    description: "fieldNames.address.neighborhood",
  },
  "address.postalCode": {
    defaultMessage: "Postal Code",
    description: "fieldNames.adress.postalCode",
  },
  "address.region": {
    defaultMessage: "Region",
    description: "fieldNames.address.region",
  },
  "address.state": {
    defaultMessage: "State",
    description: "fieldNames.address.state",
  },
  "address.street": {
    defaultMessage: "Street",
    description: "fieldNames.address.street",
  },
  "address.streetExtended": {
    defaultMessage: "Street Extended",
    description: "fieldNames.address.streetExtended",
  },
  "location.name": {
    defaultMessage: "Name",
    description: "fieldNames.location.name",
  },
  "location.street": {
    defaultMessage: "Street",
    description: "fieldNames.location.street",
  },
  "location.city": {
    defaultMessage: "City",
    description: "fieldNames.location.city",
  },
  "location.state": {
    defaultMessage: "State",
    description: "fieldNames.location.state",
  },
  "location.country": {
    defaultMessage: "Country",
    description: "fieldNames.location.country",
  },
  "location.postalCode": {
    defaultMessage: "Postal Code",
    description: "fieldNames.location.postalCode",
  },
  "times.prepTime": {
    defaultMessage: "Preparation Time",
    description: "fieldNames.times.prepTime",
  },
  "times.activeTime": {
    defaultMessage: "Cooking Time",
    description: "fieldNames.times.activeTime",
  },
  "times.totalTime": {
    defaultMessage: "Total Time",
    description: "fieldNames.times.totalTime",
  },
  "times.displayTime": {
    defaultMessage: "Display Time",
    description: "fieldNames.times.displayTime",
  },
  "servingSizeInfo.servingSizeRangeFrom": {
    defaultMessage: "Serving Size Range From",
    description: "fieldNames.servingSizeInfo.servingSizeRangeFrom",
  },
  "servingSizeInfo.servingSizeRangeTo": {
    defaultMessage: "Serving Size Range To",
    description: "fieldNames.servingSizeInfo.servingSizeRangeTo",
  },
  "servingSizeInfo.servingSizeUnit": {
    defaultMessage: "Serving Size Unit",
    description: "fieldNames.servingSizeInfo.servingSizeUnit",
  },
  "servingSizeInfo.servingSizeDescription": {
    defaultMessage: "Serving Size Description",
    description: "fieldNames.servingSizeInfo.servingSizeDescription",
  },
  advertiser: {
    defaultMessage: "Advertiser",
    description: "fieldNames.advertiser",
  },
  aggregateRating: {
    defaultMessage: "Rating",
    description: "fieldNames.aggregateRating",
  },
  allContributors: {
    defaultMessage: "Contributor",
    description: "fieldNames.allContributors",
  },
  altText: { defaultMessage: "Alt text", description: "fieldNames.altText" },
  alternateName: {
    defaultMessage: "Alternate Name",
    description: "fieldNames.alternateName",
  },
  amgUserId: {
    defaultMessage: "Member ID",
    description: "fieldNames.amgUserID",
  },
  anchor: { defaultMessage: "Anchor", description: "fieldNames.anchor" },
  announceFinalistsDate: {
    defaultMessage: "Finalists Announced Date",
    description: "fieldNames.announceFinalistDate",
  },

  announceFinalistsIssueDate: {
    defaultMessage: "Finalists Announced Issue Date",
    description: "fieldNames.announceFinalistsIssueDate",
  },

  announceWinnerDate: {
    defaultMessage: "Winner Announced Date",
    description: "fieldNames.announceWinnerDate",
  },
  aNumber: {
    defaultMessage: "Art Number",
    description: "fieldNames.aNumber",
  },
  artwork: {
    defaultMessage: "Artwork",
    description: "fieldNames.artwork",
  },
  assetTypes: {
    defaultMessage: "Asset types",
    description: "fieldNames.assetTypes",
  },
  assignmentNumber: {
    defaultMessage: "Assignment Number",
    description: "fieldNames.assignmentNumber",
  },
  autogenerate: {
    defaultMessage: "autogenerate",
    description: "fieldNames.autogenerate",
  },
  autoplay: { defaultMessage: "Autoplay", description: "fieldNames.autoplay" },
  awards: { defaultMessage: "Awards", description: "fieldNames.awards" },
  bars: { defaultMessage: "Bars", description: "fieldNames.bars" },
  bio: { defaultMessage: "Biography", description: "fieldNames.bio" },
  body: { defaultMessage: "Body", description: "fieldNames.body" },
  bookingPartner: {
    defaultMessage: "Booking Partner",
    description: "fieldNames.bookingPartner",
  },
  bookingUrl: {
    defaultMessage: "Booking URL",
    description: "fieldNames.bookingUrl",
  },
  brand: { defaultMessage: "Brand", description: "fieldNames.brand" },
  taxonomy: {
    defaultMessage: "Taxonomy",
    description: "fieldNames.taxonomy",
  },
  byline: {
    defaultMessage: "Byline",
    description: "fieldNames.byline",
  },
  bylineOption: {
    defaultMessage: "Advertiser Byline",
    description: "fieldNames.bylineOption",
  },
  bylineVariant: {
    defaultMessage: "Advertiser Byline Variation",
    description: "fieldNames.bylineVariant",
  },
  cabins: { defaultMessage: "Cabins", description: "fieldNames.cabins" },
  cabinsWithBalconies: {
    defaultMessage: "Cabins with Balconies",
    description: "fieldNames.cabinsWithBalconies",
  },
  cafes: { defaultMessage: "Cafes", description: "fieldNames.cafes" },
  callToAction: {
    defaultMessage: "Call to action",
    description: "fieldNames.callToAction",
  },
  callout: {
    defaultMessage: "Type of callout",
    description: "fieldNames.callout",
  },
  campaignUrl: {
    defaultMessage: "Campaign URL",
    description: "fieldNames.campaignUrl",
  },
  canonicalUrl: {
    defaultMessage: "Canonical URL",
    description: "fieldNames.canonicalUrl",
  },
  caption: { defaultMessage: "Caption", description: "fieldNames.caption" },
  categories: {
    defaultMessage: "Categories",
    description: "fieldNames.categories",
  },
  categoriesChannels: {
    defaultMessage: "Channels",
    description: "fieldNames.channelsChannels",
  },
  categoriesSections: {
    defaultMessage: "Sections",
    description: "fieldNames.categoriesSections",
  },
  categoriesTags: {
    defaultMessage: "Tags",
    description: "fieldNames.categoriesTags",
  },
  fashionTags: {
    defaultMessage: "Fashion Tags",
    description: "fieldNames.fashionTags",
  },
  categoriesOptions: {
    defaultMessage: "Sections",
    description: "fieldNames.categoriesOptions",
  },
  "content-warnings": {
    defaultMessage: "Content Warnings",
    description: "fieldNames.content-warnings",
  },
  cerosEmbed: {
    defaultMessage: "Ceros embed code snippet",
    description: "fieldNames.cerosEmbed",
  },
  channel: { defaultMessage: "Channel", description: "fieldNames.channel" },
  channels: { defaultMessage: "Channels", description: "fieldNames.channels" },
  clubs: { defaultMessage: "Clubs", description: "fieldNames.clubs" },
  collaborators: {
    defaultMessage: "Collaborators",
    description: "fieldNames.collaborators",
  },
  comparisonPrice: {
    defaultMessage: "Sale Price",
    description: "fieldNames.comparisonPrice",
  },
  composition: {
    defaultMessage: "Composition",
    description: "fieldNames.composition",
  },
  consumer: { defaultMessage: "Consumer", description: "fieldNames.consumer" },
  containers: {
    defaultMessage: "Containers",
    description: "fieldNames.containers",
  },
  contentSource: {
    defaultMessage: "Content Source",
    description: "fieldNames.contentSource",
  },
  "contentTypes.publish.uriMap": {
    defaultMessage: "URI map",
    description: "fieldNames.contentTypes.publish.uriMap",
  },
  contestSubmissionEndDate: {
    defaultMessage: "Caption Submission Deadline",
    description: "fieldNames.contestSubmissionEndDate",
  },
  "contest.announceFinalistsIssueDate": {
    defaultMessage: "Finalists Announced Issue Date",
    description: "fieldNames.contest.announceFinalistsIssueDate",
  },
  "contest.announceFinalistsDate": {
    defaultMessage: "Finalists Announced Date",
    description: "fieldNames.contest.announceFinalistDate",
  },
  "contest.announceWinnerDate": {
    defaultMessage: "Winner Announced Date",
    description: "fieldNames.contest.announceWinnerDate",
  },
  "livestory.startTime": {
    defaultMessage: "Start Time",
    description: "fieldNames.livestory.startTime",
  },
  "contest.submissionEndDate": {
    defaultMessage: "Caption Submission Deadline",
    description: "fieldNames.contest.submissionEndDate",
  },
  "contest.votingEndDate": {
    defaultMessage: "Voting Deadline",
    description: "fieldNames.contest.votingEndDate",
  },
  contributor: {
    defaultMessage: "Contributor",
    description: "fieldNames.contributor",
  },
  contributorsAuthor: {
    defaultMessage: "Author",
    description: "fieldNames.contributorsAuthor",
  },
  copyright: {
    defaultMessage: "Copyright",
    description: "fieldNames.copyright",
  },
  copyrightDisplay: {
    defaultMessage: "Copyright Display",
    description: "fieldNames.copyrightDisplay",
  },
  copyrightYear: {
    defaultMessage: "Copyright Year",
    description: "fieldNames.copyrightYear",
  },
  countryCode: {
    defaultMessage: "Locality",
    description: "fieldNames.countryCode",
  },
  courses: { defaultMessage: "Courses", description: "fieldNames.courses" },
  credit: { defaultMessage: "Credit", description: "fieldNames.credit" },
  cruiseLine: {
    defaultMessage: "Cruise Line",
    description: "fieldNames.cruiseLine",
  },
  cuisine: { defaultMessage: "Cuisine", description: "fieldNames.cuisine" },
  curationType: {
    defaultMessage: "Curation type",
    description: "fieldNames.curationType",
  },
  curationSubType: {
    defaultMessage: "Curation type",
    description: "fieldNames.curationSubType",
  },
  currency: { defaultMessage: "Currency", description: "fieldNames.currency" },
  ctaLabel: {
    defaultMessage: "CTA Label",
    description: "fieldNames.ctaLabel",
  },
  date: { defaultMessage: "Date", description: "fieldNames.date" },
  dateCreated: {
    defaultMessage: "Date Created",
    description: "fieldNames.dateCreated",
  },
  defaultTemplate: {
    defaultMessage: "Default template",
    description: "fieldNames.defaultTemplate",
  },
  defaultType: {
    defaultMessage: "Default type",
    description: "fieldNames.defaultType",
  },
  dek: { defaultMessage: "Introduction", description: "fieldNames.dek" },
  description: {
    defaultMessage: "Description",
    description: "fieldNames.description",
  },
  dimensions: {
    defaultMessage: "Dimensions",
    description: "fieldNames.dimensions",
  },
  disambiguatingDescription: {
    defaultMessage: "Disambiguation",
    description: "fieldNames.disambiguatingDescription",
  },
  discontinueDate: {
    defaultMessage: "Discontinue Date",
    description: "fieldNames.discontinueDate",
  },
  displayBrand: {
    defaultMessage: "Display on",
    description: "fieldNames.displayBrand",
  },
  displayName: {
    defaultMessage: "Display Name",
    description: "fieldNames.displayName",
  },
  durationInMs: {
    defaultMessage: "Audio Duration",
    description: "fieldNames.durationInMs",
  },
  eCommLink: {
    defaultMessage: "E-Commerce Link",
    description: "fieldNames.ecommLink",
  },
  email: { defaultMessage: "Email", description: "fieldNames.email" },
  emails: { defaultMessage: "Email", description: "fieldNames.email" },
  embedUrl: { defaultMessage: "Embed URL", description: "fieldNames.embedUrl" },
  endDate: { defaultMessage: "End Date", description: "fieldNames.endDate" },
  endTime: { defaultMessage: "End Time", description: "fieldNames.endTime" },
  eventDetails: {
    defaultMessage: "Event Details",
    description: "fieldNames.eventDetails",
  },
  excludeCategories: {
    defaultMessage: "Categories to Exclude",
    description: "fieldNames.excludeCategories",
  },

  excludeCategoriesChannels: {
    defaultMessage: "Channels to Exclude",
    description: "fieldNames.excludeCategoriesChannels",
  },

  excludeCategoriesTags: {
    defaultMessage: "Tags to Exclude",
    description: "fieldNames.excludeCategoriesTags",
  },

  exclusionLogic: {
    defaultMessage: "Exclusion Logic",
    description: "fieldNames.exclusionLogic",
  },
  expireDate: {
    defaultMessage: "Expire Date",
    description: "fieldNames.expireDate",
  },
  expireTime: {
    defaultMessage: "Expire Time",
    description: "fieldNames.expireTime",
  },
  eventRubric: {
    defaultMessage: "Event Rubric",
    description: "fieldNames.eventRubric",
  },
  eventStatus: {
    defaultMessage: "Event Status",
    description: "fieldNames.eventStatus",
  },
  desktop: {
    defaultMessage: "Desktop",
    description: "fieldNames.desktop",
  },
  mobile: {
    defaultMessage: "Mobile",
    description: "fieldNames.mobile",
  },
  familyName: {
    defaultMessage: "Last Name",
    description: "fieldNames.familyName",
  },
  featuredImage: {
    defaultMessage: "Site Image",
    description: "fieldNames.featuredImage",
  },
  features: {
    defaultMessage: "Features",
    description: "fieldNames.features",
  },
  fileName: { defaultMessage: "File Name", description: "fieldNames.fileName" },
  filterCategories: {
    defaultMessage: "Categories to Include",
    description: "fieldNames.filterCategories",
  },
  categoryTaxonomies: {
    defaultMessage: "Categories",
    description: "Copilot label",
  },
  includedCategoryTaxonomies: {
    defaultMessage: "Categories to Include",
    description: "Copilot label",
  },
  excludedCategoryTaxonomies: {
    defaultMessage: "Categories to exclude",
    description: "Copilot label",
  },
  filterCategoriesChannels: {
    defaultMessage: "Channels to Include",
    description: "fieldNames.filterCategoriesChannels",
  },
  filterCategoriesTags: {
    defaultMessage: "Tags to Include",
    description: "fieldNames.filterCategoriesTags",
  },
  firstName: {
    defaultMessage: "First Name",
    description: "fieldNames.firstName",
  },
  geo: { defaultMessage: "Latitude, Longitude", description: "fieldNames.geo" },
  "geo.lat": { defaultMessage: "Latitude", description: "fieldNames.geo.lat" },
  "geo.lng": { defaultMessage: "Longitude", description: "fieldNames.geo.lng" },
  givenName: {
    defaultMessage: "First Name",
    description: "fieldNames.givenName",
  },
  globalCaption: {
    defaultMessage: "Global caption",
    description: "fieldNames.globalCaption",
  },
  globalCredit: {
    defaultMessage: "Global credit",
    description: "fieldNames.globalCredit",
  },
  groupName: {
    defaultMessage: "Group Name",
    description: "fieldNames.groupName",
  },
  gtin: {
    defaultMessage: "Global Trade Identification Number",
    description: "fieldNames.gtin",
  },
  handle: { defaultMessage: "Handle", description: "fieldNames.handle" },
  hasNoSlug: {
    defaultMessage: "Has no slug",
    description: "fieldNames.hasNoSlug",
  },
  hedAndDek: {
    defaultMessage: "Title & Introduction",
    description: "fieldNames.hedAndDek",
  },
  hed: { defaultMessage: "Title", description: "fieldNames.hed" },
  height: { defaultMessage: "Height", description: "fieldNames.height" },
  honorificPrefix: {
    defaultMessage: "Prefix",
    description: "fieldNames.honorificPrefix",
  },
  honorificSuffix: {
    defaultMessage: "Suffix",
    description: "fieldNames.honorificSuffix",
  },
  "hostnames.consumer": {
    defaultMessage: "Consumer",
    description: "fieldNames.consumer",
  },
  "hostnames.preview": {
    defaultMessage: "Preview",
    description: "fieldNames.preview",
  },
  hours: { defaultMessage: "Hours", description: "fieldNames.hours" },
  image: { defaultMessage: "Image", description: "fieldNames.image" },
  isReleaseOf: {
    defaultMessage: "Is Release Of",
    description: "fieldNames.isReleaseOf",
  },
  excludeFromRecirc: {
    defaultMessage: "Recirculation",
  },
  excludeFromSitemaps: {
    defaultMessage: "Sitemaps, rivers, site search",
  },
  imageAspectRatio: {
    defaultMessage: "Image Aspect Ratio",
    description: "fieldNames.imageAspectRatio",
  },
  byArtist: {
    defaultMessage: "By Artist",
    description: "fieldNames.byArtist",
  },
  inclusionLogic: {
    defaultMessage: "Inclusion logic",
    description: "fieldNames.inclusionLogic",
  },
  draft: { defaultMessage: "Draft", description: "fieldNames.draft" },
  draftEnabled: {
    defaultMessage: "Draft Enabled",
    description: "fieldNames.draftEnabled",
  },
  "curatedSearchQuery.filters.language": {
    defaultMessage: "Languages",
    description: "fieldNames.curatedSearchQuery.filters.language",
  },
  language: {
    defaultMessage: "Languages",
    description: "fieldNames.languages",
  },
  running: { defaultMessage: "Running", description: "fieldNames.running" },
  terminated: {
    defaultMessage: "Terminated",
    description: "fieldNames.terminated",
  },
  variantAWinner: {
    defaultMessage: "Variant A Winner",
    description: "fieldNames.variantAWinner",
  },
  variantBWinner: {
    defaultMessage: "Variant B Winner",
    description: "fieldNames.variantBWinner",
  },
  variantACurrentlyLive: {
    defaultMessage: "Variant A Currently Live",
    description: "fieldNames.variantACurrentlyLive",
  },

  variantBCurrentlyLive: {
    defaultMessage: "Variant B Currently Live",
    description: "fieldNames.variantBCurrentlyLive",
  },
  maxNumExperiments: {
    defaultMessage: "Max Number of Experiments Running",
    description: "fieldNames.maxNumExperiments",
  },
  unpublishedWinnerMatchesDraftRevision: {
    defaultMessage: "Winner Matches Draft Revision",
    description: "fieldNames.unpublishedWinnerMatchesDraftRevision",
  },
  ingredient: {
    defaultMessage: "Ingredient",
    description: "fieldNames.ingredient",
  },
  "ingredientGroups.ingredientGroup.ingredients": {
    defaultMessage: "Ingredients",
    description: "fieldNames.ingredientGroups.ingredientGroup.ingredients",
  },
  "ingredientGroups.ingredientGroup.ingredients.ingredient.description": {
    defaultMessage: "Ingredient descriptions",
    description:
      "fieldNames.ingredientGroups.ingredientGroup.ingredients.ingredient.description",
  },
  "ingredientGroups.ingredientGroup.ingredients.ingredient.name": {
    defaultMessage: "Ingredient names",
    description:
      "fieldNames.ingredientGroups.ingredientGroup.ingredients.ingredient.name",
  },
  isNative: { defaultMessage: "Native", description: "fieldNames.isNative" },
  "preparationGroups.preparationGroup.steps": {
    defaultMessage: "Instructions",
    description: "fieldNames.preparationGroups.preparationGroup.steps",
  },
  "preparationGroups.preparationGroup.step.description": {
    defaultMessage: "Instruction descriptions",
    description:
      "fieldNames.preparationGroups.preparationGroup.step.description",
  },
  ingredientGroups: {
    defaultMessage: "Ingredient Groups",
    description: "fieldNames.ingredientGroups",
  },
  preparationGroups: {
    defaultMessage: "Preparation Groups",
    description: "fieldNames.preparationGroups",
  },
  provider: { defaultMessage: "Provider", description: "fieldNames.provider" },
  ingredientDescription: {
    defaultMessage: "Ingredient Description",
    description: "fieldNames.ingredientDescription",
  },
  ingredientSize: {
    defaultMessage: "Ingredient Size",
    description: "fieldNames.ingredientSize",
  },
  ingredients: {
    defaultMessage: "Ingredients",
    description: "fieldNames.ingredients",
  },
  instruction: {
    defaultMessage: "Instruction",
    description: "fieldNames.instruction",
  },
  interactiveOverride: {
    defaultMessage: "Interactive Override URL",
    description: "fieldNames.interactiveOverride",
  },
  interludeOverride: {
    defaultMessage: "Related Video",
    description: "fieldNames.interludeOverride",
  },
  relatedStories: {
    defaultMessage: "Related Content",
    description: "fieldNames.relatedStories",
  },
  relatedVideo: {
    defaultMessage: "Related Video",
    description: "fieldNames.relatedVideo",
  },
  isCompilation: {
    defaultMessage: "Compilation",
    description: "fieldNames.isCompilation",
  },
  issueDate: {
    defaultMessage: "Issue Date",
    description: "fieldNames.issueDate",
  },
  issueMonth: {
    defaultMessage: "Issue Month",
    description: "fieldNames.issueMonth",
  },
  issueYear: {
    defaultMessage: "Issue Year",
    description: "fieldNames.issueYear",
  },
  itemLimit: {
    defaultMessage: "Number of results",
    description: "fieldNames.itemLimit",
  },
  itemReviewed: {
    defaultMessage: "Item reviewed",
    description: "fieldNames.itemReviewed",
  },
  items: { defaultMessage: "Items", description: "fieldNames.items" },
  itemsReviewed: {
    defaultMessage: "Items reviewed",
    description: "fieldNames.itemsReviewed",
  },
  imageSocial: {
    defaultMessage: "Social Image",
    description: "fieldNames.imageSocial",
  },
  label: { defaultMessage: "Label", description: "fieldNames.label" },
  languages: {
    defaultMessage: "Languages",
    description: "fieldNames.languages",
  },
  lastName: { defaultMessage: "Last Name", description: "fieldNames.lastName" },
  lastReviewed: {
    defaultMessage: "Last Reviewed",
    description: "fieldNames.lastReviewed",
  },
  layout: { defaultMessage: "Layout", description: "fieldNames.layout" },
  layoutOptions: {
    defaultMessage: "Layout Options",
    description: "fieldNames.layoutOptions",
  },
  ledeCaption: {
    defaultMessage: "Story Image Caption",
    description: "fieldNames.ledeCaption",
  },
  legacyId: { defaultMessage: "Legacy ID", description: "fieldNames.legacyId" },
  linkList: { defaultMessage: "Link List", description: "fieldNames.linkList" },
  linkText: { defaultMessage: "Link text", description: "fieldNames.linkText" },
  linkUrl: {
    defaultMessage: "Please add the link URL",
    description: "fieldNames.linkUrl",
  },
  locale: {
    defaultMessage: "System language",
    description: "fieldNames.locale",
  },
  lodgingOffers: {
    defaultMessage: "Offers",
    description: "fieldNames.lodgingOffers",
  },
  logo: { defaultMessage: "Advertiser logo", description: "fieldNames.logo" },
  loginProvider: {
    defaultMessage: "Enable Okta",
    description: "fieldNames.loginProvider",
  },
  longDek: {
    defaultMessage: "Focus Module Dek",
    description: "fieldNames.longDek",
  },
  memberSharing: {
    defaultMessage: "Member Sharing",
    description: "fieldNames.memberSharing",
  },
  "metadata.projectStatus": {
    defaultMessage: "Project Status",
    description: "fieldNames.metadat.projectStatus",
  },

  "metadata.projectStatus.id": {
    defaultMessage: "Project status",
    description: "fieldNames.metadata.projectStatus.id",
  },
  "metadata.publishData.searchable": {
    defaultMessage: "Searchable",
    description: "fieldNames.metadata.publishData.searchable",
  },
  "metadata.publishData.template": {
    defaultMessage: "Template",
    description: "fieldNames.metadata.publishData.template",
  },
  "metadata.publishData.uri": {
    defaultMessage: "Publish URL",
    description: "fieldNames.metadata.publishData.uri",
  },
  mpn: {
    defaultMessage: "Manufacturer Part Number",
    description: "fieldNames.mpn",
  },
  musicGroupImage: {
    defaultMessage: "Music Group Image",
    description: "fieldNames.musicGroupImage",
  },
  muted: { defaultMessage: "Muted", description: "fieldNames.muted" },
  name: { defaultMessage: "Name", description: "fieldNames.name" },
  network: { defaultMessage: "Network", description: "fieldNames.network" },
  newPassword: {
    defaultMessage: "New password",
    description: "fieldNames.newPassword",
  },
  noFollow: {
    defaultMessage: "Mark link as nofollow",
    description: "fieldNames.noFollow",
  },
  notCategoryIds: {
    defaultMessage: "Categories to exclude",
    description: "fieldNames.notCategoryIds",
  },
  note: { defaultMessage: "Note", description: "fieldNames.note" },
  noteDescription: {
    defaultMessage: "Note description",
    description: "fieldNames.noteDescription",
  },
  noteTitle: {
    defaultMessage: "Note title",
    description: "fieldNames.noteTitle",
  },
  notes: { defaultMessage: "Internal notes", description: "fieldNames.notes" },
  nutritionalInfo: {
    defaultMessage: "Nutritional Info",
    description: "fieldNames.nutritionalInfo",
  },
  notRecommendable: {
    defaultMessage: "Exclude from recirculation",
    description: "fieldNames.notRecommendable",
  },
  notSearchable: {
    defaultMessage: "Exclude from RSS feeds, sitemaps, rivers, and site search",
    description: "fieldNames.notSearchable",
  },
  numberOfLifts: {
    defaultMessage: "Number of Lifts",
    description: "fieldNames.numberOfLifts",
  },
  numberOfRuns: {
    defaultMessage: "Number of Runs",
    description: "fieldNames.numberOfRuns",
  },
  oemLink: {
    defaultMessage: "Manufacturer URL",
    description: "fieldNames.oemLink",
  },
  offerLink: {
    defaultMessage: "Offer URL",
    description: "fieldNames.offerLink",
  },
  offerType: {
    defaultMessage: "Offer type",
    description: "fieldNames.offerType",
  },
  offers: { defaultMessage: "Offers", description: "fieldNames.offers" },
  "offers.seller.name": {
    defaultMessage: "Booking Partner",
    description: "fieldNames.offers.seller.name",
  },
  "offers.sellerName": {
    defaultMessage: "Retailer Name",
    description: "fieldNames.offers.sellerName",
  },
  "offers.purchaseUri": {
    defaultMessage: "Offer purchase URIs",
    description: "fieldNames.offers.purchaseUri",
  },
  "offers.offerLink": {
    defaultMessage: "Offer URL",
    description: "fieldNames.offers.offerLink",
  },
  "offers.offerType": {
    defaultMessage: "Offer type",
    description: "fieldNames.offers.offerType",
  },
  oldPassword: {
    defaultMessage: "Old password",
    description: "fieldNames.oldPassword",
  },
  organization: {
    defaultMessage: "Organization",
    description: "fieldNames.organization",
  },
  "componentConfigs.SummaryItem.settings.hideRubricItemSummary": {
    defaultMessage: "Hide rubric",
    description:
      "fieldNames.componentConfigs.SummaryItem.settings.hideRubricItemSummary",
  },
  "componentConfigs.SummaryItem.settings.shouldHideContributors": {
    defaultMessage: "Hide byline",
    description:
      "fieldNames.componentConfigs.SummaryItem.settings.shouldHideContributors",
  },
  "componentConfigs.SummaryItem.settings.shouldHideDangerousDek": {
    defaultMessage: "Hide dek",
    description:
      "fieldNames.componentConfigs.SummaryItem.settings.shouldHideDangerousDek",
  },
  "componentConfigs.SummaryItem.settings.shouldHidePublishDate": {
    defaultMessage: "Hide publish date",
    description:
      "fieldNames.componentConfigs.SummaryItem.settings.shouldHidePublishDate",
  },
  "componentConfigs.SmartItem.settings.content.showRubric": {
    defaultMessage: "Rubric",
    description:
      "fieldNames.componentConfigs.SmartItem.settings.content.showRubric",
  },
  "componentConfigs.SmartItem.settings.content.showDek": {
    defaultMessage: "Dek",
    description:
      "fieldNames.componentConfigs.SmartItem.settings.content.showDek",
  },
  "componentConfigs.SmartItem.settings.content.showBylines": {
    defaultMessage: "Byline",
    description:
      "fieldNames.componentConfigs.SmartItem.settings.content.showBylines",
  },
  "componentConfigs.SmartItem.settings.content.showPublishDate": {
    defaultMessage: "Publish Date",
    description:
      "fieldNames.componentConfigs.SmartItem.settings.content.showPublishDate",
  },
  "componentConfigs.SmartItem.settings.textPosition": {
    defaultMessage: "Text Position",
    description: "fieldNames.componentConfigs.SmartItem.settings.textPosition",
  },
  textAlign: {
    defaultMessage: "Text Position",
    description: "fieldNames.textAlign",
  },
  textPosition: {
    defaultMessage: "Text Position",
    description: "fieldNames.textPosition",
  },
  passengers: {
    defaultMessage: "Passengers",
    description: "fieldNames.passengers",
  },
  password: { defaultMessage: "Password", description: "fieldNames.password" },
  people: { defaultMessage: "People", description: "fieldNames.people" },
  phone: { defaultMessage: "Phone Number", description: "fieldNames.phone" },
  phoneNumbers: {
    defaultMessage: "Phone Number",
    description: "fieldNames.phone",
  },
  photosLede: {
    defaultMessage: "Story Image",
    description: "fieldNames.photosLede",
  },
  photosLogo: {
    defaultMessage: "Advertiser logo",
    description: "fieldNames.photosLogo",
  },
  photosSocial: {
    defaultMessage: "Social Image",
    description: "fieldNames.photosSocial",
  },
  photosTout: {
    defaultMessage: "Site Image",
    description: "fieldNames.photosTout",
  },
  touts: {
    defaultMessage: "Tout Story",
    description: "fieldNames.touts",
  },
  year: {
    defaultMessage: "Year",
    description: "fieldNames.year",
  },
  paymentsAccepted: {
    defaultMessage: "Payments Accepted",
    descroption: "fieldNames.paymentsAccepted",
  },
  playerComments: {
    defaultMessage: "Player Comments",
    description: "fieldNames.playerComments",
  },
  preference: {
    defaultMessage: "Preference",
    description: "fieldNames.preference",
  },
  preset: {
    defaultMessage: "Select a template",
    description: "fieldNames.preset",
  },
  preview: { defaultMessage: "Preview", description: "fieldNames.preview" },
  "preview.mountPoint": {
    defaultMessage: "Preview Mount Point",
    description: "fieldNames.preview.mountPoint",
  },

  "preview.urlPrefix": {
    defaultMessage: "Preview URL prefix",
    description: "fieldNames.urlPrefix",
  },
  price: { defaultMessage: "Price", description: "fieldNames.price" },
  printHed: {
    defaultMessage: "Print Headline",
    description: "fieldNames.printHed",
  },
  printDek: {
    defaultMessage: "Print Dek",
    description: "fieldNames.printDek",
  },
  productType: {
    defaultMessage: "Product Type",
    description: "fieldNames.productType",
  },
  promoAssetA: {
    defaultMessage: "Site Image A",
    description: "fieldNames.promoAssetA",
  },
  promoAssetB: {
    defaultMessage: "Site Image B",
    description: "fieldNames.promoAssetB",
  },
  promoDek: {
    defaultMessage: "Site Introduction",
    description: "fieldNames.promoDek",
  },
  promoDekA: {
    defaultMessage: "Site introduction A",
    description: "fieldNames.promoDekA",
  },
  promoDekB: {
    defaultMessage: "Site introduction B",
    description: "fieldNames.promoDekB",
  },
  promoHed: {
    defaultMessage: "Site Title",
    description: "fieldNames.promoHed",
  },
  promoHedA: {
    defaultMessage: "Site Title A",
    description: "fieldNames.promoHedA",
  },
  promoHedB: {
    defaultMessage: "Site Title B",
    description: "fieldNames.promoHedB",
  },
  pros: {
    defaultMessage: "Pros",
    description: "fieldNames.Pros",
  },
  cons: {
    defaultMessage: "Cons",
    description: "fieldNames.cons",
  },
  productPros: {
    defaultMessage: "Pros",
    description: "fieldNames.pros",
  },
  productCons: {
    defaultMessage: "Cons",
    description: "fieldNames.cons",
  },
  rating: {
    defaultMessage: "Rating",
    description: "fieldNames.rating",
  },
  "publish.uriMap": {
    defaultMessage: "Publish URL map",
    description: "fieldNames.publish.uriMap",
  },
  "publish.urlPrefix": {
    defaultMessage: "Publish URL prefix",
    description: "fieldNames.publish.urlPrefix",
  },
  publishDate: {
    defaultMessage: "Publish Date",
    description: "fieldNames.publishData",
  },
  publisher: {
    defaultMessage: "Publisher",
    description: "fieldNames.publisher",
  },
  publisherDisplay: {
    defaultMessage: "Publisher",
    description: "fieldNames.publisherDisplay",
  },
  publishTime: {
    defaultMessage: "Publish Time",
    description: "fieldNames.publishTime",
  },
  pullQuotes: {
    defaultMessage: "Pull Quotes",
    description: "fieldNames.pullQuotes",
  },
  purchaseUri: {
    defaultMessage: "Booking URL",
    description: "fieldNames.purchaseUri",
  },
  secondaryType: {
    defaultMessage: "Secondary Type",
    description: "fieldNames.secondaryType",
  },
  seoTitle: {
    defaultMessage: "SEO Title",
    description: "fieldNames.seoTitle",
  },
  seoDescription: {
    defaultMessage: "SEO Description",
    description: "fieldNames.seoDescription",
  },
  showDeactivatedUsers: {
    defaultMessage: "Show deactivated users",
    description: "fieldNames.showDeactivatedUsers",
  },
  shortLink: {
    defaultMessage: "Affiliate URL",
    description: "fieldNames.shortLink",
  },
  shortUrl: {
    defaultMessage: "Affiliate URL",
    description: "fieldNames.shortUrl",
  },
  query: { defaultMessage: "Search Terms", description: "fieldNames." },
  "query.filters.categoryIds": {
    defaultMessage: "Filter category",
    description: "fieldNames.query.filters.categoryIds",
  },
  "query.filters.channel": {
    defaultMessage: "Channels",
    description: "fieldNames.query.filters.channel",
  },
  "query.filters.contentSource": {
    defaultMessage: "Content Source",
    description: "fieldNames.query.filters.contentSource",
  },
  "curatedSearchQuery.filters.contentSource": {
    defaultMessage: "Content source",
    description: "fieldNames.curatedSearchQuery.filters.contentSource",
  },
  "query.filters.contributor": {
    defaultMessage: "Contributor",
    description: "fieldNames.query.filters.contributor",
  },
  "curatedSearchQuery.filters.contributor": {
    defaultMessage: "Contributor",
    description: "fieldNames.curatedSearchQuery.filters.contributor",
  },
  "query.filters.issueDate": {
    defaultMessage: "Issue Date",
    description: "fieldNames.query.filters.issueDate",
  },
  "curatedSearchQuery.filters.issueDate": {
    defaultMessage: "Issue Date",
    description: "fieldNames.curatedSearchQuery.filters.issueDate",
  },
  "query.filters.nottags": {
    defaultMessage: "Tags to exclude",
    description: "fieldNames.query.filters.nottags",
  },
  "query.filters.producttype": {
    defaultMessage: "Club Type",
    description: "fieldNames.query.filters.producttype",
  },
  "query.filters.subchannel": {
    defaultMessage: "Subchannels",
    description: "fieldNames.query.filters.subchannel",
  },
  "query.filters.tags": {
    defaultMessage: "Filter tag",
    description: "fieldNames.query.filters.tags",
  },
  "query.filters.types": {
    defaultMessage: "Filter By Content",
    description: "fieldNames.query.filters.types",
  },
  "curatedSearchQuery.filters.types": {
    defaultMessage: "Content Types to Include",
    description: "fieldNames.curatedSearchQuery.filters.types",
  },
  "query.q": {
    defaultMessage: "Search terms",
    description: "fieldNames.query.q",
  },
  "curatedSearchQuery.q": {
    defaultMessage: "Search terms",
    description: "fieldNames.curatedSearchQuery.q",
  },
  "query.size": {
    defaultMessage: "Number of results",
    description: "fieldNames.query.size",
  },
  "curatedSearchQuery.size": {
    defaultMessage: "Number of results",
    description: "fieldNames.curatedSearchQuery.size",
  },
  "curatedSearchQuery.categoriesFilterOperator": {
    defaultMessage: "Inclusion Logic",
    description: "Inclusion Logic",
  },
  "curatedSearchQuery.notCategoriesFilterOperator": {
    defaultMessage: "Exclusion Logic",
    description: "Exclusion Logic",
  },
  quickDate: {
    defaultMessage: "Quick date",
    description: "fieldNames.quickDate",
  },
  "ratingBlock.overall": {
    defaultMessage: "Overall",
    description: "fieldNames.ratingBlock.overall",
  },
  recipeType: {
    defaultMessage: "Recipe Type",
    description: "fieldNames.recipeType",
  },
  recommendable: {
    defaultMessage: "Include in recirculation",
    description: "fieldNames.recommendable",
  },
  region: { defaultMessage: "Region", description: "fieldNames.region" },
  related: {
    defaultMessage: "Related Stories",
    description: "fieldNames.related",
  },
  releaseDate: {
    defaultMessage: "Release Date",
    description: "fieldNames.releaseDate",
  },
  reservationLink: {
    defaultMessage: "Reservation Link",
    description: "fieldsNames.reservationLink",
  },
  reservations: {
    defaultMessage: "Reservations",
    description: "fieldNames.reservations",
  },
  restaurant: {
    defaultMessage: "Restaurant",
    description: "fieldNames.restaurant",
  },
  restaurants: {
    defaultMessage: "Restaurants",
    description: "fieldNames.restaurants",
  },
  restrictCropping: {
    defaultMessage: "Do not crop",
    description: "fieldNames.restrictCropping",
  },
  rightsUsage: {
    defaultMessage: "Rights Usage",
    description: "fieldNames.rightsUsage",
  },
  role: { defaultMessage: "Role", description: "fieldNames.role" },
  roles: { defaultMessage: "Roles", description: "fieldNames.person.roles" },
  rooms: { defaultMessage: "Rooms", description: "fieldNames.rooms" },
  rubric: { defaultMessage: "Kicker", description: "fieldNames.rubric" },
  statusCode: {
    defaultMessage: "Response Code",
    description: "fieldNames.statusCode",
  },
  "search.excludeCategories": {
    defaultMessage: "Categories to exclude",
    description: "fieldNames.search.excludeCategories",
  },

  "search.excludeCategoriesChannels": {
    defaultMessage: "Channels to Exclude",
    description: "fieldNames.search.excludeCategoriesChannel",
  },
  "search.excludeCategoriesTags": {
    defaultMessage: "Tags to exclude",
    description: "fieldNames.search.excludeCategoriesTags",
  },
  "search.filterCategories": {
    defaultMessage: "Categories to Include",
    description: "fieldNames.search.filterCategorie",
  },
  "search.filterCategoriesChannels": {
    defaultMessage: "Channels to Include",
    description: "fieldNames.search.filterCategoriesChannels",
  },
  startDate: {
    defaultMessage: "Start Date",
    description: "fieldNames.startDate",
  },
  "search.filterCategoriesTags": {
    defaultMessage: "Tags to include",
    description: "fieldNames.search.filterCategoriesTags",
  },
  "search.filters.categoryIds": {
    defaultMessage: "Filter category",
    description: "fieldNames.search.filters.categoryIds",
  },
  "search.notCategoryIds": {
    defaultMessage: "Categories to exclude",
    description: "fieldNames.search.notCategoryIds",
  },
  "search.q": { defaultMessage: "Search terms", description: "fieldNames." },
  searchable: {
    defaultMessage: "Include in search",
    description: "fieldNames.search.q",
  },
  "seller.name": {
    defaultMessage: "Booking Partner",
    description: "fieldNames.seller.name",
  },
  sellerName: {
    defaultMessage: "Retailer Name",
    description: "fieldNames.sellerName",
  },
  "seo.category": {
    defaultMessage: "SEO Category",
    description: "fieldNames.seo.category",
  },
  "seo.description": {
    defaultMessage: "SEO description",
    description: "fieldNames.seo.description",
  },
  "seo.keyword": {
    defaultMessage: "Search phrase",
    description: "fieldNames.seo.keyboard",
  },
  "seo.title": {
    defaultMessage: "SEO title",
    description: "fieldNames.seo.title",
  },
  service: { defaultMessage: "Service", description: "fieldNames.service" },
  shortDescription: {
    defaultMessage: "Short Description",
    description: "fieldNames.shortDescription",
  },
  shortBio: {
    defaultMessage: "Short Bio",
    description: "fieldNames.shortBio",
  },
  size: { defaultMessage: "Number of Results", description: "fieldNames.size" },
  slideCaption: {
    defaultMessage: "Slide caption",
    description: "fieldNames.slideCaption",
  },
  slideTitle: {
    defaultMessage: "Slide title",
    description: "fieldNames.slideTitle",
  },
  slug: { defaultMessage: "Slug", description: "fieldNames.slug" },
  slugAddon: {
    defaultMessage: "Slug add-on",
    description: "fieldNames.slugAddon",
  },
  smallestCabin: {
    defaultMessage: "Smallest Cabin",
    description: "fieldNames.smallestCabin",
  },
  socialDescription: {
    defaultMessage: "Social Description",
    description: "fieldNames.socialDescription",
  },
  socialMedia: {
    defaultMessage: "{count, plural, =1 {Social medium} other {Social media}}",
    description: "fieldNames.socialMedia",
  },
  subtype: {
    defaultMessage: "Sub Type",
    description: "fieldNames.subtype",
  },
  subType: {
    defaultMessage: "Sub Type",
    description: "fieldNames.subType",
  },
  swimmingPools: {
    defaultMessage: "Swimming Pools",
    description: "fieldNames.swimmingPools",
  },
  featuredBio: {
    defaultMessage: "Featured Bio",
    description: "fieldNames.featuredBio",
  },
  featuredImg: {
    defaultMessage: "Featured Image",
    description: "fieldNames.featuredImg",
  },
  featuredStories: {
    defaultMessage: "Featured Stories",
    description: "fieldNames.featuredStories",
  },
  relatedContent: {
    defaultMessage: "Related Content",
    description: "fieldNames.relatedContent",
  },
  socialTitle: {
    defaultMessage: "Social Title",
    description: "fieldNames.socialTitle",
  },
  softSlug: {
    defaultMessage: "Slug (slashes allowed)",
    description: "fieldNames.softSlug",
  },
  source: { defaultMessage: "Source", description: "fieldNames.source" },
  sponsorByline: {
    defaultMessage: "Sponsor Byline",
    description: "fieldNames.sponsorByline",
  },
  startTime: {
    defaultMessage: "Start Time",
    description: "fieldNames.startTime",
  },
  liveStartDate: {
    defaultMessage: "Start Date",
    description: "fieldNames.liveStartDate",
  },
  liveEndDate: {
    defaultMessage: "End Date",
    description: "fieldNames.liveEndDate",
  },
  step: { defaultMessage: "Step", description: "fieldNames.step" },
  subChannel: {
    defaultMessage: "Subchannel",
    description: "fieldNames.subChannel",
  },
  subChannels: {
    defaultMessage: "Subchannels",
    description: "fieldNames.subChannels",
  },
  subject: { defaultMessage: "Subject", description: "fieldNames.subject" },
  summary: { defaultMessage: "Summary", description: "fieldNames.summary" },
  systemLanguage: {
    defaultMessage: "System language",
    description: "fieldNames.systemLanguage",
  },
  tags: { defaultMessage: "Tags", description: "fieldNames.tags" },
  workflowtags: {
    defaultMessage: "Workflow Tags",
    description: "fieldNames.workflowtags",
  },
  target: {
    defaultMessage: "Target (Destination)",
    description: "fieldNames.target",
  },
  "temperatures.cookingTemp": {
    defaultMessage: "Cooking Temp (F)",
    description: "fieldNames.temperature.cookingTemp",
  },
  "temperatures.preheatTemp": {
    defaultMessage: "Preheat Temp (F)",
    description: "fieldNames.temperature.preheatTemp",
  },
  template: { defaultMessage: "Template", description: "fieldNames.template" },
  templates: {
    defaultMessage: "Templates",
    description: "fieldNames.templates",
  },
  thirdPartyMedia: {
    defaultMessage: "Third Party Media",
    description: "fieldNames.thirdPartyMedia",
  },
  outlet: {
    defaultMessage: "Outlet",
    description: "fieldNames.outlet",
  },
  id: {
    defaultMessage: "ID",
    description: "fieldNames.id",
  },
  ticketUrl: {
    defaultMessage: "Ticket URL",
    description: "fieldNames.ticketUrl",
  },
  time: { defaultMessage: "Time", description: "fieldNames.time" },
  title: { defaultMessage: "Title", description: "fieldNames.title" },
  timeZone: { defaultMessage: "Zone", description: "fieldNames.timeZone" },
  translationsHighlighted: {
    defaultMessage: "Highlight translations",
    description: "fieldNames.translationsHighlighted",
  },
  agencies: {
    defaultMessage: "Agencies",
    description: "fieldNames.agencies",
  },
  transcriptUrl: {
    defaultMessage: "Transcript Url",
    description: "fieldNames.transcriptUrl",
  },
  treatment: {
    defaultMessage: "Treatment",
    description: "fieldNames.treatment",
  },
  type: { defaultMessage: "Type", description: "fieldNames.type" },
  types: { defaultMessage: "Types", description: "fieldNames.types" },
  tzdata: { defaultMessage: "Time zone", description: "fieldNames.tzdata" },
  unit: { defaultMessage: "Unit", description: "fieldNames.unit" },
  unitQuantity: {
    defaultMessage: "Quantity",
    description: "fieldNames.unitQuantity",
  },
  unitSize: { defaultMessage: "Unit Size", description: "fieldNames.unitSize" },
  url: { defaultMessage: "URL", description: "fieldNames.url" },
  urls: { defaultMessage: "URL", description: "fieldNames.url" },
  uri: { defaultMessage: "Publish URL", description: "fieldNames.uri" },
  userName: { defaultMessage: "Username", description: "fieldNames.userName" },
  value: { defaultMessage: "Value", description: "fieldNames.value" },
  verifyPassword: {
    defaultMessage: "Verify password",
    description: "fieldNames.verifyPassword",
  },
  videosLede: {
    defaultMessage: "Story Video",
    description: "fieldNames.videosLede",
  },
  votingEndDate: {
    defaultMessage: "Voting Deadline",
    description: "fieldNames.votingEndDate",
  },
  width: { defaultMessage: "Width", description: "fieldNames.width" },
  willMakeAgainPct: {
    defaultMessage: "Make it again (%)",
    description: "fieldNames.willMakeAgainPct",
  },
  workflowTags: {
    defaultMessage: "Workflow Tags",
    description: "fieldNames.workflowTags",
  },
  zone: { defaultMessage: "Zone", description: "fieldNames.zone" },
  "query.filters.categories": {
    defaultMessage: "Tags",
    description: "fieldNames.query.filters.categories",
  },
  prepNotesHed: { defaultMessage: "Note Title", description: "prepNotesHed" },
  prepNotes: { defaultMessage: "Note Description", description: "prepNotes" },
  displayTime: { defaultMessage: "Display" },
  prepTime: { defaultMessage: "Prep" },
  activeTime: { defaultMessage: "Active" },
  totalTime: { defaultMessage: "Total" },
  servingSizeRangeFrom: { defaultMessage: "From" },
  servingSizeRangeTo: { defaultMessage: "To" },
  servingSizeDescription: { defaultMessage: "Description" },
  servingSizeUnit: { defaultMessage: "Unit" },
  products: { defaultMessage: "Products" },
  venue: { defaultMessage: "Venue" },
  preheatTemp: { defaultMessage: "Preheat Temp (F)" },
  cookingTemp: { defaultMessage: "Cooking Temp (F)" },
  cookbooks: { defaultMessage: "Cookbooks" },
  rotdDate: { defaultMessage: "Recipe of the Day", description: "rotdDate" },
  photosBadge: {
    defaultMessage: "Badge",
    description: "photosBadge",
  },
  ingredientProducts: {
    defaultMessage: "Ingredient Products",
    description: "ingredientProducts",
  },
  videosTout: {
    defaultMessage: "Promo Video",
    description: "videosTout",
  },
  linkBanner: { defaultMessage: "Link Banner", description: "linkBanner" },
  socialShortUrl: {
    defaultMessage: "Short URL",
    description: "socialShortUrl",
  },
  relatedAudio: {
    defaultMessage: "Related Audio",
    description: "relatedAudio",
  },
  categoriesBrandmag: {
    defaultMessage: "Brandmag",
    descriptipon: "brandmag",
  },
  customAdvertisementZone: {
    defaultMessage: "Advertisement Custom Zone",
    descriptipon: "customAdvertisementZone",
  },
  categoriesOccasion: {
    defaultMessage: "Occassion",
    descriptipon: "occasion",
  },
  categoriesSeason: {
    defaultMessage: "Season",
    descriptipon: "season",
  },
  categoriesMeal: {
    defaultMessage: "Meal Type",
    descriptipon: "meal",
  },
  categoriesDishes: {
    defaultMessage: "Dishes",
    descriptipon: "dishes",
  },
  categoriesRegion: {
    defaultMessage: "Region",
    descriptipon: "region",
  },
  categoriesIngredient: {
    defaultMessage: "Ingredient",
    descriptipon: "ingredient",
  },
  categoriesLabel: {
    defaultMessage: "Label",
    descriptipon: "label",
  },
  categoriesType: {
    defaultMessage: "Type",
    descriptipon: "type",
  },
  categoriesLayout: {
    defaultMessage: "Layout",
    descriptipon: "layour",
  },
  showInHomepage: {
    defaultMessage: "Show in homepage",
    descriptipon: "showInHomepage",
  },
  showInArchive: {
    defaultMessage: "Show in archive",
    descriptipon: "showInArchive",
  },
  showInRelated: {
    defaultMessage: "Show in related",
    descriptipon: "showInRelated",
  },
  gender: {
    defaultMessage: "Gender",
    descriptipon: "Gender",
  },
  code: {
    defaultMessage: "Code",
    description: "fieldNames.code",
  },
  tdzone: {
    defaultMessage: "Time Zone",
    description: "fieldNames.timeZone",
  },
  legalName: {
    defaultMessage: "Business Name",
    description: "legalName",
  },
  editorialQuote: {
    defaultMessage: "Quote",
    description: "editorialQuote.quote",
  },
  disableEditorialQuote: {
    defaultMessage: "Disable Editorial Quote",
    description: "editorialQuote.disableEditorialQuote",
  },
  knowsAbout: {
    defaultMessage: "Short Bio/Profile Tagline",
    description: "knowsAbout",
  },
  "contactPoint.email": {
    defaultMessage: "Email",
    description: "contactPoint.email",
  },
  "contactPoint.telephone": {
    defaultMessage: "Phone Number",
    description: "contactPoint.telephone",
  },
  "contactPoint.url": {
    defaultMessage: "Website",
    description: "contactPoint.url",
  },
  hasDataConsent: {
    defaultMessage:
      "I have read and accept the User Terms (https://www.condenast.com/user-agreement/) and confirm that the content and images submitted as part of my application belong to me and do not and will not infringe, misappropriate or otherwise violate any third party rights, including without limitation, music, talent, logos, trademarks, copyright, or any other third party intellectual property rights. I understand and agree that Conde Nast will process my personal data in accordance with its Privacy Policy (https://www.condenast.com/privacy-policy).",
    description: "hasDataConsent",
  },
  published: {
    defaultMessage: "Published",
  },
  categoriesFilterOperator: { defaultMessage: "Inclusion Logic" },
  notCategoriesFilterOperator: { defaultMessage: "Exclusion Logic" },
  scheduled: { defaultMessage: "Scheduled" },
  all: { defaultMessage: "All" },
  archived: { defaultMessage: "Hidden" },
  issyndicatee: { defaultMessage: "Syndicated" },
  relevanceLatest: { defaultMessage: "Relevance (Latest)" },
  relevanceAllTime: { defaultMessage: "Relevance (All Time)" },
  lastModified: { defaultMessage: "Last Modified" },
  lastPublished: { defaultMessage: "Last Published" },
  lastCreated: { defaultMessage: "Last Created" },
  continuousPlay: { defaultMessage: "Continuous Play" },
  disableAds: { defaultMessage: "Disable Ads" },
  disableAutoplay: { defaultMessage: "Disable Autoplay" },
  loopVideo: { defaultMessage: "Loop Video" },
  introduction: { defaultMessage: "Introduction" },
});

import { Select } from "@components";
import { useIntl } from "react-intl";
import { GetBrand, GetBrandVariables } from "@types";
import { useQuery } from "@apollo/client";
import { Queries } from "@gql";

export const ContentTypeSelector = (props: {
  setFilters: (selected: Array<string>, type: string) => void;
  preSelectedContentTypes: Array<string>;
  organizationId: string;
  filterType: string;
}) => {
  let intl = useIntl();
  const { preSelectedContentTypes, setFilters, organizationId, filterType } =
    props;

  let { data: brand } = useQuery<GetBrand, GetBrandVariables>(
    Queries.GET_BRAND,
    {
      variables: {
        organizationId,
      },
    }
  );

  let options =
    filterType === "scheduled"
      ? brand &&
        brand.brandConfiguration.contentTypes?.filter(
          (d) =>
            d.label.toLowerCase() !== "cne video" &&
            d.label.toLowerCase() !== "external link" &&
            d.label.toLowerCase() !== "photo" &&
            d.label.toLowerCase() !== "push notification" &&
            d.label.toLowerCase() !== "categories" &&
            d.label.toLowerCase() !== "clip" &&
            d.label.toLowerCase() !== "cartoon" &&
            d.label.toLowerCase() !== "audiofile"
        )
      : brand && brand.brandConfiguration.contentTypes;

  return (
    <Select
      id={"contentType"}
      multiple={true}
      placeholder={intl.formatMessage({
        defaultMessage: "Select",
        description:
          "Select as a verb. Placeholder text for a select form control",
      })}
      options={options || []}
      label={intl.formatMessage({
        defaultMessage: "Content Types",
        description: "Filter Content Types label",
      })}
      onChange={(selected) => {
        setFilters(selected, "types");
      }}
      value={preSelectedContentTypes}
      errors={[]}
    />
  );
};

ContentTypeSelector.displayName = "ContentTypeSelector";

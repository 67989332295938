import { SearchContainer, ListContainer } from "./ContainerTypes";
import { CurationType } from "@types";
import { BundleContainerComponent } from "../../types";

export const ContainerComponentMapping: {
  [key: string]: BundleContainerComponent | null;
} = {
  [CurationType.LIST]: ListContainer,
  [CurationType.SEARCH]: SearchContainer,
  [CurationType.TEXT]: null,
  [CurationType.MULTI_SEARCH]: null,
  [CurationType.SMART_LIST]: null,
};

import { useMemo, useState } from "react";
import { ARIA, Button, Table } from "@components";
import { useClipboardCopy, useToast } from "@hooks";
import { useIntl, FormattedMessage, FormattedDate } from "react-intl";
import {
  LinkIcon,
  PublishedIcon,
  ScheduledIcon,
} from "@condenast/gemini/icons";
import styled from "styled-components";
import { ThemeProvider } from "@contexts";
import { SyndicationData, SyndicationTargetData } from "@types";

const DialogBody = styled.div`
  padding: var(--spacing-xs) var(--spacing-md) var(--spacing-sm);
  max-width: 100vw;
  overflow: auto;
`;

const UriLink = styled.a`
  display: inline-flex;
  cursor: pointer;
  font: ${(props) => props.theme.FontBody};
  color: ${(props) => props.theme.SecondaryColor};

  & svg {
    margin-left: var(--spacing-xs);
  }
`;

const PublishDescription = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatusMessage = styled.span`
  font: ${(props) => props.theme.FontSmallStatement};

  strong {
    font: ${(props) => props.theme.FontLabel};
    color: ${(props) => props.theme.ToastIconColor};
  }

  svg {
    color: ${(props) => props.theme.ToastIconColor};
    vertical-align: top;
  }
`;

const PublishHistoryTable = styled(Table)`
  box-shadow: none;
  min-width: calc(10 * var(--spacing-xxl));
`;

const PublishHistoryRow = styled(Table.Row)`
  min-height: var(--spacing-xl);
  padding: var(--spacing-xs) 0;
`;

const PublishHistoryHeader = styled(Table.Header)`
  min-height: unset;
`;

const ActionButton = styled(Button)`
  font: ${(props) => props.theme.FontLabel};
`;

const ActionCell = styled(Table.Cell)`
  display: flex;
  justify-content: end;
`;

type PublishedRevisionData = {
  expired?: boolean;
  version: number;
  authorName: string;
  pubDate: string;
  createdAt: string;
};

function isRevision(
  entry: PublishedRevisionData | SyndicationTargetData
): entry is PublishedRevisionData {
  return (entry as PublishedRevisionData).authorName !== undefined;
}

function isSyndication(
  entry: PublishedRevisionData | SyndicationTargetData
): entry is SyndicationTargetData {
  return (entry as SyndicationTargetData).syndicationAuthor !== undefined;
}

function compareRevisionsAndSyndications(
  pub1: PublishedRevisionData | SyndicationTargetData,
  pub2: PublishedRevisionData | SyndicationTargetData
) {
  const createdAt1 = new Date(pub1.createdAt);
  const createdAt2 = new Date(pub2.createdAt);
  return createdAt1 > createdAt2 ? -1 : 1;
}

const dateFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
} as const;

const PublishStatus = (props: {
  revision: PublishedRevisionData;
  isLive: boolean;
  now: Date;
}) => {
  const { revision, isLive, now } = props;
  const intl = useIntl();
  return revision.expired ? (
    <ThemeProvider tint="gray">
      <StatusMessage>
        <del>
          <strong>
            <FormattedMessage
              defaultMessage="Previous"
              description="Previously live publish status"
            />
          </strong>{" "}
          <FormattedMessage
            defaultMessage="on {date} by {author}"
            description="Date and author info"
            values={{
              date: intl.formatDate(revision.createdAt, dateFormatOptions),
              author: revision.authorName,
            }}
          />
        </del>
      </StatusMessage>
    </ThemeProvider>
  ) : isLive ? (
    <ThemeProvider tint="green">
      <StatusMessage>
        <PublishedIcon size="small" />{" "}
        <strong>
          <FormattedMessage
            defaultMessage="Published"
            description="Publish status for published content"
          />
        </strong>{" "}
        <FormattedMessage
          defaultMessage="on {date} by {author}"
          description="Date and author info"
          values={{
            date: intl.formatDate(revision.createdAt, dateFormatOptions),
            author: revision.authorName,
          }}
        />
      </StatusMessage>
    </ThemeProvider>
  ) : new Date(revision.pubDate) > now ? (
    <ThemeProvider tint="yellow">
      <StatusMessage>
        <ScheduledIcon size="small" />{" "}
        <strong>
          <FormattedMessage
            defaultMessage="Scheduled"
            description="Publish status for scheduled content"
          />
        </strong>{" "}
        <FormattedMessage
          defaultMessage="on {date} by {author}"
          description="Date and author info"
          values={{
            date: intl.formatDate(revision.createdAt, dateFormatOptions),
            author: revision.authorName,
          }}
        />
      </StatusMessage>
    </ThemeProvider>
  ) : (
    <ThemeProvider tint="gray">
      <StatusMessage>
        <strong>
          <FormattedMessage
            defaultMessage="Previous"
            description="Previously live publish status"
          />
        </strong>{" "}
        <FormattedMessage
          defaultMessage="on {date} by {author}"
          description="Date and author info"
          values={{
            date: intl.formatDate(revision.createdAt, dateFormatOptions),
            author: revision.authorName,
          }}
        />
      </StatusMessage>
    </ThemeProvider>
  );
};

const PublishedRevisionEntry = (props: {
  revision: PublishedRevisionData;
  isLive: boolean;
  now: Date;
  unschedule: (version: number) => Promise<void>;
}) => {
  const { revision, now, isLive, unschedule } = props;
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <PublishHistoryRow>
      <Table.Cell>
        <PublishStatus revision={revision} now={now} isLive={isLive} />
      </Table.Cell>
      <Table.Cell>
        <StatusMessage>
          <FormattedDate value={revision.pubDate} {...dateFormatOptions} />
        </StatusMessage>
      </Table.Cell>
      <ActionCell>
        {!revision.expired && (new Date(revision.pubDate) > now || isLive) && (
          <ActionButton
            disabled={loading}
            onClick={(evt) => {
              evt.preventDefault();
              setLoading(true);
              unschedule(revision.version).then(() => {
                setLoading(false);
              });
            }}
            size="medium"
          >
            {isLive ? (
              <FormattedMessage defaultMessage="Unpublish" />
            ) : (
              <FormattedMessage defaultMessage="Unschedule" />
            )}
          </ActionButton>
        )}
      </ActionCell>
    </PublishHistoryRow>
  );
};

const SyndicationEntry = (props: {
  syndication: SyndicationTargetData;
  sourceId?: string;
  source?: string;
}) => {
  const { syndication, sourceId, source } = props;
  const intl = useIntl();
  return (
    <PublishHistoryRow>
      <Table.Cell>
        <ThemeProvider tint="gray">
          <StatusMessage>
            {sourceId ? (
              <FormattedMessage
                // eslint-disable-next-line formatjs/enforce-placeholders
                defaultMessage={`<md>**Syndicated from {brand}**</md> on {date} by {author} `}
                values={{
                  brand: sourceId ? source : syndication.brand,
                  date: intl.formatDate(
                    syndication.createdAt,
                    dateFormatOptions
                  ),
                  author: syndication.syndicationAuthor,
                }}
              />
            ) : (
              <FormattedMessage
                // eslint-disable-next-line formatjs/enforce-placeholders
                defaultMessage={`<md>**Syndicated to {brand}**</md> on {date} by {author} `}
                values={{
                  brand: sourceId ? source : syndication.brand,
                  date: intl.formatDate(
                    syndication.createdAt,
                    dateFormatOptions
                  ),
                  author: syndication.syndicationAuthor,
                }}
              />
            )}
          </StatusMessage>
        </ThemeProvider>
      </Table.Cell>
      <Table.Cell>
        <StatusMessage>
          <FormattedDate value={syndication.createdAt} {...dateFormatOptions} />
        </StatusMessage>
      </Table.Cell>
    </PublishHistoryRow>
  );
};

export const PublishHistory = (props: {
  publishedRevisions: PublishedRevisionData[];
  syndications?: SyndicationData;
  uri: string;
  consumerURL: string;
  onClose: () => void;
  unschedule: (version: number) => Promise<void>;
  diffUrl?: string;
  contentType: string;
}) => {
  const intl = useIntl();
  const now = new Date();
  const revisionsAndSyndications = useMemo(
    () =>
      [
        ...props.publishedRevisions,
        ...(props.syndications?.targets ?? []),
      ].sort(compareRevisionsAndSyndications),
    [props.publishedRevisions, props.syndications]
  );

  const livePublishedRevision = revisionsAndSyndications.find(
    (pub) => isRevision(pub) && !pub.expired && new Date(pub.pubDate) < now
  );
  const copyToClipboard = useClipboardCopy(`${props.consumerURL}/${props.uri}`);
  const showOnCopyMessage = useToast({
    type: "success",
    children: intl.formatMessage({
      defaultMessage: "Share link copied to clipboard",
    }),
  });

  const copyURI = () => {
    copyToClipboard();
    showOnCopyMessage();
  };

  return (
    <ARIA.Dialog
      title={intl.formatMessage({ defaultMessage: "Publish History" })}
      description={
        <PublishDescription>
          <UriLink
            role="button"
            aria-label={intl.formatMessage({ defaultMessage: "Copy link" })}
            aria-details={intl.formatMessage({
              defaultMessage:
                "This link can be viewed without a Copilot login. This is not a live URL.",
            })}
            onClick={copyURI}
            tabIndex={0}
          >
            {props.uri}
            <LinkIcon size="regular" role="presentation" />
          </UriLink>
          {props.diffUrl && (
            <Button
              as="a"
              href={props.diffUrl}
              target="_blank"
              rel="noopener noreferrer"
              size="small"
            >
              <FormattedMessage defaultMessage="Compare Revisions" />
            </Button>
          )}
        </PublishDescription>
      }
      size={"large"}
      onClose={props.onClose}
    >
      <DialogBody>
        <PublishHistoryTable gridTemplateColumns="2fr 1fr 100px">
          <Table.Head>
            <PublishHistoryRow>
              <PublishHistoryHeader>
                {intl.formatMessage({ defaultMessage: "Status" })}
              </PublishHistoryHeader>
              <PublishHistoryHeader>
                {intl.formatMessage({ defaultMessage: "Display publish date" })}
              </PublishHistoryHeader>
            </PublishHistoryRow>
          </Table.Head>
          <Table.Body>
            {revisionsAndSyndications.length ? (
              revisionsAndSyndications.map((revision) =>
                isRevision(revision) ? (
                  <PublishedRevisionEntry
                    key={`${revision.version}-${revision.createdAt}`}
                    revision={revision}
                    now={now}
                    isLive={revision === livePublishedRevision}
                    unschedule={props.unschedule}
                  />
                ) : isSyndication(revision) ? (
                  <SyndicationEntry
                    key={`${revision.createdAt}`}
                    syndication={revision}
                    sourceId={props.syndications?.sourceId}
                    source={props.syndications?.source}
                  />
                ) : (
                  <></>
                )
              )
            ) : (
              <PublishHistoryRow>
                <Table.Cell>
                  <FormattedMessage defaultMessage="This item has not yet been published" />
                </Table.Cell>
              </PublishHistoryRow>
            )}
          </Table.Body>
        </PublishHistoryTable>
      </DialogBody>
    </ARIA.Dialog>
  );
};
PublishHistory.displayName = "PublishHistory";
